import { Button, Modal, notification } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import arrowIcon from '../../../assets/images/ic-arrow-back.svg';
import css from '../../../assets/styles/budget.module.css';
import { getStorageToken } from '../../../helpers/storageTools';
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import config from '../../../config';
import ContractsService from '../../../services/ContractsService';
import { IAmendmentModel } from '../../../models/AmendmentModel';
import { IContractModel } from '../../../models/DocumentsModel';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Props {
  id: number | null;
  isOpen: boolean;
  setClose: () => void;
  isAmendment: boolean;
}

const contractsService = new ContractsService();

const PreviewPDF = (props: Props) => {
  const { id, isOpen, setClose, isAmendment } = props;
  const MIN_SCALE = 0.5;
  const MAX_SCALE = 3.0;
  const STEM_SCALE = 0.25;
  const [item, setItem] = useState<IAmendmentModel | IContractModel | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageScale, setPageScale] = useState(1.0);
  const [numPages, setNumPages] = useState<number | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (id && !isAmendment) {
      contractsService
        .getContractInfoById(id)
        .then((value) => setItem(value))
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        );
    }
  }, []);

  const onDocumentLoad = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const zoomIn = () => {
    if (pageScale === MAX_SCALE) {
      return;
    }
    setPageScale((state) => state + STEM_SCALE);
  };
  const zoomOut = () => {
    if (pageScale === MIN_SCALE) {
      return;
    }
    setPageScale((state) => state - STEM_SCALE);
  };

  const file = useMemo(
    () => ({
      url:
        isAmendment && id
          ? `${config.baseRequest}amendments/${id}/fileFull`
          : `${config.baseRequest}file/binary/${item?.file}`,
      httpHeaders: {
        Authorization: 'Bearer ' + getStorageToken(),
      },
    }),
    [item, id],
  );

  return (
    <Modal className="preview-pdf-modal" centered visible={isOpen} footer={null} closable={false}>
      <div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <div></div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
              <Button shape="circle" icon={<ZoomOutOutlined />} onClick={zoomOut} disabled={pageScale === MIN_SCALE} />
              <span>{`${pageScale.toFixed(2).replace(/0\.|\./gi, '')}%`}</span>
              <Button shape="circle" icon={<ZoomInOutlined />} onClick={zoomIn} disabled={pageScale === MAX_SCALE} />
            </div>
            <Button onClick={() => setClose()} className={`${css['return-btn']} common-secondary-btn`}>
              {t('common.return.btn')}
            </Button>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginTop: '1rem' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                disabled={pageNumber === 1}
                onClick={() => setPageNumber(pageNumber - 1)}
                style={{ border: 'none' }}>
                <img src={arrowIcon} alt="" style={{ height: '15px', width: '15px' }} />
              </Button>
              <span>
                {pageNumber}/{numPages || '1'}
              </span>
              <Button
                disabled={pageNumber === numPages}
                onClick={() => setPageNumber(pageNumber + 1)}
                style={{ border: 'none' }}>
                <img src={arrowIcon} alt="" style={{ transform: 'rotate(180deg)', height: '15px', width: '15px' }} />
              </Button>
            </div>
          </div>
        </div>
        {(isAmendment ? file : item) && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Document className="document-pdf" file={file} onLoadError={console.error} onLoadSuccess={onDocumentLoad}>
              <Page scale={pageScale} pageNumber={pageNumber} />
            </Document>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default PreviewPDF;
