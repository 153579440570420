import React, { useContext } from 'react';
import { CategoriesContext } from '../../../contexts/CategoriesContextProvider';
import { Button, Card, Space, Spin, Typography } from 'antd';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import {
  CATEGORY_SET_CURRENT_NODE,
  CATEGORY_SET_IS_SELECTED_NODE,
} from '../../../constants/actionTypes/categoriesConstants';
import { useLessThen801 } from '../../../helpers/mediaDetect';

interface NodesModifyLayoutModel {
  submit: (values: any) => void;
  loading?: boolean;
  isSaving?: boolean;
  children: any;
}

const CardTitle = ({ id, submit, cancel }: any) => (
  <div className="modify-title-header">
    <Typography.Text type="secondary">{!id ? `Create new category` : `# ${id}`}</Typography.Text>
    <div className="modify-action-btn">
      <Space>
        <Button size="small" type="primary" onClick={submit}>
          <SaveOutlined />
        </Button>
        <Button size="small" onClick={cancel}>
          <CloseOutlined />
        </Button>
      </Space>
    </div>
  </div>
);

const NodesModifyLayout: React.FC<NodesModifyLayoutModel> = ({
  submit,
  children,
  loading = false,
  isSaving = false,
}: NodesModifyLayoutModel) => {
  const { nodes, nodesDispatch } = useContext(CategoriesContext);
  const { height } = useWindowDimensions();

  const cancel = () => {
    nodesDispatch({
      type: CATEGORY_SET_IS_SELECTED_NODE,
      isSelectedNode: false,
    });
    nodesDispatch({
      type: CATEGORY_SET_CURRENT_NODE,
      currentNode: null,
      currentNodeKey: null,
    });
  };

  return (
    <Card
      title={<CardTitle id={nodes?.currentNode?.id} submit={submit} cancel={cancel} />}
      style={useLessThen801() ? {} : { height: height - 300, overflow: 'auto' }}
      loading={loading}>
      <Spin spinning={isSaving}>{children}</Spin>
    </Card>
  );
};

export default NodesModifyLayout;
