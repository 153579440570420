import React, { useState, useEffect, useContext } from 'react';
import { notification, Spin, Table, TablePaginationConfig } from 'antd';
import { useTranslation } from 'react-i18next';
import { getColumns } from './components/NotificationHistoryColumns';
import { HeaderContext } from '../../contexts/HeaderContextProvider';
import { CHANGE_CURRENT_PAGE } from '../../constants/actionTypes/headerConstants';
import css from '../../assets/styles/consumers.module.css';
import { generateFilterfromObj } from '../../helpers/generateFIlterfromObj';
import { generateSorter } from '../../helpers/generateSorter';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import filterIcon from '../../assets/images/ic-filter.svg';
import filterActiveIcon from '../../assets/images/ic-filter-active.svg';
import { useLessThen801 } from '../../helpers/mediaDetect';
import TableMobile from '../common/TableMobile';
import NotificationHistoryControl from './components/NotificationHistoryControl';
import NotificationsService from '../../services/NotificationsService';
import { INotificationHistory, NotificationsHistory } from '../../models/NotificationModel';
import { SHORT_DATE_TIME_FORMAT } from '../../constants/formats';
import { isEmptyObject } from '../../helpers/isEmptyObject';
import { IResponceData } from '../../typings/IServiceOptions';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import i18n from '../../utils/i18n';

const service = new NotificationsService();

const NotificationHistoryPage: React.FC = () => {
  const { t } = useTranslation();
  const [items, setItems] = useState<NotificationsHistory>([]);
  const { height: windowHeight } = useWindowDimensions();
  const [fetchingData, setFetchingData] = useState(false);
  const { headerDispatch } = useContext(HeaderContext);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [currentFilters, setCurrentFilters] = useState<{ [name: string]: any }>({});

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    skip: 0,
  });

  const option = {
    filters: generateFilterfromObj(currentFilters).filter,
    count: true,
    top: pagination.pageSize,
    current: pagination.current,
    orderBy: generateSorter(['createdDate desc']).orderBy,
  };

  useEffect(() => {
    headerDispatch({
      type: CHANGE_CURRENT_PAGE,
      currentPage: t('header.title.Notification'),
      path: 'notification',
    });
  }, [i18n.language]);

  const getNotifications = (option: { [name: string]: any }) => {
    setFetchingData(true);
    service
      .getNotificationsHistory(option)
      .then((res: IResponceData<INotificationHistory>) => {
        const { count, items } = res;
        const { current, top } = option;
        setPagination({ ...pagination, current, total: count, pageSize: top });
        setItems(items);
      })
      .catch(() =>
        notification.error({
          message: t('common.error.internalServerError'),
        }),
      )
      .finally(() => setFetchingData(false));
  };

  useEffect(() => {
    getNotifications(option);
  }, []);

  const applyNewFilter = (filters: { [name: string]: any }) => {
    setCurrentFilters({ ...currentFilters, ...filters });
    return { ...currentFilters, ...filters };
  };

  const onTableChange = (
    pagination?: TablePaginationConfig,
    filters?: Record<string, FilterValue | null | any>,
    sorter?: SorterResult<INotificationHistory> | SorterResult<INotificationHistory>[],
  ) => {
    let params: { [name: string]: any } = {
      ...option,
    };
    if (filters && !isEmptyObject(filters)) {
      params = {
        ...params,
        filters: generateFilterfromObj(applyNewFilter(filters)).filter,
      };
    } else {
      params = {
        ...params,
        filters: generateFilterfromObj(currentFilters).filter,
      };
    }
    if (pagination && pagination.pageSize && pagination.current) {
      params = {
        ...params,
        top: pagination.pageSize,
        skip: pagination.pageSize * (pagination.current - 1),
        current: pagination.current,
      };
    } else {
      params = {
        ...params,
        skip: 0,
        current: 1,
      };
    }
    if (sorter && !Array.isArray(sorter) && sorter.order) {
      const { order, columnKey } = sorter;
      switch (columnKey) {
        case 'createdDate': {
          params = {
            ...params,
            orderBy: `createdDate ${order === 'ascend' ? 'asc' : 'desc'}`,
          };
          break;
        }
      }
    }

    getNotifications(params);
  };

  const changeOpenFilter = () => {
    setIsOpenFilter(!isOpenFilter);
  };

  const loadMore = (option: { [name: string]: any }) => {
    setFetchingData(true);
    option.skip = items.length;
    service
      .getNotificationsHistory(option)
      .then((res: IResponceData<INotificationHistory>) => {
        const { count } = res;
        const { current, top } = option;
        setPagination({ ...pagination, current, total: count, pageSize: top });
        setItems([...items, ...res.items]);
      })
      .catch((e) => console.log(e))
      .finally(() => setFetchingData(false));
  };

  if (useLessThen801()) {
    const data =
      items.length > 0 &&
      (items.map((item: INotificationHistory) => [
        {
          title: '#',
          description: item.id,
          type: 'string',
        },
        { title: t('notifications.column.event.name'), description: item.title, type: 'string' },
        { title: t('notifications.column.message'), description: item.message, type: 'string' },
        {
          title: t('notifications.column.creator'),
          description: `${item.createdUser?.firstName || ''} ${item.createdUser?.lastName || ''}`,
          type: 'string',
        },
        {
          title: t('notifications.column.date'),
          description: item.createdDate,
          format: SHORT_DATE_TIME_FORMAT,
          type: 'date',
        },
        {
          title: t('users.table.columns.name'),
          description: item.recipients.map((el) => el.recipient?.login).join(' '),
          type: 'string',
        },
      ]) as any);

    return (
      <>
        <div className="common-mobile-title-container">
          <span style={{ fontSize: '20px' }}>{t('header.title.Notification')}</span>
          <>
            <div className="common-modbile-filter-container" onClick={changeOpenFilter}>
              <img src={isOpenFilter ? filterActiveIcon : filterIcon} width={16} />
              <span className="filter-title" style={{ marginRight: '1rem' }}>
                {isOpenFilter ? t('common.filter.close') : t('common.filter.open')}
              </span>
            </div>
          </>
        </div>
        {isOpenFilter && <NotificationHistoryControl onTableChange={onTableChange} />}
        <TableMobile
          data={data}
          isLoading={fetchingData}
          loadMore={loadMore}
          option={option}
          itemsLength={items.length}
          totalItems={pagination.total}
        />
      </>
    );
  }

  return (
    <>
      <Spin spinning={fetchingData}>
        <NotificationHistoryControl onTableChange={onTableChange} />
        <div className={css['consumers-table-container']}>
          <Table
            columns={getColumns({
              t,
            })}
            onChange={onTableChange}
            size="middle"
            dataSource={items}
            showHeader={true}
            scroll={{ y: windowHeight - 300 }}
            className="consumers-info-table"
            rowClassName="common-table-row--pointer"
            pagination={pagination}
            rowKey="id"
          />
        </div>
      </Spin>
    </>
  );
};

export default NotificationHistoryPage;
