import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, DatePicker, Drawer, Form, Input, InputNumber, notification, Select, Table, Upload } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../../assets/styles/budget.module.css';
import { ConsumerModel } from '../../../../models/ConsumerModel';
import ProjectsService from '../../../../services/ProjectsService';
import ConfirmUploadForm from './ConfirmUploadForm';
import { getColumns } from './InvoiceUploadColumns';
import confirmIcon from '../../../../assets/images/ic-check-mark-gray-copy.svg';
import alertIcon from '../../../../assets/images/ic-alert.svg';
import { CompanyModel, PaymentInfo } from '../../../../models/CompaniesModel';
import { useLessThen801 } from '../../../../helpers/mediaDetect';
import { ICFC, ICFCitem } from '../../../../models/CFCModel';
import CFCService from '../../../../services/CFCService';
import i18n from '../../../../utils/i18n';
import CustomSignFlow from '../../../../services/CustomSignFlow';
import { IFlow, IFlowStep, ISignatoryFlow } from '../../../../models/CustomSignFlowModel';
import { generateFilterfromObj } from '../../../../helpers/generateFIlterfromObj';
import { FilterTypes } from '../../../../constants/filterTypes';
import UsersService from '../../../../services/UsersService';
import { convertToCoins, convertToCurrency, convertToPriceWithSpace } from '../../../../helpers/convertToPrice';
import { NewBudgetModel } from '../../../../models/BudgetModel';
import { IUserEasy } from '../../../../models/UserModel';
import { IResponceData } from '../../../../typings/IServiceOptions';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';

interface InvoiceUploadProps {
  isOpenUpload: boolean;
  setIsOpenUpload: (b: boolean) => void;
  projectId: string;
  propertyId: string;
  setIsCreatedInvoice: (b: boolean) => void;
  setIsOpenStatusForm: (b: boolean) => void;
  setCurrentInvoice: (v: number | null) => void;
  setIsNeedUpdate: (b: boolean) => void;
  setIsOpenUploadMessageModal: (b: boolean) => void;
}
const { TextArea } = Input;

const projectsService = new ProjectsService();
const cfcService = new CFCService();
const serviceFlow = new CustomSignFlow();
const userService = new UsersService();

const InvoiceUploadForm = (props: InvoiceUploadProps) => {
  const {
    isOpenUpload,
    setIsOpenUpload,
    projectId,
    propertyId,
    setIsCreatedInvoice,
    setIsOpenStatusForm,
    setCurrentInvoice,
    setIsNeedUpdate,
    setIsOpenUploadMessageModal,
  } = props;
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState<{ index: number; quantity: number; price: number }[]>([]);
  const [isOpenIntroduction, setIsOpenIntroduction] = useState(false);
  const [isOpenIncluding, setIsOpenIncluding] = useState(false);
  const [isOpenInvoiceDate, setIsOpenInvoiceDate] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [project, setProject] = useState<any>(null);
  const [introductionText, setIntroductionText] = useState('');
  const [includingText, setIncludingText] = useState('');
  const [subtotal, setSubtotal] = useState(0);
  const [subtotal2, setSubtotal2] = useState(0);
  const [vat, setVat] = useState(7.7);
  const [fees, setFees] = useState(18);
  const [createdBody, setCreatedBody] = useState<NewBudgetModel | null>(null);
  const [total, setTotal] = useState(0);
  const [itemKey, setItemKey] = useState(0);
  const [invoiceDate, setInvoiceDate] = useState<moment.Moment | null>(moment());
  const [fileAttachmentList, setFileAttachmentList] = useState<UploadFile[]>([]);
  const [paymentsProfile, setPaymentsProfile] = useState<PaymentInfo[] | null>(null);
  const [paymentProfile, setPaymentProfile] = useState<PaymentInfo | null>(null);
  const [deadline, setDeadline] = useState<moment.Moment | null>(moment().add(10, 'days'));
  const [CFC, setCFC] = useState<ICFC[]>([]);
  const [CFCitems, setCFCitems] = useState<ICFCitem[]>([]);
  const [isNeedUpdateSum, setIsNeedUpdateSum] = useState(false);
  const [flows, setFlows] = useState<IFlow[]>([]);
  const [flowSteps, setFlowSteps] = useState<IFlowStep[]>([]);
  const [selectConsumers, setSelectConsumers] = useState<ConsumerModel[]>([]);

  const [searchValue, setSearchValue] = useState<null | string>(null);
  const [currentTimeout, setCurrentTimeout] = useState<NodeJS.Timeout | null>(null);
  const [agents, setAgents] = useState<IUserEasy[]>([]);
  const [consumers, setConsumers] = useState<ConsumerModel[]>([]);
  const [isLastAgentsReq, setIsLastAgentsReq] = useState(false);
  const [fetchingUserData, setFetchingUserData] = useState(false);
  const [paginationAgents, setPaginationAgents] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    skip: 0,
  });

  const [fields, setFields] = useState([
    { id: 1, isDone: true, title: t('budget.upload.overview.fields.client') },
    { id: 2, isDone: true, title: t('budget.upload.overview.fields.id') },
    { id: 3, isDone: false, title: t('budget.upload.overview.fields.invoice') },
    { id: 4, isDone: true, title: t('budget.upload.overview.fields.date') },
    { id: 5, isDone: false, title: t('budget.upload.overview.fields.items') },
    { id: 6, isDone: true, title: t('budget.upload.overview.fields.details') },
    { id: 7, isDone: true, title: t('budget.upload.overview.fields.vat') },
    { id: 8, isDone: true, title: t('budget.upload.overview.fields.fees') },
  ]);

  const [form] = Form.useForm();

  useEffect(() => {
    getAgents();
    getConsumers();
  }, []);

  useEffect(() => {
    if (searchValue === '') {
      return getAgents();
    }
    if (searchValue) {
      getAgents();
    }
  }, [searchValue]);

  const getAgents = () => {
    const optionUser = {
      count: true,
      top: paginationAgents.pageSize,
      current: paginationAgents.current,
      skip: paginationAgents.pageSize * (paginationAgents.current - 1) || 0,
      fullName: searchValue || '',
      projectId: [projectId],
      roles: ['agent', 'admin', 'super_agent'],
    };

    setFetchingUserData(true);
    userService
      .getEasyUsers(optionUser)
      .then((res: IResponceData<IUserEasy>) => {
        const { count, items } = res;
        const { current, top } = optionUser;
        setPaginationAgents({ ...paginationAgents, current: current + 1, total: count, pageSize: top });
        const ids = Array.from(new Set([...agents, ...items].map((user) => user.id)));
        const uniqUsers = ids.map((id) => [...agents, ...items].find((customer) => customer.id === id));
        setAgents(uniqUsers as IUserEasy[]);
        setIsLastAgentsReq(current * top >= count);
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingUserData(false));
  };

  const getConsumers = () => {
    userService
      .getConsumersByProperty(+propertyId)
      .then((value: ConsumerModel[]) => setConsumers(value))
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      );
  };

  useEffect(() => {
    const newFields = fields.slice().map((item) => {
      if (item.id === 5) {
        return {
          id: item.id,
          isDone: dataSource.length > 0 ? true : false,
          title: item.title,
        };
      } else {
        return item;
      }
    });
    setFields(newFields);
  }, [dataSource]);

  useEffect(() => {
    projectsService
      .getProjectById(+projectId)
      .then((value) => {
        setProject(value);
        if (!value.defaultPaymentInfoId || value.companies.length === 0) {
          notification.error({
            message: `${t('project.not.fill.payment')} ${value.title}`,
          });
          setIsOpenUpload(false);
        }
        if (value.companies.length > 0) {
          setPaymentsProfile(
            value.companies.reduceRight((prev: PaymentInfo[], curr: CompanyModel) => {
              prev.push(...curr.paymentInfo);
              return prev;
            }, []),
          );
        }
        form.setFieldsValue({ defaultPaymentInfoId: value.defaultPaymentInfoId });
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      );
  }, []);

  useEffect(() => {
    serviceFlow
      .getFlows({
        filters: generateFilterfromObj({
          'projects/id': {
            type: FilterTypes.SELECT,
            value: +projectId,
          },
          'document_types/code': {
            type: FilterTypes.SELECT,
            value: 'invoices',
          },
        }),
      })
      .then((flows: IFlow[]) => {
        setFlows(flows);
        const defaultFlowId = flows.find((item) => item.isDefault)?.id;
        form.setFieldsValue({ flow: defaultFlowId || '' });
        setFlowSteps(
          flows
            .find((item) => item.id === defaultFlowId)
            ?.flowsSteps.map((item) => ({ signatoriesName: item.step.signatoriesName, code: item.step.code })) || [],
        );
      });
  }, []);

  useEffect(() => {
    const subtotalCoins = convertToCoins(subtotal);
    const subtotal2Coins = convertToCoins(subtotal2);
    setSubtotal2(
      convertToCurrency(
        Math.floor(subtotalCoins + (fees / 100) * subtotalCoins < 0 ? 0 : subtotalCoins + (fees / 100) * subtotalCoins),
      ),
    );
    setTotal(
      convertToCurrency(
        Math.floor(
          subtotal2Coins + (vat / 100) * subtotal2Coins < 0 ? 0 : subtotal2Coins + (vat / 100) * subtotal2Coins,
        ),
      ),
    );
  }, [vat, subtotal, fees, subtotal2]);

  const onSubmit = (value: any) => {
    if (!value.items) {
      notification.error({
        message: t('common.no.items'),
      });
      return;
    }

    const body: NewBudgetModel = {
      paymentDate: moment(value.deadline).toString(),
      invoiceDate: moment(invoiceDate).format('YYYY-MM-DD'),
      name:
        (selectConsumers.length > 0 &&
          selectConsumers.map((user) => `${user.firstName || ''} ${user.lastName || ''}`).join(' & ')) ||
        '',
      sum: +(+subtotal * 100).toFixed(0),
      fees,
      sumWithFees: +(+subtotal2 * 100).toFixed(0),
      vat,
      sumWithVat: +(total * 100).toFixed(0),
      description: value.description,
      number: value.invoiceId,
      property: {
        id: +propertyId,
      },
      companyPaymentInfo: {
        id: paymentProfile!.id,
      },
      currency: {
        code: project?.defaultCurrency?.code || t('budget.currency').toLowerCase(),
      },
      statusV2: {
        code: 'new',
      },
      items: value.items?.map((item: any) => {
        if (item.cfc) {
          if (CFC.map((cfc) => cfc.code.toString().toLowerCase()).includes(item.cfc.toLowerCase()) && item.id) {
            return {
              taskName: `${item.name}${item.comment ? ` (${item.comment})` : ''}`,
              quantity: +item.quantity,
              price: +(+item.price * 100).toFixed(0),
              total: +(+item.price * +item.quantity * 100).toFixed(0),
              cfcCodeItem: {
                id: item.id,
                description: item.name || '',
                cfc: { code: item.cfc },
                unit: item.unit || '-',
              },
            };
          }
          return {
            taskName: `${item.name}${item.comment ? ` (${item.comment})` : ''}`,
            quantity: +item.quantity,
            price: +(+item.price * 100).toFixed(0),
            total: +(+item.price * +item.quantity * 100).toFixed(0),
            cfcCodeItemCustom: {
              description: item.name || '',
              cfc: { code: item.cfc },
              unit: item.unit || '-',
            },
          };
        }
        return {
          taskName: `${item.name}${item.comment ? ` (${item.comment})` : ''}`,
          quantity: +item.quantity,
          price: +(+item.price * 100).toFixed(0),
          total: +(+item.price * +item.quantity * 100).toFixed(0),
        };
      }),
      comment: value.comment,
      flow: value.flow ? { id: value.flow } : null,
    } as NewBudgetModel;

    const signatiriesFlow: ISignatoryFlow[] = [];
    const currentFlow = flows
      ?.find((item) => value.flow && item.id === +value.flow)
      ?.flowsSteps?.sort((a, b) => a.ord - b.ord);

    currentFlow &&
      currentFlow.forEach((item) => {
        value[item.step.code].forEach((id: number) => {
          signatiriesFlow.push({ consumerId: id, stepCode: item.stepCode });
        });
      });

    if (signatiriesFlow.length > 0) {
      body['signatories'] = signatiriesFlow;
    }

    if (introductionText) {
      body.introduction = introductionText;
    }
    if (includingText) {
      body.concluding = includingText;
    }
    setIsOpenConfirm(true);
    setCreatedBody(body);
  };

  const addItem = () => {
    setCFCitems([]);
    setDataSource([...dataSource, { index: itemKey, quantity: 1, price: 0 }]);
    setItemKey(itemKey + 1);
  };

  useEffect(() => {
    if (consumers.length > 0 && form.getFieldValue('flow') === '') {
      return setSelectConsumers(consumers.filter((user) => user.id));
    }
    setSelectConsumers([]);
  }, [consumers, form]);

  const onFormChange: (changedValues: any, values: any) => void = (_, allValues) => {
    if (allValues.items) {
      let sum = 0;
      allValues.items.forEach((item: any) => {
        if (item.price && item.quantity) {
          sum = sum + +item.price * +item.quantity;
        } else if (!item.price) {
          return;
        } else {
          sum = +(+item.price);
        }
      });
      setSubtotal(sum);
    }
    const consumerFieldsName = flowSteps.filter((item) => item.code.split('_').includes('client')) ?? [];
    let currentConsumers: number[] = [];
    consumerFieldsName?.forEach((item) => {
      const users = allValues[item.code];
      if (users && users.length > 0) {
        currentConsumers.push(...users);
      }
    });
    const uniqCustomers = (currentConsumers.map((id) => consumers.find((customer) => customer.id === id)) ??
      []) as ConsumerModel[];
    setTimeout(() => {
      setSelectConsumers(allValues.flow === '' ? consumers.filter((user) => user.id) : uniqCustomers);
    }, 1);
  };

  const handleFileAttachment = (info: UploadChangeParam<UploadFile>) => {
    setFileAttachmentList(info.fileList);
  };

  const onChangePaymentProfile = (idProfile: number) => {
    if (paymentsProfile && paymentsProfile.length > 0) {
      setPaymentProfile(paymentsProfile.find((el: PaymentInfo) => el.id === idProfile) || null);
    }
  };

  useEffect(() => {
    if (project?.key) {
      getCFC(project.key);
    }
  }, [project]);

  const getCFCitems = (code: string) => {
    cfcService
      .getCFCitems(code, project.key)
      .then((value) => setCFCitems(value))
      .catch((e) => {
        notification.error({
          message: e,
        });
      });
  };

  const getCFC = (projectKey: string, option = {}) => {
    cfcService
      .getCFC(projectKey, option)
      .then((res) => {
        setCFC(res);
      })
      .catch((e) => {
        notification.error({
          message: e,
        });
      });
  };

  useEffect(() => {
    if (isNeedUpdateSum && form.getFieldsValue() && form.getFieldsValue()?.items?.length > 0) {
      setIsNeedUpdateSum(false);
      onFormChange(null, form.getFieldsValue());
    }
  }, [isNeedUpdateSum]);

  useEffect(() => {
    if (paymentsProfile && paymentsProfile.length > 0 && project.defaultPaymentInfoId && !paymentProfile) {
      setPaymentProfile(paymentsProfile.find((el: PaymentInfo) => el.id === project.defaultPaymentInfoId) || null);
    }
  }, [paymentsProfile]);

  const onSelectFlow = (value: number | '') => {
    form.setFieldsValue({ status: 'new' });
    setSelectConsumers([]);
    setFlowSteps(
      flows
        .find((item) => item.id === value)
        ?.flowsSteps.map((item) => ({ signatoriesName: item.step.signatoriesName, code: item.step.code })) || [],
    );
  };

  return (
    <Drawer className="upload-form-drawer" visible={isOpenUpload} placement="top" closable={false}>
      <Form autoComplete="off" onFinish={onSubmit} form={form} onValuesChange={onFormChange}>
        {isOpenConfirm && createdBody && (
          <ConfirmUploadForm
            isOpenConfirm={isOpenConfirm}
            setIsOpenUpload={setIsOpenUpload}
            setIsOpenConfirm={setIsOpenConfirm}
            createdBody={createdBody}
            setIsCreatedInvoice={setIsCreatedInvoice}
            setIsOpenStatusForm={setIsOpenStatusForm}
            setCurrentInvoice={setCurrentInvoice}
            setIsNeedUpdate={setIsNeedUpdate}
            fileAttachmentList={fileAttachmentList}
            setIsOpenUploadMessageModal={setIsOpenUploadMessageModal}
          />
        )}
        <Form.Item>
          <div className={css['btns-container']}>
            <Button className={`${css['return-btn']} common-secondary-btn`} onClick={() => setIsOpenUpload(false)}>
              {t('common.return.btn')}
            </Button>
            <Button
              className={`${css['upload-btn']} common-primary-btn`}
              style={{ marginLeft: '1rem' }}
              htmlType="submit">
              {t('common.title.create')}
            </Button>
          </div>
        </Form.Item>
        <div className={css['content-container']}>
          <div className={css['upload-info-container']}>
            <div className={css['invoice-info-container']}>
              <span style={{ fontWeight: 800, fontSize: '20px', marginBottom: '1rem' }}>
                {t('budget.upload.invoice.title')}
              </span>
              <div
                className={css['upload-steps-quoteId-container']}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: '1rem',
                }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: useLessThen801() ? '1rem' : '0' }}>
                  <span className={css['upload-steps-quoteId']}>{t('budget.upload.overview.fields.invoice')}</span>
                  <Form.Item
                    name="invoiceId"
                    style={{ margin: 0, marginLeft: '1rem' }}
                    rules={[{ required: true, message: t('common.validation.required') }]}>
                    <Input
                      style={{ width: useLessThen801() ? '100%' : '200px' }}
                      className="consumer-form-input common-animation-primary"
                      onChange={(e) => {
                        const newFields = fields.slice().map((item) => {
                          if (item.id === 3) {
                            return {
                              id: item.id,
                              isDone: e.target.value ? true : false,
                              title: item.title,
                            };
                          } else {
                            return item;
                          }
                        });
                        setFields(newFields);
                      }}
                    />
                  </Form.Item>
                </div>
                <div>
                  {!isOpenInvoiceDate && (
                    <span onClick={() => setIsOpenInvoiceDate(true)} className={css['upload-steps-qouteDate']}>
                      <span>{t('budget.upload.overview.fields.date')}</span>
                      {` ${moment(invoiceDate).format('DD.MM.YYYY') || moment().format('DD.MM.YYYY')}`}
                    </span>
                  )}
                  {isOpenInvoiceDate && (
                    <Form.Item name="invoiceDate" className={css['upload-steps-qouteDate']}>
                      <DatePicker
                        getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
                        inputReadOnly
                        defaultValue={moment()}
                        onBlur={() => setIsOpenInvoiceDate(false)}
                        onChange={(value) => setInvoiceDate(value)}
                        className={`common-date-picker common-animation-primary ${css['upload-steps-qouteDate']}`}
                        suffixIcon={<div className={'calendar-img'} style={{ width: '18px', height: '18px' }}></div>}
                      />
                    </Form.Item>
                  )}
                  <div>
                    <span>
                      <span>{t('budget.table.date')}</span>
                      {` ${moment(deadline).format('DD.MM.YYYY') || moment().format('DD.MM.YYYY')}`}
                    </span>
                  </div>
                </div>
              </div>
              <div className={css['upload-user-project-container']} style={{ marginBottom: '1rem' }}>
                {t('consumers.create.name')}:
                <span style={{ color: '#778dac', marginLeft: '1rem' }}>
                  {selectConsumers.map((user) => `${user.firstName || ''} ${user.lastName || ''}`).join(' & ')}
                </span>
              </div>
              {!isOpenIntroduction &&
                (introductionText ? (
                  <span onClick={() => setIsOpenIntroduction(true)}>{introductionText}</span>
                ) : (
                  <Button onClick={() => setIsOpenIntroduction(true)} className={css['upload-add-btn']}>
                    <PlusCircleOutlined />
                    {t('budget.invoice.form.optional')}
                  </Button>
                ))}
              <Form.Item name="introductionText">
                {isOpenIntroduction && (
                  <TextArea
                    onBlur={() => setIsOpenIntroduction(false)}
                    onChange={(value) => setIntroductionText(value.target.value)}
                    className="consumer-form-input common-animation-primary"
                  />
                )}
              </Form.Item>
              <Table
                columns={getColumns({
                  t,
                  form,
                  dataSource,
                  setDataSource,
                  CFC,
                  getCFCitems,
                  CFCitems,
                  setIsNeedUpdateSum,
                })}
                className="upload-invoice-table"
                dataSource={dataSource}
                pagination={false}
                key="index"
                rowKey={(record) => record.index}
                showHeader={true}
                rowClassName="common-table-row--pointer"
                size="small"
              />
              <Button onClick={() => addItem()} className={css['upload-add-btn']}>
                <PlusCircleOutlined />
                {t('budget.invoice.form.add.item')}
              </Button>
            </div>
            <div className={css['upload-cost-container']}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: useLessThen801() ? '100%' : '30%',
                  marginTop: useLessThen801() ? '1rem' : '0',
                }}>
                <span>{t('budget.invoice.form.subtotal.1')}:</span>
                <span>{`${project?.defaultCurrency?.description || ''} ${convertToPriceWithSpace(subtotal)}`}</span>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  minWidth: i18n.language === 'fr-FR' ? '64.8%' : '44.8%',
                  marginBottom: '0.5rem',
                }}>
                <Form.Item
                  name="fees"
                  label={`${t('budget.upload.overview.fields.fees')} (%)`}
                  rules={[{ required: true, message: t('common.validation.required') }]}
                  initialValue={18}
                  style={{ marginBottom: 0 }}>
                  <InputNumber
                    min={0}
                    style={{ width: '70px', marginLeft: '0.5rem' }}
                    onChange={(e) => {
                      setFees(+e);
                      const newFields = fields.slice().map((item) => {
                        if (item.id === 8) {
                          return {
                            id: item.id,
                            isDone: typeof e === 'number' ? true : false,
                            title: item.title,
                          };
                        } else {
                          return item;
                        }
                      });
                      setFields(newFields);
                    }}
                    className="consumer-form-input common-animation-primary"
                  />
                </Form.Item>
                <span>{`${project?.defaultCurrency?.description || ''} ${convertToPriceWithSpace(
                  (fees / 100) * subtotal,
                )}`}</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '30%' }}>
                <span>{t('budget.invoice.form.subtotal.2')}:</span>
                <span>{`${project?.defaultCurrency?.description || ''} ${convertToPriceWithSpace(subtotal2)}`}</span>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: useLessThen801() ? '100%' : '30%',
                }}>
                <Form.Item
                  name="vat"
                  className="upload-steps-vat-container"
                  label={<span>{`${t('budget.upload.overview.fields.vat')} (%)`}</span>}
                  rules={[{ required: true, message: t('common.validation.required') }]}
                  initialValue={7.7}
                  style={{ marginBottom: 0, width: useLessThen801() ? 'fit-content' : 'auto' }}>
                  <InputNumber
                    min={0}
                    style={{ width: '70px', marginLeft: '0.5rem' }}
                    onChange={(e) => {
                      setVat(+e);
                      const newFields = fields.slice().map((item) => {
                        if (item.id === 7) {
                          return {
                            id: item.id,
                            isDone: typeof e === 'number' ? true : false,
                            title: item.title,
                          };
                        } else {
                          return item;
                        }
                      });
                      setFields(newFields);
                    }}
                    className="consumer-form-input common-animation-primary"
                  />
                </Form.Item>
                <span>{`${project?.defaultCurrency?.description || ''} ${convertToPriceWithSpace(
                  (vat / 100) * subtotal2,
                )}`}</span>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: useLessThen801() ? '100%' : '30%',
                  marginBottom: useLessThen801() ? '1rem' : '0',
                }}>
                <span>{t('budget.invoice.form.total')}</span>
                <span>{`${project?.defaultCurrency?.description || ''} ${convertToPriceWithSpace(total)}`}</span>
              </div>
            </div>
            {!isOpenIncluding &&
              (includingText ? (
                <span onClick={() => setIsOpenIncluding(true)}>{includingText}</span>
              ) : (
                <Button onClick={() => setIsOpenIncluding(true)} className={css['upload-add-btn']}>
                  <PlusCircleOutlined />
                  {t('budget.invoice.form.concluding')}
                </Button>
              ))}
            <Form.Item name="includingText">
              {isOpenIncluding && (
                <TextArea
                  onBlur={() => setIsOpenIncluding(false)}
                  onChange={(value) => setIncludingText(value.target.value)}
                  className="consumer-form-input common-animation-primary"
                />
              )}
            </Form.Item>
            <div
              className="invoice-select-container-paymentProfile"
              style={{ display: 'flex', alignItems: 'center', marginBottom: '24px' }}>
              <span style={{ marginRight: '1rem' }}>{t('budget.payment.profile.select')}</span>
              <Form.Item name="defaultPaymentInfoId">
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
                  className="consumers-control-select common-animation-primary"
                  onChange={onChangePaymentProfile}
                  style={{ marginTop: 0 }}>
                  {paymentsProfile &&
                    paymentsProfile
                      .sort((a, b) => a.accountHolder.toLowerCase().localeCompare(b.accountHolder.toLowerCase()))
                      .map((item: PaymentInfo) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.accountHolder}
                        </Select.Option>
                      ))}
                </Select>
              </Form.Item>
            </div>
            <div>
              <span style={{ fontWeight: 'bold', color: '#778dac' }}>{t('budget.invoice.form.bank.acc')}</span>
              <br />
              <span style={{ color: '#778dac' }}>{`Receiver: ${paymentProfile?.accountHolder || ''}`}</span>
              <br />
              <span style={{ color: '#778dac' }}>{paymentProfile?.bankName || ''}</span>
              <br />
              <span style={{ color: '#778dac' }}>{`Country of bank: ${paymentProfile?.countryOfBank || ''}`}</span>
              <br />
              <span style={{ color: '#778dac' }}>{`IBAN: ${paymentProfile?.iban || ''}`}</span>
              <br />
              <span style={{ color: '#778dac' }}>{`SWIFT/BIC: ${paymentProfile?.swift || ''}`}</span>
              <br />
            </div>
          </div>
          <div className={css['upload-steps-right-col']}>
            <div className={css['upload-steps-item']}>
              <div className={css['upload-steps-container']}>
                <span style={{ marginBottom: '0.5rem' }}>{t('contract.flow.title')}</span>
                <Form.Item name="flow" colon={false} className={css['form-item']} initialValue={''} labelAlign="left">
                  <Select
                    className="common-select common-animation-primary"
                    suffixIcon={
                      <div
                        className={`arrow-drop-img ${css['arrow-drop']}`}
                        style={{ width: '8px', height: '4px' }}></div>
                    }
                    style={{ marginTop: '0' }}
                    onSelect={onSelectFlow}>
                    <Select.Option value={''}>{t('contract.flow.noFlow')}</Select.Option>
                    {flows.length > 0 &&
                      flows.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                {flowSteps.length > 0 &&
                  flowSteps.map((item) => (
                    <div key={item.code}>
                      <span style={{ marginBottom: '0.5rem' }}>{`${item.signatoriesName} *`}</span>
                      <Form.Item
                        name={item.code}
                        colon={false}
                        rules={[{ required: true, message: t('common.validation.required') }]}
                        className={css['form-item']}
                        labelAlign="left">
                        <Select
                          className="common-select common-animation-primary"
                          mode="multiple"
                          onDropdownVisibleChange={(value) => {
                            if (item.code.split('_').includes('agent') && !value && searchValue) {
                              setAgents([]);
                              setPaginationAgents({ ...paginationAgents, current: 1, skip: 0 });
                              setSearchValue('');
                            }
                          }}
                          onSearch={(value) => {
                            if (item.code.split('_').includes('agent')) {
                              setAgents([]);
                              setPaginationAgents({ ...paginationAgents, current: 1, skip: 0 });
                              clearTimeout(Number(currentTimeout));
                              setCurrentTimeout(setTimeout(() => setSearchValue(value), 1000));
                            }
                          }}
                          filterOption={(value, option) =>
                            item.code.split('_').includes('agent')
                              ? true
                              : option?.children?.toLowerCase().indexOf(value.toLowerCase()) >= 0
                          }
                          dropdownClassName={`documents-control-select-dropdown ${
                            fetchingUserData ? 'consumers-control-select-dropdown--progress' : ''
                          }`}
                          onPopupScroll={(e: any) => {
                            e.persist();
                            let target = e.target;
                            if (
                              item.code.split('_').includes('agent') &&
                              !fetchingUserData &&
                              !isLastAgentsReq &&
                              target.scrollTop + target.offsetHeight === target.scrollHeight
                            ) {
                              getAgents();
                            }
                          }}
                          suffixIcon={
                            <div
                              className={`arrow-drop-img ${css['arrow-drop']}`}
                              style={{ width: '8px', height: '4px' }}></div>
                          }>
                          {item.code.split('_').includes('agent')
                            ? agents.length > 0 &&
                              agents.map((signatory) => (
                                <Select.Option key={signatory.id} value={signatory.id}>
                                  {`${signatory.firstName} ${signatory.lastName}`}
                                </Select.Option>
                              ))
                            : consumers.length > 0 &&
                              consumers
                                .sort((a, b) => a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()))
                                .map((signatory) => (
                                  <Select.Option key={signatory.id} value={signatory.id}>
                                    {`${signatory.firstName} ${signatory.lastName}`}
                                  </Select.Option>
                                ))}
                        </Select>
                      </Form.Item>
                    </div>
                  ))}
                <span style={{ marginBottom: '0.5rem' }}>{t('budget.form.date')}</span>
                <Form.Item
                  name="deadline"
                  className={css['drawer-form-item']}
                  initialValue={moment().add(10, 'days')}
                  rules={[{ required: true, message: t('common.validation.required') }]}>
                  <DatePicker
                    inputReadOnly
                    format="DD.MM.YYYY"
                    className="common-animation-primary"
                    getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
                    onChange={(value) => setDeadline(value)}
                  />
                </Form.Item>
                <span style={{ marginBottom: '1rem' }}>{t('roles.table.columns.description')}</span>
                <Form.Item name="description">
                  <TextArea className="consumer-form-input common-animation-primary" />
                </Form.Item>
                <span style={{ marginBottom: '0.5rem' }}>{t('budget.form.sign.comments')}</span>
                <Form.Item name="comment" className={css['drawer-form-item']}>
                  <TextArea
                    className="consumer-form-input common-animation-primary"
                    autoSize={{ minRows: 2, maxRows: 3 }}
                  />
                </Form.Item>
              </div>
              <div className={css['upload-steps-container']}>
                <span style={{ marginBottom: '1rem' }}>{t('budget.upload.overview.title')}</span>
                <span style={{ borderBottomStyle: 'dashed', color: '#778dac', marginBottom: '1rem' }}>
                  {t('budget.upload.overview.fields.title')}
                </span>
                {fields.map((field) => (
                  <span key={field.id} style={{ color: '#778dac', marginBottom: '0.5rem' }}>
                    {field.isDone ? <img src={confirmIcon} alt="" /> : <img src={alertIcon} alt="" />}
                    <span style={{ marginLeft: '1rem' }}>{field.title}</span>
                  </span>
                ))}
              </div>
            </div>
            <div className={css['upload-steps-item']}>
              <div className={css['upload-steps-container']}>
                <span style={{ marginBottom: '1rem' }}>{t('budget.upload.attachments.title')}</span>
                <div>
                  <Form.Item name="fileAttachment">
                    <Upload
                      className="consumers-import-upload-item upload-list-invoice"
                      style={{ width: '100%' }}
                      beforeUpload={() => false}
                      onChange={handleFileAttachment}
                      onRemove={() => true}
                      multiple={true}
                      fileList={fileAttachmentList}
                      accept=".pdf">
                      <div className={css['upload-item-budget']}>
                        <span style={{ color: '#076ee5' }}>{t('budget.upload.attachment.btn.add')}</span>
                      </div>
                    </Upload>
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

export default InvoiceUploadForm;
