import { DeleteOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Form, Input, Image, Row, Space, Upload, Select, FormInstance } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../contexts/AppContextProvider';
import { CompanyModel } from '../../../models/CompaniesModel';
import css from '../../../assets/styles/companies.module.css';
import editIcon from '../../../assets/images/ic-edit-blue.svg';
import checkIcon from '../../../assets/images/ic-check-mark.svg';
import { IProjectEasy } from '../../../models/ProjectModel';
import { UploadChangeParam } from 'antd/lib/upload';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { IAppContext } from '../../../typings/IApp';

interface ICompanyInfo {
  form: FormInstance;
  company: CompanyModel | undefined;
  projects: IProjectEasy[];
  deleteLogo: () => void;
  handleChangeLogo: (info: UploadChangeParam<UploadFile<any>>) => void;
  logoUrl: string | ArrayBuffer | null;
  isLoadingLogo: boolean;
  beforeUploadLogo: (file: RcFile) => boolean;
  isEmailEdit: boolean;
  setIsEmailEdit: (b: boolean) => void;
  isCreate: boolean;
}

const CompanyInfo = ({
  form,
  company,
  projects,
  deleteLogo,
  handleChangeLogo,
  logoUrl,
  isLoadingLogo,
  beforeUploadLogo,
  isEmailEdit,
  setIsEmailEdit,
  isCreate,
}: ICompanyInfo) => {
  const { t } = useTranslation();
  const {
    app: { user },
  } = useContext<IAppContext>(AppContext);

  const checkSuperBroker = () => {
    if (company && user.rolesParams.IS_SUPER_BROKER) {
      return !isEmailEdit;
    }
    return !!company;
  };

  const handleCheckEditEmail = () => {
    if (isEmailEdit) {
      return form.submit();
    }
    setIsEmailEdit(!isEmailEdit);
  };

  return (
    <Col span={12} style={{ height: '100%' }} className={css['companies-modify-col']}>
      <div className="company-modify-first-step" style={{ height: '100%' }}>
        <div
          className={css['companies-table-container']}
          style={{ padding: '36px', height: '100%', overflowY: 'auto' }}>
          <Row>
            <Form.Item>
              {logoUrl ? (
                <Avatar
                  src={
                    <>
                      <Image src={logoUrl as string} preview={false} />
                      <div className="logo-mask">
                        <Space>
                          <Upload
                            accept=".png, .jpg, .jpeg"
                            showUploadList={false}
                            beforeUpload={beforeUploadLogo}
                            onChange={handleChangeLogo}
                            customRequest={() => {}}>
                            <Button type="link" size="large">
                              <EditOutlined style={{ fontSize: '24px' }} />
                            </Button>
                          </Upload>
                          <Button type="link" size="large" onClick={deleteLogo}>
                            <DeleteOutlined style={{ fontSize: '24px' }} />
                          </Button>
                        </Space>
                      </div>
                    </>
                  }
                  className="consumer-avatar"
                  size={150}
                />
              ) : (
                <Upload.Dragger
                  accept=".png, .jpg, .jpeg"
                  listType="picture-card"
                  showUploadList={false}
                  beforeUpload={beforeUploadLogo}
                  onChange={handleChangeLogo}
                  customRequest={() => {}}
                  className="consumer-modify-upload">
                  <div className="consumer-modify-upload">
                    {isLoadingLogo ? <LoadingOutlined /> : <div className="consumer-modify-upload-icon"></div>}
                    <div style={{ marginTop: 8, marginLeft: 4, marginRight: 4, fontSize: 12 }}>
                      {t('consumers.btn.upload.profile')}
                      <div>({t('consumers.btn.upload.size')})</div>
                    </div>
                  </div>
                </Upload.Dragger>
              )}
            </Form.Item>
          </Row>
          <Row>
            <Form.Item
              style={{ width: '100%' }}
              label={<span style={{ color: '#778dac' }}>{t('projects.create.company.name')}</span>}
              name="companyName"
              initialValue={company?.companyName}
              rules={[{ required: true, message: t('common.validation.required') }]}>
              <Input className="consumer-form-input common-animation-primary" />
            </Form.Item>
          </Row>
          <Row>
            <div className={css['companies-table-group-title']}>{t('companies.group.company.address')}</div>
            <Row className={css['companies-table-group-row']}>
              <Form.Item
                style={{ width: '100%' }}
                name="street"
                initialValue={company?.street}
                className={css['form-item']}
                rules={[{ required: true, message: t('common.validation.required') }]}
                label={<span style={{ color: '#778dac' }}>{t('projects.create.account.street')}</span>}>
                <Input className="consumer-form-input common-animation-primary" />
              </Form.Item>
            </Row>
            <Row className={css['companies-table-group-row']}>
              <Form.Item
                style={{ width: '100%' }}
                name="zip"
                initialValue={company?.zip}
                className={css['form-item']}
                rules={[{ required: true, message: t('common.validation.required') }]}
                label={<span style={{ color: '#778dac' }}>{t('projects.create.zip')}</span>}>
                <Input className="consumer-form-input common-animation-primary" />
              </Form.Item>
              <Form.Item
                style={{ width: '100%' }}
                name="city"
                initialValue={company?.city}
                className={css['form-item']}
                rules={[{ required: true, message: t('common.validation.required') }]}
                label={<span style={{ color: '#778dac' }}>{t('projects.create.city')}</span>}>
                <Input className="consumer-form-input common-animation-primary" />
              </Form.Item>
            </Row>
            <Row className={css['companies-table-group-row']}>
              <Form.Item
                style={{ width: '100%' }}
                className={css['form-item']}
                name="state"
                initialValue={company?.state}
                rules={[{ required: true, message: t('common.validation.required') }]}
                label={<span style={{ color: '#778dac' }}>{t('projects.create.state')}</span>}>
                <Input className="consumer-form-input common-animation-primary" />
              </Form.Item>
              <Form.Item
                style={{ width: '100%' }}
                className={css['form-item']}
                name="country"
                initialValue={company?.country}
                rules={[{ required: true, message: t('common.validation.required') }]}
                label={<span style={{ color: '#778dac' }}>{t('projects.create.country')}</span>}>
                <Input className="consumer-form-input common-animation-primary" />
              </Form.Item>
            </Row>
            <Row className={css['companies-table-group-row']} style={{ position: 'relative' }}>
              <Form.Item
                style={{ width: '100%' }}
                initialValue={company?.phone}
                label={<span style={{ color: '#778dac' }}>{t('projects.create.phone.number')}</span>}
                rules={[{ required: true, message: t('common.validation.required') }]}
                name="phone">
                <Input className="consumer-form-input common-animation-primary" />
              </Form.Item>
              <Form.Item
                style={{ width: '100%' }}
                initialValue={company?.email}
                label={<span style={{ color: '#778dac' }}>{t('projects.create.email')}</span>}
                rules={[{ required: true, message: t('common.validation.required') }, { type: 'email' }]}
                name="email">
                <Input className="consumer-form-input common-animation-primary" disabled={checkSuperBroker()} />
              </Form.Item>
              {company && user.rolesParams.IS_SUPER_BROKER && (
                <Button
                  className={css['companies-edit-email-btn']}
                  icon={<img src={isEmailEdit ? checkIcon : editIcon} />}
                  onClick={() => handleCheckEditEmail()}></Button>
              )}
            </Row>
          </Row>
          {!isCreate && (
            <Row>
              <div className={css['companies-table-group-title']}>{t('companies.group.projects')}*</div>
              <Row className={css['companies-table-group-row']}>
                <Col span={24}>
                  <Form.Item
                    name="projects"
                    style={{ margin: 0 }}
                    rules={[{ required: true, message: t('common.validation.required') }]}>
                    <Select
                      showSearch
                      mode="multiple"
                      className="common-select common-animation-primary"
                      suffixIcon={
                        <div
                          className={`arrow-drop-img ${css['arrow-drop']}`}
                          style={{ width: '8px', height: '4px' }}></div>
                      }
                      filterOption={(input: string, { children }: any) => {
                        return children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                      }}
                      placeholder={t('users.create.card.user.projects.placeholder')}>
                      {projects &&
                        projects.length > 0 &&
                        projects
                          .sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))
                          .map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.title}
                            </Select.Option>
                          ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Row>
          )}
        </div>
      </div>
    </Col>
  );
};

export default CompanyInfo;
