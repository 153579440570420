import { Button, DatePicker, Input, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import css from '../../../assets/styles/tickets.module.css';
import ProjectsService from '../../../services/ProjectsService';
import TicketsService from '../../../services/TicketsService';
import { IProjectEasy } from '../../../models/ProjectModel';
import {
  PriorityTicketModel,
  PrioritiesTicketModel,
  StatusTicketModel,
  StatusesTicketModel,
  TypeTicketModel,
  TypesTicketModel,
} from '../../../models/TicketModel';
import { FilterTypes } from '../../../constants/filterTypes';
import { AppContext } from '../../../contexts/AppContextProvider';
import { FiltersContext } from '../../../contexts/FiltersContextProvider';
import { SET_TICKETS_FILTERS } from '../../../constants/actionTypes/filtersConstants';
import { IAppContext } from '../../../typings/IApp';

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

interface PropsControlModel {
  props: any;
  onTableChange: any;
  getTickets: any;
  option: any;
  types: TypesTicketModel | null;
}

const projectsService = new ProjectsService();
const ticketsService = new TicketsService();

const TicketsControl = (propsControl: PropsControlModel) => {
  const { onTableChange, props, getTickets, option, types } = propsControl;
  const { t } = useTranslation();
  const [projects, setProjects] = useState<IProjectEasy[]>([]);
  const [statuses, setStatuses] = useState<StatusesTicketModel | null>(null);
  const [priorities, setPriorities] = useState<PrioritiesTicketModel | null>(null);
  const [isShowExtFilter, setIsShowExtFilter] = useState<boolean>(false);
  const {
    app: { user },
  } = useContext<IAppContext>(AppContext);
  const {
    filters: { ticketsFilter },
    filtersDispatch,
  } = useContext(FiltersContext);
  const [filters, setFilters] = useState(ticketsFilter);
  const [currentTimeout, setCurrentTimeout] = useState<any>(null);
  const isConsumer = !!user?.rolesParams?.IS_CONSUMER;

  useEffect(() => {
    projectsService.getProjects().then((value: IProjectEasy[]) => setProjects(value));
    ticketsService.getStatuses().then((value: StatusesTicketModel) => setStatuses(value));
    ticketsService.getPriorities().then((value: PrioritiesTicketModel) => setPriorities(value));
  }, []);

  useEffect(() => {
    filtersDispatch({
      type: SET_TICKETS_FILTERS,
      filters,
    });
  }, [filters]);

  useEffect(() => {
    if (user?.projects?.length === 1) {
      setFilters({ ...filters, project: user.projects[0].id });
    }
  }, [user]);

  const onChangeProject = (value: any) => {
    setFilters({ ...filters, project: value });
    let projectFilter;
    if (value) {
      projectFilter = {
        'project/id': {
          type: FilterTypes.SELECT,
          value: +value,
        },
      };
    } else {
      projectFilter = undefined;
    }

    onTableChange(null, { projectFilter });
  };

  const onChangeType = (value: any) => {
    setFilters({ ...filters, type: value });
    let typeFilter;
    switch (value) {
      case 'incoming':
        typeFilter = {
          'recipient/id': {
            type: FilterTypes.SELECT,
            value: user.id,
          },
        };
        break;
      case 'outgoing':
        typeFilter = {
          'created_user/id': {
            type: FilterTypes.SELECT,
            value: user.id,
          },
        };
        break;
      default:
        typeFilter = undefined;
    }

    onTableChange(null, { typeFilter });
  };

  const onChangeStatus = (value: any) => {
    setFilters({ ...filters, status: value });
    let statusFilter;
    statusFilter = {
      'status/code': {
        type: FilterTypes.SELECT,
        value: value ? value : undefined,
      },
    };

    onTableChange(null, { statusFilter });
  };

  const onChangePriority = (value: any) => {
    setFilters({ ...filters, priority: value });
    let priorityFilter;
    priorityFilter = {
      'priority/code': {
        type: FilterTypes.SELECT,
        value: value ? value : undefined,
      },
    };

    onTableChange(null, { priorityFilter });
  };

  const onChangeDateRange = (value: any) => {
    setFilters({ ...filters, dateRange: value });
    let dateRangeFilter;
    dateRangeFilter = {
      dueDate: {
        type: FilterTypes.DATE_RANGE,
        value: value ? value : undefined,
      },
    };

    onTableChange(null, { dateRangeFilter });
  };

  const onSearch = (value: any) => {
    const searchFilter = {
      searchByCreated: {
        type: FilterTypes.MULTISEARCH,
        value: [
          { 'created_user/firstName': value.target.value },
          { 'created_user/lastName': value.target.value },
          { 'created_user/email': value.target.value },
          { subject: value.target.value },
        ],
      },
      searchByRecipient: {
        type: FilterTypes.MULTISEARCH,
        value: [
          { 'recipient/firstName': value.target.value },
          { 'recipient/lastName': value.target.value },
          { 'recipient/email': value.target.value },
          { subject: value.target.value },
        ],
      },
    };

    clearTimeout(currentTimeout);
    setCurrentTimeout(setTimeout(() => onTableChange(null, { searchFilter }), 1000));
  };

  return (
    <div className={css['tickets-control-container']}>
      <div
        className={`${css['tickets-control-filters-container']} consumer-control-wrapper`}
        style={{
          alignItems: 'start',
          marginBottom: 0,
          height: '100%',
          marginTop: 0,
          flexDirection: isShowExtFilter ? 'column' : 'row',
        }}>
        <div className={css['ticket-filter-row']}>
          <Search
            allowClear
            className={`consumers-control-search common-animation-primary ${css['ticketsSelect']}`}
            style={{ width: '280px', padding: '5px 0', marginRight: 20, marginBottom: 8 }}
            placeholder={
              isConsumer
                ? t('tickets.control.filter.placeholder.consumer')
                : t('tickets.control.filter.placeholder.broker')
            }
            suffix={<div className="search-img" style={{ width: '20px', height: '20px' }}></div>}
            onChange={onSearch}
          />
          <Select
            defaultValue={ticketsFilter.type}
            style={{ padding: '5px 0', marginRight: 20 }}
            className={`consumers-control-select  common-animation-primary ${css['ticketsSelect']}`}
            onChange={onChangeType}
            dropdownClassName="consumers-control-select-dropdown"
            placeholder={t('tickets.control.filter.placeholder.type')}
            suffixIcon={
              <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
            }>
            <Option value="" className="budget-control-option">
              <span style={{ fontSize: '14px', fontWeight: 600 }}>{t('tickets.control.filter.defvalue.type')}</span>
            </Option>
            {types &&
              types.map((type: TypeTicketModel) => (
                <Option value={type.code} key={type.code} className="budget-control-option">
                  <span style={{ fontSize: '14px' }}>{type.description}</span>
                </Option>
              ))}
          </Select>
          <Select
            defaultValue={ticketsFilter.status}
            style={{ padding: '5px 0', marginRight: 20 }}
            className={`consumers-control-select common-animation-primary ${css['ticketsSelect']}`}
            onChange={onChangeStatus}
            dropdownClassName="consumers-control-select-dropdown"
            placeholder={t('tickets.control.filter.placeholder.Status')}
            suffixIcon={
              <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
            }>
            <Option value="" className="budget-control-option">
              <span style={{ fontSize: '14px', fontWeight: 600 }}>{t('tickets.control.filter.defvalue.status')}</span>
            </Option>
            {statuses &&
              statuses.map((status: StatusTicketModel) => (
                <Option value={status.code} key={status.code} className="budget-control-option">
                  <span style={{ fontSize: '14px' }}>{status.description}</span>
                </Option>
              ))}
          </Select>

          {!isConsumer && (
            <Button
              type="link"
              className={css['btn-filter-extend']}
              onClick={() => setIsShowExtFilter((state) => !state)}>
              {t('tickets.control.filter.extend')}
              <div
                className={`${css['arrow-drop-img']} ${isShowExtFilter ? '' : css['arrow-drop']}`}
                style={{ marginLeft: 5, width: '8px', height: '4px' }}></div>
            </Button>
          )}
        </div>
        {!isConsumer && isShowExtFilter && (
          <div className={css['ticket-filter-row']} style={{ marginTop: 10 }}>
            <Select
              defaultValue={ticketsFilter.project}
              style={{ padding: '5px 0', marginRight: 20 }}
              className={`consumers-control-select common-animation-primary ${css['ticketsSelect']}`}
              onChange={onChangeProject}
              dropdownClassName="consumers-control-select-dropdown"
              placeholder={t('tickets.control.filter.placeholder.project')}
              suffixIcon={
                <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
              }>
              <Option value="" className="budget-control-option">
                <span style={{ fontSize: '14px', fontWeight: 600 }}>
                  {t('tickets.control.filter.defvalue.project')}
                </span>
              </Option>
              {projects &&
                projects
                  .sort((a, b) => a.title.localeCompare(b.title))
                  .map((project) => (
                    <Option value={project.id} key={project.id} className="budget-control-option">
                      <span style={{ fontSize: '14px' }}>{project.title}</span>
                    </Option>
                  ))}
            </Select>
            <Select
              defaultValue={ticketsFilter.priority}
              style={{ padding: '5px 0', marginRight: 18 }}
              className={`consumers-control-select common-animation-primary ${css['ticketsSelect']}`}
              onChange={onChangePriority}
              dropdownClassName="consumers-control-select-dropdown"
              placeholder={t('tickets.control.filter.placeholder.priority')}
              suffixIcon={
                <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
              }>
              <Option value="" className="budget-control-option">
                <span style={{ fontSize: '14px', fontWeight: 600 }}>
                  {t('tickets.control.filter.defvalue.priority')}
                </span>
              </Option>
              {priorities &&
                priorities.map((priority: PriorityTicketModel) => (
                  <Option value={priority.code} key={priority.code} className="budget-control-option">
                    <span style={{ fontSize: '14px' }}>{priority.description}</span>
                  </Option>
                ))}
            </Select>
            <RangePicker
              inputReadOnly
              defaultValue={ticketsFilter.dateRange}
              style={{ height: 40, marginLeft: 0, maxWidth: '280px' }}
              suffixIcon={<div className={'calendar-img'} style={{ width: '18px', height: '18px' }}></div>}
              className={`gallery-control-range-picker common-animation-primary ${css['ticketsSelect']}`}
              onChange={onChangeDateRange}
            />
          </div>
        )}
      </div>
      <div className={css['tickets-control-btns-container']}>
        <Link
          to={`${props.history.location.pathname}/new`}
          className={'common-animation-primary'}
          style={{ borderRadius: '4px' }}>
          <Button className={`${css['tickets-add-new']} common-primary-btn`}>{t('common.btn.new')}</Button>
        </Link>
      </div>
    </div>
  );
};

export default TicketsControl;
