import { Button, notification, Spin } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../assets/styles/budget.module.css';
import { PlanModel } from '../../../models/PropertyModel';
import ListPageServices from '../../../services/PropertiesService';

interface DeleteConfirmFormProps {
  isOpen: boolean;
  plan: PlanModel | null;
  setIsNeedUpdate?: (b: boolean) => void;
  setClose: () => void;
}

const service = new ListPageServices();

const ConfirmDeletePlan = (props: DeleteConfirmFormProps) => {
  const { plan, isOpen, setClose, setIsNeedUpdate } = props;
  const [fetchingData, setFetchingData] = useState(false);
  const { t } = useTranslation();

  const deletePlan = () => {
    setFetchingData(true);
    {
      plan &&
        service
          .deleteImages([plan.id])
          .then(() => {
            setClose();
            notification.success({
              message: t('properties.modal.delete.success.message'),
            });
            setIsNeedUpdate && setIsNeedUpdate(true);
          })
          .catch((e) =>
            notification.error({
              message: e,
            }),
          )
          .finally(() => setFetchingData(false));
    }
  };

  return (
    <Spin spinning={fetchingData}>
      <Modal
        className="common-modal"
        centered
        style={{ width: '10rem' }}
        visible={isOpen}
        footer={
          <div className="common-modal-footer">
            <Button className={`common-gray-border-btn common-secondary-btn`} onClick={() => setClose()}>
              {t('budget.modal.cancel.btn')}
            </Button>
            <Button className={`${css['invoice-modal-save-btn']} common-modal-btn`} onClick={() => deletePlan()}>
              {t('budget.modal.delete.btn')}
            </Button>
          </div>
        }
        closable={false}>
        <>
          {plan && (
            <>
              <span className={css['delete-invoice-title']}>{`${t('properties.modal.confirm.delete.message')}?`}</span>
              <br />
              <span>{plan.name}</span>
            </>
          )}
        </>
      </Modal>
    </Spin>
  );
};

export default ConfirmDeletePlan;
