import React from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { getColumns } from './NestedRequestsColumns';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

interface NestedRequestTableModel {
  record: any;
}

const NestedRequestTable: React.FC<NestedRequestTableModel> = ({ record }: NestedRequestTableModel) => {
  const { t } = useTranslation();
  const { height: windowHeight } = useWindowDimensions();

  const { options } = record || {};

  return (
    <Table
      columns={getColumns({ t })}
      size="small"
      dataSource={options}
      rowKey="id"
      rowClassName="common-table-row--pointer"
      scroll={{ x: '100%', y: windowHeight - 475 }}
    />
  );
};

export default NestedRequestTable;
