import React from 'react';
import css from '../../assets/styles/budget.module.css';
import BudgetListInfo from './components/BudgetListInfo';
import DiagramBudget from './components/DiagramComponent';

interface BrokerBudgetProps {
  propertyId: number;
  projectId: number;
  setIsOpenUpload: (b: boolean) => void;
  isCreatedInvoice: boolean;
  setIsCreatedInvoice: (b: boolean) => void;
  isOpenStatusForm: boolean;
  setIsOpenStatusForm: (b: boolean) => void;
  setCurrentInvoice: (v: number | null) => void;
  currentInvoice: number | null;
  isNeedUpdate: boolean;
  setIsNeedUpdate: (b: boolean) => void;
  currency: string;
}

const BrokerBudget = (props: BrokerBudgetProps) => {
  const {
    propertyId,
    projectId,
    setIsOpenUpload,
    setIsCreatedInvoice,
    isCreatedInvoice,
    isOpenStatusForm,
    setIsOpenStatusForm,
    setCurrentInvoice,
    currentInvoice,
    isNeedUpdate,
    setIsNeedUpdate,
    currency,
  } = props;

  return (
    <div className={css['container']}>
      <DiagramBudget
        propertyId={propertyId}
        setIsNeedUpdate={setIsNeedUpdate}
        isNeedUpdate={isNeedUpdate}
        projectId={projectId}
        currency={currency}
      />
      <BudgetListInfo
        propertyId={propertyId}
        setIsNeedUpdate={setIsNeedUpdate}
        setIsOpenUpload={setIsOpenUpload}
        setIsCreatedInvoice={setIsCreatedInvoice}
        isCreatedInvoice={isCreatedInvoice}
        isOpenStatusForm={isOpenStatusForm}
        setIsOpenStatusForm={setIsOpenStatusForm}
        currentInvoice={currentInvoice}
        setCurrentInvoice={setCurrentInvoice}
      />
    </div>
  );
};

export default BrokerBudget;
