import { Button, Modal, notification, Spin } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../../assets/styles/faq.module.css';
import FAQService from '../../../../services/FAQService';

interface DeleteFormProps {
  id: number | null;
  setClose: () => void;
  isOpen: boolean;
}

const service = new FAQService();

const DeleteForm = (props: DeleteFormProps) => {
  const { id, setClose, isOpen } = props;
  const { t } = useTranslation();
  const [fetchingData, setFetchingData] = useState(false);

  const deleteQuestion = () => {
    setFetchingData(true);
    service
      .deleteFAQ(id)
      .then(() =>
        notification.success({
          message: t('manage.modal.success.delete.question'),
        }),
      )
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => {
        setClose();
        setFetchingData(false);
      });
  };

  return (
    <Spin spinning={fetchingData}>
      <Modal
        className="common-modal"
        centered
        visible={isOpen}
        style={{ width: '10rem' }}
        footer={
          <div className="common-modal-footer">
            <Button
              className={`common-gray-border-btn common-secondary-btn`}
              onClick={() => setClose()}
              disabled={fetchingData}>
              {t('budget.modal.cancel.btn')}
            </Button>
            <Button
              className={`common-green-btn common-modal-btn`}
              onClick={() => deleteQuestion()}
              disabled={fetchingData}>
              {t('budget.modal.delete.btn')}
            </Button>
          </div>
        }
        closable={false}>
        <span className={css['delete-question-title']}>
          {t('manage.modal.confirm.delete.question')}
          {`${id}?`}
        </span>
      </Modal>
    </Spin>
  );
};

export default DeleteForm;
