import { ICreatedUserModel, IPropertyModel } from './CommonDocumnetsModel';
import { PropertyModel } from './PropertyModel';

interface CommonObject {
  id: number;
}

export enum CalendarTypes {
  update = 'update',
  document = 'document',
  image = 'image',
  post = 'post',
}

export interface NewUpdate {
  description?: string | null;
  name?: string | null;
  isPublished?: boolean;
  date: string;
  properties: CommonObject[];
  type?: {
    code: CalendarTypes;
  };
}

export interface EditUpdate extends NewUpdate {
  id: number;
}

export interface UpdateModel {
  createdDate: string;
  date: string;
  deletedDate: string | null;
  description: string | null;
  files: Array<{
    fileName: string;
    id: number;
  }>;
  id: number;
  invoice: any;
  isPublished: boolean;
  isSystem: boolean;
  properties: PropertyModel[];
  updatedDate: string | null;
  type: any;
}

export type UpdatesModel = Array<UpdateModel>;

export interface ICalendarDocument {
  id: number;
  name: string;
  description: string;
  isPublished: boolean;
  date: string;
  createdDate: string;
  updatedDate: null | string;
  deletedDate: null | string;
  isSystem: boolean;
  properties: IPropertyModel[];
  type: {
    code: string;
    description: string;
  };
  files: {
    id: number;
    fileName: string;
    file: string;
    size: number;
  }[];
  invoice: null;
  createdUser: ICreatedUserModel;
}

//Posts
export interface IFile {
  id: number;
  fileName: string;
  file: string;
  size: number;
}

export interface IPost {
  id: number;
  name: null;
  description: string | null;
  isPublished: boolean;
  date: string;
  createdDate: string;
  updatedDate: string | null;
  deletedDate: string | null;
  isSystem: boolean;
  properties: IPropertyModel[];
  type: { code: CalendarTypes; descriptions: string };
  files: IFile[];
  invoice: null;
  createdUser: ICreatedUserModel;
}
