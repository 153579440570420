import React from 'react';
import { Badge, Button } from 'antd';
import { NavLink } from 'react-router-dom';
import LogoComponent from '../../../components/common/LogoComponent';
import css from '../../../assets/styles/consumers.module.css';
import editIcon from '../../../assets/images/ic-edit-dark.svg';
import { IUserTable } from '../../../models/UserModel';
import { TFunction } from 'react-i18next';

interface UserColumnsModel {
  isOpenSwitchUserBlock: (user: IUserTable) => void;
  t: TFunction<'translation'>;
}

export const getColumns = ({ isOpenSwitchUserBlock, t }: UserColumnsModel) => [
  {
    title: () => (
      <span className="budget-table-tr" style={{ marginLeft: '8px' }}>
        #
      </span>
    ),
    key: 'logo',
    width: 50,
    render: (cell: IUserTable) => (
      <LogoComponent id={cell.id} name={`${cell.firstName} ${cell.lastName}`} image={cell.image || ''} />
    ),
  },
  {
    title: () => <span className="budget-table-tr">{t('users.table.columns.login')}</span>,
    dataIndex: 'login',
    key: 'login',
    width: 200,
  },
  {
    title: () => <span className="budget-table-tr">{t('users.table.columns.name')}</span>,
    key: 'name',
    width: 200,
    render: (cell: IUserTable) => `${cell.firstName} ${cell.lastName}`,
  },
  {
    title: () => <span className="budget-table-tr">{t('consumers.table.columns.project')}</span>,
    key: 'project',
    width: 280,
    render: (cell: IUserTable) => {
      const project = cell.projects ?? [];
      const deletedIds = cell.usersProjects.filter((p) => p.deletedDate !== null).map((p) => p.projectId);
      return project.map((p, index) => (
        <span key={index} style={{ color: deletedIds.includes(p.id) ? '#778dac' : '#00122d' }}>{`${p.title}${
          index === project.length - 1 ? '' : ', '
        }`}</span>
      ));
    },
  },
  // TODO: Review after backend refactoring
  // {
  //   title: () => <span className="budget-table-tr">{t('roles.bread.root')}</span>,
  //   key: 'project',
  //   width: 120,
  //   render: (cell: UserModel) => {
  //     return (
  //       <div className="common-flex-column-style">
  //         {cell.roles && cell.roles.length > 0 && cell.roles.map((roll) => <span>{roll}</span>)}
  //       </div>
  //     );
  //   },
  // },
  {
    title: () => <span className="budget-table-tr">{t('users.table.columns.lockedDate')}</span>,
    key: 'lockedDate',
    render: (cell: IUserTable) => {
      return cell.lockedDate ? <Badge status="error" text="fermé à clé" /> : '';
    },
  },
  {
    title: () => <span className="budget-table-tr">{t('users.table.columns.isPasswordExpired')}</span>,
    key: 'isPasswordExpired',
    render: (cell: IUserTable) => (cell.isPasswordExpired ? <Badge status="error" text="passe expiré" /> : ''),
  },
  {
    title: () => <span className="budget-table-tr">{t('users.table.columns.isActive')}</span>,
    dataIndex: 'isActive',
    key: 'isActive',
    render: (cell: IUserTable) =>
      cell ? <Badge status="success" text="Online" /> : <Badge status="error" text="Offline" />,
  },

  {
    title: () => <span className="budget-table-tr">{t('users.table.columns.action')}</span>,
    key: 'action',
    fixed: 'right' as any,
    width: '225px',
    render: (cell: IUserTable) => {
      return (
        <div style={{ display: 'flex', backgroundColor: 'inherit', gap: '1rem' }}>
          <NavLink to={`/users/modify/${cell.id}`} style={{ borderRadius: 4 }} className="common-animation-secondary">
            <Button
              className="common-secondary-btn common-color-blue"
              style={{
                borderColor: '#076ee5',
                backgroundColor: '#eef0f4',
                borderRadius: '6px',
              }}>
              <img src={editIcon} alt="" style={{ width: '12px', height: '12px', marginRight: '0.5rem' }} />
              {t('common.btn.modify.lower')}
            </Button>
          </NavLink>
          <Button
            className={`${css['edit-consumer-btn']} common-color-dark`}
            onClick={() => isOpenSwitchUserBlock(cell)}
            style={{ borderColor: '#076ee5', backgroundColor: '#eef0f4', borderRadius: '6px' }}>
            <span style={{ color: '#076ee5' }}>
              {cell.usersProjects.filter((item) => item.deletedDate === null)?.length === 0
                ? t('user.btn.activate')
                : t('user.btn.deactivate')}
            </span>
          </Button>
        </div>
      );
    },
  },
];
