import React from 'react';
import { TFunction } from 'react-i18next';
import { ICFCItemModel } from '../../../models/CommonDocumnetsModel';

interface ColumnsProps {
  t: TFunction<'translation'>;
}

export const getColumns = ({ t }: ColumnsProps) => [
  {
    title: t('projects.table.columns.title'),
    key: 'taskName',
    dataIndex: 'taskName',
  },
  {
    title: t('product.table.columns.price'),
    key: 'price',
    render: (cell: ICFCItemModel) => {
      const total = +cell.quantity * +cell.price;
      return <span>{(total / 100).toFixed(2)}</span>;
    },
  },
];
