import { Button, notification, Spin } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../assets/styles/budget.module.css';
import AmendmentService from '../../../services/AmendmentService';
import ContractsService from '../../../services/ContractsService';

interface DeleteQuoteFormProps {
  isOpen: boolean;
  currentId: number;
  getItems: () => void;
  setClose: () => void;
  itemNumber: string | null;
  isAmendment: boolean;
}

const amendmentService = new AmendmentService();
const contractsService = new ContractsService();

const DeleteAmendmentForm = (props: DeleteQuoteFormProps) => {
  const { currentId, isOpen, setClose, getItems, itemNumber, isAmendment } = props;
  const [fetchingData, setFetchingData] = useState(false);
  const { t } = useTranslation();

  const deleteItem = () => {
    setFetchingData(true);
    if (isAmendment) {
      amendmentService
        .deleteAmedment(currentId)
        .then(() => {
          setClose();
          notification.success({
            message: t('amendment.modal.delete.success.message'),
          });
          getItems();
        })
        .catch((e) =>
          notification.error({
            message: e,
          }),
        )
        .finally(() => setFetchingData(false));
    } else {
      contractsService
        .deleteContract(currentId)
        .then(() => {
          setClose();
          notification.success({
            message: t('contract.modal.delete.success.message'),
          });
          getItems();
        })
        .catch((e) =>
          notification.error({
            message: e,
          }),
        )
        .finally(() => setFetchingData(false));
    }
  };

  return (
    <Spin spinning={fetchingData}>
      <Modal
        className="common-modal"
        centered
        style={{ width: '10rem' }}
        visible={isOpen}
        footer={
          <div className="common-modal-footer">
            <Button className={`common-gray-border-btn common-secondary-btn`} onClick={() => setClose()}>
              {t('budget.modal.cancel.btn')}
            </Button>
            <Button className={`common-green-btn common-modal-btn`} onClick={() => deleteItem()}>
              {t('budget.modal.delete.btn')}
            </Button>
          </div>
        }
        closable={false}>
        <span className={css['delete-invoice-title']}>{`${
          isAmendment ? t('amendment.modal.confirm.delete.message') : t('contract.modal.confirm.delete.message')
        } ${itemNumber}?`}</span>
      </Modal>
    </Spin>
  );
};

export default DeleteAmendmentForm;
