import React, { useState, useEffect, useContext } from 'react';
import { Form, Row, Col, Input, InputNumber, Checkbox, Upload, Button, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import VariantsService from '../../../services/VariantsService';
import { TypologiesContext } from '../../../contexts/TypologiesContextProvider';
import { nodeKeys } from '../enums/nodeKeys';
import NodesModifyLayout from './NodesModifyLayout';
import {
  TYPOLOGY_REFRESH_ITEMS,
  TYPOLOGY_SET_SELECTED_NODES,
} from '../../../constants/actionTypes/typologiesConstants';
import { validateObjectField } from '../../../helpers/validateObjectField';
import { PlusOutlined } from '@ant-design/icons';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { fileURLToAntdModel } from '../../../helpers/imageToAntdModel';
import { useLessThen801 } from '../../../helpers/mediaDetect';

const service: VariantsService = new VariantsService();

const VariantsModify: React.FC<any> = (props: any) => {
  const {
    nodes: { currentNode, currentNodeKey, parent },
    nodesDispatch,
  } = useContext(TypologiesContext);
  const { t } = useTranslation();
  const isCreate = currentNodeKey === nodeKeys.variant && !currentNode;
  const [form] = Form.useForm();
  const [variant, setVariant] = useState<any>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [levelId, setLevelId] = useState<number | null>(null);

  const [floorPlanImg, setFloorPlanImg] = useState<any>();
  const [defaultFloorPlanImg, setDefaultFloorPlanImg] = useState<any>([]);
  const [isUploadFloorPlanImg, setIsUploadFloorPlanImg] = useState<boolean>(false);
  const [isDeleteFloorPlanImg, setIsDeleteFloorPlanImg] = useState<boolean>(false);

  const [shortFloorPlanImg, setShortFloorPlanImg] = useState<any>();
  const [defaultShortFloorPlanImg, setDefaultShortFloorPlanImg] = useState<any>([]);
  const [isUploadShortFloorPlanImg, setIsUploadShortFloorPlanImg] = useState<boolean>(false);
  const [isDeleteShortFloorPlanImg, setIsDeleteShortFloorPlanImg] = useState<boolean>(false);

  const setIsRefresh = (isRefresh: boolean) => {
    nodesDispatch({
      type: TYPOLOGY_REFRESH_ITEMS,
      isRefresh,
    });
  };

  const setSelectedNodes = (selectedNodes: any[]) => {
    nodesDispatch({
      type: TYPOLOGY_SET_SELECTED_NODES,
      selectedNodes,
    });
  };

  const getVariant = (id: number) => {
    return service
      .getVariantById(id)
      .then((res: any) => {
        setVariant(res);
        form.setFieldsValue({
          title: res.title,
          name: res.name,
          description: res.description,
          rooms: res.rooms,
          allSurface: res.allSurface,
          isActive: res.isActive,
          isDefault: res.isDefault,
          babylonCode: res.babylonCode,
          babylonParams: res.babylonParams,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAsyncData = async () => {
    const actions = [];
    if (!isCreate) {
      actions.unshift(getVariant(currentNode.id));
    }
    Promise.all(actions).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (currentNodeKey === nodeKeys.variant && (!currentNode || variant?.id !== currentNode?.id)) {
      setLoading(true);
      getAsyncData();
    }
    if (currentNodeKey === nodeKeys.variant && isCreate) {
      setLevelId(parent.id);
    }
  }, [currentNodeKey, currentNode]);

  useEffect(() => {
    if (variant) {
      if (variant.imgFloorPlan) {
        const file = fileURLToAntdModel({
          name: variant.imgFloorPlan,
          id: Date.now(),
        });
        setDefaultFloorPlanImg([file]);
        setIsUploadFloorPlanImg(true);
      }
      if (variant.imgShortFloorPlan) {
        const file = fileURLToAntdModel({
          name: variant.imgShortFloorPlan,
          id: Date.now(),
        });
        setDefaultShortFloorPlanImg([file]);
        setIsUploadShortFloorPlanImg(true);
      }
    }
  }, [variant]);

  const onSubmit = async () => {
    const values = await form.validateFields();
    const hasProperty = Object.prototype.hasOwnProperty;
    if (!hasProperty.call(values, 'errorFields')) {
      setIsSaving(true);
      const modelActions = (id: number) => {
        const promises: Promise<any>[] = [];
        if (floorPlanImg) {
          promises.push(service.uploadImageFloorPlan(id, floorPlanImg));
        }
        if (isDeleteFloorPlanImg && !floorPlanImg) {
          promises.push(service.deleteImageFloorPlan(id));
        }
        if (shortFloorPlanImg) {
          promises.push(service.uploadImageShortFloorPlan(id, shortFloorPlanImg));
        }
        if (isDeleteShortFloorPlanImg && !shortFloorPlanImg) {
          promises.push(service.deleteImageShortFloorPlan(id));
        }
        return Promise.all(promises);
      };

      if (isCreate) {
        service
          .createVariant({
            ...values,
            level: { id: levelId },
          })
          .then((res: any) => {
            modelActions(res.id)
              .then(() => {
                setSelectedNodes([`${nodeKeys.variant}-${res.id}`]);
                getVariant(res.id);
                notification.success({
                  message: t('variant.create.notification.create'),
                });
                setIsRefresh(true);
              })
              .catch((e) => {
                notification.error({
                  message: e.message || t('common.error.internalServerError'),
                });
              })
              .finally(() => setIsSaving(false));
          });
      } else {
        service
          .updateVariant({
            ...values,
            id: currentNode.id,
          })
          .then(() => {
            modelActions(currentNode.id)
              .then(() => {
                getVariant(currentNode.id);
                notification.success({
                  message: t('variant.create.notification.update'),
                });
                setIsRefresh(true);
              })
              .catch((e) => {
                notification.error({
                  message: e.message || t('common.error.internalServerError'),
                });
              })
              .finally(() => setIsSaving(false));
          });
      }
    }
  };

  const onChangeFloorPlanImg = (info: UploadChangeParam<UploadFile<any>>) => {
    const { file }: any = info;
    setFloorPlanImg(file?.status !== 'removed' ? file : null);
    setIsUploadFloorPlanImg(file?.status !== 'removed');
    if (file?.status === 'removed') {
      setIsDeleteFloorPlanImg(true);
    }
  };

  const onChangeShortFloorPlanImg = (info: UploadChangeParam<UploadFile<any>>) => {
    const { file }: any = info;
    setShortFloorPlanImg(file?.status !== 'removed' ? file : null);
    setIsUploadShortFloorPlanImg(file?.status !== 'removed');
    if (file?.status === 'removed') {
      setIsDeleteShortFloorPlanImg(true);
    }
  };

  if (useLessThen801()) {
    return (
      <NodesModifyLayout submit={onSubmit} loading={loading} isSaving={isSaving}>
        <Form autoComplete="off" form={form} layout="vertical" name="modify_variant">
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Form.Item
                label={t('variant.create.title')}
                name="title"
                rules={[{ required: true, message: t('variant.create.title.validation') }]}>
                <Input className="common-animation-primary" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t('variant.create.name')}
                name="name"
                rules={[{ required: true, message: t('variant.create.name.validation') }]}>
                <Input className="common-animation-primary" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t('variant.create.description')}
                name="description"
                rules={[{ required: true, message: t('variant.create.description.validation') }]}>
                <Input className="common-animation-primary" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={t('variant.create.rooms')} name="rooms">
                <Input className="common-animation-primary" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={t('variant.create.allSurface')} name="allSurface">
                <InputNumber className="common-animation-primary" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={t('variant.create.babylonCode')} name="babylonCode">
                <Input className="common-animation-primary" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t('variant.create.babylonParams')}
                name="babylonParams"
                rules={[validateObjectField('babylonParams')]}>
                <Input.TextArea rows={4} className="common-animation-primary" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={t('variant.create.isActive.label')} name="isActive" valuePropName="checked">
                <Checkbox>{t('variant.create.isActive.value')}</Checkbox>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={t('variant.create.isDefault.label')} name="isDefault" valuePropName="checked">
                <Checkbox>{t('variant.create.isDefault.value')}</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label={t('variant.create.floorPlanImg')} name="model">
                <div className="dropbox">
                  <Upload
                    listType="picture"
                    accept=".png, .jpg, .jpeg"
                    beforeUpload={() => false}
                    onChange={onChangeFloorPlanImg}
                    defaultFileList={defaultFloorPlanImg}
                    customRequest={() => {}}
                    className="variant-floor-plan">
                    {!isUploadFloorPlanImg && (
                      <Button className="btn-upload" icon={<PlusOutlined />}>
                        {t('common.btn.upload')}
                      </Button>
                    )}
                  </Upload>
                </div>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={t('variant.create.shortFloorPlanImg')} name="model">
                <div className="dropbox">
                  <Upload
                    listType="picture"
                    accept=".png, .jpg, .jpeg"
                    beforeUpload={() => false}
                    onChange={onChangeShortFloorPlanImg}
                    defaultFileList={defaultShortFloorPlanImg}
                    customRequest={() => {}}
                    className="variant-floor-plan">
                    {!isUploadShortFloorPlanImg && (
                      <Button className="btn-upload" icon={<PlusOutlined />}>
                        {t('common.btn.upload')}
                      </Button>
                    )}
                  </Upload>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </NodesModifyLayout>
    );
  }

  return (
    <NodesModifyLayout submit={onSubmit} loading={loading} isSaving={isSaving}>
      <Form autoComplete="off" form={form} layout="vertical" name="modify_variant">
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Form.Item
              label={t('variant.create.title')}
              name="title"
              rules={[{ required: true, message: t('variant.create.title.validation') }]}>
              <Input className="common-animation-primary" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('variant.create.name')}
              name="name"
              rules={[{ required: true, message: t('variant.create.name.validation') }]}>
              <Input className="common-animation-primary" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('variant.create.description')}
              name="description"
              rules={[{ required: true, message: t('variant.create.description.validation') }]}>
              <Input className="common-animation-primary" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('variant.create.rooms')} name="rooms">
              <Input className="common-animation-primary" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('variant.create.allSurface')} name="allSurface">
              <InputNumber className="common-animation-primary" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('variant.create.babylonCode')} name="babylonCode">
              <Input className="common-animation-primary" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('variant.create.babylonParams')}
              name="babylonParams"
              rules={[validateObjectField('babylonParams')]}>
              <Input.TextArea rows={4} className="common-animation-primary" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('variant.create.isActive.label')} name="isActive" valuePropName="checked">
              <Checkbox>{t('variant.create.isActive.value')}</Checkbox>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('variant.create.isDefault.label')} name="isDefault" valuePropName="checked">
              <Checkbox>{t('variant.create.isDefault.value')}</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label={t('variant.create.floorPlanImg')} name="model">
              <div className="dropbox">
                <Upload
                  listType="picture"
                  accept=".png, .jpg, .jpeg"
                  beforeUpload={() => false}
                  onChange={onChangeFloorPlanImg}
                  defaultFileList={defaultFloorPlanImg}
                  customRequest={() => {}}
                  className="variant-floor-plan">
                  {!isUploadFloorPlanImg && (
                    <Button className="btn-upload" icon={<PlusOutlined />}>
                      {t('common.btn.upload')}
                    </Button>
                  )}
                </Upload>
              </div>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('variant.create.shortFloorPlanImg')} name="model">
              <div className="dropbox">
                <Upload
                  listType="picture"
                  accept=".png, .jpg, .jpeg"
                  beforeUpload={() => false}
                  onChange={onChangeShortFloorPlanImg}
                  defaultFileList={defaultShortFloorPlanImg}
                  customRequest={() => {}}
                  className="variant-floor-plan">
                  {!isUploadShortFloorPlanImg && (
                    <Button className="btn-upload" icon={<PlusOutlined />}>
                      {t('common.btn.upload')}
                    </Button>
                  )}
                </Upload>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </NodesModifyLayout>
  );
};

export default VariantsModify;
