import { Button, DatePicker, notification, Select, TablePaginationConfig } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { FilterTypes } from '../../../constants/filterTypes';
import { useTranslation } from 'react-i18next';
import css from '../../../assets/styles/tasks.module.css';
import { AppContext } from '../../../contexts/AppContextProvider';
import { StatusTask } from '../../../models/TasksModel';
import { FiltersContext } from '../../../contexts/FiltersContextProvider';
import { SET_TASKS_FILTERS } from '../../../constants/actionTypes/filtersConstants';
import { useLessThen801 } from '../../../helpers/mediaDetect';
import UsersService from '../../../services/UsersService';
import { FilterValue } from 'antd/lib/table/interface';
import { RangePickerProps } from 'antd/lib/date-picker';
import { IUserEasy } from '../../../models/UserModel';
import { IResponceData } from '../../../typings/IServiceOptions';
import { IAppContext } from '../../../typings/IApp';

interface ITasksControl {
  onControlChange: (pagination?: TablePaginationConfig, filters?: Record<string, FilterValue | null | any>) => void;
  setIsCreate: (b: boolean) => void;
  isCreate: boolean;
  consumerId?: number;
  setTasks: (task: []) => void;
}

const { Option } = Select;
const { RangePicker } = DatePicker;
const userService = new UsersService();

const TasksControl = ({ onControlChange, setIsCreate, isCreate, consumerId, setTasks }: ITasksControl) => {
  const {
    app: { user },
  } = useContext<IAppContext>(AppContext);
  const {
    filters: { tasksFilter },
    filtersDispatch,
  } = useContext(FiltersContext);
  const { t } = useTranslation();
  const [users, setUsers] = useState<IUserEasy[]>([]);
  const [userIdSelect, setUserIdSelect] = useState<undefined | number>(tasksFilter.assignee);
  const [dataRange, setDataRange] = useState<RangePickerProps['value']>(tasksFilter.dateRange);
  const [filters, setFilters] = useState(tasksFilter);
  const [isLastUserReq, setIsLastUserReq] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    filtersDispatch({
      type: SET_TASKS_FILTERS,
      filters,
    });
  }, [filters]);

  useEffect(() => {
    if (tasksFilter.assigneeRole.length > 2) {
      getUsers(tasksFilter.assigneeRole, true);
    }
  }, []);

  useEffect(() => {
    setFilters({ ...filters, assignee: userIdSelect });
  }, [userIdSelect]);

  const onChangeStatus = (value: string) => {
    setFilters({ ...filters, status: value });
    const body = {
      'status/code': {
        type: FilterTypes.SELECT,
        value: value,
      },
    };
    onControlChange(undefined, body);
  };

  const getUsers = (role: string, isFirstLoad = false, isLoad = false) => {
    const optionUser = {
      count: true,
      top: pagination.pageSize,
      current: pagination.current,
      skip: pagination.pageSize * (pagination.current - 1) || 0,
      roles: [role],
    };

    setFetchingData(true);
    userService
      .getEasyUsers(optionUser)
      .then((res: IResponceData<IUserEasy>) => {
        const { count, items } = res;
        const { current, top } = optionUser;
        setPagination({ ...pagination, current: current + 1, total: count, pageSize: top });
        setIsLastUserReq(current * top >= count);

        if (items.length > 0) {
          setUsers([...users, ...items]);
          if (isFirstLoad && tasksFilter.assignee) {
            return getUserTasks(tasksFilter.assignee);
          }
          if (!isLoad) {
            return getUserTasks(items[0].id);
          }
          return;
        }

        setUsers([]);
        setUserIdSelect(undefined);
        setTasks([]);
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingData(false));
  };

  const getUserTasks = (id: string | number) => {
    setUserIdSelect(+id);
    const body = {
      'assignee/id': {
        type: FilterTypes.SELECT,
        value: id.toString(),
      },
    };
    onControlChange(undefined, body);
  };

  const onSelectRole = (value: string) => {
    setFilters({ ...filters, assigneeRole: value });
    if (!value.length) {
      getUserTasks('');
      return setUsers([]);
    }
    if (value === 'me') {
      getUserTasks(user.id);
      return setUsers([]);
    }

    getUsers(value);
  };

  useEffect(() => {
    if (dataRange) {
      setDataRange(tasksFilter.dataRange);
    }
  }, [tasksFilter.dataRange]);

  const onChangeData = (value: RangePickerProps['value']) => {
    setFilters({ ...filters, dateRange: value });
    const body = {
      deadline: {
        type: FilterTypes.DATE_RANGE,
        value: value,
      },
    };
    onControlChange(undefined, body);
  };

  return (
    <div className={css['tasks-control-container']}>
      <div className={css['tasks-control-filters']}>
        {!consumerId && (
          <>
            <Select
              defaultValue={filters.status}
              className="documents-control-select tasks-select-status common-animation-primary"
              onChange={onChangeStatus}
              style={{ paddingTop: '5px', paddingBottom: '5px' }}
              dropdownClassName="documents-control-select-dropdown"
              suffixIcon={
                <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
              }>
              <Option value="" className="documents-control-option">
                <span style={{ fontSize: '14px', fontWeight: 600 }}>{t('tasks.control.status.all')}</span>
              </Option>
              <Option value={StatusTask.done} className="documents-control-option">
                <span style={{ fontSize: '14px', fontWeight: 600 }}>{t('tasks.control.status.done')}</span>
              </Option>
              <Option value={StatusTask.todo} className="documents-control-option">
                <span style={{ fontSize: '14px', fontWeight: 600 }}>{t('tasks.control.status.todo')}</span>
              </Option>
            </Select>
            <Select
              style={{ marginLeft: '5px', paddingTop: '5px', paddingBottom: '5px' }}
              placeholder={t('tasks.control.assignee.all')}
              className="documents-control-select common-animation-primary"
              onChange={onSelectRole}
              defaultValue={filters.assigneeRole}
              dropdownClassName="documents-control-select-dropdown"
              suffixIcon={
                <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
              }>
              <Option value="" className="documents-control-option">
                <span style={{ fontSize: '14px', fontWeight: 600 }}>{t('tasks.control.assignee.all')}</span>
              </Option>
              <Option value="me" className="documents-control-option">
                <span style={{ fontSize: '14px', fontWeight: 600 }}>{`${t('common.me')} (${user.firstName} ${
                  user.lastName
                })`}</span>
              </Option>
              <Option value="agent" className="documents-control-option">
                <span style={{ fontSize: '14px', fontWeight: 600 }}>{t('quotes.modal.form.agents')}</span>
              </Option>
              <Option value="consumer" className="documents-control-option">
                <span style={{ fontSize: '14px', fontWeight: 600 }}>{t('quotes.modal.form.acquirers')}</span>
              </Option>
              <Option value="supplier" className="documents-control-option">
                <span style={{ fontSize: '14px', fontWeight: 600 }}>{t('tasks.control.suppliers')}</span>
              </Option>
            </Select>
            {users.length > 0 && (
              <Select
                style={{ marginLeft: '5px', paddingTop: '5px', paddingBottom: '5px' }}
                placeholder={t('tasks.control.assignee.all')}
                className="documents-control-select common-animation-primary"
                onChange={getUserTasks}
                value={userIdSelect}
                dropdownClassName={`documents-control-select-dropdown ${
                  fetchingData ? 'consumers-control-select-dropdown--progress' : ''
                }`}
                onPopupScroll={(e: any) => {
                  e.persist();
                  let target = e.target;
                  if (
                    !fetchingData &&
                    !isLastUserReq &&
                    target.scrollTop + target.offsetHeight === target.scrollHeight
                  ) {
                    getUsers(tasksFilter.assigneeRole, false, true);
                  }
                }}
                suffixIcon={
                  <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
                }>
                {users.map((user) => (
                  <Option value={user.id} key={user.id} className="documents-control-option">
                    <span style={{ fontSize: '14px', fontWeight: 600 }}>{`${user.firstName} ${user.lastName}`}</span>
                  </Option>
                ))}
              </Select>
            )}
            <RangePicker
              style={{ paddingTop: '5px', paddingBottom: '5px' }}
              suffixIcon={<div className={'calendar-img'} style={{ width: '18px', height: '18px' }}></div>}
              className="documents-control-range-picker task-control-range-picker common-animation-primary"
              defaultValue={dataRange}
              inputReadOnly
              onChange={onChangeData}
            />
          </>
        )}
      </div>
      {!useLessThen801() && (
        <Button
          className={`${css[isCreate ? 'add-task-btn-active' : 'add-task-btn']} common-primary-btn`}
          style={{ height: '42px' }}
          onClick={() => setIsCreate(!isCreate)}>
          <span>{t('tasks.control.add.btn')}</span>
        </Button>
      )}
    </div>
  );
};

export default TasksControl;
