import React from 'react';
import { Badge, Button, Dropdown, Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import css from '../../../assets/styles/properties.module.css';
import editIcon from '../../../assets/images/ic-edit-dark.svg';
import deleteIcon from '../../../assets/images/ic-delete-dark-gray.svg';
import moreIcon from '../../../assets/images/ic-more.svg';
import { EnterOutlined } from '@ant-design/icons';
import { TFunction } from 'react-i18next';

type SortType = 'ascend' | 'descend';

export const getBadgeStatus = (status: string) => {
  switch (status) {
    case 'available':
      return 'success';
    case 'sold':
      return 'error';
    case 'reserved':
      return 'warning';
    case 'rented':
      return 'warning';
  }
};

export const getColumns = (
  t: TFunction<'translation'>,
  createProperty: any,
  handleEdit: any,
  isSupplier: boolean,
  deleteProperty: (item: any) => void,
) => [
  {
    title: () => <span className="budget-table-tr">{t('properties.table.columns.title')}</span>,
    defaultSortOrder: 'ascend' as SortType,
    dataIndex: ['title'],
    key: 'title',
    width: '35%',
    render: (cell: any) => cell || 'N/A',
    sorter: (a: any, b: any) => (a.title > b.title ? 1 : -1),
  },
  {
    title: () => <span className="budget-table-tr">{t('properties.table.columns.type')}</span>,
    dataIndex: 'type',
    key: 'type',
    width: '18%',
    render: (cell: any) => (cell ? cell.description : 'N/A'),
  },
  {
    title: () => <span className="budget-table-tr">{t('properties.table.columns.status')}</span>,
    dataIndex: 'status',
    key: 'status',
    width: '18%',
    render: (cell: any) => (cell ? <Badge status={getBadgeStatus(cell.code)} text={cell.description} /> : 'N/A'),
  },
  {
    title: () => <span className="budget-table-tr">{t('properties.table.columns.consumers')}</span>,
    dataIndex: 'consumers',
    key: 'consumers',
    width: '21%',
    render: (cell: any, row: any) =>
      row.consumers?.length > 0
        ? row.consumers.map((user: any, index: number) => (
            <React.Fragment key={user.id}>
              <NavLink to={`/consumers/modify/${user.id}`}>{`${user.firstName} ${user.lastName}`}</NavLink>
              {`${index !== row.consumers.length - 1 ? ', ' : ''}`}
            </React.Fragment>
          ))
        : 'N/A',
  },
  {
    title: () => <span className="budget-table-tr">{t('properties.table.columns.action')}</span>,
    key: 'action',
    width: 110,
    render: (cell: any, row: any) => {
      const menu = (
        <Menu className="update-menu-container" style={{ width: '160px' }}>
          <Menu.Item key="edit">
            <div onClick={() => handleEdit(cell)} className={css['update-menu-item']}>
              <Button
                style={{
                  border: 'none',
                  backgroundColor: 'inherit',
                  boxShadow: 'none',
                  padding: 0,
                  marginRight: '0.5rem',
                }}>
                <img src={editIcon} alt="" style={{ height: '15px', width: '15px' }} />
              </Button>
              <span className={css['title']}>{t('gallery.modal.select.btn')}</span>
            </div>
          </Menu.Item>
          {cell.type && cell.type.code !== 'unit' && (
            <Menu.Item key="create">
              <div onClick={() => createProperty(cell)} className={css['update-menu-item']}>
                <Button
                  style={{
                    border: 'none',
                    backgroundColor: 'inherit',
                    boxShadow: 'none',
                    padding: 0,
                    marginRight: '0.5rem',
                  }}>
                  <EnterOutlined style={{ fontSize: '14px', color: '#00122d', transform: 'scale(-1, 1)' }} />
                </Button>
                <span className={css['title']}>{t('properties.control.btn.new')}</span>
              </div>
            </Menu.Item>
          )}
          {cell.childrens?.length === 0 && (
            <Menu.Item key="delete">
              <div onClick={() => deleteProperty(cell)} className={css['update-menu-item']}>
                <Button
                  style={{
                    border: 'none',
                    backgroundColor: 'inherit',
                    boxShadow: 'none',
                    padding: 0,
                    marginRight: '0.5rem',
                  }}>
                  <img src={deleteIcon} alt="" style={{ height: '15px', width: '15px' }} />
                </Button>
                <span className={css['title']}>{t('common.title.delete')}</span>
              </div>
            </Menu.Item>
          )}
        </Menu>
      );
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          {cell?.childrens?.length === 0 && (
            <NavLink
              to={{
                pathname: `/properties/${cell?.id}/project/${cell?.project?.id}/manage/${
                  isSupplier ? 'details_project' : 'calendar'
                }`,
                state: {
                  projectKey: cell?.project?.key,
                },
              }}
              style={{ borderRadius: 4, marginRight: isSupplier ? 0 : '1rem' }}
              className="common-animation-primary">
              <Button
                className="common-secondary-btn"
                style={{ borderColor: '#076ee5', backgroundColor: '#eef0f4', borderRadius: '6px' }}>
                <span style={{ color: '#076ee5' }}>{t('consumers.table.columns.manage')}</span>
              </Button>
            </NavLink>
          )}
          {!isSupplier && (
            <Dropdown overlay={menu} trigger={['click']}>
              <Button className={css['update-dropdown-btn']}>
                <img src={moreIcon} alt="" height={15} width={15} />
              </Button>
            </Dropdown>
          )}
        </div>
      );
    },
  },
];
