export const setTextColorByStatus = (statusCode: string) => {
  switch (statusCode) {
    case 'client_approve':
    case 'agent_approve':
    case 'agent_approval': {
      return '#0065de';
    }
    case 'client_sign':
    case 'agent_sign':
    case 'acquirer_signature': {
      return '#e08b32';
    }
    case 'client_approve_partially':
    case 'client_sign_partially':
    case 'agent_approve_partially':
    case 'agent_sign_partially':
    case 'agent_signature': {
      return '#26bedc';
    }
    case 'done':
    case 'signed': {
      return '#50c264';
    }
    case 'new': {
      return '#cd0597';
    }
    case 'approved': {
      return '#19ac87';
    }
    case 'signed': {
      return '#00d29f';
    }
    case 'partly_signed': {
      return '#eb7d5a';
    }
    case 'partly_paid': {
      return '#eb7d5a';
    }
    case 'paid': {
      return '#21b793';
    }
    case 'in_process':
    case 'on_pause':
    case 'suppliers_quote': {
      return '#b37feb';
    }
    case 'partially_sign': {
      return '#eb7d5a';
    }
    default:
      return '#fc0000';
  }
};
