import { Button, Form, Table, notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getColumns } from './getColumn';
import ListPageServices from '../../../../services/PropertiesService';
import { PropertyModel } from '../../../../models/PropertyModel';
import { IAppContext } from '../../../../typings/IApp';
import { AppContext } from '../../../../contexts/AppContextProvider';
import { IUserExport } from '../../../../models/ConsumerModel';
import ConsumersService from '../../../../services/ConsumersService';

interface IProps {
  users: IUserExport[];
  projectId: number;
  setStage: (n: number) => void;
  handleClose: () => void;
  setFetchingData: (b: boolean) => void;
}
const propertyService = new ListPageServices();
const consumersService = new ConsumersService();

const ImportConsumersSecondStage = ({ projectId, users, handleClose, setFetchingData, setStage }: IProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const {
    app: { user },
  } = useContext<IAppContext>(AppContext);

  const [properties, setProperties] = useState<PropertyModel[]>([]);

  useEffect(() => {
    const projectKey = (user && user.projects.find((project) => project.id === projectId)?.key) || '';
    propertyService
      .getLightTreeByProject({
        id: projectId,
        projectKey,
      })
      .then((data: PropertyModel[]) => {
        setProperties(data);
      });
  }, []);

  const onSubmit = (properties: any) => {
    setFetchingData(true);
    const projectKey = (user && user.projects.find((project) => project.id === projectId)?.key) || '';
    const consumers = users.map((u) => {
      return {
        ...u,
        properties: properties[u.email].map((propertyId: number) => ({
          id: propertyId.toString(),
        })),
      };
    });
    consumersService
      .importConsumersCreate({ consumers }, projectKey)
      .then(() => {
        notification.success({
          message: t('consumers.success.import'),
        });
        handleClose();
      })
      .catch((e) => {
        notification.error({
          message: e.message || t('common.error.internalServerError'),
        });
      })
      .finally(() => {
        setFetchingData(false);
      });
  };

  return (
    <div style={{ padding: '30px 30px 10px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h2 style={{ marginBottom: 0, textTransform: 'uppercase' }}>{t('consumers.import.title')}</h2>
        <div className="common-modal-footer">
          <Button className={`common-gray-border-btn common-secondary-btn`} onClick={() => setStage(1)}>
            {t('consumers.modal.cancel.btn')}
          </Button>
          <Button className={`common-green-btn common-modal-btn`} onClick={form.submit}>
            {t('consumers.modal.import.btn')}
          </Button>
        </div>
      </div>
      <p style={{ color: '#FF3232', marginBottom: 10 }}>{t('consumers.import.warning')}</p>
      <Form form={form} onFinish={onSubmit}>
        <Table
          columns={getColumns({
            t,
            properties,
          })}
          size="small"
          className={`list-info-table`}
          dataSource={users}
          showHeader={true}
          pagination={false}
          scroll={{ y: 600 }}
          rowKey="email"
        />
      </Form>
    </div>
  );
};

export default ImportConsumersSecondStage;
