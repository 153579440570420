import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button, notification, Table, TableProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { getColumns } from './components/ProjectsColumns';
import ProjectsService from '../../services/ProjectsService';
import { HeaderContext } from '../../contexts/HeaderContextProvider';
import { CHANGE_CURRENT_PAGE } from '../../constants/actionTypes/headerConstants';
import css from '../../assets/styles/project.module.css';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useLessThen801 } from '../../helpers/mediaDetect';
import TableMobile from '../common/TableMobile';
import LogoComponent from '../../components/common/LogoComponent';
import { convertToLocalDateTime } from '../../helpers/converToLocalDate';
import { RouteComponentProps, useHistory } from 'react-router';
import BoardTourComponent from '../common/BoardTourComponent';
import CustomOnBoard from '../common/CustomOnBoard';
import { getOnboarding, OnboardingTypes, saveOnboarding } from '../../helpers/onboarding';
import { AppContext } from '../../contexts/AppContextProvider';
import { generateSorter } from '../../helpers/generateSorter';
import { IProjectEasy } from '../../models/ProjectModel';
import { IAppContext } from '../../typings/IApp';
import i18n from '../../utils/i18n';

const service = new ProjectsService();

const ProjectsPage: React.FC<RouteComponentProps> = (props) => {
  const { t } = useTranslation();
  const [items, setItems] = useState<IProjectEasy[]>([]);
  const { height: windowHeight } = useWindowDimensions();
  const history = useHistory();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    skip: 0,
    showSizeChanger: true,
  });
  const {
    app: { user },
  } = useContext<IAppContext>(AppContext);
  const { headerDispatch } = useContext(HeaderContext);
  useEffect(() => {
    headerDispatch({
      type: CHANGE_CURRENT_PAGE,
      currentPage: t('header.title.Projects'),
      path: 'projects',
    });
  }, [i18n.language]);

  const option = {
    count: true,
    top: pagination.pageSize,
    current: pagination.current,
    orderBy: generateSorter(['name asc']).orderBy,
    skip: pagination.pageSize * (pagination.current - 1) || 0,
  };

  //board
  const [steps, _] = useState([
    {
      target: '.project-first-step',
      content: (
        <BoardTourComponent title={t('board.project.first.title')} description={t('board.project.first.text')} />
      ),
      disableBeacon: true,
      placement: 'left',
    },
  ]);
  const [run, setRun] = useState(false);

  const getProjects = (option: { [name: string]: any }) => {
    service
      .getProjects(option)
      .then((res) => {
        const { count, items } = res;
        const { current, top } = option;
        setPagination({ ...pagination, current, total: count, pageSize: top });
        setItems(items);
        setRun(getOnboarding(OnboardingTypes.PROJECT));
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      );
  };

  useEffect(() => {
    getProjects(option);
  }, [setItems]);

  const onTableChange: TableProps<IProjectEasy>['onChange'] = (pagination, _, sorter) => {
    let params: { [name: string]: any } = {
      ...option,
    };
    if (pagination && pagination.pageSize && pagination.current) {
      params = {
        ...params,
        top: pagination.pageSize,
        skip: pagination.pageSize * (pagination.current - 1),
        current: pagination.current,
      };
    } else {
      params = {
        ...params,
        skip: 0,
        current: 1,
      };
    }

    if (sorter && !Array.isArray(sorter) && sorter.order) {
      const { order, columnKey } = sorter;
      params = {
        ...params,
        orderBy: `${columnKey} ${order === 'ascend' ? 'asc' : 'desc'}`,
      };
    }

    getProjects(params);
  };

  if (useLessThen801()) {
    const data =
      items.length > 0 &&
      (items.map((item) => [
        {
          title: '#',
          description: <LogoComponent id={item.id} name={item.title} image={item.logo || ''} />,
          type: 'string',
        },
        { title: t('projects.table.columns.name'), description: item.name, type: 'string' },
        { title: t('projects.table.columns.title'), description: item.title, type: 'string' },
        {
          title: t('projects.table.columns.createdDate'),
          description: item.createdDate ? convertToLocalDateTime(item.createdDate) : '',
          type: 'string',
        },
        {
          title: t('projects.table.columns.updatedDate'),
          description: item.updatedDate ? convertToLocalDateTime(item.updatedDate) : '',
          type: 'string',
        },
        {
          title: t('budget.table.action'),
          description: item.file,
          type: 'button',
          openByFunction: true,
          btnsInfo: [
            {
              function: () => history.push(`/projects/modify/${item.id}/general`),
              functionBtnTitle: t('common.btn.modify.lower'),
            },
          ],
        },
      ]) as any);
    return (
      <>
        <div className="common-mobile-title-container">
          <span style={{ fontSize: '20px' }}>{t('header.title.Projects')}</span>
          <div className={css['project-control-container']}>
            <Link
              to={`${props.history.location.pathname}/modify`}
              style={{ borderRadius: 4 }}
              className="common-animation-primary">
              <Button className={`common-blue-btn common-primary-btn`} style={{ height: '42px' }}>
                {t('common.btn.new')}
              </Button>
            </Link>
          </div>
        </div>
        <TableMobile data={data} isLoading={false} option={{}} itemsLength={items.length} totalItems={items.length} />
      </>
    );
  }

  return (
    <>
      <CustomOnBoard
        steps={steps}
        run={run}
        onFinish={() => saveOnboarding(OnboardingTypes.PROJECT, user.boardCheckpoint)}
      />
      <div className={css['project-control-container']}>
        <Link
          to={`${props.history.location.pathname}/modify/general`}
          style={{ borderRadius: 4 }}
          className="common-animation-primary project-first-step">
          <Button className={`common-blue-btn common-primary-btn`} style={{ height: '42px' }}>
            {t('common.btn.new')}
          </Button>
        </Link>
      </div>
      <div className={css['project-table-container']}>
        <Table
          columns={getColumns({
            t,
          })}
          dataSource={items}
          showHeader={true}
          onChange={onTableChange}
          scroll={{ y: windowHeight - 280, x: 900 }}
          size="middle"
          pagination={pagination}
          className="consumers-info-table"
          rowClassName="common-table-row--pointer"
          rowKey="id"
        />
      </div>
    </>
  );
};

export default ProjectsPage;
