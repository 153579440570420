import { Button, Select, Input, DatePicker, TablePaginationConfig } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import css from '../../../assets/styles/budget.module.css';
import { useTranslation } from 'react-i18next';
import searchIcon from '../../../assets/images/ic-search.svg';
import { FilterTypes } from '../../../constants/filterTypes';
import { FiltersContext } from '../../../contexts/FiltersContextProvider';
import { SET_QUOTES_FILTERS } from '../../../constants/actionTypes/filtersConstants';
import { useLessThen801 } from '../../../helpers/mediaDetect';
import { useLocation } from 'react-router';
import moment from 'moment';
import { getSearchParam } from '../../../helpers/customUrlSearchParams';
import CustomSignFlow from '../../../services/CustomSignFlow';
import { setTextColorByStatus } from '../../../helpers/setTextColorByStatus';
import { generateFilterfromObj } from '../../../helpers/generateFIlterfromObj';
import { StatusesDocumentsModel } from '../../../models/DocumentsModel';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { IQuoteModel } from '../../../models/QuotesModel';
import { RangePickerProps } from 'antd/lib/date-picker';

interface QuotesControlProps {
  setIsOpenModal: (b: boolean) => void;
  isConsumer: boolean;
  isSupplier: boolean;
  onTableChange: (
    pagination?: TablePaginationConfig,
    filters?: Record<string, FilterValue | null | any>,
    sorter?: SorterResult<IQuoteModel> | SorterResult<IQuoteModel>[],
  ) => void;
  propertyId: number;
  setIsOpenUpload?: (b: boolean) => void;
  pagination: {
    current: number;
    pageSize: number;
    total: number;
    showSizeChanger: boolean;
  };
  activeTab: number;
}

const { Option } = Select;
const { Search } = Input;
const { RangePicker } = DatePicker;

const serviceFlow = new CustomSignFlow();

const QuotesControl = (props: QuotesControlProps) => {
  const { setIsOpenModal, isConsumer, isSupplier, onTableChange, propertyId, setIsOpenUpload, activeTab } = props;
  const { t } = useTranslation();
  const {
    filters: { quotesFilter },
    filtersDispatch,
  } = useContext(FiltersContext);
  const { search } = useLocation();
  const nameQuery = getSearchParam(search, 'name');
  const deadline = getSearchParam(search, 'deadline');
  const [searchValue, setSearchValue] = useState(nameQuery || '');
  const [statuses, setStatuses] = useState<StatusesDocumentsModel>([]);
  const [currentTimeout, setCurrentTimeout] = useState<NodeJS.Timeout | null>(null);
  const [dataRangeDeadline, setDataRangeDeadline] = useState<RangePickerProps['value']>(
    deadline ? [moment(new Date(deadline)), moment(new Date(deadline))] : undefined,
  );

  useEffect(() => {
    serviceFlow
      .getStatuses({
        filters: generateFilterfromObj({
          'doc_types_statuses/typeCode': {
            type: FilterTypes.SELECT,
            value: 'quotes',
          },
        }),
      })
      .then((value: StatusesDocumentsModel) => setStatuses(value))
      .catch((e) => console.log(e));
  }, [propertyId]);

  useEffect(() => {
    if (nameQuery || deadline) {
      let body: { [name: string]: { type: string; value: any } } = {};
      if (nameQuery) {
        body['number'] = {
          type: FilterTypes.SEARCH,
          value: nameQuery,
        };
      } else {
        setSearchValue('');
        body['number'] = {
          type: FilterTypes.SEARCH,
          value: '',
        };
      }
      if (deadline) {
        body['deadline'] = {
          type: FilterTypes.DATE_RANGE,
          value: [moment(new Date(deadline)), moment(new Date(deadline))],
        };
      } else {
        setDataRangeDeadline(null);
        body['deadline'] = {
          type: FilterTypes.DATE_RANGE,
          value: null,
        };
      }
      setTimeout(() => onTableChange(undefined, body), 1000);
    }
  }, [nameQuery, deadline]);

  const onChangeStatus = (value: string) => {
    filtersDispatch({
      type: SET_QUOTES_FILTERS,
      filters: {
        status: value,
      },
    });
    const body = {
      'status_v2/code': {
        type: FilterTypes.SELECT,
        value: value,
      },
    };
    onTableChange(undefined, body);
  };

  const onSearch = (value: string) => {
    setSearchValue(value || '');
    const body = {
      number: {
        type: FilterTypes.SEARCH,
        value: value,
      },
    };
    clearTimeout(Number(currentTimeout));
    setCurrentTimeout(setTimeout(() => onTableChange(undefined, body), 1000));
  };

  const changeDeadline = (value: RangePickerProps['value']) => {
    setDataRangeDeadline(value || null);
    const body = {
      deadline: {
        type: FilterTypes.DATE_RANGE,
        value: value,
      },
    };
    onTableChange(undefined, body);
  };

  return (
    <div className={css['budget-control-container']}>
      <div className={css['budget-filter-control']}>
        <div className={`${css['budget-filters']} budget-control-wrapper`}>
          <Select
            style={{ width: '150px' }}
            value={quotesFilter.status}
            className="budget-control-select common-animation-primary"
            dropdownClassName="budget-control-select-dropdown"
            onChange={onChangeStatus}
            placeholder={t('budget.control.filter.status.placeholder')}
            suffixIcon={
              <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
            }>
            <Option value="" className="budget-control-option">
              <span style={{ fontSize: '14px', fontWeight: 600 }}>{t('budget.control.select.all')}</span>
            </Option>
            {statuses.length > 0 &&
              statuses.map((status) => (
                <Option value={status.code} key={status.code} className="budget-control-option">
                  <span style={{ fontSize: '14px', fontWeight: 600, color: setTextColorByStatus(status.code) }}>
                    {status.description}
                  </span>
                </Option>
              ))}
          </Select>
        </div>
        <Search
          onChange={(e) => onSearch(e.target.value)}
          placeholder={t('budget.search.number.placeholder')}
          allowClear
          value={searchValue}
          className="budget-control-search common-animation-primary"
          suffix={<img src={searchIcon} alt="" />}
          style={{ width: '195px', marginLeft: '5px', padding: '1px 0' }}
        />
        <RangePicker
          suffixIcon={<div className={'calendar-img'} style={{ width: '12px', height: '12px' }}></div>}
          className="budget-control-range-picker common-animation-primary"
          style={{ height: '34px', padding: '10px 5px' }}
          value={dataRangeDeadline}
          format="DD.MM.YYYY"
          placeholder={[t('report.table.columns.start'), t('report.table.columns.end')]}
          inputReadOnly
          onChange={(dates) => changeDeadline(dates)}
        />
      </div>
      {!useLessThen801() && activeTab !== 2 && (
        <div style={{ display: 'flex' }}>
          {!isConsumer && (
            <Button
              className={`common-blue-border-btn common-secondary-btn`}
              onClick={() => setIsOpenModal(true)}
              style={{ marginRight: '1rem' }}>
              <span>{t('quotes.table.upload.invoce')}</span>
            </Button>
          )}
          {!isConsumer && !isSupplier && (
            <Button
              className={`common-blue-btn common-primary-btn`}
              onClick={() => setIsOpenUpload && setIsOpenUpload(true)}>
              <span>{t('budget.control.create.btn')}</span>
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default QuotesControl;
