import React, { useEffect } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';
import { ConfigProvider } from 'antd';

import LoginPage from './views/common/LoginPage';
import ChangePasswordPage from './views/common/ChangePasswordPage';
import DefaultLayout from './containers/DefaultLayout/DefaultLayout';
import AppContextProvider from './contexts/AppContextProvider';
import { createBrowserHistory } from 'history';
import config from './config';
import TagManager from 'react-gtm-module';

import './utils/i18n';
import { useTranslation } from 'react-i18next';
import { langs } from './utils/i18n';
import { Locale } from 'antd/lib/locale-provider';
import RegisterPage from './views/common/RegisterPage';
import { hotjar } from 'react-hotjar';
import RestorePasswordPage from './views/common/RestorePasswordPage';
import ConfirmNewPasswordPage from './views/common/ConfirmNewPasswordPage';
import './assets/styles/app.css';
import './assets/styles/common.css';
import 'suneditor/dist/css/suneditor.min.css';
import ProcoreCallBackPage from './views/common/ProcoreCallBackPage';

if (config.enableTracking) {
  const trakingId = config.gaTrackingId || '';
  ReactGA.initialize(trakingId);
}

if (config.enableGTM) {
  const GTMArgs = {
    gtmId: config.gtmId || '',
  };
  TagManager.initialize(GTMArgs);
}

const history = createBrowserHistory();
history.listen((location) => {
  if (config.enableTracking) {
    const pathname = location.pathname + (location.search ? location.search : '');
    ReactGA.set({ page: pathname });
    ReactGA.pageview(pathname);
  }
});

const App: React.FC = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    if (config.enableTracking && window !== undefined) {
      const pathname = window.location.pathname + (window.location.search ? window.location.search : '');
      ReactGA.pageview(pathname);
    }
    if (config.enableHotjar && window !== undefined) {
      hotjar.initialize(Number(config.hotjarId), Number(config.hotjarSv));
    }
  }, []);

  const getAntdLocale = () => {
    const lang = langs.find((lng) => lng.key === i18n.language);
    if (lang) {
      return lang.antLocale as Locale;
    }
    return undefined;
  };
  const locale: Locale | undefined = getAntdLocale();

  return (
    <Router history={history}>
      <AppContextProvider>
        <Switch>
          <Route exact path="/procore/callback" component={ProcoreCallBackPage} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/restore-pass" component={RestorePasswordPage} />
          <Route exact path="/change-pass" component={ChangePasswordPage} />
          <Route exact path="/register" component={RegisterPage} />
          <Route exact path="/new-pass" component={ConfirmNewPasswordPage} />
          <ConfigProvider locale={locale}>
            <Route path="/" component={DefaultLayout} />
          </ConfigProvider>
        </Switch>
      </AppContextProvider>
    </Router>
  );
};

export default App;
