import { Badge, Button, Calendar, DatePicker, notification, Row } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import css from '../../../assets/styles/calendar.module.css';
import calendarIcon from '../../../assets/images/ic-calendar-date-picker.svg';
import CalendarService from '../../../services/CalendarServices';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../contexts/AppContextProvider';
import UpdatesComponent from '../ConsumerCalendar/components/UpdatesComponents';
import UpdatesEditComponent from '../BrokerCalendar/components/UpdatesEditComponent';
import { useLessThen801 } from '../../../helpers/mediaDetect';
import { formatDate } from '../../../helpers/dateTools';
import UpdatesMobileComponent from '../ConsumerCalendar/components/UpdatesMobileComponent';
import UpdatesBrokerMobileComponent from '../BrokerCalendar/components/UpdatesBrokerMobileComponent';
import MeetingsService from '../../../services/MeetingsService';
import { MeetingModel, MeetingsModel } from '../../../models/MeetingsModel';
import { CalendarTypes, IPost, UpdateModel } from '../../../models/CalendarModel';
import { IAppContext } from '../../../typings/IApp';

interface CalendarBrokerProps {
  propertyId: number;
}

const service = new CalendarService();
const meetingService = new MeetingsService();

const CalendarComponent = (props: CalendarBrokerProps) => {
  const { t } = useTranslation();
  const {
    app: { user, isConsumer },
  } = useContext<IAppContext>(AppContext);
  const { propertyId } = props;
  const [currDate, setCurrDate] = useState(moment());
  const [updates, setUpdates] = useState<(MeetingModel | UpdateModel)[]>([]);
  const [begin, setBegin] = useState(moment().startOf('month').format('YYYY-MM-DD hh:mm'));
  const [end, setEnd] = useState(moment().endOf('month').format('YYYY-MM-DD hh:mm'));
  const [isOpenPicker, setIsOpenPicker] = useState(false);
  const [currUpdates, setCurrUpdates] = useState<(MeetingModel | UpdateModel)[]>([]);
  const [datePickerValue, setDatePickerValue] = useState<moment.Moment | null>(null);
  const [isChangedDate, setIsChangedDate] = useState(false);

  useEffect(() => {
    getCalendarUpdates(propertyId);
  }, [currDate, propertyId]);

  const getCalendarUpdates = (id: number | null) => {
    service
      .getCalendarUpdates({
        filter: {
          or: [
            {
              'type/code': {
                eq: CalendarTypes.update,
              },
            },
            {
              'type/code': {
                eq: CalendarTypes.post,
              },
            },
          ],
          and: [
            {
              'properties/id': {
                eq: id,
              },
            },
            {
              date: {
                ge: moment(begin).set({ hour: 0, minute: 0 }).format('YYYY-MM-DD HH:mm'),
              },
            },
            {
              date: {
                lt: moment(end).set({ hour: 0, minute: 0 }).add(1, 'day').format('YYYY-MM-DD HH:mm'),
              },
            },
          ],
        },
      })
      .then((value: UpdateModel[]) => {
        getMeetings(value);
      })
      .catch((e) => console.log(e));
  };

  const getMeetings = (updates: UpdateModel[]) => {
    meetingService
      .getMeetings({
        filter: {
          and: [
            {
              datetime: {
                ge: moment(begin).set({ hour: 0, minute: 0 }).format('YYYY-MM-DD HH:mm'),
              },
            },
            {
              datetime: {
                lt: moment(end).set({ hour: 0, minute: 0 }).add(1, 'day').format('YYYY-MM-DD HH:mm'),
              },
            },
          ],
          or: [
            {
              'created_user/id': {
                eq: +user.id,
              },
            },
            {
              'assignees/id': {
                eq: +user.id,
              },
            },
          ],
        },
      })
      .then((value: MeetingsModel) => {
        setUpdates([...updates, ...value]);
        setCurrUpdates([...updates, ...value]);
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      );
  };

  const setMonth = (step: string) => {
    const currentMonth = currDate.clone();
    if (step === 'prev') {
      currentMonth.subtract(1, 'months');
      setBegin(currentMonth.format('YYYY-MM-01'));
      setEnd(currentMonth.format('YYYY-MM-') + currentMonth.daysInMonth());
    } else {
      currentMonth.add(1, 'months');
      setBegin(currentMonth.format('YYYY-MM-01'));
      setEnd(currentMonth.format('YYYY-MM-') + currentMonth.daysInMonth());
    }
    getCalendarUpdates(propertyId);
    setCurrDate(currentMonth);
  };

  const setDay = (date: moment.Moment) => {
    if (moment(date).format('MM') !== moment(currDate).format('MM')) {
      setBegin(date.format('YYYY-MM-01'));
      setEnd(date.format('YYYY-MM-') + date.daysInMonth());
    }
    setCurrDate(date);
  };

  const dateCellRender = (value: moment.Moment) => {
    const listData = updates.filter(
      (update: any) => moment(update.date || update.datetime).format('YYYY-MM-DD') === value.utc().format('YYYY-MM-DD'),
    );

    return (
      <ul className={css['events']}>
        {listData.slice(0, 3).map((_, index: number) => (
          <li className={css['dot']} key={index}></li>
        ))}
      </ul>
    );
  };

  const dateRender = (current: moment.Moment) => {
    const listData = updates.filter(
      (update: any) =>
        moment(update.date || update.datetime).format('YYYY-MM-DD') === current.utc().format('YYYY-MM-DD'),
    );

    return (
      <div
        className="ant-picker-cell-inner"
        onClick={() => {
          setCurrUpdates(listData);
          setDatePickerValue(current);
          setIsOpenPicker(false);
          setIsChangedDate(true);
        }}>
        {listData.length > 0 ? (
          <Badge count={listData.length} size="small" style={{ top: '-4px' }} color="blue">
            <span style={{ fontSize: '18px', marginBottom: '2px' }}>{current.date()}</span>
          </Badge>
        ) : (
          <span style={{ fontSize: '18px', marginBottom: '2px' }}>{current.date()}</span>
        )}
      </div>
    );
  };

  const setMobileCalendarDate = (value: moment.Moment) => {
    setDatePickerValue(null);
    setIsChangedDate(false);
    setCurrDate(value);
    setBegin(value.format('YYYY-MM-01'));
    setEnd(value.format('YYYY-MM-') + value.daysInMonth());
  };

  const resetMobileCalendarDate = () => {
    setDatePickerValue(null);
    getCalendarUpdates(propertyId);
    setIsChangedDate(false);
  };

  if (useLessThen801()) {
    return (
      <div className={css['calendar-mobile-container']} style={{ border: isConsumer ? 'auto' : 'none' }}>
        <span className={css['calendar-title']}>{t('calendar.calendar.title')}</span>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: '1rem' }}>
          <span style={{ color: '#778dac', fontSize: '16px', marginRight: '1rem' }}>{`${formatDate(
            currDate,
            'MMMM YYYY',
            true,
          )}`}</span>
          <DatePicker
            allowClear={false}
            showToday={false}
            onBlur={() => setIsOpenPicker(false)}
            onClick={() => setIsOpenPicker(true)}
            open={isOpenPicker}
            value={datePickerValue}
            inputReadOnly
            format="DD.MM.YYYY"
            dateRender={dateRender}
            suffixIcon={<img src={calendarIcon} alt="" />}
            style={{ width: '50%' }}
            onPanelChange={(value) => {
              setMobileCalendarDate(moment(value));
            }}
          />
          {isChangedDate && (
            <span onClick={() => resetMobileCalendarDate()} style={{ marginLeft: '0.5rem' }}>
              x
            </span>
          )}
        </div>
        {updates.length > 0 &&
          (isConsumer ? (
            <UpdatesMobileComponent updates={currUpdates} />
          ) : (
            <UpdatesBrokerMobileComponent updates={currUpdates} resetMobileCalendarDate={resetMobileCalendarDate} />
          ))}
      </div>
    );
  }

  return (
    <div
      className={isConsumer ? 'calendar-container-consumer' : 'calendar-container'}
      style={{ height: 'calc(100vh - 15rem)' }}>
      <Calendar
        className="calendar-date"
        value={currDate}
        fullscreen
        dateCellRender={dateCellRender}
        onSelect={(date) => setDay(date)}
        headerRender={() => (
          <Row className={css['calendar-header']}>
            <span className={css['calendar-title']}>{t('calendar.calendar.title')}</span>
            <div className={css['header-date']}>
              <span className={css['current-date']}>{`${formatDate(currDate, 'MMMM YYYY', true)}`}</span>
              <span className={css['arrows-container']}>
                <Button className={css['arrow-container']} onClick={() => setMonth('prev')}>
                  <div className={`${css['arrow']} ${css['arrow-left']}`}></div>
                </Button>
                <Button className={css['arrow-container']} onClick={() => setMonth('next')}>
                  <div className={`${css['arrow']} ${css['arrow-right']}`}></div>
                </Button>
              </span>
            </div>
          </Row>
        )}
      />
      {isConsumer ? (
        <UpdatesComponent updates={updates} currDate={currDate} />
      ) : (
        <UpdatesEditComponent
          setCurrDate={setCurrDate}
          currDate={currDate}
          updates={updates}
          propertyId={propertyId}
          getCalendarUpdates={getCalendarUpdates}
        />
      )}
    </div>
  );
};

export default CalendarComponent;
