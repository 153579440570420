import React, { useState, useEffect, useContext } from 'react';
import { Modal, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { getColumns } from './components/AssignOptionColumns';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import SpacesService from '../../services/SpacesService';
import { AppContext } from '../../contexts/AppContextProvider';
import { IAppContext } from '../../typings/IApp';

export interface AssignOptionModalModel {
  spaceObject?: any;
  isVisible?: boolean;
  onOk?: (selectedKeys: any[], isSelectedAll: boolean) => void;
  onCancel?: () => void;
}

const spacesService: SpacesService = new SpacesService();

const AssignOptionModal: React.FC<AssignOptionModalModel> = ({
  spaceObject,
  isVisible = false,
  onOk = () => {},
  onCancel = () => {},
}: AssignOptionModalModel) => {
  const {
    app: { currentProject },
  } = useContext<IAppContext>(AppContext);
  const { t } = useTranslation();
  const { height: windowHeight } = useWindowDimensions();
  const [objects, setObjects] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<any[]>([]);

  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const defaultParams = {
    count: true,
    expand: ['space/variant/level/typology/project'],
    filter: {
      project: { key: { eq: currentProject?.key } },
      ...(spaceObject ? { id: { ne: spaceObject.id } } : {}),
    },
  };

  const getSpaceObjectsAsync = (option: any) => {
    return spacesService.getSpaceObjects(option);
  };

  const getSpaceObjects = (option: any) => {
    getSpaceObjectsAsync(option).then(({ items, count }: any) => {
      const { current, top } = option;
      setObjects(items);
      setPagination({ ...pagination, current, total: count, pageSize: top });
    });
  };

  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    total: 0,
  });

  useEffect(() => {
    getSpaceObjects({
      ...defaultParams,
      top: pagination.pageSize,
    });
  }, []);

  const handleSelect = (values: any) => {
    setSelectedRowKeys(values);
  };

  const mapFilterValues = (filters: any) => {
    const keys = Object.keys(filters);
    const result = keys.reduce((prev: any, curr: string) => {
      const [value]: string[] = filters[curr] || [];
      const [parrent, child] = curr.split('-');
      return (
        !child
          ? value
            ? (prev[`tolower(${parrent})`] = {
                contains: encodeURIComponent(value.toLowerCase()),
              })
            : {}
          : value
          ? (prev[parrent] = {
              [`tolower(${child})`]: {
                contains: encodeURIComponent(value.toLowerCase()),
              },
            })
          : {},
        prev
      );
    }, {});
    return result;
  };

  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    let params: any = {
      ...defaultParams,
    };

    if (filters) {
      setSelectedFilters(filters);
      const data = mapFilterValues(filters);

      params = {
        ...params,
        filter: { ...data, ...defaultParams.filter },
      };
    }

    if (pagination) {
      params = {
        ...params,
        top: pagination.pageSize,
        skip: pagination.pageSize * (pagination.current - 1),
        current: pagination.current,
      };
    }

    if (sorter && sorter.order) {
      params = {
        ...params,
        orderBy: `${sorter.columnKey.replace('-', '/')} ${sorter.order === 'ascend' ? 'asc' : 'desc'}`,
      };
    }

    getSpaceObjects({
      ...params,
    });
  };

  const onSumbit = () => {
    setLoading(true);
    if (!isSelectedAll) {
      onOk(selectedRowKeys, isSelectedAll);
      setLoading(false);
      return;
    }

    const options = {
      filter: { ...mapFilterValues(selectedFilters), ...defaultParams.filter },
      expand: defaultParams.expand,
    };
    getSpaceObjectsAsync(options)
      .then((res: any) => {
        const selectedObjects = res.map((item: any) => item.id);
        onOk(selectedObjects, false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      title={t('space.object.option.modal.title')}
      visible={isVisible}
      okText={t('space.object.option.modal.assign')}
      onOk={onSumbit}
      onCancel={onCancel}
      width={'80%'}
      centered={true}
      maskClosable={false}>
      <Table
        loading={loading}
        columns={getColumns({ t })}
        size="small"
        dataSource={objects}
        showHeader={true}
        rowKey="id"
        scroll={{ x: '100%', y: windowHeight - 100 }}
        pagination={pagination}
        rowClassName="common-table-row--pointer"
        rowSelection={{
          selectedRowKeys,
          onChange: handleSelect,
          onSelectAll: (selected) => setIsSelectedAll(selected),
        }}
        onChange={onTableChange}
      />
    </Modal>
  );
};

export default AssignOptionModal;
