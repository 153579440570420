import React, { useContext, useEffect } from 'react';
import { CHANGE_CURRENT_PAGE } from '../../constants/actionTypes/headerConstants';
import { HeaderContext } from '../../contexts/HeaderContextProvider';
import TasksListInfo from './components/TasksListInfo';
import css from '../../assets/styles/documents.module.css';
import { AppContext } from '../../contexts/AppContextProvider';
import { IAppContext } from '../../typings/IApp';
import { useTranslation } from 'react-i18next';
import i18n from '../../utils/i18n';

interface ITasks {
  isTab?: boolean;
}

const Tasks = ({ isTab = false }: ITasks) => {
  const { t } = useTranslation();
  const {
    app: { isConsumer, user },
  } = useContext<IAppContext>(AppContext);
  const { headerDispatch } = useContext(HeaderContext);

  useEffect(() => {
    headerDispatch({
      type: CHANGE_CURRENT_PAGE,
      currentPage: t('consumers.manage.tabs.tasks'),
      path: 'tasks',
    });
  }, [i18n.language]);

  return (
    <div className={isTab ? css['container'] : ''}>
      <TasksListInfo consumerId={isConsumer ? user.id : undefined} isTab={isTab} />
    </div>
  );
};

export default Tasks;
