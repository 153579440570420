import { Button, notification, Spin } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CalendarService from '../../../services/CalendarServices';

interface IPostConfirmModal {
  setClose: () => void;
  isOpen: boolean;
  currentId: number | null;
}

const service = new CalendarService();

const PostConfirmModal = (props: IPostConfirmModal) => {
  const { setClose, isOpen, currentId } = props;
  const { t } = useTranslation();
  const [fetchingData, setFetchingData] = useState(false);

  const deleteUpdate = () => {
    setFetchingData(true);
    service
      .deleteCalendarUpdate(currentId)
      .then(() => {
        notification.success({
          message: t('post.notification.deleted'),
        });
        setClose();
      })
      .catch((e) => {
        notification.error({
          message: e,
        });
        setClose();
      })
      .finally(() => setFetchingData(false));
  };

  return (
    <Spin spinning={fetchingData}>
      <Modal
        className="common-modal"
        centered
        style={{ width: '20rem' }}
        visible={isOpen}
        title={
          <div>
            <span style={{ color: '#00122D' }}>{t('post.warning.delete.title')}</span>
          </div>
        }
        footer={
          <div className="common-modal-footer">
            <Button className={`common-gray-border-btn common-secondary-btn`} onClick={setClose}>
              {t('budget.modal.cancel.btn')}
            </Button>
            <Button className={`common-green-btn common-modal-btn`} onClick={deleteUpdate}>
              {t('budget.modal.delete.btn')}
            </Button>
          </div>
        }
        closable={false}>
        <div style={{ display: 'flex', justifyContent: 'center', color: '#00122D', fontWeight: 400 }}>
          {t('post.warning.delete.description')}
        </div>
      </Modal>
    </Spin>
  );
};

export default PostConfirmModal;
