import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Input, Row, TreeSelect, Upload, notification, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import DynamicFields from '../../common/DynamicFields';
import { PlusOutlined } from '@ant-design/icons';
import ArticlesService from '../../../services/ArticlesService';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';

interface ArticleModifyFormModel {
  onCancel: () => void;
  onSetIsSaving?: (isSaving: boolean) => void;
  onSetIsLoading?: (isLoadingSaving: boolean) => void;
  callBack: (values: any) => void;
  loading?: boolean;
  articleCategories: any[];
  initialValue?: any;
}

const service: ArticlesService = new ArticlesService();

const ArticleModifyForm: React.FC<any> = ({
  onCancel,
  onSetIsSaving = () => {},
  onSetIsLoading = () => {},
  loading = false,
  callBack,
  articleCategories = [],
  initialValue = undefined,
}: ArticleModifyFormModel) => {
  const { t } = useTranslation();
  const [formArticle] = Form.useForm();

  const [articleImages, setArticleImages] = useState([]);
  const [deletedArticleImages, setDeletedArticleImages] = useState<number[]>([]);
  const [isShowArticleAddSelect, setIsShowArticleAddSelect] = useState(false);
  const [articleParams, setArticleParams] = useState<any[]>([]);

  const getArticleParams = () => {
    return service.getArticleFields().then((res: any) => {
      setArticleParams(res);
    });
  };

  const getAsyncData = async () => {
    const actions = [getArticleParams()];
    Promise.all(actions).finally(() => {
      onSetIsLoading(false);
    });
  };

  useEffect(() => {
    onSetIsLoading(true);
    getAsyncData();
  }, []);

  useEffect(() => {
    if (initialValue) {
      formArticle.setFieldsValue(initialValue);
    }
  }, [initialValue]);

  const handleShowArticleSelect = (isShow?: boolean) => {
    setIsShowArticleAddSelect(isShow === undefined ? !isShowArticleAddSelect : isShow);
  };

  const onChangeArticleImages = (info: UploadChangeParam<UploadFile<any>>) => {
    const { file, fileList }: any = info;
    const hasOwnProperty = Object.prototype.hasOwnProperty;
    setArticleImages(fileList);
    if (file?.status === 'removed' && !hasOwnProperty.call(file, 'originFileObj')) {
      setDeletedArticleImages([...deletedArticleImages, +file.uid]);
    }
  };

  const onSubmitArticle = async () => {
    const values = await formArticle.validateFields();
    const hasProperty = Object.prototype.hasOwnProperty;
    if (!hasProperty.call(values, 'errorFields')) {
      onSetIsSaving(true);
      const modelActions = (id: number) => {
        const promises: Promise<any>[] = [];
        const hasOwnProperty = Object.prototype.hasOwnProperty;
        const newImages = articleImages.filter((image: any) => hasOwnProperty.call(image, 'originFileObj'));
        if (newImages.length > 0) {
          promises.push(
            service.uploadImages({
              id,
              type: 'picture',
              files: newImages,
            }),
          );
        }
        if (deletedArticleImages.length > 0) {
          promises.push(service.deleteImages(deletedArticleImages));
        }
        return Promise.all(promises);
      };

      const { article, articleFields } = values;
      service
        .createArticle({
          ...article,
          fields: Object.entries(articleFields || []).map(([code, element]: [any, any]) => ({
            field: { code },
            value: element.value || null,
          })),
        })
        .then((res: any) => {
          const { id } = res;
          modelActions(id)
            .then(() => {
              notification.success({
                message: t('product.create.card.article.notification.create'),
              });
              callBack(res);
            })
            .catch((e) => {
              console.error(e);
              notification.error({
                message: `Error while article create`,
              });
            })
            .finally(() => onSetIsSaving(false));
        });
    }
  };

  return (
    <Form autoComplete="off" form={formArticle} layout="vertical" name="create_article">
      <Card
        title={
          <div style={{ position: 'relative' }}>
            {t('product.create.card.article.create.title')}
            <div style={{ position: 'absolute', right: '10px', top: '-3px' }}>
              <Space>
                <Button onClick={onCancel}>{t('product.create.card.article.cancel.btn')}</Button>
                <Button type="primary" onClick={onSubmitArticle}>
                  {t('product.create.card.article.save.btn')}
                </Button>
              </Space>
            </div>
          </div>
        }
        loading={loading}>
        <Row gutter={[24, 4]}>
          <Col span={12}>
            <Card title={t('product.create.article.card.basic.info')} loading={loading}>
              <Row gutter={[24, 4]}>
                <Col span={12}>
                  <Form.Item
                    label={t('product.create.article.name')}
                    name={['article', 'name']}
                    rules={[{ required: true, message: t('product.create.article.name.validation') }]}>
                    <Input className="common-animation-primary" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t('product.create.article.description')}
                    name={['article', 'description']}
                    rules={[{ required: true, message: t('product.create.article.description.validation') }]}>
                    <Input className="common-animation-primary" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={t('product.create.card.article.category')}
                    name={['article', 'category', 'id']}
                    rules={[{ required: true, message: t('product.create.card.article.category.validation') }]}>
                    <TreeSelect
                      allowClear
                      treeData={articleCategories.map((c: any) => ({
                        id: c.id,
                        value: c.id,
                        pId: c.parentId,
                        title: c.name,
                        label: c.name,
                        key: c.id,
                      }))}
                      treeDataSimpleMode
                    />
                  </Form.Item>
                </Col>
                <DynamicFields
                  name="articleFields"
                  isSelect={isShowArticleAddSelect}
                  handleSelect={handleShowArticleSelect}
                  fields={articleParams}
                />
              </Row>
            </Card>
          </Col>
          <Col span={12}>
            <Card title={t('product.create.article.card.media')} loading={loading}>
              <Row gutter={[24, 4]}>
                <Col span={24}>
                  <Form.Item label={t('product.create.article.images')} name="images">
                    <div className="dropbox">
                      <Upload.Dragger
                        listType="picture"
                        accept=".png, .jpg, .jpeg"
                        beforeUpload={() => false}
                        defaultFileList={articleImages}
                        onChange={onChangeArticleImages}
                        multiple>
                        <p className="ant-upload-drag-icon" style={{ marginTop: 0, marginBottom: 0 }}>
                          <PlusOutlined />
                        </p>
                      </Upload.Dragger>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

export default ArticleModifyForm;
