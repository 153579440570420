import React from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../../../assets/styles/faqAI.module.css';
import { Button, Form, FormInstance, Select } from 'antd';
import { AiLoadingActions, IAiTopicWithId, ILoadingAction, TopicsModel } from '../../../../../models/FAQModel';
import { aiLanguageGenerating } from '../../../../../constants/aiLanguageGenerating';
import aiRegenerateIcon from '../../../../../assets/images/aiRegenerate.svg';
import { useLessThen801 } from '../../../../../helpers/mediaDetect';

const { Option } = Select;

interface IProps {
  id: number;
  isChecked: boolean;
  form: FormInstance;
  aiTopics: IAiTopicWithId[];
  languageGenerating: aiLanguageGenerating;
  topics: TopicsModel;
  loadingAction?: ILoadingAction;
  isTopicsLoading: boolean;
  handleGenerateTopic: () => void;
}

const AITopicFields = ({
  id,
  form,
  aiTopics,
  languageGenerating,
  topics,
  loadingAction,
  handleGenerateTopic,
  isChecked,
  isTopicsLoading,
}: IProps) => {
  const { t } = useTranslation();

  const handleSelect = (selectId: number | string | undefined = undefined, field: 'en' | 'fr') => {
    const regex = /^ai\d+/;
    const formValues = form.getFieldsValue();
    if (!selectId) {
      formValues[id] = { ...formValues[id], topicEn: [], topicFr: [] };
      form.setFieldsValue({ ...formValues });
      return;
    }
    if (Number.isInteger(selectId) || regex.test(selectId!.toString())) {
      formValues[id] = { ...formValues[id], topicEn: [selectId], topicFr: [selectId] };
      form.setFieldsValue({ ...formValues });
      return;
    }
    if (selectId && selectId.toString().length > 0) {
      formValues[id] =
        languageGenerating === aiLanguageGenerating.fr_en
          ? {
              ...formValues[id],
              topicEn: field === 'en' ? [selectId] : [],
              topicFr: field === 'fr' ? [selectId] : [],
            }
          : { ...formValues[id], topicEn: [selectId], topicFr: [selectId] };
      form.setFieldsValue({ ...formValues });
      return;
    }
  };

  return (
    <div className={css.aiTopicContainer}>
      <Form.Item
        name={[id, languageGenerating !== aiLanguageGenerating.fr ? 'topicEn' : 'topicFr']}
        label={
          <span style={{ color: '#778dac' }}>
            {`${t('manage.faq.columns.topic')} ${languageGenerating === aiLanguageGenerating.fr ? 'FR' : 'EN'}`}
          </span>
        }
        rules={[{ required: isChecked, message: t('common.validation.required') }]}
        style={{ marginBottom: 16, width: '100%', marginRight: '0.7rem' }}>
        <Select
          mode="tags"
          maxTagCount={1}
          style={{ width: '100%' }}
          className="common-select common-animation-primary"
          dropdownClassName="budget-control-select-dropdown"
          placeholder={t('faq.ai.select.topic')}
          loading={isTopicsLoading}
          disabled={isTopicsLoading}
          filterOption={(value, option) => {
            return option?.children.props.children.toLowerCase().indexOf(value.toLowerCase()) > -1;
          }}
          onSelect={(selectId: number | string | undefined) => handleSelect(selectId, 'en')}
          onDeselect={() => handleSelect(undefined, 'en')}>
          {aiTopics.length > 0 &&
            aiTopics.map((topic) => (
              <Option value={topic.id} key={topic.id} className="budget-control-option">
                <span style={{ fontSize: '14px', color: '#356ede' }}>
                  {languageGenerating !== aiLanguageGenerating.fr ? topic.en.topic : topic.fr.topic}
                </span>
              </Option>
            ))}
          {topics.length > 0 &&
            topics.map((topic) => (
              <Option value={topic.id} key={topic.id} className="budget-control-option">
                <span style={{ fontSize: '14px' }}>
                  {languageGenerating !== aiLanguageGenerating.fr ? topic.topicEn : topic.topicFr}
                </span>
              </Option>
            ))}
        </Select>
      </Form.Item>
      <div
        className={css.aiTopicRightContainer}
        style={languageGenerating !== aiLanguageGenerating.fr_en ? { width: 'auto' } : {}}>
        {languageGenerating === aiLanguageGenerating.fr_en && (
          <Form.Item
            name={[id, 'topicFr']}
            label={<span style={{ color: '#778dac' }}>{t('manage.faq.columns.topic')} FR</span>}
            rules={[{ required: isChecked, message: t('common.validation.required') }]}
            style={{ marginBottom: 16, width: '100%', marginRight: '0.9rem' }}>
            <Select
              mode="tags"
              maxTagCount={1}
              style={{ width: '100%' }}
              className="common-select common-animation-primary"
              dropdownClassName="budget-control-select-dropdown"
              placeholder={t('faq.ai.select.topic')}
              loading={isTopicsLoading}
              disabled={isTopicsLoading}
              filterOption={(value, option) => {
                return option?.children.props.children.toLowerCase().indexOf(value.toLowerCase()) > -1;
              }}
              onSelect={(selectId: number | string | undefined) => handleSelect(selectId, 'en')}
              onDeselect={() => handleSelect(undefined, 'en')}>
              {aiTopics.length > 0 &&
                aiTopics.map((topic) => (
                  <Option value={topic.id} key={topic.id} className="budget-control-option">
                    <span style={{ fontSize: '14px', color: '#356ede' }}>{topic.fr.topic}</span>
                  </Option>
                ))}
              {topics.length > 0 &&
                topics.map((topic) => (
                  <Option value={topic.id} key={topic.id} className="budget-control-option">
                    <span style={{ fontSize: '14px' }}>{topic.topicFr}</span>
                  </Option>
                ))}
            </Select>
          </Form.Item>
        )}
        <Button
          className={css.aiRegenerateBtn}
          style={{ marginTop: 30 }}
          onClick={handleGenerateTopic}
          loading={
            loadingAction &&
            loadingAction.owner === AiLoadingActions.generateTopics &&
            loadingAction.index === id &&
            loadingAction.isloading
          }
          disabled={
            loadingAction &&
            (loadingAction.owner === AiLoadingActions.generateTopics
              ? loadingAction.index !== id && loadingAction.isloading
              : loadingAction.isloading)
          }>
          <img src={aiRegenerateIcon} style={{ marginRight: '1rem' }} />
          {t('faq.step2.btn.generate')}
        </Button>
      </div>
    </div>
  );
};

export default AITopicFields;
