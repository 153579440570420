import { PlusCircleOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Image,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
  Space,
  Table,
  Upload,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../assets/styles/budget.module.css';
import config from '../../../config';
import { ConsumerModel } from '../../../models/ConsumerModel';
import ProjectsService from '../../../services/ProjectsService';
import ConfirmUploadForm from './ConfirmUploadForm';
import { getColumns } from './FileManagementCreateColumns';
import { CompanyModel, PaymentInfo } from '../../../models/CompaniesModel';
import ListPageServices from '../../../services/PropertiesService';
import { PropertiesModel } from '../../../models/PropertyModel';
import UsersService from '../../../services/UsersService';
import CFCService from '../../../services/CFCService';
import { ICFC, ICFCitem } from '../../../models/CFCModel';
import i18n from '../../../utils/i18n';
import AmendmentQuotes from '../../Amendment/components/create/components/AmendmentQuotes';
import AmendmentContractAndAmendments from '../../Amendment/components/create/components/AmendmentContractAndAmendments';
import { convertToCoins, convertToCurrency, convertToPriceWithSpace } from '../../../helpers/convertToPrice';
import { useLessThen801 } from '../../../helpers/mediaDetect';
import CustomSignFlow from '../../../services/CustomSignFlow';
import { IFlow, IFlowStep, ISignatoryFlow } from '../../../models/CustomSignFlowModel';
import { generateFilterfromObj } from '../../../helpers/generateFIlterfromObj';
import { FilterTypes } from '../../../constants/filterTypes';
import { isEmptyObject } from '../../../helpers/isEmptyObject';
import { ICreateQuoteModel } from '../../../models/QuotesModel';
import { NewBudgetModel } from '../../../models/BudgetModel';
import { NewDocumentsModel } from '../../../models/DocumentsModel';
import { ICreateAmend } from '../../../models/AmendmentModel';
import { IUserEasy } from '../../../models/UserModel';
import { IResponceData } from '../../../typings/IServiceOptions';
import useGetPageTypeConfig from '../hooks/useGetPageTypeConfig';

interface QuoteUploadProps {
  isOpenCreate: boolean;
  user: any;
  handleOpenStatus: ({ itemId }: { itemId: number }) => void;
  closeCreate: () => void;
}
const { TextArea } = Input;
const { Option } = Select;

const projectsService = new ProjectsService();
const propertyService = new ListPageServices();
const userService = new UsersService();
const cfcService = new CFCService();
const serviceFlow = new CustomSignFlow();

const CreateFileManagement = (props: QuoteUploadProps) => {
  const { isOpenCreate, closeCreate, user, handleOpenStatus } = props;
  const { t } = useTranslation();
  const { isQuotes, isAmendments, isInvoices } = useGetPageTypeConfig();
  const [dataSource, setDataSource] = useState<{ index: number; quantity: number; price: number }[]>([]);
  const [isOpenIntroduction, setIsOpenIntroduction] = useState(false);
  const [isOpenIncluding, setIsOpenIncluding] = useState(false);
  const [isOpenQuoteDate, setIsOpenQuoteDate] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  const [project, setProject] = useState<any>(null);
  const [introductionText, setIntroductionText] = useState('');
  const [includingText, setIncludingText] = useState('');
  const [subtotal, setSubtotal] = useState(0);
  const [subtotal2, setSubtotal2] = useState(0);
  const [vat, setVat] = useState(7.7);
  const [fees, setFees] = useState(18);
  const [createdBody, setCreatedBody] = useState<
    ICreateQuoteModel | NewBudgetModel | NewDocumentsModel | ICreateAmend | null
  >(null);
  const [total, setTotal] = useState(0);
  const [itemKey, setItemKey] = useState(0);
  const [qouteDate, setQuoteDate] = useState<moment.Moment | null>(moment());
  const [deadline, setDeadline] = useState<moment.Moment | null>(moment().add(10, 'days'));
  const [paymentDate, setPaymentDate] = useState<moment.Moment | null>(moment());
  const [fileAttachmentList, setFileAttachmentList] = useState([] as any[]);
  const [paymentsProfile, setPaymentsProfile] = useState<PaymentInfo[] | null>(null);
  const [paymentProfile, setPaymentProfile] = useState<PaymentInfo | null>(null);
  const [selectCompany, setSelectCompany] = useState<CompanyModel>();
  const [properties, setProperties] = useState<PropertiesModel>([]);
  const [consumers, setConsumers] = useState<ConsumerModel[]>([]);
  const [propertyId, setPropertyId] = useState<string | null>(null);
  const [CFC, setCFC] = useState<ICFC[]>([]);
  const [CFCitems, setCFCitems] = useState<ICFCitem[]>([]);
  const [isNeedUpdateSum, setIsNeedUpdateSum] = useState(false);
  const [selectConsumers, setSelectConsumers] = useState<ConsumerModel[]>([]);
  const [flows, setFlows] = useState<IFlow[]>([]);
  const [flowSteps, setFlowSteps] = useState<IFlowStep[]>([]);

  const [projectId, setProjectId] = useState<null | number>(null);
  const [searchValue, setSearchValue] = useState<null | string>(null);
  const [currentTimeout, setCurrentTimeout] = useState<NodeJS.Timeout | null>(null);
  const [agents, setAgents] = useState<IUserEasy[]>([]);
  const [isLastAgentsReq, setIsLastAgentsReq] = useState(false);
  const [fetchingUserData, setFetchingUserData] = useState(false);
  const [paginationAgents, setPaginationAgents] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    skip: 0,
  });

  const [fileContract, setFileContract] = useState([] as any[]);
  const [fileQuotesList, setFileQuotesList] = useState([] as any[]);
  const [fileAmendmentsList, setFileAmendmentsList] = useState([] as any[]);

  const [form] = Form.useForm();

  useEffect(() => {
    if (selectCompany?.logo) {
      setLogoUrl(`${config.storeUrl}/${selectCompany.logo}`);
    } else {
      setLogoUrl(null);
    }
  }, [selectCompany]);

  useEffect(() => {
    if (paymentProfile) {
      setSelectCompany(
        project.companies.find((company: CompanyModel) =>
          company.paymentInfo.find((payment: PaymentInfo) => payment.id === paymentProfile.id),
        ),
      );
    }
  }, [paymentProfile]);

  useEffect(() => {
    const subtotalCoins = convertToCoins(subtotal);
    const subtotal2Coins = convertToCoins(subtotal2);
    setSubtotal2(
      convertToCurrency(
        Math.floor(subtotalCoins + (fees / 100) * subtotalCoins < 0 ? 0 : subtotalCoins + (fees / 100) * subtotalCoins),
      ),
    );
    setTotal(
      convertToCurrency(
        Math.floor(
          subtotal2Coins + (vat / 100) * subtotal2Coins < 0 ? 0 : subtotal2Coins + (vat / 100) * subtotal2Coins,
        ),
      ),
    );
  }, [vat, subtotal, fees, subtotal2]);

  useEffect(() => {
    if (projectId) {
      getAgents();
      serviceFlow
        .getFlows({
          filters: generateFilterfromObj({
            'projects/id': {
              type: FilterTypes.SELECT,
              value: +projectId,
            },
            'document_types/code': {
              type: FilterTypes.SELECT,
              value: isQuotes ? 'quotes' : isAmendments ? 'amendments' : isInvoices ? 'invoices' : null,
            },
          }),
        })
        .then((flows: IFlow[]) => {
          setFlows(flows);
          const defaultFlowId = flows.find((item) => item.isDefault)?.id;
          form.setFieldsValue({ flow: defaultFlowId || '' });
          setFlowSteps(
            flows
              .find((item) => item.id === defaultFlowId)
              ?.flowsSteps.map((item) => ({ signatoriesName: item.step.signatoriesName, code: item.step.code })) || [],
          );
        });
    }
  }, [projectId]);

  useEffect(() => {
    if (searchValue || searchValue === '') {
      getAgents();
    }
  }, [searchValue]);

  const getAgents = () => {
    const optionUser: any = {
      count: true,
      top: paginationAgents.pageSize,
      current: paginationAgents.current,
      skip: paginationAgents.pageSize * (paginationAgents.current - 1) || 0,
      fullName: searchValue || '',
      projectId: [projectId],
      roles: ['agent', 'admin', 'super_agent'],
    };

    setFetchingUserData(true);
    userService
      .getEasyUsers(optionUser)
      .then((res: IResponceData<IUserEasy>) => {
        const { count, items } = res;
        const { current, top } = optionUser;
        setPaginationAgents({ ...paginationAgents, current: current + 1, total: count, pageSize: top });
        const ids = Array.from(new Set([...agents, ...items].map((user) => user.id)));
        const uniqUsers = ids.map((id) => [...agents, ...items].find((customer) => customer.id === id));
        setAgents(uniqUsers as IUserEasy[]);
        setIsLastAgentsReq(current * top >= count);
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingUserData(false));
  };

  const resetUsers = () => {
    //agents
    setPaginationAgents({
      current: 1,
      pageSize: 10,
      total: 0,
      skip: 0,
    });
    setIsLastAgentsReq(false);
    setAgents([]);
    //consumers
    setConsumers([]);

    const fieldsValues: any = {};
    const fieldValue = form.getFieldsValue();
    flows
      .find((el) => el.id === fieldValue.flow)
      ?.flowsSteps.map((item) => ({
        stepCode: item.stepCode,
        signatoriesName: item.step.signatoriesName,
      }))
      .forEach((el) => {
        fieldsValues[el.stepCode] = [];
      });

    if (!isEmptyObject(fieldsValues)) {
      form.setFieldsValue(fieldsValues);
    }
  };

  const onSubmit = (value: any) => {
    if (isAmendments ? value.quotes.filter((item: any) => item.name === null).length > 0 : !value.items) {
      notification.error({
        message: t('common.no.items'),
      });
      return;
    }

    let currentConsumersId: any[] = [];
    if (isQuotes || isAmendments) {
      const consumerFieldsName = flowSteps.filter((item) => item.code.split('_').includes('client')) ?? [];
      consumerFieldsName?.forEach((item) => {
        const users = value[item.code];
        if (users && users.length > 0) {
          currentConsumersId.push(...users);
        }
      });
    }
    const consumerForPayment = consumers.find((item) => item.id === currentConsumersId[currentConsumersId.length - 1]);
    if (
      isAmendments &&
      value.flow !== '' &&
      (!consumerForPayment?.paymentInfo?.id ||
        !consumerForPayment?.paymentInfo?.firstName ||
        !consumerForPayment?.paymentInfo?.lastName)
    ) {
      notification.error({
        message: t('common.no.user.paymentProfile'),
      });
      return;
    }

    const body = {
      number: value.quoteId,
      description: value.description,
      property: {
        id: propertyId && +propertyId,
      },
      companyPaymentInfo: {
        id: paymentProfile?.id,
      },
      currency: {
        code: project?.defaultCurrency?.code || t('budget.currency').toLowerCase(),
      },
      statusV2: {
        code: 'new',
      },
      flow: value.flow ? { id: value.flow } : null,
    } as any;

    const signatoriesFlow: ISignatoryFlow[] = [];
    const currentFlow = flows
      ?.find((item) => value.flow && item.id === +value.flow)
      ?.flowsSteps?.sort((a, b) => a.ord - b.ord);

    currentFlow &&
      currentFlow.forEach((item) => {
        value[item.step.code].forEach((id: number) => {
          signatoriesFlow.push({ consumerId: id, stepCode: item.stepCode });
        });
      });

    if (signatoriesFlow.length > 0) {
      body['signatories'] = signatoriesFlow;
    }

    if (isQuotes) {
      body.name = selectConsumers.map((user) => `${user.firstName || ''} ${user.lastName || ''}`).join(' & ');
      body.quoteDate = moment(qouteDate).format('YYYY-MM-DD');
      body.deadline = moment(value.deadline).utc().format('YYYY-MM-DD');
      body.comment = value.comment;
    }

    if (isAmendments) {
      body.vat = 7.7;
      body.deltaWithVat = (+value.currentSum.sumWithVat * 100).toFixed(0);
      body.fees = 18;
      body.objectText = value.objectText;
      body.consumersList = selectConsumers.map((user) => `${user.firstName || ''} ${user.lastName || ''}`).join(' & ');
      body.deltaWithFees = (+value.currentSum.sumWithFees * 100).toFixed(0);
      body.sumWithVat = (+value.totalFullSum.sumWithVat * 100).toFixed(0);
      body.sumWithFees = (+value.totalFullSum.sumWithFees * 100).toFixed(0);
      body.name = value.amedmentName;
      body.date = moment(qouteDate).format('YYYY-MM-DD');
      body.deadline = moment(value.deadline).utc().format('YYYY-MM-DD');
      body.signInfo = value.signInfo;
      body.comment = value.comment;
      body.contract = {
        id: value.contract.id || undefined,
        title: value.contract.name || undefined,
        sumWithFees: (+value.contract.sumWithFees * 100).toFixed(0),
        sumWithVat: (+value.contract.sumWithVat * 100).toFixed(0),
        isNeedCreate: fileContract.length > 0,
      };
      body.quotes = value.quotes.map((item: any) => ({
        id: item.id || undefined,
        number: item.name,
        guid: item.uid || undefined,
        sumWithFees: (+item.sumWithFees * 100).toFixed(0),
        sumWithVat: (+item.sumWithVat * 100).toFixed(0),
      }));
      if (value.amendments.length > 1 || value.amendments[0].name) {
        body.amendments = value.amendments.map((item: any) => ({
          id: item.id || undefined,
          number: item.fakeName || item.name,
          guid: item.uid || undefined,
          deltaWithFees: (+item.sumWithFees * 100).toFixed(0),
          deltaWithVat: (+item.sumWithVat * 100).toFixed(0),
        }));
      }
      if (consumerForPayment) {
        body['consumerPaymentInfo'] = {
          id: consumerForPayment?.paymentInfo.id,
        };
      }
    } else {
      body.items = value.items?.map((item: any) => {
        const itemBody: any = {
          taskName: `${item.name}${item.comment ? ` (${item.comment})` : ''}`,
          quantity: +item.quantity,
          price: +(+item.price * 100).toFixed(0),
          total: +(+item.price * +item.quantity * 100).toFixed(0),
        };
        if (item.cfc) {
          itemBody[
            CFC.map((cfc) => cfc.code.toString().toLowerCase()).includes(item.cfc.toLowerCase()) && item.id
              ? 'cfcCodeItem'
              : 'cfcCodeItemCustom'
          ] = {
            id: item.id,
            description: item.name || '',
            cfc: { code: item.cfc },
            unit: item.unit || '-',
          };
          if (!CFC.map((cfc) => cfc.code.toString().toLowerCase()).includes(item.cfc.toLowerCase()) && !item.id) {
            delete itemBody.cfcCodeItemCustom.id;
          }
        }
        return itemBody;
      });
      if (!isQuotes && !isInvoices) {
        body.isAmendment = value.isAmendment;
      }
      body.sum = +(+subtotal * 100).toFixed(0);
      body.fees = fees;
      body.sumWithFees = +(+subtotal2 * 100).toFixed(0);
      body.vat = vat;
      body.sumWithVat = +(total * 100).toFixed(0);
    }

    if (isInvoices) {
      body.name = selectConsumers.map((user) => `${user.firstName || ''} ${user.lastName || ''}`).join(' & ');
      body.paymentDate = moment(value.paymentDate);
      body.invoiceDate = moment().format('YYYY-MM-DD');
      body.comment = value.comment;
    }

    if (introductionText) {
      body.introduction = introductionText;
    }
    if (includingText) {
      body.concluding = includingText;
    }

    setIsOpenConfirm(true);
    setCreatedBody(body);
  };

  const addItem = () => {
    if (project?.id) {
      setCFCitems([]);
      setDataSource([...dataSource, { index: itemKey, quantity: 1, price: 0 }]);
      setItemKey(itemKey + 1);
    } else {
      notification.warning({
        message: t('properties.notification.select.project'),
      });
    }
  };

  useEffect(() => {
    if (isNeedUpdateSum && form.getFieldsValue() && form.getFieldsValue()?.items?.length > 0) {
      setIsNeedUpdateSum(false);
      onFormChange(null, form.getFieldsValue());
    }
  }, [isNeedUpdateSum]);

  useEffect(() => {
    if (consumers.length > 0 && form.getFieldValue('flow') === '') {
      return setSelectConsumers(consumers.filter((user) => user.id));
    }
    setSelectConsumers([]);
  }, [consumers, form]);

  const onFormChange = (value: any, allValues: any) => {
    if (allValues.items) {
      let sum = 0;
      allValues.items.forEach((item: any) => {
        if (item.price && item.quantity) {
          sum = sum + +item.price * +item.quantity;
        } else if (!item.price) {
          return;
        } else {
          sum = +(+item.price);
        }
      });
      setSubtotal(sum < 0 ? 0 : sum);
    }

    const consumerFieldsName = flowSteps.filter((item) => item.code.split('_').includes('client')) ?? [];
    let currentConsumers: any[] = [];
    consumerFieldsName?.forEach((item) => {
      const users = allValues[item.code];
      if (users && users.length > 0) {
        currentConsumers.push(...users);
      }
    });
    const uniqCustomers = (currentConsumers.map((id) => consumers.find((customer) => customer.id === id)) ??
      []) as ConsumerModel[];
    setTimeout(() => {
      setSelectConsumers(allValues.flow === '' ? consumers.filter((user) => user.id) : uniqCustomers);
    }, 1);
  };

  const handleFileAttachment = (info: any) => {
    setFileAttachmentList(info.fileList);
  };

  const onChangePaymentProfile = (idProfile: number) => {
    if (paymentsProfile && paymentsProfile.length > 0) {
      setPaymentProfile(paymentsProfile.find((el: PaymentInfo) => el.id === idProfile) || null);
    }
  };

  const getProperties = (key: string) => {
    propertyService
      .getProperties(key)
      .then((value: PropertiesModel) => setProperties(value.filter((prop) => prop.childrens.length === 0)))
      .catch((e: any) =>
        notification.error({
          message: e.message,
        }),
      );
  };

  const getConsumers = (propertyId: number) => {
    userService
      .getConsumersByProperty(propertyId)
      .then((value: any) => setConsumers(value))
      .catch((e: any) =>
        notification.error({
          message: e.message,
        }),
      );
  };

  const getCFC = (projectKey: string, option = {}) => {
    cfcService
      .getCFC(projectKey, option)
      .then((res: any) => {
        setCFC(res);
      })
      .catch((e) => {
        notification.error({
          message: e,
        });
      });
  };

  const getCFCitems = (code: string) => {
    const projectKey = user.projects.find((userProject: any) => userProject.id === project.id).key;
    cfcService
      .getCFCitems(code, projectKey)
      .then((value) => setCFCitems(value))
      .catch((e) => {
        notification.error({
          message: e,
        });
      });
  };

  const changeProject = (id: number) => {
    resetUsers();
    setProjectId(id);
    setPropertyId(null);
    const fieldValue = form.getFieldsValue();
    form.setFieldsValue({ ...fieldValue, property: null, consumers: [] });
    getProject(id);
    const key = user.projects.find((project: any) => project.id === id).key;
    getProperties(key);
    getCFC(key);
  };

  const changeProperty = (propertyId: number) => {
    resetUsers();
    setPropertyId(propertyId.toString() || null);
    const fieldValue = form.getFieldsValue();
    form.setFieldsValue({ ...fieldValue, consumers: [] });
    getConsumers(propertyId);
    getAgents();
  };

  const getProject = (projectId: number) => {
    projectsService
      .getProjectById(+projectId)
      .then((value) => {
        setProject(value);
        if (!value.defaultPaymentInfoId || value.companies.length === 0) {
          notification.error({
            message: `${t('project.not.fill.payment')} ${value.title}`,
          });
        }
        if (value.companies.length > 0) {
          setPaymentsProfile(
            value.companies.reduceRight((prev: PaymentInfo[], curr: CompanyModel) => {
              prev.push(...curr.paymentInfo);
              return prev;
            }, []),
          );
          setSelectCompany(
            value.companies.find((company: CompanyModel) =>
              company.paymentInfo.find((payment: PaymentInfo) => payment.id === value.defaultPaymentInfoId),
            ),
          );
        }
        form.setFieldsValue({ defaultPaymentInfoId: value.defaultPaymentInfoId });
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      );
  };

  useEffect(() => {
    if (paymentsProfile && paymentsProfile.length > 0 && project.defaultPaymentInfoId && !paymentProfile) {
      setPaymentProfile(paymentsProfile.find((el: PaymentInfo) => el.id === project.defaultPaymentInfoId) || null);
    }
  }, [paymentsProfile]);

  const onSelectFlow = (value: number | '') => {
    form.setFieldsValue({ status: 'new' });
    setSelectConsumers([]);
    setFlowSteps(
      flows
        .find((item) => item.id === value)
        ?.flowsSteps.map((item) => ({ signatoriesName: item.step.signatoriesName, code: item.step.code })) || [],
    );
  };

  return (
    <Drawer className="upload-form-drawer" visible={isOpenCreate} placement="top" closable={false}>
      <Form autoComplete="off" onFinish={onSubmit} form={form} onValuesChange={onFormChange}>
        {isOpenConfirm && createdBody && (
          <ConfirmUploadForm
            isOpenConfirm={isOpenConfirm}
            closeCreate={closeCreate}
            setIsOpenConfirm={setIsOpenConfirm}
            createdBody={createdBody}
            fileAttachmentList={fileAttachmentList}
            fileAmendmentsList={fileAmendmentsList}
            fileQuotesList={fileQuotesList}
            fileContract={fileContract}
            handleOpenStatus={handleOpenStatus}
          />
        )}
        <Form.Item>
          <div className={css['btns-container']}>
            <Button className={`${css['return-btn']} common-secondary-btn`} onClick={closeCreate}>
              {t('common.return.btn')}
            </Button>
            <Button
              className={`${css['upload-btn']} common-primary-btn`}
              style={{ marginLeft: '1rem' }}
              htmlType="submit">
              {t('common.title.create')}
            </Button>
          </div>
        </Form.Item>
        <div className={css['content-container']}>
          <div className={css['upload-info-container']}>
            <div className={css['invoice-info-container']}>
              <span style={{ fontWeight: 800, fontSize: '20px', marginBottom: '1rem' }}>
                {isQuotes
                  ? t('quotes.upload.invoice.title')
                  : isAmendments
                  ? t('amendment.create.title')
                  : t('budget.upload.invoice.title')}
              </span>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: '1rem',
                }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>
                    {isQuotes
                      ? t('quotes.upload.overview.fields.quote')
                      : isAmendments
                      ? t('amendment.id')
                      : t('budget.upload.overview.fields.invoice')}
                  </span>
                  <Form.Item
                    name="quoteId"
                    style={{ margin: 0, marginLeft: '1rem' }}
                    rules={[{ required: true, message: t('common.validation.required') }]}>
                    <Input
                      onPressEnter={(e) => e.preventDefault()}
                      style={{ width: '200px' }}
                      className="consumer-form-input common-animation-primary"
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item name="quoteDate" initialValue={moment()} style={{ marginBottom: 0 }}>
                    <>
                      {!isOpenQuoteDate && (
                        <span onClick={() => setIsOpenQuoteDate(true)}>
                          <span>
                            {isQuotes
                              ? t('quote.upload.overview.fields.date')
                              : isAmendments
                              ? t('amendment.date')
                              : t('budget.upload.overview.fields.date')}
                          </span>
                          {` ${moment(qouteDate).format('DD.MM.YYYY') || moment().format('DD.MM.YYYY')}`}
                        </span>
                      )}
                      {isOpenQuoteDate && (
                        <DatePicker
                          getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
                          onBlur={() => setIsOpenQuoteDate(false)}
                          format="DD.MM.YYYY"
                          onChange={(value) => setQuoteDate(value)}
                          suffixIcon={<div className={'calendar-img'} style={{ width: '12px', height: '12px' }}></div>}
                          className="common-date-picker common-animation-primary"
                        />
                      )}
                    </>
                  </Form.Item>
                  <div>
                    <span>
                      <span>{t(isInvoices ? 'budget.table.date' : 'fileManagement.table.deadline')}</span>
                      {` ${
                        moment(isInvoices ? paymentDate : deadline).format('DD.MM.YYYY') ||
                        moment().format('DD.MM.YYYY')
                      }`}
                    </span>
                  </div>
                </div>
              </div>
              {isAmendments ? (
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                  <span>{t('amendment.name')}</span>
                  <Form.Item
                    name="amedmentName"
                    style={{ margin: 0, marginLeft: '1rem' }}
                    rules={[{ required: true, message: t('common.validation.required') }]}>
                    <Input
                      onPressEnter={(e) => e.preventDefault()}
                      style={{ width: '200px' }}
                      className="consumer-form-input common-animation-primary"
                    />
                  </Form.Item>
                </div>
              ) : (
                <div className={css['upload-user-project-container']} style={{ marginBottom: '1rem' }}>
                  {t('consumers.create.name')}:
                  <span style={{ color: '#778dac', marginLeft: '1rem' }}>
                    {selectConsumers.map((user) => `${user.firstName || ''} ${user.lastName || ''}`).join(' & ')}
                  </span>
                </div>
              )}
              {!isOpenIntroduction &&
                (introductionText ? (
                  <span onClick={() => setIsOpenIntroduction(true)}>{introductionText}</span>
                ) : (
                  <Button onClick={() => setIsOpenIntroduction(true)} className={css['upload-add-btn']}>
                    <PlusCircleOutlined />
                    {t('budget.invoice.form.optional')}
                  </Button>
                ))}
              <Form.Item name="introductionText">
                {isOpenIntroduction && (
                  <TextArea
                    onBlur={() => setIsOpenIntroduction(false)}
                    onChange={(value) => setIntroductionText(value.target.value)}
                    className="consumer-form-input common-animation-primary"
                  />
                )}
              </Form.Item>
              {isAmendments && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <span className={css['upload-steps-quoteId']}>{t('amendment.objectText')}</span>
                  <Form.Item
                    name="objectText"
                    initialValue={t('ammendment.template.object')}
                    style={{ margin: 0, marginLeft: '1rem' }}
                    rules={[{ required: true, message: t('common.validation.required') }]}>
                    <Input
                      style={{ width: useLessThen801() ? '100%' : '400px' }}
                      className="consumer-form-input common-animation-primary"
                      onPressEnter={(e) => e.preventDefault()}
                    />
                  </Form.Item>
                </div>
              )}
              {isAmendments ? (
                <div className={css['invoice-info-container']} style={{ marginBottom: '1rem' }}>
                  <Row>
                    <Col flex={'auto'}></Col>
                    <Col style={{ display: 'flex', justifyContent: 'center' }} flex={'0 0 180px'}>
                      <span style={{ fontWeight: 700 }}>HT</span>
                    </Col>
                    <Col style={{ display: 'flex', justifyContent: 'center' }} flex={'0 0 180px'}>
                      <span style={{ fontWeight: 700 }}>TTC</span>
                    </Col>
                  </Row>
                  <AmendmentQuotes
                    form={form}
                    propertyId={propertyId}
                    fileQuotesList={fileQuotesList}
                    setFileQuotesList={setFileQuotesList}
                    currency={project?.defaultCurrency?.description}
                  />
                  <AmendmentContractAndAmendments
                    form={form}
                    propertyId={propertyId}
                    setFileContract={setFileContract}
                    fileAmedmentsList={fileAmendmentsList}
                    setFileAmedmentsList={setFileAmendmentsList}
                    currency={project?.defaultCurrency?.description}
                  />
                </div>
              ) : (
                <>
                  <Table
                    columns={getColumns({
                      t,
                      form,
                      dataSource,
                      setDataSource,
                      CFC,
                      getCFCitems,
                      CFCitems,
                      setIsNeedUpdateSum,
                      currency: project?.defaultCurrency?.description,
                    })}
                    className="upload-invoice-table"
                    rowClassName="common-table-row--pointer"
                    dataSource={dataSource}
                    pagination={false}
                    key="index"
                    rowKey={(record) => record.index}
                    showHeader={true}
                    size="small"
                  />
                  <Button onClick={() => addItem()} className={css['upload-add-btn']}>
                    <PlusCircleOutlined />
                    {t('budget.invoice.form.add.item')}
                  </Button>
                </>
              )}
            </div>
            {!isAmendments && (
              <div className={css['upload-cost-container']}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '30%' }}>
                  <span>{t('budget.invoice.form.subtotal.1')}:</span>
                  <span>{`${project?.defaultCurrency?.description || ''} ${convertToPriceWithSpace(subtotal)}`}</span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    minWidth: i18n.language === 'fr-FR' ? '64.8%' : '44.8%',
                    marginBottom: '0.5rem',
                  }}>
                  <Form.Item
                    name="fees"
                    label={`${t('budget.upload.overview.fields.fees')} (%)`}
                    rules={[{ required: true, message: t('common.validation.required') }]}
                    initialValue={18}
                    style={{ marginBottom: 0 }}>
                    <InputNumber
                      onPressEnter={(e) => e.preventDefault()}
                      min={0}
                      style={{ width: '70px', marginLeft: '0.5rem' }}
                      onChange={(e) => {
                        setFees(+e);
                      }}
                      className="consumer-form-input common-animation-primary"
                    />
                  </Form.Item>
                  <span>{`${project?.defaultCurrency?.description || ''} ${convertToPriceWithSpace(
                    (fees / 100) * subtotal,
                  )}`}</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '30%' }}>
                  <span>{t('budget.invoice.form.subtotal.2')}:</span>
                  <span>{`${project?.defaultCurrency?.description || ''} ${convertToPriceWithSpace(subtotal2)}`}</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '30%' }}>
                  <Form.Item
                    name="vat"
                    label={<span>{`${t('budget.upload.overview.fields.vat')} (%)`}</span>}
                    rules={[{ required: true, message: t('common.validation.required') }]}
                    initialValue={7.7}
                    style={{ marginBottom: 0 }}>
                    <InputNumber
                      onPressEnter={(e) => e.preventDefault()}
                      min={0}
                      style={{ width: '70px', marginLeft: '0.5rem' }}
                      onChange={(e) => {
                        setVat(+e);
                      }}
                      className="consumer-form-input common-animation-primary"
                    />
                  </Form.Item>
                  <span>{`${project?.defaultCurrency?.description || ''} ${convertToPriceWithSpace(
                    (vat / 100) * subtotal2,
                  )}`}</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '30%' }}>
                  <span>{t('budget.invoice.form.total')}</span>
                  <span>{`${project?.defaultCurrency?.description || ''} ${convertToPriceWithSpace(total)}`}</span>
                </div>
              </div>
            )}
            {!isOpenIncluding &&
              (includingText ? (
                <span onClick={() => setIsOpenIncluding(true)}>{includingText}</span>
              ) : (
                <Button onClick={() => setIsOpenIncluding(true)} className={css['upload-add-btn']}>
                  <PlusCircleOutlined />
                  {t('budget.invoice.form.concluding')}
                </Button>
              ))}
            <Form.Item name="includingText">
              {isOpenIncluding && (
                <TextArea
                  onBlur={() => setIsOpenIncluding(false)}
                  onChange={(value) => setIncludingText(value.target.value)}
                  className="consumer-form-input common-animation-primary"
                />
              )}
            </Form.Item>
            <div
              className="invoice-select-container-paymentProfile"
              style={{ display: 'flex', alignItems: 'center', marginBottom: '24px' }}>
              <span>{t('budget.payment.profile.select')}</span>
              <Form.Item name="defaultPaymentInfoId">
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
                  className="consumers-control-select common-animation-primary"
                  onChange={onChangePaymentProfile}
                  style={{ marginLeft: '1rem' }}>
                  {paymentsProfile &&
                    paymentsProfile
                      .sort((a, b) => a.accountHolder.toLowerCase().localeCompare(b.accountHolder.toLowerCase()))
                      .map((item: PaymentInfo) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.accountHolder}
                        </Select.Option>
                      ))}
                </Select>
              </Form.Item>
            </div>
            <div className={css['upload-user-project-container']}>
              {isQuotes ? (
                <div className={css['upload-user-container']}>
                  {logoUrl && (
                    <Avatar
                      src={
                        <>
                          <Image src={logoUrl as string} preview={false} />
                          <div className="logo-mask">
                            <Space>
                              <Upload accept=".png, .jpg, .jpeg" showUploadList={false} customRequest={() => {}} />
                            </Space>
                          </div>
                        </>
                      }
                      size={60}
                      className="consumer-avatar"
                    />
                  )}
                  <span style={{ fontWeight: 'bold', color: '#778dac' }}>{selectCompany?.companyName || ''}</span>
                  <span style={{ color: '#778dac' }}>{`${selectCompany?.street || ''}`}</span>
                  <span style={{ color: '#778dac' }}>{`${selectCompany?.zip || ''} ${selectCompany?.city || ''} ${
                    selectCompany?.state || ''
                  } ${selectCompany?.country || ''}`}</span>
                  <span style={{ color: '#778dac' }}>{selectCompany?.email || ''}</span>
                  <span style={{ color: '#778dac' }}>{`${paymentProfile?.taxId || ''} ${
                    paymentProfile?.vatId || ''
                  }`}</span>
                </div>
              ) : (
                <div className={css['upload-user-container']}>
                  {logoUrl && (
                    <Avatar
                      src={
                        <>
                          <Image src={logoUrl as string} preview={false} />
                          <div className="logo-mask">
                            <Space>
                              <Upload accept=".png, .jpg, .jpeg" showUploadList={false} customRequest={() => {}} />
                            </Space>
                          </div>
                        </>
                      }
                      size={60}
                      className="consumer-avatar"
                    />
                  )}
                  <span style={{ fontWeight: 'bold', color: '#778dac' }}>{t('budget.invoice.form.bank.acc')}</span>
                  <span style={{ color: '#778dac' }}>{`Receiver: ${paymentProfile?.accountHolder || ''}`}</span>
                  <span style={{ color: '#778dac' }}>{paymentProfile?.bankName || ''}</span>
                  <span style={{ color: '#778dac' }}>{`Country of bank: ${paymentProfile?.countryOfBank || ''}`}</span>
                  <span style={{ color: '#778dac' }}>{`IBAN: ${paymentProfile?.iban || ''}`}</span>
                  <span style={{ color: '#778dac' }}>{`SWIFT/BIC: ${paymentProfile?.swift || ''}`}</span>
                </div>
              )}
            </div>
          </div>
          <div className={css['upload-steps-right-col']}>
            <div className={css['upload-steps-item']}>
              <div className={css['upload-steps-container']}>
                <Col span={24}>
                  <Form.Item
                    name="project"
                    colon={false}
                    rules={[{ required: true, message: t('common.validation.required') }]}
                    label={<span style={{ color: '#778dac' }}>{t('tickets.create.project')}</span>}
                    className={css['form-item']}
                    labelAlign="left">
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
                      className="common-select common-animation-primary"
                      dropdownClassName="documents-control-select-dropdown"
                      onChange={changeProject}
                      placeholder={t('tickets.create.placeholder.project')}
                      suffixIcon={
                        <div
                          className={`arrow-drop-img ${css['arrow-drop']}`}
                          style={{ width: '8px', height: '4px' }}></div>
                      }>
                      {user &&
                        user.projects &&
                        user.projects
                          .sort((a: any, b: any) => a.title?.toLowerCase().localeCompare(b.title?.toLowerCase()))
                          .map((project: any) => (
                            <Option
                              value={project.id}
                              key={project.id}
                              className="documents-control-option"
                              disabled={!project.defaultPaymentInfoId || project.companies?.length === 0}>
                              <span style={{ fontSize: '14px', fontWeight: 600 }}>{project.title}</span>
                            </Option>
                          ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="property"
                    colon={false}
                    rules={[{ required: true, message: t('common.validation.required') }]}
                    label={<span style={{ color: '#778dac' }}>{t('tickets.create.property')}</span>}
                    className={css['form-item']}
                    labelAlign="left">
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
                      className="common-select common-animation-primary"
                      dropdownClassName="documents-control-select-dropdown"
                      placeholder={t('tickets.create.placeholder.property')}
                      onChange={changeProperty}
                      disabled={!form.getFieldsValue().project}
                      suffixIcon={
                        <div
                          className={`arrow-drop-img ${css['arrow-drop']}`}
                          style={{ width: '8px', height: '4px' }}></div>
                      }>
                      {properties.length > 0 &&
                        properties
                          .sort((a: any, b: any) => a.title?.toLowerCase().localeCompare(b.title?.toLowerCase()))
                          .map((project: any) => (
                            <Option value={project.id} key={project.id} className="documents-control-option">
                              <span style={{ fontSize: '14px', fontWeight: 600 }}>{project.title}</span>
                            </Option>
                          ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Form.Item
                  name="flow"
                  colon={false}
                  label={<span style={{ color: '#778dac' }}>{t('contract.flow.title')}</span>}
                  className={css['form-item']}
                  initialValue={''}
                  labelAlign="left">
                  <Select
                    className="common-select common-animation-primary"
                    suffixIcon={
                      <div
                        className={`arrow-drop-img ${css['arrow-drop']}`}
                        style={{ width: '8px', height: '4px' }}></div>
                    }
                    style={{ marginTop: '0' }}
                    onSelect={onSelectFlow}
                    disabled={!propertyId}>
                    <Select.Option value={''}>{t('contract.flow.noFlow')}</Select.Option>
                    {flows.length > 0 &&
                      flows.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                {flowSteps.length > 0 &&
                  flowSteps.map((item) => (
                    <div key={item.code}>
                      <Form.Item
                        name={item.code}
                        colon={false}
                        label={<span style={{ color: '#778dac' }}>{`${item.signatoriesName} *`}</span>}
                        rules={[{ required: true, message: t('common.validation.required') }]}
                        className={css['form-item']}
                        labelAlign="left">
                        <Select
                          className="common-select common-animation-primary"
                          mode="multiple"
                          onDropdownVisibleChange={(value) => {
                            if (item.code.split('_').includes('agent') && !value && searchValue) {
                              setAgents([]);
                              setPaginationAgents({ ...paginationAgents, current: 1, skip: 0 });
                              setSearchValue('');
                            }
                          }}
                          onSearch={(value) => {
                            if (item.code.split('_').includes('agent')) {
                              setAgents([]);
                              setPaginationAgents({ ...paginationAgents, current: 1, skip: 0 });
                              clearTimeout(Number(currentTimeout));
                              setCurrentTimeout(setTimeout(() => setSearchValue(value), 1000));
                            }
                          }}
                          filterOption={(value, option) =>
                            item.code.split('_').includes('agent')
                              ? true
                              : option?.children?.toLowerCase().indexOf(value.toLowerCase()) >= 0
                          }
                          dropdownClassName={`documents-control-select-dropdown ${
                            fetchingUserData ? 'consumers-control-select-dropdown--progress' : ''
                          }`}
                          onPopupScroll={(e: any) => {
                            e.persist();
                            let target = e.target;
                            if (
                              item.code.split('_').includes('agent') &&
                              !fetchingUserData &&
                              !isLastAgentsReq &&
                              target.scrollTop + target.offsetHeight === target.scrollHeight
                            ) {
                              getAgents();
                            }
                          }}
                          suffixIcon={
                            <div
                              className={`arrow-drop-img ${css['arrow-drop']}`}
                              style={{ width: '8px', height: '4px' }}></div>
                          }
                          disabled={!propertyId}>
                          {item.code.split('_').includes('agent')
                            ? agents.length > 0 &&
                              agents.map((signatory) => (
                                <Select.Option key={signatory.id} value={signatory.id}>
                                  {`${signatory.firstName} ${signatory.lastName}`}
                                </Select.Option>
                              ))
                            : consumers.length > 0 &&
                              consumers
                                .sort((a, b) => a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()))
                                .map((signatory) => (
                                  <Select.Option key={signatory.id} value={signatory.id}>
                                    {`${signatory.firstName} ${signatory.lastName}`}
                                  </Select.Option>
                                ))}
                        </Select>
                      </Form.Item>
                    </div>
                  ))}
                <Col span={24}>
                  <Form.Item
                    name="description"
                    colon={false}
                    label={<span style={{ color: '#778dac' }}>{t('budget.form.description')}</span>}
                    className={css['form-item']}
                    labelAlign="left">
                    <TextArea
                      bordered={false}
                      className="consumer-form-input common-animation-primary"
                      autoSize={{ minRows: 2, maxRows: 10 }}
                    />
                  </Form.Item>
                </Col>
                {(isQuotes || isAmendments || isInvoices) && (
                  <Col span={24}>
                    <Form.Item
                      name="comment"
                      colon={false}
                      label={<span style={{ color: '#778dac' }}>{t('fileManagement.upload.comment')}</span>}
                      className={css['form-item']}
                      labelAlign="left">
                      <TextArea
                        bordered={false}
                        className="consumer-form-input common-animation-primary"
                        autoSize={{ minRows: 2, maxRows: 10 }}
                      />
                    </Form.Item>
                  </Col>
                )}
                {(isQuotes || isAmendments) && (
                  <Col span={24}>
                    <Form.Item
                      name="deadline"
                      colon={false}
                      label={<span style={{ color: '#778dac' }}>{t('budget.form.deadline')}</span>}
                      className={css['form-item']}
                      initialValue={moment().add(10, 'days')}
                      labelAlign="left">
                      <DatePicker
                        getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
                        style={{ width: '100%' }}
                        suffixIcon={<div className={'calendar-img'} style={{ width: '12px', height: '12px' }}></div>}
                        className="common-date-picker common-animation-primary"
                        format="DD.MM.YYYY"
                        onChange={(value) => setDeadline(value)}
                      />
                    </Form.Item>
                  </Col>
                )}
                {isInvoices && (
                  <Col span={24}>
                    <Form.Item
                      name="paymentDate"
                      colon={false}
                      initialValue={moment()}
                      label={<span style={{ color: '#778dac' }}>{t('budget.form.date')}</span>}
                      className={css['form-item']}
                      labelAlign="left">
                      <DatePicker
                        getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
                        style={{ width: '100%' }}
                        suffixIcon={<div className={'calendar-img'} style={{ width: '12px', height: '12px' }}></div>}
                        className="common-date-picker common-animation-primary"
                        format="DD.MM.YYYY"
                        onChange={(value) => setPaymentDate(value)}
                      />
                    </Form.Item>
                  </Col>
                )}
              </div>
            </div>
            <div className={css['upload-steps-item']}>
              <div className={css['upload-steps-container']}>
                <span style={{ marginBottom: '1rem' }}>{t('budget.upload.attachments.title')}</span>
                <div>
                  <Form.Item name="fileAttachment">
                    <Upload
                      className="consumers-import-upload-item upload-list-invoice"
                      style={{ width: '100%' }}
                      beforeUpload={() => false}
                      onChange={handleFileAttachment}
                      onRemove={() => true}
                      multiple={true}
                      fileList={fileAttachmentList}
                      accept=".pdf">
                      <div className={css['upload-item-budget']}>
                        <span style={{ color: '#076ee5' }}>{t('budget.upload.attachment.btn.add')}</span>
                      </div>
                    </Upload>
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

export default CreateFileManagement;
