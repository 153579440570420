import { Button } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../assets/styles/post.module.css';
import LogoComponent from '../../../components/common/LogoComponent';
import { AppContext } from '../../../contexts/AppContextProvider';
import { useLessThen1100 } from '../../../helpers/mediaDetect';
import { CalendarTypes } from '../../../models/CalendarModel';
import { IAppContext } from '../../../typings/IApp';

interface IPostCreateFilters {
  openModal: (t: CalendarTypes) => void;
}

const PostCreateFilters = ({ openModal }: IPostCreateFilters) => {
  const { t } = useTranslation();
  const { app } = useContext<IAppContext>(AppContext);
  return (
    <div className={css['post-create-filters-container']}>
      <div>
        <LogoComponent
          id={app.user.id}
          name={`${app.user.firstName} ${app.user.lastName}`}
          image={app.user.image || ''}
        />
      </div>
      <div className={css['post-create-frame']}>
        <span
          style={{
            fontSize: '15px',
            color: '#778DAC',
          }}>
          {`${t('post.create.fileter.title')}:`}
        </span>
        <Button
          className="common-blue-btn common-primary-btn"
          style={useLessThen1100() ? { width: '100%' } : {}}
          onClick={() => openModal(CalendarTypes.update)}>
          {t('post.update')}
        </Button>
        <Button
          className="common-blue-btn common-primary-btn"
          style={useLessThen1100() ? { width: '100%' } : {}}
          onClick={() => openModal(CalendarTypes.document)}>
          {t('post.documents.general')}
        </Button>
        <Button
          className="common-blue-btn common-primary-btn"
          style={useLessThen1100() ? { width: '100%' } : {}}
          onClick={() => openModal(CalendarTypes.image)}>
          {t('post.image')}
        </Button>
      </div>
    </div>
  );
};

export default PostCreateFilters;
