import React from 'react';
import { Button, Dropdown, Menu, Badge, Row, Col, Typography } from 'antd';
import { DashOutlined } from '@ant-design/icons';
import ProductNameLayout from '../../../components/common/ProductNameLayout';
import ArticleNameLayout from '../../../components/common/ArticleNameLayout';
import { TFunction } from 'react-i18next';

interface SpaceOptionColumnsModel {
  t: TFunction<'translation'>;
  onEdit: (option: any) => void;
  onDelete: (id: number) => void;
}

export const getColumns = ({ t, onEdit, onDelete }: SpaceOptionColumnsModel) => [
  {
    title: t('space.option.table.columns.name'),
    key: 'name',
    render: (cell: any) => (
      <Row align="top">
        <Col span={12}>
          <ProductNameLayout product={cell.product} />
        </Col>
        <Col span={12}>
          <Typography.Text type="secondary">
            <ArticleNameLayout article={cell.product?.article} />
          </Typography.Text>
        </Col>
      </Row>
    ),
    width: 250,
  },
  {
    title: t('space.option.table.columns.isActive'),
    dataIndex: 'isActive',
    key: 'isActive',
    render: (cell: any) => {
      return cell ? (
        <Badge status="success" text={t('space.option.table.columns.isActive.active')} />
      ) : (
        <Badge status="error" text={t('space.option.table.columns.isActive.inactive')} />
      );
    },
    width: 150,
  },
  {
    title: t('space.option.table.columns.isDefault'),
    dataIndex: 'isDefault',
    key: 'isDefault',
    render: (cell: any) => {
      return cell ? <Badge status="success" text={t('space.option.table.columns.isDefault.text')} /> : <div></div>;
    },
    width: 150,
  },
  {
    title: t('space.option.table.columns.action'),
    key: 'action',
    width: 100,
    render: (cell: any) => {
      const menu = (
        <Menu>
          <Menu.Item key="edit" onClick={() => onEdit(cell)}>
            <Button type="link">{t('common.btn.modify')}</Button>
          </Menu.Item>
          {onDelete && (
            <Menu.Item key="block" onClick={() => onDelete(cell.id)}>
              <Button type="link">{t('common.title.delete')}</Button>
            </Menu.Item>
          )}
        </Menu>
      );

      return (
        <span>
          <Dropdown overlay={menu} trigger={['click']}>
            <Button type="link">
              <DashOutlined />
            </Button>
          </Dropdown>
        </span>
      );
    },
  },
];
