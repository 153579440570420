import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import ConsumersService from '../../services/ConsumersService';
import { Table, notification } from 'antd';
import { getColumns } from './components/LeadsColumns';
import { AppContext } from '../../contexts/AppContextProvider';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { HeaderContext } from '../../contexts/HeaderContextProvider';
import { CHANGE_CURRENT_PAGE } from '../../constants/actionTypes/headerConstants';
import css from '../../assets/styles/leads.module.css';
import LeadsControl from './components/LeadsControl';
import { generateFilterfromObj } from '../../helpers/generateFIlterfromObj';
import { FiltersContext } from '../../contexts/FiltersContextProvider';
import { FilterTypes } from '../../constants/filterTypes';
import { IAppContext } from '../../typings/IApp';
import i18n from '../../utils/i18n';

const service: ConsumersService = new ConsumersService();

const LeadsPage: React.FC<any> = (props: any) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    app: { currentProject },
  } = useContext<IAppContext>(AppContext);
  const {
    filters: { leadsFilter },
  } = useContext(FiltersContext);
  const { height: windowHeight } = useWindowDimensions();
  const [items, setItems] = useState<any>([]);
  const [breadcrumbNameMap, setBreadcrumbNameMap] = useState<{ [name: string]: string }>({});
  const [currentFilters, setCurrentFilters] = useState<any>({
    isConfirmed: {
      type: FilterTypes.SELECT,
      value: leadsFilter.status,
    },
  });

  const [loading, setLoading] = useState<boolean>(false);
  const { headerDispatch } = useContext(HeaderContext);
  useEffect(() => {
    headerDispatch({
      type: CHANGE_CURRENT_PAGE,
      currentPage: t('header.title.Leads'),
      path: 'leads',
    });
  }, [i18n.language]);
  const defaultParams = {
    count: true,
  };

  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    total: 0,
  });

  const getConsumers = (option: any) => {
    setLoading(true);
    service
      .getConsumers(option, currentProject?.key)
      .then((res: any) => {
        const { items, count } = res;
        const { current, top } = option;
        setItems(items);
        setPagination({ ...pagination, current, total: count, pageSize: top });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getConsumers({
      ...defaultParams,
      top: pagination.pageSize,
    });
  }, [currentProject.key]);

  useEffect(() => {
    setBreadcrumbNameMap(
      props.history.location.pathname
        .replace('/leads', '')
        .split('/')
        .slice(1)
        .reduce(
          (res: any, curr: any, index: number) => ({
            ...res,
            [[Object.keys(res)[index], curr].join('/')]: curr.split(':').shift(),
          }),
          { '/leads': t('header.title.Leads') },
        ),
    );
  }, [props.history.location.pathname]);

  const resendConfirmation = (email: string) => {
    service.resendConsumerEmail(email, currentProject?.key).then(() => {
      notification.success({
        message: t('consumers.table.columns.action.resend.success'),
      });
    });
  };

  const isEmptyFilter = (obj: any) => {
    return Object.keys(obj).length === 0;
  };

  const applyNewFilter = (filters: any) => {
    setCurrentFilters({ ...currentFilters, ...filters });
    return { ...currentFilters, ...filters };
  };

  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    let params: any = {
      ...defaultParams,
    };

    if (filters && !isEmptyFilter(filters)) {
      params = {
        ...params,
        filters: generateFilterfromObj(applyNewFilter(filters)),
      };
    } else {
      params = {
        ...params,
        filters: generateFilterfromObj(currentFilters),
      };
    }

    if (pagination) {
      params = {
        ...params,
        top: pagination.pageSize,
        skip: pagination.pageSize * (pagination.current - 1),
        current: pagination.current,
      };
    }

    if (sorter && sorter.order) {
      params = {
        ...params,
        orderBy: `${sorter.columnKey.replace('-', '/')} ${sorter.order === 'ascend' ? 'asc' : 'desc'}`,
      };
    }
    getConsumers(params);
  };

  return (
    <>
      <HeaderTable breadcrumb={breadcrumbNameMap}></HeaderTable>
      <LeadsControl props={props} onTableChange={onTableChange} />
      <div className={css['leads-table-container']}>
        <Table
          columns={getColumns({
            onDelete: () => {},
            resendConfirmation,
            history,
            t,
          })}
          loading={loading}
          size="middle"
          dataSource={items}
          showHeader={true}
          className="consumers-info-table"
          rowClassName="common-table-row--pointer"
          rowKey="id"
          scroll={{ x: '100%', y: windowHeight - 300 }}
          pagination={pagination}
          onChange={onTableChange}
        />
      </div>
    </>
  );
};

export default LeadsPage;
