import React from 'react';
import css from '../../../../assets/styles/faqAI.module.css';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

interface IProps {
  isDraft?: boolean;
}

const AILoader = ({ isDraft }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className={css.aiLoaderContainer}>
      <div className={css.aiLoaderContent}>
        <LoadingOutlined style={{ fontSize: '2rem', position: 'absolute', top: -100, left: '46.5%' }} />
        <h2 style={{ fontWeight: 600, marginBottom: '1rem' }}>
          {isDraft ? t('faq.ai.loader.title2') : t('faq.ai.loader.title')}
        </h2>
        {!isDraft && (
          <>
            <p>{t('faq.ai.loader.text1')}</p>
            <p>{t('faq.ai.loader.text2')}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default AILoader;
