import { Button } from 'antd';
import React from 'react';
import css from '../../../../assets/styles/statistics.module.css';
import { useTranslation } from 'react-i18next';
import { DocumentTypes } from '../StatisticsPage';

interface IProps {
  documentType: string;
  setDocumentType: (v: DocumentTypes) => void;
}
const Control: React.FC<IProps> = ({ documentType, setDocumentType }) => {
  const { t } = useTranslation();
  return (
    <div className={css['control-container']}>
      <Button
        style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
        type={documentType === DocumentTypes.quote ? 'primary' : 'default'}
        onClick={() => setDocumentType(DocumentTypes.quote)}>
        {t('consumers.manage.tabs.quotes')}
      </Button>
      <Button
        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
        type={documentType === DocumentTypes.amendments ? 'primary' : 'default'}
        onClick={() => setDocumentType(DocumentTypes.amendments)}>
        {t('dashboard.statistics.amendments')}
      </Button>
    </div>
  );
};

export default Control;
