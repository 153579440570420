import { ArrowRightOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../assets/styles/internal.module.css';

interface Props {
  isOpen: boolean;
  setIsOpen: any;
  setIsOpenUpload: any;
}

const SelectTypeModal = (props: Props) => {
  const { isOpen, setIsOpen, setIsOpenUpload } = props;
  const { t } = useTranslation();

  const selectInternalType = (type: string) => {
    setIsOpen(false);
    setIsOpenUpload({ documentType: type });
  };

  return (
    <Modal centered visible={isOpen} style={{ height: '20rem', width: '10rem' }} footer={null} closable={false}>
      <div style={{ padding: '3rem 0 0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <span className={css['select-type-title']}>{t('internal.documents.select.type.upload')}</span>
      </div>
      <div
        className={`${css['select-type-container']} common-animation-primary`}
        onClick={() => selectInternalType('quote')}>
        <span>{t('quotes.upload.invoice.title')}</span>
        <ArrowRightOutlined className={css['arrow']} />
      </div>
      <div
        className={`${css['select-type-container']} common-animation-primary`}
        onClick={() => selectInternalType('document')}>
        <span>{t('internal.documents.type.document')}</span>
        <ArrowRightOutlined className={css['arrow']} />
      </div>
      <div
        className={`${css['select-type-container']} common-animation-primary`}
        onClick={() => selectInternalType('invoice')}>
        <span>{t('internal.documents.type.invoice')}</span>
        <ArrowRightOutlined className={css['arrow']} />
      </div>
      <div
        className={`${css['select-type-container']} common-animation-primary`}
        onClick={() => selectInternalType('amendment')}>
        <span>{t('amendment.title')}</span>
        <ArrowRightOutlined className={css['arrow']} />
      </div>
    </Modal>
  );
};

export default SelectTypeModal;
