import { useState, useEffect } from 'react';

const copyToClipBoard = (str: string) => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.display = 'none';
  document.body.appendChild(el);
  el.select();
  const success = document.execCommand('copy');
  document.body.removeChild(el);
  return success;
};

export const useCopyToClipboard = () => {
  const [copied, setCopied] = useState(false);

  const copy = (str: string) => {
    if (!copied) {
      setCopied(copyToClipBoard(str));
    }
  };

  useEffect(() => {
    if (copied) {
      setCopied(false);
    }
  }, [copied]);

  return { copied, copy };
};
