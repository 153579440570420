import { Button, notification, Spin } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../assets/styles/documents.module.css';
import InternalDocumentsService from '../../../services/InternalDocumentsService';

interface DeleteConfirmFormProps {
  isOpen: boolean;
  currentId: number | undefined;
  getInternalDocuments: any;
  option: any;
  setClose: any;
  documentName: string | undefined;
  documentType: string | undefined;
}

const service = new InternalDocumentsService();

const DeleteInternalForm = (props: DeleteConfirmFormProps) => {
  const { currentId, isOpen, setClose, getInternalDocuments, option, documentType, documentName } = props;
  const [fetchingData, setFetchingData] = useState(false);
  const { t } = useTranslation();

  const successMessage = () => {
    switch (documentType) {
      case 'quote': {
        return t('quotes.modal.delete.success.message');
      }
      case 'document': {
        return t('documents.modal.delete.success.message');
      }
      default:
        return t('budget.modal.update.delete.success');
    }
  };

  const deleteInternalDocument = () => {
    if (currentId) {
      setFetchingData(true);
      service
        .deleteInternalDocument(currentId)
        .then(() => {
          setClose();
          notification.success({
            message: successMessage(),
          });
          getInternalDocuments(option);
        })
        .catch((e: any) =>
          notification.error({
            message: e,
          }),
        )
        .finally(() => setFetchingData(false));
    }
  };

  return (
    <Spin spinning={fetchingData}>
      <Modal
        className="common-modal"
        centered
        style={{ width: '10rem' }}
        visible={isOpen}
        footer={
          <div className="common-modal-footer">
            <Button
              className={`common-gray-border-btn common-secondary-btn`}
              disabled={fetchingData}
              onClick={() => setClose()}>
              {t('documents.modal.cancel.btn')}
            </Button>
            <Button
              className={`common-green-btn common-modal-btn`}
              disabled={fetchingData}
              onClick={() => deleteInternalDocument()}>
              {t('documents.modal.delete.btn')}
            </Button>
          </div>
        }
        closable={false}>
        <span className={css['delete-documents-title']}>{`${t(
          'internal.documents.confirm.delete',
        )} ${documentType} ${documentName}?`}</span>
      </Modal>
    </Spin>
  );
};

export default DeleteInternalForm;
