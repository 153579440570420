import { Button, notification, Spin } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../assets/styles/budget.module.css';
import CompaniesService from '../../../services/CompaniesService';

interface CompaniesDeleteConfirmModal {
  isOpen: boolean;
  currentId: number;
  getCompanies: () => void;
  setClose: () => void;
  companyName: string | null;
}

const service = new CompaniesService();

const CompaniesDeleteConfirmModal = (props: CompaniesDeleteConfirmModal) => {
  const { currentId, isOpen, setClose, getCompanies, companyName } = props;
  const [fetchingData, setFetchingData] = useState(false);
  const { t } = useTranslation();

  const deleteCompany = () => {
    setFetchingData(true);
    service
      .deleteCompany(currentId)
      .then(() => {
        getCompanies();
        notification.success({
          message: t('companies.delete.success.notify'),
        });
        setClose();
      })
      .catch((e) =>
        notification.error({
          message: e,
        }),
      )
      .finally(() => setFetchingData(false));
  };

  return (
    <Spin spinning={fetchingData}>
      <Modal
        className="common-modal"
        centered
        style={{ width: '10rem' }}
        visible={isOpen}
        footer={
          <div className="common-modal-footer">
            <Button className={`common-gray-border-btn common-secondary-btn`} onClick={setClose}>
              {t('budget.modal.cancel.btn')}
            </Button>
            <Button className={`common-green-btn common-modal-btn`} onClick={deleteCompany}>
              {t('budget.modal.delete.btn')}
            </Button>
          </div>
        }
        closable={false}>
        <span className={css['delete-invoice-title']}>{`${t(
          'companies.modal.confirm.delete.message',
        )} ${companyName}?`}</span>
      </Modal>
    </Spin>
  );
};

export default CompaniesDeleteConfirmModal;
