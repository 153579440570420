import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router';
import css from '../../../assets/styles/budget.module.css';
import { Table, Spin, notification, Button, TablePaginationConfig } from 'antd';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../contexts/AppContextProvider';
import { generateFilterfromObj } from '../../../helpers/generateFIlterfromObj';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import QuotesService from '../../../services/QuoteService';
import QuoteStatusForm from './UploadQuote/QuotesStatusForm';
import QuotesControl from './QuotesControl';
import { getQuotesColumns, getQuotesSupplierColumns } from './QuotesColumns';
import QuoteForm from './QuoteForm';
import DeleteQuoteForm from './DeleteQuoteForm';
import ArchivesQuotes from './ArchivesQuotes/ArchivesQuotes';
import ArchiveConfirmForm from './ArchivesQuotes/ArchiveConfirmForm';
import ConsumerQuoteForm from './ConsumerQuoteForm';
import PreviewPDF from './PreviewPDF';
import SigningConfirmForm from '../../common/SigningConfirmForm';
import { getUriSearchValue } from '../../../helpers/proccessUri';
import { docusignEventStatuses } from '../../../constants/docusignEventStatuses';
import { getStorageEnvelopeId, setStorageEnvelopeId } from '../../../helpers/envelopeStorage';
import { useLessThen801 } from '../../../helpers/mediaDetect';
import TableMobile from '../../common/TableMobile';
import { FiltersContext } from '../../../contexts/FiltersContextProvider';
import { FilterTypes } from '../../../constants/filterTypes';
import filterIcon from '../../../assets/images/ic-filter.svg';
import filterActiveIcon from '../../../assets/images/ic-filter-active.svg';
import moment from 'moment';
import UploadFileLoader from '../../common/UploadFileLoader';
import CommonApproveForm from '../../common/CommonApproveForm';
import CommonHistoryModal from '../../common/CommonHistoryModal';
import { SET_QUOTES_FILTERS } from '../../../constants/actionTypes/filtersConstants';
import { IQuoteModel } from '../../../models/QuotesModel';
import { isEmptyFilter } from '../../../helpers/isEmptyFilter';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { IHistoryModel } from '../../../models/CommonDocumnetsModel';
import { IAppContext } from '../../../typings/IApp';
import { getSearchParam } from '../../../helpers/customUrlSearchParams';
import { authDownloadFile } from '../../../helpers/authFileTools';

const service = new QuotesService();

interface QuotesListProps {
  propertyId: number;
  supplierId?: number;
  setIsOpenUploadQuote?: (b: boolean) => void;
  isCreatedQuote?: boolean;
  setIsCreatedQuote?: (b: boolean) => void;
  isOpenStatusQuoteForm?: boolean;
  setIsOpenStatusQuoteForm?: (b: boolean) => void;
  setCurrentQuote?: (v: number | null) => void;
  currentQuote?: number | null;
}

const QuotesListInfo = (props: QuotesListProps) => {
  const {
    propertyId,
    supplierId,
    setIsOpenUploadQuote,
    isCreatedQuote,
    setIsCreatedQuote,
    setIsOpenStatusQuoteForm,
    isOpenStatusQuoteForm,
    setCurrentQuote,
    currentQuote,
  } = props;
  const {
    filters: { quotesFilter },
    filtersDispatch,
  } = useContext(FiltersContext);
  const {
    app: { isConsumer, isSupplier, user },
  } = useContext<IAppContext>(AppContext);
  const location = useLocation();
  const tabIndex = getSearchParam(location.search, 'tabIndex');
  const history = useHistory();
  const [quotesInfo, setQuotesInfo] = useState<IQuoteModel[]>([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [currentFilters, setCurrentFilters] = useState({
    'status_v2/code': {
      type: FilterTypes.SELECT,
      value: quotesFilter.status,
    },
    'created_user/id': {
      type: FilterTypes.SELECT,
      value: isSupplier && supplierId ? +supplierId : null,
    },
    'roles/name': {
      type: isSupplier ? FilterTypes.SELECT : FilterTypes.NOT,
      value: 'supplier',
    },
  });
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [isOpenArchiveConfirm, setIsOpenArchiveConfirm] = useState(false);
  const [isOpenArchives, setIsOpenArchives] = useState(false);
  const [isOpenSigningConfirm, setIsOpenSigningConfirm] = useState(false);
  const [isOpenApprove, setIsOpenApprove] = useState(false);
  const [isOpenUploadLoader, setIsOpenUploadLoader] = useState(false);
  const [currentQuoteNumber, setCurrentQuoteNumber] = useState<string | null>(null);
  const event = getUriSearchValue(location.search, 'event');
  const [isDocusignConfirm, setIsDocusignConfirm] = useState(
    !!event && event === docusignEventStatuses.signingComplete,
  );
  const [isDocusignDecline, setIsDocusignDecline] = useState(!!event && event === docusignEventStatuses.decline);
  const [signInfo, setSignInfo] = useState<string | null>(null);
  const { height: windowHeight } = useWindowDimensions();
  const { t } = useTranslation();
  const [fetchingData, setFetchingData] = useState(false);
  const [activeTab, setActiveTab] = useState(tabIndex ? +tabIndex : 1);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
  });

  //history
  const [isOpenHistory, setIsOpenHistory] = useState(false);
  const [historyArray, setHistoryArray] = useState<IHistoryModel>([]);
  const [currentItem, setCurrentItem] = useState<IQuoteModel | string | null>(null);

  const tabsTableQuote = [
    { id: 1, title: t('quotes.tabs.title.final') },
    { id: 2, title: t('quotes.tabs.title.supplier') },
  ];

  const option = {
    count: true,
    top: pagination.pageSize,
    current: pagination.current,
    filters: {
      filter: generateFilterfromObj(currentFilters).filter,
    },
    orderBy: ['createdDate desc'],
  };

  const onChangeTabQuotes = () => {
    if (!isSupplier) {
      if (activeTab === 1) {
        const body = {
          'roles/name': {
            type: FilterTypes.NOT,
            value: 'supplier',
          },
        };
        return onTableChange(undefined, body);
      }
      if (activeTab === 2) {
        const body = {
          'roles/name': {
            type: FilterTypes.SELECT,
            value: 'supplier',
          },
        };
        return onTableChange(undefined, body);
      }
    }
  };

  useEffect(() => {
    onChangeTabQuotes();
  }, [activeTab]);

  useEffect(() => {
    const envelopeId = getStorageEnvelopeId();
    if (isDocusignConfirm) {
      setFetchingData(true);
      if (!envelopeId) {
        setFetchingData(false);
        setIsDocusignConfirm(false);
      } else {
        service
          .confirmStep(envelopeId)
          .catch((e) => console.error(e))
          .finally(() => {
            setIsDocusignConfirm(false);
            setStorageEnvelopeId('');
            history.replace({ search: '' });
          });
      }
    } else if (isDocusignDecline) {
      setFetchingData(true);
      if (!envelopeId) {
        setFetchingData(false);
        setIsDocusignDecline(false);
      } else {
        service
          .rejectQuote(null, '', envelopeId)
          .catch((e) => console.error(e))
          .finally(() => {
            setIsDocusignDecline(false);
            setStorageEnvelopeId('');
            history.replace({ search: '' });
          });
      }
    }
    if (location.search.includes('event')) {
      history.replace({ search: '' });
    }
  }, []);

  useEffect(() => {
    if (isCreatedQuote) {
      getQuotesInfo(option);
      setIsCreatedQuote && setIsCreatedQuote(false);
    }
  }, [isCreatedQuote]);

  useEffect(() => {
    if (!location.search || isDocusignConfirm) {
      getLocalFilters();
      getQuotesInfo(option);
    }
  }, [propertyId, isDocusignConfirm]);

  const getLocalFilters = () => {
    const localFilters = localStorage.getItem('quotesFilter');
    const filters = localFilters && JSON.parse(localFilters);
    if (filters) {
      option.filters = generateFilterfromObj(
        applyNewFilter({
          'status_v2/code': {
            type: FilterTypes.SELECT,
            value: filters.status,
          },
        }),
      );
      filtersDispatch({
        type: SET_QUOTES_FILTERS,
        filters: {
          status: filters.status,
        },
      });
    }
  };

  const getQuotesInfo = (option: { [name: string]: any }) => {
    if (!isDocusignConfirm) {
      setFetchingData(true);
      service
        .getQuotesNoArchived(propertyId, option)
        .then((res) => {
          const { count, items } = res;
          const { current, top } = option;
          setPagination({ ...pagination, current, total: count, pageSize: top });
          setQuotesInfo(items);
          checkIsDelete(items);
        })
        .catch((e) => console.log(e))
        .finally(() => setFetchingData(false));
    }
  };

  const checkIsDelete = (items: IQuoteModel[]) => {
    if (location.search && items.length === 0) {
      notification.warning({
        message: t('common.warning.notification.file.deleted'),
      });
      history.push(location.pathname);
    }
  };

  const loadMore = (option: { [name: string]: any }) => {
    if (!isDocusignConfirm) {
      setFetchingData(true);
      option.skip = option.skip ? option.skip + 10 : 10;
      service
        .getQuotesNoArchived(propertyId, option)
        .then((res) => {
          const { count, items } = res;
          const { current, top } = option;
          setPagination({ ...pagination, current, total: count, pageSize: top });
          setQuotesInfo([...quotesInfo, ...items]);
        })
        .catch((e) => console.log(e))
        .finally(() => setFetchingData(false));
    }
  };

  const setOpen = (id?: number) => {
    setIsOpenModal(true);
    if (id) {
      setCurrentId(id);
    }
  };

  const setOpenStatus = (id?: number) => {
    setIsOpenStatusQuoteForm && setIsOpenStatusQuoteForm(true);
    if (id) {
      setCurrentId(id);
    }
  };

  const setCloseStatus = () => {
    setIsOpenStatusQuoteForm && setIsOpenStatusQuoteForm(false);
    setCurrentId(null);
    getQuotesInfo(option);
  };

  const setClose = () => {
    setIsOpenModal(false);
    setCurrentId(null);
  };

  const setOpenDelete = (id?: number, quoteNumber?: string) => {
    setIsOpenDeleteModal(true);
    if (id) {
      setCurrentId(id);
    }
    if (quoteNumber) {
      setCurrentQuoteNumber(quoteNumber);
    }
  };

  const setCloseDelete = () => {
    setIsOpenDeleteModal(false);
    setCurrentId(null);
    setCurrentQuoteNumber(null);
  };

  const openArchiveConfirm = (id?: number, quoteNumber?: string) => {
    setIsOpenArchiveConfirm(true);
    if (id) {
      setCurrentId(id);
    }
    if (quoteNumber) {
      setCurrentQuoteNumber(quoteNumber);
    }
  };

  const closeArchiveConfirm = () => {
    setIsOpenArchiveConfirm(false);
    setCurrentId(null);
    getQuotesInfo(option);
  };

  const openSigningConfirm = (id?: number, signInfo?: string) => {
    setIsOpenSigningConfirm(true);
    if (id) {
      setCurrentId(id);
      setSignInfo(signInfo || null);
    }
  };

  const closeSigningConfirm = () => {
    setIsOpenSigningConfirm(false);
    setCurrentId(null);
    setSignInfo(null);
    getQuotesInfo(option);
  };

  const openPreview = (id?: number) => {
    setIsOpenPreview(true);
    if (id) {
      setCurrentId(id);
    }
  };

  const closePreview = () => {
    setIsOpenPreview(false);
    setCurrentId(null);
    getQuotesInfo(option);
  };

  const closeUploadLoader = () => {
    setIsOpenUploadLoader(false);
    getQuotesInfo(option);
  };

  const data =
    quotesInfo.length > 0 &&
    (quotesInfo.map((item) =>
      isSupplier || activeTab === 2
        ? [
            {
              title: '№',
              description: item.number,
              warning: {
                title: (
                  <span style={{ color: '#EB3B3B', fontSize: '18px' }}>{t('common.popover.attention.file.title')}</span>
                ),
                content: (
                  <div style={{ width: '200px' }}>
                    <p>{t('common.popover.attention.file.content')}</p>
                  </div>
                ),
              },
              type: !item.file ? 'warning' : 'string',
            },
            { title: t('documents.table.column.description'), description: item.description, type: 'string' },
            { title: t('budget.table.sum.total'), description: item.sumWithVat, type: 'price' },
            { title: t('budget.table.status'), description: item.statusV2, type: 'status' },
            {
              title: t('budget.table.action'),
              description: item.file,
              type: 'button',
              previewAction: !!item.file && openPreview,
              id: item.id,
              signAction: openSigningConfirm,
              signatories: !isSupplier && item.signatories,
              comment: item.comment || item.signInfo,
              status: item.statusV2,
              openByFunction: !isConsumer,
              btnsInfo: [
                {
                  function: () => (item.items.length > 0 ? setOpenStatus(item.id) : setOpen(item.id)),
                  functionBtnTitle: t('budget.table.edit'),
                  isNotShow: activeTab === 2 ? !isSupplier : false,
                },
                {
                  function: () =>
                    item.flow ? openHistory(item.id, item.number, item) : openHistory(item.id, item.number),
                  functionBtnTitle: t('fileManagement.history.title'),
                  isNotShow: isConsumer,
                },
                {
                  function: () => authDownloadFile(item.file, item.fileName),
                  functionBtnTitle: t('budget.table.download'),
                },
              ],
            },
          ]
        : [
            {
              title: '№',
              description: item.number,
              warning: {
                title: (
                  <span style={{ color: '#EB3B3B', fontSize: '18px' }}>{t('common.popover.attention.file.title')}</span>
                ),
                content: (
                  <div style={{ width: '200px' }}>
                    <p>{t('common.popover.attention.file.content')}</p>
                  </div>
                ),
              },
              type: !item.file ? 'warning' : 'string',
            },
            { title: t('documents.table.column.description'), description: item.description, type: 'string' },
            { title: t('amendment.title'), description: item?.amendment?.number || '-', type: 'string' },
            { title: t('budget.table.sum.total'), description: item.sumWithVat, type: 'price' },
            { title: t('budget.form.deadline'), description: item.deadline, type: 'date' },
            { title: t('budget.table.status'), description: item.statusV2, type: 'status' },
            {
              title: t('budget.table.action'),
              description: item.file,
              type: 'button',
              previewAction: !!item.file && openPreview,
              id: item.id,
              comment: item.comment || item.signInfo,
              status: item.statusV2,
              openByFunction: true,
              btnsInfo: [
                {
                  function: () => (item.items.length > 0 ? setOpenStatus(item.id) : setOpen(item.id)),
                  functionBtnTitle: t('budget.table.edit'),
                  isNotShow: isSupplier || isConsumer,
                },
                {
                  function: () => openApprove(item.id),
                  functionBtnTitle: t('common.title.approve.reject'),
                  isNotShow: !(
                    item.signatories &&
                    item.signatories.findIndex(
                      (signatory) =>
                        signatory.consumerId === user.id &&
                        (item.step?.code === 'agent_approve_open' ||
                          item.step?.code === 'agent_approve_close' ||
                          item.step?.code === 'client_approve') &&
                        signatory.stepCode === item.step?.code &&
                        !signatory.isDone,
                    ) >= 0 &&
                    !['rejected'].includes(item.statusV2.code)
                  ),
                },
                {
                  function: () => openSigningConfirm(item.id),
                  functionBtnTitle: t('common.title.sign'),
                  isNotShow: !(
                    item.signatories &&
                    item.signatories.findIndex(
                      (signatory) =>
                        signatory.consumerId === user.id &&
                        (item.step?.code === 'agent_sign_open' ||
                          item.step?.code === 'agent_sign_close' ||
                          item.step?.code === 'client_sign') &&
                        signatory.stepCode === item.step?.code &&
                        !signatory.isDone,
                    ) >= 0 &&
                    !['rejected'].includes(item.statusV2.code)
                  ),
                },
                {
                  function: () => authDownloadFile(item.file, item.fileName),
                  functionBtnTitle: t('budget.table.download'),
                },
              ],
            },
          ],
    ) as any);

  const applyNewFilter = (filters: { [name: string]: any }) => {
    setCurrentFilters({ ...currentFilters, ...filters });
    return { ...currentFilters, ...filters };
  };

  const onTableChange = (
    pagination?: TablePaginationConfig,
    filters?: Record<string, FilterValue | null | any>,
    sorter?: SorterResult<IQuoteModel> | SorterResult<IQuoteModel>[],
  ) => {
    let params: { [name: string]: any } = {
      ...option,
    };

    if (filters && !isEmptyFilter(filters)) {
      params = {
        ...params,
        filters: generateFilterfromObj(applyNewFilter(filters)),
      };
    } else {
      params = {
        ...params,
        filters: generateFilterfromObj(currentFilters),
      };
    }
    if (pagination && pagination.pageSize && pagination.current) {
      params = {
        ...params,
        top: pagination.pageSize,
        skip: pagination.pageSize * (pagination.current - 1),
        current: pagination.current,
      };
    } else {
      params = {
        ...params,
        skip: 0,
        current: 1,
      };
    }
    if (sorter && !Array.isArray(sorter) && sorter.order) {
      const { order, columnKey } = sorter;
      switch (columnKey) {
        case 'createdDate': {
          params = {
            ...params,
            orderBy: `createdDate ${order === 'ascend' ? 'asc' : 'desc'}`,
          };
          break;
        }
        case 'deadline': {
          params = {
            ...params,
            orderBy: `deadline ${order === 'ascend' ? 'asc' : 'desc'}`,
          };
          break;
        }
        case 'lastSignDateTime': {
          params = {
            ...params,
            orderBy: `lastSignDateTime ${order === 'ascend' ? 'asc' : 'desc'}`,
          };
          break;
        }
        case 'sumWithVat': {
          params = {
            ...params,
            orderBy: `sumWithVat ${order === 'ascend' ? 'asc' : 'desc'}`,
          };
          break;
        }
        default: {
          return;
        }
      }
    }

    // TODO: need to be refactored, it's made double quote request
    getQuotesInfo(params);
  };

  const signQuote = () => {
    localStorage.setItem('quotesFilter', JSON.stringify(quotesFilter));
    if (currentId) {
      setFetchingData(true);
      service
        .getSigningUrl(currentId, window.location.href, user.id)
        .then((res) => {
          const { alreadySigned = false, declined = false } = res;
          if (!alreadySigned && !declined) {
            setStorageEnvelopeId(res.envelopeId);
            window.location.href = res.redirectUrl;
          } else {
            if (alreadySigned) {
              notification.warning({
                message: t('quotes.modal.already.signed.message'),
              });
            }
            if (declined) {
              notification.warning({
                message: t('quotes.modal.already.decline.message'),
              });
            }
            setFetchingData(false);
          }
        })
        .catch((e) => {
          notification.error({
            message: e.message,
          });
          setFetchingData(false);
        })
        .finally(() => {
          setClose();
        });
    }
  };

  const rejectQuote = (iD: number | null, comment: string, isApprove: boolean = false) => {
    const id = iD || currentId;
    if (id) {
      service
        .rejectQuote(id, comment)
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        )
        .finally(() => {
          isApprove ? closeApprove() : closeSigningConfirm();
        });
    }
  };

  const openApprove = (id: number) => {
    setCurrentId(id);
    setIsOpenApprove(true);
  };

  const closeApprove = () => {
    setCurrentId(null);
    setIsOpenApprove(false);
    getQuotesInfo(option);
  };

  const changeOpenFilter = () => {
    setIsOpenFilter(!isOpenFilter);
  };

  const approveItem = () => {
    setFetchingData(true);
    if (currentId) {
      service
        .confirmStep(undefined, currentId)
        .then(() => {
          notification.success({
            message: t('fileManagement.quote.approved'),
          });
          closeApprove();
        })
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        )
        .finally(() => setFetchingData(false));
    }
  };

  const openHistory = (id: number, title?: string, item?: IQuoteModel) => {
    if (item) {
      if (item.statusV2.code === 'rejected') {
        setFetchingData(true);
        service
          .getQuoteHistory(id)
          .then((value) => {
            setIsOpenHistory(true);
            setCurrentId(id);
            setHistoryArray(value);
            setCurrentItem(item);
          })
          .catch((e) =>
            notification.error({
              message: e.message,
            }),
          )
          .finally(() => setFetchingData(false));
        return;
      } else {
        setIsOpenHistory(true);
        setCurrentItem(item);
        return;
      }
    }
    setFetchingData(true);
    service
      .getQuoteHistory(id)
      .then((value) => {
        setIsOpenHistory(true);
        setCurrentId(id);
        setHistoryArray(value);
        setCurrentItem(title || null);
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingData(false));
  };

  const closeHistory = () => {
    setCurrentId(null);
    setIsOpenHistory(false);
    setCurrentItem(null);
  };

  if (useLessThen801()) {
    return (
      <>
        <div className="common-mobile-title-container">
          <span style={{ fontSize: '20px' }}>{t('consumers.manage.tabs.quotes')}</span>
          <div className="common-modbile-filter-container" onClick={changeOpenFilter}>
            <img src={isOpenFilter ? filterActiveIcon : filterIcon} width={16} />
            <span className="filter-title">{isOpenFilter ? t('common.filter.close') : t('common.filter.open')}</span>
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: !isSupplier ? '0' : isOpenFilter ? '0' : '1rem' }}>
          {!isConsumer && activeTab !== 2 && (
            <Button
              className={`common-blue-border-btn common-secondary-btn`}
              onClick={() => setIsOpenModal(true)}
              style={{ width: '100%' }}>
              <span>{t('quotes.table.upload.invoce')}</span>
            </Button>
          )}
        </div>
        {isOpenFilter && (
          <QuotesControl
            activeTab={activeTab}
            pagination={pagination}
            onTableChange={onTableChange}
            setIsOpenModal={setIsOpenModal}
            propertyId={propertyId}
            isConsumer={isConsumer}
            isSupplier={isSupplier}
            setIsOpenUpload={setIsOpenUploadQuote}
          />
        )}
        <div>
          {!isSupplier && !isConsumer && (
            <div className={css['container-table-quote-control']}>
              {tabsTableQuote.map((el) => (
                <Button
                  key={el.id}
                  className={`${activeTab === el.id ? css['active'] : ''}`}
                  onClick={() => setActiveTab(el.id)}>
                  {el.title}
                </Button>
              ))}
            </div>
          )}
        </div>
        <div className="common-flex-end-style" style={{ marginBottom: '1rem' }}>
          <span onClick={() => setIsOpenArchives(true)} className={`common-archive-icon-item common-animation-primary`}>
            <div className={css['archive-img']} style={{ marginRight: '0.5rem', width: '18px', height: '14px' }}></div>
            <span style={{ color: '#076ee5', marginRight: '0.5rem', textDecoration: 'underline' }}>
              {t('common.archive.title')}
            </span>
          </span>
        </div>
        <TableMobile
          data={data}
          isLoading={fetchingData}
          loadMore={loadMore}
          option={option}
          itemsLength={quotesInfo.length}
          totalItems={pagination.total}
        />
        {isOpenPreview && <PreviewPDF id={currentId} isOpen={isOpenPreview} setClose={closePreview} />}
        {isOpenSigningConfirm && (
          <SigningConfirmForm
            id={currentId}
            isOpen={isOpenSigningConfirm}
            setClose={closeSigningConfirm}
            sign={signQuote}
            reject={rejectQuote}
            fetchingData={fetchingData}
            signInfo={signInfo}
          />
        )}
        {isOpenStatusQuoteForm && (
          <QuoteStatusForm
            isOpen={isOpenStatusQuoteForm}
            id={currentId}
            propertyId={propertyId}
            setCloseStatus={setCloseStatus}
            currentQuote={currentQuote}
            setCurrentQuote={setCurrentQuote}
          />
        )}
        {isOpenArchives && (
          <ArchivesQuotes
            isOpen={isOpenArchives}
            setIsOpen={setIsOpenArchives}
            propertyId={propertyId}
            getQuotes={() => getQuotesInfo(option)}
            isConsumer={isConsumer}
            isSupplier={isSupplier || activeTab === 2}
          />
        )}
        {isOpenUploadLoader && (
          <UploadFileLoader isOpen={isOpenUploadLoader} isLoading={isUploadingFile} setClose={closeUploadLoader} />
        )}
        {isOpenModal &&
          (isConsumer ? (
            <ConsumerQuoteForm
              setClose={setClose}
              getQuotes={() => getQuotesInfo(option)}
              currentId={currentId}
              isOpenModal={isOpenModal}
            />
          ) : (
            <QuoteForm
              setClose={setClose}
              propertyId={propertyId}
              getQuotes={() => getQuotesInfo(Option)}
              currentId={currentId}
              isOpenModal={isOpenModal}
              setIsOpenUploadLoader={setIsOpenUploadLoader}
              setIsUploadingFile={setIsUploadingFile}
              isSupplier={isSupplier || activeTab === 2}
            />
          ))}
        {isOpenApprove && (
          <CommonApproveForm
            approve={approveItem}
            isOpen={isOpenApprove}
            reject={(id, comment) => rejectQuote(id, comment, true)}
            setClose={closeApprove}
            fetchingData={fetchingData}
            type={'quote'}
          />
        )}
        {isOpenHistory && (
          <CommonHistoryModal
            fetchingData={fetchingData}
            setFetchingData={setFetchingData}
            isOpen={isOpenHistory}
            items={historyArray}
            setClose={closeHistory}
            currentItem={currentItem}
          />
        )}
      </>
    );
  }

  return (
    <div className={css['table-container']} style={{ width: '100%', flex: 'none' }}>
      <Spin spinning={fetchingData}>
        {isOpenStatusQuoteForm && (
          <QuoteStatusForm
            propertyId={propertyId}
            isOpen={isOpenStatusQuoteForm}
            id={currentId}
            setCloseStatus={setCloseStatus}
            currentQuote={currentQuote}
            setCurrentQuote={setCurrentQuote}
          />
        )}
        <QuotesControl
          activeTab={activeTab}
          pagination={pagination}
          onTableChange={onTableChange}
          setIsOpenModal={setIsOpenModal}
          propertyId={propertyId}
          isConsumer={isConsumer}
          isSupplier={isSupplier}
          setIsOpenUpload={setIsOpenUploadQuote}
        />
        {isSupplier && (
          <div
            style={{
              color: '#16122D',
              borderBottom: '1px solid #C4C6CE',
            }}>
            {t('quotes.supplier.title')}
          </div>
        )}
        <div style={isSupplier ? {} : { display: 'flex', gap: '1rem' }}>
          {!isSupplier && !isConsumer && (
            <div className={css['container-table-quote-control']}>
              {tabsTableQuote.map((el) => (
                <Button
                  key={el.id}
                  className={`${activeTab === el.id ? css['active'] : ''}`}
                  onClick={() => setActiveTab(el.id)}>
                  {el.title}
                </Button>
              ))}
            </div>
          )}
          <Table
            columns={
              isSupplier || activeTab === 2
                ? getQuotesSupplierColumns({
                    t,
                    setOpen,
                    isConsumer,
                    setOpenDelete,
                    setOpenStatus,
                    openArchiveConfirm,
                    openPreview,
                    isSupplier,
                  })
                : getQuotesColumns({
                    t,
                    setOpen,
                    isConsumer,
                    setOpenDelete,
                    setOpenStatus,
                    openArchiveConfirm,
                    openPreview,
                    openSigningConfirm,
                    consumerId: user.id,
                    openApprove,
                    openHistory,
                  })
            }
            size="small"
            className={`list-info-table ${!isSupplier && !isConsumer && css['custom-supplier-table']}`}
            rowClassName={(record) =>
              `common-table-row--pointer ${
                (!record.file ||
                  (moment(record.deadline).isBefore(moment()) &&
                    !['signed', 'rejected', 'done', 'canceled'].includes(record.statusV2.code))) &&
                'common-table-row--error'
              }`
            }
            dataSource={quotesInfo}
            showHeader={true}
            pagination={pagination}
            scroll={{ y: isConsumer ? windowHeight - 330 : windowHeight - 360 }}
            onChange={onTableChange}
            rowKey="id"
          />
        </div>
        <div className="common-flex-end-style">
          <span onClick={() => setIsOpenArchives(true)} className={`common-archive-icon-item common-animation-primary`}>
            <div className={css['archive-img']} style={{ marginRight: '0.5rem', width: '18px', height: '14px' }}></div>
            <span style={{ color: '#076ee5', marginRight: '0.5rem', textDecoration: 'underline' }}>
              {t('common.archive.title')}
            </span>
          </span>
        </div>
        {isOpenModal &&
          (isConsumer ? (
            <ConsumerQuoteForm
              setClose={setClose}
              getQuotes={() => getQuotesInfo(option)}
              currentId={currentId}
              isOpenModal={isOpenModal}
            />
          ) : (
            <QuoteForm
              setClose={setClose}
              propertyId={propertyId}
              getQuotes={() => getQuotesInfo(option)}
              currentId={currentId}
              isOpenModal={isOpenModal}
              setIsOpenUploadLoader={setIsOpenUploadLoader}
              setIsUploadingFile={setIsUploadingFile}
              isSupplier={isSupplier || activeTab === 2}
            />
          ))}
        {isOpenDeleteModal && currentId && (
          <DeleteQuoteForm
            setClose={setCloseDelete}
            quoteNumber={currentQuoteNumber}
            getQuote={() => getQuotesInfo(option)}
            currentId={currentId}
            isOpen={isOpenDeleteModal}
          />
        )}
        {isOpenArchives && (
          <ArchivesQuotes
            isOpen={isOpenArchives}
            setIsOpen={setIsOpenArchives}
            propertyId={propertyId}
            getQuotes={() => getQuotesInfo(option)}
            isConsumer={isConsumer}
            isSupplier={isSupplier || activeTab === 2}
          />
        )}
        {isOpenArchiveConfirm && (
          <ArchiveConfirmForm
            id={currentId}
            quoteNumber={currentQuoteNumber}
            isOpen={isOpenArchiveConfirm}
            setClose={closeArchiveConfirm}
          />
        )}
        {isOpenSigningConfirm && (
          <SigningConfirmForm
            id={currentId}
            isOpen={isOpenSigningConfirm}
            setClose={closeSigningConfirm}
            sign={signQuote}
            reject={rejectQuote}
            fetchingData={fetchingData}
            signInfo={signInfo}
          />
        )}
        {isOpenPreview && <PreviewPDF id={currentId} isOpen={isOpenPreview} setClose={closePreview} />}
        {isOpenUploadLoader && (
          <UploadFileLoader isOpen={isOpenUploadLoader} isLoading={isUploadingFile} setClose={closeUploadLoader} />
        )}
        {isOpenApprove && (
          <CommonApproveForm
            approve={approveItem}
            isOpen={isOpenApprove}
            reject={(id, comment) => rejectQuote(id, comment, true)}
            setClose={closeApprove}
            fetchingData={fetchingData}
            type={'quote'}
          />
        )}
        {isOpenHistory && (
          <CommonHistoryModal
            fetchingData={fetchingData}
            setFetchingData={setFetchingData}
            isOpen={isOpenHistory}
            items={historyArray}
            setClose={closeHistory}
            currentItem={currentItem}
          />
        )}
      </Spin>
    </div>
  );
};

export default QuotesListInfo;
