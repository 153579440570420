import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { RouterListModel } from '../models/RouterListModel';
import NotFoundPage from '../views/common/NotFoundPage';

interface IRoutes {
  options: RouterListModel[];
}

const Routes: React.FC<IRoutes> = ({ options }: IRoutes) => {
  var routeKeyIndex = 0;
  const parseRouteItem = (items: RouterListModel[]) => {
    let result: any[] = [];
    items.forEach((route: RouterListModel) => {
      if (route.routes && route.routes.length) result = [...result, ...parseRouteItem(route.routes)];

      if (route.children && route.children.length) result = [...result, ...parseRouteItem(route.children)];

      if (route.component) {
        result.push(
          <Route
            key={routeKeyIndex++}
            path={route.path}
            exact={route.exact}
            render={(props: any) => <route.component {...props} />}
          />,
        );
      }
    });

    return result;
  };

  return (
    <Switch>
      {parseRouteItem(options)}
      <Route path={'/'} exact={true} />
      <Route component={(props: any) => <NotFoundPage {...props} />} />
    </Switch>
  );
};

export default Routes;
