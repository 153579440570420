import HttpService from './common/httpService';
import config from '../config';
import { getApiKeyOptions } from '../helpers/getApiKeyOptions';
import buildQuery from 'odata-query';

interface ArticleUploadImagesModel {
  id: number;
  type: string;
  files: any;
  projectKey?: string | undefined;
}

class ArticlesService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getArticles(filter: any = null, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    const query = buildQuery({ filter, orderBy: ['name'] });
    return this.httpService.get(`${config.baseRequest}articles${query}`, options);
  }

  public getArticleById(id: number, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.get(`${config.baseRequest}articles/${id}`, options);
  }

  public getArticleFields(projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.get(`${config.baseRequest}articles/fields`, options);
  }

  public getArticleCategories(projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.get(`${config.baseRequest}articles/categories`, options);
  }

  public getSimpleArticleCategories(projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.get(`${config.baseRequest}articles/categories/simple`, options);
  }

  public getArticleCategoryTypes(projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.get(`${config.baseRequest}articles/categories/types`, options);
  }

  public createArticle(body: any, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.post(`${config.baseRequest}articles`, body, options);
  }

  public createCategory(body: any, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.post(`${config.baseRequest}articles/categories`, body, options);
  }

  public updateArticle(body: any, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.put(`${config.baseRequest}articles`, body, options);
  }

  public updateCategory(body: any, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.put(`${config.baseRequest}articles/categories`, body, options);
  }

  public deleteArticle(id: number, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.delete(`${config.baseRequest}articles/${id}`, null, options);
  }

  public deleteCategory(id: number, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.delete(`${config.baseRequest}articles/categories/${id}`, null, options);
  }

  public uploadImages({ id, type, projectKey, files }: ArticleUploadImagesModel) {
    const formData = new FormData();

    files
      .filter((data: any) => !!data.originFileObj)
      .forEach((data: any) => {
        formData.append('file', data.originFileObj);
      });

    const options = getApiKeyOptions(projectKey);
    return this.httpService.postFile(`${config.baseRequest}articles/images/${id}/${type}`, formData, options);
  }

  public deleteImages(ids: number[]) {
    return this.httpService.delete(`${config.baseRequest}articles/images`, ids);
  }
}

export default ArticlesService;
