import { RoleModel } from '../models/RoleModel';
import HttpService from './common/httpService';
import config from '../config';

class RolesService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getRoles() {
    return this.httpService.get(`${config.baseRequest}roles`);
  }

  public getRoleByCode(name: string) {
    return this.httpService.get(`${config.baseRequest}roles/${name}`);
  }

  public getMenuFunctions() {
    return this.httpService.get(`${config.baseRequest}functions`);
  }

  public getParams = () => {
    return this.httpService.get(`${config.baseRequest}params`);
  };

  public createRole(body: RoleModel) {
    return this.httpService.post(`${config.baseRequest}roles`, body);
  }

  public updateRole(code: string, body: RoleModel) {
    return this.httpService.put(`${config.baseRequest}roles/${code}`, body);
  }
}

export default RolesService;
