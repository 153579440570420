import moment from 'moment';
import { FilterTypes } from '../constants/filterTypes';

export const generateFilterfromObj = (filters: any) => {
  const filter = [] as any[];
  for (const prop in filters) {
    if (filters.hasOwnProperty(prop) && filters[prop].type && (filters[prop].value || filters[prop].value === false)) {
      switch (filters[prop].type) {
        case FilterTypes.SEARCH:
          filter.unshift({
            [`tolower(${prop})`]: {
              contains: encodeURIComponent(filters[prop].value.toLowerCase().replace(/ /g, '%')),
            },
          });
          break;
        case FilterTypes.INPUT:
        case FilterTypes.RADIO:
        case FilterTypes.SELECT:
          filter.push({ [`${prop}`]: encodeURIComponent(filters[prop].value) });
          break;
        case FilterTypes.CHECKBOX:
        case FilterTypes.SELECT_MULTIPLE:
          if (filters[prop].value.length) {
            filter.push({ or: filters[prop].value.map((item: any) => ({ [`${prop}`]: encodeURIComponent(item) })) });
          }
          break;
        case FilterTypes.MULTISEARCH:
          if (Object.values(filters[prop].value[0])[0]) {
            filter.push({
              or: filters[prop].value.map((item: any) => ({
                [`tolower(${Object.keys(item)[0]})`]: {
                  contains: (Object.values(item)[0] as any).toLowerCase(),
                },
              })),
            });
          }
          break;
        case FilterTypes.DATE_RANGE:
          const [from, to] = filters[prop].value;
          if (from && to) {
            filter.push({
              [`${prop}`]: {
                ge: moment(from).startOf('day').format('YYYY-MM-DD HH:mm'),
              },
            });
            filter.push({
              [`${prop}`]: {
                le: moment(to).endOf('day').format('YYYY-MM-DD HH:mm'),
              },
            });
          }
          break;
        case FilterTypes.NOT:
          filter.push({ [`${prop}`]: { ne: filters[prop].value } });
          break;
        case FilterTypes.AND:
          if (filters[prop].value.length) {
            filter.push({
              and: filters[prop].value.map((item: any) => ({
                [`${Object.keys(item)[0]}`]: encodeURIComponent(Object.values(item)[0] as any),
              })),
            });
          }
          break;
        case FilterTypes.OR:
          if (filters[prop].value.length) {
            filter.push({
              or: filters[prop].value.map((item: any) => ({
                [`${Object.keys(item)[0]}`]: encodeURIComponent(Object.values(item)[0] as any),
              })),
            });
          }
          break;
          case FilterTypes.DATE_LESS_THAN:
            const dateLessThan = filters[prop].value;
            if (dateLessThan) {
              filter.push({
                [`${prop}`]: {
                  lt: moment(dateLessThan).startOf('day').format('YYYY-MM-DD'),
                },
              });
            }
            break;
        default:
          break;
      }
    }
  }
  return { filter };
};
