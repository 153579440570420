import React, { useState, useEffect, useContext, useRef } from 'react';
import { Form, Row, Col, Input, Select, notification, Tabs, Table, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import SpacesService from '../../../services/SpacesService';
import { TypologiesContext } from '../../../contexts/TypologiesContextProvider';
import { nodeKeys } from '../enums/nodeKeys';
import NodesModifyLayout from './NodesModifyLayout';
import {
  TYPOLOGY_REFRESH_ITEMS,
  TYPOLOGY_SET_SELECTED_NODES,
  TYPOLOGY_SET_CURRENT_NODE,
} from '../../../constants/actionTypes/typologiesConstants';
import { validateObjectField } from '../../../helpers/validateObjectField';
import { getColumns } from './SpaceOptionColumns';
import SpaceOptionsModify from './SpaceOptionsModify';
import AssignOptionModal from '../../../components/AssignOption/AssignOptionModal';
import { useLessThen801 } from '../../../helpers/mediaDetect';

const service: SpacesService = new SpacesService();
enum tabs {
  base = 'base',
  option = 'option',
}

const SpaceObjectsModify: React.FC<any> = (props: any) => {
  const {
    nodes: { currentNode, currentNodeKey, parent },
    nodesDispatch,
  } = useContext(TypologiesContext);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const modifyFormRef = useRef();
  const [spaceObject, setSpaceObject] = useState<any>(undefined);
  const [options, setOptions] = useState<any[]>([]);
  const [option, setOption] = useState<any>(undefined);
  const [types, setTypes] = useState<any[]>([]);
  const [spaceId, setSpaceId] = useState<number | null>(null);
  const [activeTab, setActiveTab] = useState<string>('base');
  const [selectedOptionKeys, setSelectedOptionKeys] = useState<any[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isModifyOption, setIsModifyOption] = useState<boolean>(false);
  const [isShowAssignModal, setIsShowAssignModal] = useState<boolean>(false);
  const [isSelectedAllOptions, setIsSelectedAllOptions] = useState<boolean>(false);
  const [isCreate, setIsCreate] = useState<boolean>(currentNodeKey === nodeKeys.spaceObject && !currentNode);

  const setIsRefresh = (isRefresh: boolean) => {
    nodesDispatch({
      type: TYPOLOGY_REFRESH_ITEMS,
      isRefresh,
    });
  };

  const setSelectedNodes = (selectedNodes: any[]) => {
    nodesDispatch({
      type: TYPOLOGY_SET_SELECTED_NODES,
      selectedNodes,
    });
  };

  const setCurrentNode = (node: any, key: string) => {
    nodesDispatch({
      type: TYPOLOGY_SET_CURRENT_NODE,
      currentNode: node,
      currentNodeKey: key,
    });
  };

  const getSpaceObject = (id: number) => {
    return service
      .getSpaceObjectById(id)
      .then((res: any) => {
        const { options, ...data } = res;
        setSpaceObject(data);
        setOptions(options);
        setIsCreate(false);
        form.setFieldsValue({
          name: res.name,
          title: res.title,
          babylonCode: res.babylonCode,
          babylonParams: res.babylonParams && JSON.stringify(res.babylonParams),
          isActive: res.isActive,
          isDefault: res.isDefault,
          type: res.type,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getSpaceObjectTypes = () => {
    return service.getSpaceObjectTypes().then((res: any) => {
      setTypes(res);
    });
  };

  const getAsyncData = async () => {
    const actions = [getSpaceObjectTypes()];
    if (!isCreate) {
      actions.unshift(getSpaceObject(currentNode.id));
    }
    Promise.all(actions).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (currentNodeKey === nodeKeys.spaceObject && (!currentNode || spaceObject?.id !== currentNode?.id)) {
      setLoading(true);
      getAsyncData();
    }
    if (currentNodeKey === nodeKeys.spaceObject && isCreate) {
      setSpaceId(parent.id);
    }
  }, [currentNodeKey, currentNode]);

  useEffect(() => {
    if (spaceObject) {
      setIsCreate(false);
    }
  }, [spaceObject]);

  const onTabsChange = (activeKey: string) => {
    setActiveTab(activeKey);
  };

  const onModifyCancel = () => {
    setIsModifyOption(false);
    setOption(null);
  };

  const onEditOption = (option: any) => {
    setIsModifyOption(true);
    setOption(option);
  };

  const onDeleteOption = (id: number) => {
    service.deleteSpaceObjectOption(id).then(() => {
      getSpaceObject(currentNode.id);
    });
  };

  const onSubmit = async () => {
    const hasProperty = Object.prototype.hasOwnProperty;
    if (isModifyOption && activeTab === tabs.option) {
      const { current }: any = modifyFormRef;
      if (current) {
        const values = await current.validateFields();
        if (!hasProperty.call(values, 'errorFields')) {
          const isCreate = !option?.id;
          if (isCreate) {
            service
              .bulkCreateSpaceObjectOption({
                spaceObject: { id: currentNode.id },
                ...values,
              })
              .then(() => {
                setIsModifyOption(false);
                getSpaceObject(currentNode.id);
              });
          } else {
            service
              .updateSpaceObjectOption({
                id: option.id,
                ...values,
              })
              .then(() => {
                setIsModifyOption(false);
                getSpaceObject(currentNode.id);
              });
          }
        }
      }
    } else {
      const values = await form.validateFields();
      if (!hasProperty.call(values, 'errorFields')) {
        setIsSaving(true);
        if (isCreate) {
          service
            .createSpaceObject({
              ...values,
              ...(values.babylonParams ? { babylonParams: JSON.parse(values.babylonParams) } : {}),
              space: { id: spaceId },
            })
            .then((res: any) => {
              const key = `${nodeKeys.spaceObject}-${res.id}`;
              setSelectedNodes([key]);
              getSpaceObject(res.id);
              setCurrentNode(res, nodeKeys.spaceObject);
              notification.success({
                message: t('space.object.create.notification.create'),
              });
              setIsRefresh(true);
            })
            .catch((e) => {
              notification.error({
                message: e.message || t('common.error.internalServerError'),
              });
            })
            .finally(() => setIsSaving(false));
        } else {
          service
            .updateSpaceObject({
              ...values,
              ...(values.babylonParams ? { babylonParams: JSON.parse(values.babylonParams) } : {}),
              id: currentNode.id,
            })
            .then(() => {
              getSpaceObject(currentNode.id);
              notification.success({
                message: t('space.object.create.notification.update'),
              });
              setIsRefresh(true);
            })
            .catch((e) => {
              notification.error({
                message: e.message || t('common.error.internalServerError'),
              });
            })
            .finally(() => setIsSaving(false));
        }
      }
    }
  };

  const onAssignOptions = (selectedKeys: any[], isSelectedAllObjects: boolean) => {
    const options = option ? [option.id] : selectedOptionKeys;
    if (options.length > 0) {
      service
        .assignOptionToSpaceObject({
          currentObjectId: spaceObject.id,
          options,
          objects: selectedKeys,
          isSelectedAllObjects,
          isSelectedAllOptions,
        })
        .then(() => {
          notification.success({
            message: t('space.object.option.assign.success'),
          });
        })
        .finally(() => setIsShowAssignModal(false));
    }
  };

  const handleSelectOption = (values: any) => {
    setSelectedOptionKeys(values);
  };

  if (useLessThen801()) {
    return (
      <NodesModifyLayout
        submit={onSubmit}
        cancel={onModifyCancel}
        loading={loading}
        isSaving={isSaving}
        isShowCreate={!isModifyOption && activeTab === tabs.option}
        isCustomCancel={isModifyOption && activeTab === tabs.option}
        isShowApply={activeTab === tabs.option && (!!option || selectedOptionKeys.length > 0)}
        isShowSave={activeTab === tabs.base || isModifyOption}
        onCreate={() => setIsModifyOption(true)}
        onHandleAssign={() => setIsShowAssignModal(true)}>
        <Tabs onChange={onTabsChange}>
          <Tabs.TabPane key={tabs.base} tab={t('space.object.create.basic.tab.title')}>
            <Form autoComplete="off" form={form} layout="vertical" name="modify_space_object">
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Form.Item
                    label={t('space.object.create.name')}
                    name="name"
                    rules={[{ required: true, message: t('space.object.create.name.validation') }]}>
                    <Input className="common-animation-primary" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={t('space.object.create.title')}
                    name="title"
                    rules={[{ required: true, message: t('space.object.create.title.validation') }]}>
                    <Input className="common-animation-primary" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label={t('space.object.create.types')} name={['type', 'id']}>
                    <Select
                      className="budget-control-select common-select common-animation-primary"
                      allowClear
                      placeholder={t('common.goods.placeholder')}>
                      {types.map((item: any) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item?.description}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label={t('space.object.create.babylonCode')} name="babylonCode">
                    <Input className="common-animation-primary" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={t('space.object.create.babylonParams')}
                    name="babylonParams"
                    rules={[validateObjectField('babylonParams')]}>
                    <Input.TextArea rows={4} className="common-animation-primary" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane key={tabs.option} tab={t('space.object.create.option.tab.title')} disabled={isCreate}>
            {!isModifyOption ? (
              <Table
                columns={getColumns({
                  t,
                  onEdit: onEditOption,
                  onDelete: onDeleteOption,
                })}
                size="small"
                dataSource={options}
                showHeader={true}
                rowKey="id"
                rowClassName="common-table-row--pointer"
                rowSelection={{
                  selectedRowKeys: selectedOptionKeys,
                  onChange: handleSelectOption,
                  onSelectAll: (selected) => setIsSelectedAllOptions(selected),
                }}
              />
            ) : (
              <Card title={t(`space.object.${!option ? 'create' : 'update'}.option.title`)}>
                <SpaceOptionsModify ref={modifyFormRef} option={option} options={options} />
              </Card>
            )}
          </Tabs.TabPane>
        </Tabs>
        {isShowAssignModal && (
          <AssignOptionModal
            spaceObject={spaceObject}
            isVisible={isShowAssignModal}
            onOk={onAssignOptions}
            onCancel={() => setIsShowAssignModal(false)}
          />
        )}
      </NodesModifyLayout>
    );
  }

  return (
    <NodesModifyLayout
      submit={onSubmit}
      cancel={onModifyCancel}
      loading={loading}
      isSaving={isSaving}
      isShowCreate={!isModifyOption && activeTab === tabs.option}
      isCustomCancel={isModifyOption && activeTab === tabs.option}
      isShowApply={activeTab === tabs.option && (!!option || selectedOptionKeys.length > 0)}
      isShowSave={activeTab === tabs.base || isModifyOption}
      onCreate={() => setIsModifyOption(true)}
      onHandleAssign={() => setIsShowAssignModal(true)}>
      <Tabs onChange={onTabsChange}>
        <Tabs.TabPane key={tabs.base} tab={t('space.object.create.basic.tab.title')}>
          <Form autoComplete="off" form={form} layout="vertical" name="modify_space_object">
            <Row gutter={[24, 24]}>
              <Col span={12}>
                <Form.Item
                  label={t('space.object.create.name')}
                  name="name"
                  rules={[{ required: true, message: t('space.object.create.name.validation') }]}>
                  <Input className="common-animation-primary" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('space.object.create.title')}
                  name="title"
                  rules={[{ required: true, message: t('space.object.create.title.validation') }]}>
                  <Input className="common-animation-primary" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t('space.object.create.types')} name={['type', 'id']}>
                  <Select
                    className="budget-control-select common-select common-animation-primary"
                    allowClear
                    placeholder={t('common.goods.placeholder')}>
                    {types.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item?.description}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t('space.object.create.babylonCode')} name="babylonCode">
                  <Input className="common-animation-primary" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('space.object.create.babylonParams')}
                  name="babylonParams"
                  rules={[validateObjectField('babylonParams')]}>
                  <Input.TextArea rows={4} className="common-animation-primary" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane key={tabs.option} tab={t('space.object.create.option.tab.title')} disabled={isCreate}>
          {!isModifyOption ? (
            <Table
              columns={getColumns({
                t,
                onEdit: onEditOption,
                onDelete: onDeleteOption,
              })}
              size="small"
              dataSource={options}
              showHeader={true}
              rowKey="id"
              rowClassName="common-table-row--pointer"
              rowSelection={{
                selectedRowKeys: selectedOptionKeys,
                onChange: handleSelectOption,
                onSelectAll: (selected) => setIsSelectedAllOptions(selected),
              }}
            />
          ) : (
            <Card title={t(`space.object.${!option ? 'create' : 'update'}.option.title`)}>
              <SpaceOptionsModify ref={modifyFormRef} option={option} options={options} />
            </Card>
          )}
        </Tabs.TabPane>
      </Tabs>
      {isShowAssignModal && (
        <AssignOptionModal
          spaceObject={spaceObject}
          isVisible={isShowAssignModal}
          onOk={onAssignOptions}
          onCancel={() => setIsShowAssignModal(false)}
        />
      )}
    </NodesModifyLayout>
  );
};

export default SpaceObjectsModify;
