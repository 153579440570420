import useMedia from 'use-media';

export const useLessThen1440 = () => {
  return useMedia({ maxWidth: '1440px' });
};

export const useLessThen1100 = () => {
  return useMedia({ maxWidth: '1100px' });
};

export const useLessThen801 = () => {
  return useMedia({ maxWidth: '801px' });
};

export const useLessThen768 = () => {
  return useMedia({ maxWidth: '768px' });
};

export const useLessThen640 = () => {
  return useMedia({ maxWidth: '640px' });
};

export const useLessThen480 = () => {
  return useMedia({ maxWidth: '480px' });
};
