import HttpService from './common/httpService';
import config from '../config';
import buildQuery from 'odata-query';
import { ICreateQuoteModel, IUpdateQuoteModel } from '../models/QuotesModel';

class QuotesService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getQuotes(option: any, propertyId?: number | null) {
    const { top, count, skip, filters, orderBy = null } = option;

    let filter = [];

    let defaultFilter: any = {
      'property/id': {
        eq: propertyId,
      },
    };

    if (!propertyId) {
      defaultFilter = {};
    }

    if (filters) {
      if (Object.keys(defaultFilter).length) {
        filter = [defaultFilter, ...filters.filter];
      } else {
        filter = [...filters.filter];
      }
      filter.sort((a: any, b: any) =>
        Object.keys(Object.values(a)[0] as any)[0] == 'contains'
          ? -1
          : Object.keys(Object.values(b)[0] as any)[0] == 'contains'
          ? 1
          : 0,
      );
    } else {
      filter = defaultFilter;
    }

    const query = buildQuery({ filter, top, count, skip, orderBy });
    return this.httpService.get(`${config.baseRequest}quotes${query}`);
  }

  public getQuotesNoArchived(propertyId: number | null, option: any) {
    const { top, count, skip, filters, orderBy = null } = option;

    let filter: any = [];

    let defaultFilter: any = {
      'property/id': {
        eq: propertyId,
      },
      isArchive: {
        eq: false,
      },
    };

    if (!propertyId) {
      defaultFilter = {
        isArchive: {
          eq: false,
        },
      };
    }

    if (filters) {
      filters.filter.sort((a: any, b: any) => {
        const check = (item: any) => {
          if (
            Object.values(item).length > 0 &&
            (Object.values(item)[0] as any).length > 0 &&
            Object.values(Object.values(item)[0] as any).length > 0 &&
            Object.values((Object.values(item)[0] as any)[0] as any).length > 0
          ) {
            return Object.keys(Object.values((Object.values(item)[0] as any)[0] as any)[0] as any)[0] == 'contains';
          }
          return false;
        };

        return Object.keys(Object.values(a)[0] as any)[0] == 'contains' || check(a)
          ? -1
          : Object.keys(Object.values(b)[0] as any)[0] == 'contains'
          ? 1
          : 0;
      });
      filter = [...filters.filter, defaultFilter];

      filter.sort((a: any, b: any) => {
        const check = (item: any) => {
          if (
            Object.values(item).length > 0 &&
            (Object.values(item)[0] as any).length > 0 &&
            Object.values(Object.values(item)[0] as any).length > 0 &&
            Object.values((Object.values(item)[0] as any)[0] as any).length > 0
          ) {
            return Object.keys(Object.values((Object.values(item)[0] as any)[0] as any)[0] as any)[0] == 'contains';
          }
          return false;
        };

        return Object.keys(Object.values(a)[0] as any)[0] == 'contains' || check(a)
          ? -1
          : Object.keys(Object.values(b)[0] as any)[0] == 'contains'
          ? 1
          : 0;
      });
    } else {
      filter = defaultFilter;
    }

    const query = buildQuery({ filter, top, count, skip, orderBy });
    return this.httpService.get(`${config.baseRequest}quotes${query}`);
  }

  public getQuoteInfoById(id: number) {
    return this.httpService.get(`${config.baseRequest}quotes/${id}`);
  }

  public createQuote(body: ICreateQuoteModel) {
    return this.httpService.post(`${config.baseRequest}quotes`, body);
  }

  public updateQuote(body: IUpdateQuoteModel) {
    return this.httpService.put(`${config.baseRequest}quotes`, body);
  }

  public rejectQuote(id: number | null, comment: string, envelopeId?: string) {
    const body = id ? { id, comment } : { envelopeId, comment };
    return this.httpService.patch(`${config.baseRequest}quotes/step/reject`, body);
  }

  public deleteQuote(id: number) {
    return this.httpService.delete(`${config.baseRequest}quotes/${id}`);
  }

  public uploadQuoteFiles(updateId: number, file: any) {
    return this.httpService.postFile(`${config.baseRequest}quotes/${updateId}/file`, file);
  }

  public getSigningUrl(id: number, returnUrl: string, consumerId: number) {
    return this.httpService.post(`${config.baseRequest}quotes/step/redirect`, { id, consumerId, returnUrl });
  }

  public confirmStep(envelopeId?: string, id?: number) {
    return this.httpService.patch(`${config.baseRequest}quotes/step/done`, envelopeId ? { envelopeId } : { id });
  }

  public getQuoteFile = (fileName: string) => {
    return this.httpService.getFile(`${config.baseRequest}file/binary/${fileName}`).then((res: any) => {
      let file = new File([res], fileName);
      return file;
    });
  };
  public approveQuote(id: number) {
    return this.httpService.put(`${config.baseRequest}quotes/${id}/approve`, {});
  }
  public getQuoteHistory(id: number) {
    return this.httpService.get(`${config.baseRequest}quotes/${id}/history`);
  }
  public downloadQuotes(option: any) {
    const { filters, orderBy = null } = option;

    let filter = [];

    let defaultFilter: any = {
      isArchive: {
        eq: false,
      },
    };

    if (filters) {
      filter = [...filters.filter, defaultFilter];
      filter.sort((a: any, b: any) => {
        const check = (item: any) => {
          if (
            Object.values(item).length > 0 &&
            (Object.values(item)[0] as any).length > 0 &&
            Object.values(Object.values(item)[0] as any).length > 0 &&
            Object.values((Object.values(item)[0] as any)[0] as any).length > 0
          ) {
            return Object.keys(Object.values((Object.values(item)[0] as any)[0] as any)[0] as any)[0] == 'contains';
          }
          return false;
        };

        return Object.keys(Object.values(a)[0] as any)[0] == 'contains' || check(a)
          ? -1
          : Object.keys(Object.values(b)[0] as any)[0] == 'contains'
          ? 1
          : 0;
      });
    } else {
      filter = defaultFilter;
    }

    const query = buildQuery({ filter, orderBy });
    return this.httpService.getFile(`${config.baseRequest}quotes/archive/zip${query}`);
  }
}

export default QuotesService;
