export default {
  defaultLng: process.env.REACT_APP_DEFAULT_LANGUAGE || 'fr-FR',
  baseRequest: process.env.REACT_APP_BASE_REQUEST,
  authRequest: process.env.REACT_APP_AUTH_REQUEST,
  storeUrl: process.env.REACT_APP_STORE_URL,
  appPreview: process.env.REACT_APP_PREVIEW,
  baseTimeOut: process.env.REACT_APP_BASE_TIME_OUT,
  enableTracking: process.env.REACT_APP_ENABLE_TRACKING,
  enableHotjar: process.env.REACT_APP_ENABLE_HOTJAR === 'true',
  gaTrackingId: process.env.REACT_APP_GA_TRAKING_ID,
  hotjarId: process.env.REACT_APP_HJID,
  hotjarSv: process.env.REACT_APP_HJSV,
  enableGTM: process.env.REACT_APP_ENABLE_GTM_TRACKING,
  gtmId: process.env.REACT_APP_GTM_TRACKING_ID,
  notificationSocket: process.env.REACT_APP_NOTIFICATION_SOCKET,
  enableProcoreAuth: process.env.REACT_APP_ENABLE_PROCORE_AUTH,
  procoreAuthUrl: process.env.REACT_APP_PROCORE_AUTH_URL,
  procoreResponseType: process.env.REACT_APP_PROCORE_RESPONSE_TYPE,
  procoreClientId: process.env.REACT_APP_PROCORE_CLIENT_ID,
  procoreRedirectUri: process.env.REACT_APP_PROCORE_REDIRECT_URI,
  enableOnboarding: process.env.REACT_APP_ENABLE_ONBOARDING,
  recaptchaPublicSiteKey: process.env.REACT_APP_RECAPTCHA_PUBLIC_SITE_KEY || '',
  enableRecaptcha: process.env.REACT_APP_ENABLE_RECAPTCHA === 'true',
  gptTokensLimit: process.env.REACT_APP_GPT_TOKENS_LIMIT || 3000,
  arkaUrl: process.env.REACT_APP_ARKA_URL,
  arkaProjectsId: process.env.REACT_APP_ARKA_PROJECTS_ID,
};
