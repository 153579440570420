import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { Form, Input, Button, Row, Col, Avatar, Image, Space, Upload, notification, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import UsersService from '../../services/UsersService';
import config from '../../config';
import { DeleteOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { UploadChangeParam } from 'antd/lib/upload';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { getBase64Url } from '../../helpers/avatarTools';
import css from '../../assets/styles/consumers.module.css';
import ProjectFields from './components/ProjectFields';
import DefaultProjectFields from './components/DefaultProjectFields';
import { AppContext } from '../../contexts/AppContextProvider';
import { CHANGE_CURRENT_PAGE } from '../../constants/actionTypes/headerConstants';
import { HeaderContext } from '../../contexts/HeaderContextProvider';
import editIcon from '../../assets/images/ic-edit-blue.svg';
import checkIcon from '../../assets/images/ic-check-mark.svg';
import moment from 'moment';
import ConsumersService from '../../services/ConsumersService';
import ConfirmChangeEmailModal from './components/ConfirmChangeEmailModal';
import { IUserFull, UserCreateModel } from '../../models/UserModel';
import { IProjectCompany } from '../../models/ProjectModel';
import { IAppContext } from '../../typings/IApp';

const userService: UsersService = new UsersService();
const consumerService = new ConsumersService();

const ConsumerModifyPage: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const isCreate = !id;
  const [form] = Form.useForm();
  const [userModify, setUserModify] = useState<IUserFull | undefined>(undefined);
  const [breadcrumbNameMap, setBreadcrumbNameMap] = useState<{ [name: string]: string }>({});
  const [image, setImage] = useState<UploadFile | null>(null);
  const [imageUrl, setImageUrl] = useState<string | ArrayBuffer | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDeleteImage, setIsDeleteImage] = useState<boolean>(false);
  const [isLoadingImage, setIsLoadingImage] = useState<boolean>(false);
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [userProjects, setUserProjects] = useState<IProjectCompany[]>([]);
  const [isEmailEdit, setIsEmailEdit] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { headerDispatch } = useContext(HeaderContext);
  const {
    app: { user, isSupplier },
  } = useContext<IAppContext>(AppContext);

  const projects = user && user.projects;

  const getUser = () => {
    userService.getUserById(+id).then((user: IUserFull) => {
      setUserModify(user);
      form.setFieldsValue({
        login: user.login,
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
        email: user.email,
        bookingLink: user.bookingLink,
        defaultFields: user.projects?.map((property) => property.id) || [],
        payment_street: user.paymentInfo?.street,
        payment_city: user.paymentInfo?.city,
        payment_zip: user.paymentInfo?.zip,
        payment_state: user.paymentInfo?.state,
        payment_country: user.paymentInfo?.country,
      });
      setSelectedProjects(user.projects?.map((property) => property.key));
      setUserProjects(user.projects);
      if (user.image) {
        setImageUrl(`${config.storeUrl}/${user.image}`);
      }
    });
  };

  useEffect(() => {
    if (!isCreate) {
      getUser();
    }
  }, []);

  useEffect(() => {
    if (isCreate) {
      headerDispatch({
        type: CHANGE_CURRENT_PAGE,
        currentPage: t('header.title.Consumers.create'),
        path: 'consumers/modify',
      });
    }
  }, []);

  useEffect(() => {
    setBreadcrumbNameMap(
      history.location.pathname
        .replace('/consumers', '')
        .replace(/\/modify\/\d+/, '/Modifications')
        .replace(/\/modify/, `/${t('common.title.create')}`)
        .split('/')
        .slice(1)
        .reduce(
          (res, curr, index) => ({
            ...res,
            [[Object.keys(res)[index], curr].join('/')]: curr.split(':').shift(),
          }),
          { '/consumers': t('header.title.Consumers') },
        ),
    );
  }, [history.location.pathname]);

  const beforeUploadImage = (file: RcFile) => {
    setIsLoadingImage(true);
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      notification.error({ message: t('projects.create.logo.mimetype.error') });
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      notification.error({ message: t('projects.create.logo.fileSize.error') });
    }
    setIsLoadingImage(false);
    return isJpgOrPng && isLt2M;
  };

  const handleChangeImage = (info: UploadChangeParam<UploadFile<any>>) => {
    const { file } = info;
    getBase64Url(file.originFileObj, (imageUrl: string | ArrayBuffer | null) => {
      setImage(file);
      setImageUrl(imageUrl);
      setIsLoadingImage(false);
    });
  };

  const deleteImage = () => {
    if (imageUrl) {
      setImage(null);
      setImageUrl(null);
      setIsDeleteImage(true);
    }
  };

  const onSubmit = (values: any) => {
    if (
      (!values.fields || values.fields.length === 0) &&
      (!values.defaultFields || values.defaultFields.length === 0)
    ) {
      notification.error({
        message: t('consumers.create.error.no.property'),
      });
      return;
    }

    if (
      (values.payment_street && values.payment_city && values.payment_zip && values.payment_country) ||
      (!values.payment_street && !values.payment_city && !values.payment_zip && !values.payment_country)
    ) {
      const defaultProperties = values.defaultFields && values.defaultFields.map((field: any) => field.property).flat();
      const defaultPropertiesArray = defaultProperties && [...defaultProperties];
      const newProperties = values.fields && values.fields.map((field: any) => field.property).flat();
      let resultProperties;
      if (!defaultPropertiesArray) {
        resultProperties = [...newProperties];
      } else if (!newProperties) {
        resultProperties = defaultPropertiesArray;
      } else {
        resultProperties = defaultPropertiesArray.concat(...newProperties);
      }
      const defaultProjects = values.defaultFields && values.defaultFields.map((field: any) => field.project);
      const newProjects = values.fields && values.fields.map((field: any) => field.project);
      const defaultProjectsArray =
        defaultProjects &&
        projects
          .filter((f: any) => defaultProjects.some((item: any) => item === f.title || item === f.key))
          .map((item: any) => item.id);
      const newProjectsArray =
        newProjects &&
        projects.filter((f: any) => newProjects.some((item: any) => item === f.key)).map((item: any) => item.id);
      let resultProjects;
      if (!defaultProjects) {
        resultProjects = [...newProjectsArray];
      } else if (!newProjects) {
        resultProjects = defaultProjectsArray;
      } else {
        resultProjects = defaultProjectsArray.concat(...newProjectsArray);
      }

      setIsSaving(true);
      const body: { [name: string]: any } = {
        firstName: values.firstName,
        projects: resultProjects,
        properties: resultProperties,
        roles: ['consumer'],
        login: values.email.trim(),
        lastName: values.lastName,
        phone: values.phone,
        email: values.email,
      };

      if (
        !values.payment_street &&
        !values.payment_city &&
        !values.payment_zip &&
        !values.payment_state &&
        !values.payment_country
      ) {
        body.paymentInfo = null;
      } else {
        body.paymentInfo = {
          firstName: values.firstName,
          lastName: values.lastName,
          street: values.payment_street,
          city: values.payment_city,
          zip: values.payment_zip,
          state: values.payment_state || null,
          country: values.payment_country,
        };
      }
      if (userModify?.paymentInfo?.id) {
        body.paymentInfo.id = userModify.paymentInfo.id;
      }

      const modelActions = (id: number) => {
        const promises: Promise<any>[] = [];
        if (image) {
          promises.push(userService.uploadImage(id, image));
        }
        if (isDeleteImage && !imageUrl) {
          promises.push(userService.deleteImage(id));
        }
        Promise.all(promises).finally(() => {
          setIsSaving(false);
          !isEmailEdit && history.push('/consumers');
          setIsEmailEdit(false);
        });
      };

      if (isCreate) {
        userService
          .createUser(body as UserCreateModel)
          .then((res: any) => {
            modelActions(res.id);
            if (res.responseStatus === 202) {
              notification.warning({
                message: `${t('consumers.create.success.notify2.1')} ${res.email} ${t(
                  'consumers.create.success.notify2.2',
                )}`,
              });
            } else {
              notification.success({
                message: t('consumers.create.success.notify'),
              });
            }
          })
          .catch((e) => {
            notification.error({
              message: e.message,
            });
            setIsSaving(false);
          });
      } else {
        userService
          .updateUser(+id, body)
          .then(() => {
            modelActions(+id);
            notification.success({
              message: t('consumers.update.success.notify'),
            });
          })
          .catch((e) => {
            notification.error({
              message: e.message,
            });
            setIsSaving(false);
          });
      }
    } else {
      notification.error({
        message: t('common.fill.in.general.info'),
      });
      return;
    }
  };

  const resendEmail = () => {
    if (userModify) {
      setIsSaving(true);
      consumerService
        .resendEmail(userModify.id)
        .then(() => {
          notification.success({
            message: t('consumers.email.resend.notification'),
          });
        })
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        )
        .finally(() => setIsSaving(false));
    }
  };

  const onCancel = () => {
    history.push('/consumers');
  };

  const checkSuperBroker = () => {
    if (userModify && user.rolesParams.IS_SUPER_BROKER) {
      return !isEmailEdit;
    }
    return !!userModify;
  };

  const handleSave = () => {
    if (userModify && user.rolesParams.IS_SUPER_BROKER && isEmailEdit) {
      return setOpenModal(true);
    }
    form.submit();
  };

  const onOk = () => {
    setIsEmailEdit(false);
    form.submit();
    setOpenModal(false);
  };

  const handleCheckEditEmail = () => {
    if (isEmailEdit) {
      return form.submit();
    }
    setIsEmailEdit(!isEmailEdit);
  };

  const unblockUser = () => {
    setIsSaving(true);
    userService
      .unblock(userModify!.id)
      .then(() => {
        getUser();
        notification.success({
          message: t('consumers.unblock.success.notify'),
        });
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setIsSaving(false));
  };

  return (
    <Spin spinning={isSaving}>
      <Form
        autoComplete="off"
        form={form}
        layout="vertical"
        name="modify_users"
        onFinish={onSubmit}
        className="consumer-modify-container">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <HeaderTable breadcrumb={breadcrumbNameMap} />
          <Form.Item style={{ display: 'flex', alignItems: 'center', marginBottom: 0 }}>
            <Button
              htmlType="button"
              onClick={onCancel}
              className={`common-blue-border-btn common-secondary-btn`}
              style={{ height: '42px', marginRight: '1rem' }}>
              {t('common.btn.cancel')}
            </Button>
            <Button className={`common-blue-btn common-primary-btn`} onClick={handleSave} style={{ height: '42px' }}>
              {t('common.btn.save')}
            </Button>
          </Form.Item>
        </div>
        <Row
          gutter={[12, 4]}
          style={{ paddingTop: '24px', height: '82vh' }}
          className={css['consumer-modify-container']}>
          <Col span={13} style={{ height: '100%' }} className={css['consumer-modify-col']}>
            <div
              className={css['consumers-table-container']}
              style={{ padding: '36px', height: '100%', overflowY: 'auto' }}>
              <Row>
                <Form.Item>
                  {imageUrl ? (
                    <Avatar
                      src={
                        <>
                          <Image src={imageUrl as string} preview={false} />
                          <div className="logo-mask">
                            <Space>
                              <Upload
                                accept=".png, .jpg, .jpeg"
                                showUploadList={false}
                                beforeUpload={beforeUploadImage}
                                onChange={handleChangeImage}
                                customRequest={() => {}}>
                                <Button type="link" size="large">
                                  <EditOutlined style={{ fontSize: '24px' }} />
                                </Button>
                              </Upload>
                              <Button type="link" size="large" onClick={deleteImage}>
                                <DeleteOutlined style={{ fontSize: '24px' }} />
                              </Button>
                            </Space>
                          </div>
                        </>
                      }
                      className="consumer-avatar"
                      size={150}
                    />
                  ) : (
                    <Upload.Dragger
                      accept=".png, .jpg, .jpeg"
                      listType="picture-card"
                      showUploadList={false}
                      beforeUpload={beforeUploadImage}
                      onChange={handleChangeImage}
                      customRequest={() => {}}
                      className="consumer-modify-upload">
                      <div className="consumer-modify-upload">
                        {isLoadingImage ? <LoadingOutlined /> : <div className="consumer-modify-upload-icon"></div>}
                        <div style={{ marginTop: 8, marginLeft: 4, marginRight: 4 }}>
                          {t('consumers.btn.upload.profile')}
                        </div>
                      </div>
                    </Upload.Dragger>
                  )}
                </Form.Item>
              </Row>
              <Row>
                <div className={css['consumer-table-group-title']}>{t('consumers.table.group.personal.info')}</div>
                <Row className={css['consumer-table-group-row']}>
                  <Form.Item
                    style={{ width: '100%' }}
                    label={<span style={{ color: '#778dac' }}>{t('consumers.create.card.user.firstName')}</span>}
                    name="firstName"
                    rules={[{ required: true, message: t('common.validation.required') }]}>
                    <Input className="consumer-form-input common-animation-primary" />
                  </Form.Item>
                  <Form.Item
                    style={{ width: '100%' }}
                    label={<span style={{ color: '#778dac' }}>{t('consumers.create.card.user.lastName')}</span>}
                    name="lastName"
                    rules={[{ required: true, message: t('common.validation.required') }]}>
                    <Input className="consumer-form-input common-animation-primary" />
                  </Form.Item>
                </Row>
                <Row className={css['consumer-table-group-row']} style={{ position: 'relative' }}>
                  <Form.Item
                    style={{ width: '100%' }}
                    label={<span style={{ color: '#778dac' }}>{t('consumers.create.card.user.phone')}</span>}
                    rules={[{ required: true, message: t('common.validation.required') }]}
                    name="phone">
                    <Input className="consumer-form-input common-animation-primary" />
                  </Form.Item>
                  <Form.Item
                    style={{ width: '100%' }}
                    label={<span style={{ color: '#778dac' }}>{t('consumers.create.card.user.email')}</span>}
                    rules={[{ required: true, message: t('common.validation.required') }, { type: 'email' }]}
                    name="email">
                    <Input className="consumer-form-input common-animation-primary" disabled={checkSuperBroker()} />
                  </Form.Item>
                  {userModify && user.rolesParams.IS_SUPER_BROKER && (
                    <Button
                      className={css['consumer-edit-email-btn']}
                      icon={<img src={isEmailEdit ? checkIcon : editIcon} />}
                      onClick={() => handleCheckEditEmail()}></Button>
                  )}
                </Row>
              </Row>
              <Row>
                <div className={css['consumer-table-group-title']}>{t('consumers.table.group.document.info')}</div>
                <Row className={css['consumer-table-group-row']}>
                  <Form.Item
                    style={{ width: '100%' }}
                    name="payment_street"
                    className={css['form-item']}
                    label={<span style={{ color: '#778dac' }}>{t('consumers.create.user.payment.street')}</span>}
                    rules={[{ required: true, message: t('common.validation.required') }]}>
                    <Input className="consumer-form-input common-animation-primary" />
                  </Form.Item>
                </Row>
                <Row className={css['consumer-table-group-row']}>
                  <Form.Item
                    style={{ width: '100%' }}
                    name="payment_zip"
                    className={css['form-item']}
                    label={<span style={{ color: '#778dac' }}>{t('consumers.create.user.payment.zip')}</span>}
                    rules={[{ required: true, message: t('common.validation.required') }]}>
                    <Input className="consumer-form-input common-animation-primary" />
                  </Form.Item>
                  <Form.Item
                    style={{ width: '100%' }}
                    name="payment_city"
                    className={css['form-item']}
                    label={<span style={{ color: '#778dac' }}>{t('consumers.create.user.payment.city')}</span>}
                    rules={[{ required: true, message: t('common.validation.required') }]}>
                    <Input className="consumer-form-input common-animation-primary" />
                  </Form.Item>
                </Row>
                <Row className={css['consumer-table-group-row']}>
                  <Form.Item
                    style={{ width: '100%' }}
                    name="payment_state"
                    className={css['form-item']}
                    label={<span style={{ color: '#778dac' }}>{t('consumers.create.user.payment.state')}</span>}>
                    <Input className="consumer-form-input common-animation-primary" />
                  </Form.Item>
                  <Form.Item
                    style={{ width: '100%' }}
                    name="payment_country"
                    className={css['form-item']}
                    label={<span style={{ color: '#778dac' }}>{t('consumers.create.user.payment.country')}</span>}
                    rules={[{ required: true, message: t('common.validation.required') }]}>
                    <Input className="consumer-form-input common-animation-primary" />
                  </Form.Item>
                </Row>
              </Row>
            </div>
          </Col>
          <Col span={11} style={{ height: '100%' }} className={css['consumer-modify-col']}>
            <div
              className={css['consumers-table-container']}
              style={{ padding: '36px', height: '100%', overflowY: 'auto' }}>
              <Row>
                <div className={css['consumer-table-group-title']}>{t('consumers.table.group.property.info')}</div>
                <Form.List name="defaultFields">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <Row key={field.name} gutter={[0, 4]} className={css['consumer-table-group-row__right-col']}>
                          {userProjects.length > 0 && (
                            <>
                              <DefaultProjectFields
                                key={userProjects[index].id}
                                projects={projects}
                                user={userModify}
                                consumerProject={userProjects[index]}
                                index={index}
                                selectedProjects={selectedProjects}
                                setSelectedProjects={setSelectedProjects}
                                form={form}
                              />
                              <Button
                                onClick={() => {
                                  remove(field.name);
                                  const newUserProjects = userProjects.slice(0);
                                  newUserProjects.splice(index, 1);
                                  setUserProjects(newUserProjects);
                                  const fieldValue = form.getFieldsValue();
                                  const newDefaultFields =
                                    fieldValue.defaultFields &&
                                    fieldValue.defaultFields.map((field: { project: string }) => field.project);
                                  const newFields =
                                    fieldValue.fields &&
                                    fieldValue.fields.map((field: { project: string }) => field.project);
                                  const newSelectedProjects = newFields
                                    ? newDefaultFields.concat(newFields)
                                    : newDefaultFields;
                                  setSelectedProjects(newSelectedProjects);
                                }}
                                className={css['consumer-table-btn-delete']}></Button>
                            </>
                          )}
                        </Row>
                      ))}
                    </>
                  )}
                </Form.List>
                <Form.List name="fields">
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        {fields.map((field, index) => (
                          <Row key={field.key} gutter={[0, 4]} className={css['consumer-table-group-row__right-col']}>
                            <ProjectFields
                              projects={projects}
                              user={userModify}
                              selectedProjects={selectedProjects}
                              setSelectedProjects={setSelectedProjects}
                              index={index}
                              remove={remove}
                              form={form}
                            />
                            <Button
                              onClick={() => {
                                remove(field.name);
                                const fieldValue = form.getFieldsValue();
                                const newDefaultFields =
                                  fieldValue.defaultFields &&
                                  fieldValue.defaultFields.map((field: { project: string }) => field.project);
                                const newFields =
                                  fieldValue.fields &&
                                  fieldValue.fields.map((field: { project: string }) => field.project);
                                const newSelectedProjects = newDefaultFields
                                  ? newDefaultFields.concat(newFields)
                                  : newFields;
                                setSelectedProjects(newSelectedProjects);
                              }}
                              className={css['consumer-table-btn-delete']}></Button>
                          </Row>
                        ))}
                        <Row className={css['create-add-project-container']} style={{ marginBottom: '24px' }}>
                          <Button
                            className={`common-gray-border-btn ${css['common-consumer-modify-btn']}`}
                            onClick={() => add()}>
                            {t('consumers.create.add.project')}
                          </Button>
                        </Row>
                      </>
                    );
                  }}
                </Form.List>
              </Row>
              <Row>
                <div className={css['consumer-table-group-title']}>{t('consumers.table.group.tech.info')}</div>
                <Row gutter={[0, 4]} className={css['consumer-table-group-row__right-col']}>
                  <Col span={24}>
                    <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ color: '#778dac' }}>{t('consumers.email.confirmed')}</span>
                        {userModify && userModify.isConfirmed ? (
                          <span style={{ color: '#00d2ab', marginLeft: '2rem' }}>{t('common.confirm.btn.yes')}</span>
                        ) : (
                          <span style={{ color: '#eb7d7e', marginLeft: '2rem' }}>{t('common.confirm.btn.cancel')}</span>
                        )}
                      </span>
                      {!isSupplier && userModify && !userModify.isConfirmed && (
                        <Button
                          className={`common-blue-btn common-primary-btn ${css['common-consumer-modify-btn']}`}
                          onClick={() => resendEmail()}>
                          {t('consumers.email.resend.btn')}
                        </Button>
                      )}
                    </Row>
                    {userModify && userModify.lastLoginDate && (
                      <Row
                        style={{
                          marginTop: '1rem',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                        }}>
                        <span style={{ color: '#778dac' }}>{t('consumers.table.columns.lastLoginDate')}</span>
                        <span style={{ marginLeft: '1rem' }}>{`${moment(userModify.lastLoginDate).format(
                          'YYYY.MM.DD HH:MM',
                        )}`}</span>
                      </Row>
                    )}
                    {user.rolesParams.IS_SUPER_BROKER && userModify && userModify.lockedDate && (
                      <div style={{ marginTop: '1rem', float: 'right' }} className={css['unlock-container']}>
                        <Button
                          className={`common-blue-btn common-primary-btn ${css['common-consumer-modify-btn']}`}
                          onClick={unblockUser}>
                          {t('consumers.btn.unblock')}
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
              </Row>
            </div>
          </Col>
        </Row>
      </Form>
      <ConfirmChangeEmailModal isOpen={openModal} onOk={onOk} onCancel={() => setOpenModal(false)} />
    </Spin>
  );
};

export default ConsumerModifyPage;
