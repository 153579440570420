import HttpService from './common/httpService';
import config from '../config';
import { getApiKeyOptions } from '../helpers/getApiKeyOptions';
import generateODataQuery, { IODataModel } from '../helpers/generateODataQuery';

class SpacesService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getSpaces(projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.get(`${config.baseRequest}spaces`, options);
  }

  public getSpaceById(id: number, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.get(`${config.baseRequest}spaces/${id}`, options);
  }

  public getSpaceTypes(projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.get(`${config.baseRequest}spaces/dictionary/types`, options);
  }

  public getSpaceObjects(params: IODataModel = {}, projectKey?: string) {
    const query = generateODataQuery(params);
    const options = getApiKeyOptions(projectKey);
    return this.httpService.get(`${config.baseRequest}spaces/objects${query}`, options);
  }

  public getSpaceObjectById(id: number, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.get(`${config.baseRequest}spaces/objects/${id}`, options);
  }

  public getSpaceObjectTypes(projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.get(`${config.baseRequest}spaces/objects/dictionary/types`, options);
  }

  public createSpace(body: any, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.post(`${config.baseRequest}spaces`, body, options);
  }

  public createSpaceObject(body: any, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.post(`${config.baseRequest}spaces/objects`, body, options);
  }

  public createSpaceObjectOption(body: any, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.post(`${config.baseRequest}spaces/objects/options`, body, options);
  }

  public bulkCreateSpaceObjectOption(body: any, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.post(`${config.baseRequest}spaces/objects/options/bulk`, body, options);
  }

  public assignOptionToSpaceObject(body: any, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.post(`${config.baseRequest}spaces/objects/options/assign`, body, options);
  }

  public updateSpace(body: any, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.put(`${config.baseRequest}spaces`, body, options);
  }

  public updateSpaceObject(body: any, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.put(`${config.baseRequest}spaces/objects`, body, options);
  }

  public updateSpaceObjectOption(body: any, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.put(`${config.baseRequest}spaces/objects/options`, body, options);
  }

  public deleteSpace(id: number, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.delete(`${config.baseRequest}spaces/${id}`, null, options);
  }

  public deleteSpaceObject(id: number, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.delete(`${config.baseRequest}spaces/objects/${id}`, null, options);
  }

  public deleteSpaceObjectOption(id: number, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.delete(`${config.baseRequest}spaces/objects/options/${id}`, null, options);
  }
}

export default SpacesService;
