import { Col, Form, Row, Select, FormInstance, Cascader, DatePicker } from 'antd';
import React, { useEffect, useState, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import config from '../../../config';
import HttpService from '../../../services/common/httpService';
import css from '../../../assets/styles/budget.module.css';
import { ReportDynamicParamsContext } from '../../../contexts/ReportDynamicParamsProvider';
import {
  getTemplateVariables,
  isTemplateValue,
  parseTemplateVariable,
  replaceTemplateValue,
} from '../../../helpers/templatesHelper';
import { IsJsonString } from '../../../helpers/isJSONValid';
import ListPageServices from '../../../services/PropertiesService';
import moment from 'moment';

interface DynamicParamModel {
  param: any;
  form: FormInstance<any>;
  changed: any;
  setChanged: (val: any) => void;
  setParentIdCustom: (val: string) => void;
}

const httpService = new HttpService();
const propertyService = new ListPageServices();

const DynamicParams: React.FC<DynamicParamModel> = ({
  param,
  form,
  changed,
  setChanged,
  setParentIdCustom,
}: DynamicParamModel) => {
  const { type, relatedOn = null } = param;
  const { i18n, t } = useTranslation();

  const lang = useMemo(() => i18n.language.split('-')[0], [i18n.language]);
  const isChanged = useMemo(() => changed[relatedOn], [changed[relatedOn]]);
  const { data, dataDispatch } = useContext(ReportDynamicParamsContext);

  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [properties, setProperties] = useState<any[]>([]);
  const formValues = form.getFieldsValue();

  const relValue = formValues[relatedOn];
  const isHide = !(!!relatedOn && !relValue);
  const isParent = param.name === 'parentId';

  const getData = (url: string, headers: any = undefined) => {
    setIsFetchingData(true);
    httpService
      .get(url, headers ? { headers } : null)
      .then((res: any) => {
        const hasOwnProperty = Object.prototype.hasOwnProperty;
        const result = hasOwnProperty.call(res, 'items') ? res.items : res;
        dataDispatch({ keyName: param.name, data: result, key: data?.key || null });
      })
      .finally(() => setIsFetchingData(false));
  };

  const getRelId = (value: any, key: string) => {
    if (IsJsonString(value)) {
      const valObj = JSON.parse(value);
      return valObj[key];
    }
    return value;
  };

  useEffect(() => {
    if (type === 'api') {
      const hasRelation = isTemplateValue(param.request) && !!relatedOn;
      const { key: idKey } = param;
      const [startWith] = param.request;
      const request = startWith === '/' ? param.request.substr(1, param.request.length) : param.request;
      let url = `${config.baseRequest}${request}`;
      if (!hasRelation) {
        let headers = null;
        if (data.key) {
          headers = {
            'p-api-key': data.key,
          };
        }
        getData(url, headers);
      } else {
        const vars = getTemplateVariables(url) || [];
        for (const item of vars) {
          const { key } = parseTemplateVariable(item);
          const relId = getRelId(formValues[relatedOn], idKey);
          const relObject = data[relatedOn]?.find((val: any) => val.id === relId) || {};
          url = replaceTemplateValue(url, item, relObject[key]);
        }
        let headers = { ...param.headers } || null;
        if (headers) {
          const headerKeys = Object.keys(headers);
          const hasHeaderRelation = headerKeys.some((item: string) => {
            const isTmp = isTemplateValue(headers[item]);
            return isTmp;
          });
          if (hasHeaderRelation) {
            headerKeys.forEach((item: string) => {
              const vars = getTemplateVariables(headers[item]) || [];
              for (const header of vars) {
                const { key } = parseTemplateVariable(header);
                const relId = getRelId(formValues[relatedOn], idKey);
                const relObject = data[relatedOn]?.find((val: any) => val.id === relId) || {};
                headers[item] = replaceTemplateValue(headers[item], header, relObject[key]);
              }
            });
          }
        }
        if (isChanged) getData(url, headers);
      }
      setChanged({ ...changed, [relatedOn]: false });
    }
  }, [isChanged]);

  useEffect(() => {
    if (isParent && data && data[param.name]) {
      propertiesTree(data[param.name]);
    }
  }, [data]);

  const loadData = async (selectedOptions: any) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;

    const propertyChildren = await propertyService
      .getPropertyChildren({
        id: targetOption.id,
        projectKey: data.key,
      })
      .finally(() => {
        targetOption.loading = false;
      });
    propertiesTree(propertyChildren, targetOption);
  };

  const propertiesTree = (value: any[], targetOption?: any) => {
    let newData: any[] = [];
    if (Array.isArray(value)) {
      if (targetOption) {
        targetOption.childrens = value?.map((item: any) => {
          const { childrens, ...rest } = item;
          return {
            ...rest,
            childrens: childrens.length > 0 ? childrens.filter((item: any) => item.type.code !== 'unit') : [],
            disabled: childrens.length === 0,
            isLeaf: childrens.length === 0 || childrens.filter((item: any) => item.type.code !== 'unit').length === 0,
          };
        });
      } else {
        newData = value?.map((item: any) => {
          const { childrens, ...rest } = item;
          return {
            ...rest,
            childrens: childrens.length > 0 ? childrens.filter((item: any) => item.type.code !== 'unit') : [],
            disabled: childrens.length === 0,
            isLeaf: childrens.length === 0 || childrens.filter((item: any) => item.type.code !== 'unit').length === 0,
          };
        });
      }
      setProperties(targetOption ? [...properties] : newData);
    }
  };

  const apiData = data && data[param.name] ? data[param.name] : [];

  const renderFields = () => {
    switch (type) {
      case 'api': {
        return (
          <Col span={24} style={{ height: '90%' }}>
            <Form.Item
              rules={[{ required: param.required, message: t('common.validation.required') }]}
              label={<span style={{ color: '#778dac' }}>{param[`title_${lang}`]}</span>}
              name={param.name}>
              {isParent ? (
                <Cascader
                  //NOTE: need research why is note supported in 4.17.0 v of antd
                  // suffixIcon={
                  //  <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
                  //}
                  className="consumer-form-input common-animation-primary"
                  style={{ height: 34, minWidth: 200 }}
                  fieldNames={{ label: 'title', value: 'id', children: 'childrens' }}
                  options={properties}
                  loadData={loadData}
                  changeOnSelect
                  onChange={(value: any, items: any) => {
                    if (items.length > 0) {
                      setParentIdCustom(
                        JSON.stringify({
                          [param.key]: items[items.length - 1][param.key],
                          [param.label]: items[items.length - 1][param.label],
                        }),
                      );
                    } else {
                      setParentIdCustom('');
                    }
                  }}
                />
              ) : (
                <Select
                  loading={isFetchingData}
                  allowClear
                  disabled={apiData.length === 0}
                  onChange={(value) => {
                    dataDispatch(
                      (data.key =
                        data.project.find((el: any) => el.id === JSON.parse(value as string).id)?.key || data.key),
                    );
                  }}
                  className="consumer-form-input common-animation-primary"
                  suffixIcon={
                    <div
                      className={`arrow-drop-img ${css['arrow-drop']}`}
                      style={{ width: '8px', height: '4px' }}></div>
                  }>
                  {apiData.length > 0 &&
                    apiData?.map((item: any) => (
                      <Select.Option
                        key={item[param.key]}
                        value={JSON.stringify({
                          [param.key]: item[param.key],
                          [param.label]: item[param.label],
                        })}>
                        {item[param.label]}
                      </Select.Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        );
      }
      case 'enum': {
        const { items } = param;
        return (
          <Col span={24} style={{ height: '90%' }}>
            <Form.Item
              rules={[{ required: param.required, message: t('common.validation.required') }]}
              label={<span style={{ color: '#778dac' }}>{param[`title_${lang}`]}</span>}
              name={param.name}>
              <Select loading={isFetchingData} className="consumer-form-input common-animation-primary">
                {items.length > 0 &&
                  items?.map((item: any) => (
                    <Select.Option key={item.key} value={item.key}>
                      {item[`label_${lang}`]}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        );
      }
      case 'date_start': {
        return (
          <Col span={24} style={{ height: '90%' }}>
            <Form.Item
              rules={[{ required: param.required, message: t('common.validation.required') }]}
              label={<span style={{ color: '#778dac' }}>{param[`title_${lang}`]}</span>}
              name={param.name}>
              <DatePicker
                disabled={isFetchingData}
                style={{ width: '100%' }}
                className="consumer-form-input common-animation-primary"
                onChange={(value) => {
                  form.setFieldsValue({
                    [param.name]: moment(value).set({
                      hour: 0,
                      minute: 0,
                      second: 0,
                      millisecond: 0,
                    }),
                  });
                }}
              />
            </Form.Item>
          </Col>
        );
      }
      case 'date_end': {
        return (
          <Col span={24} style={{ height: '90%' }}>
            <Form.Item
              rules={[{ required: param.required, message: t('common.validation.required') }]}
              label={<span style={{ color: '#778dac' }}>{param[`title_${lang}`]}</span>}
              name={param.name}>
              <DatePicker
                disabled={isFetchingData}
                style={{ width: '100%' }}
                className="consumer-form-input common-animation-primary"
                onChange={(value) => {
                  form.setFieldsValue({
                    [param.name]: moment(value).set({
                      hour: 23,
                      minute: 59,
                      second: 59,
                      millisecond: 999,
                    }),
                  });
                }}
              />
            </Form.Item>
          </Col>
        );
      }
      default:
        return null;
    }
  };

  return (
    <Row gutter={24} style={{ height: '100%' }}>
      {isHide && renderFields()}
    </Row>
  );
};

export default DynamicParams;
