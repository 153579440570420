import React from 'react';
import TypologiesContextProvider from '../../contexts/TypologiesContextProvider';
import TypologiesPage from './TypologiesPage';

const TypologiesLayout: React.FC = (props: any) => {
  return (
    <TypologiesContextProvider>
      <TypologiesPage {...props} />
    </TypologiesContextProvider>
  );
};

export default TypologiesLayout;
