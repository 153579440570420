import { Button, Col, Form, Input, Modal, Row } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../../assets/styles/companies.module.css';
import { PaymentInfo } from '../../../../models/CompaniesModel';

interface IModalProps {
  isOpenModal: boolean;
  setClose: () => void;
  paymentInfo: PaymentInfo | undefined;
  onSave: (p: PaymentInfo) => void;
}

const ModalFormPaymentProfile = ({ isOpenModal, setClose, paymentInfo, onSave }: IModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    if (paymentInfo) {
      form.setFieldsValue(paymentInfo);
    } else {
      form.setFieldsValue({
        accountHolder: '',
        bankName: '',
        countryOfBank: '',
        iban: '',
        swift: '',
        taxId: '',
        vatId: '',
      });
    }
  }, [paymentInfo]);

  const onSubmit = (data: PaymentInfo) => {
    onSave(data);
    form.resetFields();
  };

  const onClose = () => {
    setClose();
    form.resetFields();
  };

  return (
    <Modal
      className="common-modal"
      centered
      width={'30rem'}
      visible={isOpenModal}
      footer={
        <div className="common-modal-footer">
          <Button className={`${css['payment-modal-cancel-btn']} common-secondary-btn`} onClick={() => onClose()}>
            {t('budget.modal.cancel.btn')}
          </Button>
          <Button className={`${css['payment-modal-save-btn']} common-modal-btn`} onClick={() => form.submit()}>
            {t('budget.modal.save.btn')}
          </Button>
        </div>
      }
      closable={false}>
      <Form autoComplete="off" layout="vertical" form={form} onFinish={onSubmit}>
        {paymentInfo && (
          <Form.Item colon={false} name="id" style={{ display: 'none' }}>
            <Input />
          </Form.Item>
        )}
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              colon={false}
              rules={[{ required: true, message: t('common.validation.required') }]}
              name="accountHolder"
              className={css['form-item']}
              label={<span style={{ color: '#778dac' }}>{t('projects.create.account.holder')}</span>}>
              <Input className="consumer-form-input common-animation-primary" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              colon={false}
              rules={[{ required: true, message: t('common.validation.required') }]}
              name="bankName"
              className={css['form-item']}
              label={<span style={{ color: '#778dac' }}>{t('projects.create.bank.name')}</span>}>
              <Input className="consumer-form-input common-animation-primary" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              colon={false}
              rules={[{ required: true, message: t('common.validation.required') }]}
              name="countryOfBank"
              className={css['form-item']}
              label={<span style={{ color: '#778dac' }}>{t('projects.create.bank.country')}</span>}>
              <Input className="consumer-form-input common-animation-primary" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              colon={false}
              rules={[{ required: true, message: t('common.validation.required') }]}
              name="iban"
              className={css['form-item']}
              label={<span style={{ color: '#778dac' }}>{t('projects.create.iban')}</span>}>
              <Input className="consumer-form-input common-animation-primary" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              colon={false}
              rules={[{ required: true, message: t('common.validation.required') }]}
              name="swift"
              className={css['form-item']}
              label={<span style={{ color: '#778dac' }}>{t('projects.create.swift.bic')}</span>}>
              <Input className="consumer-form-input common-animation-primary" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              colon={false}
              rules={[{ required: true, message: t('common.validation.required') }]}
              name="taxId"
              className={css['form-item']}
              label={<span style={{ color: '#778dac' }}>{t('projects.create.tax.id')}</span>}>
              <Input className="consumer-form-input common-animation-primary" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              colon={false}
              rules={[{ required: true, message: t('common.validation.required') }]}
              name="vatId"
              className={css['form-item']}
              label={<span style={{ color: '#778dac' }}>{t('projects.create.vat.id')}</span>}>
              <Input className="consumer-form-input common-animation-primary" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalFormPaymentProfile;
