import { Button, Modal, Spin, Table, TableProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterTypes } from '../../../../constants/filterTypes';
import { generateFilterfromObj } from '../../../../helpers/generateFIlterfromObj';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { IAmendmentModel } from '../../../../models/AmendmentModel';
import { IContractModel } from '../../../../models/DocumentsModel';
import AmendmentService from '../../../../services/AmendmentService';
import ContractsService from '../../../../services/ContractsService';
import { getDocumentsColumns, getAmedmentsColumns } from './commonColumns';

const contractsService = new ContractsService();
const amendmentService = new AmendmentService();

interface ISelectQuotesModal {
  propertyId: number;
  isOpen: boolean;
  onClose: () => void;
  onSave: (ids: number[]) => void;
  isContract: boolean;
}

const SelectTableModal = ({ propertyId, isOpen, onClose, onSave, isContract }: ISelectQuotesModal) => {
  const { t } = useTranslation();
  const { height: windowHeight } = useWindowDimensions();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [itemsInfo, setItemsInfo] = useState<IContractModel[] | IAmendmentModel[]>([]);
  const [fetchingData, setFetchingData] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    skip: 0,
    showSizeChanger: true,
  });

  const option = {
    count: true,
    top: pagination.pageSize,
    current: pagination.current,
    orderBy: ['createdDate desc'],
  };

  const getItems = (option: { [name: string]: any }) => {
    setFetchingData(true);
    option.filters = generateFilterfromObj({
      'status_v2/code': {
        type: FilterTypes.SELECT,
        value: 'signed',
      },
    });
    if (isContract) {
      contractsService
        .getContracts(+propertyId, option)
        .then((res) => {
          const { count, items } = res;
          const { current, top } = option;
          setPagination({ ...pagination, current, total: count, pageSize: top });
          setItemsInfo(items);
        })
        .catch((e) => console.log(e))
        .finally(() => setFetchingData(false));
      return;
    }
    amendmentService
      .getAmendments(+propertyId, option)
      .then((res) => {
        const { count, items } = res;
        const { current, top } = option;
        setPagination({ ...pagination, current, total: count, pageSize: top });
        setItemsInfo(items);
      })
      .catch((e) => console.log(e))
      .finally(() => setFetchingData(false));
  };

  useEffect(() => {
    getItems(option);
  }, []);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onTableChange: TableProps<IContractModel | IAmendmentModel>['onChange'] = (pagination) => {
    let params: { [name: string]: any } = {
      ...option,
    };

    if (pagination && pagination.pageSize && pagination.current) {
      params = {
        ...params,
        top: pagination.pageSize,
        skip: pagination.pageSize * (pagination.current - 1),
        current: pagination.current,
      };
    }

    getItems(params);
  };

  return (
    <Spin spinning={fetchingData}>
      <Modal className="common-modal" centered width={'70rem'} visible={isOpen} closable={false} footer={null}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
          <div style={{ fontSize: 20, fontWeight: 600 }}>
            {isContract ? t('amendment.contaracts') : t('amendment.title')}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              className={`common-gray-border-btn common-secondary-btn`}
              style={{ marginRight: '1rem' }}
              onClick={onClose}>
              {t('budget.modal.cancel.btn')}
            </Button>
            <Button className={`common-green-btn common-modal-btn`} onClick={() => onSave(selectedRowKeys as number[])}>
              {t('budget.modal.save.btn')}
            </Button>
          </div>
        </div>
        {isContract && (
          <Table
            columns={getDocumentsColumns({ t })}
            size="small"
            style={{ background: 'transparent' }}
            className="consumers-info-table"
            dataSource={itemsInfo as IContractModel[]}
            showHeader={true}
            pagination={pagination}
            rowSelection={{
              type: 'radio',
              ...rowSelection,
            }}
            onChange={onTableChange as TableProps<IContractModel>['onChange']}
            scroll={{ y: windowHeight - 450 }}
            rowKey="id"
          />
        )}
        {!isContract && (
          <Table
            columns={getAmedmentsColumns({ t })}
            size="small"
            style={{ background: 'transparent' }}
            className="consumers-info-table"
            dataSource={itemsInfo as IAmendmentModel[]}
            showHeader={true}
            pagination={pagination}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            onChange={onTableChange as TableProps<IAmendmentModel>['onChange']}
            scroll={{ y: windowHeight - 450 }}
            rowKey="id"
          />
        )}
      </Modal>
    </Spin>
  );
};

export default SelectTableModal;
