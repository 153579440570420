import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { Form, Button, Row, Col, Card, Input, InputNumber, Checkbox, notification, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import { Link } from 'react-router-dom';
import Variants from './components/Variants';
import LevelsService from '../../services/LevelsService';

const service: LevelsService = new LevelsService();

const LevelsModifyPage: React.FC<any> = (props: any) => {
  const { t } = useTranslation();
  const { id, typologyId } = useParams<any>();
  const history = useHistory();
  const isCreate = !id;
  const [form] = Form.useForm();

  const [breadcrumbNameMap, setBreadcrumbNameMap] = useState<{ [name: string]: string }>({});
  const [level, setLevel] = useState<any>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const getLevel = () => {
    return service.getLevelById(id).then((res: any) => {
      setLevel(res);
      form.setFieldsValue({
        name: res.name,
        description: res.description,
        level: res.level,
        isDefault: res.isDefault,
      });
    });
  };

  const getAsyncData = async () => {
    const actions = [];
    if (!isCreate) {
      actions.unshift(getLevel());
    }
    Promise.all(actions).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    getAsyncData();
  }, [id]);

  const onSubmit = (values: any) => {
    setIsSaving(true);
    if (isCreate) {
      service
        .createLevel({
          ...values,
          typology: { id: typologyId },
        })
        .then((res: any) => {
          history.push(`${history.location.pathname}/${res.id}`);
          notification.success({
            message: t('level.create.notification.create'),
          });
        })
        .catch((e) => {
          notification.error({
            message: e.message || t('common.error.internalServerError'),
          });
        })
        .finally(() => setIsSaving(false));
    } else {
      service
        .updateLevel({
          ...values,
          id: +id,
        })
        .then(() => {
          history.push(`${history.location.pathname}`);
          notification.success({
            message: t('level.create.notification.update'),
          });
        })
        .catch((e) => {
          notification.error({
            message: e.message || t('common.error.internalServerError'),
          });
        })
        .finally(() => setIsSaving(false));
    }
  };

  const onCancel = () => {
    history.push(`/typologies/modify/${typologyId}`);
  };

  return (
    <Spin spinning={isSaving}>
      <Form autoComplete="off" form={form} layout="vertical" name="modify_typology" onFinish={onSubmit}>
        <HeaderTable
          title={!isCreate ? `${t('level.create.title.update')} #${id}` : t('level.create.title.create')}
          breadcrumb={breadcrumbNameMap}>
          <Form.Item>
            <Button htmlType="button" onClick={onCancel} className="header-btn">
              {t('common.btn.cancel')}
            </Button>
            <Button type="primary" htmlType="submit" className="header-btn">
              {t('common.btn.save')}
            </Button>
          </Form.Item>
        </HeaderTable>
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Card title={t('level.create.card.level.label')} loading={loading}>
              <Row gutter={[24, 4]}>
                <Col span={12}>
                  <Form.Item
                    label={t('level.create.card.level.name')}
                    name="name"
                    rules={[{ required: true, message: t('level.create.card.level.name.validation') }]}>
                    <Input className="common-animation-primary" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t('level.create.card.level.description')}
                    name="description"
                    rules={[{ required: true, message: t('level.create.card.level.description.validation') }]}>
                    <Input className="common-animation-primary" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t('level.create.card.level.level')}
                    name="level"
                    rules={[{ required: true, message: t('level.create.card.level.level.validation') }]}>
                    <InputNumber className="common-animation-primary" style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t('level.create.card.level.isDefault.label')}
                    name="isDefault"
                    valuePropName="checked">
                    <Checkbox>{t('level.create.card.level.isDefault.value')}</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
          {!isCreate && (
            <Col span={24}>
              <Card
                title={t('level.create.card.variants.title')}
                extra={
                  <Link to={`/typologies/${typologyId}/levels/${id}/variants/modify`}>
                    <Button type="primary" style={{ width: 100 }}>
                      {t('common.btn.new')}
                    </Button>
                  </Link>
                }
                loading={loading}>
                <Variants items={level?.variants || []} typologyId={typologyId} levelId={id} />
              </Card>
            </Col>
          )}
        </Row>
      </Form>
    </Spin>
  );
};

export default LevelsModifyPage;
