import HttpService from './common/httpService';
import config from '../config';
import buildQuery from 'odata-query';
import { NewBudgetModel, UpdatedBudgetModel } from '../models/BudgetModel';

class BudgetService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getBudget(propertyId: number | null, option: any) {
    const { top, count, skip, filters } = option;

    let filter = [];

    let defaultFilter: any = {
      'property/id': {
        eq: propertyId,
      },
    };

    if (!propertyId) {
      defaultFilter = {};
    }

    if (filters) {
      if (Object.keys(defaultFilter).length) {
        filter = [defaultFilter, ...filters.filter];
      } else {
        filter = [...filters.filter];
      }
      filter.sort((a: any, b: any) =>
        Object.keys(Object.values(a)[0] as any)[0] == 'contains'
          ? -1
          : Object.keys(Object.values(b)[0] as any)[0] == 'contains'
          ? 1
          : 0,
      );
    } else {
      filter = defaultFilter;
    }

    const query = buildQuery({ filter, top, count, skip });
    return this.httpService.get(`${config.baseRequest}invoices${query}`);
  }
  public getBudgetNoArchived(propertyId: number | null, option: any) {
    const { top, count, skip, filters, orderBy } = option;

    let filter = [];

    let defaultFilter: any = {
      'property/id': {
        eq: propertyId,
      },
      isArchive: {
        eq: false,
      },
    };

    if (!propertyId) {
      defaultFilter = {
        isArchive: {
          eq: false,
        },
      };
    }

    if (filters) {
      filters.filter.sort((a: any, b: any) => {
        const check = (item: any) => {
          if (
            Object.values(item).length > 0 &&
            (Object.values(item)[0] as any).length > 0 &&
            Object.values(Object.values(item)[0] as any).length > 0 &&
            Object.values((Object.values(item)[0] as any)[0] as any).length > 0
          ) {
            return Object.keys(Object.values((Object.values(item)[0] as any)[0] as any)[0] as any)[0] == 'contains';
          }
          return false;
        };

        return Object.keys(Object.values(a)[0] as any)[0] == 'contains' || check(a)
          ? -1
          : Object.keys(Object.values(b)[0] as any)[0] == 'contains'
          ? 1
          : 0;
      });
      filter = [...filters.filter, defaultFilter];
      filter.sort((a: any, b: any) => {
        const check = (item: any) => {
          if (
            Object.values(item).length > 0 &&
            (Object.values(item)[0] as any).length > 0 &&
            Object.values(Object.values(item)[0] as any).length > 0 &&
            Object.values((Object.values(item)[0] as any)[0] as any).length > 0
          ) {
            return Object.keys(Object.values((Object.values(item)[0] as any)[0] as any)[0] as any)[0] == 'contains';
          }
          return false;
        };

        return Object.keys(Object.values(a)[0] as any)[0] == 'contains' || check(a)
          ? -1
          : Object.keys(Object.values(b)[0] as any)[0] == 'contains'
          ? 1
          : 0;
      });
    } else {
      filter = defaultFilter;
    }

    const query = buildQuery({ filter, top, count, skip, orderBy });
    return this.httpService.get(`${config.baseRequest}invoices${query}`);
  }

  public getBudgetInfoById(id: number) {
    return this.httpService.get(`${config.baseRequest}invoices/${id}`);
  }

  public createInvoice(body: NewBudgetModel) {
    return this.httpService.post(`${config.baseRequest}invoices`, body);
  }

  public updateInvoice(body: UpdatedBudgetModel | { id: number; isArchive: boolean }) {
    return this.httpService.put(`${config.baseRequest}invoices`, body);
  }

  public deleteInvoice(id: number) {
    return this.httpService.delete(`${config.baseRequest}invoices/${id}`);
  }

  public uploadInvoiceFiles(updateId: number, file: any) {
    return this.httpService.postFile(`${config.baseRequest}invoices/${updateId}/file`, file);
  }

  //for diagram in budget tab
  public getBudgetPrices(id: number) {
    return this.httpService.get(`${config.baseRequest}invoices/budget_by_property/${id}`);
  }

  public getInvoiceFile = (fileName: string) => {
    return this.httpService.getFile(`${config.baseRequest}file/binary/${fileName}`).then((res: any) => {
      let file = new File([res], fileName);
      return file;
    });
  };

  public getInvoiceHistory(id: number) {
    return this.httpService.get(`${config.baseRequest}invoices/${id}/history`);
  }

  public confirmStep(envelopeId?: string, id?: number) {
    return this.httpService.patch(`${config.baseRequest}invoices/step/done`, envelopeId ? { envelopeId } : { id });
  }

  public getSigningUrl(id: number, returnUrl: string, consumerId?: number) {
    return this.httpService.post(`${config.baseRequest}invoices/step/redirect`, { id, consumerId, returnUrl });
  }

  public rejectInvoice(id: number | null, comment: string, envelopeId?: string) {
    const body = id ? { id, comment } : { envelopeId, comment };
    return this.httpService.patch(`${config.baseRequest}invoices/step/reject`, body);
  }
}

export default BudgetService;
