import HttpService from './common/httpService';
import config from '../config';
import { IODataModel } from '../helpers/generateODataQuery';
import { getApiKeyOptions } from '../helpers/getApiKeyOptions';
import buildQuery from 'odata-query';
import { UserCreateModel, UserUpdateModel } from '../models/UserModel';

class ConsumersService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getConsumers(params: IODataModel = {}, projectKey?: string) {
    const { top, count, skip, filters } = params;

    let filter: any = [];

    if (filters) {
      filter = [...filters.filter];
      filter.sort((a: any, b: any) =>
        Object.keys(Object.values(a)[0] as any)[0] == 'contains'
          ? -1
          : Object.keys(Object.values(b)[0] as any)[0] == 'contains'
          ? 1
          : 0,
      );
    }

    const options = getApiKeyOptions(projectKey);
    const query = buildQuery({ filter, top, count, skip });
    return this.httpService.get(`${config.baseRequest}consumers${query}`, options);
  }

  public getAllConsumers(params: any) {
    const { top, count, skip, filter, orderBy } = params;

    filter.sort((a: any, b: any) =>
      Object.keys(Object.values((Object.values(a)[0] as any)[0])[0] as any)[0] == 'contains'
        ? -1
        : Object.keys(Object.values((Object.values(b)[0] as any)[0])[0] as any)[0] == 'contains'
        ? 1
        : 0,
    );
    const query = buildQuery({ top, count, skip, filter, orderBy });

    return this.httpService.get(`${config.baseRequest}users/consumers${query}`);
  }

  public getConsumerById(id: number, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.get(`${config.baseRequest}consumers/${id}`, options);
  }

  public createConsumer(body: UserCreateModel, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.post(`${config.baseRequest}consumers`, body, options);
  }

  public updateConsumer(body: UserUpdateModel, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.put(`${config.baseRequest}consumers`, body, options);
  }

  //confirm request for user
  public resendConsumerEmail(email: string, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.put(`${config.baseRequest}auth/consumer/resend/confirmation`, { email }, options);
  }

  public deleteConsumer(id: number, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.delete(`${config.baseRequest}consumers/${id}`, null, options);
  }

  public importConsumersParse(body: any, projectKey: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.postFile(`${config.baseRequest}importsConsumers/parse`, body, options);
  }

  public importConsumersCreate(body: any, projectKey: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.post(`${config.baseRequest}importsConsumers/create`, body, options);
  }

  public exampleImportConsumers(projectKey: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.get(`${config.baseRequest}importsConsumers/example`, options);
  }

  //resend registration email
  public resendEmail(id: number) {
    return this.httpService.post(`${config.baseRequest}auth/register/repeatedEmail/${id}`);
  }

  public getConsumersV2(params: any = {}, brokerProjects: any) {
    const { top, count, skip, filters, byProperties, orderBy } = params;

    let filter = [];

    let defaultFilter: any = {
      or: brokerProjects.map((item: any) => {
        return { [byProperties ? 'properties/project/id' : 'projects/id']: { eq: item } };
      }),
    };

    if (filters.length > 0) {
      filter = [defaultFilter, ...filters];
      filter.sort((a: any, b: any) => {
        const check = (item: any) => {
          if (
            Object.values(item).length > 0 &&
            (Object.values(item)[0] as any).length > 0 &&
            Object.values(Object.values(item)[0] as any).length > 0 &&
            Object.values((Object.values(item)[0] as any)[0] as any).length > 0
          ) {
            return Object.keys(Object.values((Object.values(item)[0] as any)[0] as any)[0] as any)[0] == 'contains';
          }
          return false;
        };

        return Object.keys(Object.values(a)[0] as any)[0] == 'contains' || check(a)
          ? -1
          : Object.keys(Object.values(b)[0] as any)[0] == 'contains'
          ? 1
          : 0;
      });
    } else {
      filter = defaultFilter;
    }
    const query = buildQuery({ filter, top, count, skip, orderBy });
    return this.httpService.get(`${config.baseRequest}users/consumers/v2${query}&$byProperties=${byProperties}`);
  }

  public updateConsumerPropertyV2(id: number, body: any) {
    return this.httpService.put(`${config.baseRequest}users/consumers/${id}/property`, body);
  }
}

export default ConsumersService;
