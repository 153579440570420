import { FileOutlined, FileSearchOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Upload } from 'antd';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../assets/styles/details.module.css';
import config from '../../../config';
import { PropertyModel, PlanModel } from '../../../models/PropertyModel';
import downloadIcon from '../../../assets/images/ic-download-dark.svg';
import deleteIcon from '../../../assets/images/ic-delete-dark-gray.svg';
import moreIcon from '../../../assets/images/ic-more.svg';
import PreviewPDF from './PreviewPDF';
import ConfirmDeletePlan from './ConfirmDeletePlan';
import CreatePlan from './CreatePlan';
import { AppContext } from '../../../contexts/AppContextProvider';
import ProjectDetailsTable from './ProjectDetailsTable';
import { IUserEasy } from '../../../models/UserModel';
import { IAppContext } from '../../../typings/IApp';

interface ProjectDetailsProps {
  propertyInfo: PropertyModel | null;
  setIsNeedUpdate?: (b: boolean) => void;
  consumers?: IUserEasy[];
}

const ProjectDetails = (props: ProjectDetailsProps) => {
  const { propertyInfo, setIsNeedUpdate, consumers } = props;
  const { t } = useTranslation();
  const [currentPlan, setCurrentPlan] = useState<PlanModel | null>(null);
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const {
    app: { isConsumer, isSupplier },
  } = useContext<IAppContext>(AppContext);
  if (!propertyInfo) {
    return null;
  }

  const openPreview = (plan: PlanModel) => {
    if (plan.name.slice(-3) === 'pdf') {
      setCurrentPlan(plan);
      setIsOpenPreview(true);
    } else {
      let src = `${config.storeUrl}/${plan.name}`;
      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);
      imgWindow?.document.write(image.outerHTML);
    }
  };
  const closePreview = () => {
    setCurrentPlan(null);
    setIsOpenPreview(false);
  };

  const openDelete = (plan: PlanModel) => {
    setCurrentPlan(plan);
    setIsOpenDelete(true);
  };

  const closeDelete = () => {
    setCurrentPlan(null);
    setIsOpenDelete(false);
    setIsNeedUpdate && setIsNeedUpdate(true);
  };

  return (
    <div className={css['project-details-container']}>
      {isOpenPreview && <PreviewPDF isOpen={isOpenPreview} plan={currentPlan} setClose={closePreview} />}
      {isOpenDelete && (
        <ConfirmDeletePlan
          isOpen={isOpenDelete}
          plan={currentPlan}
          setClose={closeDelete}
          setIsNeedUpdate={setIsNeedUpdate}
        />
      )}
      {isOpenCreate && (
        <CreatePlan isOpen={isOpenCreate} setIsNeedUpdate={setIsNeedUpdate} setClose={setIsOpenCreate} />
      )}
      <span className={css['project-details-title']}>{t('project.details.project.title')}</span>
      <ProjectDetailsTable
        t={t}
        propertyInfo={propertyInfo}
        consumers={consumers}
        isSupplier={isSupplier}
        isConsumer={isConsumer}
      />
      <span>{t('project.details.floor.plan')}</span>
      {!isConsumer && !isSupplier && (
        <Button
          onClick={() => setIsOpenCreate(true)}
          className={`common-blue-btn common-primary-btn`}
          style={{ margin: '1rem 0' }}>
          {t('budget.upload.attachment.btn.add')}
        </Button>
      )}
      <div style={{ flex: 2 }}>
        <div className={css['project-details-floor-plan']}>
          {propertyInfo.plans.map((plan: PlanModel) => {
            const menu = (
              <Menu className="update-menu-container" style={{ width: '130px' }} key={plan.id}>
                <Menu.Item key="preview">
                  <div
                    className={css['update-menu-item']}
                    onClick={() => {
                      openPreview(plan);
                    }}>
                    <FileSearchOutlined />
                    <span style={{ color: '#00122d', fontWeight: 600, marginLeft: '0.6rem' }}>
                      {t('common.title.preview')}
                    </span>
                  </div>
                </Menu.Item>
                {!isConsumer && !isSupplier && (
                  <Menu.Item key="delete">
                    <div className={css['update-menu-item']} onClick={() => openDelete(plan)}>
                      <Button
                        style={{
                          border: 'none',
                          backgroundColor: 'inherit',
                          boxShadow: 'none',
                          padding: 0,
                          marginRight: '0.5rem',
                        }}>
                        <img src={deleteIcon} alt="" style={{ height: '15px', width: '15px' }} />
                      </Button>
                      <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.title.delete')}</span>
                    </div>
                  </Menu.Item>
                )}
                <Menu.Item key="upload">
                  <div className={css['update-menu-item']}>
                    <img src={downloadIcon} alt="" style={{ height: '15px', width: '15px' }} />
                    <Upload
                      className="download-budget-item"
                      iconRender={() => <></>}
                      showUploadList={{ showRemoveIcon: false }}
                      style={{ height: '100%', backgroundColor: 'inherit' }}
                      maxCount={1}
                      beforeUpload={() => false}
                      fileList={[
                        {
                          name: t('budget.table.download'),
                          uid: plan.name,
                          url: `${config.storeUrl}/${plan.name}`,
                        },
                      ]}
                    />
                  </div>
                </Menu.Item>
              </Menu>
            );
            return (
              <div
                key={plan.id}
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1rem' }}>
                <span style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                  <FileOutlined style={{ marginRight: '1rem' }} />
                  {plan.name}
                </span>
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button className={css['update-dropdown-btn']} style={{ marginRight: '1rem' }}>
                    <img src={moreIcon} alt="" height={15} width={15} />
                  </Button>
                </Dropdown>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
