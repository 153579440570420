import { CheckOutlined } from '@ant-design/icons';
import moment from 'moment';
import React from 'react';
import { TFunction } from 'react-i18next';
import archivedIcon from '../../../../assets/images/ic-archive.svg';
import downloadIcon from '../../../../assets/images/ic-download-dark.svg';
import { authDownloadFile } from '../../../../helpers/authFileTools';
import { InternalDocumentModel } from '../../../../models/InternalDocumentsModel';
import { IStatusModel } from '../../../../models/StatusModel';

interface BudgetColumnsProps {
  t: TFunction<'translation'>;
  unArchive: (id: number) => void;
}

const colors: any = {
  new: '#1374e6',
  approved: '#19ac87',
  signed: '#00d29f',
};

const setImgByStatus = (status: IStatusModel) => {
  const { code, description } = status;
  return <span style={{ color: colors[code] || '#eb7d5a', fontSize: '14px' }}>{description.toLowerCase()}</span>;
};

export const getColumns = ({ t, unArchive }: BudgetColumnsProps) => [
  {
    title: () => (
      <span className="budget-table-tr" style={{ marginLeft: '8px' }}>
        type
      </span>
    ),
    key: 'type',
    width: '40px',
    render: (cell: InternalDocumentModel) => <span>{cell.type.description}</span>,
  },
  {
    title: () => <span className="budget-table-tr">{t('category.create.name')}</span>,
    dataIndex: 'name',
    width: '100px',
    sorter: true,
    key: 'name',
  },
  {
    title: () => <span className="budget-table-tr">{t('documents.table.date')}</span>,
    width: '60px',
    key: 'date',
    render: (cell: InternalDocumentModel) => {
      return <span>{moment(cell.createdDate).format('DD.MM.YYYY')}</span>;
    },
  },
  {
    title: () => <span className="budget-table-tr">{t('tickets.table.columns.acquirer')}</span>,
    width: '100px',
    key: 'consumer',
    render: (cell: InternalDocumentModel) => {
      return <span>{`${cell.consumer.firstName} ${cell.consumer.lastName}`}</span>;
    },
  },
  {
    title: () => <span className="budget-table-tr">{t('project.details.table.project')}</span>,
    width: '70px',
    key: 'project',
    render: (cell: InternalDocumentModel) => {
      return <span>{cell.project.name}</span>;
    },
  },
  {
    title: () => <span className="budget-table-tr">{t('project.details.table.property')}</span>,
    width: '70px',
    key: 'property',
    render: (cell: InternalDocumentModel) => {
      return <span>{cell.property.title}</span>;
    },
  },
  {
    title: () => <span className="budget-table-tr">{t('internal.documents.signatories')}</span>,
    width: '100px',
    key: 'signatories',
    render: (cell: InternalDocumentModel) => {
      return cell.signatories.map((s) => (
        <div style={{ display: 'flex', alignItems: 'center' }} key={s.user.id}>
          {s.isSign && <CheckOutlined style={{ color: '#439544' }} />}
          <span
            key={s.user.id}
            style={{
              marginLeft: s.isSign ? '1rem' : 'calc(1rem + 14px)',
            }}>{`${s.user.firstName} ${s.user.lastName}`}</span>
        </div>
      ));
    },
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.status')}</span>,
    key: 'status',
    width: '80px',
    render: (cell: InternalDocumentModel) => setImgByStatus(cell.status),
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.action')}</span>,
    key: 'action',
    width: '150px',
    render: (cell: InternalDocumentModel) => (
      <div style={{ display: 'flex', gap: '1rem' }}>
        <div onClick={() => authDownloadFile(cell.file)} className="download-budget-item common-unarchive-btn">
          <img src={downloadIcon} alt="" style={{ height: '15px', width: '15px', marginRight: '0.5rem' }} />
          <span style={{ color: '#00122d', fontWeight: 600 }}>{t('budget.table.download')}</span>
        </div>
        <div onClick={() => unArchive(cell.id)} className="download-budget-item common-unarchive-btn">
          <img src={archivedIcon} alt="" style={{ height: '15px', width: '15px', marginRight: '0.5rem' }} />
          <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.title.unarchive')}</span>
        </div>
      </div>
    ),
  },
];
