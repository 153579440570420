import { Button, Empty, List, notification, Pagination, Skeleton, Spin, TablePaginationConfig } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../contexts/AppContextProvider';
import TasksControl from './TasksControl';
import TaskItem from './TaskItem';
import TasksService from '../../../services/TasksService';
import { TaskModel, TasksModel } from '../../../models/TasksModel';
import { generateFilterfromObj } from '../../../helpers/generateFIlterfromObj';
import TaskForm from './TaskForm';
import css from '../../../assets/styles/tasks.module.css';
import { useTranslation } from 'react-i18next';
import { generateSorter } from '../../../helpers/generateSorter';
import { FilterTypes } from '../../../constants/filterTypes';
import { FiltersContext } from '../../../contexts/FiltersContextProvider';
import filterIcon from '../../../assets/images/ic-filter.svg';
import filterActiveIcon from '../../../assets/images/ic-filter-active.svg';
import { useLessThen801 } from '../../../helpers/mediaDetect';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FilterValue } from 'antd/lib/table/interface';
import { IAppContext } from '../../../typings/IApp';
const service = new TasksService();

interface ITaskListInfo {
  consumerId?: number;
  isTab?: boolean;
}

const TasksListInfo = ({ consumerId, isTab = false }: ITaskListInfo) => {
  const {
    app: { isConsumer, user, isSupplier },
  } = useContext<IAppContext>(AppContext);
  const {
    filters: { tasksFilter },
  } = useContext(FiltersContext);
  const { t } = useTranslation();
  const [fetchingData, setFetchingData] = useState(false);
  const [tasks, setTasks] = useState<TasksModel>([]);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [currentFilters, setCurrentFilters] = useState(
    consumerId
      ? {}
      : {
          'status/code': {
            type: FilterTypes.SELECT,
            value: tasksFilter.status,
          },
          'assignee/id': {
            type: FilterTypes.SELECT,
            value: tasksFilter.assigneeRole === 'me' ? tasksFilter.assignee : null,
          },
          deadline: {
            type: FilterTypes.DATE_RANGE,
            value: tasksFilter.dateRange,
          },
        },
  );
  const [isCreate, setIsCreate] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 1,
  });

  const option = {
    count: true,
    filters: {
      filter: generateFilterfromObj(currentFilters).filter,
    },
    top: pagination.pageSize,
    current: pagination.current,
    skip: pagination.pageSize * (pagination.current - 1),
    orderBy: generateSorter(['status/code desc', 'createdDate desc']).orderBy,
  };

  const getTasks = (option: { [name: string]: any }) => {
    setFetchingData(true);
    service
      .getTasks(option)
      .then((res) => {
        const { count, items } = res;
        const { current, top } = option;
        setPagination({ ...pagination, current, total: count, pageSize: top });
        setTasks(items);
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingData(false));
  };

  useEffect(() => {
    getTasks(option);
  }, []);

  const applyNewFilter = (filters: { [name: string]: any }) => {
    setCurrentFilters({ ...currentFilters, ...filters });
    return { ...currentFilters, ...filters };
  };

  const onControlChange = (pagination?: TablePaginationConfig, filters?: Record<string, FilterValue | null | any>) => {
    let params: { [name: string]: any } = {
      ...option,
    };

    if (filters) {
      params = {
        ...params,
        filters: generateFilterfromObj(applyNewFilter(filters)),
      };
    } else {
      params = {
        ...params,
        filters: generateFilterfromObj(currentFilters),
      };
    }
    if (pagination && pagination.pageSize && pagination.current) {
      params = {
        ...params,
        top: pagination.pageSize,
        skip: pagination.pageSize * (pagination.current - 1),
        current: pagination.current,
      };
    } else {
      params = {
        ...params,
        skip: 0,
        current: 1,
      };
    }

    getTasks(params);
  };

  const onChangePagination = (page: number, pageSize: number) => {
    onControlChange({ current: page, pageSize });
  };

  const getClassName = () => {
    if (isConsumer || isSupplier) {
      return css['tasks-container--consumer'];
    } else {
      return isTab
        ? css[`tasks-container--agent${isCreate ? '_create' : ''}`]
        : css[`tasks-container${isCreate ? '_create' : ''}`];
    }
  };

  const changeOpenFilter = () => {
    setIsOpenFilter(!isOpenFilter);
  };

  const loadMore = () => {
    setFetchingData(true);
    option.skip = tasks.length;

    service
      .getTasks(option)
      .then((res) => {
        const { count, items } = res;
        const { current, top } = option;
        setPagination({ ...pagination, current, total: count, pageSize: top });
        setTasks([...tasks, ...items]);
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingData(false));
  };

  if (useLessThen801()) {
    return (
      <>
        <div className="common-mobile-title-container">
          <span style={{ fontSize: '20px' }}>{t('consumers.manage.tabs.tasks')}</span>
          {!isConsumer && !isSupplier && (
            <>
              {!consumerId && (
                <div className="common-modbile-filter-container" onClick={changeOpenFilter}>
                  <img src={isOpenFilter ? filterActiveIcon : filterIcon} width={16} />
                  <span className="filter-title" style={{ marginRight: '1rem' }}>
                    {isOpenFilter ? t('common.filter.close') : t('common.filter.open')}
                  </span>
                </div>
              )}
              <Button
                className={`${css[isCreate ? 'add-task-btn-active' : 'add-task-btn']} common-primary-btn`}
                onClick={() => setIsCreate(!isCreate)}>
                <span>{t('tasks.control.add.btn')}</span>
              </Button>
            </>
          )}
        </div>
        {isOpenFilter && (
          <TasksControl
            setTasks={setTasks}
            onControlChange={onControlChange}
            setIsCreate={setIsCreate}
            isCreate={isCreate}
            consumerId={consumerId}
          />
        )}
        <div
          className={`${css['tasks-container-frame']} ${
            css[`task-container-frame${isConsumer || isSupplier ? '-consumer' : isTab ? '-agent' : '-size'}`]
          }`}>
          {!isConsumer && !isSupplier && (
            <TaskForm
              getTasks={() => getTasks(option)}
              showBottom={isCreate}
              openBottom={() => setIsCreate(true)}
              userId={consumerId ? +consumerId : user.id}
              onFinish={() => setIsCreate(false)}
            />
          )}
          {tasks.length > 0 ? (
            <div className={getClassName()}>
              <InfiniteScroll
                dataLength={tasks.length}
                next={loadMore}
                hasMore={tasks.length < pagination.total}
                loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                scrollableTarget="scrollableDiv">
                <List
                  dataSource={tasks}
                  rowKey={(item: TaskModel) => item.id}
                  renderItem={(item: TaskModel) => (
                    <TaskItem
                      key={item.id}
                      isConsumer={isConsumer}
                      isSupplier={isSupplier}
                      task={item}
                      getTasks={() => getTasks(option)}
                      setFetchingData={setFetchingData}
                    />
                  )}
                />
              </InfiniteScroll>
            </div>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </div>
      </>
    );
  }

  return (
    <Spin spinning={fetchingData}>
      {!isConsumer && !isSupplier && (
        <TasksControl
          setTasks={setTasks}
          onControlChange={onControlChange}
          setIsCreate={setIsCreate}
          isCreate={isCreate}
          consumerId={consumerId}
        />
      )}
      <div
        className={`${css['tasks-container-frame']} ${
          css[`task-container-frame${isConsumer || isSupplier ? '-consumer' : isTab ? '-agent' : '-size'}`]
        }`}>
        {!isConsumer && !isSupplier && (
          <TaskForm
            getTasks={() => getTasks(option)}
            showBottom={isCreate}
            openBottom={() => setIsCreate(true)}
            userId={consumerId ? +consumerId : user.id}
            onFinish={() => setIsCreate(false)}
          />
        )}
        {tasks.length > 0 ? (
          <div
            className={getClassName()}
            style={{
              overflowY: 'auto',
            }}>
            <List
              dataSource={tasks}
              rowKey={(item: TaskModel) => item.id}
              renderItem={(item: TaskModel) => (
                <TaskItem
                  key={item.id}
                  isConsumer={isConsumer}
                  isSupplier={isSupplier}
                  task={item}
                  getTasks={() => getTasks(option)}
                  setFetchingData={setFetchingData}
                />
              )}
            />
          </div>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
        <div className="tasks-pagination" style={{ textAlign: 'right' }}>
          <Pagination
            size="small"
            style={{ marginTop: '0.5rem' }}
            current={pagination.current}
            showSizeChanger
            onChange={onChangePagination}
            pageSize={pagination.pageSize}
            total={pagination.total}
          />
        </div>
      </div>
    </Spin>
  );
};

export default TasksListInfo;
