import React from 'react';
import css from '../../../assets/styles/calendar.module.css';
import CalendarComponent from '../components/CalendarComponent';
import ProgressBrokerComponent from './components/ProgressBrokerComponent';

interface BrokerCalendarProps {
  propertyId: number;
}

const BrokerCalendar = (props: BrokerCalendarProps) => {
  const { propertyId } = props;

  return (
    <div className={css['container']}>
      <ProgressBrokerComponent propertyId={propertyId} />
      <CalendarComponent propertyId={propertyId} />
    </div>
  );
};

export default BrokerCalendar;
