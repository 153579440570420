import HttpService from './common/httpService';
import config from '../config';
import { CreateStepModel, UpdatedStep } from '../models/TimelineModel';

class TimelineService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }
  //Construction stages in property tab

  public getStatuses() {
    return this.httpService.get(`${config.baseRequest}dictionary/timeline_statuses`);
  }
  public getTimeline(id: number) {
    return this.httpService.get(`${config.baseRequest}timeline/${id}`);
  }
  public updateTimeline(body: UpdatedStep) {
    return this.httpService.put(`${config.baseRequest}timeline`, body);
  }
  public createTimeline(body: CreateStepModel, propertyId: number) {
    return this.httpService.post(`${config.baseRequest}timeline/${propertyId}`, body);
  }
}

export default TimelineService;
