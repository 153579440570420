import React, { useContext, useEffect, useState } from 'react';
import ListPageServices from '../../services/PropertiesService';
import ClientInfo from './components/ClientInfo';
import ProjectDetails from './components/ProjectDetails';
import css from '../../assets/styles/details.module.css';
import { PropertyModel } from '../../models/PropertyModel';
import { AppContext } from '../../contexts/AppContextProvider';
import { useParams } from 'react-router';
import { IUserEasy } from '../../models/UserModel';
import { IAppContext } from '../../typings/IApp';

interface BrokerDetailsPageProps {
  brokerInfo?: IUserEasy;
  consumers: IUserEasy[];
}

const propertyService = new ListPageServices();

const BrokerDetailsPage = ({ brokerInfo, consumers }: BrokerDetailsPageProps) => {
  const {
    app: { user, isConsumer },
  } = useContext<IAppContext>(AppContext);
  const params = useParams<{ projectId: string; propertyId: string }>();
  const { projectId, propertyId } = params;

  const [propertyInfo, setPropertyInfo] = useState<PropertyModel | null>(null);
  const projectKey =
    (user && user.projects.length > 0 && user.projects.find((item) => item.id == +projectId)?.key) || '';
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  useEffect(() => {
    propertyService
      .getPropertyById({ id: +propertyId, projectKey })
      .then((value: PropertyModel) => setPropertyInfo(value))
      .catch((e) => console.log(e))
      .finally(() => setIsNeedUpdate(false));
  }, [isNeedUpdate]);

  return (
    <div className={css['container-broker']}>
      <div className={css['container-broker-client-info']}>
        {isConsumer && propertyInfo && <ClientInfo propertyInfo={propertyInfo} userId={user.id} />}
        {brokerInfo && <ClientInfo propertyInfo={propertyInfo} brokerInfo={brokerInfo} />}
      </div>
      <ProjectDetails propertyInfo={propertyInfo} setIsNeedUpdate={setIsNeedUpdate} consumers={consumers} />
    </div>
  );
};

export default BrokerDetailsPage;
