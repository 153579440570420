import { Button, Modal, notification, Radio, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LogoComponent from '../../../../components/common/LogoComponent';
import { ICreateFlow, IFlow } from '../../../../models/CustomSignFlowModel';
import { IProjectEasy } from '../../../../models/ProjectModel';
import CustomSignFlow from '../../../../services/CustomSignFlow';
import ProjectsService from '../../../../services/ProjectsService';

const service = new ProjectsService();
const serviceFlow = new CustomSignFlow();

interface IModalCopyToProject {
  isVisible: boolean;
  setClose: () => void;
  projectId: number;
  flows: IFlow[];
}

const ModalCopyToProject = ({ isVisible, setClose, projectId, flows }: IModalCopyToProject) => {
  const { t } = useTranslation();
  const [fetchingData, setFetchingData] = useState(true);
  const [items, setItems] = useState<IProjectEasy[]>([]);
  const [item, setItem] = useState<undefined | number>(undefined);

  useEffect(() => {
    service
      .getProjects()
      .then((res: IProjectEasy[]) => {
        setItems(res);
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingData(false));
  }, []);

  const handleCopy = () => {
    if (item) {
      const promises: Promise<any>[] = [];
      flows.forEach((flow) => {
        const body: ICreateFlow = {
          name: flow.name,
          documentTypes: flow.documentTypes.map((item) => ({ code: item.code })),
          projects: [{ id: item }],
          flowsSteps: flow.flowsSteps.map((step) => ({ stepCode: step.stepCode, ord: step.ord })),
        };
        promises.push(serviceFlow.createFlow(body));
      });
      Promise.all(promises)
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        )
        .finally(() => {
          setFetchingData(false);
          setClose();
        });
    }
  };

  return (
    <Modal
      visible={isVisible}
      closable={false}
      centered
      className="common-modal"
      title={<span style={{ fontWeight: 600, fontSize: '18px' }}>{t('projects.flow.modal.title')}</span>}
      footer={
        <div className="common-modal-footer">
          <Button
            className={`common-gray-border-btn common-secondary-btn`}
            disabled={fetchingData}
            onClick={() => setClose()}>
            {t('budget.modal.cancel.btn')}
          </Button>
          <Button className={`common-green-btn common-modal-btn`} disabled={fetchingData} onClick={handleCopy}>
            {t('projects.flow.modal.btn.copy').toUpperCase()}
          </Button>
        </div>
      }
      maskClosable={false}>
      <Radio.Group onChange={(e) => setItem(e.target.value)} value={item} style={{ margin: '0 1rem' }}>
        <Space direction="vertical">
          {items.length > 0 &&
            items
              .filter((item) => item.id !== projectId)
              .map((item) => (
                <Radio value={item.id} key={item.id}>
                  <LogoComponent id={item.id} name={item.title} image={item.logo || ''} />{' '}
                  <span style={{ marginLeft: '0.5rem' }}>{item.title}</span>
                </Radio>
              ))}
        </Space>
      </Radio.Group>
    </Modal>
  );
};

export default ModalCopyToProject;
