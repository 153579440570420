import { Badge, Input, Form, Button, notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../assets/styles/budget.module.css';
import { AppContext } from '../../../contexts/AppContextProvider';
import { VictoryPie, VictoryTheme } from 'victory';
import BudgetService from '../../../services/BudgetService';
import { BudgetPricesModel } from '../../../models/BudgetModel';
import { convertToPrice } from '../../../helpers/convertToPrice';
import ListPageServices from '../../../services/PropertiesService';
import checkIcon from '../../../assets/images/ic-check-mark-white.svg';
import { LoadingOutlined } from '@ant-design/icons';
import budgetIcon from '../../../assets/images/ic-budget.svg';
import { IAppContext } from '../../../typings/IApp';

const service = new BudgetService();
const propertyService = new ListPageServices();

interface BudgetPrice {
  id: number;
  color: string;
  name: string;
  price?: number;
}

interface DiagramBudgetProps {
  propertyId: number;
  setIsNeedUpdate?: (b: boolean) => void;
  isNeedUpdate?: boolean;
  projectId?: number;
  currency: string;
}

const DiagramBudget = (props: DiagramBudgetProps) => {
  const { t } = useTranslation();
  const {
    app: { isConsumer, user },
  } = useContext<IAppContext>(AppContext);
  const { propertyId, isNeedUpdate, setIsNeedUpdate, projectId, currency } = props;
  const projectKey =
    (user && user.projects.length > 0 && user.projects.find((item) => item.id == projectId)?.key) || '';

  const [prices, setPrices] = useState<BudgetPricesModel | null>(null);
  const [isEditMode, setEditMode] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);

  const isEmptyPrices = () => {
    return prices && Object.values(prices).every((x) => !x);
  };

  const getBudgetPrices = () => {
    service
      .getBudgetPrices(propertyId)
      .then((value: BudgetPricesModel) => {
        setPrices(value);
      })
      .catch((e) => console.log(e))
      .finally(() => setIsNeedUpdate && setIsNeedUpdate(false));
  };

  useEffect(() => {
    getBudgetPrices();
  }, [isNeedUpdate, propertyId]);

  const data = [
    { label: ' ', y: Number(prices?.remainingBudget) },
    { label: ' ', y: Number(prices?.toBeSpentBudget) },
    { label: ' ', y: Number(prices?.spentBudget) },
  ];

  const budgetPriceList = [
    {
      id: 1,
      color: '#076ee5',
      name: t('budget.price.remaining'),
      price: prices?.remainingBudget,
    },
    {
      id: 2,
      color: '#07dfec',
      name: t('budget.price.tospent'),
      price: prices?.toBeSpentBudget,
    },
    {
      id: 3,
      color: '#09fec3',
      name: t('budget.price.spent'),
      price: prices?.spentBudget,
    },
  ];

  const setTotalBudget = (value: any) => {
    if (isNaN(Number(value.budget))) {
      notification.error({
        message: t('budget.error.format.total.number'),
      });
      return;
    }

    const body = {
      budget: +value.budget.replace(/^0+/, '') * 100,
      budget_currency: {
        code: 'chf',
      },
    };

    setFetchingData(true);
    propertyService
      .saveProperty({ body, id: propertyId, projectKey })
      .then(() => {
        notification.success({
          message: t('budget.error.format.total.number'),
        });
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setFetchingData(false);
        setEditMode(false);
        getBudgetPrices();
      });
  };

  return (
    <div className={css['budget-diagram-container']}>
      <div className={css['total-budget-container']}>
        <span className={css['total-budget-title']}>{t('budget.total.title')}</span>
        {isConsumer && prices ? (
          <span className={css['total-budget-desc']}>{`${currency} ${convertToPrice(prices.totalBudget)}`}</span>
        ) : prices && isEditMode ? (
          <span className={css['total-budget-input']}>
            <Form
              autoComplete="off"
              onFinish={setTotalBudget}
              layout="horizontal"
              className="total-budget-form common-animation-primary">
              <Form.Item name="budget" initialValue={prices.totalBudget / 100}>
                <Input bordered={false} />
              </Form.Item>
              <Button htmlType="submit" className={css['total-budget-submit-btn']} disabled={fetchingData}>
                {fetchingData ? <LoadingOutlined style={{ color: '#fff' }} /> : <img src={checkIcon} alt="" />}
              </Button>
            </Form>
          </span>
        ) : (
          <span className={css['total-budget-info']} onClick={() => setEditMode(true)}>
            {currency} {prices && convertToPrice(prices.totalBudget)}
          </span>
        )}
      </div>
      <div className={css['diagram-container']}>
        {prices && !isEmptyPrices() ? (
          <VictoryPie
            theme={VictoryTheme.material}
            data={data}
            colorScale={['#076ee5', '#07dfec', '#09fec3']}
            labelRadius={150}
          />
        ) : (
          <div className={css['diagram-empty-container']}>
            <img src={budgetIcon} alt="" style={{ height: '9rem', width: '9rem' }} />
            <span style={{ color: '#bcbebf', marginTop: '1rem' }}>{t('common.no.data')}</span>
          </div>
        )}
        <ul className={css['budget-list']}>
          {prices &&
            budgetPriceList.map((budgetPrice: BudgetPrice) => (
              <li className={css['budget-list-item']} key={budgetPrice.id}>
                <Badge color={budgetPrice.color} />
                <span>{`${budgetPrice.name} : ${currency} ${
                  budgetPrice.price ? convertToPrice(budgetPrice.price) : '-'
                }`}</span>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};
export default DiagramBudget;
