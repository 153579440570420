import React, { useContext, useState } from 'react';
import css from '../../../../assets/styles/dashboard.module.css';
import { AppContext } from '../../../../contexts/AppContextProvider';
import { IAppContext } from '../../../../typings/IApp';
import NoPropertyPage from '../../../common/NoPropertyPage';
import ConsumerCarousel from './ConsumerCarousel';
import ConsumerDashboardInfo from './ConsumerDashboardInfo';
import ConsumerDashboardNotes from './ConsumerDashboardNotes';

const ConsumerDashboard = () => {
  const [isEmptyGallery, setIsEmptyGallery] = useState(true);
  const {
    app: { currentProperty },
  } = useContext<IAppContext>(AppContext);

  if (!currentProperty) {
    return <NoPropertyPage />;
  }

  return (
    <div className={css['consumer-container']}>
      <ConsumerDashboardInfo />
      <div className={css[isEmptyGallery ? 'consumer-container-right-notes' : 'consumer-container-right-col']}>
        <ConsumerCarousel setIsEmptyGallery={setIsEmptyGallery} />
        <ConsumerDashboardNotes isEmptyGallery={isEmptyGallery} />
      </div>
    </div>
  );
};

export default ConsumerDashboard;
