import React from 'react';
import { Badge, Button, Dropdown, Menu } from 'antd';
import { DashOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import { TFunction } from 'react-i18next';

interface LevelsColumnsModel {
  t: TFunction<'translation'>;
  typologyId: number;
}

export const getColumns = ({ t, typologyId }: LevelsColumnsModel) => [
  {
    title: t('level.table.columns.name'),
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: t('level.table.columns.title'),
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: t('level.table.columns.level'),
    dataIndex: 'level',
    key: 'level',
  },
  {
    title: t('level.table.columns.isDefault'),
    dataIndex: 'isDefault',
    key: 'isDefault',
    render: (cell: any) => (cell ? <Badge status="success" text={t('level.create.card.level.isDefault.label')} /> : ''),
  },
  {
    title: t('typology.table.columns.action'),
    key: 'action',
    render: (cell: any) => {
      const menu = (
        <Menu>
          <Menu.Item key="edit">
            <NavLink to={`/typologies/${typologyId}/levels/modify/${cell.id}`}>{t('common.btn.modify')}</NavLink>
          </Menu.Item>
        </Menu>
      );

      return (
        <span>
          <Dropdown overlay={menu} trigger={['click']}>
            <Button type="link">
              <DashOutlined />
            </Button>
          </Dropdown>
        </span>
      );
    },
  },
];
