import { Card, Col, Row, Form, Input, Button, Radio, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import logo from '../../assets/images/logo_dark.svg';
import logo_big from '../../assets/images/login_big.png';
import css from '../../assets/styles/login.module.css';
import { useTranslation } from 'react-i18next';
import { ArrowRightOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import i18n, { langs } from '../../utils/i18n';
import { useHistory, useLocation } from 'react-router';
import RegisterPageServices from '../../services/RegisterPageService';
import { RegisterModel, UserInfoModel } from '../../models/RegisterModel';

const service = new RegisterPageServices();

const RegisterPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [userInfo, setUserInfo] = useState<UserInfoModel | null>(null);
  const currentToken = location.search.slice(1);

  useEffect(() => {
    service
      .checkToken(currentToken)
      .then((value: UserInfoModel) => {
        setUserInfo(value);
      })
      .catch((e) => {
        history.push('/login');
      });
  }, []);

  const LanguageRadio = ({ i18n, changeLanguage }: any) => (
    <Radio.Group
      size="small"
      defaultValue={i18n.language}
      onChange={(e) => changeLanguage(e.target.value)}
      className={css['language-group-btn']}>
      {langs &&
        langs.map((lng: any) => (
          <Radio.Button key={lng.key} value={lng.key} className={css['language-btn']}>
            {lng.label}
          </Radio.Button>
        ))}
    </Radio.Group>
  );
  const changeLanguage = (key: string) => {
    if (i18n.language !== key) {
      i18n.changeLanguage(key);
    }
  };
  const handleSubmit = (values: RegisterModel) => {
    if (values.password !== values.confirmPassword) {
      notification.error({
        message: 'Error',
        description: 'Password mismatch',
      });
    } else {
      service
        .registerUser({ ...values, email: userInfo?.email })
        .then(() => history.push('/login'))
        .catch((e: any) => {
          notification.error({
            message: 'Error',
            description: e.message,
          });
        });
    }
  };

  if (!userInfo) {
    return null;
  }
  return (
    <Row justify="center" align="middle" className="login-container">
      <Col>
        <div style={{ background: '#fff' }}>
          <img src={logo_big} alt="" className={css['login-img']} style={{ height: 700 }} />
        </div>
      </Col>
      <Col className="container-col-register">
        <Card style={{ width: 400, height: 700, border: 'none' }} className={css['login-body']}>
          <img src={logo} alt="Logo" className={css['logo-login']} />
          <p className={css['login-form-title']} style={{ fontSize: '18px' }}>
            {t('register.title')}
          </p>
          <Form onFinish={handleSubmit} className={css['login-form']}>
            <p className={css['register-title']}>{t('register.name')}</p>
            <Form.Item
              name="firstName"
              initialValue={userInfo ? userInfo?.firstName : ''}
              rules={[
                {
                  required: true,
                  message: 'Please input your name!',
                },
              ]}>
              <Input className="common-animation-primary" style={{ borderRadius: '6px' }} autoComplete="off" />
            </Form.Item>
            <p className={css['register-title']}>{t('register.surname')}</p>
            <Form.Item
              name="lastName"
              initialValue={userInfo ? userInfo.lastName : ''}
              rules={[
                {
                  required: true,
                  message: 'Please input your surname!',
                },
              ]}>
              <Input className="common-animation-primary" style={{ borderRadius: '6px' }} autoComplete="off" />
            </Form.Item>
            <p className={css['register-title']}>{t('register.phone')}</p>
            <Form.Item
              name="phone"
              initialValue={userInfo ? userInfo.phone : ''}
              rules={[
                {
                  required: true,
                  message: 'Please input your phone!',
                },
              ]}>
              <Input className="common-animation-primary" style={{ borderRadius: '6px' }} autoComplete="off" />
            </Form.Item>
            <p className={css['register-title']}>{t('register.password')}</p>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: t('register.password.validation.required'),
                },
                {
                  min: 6,
                  message: t('register.password.validation.min'),
                },
                {
                  pattern: /^(?:(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).*)$/,
                  message: t('register.password.validation.pattern'),
                },
              ]}>
              <Input.Password
                className="common-animation-primary"
                type="password"
                style={{ borderRadius: '6px' }}
                autoComplete="off"
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            <p className={css['register-title']}>{t('register.password.repeat')}</p>
            <Form.Item
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: t('register.password.validation.required'),
                },
                {
                  min: 6,
                  message: t('register.password.validation.min'),
                },
                {
                  pattern: /^(?:(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).*)$/,
                  message: t('register.password.validation.pattern'),
                },
              ]}>
              <Input.Password
                className="common-animation-primary"
                type="password"
                style={{ borderRadius: '6px' }}
                autoComplete="off"
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            <div style={{ height: '100%' }}>
              <Button
                type="primary"
                htmlType="submit"
                className={`${css['login-form-button']} common-primary-btn`}
                block
                shape="round">
                {t('register.btn.send')}
                <ArrowRightOutlined />
              </Button>
            </div>
          </Form>
          <div className={css['language-radio']}>
            <LanguageRadio i18n={i18n} changeLanguage={changeLanguage} />
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default RegisterPage;
