import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import config from '../../config';

import translationEN from './locales/en-US/translation.json';
import translationFR from './locales/fr-FR/translation.json';
import frFR from 'antd/lib/locale-provider/fr_FR';
import enUS from 'antd/lib/locale-provider/en_US';

const test = () => {
  const arr: any[] = [];

  Object.keys(translationFR).forEach((key) => {
    if ((translationEN as any)[key] === undefined) {
      arr.push(key);
    }
  });
};
test();
// lang list
const langs = [
  {
    key: 'en-US',
    label: 'EN',
    antLocale: enUS,
  },
  {
    key: 'fr-FR',
    label: 'FR',
    antLocale: frFR,
  },
];

// the translations
const resources = {
  'en-US': { translation: translationEN },
  'fr-FR': { translation: translationFR },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    supportedLngs: ['en-US', 'fr-FR'],
    fallbackLng: config.defaultLng || 'en-US',
    detection: {
      order: ['localStorage'],
      lookupFromPathIndex: 0,
      caches: ['localStorage'],
    },
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export { langs };
export default i18n;
