import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import downloadIcon from '../../../assets/images/ic-download-dark.svg';
import css from '../../../assets/styles/consumers.module.css';
import editIcon from '../../../assets/images/ic-edit-dark.svg';
import { InternalDocumentModel } from '../../../models/InternalDocumentsModel';
import moment from 'moment';
import { IStatusModel } from '../../../models/StatusModel';
import { CheckOutlined, FileDoneOutlined, FileSearchOutlined } from '@ant-design/icons';
import { noPDFFormat } from '../../../helpers/noPDFFormat';
import { authDownloadFile } from '../../../helpers/authFileTools';
import moreIcon from '../../../assets/images/ic-more.svg';
import deleteIcon from '../../../assets/images/ic-delete-dark-gray.svg';
import archiveIcon from '../../../assets/images/ic-archive.svg';
import tagIcon from '../../../assets/images/tag-icon.svg';
import quoteIcon from '../../../assets/images/quo.svg';
import invoiceIcon from '../../../assets/images/inv.svg';
import documentIcon from '../../../assets/images/doc.svg';
import amendmentIcon from '../../../assets/images/ame.svg';
import allocateIcon from '../../../assets/images/alocate.svg';
import { TFunction } from 'react-i18next';

interface InternalColumnsModel {
  t: TFunction<'translation'>;
  setOpenDelete: any;
  openPreview: any;
  openArchiveConfirm: any;
  openUpload: any;
  userId: number;
  openSigningConfirm: any;
}

const colors: any = {
  new: '#1374e6',
  approved: '#19ac87',
  signed: '#00d29f',
  rejected: '#ff4d4f',
};

const icons: any = {
  ['quote']: quoteIcon,
  ['document']: documentIcon,
  ['invoice']: invoiceIcon,
  ['amendment']: amendmentIcon,
};

const setImgByStatus = (status: IStatusModel) => {
  const { code, description } = status;
  return <span style={{ color: colors[code] || '#eb7d5a', fontSize: '14px' }}>{description.toLowerCase()}</span>;
};

const setType = (code: string) => {
  return <img src={icons[code]} alt="" />;
};

export const getColumns = ({
  t,
  setOpenDelete,
  openPreview,
  openArchiveConfirm,
  openUpload,
  userId,
  openSigningConfirm,
}: InternalColumnsModel) => [
  {
    title: () => (
      <span className="budget-table-tr" style={{ marginLeft: '8px' }}>
        <img src={tagIcon} alt="" />
      </span>
    ),
    key: 'type',
    width: '50px',
    render: (cell: InternalDocumentModel) => setType(cell.type.code),
  },
  {
    title: () => <span className="budget-table-tr">{t('category.create.name')}</span>,
    dataIndex: 'name',
    width: '200px',
    sorter: true,
    key: 'name',
  },
  {
    title: () => <span className="budget-table-tr">{t('documents.table.date')}</span>,
    width: '100px',
    key: 'date',
    render: (cell: InternalDocumentModel) => {
      return <span>{moment(cell.createdDate).format('DD.MM.YYYY')}</span>;
    },
  },
  {
    title: () => <span className="budget-table-tr">{t('tickets.table.columns.acquirer')}</span>,
    width: '250px',
    key: 'consumer',
    render: (cell: InternalDocumentModel) => {
      return <span>{`${cell.consumer.firstName} ${cell.consumer.lastName}`}</span>;
    },
  },
  {
    title: () => <span className="budget-table-tr">{t('project.details.table.project')}</span>,
    width: '150px',
    key: 'project',
    render: (cell: InternalDocumentModel) => {
      return <span>{cell.project.name}</span>;
    },
  },
  {
    title: () => <span className="budget-table-tr">{t('project.details.table.property')}</span>,
    width: '150px',
    key: 'property',
    render: (cell: InternalDocumentModel) => {
      return <span>{cell.property.title}</span>;
    },
  },
  {
    title: () => <span className="budget-table-tr">{t('internal.documents.signatories')}</span>,
    width: '250px',
    key: 'signatories',
    render: (cell: InternalDocumentModel) => {
      return cell.signatories.map((s) => (
        <div style={{ display: 'flex', alignItems: 'center' }} key={s.user.id}>
          {s.isSign && <CheckOutlined style={{ color: '#439544' }} />}
          <span
            key={s.user.id}
            style={{
              marginLeft: s.isSign ? '1rem' : 'calc(1rem + 14px)',
            }}>{`${s.user.firstName} ${s.user.lastName}`}</span>
        </div>
      ));
    },
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.status')}</span>,
    key: 'status',
    width: '120px',
    render: (cell: InternalDocumentModel) => setImgByStatus(cell.status),
  },
  {
    title: () => <span className="documents-table-tr">{t('documents.table.action')}</span>,
    key: 'action',
    width: 70,
    fixed: 'right' as any,
    render: (cell: InternalDocumentModel) => {
      const isCanSign =
        cell.signatories
          .filter((signatory: any) => signatory.user.id === userId)
          .findIndex((signatory: any) => !signatory.isSign) >= 0;

      const menu = (
        <Menu className="update-menu-container" style={{ width: '130px' }}>
          <Menu.Item key="edit">
            <div
              onClick={() =>
                openUpload({
                  documentType: cell.type.code,
                  documentName: cell.name,
                  currentId: cell.id,
                  isAllocate: false,
                })
              }
              className={css['update-menu-item']}>
              <Button
                style={{
                  border: 'none',
                  backgroundColor: 'inherit',
                  boxShadow: 'none',
                  padding: 0,
                  marginRight: '0.5rem',
                }}>
                <img src={editIcon} alt="" style={{ height: '15px', width: '15px' }} />
              </Button>
              <span style={{ color: '#00122d', fontWeight: 600 }}>{t('budget.table.edit')}</span>
            </div>
          </Menu.Item>
          {['new', 'signed'].includes(cell.status.code) && (
            <Menu.Item key="allocate">
              <div
                onClick={() =>
                  openUpload({
                    documentType: cell.type.code,
                    documentName: cell.name,
                    currentId: cell.id,
                    isAllocate: true,
                  })
                }
                className={css['update-menu-item']}>
                <Button
                  style={{
                    border: 'none',
                    backgroundColor: 'inherit',
                    boxShadow: 'none',
                    padding: 0,
                    marginRight: '0.5rem',
                  }}>
                  <img src={allocateIcon} alt="" style={{ height: '15px', width: '15px' }} />
                </Button>
                <span style={{ color: '#00122d', fontWeight: 600 }}>{t('internal.documents.allocate.status')}</span>
              </div>
            </Menu.Item>
          )}
          {cell.file && (
            <Menu.Item key="preview">
              <div
                className={css['update-menu-item']}
                onClick={() => {
                  cell.file.slice(-3) === 'pdf' ? openPreview(cell.id) : noPDFFormat(t);
                }}>
                <FileSearchOutlined />
                <span style={{ color: '#00122d', fontWeight: 600, marginLeft: '0.6rem' }}>
                  {t('common.title.preview')}
                </span>
              </div>
            </Menu.Item>
          )}
          {cell.signatories.length > 0 && isCanSign && ['pending', 'partly_signed'].includes(cell.status.code) && (
            <Menu.Item key="sign">
              <div className={css['update-menu-item']} onClick={() => openSigningConfirm(cell.id, cell.name)}>
                <FileDoneOutlined />
                <span style={{ color: '#00122d', fontWeight: 600, marginLeft: '0.6rem' }}>
                  {t('common.title.sign')}
                </span>
              </div>
            </Menu.Item>
          )}
          <Menu.Item key="delete">
            <div
              onClick={() =>
                setOpenDelete({ documentType: cell.type.code, documentName: cell.name, currentId: cell.id })
              }
              className={css['update-menu-item']}>
              <Button
                style={{
                  border: 'none',
                  backgroundColor: 'inherit',
                  boxShadow: 'none',
                  padding: 0,
                  marginRight: '0.5rem',
                }}>
                <img src={deleteIcon} alt="" style={{ height: '15px', width: '15px' }} />
              </Button>
              <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.title.delete')}</span>
            </div>
          </Menu.Item>
          <Menu.Item key="archive">
            <div onClick={() => openArchiveConfirm(cell.id)} className={css['update-menu-item']}>
              <img src={archiveIcon} alt="" style={{ height: '15px', width: '15px', marginRight: '0.5rem' }} />
              <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.title.archive')}</span>
            </div>
          </Menu.Item>
          {cell.file && (
            <Menu.Item key="upload">
              <div className={css['update-menu-item']} onClick={() => authDownloadFile(cell.file)}>
                <img src={downloadIcon} alt="" style={{ height: '15px', width: '15px', marginRight: '0.5rem' }} />
                <span style={{ color: '#00122d', fontWeight: 600 }}>{t('budget.table.download')}</span>
              </div>
            </Menu.Item>
          )}
        </Menu>
      );
      return (
        <>
          <Dropdown overlay={menu} trigger={['click']}>
            <Button className={css['update-dropdown-btn']}>
              <img src={moreIcon} alt="" height={15} width={15} />
            </Button>
          </Dropdown>
        </>
      );
    },
  },
];
