import React, { useContext } from 'react';
import { Result, Button } from 'antd';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../contexts/AppContextProvider';
import { IAppContext } from '../../typings/IApp';

const NotFoundPage: React.FC = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const {
    app: { isSupplier },
  } = useContext<IAppContext>(AppContext);
  return (
    <Result
      status="404"
      title="404"
      subTitle={t('common.not.found.page')}
      extra={
        <Button type="primary" onClick={() => history.push(isSupplier ? '/consumers' : '/dashboard')}>
          {t('common.back.home.btn')}
        </Button>
      }
    />
  );
};

export default NotFoundPage;
