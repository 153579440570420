import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DictionariesServices from '../../services/DictionariesServices';
import { getErrorNotification } from '../../components/common/Notifications';
import { Select, Form } from 'antd';
import { selectFilterOption } from '../../helpers/selectFilterOption';
import { getNestedValueByPath } from '../../helpers/getNestedValueByPath';
import css from '../../assets/styles/properties.module.css';

const { Option } = Select;

interface DictionarySelectProps {
  dictionaryName: string | null;
  keyField: string;
  showField: string | string[];
  showFieldSeparator?: string;
  onChange?: (value: any) => void;
  value?: any;
  multipleMode?: boolean;
  name: any;
  label: any;
  initialValue?: any;
}

const service = new DictionariesServices();

const DictionaryFieldSelect = ({
  dictionaryName,
  keyField,
  showField,
  showFieldSeparator,
  onChange,
  multipleMode,
  name,
  label,
  initialValue,
}: DictionarySelectProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState([] as any[]);

  useEffect(() => {
    if (dictionaryName) {
      service
        .getDictionary(dictionaryName)
        .then((options) => {
          setOptions(options);
        })
        .catch((e) => {
          getErrorNotification(e);
        });
    }
  }, [dictionaryName]);

  const backupOnChange = () => null;

  return (
    <Form.Item
      name={name}
      label={label}
      initialValue={initialValue}
      rules={[{ required: true, message: t('common.validation.required') }]}
      wrapperCol={{ span: 24 }}>
      <Select
        mode={multipleMode ? 'multiple' : undefined}
        onChange={onChange || backupOnChange}
        className="faq-modify-select common-animation-primary"
        suffixIcon={
          <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
        }
        filterOption={selectFilterOption}>
        {options
          .sort((a, b) => a?.description.toLowerCase().localeCompare(b?.description.toLowerCase()))
          .map((option) => {
            if (Array.isArray(showField)) {
              const label = showField
                .map((field) => getNestedValueByPath(option, field, '.'))
                .join(showFieldSeparator || ', ');
              return (
                <Option key={option[keyField]} value={option[keyField]}>
                  {label}
                </Option>
              );
            }
            return (
              <Option key={option[keyField]} value={option[keyField]}>
                {option[showField]}
              </Option>
            );
          })}
      </Select>
    </Form.Item>
  );
};

export default DictionaryFieldSelect;
