import moment from 'moment';
import React from 'react';
import { convertToPrice } from '../../../../helpers/convertToPrice';
import archivedIcon from '../../../../assets/images/ic-archive.svg';
import downloadIcon from '../../../../assets/images/ic-download-dark.svg';
import { authDownloadFile } from '../../../../helpers/authFileTools';
import { IQuoteModel } from '../../../../models/QuotesModel';
import { TFunction } from 'react-i18next';

interface ColumnsProps {
  t: TFunction<'translation'>;
  unArchive: (id: number) => void;
  isConsumer: boolean;
}

export const getColumns = ({ t, unArchive, isConsumer }: ColumnsProps) => [
  {
    title: () => <span className="budget-table-tr">№</span>,
    key: 'number',
    width: '15%',
    render: (cell: IQuoteModel) => <span>{cell.number}</span>,
  },
  {
    title: () => <span className="budget-table-tr">{t('documents.table.column.description')}</span>,
    key: 'description',
    width: '14%',
    render: (cell: IQuoteModel) => <span style={{ wordWrap: 'break-word' }}>{cell.description}</span>,
  },
  {
    title: () => <span className="budget-table-tr">{t('amendment.title')}</span>,
    key: 'amendment',
    width: '14%',
    render: (cell: IQuoteModel) => <span style={{ wordWrap: 'break-word' }}>{cell?.amendment?.number || '-'}</span>,
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.sum.total')}</span>,
    key: 'sumWithVat',
    width: '14%',
    render: (cell: IQuoteModel) =>
      `${cell.currency?.description || t('budget.currency')} ${convertToPrice(+cell.sumWithVat)}`,
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.form.deadline')}</span>,
    key: 'deadline',
    width: '12%',
    render: (cell: IQuoteModel) => (!!cell.deadline ? moment(cell.deadline).format('DD.MM.YYYY') : '-'),
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.status')}</span>,
    key: 'status',
    width: '11%',
    render: () => <span>{t('common.archived.status')}</span>,
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.action')}</span>,
    key: 'action',
    width: 224,
    render: (cell: IQuoteModel) => (
      <div style={{ display: 'flex', gap: '1rem' }}>
        <div onClick={() => authDownloadFile(cell.file)} className="download-budget-item common-unarchive-btn">
          <img src={downloadIcon} alt="" style={{ height: '15px', width: '15px', marginRight: '0.5rem' }} />
          <span style={{ color: '#00122d', fontWeight: 600 }}>{t('budget.table.download')}</span>
        </div>
        {!isConsumer && (
          <div onClick={() => unArchive(cell.id)} className="download-budget-item common-unarchive-btn">
            <img src={archivedIcon} alt="" style={{ height: '15px', width: '15px', marginRight: '0.5rem' }} />
            <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.title.unarchive')}</span>
          </div>
        )}
      </div>
    ),
  },
];
