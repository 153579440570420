import React, { createContext, useReducer } from 'react';
import { initialState, reducer } from '../reducers/filtersReducer';

export const FiltersContext = createContext({} as any);

const FiltersContextProvider: React.FC = (props: any) => {
  const [filters, filtersDispatch] = useReducer(reducer, initialState);

  return <FiltersContext.Provider value={{ filters, filtersDispatch }}>{props.children}</FiltersContext.Provider>;
};

export default FiltersContextProvider;
