import React, { createContext, useReducer } from 'react';
import { initialState, reducer } from '../reducers/navReducer';

export const NavContext = createContext({} as any);

const NavContextProvider: React.FC = (props: any) => {
  const [nav, navDispatch] = useReducer(reducer, initialState);

  return <NavContext.Provider value={{ nav, navDispatch }}>{props.children}</NavContext.Provider>;
};

export default NavContextProvider;
