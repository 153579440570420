import { Card } from 'antd';
import React from 'react';
import CustomProgress from '../Common/CustomProgress';
import css from '../../../../../assets/styles/statistics.module.css';
import { DashboardStatusColors } from '../../../../../constants/DashboardStatusColors';

interface IProps {
  item: { label: string; code: string; count: number };
  count: number;
}

const ProjectCardItem: React.FC<IProps> = ({ item, count }) => {
  const progress = [
    {
      id: 1,
      color: DashboardStatusColors.find((colors) => colors.code === item.code)!.color,
      percent: (item.count / count) * 100 || 0,
    },
  ];
  return (
    <Card
      style={{
        backgroundColor: '#F5F7FA',
        borderRadius: 4,
        border: 'none',
        padding: '2px 5px',
        width: '100%',
        minWidth: 150,
      }}>
      <div className={css['status']}>{item.label}</div>
      <div>
        <span className={css['project-count']}>{`${item.count}/`}</span>
        <span className={css['project-persent']}>{`${Math.floor(progress[0].percent)}%`}</span>
      </div>
      <CustomProgress items={progress} />
    </Card>
  );
};

export default ProjectCardItem;
