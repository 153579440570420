import React, { useContext, useEffect, useState } from 'react';
import { Card, notification, Spin, Table, TablePaginationConfig } from 'antd';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { isEmptyFilter } from '../../helpers/isEmptyFilter';
import { generateFilterfromObj } from '../../helpers/generateFIlterfromObj';
import { getColumns } from './components/ProjectCFCColumns';
import ProjectControl from './components/ProjectControl';
import ProjectCFCModal from './components/ProjectCFCModal';
import { useLessThen801 } from '../../helpers/mediaDetect';
import TableMobile from '../common/TableMobile';
import filterIcon from '../../assets/images/ic-filter.svg';
import filterActiveIcon from '../../assets/images/ic-filter-active.svg';
import CFCService from '../../services/CFCService';
import { AppContext } from '../../contexts/AppContextProvider';
import { ICFC } from '../../models/CFCModel';
import BoardTourComponent from '../common/BoardTourComponent';
import CustomOnBoard from '../common/CustomOnBoard';
import { getOnboarding, OnboardingTypes, saveOnboarding } from '../../helpers/onboarding';
import { FilterValue } from 'antd/lib/table/interface';
import { IAppContext } from '../../typings/IApp';

interface IProjectCFCPage {
  id?: string;
}

const service = new CFCService();

const ProjectCFCPage = ({ id }: IProjectCFCPage) => {
  const { t } = useTranslation();
  const {
    app: { user },
  } = useContext<IAppContext>(AppContext);
  const { height: windowHeight } = useWindowDimensions();
  const [currentFilters, setCurrentFilters] = useState<{ [name: string]: { type: string; value: any } }>();
  const [CFC, setCFC] = useState<ICFC[]>([]);
  const [currentCFC, setCurrentCFC] = useState<ICFC | null>(null);
  const [fetchingData, setFetchingData] = useState(false);
  const [isOpenModa, setIsOpenModal] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    skip: 0,
    showSizeChanger: true,
  });
  const projectKey = user.projects.find((project) => id && project.id == +id)?.key || '';

  const [statusValue, setStatusValue] = useState('');

  const option = {
    count: true,
    top: pagination.pageSize,
    current: pagination.current,
    filters: {
      filter: generateFilterfromObj(currentFilters).filter,
    },
  };

  //board
  const [steps, _] = useState([
    {
      target: '.cfc-modify-first-step',
      content: (
        <BoardTourComponent title={t('board.cfc.modify.first.title')} description={t('board.cfc.modify.first.text')} />
      ),
      disableBeacon: true,
      placement: 'top-center',
    },
    {
      target: '.cfc-modify-second-step',
      disableBeacon: true,
      content: (
        <BoardTourComponent
          title={t('board.cfc.modify.second.title')}
          description={t('board.cfc.modify.second.text')}
        />
      ),
      placement: 'left',
    },
  ]);
  const [run, setRun] = useState(false);

  useEffect(() => {
    getCFC(option);
  }, []);

  const getCFC = async (option: { [name: string]: any }) => {
    setFetchingData(true);
    await service
      .getCFC(projectKey, option)
      .then((res) => {
        const { count, items } = res;
        const { current, top } = option;
        setPagination({ ...pagination, current, total: count, pageSize: top });
        setCFC(items);
        setRun(getOnboarding(OnboardingTypes.PROJECT_CFC));
      })
      .catch((e) => {
        notification.error({
          message: e,
        });
      })
      .finally(() => setFetchingData(false));
  };

  const applyNewFilter = (filters: { [name: string]: any }) => {
    setCurrentFilters({ ...currentFilters, ...filters });
    return { ...currentFilters, ...filters };
  };

  const onTableChange = (pagination?: TablePaginationConfig, filters?: Record<string, FilterValue | null | any>) => {
    let params: { [name: string]: any } = {
      ...option,
    };

    if (filters && !isEmptyFilter(filters)) {
      params = {
        ...params,
        filters: generateFilterfromObj(applyNewFilter(filters)),
      };
    } else {
      params = {
        ...params,
        filters: generateFilterfromObj(currentFilters),
      };
    }
    if (pagination && pagination.pageSize && pagination.current) {
      params = {
        ...params,
        top: pagination.pageSize,
        skip: pagination.pageSize * (pagination.current - 1),
        current: pagination.current,
      };
    } else {
      params = {
        ...params,
        skip: 0,
        current: 1,
      };
    }

    getCFC(params);
  };

  const openModal = (CFC: ICFC) => {
    setCurrentCFC(CFC);
    setIsOpenModal(true);
  };

  const onFiltersChange = (status: string) => {
    setStatusValue(status);
  };

  const handleSave = () => {
    setIsOpenModal(false);
    getCFC(option);
  };

  const changeOpenFilter = () => {
    setIsOpenFilter(!isOpenFilter);
  };

  const loadMore = (option: { [name: string]: any }) => {
    setFetchingData(true);
    option.skip = option.skip ? option.skip + 10 : 10;
    service
      .getCFC(projectKey, option)
      .then((res) => {
        const { count, items } = res;
        const { current, top } = option;
        setPagination({ ...pagination, current, total: count, pageSize: top });
        setCFC([...CFC, ...items]);
      })
      .catch((e) => console.log(e))
      .finally(() => setFetchingData(false));
  };

  if (useLessThen801()) {
    const data =
      CFC.length > 0 &&
      (CFC.filter((item: ICFC) =>
        statusValue === ''
          ? true
          : statusValue === 'true'
          ? item.active === true
          : item.active === false || item.active === null,
      ).map((item: ICFC) => [
        { title: t('projects.cfc'), description: item.code, type: 'string' },
        { title: t('budget.form.description'), description: item.description, type: 'string' },
        { title: t('projects.cfc.items'), description: item.items, type: 'string' },
        {
          title: t('budget.table.status'),
          description: item.active
            ? t('common.filters.isActive').toLowerCase()
            : t('common.filters.nonActive').toLowerCase(),
          type: 'string',
        },
        {
          title: t('budget.table.action'),
          type: 'button',
          openByFunction: true,
          btnsInfo: [
            {
              function: () => openModal(item),
              functionBtnTitle: t('projects.cfc.setting'),
            },
          ],
        },
      ]) as any);
    return (
      <>
        <div className="common-mobile-title-container">
          <span style={{ fontSize: '20px' }}>{`${t('projects.cfc')} ${id ? `#${id}` : ''}`}</span>
          <div className="common-modbile-filter-container" onClick={changeOpenFilter}>
            <img src={isOpenFilter ? filterActiveIcon : filterIcon} width={16} />
            <span className="filter-title" style={{ marginRight: '1rem' }}>
              {isOpenFilter ? t('common.filter.close') : t('common.filter.open')}
            </span>
          </div>
        </div>
        {isOpenFilter && <ProjectControl onFiltersChange={onFiltersChange} onTableChange={onTableChange} />}
        <TableMobile
          data={data}
          isLoading={fetchingData}
          loadMore={loadMore}
          option={option}
          itemsLength={CFC.length}
          totalItems={pagination.total}
        />
        {isOpenModa && (
          <ProjectCFCModal
            isOpen={isOpenModa}
            handleClose={() => {
              setIsOpenModal(false);
            }}
            CFC={currentCFC}
            projectId={id}
            handleSave={handleSave}
          />
        )}
      </>
    );
  }

  return (
    <Card
      style={{
        padding: '0.5rem',
        backgroundColor: '#f5f7fa',
        border: 'solid 1px #c4c6ce',
        borderRadius: '6px',
        height: '100%',
      }}>
      <Spin spinning={fetchingData} className="project-cfc-spin">
        <CustomOnBoard
          steps={steps}
          run={run}
          onFinish={() => saveOnboarding(OnboardingTypes.PROJECT_CFC, user.boardCheckpoint)}
        />
        <ProjectControl onFiltersChange={onFiltersChange} onTableChange={onTableChange} />
        <div className="cfc-modify-first-step">
          <Table
            columns={getColumns({
              t,
              openModal,
            })}
            size="small"
            className="consumers-info-table"
            dataSource={CFC.filter((item: ICFC) =>
              statusValue === ''
                ? true
                : statusValue === 'true'
                ? item.active === true
                : item.active === false || item.active === null,
            )}
            showHeader={true}
            pagination={pagination}
            scroll={{ y: windowHeight - 360 }}
            onChange={onTableChange}
            rowKey="code"
          />
        </div>
        {isOpenModa && (
          <ProjectCFCModal
            isOpen={isOpenModa}
            handleClose={() => {
              setIsOpenModal(false);
            }}
            CFC={currentCFC}
            projectId={id}
            handleSave={handleSave}
          />
        )}
      </Spin>
    </Card>
  );
};

export default ProjectCFCPage;
