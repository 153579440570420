import React from 'react';
import { Tag, Dropdown, Button, Menu } from 'antd';
import css from '../../../assets/styles/roles.module.css';
import moreIcon from '../../../assets/images/ic-more.svg';
import editIcon from '../../../assets/images/ic-edit-dark.svg';
import { TFunction } from 'react-i18next';
import { FunctionModel } from '../../../models/FunctionModel';
import { RoleModel } from '../../../models/RoleModel';
import { History } from 'history';

interface RolesColumnsModel {
  history: History;
  t: TFunction<'translation'>;
}

export const getColumns = ({ t, history }: RolesColumnsModel) => [
  {
    title: () => <span className="budget-table-tr">{t('roles.table.columns.name')}</span>,
    dataIndex: 'name',
    key: 'name',
    width: '10%',
  },
  {
    title: () => <span className="budget-table-tr">{t('roles.table.columns.description')}</span>,
    dataIndex: 'description',
    key: 'description',
    width: '10%',
  },
  {
    title: () => <span className="budget-table-tr">{t('roles.table.columns.menuFunctions')}</span>,
    dataIndex: 'menuFunctions',
    render: (functions: FunctionModel[]) => (
      <div>
        {functions.map((item) => (
          <Tag
            key={item.id}
            style={{
              backgroundColor: 'rgba(56, 83, 92, 0.8)',
              color: 'rgb(255, 255, 255)',
              borderColor: 'rgba(56, 83, 92, 0.85)',
              margin: '2px 8px 2px 0',
            }}>
            {item.name}
          </Tag>
        ))}
      </div>
    ),
    width: '73%',
  },
  {
    title: () => <span className="budget-table-tr">{t('roles.table.columns.action')}</span>,
    key: 'action',
    fixed: 'right' as any,
    with: '150px',
    render: (cell: RoleModel) => {
      const menu = (
        <Menu className="update-menu-container" style={{ width: '100px' }}>
          <Menu.Item
            key="edit"
            className={css['update-menu-item']}
            onClick={() => history.push(`/roles/modify/${cell.name}`)}>
            <Button
              type="link"
              style={{
                border: 'none',
                backgroundColor: 'inherit',
                boxShadow: 'none',
                padding: 0,
                marginRight: '0.5rem',
              }}>
              <img src={editIcon} alt="" style={{ height: '15px', width: '15px' }} />
            </Button>
            <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.btn.modify')}</span>
          </Menu.Item>
        </Menu>
      );

      return (
        <span>
          <Dropdown overlay={menu} trigger={['click']}>
            <Button className={css['roles-dropdown-btn']}>
              <img src={moreIcon} alt="" height={15} width={15} />
            </Button>
          </Dropdown>
        </span>
      );
    },
  },
];
