import {
  Button,
  Modal,
  Row,
  Upload,
  Form,
  Input,
  Col,
  Select,
  DatePicker,
  notification,
  Spin,
  Space,
  Radio,
} from 'antd';
import React, { useEffect, useState } from 'react';
import css from '../../../../assets/styles/internal.module.css';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import calendarIcon from '../../../../assets/images/ic-calendar-date-picker.svg';
import config from '../../../../config';
import { validateDateLessThanToday } from '../../../../helpers/validateDateField';
import { InternalDocumentModel } from '../../../../models/InternalDocumentsModel';
import InternalDocumentsService from '../../../../services/InternalDocumentsService';
import ProjectsService from '../../../../services/ProjectsService';
import { IProjectEasy, ProjectsModel } from '../../../../models/ProjectModel';
import { PropertiesModel } from '../../../../models/PropertyModel';
import ListPageServices from '../../../../services/PropertiesService';
import ConsumersService from '../../../../services/ConsumersService';
import { ConsumerModel } from '../../../../models/ConsumerModel';
import UsersService from '../../../../services/UsersService';
import { convertToCoins } from '../../../../helpers/convertToPrice';
import { generateSorter } from '../../../../helpers/generateSorter';

interface ModalProps {
  isOpenModal: boolean;
  currentId: any;
  getInternalDocuments: any;
  option: any;
  setClose: any;
  type: any;
  isAllocate: boolean | undefined;
}

const internalService = new InternalDocumentsService();
const projectService = new ProjectsService();
const propertyService = new ListPageServices();
const consumerService = new ConsumersService();
const userService = new UsersService();

const { Option } = Select;
const { TextArea } = Input;

const UploadInternalForm = (props: ModalProps) => {
  const { isOpenModal, currentId, getInternalDocuments, setClose, option, type, isAllocate } = props;
  const { t } = useTranslation();
  const [internalInfo, setInternalInfo] = useState<InternalDocumentModel | null>(null);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([] as any[]);
  const [fetchingData, setFetchingData] = useState(false);
  const [isHaveFile, setIsHaveFile] = useState(true);
  const [projects, setProjects] = useState<IProjectEasy[]>([]);
  const [properties, setProperties] = useState<PropertiesModel>([]);
  const [brokers, setBrokers] = useState<any[]>([]);
  const [consumers, setConsumers] = useState<ConsumerModel[]>([]);
  const [isHaveSignature, setIsHaveSignature] = useState(false);
  const [isHaveConsumersSignature, setIsHaveConsumersSignature] = useState(false);
  const [fileAttachmentList, setFileAttachmentList] = useState([] as any[]);
  const [isDisabled, setIsDisabled] = useState(false);
  const typeName = type && type[0].toUpperCase() + type.slice(1);
  const isAllocatedDocument = type && !['invoice', 'quote', 'amendment'].includes(type);
  const isAllocatedAmendment = type && type === 'amendment';

  const [projectId, setProjectId] = useState<null | number>(null);
  const [currentSelectedUsers, setCurrentSelectedUsers] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<null | string>(null);
  const [currentTimeout, setCurrentTimeout] = useState<any>(null);
  const [isLastAgentsReq, setIsLastAgentsReq] = useState(false);
  const [isLastConsumersReq, setIsLastConsumersReq] = useState(false);
  const [fetchingUserData, setFetchingUserData] = useState(false);
  const [paginationAgents, setPaginationAgents] = useState<any>({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [paginationConsumers, setPaginationConsumers] = useState<any>({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    projectService
      .getProjects()
      .then((value: IProjectEasy[]) =>
        setProjects(value.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))),
      )
      .catch((e: any) =>
        notification.error({
          message: e.message,
        }),
      );
  }, []);

  useEffect(() => {
    if (currentId) {
      setFetchingData(true);
      internalService
        .getInternalDocumentsById(currentId)
        .then((value: InternalDocumentModel) => {
          setIsDisabled(['signed', 'partly_signed', 'rejected'].includes(value.status.code));
          getProperties(null, value.project.key);
          setProjectId(value?.project?.id);
          getConsumers([value?.consumer], value.property.id);
          getAgents(
            value?.signatories?.map((item: any) => item.user.id),
            [],
            value?.project?.id,
          );
          if (value.signatories && value.signatories.length > 0) {
            setIsHaveSignature(true);
          }
          form.setFieldsValue({
            name: value.name,
            status: value.status.code,
            signatories:
              value.signatories && value.signatories.length > 0
                ? value.signatories.map((item: any) => item.user.id)
                : [],
            project: value.project.id,
            property: value.property.id,
            consumer: value.consumer.id,
            hasSignature: value.signatories && value.signatories.length > 0,
          });
          if (value.file) {
            setFileList([
              {
                name: `${value.file.slice(0, 40)}...`,
                uid: value.file,
                url: `${config.storeUrl}/${value.file}`,
              },
            ]);
          }
          setInternalInfo(value);
        })
        .catch((e: any) => console.log(e))
        .finally(() => setFetchingData(false));
    }
  }, []);

  useEffect(() => {
    if (projectId) {
      getAgents();
    }
  }, [projectId]);

  useEffect(() => {
    if (searchValue === '') {
      return getAgents([], currentSelectedUsers);
    }
    if (searchValue) {
      getAgents();
    }
  }, [searchValue]);

  const getAgents = (agentsId?: number[], agentsRes: any[] = [], projectIdProp?: number) => {
    if (agentsId && agentsId.length > 0) {
      userService
        .getEasyUsers({ userId: agentsId })
        .then((res: any) => {
          setCurrentSelectedUsers(res);
          getAgents([], res, projectIdProp);
        })
        .catch((e: any) =>
          notification.error({
            message: e.message,
          }),
        );
      return;
    }
    const optionUser: any = {
      count: true,
      top: paginationAgents.pageSize,
      current: paginationAgents.current,
      skip: paginationAgents.pageSize * (paginationAgents.current - 1) || 0,
      fullName: searchValue || '',
      projectId: [projectIdProp || projectId],
      roles: ['agent', 'admin', 'super_agent'],
    };

    setFetchingUserData(true);
    userService
      .getEasyUsers(optionUser)
      .then((res: any) => {
        const { count, items } = res;
        const { current, top } = optionUser;
        setPaginationAgents({ ...paginationAgents, current: current + 1, total: count, pageSize: top });
        setBrokers([...brokers, ...items]);
        let agentsId: any[] = [];
        if (brokers.length > 0) {
          agentsId = brokers.map((broker) => broker.id);
        } else if (agentsRes.length > 0) {
          agentsId = agentsRes.map((agent) => agent.id);
        }
        setBrokers(
          [...brokers, ...agentsRes, ...items.filter((item: any) => !agentsId.includes(item.id))].sort(
            (a: any, b: any) => a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()),
          ),
        );
        setIsLastAgentsReq(current * top >= count);
      })
      .catch((e: any) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingUserData(false));
  };

  const handleFile = (info: any) => {
    setFileList(info.fileList);
    setIsHaveFile(true);
  };

  const successUpdate = () => {
    setClose();
    notification.success({
      message: isAllocate
        ? `${type} ${t('internal.documents.success.allocate')}`
        : `${type} ${t('internal.documents.success.updated')}`,
    });
    getInternalDocuments(option);
  };

  const getProperties = (id: number | null, key?: string) => {
    setFetchingData(true);
    const fieldValue = form.getFieldsValue();
    form.setFieldsValue({ ...fieldValue, property: null, consumer: null });
    propertyService
      .getProperties(key || projects.find((item) => item.id === id)?.key)
      .then((value: PropertiesModel) =>
        setProperties(
          value
            .filter((property: any) => property.childrens.length === 0)
            .sort((a: any, b: any) => a.title.toLowerCase().localeCompare(b.title.toLowerCase())),
        ),
      )
      .finally(() => setFetchingData(false));
  };

  const getConsumers = (consumersRes: any[] = [], propertyId?: number) => {
    const fieldValue = form.getFieldsValue();
    const id = fieldValue?.property || propertyId;

    const optionUser = {
      count: true,
      filter: [
        {
          'properties/id': {
            eq: id,
          },
        },
      ],
      top: paginationConsumers.pageSize,
      current: paginationConsumers.current,
      orderBy: generateSorter(['firstName asc', 'lastName asc']).orderBy,
      skip: paginationConsumers.pageSize * (paginationConsumers.current - 1) || 0,
    };
    setFetchingUserData(true);

    form.setFieldsValue({ ...fieldValue, consumer: null });
    consumerService
      .getAllConsumers(optionUser)
      .then((res) => {
        const { count, items } = res;
        const { current, top } = optionUser;
        let consumersId: any[] = [];
        if (consumers.length > 0) {
          consumersId = consumers.map((consumer) => consumer.id);
        } else if (consumersRes.length > 0) {
          consumersId = consumersRes.map((consumer) => consumer.id);
        }
        setConsumers(
          [...consumers, ...consumersRes, ...items.filter((item: any) => !consumersId.includes(item.id))].sort(
            (a: any, b: any) => a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()),
          ),
        );
        setPaginationConsumers({ ...paginationConsumers, current: current + 1, total: count, pageSize: top });
        setIsLastConsumersReq(current * top >= count);
      })
      .catch((e: any) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingUserData(false));
  };

  const handleSignatureChange = (e: any) => {
    setIsHaveSignature(e.target.value);
  };

  const handleConsumersSignatureChange = (e: any) => {
    setIsHaveConsumersSignature(e.target.value);
  };

  const handleFileAttachment = (info: any) => {
    setFileAttachmentList(info.fileList);
  };

  const onSubmit = (values: any) => {
    if (fileList.length < 1) {
      setIsHaveFile(false);
      return;
    }

    setFetchingData(true);
    const filesData = new FormData();
    for (const file of fileList) {
      if (file.originFileObj) {
        filesData.append('file', file.originFileObj);
      }
    }
    if (!internalInfo) {
      for (const file of fileAttachmentList) {
        if (file.originFileObj) {
          filesData.append('attachments', file.originFileObj);
        }
      }
    }

    const body = {
      project: {
        id: values.project,
      },
      property: {
        id: values.property,
      },
      signatories: isAllocate
        ? (values.signatories &&
            values.signatories.length > 0 &&
            values.signatories.map((id: number) => ({
              consumerId: id,
              isAgent: true,
            }))) || [{ consumerId: values.consumer }]
        : values.signatories || [],
      consumer: {
        id: values.consumer,
      },
    } as any;
    //ALLOCATE
    if (isAllocate) {
      if (values.introduction) {
        body.introduction = values.introduction;
      }
      if (values.concluding) {
        body.concluding = values.concluding;
      }
      if (values.deadline) {
        body.deadline = moment(values.deadline).format('YYYY-MM-DD');
      }
      if (values.sum) {
        if (isAllocatedAmendment) {
          body.deltaWithFees = convertToCoins(+values.sum);
        } else {
          body.sum = convertToCoins(+values.sum);
        }
      }
      if (values.sumWithVat) {
        if (isAllocatedAmendment) {
          body.deltaWithVat = convertToCoins(+values.sumWithVat);
        } else {
          body.sumWithVat = convertToCoins(+values.sumWithVat);
        }
      }
      if (values.date && !isAllocatedAmendment) {
        body.paymentDate = moment(values.date).format('YYYY-MM-DD');
      }
      if (values.number) {
        body.number = values.number;
      }
      if (values.consumersSignatories && values.consumersSignatories.length > 0) {
        body.signatories = values.consumersSignatories?.map((id: number) => ({
          consumerId: id,
        }));
      }
      if (values.description) {
        body.description = values.description;
      }
      if (values.comments) {
        body.comment = values.comments;
      }
      if (isAllocatedAmendment) {
        const signatories = [];
        //consumer
        signatories.push({ consumerId: values.consumer, isAgent: false });
        if (internalInfo) {
          //for responsible agent
          signatories.push({
            consumerId: internalInfo.createdUser.id,
            isAgent: true,
            isCreator: true,
            isNeedSign: true,
            isNeedApprove: false,
          });
        }
        //sign agents
        if (values.signatories && values.signatories.length > 0) {
          values.signatories
            .filter((id: number) => id !== internalInfo?.createdUser.id)
            .map((id: number) =>
              signatories.push({
                consumerId: id,
                isAgent: true,
                isNeedSign: true,
              }),
            );
        }
        body.name = values.name;
        body.objectText = t('ammendment.template.object');
        body.signatories = signatories || [];
        delete body.project;
        delete body.consumer;
      } else {
        body.title = values.name;
      }
      body.file = internalInfo && internalInfo.file;
      body.status = { code: isAllocatedAmendment ? 'acquirer_signature' : 'new' };
      internalService
        .allocateInternalDocument(currentId, body)
        .then(() => successUpdate())
        .catch((e: any) => notification.error({ message: e.message }));
    } else {
      // EDIT
      body.type = {
        code: type,
      };
      body.name = values.name;
      if (internalInfo) {
        internalService
          .updateInternalDocument(currentId, body)
          .then(() => {
            if (filesData.getAll('file').length > 0) {
              internalService
                .uploadInternalDocumentFile(currentId, filesData)
                .then(() => {
                  successUpdate();
                })
                .catch((e: any) => {
                  notification.error({
                    message: e.message || t('common.error.internalServerError'),
                  });
                });
            } else {
              successUpdate();
            }
          })
          .catch((e: any) => {
            setClose();
            notification.error({
              message: e.message || t('common.error.internalServerError'),
            });
          })
          .finally(() => {
            setClose();
            setFetchingData(false);
          });
      } else {
        internalService
          .createInternalDocument({
            name: values.name,
            type: {
              code: type,
            },
            project: {
              id: values.project,
            },
            property: {
              id: values.property,
            },
            signatories: values.signatories || [],
            consumer: {
              id: values.consumer,
            },
          })
          .then((value: any) => {
            if (filesData.getAll('file').length > 0 && value.id) {
              internalService
                .uploadInternalDocumentFile(value.id, filesData)
                .then(() => {
                  setClose();
                  notification.success({
                    message: `${type} ${t('internal.documents.success.create')}`,
                  });
                  getInternalDocuments(option);
                })
                .catch((e: any) => {
                  notification.error({
                    message: e.message || t('common.error.internalServerError'),
                  });
                });
            }
          })
          .catch((e: any) => {
            setClose();
            notification.error({
              message: e.message || t('common.error.internalServerError'),
            });
          })
          .finally(() => {
            setClose();
            setFetchingData(false);
          });
      }
    }
  };

  return (
    <Spin spinning={fetchingData}>
      <Modal
        className={`add-invoice-modal ${!internalInfo ? 'add-invoice-modal-quote' : ''}`}
        centered
        visible={isOpenModal}
        footer={
          <div className="common-modal-footer">
            <Button
              className={`common-gray-border-btn common-secondary-btn`}
              disabled={fetchingData}
              onClick={() => setClose()}>
              {t('budget.modal.cancel.btn')}
            </Button>
            <Button
              className={`common-green-btn common-modal-btn`}
              disabled={fetchingData || (!isAllocate && isDisabled)}
              onClick={() => form.submit()}>
              {t('budget.modal.save.btn')}
            </Button>
          </div>
        }
        closable={false}>
        <Form autoComplete="off" onFinish={onSubmit} form={form} style={{ maxHeight: '90vh', overflowY: 'auto' }}>
          <Form.Item
            className={css['form-item-internal']}
            colon={false}
            label={t('quotes.modal.upload.file')}
            labelCol={{ span: 24 }}
            initialValue={fileList}
            name="file">
            <>
              <Upload
                style={{ width: '100%' }}
                className="consumers-import-upload-item"
                maxCount={1}
                beforeUpload={() => false}
                onChange={handleFile}
                accept=".pdf"
                fileList={fileList}
                disabled={isDisabled}>
                {fileList.length < 1 && (
                  <div className={css['upload-item-internal']}>
                    <div
                      className={css['upload-img']}
                      style={{ marginRight: '0.5rem', width: '18px', height: '14px' }}></div>
                    <span style={{ color: '#076ee5' }}>{t('budget.modal.upload.btn.title')}</span>
                  </div>
                )}
              </Upload>
              {!internalInfo && (
                <>
                  <div style={{ width: '100%', borderBottom: '1px solid #dbdde4', margin: '8px 0' }}></div>
                  <Upload
                    className="consumers-import-upload-item upload-list"
                    style={{ width: '100%' }}
                    beforeUpload={() => false}
                    onChange={handleFileAttachment}
                    onRemove={() => true}
                    multiple={true}
                    fileList={fileAttachmentList}
                    accept=".pdf"
                    disabled={isDisabled}>
                    <div className={css['upload-item-internal']}>
                      <span style={{ color: '#076ee5' }}>{t('documents.modal.upload.attachment.btn.title')}</span>
                    </div>
                  </Upload>
                </>
              )}
              {!isHaveFile && <span style={{ color: '#ff4d4f' }}>{t('common.validation.required')}</span>}
            </>
          </Form.Item>
          <Form.Item
            style={{ padding: '2.2rem', paddingTop: '0.5rem', paddingBottom: internalInfo ? '2.2rem' : '3.2rem' }}>
            <Row gutter={[24, 4]}>
              {isAllocate && !isAllocatedDocument && (
                <Col span={12}>
                  <Form.Item
                    rules={[{ required: true, message: t('common.validation.required') }]}
                    name="number"
                    colon={false}
                    label={<span style={{ color: '#778dac' }}>{t('budget.form.number')}</span>}
                    className={css['form-item']}
                    labelAlign="left">
                    <Input className="common-animation-primary" />
                  </Form.Item>
                </Col>
              )}
              <Col span={isAllocate ? (isAllocatedDocument ? 24 : 12) : 24}>
                <Form.Item
                  rules={[{ required: true, message: t('common.validation.required') }]}
                  name="name"
                  colon={false}
                  label={
                    <span style={{ color: '#778dac' }}>
                      {`${typeName} ${t('internal.documents.label.name')}`}{' '}
                      {isAllocate &&
                        (!isAllocatedAmendment || !isAllocatedDocument) &&
                        `(${t('budget.form.description')})`}{' '}
                      *
                    </span>
                  }
                  className={css['form-item']}
                  labelAlign="left">
                  <Input disabled={isDisabled} className="common-animation-primary" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 4]}>
              <Col span={12}>
                <Form.Item
                  rules={[{ required: true, message: t('common.validation.required') }]}
                  name="project"
                  colon={false}
                  label={<span style={{ color: '#778dac' }}>{t('tickets.create.project')}</span>}
                  className={css['form-item']}
                  labelAlign="left">
                  <Select
                    className="common-select common-animation-primary"
                    disabled={isDisabled}
                    suffixIcon={
                      <div
                        className={`arrow-drop-img ${css['arrow-drop']}`}
                        style={{ width: '8px', height: '4px' }}></div>
                    }
                    onSelect={(value: any) => {
                      setProjectId(value);
                      getProperties(value);
                    }}>
                    {projects.map((project) => (
                      <Option value={project.id} key={project.id}>
                        {project.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  rules={[{ required: true, message: t('common.validation.required') }]}
                  name="property"
                  colon={false}
                  label={<span style={{ color: '#778dac' }}>{t('tickets.create.property')}</span>}
                  className={css['form-item']}
                  labelAlign="left">
                  <Select
                    className="common-select common-animation-primary"
                    suffixIcon={
                      <div
                        className={`arrow-drop-img ${css['arrow-drop']}`}
                        style={{ width: '8px', height: '4px' }}></div>
                    }
                    disabled={!form.getFieldsValue().project || isDisabled}
                    onSelect={(value: any) => getConsumers()}>
                    {properties.map((property) => (
                      <Option value={property.id} key={property.id}>
                        {property.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 4]} style={{ display: 'flex', alignItems: 'center' }}>
              <Col span={24}>
                <Form.Item
                  rules={[{ required: true, message: t('common.validation.required') }]}
                  name="consumer"
                  colon={false}
                  label={<span style={{ color: '#778dac' }}>{`${t('tickets.table.columns.acquirer')} *`}</span>}
                  className={css['form-item']}
                  labelAlign="left">
                  <Select
                    className="common-select common-animation-primary"
                    suffixIcon={
                      <div
                        className={`arrow-drop-img ${css['arrow-drop']}`}
                        style={{ width: '8px', height: '4px' }}></div>
                    }
                    dropdownClassName={`${fetchingUserData ? 'consumers-control-select-dropdown--progress' : ''}`}
                    onPopupScroll={(e: any) => {
                      e.persist();
                      let target = e.target;
                      if (
                        !fetchingUserData &&
                        !isLastConsumersReq &&
                        target.scrollTop + target.offsetHeight === target.scrollHeight
                      ) {
                        getConsumers();
                      }
                    }}
                    disabled={!form.getFieldsValue().property || isDisabled}>
                    {consumers.length > 0 &&
                      consumers?.map((consumer) => (
                        <Option value={consumer.id} key={consumer.id}>
                          {`${consumer.firstName} ${consumer.lastName}`}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {!isAllocate && (
              <Row gutter={[24, 4]} style={{ display: 'flex', alignItems: 'center' }}>
                <Col span={24}>
                  <Space align="start">
                    <span style={{ color: '#778dac' }}>{t('internal.documents.form.signature')}</span>
                    <Form.Item name="hasSignature" className={css['form-item']} initialValue={false}>
                      <Radio.Group onChange={handleSignatureChange} disabled={isDisabled}>
                        <Radio value={true}>{t('common.confirm.btn.yes')}</Radio>
                        <Radio value={false}>{t('common.confirm.btn.cancel')}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Space>
                </Col>
              </Row>
            )}
            {isAllocate && !isAllocatedDocument && (
              <Row gutter={[24, 4]}>
                <Col span={12}>
                  <Form.Item
                    name="sum"
                    colon={false}
                    rules={[{ required: true, message: t('common.validation.required') }]}
                    label={<span style={{ color: '#778dac' }}>{t('budget.form.sum')}</span>}
                    className={css['form-item']}
                    labelAlign="left">
                    <Input className="common-animation-primary" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="sumWithVat"
                    colon={false}
                    rules={[{ required: true, message: t('common.validation.required') }]}
                    label={<span style={{ color: '#778dac' }}>{t('budget.form.sum.with.vat')}</span>}
                    className={css['form-item']}
                    labelAlign="left">
                    <Input className="common-animation-primary" />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {isAllocate && !isAllocatedDocument && (
              <Row gutter={[24, 4]}>
                <Col span={12} style={{ display: 'none' }}>
                  <Form.Item
                    name="date"
                    colon={false}
                    label={<span style={{ color: '#778dac' }}>{t('budget.form.currentDate')}</span>}
                    className={css['form-item']}
                    initialValue={moment()}
                    labelAlign="left">
                    <DatePicker
                      inputReadOnly
                      className="common-date-picker common-animation-primary"
                      style={{ width: '100%' }}
                      suffixIcon={<div className={'calendar-img'} style={{ width: '12px', height: '12px' }}></div>}
                      disabled
                      format="DD.MM.YYYY"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="deadline"
                    colon={false}
                    label={<span style={{ color: '#778dac' }}>{t('budget.form.deadline')}</span>}
                    className={css['form-item']}
                    labelAlign="left"
                    initialValue={moment().add(10, 'days')}
                    rules={[
                      { required: true, message: t('common.validation.required') },
                      validateDateLessThanToday('deadline', 'date', t('budget.form.deadline.validate')),
                    ]}>
                    <DatePicker
                      inputReadOnly
                      className="common-animation-primary"
                      style={{ width: '100%' }}
                      suffixIcon={<img src={calendarIcon} alt="" />}
                      format="DD.MM.YYYY"
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {isAllocate && (isAllocatedDocument || isAllocatedAmendment) && (
              <Row gutter={[24, 4]} style={{ display: 'flex', alignItems: 'center' }}>
                <Col span={24}>
                  <Form.Item
                    name="description"
                    colon={false}
                    rules={[{ max: 100, message: t('gallery.validation.maxLength100') }]}
                    label={<span style={{ color: '#778dac' }}>{t('documents.form.description')}</span>}
                    className={css['form-item']}
                    labelAlign="left">
                    <TextArea autoSize={true} className="common-animation-primary" />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {isAllocate && (
              <Row gutter={[24, 4]} style={{ display: 'flex', alignItems: 'center' }}>
                <Col span={24}>
                  <Form.Item
                    name="comments"
                    colon={false}
                    rules={[{ max: 100, message: t('gallery.validation.maxLength100') }]}
                    label={<span style={{ color: '#778dac' }}>{t('budget.form.sign.comments')}</span>}
                    className={css['form-item']}
                    labelAlign="left">
                    <TextArea autoSize={true} className="common-animation-primary" />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {isAllocate && (
              <Row gutter={[24, 4]} style={{ display: 'flex', alignItems: 'center' }}>
                <Col span={24}>
                  <Space align="start">
                    <span style={{ color: '#778dac' }}>{t('documents.form.signature')}</span>
                    <Form.Item name="hasConsumerSignature" className={css['form-item']} initialValue={false}>
                      <Radio.Group onChange={handleConsumersSignatureChange}>
                        <Radio value={true}>{t('common.confirm.btn.yes')}</Radio>
                        <Radio value={false}>{t('common.confirm.btn.cancel')}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Space>
                </Col>
              </Row>
            )}
            {isAllocate && isHaveConsumersSignature && (
              <Form.Item
                name="consumersSignatories"
                colon={false}
                rules={[{ required: true, message: t('common.validation.required') }]}
                className={css['form-item']}
                labelAlign="left">
                <Select
                  className="common-select common-animation-primary"
                  filterOption={(input, option) => {
                    return option?.children.toLowerCase().includes(input.toLowerCase());
                  }}
                  placeholder={t('quotes.modal.form.signatories.placeholder')}
                  dropdownClassName={`${fetchingUserData ? 'consumers-control-select-dropdown--progress' : ''}`}
                  onPopupScroll={(e: any) => {
                    e.persist();
                    let target = e.target;
                    if (
                      !fetchingUserData &&
                      !isLastConsumersReq &&
                      target.scrollTop + target.offsetHeight === target.scrollHeight
                    ) {
                      getConsumers();
                    }
                  }}
                  disabled={(!!currentId && internalInfo?.status.code !== 'new') || isDisabled}
                  mode="multiple">
                  {consumers.length > 0 &&
                    consumers.map((consumer: any) => (
                      <Select.Option key={consumer.id} value={consumer.id}>
                        {`${consumer.firstName} ${consumer.lastName}`}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            )}
            {isHaveSignature && (
              <Form.Item
                name="signatories"
                colon={false}
                rules={[{ required: true, message: t('common.validation.required') }]}
                className={css['form-item']}
                labelAlign="left">
                <Select
                  className="common-select common-animation-primary"
                  placeholder={t('quotes.modal.form.signatories.placeholder')}
                  disabled={isDisabled || !projectId}
                  onDropdownVisibleChange={(value) => {
                    if (!value && searchValue) {
                      setBrokers([]);
                      setPaginationAgents({ ...paginationAgents, current: 1, skip: 0 });
                      setSearchValue('');
                    }
                  }}
                  onSearch={(value) => {
                    setBrokers([]);
                    setPaginationAgents({ ...paginationAgents, current: 1, skip: 0 });
                    clearTimeout(currentTimeout);
                    setCurrentTimeout(setTimeout(() => setSearchValue(value), 1000));
                  }}
                  filterOption={(value, option) => true}
                  dropdownClassName={`${fetchingUserData ? 'consumers-control-select-dropdown--progress' : ''}`}
                  onPopupScroll={(e: any) => {
                    e.persist();
                    let target = e.target;
                    if (
                      !fetchingUserData &&
                      !isLastAgentsReq &&
                      target.scrollTop + target.offsetHeight === target.scrollHeight
                    ) {
                      getAgents();
                    }
                  }}
                  mode="multiple">
                  {brokers.length > 0 &&
                    brokers.map((broker: any) => (
                      <Select.Option key={broker.id} value={broker.id}>
                        {`${broker.firstName} ${broker.lastName}`}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            )}
            <Row gutter={[24, 4]}>
              <Col span={12}>
                <Form.Item
                  name="status"
                  colon={false}
                  rules={[
                    {
                      required: true,
                      message: t('common.validation.required'),
                    },
                  ]}
                  label={
                    <span style={{ color: '#778dac' }} className="documents-form-label">
                      {t('documents.form.status')}
                    </span>
                  }
                  initialValue={'new'}
                  className={css['form-item']}
                  labelAlign="left">
                  <Select
                    className="common-select common-animation-primary"
                    suffixIcon={
                      <div
                        className={`arrow-drop-img ${css['arrow-drop']}`}
                        style={{ width: '8px', height: '4px' }}></div>
                    }
                    disabled={true}></Select>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    </Spin>
  );
};

export default UploadInternalForm;
