import { Button, Modal, notification, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MeetingModel } from '../../../models/MeetingsModel';
import MeetingsService from '../../../services/MeetingsService';
import MeetingFormComponent from './MeetingFormComponent';

interface IForm {
  isOpen: boolean;
  id?: number | null;
  setClose: () => void;
}

const meetingService = new MeetingsService();

const MeetingForm = (props: IForm) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const { isOpen, id, setClose } = props;
  const [fetchingData, setFetchingData] = useState(false);
  const [meetingInfo, setMeetingInfo] = useState<MeetingModel | null>(null);

  useEffect(() => {
    if (id) {
      setFetchingData(true);
      meetingService
        .getMeetingById(id)
        .then((value: MeetingModel) => {
          setMeetingInfo(value);
          form.setFieldsValue({
            name: value.name,
            details: value.details,
            date: moment(value.datetime),
            time: moment(value.datetime),
            assignees: value.assignees.map((a) => a.id),
          });
        })
        .catch((e) => {
          notification.error({
            message: e.message,
          });
          setClose();
        })
        .finally(() => setFetchingData(false));
    }
  }, []);

  const onSubmit = (values: any) => {
    setFetchingData(true);

    const date = new Date(
      moment(values.date).format('YYYY-MM-DD') + ' ' + moment(values.time).format('HH:mm'),
    ).toUTCString();

    const body: { [name: string]: any } = {
      name: values.name,
      details: values.details,
      assignees:
        values.assignees.map((assignee: any) => {
          return { id: assignee };
        }) || [],
      datetime: date,
    };

    if (meetingInfo) {
      body.id = meetingInfo.id;
      meetingService
        .updateMeeting(body)
        .then(() => {
          notification.success({
            message: t('meetings.success.update'),
          });
          setClose();
        })
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        )
        .finally(() => setFetchingData(false));
    } else {
      meetingService
        .createMeeting(body)
        .then(() => {
          notification.success({
            message: t('meetings.success.create'),
          });
          setClose();
        })
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        )
        .finally(() => setFetchingData(false));
    }
  };

  return (
    <Spin spinning={fetchingData}>
      <Modal
        visible={isOpen}
        closable={false}
        className="upload-modal"
        centered
        footer={
          <div className="common-modal-footer">
            <Button
              className={`common-secondary-btn common-gray-border-btn`}
              disabled={fetchingData}
              onClick={() => setClose()}>
              {t('budget.modal.cancel.btn')}
            </Button>
            <Button
              className={`common-primary-btn common-green-btn`}
              disabled={fetchingData}
              onClick={() => form.submit()}>
              {meetingInfo ? t('meetings.update.meeting') : t('meetings.create.meeting')}
            </Button>
          </div>
        }>
        <MeetingFormComponent onSubmit={onSubmit} form={form} id={id} meetingInfo={meetingInfo} />
      </Modal>
    </Spin>
  );
};

export default MeetingForm;
