import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../../assets/images/logo_dark.svg';
import logo_collapsed from '../../../../assets/images/logo-collapsed.svg';
import { AppContext } from '../../../../contexts/AppContextProvider';
import { IAppContext } from '../../../../typings/IApp';

interface LogoModel {
  isCollapsed: boolean;
}

const Logo: React.FC<LogoModel> = ({ isCollapsed }: LogoModel) => {
  const {
    app: { isSupplier },
  } = useContext<IAppContext>(AppContext);
  return (
    <div className={isCollapsed ? `logo-collapsed` : `logo`}>
      <Link to={isSupplier ? '/consumers' : '/dashboard'}>
        <img
          src={!isCollapsed ? logo : logo_collapsed}
          alt="Logo"
          style={!isCollapsed ? { width: '150px', height: '30px' } : {}}
        />
      </Link>
    </div>
  );
};

export default Logo;
