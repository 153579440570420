import React, { useState, useContext, useEffect } from 'react';
import { Form, Row, Col, Input, InputNumber, Checkbox, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import LevelsService from '../../../services/LevelsService';
import { TypologiesContext } from '../../../contexts/TypologiesContextProvider';
import { nodeKeys } from '../enums/nodeKeys';
import NodesModifyLayout from './NodesModifyLayout';
import { TYPOLOGY_REFRESH_ITEMS } from '../../../constants/actionTypes/typologiesConstants';
import { validateObjectField } from '../../../helpers/validateObjectField';
import { useLessThen801 } from '../../../helpers/mediaDetect';

const service: LevelsService = new LevelsService();

const LevelsModify: React.FC<any> = (props: any) => {
  const {
    nodes: { currentNode, currentNodeKey, parent },
    nodesDispatch,
  } = useContext(TypologiesContext);
  const { t } = useTranslation();
  const isCreate = currentNodeKey === nodeKeys.level && !currentNode;
  const [form] = Form.useForm();

  const [level, setLevel] = useState<any>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [typologyId, setTypologyId] = useState<number | null>(null);

  const setIsRefresh = (isRefresh: boolean) => {
    nodesDispatch({
      type: TYPOLOGY_REFRESH_ITEMS,
      isRefresh,
    });
  };

  const getLevel = (id: number) => {
    return service
      .getLevelById(id)
      .then((res: any) => {
        setLevel(res);
        form.setFieldsValue({
          name: res.name,
          title: res.title,
          level: res.level,
          isDefault: res.isDefault,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (currentNodeKey === nodeKeys.level && currentNode && level?.id !== currentNode?.id) {
      setLoading(true);
      getLevel(currentNode.id);
    }
    if (currentNodeKey === nodeKeys.level && isCreate) {
      setTypologyId(parent.id);
    }
  }, [currentNodeKey, currentNode, isCreate]);

  const onSubmit = async () => {
    const values = await form.validateFields();
    const hasProperty = Object.prototype.hasOwnProperty;
    if (!hasProperty.call(values, 'errorFields')) {
      setIsSaving(true);
      if (isCreate) {
        service
          .createLevel({
            ...values,
            typology: { id: typologyId },
          })
          .then((res: any) => {
            getLevel(res.id);
            notification.success({
              message: t('level.create.notification.create'),
            });
            setIsRefresh(true);
          })
          .catch((e) => {
            notification.error({
              message: e.message || t('common.error.internalServerError'),
            });
          })
          .finally(() => setIsSaving(false));
      } else {
        service
          .updateLevel({
            ...values,
            id: currentNode.id,
          })
          .then(() => {
            getLevel(currentNode.id);
            notification.success({
              message: t('level.create.notification.update'),
            });
            setIsRefresh(true);
          })
          .catch((e) => {
            notification.error({
              message: e.message || t('common.error.internalServerError'),
            });
          })
          .finally(() => setIsSaving(false));
      }
    }
  };

  if (useLessThen801()) {
    return (
      <NodesModifyLayout submit={onSubmit} loading={loading} isSaving={isSaving}>
        <Form autoComplete="off" form={form} layout="vertical" name="modify_level">
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Form.Item
                label={t('level.create.card.level.name')}
                name="name"
                rules={[{ required: true, message: t('level.create.card.level.name.validation') }]}>
                <Input className="common-animation-primary" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t('level.create.card.level.title')}
                name="title"
                rules={[{ required: true, message: t('level.create.card.level.title.validation') }]}>
                <Input className="common-animation-primary" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t('level.create.card.level.level')}
                name="level"
                rules={[{ required: true, message: t('level.create.card.level.level.validation') }]}>
                <InputNumber className="common-animation-primary" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={t('level.create.card.level.isDefault.label')} name="isDefault" valuePropName="checked">
                <Checkbox>{t('level.create.card.level.isDefault.value')}</Checkbox>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={t('level.create.babylonCode')} name="babylonCode">
                <Input className="common-animation-primary" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t('level.create.babylonParams')}
                name="babylonParams"
                rules={[validateObjectField('babylonParams')]}>
                <Input.TextArea rows={4} className="common-animation-primary" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </NodesModifyLayout>
    );
  }

  return (
    <NodesModifyLayout submit={onSubmit} loading={loading} isSaving={isSaving}>
      <Form autoComplete="off" form={form} layout="vertical" name="modify_level">
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Form.Item
              label={t('level.create.card.level.name')}
              name="name"
              rules={[{ required: true, message: t('level.create.card.level.name.validation') }]}>
              <Input className="common-animation-primary" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('level.create.card.level.title')}
              name="title"
              rules={[{ required: true, message: t('level.create.card.level.title.validation') }]}>
              <Input className="common-animation-primary" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('level.create.card.level.level')}
              name="level"
              rules={[{ required: true, message: t('level.create.card.level.level.validation') }]}>
              <InputNumber className="common-animation-primary" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('level.create.card.level.isDefault.label')} name="isDefault" valuePropName="checked">
              <Checkbox>{t('level.create.card.level.isDefault.value')}</Checkbox>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('level.create.babylonCode')} name="babylonCode">
              <Input className="common-animation-primary" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('level.create.babylonParams')}
              name="babylonParams"
              rules={[validateObjectField('babylonParams')]}>
              <Input.TextArea rows={4} className="common-animation-primary" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </NodesModifyLayout>
  );
};

export default LevelsModify;
