import { Button, Select } from 'antd';
import Search from 'antd/lib/input/Search';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import css from '../../../assets/styles/product.module.css';
import searchIcon from '../../../assets/images/ic-search.svg';
import { useTranslation } from 'react-i18next';
import { Option } from 'antd/lib/mentions';
import { FilterTypes } from '../../../constants/filterTypes';
import { FiltersContext } from '../../../contexts/FiltersContextProvider';
import { SET_PRODUCTS_FILTERS } from '../../../constants/actionTypes/filtersConstants';
import { useLessThen801 } from '../../../helpers/mediaDetect';

interface PropsControlModel {
  props: any;
  onTableChange: any;
}

const ProductControl = (propsControl: PropsControlModel) => {
  const { onTableChange, props } = propsControl;
  const { t } = useTranslation();
  const [currentTimeout, setCurrentTimeout] = useState<any>(null);

  const {
    filters: { productsFilter },
    filtersDispatch,
  } = useContext(FiltersContext);

  const onSearch = (value: any) => {
    const searchFilter = {
      searchByName: {
        type: FilterTypes.MULTISEARCH,
        value: [{ 'article/name': value.target.value }, { code: value.target.value }],
      },
    };

    clearTimeout(currentTimeout);
    setCurrentTimeout(setTimeout(() => onTableChange(null, searchFilter), 1000));
  };

  const onChangeStatus = (value: any) => {
    filtersDispatch({
      type: SET_PRODUCTS_FILTERS,
      filters: {
        status: value,
      },
    });
    const statusFilter = {
      isActive: {
        type: FilterTypes.SELECT,
        value: value ? value : undefined,
      },
    };

    onTableChange(null, statusFilter);
  };
  return (
    <div className={css['product-control-container']}>
      <div className={css['product-filter-control']}>
        <div className={`${css['product-filters']} budget-control-wrapper`}>
          <Search
            allowClear
            className="tickets-control-search common-animation-primary"
            style={useLessThen801() ? { padding: '4px 0' } : { width: '280px', padding: '4px 0', marginRight: 20 }}
            placeholder={t('product.control.search.placeholder')}
            suffix={<img src={searchIcon} alt="" />}
            onChange={onSearch}
          />
          <Select
            defaultValue={productsFilter.status}
            style={useLessThen801() ? { padding: '5px 0' } : { padding: '5px 0', marginRight: 20 }}
            className="consumers-control-select common-animation-primary"
            onChange={onChangeStatus}
            dropdownClassName="consumers-control-select-dropdown"
            placeholder={t('product.control.filter.placeholder.status')}
            suffixIcon={
              <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
            }>
            <Option value="" className="budget-control-option">
              <span style={{ fontSize: '14px', fontWeight: 600 }}>{t('product.control.filter.defvalue.status')}</span>
            </Option>
            <Option value="true" className="budget-control-option">
              <span style={{ fontSize: '14px', fontWeight: 600 }}>{t('common.filters.isActive')}</span>
            </Option>
            <Option value="false" className="budget-control-option">
              <span style={{ fontSize: '14px', fontWeight: 600 }}>{t('common.filters.nonActive')}</span>
            </Option>
          </Select>
        </div>
      </div>
      {!useLessThen801() && (
        <Link to={`/products/create`} style={{ borderRadius: 4 }} className="common-animation-primary">
          <Button className={`common-blue-btn common-primary-btn`} style={{ height: '42px' }}>
            {t('common.btn.new')}
          </Button>
        </Link>
      )}
    </div>
  );
};

export default ProductControl;
