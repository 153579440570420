import { notification } from 'antd';
import { useState } from 'react';
import { IUserEasy } from '../models/UserModel';
import UsersService from '../services/UsersService';
import { IResponceData } from '../typings/IServiceOptions';

const userService = new UsersService();

export const useEasyUsers = (brokerProjects: number[], isConsumer: boolean = false) => {
  const [fetchingUserData, setFetchingUserData] = useState(false);
  const [isLastUsersReq, setIsLastUsersReq] = useState(false);
  const [users, setUsers] = useState<IUserEasy[]>([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    skip: 0,
  });

  const option = {
    count: true,
    top: pagination.pageSize,
    current: pagination.current,
    skip: pagination.pageSize * (pagination.current - 1) || 0,
    projectId: brokerProjects,
    roles: isConsumer ? ['consumer'] : ['agent', 'admin', 'super_agent'],
  };

  const getUsers = async (usersId?: number[], usersRes: IUserEasy[] = []) => {
    if (usersId && usersId.length > 0) {
      userService
        .getEasyUsers({ userId: usersId })
        .then((res: IUserEasy[]) => {
          getUsers([], res);
        })
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        );
      return;
    }

    setFetchingUserData(true);
    await userService
      .getEasyUsers(option)
      .then((res: IResponceData<IUserEasy>) => {
        const { count, items } = res;
        const { current, top } = option;
        setPagination({ ...pagination, current: current + 1, total: count, pageSize: top });
        let usersId: number[] = [];
        if (users.length > 0) {
          usersId = users.map((u) => u.id);
        } else if (usersRes.length > 0) {
          usersId = usersRes.map((u) => u.id);
        }
        const ids = Array.from(
          new Set(
            [...users, ...usersRes, ...items.filter((item) => !usersId.includes(item.id))].map((user) => user.id),
          ),
        );
        const uniqUsers = ids.map((id) =>
          [...users, ...usersRes, ...items.filter((item) => !usersId.includes(item.id))].find(
            (customer) => customer.id === id,
          ),
        );
        setUsers(
          (uniqUsers as IUserEasy[]).sort((a, b) => a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase())),
        );
        setIsLastUsersReq(current * top >= count);
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingUserData(false));
  };
  return { users, isLastUsersReq, fetchingUserData, getUsers };
};
