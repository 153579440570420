import fetchIntercept from 'fetch-intercept';
import { failedTofetch, tokenExpired } from '../../constants/tokenFailConstants';
import { getStorageToken } from '../../helpers/storageTools';
import i18n from '../../utils/i18n';

export function callInterceptor() {
  fetchIntercept.register({
    request: function (url: any, config: any) {
      const id_token = getStorageToken();
      const lang = i18n.language.split('-')[0];
      if (id_token) {
        if (config.headers) {
          Object.assign(config.headers, config.headers, {
            Authorization: 'Bearer ' + id_token,
            'Accept-Language': lang,
            'front-environment': 'default',
          });
        } else {
          config.headers = {
            Authorization: 'Bearer ' + id_token,
            'Accept-Language': lang,
            'front-environment': 'default',
          };
        }
      }

      return [url, config];
    },

    // responseError: function (error: any) {
    //   // if(error.message === 'Failed to fetch') {
    //   //   window.location.href = `${window.location.origin}/login?type=${failedTofetch}`;
    //   // }
    //   return Promise.reject(error);
    // },

    response: function (res: any) {
      if (res.status === 401 || (res.status === 500 && res.message === 'Token expired')) {
        window.location.href = `${window.location.origin}/login?type=${tokenExpired}`;
        return null;
      } else {
        return res;
      }
    },
  });
}
