import { Table, TablePaginationConfig } from 'antd';
import { FilterValue } from 'antd/lib/table/interface';
import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CHANGE_CURRENT_PAGE } from '../../constants/actionTypes/headerConstants';
import { HeaderContext } from '../../contexts/HeaderContextProvider';
import { generateFilterfromObj } from '../../helpers/generateFIlterfromObj';
import { isEmptyObject } from '../../helpers/isEmptyObject';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { ODataOptionModel } from '../../models/ODataOptionModel';
import { IReport } from '../../models/ReportModel';
import ReportsService from '../../services/ReportsService';
import { IResponceData } from '../../typings/IServiceOptions';
import ReportControl from './components/ReportControl';
import { getColumns } from './components/ReportsColumns';

const service = new ReportsService();

const ReportsPage: React.FC = () => {
  const { t } = useTranslation();
  const [reports, setReports] = useState<IReport[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentFilters, setCurrentFilters] = useState<{ [name: string]: { type: string; value: any } }>({});
  const [pagination, setPagination] = useState({
    current: 1 as number | undefined,
    pageSize: 10 as number | undefined,
    showSizeChanger: true,
    total: 0,
    skip: 0,
  });

  const { height: windowHeight } = useWindowDimensions();
  const { headerDispatch } = useContext(HeaderContext);

  const defaultOptions = {
    count: true,
    orderBy: 'name asc',
  };

  const getReports = (options?: ODataOptionModel, current?: number) => {
    setLoading(true);
    service
      .getReports(options)
      .then((res: IResponceData<IReport>) => {
        const { items, count } = res;
        const { top } = options || {};
        setReports(items);
        setPagination({ ...pagination, current, total: count, pageSize: top });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    headerDispatch({
      type: CHANGE_CURRENT_PAGE,
      currentPage: t('header.title.Reports'),
      path: 'reports',
    });
    getReports(
      {
        ...defaultOptions,
        top: pagination.pageSize,
      },
      pagination.current,
    );
  }, []);

  const applyNewFilter = (filters: { [name: string]: any }) => {
    setCurrentFilters({ ...currentFilters, ...filters });
    return { ...currentFilters, ...filters };
  };

  const onTableChange = (paging: TablePaginationConfig, filters?: Record<string, FilterValue | null | any>) => {
    let params: { [name: string]: any } = {
      ...defaultOptions,
      ...pagination,
    };

    if (filters && !isEmptyObject(filters)) {
      params = {
        ...params,
        filter: generateFilterfromObj(applyNewFilter(filters)).filter,
      };
    } else {
      params = {
        ...params,
        filter: generateFilterfromObj(currentFilters).filter,
      };
    }

    if (paging && paging.pageSize && paging.current) {
      params = {
        ...params,
        top: paging.pageSize,
        skip: paging.pageSize * (paging.current - 1),
      };
    }
    getReports(params, pagination.current);
  };

  return (
    <>
      <ReportControl onTableChange={onTableChange} />
      <div className="table-container">
        <Table
          columns={getColumns(t)}
          loading={loading}
          size="middle"
          dataSource={reports}
          showHeader={true}
          className="consumers-info-table"
          rowClassName="common-table-row--pointer"
          rowKey="id"
          scroll={{ y: windowHeight - 450 }}
          pagination={pagination}
          onChange={onTableChange}
        />
      </div>
    </>
  );
};

export default ReportsPage;
