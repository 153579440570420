import { DeleteOutlined, EditOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Form, Image, Input, notification, Row, Select, Space, Spin, Upload } from 'antd';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import config from '../../config';
import { CHANGE_CURRENT_PAGE } from '../../constants/actionTypes/headerConstants';
import { AppContext } from '../../contexts/AppContextProvider';
import { HeaderContext } from '../../contexts/HeaderContextProvider';
import { getBase64Url } from '../../helpers/avatarTools';
import UsersService from '../../services/UsersService';
import css from '../../assets/styles/consumers.module.css';
import { useLessThen801 } from '../../helpers/mediaDetect';
import { IAppContext } from '../../typings/IApp';
import { IUserFull } from '../../models/UserModel';
import i18n from '../../utils/i18n';

const userService: UsersService = new UsersService();

const MyProfilePage = () => {
  const { headerDispatch } = useContext(HeaderContext);
  const {
    app: { user, isSupplier },
  } = useContext<IAppContext>(AppContext);
  const { t } = useTranslation();
  const [currentUser, setCurrentUser] = useState<IUserFull>();
  const [imageUrl, setImageUrl] = useState<string | ArrayBuffer | null>(null);
  const [fetchingData, setFetchingData] = useState(false);
  const userId = user && user.id;
  const [form] = Form.useForm();
  const [isLoadingImage, setIsLoadingImage] = useState<boolean>(false);
  const [image, setImage] = useState<UploadFile | null>(null);
  const [isDeleteImage, setIsDeleteImage] = useState<boolean>(false);
  const history = useHistory();

  const getUser = () => {
    setFetchingData(true);
    userService
      .getUserById(userId)
      .then((value: IUserFull) => {
        form.setFieldsValue({
          login: value.login,
          firstName: value.firstName,
          lastName: value.lastName,
          phone: value.phone,
          email: value.email,
          bookingLink: value.bookingLink,
          defaultFields: value.projects?.map((property) => property.id) || [],
          payment_street: value.paymentInfo?.street,
          payment_city: value.paymentInfo?.city,
          payment_zip: value.paymentInfo?.zip,
          payment_state: value.paymentInfo?.state,
          payment_country: value.paymentInfo?.country,
        });

        setCurrentUser(value);
        if (value.image) {
          setImageUrl(`${config.storeUrl}/${value.image}`);
        }
      })
      .catch((e) =>
        notification.error({
          message: e,
        }),
      )
      .finally(() => setFetchingData(false));
  };

  useEffect(() => {
    headerDispatch({
      type: CHANGE_CURRENT_PAGE,
      currentPage: t('header.title.myProfile'),
      path: 'my_profile',
    });
  }, [i18n.language]);

  useEffect(() => {
    getUser();
  }, []);

  const onSubmit = (values: any) => {
    setFetchingData(true);

    const body = {
      firstName: values.firstName,
      projects: currentUser!.projects.map((project) => project.id),
      properties: currentUser!.properties.map((property) => property.id),
      roles: ['consumer'],
      login: values.email,
      lastName: values.lastName,
      phone: values.phone,
      email: values.email,
    } as any;

    if (
      !values.payment_street &&
      !values.payment_city &&
      !values.payment_zip &&
      !values.payment_state &&
      !values.payment_country
    ) {
      body.paymentInfo = null;
    } else {
      body.paymentInfo = {
        firstName: null,
        lastName: null,
        street: values.payment_street,
        city: values.payment_city,
        zip: values.payment_zip,
        state: values.payment_state || null,
        country: values.payment_country,
      };
    }

    if (user?.paymentInfo?.id) {
      body.paymentInfo.id = user.paymentInfo.id;
    }

    const modelActions = (id: number) => {
      const promises: Promise<any>[] = [];
      if (image) {
        promises.push(userService.uploadImage(id, image));
      }
      if (isDeleteImage && !imageUrl) {
        promises.push(userService.deleteImage(id));
      }
      Promise.all(promises);
    };

    userService
      .updateUser(currentUser!.id, body)
      .then(() => {
        modelActions(+currentUser!.id);
        notification.success({
          message: t('my.profile.success.update'),
        });
      })
      .catch((e) => {
        notification.error({
          message: e.message,
        });
      })
      .finally(() => {
        setFetchingData(false);
        history.push('/profile');
      });
  };

  const beforeUploadImage = (file: RcFile) => {
    setIsLoadingImage(true);
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      notification.error({ message: t('projects.create.logo.mimetype.error') });
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      notification.error({ message: t('projects.create.logo.fileSize.error') });
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChangeImage = (info: UploadChangeParam<UploadFile>) => {
    const { file } = info;
    getBase64Url(file.originFileObj, (imageUrl: string | ArrayBuffer | null) => {
      setImage(file);
      setImageUrl(imageUrl);
      setIsLoadingImage(false);
    });
  };

  const deleteImage = () => {
    if (imageUrl) {
      setImage(null);
      setImageUrl(null);
      setIsDeleteImage(true);
    }
  };

  const onCancel = () => {
    history.push(isSupplier ? '/consumers' : '/dashboard');
  };

  return (
    <Spin spinning={fetchingData}>
      <Form
        autoComplete="off"
        form={form}
        layout="vertical"
        name="modify_users"
        onFinish={onSubmit}
        className={'consumer-profile-form'}>
        <Form.Item style={{ width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Button
              className={`common-blue-border-btn common-secondary-btn`}
              style={
                useLessThen801()
                  ? { height: '42px', marginRight: '0.5rem', width: '100%' }
                  : { height: '42px', marginRight: '1rem' }
              }
              disabled={fetchingData}
              onClick={() => onCancel()}>
              {t('consumers.modal.cancel.btn')}
            </Button>
            <Button
              className={`common-blue-btn common-primary-btn`}
              htmlType="submit"
              disabled={fetchingData}
              style={useLessThen801() ? { height: '42px', marginLeft: '0.5rem', width: '100%' } : { height: '42px' }}>
              {t('common.btn.save')}
            </Button>
          </div>
        </Form.Item>
        <div className={css['payment-info-container']} style={{ height: 'calc(100% - 20px)' }}>
          <Row gutter={[24, 4]} className="my-profile-row-container">
            <Col className={css['myProfile-line']}>
              <Form.Item>
                {imageUrl ? (
                  <Avatar
                    src={
                      <>
                        <Image src={imageUrl as string} preview={false} />
                        <div className="logo-mask">
                          <Space>
                            <Upload
                              accept=".png, .jpg, .jpeg"
                              showUploadList={false}
                              beforeUpload={beforeUploadImage}
                              onChange={handleChangeImage}
                              customRequest={() => {}}>
                              <Button type="link" size="large">
                                <EditOutlined style={{ fontSize: '24px' }} />
                              </Button>
                            </Upload>
                            <Button type="link" size="large" onClick={deleteImage}>
                              <DeleteOutlined style={{ fontSize: '24px' }} />
                            </Button>
                          </Space>
                        </div>
                      </>
                    }
                    className="consumer-avatar"
                    size={150}
                  />
                ) : (
                  <Upload.Dragger
                    accept=".png, .jpg, .jpeg"
                    listType="picture-card"
                    showUploadList={false}
                    beforeUpload={beforeUploadImage}
                    onChange={handleChangeImage}
                    customRequest={() => {}}
                    className="consumer-modify-upload">
                    <div className="consumer-modify-upload">
                      {isLoadingImage ? <LoadingOutlined /> : <PlusOutlined />}
                      <div style={{ marginTop: 8 }}>{t('common.btn.upload')}</div>
                    </div>
                  </Upload.Dragger>
                )}
              </Form.Item>
            </Col>
            <div className={css['consumer-info-container']}>
              <Row gutter={[24, 4]}>
                <Col span={useLessThen801() ? 24 : 10}>
                  <Form.Item
                    label={<span style={{ color: '#778dac' }}>{t('consumers.create.card.user.firstName')}</span>}
                    name="firstName"
                    rules={[{ required: true, message: 'Please input name!' }]}>
                    <Input className="consumer-form-input common-animation-primary" disabled />
                  </Form.Item>
                </Col>
                <Col span={useLessThen801() ? 24 : 10}>
                  <Form.Item
                    label={<span style={{ color: '#778dac' }}>{t('consumers.create.card.user.lastName')}</span>}
                    name="lastName"
                    rules={[{ required: true, message: 'Please input last name!' }]}>
                    <Input className="consumer-form-input common-animation-primary" disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[24, 4]}>
                <Col span={useLessThen801() ? 24 : 10}>
                  <Form.Item
                    label={<span style={{ color: '#778dac' }}>{t('consumers.create.card.user.phone')}</span>}
                    name="phone">
                    <Input className="consumer-form-input common-animation-primary" />
                  </Form.Item>
                </Col>
                <Col span={useLessThen801() ? 24 : 10}>
                  <Form.Item
                    label={<span style={{ color: '#778dac' }}>{t('consumers.create.card.user.email')}</span>}
                    name="email"
                    rules={[{ type: 'email' }]}>
                    <Input className="consumer-form-input common-animation-primary" disabled />
                  </Form.Item>
                </Col>
              </Row>
              {currentUser && (
                <Form.List name="defaultFields">
                  {(fields) => (
                    <>
                      {fields.map((field, index) => {
                        const prop = currentUser.properties
                          .filter((prop) => prop.project.id === currentUser.projects[index].id)
                          .map((item) => item.title);
                        return (
                          <div key={field.name} style={{ width: '100%' }}>
                            <Row gutter={[24, 4]} className="profile-default-fields">
                              <Col span={10} className="dynamic-profile-fields">
                                <Form.Item
                                  initialValue={currentUser.projects[index].title}
                                  rules={[{ required: true, message: t('common.validation.required') }]}
                                  name={[index, 'project']}>
                                  <Select
                                    suffixIcon={
                                      <div
                                        className={`arrow-drop-img ${css['arrow-drop']}`}
                                        style={{ width: '8px', height: '4px' }}></div>
                                    }
                                    placeholder={t('users.create.card.user.projects.placeholder')}
                                    className="consumer-form-input common-animation-primary"
                                    disabled></Select>
                                </Form.Item>
                              </Col>
                              <Col span={10} className="dynamic-profile-fields">
                                <Form.Item
                                  shouldUpdate
                                  name={[index, 'property']}
                                  initialValue={prop}
                                  rules={[{ required: true, message: t('common.validation.required') }]}>
                                  <Select
                                    placeholder="Please select property"
                                    className="consumer-form-input common-animation-primary"
                                    mode="multiple"
                                    allowClear={true}
                                    disabled></Select>
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                    </>
                  )}
                </Form.List>
              )}
            </div>
          </Row>
          <Row gutter={12} style={{ width: '100%', padding: '1rem' }}>
            <Col span={useLessThen801() ? 24 : 12}>
              <Form.Item
                colon={false}
                name="payment_street"
                className={css['form-item']}
                label={<span style={{ color: '#778dac' }}>{t('consumers.create.user.payment.street')}</span>}>
                <Input className="consumer-form-input common-animation-primary" />
              </Form.Item>
            </Col>
            <Col span={useLessThen801() ? 24 : 6}>
              <Form.Item
                colon={false}
                name="payment_zip"
                className={css['form-item']}
                label={<span style={{ color: '#778dac' }}>{t('consumers.create.user.payment.zip')}</span>}>
                <Input className="consumer-form-input common-animation-primary" />
              </Form.Item>
            </Col>
            <Col span={useLessThen801() ? 24 : 6}>
              <Form.Item
                colon={false}
                name="payment_city"
                className={css['form-item']}
                label={<span style={{ color: '#778dac' }}>{t('consumers.create.user.payment.city')}</span>}>
                <Input className="consumer-form-input common-animation-primary" />
              </Form.Item>
            </Col>
            <Col span={useLessThen801() ? 24 : 6} className="profile-payment-state">
              <Form.Item
                colon={false}
                name="payment_state"
                className={css['form-item']}
                label={<span style={{ color: '#778dac' }}>{t('consumers.create.user.payment.state')}</span>}>
                <Input className="consumer-form-input common-animation-primary" />
              </Form.Item>
            </Col>
            <Col span={useLessThen801() ? 24 : 6}>
              <Form.Item
                colon={false}
                name="payment_country"
                className={css['form-item']}
                label={<span style={{ color: '#778dac' }}>{t('consumers.create.user.payment.country')}</span>}>
                <Input className="consumer-form-input common-animation-primary" />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </Spin>
  );
};

export default MyProfilePage;
