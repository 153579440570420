import { Card, Empty } from 'antd';
import React from 'react';
import css from '../../../../../assets/styles/statistics.module.css';
import BuildingStatusItem from './BuildingStatusItem';
import CustomProgress from '../Common/CustomProgress';
import { IQuoteProperty } from '../../../../../models/DashboardModel';
import { useStatusCustomProgress } from '../../../../../hooks/useStatusCustomProgress';

interface IProps {
  property: IQuoteProperty;
  projectId: number;
  isQuote: boolean;
}

const BuildingCard: React.FC<IProps> = ({ property, projectId, isQuote }) => {
  const statusesProgres = useStatusCustomProgress(property.statuses);
  return (
    <Card
      style={{
        border: '1px solid #C4C6CD',
        borderRadius: 8,
        padding: '2px 6px',
        width: '100%',
      }}>
      <div className={css['card-container-body']}>
        <span className={css['title']}>{property.title}</span>
        {property.statuses && property.statuses.length > 0 ? (
          <>
            <div>
              {property.statuses.map((status) => (
                <BuildingStatusItem
                  key={status.code}
                  status={status}
                  projectId={projectId}
                  propertyId={property.id}
                  isQuote={isQuote}
                />
              ))}
            </div>
            <CustomProgress items={statusesProgres} />
          </>
        ) : (
          <Empty />
        )}
      </div>
    </Card>
  );
};

export default BuildingCard;
