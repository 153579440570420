import { Button, Col, Form, Input, Modal, notification, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TopicModel } from '../../../../../models/FAQModel';
import FAQService from '../../../../../services/FAQService';
import css from '../../../../../assets/styles/faq.module.css';

const service = new FAQService();

interface TopicFormProps {
  id?: number | null;
  setClose: () => void;
  isOpen: boolean;
}

const TopicForm = (props: TopicFormProps) => {
  const { id, setClose, isOpen } = props;
  const [fetchingData, setFetchingData] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    if (id) {
      setFetchingData(true);
      service
        .getTopicById(id)
        .then((value: TopicModel) =>
          form.setFieldsValue({
            topicEn: value.topicEn,
            topicFr: value.topicFr,
          }),
        )
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        )
        .finally(() => setFetchingData(false));
    }
  }, []);

  const onSubmit = (value: any) => {
    if (id) {
      service
        .updateTopic({ ...value, id })
        .then(() => {
          notification.success({
            message: t('manage.success.updated.topic'),
          });
        })
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        )
        .finally(() => {
          setFetchingData(false);
          setClose();
        });
    } else {
      service
        .createTopic(value)
        .then(() => {
          notification.success({
            message: t('manage.success.created.topic'),
          });
        })
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        )
        .finally(() => {
          setFetchingData(false);
          setClose();
        });
    }
  };

  return (
    <Spin spinning={fetchingData}>
      <Modal
        className="common-modal"
        centered
        style={{ width: '400px' }}
        visible={isOpen}
        title={
          <span style={{ fontWeight: 600, fontSize: '16px', color: '#00122d' }}>
            {id ? t('manage.topic.update.title') : t('manage.topic.create.title')}
          </span>
        }
        footer={
          <div className="common-modal-footer">
            <Button
              className={`common-gray-border-btn common-secondary-btn`}
              onClick={() => setClose()}
              disabled={fetchingData}>
              {t('budget.modal.cancel.btn')}
            </Button>
            <Button
              onClick={() => form.submit()}
              className={`common-green-btn common-modal-btn`}
              disabled={fetchingData}>
              {t('budget.modal.save.btn')}
            </Button>
          </div>
        }
        closable={false}>
        <Form autoComplete="off" onFinish={onSubmit} form={form}>
          <div style={{ paddingTop: '1rem' }}>
            <Row gutter={[24, 4]}>
              <Col span={20}>
                <Form.Item
                  name="topicEn"
                  colon={false}
                  rules={[{ required: true, message: t('common.validation.required') }]}
                  label={
                    <span style={{ color: '#778dac' }} className="budget-form-label">
                      {t('manage.topic.form.en.title')}
                    </span>
                  }
                  className={css['form-item']}
                  labelAlign="left">
                  <Input className="common-animation-primary" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 4]}>
              <Col span={20}>
                <Form.Item
                  name="topicFr"
                  colon={false}
                  rules={[{ required: true, message: t('common.validation.required') }]}
                  label={<span style={{ color: '#778dac' }}>{t('manage.topic.form.fr.title')}</span>}
                  className={css['form-item']}
                  labelAlign="left">
                  <Input className="common-animation-primary" />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      </Modal>
    </Spin>
  );
};

export default TopicForm;
