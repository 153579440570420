exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".changePassword_changePassword-img__1OGSX {\n  width: 100%;\n  height: 500px;\n  border-radius: 0 15px 15px 0;\n}\n.changePassword_changePassword-form-button__3ZLkY {\n  box-shadow: 3px 2px 17px 1px rgba(0, 18, 45, 0.2);\n  border-radius: 6px;\n  background-color: #076ee5 !important;\n  border: none !important;\n  height: 40px !important;\n}\n.changePassword_changePassword-body__1yhAA {\n  padding: 24px 40px 0 40px !important;\n}\n.changePassword_changePassword-title__3RpBF {\n  margin-bottom: 0;\n  color: #778dac;\n}\n.changePassword_changePassword-form-title__320s4 {\n  font-size: 18px;\n  font-weight: 800;\n}\n@media screen and (max-width: 575px) {\n  .changePassword_changePassword-img__1OGSX {\n    display: none;\n  }\n}\n@media screen and (max-width: 575px) {\n  .changePassword_changePassword-container__1t8zB {\n    height: 100% !important;\n    width: 100% !important;\n    background-color: white !important;\n  }\n}\n@media screen and (max-width: 575px) {\n  .changePassword_changePassword-body__1yhAA {\n    margin-top: 5% !important;\n    padding-left: 1rem !important;\n    border: none !important;\n    width: 100% !important;\n  }\n}\n.changePassword_logo-changePassword___Jpv0 {\n  width: 40%;\n  margin-bottom: 3.7rem;\n}\n", ""]);

// Exports
exports.locals = {
	"changePassword-img": "changePassword_changePassword-img__1OGSX",
	"changePassword-form-button": "changePassword_changePassword-form-button__3ZLkY",
	"changePassword-body": "changePassword_changePassword-body__1yhAA",
	"changePassword-title": "changePassword_changePassword-title__3RpBF",
	"changePassword-form-title": "changePassword_changePassword-form-title__320s4",
	"changePassword-container": "changePassword_changePassword-container__1t8zB",
	"logo-changePassword": "changePassword_logo-changePassword___Jpv0"
};