import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CHANGE_CURRENT_PAGE } from '../../constants/actionTypes/headerConstants';
import { HeaderContext } from '../../contexts/HeaderContextProvider';
import css from '../../assets/styles/meetings.module.css';
import { Badge, Button, Calendar, DatePicker, notification, Row, Spin } from 'antd';
import { formatDate } from '../../helpers/dateTools';
import moment from 'moment';
import PreviewMeetingForm from './components/PreviewMeetingForm';
import MeetingForm from './components/MeetingForm';
import DeleteMeetingForm from './components/DeleteMeetingForm';
import { useLessThen801 } from '../../helpers/mediaDetect';
import calendarIcon from '../../assets/images/ic-calendar-date-picker.svg';
import MeetingsList from './components/MeetingsList';
import MeetingsService from '../../services/MeetingsService';
import { MeetingModel, MeetingsModel } from '../../models/MeetingsModel';
import { AppContext } from '../../contexts/AppContextProvider';
import ModalUploadFileSuccess from '../../components/common/Modals/ModalUploadFileSuccess';
import { IAppContext } from '../../typings/IApp';
import i18n from '../../utils/i18n';

const meetingService = new MeetingsService();

const MeetingsPage = () => {
  const { headerDispatch } = useContext(HeaderContext);
  const {
    app: { user },
  } = useContext<IAppContext>(AppContext);
  const { t } = useTranslation();
  const [currDate, setCurrDate] = useState(moment());
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenUploadMessageModal, setIsOpenUploadMessageModal] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [isOpenPicker, setIsOpenPicker] = useState(false);
  const [datePickerValue, setDatePickerValue] = useState<moment.Moment | null>(null);
  const [isChangedDate, setIsChangedDate] = useState(false);
  const [begin, setBegin] = useState(moment().startOf('month').format('YYYY-MM-DD hh:mm'));
  const [end, setEnd] = useState(moment().endOf('month').format('YYYY-MM-DD hh:mm'));
  const [meetings, setMeetings] = useState<MeetingsModel>([]);
  const [fetchingData, setFetchingData] = useState(false);
  const [currentMeetings, setCurrentMeetings] = useState<MeetingsModel>([]);

  useEffect(() => {
    headerDispatch({
      type: CHANGE_CURRENT_PAGE,
      currentPage: t('header.title.Meetings'),
      path: 'meetings',
    });
  }, [i18n.language]);

  useEffect(() => {
    getMeetings();
  }, [currDate]);

  const getMeetings = () => {
    setFetchingData(true);
    meetingService
      .getMeetings({
        filter: {
          and: [
            {
              datetime: {
                ge: moment(begin).set({ hour: 0, minute: 0 }).format('YYYY-MM-DD HH:mm'),
              },
            },
            {
              datetime: {
                lt: moment(end).set({ hour: 0, minute: 0 }).add(1, 'day').format('YYYY-MM-DD HH:mm'),
              },
            },
          ],
          or: [
            {
              'created_user/id': {
                eq: +user.id,
              },
            },
            {
              'assignees/id': {
                eq: +user.id,
              },
            },
          ],
        },
      })
      .then((value: MeetingsModel) => {
        setMeetings(value);
        setCurrentMeetings(value);
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingData(false));
  };

  const setMonth = (step: string) => {
    const currentMonth = currDate.clone();
    if (step === 'prev') {
      currentMonth.subtract(1, 'months');
      setBegin(currentMonth.format('YYYY-MM-01'));
      setEnd(currentMonth.format('YYYY-MM-') + currentMonth.daysInMonth());
    } else {
      currentMonth.add(1, 'months');
      setBegin(currentMonth.format('YYYY-MM-01'));
      setEnd(currentMonth.format('YYYY-MM-') + currentMonth.daysInMonth());
    }
    setCurrDate(currentMonth);
    getMeetings();
  };

  const dateCellRender = (value: moment.Moment) => {
    const listData = meetings.filter(
      (meeting: MeetingModel) => moment(meeting.datetime).format('YYYY-MM-DD') === value.format('YYYY-MM-DD'),
    );
    return (
      <ul className={css['events']}>
        {listData.slice(0, 1).map((_, index: number) => (
          <li className={css['dot']} key={index}></li>
        ))}
      </ul>
    );
  };

  const openEdit = (id?: number) => {
    if (id) {
      setCurrentId(id);
    }
    setIsOpenEdit(true);
  };

  const closeEdit = () => {
    setCurrentId(null);
    setIsOpenEdit(false);
    getMeetings();
  };

  const openDelete = (id?: number) => {
    if (id) {
      setCurrentId(id);
    }
    setIsOpenDelete(true);
  };

  const closeDelete = () => {
    setCurrentId(null);
    setIsOpenDelete(false);
    getMeetings();
  };

  const openPreview = (id?: number) => {
    if (id) {
      setCurrentId(id);
    }
    setIsOpenPreview(true);
  };

  const closePreview = () => {
    setCurrentId(null);
    setIsOpenPreview(false);
    getMeetings();
  };

  const dateRender = (current: moment.Moment) => {
    const listData = meetings.filter(
      (meeting) => moment(meeting.datetime).format('YYYY-MM-DD') === current.utc().format('YYYY-MM-DD'),
    );
    return (
      <div
        className="ant-picker-cell-inner"
        onClick={() => {
          setCurrentMeetings(listData);
          setDatePickerValue(current);
          setIsOpenPicker(false);
          setIsChangedDate(true);
        }}>
        {listData.length > 0 ? (
          <Badge count={listData.length} size="small" style={{ top: '-4px' }} color="blue">
            <span style={{ fontSize: '18px', marginBottom: '2px' }}>{current.date()}</span>
          </Badge>
        ) : (
          <span style={{ fontSize: '18px', marginBottom: '2px' }}>{current.date()}</span>
        )}
      </div>
    );
  };

  const setMobileCalendarDate = (value: moment.Moment) => {
    setDatePickerValue(null);
    setIsChangedDate(false);
    setCurrDate(value);
    setBegin(value.format('YYYY-MM-01'));
    setEnd(value.format('YYYY-MM-') + value.daysInMonth());
  };

  const resetMobileCalendarDate = () => {
    setDatePickerValue(null);
    getMeetings();
    setIsChangedDate(false);
  };

  if (useLessThen801()) {
    return (
      <div className={css['container']}>
        <div className={css['header-container']}>
          <span className={css['meeting-title']}>{t('meetings.title.page')}</span>
          <Button className={css['add-meeting-btn']} onClick={() => openEdit()}>{`+ ${t('meetings.add.new')}`}</Button>
        </div>
        <div className={css['content-container']}>
          <div className={css['calendar-container']}>
            <span style={{ color: '#778dac', fontSize: '16px', marginRight: '1rem' }}>{`${formatDate(
              currDate,
              'MMMM YYYY',
              true,
            )}`}</span>
            <DatePicker
              allowClear={false}
              showToday={false}
              onBlur={() => setIsOpenPicker(false)}
              onClick={() => setIsOpenPicker(true)}
              open={isOpenPicker}
              value={datePickerValue}
              inputReadOnly
              format="DD.MM.YYYY"
              dateRender={dateRender}
              suffixIcon={<img src={calendarIcon} alt="" />}
              style={{ width: '50%' }}
              onPanelChange={(value) => {
                setMobileCalendarDate(moment(value));
              }}
            />
            {isChangedDate && (
              <span onClick={() => resetMobileCalendarDate()} style={{ marginLeft: '0.5rem' }}>
                x
              </span>
            )}
          </div>
        </div>
        <MeetingsList
          openPreview={openPreview}
          openDelete={openDelete}
          openEdit={openEdit}
          meetings={currentMeetings}
        />
        {isOpenPreview && (
          <PreviewMeetingForm
            isOpen={isOpenPreview}
            id={currentId}
            setClose={closePreview}
            openDelete={openDelete}
            openEdit={openEdit}
            setIsOpenUploadMessageModal={setIsOpenUploadMessageModal}
          />
        )}
        {isOpenUploadMessageModal && (
          <ModalUploadFileSuccess isOpen={isOpenUploadMessageModal} onOk={() => setIsOpenUploadMessageModal(false)} />
        )}
        {isOpenEdit && <MeetingForm isOpen={isOpenEdit} id={currentId} setClose={closeEdit} />}
        {isOpenDelete && currentId && (
          <DeleteMeetingForm currentId={currentId} isOpen={isOpenDelete} setClose={closeDelete} />
        )}
      </div>
    );
  }
  const setDay = (date: moment.Moment) => {
    if (moment(date).format('MM') !== moment(currDate).format('MM')) {
      setBegin(date.format('YYYY-MM-01'));
      setEnd(date.format('YYYY-MM-') + date.daysInMonth());
    }
    setCurrDate(date);
  };

  return (
    <Spin spinning={fetchingData}>
      <div className={css['container']}>
        <div className={css['header-container']}>
          <span className={css['meeting-title']}>{t('meetings.title.page')}</span>
          <Button className={`common-primary-btn common-blue-btn`} onClick={() => openEdit()}>{`+ ${t(
            'meetings.add.new',
          )}`}</Button>
        </div>
        <div className={css['content-container']}>
          <div className={css['calendar-container']}>
            <Calendar
              className="calendar-date"
              style={{ width: '100%' }}
              value={currDate}
              onSelect={(date) => setDay(date)}
              dateCellRender={dateCellRender}
              headerRender={() => (
                <Row className={css['calendar-header']}>
                  <div className={css['header-date']}>
                    <span className={css['current-date']}>{`${formatDate(currDate, 'MMMM YYYY', true)}`}</span>
                    <span className={css['arrows-container']}>
                      <Button className={css['arrow-container']} onClick={() => setMonth('prev')}>
                        <div className={`${css['arrow']} ${css['arrow-left']}`}></div>
                      </Button>
                      <Button className={css['arrow-container']} onClick={() => setMonth('next')}>
                        <div className={`${css['arrow']} ${css['arrow-right']}`}></div>
                      </Button>
                    </span>
                  </div>
                </Row>
              )}
            />
          </div>
          {meetings.length > 0 && (
            <MeetingsList openPreview={openPreview} openDelete={openDelete} openEdit={openEdit} meetings={meetings} />
          )}
        </div>
        {isOpenPreview && (
          <PreviewMeetingForm
            isOpen={isOpenPreview}
            id={currentId}
            setClose={closePreview}
            openDelete={openDelete}
            openEdit={openEdit}
            setIsOpenUploadMessageModal={setIsOpenUploadMessageModal}
          />
        )}
        {isOpenUploadMessageModal && (
          <ModalUploadFileSuccess isOpen={isOpenUploadMessageModal} onOk={() => setIsOpenUploadMessageModal(false)} />
        )}
        {isOpenEdit && <MeetingForm isOpen={isOpenEdit} id={currentId} setClose={closeEdit} />}
        {isOpenDelete && currentId && (
          <DeleteMeetingForm currentId={currentId} isOpen={isOpenDelete} setClose={closeDelete} />
        )}
      </div>
    </Spin>
  );
};

export default MeetingsPage;
