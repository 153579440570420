import moment from 'moment';
import 'moment/locale/fr';
import i18n from '../utils/i18n';

export const formatDate = (date: any, format: string = 'YYYY-MM-DD HH:mm', isNeedLocale: boolean = false) => {
  if (date && isNeedLocale) {
    return moment(date).locale(i18n.language).format(format);
  }
  if (date) {
    return moment(date).format(format);
  }
  return;
};

export const disabledDate = (current: any) => current && current < moment().endOf('day');
