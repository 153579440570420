const SET_CONSUMERS_FILTERS = 'SET_CONSUMERS_FILTERS';
const SET_CONSUMERS_PAGINATION = 'SET_CONSUMERS_PAGINATION';
const SET_TASKS_FILTERS = 'SET_TASKS_FILTERS';
const SET_FAQ_FILTERS = 'SET_FAQ_FILTERS';
const SET_HIW_FILTERS = 'SET_HIW_FILTERS';
const SET_TICKETS_FILTERS = 'SET_TICKETS_FILTERS';
const SET_INTERNAL_DOCUMENTS_FILTERS = 'SET_INTERNAL_DOCUMENTS_FILTERS';
const SET_PROPERTIES_FILTERS = 'SET_PROPERTIES_FILTERS';
const SET_PRODUCTS_FILTERS = 'SET_PRODUCTS_FILTERS';
const SET_USERS_FILTERS = 'SET_USERS_FILTERS';
const SET_USER_PAGINATION = 'SET_USER_PAGINATION';
const SET_LEADS_FILTERS = 'SET_LEADS_FILTERS';
const SET_AMENDMENTS_FILTERS = 'SET_AMENDMENTS_FILTERS';
const SET_CONTRACTS_FILTERS = 'SET_CONTRACTS_FILTERS';
const SET_QUOTES_FILTERS = 'SET_QUOTES_FILTERS';
const SET_INVOICES_FILTERS = 'SET_INVOICES_FILTERS';
const SET_DOCUMENTS_FILTERS = 'SET_DOCUMENTS_FILTERS';
const SET_GALLERY_FILTERS = 'SET_GALLERY_FILTERS';
const SET_FILEMANAGMENT_FILTERS = 'SET_FILEMANAGMENT_FILTERS';
const GET_FILEMANAGMENT_FILTERS = 'GET_FILEMANAGMENT_FILTERS';
const SAVE_FILEMANAGMENT_FILTERS = 'SAVE_FILEMANAGMENT_FILTERS';
const CLEAR_FILEMANAGMENT_FILTERS = 'CLEAR_FILEMANAGMENT_FILTERS';
const SET_FINAL_ACCOUNTS_FILTERS = 'SET_FINAL_ACCOUNTS_FILTERS';

export {
  SET_CONSUMERS_FILTERS,
  SET_CONSUMERS_PAGINATION,
  SET_TASKS_FILTERS,
  SET_FAQ_FILTERS,
  SET_HIW_FILTERS,
  SET_TICKETS_FILTERS,
  SET_INTERNAL_DOCUMENTS_FILTERS,
  SET_PROPERTIES_FILTERS,
  SET_PRODUCTS_FILTERS,
  SET_USERS_FILTERS,
  SET_USER_PAGINATION,
  SET_LEADS_FILTERS,
  SET_AMENDMENTS_FILTERS,
  SET_CONTRACTS_FILTERS,
  SET_QUOTES_FILTERS,
  SET_INVOICES_FILTERS,
  SET_DOCUMENTS_FILTERS,
  SET_GALLERY_FILTERS,
  SET_FILEMANAGMENT_FILTERS,
  GET_FILEMANAGMENT_FILTERS,
  CLEAR_FILEMANAGMENT_FILTERS,
  SAVE_FILEMANAGMENT_FILTERS,
  SET_FINAL_ACCOUNTS_FILTERS,
};
