import { DashboardStatusColors } from '../constants/DashboardStatusColors';
import { IQuoteStatus } from '../models/DashboardModel';

export const useStatusCustomProgress = (statuses?: IQuoteStatus[]) => {
  if (!statuses) {
    return [];
  }

  const result: { id: number; color: string; percent: number }[] = [];

  const countAllStatus = statuses.reduce((prev, curr) => {
    return (prev += curr.count);
  }, 0);

  statuses.forEach((item, index) => {
    const percent = (item.count / countAllStatus) * 100;
    result.push({
      id: index + 1,
      color: DashboardStatusColors.find((colors) => colors.code === item.code)!.color,
      percent,
    });
  });

  return result;
};
