/**
 * ```Convert node object to array```
 * @param array Callback array
 * @param item Node object
 * @returns Array of nodes
 */
export const flatTreeArray = (array: any[], item: any) => {
  array.push(item);
  if (item.children) {
    return item.children.map((i: any) => flatTreeArray(array, i));
  }
};

/**
 * ```Find node by key```
 * @param nodeKey Node key
 * @param source Source array
 * @returns Node
 */
export const findNodeObj = (nodeKey: string, source: any): any => {
  const flaten: any[] = [];
  source.map((item: any) => flatTreeArray(flaten, item));
  return flaten.find((node: any) => node.key === nodeKey);
};

/**
 * ```Find all leafs in a node```
 * (leaf object must have isLeaf property)
 * @param node Node object
 * @returns Leafs
 */
export const findAllLeafs = (node: any) => {
  let result: any[] = [];
  if (node.isLeaf) {
    result.push(node);
  } else {
    node.children.forEach((child: any) => {
      const childLeaf = findAllLeafs(child);
      result = [...result, ...childLeaf];
    });
  }
  return result;
};
