import { Button, DatePicker, Select, Input, TablePaginationConfig } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import css from '../../../assets/styles/documents.module.css';
import { useTranslation } from 'react-i18next';
import { StatusesDocumentsModel } from '../../../models/DocumentsModel';
import searchIcon from '../../../assets/images/ic-search.svg';
import { FilterTypes } from '../../../constants/filterTypes';
import { FiltersContext } from '../../../contexts/FiltersContextProvider';
import { SET_DOCUMENTS_FILTERS } from '../../../constants/actionTypes/filtersConstants';
import { useLessThen801 } from '../../../helpers/mediaDetect';
import { useLocation } from 'react-router';
import { getSearchParam } from '../../../helpers/customUrlSearchParams';
import CustomSignFlow from '../../../services/CustomSignFlow';
import { setTextColorByStatus } from '../../../helpers/setTextColorByStatus';
import { generateFilterfromObj } from '../../../helpers/generateFIlterfromObj';
import { FilterValue } from 'antd/lib/table/interface';
import { RangePickerProps } from 'antd/lib/date-picker';

interface DocumentsControlProps {
  setIsOpenModal: (b: boolean) => void;
  isConsumer: boolean;
  onTableChange: (pagination?: TablePaginationConfig, filters?: Record<string, FilterValue | null | any>) => void;
  propertyId: number;
}

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Search } = Input;

const customFlowService = new CustomSignFlow();

const DocumentsControl = (props: DocumentsControlProps) => {
  const { setIsOpenModal, isConsumer, onTableChange, propertyId } = props;
  const { t } = useTranslation();
  const {
    filters: { documentsFilter },
    filtersDispatch,
  } = useContext(FiltersContext);
  const { search } = useLocation();
  const nameQuery = getSearchParam(search, 'name');
  const [searchValue, setSearchValue] = useState(nameQuery || '');
  const [filters, setFilters] = useState(documentsFilter);
  const [statuses, setStatuses] = useState<StatusesDocumentsModel>([]);
  const [dataRange, setDataRange] = useState<RangePickerProps['value']>(documentsFilter.dateRange);
  const [currentTimeout, setCurrentTimeout] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    filtersDispatch({
      type: SET_DOCUMENTS_FILTERS,
      filters,
    });
  }, [filters]);

  useEffect(() => {
    customFlowService
      .getStatuses({
        filters: generateFilterfromObj({
          'doc_types_statuses/typeCode': {
            type: FilterTypes.SELECT,
            value: 'documents',
          },
        }),
      })
      .then((value: StatusesDocumentsModel) => setStatuses(value))
      .catch((e) => console.log(e));
  }, [propertyId]);

  useEffect(() => {
    setDataRange(documentsFilter.dateRange);
  }, [documentsFilter.dateRange]);

  useEffect(() => {
    if (nameQuery) {
      onSearch(nameQuery);
    } else {
      onSearch('');
    }
  }, [nameQuery]);

  const onChangeStatus = (value: string) => {
    setFilters({ ...filters, status: value });
    const body = {
      'status_v2/code': {
        type: FilterTypes.SELECT,
        value: value,
      },
    };
    onTableChange(undefined, body);
  };

  const onSearch = (value: string) => {
    setSearchValue(value || '');
    const body = {
      title: {
        type: FilterTypes.SEARCH,
        value: value,
      },
    };
    clearTimeout(Number(currentTimeout));
    setCurrentTimeout(setTimeout(() => onTableChange(undefined, body), 1000));
  };

  const onChangeCalendar = (value: RangePickerProps['value']) => {
    setFilters({ ...filters, dateRange: value });
    const body = {
      createdDate: {
        type: FilterTypes.DATE_RANGE,
        value: value,
      },
    };
    onTableChange(undefined, body);
  };

  return (
    <div className={css['documents-control-container']}>
      <div className={css['documents-filter-control']}>
        <div className={`${css['documents-filters']} documents-control-wrapper`}>
          <Select
            value={filters.status}
            className="documents-control-select common-animation-primary new-status-container"
            onChange={onChangeStatus}
            placeholder={t('documents.control.filter.status.placeholder')}
            dropdownClassName="documents-control-select-dropdown"
            suffixIcon={
              <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
            }>
            <Option value="" className="documents-control-option">
              <span style={{ fontSize: '14px', fontWeight: 600 }}>{t('documents.control.select.all')}</span>
            </Option>
            {statuses.length > 0 &&
              statuses.map((status) => (
                <Option value={status.code} key={status.code} className="documents-control-option">
                  <span style={{ fontSize: '14px', fontWeight: 600, color: setTextColorByStatus(status.code) }}>
                    {status.description}
                  </span>
                </Option>
              ))}
          </Select>
        </div>
        <Search
          onChange={(e) => onSearch(e.target.value)}
          placeholder={t('documents.search.name.placeholder')}
          allowClear
          value={searchValue}
          className="documents-control-search common-animation-primary"
          suffix={<img src={searchIcon} alt="" />}
          style={{ width: '195px', marginLeft: '5px', padding: '1px 0' }}
        />
        <RangePicker
          suffixIcon={<div className={'calendar-img'} style={{ width: '18px', height: '18px' }}></div>}
          className="documents-control-range-picker common-animation-primary"
          style={{ height: '34px' }}
          defaultValue={dataRange}
          inputReadOnly
          onChange={onChangeCalendar}
        />
      </div>
      {!isConsumer && !useLessThen801() && (
        <Button className={`common-blue-btn common-primary-btn`} onClick={() => setIsOpenModal(true)}>
          <span>{t('document.upload')}</span>
        </Button>
      )}
    </div>
  );
};

export default DocumentsControl;
