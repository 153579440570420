import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Menu, Tooltip } from 'antd';
import { AppContext } from '../../../../contexts/AppContextProvider';
import { DiffOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import { getRouting } from '../../../../helpers/getRouting';
import { RouterListModel } from '../../../../models/RouterListModel';
import { defaultFunctions } from '../../../../constants/DefaultFunctions';
import { HeaderContext } from '../../../../contexts/HeaderContextProvider';
import { navGroups } from '../../../../constants/NavGroups';
import { useHistory } from 'react-router';
import logOutIcon from '../../../../assets/images/ic-log-out.svg';
import { useLessThen801 } from '../../../../helpers/mediaDetect';
import {
  SET_CURRENT_PROJECT,
  SET_CURRENT_PROPERTY,
  SET_NON_AUTHENTICATED,
} from '../../../../constants/actionTypes/appConstants';
import LoginPageServices from '../../../../services/LoginPageServices';
import css from './../../../../assets/styles/header.module.css';
import { useTranslation } from 'react-i18next';
import Projects from '../Projects';
import Properties from '../Properties';
import { NavContext } from '../../../../contexts/NavContextProvider';
import { useLocation } from 'react-router';
import { getSearchParam } from '../../../../helpers/customUrlSearchParams';
import { IAppContext } from '../../../../typings/IApp';
import config from '../../../../config';
import ArkaService from '../../../../services/ArkaService';
import { getErrorNotification } from '../../Notifications';

const loginService = new LoginPageServices();
const arkaService: ArkaService = new ArkaService();

interface Props {
  setIsOpenNav?: (b: boolean) => void;
  isMenuCollapsed?: boolean;
}
const NavMenu = (props: Props) => {
  const {
    header: { path },
  } = useContext(HeaderContext);
  const {
    app,
    appDispatch,
    app: { user, currentProject, currentProperty, isConsumer, isSupplier },
  } = useContext<IAppContext>(AppContext);
  const [isArkaLoader, setIsArkaLoader] = useState<boolean>(false);

  const isShowArkaBtn = useMemo(() => {
    const userProjectsIds = user?.projects.map((el) => el.id) || [];
    return config.arkaProjectsId?.split(',').some((projectId) => userProjectsIds.includes(+projectId));
  }, [config.arkaProjectsId, user?.projects]);

  const redirectToArka = async () => {
    setIsArkaLoader(true);
    await arkaService
      .getToken()
      .then((res) => {
        const a = document.createElement('a');
        a.href = `${config.arkaUrl}?token=${res.token}`;
        a.target = '_blank';
        a.click();
      })
      .catch((e) => {
        getErrorNotification(e);
      })
      .finally(() => setIsArkaLoader(false));
  };
  const { t } = useTranslation();

  const { search } = useLocation();
  const propertyIdQuery = getSearchParam(search, 'propertyId');
  const projectIdQuery = getSearchParam(search, 'projectId');

  const {
    nav: { isMenuCollapsed },
  } = useContext(NavContext);

  const history = useHistory();
  const { setIsOpenNav } = props;

  const functions = app.user
    ? isSupplier
      ? app.user.functions
      : defaultFunctions.concat(app.user.functions as any)
    : defaultFunctions;
  const settingFunctions = !isConsumer
    ? (functions as any).filter((route: any) => route.path !== 'calendar')
    : functions;

  const routings = getRouting(settingFunctions);

  const routingsNoGroup = useMemo(
    () =>
      routings &&
      routings.filter((route) =>
        currentProject?.externalId ? !route.group : !route.group && !['change_orders'].includes(route.code),
      ),
    [currentProject, routings],
  );

  //ord sort
  routings.forEach((rout) => {
    if (rout.children.length > 0) {
      rout.children.sort((a: any, b: any) => b.ord - a.ord);
    }
  });

  const logOut = async () => {
    loginService.logOut().finally(() => {
      window.localStorage.removeItem('isClosedHelper');
      appDispatch({ type: SET_NON_AUTHENTICATED });
      history.push('/login');
    });
  };

  useEffect(() => {
    if (user.projects.length > 0 && projectIdQuery && currentProject.id !== +projectIdQuery) {
      const item = user?.projects.find((project) => project.id === +projectIdQuery);
      item && appDispatch({ type: SET_CURRENT_PROJECT, currentProject: item });
    }
    if (user.properties.length > 0 && propertyIdQuery && currentProperty.id !== +propertyIdQuery) {
      const item = user?.properties.find((property) => property.id === +propertyIdQuery);
      item && appDispatch({ type: SET_CURRENT_PROPERTY, currentProperty: item });
    }
  }, [propertyIdQuery, projectIdQuery]);

  const menuIcons = (icon: string) => {
    switch (icon) {
      case 'home-outlined':
        return <div className="nav-custom-icons nav-properties-icons"></div>;
      case 'user-outlined':
        return <div className="nav-custom-icons nav-users-icons"></div>;
      case 'usergroup-add-outlined':
        return <div className="nav-custom-icons nav-les-roles-icons"></div>;
      case 'settings':
        return <div className="nav-custom-icons nav-configurator-icons"></div>;
      case 'apartment-outlined':
        return <div className="nav-custom-icons nav-typologies-icons"></div>;
      case 'diff-outlined':
        return <DiffOutlined />;
      case 'project-outlined':
        return <div className="nav-custom-icons nav-projects-icons"></div>;
      case 'team-outlined':
        return <div className="nav-custom-icons nav-consumers-icons"></div>;
      case 'database-outlined':
        return <div className="nav-custom-icons nav-inventory-icons"></div>;
      case 'barcode-outlined':
        return <div className="nav-custom-icons nav-des-produits-icons"></div>;
      case 'unordered-list-outlined':
        return <div className="nav-custom-icons nav-categories-icons"></div>;
      case 'solution-outlined':
        return <div className="nav-custom-icons nav-crm-icons"></div>;
      case 'delivered-procedure-outlined':
        return <div className="nav-custom-icons nav-demandes-icons"></div>;
      case 'dashboard-icon-consumer':
        return <div className="nav-custom-icons nav-dashboard-consumer-icons"></div>;
      case 'dashboard-icon':
        return <div className="nav-custom-icons nav-dashboard-icons"></div>;
      case 'tasks-icon-consumer':
        return <div className="nav-custom-icons tasks-consumer-icons"></div>;
      case 'tasks-icon':
        return <div className="nav-custom-icons tasks-icons"></div>;
      case 'tickets-icon-consumer':
        return <div className="nav-custom-icons nav-tickets-consumer-icons"></div>;
      case 'tickets-icon':
        return <div className="nav-custom-icons nav-tickets-icons"></div>;
      case 'calendar-icon-consumer':
        return <div className="nav-custom-icons nav-calendar-consumer-icons"></div>;
      case 'post-icon':
        return <div className="nav-custom-icons nav-post-icons"></div>;
      case 'post-icon-consumer':
        return <div className="nav-custom-icons nav-post-consumer-icons"></div>;
      case 'calendar-icon':
        return <div className="nav-custom-icons nav-calendar-icons"></div>;
      case 'meeting-icon-consumer':
        return <div className="nav-custom-icons nav-meeting-consumer-icons"></div>;
      case 'meeting-icon':
        return <div className="nav-custom-icons nav-meeting-icons"></div>;
      case 'budget-icon-consumer':
      case 'budget-icon':
        return <div className="nav-custom-icons nav-budget-icons"></div>;
      case 'documents-icon-consumer':
      case 'documents-icon':
        return <div className="nav-custom-icons nav-documents-icons"></div>;
      case 'gallery-icon-consumer':
      case 'gallery-icon':
        return <div className="nav-custom-icons nav-gallery-icons"></div>;
      case 'details-project-icon-consumer':
        return <div className="nav-custom-icons nav-project-icons"></div>;
      case 'faq-icon-consumer':
        return <div className="nav-custom-icons nav-faq-consumer-icons"></div>;
      case 'faq-icon':
        return <div className="nav-custom-icons nav-faq-icons"></div>;
      case 'manage-faq-icon':
        return <div className="nav-custom-icons nav-faq-icons"></div>;
      case 'profile-icon-consumer':
      case 'profile-icon':
        return <div className="nav-custom-icons nav-profile-icons"></div>;
      case 'document-file-icon-consumer':
      case 'quotes-icon-consumer':
        return <div className="nav-custom-icons nav-quotes-consumer-icons"></div>;
      case 'quotes-icon':
        return <div className="nav-custom-icons nav-quotes-icons"></div>;
      case 'reports-icon':
        return <div className="nav-custom-icons nav-reports-icons"></div>;
      case 'reports-icon-active':
        return <div className="nav-custom-icons nav-reports-icons--active"></div>;
      case 'companies-icon':
        return <div className="nav-custom-icons nav-companies-icons"></div>;
      case 'companies-icon-active':
        return <div className="nav-custom-icons nav-companies-icons--active"></div>;
      case 'internal-documents-icon':
        return <div className="nav-custom-icons nav-internal-icons"></div>;
      case 'quote-file-icon':
        return <div className="nav-custom-icons nav-quote-file-icons"></div>;
      case 'invoice-file-icon':
        return <div className="nav-custom-icons nav-invoice-file-icons"></div>;
      case 'document-file-icon':
        return <div className="nav-custom-icons nav-document-file-icons"></div>;
      case 'notification-history-icon':
        return <div className="nav-custom-icons nav-notification-history-icons"></div>;
      default:
        return;
    }
  };

  const parseMenuItems = (routings: any, isSubMenu = false) => {
    return routings
      .sort((a: any, b: any) => a.ord - b.ord)
      .map((route: RouterListModel) => {
        return (route.children && route.children.length) || !route.path ? (
          <Menu.SubMenu
            popupClassName={isMenuCollapsed && 'nav-group-container'}
            key={route.code}
            title={
              <>
                <span className="nav-icons">{menuIcons(`${route.icon}${isConsumer ? '-consumer' : ''}`)}</span>
                <span className="nav-submenu-item">{route.name}</span>
              </>
            }>
            {isMenuCollapsed && (
              <Menu.Item className="nav-menu-sub-label" disabled={true}>
                <span style={{ color: '#778dac' }}>{route.name}</span>
              </Menu.Item>
            )}
            {parseMenuItems(route.children, true)}
          </Menu.SubMenu>
        ) : (
          <Menu.Item
            className="common-animation-secondary"
            key={route.code}
            onClick={() => setIsOpenNav && setIsOpenNav(false)}>
            {isMenuCollapsed && !isSubMenu ? (
              <Tooltip
                placement="right"
                color="#fff"
                title={route.name}
                overlayClassName="nav-tooltip-container"
                overlayInnerStyle={{ color: '#000' }}>
                <span className="nav-icons">{menuIcons(`${route.icon}${isConsumer ? '-consumer' : ''}`)}</span>
                <span className="nav-menu-item">{route.name}</span>
                <NavLink to={route.path} />
              </Tooltip>
            ) : (
              <>
                <span className="nav-icons">{menuIcons(`${route.icon}${isConsumer ? '-consumer' : ''}`)}</span>
                <span className="nav-menu-item">{route.name}</span>
                <NavLink to={route.path} />
              </>
            )}
          </Menu.Item>
        );
      });
  };

  return (
    <div
      className={
        useLessThen801()
          ? 'nav-menu-mobile'
          : `nav-menu ${
              isConsumer && !isMenuCollapsed ? (isShowArkaBtn ? 'nav-menu--consumer_arka ' : 'nav-menu--consumer') : ''
            }`
      }
      style={useLessThen801() ? undefined : { height: isShowArkaBtn ? 'calc(100% - 225px)' : 'calc(100% - 165px)' }}>
      {/* routes without group */}
      {routingsNoGroup.length > 0 && (
        <Menu
          mode="inline"
          theme="light"
          selectedKeys={path}
          className="menu-container"
          style={{ paddingBottom: useLessThen801() ? '50px' : '0px' }}>
          {useLessThen801() && <Projects isCollapsed={isMenuCollapsed} />}
          {useLessThen801() && <Properties isCollapsed={isMenuCollapsed} />}
          {useLessThen801() && isShowArkaBtn && (
            <Button
              htmlType="button"
              loading={isArkaLoader}
              onClick={redirectToArka}
              className={`common-blue-border-btn common-secondary-btn`}
              style={{ height: '42px', width: 'calc(100% - 2rem)', margin: '0 1rem 1rem' }}>
              {t('arka.btn')}
            </Button>
          )}
          {parseMenuItems(routingsNoGroup)}
          {useLessThen801() &&
            navGroups.map((group) => {
              const updatedRouting = routings.filter((route) => route.group?.code === group.code);
              //dont show title without childrens
              if (updatedRouting.length === 0) {
                return null;
              }
              //routes with group
              return (
                <div key={group.code}>
                  <Menu mode="inline" theme="light" selectedKeys={path}>
                    {parseMenuItems(updatedRouting)}
                  </Menu>
                </div>
              );
            })}
          {useLessThen801() && (
            <Menu.Item key="log-out" className="menu-item-logout" onClick={logOut}>
              <img src={logOutIcon} alt="" className={css['log-out-btn']} />
              <span>{t('header.sign.out.btn')}</span>
            </Menu.Item>
          )}
        </Menu>
      )}
      {!useLessThen801() &&
        navGroups.map((group) => {
          const updatedRouting = routings.filter((route) => route.group?.code === group.code);
          //dont show title without childrens
          if (updatedRouting.length === 0) {
            return null;
          }
          //routes with group
          return (
            <div key={group.code}>
              {!isMenuCollapsed && (
                <div className="nav-group-title">
                  <span>{group.description}</span>
                </div>
              )}
              <Menu mode="inline" theme="light" selectedKeys={path} defaultOpenKeys={['file_management']}>
                {parseMenuItems(updatedRouting)}
              </Menu>
            </div>
          );
        })}
    </div>
  );
};
export default NavMenu;
