import { Form, TreeSelect } from 'antd';
import React from 'react';
import { TFunction } from 'react-i18next';
import { PropertyModel } from '../../../../models/PropertyModel';
import { IUserExport } from '../../../../models/ConsumerModel';
interface IProps {
  t: TFunction<'translation'>;
  properties: PropertyModel[];
}

export const getColumns = ({ t, properties }: IProps) => [
  {
    title: () => <span className="budget-table-tr">{t('consumers.table.columns.email')}</span>,
    key: 'email',
    width: '30%',
    render: (cell: IUserExport) => <span>{cell.email}</span>,
  },
  {
    title: () => <span className="budget-table-tr">{t('consumers.table.columns.name')}</span>,
    key: 'name',
    width: '30%',
    render: (cell: IUserExport) => (
      <span style={{ wordWrap: 'break-word' }}>{`${cell.firstName} ${cell.lastName}`}</span>
    ),
  },
  {
    title: () => <span className="budget-table-tr">{t('consumers.table.columns.property')}</span>,
    key: 'property',
    width: '40%',
    render: (cell: IUserExport) => (
      <Form.Item
        name={[cell.email]}
        style={{ marginBottom: 0 }}
        rules={[{ required: true, message: t('common.validation.required') }]}>
        <TreeSelect
          className="common-150-select common-select common-animation-primary"
          treeData={properties}
          multiple
          showSearch
          filterTreeNode={(search: string, item: any) => item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0}
          placeholder={t('common.goods.placeholder')}
          style={{ width: '100%' }}
          showCheckedStrategy="SHOW_CHILD"
          treeCheckable
          maxTagCount={1}
          fieldNames={{ label: 'title', value: 'id', children: 'childrens' }}
        />
      </Form.Item>
    ),
  },
];
