import { Button, Form, Modal, Radio } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../contexts/AppContextProvider';
import css from '../../../assets/styles/properties.module.css';
import { useHistory } from 'react-router';
import { IAppContext } from '../../../typings/IApp';

interface SelectProps {
  setIsOpenSelect: any;
  isOpenSelect: boolean;
  data: any;
}

const SelectProjectForm = (props: SelectProps) => {
  const { setIsOpenSelect, isOpenSelect, data } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const history = useHistory();
  const {
    app: { user },
  } = useContext<IAppContext>(AppContext);
  const projects = user?.projects;

  const onSubmit = (value: any) => {
    history.push(`/properties/property/project/${value.projectId}`);
  };

  return (
    <Modal
      visible={isOpenSelect}
      className="common-modal"
      centered
      footer={
        <div className="common-modal-footer">
          <Button className={`common-gray-border-btn common-secondary-btn`} onClick={() => setIsOpenSelect(false)}>
            {t('consumers.modal.cancel.btn')}
          </Button>
          <Button className={`common-green-btn common-modal-btn`} onClick={() => form.submit()}>
            {t('properties.create.items.goods.placeholder')}
          </Button>
        </div>
      }
      closable={false}>
      <Form autoComplete="off" onFinish={onSubmit} form={form} style={{ display: 'flex', flexDirection: 'column' }}>
        <Form.Item
          colon={false}
          name="projectId"
          style={{ padding: '1rem 3rem', overflowY: 'auto' }}
          rules={[{ required: true, message: t('common.validation.required') }]}
          labelCol={{ span: 24 }}
          label={t('property.create.modal.title')}>
          <Radio.Group
            style={{ display: 'flex', flexDirection: 'column', padding: '0 1rem', marginBottom: '3rem' }}
            className="consumers-import-checkbox-group">
            {projects &&
              projects.map((project: any) => (
                <Radio key={project.id} value={project.id} className="consumers-import-checkbox">
                  {project.title}
                </Radio>
              ))}
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SelectProjectForm;
