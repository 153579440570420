import { DatePicker, Form, notification, Select, Upload } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../../../assets/styles/budget.module.css';
import { validateDateLessThanToday } from '../../../../../helpers/validateDateField';
import confirmIcon from '../../../../../assets/images/ic-check-mark-gray-copy.svg';
import alertIcon from '../../../../../assets/images/ic-alert.svg';
import UsersService from '../../../../../services/UsersService';
import { ConsumerModel } from '../../../../../models/ConsumerModel';
import { useParams } from 'react-router';
import { IFlow, IFlowStep } from '../../../../../models/CustomSignFlowModel';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { IUserEasy } from '../../../../../models/UserModel';
import { IResponceData } from '../../../../../typings/IServiceOptions';

interface IFinalAccountRight {
  handleFileAttachment: (info: UploadChangeParam<UploadFile>) => void;
  fileAttachmentList: UploadFile[];
  fields: {
    id: number;
    isDone: boolean;
    title: string;
  }[];
  propertyId: string;
  setConsumersList: (v: ConsumerModel[]) => void;
  onSelectFlow: (v: number | '') => void;
  flows: IFlow[];
  flowSteps: IFlowStep[];
}

const userService = new UsersService();

const FinalAccountRight = ({
  handleFileAttachment,
  fileAttachmentList,
  fields,
  propertyId,
  setConsumersList,
  onSelectFlow,
  flows,
  flowSteps,
}: IFinalAccountRight) => {
  const { t } = useTranslation();
  const params: { projectId: string } = useParams();
  const { projectId } = params;
  const [searchValue, setSearchValue] = useState<null | string>(null);
  const [currentTimeout, setCurrentTimeout] = useState<NodeJS.Timeout | null>(null);
  const [consumers, setConsumers] = useState<ConsumerModel[]>([]);
  const [agents, setAgents] = useState<IUserEasy[]>([]);
  const [isLastAgentsReq, setIsLastAgentsReq] = useState(false);
  const [fetchingUserData, setFetchingUserData] = useState(false);
  const [paginationAgents, setPaginationAgents] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    skip: 0,
  });

  useEffect(() => {
    getAgents();
    getConsumers();
  }, []);

  useEffect(() => {
    if (searchValue === '') {
      return getAgents();
    }
    if (searchValue) {
      getAgents();
    }
  }, [searchValue]);

  const getAgents = () => {
    const optionUser = {
      count: true,
      top: paginationAgents.pageSize,
      current: paginationAgents.current,
      skip: paginationAgents.pageSize * (paginationAgents.current - 1) || 0,
      projectId: [projectId],
      fullName: searchValue || '',
      roles: ['agent', 'admin', 'super_agent'],
    };

    setFetchingUserData(true);
    userService
      .getEasyUsers(optionUser)
      .then((res: IResponceData<IUserEasy>) => {
        const { count, items } = res;
        const { current, top } = optionUser;
        setPaginationAgents({ ...paginationAgents, current: current + 1, total: count, pageSize: top });
        const ids = Array.from(new Set([...agents, ...items].map((user) => user.id)));
        const uniqUsers = ids.map((id) => [...agents, ...items].find((customer) => customer.id === id));
        setAgents(uniqUsers as IUserEasy[]);
        setIsLastAgentsReq(current * top >= count);
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingUserData(false));
  };

  const getConsumers = () => {
    userService
      .getConsumersByProperty(+propertyId)
      .then((value: ConsumerModel[]) => {
        setConsumers(value);
        setConsumersList(value);
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      );
  };

  return (
    <div className={css['upload-steps-right-col']}>
      <div className={css['upload-steps-item']}>
        <div className={css['upload-steps-container']}>
          <span style={{ marginBottom: '0.5rem' }}>{t('contract.flow.title')}</span>
          <Form.Item name="flow" colon={false} className={css['form-item']} initialValue={''} labelAlign="left">
            <Select
              className="common-select common-animation-primary"
              suffixIcon={
                <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
              }
              style={{ marginTop: '0' }}
              onSelect={onSelectFlow}>
              <Select.Option value={''}>{t('contract.flow.noFlow')}</Select.Option>
              {flows.length > 0 &&
                flows.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          {flowSteps.length > 0 &&
            flowSteps.map((item) => (
              <div key={item.code}>
                <span style={{ marginBottom: '0.5rem' }}>{`${item.signatoriesName} *`}</span>
                <Form.Item
                  name={item.code}
                  colon={false}
                  rules={[{ required: true, message: t('common.validation.required') }]}
                  className={css['form-item']}
                  labelAlign="left">
                  <Select
                    className="common-select common-animation-primary"
                    mode="multiple"
                    onDropdownVisibleChange={(value) => {
                      if (item.code.split('_').includes('agent') && !value && searchValue) {
                        setAgents([]);
                        setPaginationAgents({ ...paginationAgents, current: 1, skip: 0 });
                        setSearchValue('');
                      }
                    }}
                    onSearch={(value) => {
                      if (item.code.split('_').includes('agent')) {
                        setAgents([]);
                        setPaginationAgents({ ...paginationAgents, current: 1, skip: 0 });
                        clearTimeout(Number(currentTimeout));
                        setCurrentTimeout(setTimeout(() => setSearchValue(value), 1000));
                      }
                    }}
                    filterOption={(value, option) =>
                      item.code.split('_').includes('agent')
                        ? true
                        : option?.children?.toLowerCase().indexOf(value.toLowerCase()) >= 0
                    }
                    dropdownClassName={`documents-control-select-dropdown ${
                      fetchingUserData ? 'consumers-control-select-dropdown--progress' : ''
                    }`}
                    onPopupScroll={(e: any) => {
                      e.persist();
                      let target = e.target;
                      if (
                        item.code.split('_').includes('agent') &&
                        !fetchingUserData &&
                        !isLastAgentsReq &&
                        target.scrollTop + target.offsetHeight === target.scrollHeight
                      ) {
                        getAgents();
                      }
                    }}
                    suffixIcon={
                      <div
                        className={`arrow-drop-img ${css['arrow-drop']}`}
                        style={{ width: '8px', height: '4px' }}></div>
                    }>
                    {item.code.split('_').includes('agent')
                      ? agents.length > 0 &&
                        agents.map((signatory) => (
                          <Select.Option key={signatory.id} value={signatory.id}>
                            {`${signatory.firstName} ${signatory.lastName}`}
                          </Select.Option>
                        ))
                      : consumers.length > 0 &&
                        consumers
                          .sort((a, b) => a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()))
                          .map((signatory) => (
                            <Select.Option key={signatory.id} value={signatory.id}>
                              {`${signatory.firstName} ${signatory.lastName}`}
                            </Select.Option>
                          ))}
                  </Select>
                </Form.Item>
              </div>
            ))}
          <span style={{ marginBottom: '0.5rem' }}>{t('budget.form.deadline')}</span>
          <Form.Item
            name="deadline"
            className={css['drawer-form-item']}
            initialValue={moment().add(10, 'days')}
            rules={[
              { required: true, message: t('common.validation.required') },
              validateDateLessThanToday('deadline', moment(), t('budget.form.deadline.validate')),
            ]}>
            <DatePicker
              inputReadOnly
              format="DD.MM.YYYY"
              className="common-animation-primary"
              getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
            />
          </Form.Item>
          <span style={{ marginBottom: '0.5rem' }}>{t('roles.table.columns.description')}</span>
          <Form.Item name="description" className={css['drawer-form-item']}>
            <TextArea className="consumer-form-input common-animation-primary" autoSize={{ minRows: 2, maxRows: 3 }} />
          </Form.Item>
          <span style={{ marginBottom: '0.5rem' }}>{t('budget.form.sign.comments')}</span>
          <Form.Item name="signInfo" className={css['drawer-form-item']}>
            <TextArea className="consumer-form-input common-animation-primary" autoSize={{ minRows: 2, maxRows: 3 }} />
          </Form.Item>
        </div>
        <div className={css['upload-steps-container']}>
          <span style={{ marginBottom: '1rem' }}>{t('budget.upload.overview.title')}</span>
          <span style={{ borderBottomStyle: 'dashed', color: '#778dac', marginBottom: '1rem' }}>
            {t('budget.upload.overview.fields.title')}
          </span>
          {fields.map((field) => (
            <span key={field.id} style={{ color: '#778dac', marginBottom: '0.5rem' }}>
              {field.isDone ? <img src={confirmIcon} alt="" /> : <img src={alertIcon} alt="" />}
              <span style={{ marginLeft: '1rem' }}>{field.title}</span>
            </span>
          ))}
        </div>
      </div>
      <div className={css['upload-steps-item']}>
        <div className={css['upload-steps-container']}>
          <span style={{ marginBottom: '1rem' }}>{t('budget.upload.attachments.title')}</span>
          <div>
            <Form.Item name="fileAttachment">
              <Upload
                className="consumers-import-upload-item upload-list-invoice"
                style={{ width: '100%' }}
                beforeUpload={() => false}
                onChange={handleFileAttachment}
                onRemove={() => true}
                multiple={true}
                fileList={fileAttachmentList}
                accept=".pdf">
                <div className={css['upload-item-budget']}>
                  <span style={{ color: '#076ee5' }}>{t('budget.upload.attachment.btn.add')}</span>
                </div>
              </Upload>
            </Form.Item>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinalAccountRight;
