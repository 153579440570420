import { Select, Switch, TablePaginationConfig } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLessThen801 } from '../../../helpers/mediaDetect';
import searchIcon from '../../../assets/images/ic-search.svg';
import Search from 'antd/lib/input/Search';
import css from '../../../assets/styles/project.module.css';
import { ICFC } from '../../../models/CFCModel';
import { FilterTypes } from '../../../constants/filterTypes';
import { FilterValue } from 'antd/lib/table/interface';

const { Option } = Select;

interface IProjectControlProps {
  CFC?: ICFC | null;
  isModalCFC?: boolean;
  onStatusChange?: (b: boolean) => void;
  onFiltersChange?: (search: string, status: string, group: string) => void;
  onTableChange?: (pagination?: TablePaginationConfig, filters?: Record<string, FilterValue | null | any>) => void;
}

const ProjectControl = ({ CFC, isModalCFC, onStatusChange, onFiltersChange, onTableChange }: IProjectControlProps) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');
  const [group, setGroup] = useState('');
  const [currentTimeout, setCurrentTimeout] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    onFiltersChange && onFiltersChange(search, status, group);
  }, [search, status, group]);

  const onSearchCFC = (value: string) => {
    setSearch(value || '');
    const body = {
      searchByCFC: {
        type: FilterTypes.MULTISEARCH,
        value: [{ code: value }, { description_en: value }, { description_fr: value }],
      },
    };
    clearTimeout(Number(currentTimeout));
    setCurrentTimeout(setTimeout(() => onTableChange && onTableChange(undefined, body), 1000));
    onTableChange && onTableChange(undefined, body);
  };

  // const onChangeStatus = (value: any) => {
  //   setStatus(value || '');
  //   const body = {
  //     active: {
  //       type: value === '' || value === 'true' ? FilterTypes.SELECT : FilterTypes.SELECT_MULTIPLE,
  //       value: value === '' || value === 'true' ? (value === '' ? '' : true) : [false, null],
  //     },
  //   };
  //   onTableChange(null, body);
  // };

  return (
    <div style={{ display: 'flex', marginBottom: '1rem' }} className={css['project-control']}>
      {isModalCFC ? (
        <>
          <Search
            allowClear
            className="consumers-control-search common-animation-primary"
            value={search}
            style={useLessThen801() ? { padding: '5px 0', marginTop: 0 } : { width: '230px', padding: '5px 0' }}
            placeholder={t('post.control.search.placeholder')}
            suffix={<img src={searchIcon} alt="" />}
            onChange={(e) => setSearch(e.target.value)}
          />
          {/* <Select
            value={group}
            style={
              useLessThen801() ? { padding: '5px 0', marginTop: '1rem' } : { padding: '5px 0', marginLeft: '1rem' }
            }
            className="consumers-control-select common-animation-primary"
            onChange={setGroup}
            dropdownClassName="consumers-control-select-dropdown"
            suffixIcon={
              <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
            }>
            <Option value={''} className="budget-control-option">
              <span style={{ fontSize: '14px', fontWeight: 600 }}>{t('projects.filter.all.groups')}</span>
            </Option>
            <Option value={'Douche'} className="budget-control-option">
              <span style={{ fontSize: '14px', fontWeight: 600 }}>Douche</span>
            </Option>
            <Option value={'Baignoire'} className="budget-control-option">
              <span style={{ fontSize: '14px', fontWeight: 600 }}>Baignoire</span>
            </Option>
            <Option value={'WC'} className="budget-control-option">
              <span style={{ fontSize: '14px', fontWeight: 600 }}>WC</span>
            </Option>
          </Select> */}
          <div
            style={
              useLessThen801()
                ? { display: 'flex', alignItems: 'center', marginTop: '1rem' }
                : { display: 'flex', alignItems: 'center', marginLeft: '1rem' }
            }>
            <span style={{ fontWeight: 600, marginRight: '0.5rem' }}>{t('projects.filter.status')} :</span>
            <Switch size="small" defaultChecked={CFC?.active || false} onChange={onStatusChange} />
            <span style={{ fontWeight: 400, marginLeft: '0.5rem' }}>
              {t('common.filters.isActive').toLocaleLowerCase()}
            </span>
          </div>
        </>
      ) : (
        <>
          <Search
            allowClear
            className="consumers-control-search common-animation-primary"
            value={search}
            style={useLessThen801() ? { padding: '5px 0', marginTop: 0 } : { width: '230px', padding: '5px 0' }}
            placeholder={t('projects.filter.search.placeholder')}
            suffix={<img src={searchIcon} alt="" />}
            onChange={(e) => onSearchCFC(e.target.value)}
          />
          <Select
            value={status.toString()}
            style={
              useLessThen801() ? { padding: '5px 0', marginTop: '1rem' } : { padding: '5px 0', marginLeft: '1rem' }
            }
            className="consumers-control-select common-animation-primary"
            onChange={setStatus}
            dropdownClassName="consumers-control-select-dropdown"
            suffixIcon={
              <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
            }>
            <Option value={''} className="budget-control-option">
              <span style={{ fontSize: '14px', fontWeight: 600 }}>{t('leads.control.filter.defvalue.status')}</span>
            </Option>
            <Option value={'true'} className="budget-control-option">
              <span style={{ fontSize: '14px', fontWeight: 600 }}>{t('projects.filter.only.active')}</span>
            </Option>
            <Option value={'false'} className="budget-control-option">
              <span style={{ fontSize: '14px', fontWeight: 600 }}>{t('projects.filter.only.inactive')}</span>
            </Option>
          </Select>
        </>
      )}
    </div>
  );
};

export default ProjectControl;
