import {
  CATEGORY_SET_ITEMS,
  CATEGORY_REFRESH_ITEMS,
  CATEGORY_SET_IS_SELECTED_NODE,
  CATEGORY_SET_CURRENT_NODE,
  CATEGORY_SET_PARENT,
  CATEGORY_SET_SELECTED_NODES,
  CATEGORY_SET_TYPES,
} from '../constants/actionTypes/categoriesConstants';

interface CategoriesReducerModel {
  type?: string;
  currentNode?: any;
  currentNodeKey?: string | null;
  parent?: any;
  isSelectedNode?: boolean;
  selectedNodes: any[];
  items?: any[];
  types?: any[];
  isRefresh?: boolean;
}

export const initialState: CategoriesReducerModel = {
  currentNode: undefined,
  currentNodeKey: null,
  parent: null,
  isSelectedNode: false,
  selectedNodes: [],
  items: [],
  types: [],
  isRefresh: false,
};

export function reducer(state: any, action: CategoriesReducerModel) {
  switch (action.type) {
    case CATEGORY_SET_ITEMS:
      return {
        ...state,
        items: action.items,
      };
    case CATEGORY_REFRESH_ITEMS:
      return {
        ...state,
        isRefresh: action.isRefresh,
      };
    case CATEGORY_SET_IS_SELECTED_NODE:
      return {
        ...state,
        isSelectedNode: action.isSelectedNode,
      };
    case CATEGORY_SET_CURRENT_NODE:
      return {
        ...state,
        currentNode: action.currentNode,
        currentNodeKey: action.currentNodeKey,
      };
    case CATEGORY_SET_PARENT:
      return {
        ...state,
        parent: action.parent,
      };
    case CATEGORY_SET_SELECTED_NODES:
      return {
        ...state,
        selectedNodes: action.selectedNodes,
      };
    case CATEGORY_SET_TYPES:
      return {
        ...state,
        types: action.types,
      };
    default:
      return initialState;
  }
}
