import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { Form, Input, Button, Card, Row, Col, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import RolesService from '../../services/RolesService';
import { useLessThen801 } from '../../helpers/mediaDetect';
import { RoleModel } from '../../models/RoleModel';
import { FunctionModel } from '../../models/FunctionModel';

const rolesService: RolesService = new RolesService();

const { OptGroup } = Select;

const RolesModifyPage: React.FC = () => {
  const { t } = useTranslation();
  const { name } = useParams<{ name: string }>();
  const history = useHistory();
  const isCreate = !name;
  const [form] = Form.useForm();
  const [functions, setFunctions] = useState<FunctionModel[]>([]);
  const [breadcrumbNameMap, setBreadcrumbNameMap] = useState<{ [name: string]: string }>({});

  const getFunctions = () => {
    return rolesService.getMenuFunctions().then((res: FunctionModel[]) => {
      setFunctions(res);
    });
  };

  const getAsyncData = async () => {
    const actions = [getFunctions()];
    if (!isCreate) {
      actions.unshift(getRole());
    }
    Promise.all(actions).finally(() => {
      // TODO: set loading stop
      console.log('>>> STOP -> ', new Date());
    });
  };

  const getRole = () => {
    return rolesService.getRoleByCode(name).then((res: RoleModel) => {
      form.setFieldsValue({
        name: res.name,
        description: res.description,
        menuFunctions: res.menuFunctions?.map((func) => func.id),
      });
    });
  };

  useEffect(() => {
    // TODO: set loading start
    console.log('>>> START -> ', new Date());
    getAsyncData();
  }, []);

  useEffect(() => {
    setBreadcrumbNameMap(
      history.location.pathname
        .replace('/roles', '')
        .replace(/\/modify\/\S+/, '/Modifications')
        .replace(/\/modify/, `/${t('common.title.create')}`)
        .split('/')
        .slice(1)
        .reduce(
          (res, curr, index) => ({
            ...res,
            [[Object.keys(res)[index], curr].join('/')]: curr.split(':').shift(),
          }),
          { '/roles': t('roles.bread.root') },
        ),
    );
  }, [history.location.pathname]);

  const onSubmit = (values: any) => {
    const { name, description, menuFunctions = [] } = values;

    if (isCreate) {
      rolesService
        .createRole({
          name,
          description,
          menuFunctions,
        })
        .then(() => {
          history.push('/roles');
        });
    } else {
      rolesService
        .updateRole(name, {
          name,
          description,
          menuFunctions,
        })
        .then(() => {
          history.push('/roles');
        });
    }
  };

  const onCancel = () => {
    history.push('/roles');
  };

  if (useLessThen801()) {
    return (
      <div>
        <Form autoComplete="off" form={form} layout="vertical" name="modify_role" onFinish={onSubmit}>
          <HeaderTable
            title={!isCreate ? `${t('roles.create.title.update')} #${name}` : t('roles.create.title.create')}
            breadcrumb={breadcrumbNameMap}>
            <Form.Item>
              <div style={{ display: 'flex' }}>
                <Button
                  htmlType="button"
                  onClick={onCancel}
                  className={`common-blue-border-btn common-secondary-btn`}
                  style={{ height: '42px', marginRight: '0.5rem', width: '100%' }}>
                  {t('common.btn.cancel')}
                </Button>
                <Button
                  htmlType="submit"
                  className={`common-blue-btn common-primary-btn`}
                  style={{ height: '42px', marginLeft: '0.5rem', width: '100%' }}>
                  {t('common.btn.save')}
                </Button>
              </div>
            </Form.Item>
          </HeaderTable>
          <Row gutter={[24, 4]}>
            <Col span={24}>
              <Card title={t('roles.create.card.role.title')}>
                <Form.Item
                  label={t('roles.create.card.role.name')}
                  name="name"
                  rules={[{ required: true, message: t('roles.create.card.role.name.validation') }]}>
                  <Input disabled={!isCreate} className="common-animation-primary" />
                </Form.Item>
                <Form.Item
                  label={t('roles.create.card.role.description')}
                  name="description"
                  rules={[{ required: true, message: t('roles.create.card.role.description.validation') }]}>
                  <Input className="common-animation-primary" />
                </Form.Item>
                <Form.Item name="menuFunctions" label={t('roles.create.card.role.menuFunctions')}>
                  <Select
                    className="common-select common-animation-primary"
                    mode="multiple"
                    placeholder={t('roles.create.card.role.menuFunctions.placeholder')}>
                    <OptGroup label={t('quotes.modal.form.agents')}>
                      {functions.length > 0 &&
                        functions
                          .filter((f) => !f.isConsumers)
                          .map((func) => (
                            <Select.Option value={func.id} key={func.id} title={func.name} disabled={!func.isActive}>
                              {func.name}
                            </Select.Option>
                          ))}
                    </OptGroup>
                    <OptGroup label={t('consumers.bread.root')}>
                      {functions.length > 0 &&
                        functions
                          .filter((f) => f.isConsumers)
                          .map((func) => (
                            <Select.Option value={func.id} key={func.id} title={func.name} disabled={!func.isActive}>
                              {func.name}
                            </Select.Option>
                          ))}
                    </OptGroup>
                  </Select>
                </Form.Item>
              </Card>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }

  return (
    <div>
      <Form autoComplete="off" form={form} layout="vertical" name="modify_role" onFinish={onSubmit}>
        <HeaderTable
          title={!isCreate ? `${t('roles.create.title.update')} #${name}` : t('roles.create.title.create')}
          breadcrumb={breadcrumbNameMap}>
          <Form.Item>
            <Button
              htmlType="button"
              onClick={onCancel}
              className={`common-blue-border-btn common-secondary-btn`}
              style={{ height: '42px', marginRight: '1rem' }}>
              {t('common.btn.cancel')}
            </Button>
            <Button htmlType="submit" className={`common-blue-btn common-primary-btn`} style={{ height: '42px' }}>
              {t('common.btn.save')}
            </Button>
          </Form.Item>
        </HeaderTable>
        <Row gutter={[24, 4]}>
          <Col span={24}>
            <Card title={t('roles.create.card.role.title')}>
              <Row gutter={[24, 4]}>
                <Col span={12}>
                  <Form.Item
                    label={t('roles.create.card.role.name')}
                    name="name"
                    rules={[{ required: true, message: t('roles.create.card.role.name.validation') }]}>
                    <Input disabled={!isCreate} className="common-animation-primary" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t('roles.create.card.role.description')}
                    name="description"
                    rules={[{ required: true, message: t('roles.create.card.role.description.validation') }]}>
                    <Input className="common-animation-primary" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item name="menuFunctions" label={t('roles.create.card.role.menuFunctions')}>
                <Select
                  className="common-select common-animation-primary"
                  mode="multiple"
                  placeholder={t('roles.create.card.role.menuFunctions.placeholder')}>
                  <OptGroup label={t('quotes.modal.form.agents')}>
                    {functions.length > 0 &&
                      functions
                        .filter((f) => !f.isConsumers)
                        .map((func) => (
                          <Select.Option value={func.id} key={func.id} title={func.name} disabled={!func.isActive}>
                            {func.name}
                          </Select.Option>
                        ))}
                  </OptGroup>
                  <OptGroup label={t('consumers.bread.root')}>
                    {functions.length > 0 &&
                      functions
                        .filter((f) => f.isConsumers)
                        .map((func) => (
                          <Select.Option value={func.id} key={func.id} title={func.name} disabled={!func.isActive}>
                            {func.name}
                          </Select.Option>
                        ))}
                  </OptGroup>
                </Select>
              </Form.Item>
            </Card>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default RolesModifyPage;
