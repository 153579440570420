import React from 'react';
import CategoriesContextProvider from '../../contexts/CategoriesContextProvider';
import CategoriesPage from './CategoriesPage';

const CategoriesLayout: React.FC = (props: any) => {
  return (
    <CategoriesContextProvider>
      <CategoriesPage {...props} />
    </CategoriesContextProvider>
  );
};

export default CategoriesLayout;
