import { Button, Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../../../assets/styles/budget.module.css';
import { PlusCircleOutlined } from '@ant-design/icons';

interface IAmendmentOptionalText {
  isTopOptionalText: boolean;
  introductionText?: string;
  setIntroductionText?: (s: string) => void;
  includingText?: string;
  setIncludingText?: (s: string) => void;
}

const AmendmentOptionalText = ({
  isTopOptionalText,
  includingText,
  setIncludingText,
  introductionText,
  setIntroductionText,
}: IAmendmentOptionalText) => {
  const { t } = useTranslation();

  const [isOpenIntroduction, setIsOpenIntroduction] = useState(false);
  const [isOpenIncluding, setIsOpenIncluding] = useState(false);

  if (isTopOptionalText) {
    return (
      <>
        {!isOpenIntroduction &&
          (introductionText ? (
            <span onClick={() => setIsOpenIntroduction(true)}>{introductionText}</span>
          ) : (
            <Button onClick={() => setIsOpenIntroduction(true)} className={css['upload-add-btn']}>
              <PlusCircleOutlined />
              {t('budget.invoice.form.optional')}
            </Button>
          ))}
        <Form.Item name="introductionText">
          {isOpenIntroduction && (
            <TextArea
              onBlur={() => setIsOpenIntroduction(false)}
              onChange={(value) => setIntroductionText && setIntroductionText(value.target.value)}
              className="consumer-form-input common-animation-primary"
            />
          )}
        </Form.Item>
      </>
    );
  }
  return (
    <>
      {!isOpenIncluding &&
        (includingText ? (
          <span onClick={() => setIsOpenIncluding(true)}>{includingText}</span>
        ) : (
          <Button onClick={() => setIsOpenIncluding(true)} className={css['upload-add-btn']}>
            <PlusCircleOutlined />
            {t('budget.invoice.form.concluding')}
          </Button>
        ))}
      <Form.Item name="includingText">
        {isOpenIncluding && (
          <TextArea
            onBlur={() => setIsOpenIncluding(false)}
            onChange={(value) => setIncludingText && setIncludingText(value.target.value)}
            className="consumer-form-input common-animation-primary"
          />
        )}
      </Form.Item>
    </>
  );
};

export default AmendmentOptionalText;
