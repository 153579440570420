import {
  UPDATE_USER,
  DELETE_USER,
  SET_CHANGE_PASSWORD,
  DELETE_CHANGE_PASSWORD,
  SET_AUTHENTICATED,
  SET_NON_AUTHENTICATED,
  SET_PROFILE,
  SET_TECH_TYPES,
  SET_IS_NON_LOGIN,
  SET_CURRENT_PROJECT,
  SET_CURRENT_PROPERTY,
  CHANGE_ONLY_MY_CONSUMERS,
  CHANGE_IS_NEED_SAVE_PROPERTIES,
  CHANGE_IS_NEED_OPEN_MODAL_SAVE,
  SET_LOGOUT_REF,
  SET_IS_OPEN_HELPER,
} from '../constants/actionTypes/appConstants';
import { IsJsonString } from '../helpers/isJSONValid';

export const initialState = {
  user: IsJsonString(localStorage.getItem('user') as string)
    ? JSON.parse(localStorage.getItem('user') as string)
    : null,
  currentProject: IsJsonString(localStorage.getItem('currentProject') as string)
    ? JSON.parse(localStorage.getItem('currentProject') as string)
    : null,
  currentProperty: IsJsonString(localStorage.getItem('currentProperty') as string)
    ? JSON.parse(localStorage.getItem('currentProperty') as string)
    : null,
  isMenuCollapsed: false,
  isAuthenticated: localStorage.getItem('id_token') ? true : false,
  isLogin: false,
  roles: localStorage.getItem('roles') || null,
  techTypes: undefined,
  username: undefined,
  isChangePassword: false,
  isConsumer: IsJsonString(localStorage.getItem('IS_CONSUMER') as string)
    ? JSON.parse(localStorage.getItem('IS_CONSUMER') as string) === 'true'
      ? true
      : false
    : false,
  isSupplier: IsJsonString(localStorage.getItem('IS_SUPPLIER') as string)
    ? JSON.parse(localStorage.getItem('IS_SUPPLIER') as string) === 'true'
      ? true
      : false
    : false,
  isOnlyMyConsumers: true,
  isNeedSaveForProperties: false,
  isNeedOpenModalSave: false,
  logoutRef: null as null | HTMLImageElement,
  isOpenHelper: true,
};

export function reducer(state: any, action: any) {
  switch (action.type) {
    case UPDATE_USER:
      return { username: action.username };
    case DELETE_USER:
      return { username: null };
    case SET_CHANGE_PASSWORD:
      return { isChangePassword: true, username: action.username };
    case DELETE_CHANGE_PASSWORD:
      return { isChangePassword: false, username: undefined };
    case SET_AUTHENTICATED:
      const project =
        action.user.projects?.find((item: any) => state.currentProject?.id === item.id) ||
        (action.user.projects || [])[0];
      return {
        isAuthenticated: true,
        isLogin: false,
        user: action.user,
        roles: action.user.roles,
        isConsumer: action.user.rolesParams && action.user.rolesParams.IS_CONSUMER,
        isSupplier: action.user.rolesParams && action.user.rolesParams.IS_SUPPLIER,
        currentProject: project || null,
        currentProperty:
          action.user.properties?.find((property: any) => property.project.id === project.id) ||
          (action.user.properties || [])[0] ||
          null,
      };
    case SET_NON_AUTHENTICATED:
      return { isAuthenticated: false, user: null };
    case SET_PROFILE:
      return {
        ...state,
        user: action.user,
        roles: action.user.roles,
        currentProject:
          action.user.projects?.find((item: any) => state.currentProject?.id === item.id) ||
          (action.user.projects || [])[0] ||
          null,
        currentProperty:
          state.currentProperty ||
          action.user.properties.find((property: any) => property.project.id === state.currentProject?.id) ||
          (action.user.properties || [])[0] ||
          null,
      };
    case SET_IS_NON_LOGIN:
      return { ...state, isLogin: false };
    case SET_TECH_TYPES:
      return { ...state, techTypes: action.techTypes };
    case SET_CURRENT_PROJECT:
      return { ...state, currentProject: action.currentProject };
    case SET_CURRENT_PROPERTY:
      return { ...state, currentProperty: action.currentProperty };
    case CHANGE_ONLY_MY_CONSUMERS:
      return { ...state, isOnlyMyConsumers: action.isOnlyMyConsumers };
    case CHANGE_IS_NEED_SAVE_PROPERTIES:
      return { ...state, isNeedSaveForProperties: action.isNeedSaveForProperties };
    case CHANGE_IS_NEED_OPEN_MODAL_SAVE:
      return { ...state, isNeedOpenModalSave: action.isNeedOpenModalSave };
    case SET_LOGOUT_REF:
      return { ...state, logoutRef: action.logoutRef };
    case SET_IS_OPEN_HELPER:
      return { ...state, isOpenHelper: action.isOpenHelper };
    default:
      return initialState;
  }
}
