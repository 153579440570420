import React from 'react';
import { Button, Checkbox, Dropdown, Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import css from '../../../../assets/styles/faq.module.css';
import i18n from '../../../../utils/i18n';
import { QuestionModel } from '../../../../models/FAQModel';
import editIcon from '../../../../assets/images/ic-edit-dark.svg';
import deleteIcon from '../../../../assets/images/ic-delete-dark-gray.svg';
import moreIcon from '../../../../assets/images/ic-more.svg';
import { TFunction } from 'react-i18next';

interface ConsumerColumnsModel {
  t: TFunction<'translation'>;
  setOpenDelete: (n: number) => void;
  isSelectMode: boolean;
  setDeletedItems: (n: number) => void;
  selectedItems: Array<number> | [];
  setAllDeletedItems: (b: boolean) => void;
}

export const getColumns = ({
  t,
  setOpenDelete,
  isSelectMode,
  setDeletedItems,
  selectedItems,
  setAllDeletedItems,
}: ConsumerColumnsModel) => [
  {
    title: () => isSelectMode && <Checkbox onChange={(e) => setAllDeletedItems(e.target.checked)} />,
    width: isSelectMode ? 40 : 0,
    key: 'selected',
    render: (cell: QuestionModel) =>
      isSelectMode && (
        <Checkbox
          onChange={() => setDeletedItems(cell.id)}
          checked={Boolean(selectedItems.find((item) => item === cell.id))}
        />
      ),
  },
  {
    title: () => <span className="budget-table-tr">№</span>,
    dataIndex: 'id',
    width: 40,
    key: 'id',
  },
  {
    title: () => <span className="budget-table-tr">{t('manage.faq.columns.question')}</span>,
    key: 'question',
    width: 300,
    render: (cell: QuestionModel) => (i18n.language === 'fr-FR' ? cell.questionFr : cell.questionEn),
  },
  {
    title: () => <span className="budget-table-tr">{t('manage.faq.columns.topic')}</span>,
    key: 'topic',
    width: 200,
    render: (cell: QuestionModel) => (i18n.language === 'fr-FR' ? cell.topic?.topicFr : cell.topic?.topicEn),
  },
  {
    title: () => <span className="budget-table-tr">{t('manage.faq.columns.statuses')}</span>,
    key: 'statuses',
    width: 200,
    render: (cell: QuestionModel) => {
      return (
        <div className={css['statuses-column-published-container']}>
          <span>
            EN:
            <span className={css[cell.isPublishedEn ? 'published-status' : 'draft-status']}>{` ${
              cell.isPublishedEn ? t('manage.faq.columns.statuses.published') : t('manage.faq.columns.statuses.draft')
            }`}</span>
          </span>
          <span>
            FR:
            <span className={css[cell.isPublishedFr ? 'published-status' : 'draft-status']}>{` ${
              cell.isPublishedFr ? t('manage.faq.columns.statuses.published') : t('manage.faq.columns.statuses.draft')
            }`}</span>
          </span>
        </div>
      );
    },
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.action')}</span>,
    key: 'action',
    width: 50,
    fixed: 'right' as any,
    render: (cell: QuestionModel) => {
      const menu = (
        <Menu className="update-menu-container" style={{ width: '130px' }}>
          <Menu.Item key="edit">
            <NavLink to={`/manage_hiw/modify/${cell.id}`} className={css['update-menu-item']}>
              <Button
                style={{
                  border: 'none',
                  backgroundColor: 'inherit',
                  boxShadow: 'none',
                  padding: 0,
                  marginRight: '0.5rem',
                }}>
                <img src={editIcon} alt="" style={{ height: '15px', width: '15px' }} />
              </Button>
              <span style={{ color: '#00122d', fontWeight: 600 }}>{t('budget.table.edit')}</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="delete">
            <div onClick={() => setOpenDelete(cell.id)} className={css['update-menu-item']}>
              <Button
                style={{
                  border: 'none',
                  backgroundColor: 'inherit',
                  boxShadow: 'none',
                  padding: 0,
                  marginRight: '0.5rem',
                }}>
                <img src={deleteIcon} alt="" style={{ height: '15px', width: '15px' }} />
              </Button>
              <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.title.delete')}</span>
            </div>
          </Menu.Item>
        </Menu>
      );
      return (
        <Dropdown overlay={menu} trigger={['click']}>
          <Button className={css['update-dropdown-btn']}>
            <img src={moreIcon} alt="" height={15} width={15} />
          </Button>
        </Dropdown>
      );
    },
  },
];
