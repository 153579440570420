import React from 'react';
import css from '../../assets/styles/budget.module.css';
import FinalAccountListInfo from './components/FinalAccountListInfo';

interface IFinalAccountPage {
  propertyId: number;
  setIsOpenUploadFinalAccount: (b: boolean) => void;
  setIsCreatedFinalAccount: (b: boolean) => void;
  isOpenStatusFinalAccountForm: boolean;
  isCreatedFinalAccount: boolean;
  setIsOpenStatusFinalAccountForm: (b: boolean) => void;
  setCurrentFinalAccount: (v: number | null) => void;
  currentFinalAccount: number | null;
}

const FinalAccountPage = ({
  propertyId,
  setIsOpenUploadFinalAccount,
  setIsCreatedFinalAccount,
  isOpenStatusFinalAccountForm,
  isCreatedFinalAccount,
  setIsOpenStatusFinalAccountForm,
  setCurrentFinalAccount,
  currentFinalAccount,
}: IFinalAccountPage) => {
  return (
    <div className={css['container']}>
      <FinalAccountListInfo
        propertyId={propertyId}
        setIsOpenUploadFinalAccount={setIsOpenUploadFinalAccount}
        setIsCreatedFinalAccount={setIsCreatedFinalAccount}
        isOpenStatusFinalAccountForm={isOpenStatusFinalAccountForm}
        isCreatedFinalAccount={isCreatedFinalAccount}
        setIsOpenStatusFinalAccountForm={setIsOpenStatusFinalAccountForm}
        setCurrentFinalAccount={setCurrentFinalAccount}
        currentFinalAccount={currentFinalAccount}
      />
    </div>
  );
};

export default FinalAccountPage;
