import { Alert, Popover, Space, Typography } from 'antd';
import React from 'react';
import { convertToLocalDate, convertToLocalDateTime } from '../../../helpers/converToLocalDate';
import downloadIcon from '../../../assets/images/ic-download-dark.svg';
import { IStatusModel } from '../../../models/StatusModel';
import { TFunction } from 'react-i18next';

interface ReportDataColumnsModel {
  t: TFunction<'translation'>;
  downloadFile: (reportName: string, id: number, fileName: string) => void;
  lang: string;
}

const labelKey = '_label';

const colors: any = {
  start: '#1374e6',
  inProgress: '#19ac87',
  processed: '#00d29f',
  rendering: '#19ac87',
  generated: '#21b793',
  error: '#e85e68',
};

const renderStatusCell = (status: IStatusModel, row: any) => {
  const { code, description } = status;
  if (code === 'error') {
    const content = (
      <Alert
        message="Error"
        description={row?.error?.message}
        type="error"
        showIcon
        style={{ maxWidth: '500px', maxHeight: '200px', overflow: 'auto' }}
      />
    );
    return (
      <Popover content={content} trigger="hover">
        <span style={{ color: colors[code] || '#eb7d5a', fontSize: '14px' }}>{description.toLowerCase()}</span>
      </Popover>
    );
  }
  return <span style={{ color: colors[code] || '#eb7d5a', fontSize: '14px' }}>{description.toLowerCase()}</span>;
};

const renderParamValue = (value: any, param: any, lang: string, label: string | undefined | null) => {
  switch (param && param.type) {
    case 'api': {
      const paramValue = label || `${param.key} = ${value}`;
      return `${param[`title_${lang}`]}: ${paramValue}`;
    }
    case 'date_start':
    case 'date_end': {
      return `${param[`title_${lang}`]}: ${convertToLocalDate(value)}`;
    }
    default: {
      return `${param[`title_${lang}`]}: ${value}`;
    }
  }
};

const renderParametersCell = (values: any, row: any, lang: string) => {
  const { report: { params = [] } = {} } = row;
  const keys = Object.keys(values || {}).filter((item: string) => !item.includes('_label'));
  if (keys && keys.length > 0) {
    return (
      <Space direction="vertical">
        {keys.map((item: string, index: number) => {
          const param = params.find((p: any) => p.name === item);
          return (
            <Typography.Text code key={index}>
              {renderParamValue(values[item], param, lang, values[`${item}${labelKey}`])}
            </Typography.Text>
          );
        })}
      </Space>
    );
  }
  return null;
};

export const getColumns = ({ t, downloadFile, lang }: ReportDataColumnsModel) =>
  [
    {
      title: () => <span className="budget-table-tr">#</span>,
      dataIndex: ['id'],
      key: 'id',
      width: 60,
    },
    {
      title: () => <span className="budget-table-tr">{t('report.table.columns.start')}</span>,
      dataIndex: ['startDate'],
      key: 'startDate',
      width: 150,
      render: (cell: any) => (cell ? convertToLocalDateTime(cell) : ''),
    },
    {
      title: <span className="budget-table-tr">{t('report.table.columns.end')}</span>,
      dataIndex: ['changedStatusDate'],
      key: 'changedStatusDate',
      width: 150,
      render: (cell: any, row: any) => (cell && row.status?.code === 'generated' ? convertToLocalDateTime(cell) : ''),
    },
    {
      title: <span className="budget-table-tr">{t('report.table.columns.status')}</span>,
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      render: (cell: any, row: any) => renderStatusCell(cell, row),
    },
    {
      title: <span className="budget-table-tr">{t('report.table.columns.file')}</span>,
      dataIndex: 'fileName',
      key: 'fileName',
      width: '20%',
    },
    {
      title: <span className="budget-table-tr">{t('report.table.columns.params')}</span>,
      dataIndex: 'parameters',
      key: 'parameters',
      render: (cell: any, row: any) => renderParametersCell(cell, row, lang),
    },
    {
      title: <span className="budget-table-tr">{t('report.table.columns.action')}</span>,
      key: 'action',
      width: 150,
      render: (cell: any, row: any) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {row.status?.code === 'generated' && (
              <div
                onClick={() => downloadFile(cell.report.name, cell.id, cell.fileName)}
                className="download-budget-item common-unarchive-btn">
                <img src={downloadIcon} alt="" style={{ height: '15px', width: '15px', marginRight: '0.5rem' }} />
                <span style={{ color: '#00122d', fontWeight: 600 }}>{t('budget.table.download')}</span>
              </div>
            )}
          </div>
        );
      },
    },
  ] as any[];
