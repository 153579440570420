const CATEGORY_SET_CURRENT_NODE = 'CATEGORY_SET_CURRENT_NODE';
const CATEGORY_SET_IS_SELECTED_NODE = 'CATEGORY_SET_IS_SELECTED_NODE';
const CATEGORY_SET_SELECTED_NODES = 'CATEGORY_SET_SELECTED_NODES';
const CATEGORY_SET_ITEMS = 'CATEGORY_SET_ITEMS';
const CATEGORY_SET_TYPES = 'CATEGORY_SET_TYPES';
const CATEGORY_REFRESH_ITEMS = 'CATEGORY_REFRESH_ITEMS';
const CATEGORY_SET_PARENT = 'CATEGORY_SET_PARENT';

export {
  CATEGORY_SET_CURRENT_NODE,
  CATEGORY_SET_IS_SELECTED_NODE,
  CATEGORY_SET_SELECTED_NODES,
  CATEGORY_SET_ITEMS,
  CATEGORY_SET_TYPES,
  CATEGORY_REFRESH_ITEMS,
  CATEGORY_SET_PARENT,
};
