import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Form, Input, Button, DatePicker, Row, Col, Select, Upload, notification, Spin, Card } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../contexts/AppContextProvider';
import { disabledDate } from '../../helpers/dateTools';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import TicketsService from '../../services/TicketsService';
import UsersService from '../../services/UsersService';
import ConsumersService from '../../services/ConsumersService';
import css from '../../assets/styles/tickets.module.css';
import attachIcon from '../../assets/images/ic-attach.svg';
import { generateSorter } from '../../helpers/generateSorter';
import { FilterTypes } from '../../constants/filterTypes';
import { generateFilterfromObj } from '../../helpers/generateFIlterfromObj';
import { IAppContext } from '../../typings/IApp';

const ticketsService: TicketsService = new TicketsService();
const consumersService: ConsumersService = new ConsumersService();
const usersService: UsersService = new UsersService();

const TicketNewPage: React.FC = (props: any) => {
  const { t } = useTranslation();
  const {
    app: { user, currentProperty, currentProject },
  } = useContext<IAppContext>(AppContext);
  const history = useHistory();
  const isConsumer = !!user?.rolesParams?.IS_CONSUMER;

  const [form] = Form.useForm();

  const [isDisabledSave, setIsDisabledSave] = useState(false);
  const [consumers, setConsumers] = useState<any[]>([]);
  const [priorities, setPriorities] = useState<any[]>([]);
  const [properties, setProperties] = useState([]);
  const [projects, setProjects] = useState<any[]>([]);
  const [initConsumer, setInitConsumer] = useState<any>({
    broker: undefined,
  });

  const [searchValue, setSearchValue] = useState<null | string>(null);
  const [currentTimeout, setCurrentTimeout] = useState<any>(null);
  const [isLastUserReq, setIsLastUserReq] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [breadcrumbNameMap, setBreadcrumbNameMap] = useState<{ [name: string]: string }>({});
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fileList, setFileList] = useState([] as any[]);
  const getPriorities = () => {
    ticketsService.getPriorities().then((res: any) => {
      setPriorities(res);
    });
  };

  const getConsumers = () => {
    const optionUser: any = {
      count: true,
      top: pagination.pageSize,
      current: pagination.current,
      orderBy: generateSorter(['firstName asc', 'lastName asc']).orderBy,
      skip: pagination.pageSize * (pagination.current - 1) || 0,
    };

    setFetchingData(true);

    // Read all consumers by the broker's projects
    if (!user.projects.length) return;
    (optionUser['filter'] = [
      {
        or: user.projects.map((project: any) => ({ 'projects/id': project.id })),
      },
      ...generateFilterfromObj({
        searchByName: {
          type: FilterTypes.MULTISEARCH,
          value: [{ firstName: searchValue }, { lastName: searchValue }],
        },
      }).filter,
    ]),
      consumersService
        .getAllConsumers(optionUser)
        .then((res: any) => {
          const { count, items } = res;
          const { current, top } = optionUser;
          setPagination({ ...pagination, current: current + 1, total: count, pageSize: top });
          setIsLastUserReq(current * top >= count);

          const ids = Array.from(new Set([...consumers, ...items].map((customer: any) => customer.id)));
          const uniqCustomers = ids.map((id) => [...consumers, ...items].find((customer: any) => customer.id === id));
          setConsumers(uniqCustomers);
        })
        .finally(() => setFetchingData(false));
  };

  const getBroker = async () => {
    const defaultRecipient = await getDefaultRecipient();
    const broker = defaultRecipient || currentProperty?.agent?.id;
    if (!broker) {
      notification.error({ message: t('tickets.recipient.error') });
      setIsDisabledSave(true);
    }
    setInitConsumer({ broker, project: currentProject?.id, property: currentProperty?.id });
  };

  const getDefaultRecipient = async (): Promise<number | null> => {
    const setting = (currentProject?.settings as any[]).find((item) => item.setting.code === 'defaultTicketRecipient');
    let recipient: number | null = null;
    if (setting && setting.value) {
      let res = await usersService.getUserByEmail(setting.value);
      recipient = res.length > 0 && res[0]?.id ? (res[0]?.id as number) : null;
    }
    return recipient;
  };

  useEffect(() => {
    if (isConsumer) {
      getBroker();
    } else {
      getConsumers();
    }

    getPriorities();
  }, [currentProperty]);

  useEffect(() => {
    if (searchValue === '' || searchValue) {
      getConsumers();
    }
  }, [searchValue]);

  useEffect(() => {
    setBreadcrumbNameMap(
      history.location.pathname
        .replace('/tickets', '')
        .replace(/\/modify\/\d+/, '/Modifications')
        .replace(/\/modify/, `/${t('common.title.create')}`)
        .replace('/new', `/${t('quotes.status.new')}`)
        .split('/')
        .slice(1)
        .reduce(
          (res: any, curr: any, index: number) => ({
            ...res,
            [[Object.keys(res)[index], curr].join('/')]: curr.split(':').shift(),
          }),
          { '/tickets': t('header.title.Tickets') },
        ),
    );
  }, [history.location.pathname]);

  const onCancelOrSuccess = (isShowMessage: boolean = false) => {
    if (isShowMessage) {
      notification.success({
        message: t('tickets.create.success.notify'),
      });
    }
    history.push('/tickets');
  };

  const onSubmit = (values: any) => {
    const body = {
      subject: values.subject,
      dueDate: values.date.format('YYYY-MM-DD'),
      recipient: isConsumer ? initConsumer.broker : values.user,
      priority: {
        code: values.priority,
      },
      project: isConsumer ? initConsumer.project : values.project,
      property: isConsumer ? initConsumer.property : values.property,
    } as any;

    const filesData = new FormData();
    for (const file of fileList) {
      if (file.originFileObj) {
        filesData.append('file', file.originFileObj);
      }
    }
    setIsSaving(true);

    ticketsService
      .createTicket(body)
      .then((res: any) => {
        ticketsService
          .addMessageByTicketId(res.id, { message: values.message || 'Message...' })
          .then((resMessage: any) => {
            if (filesData.getAll('file').length > 0) {
              ticketsService
                .addFileByMessageId(resMessage.id, filesData)
                .then(() => {
                  onCancelOrSuccess(true);
                })
                .catch((e: any) => {
                  console.log(e);
                  setIsSaving(false);
                });
            } else {
              onCancelOrSuccess(true);
            }
          })
          .catch((e) => {
            notification.error({
              message: e.message,
            });
            setIsSaving(false);
          });
      })
      .catch((e) => {
        notification.error({
          message: e.message,
        });
        setIsSaving(false);
      });

    return;
  };

  const handleFile = (info: any) => {
    setFileList(info.fileList);
  };
  //NOTE: uncommit if that will be needed
  // console.log('form.getFieldValue', form.getFieldValue('priority'), form.getFieldValue('user'));

  return (
    <Spin spinning={isSaving}>
      <Form autoComplete="off" form={form} layout="vertical" name="modify_users" onFinish={onSubmit}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <HeaderTable breadcrumb={breadcrumbNameMap} />
          <Form.Item style={{ display: 'flex', alignItems: 'center', marginBottom: 0 }}>
            <Button
              onClick={() => onCancelOrSuccess(false)}
              className={`common-blue-border-btn common-secondary-btn`}
              style={{ height: '42px', marginRight: '1rem' }}>
              {t('common.btn.cancel')}
            </Button>
            <Button
              htmlType="submit"
              className={`common-blue-btn common-primary-btn`}
              style={{ height: '42px' }}
              disabled={isDisabledSave}>
              {t('common.btn.save')}
            </Button>
          </Form.Item>
        </div>

        <Card loading={isLoading} className={css['tickets-card']}>
          <Row gutter={[24, 4]} style={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}>
            <div style={{ width: '90%', flex: '0 0 90%', marginBottom: '1rem' }}>
              <Row gutter={[48, 4]}>
                <Col xs={24} lg={10} xl={6}>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label={<span style={{ color: '#778dac' }}>{t('tickets.create.user')}</span>}
                        rules={
                          isConsumer
                            ? [{ required: false }]
                            : [{ required: true, message: t('common.validation.required') }]
                        }
                        name="user"
                        hidden={isConsumer}>
                        <Select
                          onChange={(value: number) => {
                            const fieldValue = form.getFieldsValue();
                            const found = consumers.find((consumer) => consumer.id === value);

                            setProjects(found.projects);

                            const project = found.projects.length === 1 ? found.projects[0].id : undefined;
                            const property =
                              found.projects.length === 1 && found.properties.length === 1
                                ? found.properties[0].id
                                : undefined;
                            let selectedProperty = [];

                            if (project) selectedProperty = found.properties;

                            setProperties(selectedProperty);
                            form.setFieldsValue({ ...fieldValue, project, property });
                          }}
                          placeholder={t('tickets.create.placeholder.acquirer')}
                          className="consumer-form-input common-animation-primary"
                          onPopupScroll={(e: any) => {
                            e.persist();
                            let target = e.target;
                            if (
                              !fetchingData &&
                              !isLastUserReq &&
                              target.scrollTop + target.offsetHeight === target.scrollHeight
                            ) {
                              getConsumers();
                            }
                          }}
                          onDropdownVisibleChange={(value) => {
                            if (!value && searchValue) {
                              setConsumers([]);
                              setPagination({ ...pagination, current: 1, skip: 0 });
                              setSearchValue('');
                            }
                          }}
                          onSearch={(value) => {
                            setConsumers([]);
                            setPagination({ ...pagination, current: 1, skip: 0 });
                            clearTimeout(currentTimeout);
                            setCurrentTimeout(setTimeout(() => setSearchValue(value), 1000));
                          }}
                          dropdownClassName={` ${fetchingData ? 'consumers-control-select-dropdown--progress' : ''}`}
                          showSearch
                          suffixIcon={
                            <div
                              className={`arrow-drop-img ${css['arrow-drop']}`}
                              style={{ width: '8px', height: '4px' }}></div>
                          }
                          filterOption={(input: any, option: any) => true}>
                          {consumers.map((consumer: any) => {
                            return (
                              <Select.Option
                                value={consumer.id}
                                key={consumer.id}
                                title={`${consumer.firstName} ${consumer.lastName} ${consumer.email}`}
                                disabled={form && form.getFieldValue('user') === consumer.id}>
                                <>
                                  {`${consumer.firstName} ${consumer.lastName}`}
                                  <span style={{ fontSize: 12 }}> ({`${consumer.email}`})</span>
                                </>
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[24, 4]}>
                    <Col span={12}>
                      <Form.Item
                        label={<span style={{ color: '#778dac' }}>{t('tickets.create.project')}</span>}
                        rules={
                          isConsumer
                            ? [{ required: false }]
                            : [{ required: true, message: t('common.validation.required') }]
                        }
                        name="project"
                        hidden={isConsumer}>
                        <Select
                          onChange={(value: number) => {
                            const fieldValue = form.getFieldsValue();
                            const consumer = consumers.find((consumer) => consumer.id === fieldValue.user);
                            const found = consumer.properties.filter((property: any) => property.project.id === value);
                            setProperties(found);

                            const property = found.length === 1 ? found[0].id : undefined;
                            form.setFieldsValue({ ...fieldValue, property });
                          }}
                          suffixIcon={
                            <div
                              className={`arrow-drop-img ${css['arrow-drop']}`}
                              style={{ width: '8px', height: '4px' }}></div>
                          }
                          placeholder={t('tickets.create.placeholder.project')}
                          disabled={!projects.length}
                          className="consumer-form-input common-animation-primary">
                          {projects.map((project: any) => {
                            return (
                              <Select.Option
                                value={project.id}
                                key={project.id}
                                title={project.title}
                                disabled={form && form.getFieldValue('project') === project.id}>
                                {project.title}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        shouldUpdate
                        name="property"
                        label={<span style={{ color: '#778dac' }}>{t('tickets.create.property')}</span>}
                        rules={
                          isConsumer
                            ? [{ required: false }]
                            : [{ required: true, message: t('common.validation.required') }]
                        }
                        hidden={isConsumer}>
                        <Select
                          placeholder={t('tickets.create.placeholder.property')}
                          disabled={!properties.length}
                          className="consumer-form-input common-animation-primary"
                          suffixIcon={
                            <div
                              className={`arrow-drop-img ${css['arrow-drop']}`}
                              style={{ width: '8px', height: '4px' }}></div>
                          }>
                          {properties.map((property: any) => {
                            return (
                              <Select.Option
                                value={property.id}
                                key={property.id}
                                title={property.title}
                                disabled={form && form.getFieldValue('property') === property.id}>
                                {property.title}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        shouldUpdate
                        name="priority"
                        label={<span style={{ color: '#778dac' }}>{t('tickets.create.priority')}</span>}
                        rules={[{ required: true, message: t('common.validation.required') }]}>
                        <Select
                          placeholder={t('tickets.create.placeholder.priority')}
                          className="consumer-form-input common-animation-primary"
                          suffixIcon={
                            <div
                              className={`arrow-drop-img ${css['arrow-drop']}`}
                              style={{ width: '8px', height: '4px' }}></div>
                          }>
                          {priorities.map((priority: any) => {
                            return (
                              <Select.Option
                                value={priority.code}
                                key={priority.code}
                                title={priority.description}
                                disabled={form && form.getFieldValue('priority') === priority.code}>
                                {priority.description}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label={<span style={{ color: '#778dac' }}>{t('tickets.create.date')}</span>}
                        rules={[{ required: true, message: t('common.validation.required') }]}
                        name="date">
                        <DatePicker
                          inputReadOnly
                          className="consumer-form-input common-animation-primary"
                          suffixIcon={<div className={'calendar-img'} style={{ width: '12px', height: '12px' }}></div>}
                          allowClear={false}
                          format={'DD.MM.YYYY'}
                          disabledDate={disabledDate}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} lg={14} xl={18}>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label={<span style={{ color: '#778dac' }}>{t('tickets.create.subject')}</span>}
                        name="subject"
                        rules={[{ required: true, message: t('common.validation.required') }]}>
                        <Input className="consumer-form-input common-animation-primary" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label={<span style={{ color: '#778dac' }}>{t('tickets.create.message')}</span>}
                        rules={[{ required: true, message: t('common.validation.required') }]}
                        colon={false}
                        name="common">
                        <div
                          className="ticket-new-textarea common-animation-primary"
                          style={{
                            borderRadius: '6px',
                            padding: '1rem',
                            backgroundColor: 'white',
                          }}>
                          <Form.Item name="message">
                            <TextArea
                              bordered={false}
                              className={`${css['updates-input']}`}
                              autoSize={{ minRows: 6, maxRows: 10 }}
                              placeholder={t('tickets.create.placeholder.message')}
                              style={{ backgroundColor: 'white' }}
                            />
                          </Form.Item>
                          <Upload
                            name="upload"
                            className={'upload-updates'}
                            fileList={fileList}
                            beforeUpload={() => false}
                            onChange={handleFile}
                            multiple
                            listType="text"
                            iconRender={() => <img src={attachIcon} style={{ height: '15px', width: '15px' }} />}>
                            <Button className="common-upload-file-btn">
                              <img src={attachIcon} alt="" className={css['ic-attach']} />
                              <span className={css['attach-file-title']}>{t('tickets.attach.file')}</span>
                            </Button>
                          </Upload>
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Row>
        </Card>
      </Form>
    </Spin>
  );
};

export default TicketNewPage;
