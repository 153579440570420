import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Row, Typography, Space, Popconfirm, Button, Tree, notification, Badge } from 'antd';
import { DeleteOutlined, FileAddOutlined, ReloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import { CategoriesContext } from '../../contexts/CategoriesContextProvider';
import ArticlesService from '../../services/ArticlesService';
import {
  CATEGORY_SET_CURRENT_NODE,
  CATEGORY_SET_IS_SELECTED_NODE,
  CATEGORY_SET_ITEMS,
  CATEGORY_SET_SELECTED_NODES,
  CATEGORY_REFRESH_ITEMS,
  CATEGORY_SET_PARENT,
  CATEGORY_SET_TYPES,
} from '../../constants/actionTypes/categoriesConstants';
import CategoriesModify from './components/CategoriesModify';
import { HeaderContext } from '../../contexts/HeaderContextProvider';
import { CHANGE_CURRENT_PAGE } from '../../constants/actionTypes/headerConstants';
import { useLessThen801 } from '../../helpers/mediaDetect';
import i18n from '../../utils/i18n';

const service: ArticlesService = new ArticlesService();

const CategoriesPage: React.FC = (props: any) => {
  const { t } = useTranslation();
  const { height: windowHeight } = useWindowDimensions();
  const {
    nodes: { items, selectedNodes, ...nodes },
    nodesDispatch,
  } = useContext(CategoriesContext);
  const [tree, setTree] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { headerDispatch } = useContext(HeaderContext);
  useEffect(() => {
    headerDispatch({
      type: CHANGE_CURRENT_PAGE,
      currentPage: t('header.title.Categories'),
      path: 'categories',
    });
  }, [i18n.language]);

  const setItems = (items: any) => {
    nodesDispatch({
      type: CATEGORY_SET_ITEMS,
      items,
    });
  };

  const setTypes = (types: any) => {
    nodesDispatch({
      type: CATEGORY_SET_TYPES,
      types,
    });
  };

  const setIsRefresh = (isRefresh: boolean) => {
    nodesDispatch({
      type: CATEGORY_REFRESH_ITEMS,
      isRefresh,
    });
  };

  const setIsSelectedNode = (value: boolean) => {
    nodesDispatch({
      type: CATEGORY_SET_IS_SELECTED_NODE,
      isSelectedNode: value,
    });
  };

  const setCurrentNode = (node: any, key: string | null) => {
    nodesDispatch({
      type: CATEGORY_SET_CURRENT_NODE,
      currentNode: node,
      currentNodeKey: key,
    });
  };

  const setSelectedNodes = (selectedNodes: any[]) => {
    nodesDispatch({
      type: CATEGORY_SET_SELECTED_NODES,
      selectedNodes,
    });
  };

  const setParent = (parent: any) => {
    nodesDispatch({
      type: CATEGORY_SET_PARENT,
      parent,
    });
  };

  const mapChildNodes = (node: any) => {
    node.key = `${node.id}`;
    if (node?.children?.length > 0) {
      node.children = node.children?.sort((a: any, b: any) => a.id - b.id).map((item: any) => mapChildNodes(item));
    }
    return node;
  };

  const getCategories = () => {
    service.getArticleCategories().then((res: any) => {
      const data = res
        .sort((a: any, b: any) => a.id - b.id)
        .map((category: any) => {
          return mapChildNodes(category);
        });
      setItems(data);
    });
  };

  const getCategoryTypes = () => {
    service.getArticleCategoryTypes().then((res: any) => {
      setTypes(res);
    });
  };

  const getAsyncData = async () => {
    const actions = [getCategories(), getCategoryTypes()];
    Promise.all(actions).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (nodes.isRefresh) {
      getCategories();
      setIsRefresh(false);
    }
  }, [nodes.isRefresh]);

  useEffect(() => {
    if (items.length > 0 && nodes.types.length > 0) {
      const result: any[] = [];
      nodes.types.forEach((type: any) => {
        const children = items.filter((category: any) => category.type.name === type.name);
        result.push({
          ...type,
          key: `type-${type.id}`,
          selectable: false,
          children,
        });
      });
      setTree(result);
    }
  }, [nodes.types, items]);

  useEffect(() => {
    getAsyncData();
    setIsSelectedNode(false);
    setCurrentNode(null, null);

    return () => {
      setItems([]);
    };
  }, []);

  const parseTreeTitle = (node: any) => {
    return (
      <Space>
        {node.deletedDate ? <Badge color="red" title="Deleted" text={node.name} /> : node.description || node.name}
        {node?.children?.length > 0 && (
          <Typography.Title level={5} disabled style={{ fontSize: '12px' }}>
            (&darr; {node.children.length} sub)
          </Typography.Title>
        )}
      </Space>
    );
  };

  const onSelectNode = (selectedKeys: any, { selected, node }: any) => {
    setIsSelectedNode(false);
    setTimeout(() => {
      setSelectedNodes(selectedKeys);
      if (selected) {
        const [selectedKey] = selectedKeys;
        const key = selectedKey.split('-')[0];
        setCurrentNode(node, key);
      } else {
        setCurrentNode(null, null);
      }
      setIsSelectedNode(selected);
    }, 0);
  };

  const handleCreate = () => {
    setParent(nodes.currentNode);
    setIsSelectedNode(true);
    setCurrentNode(null, null);
  };

  const handleDelete = async () => {
    const { currentNode } = nodes;
    if (currentNode?.children && currentNode?.children?.length > 0) {
      notification.error({
        message: t('category.not.delete.message'),
      });
      return;
    }
    service.deleteCategory(currentNode.id).then(() => {
      getCategories();
      setParent(null);
      setIsSelectedNode(false);
      setCurrentNode(null, null);
    });
  };

  if (useLessThen801()) {
    return (
      <>
        <HeaderTable title={t('category.title')}></HeaderTable>
        <Row gutter={[24, 4]}>
          <Col span={24}>
            <Card
              style={{ padding: '1rem' }}
              title={
                <div className="modify-title-header">
                  <Typography.Text>{t('common.title.tree')}</Typography.Text>
                  <div className="modify-action-btn">
                    <Space size="small">
                      <Popconfirm
                        placement="topLeft"
                        title={t('common.delete.confirm.message')}
                        onConfirm={handleDelete}
                        okText={t('common.confirm.btn.yes')}
                        cancelText={t('common.confirm.btn.cancel')}>
                        <Button size="small" disabled={!nodes?.currentNode}>
                          <DeleteOutlined />
                        </Button>
                      </Popconfirm>
                      <Button size="small" onClick={handleCreate}>
                        <FileAddOutlined />
                      </Button>
                      <Button size="small" onClick={getCategories}>
                        <ReloadOutlined />
                      </Button>
                    </Space>
                  </div>
                </div>
              }>
              <Tree
                showLine
                treeData={tree}
                titleRender={parseTreeTitle}
                onSelect={onSelectNode}
                virtual={true}
                selectedKeys={selectedNodes}
              />
            </Card>
          </Col>
          {nodes.isSelectedNode && (
            <Col span={24}>
              <CategoriesModify />
            </Col>
          )}
        </Row>
      </>
    );
  }

  return (
    <>
      <HeaderTable title={t('category.title')}></HeaderTable>
      <Row gutter={[24, 4]}>
        <Col span={8}>
          <Card
            style={{ padding: '1rem' }}
            title={
              <div className="modify-title-header">
                <Typography.Text>{t('common.title.tree')}</Typography.Text>
                <div className="modify-action-btn">
                  <Space size="small">
                    <Popconfirm
                      placement="topLeft"
                      title={t('common.delete.confirm.message')}
                      onConfirm={handleDelete}
                      okText={t('common.confirm.btn.yes')}
                      cancelText={t('common.confirm.btn.cancel')}>
                      <Button size="small" disabled={!nodes?.currentNode}>
                        <DeleteOutlined />
                      </Button>
                    </Popconfirm>
                    <Button size="small" onClick={handleCreate}>
                      <FileAddOutlined />
                    </Button>
                    <Button size="small" onClick={getCategories}>
                      <ReloadOutlined />
                    </Button>
                  </Space>
                </div>
              </div>
            }>
            <Tree
              showLine
              treeData={tree}
              titleRender={parseTreeTitle}
              onSelect={onSelectNode}
              virtual={true}
              style={{ height: windowHeight - 407 }}
              height={windowHeight - 407}
              selectedKeys={selectedNodes}
            />
          </Card>
        </Col>
        {nodes.isSelectedNode && (
          <Col span={16}>
            <CategoriesModify />
          </Col>
        )}
      </Row>
    </>
  );
};

export default CategoriesPage;
