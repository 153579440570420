import React from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { getColumns } from './VariantsColumns';

interface VariantsModel {
  items: any[];
  typologyId: number;
  levelId: number;
}

const Variants: React.FC<VariantsModel> = ({ items, typologyId, levelId }: VariantsModel) => {
  const { t } = useTranslation();
  const history = useHistory();

  const onDoubleClick = (id: number) => {
    history.push(`/typologies/${typologyId}/levels/${levelId}/variants/modify/${id}`);
  };

  return (
    <Table
      columns={getColumns({ t, typologyId, levelId })}
      dataSource={items}
      showHeader={true}
      size="small"
      rowKey="id"
      rowClassName="common-table-row--pointer"
      onRow={(record: any) => ({
        onDoubleClick: () => onDoubleClick(record.id),
      })}
    />
  );
};

export default Variants;
