import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { Form, Descriptions, Col, Input, Row, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import css from './../../../assets/styles/params.module.css';

interface CoordinatesProps {
  name: string;
  values: any;
}

const Coordinates = (props: CoordinatesProps) => {
  const { t } = useTranslation();
  const [values, setValues] = useState(props.values);

  useEffect(() => {
    setValues(props.values);
  }, [props.values]);

  const addCoordinate = () => {
    setValues({ ...values, [uuidv4()]: { type: null, coordinates: null } });
  };

  const deleteCoordinate = (code: string) => {
    const toUpdate = { ...values };
    delete toUpdate[code];
    setValues(toUpdate);
  };

  return (
    <>
      <Row justify="end" style={{ marginBottom: '8px' }}>
        <Button onClick={addCoordinate} className={css['add-coordinate']} style={{ width: 'auto' }}>
          {t('properties.create.items.addContactDetails')}
        </Button>
      </Row>
      {!!Object.keys(values).length && (
        <Descriptions bordered column={1} size="small" className="parameter-descriptions-coordinates">
          {Object.entries(values).map(([code, item]: [any, any]) => (
            <Descriptions.Item
              key={['descriptions', code].join('')}
              label={
                <Form.Item
                  name={[props.name, code, 'type']}
                  label={
                    <span style={{ color: '#778dac', fontWeight: 600 }}>
                      {t('properties.create.items.addContactDetails.cut')}
                    </span>
                  }>
                  <Input className="faq-modify-select common-animation-primary" />
                </Form.Item>
              }>
              <Row justify="space-between" align="middle">
                <Col span={22}>
                  <Form.Item
                    name={[props.name, code, 'coordinates']}
                    label={
                      <span style={{ color: '#778dac', fontWeight: 600 }}>
                        {t('properties.create.items.addContactDetails.information')}
                      </span>
                    }
                    rules={[
                      {
                        pattern: /^\d+(,\d+)*$/,
                        message: t('properties.create.items.addContactDetails.validation'),
                      },
                    ]}>
                    <Input className="faq-modify-select common-animation-primary" />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Row justify="end">
                    <CloseOutlined
                      className="common-btn-icon"
                      style={{ cursor: 'pointer' }}
                      onClick={() => deleteCoordinate(code)}
                    />
                  </Row>
                </Col>
              </Row>
            </Descriptions.Item>
          ))}
        </Descriptions>
      )}
    </>
  );
};

export default Coordinates;
