import HttpService from './common/httpService';
import config from '../config';
import buildQuery from 'odata-query';
import { ICreateFinalAccount, IUpdateFinalAccount } from '../models/FinalAccountModel';

class FinalAccountService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getFinalAccounts(propertyId: number | null, option: any) {
    const { top, count, skip, filters, orderBy = null } = option;

    let filter: any = [];

    let defaultFilter: any = {
      'property/id': {
        eq: propertyId,
      },
      isArchive: {
        eq: false,
      },
    };

    if (!propertyId) {
      defaultFilter = {
        isArchive: {
          eq: false,
        },
      };
    }

    if (filters) {
      filters.filter.sort((a: any, b: any) => {
        const check = (item: any) => {
          if (
            Object.values(item).length > 0 &&
            (Object.values(item)[0] as any).length > 0 &&
            Object.values(Object.values(item)[0] as any).length > 0 &&
            Object.values((Object.values(item)[0] as any)[0] as any).length > 0
          ) {
            return Object.keys(Object.values((Object.values(item)[0] as any)[0] as any)[0] as any)[0] == 'contains';
          }
          return false;
        };

        return Object.keys(Object.values(a)[0] as any)[0] == 'contains' || check(a)
          ? -1
          : Object.keys(Object.values(b)[0] as any)[0] == 'contains'
          ? 1
          : 0;
      });
      filter = [...filters.filter, defaultFilter];

      filter.sort((a: any, b: any) => {
        const check = (item: any) => {
          if (
            Object.values(item).length > 0 &&
            (Object.values(item)[0] as any).length > 0 &&
            Object.values(Object.values(item)[0] as any).length > 0 &&
            Object.values((Object.values(item)[0] as any)[0] as any).length > 0
          ) {
            return Object.keys(Object.values((Object.values(item)[0] as any)[0] as any)[0] as any)[0] == 'contains';
          }
          return false;
        };

        return Object.keys(Object.values(a)[0] as any)[0] == 'contains' || check(a)
          ? -1
          : Object.keys(Object.values(b)[0] as any)[0] == 'contains'
          ? 1
          : 0;
      });
    } else {
      filter = defaultFilter;
    }
    const query = buildQuery({ filter, top, count, skip, orderBy });
    return this.httpService.get(`${config.baseRequest}finalAccounts${query}`);
  }
  public getArchiveFinalAccounts(propertyId: number | null, option: any) {
    const { top, count, skip, filters, orderBy = null } = option;

    let filter: any = [];

    let defaultFilter: any = {
      'property/id': {
        eq: propertyId,
      },
      isArchive: {
        eq: true,
      },
    };

    if (!propertyId) {
      defaultFilter = {
        isArchive: {
          eq: true,
        },
      };
    }

    if (filters) {
      filters.filter.sort((a: any, b: any) => {
        const check = (item: any) => {
          if (
            Object.values(item).length > 0 &&
            (Object.values(item)[0] as any).length > 0 &&
            Object.values(Object.values(item)[0] as any).length > 0 &&
            Object.values((Object.values(item)[0] as any)[0] as any).length > 0
          ) {
            return Object.keys(Object.values((Object.values(item)[0] as any)[0] as any)[0] as any)[0] == 'contains';
          }
          return false;
        };

        return Object.keys(Object.values(a)[0] as any)[0] == 'contains' || check(a)
          ? -1
          : Object.keys(Object.values(b)[0] as any)[0] == 'contains'
          ? 1
          : 0;
      });
      filter = [...filters.filter, defaultFilter];

      filter.sort((a: any, b: any) => {
        const check = (item: any) => {
          if (
            Object.values(item).length > 0 &&
            (Object.values(item)[0] as any).length > 0 &&
            Object.values(Object.values(item)[0] as any).length > 0 &&
            Object.values((Object.values(item)[0] as any)[0] as any).length > 0
          ) {
            return Object.keys(Object.values((Object.values(item)[0] as any)[0] as any)[0] as any)[0] == 'contains';
          }
          return false;
        };

        return Object.keys(Object.values(a)[0] as any)[0] == 'contains' || check(a)
          ? -1
          : Object.keys(Object.values(b)[0] as any)[0] == 'contains'
          ? 1
          : 0;
      });
    } else {
      filter = defaultFilter;
    }
    const query = buildQuery({ filter, top, count, skip, orderBy });
    return this.httpService.get(`${config.baseRequest}finalAccounts${query}`);
  }

  public getFinalAccountById(id: number) {
    return this.httpService.get(`${config.baseRequest}finalAccounts/${id}`);
  }
  public getFinalAccountHistory(id: number) {
    return this.httpService.get(`${config.baseRequest}finalAccounts/${id}/history`);
  }

  public createFinalAccount(body: ICreateFinalAccount) {
    return this.httpService.post(`${config.baseRequest}finalAccounts`, body);
  }
  public uploadFinalAccountFiles(id: number, body: any) {
    return this.httpService.postFile(`${config.baseRequest}finalAccounts/${id}/file`, body);
  }
  public getSigningUrl(id: number, returnUrl: string, consumerId?: number) {
    return this.httpService.post(`${config.baseRequest}finalAccounts/step/redirect`, { id, consumerId, returnUrl });
  }

  public updateFinalAccount(body: IUpdateFinalAccount) {
    return this.httpService.put(`${config.baseRequest}finalAccounts`, body);
  }

  public deleteFinalAccount(id: number) {
    return this.httpService.delete(`${config.baseRequest}finalAccounts/${id}`);
  }
  public deleteFinalAccountFile(id: number) {
    return this.httpService.delete(`${config.baseRequest}finalAccounts/${id}/file`);
  }

  public confirmStep(envelopeId?: string, id?: number) {
    return this.httpService.patch(`${config.baseRequest}finalAccounts/step/done`, envelopeId ? { envelopeId } : { id });
  }
  public rejectFinalAccount(id: number | null, comment: string, envelopeId?: string) {
    const body = id ? { id, comment } : { envelopeId, comment };
    return this.httpService.patch(`${config.baseRequest}finalAccounts/step/reject`, body);
  }

  public getFinalAccountFile = (fileName: string) => {
    return this.httpService.getFile(`${config.baseRequest}file/binary/${fileName}`).then((res: any) => {
      let file = new File([res], fileName);
      return file;
    });
  };
}

export default FinalAccountService;
