const UPDATE_USER = 'UPDATE_USER';
const DELETE_USER = 'DELETE_USER';
const SET_CHANGE_PASSWORD = 'SET_CHANGE_PASSWORD';
const DELETE_CHANGE_PASSWORD = 'DELETE_CHANGE_PASSWORD';

const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
const SET_NON_AUTHENTICATED = 'SET_NON_AUTHENTICATED';
const SET_PROFILE = 'SET_PROFILE';

const SET_TECH_TYPES = 'SET_TECH_TYPES';
const SET_IS_NON_LOGIN = 'SET_IS_NON_LOGIN';

const SET_CURRENT_PROJECT = 'SET_CURRENT_PROJECT';
const SET_CURRENT_PROPERTY = 'SET_CURRENT_PROPERTY';

const CHANGE_ONLY_MY_CONSUMERS = 'CHANGE_ONLY_MY_CONSUMERS';
const CHANGE_IS_NEED_SAVE_PROPERTIES = 'CHANGE_IS_NEED_SAVE_PROPERTIES';
const CHANGE_IS_NEED_OPEN_MODAL_SAVE = 'CHANGE_IS_NEED_OPEN_MODAL_SAVE';
const SET_LOGOUT_REF = 'SET_LOGOUT_REF';
const SET_IS_OPEN_HELPER = 'SET_IS_OPEN_HELPER';

export {
  UPDATE_USER,
  DELETE_USER,
  SET_CHANGE_PASSWORD,
  DELETE_CHANGE_PASSWORD,
  SET_AUTHENTICATED,
  SET_NON_AUTHENTICATED,
  SET_PROFILE,
  SET_TECH_TYPES,
  SET_IS_NON_LOGIN,
  SET_CURRENT_PROJECT,
  SET_CURRENT_PROPERTY,
  CHANGE_ONLY_MY_CONSUMERS,
  CHANGE_IS_NEED_SAVE_PROPERTIES,
  CHANGE_IS_NEED_OPEN_MODAL_SAVE,
  SET_LOGOUT_REF,
  SET_IS_OPEN_HELPER,
};
