import { Tag } from 'antd';
import React from 'react';
import { TFunction } from 'react-i18next';

const getStatusComponent = (status: string, label: string) => {
  switch (status.toLowerCase()) {
    case 'approved': {
      return (
        <Tag
          color="green"
          style={{ borderRadius: 20, color: '#008000', paddingLeft: '0.7rem', paddingRight: '0.7rem' }}>
          {label}
        </Tag>
      );
    }
    case 'draft': {
      return (
        <Tag style={{ borderRadius: 20, color: '#00122d', paddingLeft: '0.7rem', paddingRight: '0.7rem' }}>{label}</Tag>
      );
    }
    case 'rejected': {
      return (
        <Tag color="red" style={{ borderRadius: 20, color: '#cf1322', paddingLeft: '0.7rem', paddingRight: '0.7rem' }}>
          {label}
        </Tag>
      );
    }
    //pending
    default: {
      return (
        <Tag
          color="orange"
          style={{ borderRadius: 20, color: '#d46b08', paddingLeft: '0.7rem', paddingRight: '0.7rem' }}>
          {label}
        </Tag>
      );
    }
  }
};

const getProcoreStatusTag = (status: string, t: TFunction<'translation'>) => {
  const dictionary: { [status: string]: string } = {
    draft: t('procore.status.draft'),
    approved: t('procore.status.approved'),
    no_charge: t('procore.status.noCharge'),
    pending: t('procore.status.pending'),
    not_pricing: t('procore.status.notPricing'),
    not_proceeding: t('procore.status.notProceeding'),
    pricing: t('procore.status.pricing'),
    proceeding: t('procore.status.proceeding'),
    revised: t('procore.status.revised'),
    rejected: t('procore.status.rejected'),
    void: t('procore.status.void'),
  };
  return getStatusComponent(status, dictionary[status]);
};

export default getProcoreStatusTag;
