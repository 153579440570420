import React from 'react';
import { Badge, Button, Dropdown, Menu } from 'antd';
import css from '../../../assets/styles/product.module.css';
import moreIcon from '../../../assets/images/ic-more.svg';
import ArticleNameLayout from '../../../components/common/ArticleNameLayout';
import ProductNameLayout from '../../../components/common/ProductNameLayout';
import deleteIcon from '../../../assets/images/ic-delete-dark-gray.svg';
import editIcon from '../../../assets/images/ic-edit-dark.svg';
import { CopyOutlined } from '@ant-design/icons';
import { TFunction } from 'react-i18next';

interface ProductColumnsModel {
  onDelete?: (id: number) => void;
  onDuplicate?: (product: any) => void;
  history: any;
  t: TFunction<'translation'>;
}

export const getColumns = ({ onDelete, onDuplicate = () => {}, history, t }: ProductColumnsModel) => [
  {
    title: () => (
      <span className="budget-table-tr" style={{ marginLeft: '8px' }}>
        #
      </span>
    ),
    dataIndex: 'id',
    key: 'id',
    width: 40,
  },
  {
    title: () => <span className="budget-table-tr">{t('product.table.columns.article')}</span>,
    key: 'article-name',
    render: (cell: any) => <ArticleNameLayout article={cell?.article} />,
    width: 100,
  },
  {
    title: () => <span className="budget-table-tr">{t('product.table.columns.barcode')}</span>,
    key: 'code',
    render: (cell: any) => <ProductNameLayout product={cell} />,
    width: 300,
  },
  {
    title: () => <span className="budget-table-tr">{t('product.table.columns.price')}</span>,
    dataIndex: 'price',
    key: 'price',
    align: 'right',
    render: (cell: any) => (isNaN(cell) ? cell : Number(cell).toFixed(2)),
    width: 100,
  } as any,
  {
    title: () => <span className="budget-table-tr">{t('product.table.columns.isActive')}</span>,
    dataIndex: 'isActive',
    key: 'isActive',
    render: (cell: any) => {
      return cell ? (
        <Badge status="success" text={t('product.table.columns.isActive.active')} />
      ) : (
        <Badge status="error" text={t('product.table.columns.isActive.inactive')} />
      );
    },
    width: 100,
  },
  {
    title: () => <span className="budget-table-tr">{t('product.table.columns.action')}</span>,
    key: 'action',
    fixed: 'right' as any,
    with: 50,
    render: (cell: any) => {
      const menu = (
        <Menu className="update-menu-container" style={{ width: '130px' }}>
          <Menu.Item
            key="edit"
            className={css['update-menu-item']}
            onClick={() => history.push(`/products/modify/${cell.id}`)}>
            <Button
              type="link"
              style={{
                border: 'none',
                backgroundColor: 'inherit',
                boxShadow: 'none',
                padding: 0,
                marginRight: '0.5rem',
              }}>
              <img src={editIcon} alt="" style={{ height: '15px', width: '15px' }} />
            </Button>
            <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.btn.modify')}</span>
          </Menu.Item>
          <Menu.Item key="duplicate" className={css['update-menu-item']} onClick={() => onDuplicate(cell)}>
            <Button
              type="link"
              style={{
                border: 'none',
                backgroundColor: 'inherit',
                boxShadow: 'none',
                padding: 0,
                marginRight: '0.5rem',
              }}>
              <CopyOutlined style={{ color: '#000' }} />
            </Button>
            <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.btn.duplicate')}</span>
          </Menu.Item>
          {onDelete && (
            <Menu.Item key="block" className={css['update-menu-item']} onClick={() => onDelete(cell.id)}>
              <Button
                type="link"
                style={{
                  border: 'none',
                  backgroundColor: 'inherit',
                  boxShadow: 'none',
                  padding: 0,
                  marginRight: '0.5rem',
                }}>
                <img src={deleteIcon} alt="" height={15} width={15} />
              </Button>
              <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.title.delete')}</span>
            </Menu.Item>
          )}
        </Menu>
      );

      return (
        <span>
          <Dropdown overlay={menu} trigger={['click']}>
            <Button className={css['product-dropdown-btn']}>
              <img src={moreIcon} alt="" height={15} width={15} />
            </Button>
          </Dropdown>
        </span>
      );
    },
    width: '10%',
  },
];
