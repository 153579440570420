import { Button, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IModalProps {
  isOpen: boolean;
  onOk: () => void;
}

const ModalUploadFileSuccess = ({ isOpen, onOk }: IModalProps) => {
  const { t } = useTranslation();
  const [counter, setCounter] = useState(20);

  useEffect(() => {
    counter > 0 ? setTimeout(() => setCounter(counter - 1), 1000) : onOk();
  }, [counter]);

  return (
    <Modal
      className="common-modal"
      centered
      style={{ width: '10rem' }}
      visible={isOpen}
      footer={
        <div className="common-modal-footer">
          <Button className={`common-green-btn common-modal-btn`} onClick={onOk}>
            {t('common.modal.confirm.ok')}
          </Button>
        </div>
      }
      closable={false}>
      <p>{t('common.upload.file.message.success')}</p>
    </Modal>
  );
};

export default ModalUploadFileSuccess;
