import { io } from 'socket.io-client';
import config from '../config';
import { getStorageToken } from './storageTools';
import { notification } from 'antd';

interface IProps {
  eventName: string;
  callback: (res: any) => void;
  callbackError?: () => void;
  isNeedDebounce?: boolean;
  delay?: number;
  message?: string;
}

export const initSocket = ({
  eventName,
  callback,
  callbackError,
  isNeedDebounce = false,
  delay = 90000,
  message = '',
}: IProps) => {
  const socket = io(`${config.notificationSocket}`, { auth: { token: getStorageToken() } });

  const onError = (message: string) => {
    notification.error({ message });
    socket.disconnect();
    callbackError && callbackError();
  };

  let timer: NodeJS.Timeout;
  const stopDebounce = () => {
    clearTimeout(Number(timer));
  };

  const debounce = () => {
    clearTimeout(Number(timer));
    timer = setTimeout(() => {
      onError(message);
    }, delay);
  };

  socket?.on('connect', () => {
    isNeedDebounce && debounce();
    socket?.on(eventName, (res: any) => {
      stopDebounce();
      if (res.status === 500) {
        return onError(res.body.message);
      }
      callback(res);
      socket.disconnect();
    });
  });
};
