import { Card, Image } from 'antd';
import React from 'react';
import css from '../../../assets/styles/gallery.module.css';
import config from '../../../config';
import { IFile } from '../../../models/CalendarModel';

interface IImageCardProps {
  imageInfo: IFile;
}

const ImageCard = ({ imageInfo }: IImageCardProps) => {
  return (
    <Card hoverable className={`${css['gallery-card']} gallery-card`}>
      <Image className={css['gallery-card-img']} src={`${config.storeUrl}/${imageInfo.file}`} />
    </Card>
  );
};

export default ImageCard;
