import { Button, Col, DatePicker, Form, FormInstance, Input, Row, Select, TreeSelect } from 'antd';
import React, { useContext, useEffect } from 'react';
import css from '../../../../assets/styles/file-management.module.css';
import searchIcon from '../../../../assets/images/ic-search.svg';
import { setTextColorByStatus } from '../../../../helpers/setTextColorByStatus';
import { useTranslation } from 'react-i18next';
import { useLessThen801 } from '../../../../helpers/mediaDetect';
import { IStepType } from '../../../../models/CustomSignFlowModel';
import { StatusesDocumentsModel } from '../../../../models/DocumentsModel';
import { IAppContext } from '../../../../typings/IApp';
import { AppContext } from '../../../../contexts/AppContextProvider';
import { useEasyUsers } from '../../../../hooks/useEasyUsers';
import { PropertyModel } from '../../../../models/PropertyModel';
import useGetPageTypeConfig from '../../hooks/useGetPageTypeConfig';

const { Search } = Input;
const { Option, OptGroup } = Select;
const { RangePicker } = DatePicker;

interface IFileManagmentFilter {
  statuses: StatusesDocumentsModel;
  properties: PropertyModel[];
  resetFilters: (isNeedSubmit?: boolean) => void;
  openFilterBtnRef: any;
  brokerProjects: number[];
  searchAgents: number[];
  searchConsumers: number[];
  form: FormInstance;
  onValuesChange: (changedValues: any, values: any) => void;
  onSubmit: (values: any) => void;
  steps: IStepType[];
}

const FileManagmentFilter = ({
  statuses,
  properties,
  resetFilters,
  openFilterBtnRef,
  brokerProjects,
  searchAgents,
  searchConsumers,
  form,
  onValuesChange,
  onSubmit,
  steps,
}: IFileManagmentFilter) => {
  const {
    app: { user },
  } = useContext<IAppContext>(AppContext);
  const pageTypeConfig = useGetPageTypeConfig();
  const { t } = useTranslation();
  const {
    users: agents,
    isLastUsersReq: isLastAgentsReq,
    fetchingUserData: fetchingAgentsData,
    getUsers: getAgents,
  } = useEasyUsers(brokerProjects);
  const {
    users: consumers,
    isLastUsersReq: isLastConsumersReq,
    fetchingUserData: fetchingConsumersData,
    getUsers: getConsumers,
  } = useEasyUsers(brokerProjects, true);

  useEffect(() => {
    if (searchAgents.length > 0) {
      getAgents(searchAgents);
    }
  }, [searchAgents]);

  useEffect(() => {
    if (searchConsumers.length > 0) {
      getConsumers(searchConsumers);
    }
  }, [searchConsumers]);

  return (
    <Form autoComplete="off" form={form} onFinish={onSubmit} onValuesChange={onValuesChange}>
      <Row style={{ marginTop: '0.5rem' }} align="middle" gutter={12}>
        <Col span={8}>
          <span style={{ color: '#607B9E' }}>{t('consumers.table.columns.project')}:</span>
        </Col>
        <Col span={16}>
          <Form.Item name={'project'} style={{ marginBottom: 0 }} initialValue={''}>
            <Select
              className="common-150-select common-select common-animation-primary"
              dropdownClassName="documents-control-select-dropdown"
              style={{ width: '100%', padding: '5px 0' }}
              suffixIcon={
                <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
              }>
              <Option value="" className="documents-control-option">
                <span style={{ fontSize: '14px', fontWeight: 600 }}>
                  {t('tickets.control.filter.defvalue.project')}
                </span>
              </Option>
              {user &&
                user.projects &&
                user.projects
                  .sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))
                  .map((project) => (
                    <Option value={project.id} key={project.id} className="documents-control-option">
                      <span style={{ fontSize: '14px', fontWeight: 600 }}>{project.title}</span>
                    </Option>
                  ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ marginTop: '0.5rem' }} align="middle" gutter={12}>
        <Col span={8}>
          <span style={{ color: '#607B9E' }}>{t('common.search.placeholder')}:</span>
        </Col>
        <Col span={16}>
          <Form.Item name={'search'} style={{ marginBottom: 0 }}>
            <Search
              allowClear
              className="documents-control-search common-animation-primary"
              style={{ width: '100%', padding: '5px 0' }}
              placeholder={`${pageTypeConfig.isDocuments ? t('category.create.name').toLowerCase() : '№'} ${t(
                'fileManagement.control.description',
              )}`}
              suffix={<img src={searchIcon} alt="" />}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ marginTop: '0.5rem' }} align="middle" gutter={12}>
        <Col span={8}>
          <span style={{ color: '#607B9E' }}>{t('budget.control.filter.status.title')}:</span>
        </Col>
        <Col span={16}>
          <Form.Item name={'status'} style={{ marginBottom: 0 }} initialValue={''}>
            <Select
              className="common-150-select common-select common-animation-primary"
              dropdownClassName="documents-control-select-dropdown"
              style={{ width: '100%', padding: '5px 0' }}
              suffixIcon={
                <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
              }>
              <Option value="" className="documents-control-option">
                <span style={{ fontSize: '14px', fontWeight: 600, color: '#000000' }}>
                  {t('documents.control.select.all')}
                </span>
              </Option>
              {statuses.length > 0 &&
                statuses.map((status) => (
                  <Option value={status.code} key={status.code} className="documents-control-option">
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: 600,
                        color: setTextColorByStatus(status.code),
                      }}>
                      {status.description}
                    </span>
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ marginTop: '0.5rem' }} align="middle" gutter={12}>
        <Col span={8}>
          <span style={{ color: '#607B9E' }}>{t('consumers.table.columns.property')}:</span>
        </Col>
        <Col span={16}>
          <Form.Item name={'properties'} style={{ marginBottom: 0 }} initialValue={undefined}>
            <TreeSelect
              className="common-150-select common-select common-animation-primary"
              treeData={properties}
              multiple
              showSearch
              filterTreeNode={(search: string, item: any) =>
                item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0
              }
              placeholder={t('consumers.control.search.placeholder.property')}
              style={{ width: '100%' }}
              showCheckedStrategy="SHOW_CHILD"
              treeCheckable
              maxTagCount={5}
              fieldNames={{ label: 'title', value: 'id', children: 'childrens' }}
            />
          </Form.Item>
        </Col>
      </Row>
      {steps.length > 0 &&
        steps.map((step) => (
          <Row style={{ marginTop: '0.5rem' }} align="middle" gutter={12} key={step.code}>
            <Col span={8}>
              <span style={{ color: '#607B9E' }}>{step.signatoriesName}:</span>
            </Col>
            <Col span={16}>
              <Form.Item name={step.code} style={{ marginBottom: 0 }} initialValue={''}>
                <Select
                  className="common-200-select common-select common-animation-primary"
                  style={{ width: '100%', padding: '5px 0' }}
                  onClick={() =>
                    step.code.split('_').includes('agent')
                      ? agents.length === 0 && getAgents()
                      : consumers.length === 0 && getConsumers()
                  }
                  dropdownClassName={`documents-control-select-dropdown ${
                    fetchingAgentsData || fetchingConsumersData ? 'consumers-control-select-dropdown--progress' : ''
                  }`}
                  onPopupScroll={(e: any) => {
                    e.persist();
                    let target = e.target;
                    if (
                      (!fetchingAgentsData || !fetchingConsumersData) &&
                      (step.code.split('_').includes('agent') ? !isLastAgentsReq : !isLastConsumersReq) &&
                      target.scrollTop + target.offsetHeight === target.scrollHeight
                    ) {
                      step.code.split('_').includes('agent') ? getAgents() : getConsumers();
                    }
                  }}
                  suffixIcon={
                    <div
                      className={`arrow-drop-img ${css['arrow-drop']}`}
                      style={{ width: '8px', height: '4px' }}></div>
                  }>
                  <Option value="" className="documents-control-option">
                    <span style={{ fontSize: '14px', fontWeight: 600 }}>{t('tasks.control.assignee.all')}</span>
                  </Option>
                  {step.code.split('_').includes('agent') ? (
                    <>
                      <Option value={user.id} key={user.id} className="budget-control-option">
                        <span style={{ fontSize: '14px' }}>{`${user.firstName} ${user.lastName}`}</span>
                      </Option>
                      <OptGroup label={t('quotes.modal.form.agents')}>
                        {agents.length > 0 &&
                          agents
                            .filter((u) => u.id !== user.id)
                            .map((u) => (
                              <Option value={u.id} key={u.id} className="budget-control-option">
                                <span style={{ fontSize: '14px' }}>{`${u.firstName} ${u.lastName}`}</span>
                              </Option>
                            ))}
                      </OptGroup>
                    </>
                  ) : (
                    consumers.length > 0 &&
                    consumers.map((u) => (
                      <Option value={u.id} key={u.id} className="budget-control-option">
                        <span style={{ fontSize: '14px' }}>{`${u.firstName} ${u.lastName}`}</span>
                      </Option>
                    ))
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        ))}
      {pageTypeConfig.isDocuments && (
        <Row style={{ marginTop: '0.5rem' }} align="middle" gutter={12}>
          <Col span={8}>
            <span style={{ color: '#607B9E' }}>{t('budget.form.date.of.signature')}:</span>
          </Col>
          <Col span={16}>
            <Form.Item name={'signatureDate'} style={{ marginBottom: 0 }} initialValue={null}>
              <RangePicker
                suffixIcon={<div className={'calendar-img'} style={{ width: '12px', height: '12px' }}></div>}
                className="budget-control-range-picker fileManagement-rangePicker common-animation-primary"
                style={{ height: '42px', padding: '10px 5px', width: '100%' }}
                format="DD.MM.YYYY"
                placeholder={[t('fileManagement.table.columns.sign.start'), t('fileManagement.table.columns.sign.end')]}
                inputReadOnly
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      {pageTypeConfig.isInvoices && (
        <Row style={{ marginTop: '0.5rem' }} align="middle" gutter={12}>
          <Col span={8}>
            <span style={{ color: '#607B9E' }}>{t('budget.table.date')}:</span>
          </Col>
          <Col span={16}>
            <Form.Item name={'paymentDate'} style={{ marginBottom: 0 }} initialValue={null}>
              <RangePicker
                suffixIcon={<div className={'calendar-img'} style={{ width: '12px', height: '12px' }}></div>}
                className="budget-control-range-picker fileManagement-rangePicker common-animation-primary"
                style={{ height: '42px', padding: '10px 5px', width: '100%' }}
                format="DD.MM.YYYY"
                placeholder={[
                  t('fileManagement.table.columns.payment.start'),
                  t('fileManagement.table.columns.payment.end'),
                ]}
                inputReadOnly
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row style={{ marginTop: '0.5rem' }} align="middle" gutter={12}>
        <Col span={8}>
          <span style={{ color: '#607B9E' }}>{t('budget.table.created.date')}:</span>
        </Col>
        <Col span={16}>
          <Form.Item name={'createdDate'} style={{ marginBottom: 0 }} initialValue={null}>
            <RangePicker
              suffixIcon={<div className={'calendar-img'} style={{ width: '12px', height: '12px' }}></div>}
              className="budget-control-range-picker fileManagement-rangePicker common-animation-primary"
              style={{ height: '42px', padding: '10px 5px', width: '100%' }}
              format="DD.MM.YYYY"
              placeholder={[
                t('fileManagement.table.columns.createdDate.start'),
                t('fileManagement.table.columns.createdDate.end'),
              ]}
              inputReadOnly
            />
          </Form.Item>
        </Col>
      </Row>
      {(pageTypeConfig.isQuotes || pageTypeConfig.isAmendments) && (
        <Row style={{ marginTop: '0.5rem' }} align="middle" gutter={12}>
          <Col span={8}>
            <span style={{ color: '#607B9E' }}>{t('fileManagement.table.deadline')}:</span>
          </Col>
          <Col span={16}>
            <Form.Item name={'deadline'} style={{ marginBottom: 0 }} initialValue={null}>
              <RangePicker
                suffixIcon={<div className={'calendar-img'} style={{ width: '12px', height: '12px' }}></div>}
                className="budget-control-range-picker fileManagement-rangePicker common-animation-primary"
                style={{ height: '42px', padding: '10px 5px', width: '100%' }}
                format="DD.MM.YYYY"
                placeholder={[t('report.table.columns.start'), t('report.table.columns.end')]}
                inputReadOnly
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      {!useLessThen801() && (
        <Row style={{ marginTop: '0.5rem' }} align="middle" gutter={12}>
          <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              className={`common-gray-border-btn common-secondary-btn`}
              onClick={() => {
                resetFilters(true);
                openFilterBtnRef?.current?.click();
              }}
              style={{ marginRight: '0.5rem', height: '36px' }}>
              {t('fileManagement.reset.all')}
            </Button>
            <Button
              className={`common-green-btn common-modal-btn`}
              onClick={() => {
                form.submit();
                openFilterBtnRef?.current?.click();
              }}
              style={{ height: '36px' }}>
              {t('fileManagement.apply')}
            </Button>
          </Col>
        </Row>
      )}
    </Form>
  );
};

export default FileManagmentFilter;
