import HttpService from './common/httpService';
import config from '../config';
import { getApiKeyOptions } from '../helpers/getApiKeyOptions';

class VariantsService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getVariants(projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.get(`${config.baseRequest}variants`, options);
  }

  public getVariantById(id: number, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.get(`${config.baseRequest}variants/${id}`, options);
  }

  public createVariant(body: any, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.post(`${config.baseRequest}variants`, body, options);
  }

  public updateVariant(body: any, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.put(`${config.baseRequest}variants`, body, options);
  }

  public deleteVariant(id: number, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.delete(`${config.baseRequest}variants/${id}`, null, options);
  }

  public uploadImageFloorPlan(variantId: number, file: any) {
    const formData = new FormData();
    formData.append('file', file);
    return this.httpService.postFile(`${config.baseRequest}variants/img/floorplan/${variantId}`, formData);
  }

  public deleteImageFloorPlan(variantId: number) {
    return this.httpService.delete(`${config.baseRequest}variants/img/floorplan/${variantId}`);
  }

  public uploadImageShortFloorPlan(variantId: number, file: any) {
    const formData = new FormData();
    formData.append('file', file);
    return this.httpService.postFile(`${config.baseRequest}variants/img/shortfloorplan/${variantId}`, formData);
  }

  public deleteImageShortFloorPlan(variantId: number) {
    return this.httpService.delete(`${config.baseRequest}variants/img/shortfloorplan/${variantId}`);
  }
}

export default VariantsService;
