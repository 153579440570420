import React, { useContext, useEffect } from 'react';
import css from '../../assets/styles/dashboard.module.css';
import { HeaderContext } from '../../contexts/HeaderContextProvider';
import { CHANGE_CURRENT_PAGE } from '../../constants/actionTypes/headerConstants';
import { AppContext } from '../../contexts/AppContextProvider';
import ConsumerDashboard from './Components/ConsumerDashboard/ConsumerDashboard';
import { useTranslation } from 'react-i18next';
import DashboardHelper from './Components/BrokerDashboard/DashboardHelper';
import { IAppContext } from '../../typings/IApp';
import StatisticsPage from './Statistics/StatisticsPage';
import { SET_IS_OPEN_HELPER } from '../../constants/actionTypes/appConstants';
import i18n from '../../utils/i18n';

const DashboardPage = () => {
  const { t } = useTranslation();
  const { headerDispatch } = useContext(HeaderContext);
  const {
    app: { isConsumer, isOpenHelper },
    appDispatch,
  } = useContext<IAppContext>(AppContext);

  useEffect(() => {
    headerDispatch({
      type: CHANGE_CURRENT_PAGE,
      currentPage: t('header.title.Dashboard'),
      path: 'dashboard',
    });
  }, [i18n.language]);

  if (isConsumer) {
    return <ConsumerDashboard />;
  }

  return (
    <div className="common-flex-column-style">
      {isOpenHelper && (
        <DashboardHelper setIsOpenHelper={() => appDispatch({ type: SET_IS_OPEN_HELPER, isOpenHelper: false })} />
      )}
      <div className={css['broker-dashboard-container']}>
        <StatisticsPage />
      </div>
    </div>
  );
};
export default DashboardPage;
