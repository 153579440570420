import { FilterTypes } from '../constants/filterTypes';

export const generateFiltersData = (values: any, isDocuments: boolean) => {
  const getLabel = (key: string) => {
    switch (key) {
      case 'project': {
        return `property/project/id`;
      }
      case 'status': {
        return `status_v2/code`;
      }
      case 'properties': {
        return `property/id`;
      }
      case 'signatureDate': {
        return `lastSignDateTime`;
      }
      default: {
        if (key.includes('agent') || key.includes('client')) {
          return `sign_${key}/consumerId`;
        }
        return key;
      }
    }
  };

  const getFilterType = (key: string) => {
    switch (key) {
      case 'properties': {
        return FilterTypes.SELECT_MULTIPLE;
      }
      case 'createdDate':
      case 'paymentDate':
      case 'signatureDate':
      case 'deadline': {
        return FilterTypes.DATE_RANGE;
      }
      case 'search': {
        return FilterTypes.MULTISEARCH;
      }
      case 'signatories': {
        return FilterTypes.AND;
      }
      default: {
        return FilterTypes.SELECT;
      }
    }
  };
  const data: { [name: string]: { type: FilterTypes; value: any } } = {};
  let signatories: { [name: string]: string | number }[] = [];
  for (const key in values) {
    if (!!values[key]) {
      if (key.includes('agent') || key.includes('client')) {
        signatories.push({ [getLabel(key)]: values[key] });
      } else {
        data[getLabel(key)] = {
          type: getFilterType(key),
          value:
            key === 'search'
              ? [
                  {
                    [isDocuments ? 'title' : 'number']: encodeURIComponent(values[key] || ''),
                  },
                  {
                    description: encodeURIComponent(values[key]),
                  },
                ]
              : values[key],
        };
      }
    }
  }
  if (signatories.length > 0) {
    data['signatories'] = {
      type: getFilterType('signatories'),
      value: signatories,
    };
  }
  return data;
};
