import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../../assets/styles/dashboard.module.css';
import companyIcon from '../../../../assets/images/company-blue.svg';
import projectIcon from '../../../../assets/images/ic-project-active.svg';
import propertyIcon from '../../../../assets/images/properties-blue.svg';
import usersIcon from '../../../../assets/images/ic-consumers2-active.svg';
import infoIcon from '../../../../assets/images/icon-i.svg';
import { ArrowRightOutlined, CloseOutlined, StopOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import arrowIcon from '../../../../assets/images/ic-arrow-back.svg';
import doneIcon from '../../../../assets/images/ic-check-mark-gray-copy.svg';
import { useLessThen801 } from '../../../../helpers/mediaDetect';
import { Button, Carousel, notification, Tooltip } from 'antd';
import DashboardService from '../../../../services/DashboardService';
import { deleteOnBoarding, OnboardingTypes, saveUserOnboarding } from '../../../../helpers/onboarding';
import { AppContext } from '../../../../contexts/AppContextProvider';
import { IAppContext } from '../../../../typings/IApp';

const service = new DashboardService();

interface StepProps {
  step: {
    img: string;
    title: string;
    id: number;
    description: string;
    linkTitle: string;
    link: string;
    type: string;
    isDone: boolean;
    isAvailability: boolean;
  };
  setHeights?: (arr: number[]) => void;
  heights?: number[];
}

interface Props {
  setIsOpenHelper: (b: boolean) => void;
}

const DashboardHelper = (props: Props) => {
  const { t } = useTranslation();
  const {
    app: { user },
  } = useContext<IAppContext>(AppContext);
  const [fetchingData, setFetchingData] = useState(false);
  const { setIsOpenHelper } = props;

  const [steps, setSteps] = useState([
    {
      id: 1,
      title: t('dashboard.helper.first.title'),
      description: t('dashboard.helper.first.description'),
      img: companyIcon,
      link: '/companies',
      linkTitle: t('dashboard.helper.first.link'),
      type: 'companies',
      isDone: false,
      isAvailability: false,
    },
    {
      id: 2,
      title: t('dashboard.helper.second.title'),
      description: t('dashboard.helper.second.description'),
      img: projectIcon,
      link: '/projects',
      linkTitle: t('dashboard.helper.second.link'),
      type: 'projects',
      isDone: false,
      isAvailability: false,
    },
    {
      id: 3,
      title: t('dashboard.helper.third.title'),
      description: t('dashboard.helper.third.description'),
      img: propertyIcon,
      link: '/properties',
      linkTitle: t('dashboard.helper.third.link'),
      type: 'properties',
      isDone: false,
      isAvailability: false,
    },
    {
      id: 4,
      title: t('dashboard.helper.forth.title'),
      description: t('dashboard.helper.forth.description'),
      img: usersIcon,
      link: '/users',
      linkTitle: t('dashboard.helper.forth.link'),
      type: 'users',
      isDone: false,
      isAvailability: false,
    },
  ]);
  const [heights, setHeights] = useState<number[]>(steps.map(() => 1));

  useEffect(() => {
    const localeSteps = window.localStorage.getItem('dashboardHelper');
    const pagesUrl = user.functions.map((el) => `/${el.path}`);
    const userSteps = localeSteps && JSON.parse(localeSteps);
    setSteps((state) =>
      state.map((step) => {
        const currentItem = userSteps?.find((item: { id: number; isDone: boolean }) => item.id === step.id);
        if (currentItem) {
          step.isDone = currentItem.isDone;
        }
        if (pagesUrl.includes(step.link)) {
          step.isAvailability = true;
        }
        return step;
      }),
    );
  }, [user]);

  const updateCurrentStep = (id: number) => {
    const dashboardHelper = steps.map((step) => ({ id: step.id, isDone: step.id === id ? true : step.isDone }));
    let body: any = {
      dashboardHelper,
    };
    if (user.boardCheckpoint && user.boardCheckpoint.length > 0) {
      const userBoard = JSON.parse(user.boardCheckpoint);
      if (userBoard.currentStep) {
        delete userBoard.currentStep;
      }
      body = { ...userBoard, ...body };
    }
    saveUserOnboarding(body);
    window.localStorage.setItem('dashboardHelper', JSON.stringify(dashboardHelper));
  };

  const trainingTutorial = () => {
    setFetchingData(true);
    service
      .trainingTutorial()
      .then(() => {
        notification.success({
          message: t('dashboard.traning.notification'),
        });
      })
      .catch((e) => {
        notification.error({
          message: e.message,
        });
      })
      .finally(() => setFetchingData(false));
  };

  const getOnboardingKeys = (type: string) => {
    if (type === 'companies') {
      return [OnboardingTypes.COMPANIES, OnboardingTypes.COMPANIES_MODIFY_NEW, OnboardingTypes.COMPANIES_MODIFY_EDIT];
    }
    if (type === 'projects') {
      return [
        OnboardingTypes.PROJECT,
        OnboardingTypes.PROJECT_CFC,
        OnboardingTypes.PROJECT_FLOW,
        OnboardingTypes.PROJECT_MODIFY_NEW,
        OnboardingTypes.PROJECT_MODIFY_EDIT,
      ];
    }
    if (type === 'properties') {
      return [
        OnboardingTypes.PROPERTY,
        OnboardingTypes.PROPERTY_CHILDREN,
        OnboardingTypes.PROPERTY_CREATE,
        OnboardingTypes.PROPERTY_MODIFY_NEW,
        OnboardingTypes.PROPERTY_MODIFY_EDIT,
      ];
    }
    if (type === 'users') {
      return [OnboardingTypes.USER, OnboardingTypes.USER_MODIFY_NEW, OnboardingTypes.USER_MODIFY_EDIT];
    }
  };

  const StepBlock = ({ step, heights, setHeights, ...props }: StepProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [offsetHeight, setOffsetHeight] = useState<number>();
    const [minHeight, setMinHeight] = useState<number>(1);
    useEffect(() => {
      if (
        heights &&
        setHeights &&
        containerRef.current?.offsetHeight &&
        heights[step.id - 1] !== containerRef.current.offsetHeight
      ) {
        setOffsetHeight(containerRef.current.offsetHeight);
        setHeights(
          heights.map((value, index) =>
            index === step.id - 1 ? (containerRef.current?.offsetHeight as number) : value,
          ),
        );
      }
    }, []);

    useEffect(() => {
      if (heights) {
        const max = Math.max(...heights);
        if (max !== offsetHeight) {
          setMinHeight(max);
        }
      }
    }, [heights]);

    return (
      <div
        {...props}
        ref={containerRef}
        className={css['dashboard-helper-step-container']}
        style={{ backgroundColor: step.isDone || !step.isAvailability ? '#ebf0fc' : '#fff', minHeight }}>
        <div className={css['dashboard-helper-step-container-top']}>
          <span className="common-flex-between-style" style={{ width: '100%' }}>
            <div style={{ width: 'calc(15px + 0.4rem)' }}></div>
            <img src={step.img} alt="" height={24} width={24} />
            {step.isAvailability ? (
              step.isDone ? (
                <span>
                  <img src={doneIcon} alt="" className={css['done-icon']} />
                </span>
              ) : (
                <span className={css['arrow-icon-helper-container']}>
                  <img src={arrowIcon} alt="" className={css['arrow-icon']} />
                </span>
              )
            ) : (
              <span>
                <StopOutlined style={{ fontSize: '26px', color: '#fc0000' }} />
              </span>
            )}
          </span>
          {step.isDone && step.isAvailability ? (
            <NavLink
              to={step.link}
              onClick={() => {
                deleteOnBoarding(getOnboardingKeys(step.type));
              }}
              style={{ textAlign: 'center' }}>
              <span
                className={`${css['dashboard-helper-step-title']} ${css['dashboard-helper-step-link']}`}>{`${step.id}. ${step.title}`}</span>
            </NavLink>
          ) : (
            <span className={css['dashboard-helper-step-title']}>{`${step.id}. ${step.title}`}</span>
          )}
          <span className={css['dashboard-helper-step-description']}>{step.description}</span>
        </div>
        {!step.isDone && step.isAvailability && (
          <NavLink
            to={step.link}
            onClick={() => {
              deleteOnBoarding(getOnboardingKeys(step.type));
              updateCurrentStep(step.id);
            }}
            style={{ display: 'flex', marginTop: '1rem', alignItems: 'center', color: '#356ede' }}>
            {step.linkTitle}
            <ArrowRightOutlined style={{ color: '#356ede', marginLeft: '1rem' }} />
          </NavLink>
        )}
      </div>
    );
  };

  if (useLessThen801()) {
    return (
      <div className={css['dashboard-helper-container']}>
        <div className="common-flex-between-style">
          <img src={infoIcon} alt="" />
          <CloseOutlined
            className={css['close-icon']}
            onClick={() => {
              setIsOpenHelper(false);
              window.localStorage.setItem('isClosedHelper', 'true');
            }}
          />
        </div>
        <span className={css['dashboard-main-title']}>{t('dashboard.helper.main.title')}</span>
        <span className={css['dashboard-main-description']}>{t('dashboard.helper.main.description')}</span>
        <div style={{ marginTop: '1rem', padding: '10px' }}>
          <Carousel>
            {steps.map((step) =>
              step.isAvailability ? (
                <StepBlock key={step.id} step={step} heights={heights} setHeights={setHeights} />
              ) : (
                <Tooltip
                  key={step.id}
                  title={t('dashboard.traning.error')}
                  overlayInnerStyle={{ color: '#000' }}
                  color={'#fff'}
                  placement={'bottom'}>
                  <StepBlock step={step} heights={heights} setHeights={setHeights} />
                </Tooltip>
              ),
            )}
          </Carousel>
        </div>
        <div className={`${css['dasboard-training']} common-flex-style`} style={{ flexDirection: 'column' }}>
          <span style={{ textAlign: 'center' }}>{t('dashboard.traning.title')}</span>
          <Button
            disabled={fetchingData}
            className="common-gray-border-btn common-secondary-btn"
            onClick={trainingTutorial}>
            {t('dashboard.traning.btn')}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={css['dashboard-helper-container']}>
      <div className="common-flex-between-style">
        <img src={infoIcon} alt="" />
        <span className={css['dashboard-main-title']}>{t('dashboard.helper.main.title')}</span>
        <CloseOutlined
          className={css['close-icon']}
          onClick={() => {
            setIsOpenHelper(false);
            window.localStorage.setItem('isClosedHelper', 'true');
          }}
        />
      </div>
      <span className={css['dashboard-main-description']}>{t('dashboard.helper.main.description')}</span>
      <div className="common-flex-around-style" style={{ marginTop: '0.5rem', alignItems: 'normal' }}>
        {steps.map((step) =>
          step.isAvailability ? (
            <StepBlock key={step.id} step={step} />
          ) : (
            <Tooltip
              key={step.id}
              title={t('dashboard.traning.error')}
              overlayInnerStyle={{ color: '#000' }}
              color={'#fff'}
              placement={'bottom'}>
              <StepBlock step={step} />
            </Tooltip>
          ),
        )}
      </div>
      <div className={css['dasboard-training']}>
        <span style={{ marginRight: '1rem' }}>{t('dashboard.traning.title')}</span>
        <Button
          disabled={fetchingData}
          className="common-gray-border-btn common-secondary-btn"
          onClick={trainingTutorial}>
          {t('dashboard.traning.btn')}
        </Button>
      </div>
    </div>
  );
};

export default DashboardHelper;
