import { Button } from 'antd';
import React from 'react';
import css from '../../../../../assets/styles/faqAI.module.css';
import { CloseOutlined } from '@ant-design/icons';

interface IProps {
  handleOk: () => void;
  handleCancel: () => void;
  handleClose?: () => void;
  title?: string;
  description: string;
  okTitle: string;
  cancelTitle: string;
  isLoading: boolean;
  closeTitle?: string;
}

const ConfirmModal = ({
  handleOk,
  handleCancel,
  title,
  description,
  okTitle,
  cancelTitle,
  handleClose,
  isLoading,
  closeTitle,
}: IProps) => {
  return (
    <div className={css.modalMask}>
      <div className={css.modal}>
        <div className={css.modalContainer}>
          {handleClose && (
            <Button
              type="text"
              shape="circle"
              className={css.modalClose}
              disabled={isLoading}
              icon={<CloseOutlined style={{ fontSize: 18 }} />}
              onClick={handleClose}
            />
          )}
          <div style={{ textAlign: 'center' }}>
            {title && <h2>{title}</h2>}
            <p>{description}</p>
          </div>
          <div className={css.modalFooter}>
            {closeTitle &&
            <Button
            onClick={handleClose}
            className={`common-blue-border-btn common-secondary-btn`}
            loading={isLoading}>
              {closeTitle}
            </Button>
              }
            <Button
              onClick={handleCancel}
              className={`common-blue-border-btn common-secondary-btn`}
              loading={isLoading}>
              {cancelTitle}
            </Button>
            <Button onClick={handleOk} className={`common-blue-btn common-primary-btn`} loading={isLoading}>
              {okTitle}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
