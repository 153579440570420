import React, { useContext, useEffect, useState } from 'react';
import ClientInfo from './components/ClientInfo';
import ProjectDetails from './components/ProjectDetails';
import css from '../../assets/styles/details.module.css';
import { HeaderContext } from '../../contexts/HeaderContextProvider';
import { AppContext } from '../../contexts/AppContextProvider';
import { CHANGE_CURRENT_PAGE } from '../../constants/actionTypes/headerConstants';
import NoPropertyPage from '../common/NoPropertyPage';
import ListPageServices from '../../services/PropertiesService';
import { PropertyModel } from '../../models/PropertyModel';
import { useLessThen801 } from '../../helpers/mediaDetect';
import ProjectDetailsMobile from './components/ProjectDetailsMobile';
import { IAppContext } from '../../typings/IApp';
import { useTranslation } from 'react-i18next';
import i18n from '../../utils/i18n';

const propertyService = new ListPageServices();

const ConsumerDetailsPage = () => {
  const { t } = useTranslation();
  const { headerDispatch } = useContext(HeaderContext);
  const {
    app: { isConsumer, currentProject, currentProperty },
  } = useContext<IAppContext>(AppContext);

  const projectKey = isConsumer && currentProject && currentProject.key;
  const [propertyInfo, setPropertyInfo] = useState<PropertyModel | null>(null);

  useEffect(() => {
    headerDispatch({
      type: CHANGE_CURRENT_PAGE,
      currentPage: t('header.title.detailsProject'),
      path: 'details_project',
    });
  }, [i18n.language]);

  useEffect(() => {
    if (currentProperty) {
      propertyService
        .getPropertyById({ id: currentProperty.id, projectKey })
        .then((value: PropertyModel) => setPropertyInfo(value))
        .catch((e) => console.log(e));
    }
  }, [currentProperty]);

  if (!currentProperty) {
    return <NoPropertyPage />;
  }

  return (
    <div className={css['container-сonsumer']}>
      {propertyInfo && (
        <div className={css['container-broker-client-info']}>
          <ClientInfo propertyInfo={propertyInfo} />
        </div>
      )}
      {useLessThen801() ? (
        <ProjectDetailsMobile propertyInfo={propertyInfo} />
      ) : (
        <ProjectDetails propertyInfo={propertyInfo} />
      )}
    </div>
  );
};

export default ConsumerDetailsPage;
