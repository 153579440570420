// @ts-ignore
import buildQuery from 'odata-query';

export interface IODataModel {
  filter?: any;
  filters?: any;
  expand?: any;
  orderBy?: any;
  top?: number | undefined;
  skip?: number | undefined;
  count?: boolean | undefined;
}

export default (params?: any) => {
  return buildQuery(params);
};
