import { Button, Modal, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../assets/styles/budget.module.css';
import { ICreateAmend } from '../../../models/AmendmentModel';
import { NewBudgetModel } from '../../../models/BudgetModel';
import { NewDocumentsModel } from '../../../models/DocumentsModel';
import { ICreateQuoteModel } from '../../../models/QuotesModel';
import AmendmentService from '../../../services/AmendmentService';
import BudgetService from '../../../services/BudgetService';
import QuotesService from '../../../services/QuoteService';
import useCloseTabWarning from '../../../hooks/useCloseTabWarning';
import useRequests from '../hooks/useRequest';
import useGetPageTypeConfig from '../hooks/useGetPageTypeConfig';

interface ConfirmProps {
  isOpenConfirm: boolean;
  setIsOpenConfirm: (b: boolean) => void;
  createdBody: ICreateQuoteModel | NewDocumentsModel | NewBudgetModel | ICreateAmend;
  closeCreate: () => void;
  fileAttachmentList: any;
  fileQuotesList: any;
  fileAmendmentsList: any;
  fileContract: any;
  handleOpenStatus?: ({ itemId }: { itemId: number }) => void;
}
const serviceQuotes = new QuotesService();
const amendmentService = new AmendmentService();
const invoiceService = new BudgetService();

const ConfirmUploadForm = (props: ConfirmProps) => {
  const {
    isOpenConfirm,
    setIsOpenConfirm,
    createdBody,
    closeCreate,
    fileAttachmentList,
    fileQuotesList,
    fileAmendmentsList,
    fileContract,
    handleOpenStatus,
  } = props;

  const [fetchingData, setFetchingData] = useState(false);
  const { t } = useTranslation();
  const { routerPrompt: Prompt, setIsDirty } = useCloseTabWarning(t('common.modal.onbeforeunload.message'));
  const { setCreateRequestByPageType } = useRequests();
  const { isQuotes, isAmendments } = useGetPageTypeConfig();

  useEffect(() => {
    setIsDirty(fetchingData);
  }, [fetchingData]);

  const setClose = () => {
    setIsOpenConfirm(false);
  };

  const onSubmit = () => {
    setFetchingData(true);

    const filesData = new FormData();
    for (const file of fileAttachmentList) {
      if (file.originFileObj) {
        filesData.append('attachments', file.originFileObj);
      }
    }
    setCreateRequestByPageType(createdBody)
      .then(async (value: any) => {
        const file: any = await serviceQuotes.getQuoteFile(value.file).then((res) => {
          return res;
        });
        const setFileService = () => {
          if (isQuotes) {
            return serviceQuotes.uploadQuoteFiles(value.id, filesData);
          }
          if (isAmendments) {
            return amendmentService.uploadAmendmentFiles(value.id, filesData);
          }
          return invoiceService.uploadInvoiceFiles(value.id, filesData);
        };

        if (isAmendments) {
          filesData.append(`main_${value.id}`, file);
          if (value.quotes && value.quotes.length > 0) {
            value.quotes.forEach((item: any) => {
              const file = fileQuotesList.find((file: any) => file.uid === item.guid);
              if (file && file.originFileObj) {
                filesData.append(`quote_${item.id}`, file.originFileObj);
              }
            });
          }
          if (value.amendments && value.amendments.length > 0) {
            value.amendments.forEach((item: any) => {
              const file = fileAmendmentsList.find((file: any) => file.uid === item.guid);
              if (file && file.originFileObj) {
                filesData.append(`amendment_${item.id}`, file.originFileObj);
              }
            });
          }
          if (value.contract) {
            for (const file of fileContract) {
              if (file && file.originFileObj) {
                filesData.append(`contract_${value.contract.id}`, file.originFileObj);
              }
            }
          }
          setFetchingData(true);
        } else {
          filesData.append('file', file);
        }
        setFileService()
          .then(() => {
            setIsOpenConfirm(false);
            closeCreate();
            notification.success({
              message: isQuotes
                ? t('quotes.confirm.created')
                : isAmendments
                ? t('amendment.confirm.created')
                : t('budget.invoice.created'),
            });
            handleOpenStatus && handleOpenStatus({ itemId: value.id });
          })
          .catch((e) => {
            setFetchingData(false);
            if (e.status === 413) {
              return notification.error({
                message: e.message || t('common.error.largeFile'),
              });
            }
            notification.error({
              message: e.message || t('common.error.internalServerError'),
            });
          })
          .finally(() => {
            if (isAmendments) {
              setFetchingData(false);
            }
          });
      })
      .catch((e: any) => {
        setFetchingData(false);
        notification.error({
          message: e.message,
        });
      });
  };

  const getDescriptionByType = () => {
    if (isQuotes) {
      return t('quotes.confirm.warning');
    }
    if (isAmendments) {
      return t('amendment.confirm.warning');
    }
    return t('budget.confirm.warning');
  };

  return (
    <Modal
      visible={isOpenConfirm}
      centered
      wrapClassName={isAmendments && fetchingData ? 'cursorProgress' : ''}
      footer={
        <div className="common-modal-footer">
          <Button
            className={`common-gray-border-btn common-secondary-btn`}
            disabled={fetchingData}
            onClick={() => setClose()}>
            {t('budget.modal.cancel.btn')}
          </Button>
          <Button className={`common-green-btn common-modal-btn`} disabled={fetchingData} onClick={() => onSubmit()}>
            {t('budget.modal.save.btn')}
          </Button>
        </div>
      }
      closable={false}
      className="common-modal">
      <div className={css['confirm-msg-container']}>
        <span style={{ fontWeight: 600, fontSize: '18px', marginBottom: '1rem' }}>
          {t('budget.title.confirmation')}
        </span>
        <span style={{ color: '#778dac', textAlign: 'center' }}>{getDescriptionByType()}</span>
      </div>
      {Prompt}
    </Modal>
  );
};
export default ConfirmUploadForm;
