import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import {
  Form,
  Input,
  Button,
  Card,
  Row,
  Col,
  Upload,
  Typography,
  Avatar,
  Image,
  notification,
  Space,
  Spin,
  Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import UsersService from '../../services/UsersService';
import ProjectsService from '../../services/ProjectsService';
import { DeleteOutlined, EditOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';
import { UploadChangeParam } from 'antd/lib/upload';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { getBase64Url } from '../../helpers/avatarTools';
import config from '../../config';
import { fileURLToAntdModel } from '../../helpers/imageToAntdModel';
import css from '../../assets/styles/project.module.css';
import CompaniesService from '../../services/CompaniesService';
import { CompaniesInfoModel, CompanyModel, PaymentInfo, UpdatedCompayModel } from '../../models/CompaniesModel';
import { useLessThen801 } from '../../helpers/mediaDetect';
import { CHANGE_CURRENT_PAGE } from '../../constants/actionTypes/headerConstants';
import { HeaderContext } from '../../contexts/HeaderContextProvider';
import BoardTourComponent from '../common/BoardTourComponent';
import CustomOnBoard from '../common/CustomOnBoard';
import { AppContext } from '../../contexts/AppContextProvider';
import DictionariesServices from '../../services/DictionariesServices';
import { getOnboarding, OnboardingTypes, saveOnboarding } from '../../helpers/onboarding';
import { ICurrency, IProjectFull, IProjectSource } from '../../models/ProjectModel';
import { IResponceData } from '../../typings/IServiceOptions';
import { IUserEasy } from '../../models/UserModel';
import { IAppContext } from '../../typings/IApp';
import ArkaService from '../../services/ArkaService';
import i18n from '../../utils/i18n';

const userService: UsersService = new UsersService();
const companiesService: CompaniesService = new CompaniesService();
const projectsService: ProjectsService = new ProjectsService();
const discionariesService: DictionariesServices = new DictionariesServices();

const ProjectModifyPage: React.FC = () => {
  const {
    app: { user },
  } = useContext<IAppContext>(AppContext);
  const { t } = useTranslation();
  const { id }: { id: string } = useParams();
  const { headerDispatch } = useContext(HeaderContext);
  const history = useHistory();
  const isCreate = !id;
  const [form] = Form.useForm();
  const [project, setProject] = useState<IProjectFull | undefined>(undefined);
  const [users, setUsers] = useState<IUserEasy[]>([]);
  const [targetCompanies, setTargetCompanies] = useState<number[]>([]);
  const [logo, setLogo] = useState<UploadFile | null>(null);
  const [file, setFile] = useState<UploadFile | null>(null);
  const [logoUrl, setLogoUrl] = useState<string | ArrayBuffer | null>(null);
  const [sources, setSources] = useState<IProjectSource[]>([]);
  const [paymentsProfile, setPaymentsProfile] = useState<PaymentInfo[] | null>(null);
  const [companies, setCompanies] = useState<CompaniesInfoModel>();
  const [selectDefaultPyament, setSelectDefaultPyament] = useState<number | null>(null);
  const [currencies, setCurrencies] = useState<ICurrency[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDeleteLogo, setIsDeleteLogo] = useState<boolean>(false);
  const [isLoadingLogo, setIsLoadingLogo] = useState<boolean>(false);
  const [isUploadFile, setIsUploadFile] = useState<boolean>(false);
  const [isDeleteFile, setIsDeleteFile] = useState<boolean>(false);

  const [currentTimeout, setCurrentTimeout] = useState<NodeJS.Timeout | null>(null);
  const [currentSelectedUsers, setCurrentSelectedUsers] = useState<IUserEasy[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [isLastUserReq, setIsLastUserReq] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    skip: 0,
  });

  const { copied } = useCopyToClipboard();

  //board
  const [steps, _] = useState(
    user.rolesParams.IS_DEVELOPER
      ? [
          {
            target: '.project-modify-first-step',
            content: (
              <BoardTourComponent
                title={t('board.project.modify.first.title')}
                description={t('board.project.modify.first.text')}
              />
            ),
            disableBeacon: true,
            placement: 'top-end',
          },
          {
            target: '.project-modify-second-step',
            disableBeacon: true,
            content: (
              <BoardTourComponent
                title={t('board.project.modify.second.title')}
                description={t('board.project.modify.second.text')}
              />
            ),
            placement: 'left',
          },
          {
            target: '.project-modify-third-step',
            disableBeacon: true,
            content: (
              <BoardTourComponent
                title={t('board.project.modify.third.title')}
                description={t('board.project.modify.third.text')}
              />
            ),
            placement: 'left',
          },
          {
            target: '.project-modify-forth-step',
            disableBeacon: true,
            content: (
              <BoardTourComponent
                title={t('board.project.modify.forth.title')}
                description={t('board.project.modify.forth.text')}
              />
            ),
            placement: 'left',
          },
        ]
      : [
          {
            target: '.project-modify-first-step',
            content: (
              <BoardTourComponent
                title={t('board.project.modify.first.title')}
                description={t('board.project.modify.first.text')}
              />
            ),
            disableBeacon: true,
            placement: 'top-end',
          },
          {
            target: '.project-modify-third-step',
            disableBeacon: true,
            content: (
              <BoardTourComponent
                title={t('board.project.modify.third.title')}
                description={t('board.project.modify.third.text')}
              />
            ),
            placement: 'left',
          },
          {
            target: '.project-modify-forth-step',
            disableBeacon: true,
            content: (
              <BoardTourComponent
                title={t('board.project.modify.forth.title')}
                description={t('board.project.modify.forth.text')}
              />
            ),
            placement: 'left',
          },
        ],
  );
  const [run, setRun] = useState(false);

  const getProject = () => {
    projectsService.getProjectById(+id).then((project: IProjectFull) => {
      if (project.file) {
        const file = fileURLToAntdModel({
          id: `${project.file}_${project.id}`,
          name: project.file,
        });
        setIsUploadFile(true);
        setFile(file);
      }
      form.resetFields();
      setProject(project);
      form.setFieldsValue({
        name: project.name,
        title: project.title,
        code: project.code,
        source: { id: project?.source?.id || null },
        defaultPaymentInfoId: project.defaultPaymentInfoId,
        companies: project.companies.map((el) => el.id),
        users: project.users.map((el) => el.id),
        defaultCurrency: project.defaultCurrency?.code || undefined,
      });
      if (project.logo) {
        setLogoUrl(`${config.storeUrl}/${project.logo}`);
      }
      setSelectDefaultPyament(project.defaultPaymentInfoId);
      getUsers(project.users.map((el) => el.id));
    });
  };

  const getUsers = (usersId?: number[], usersRes: IUserEasy[] = []) => {
    if (usersId && usersId.length > 0) {
      userService
        .getEasyUsers({ userId: usersId })
        .then((res: IUserEasy[]) => {
          setCurrentSelectedUsers(res);
          getUsers([], res);
        })
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        );
      return;
    }
    const optionUser = {
      count: true,
      top: pagination.pageSize,
      current: pagination.current,
      skip: pagination.pageSize * (pagination.current - 1) || 0,
      fullName: searchValue || '',
    };

    setFetchingData(true);
    userService
      .getEasyUsers(optionUser)
      .then((res: IResponceData<IUserEasy>) => {
        const { count, items } = res;
        const { current, top } = optionUser;
        setPagination({ ...pagination, current: current + 1, total: count, pageSize: top });
        let usersIds: number[] = [];
        if (users.length > 0) {
          usersIds = users.map((user) => user.id);
        } else if (usersRes.length > 0) {
          usersIds = usersRes.map((user) => user.id);
        }
        setUsers([...usersRes, ...users, ...items.filter((item) => !usersIds.includes(item.id))]);
        setIsLastUserReq(current * top >= count);
      })
      .finally(() => setFetchingData(false));
  };

  useEffect(() => {
    if (searchValue === '') {
      return getUsers([], currentSelectedUsers);
    }
    if (searchValue) {
      getUsers();
    }
  }, [searchValue]);

  useEffect(() => {
    if (isCreate) {
      headerDispatch({
        type: CHANGE_CURRENT_PAGE,
        currentPage: t('projects.create.title.create'),
        path: 'projects/modify',
      });
    }
  }, [i18n.language]);

  useEffect(() => {
    setFetchingData(true);
    discionariesService
      .getDictionary('currencies')
      .then((values: ICurrency[]) => {
        setCurrencies(values);
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingData(false));
  }, []);

  useEffect(() => {
    if (!isCreate && currencies.length > 0 && project && !project?.defaultCurrency?.code) {
      const defaultCurrency = currencies.find((item) => item.code === 'chf');
      form.setFieldsValue({ defaultCurrency: defaultCurrency?.code || undefined });
    }
  }, [currencies, project]);

  useEffect(() => {
    if (companies && companies.length > 0) {
      const selectCompanise = companies.filter((company: CompanyModel) => targetCompanies.includes(company.id));
      const selectCompaniesPayment = selectCompanise.reduceRight((prev: PaymentInfo[], curr: CompanyModel) => {
        prev.push(...curr.paymentInfo);
        return prev;
      }, []);
      setPaymentsProfile(selectCompaniesPayment);
    }
  }, [targetCompanies, companies]);

  useEffect(() => {
    if (companies && companies.length > 0 && selectDefaultPyament) {
      const selectCompanise = companies.filter((company: CompanyModel) => targetCompanies.includes(company.id));
      const selectCompaniesPayment = selectCompanise.reduceRight((prev: PaymentInfo[], curr: CompanyModel) => {
        prev.push(...curr.paymentInfo);
        return prev;
      }, []);

      if (targetCompanies.length === 0 || !selectCompaniesPayment.find((el) => el.id === selectDefaultPyament)) {
        form.setFieldsValue({
          defaultPaymentInfoId: null,
        });
      }
    }
  }, [selectDefaultPyament, targetCompanies]);

  const getCompanies = () => {
    companiesService.getCompanies({}).then((res: CompaniesInfoModel) => {
      setCompanies(res);
    });
  };

  const getProjectSources = () => {
    projectsService.getProjectSources().then((sources: IProjectSource[]) => {
      setSources(sources);
    });
  };

  const getAsyncData = async () => {
    const actions = [getUsers(), getProjectSources(), getCompanies()];
    if (!isCreate) {
      actions.shift();
      actions.unshift(getProject());
    }
    Promise.all(actions).finally(() => {
      setLoading(false);
      setRun(getOnboarding(isCreate ? OnboardingTypes.PROJECT_MODIFY_NEW : OnboardingTypes.PROJECT_MODIFY_EDIT));
    });
  };

  useEffect(() => {
    setLoading(true);
    getAsyncData();
  }, []);

  useEffect(() => {
    if (project && companies && companies.length > 0) {
      const { companies: projectsCompanies } = project;
      if (projectsCompanies) {
        const data = companies
          .filter((item) => projectsCompanies.findIndex((company) => company.id === item.id) >= 0)
          .map((item) => item.id);
        setTargetCompanies(data);
      }
    }
  }, [project, companies]);

  useEffect(() => {
    if (copied) {
      notification.success({
        message: t('projects.create.copy.success'),
      });
    }
  }, [copied]);

  const onSubmit = (values: any) => {
    setIsSaving(true);
    const body = {
      usersProjects: values.users,
      code: values.code,
      name: values.name,
      title: isCreate
        ? user.rolesParams.IS_DEVELOPER
          ? values.title
          : values.name
        : user.rolesParams.IS_DEVELOPER
        ? values.title
        : project!.title,
      source: values.source,
      companies: targetCompanies.map((el) => ({ id: el })) || [],
      defaultPaymentInfoId: values.defaultPaymentInfoId,
      defaultCurrency: { code: values.defaultCurrency },
    };

    const modelActions = (id: number) => {
      const promises: Promise<any>[] = [];
      if (logo) {
        promises.push(projectsService.uploadLogo(id, logo));
      }
      if (file) {
        promises.push(projectsService.uploadFile(id, file));
      }
      if (isDeleteLogo && !logoUrl) {
        promises.push(projectsService.deleteLogo(id));
      }
      if (isDeleteFile && !file) {
        promises.push(projectsService.deleteFile(id));
      }
      Promise.all(promises).finally(() => {
        setIsSaving(false);
        history.push('/projects');
      });
    };

    if (isCreate) {
      projectsService
        .createProject(body)
        .then((res) => {
          modelActions(res.id);
          notification.success({
            message: t('project.create.success.notify'),
          });
        })
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        )
        .finally(() => setIsSaving(false));
    } else {
      projectsService
        .updateProject({
          id: +id,
          ...body,
        })
        .then(() => {
          modelActions(+id);
          updateCompany();
          notification.success({
            message: t('project.update.success.notify'),
          });
        })
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        )
        .finally(() => setIsSaving(false));
    }
  };

  const updateCompany = () => {
    if (companies) {
      companies.forEach((el: CompanyModel) => {
        if (targetCompanies.includes(el.id)) {
          if (!el.projects.find((el) => el.id === project?.id)) {
            let body = { ...el, projects: el.projects.map((p) => ({ id: p.id })) };
            body.projects.push({ id: project!.id });
            const { createdDate, updatedDate, deletedDate, logo, externalId, ...newBody } = body;
            companiesService
              .updateCompany(newBody)
              .catch((e) =>
                notification.error({
                  message: e.message,
                }),
              )
              .finally(() => setIsSaving(false));
          }
        } else {
          if (el.projects.find((el) => el.id === project?.id)) {
            let body = {
              ...el,
              projects: el.projects
                .filter((el) => el.id !== project?.id)
                .map((p, _, arr) => {
                  if (arr.length === 0) {
                    return [];
                  }
                  if (p.id !== project?.id) {
                    return { id: p.id };
                  }
                }),
            };
            const { createdDate, updatedDate, deletedDate, logo, externalId, ...newBody } = body;

            companiesService
              .updateCompany(newBody as UpdatedCompayModel)
              .catch((e) =>
                notification.error({
                  message: e.message,
                }),
              )
              .finally(() => setIsSaving(false));
          }
        }
      });
    }
  };

  const onCancel = () => {
    history.push('/projects');
  };

  const beforeUploadLogo = (file: RcFile) => {
    setIsLoadingLogo(true);
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      notification.error({ message: t('projects.create.logo.mimetype.error') });
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      notification.error({ message: t('projects.create.logo.fileSize.error') });
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChangeLogo = (info: UploadChangeParam<UploadFile>) => {
    const { file } = info;
    getBase64Url(file.originFileObj, (imageUrl: string | ArrayBuffer | null) => {
      setLogo(file);
      setLogoUrl(imageUrl);
      setIsLoadingLogo(false);
    });
  };

  const deleteLogo = () => {
    if (logoUrl) {
      setLogo(null);
      setLogoUrl(null);
      setIsDeleteLogo(true);
    }
  };

  const onChangeFile = (info: UploadChangeParam<UploadFile<any>>) => {
    const { file, fileList } = info;
    setFile(file?.status !== 'removed' ? fileList[0] : null);
    setIsUploadFile(file?.status !== 'removed');
    const hasOwnProperty = Object.prototype.hasOwnProperty;
    if (file?.status === 'removed' && !hasOwnProperty.call(file, 'originFileObj')) {
      setIsDeleteFile(true);
    }
  };

  if (useLessThen801()) {
    return (
      <Spin spinning={isSaving}>
        <Form
          autoComplete="off"
          form={form}
          layout="vertical"
          name="modify_projects"
          onFinish={onSubmit}
          style={{ width: '100%' }}>
          <HeaderTable
            title={!isCreate ? `${t('projects.create.title.update')} #${id}` : t('projects.create.title.create')}>
            <Form.Item>
              <div style={{ display: 'flex' }}>
                <Button
                  htmlType="button"
                  onClick={onCancel}
                  className={`common-blue-border-btn common-secondary-btn`}
                  style={{ height: '42px', marginRight: '0.5rem', width: '100%' }}>
                  {t('common.btn.cancel')}
                </Button>
                <Button
                  className={`common-blue-btn common-primary-btn`}
                  htmlType="submit"
                  style={{ height: '42px', marginLeft: '0.5rem', width: '100%' }}>
                  {t('common.btn.save')}
                </Button>
              </div>
            </Form.Item>
          </HeaderTable>
          <div className={css['payment-info-container']}>
            <Row gutter={[24, 4]} style={{ width: '100%' }}>
              <Card loading={loading} style={{ padding: '1rem', width: '100%', backgroundColor: '#f5f7fa' }}>
                <Row gutter={24}>
                  <Col span={24}>
                    <Form.Item>
                      {logoUrl ? (
                        <Avatar
                          src={
                            <>
                              <Image src={logoUrl as string} preview={false} />
                              <div className="logo-mask">
                                <Space>
                                  <Upload
                                    accept=".png, .jpg, .jpeg"
                                    showUploadList={false}
                                    beforeUpload={beforeUploadLogo}
                                    onChange={handleChangeLogo}
                                    customRequest={() => {}}>
                                    <Button type="link" size="large">
                                      <EditOutlined style={{ fontSize: '24px' }} />
                                    </Button>
                                  </Upload>
                                  <Button type="link" size="large" onClick={deleteLogo}>
                                    <DeleteOutlined style={{ fontSize: '24px' }} />
                                  </Button>
                                </Space>
                              </div>
                            </>
                          }
                          size={150}
                        />
                      ) : (
                        <Upload.Dragger
                          accept=".png, .jpg, .jpeg"
                          listType="picture-card"
                          showUploadList={false}
                          beforeUpload={beforeUploadLogo}
                          onChange={handleChangeLogo}
                          customRequest={() => {}}
                          className="consumer-modify-upload">
                          <div className="consumer-modify-upload">
                            {isLoadingLogo ? <LoadingOutlined /> : <div className="consumer-modify-upload-icon"></div>}
                            <div style={{ marginTop: 8, marginLeft: 4, marginRight: 4 }}>
                              {t('consumers.btn.upload.profile')}
                            </div>
                          </div>
                        </Upload.Dragger>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={
                        <span style={{ color: '#778dac' }}>
                          {t('projects.create.name')} <span style={{ color: '#ff4d4f' }}>*</span>
                        </span>
                      }
                      name="name"
                      validateTrigger={['onBlur', 'onChange']}
                      rules={[{ required: true, message: t('projects.create.name.validation') }]}>
                      <Input className="common-animation-primary" />
                    </Form.Item>
                  </Col>
                  {user.rolesParams.IS_DEVELOPER && (
                    <Col span={24}>
                      <Form.Item
                        label={
                          <span style={{ color: '#778dac' }}>
                            {t('projects.create.title')} <span style={{ color: '#ff4d4f' }}>*</span>
                          </span>
                        }
                        name="title"
                        validateTrigger={['onBlur', 'onChange']}
                        rules={[{ required: true, message: t('projects.create.title.validation') }]}>
                        <Input className="common-animation-primary" />
                      </Form.Item>
                    </Col>
                  )}
                  {user.rolesParams.IS_DEVELOPER && (
                    <>
                      <Col span={24}>
                        <Form.Item
                          label={
                            <span style={{ color: '#778dac' }}>
                              {t('projects.create.code')} <span style={{ color: '#ff4d4f' }}>*</span>
                            </span>
                          }
                          name="code"
                          validateTrigger={['onBlur', 'onChange']}
                          rules={[{ required: true, message: t('projects.create.code.validation') }]}>
                          <Input className="common-animation-primary" />
                        </Form.Item>
                      </Col>
                      {/* <Col span={24}>
                    <Form.Item label={t('projects.create.source')} name="source">
                      <Input className="common-animation-primary" />
                    </Form.Item>
                  </Col> */}
                      <Col span={24}>
                        <Form.Item
                          label={
                            <span style={{ color: '#778dac' }}>
                              {t('projects.create.source')} <span style={{ color: '#ff4d4f' }}>*</span>
                            </span>
                          }
                          name={['source', 'id']}
                          validateTrigger={['onBlur', 'onChange']}
                          rules={[{ required: true, message: t('projects.create.source.validation') }]}>
                          <Select
                            className="common-select common-animation-primary"
                            suffixIcon={
                              <div
                                className={`arrow-drop-img ${css['arrow-drop']}`}
                                style={{ width: '8px', height: '4px' }}></div>
                            }>
                            {sources.map((item) => (
                              <Select.Option key={item.id} value={item.id}>
                                {item?.description}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </>
                  )}
                  {!isCreate && user.rolesParams.IS_DEVELOPER && (
                    <Col span={24}>
                      <Form.Item label="API key">
                        <Typography.Paragraph type="secondary" copyable>
                          {project?.key}
                        </Typography.Paragraph>
                      </Form.Item>
                    </Col>
                  )}
                  {/* TODO: users list */}
                  <Col span={24}>
                    <Form.Item
                      name="users"
                      label={<span style={{ color: '#778dac' }}>{t('projects.create.users.title')}</span>}>
                      <Select
                        showSearch
                        mode="multiple"
                        maxTagCount={1}
                        className="common-select common-animation-primary"
                        suffixIcon={
                          <div
                            className={`arrow-drop-img ${css['arrow-drop']}`}
                            style={{ width: '8px', height: '4px' }}></div>
                        }
                        onPopupScroll={(e: any) => {
                          e.persist();
                          let target = e.target;
                          if (
                            !fetchingData &&
                            !isLastUserReq &&
                            target.scrollTop + target.offsetHeight === target.scrollHeight
                          ) {
                            getUsers();
                          }
                        }}
                        onDropdownVisibleChange={(value) => {
                          if (!value && searchValue) {
                            setUsers([]);
                            setPagination({ ...pagination, current: 1, skip: 0 });
                            setSearchValue('');
                          }
                        }}
                        filterOption={() => true}
                        onSearch={(value) => {
                          setUsers([]);
                          setPagination({ ...pagination, current: 1, skip: 0 });
                          clearTimeout(Number(currentTimeout));
                          setCurrentTimeout(setTimeout(() => setSearchValue(value), 1000));
                        }}
                        placeholder={t('users.select.placeholder')}>
                        {users &&
                          users.length > 0 &&
                          users.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {`${item.login} (${item.firstName} ${item.lastName})`}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  {user.rolesParams.IS_DEVELOPER && (
                    <Col span={24}>
                      <Card title={t('projects.create.users.media')} style={{ marginBottom: '1rem' }}>
                        <Form.Item
                          label={<span style={{ color: '#778dac' }}>{t('projects.create.file')}</span>}
                          name="file"
                          valuePropName="filelist">
                          <Upload
                            listType="picture"
                            accept=".png, .jpg, .jpeg, .glb, .gltf"
                            beforeUpload={() => false}
                            onChange={onChangeFile}
                            defaultFileList={file ? [file] : []}
                            customRequest={() => {}}
                            className="variant-floor-plan">
                            {!isUploadFile && <Button icon={<PlusOutlined />}>{t('common.btn.upload')}</Button>}
                          </Upload>
                        </Form.Item>
                      </Card>
                    </Col>
                  )}
                  <Col span={24}>
                    <Form.Item
                      name="companies"
                      label={<span style={{ color: '#778dac' }}>{t('companies.title.header')}</span>}>
                      <Select
                        showSearch
                        mode="multiple"
                        className="common-select common-animation-primary"
                        suffixIcon={
                          <div
                            className={`arrow-drop-img ${css['arrow-drop']}`}
                            style={{ width: '8px', height: '4px' }}></div>
                        }
                        filterOption={(input: string, { children }: any) => {
                          return children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }}
                        placeholder={t('companies.select.placeholder')}>
                        {companies &&
                          companies.length > 0 &&
                          companies
                            .sort((a, b) => a.companyName.toLowerCase().localeCompare(b.companyName.toLowerCase()))
                            .map((item) => (
                              <Select.Option key={item.id} value={item.id}>
                                {item.companyName}
                              </Select.Option>
                            ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="defaultPaymentInfoId"
                      label={<span style={{ color: '#778dac' }}>{t('project.default.payment.profile')}</span>}
                      style={{ marginTop: '1rem' }}>
                      <Select
                        className="common-select common-animation-primary"
                        suffixIcon={
                          <div
                            className={`arrow-drop-img ${css['arrow-drop']}`}
                            style={{ width: '8px', height: '4px' }}></div>
                        }
                        placeholder={t('project.payment.profile.placeholder')}
                        onChange={(value) => setSelectDefaultPyament(value ? +value : null)}>
                        {paymentsProfile &&
                          paymentsProfile
                            .sort((a, b) => a.accountHolder.toLowerCase().localeCompare(b.accountHolder.toLowerCase()))
                            .map((item: PaymentInfo) => (
                              <Select.Option key={item.id} value={item.id}>
                                {item.accountHolder}
                              </Select.Option>
                            ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Row>
          </div>
        </Form>
      </Spin>
    );
  }

  return (
    <>
      <CustomOnBoard
        steps={steps}
        run={run}
        onFinish={() =>
          saveOnboarding(
            isCreate ? OnboardingTypes.PROJECT_MODIFY_NEW : OnboardingTypes.PROJECT_MODIFY_EDIT,
            user.boardCheckpoint,
          )
        }
      />
      <Spin spinning={isSaving}>
        <Form
          autoComplete="off"
          form={form}
          layout="vertical"
          name="modify_projects"
          onFinish={onSubmit}
          style={{ height: '100%' }}>
          <div className={css['payment-info-container']} style={{ height: '100%' }}>
            <Row gutter={[24, 4]} style={{ width: '100%' }}>
              <Card
                loading={loading}
                bordered={false}
                style={{ width: '100%', padding: '1rem', backgroundColor: '#f5f7fa' }}>
                <Row gutter={4}>
                  <Col span={24}>
                    <Form.Item className="project-modify-third-step">
                      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                          htmlType="button"
                          onClick={onCancel}
                          className={`common-blue-border-btn common-secondary-btn`}
                          style={{ height: '42px', marginRight: '1rem' }}>
                          {t('common.btn.cancel')}
                        </Button>
                        <Button
                          className={`common-blue-btn common-primary-btn`}
                          htmlType="submit"
                          style={{ height: '42px' }}>
                          {t('common.btn.save')}
                        </Button>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
                <div className="project-modify-first-step">
                  <Row gutter={12}>
                    <Col span={24}>
                      <Form.Item>
                        {logoUrl ? (
                          <Avatar
                            src={
                              <>
                                <Image src={logoUrl as string} preview={false} />
                                <div className="logo-mask">
                                  <Space>
                                    <Upload
                                      accept=".png, .jpg, .jpeg"
                                      showUploadList={false}
                                      beforeUpload={beforeUploadLogo}
                                      onChange={handleChangeLogo}
                                      customRequest={() => {}}>
                                      <Button type="link" size="large">
                                        <EditOutlined style={{ fontSize: '24px' }} />
                                      </Button>
                                    </Upload>
                                    <Button type="link" size="large" onClick={deleteLogo}>
                                      <DeleteOutlined style={{ fontSize: '24px' }} />
                                    </Button>
                                  </Space>
                                </div>
                              </>
                            }
                            size={150}
                          />
                        ) : (
                          <Upload.Dragger
                            accept=".png, .jpg, .jpeg"
                            listType="picture-card"
                            showUploadList={false}
                            beforeUpload={beforeUploadLogo}
                            onChange={handleChangeLogo}
                            customRequest={() => {}}
                            className="consumer-modify-upload">
                            <div className="consumer-modify-upload">
                              {isLoadingLogo ? (
                                <LoadingOutlined />
                              ) : (
                                <div className="consumer-modify-upload-icon"></div>
                              )}
                              <div style={{ marginTop: 8, marginLeft: 4, marginRight: 4 }}>
                                {t('consumers.btn.upload.profile')}
                              </div>
                            </div>
                          </Upload.Dragger>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={
                          <span style={{ color: '#778dac' }}>
                            {t('projects.create.name')} <span style={{ color: '#ff4d4f' }}>*</span>
                          </span>
                        }
                        name="name"
                        validateTrigger={['onBlur', 'onChange']}
                        rules={[{ required: true, message: t('projects.create.name.validation') }]}>
                        <Input className="common-animation-primary" />
                      </Form.Item>
                    </Col>
                    {user.rolesParams.IS_DEVELOPER && (
                      <Col span={12}>
                        <Form.Item
                          label={
                            <span style={{ color: '#778dac' }}>
                              {t('projects.create.title')} <span style={{ color: '#ff4d4f' }}>*</span>
                            </span>
                          }
                          name="title"
                          validateTrigger={['onBlur', 'onChange']}
                          rules={[{ required: true, message: t('projects.create.title.validation') }]}>
                          <Input className="common-animation-primary" />
                        </Form.Item>
                      </Col>
                    )}
                    {user.rolesParams.IS_DEVELOPER && (
                      <>
                        <Col span={12}>
                          <Form.Item
                            label={
                              <span style={{ color: '#778dac' }}>
                                {t('projects.create.code')} <span style={{ color: '#ff4d4f' }}>*</span>
                              </span>
                            }
                            name="code"
                            validateTrigger={['onBlur', 'onChange']}
                            rules={[{ required: true, message: t('projects.create.code.validation') }]}>
                            <Input className="common-animation-primary" />
                          </Form.Item>
                        </Col>

                        {/* <Col span={12}>
                  <Form.Item label={t('projects.create.source')} name="source">
                    <Input className="common-animation-primary" />
                  </Form.Item>
                </Col> */}
                        <Col span={12}>
                          <Form.Item
                            label={
                              <span style={{ color: '#778dac' }}>
                                {t('projects.create.source')} <span style={{ color: '#ff4d4f' }}>*</span>
                              </span>
                            }
                            name={['source', 'id']}
                            validateTrigger={['onBlur', 'onChange']}
                            rules={[{ required: true, message: t('projects.create.source.validation') }]}>
                            <Select
                              className="common-select common-animation-primary"
                              suffixIcon={
                                <div
                                  className={`arrow-drop-img ${css['arrow-drop']}`}
                                  style={{ width: '8px', height: '4px' }}></div>
                              }>
                              {sources.map((item) => (
                                <Select.Option key={item.id} value={item.id}>
                                  {item?.description}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </>
                    )}
                  </Row>
                  <Row gutter={12}>
                    {!isCreate && user.rolesParams.IS_DEVELOPER && (
                      <Col xl={24} lg={20} md={18}>
                        <Form.Item label={<span style={{ color: '#778dac' }}>"API key"</span>}>
                          <Typography.Paragraph type="secondary" copyable>
                            {project?.key}
                          </Typography.Paragraph>
                        </Form.Item>
                      </Col>
                    )}
                    {/* TODO: users list */}
                    <Col span={12}>
                      <Form.Item
                        name="users"
                        label={
                          <span style={{ color: '#778dac' }}>
                            {t('projects.create.users.title')} <span style={{ color: '#ff4d4f' }}>*</span>
                          </span>
                        }
                        rules={[{ required: true, message: t('common.validation.required') }]}>
                        <Select
                          showSearch
                          mode="multiple"
                          maxTagCount={1}
                          className="common-select common-animation-primary"
                          suffixIcon={
                            <div
                              className={`arrow-drop-img ${css['arrow-drop']}`}
                              style={{ width: '8px', height: '4px' }}></div>
                          }
                          dropdownClassName={`${fetchingData ? 'consumers-control-select-dropdown--progress' : ''}`}
                          onPopupScroll={(e: any) => {
                            e.persist();
                            let target = e.target;
                            if (
                              !fetchingData &&
                              !isLastUserReq &&
                              target.scrollTop + target.offsetHeight === target.scrollHeight
                            ) {
                              getUsers();
                            }
                          }}
                          onDropdownVisibleChange={(value) => {
                            if (!value && searchValue) {
                              setUsers([]);
                              setPagination({ ...pagination, current: 1, skip: 0 });
                              setSearchValue('');
                            }
                          }}
                          filterOption={() => true}
                          onSearch={(value) => {
                            setUsers([]);
                            setPagination({ ...pagination, current: 1, skip: 0 });
                            clearTimeout(Number(currentTimeout));
                            setCurrentTimeout(setTimeout(() => setSearchValue(value), 1000));
                          }}
                          placeholder={t('users.select.placeholder')}>
                          {users &&
                            users.length > 0 &&
                            users.map((item) => (
                              <Select.Option key={item.id} value={item.id}>
                                {`${item.login} (${item.firstName} ${item.lastName})`}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    {user.rolesParams.IS_DEVELOPER && (
                      <Col xl={12} lg={10} md={9}>
                        <Card
                          title={t('projects.create.users.media')}
                          style={{ marginBottom: '1rem' }}
                          className="project-modify-second-step">
                          <Form.Item
                            label={<span style={{ color: '#778dac' }}>{t('projects.create.file')}</span>}
                            name="file"
                            valuePropName="filelist">
                            <Upload
                              listType="picture"
                              accept=".png, .jpg, .jpeg, .glb, .gltf"
                              beforeUpload={() => false}
                              onChange={onChangeFile}
                              defaultFileList={file ? [file] : []}
                              customRequest={() => {}}
                              className="variant-floor-plan">
                              {!isUploadFile && <Button icon={<PlusOutlined />}>{t('common.btn.upload')}</Button>}
                            </Upload>
                          </Form.Item>
                        </Card>
                      </Col>
                    )}
                    <Col span={12}>
                      <Form.Item
                        name="companies"
                        label={<span style={{ color: '#778dac' }}>{t('companies.title.header')}</span>}>
                        <Select
                          showSearch
                          mode="multiple"
                          className="common-select common-animation-primary"
                          suffixIcon={
                            <div
                              className={`arrow-drop-img ${css['arrow-drop']}`}
                              style={{ width: '8px', height: '4px' }}></div>
                          }
                          filterOption={(input: string, { children }: any) => {
                            return children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                          }}
                          onChange={(ids: number[]) => setTargetCompanies(ids || [])}
                          placeholder={t('companies.select.placeholder')}>
                          {companies &&
                            companies.length > 0 &&
                            companies
                              .sort((a, b) => a.companyName.toLowerCase().localeCompare(b.companyName.toLowerCase()))
                              .map((item) => (
                                <Select.Option key={item.id} value={item.id}>
                                  {item.companyName}
                                </Select.Option>
                              ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="defaultPaymentInfoId"
                        label={<span style={{ color: '#778dac' }}>{t('project.default.payment.profile')}</span>}>
                        <Select
                          className="common-select common-animation-primary"
                          suffixIcon={
                            <div
                              className={`arrow-drop-img ${css['arrow-drop']}`}
                              style={{ width: '8px', height: '4px' }}></div>
                          }
                          placeholder={t('project.payment.profile.placeholder')}
                          onChange={(value) => setSelectDefaultPyament(value ? +value : null)}>
                          {paymentsProfile &&
                            paymentsProfile
                              .sort((a, b) =>
                                a.accountHolder.toLowerCase().localeCompare(b.accountHolder.toLowerCase()),
                              )
                              .map((item: PaymentInfo) => (
                                <Select.Option key={item.id} value={item.id}>
                                  {item.accountHolder}
                                </Select.Option>
                              ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="defaultCurrency"
                        label={
                          <span style={{ color: '#778dac' }}>
                            {t('project.default.currency')} <span style={{ color: '#ff4d4f' }}>*</span>
                          </span>
                        }
                        rules={[{ required: true, message: t('common.validation.required') }]}>
                        <Select
                          className="common-select common-animation-primary"
                          suffixIcon={
                            <div
                              className={`arrow-drop-img ${css['arrow-drop']}`}
                              style={{ width: '8px', height: '4px' }}></div>
                          }
                          placeholder={t('project.currency.placeholder')}
                          disabled={!isCreate}>
                          {currencies.length > 0 &&
                            currencies.map((item) => (
                              <Select.Option key={item.code} value={item.code}>
                                {`${item.description} (${item.name})`}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Row>
          </div>
        </Form>
      </Spin>
    </>
  );
};

export default ProjectModifyPage;
