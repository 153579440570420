import { Button, Dropdown, List, Menu, Upload, Image } from 'antd';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import css from '../../../assets/styles/post.module.css';
import config from '../../../config';
import editIcon from '../../../assets/images/ic-edit-blue.svg';
import deleteIcon from '../../../assets/images/ic-delete-blue.svg';
import moreIcon from '../../../assets/images/ic-more.svg';
import calendarIcon from '../../../assets/images/ic-calendar.svg';
import arrowIcon from '../../../assets/images/ic-arrow-fill.svg';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../contexts/AppContextProvider';
import { CalendarTypes, IPost } from '../../../models/CalendarModel';
import { authDownloadFile } from '../../../helpers/authFileTools';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { DownloadOutlined } from '@ant-design/icons';
import parser from 'html-react-parser';
import { IAppContext } from '../../../typings/IApp';

interface IPostCard {
  item: IPost;
  isConsumer: boolean;
  onEdit?: (id: number) => void;
  onDelete?: (id: number) => void;
}

const PostCard = ({ item, onEdit, onDelete, isConsumer }: IPostCard) => {
  const { t } = useTranslation();
  const {
    app: { user },
  } = useContext<IAppContext>(AppContext);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const getType = (type: CalendarTypes) => {
    switch (type) {
      case CalendarTypes.update:
        return <div className={css['post-card-type-update']}>{t('post.update')}</div>;
      case CalendarTypes.document:
        return <div className={css['post-card-type-document']}>{t('post.documents')}</div>;
      case CalendarTypes.image:
        return <div className={css['post-card-type-image']}>{t('post.image')}</div>;
      default:
        return <div className={css['post-card-type-update']}>{t('post.update')}</div>;
    }
  };
  const menu = (
    <Menu className="update-menu-container">
      <Menu.Item key="edit">
        <div onClick={() => onEdit && onEdit(item.id)} className={css['update-menu-item']}>
          <img src={editIcon} alt="" style={{ marginRight: '10px', height: '15px', width: '15px' }} />
          <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.btn.modify')}</span>
        </div>
      </Menu.Item>
      <Menu.Item key="delete">
        <div onClick={() => onDelete && onDelete(item.id)} className={css['update-menu-item']}>
          <img src={deleteIcon} alt="" style={{ marginRight: '10px', height: '15px', width: '15px' }} />
          <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.title.delete')}</span>
        </div>
      </Menu.Item>
    </Menu>
  );
  return (
    <List.Item className={css['post-card-container']}>
      <div className={css['post-card-header']}>
        <div style={{ display: 'flex', alignItems: 'center' }}>{getType(item.type.code)}</div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}>
          <div style={{ display: 'flex' }}>
            <div
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}>
              <img src={calendarIcon} alt="" style={{ marginLeft: '0.5rem', height: '12px' }} />
              <span style={{ fontSize: '12px', margin: '0 0.5rem', color: '#778DAC', fontWeight: 400 }}>
                {moment(item.date).format('DD.MM.YYYY')}
              </span>
            </div>
            <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
              <img src={arrowIcon} alt="" width={13} height={12} style={{ marginLeft: '0.5rem' }} />
              <span
                style={{
                  marginLeft: '0.5rem',
                  fontSize: '12px',
                  color: '#778DAC',
                  fontWeight: 400,
                }}>
                {user?.projects?.find((project) => project.id === item?.properties[0]?.project?.id)?.title || ''}
              </span>
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: '0.5rem' }}>
            <span
              style={{
                marginRight: '1rem',
                fontSize: '12px',
                color: '#778DAC',
                fontWeight: 400,
              }}>
              {item.createdUser &&
                `${t('common.by')} ${item?.createdUser?.firstName || ''} ${item?.createdUser?.lastName || ''}`}
            </span>
          </div>
        </div>
        {!isConsumer && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Dropdown overlay={menu} trigger={['click']}>
              <Button className={css['update-dropdown-btn']}>
                <img src={moreIcon} alt="" height={15} width={15} />
              </Button>
            </Dropdown>
          </div>
        )}
      </div>
      <div style={{ width: '100%' }}>
        <span style={{ fontWeight: 400 }} className="parser-container">
          {parser(item.description || '')}
        </span>
        {item.files && (
          <>
            <Upload
              listType={
                item.type.code === CalendarTypes.document ||
                item.files.map((file) => file.file.slice(-3)).includes('pdf')
                  ? 'text'
                  : 'picture-card'
              }
              className={
                item.type.code === CalendarTypes.document ||
                item.files.map((file) => file.file.slice(-3)).includes('pdf')
                  ? 'upload-updates'
                  : 'upload-updates-view'
              }
              fileList={item.files.map((file: any) => ({
                name: file.fileName,
                uid: file.id,
                url: `${config.storeUrl}/${file.file}`,
                file: file.file,
              }))}
              showUploadList={{
                showRemoveIcon: item.type.code === CalendarTypes.image,
                removeIcon: <DownloadOutlined />,
              }}
              beforeUpload={() => false}
              onPreview={(file: any) =>
                item.type.code === CalendarTypes.image ? handlePreview(file) : authDownloadFile(file.file, file.name)
              }
              onRemove={(file: any) => {
                authDownloadFile(file.file, file.name);
              }}
            />
            {previewOpen && (
              <Image
                src={previewImage}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (value) => {
                    setPreviewOpen(value);
                  },
                }}
              />
            )}
          </>
        )}
      </div>
    </List.Item>
  );
};

export default PostCard;
