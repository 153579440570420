import { Button, Col, Dropdown, Input, Menu, Modal, notification, Row, Spin, Upload } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../assets/styles/meetings.module.css';
import moreIcon from '../../../assets/images/ic-more.svg';
import { MeetingModel } from '../../../models/MeetingsModel';
import MeetingsService from '../../../services/MeetingsService';
import { formatDate } from '../../../helpers/dateTools';
import moment from 'moment';
import { AppContext } from '../../../contexts/AppContextProvider';
import { CloseOutlined } from '@ant-design/icons';
import deleteIcon from '../../../assets/images/ic-delete-dark-gray.svg';
import editIcon from '../../../assets/images/ic-edit-dark.svg';
import LogoComponent from '../../../components/common/LogoComponent';
import config from '../../../config';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { IAppContext } from '../../../typings/IApp';

interface IPreview {
  isOpen: boolean;
  id: number | null;
  setClose: () => void;
  openEdit: (id: number | undefined) => void;
  openDelete: (id: number | undefined) => void;
  setIsOpenUploadMessageModal: (b: boolean) => void;
}

const meetingService = new MeetingsService();

const PreviewMeetingForm = (props: IPreview) => {
  const { t } = useTranslation();
  const { isOpen, id, setClose, openDelete, openEdit, setIsOpenUploadMessageModal } = props;
  const [fetchingData, setFetchingData] = useState(false);
  const [message, setMessage] = useState('');
  const [meeting, setMeeting] = useState<MeetingModel | null>(null);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const consumers = meeting?.assignees.filter((u) => u.roles[0].name === 'consumer');
  const suppliers = meeting?.assignees.filter((u) => u.roles[0].name === 'supplier');
  const agents = meeting?.assignees.filter((u) => !['consumer', 'supplier'].includes(u.roles[0].name));

  const {
    app: { user },
  } = useContext<IAppContext>(AppContext);

  useEffect(() => {
    getMeeting();
  }, []);

  const getMeeting = () => {
    setFetchingData(true);
    meetingService
      .getMeetingById(id)
      .then((value: MeetingModel) => setMeeting(value))
      .catch((e) => {
        notification.error({
          message: e.message,
        });
        setClose();
      })
      .finally(() => setFetchingData(false));
  };

  const sendComment = () => {
    if (!message) {
      return notification.warning({ message: 'please enter message' });
    }
    const filesData = new FormData();
    for (const file of fileList) {
      if (file.originFileObj) {
        filesData.append('file', file.originFileObj);
      }
    }
    setFetchingData(true);
    meetingService
      .createComment(id, { comment: message })
      .then((value) => {
        setMessage('');
        setFileList([]);
        if (filesData.getAll('file').length > 0) {
          setIsOpenUploadMessageModal(true);
          meetingService
            .uploadCommentFiles(value.id, filesData)
            .then(() => {
              getMeeting();
            })
            .catch((e) => console.log(e));
        } else {
          getMeeting();
        }
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingData(false));
  };

  const handleFile = (info: UploadChangeParam<UploadFile>) => {
    setFileList(info.fileList);
  };

  const menu = (
    <Menu className="update-menu-container" style={{ width: '130px' }}>
      {moment(meeting?.datetime).isAfter(moment()) && (
        <Menu.Item key="edit">
          <div
            onClick={() => {
              setClose();
              openEdit(meeting?.id);
            }}
            className={css['update-menu-item']}>
            <Button
              style={{
                border: 'none',
                backgroundColor: 'inherit',
                boxShadow: 'none',
                padding: 0,
                marginRight: '0.5rem',
              }}>
              <img src={editIcon} alt="" style={{ height: '15px', width: '15px' }} />
            </Button>
            <span style={{ color: '#00122d', fontWeight: 600 }}>{t('budget.table.edit')}</span>
          </div>
        </Menu.Item>
      )}
      <Menu.Item key="delete">
        <div
          onClick={() => {
            setClose();
            openDelete(meeting?.id);
          }}
          className={css['update-menu-item']}>
          <Button
            style={{
              border: 'none',
              backgroundColor: 'inherit',
              boxShadow: 'none',
              padding: 0,
              marginRight: '0.5rem',
            }}>
            <img src={deleteIcon} alt="" style={{ height: '15px', width: '15px' }} />
          </Button>
          <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.title.delete')}</span>
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <Spin spinning={fetchingData}>
      <Modal className="upload-modal" centered visible={isOpen} footer={null} closable={false}>
        <div className={css['header-preview-form']}>
          <span style={{ fontSize: '20px' }}>
            {meeting ? formatDate(meeting.datetime, 'DD MMM', true) : ''}
            <span style={{ marginLeft: '1rem', fontSize: '16px' }}>
              {meeting ? moment(meeting.datetime).format('HH:mm') : ''}
            </span>
          </span>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {meeting?.createdUser.id === user.id && (
              <Dropdown overlay={menu} trigger={['click']}>
                <Button className={css['update-dropdown-btn']}>
                  <img src={moreIcon} alt="" height={15} width={15} />
                </Button>
              </Dropdown>
            )}
            <CloseOutlined
              className="common-btn-icon common-animation-primary"
              style={{ borderRadius: '50%', marginLeft: '1rem' }}
              onClick={() => setClose()}
            />
          </div>
        </div>
        <div className={css['content-preview-form']}>
          <span>{meeting?.name || ''}</span>
          <span className={css['details-text']} style={{ marginTop: '1rem' }}>
            {`${t('meetings.details.title')}:`}
          </span>
          <span>{meeting?.details || ''}</span>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ marginTop: '1rem' }}>
              <span className={css['details-text']} style={{ marginRight: '1rem' }}>{`${
                meeting?.createdUser.roles[0].name[0].toUpperCase() + meeting?.createdUser.roles[0].name.slice(1) || ''
              } (Owner):`}</span>
              <LogoComponent
                id={meeting?.createdUser.id as number}
                name={`${meeting?.createdUser.firstName || ''} ${meeting?.createdUser.lastName || ''}`}
                image={meeting?.createdUser.image}
              />
              <span style={{ marginLeft: '1rem' }}>{`${meeting?.createdUser.firstName || ''} ${
                meeting?.createdUser.lastName || ''
              }`}</span>
            </span>
            {/* TODO: create function, refactor styles */}
            {agents && agents.length > 0 && (
              <span style={{ marginTop: '1rem', display: 'flex', alignItems: 'flex-start' }}>
                <span className={css['details-text']} style={{ marginRight: '1rem' }}>
                  {`${t('quotes.modal.form.agents')}:`}
                </span>
                <Row gutter={[24, 4]}>
                  {agents.map((agent) => (
                    <Col
                      span={agents.length === 1 || (agent.firstName + agent.lastName).length > 25 ? 24 : 12}
                      style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}
                      key={agent.id}>
                      <LogoComponent
                        id={agent.id as number}
                        name={`${agent.firstName || ''} ${agent.lastName || ''}`}
                        image={agent.image}
                      />
                      <span style={{ marginLeft: '1rem' }}>{`${agent.firstName || ''} ${agent.lastName || ''}`}</span>
                    </Col>
                  ))}
                </Row>
              </span>
            )}
            {consumers && consumers.length > 0 && (
              <span style={{ marginTop: '1rem', display: 'flex', alignItems: 'flex-start' }}>
                <span className={css['details-text']} style={{ marginRight: '1rem' }}>
                  {`${t('dashboard.acquirers.title')}:`}
                </span>
                <Row gutter={[24, 4]}>
                  {consumers.map((consumer) => (
                    <Col
                      span={consumers.length === 1 || (consumer.firstName + consumer.lastName).length > 25 ? 24 : 12}
                      style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}
                      key={consumer.id}>
                      <LogoComponent
                        id={consumer.id as number}
                        name={`${consumer.firstName || ''} ${consumer.lastName || ''}`}
                        image={consumer.image}
                      />
                      <span style={{ marginLeft: '1rem' }}>{`${consumer.firstName || ''} ${
                        consumer.lastName || ''
                      }`}</span>
                    </Col>
                  ))}
                </Row>
              </span>
            )}
            {suppliers && suppliers.length > 0 && (
              <span style={{ marginTop: '1rem', display: 'flex', alignItems: 'flex-start' }}>
                <span className={css['details-text']} style={{ marginRight: '1rem' }}>
                  {`${t('tasks.control.suppliers')}:`}
                </span>
                <Row gutter={[24, 4]}>
                  {suppliers.map((supplier) => (
                    <Col
                      span={suppliers.length === 1 || (supplier.firstName + supplier.lastName).length > 25 ? 24 : 12}
                      style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}
                      key={supplier.id}>
                      <LogoComponent
                        id={supplier.id as number}
                        name={`${supplier.firstName || ''} ${supplier.lastName || ''}`}
                        image={supplier.image}
                      />
                      <span style={{ marginLeft: '1rem' }}>{`${supplier.firstName || ''} ${
                        supplier.lastName || ''
                      }`}</span>
                    </Col>
                  ))}
                </Row>
              </span>
            )}
          </div>
        </div>
        <div className={css['footer-preview-form']}>
          <span style={{ color: '#889bb7' }}>{`${meeting ? meeting.comments.length : 0} comments`}</span>
          <div className={css['input-container']}>
            <Input
              placeholder="Start typing..."
              bordered={false}
              value={message}
              className={css['input-comment']}
              onChange={(e) => setMessage(e.target.value)}
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Upload
                accept=".pdf, .png, .jpg, .jpeg"
                className={'upload-updates'}
                fileList={fileList}
                beforeUpload={() => false}
                onChange={handleFile}
                multiple
                maxCount={1}
                itemRender={(_, file: UploadFile) => {
                  return <span>{file.name.slice(0, 5)}...</span>;
                }}
                listType="text"
                iconRender={() => null}>
                <div style={{ height: '15px', width: '15px', marginTop: '2px' }} className={css['ic-attach']}></div>
              </Upload>
              <Button className={`common-blue-btn common-primary-btn`} onClick={() => sendComment()}>
                {t('manage.faq.publish.btn')}
              </Button>
            </div>
          </div>
          <div className={css['comments-container']}>
            {meeting?.comments
              .sort((a, b) => b.id - a.id)
              .map((comment) => (
                <div style={{ display: 'flex', flexDirection: 'column' }} key={comment?.id}>
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                    <LogoComponent
                      id={comment.createdUser.id}
                      name={`${comment.createdUser.firstName} ${comment.createdUser.lastName}`}
                      image={comment.createdUser.image}
                    />
                    <span style={{ marginLeft: '1rem' }}>{`${comment.createdUser.firstName || ''} ${
                      comment.createdUser.lastName || ''
                    }`}</span>
                    <span style={{ color: '#889bb7', marginLeft: '1rem' }}>
                      {moment(comment.createdDate).format('DD.MM.YYYY')}
                    </span>
                  </div>
                  <span style={{ marginTop: '1rem' }}>{comment.comment}</span>
                  <Upload
                    listType="picture-card"
                    className={'upload-updates-view'}
                    fileList={
                      comment.files.map((file: any) => ({
                        name: '',
                        uid: file.id,
                        url: `${config.storeUrl}/${file.fileName}`,
                      })) as any
                    }
                    showUploadList={{ showRemoveIcon: false }}
                    beforeUpload={() => false}
                  />
                </div>
              ))}
          </div>
        </div>
      </Modal>
    </Spin>
  );
};

export default PreviewMeetingForm;
