import HttpService from './common/httpService';
import config from '../config';
import generateODataQuery, { IODataModel } from '../helpers/generateODataQuery';
import { getStorageToken } from '../helpers/storageTools';

class MeetingsService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getMeetings(params: IODataModel = {}) {
    const query = generateODataQuery({ filter: params.filter, orderBy: ['datetime desc'] });
    return this.httpService.get(`${config.baseRequest}events${query}`);
  }

  public getMeetingById(id: number | null) {
    return this.httpService.get(`${config.baseRequest}events/${id}`);
  }

  public createMeeting(body: any) {
    return this.httpService.post(`${config.baseRequest}events`, body);
  }

  public updateMeeting(body: any) {
    return this.httpService.put(`${config.baseRequest}events`, body);
  }

  public uploadCommentFiles(updateId: number | null, files: any) {
    return this.httpService.postFile(`${config.baseRequest}events/comments/${updateId}/files`, files);
  }

  public deleteMeeting(id: number | null) {
    return this.httpService.delete(`${config.baseRequest}events/${id}`);
  }

  public createComment(id: number | null, body: any) {
    return this.httpService.post(`${config.baseRequest}events/${id}/comments`, body);
  }

  public exportICal(id: number | null, meetingName: string) {
    return this.httpService
      .getFile(`${config.baseRequest}events/${id}/ics`, {
        header: { Authorization: 'Bearer ' + getStorageToken() },
      })
      .then((res: any) => {
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(res);
        a.href = url;
        a.download = `${meetingName}.ics`;
        a.click();
      });
  }
}

export default MeetingsService;
