import { IODataModel } from './generateODataQuery';

export const generateSorter = (sorter: any): IODataModel => {
  let orderBy: string | Array<string | null> | null = null;
  if (Array.isArray(sorter)) {
    orderBy = sorter.map((sorterItem: any) => {
      if (sorterItem && sorterItem.order) {
        return `${(sorterItem.column && sorterItem.column.sortIndex) || sorterItem.field} ${
          sorterItem.order === 'ascend' ? 'asc' : 'desc'
        }`;
      } else if (typeof sorterItem === 'string') {
        return sorterItem;
      } else {
        return null;
      }
    });
    orderBy = orderBy.filter((item) => !!item);
  } else {
    orderBy =
      sorter && sorter.order
        ? `${(sorter.column && sorter.column.sortIndex) || sorter.field} ${sorter.order === 'ascend' ? 'asc' : 'desc'}`
        : null;
  }

  return { orderBy };
};
