import { Button, Form, Input, Modal, notification, Spin, Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProjectControl from './ProjectControl';
import { getModalColumns } from './ProjectCFCColumns';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { useLessThen801 } from '../../../helpers/mediaDetect';
import css from '../../../assets/styles/project.module.css';
import { convertToCurrency } from '../../../helpers/convertToPrice';
import TableMobile from '../../common/TableMobile';
import { ICFC, ICFCitem, IUpdateCFC } from '../../../models/CFCModel';
import CFCService from '../../../services/CFCService';
import { AppContext } from '../../../contexts/AppContextProvider';
import { IAppContext } from '../../../typings/IApp';

interface IProjectCFCModal {
  CFC: ICFC | null;
  projectId?: string;
  handleClose: () => void;
  isOpen: boolean;
  handleSave: () => void;
}

const service = new CFCService();

const ProjectCFCModal = ({ CFC, projectId, handleClose, isOpen, handleSave }: IProjectCFCModal) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const {
    app: { user },
  } = useContext<IAppContext>(AppContext);
  const { height: windowHeight } = useWindowDimensions();
  const [fetchingData, setFetchingData] = useState(false);
  const [CFCitems, setCFCitems] = useState<ICFCitem[]>([]);
  const [CFCStatus, setCFCStatus] = useState<boolean>(CFC?.active || false);
  const [currency, setCurrency] = useState<string>(t('budget.currency'));

  const [searchValue, setSearchValue] = useState('');

  const projectKey = user.projects.find((project) => projectId && project.id == +projectId)?.key || '';

  useEffect(() => {
    if (projectId && +projectId) {
      setCurrency(
        user?.projects?.find((project) => project.id === +projectId)?.defaultCurrency?.description ||
          t('budget.currency'),
      );
    }
  }, [projectId]);

  const getCFCitems = async (code: string) => {
    setFetchingData(true);
    await service
      .getCFCitems(code, projectKey)
      .then((value) => setCFCitems(value))
      .catch((e) => {
        notification.error({
          message: e,
        });
      })
      .finally(() => setFetchingData(false));
  };

  const updateCFC = async (data: IUpdateCFC) => {
    setFetchingData(true);
    if (CFC?.code) {
      await service
        .updateCFC(CFC.code, data, projectKey)
        .then(() => handleSave())
        .catch((e) => {
          notification.error({
            message: e,
          });
        })
        .finally(() => setFetchingData(false));
    }
  };

  useEffect(() => {
    if (CFC?.code) {
      getCFCitems(CFC.code);
    }
  }, []);

  const onStatusChange = (status: boolean) => {
    setCFCStatus(status);
  };

  const onSubmit = (data: any) => {
    const newCFC: IUpdateCFC = {
      active: CFCStatus,
      items: data.items.map((item: { id: number; sum: string | null }) => {
        if (!item.sum) {
          return item;
        }

        const sum = item.sum.toString().includes('.')
          ? parseInt(item.sum.replace('.', ''))
          : item.sum.toString() + '00';

        return {
          id: item.id,
          sum,
        };
      }),
    };

    updateCFC(newCFC);
  };

  const onFiltersChange = (search: string) => {
    setSearchValue(search);
  };

  const onSave = () => {
    form.submit();
  };

  if (useLessThen801()) {
    const data =
      CFCitems.length > 0 &&
      (CFCitems.filter(
        (item: ICFCitem) => item.description?.toLowerCase().includes(searchValue?.toLowerCase()),
        // groupValue
        //   ? item.description?.toLowerCase().includes(searchValue?.toLowerCase()) && item.group == groupValue
        //   : item.description?.toLowerCase().includes(searchValue?.toLowerCase()),
      ).map((item, index) => [
        { title: '№', description: index + 1, type: 'string' },
        {
          title: t('budget.form.description'),
          description: (
            <Form.Item
              name={['items', index, 'description']}
              initialValue={item.description}
              style={{ marginBottom: 0 }}>
              <span>{item.description}</span>
            </Form.Item>
          ),
          type: 'component',
        },
        {
          title: t('properties.unit'),
          description: (
            <Form.Item name={['items', index, 'unit']} initialValue={item.unit} style={{ marginBottom: 0 }}>
              <span>{item.unit || '-'}</span>
            </Form.Item>
          ),
          type: 'component',
        },
        {
          title: t('budget.table.sum'),
          description: (
            <div style={{ display: 'flex', alignItems: 'center' }} className={css['project-cfc-sum-container']}>
              {currency}
              <Form.Item
                name={['items', index, 'sum']}
                initialValue={item.sum ? convertToCurrency(item.sum).toFixed(2) : item.sum}
                style={{ marginBottom: 0 }}>
                <Input type="number" step={0.01} className={css['project-cfc-input']} />
              </Form.Item>
            </div>
          ),
          type: 'component',
        },
      ]) as any);
    return (
      <Spin spinning={fetchingData}>
        <Modal
          className="common-modal"
          title={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ fontSize: 20, fontWeight: 600 }}>{`${t('projects.cfc')} ${
                CFC ? `${CFC.code}-${CFC.description}` : ''
              }`}</div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  className={`common-gray-border-btn common-secondary-btn`}
                  style={{ marginRight: '1rem' }}
                  onClick={handleClose}>
                  {t('budget.modal.cancel.btn')}
                </Button>
                <Button className={`common-green-btn common-modal-btn`} onClick={onSave}>
                  {t('budget.modal.save.btn')}
                </Button>
              </div>
            </div>
          }
          centered
          width={'50rem'}
          visible={isOpen}
          closable={false}
          footer={null}>
          <Form autoComplete="off" onFinish={onSubmit} form={form} style={{ overflow: 'auto' }}>
            <TableMobile
              data={data}
              isLoading={fetchingData}
              option={{}}
              itemsLength={CFCitems.length}
              totalItems={CFCitems.length}
            />
          </Form>
        </Modal>
      </Spin>
    );
  }

  return (
    <Spin spinning={fetchingData}>
      <Modal className="common-modal" centered width={'50rem'} visible={isOpen} closable={false} footer={null}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
          <div style={{ fontSize: 20, fontWeight: 600 }}>{`${t('projects.cfc')} ${
            CFC ? `${CFC.code}-${CFC.description}` : ''
          }`}</div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              className={`common-gray-border-btn common-secondary-btn`}
              style={{ marginRight: '1rem' }}
              onClick={handleClose}>
              {t('budget.modal.cancel.btn')}
            </Button>
            <Button className={`common-green-btn common-modal-btn`} onClick={onSave}>
              {t('budget.modal.save.btn')}
            </Button>
          </div>
        </div>
        <ProjectControl isModalCFC={true} CFC={CFC} onStatusChange={onStatusChange} onFiltersChange={onFiltersChange} />
        <Form autoComplete="off" onFinish={onSubmit} form={form}>
          <Table
            columns={getModalColumns({
              t,
              currency,
            })}
            size="small"
            style={{ background: 'transparent' }}
            className="consumers-info-table"
            dataSource={CFCitems.filter((item) => item.description?.toLowerCase().includes(searchValue?.toLowerCase()))}
            // dataSource={CFCitems.filter((item) =>
            //   groupValue
            //     ? item.description?.toLowerCase().includes(searchValue?.toLowerCase()) && item.group == groupValue
            //     : item.description?.toLowerCase().includes(searchValue?.toLowerCase()),
            // )}
            showHeader={true}
            pagination={false}
            scroll={{ y: windowHeight - 420, x: 600 }}
            rowKey="id"
          />
        </Form>
      </Modal>
    </Spin>
  );
};

export default ProjectCFCModal;
