import { TOGGLE_MENU } from '../constants/actionTypes/navConstants';

export const initialState = {
  isMenuCollapsed: false,
};

export function reducer(state: any, action: any) {
  switch (action.type) {
    case TOGGLE_MENU:
      return { isMenuCollapsed: action.isMenuCollapsed };
    default:
      return initialState;
  }
}
