import { Button, Modal, notification } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../../assets/styles/budget.module.css';
import { ICreateQuoteModel } from '../../../../models/QuotesModel';
import QuotesService from '../../../../services/QuoteService';
import useCloseTabWarning from '../../../../hooks/useCloseTabWarning';

interface ConfirmProps {
  isOpenConfirm: boolean;
  setIsOpenConfirm: (b: boolean) => void;
  createdBody: ICreateQuoteModel;
  setIsOpenUpload: (b: boolean) => void;
  setIsCreatedInvoice: (b: boolean) => void;
  setIsOpenStatusForm: (b: boolean) => void;
  setCurrentQuote: (n: number) => void;
  fileAttachmentList: UploadFile[];
  setIsOpenUploadMessageModal: (b: boolean) => void;
}
const service = new QuotesService();

const ConfirmUploadForm = (props: ConfirmProps) => {
  const {
    isOpenConfirm,
    setIsOpenConfirm,
    createdBody,
    setIsOpenUpload,
    setIsCreatedInvoice,
    setIsOpenStatusForm,
    setCurrentQuote,
    fileAttachmentList,
    setIsOpenUploadMessageModal,
  } = props;

  const [fetchingData, setFetchingData] = useState(false);
  const { t } = useTranslation();
  const { routerPrompt: Prompt, setIsDirty } = useCloseTabWarning(t('common.modal.onbeforeunload.message'));
  useEffect(() => {
    setIsDirty(fetchingData);
  }, [fetchingData]);

  const setClose = () => {
    setIsOpenConfirm(false);
  };

  const onSubmit = () => {
    setFetchingData(true);

    const filesData = new FormData();
    for (const file of fileAttachmentList) {
      if (file.originFileObj) {
        filesData.append('attachments', file.originFileObj);
      }
    }

    service
      .createQuote(createdBody)
      .then(async (value) => {
        const file = await service.getQuoteFile(value.file).then((res) => {
          return res;
        });

        filesData.append('file', file);
        setIsOpenUploadMessageModal(true);
        service
          .uploadQuoteFiles(value.id, filesData)
          .then(() => {
            setIsOpenConfirm(false);
            setCurrentQuote(value.id);
            setIsOpenUpload(false);
            notification.success({
              message: t('quotes.confirm.created'),
            });
            setIsOpenStatusForm(true);
          })
          .catch((e) => {
            notification.error({
              message: e.message || t('common.error.internalServerError'),
            });
          });
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => {
        setFetchingData(false);
        setIsCreatedInvoice(true);
      });
  };

  return (
    <Modal
      visible={isOpenConfirm}
      centered
      footer={
        <div className="common-modal-footer">
          <Button
            className={`common-gray-border-btn common-secondary-btn`}
            disabled={fetchingData}
            onClick={() => setClose()}>
            {t('budget.modal.cancel.btn')}
          </Button>
          <Button className={`common-green-btn common-modal-btn`} disabled={fetchingData} onClick={() => onSubmit()}>
            {t('budget.modal.save.btn')}
          </Button>
        </div>
      }
      closable={false}
      className="common-modal">
      <div className={css['confirm-msg-container']}>
        <span style={{ fontWeight: 600, fontSize: '18px', marginBottom: '1rem' }}>
          {t('budget.title.confirmation')}
        </span>
        <span style={{ color: '#778dac', textAlign: 'center' }}>{t('quotes.confirm.warning')}</span>
      </div>
      {Prompt}
    </Modal>
  );
};
export default ConfirmUploadForm;
