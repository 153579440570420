import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { TypologiesContext } from '../../../contexts/TypologiesContextProvider';
import { Button, Card, Space, Spin, Typography } from 'antd';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { CloseOutlined, SaveOutlined, FileAddOutlined, CopyOutlined } from '@ant-design/icons';
import {
  TYPOLOGY_SET_CURRENT_NODE,
  TYPOLOGY_SET_IS_SELECTED_NODE,
} from '../../../constants/actionTypes/typologiesConstants';
import { useLessThen801 } from '../../../helpers/mediaDetect';

interface NodesModifyLayoutModel {
  submit: (values: any) => void;
  cancel?: () => void;
  loading?: boolean;
  isSaving?: boolean;
  isShowCreate?: boolean;
  isShowApply?: boolean;
  isShowSave?: boolean;
  isCustomCancel?: boolean;
  onCreate?: () => void;
  onHandleAssign?: () => void;
  children: any;
}

const CardTitle = ({
  t,
  id,
  submit,
  cancel,
  isShowCreate,
  isShowApply,
  isShowSave,
  onCreate,
  onHandleAssign,
  currentNodeKey,
}: any) => (
  <div className="modify-title-header">
    <Typography.Text type="secondary">{!id ? `Create ${currentNodeKey}` : `# ${id}`}</Typography.Text>
    <div className="modify-action-btn">
      <Space>
        {isShowApply && (
          <Button size="small" onClick={onHandleAssign} title={t('space.object.option.assign.btn.title')}>
            <CopyOutlined />
          </Button>
        )}
        {isShowCreate && (
          <Button size="small" onClick={onCreate}>
            <FileAddOutlined />
          </Button>
        )}
        {isShowSave && (
          <Button size="small" type="primary" onClick={submit}>
            <SaveOutlined />
          </Button>
        )}
        <Button size="small" onClick={cancel}>
          <CloseOutlined />
        </Button>
      </Space>
    </div>
  </div>
);

const NodesModifyLayout: React.FC<NodesModifyLayoutModel> = ({
  submit,
  cancel = () => {},
  children,
  loading = false,
  isSaving = false,
  isShowCreate = false,
  isShowApply = false,
  isShowSave = true,
  isCustomCancel = false,
  onCreate,
  onHandleAssign,
}: NodesModifyLayoutModel) => {
  const { t } = useTranslation();
  const { nodes, nodesDispatch } = useContext(TypologiesContext);
  const { height } = useWindowDimensions();

  const customCancel = () => {
    if (isCustomCancel) {
      cancel();
    } else {
      nodesDispatch({
        type: TYPOLOGY_SET_IS_SELECTED_NODE,
        isSelectedNode: false,
      });
      nodesDispatch({
        type: TYPOLOGY_SET_CURRENT_NODE,
        currentNode: null,
        currentNodeKey: null,
      });
    }
  };

  return (
    <Card
      title={
        <CardTitle
          t={t}
          id={nodes?.currentNode?.id}
          submit={submit}
          cancel={customCancel}
          isShowCreate={isShowCreate}
          isShowApply={isShowApply}
          isShowSave={isShowSave}
          onCreate={onCreate}
          onHandleAssign={onHandleAssign}
          currentNodeKey={nodes?.currentNodeKey}
        />
      }
      style={useLessThen801() ? {} : { height: height - 300, overflow: 'auto' }}
      loading={loading}>
      <Spin spinning={isSaving}>{children}</Spin>
    </Card>
  );
};

export default NodesModifyLayout;
