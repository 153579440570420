import HttpService from './common/httpService';
import config from '../config';
import { getApiKeyOptions } from '../helpers/getApiKeyOptions';
import buildQuery from 'odata-query';

class CFCService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getCFC(projectKey: string, option: any) {
    const { top, count, skip, filters } = option;

    let filter: any = [];

    if (filters) {
      filter = [...filters.filter];
      filter.sort((a: any, b: any) =>
        Object.keys(Object.values(a)[0] as any)[0] == 'contains'
          ? -1
          : Object.keys(Object.values(b)[0] as any)[0] == 'contains'
          ? 1
          : 0,
      );
    }

    const options = getApiKeyOptions(projectKey);
    const query = buildQuery({ filter, top, count, skip });
    return this.httpService.get(`${config.baseRequest}cfcCodes${query}`, options);
  }

  public getCFCitems(code: string, projectKey: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.get(`${config.baseRequest}cfcCodes/${code}/items`, options);
  }

  public updateCFC(code: string, body: any, projectKey: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.put(`${config.baseRequest}cfcCodes/${code}`, body, options);
  }
}

export default CFCService;
