import { useLocation } from 'react-router';

const useGetPageTypeConfig = () => {
  const location = useLocation();
  const pageType = location.pathname.slice(17, location.pathname.length);
  const isDocuments = pageType === 'documents';
  const isQuotes = pageType === 'quotes';
  const isInvoices = pageType === 'invoices';
  const isAmendments = pageType === 'amendments';
  return { isDocuments, isQuotes, isInvoices, isAmendments };
};

export default useGetPageTypeConfig;
