import { Button, DatePicker, Input, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../assets/styles/budget.module.css';
import { useLocation } from 'react-router';
import { FilterTypes } from '../../../constants/filterTypes';
import searchIcon from '../../../assets/images/ic-search.svg';
import { StatusesDocumentsModel } from '../../../models/DocumentsModel';
import { AppContext } from '../../../contexts/AppContextProvider';
import { useLessThen801 } from '../../../helpers/mediaDetect';
import { getSearchParam } from '../../../helpers/customUrlSearchParams';
import CustomSignFlow from '../../../services/CustomSignFlow';
import { setTextColorByStatus } from '../../../helpers/setTextColorByStatus';
import { generateFilterfromObj } from '../../../helpers/generateFIlterfromObj';
import { FiltersContext } from '../../../contexts/FiltersContextProvider';
import { SET_FINAL_ACCOUNTS_FILTERS } from '../../../constants/actionTypes/filtersConstants';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { IFinalAccountModel } from '../../../models/FinalAccountModel';
import { RangePickerProps } from 'antd/lib/date-picker';
import { IAppContext } from '../../../typings/IApp';

interface IFinalAccountControl {
  openCreate?: (b: boolean) => void;
  setIsOpenModal: (b: boolean) => void;
  propertyId: number;
  onTableChange: (
    pagination?: TablePaginationConfig,
    filters?: Record<string, FilterValue | null | any>,
    sorter?: SorterResult<IFinalAccountModel> | SorterResult<IFinalAccountModel>[],
  ) => void;
}

const { Option } = Select;
const { Search } = Input;
const { RangePicker } = DatePicker;

const customFlowService = new CustomSignFlow();

const FinalAccountControl = ({ openCreate, setIsOpenModal, propertyId, onTableChange }: IFinalAccountControl) => {
  const { t } = useTranslation();
  const {
    app: { isConsumer },
  } = useContext<IAppContext>(AppContext);
  const {
    filters: { finalAccountsFilter },
    filtersDispatch,
  } = useContext(FiltersContext);
  const { search } = useLocation();
  const nameQuery = getSearchParam(search, 'name');
  const [searchValue, setSearchValue] = useState(nameQuery || '');
  const [statuses, setStatuses] = useState<StatusesDocumentsModel>([]);
  const [currentTimeout, setCurrentTimeout] = useState<NodeJS.Timeout | null>(null);
  const [createdDate, setCreatedDate] = useState<RangePickerProps['value']>(null);
  const [status, setStatus] = useState(finalAccountsFilter.status);

  useEffect(() => {
    customFlowService
      .getStatuses({
        filters: generateFilterfromObj({
          'doc_types_statuses/typeCode': {
            type: FilterTypes.SELECT,
            value: 'finalAccount',
          },
        }),
      })
      .then((value: StatusesDocumentsModel) => setStatuses(value))
      .catch((e) => console.log(e));
    setStatus(finalAccountsFilter.status);
  }, [propertyId]);

  useEffect(() => {
    let body: {
      [name: string]: {
        type: string;
        value: any;
      };
    } = {};
    if (nameQuery) {
      body['number'] = {
        type: FilterTypes.SEARCH,
        value: nameQuery,
      };
    } else {
      setSearchValue('');
      body['number'] = {
        type: FilterTypes.SEARCH,
        value: '',
      };
    }
    onTableChange(undefined, body);
  }, [nameQuery]);

  const onChangeStatus = (value: string) => {
    setStatus(value);
    filtersDispatch({
      type: SET_FINAL_ACCOUNTS_FILTERS,
      filters: {
        status: value,
      },
    });
    let body = {
      'status_v2/code': {
        type: FilterTypes.SELECT,
        value: value,
      },
    };
    onTableChange(undefined, body);
  };

  const onSearch = (value: string) => {
    setSearchValue(value || '');
    const body = {
      number: {
        type: FilterTypes.SEARCH,
        value: value,
      },
    };

    clearTimeout(Number(currentTimeout));
    setCurrentTimeout(setTimeout(() => onTableChange(undefined, body), 1000));
  };

  const changeCreatedDate = (value: RangePickerProps['value']) => {
    setCreatedDate(value || null);
    const body = {
      createdDate: {
        type: FilterTypes.DATE_RANGE,
        value: value,
      },
    };
    onTableChange(undefined, body);
  };

  return (
    <div className={css['budget-control-container']}>
      <div className={css['budget-filter-control']}>
        <div className={`${css['budget-filters']} budget-control-wrapper`}>
          <Select
            className={`budget-control-select common-animation-primary`}
            dropdownClassName="budget-control-select-dropdown"
            onChange={onChangeStatus}
            value={status}
            placeholder={t('budget.control.filter.status.placeholder')}
            suffixIcon={
              <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
            }>
            <Option value="" className="budget-control-option">
              <span style={{ fontSize: '14px', fontWeight: 600 }}>{t('budget.control.select.all')}</span>
            </Option>
            {statuses.length > 0 &&
              statuses.map((status) => (
                <Option value={status.code} key={status.code} className="budget-control-option">
                  <span style={{ fontSize: '14px', fontWeight: 600, color: setTextColorByStatus(status.code) }}>
                    {status.description}
                  </span>
                </Option>
              ))}
          </Select>
        </div>
        <Search
          onChange={(e) => onSearch(e.target.value)}
          placeholder={t('budget.search.number.placeholder')}
          allowClear
          value={searchValue}
          className="budget-control-search common-animation-primary"
          suffix={<img src={searchIcon} alt="" />}
          style={{ width: '195px', marginLeft: '5px', padding: '1px 0' }}
        />
        <RangePicker
          suffixIcon={<div className={'calendar-img'} style={{ width: '12px', height: '12px' }}></div>}
          className="budget-control-range-picker common-animation-primary"
          style={{ height: '34px', padding: '10px 5px' }}
          value={createdDate}
          format="DD.MM.YYYY"
          placeholder={[t('report.table.columns.start'), t('report.table.columns.end')]}
          inputReadOnly
          onChange={changeCreatedDate}
        />
      </div>
      {!isConsumer && !useLessThen801() && (
        <div style={{ display: 'flex' }}>
          <Button
            className={`common-blue-border-btn common-secondary-btn`}
            onClick={() => setIsOpenModal(true)}
            style={{ height: '42px' }}>
            <span>{t('document.upload')}</span>
          </Button>
          <Button
            className={`common-blue-btn common-primary-btn`}
            onClick={() => openCreate && openCreate(true)}
            style={{ height: '42px', marginLeft: '1rem' }}>
            <span>{t('budget.control.create.btn')}</span>
          </Button>
        </div>
      )}
    </div>
  );
};

export default FinalAccountControl;
