import { Button, Modal, Upload, notification, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import css from '../../../assets/styles/budget.module.css';
import { useTranslation } from 'react-i18next';
import config from '../../../config';
import QuotesService from '../../../services/QuoteService';
import { IQuoteModel } from '../../../models/QuotesModel';
import { UploadFile } from 'antd/lib/upload/interface';

interface QuoteModalProps {
  isOpenModal: boolean;
  currentId: number | null;
  getQuotes: () => void;
  setClose: () => void;
}

const service = new QuotesService();

const ConsumerQuoteForm = (props: QuoteModalProps) => {
  const { isOpenModal, currentId, getQuotes, setClose } = props;
  const { t } = useTranslation();
  const [quotesInfo, setQuotesInfo] = useState<IQuoteModel | null>(null);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fetchingData, setFetchingData] = useState(false);

  useEffect(() => {
    setFetchingData(true);
    if (currentId) {
      service
        .getQuoteInfoById(currentId)
        .then((value: IQuoteModel) => {
          if (value.file) {
            setFileList([
              {
                name: `${value.file.slice(0, 40)}...`,
                uid: value.file,
                url: `${config.storeUrl}/${value.file}`,
              },
            ]);
          }
          setQuotesInfo(value);
        })
        .catch((e) => console.log(e));
    }
  }, []);

  const successUpdate = () => {
    setClose();
    notification.success({
      message: t('quotes.confirm.update'),
    });
    getQuotes();
  };

  const onSubmit = (value: string) => {
    if (quotesInfo) {
      service
        .updateQuote({
          ...quotesInfo,
          statusV2: value,
        } as any)
        .then(() => successUpdate())
        .catch((e) => {
          notification.error({
            message: e.message || t('common.error.internalServerError'),
          });
        })
        .finally(() => {
          setClose();
          setFetchingData(false);
        });
    }
  };

  return (
    <Spin spinning={fetchingData}>
      <Modal centered visible={isOpenModal} footer={null} closable={false} style={{ height: '23rem' }}>
        <div style={{ padding: '1rem' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span>{`${t('quotes.upload.overview.fields.quote')}: ${quotesInfo?.number || ''}`}</span>
            <Upload
              listType="picture-card"
              className="consumer-quote-upload"
              showUploadList={{ showRemoveIcon: false }}
              beforeUpload={() => false}
              fileList={fileList}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '4rem' }}>
            <Button
              className={css['upload-btn']}
              style={{ width: '100%', marginTop: '1rem' }}
              onClick={() => onSubmit('approved')}>
              {t('quotes.status.approve.btn')}
            </Button>
            <Button
              className={css['return-btn']}
              style={{ width: '100%', marginTop: '1rem' }}
              onClick={() => onSubmit('rejected')}>
              {t('quotes.status.reject.btn')}
            </Button>
          </div>
        </div>
      </Modal>
    </Spin>
  );
};

export default ConsumerQuoteForm;
