import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../../assets/styles/dashboard.module.css';
import { AppContext } from '../../../../contexts/AppContextProvider';
import CalendarService from '../../../../services/CalendarServices';
import { IAppContext } from '../../../../typings/IApp';
import PostCard from '../../../Posts/components/PostCard';

const service = new CalendarService();

const DashboardLastPosts = () => {
  const { t } = useTranslation();
  const {
    app: { isConsumer },
  } = useContext<IAppContext>(AppContext);

  const [posts, setPosts] = useState<any>([]);

  const option = {
    top: 5,
    orderBy: ['createdDate desc'],
  };

  useEffect(() => {
    getLastCalendarUpdates(option);
  }, []);

  const getLastCalendarUpdates = (option: { [name: string]: any }) => {
    service
      .getCalendarUpdates(option)
      .then(setPosts)
      .catch((e) => console.log(e));
  };

  return (
    <div className={css['consumers-info']} style={isConsumer ? { marginBottom: 0, marginTop: '1rem' } : {}}>
      <div style={{ color: '#000', fontSize: '18px', marginBottom: '1rem' }}>{t('dashboard.post.title')}</div>
      {posts.length > 0 && posts.map((post: any) => <PostCard isConsumer={true} key={post.id} item={post} />)}
    </div>
  );
};

export default DashboardLastPosts;
