import { Avatar, Form, Select, Space, Upload, Image } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../../../assets/styles/budget.module.css';
import config from '../../../../../config';
import { PaymentInfo } from '../../../../../models/CompaniesModel';
import { IProjectFull, ProjectCompanyModel } from '../../../../../models/ProjectModel';

interface IAmendmentPayment {
  paymentsProfile: PaymentInfo[] | null;
  setPaymentProfile: (v: PaymentInfo | null) => void;
  selectCompany: ProjectCompanyModel | undefined;
  project: IProjectFull | null;
  setSelectCompany: (v: ProjectCompanyModel | undefined) => void;
  paymentProfile: PaymentInfo | null;
}

const AmendmentPayment = ({
  paymentsProfile,
  setPaymentProfile,
  selectCompany,
  project,
  setSelectCompany,
  paymentProfile,
}: IAmendmentPayment) => {
  const { t } = useTranslation();
  const [logoUrl, setLogoUrl] = useState<string | null>(null);

  useEffect(() => {
    if (selectCompany?.logo) {
      setLogoUrl(`${config.storeUrl}/${selectCompany.logo}`);
    } else {
      setLogoUrl(null);
    }
  }, [selectCompany]);

  const onChangePaymentProfile = (idProfile: number) => {
    if (paymentsProfile && paymentsProfile.length > 0) {
      setPaymentProfile(paymentsProfile.find((el: PaymentInfo) => el.id === idProfile) || null);
    }
  };

  useEffect(() => {
    if (paymentsProfile && paymentsProfile.length > 0 && project?.defaultPaymentInfoId && !paymentProfile) {
      setPaymentProfile(paymentsProfile.find((el: PaymentInfo) => el.id === project.defaultPaymentInfoId) || null);
    }
  }, [paymentsProfile]);

  useEffect(() => {
    if (paymentProfile) {
      setSelectCompany(
        project?.companies.find((company) => company.paymentInfo.find((payment) => payment.id === paymentProfile.id)),
      );
    }
  }, [paymentProfile]);

  return (
    <>
      <div
        className="invoice-select-container-paymentProfile"
        style={{ display: 'flex', alignItems: 'center', marginBottom: '24px' }}>
        <span style={{ marginRight: '1rem' }}>{t('budget.payment.profile.select')}</span>
        <Form.Item name="defaultPaymentInfoId">
          <Select
            className="consumers-control-select common-animation-primary"
            onChange={onChangePaymentProfile}
            getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
            style={{ marginTop: 0 }}>
            {paymentsProfile &&
              paymentsProfile
                .sort((a, b) => a.accountHolder.toLowerCase().localeCompare(b.accountHolder.toLowerCase()))
                .map((item: PaymentInfo) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.accountHolder}
                  </Select.Option>
                ))}
          </Select>
        </Form.Item>
      </div>
      <div className={css['upload-user-project-container']}>
        <div className={css['upload-user-container']}>
          {logoUrl && (
            <Avatar
              src={
                <>
                  <Image src={logoUrl} preview={false} />
                  <div className="logo-mask">
                    <Space>
                      <Upload accept=".png, .jpg, .jpeg" showUploadList={false} customRequest={() => {}} />
                    </Space>
                  </div>
                </>
              }
              size={60}
              className="consumer-avatar"
            />
          )}
          <span style={{ fontWeight: 'bold', color: '#778dac' }}>{selectCompany?.companyName || ''}</span>
          <span style={{ color: '#778dac' }}>{`${selectCompany?.street || ''}`}</span>
          <span style={{ color: '#778dac' }}>{`${selectCompany?.zip || ''} ${selectCompany?.city || ''} ${
            selectCompany?.state || ''
          } ${selectCompany?.country || ''}`}</span>
          <span style={{ color: '#778dac' }}>{selectCompany?.email || ''}</span>
          <span style={{ color: '#778dac' }}>{`${paymentProfile?.taxId || ''} ${paymentProfile?.vatId || ''}`}</span>
        </div>
      </div>
    </>
  );
};

export default AmendmentPayment;
