import React, { createContext, useReducer } from 'react';
import { reducer } from '../reducers/reportDynamicParamsReducer';

export const ReportDynamicParamsContext = createContext({} as any);

const ReportDynamicParamsProvider: React.FC = (props: any) => {
  const [data, dataDispatch] = useReducer(reducer, {});

  return <ReportDynamicParamsContext.Provider value={{ data, dataDispatch }}>{props.children}</ReportDynamicParamsContext.Provider>;
};

export default ReportDynamicParamsProvider;
