import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../assets/styles/budget.module.css';
import { CHANGE_CURRENT_PAGE } from '../../constants/actionTypes/headerConstants';
import { AppContext } from '../../contexts/AppContextProvider';
import { HeaderContext } from '../../contexts/HeaderContextProvider';
import { IAppContext } from '../../typings/IApp';
import NoPropertyPage from '../common/NoPropertyPage';
import BudgetListInfo from './components/BudgetListInfo';
import DiagramBudget from './components/DiagramComponent';
import i18n from '../../utils/i18n';

const ConsumerBudget = () => {
  const { t } = useTranslation();
  const { headerDispatch } = useContext(HeaderContext);
  const {
    app: { currentProject, currentProperty },
  } = useContext<IAppContext>(AppContext);

  useEffect(() => {
    headerDispatch({
      type: CHANGE_CURRENT_PAGE,
      currentPage: t('header.title.budget'),
      path: 'budget',
    });
  }, [i18n.language]);

  if (!currentProperty) {
    return <NoPropertyPage />;
  }

  return (
    <div className={css['container-сonsumer']}>
      <DiagramBudget
        propertyId={currentProperty.id}
        projectId={currentProject.id}
        currency={currentProject?.defaultCurrency?.description || t('budget.currency')}
      />
      <BudgetListInfo propertyId={currentProperty.id} />
    </div>
  );
};

export default ConsumerBudget;
