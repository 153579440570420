import React from 'react';
import { Input } from 'antd';

interface TextSearchDropDownModel {
  confirm: (params: any) => void;
  setSelectedKeys?: any;
  selectedKeys?: any;
  clearFilters?: any;
}

const TextSearchDropDown: React.FC<TextSearchDropDownModel> = ({
  confirm = () => {},
  setSelectedKeys,
  selectedKeys,
  clearFilters,
}: TextSearchDropDownModel) => {
  const onSearch = (value: string) => {
    confirm({
      closeDropdown: true,
    });
    if (!value) {
      clearFilters();
    }
  };

  return (
    <div style={{ padding: '10px' }}>
      <Input.Search
        enterButton
        allowClear
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onSearch={onSearch}
      />
    </div>
  );
};

export default TextSearchDropDown;
