import { Button, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../assets/styles/faq.module.css';

interface IConfirmChangeEmailModal {
  isOpen: boolean;
  onOk: () => void;
  onCancel: () => void;
}

const ConfirmChangeEmailModal = ({ isOpen, onOk, onCancel }: IConfirmChangeEmailModal) => {
  const { t } = useTranslation();
  return (
    <Modal
      className="common-modal"
      centered
      visible={isOpen}
      style={{ width: '10rem' }}
      footer={
        <div className="common-modal-footer">
          <Button className={`common-gray-border-btn common-secondary-btn`} onClick={onCancel}>
            {t('budget.modal.cancel.btn')}
          </Button>
          <Button className={`common-blue-btn common-modal-btn`} onClick={onOk}>
            {t('common.modal.confirm')}
          </Button>
        </div>
      }
      closable={false}>
      <span className={css['delete-question-title']}>{t('consumers.confirm.edit.email')}</span>
    </Modal>
  );
};

export default ConfirmChangeEmailModal;
