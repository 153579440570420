import { Button, notification, Popover } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FileMangmentFilter from './FileManagmentFilter';
import filterGreyIcon from '../../../../assets/images/ic-filter-grey.svg';
import filterIcon from '../../../../assets/images/ic-filter.svg';
import closeIcon from '../../../../assets/images/ic-btn-close.svg';
import { useForm } from 'antd/lib/form/Form';
import { FiltersContext } from '../../../../contexts/FiltersContextProvider';
import {
  CLEAR_FILEMANAGMENT_FILTERS,
  SET_FILEMANAGMENT_FILTERS,
} from '../../../../constants/actionTypes/filtersConstants';
import { StatusesDocumentsModel } from '../../../../models/DocumentsModel';
import { IAppContext } from '../../../../typings/IApp';
import { AppContext } from '../../../../contexts/AppContextProvider';
import ListPageServices from '../../../../services/PropertiesService';
import { isEmptyObject } from '../../../../helpers/isEmptyObject';
import moment from 'moment';
import { generateFiltersData } from '../../../../helpers/filterTools';
import { useLocation, useHistory } from 'react-router';
import { getSearchParam } from '../../../../helpers/customUrlSearchParams';
import { IStepType } from '../../../../models/CustomSignFlowModel';
import CustomSignFlow from '../../../../services/CustomSignFlow';
import { useLessThen801 } from '../../../../helpers/mediaDetect';
import { PropertyModel } from '../../../../models/PropertyModel';
import useGetPageTypeConfig from '../../hooks/useGetPageTypeConfig';
import useRequests from '../../hooks/useRequest';
import { FilterTypes } from '../../../../constants/filterTypes';

const serviceFlow = new CustomSignFlow();
const propertyService = new ListPageServices();

interface IProps {
  onTableChange: any;
  resetFiltersRef: any;
  setIsChecked?: (b: boolean) => void;
  setIsCheckedExpire: (b: boolean) => void;
}

const FileManagmentFilterPopup: React.FC<IProps> = (props) => {
  const { onTableChange, setIsChecked, setIsCheckedExpire, resetFiltersRef } = props;
  const { search, pathname } = useLocation();
  const history = useHistory();
  const statusCode = getSearchParam(search, 'statusCode');
  const propertyId = getSearchParam(search, 'propertyId');
  const projectId = getSearchParam(search, 'projectId');
  const deadline = getSearchParam(search, 'deadline');
  const name = getSearchParam(search, 'name');
  const pageTypeConfig = useGetPageTypeConfig();
  const { setStatusByPageType } = useRequests();
  const { t } = useTranslation();
  const [form] = useForm();
  const {
    app: { user },
  } = useContext<IAppContext>(AppContext);
  const {
    filters: { fileManagementFilter },
    filtersDispatch,
  } = useContext(FiltersContext);
  const isEmptyParams = !(statusCode || propertyId || projectId || name || deadline);
  const brokerProjects = user && user.projects.map((item) => item.id);
  const openFilterBtnRef = useRef<HTMLButtonElement>(null);
  const [isActiveFilters, setIsActiveFilters] = useState<boolean>(false);
  const [properties, setProperties] = useState<PropertyModel[]>([]);
  const [searchAgents, setSearchAgents] = useState<number[]>([]);
  const [searchConsumers, setSearchConsumers] = useState<number[]>([]);
  const [isSetFilters, setIsSetFilters] = useState<boolean>(false);
  const [currentTimeout, setCurrentTimeout] = useState<NodeJS.Timeout | null>(null);
  const [statuses, setStatuses] = useState<StatusesDocumentsModel>([]);
  const [steps, setSteps] = useState<IStepType[]>([]);

  useEffect(() => {
    resetFiltersRef.current = resetFilters;
    serviceFlow.getFlowSteps().then((res: IStepType[]) => setSteps(res));
    setStatusByPageType()
      .then((value: StatusesDocumentsModel) => setStatuses(value))
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      );
  }, []);

  useEffect(() => {
    if (user && user.projects && user.projects.length === 1) {
      onChangeProject(user.projects[0].id);
    }
  }, [user]);

  //set form with reducer
  useEffect(() => {
    clearTimeout(Number(currentTimeout));
    setCurrentTimeout(
      setTimeout(() => {
        if (!isSetFilters && isEmptyParams) {
          const fileldsValue = fileManagementFilter;
          const agentsId: number[] = [];
          const consumersId: number[] = [];
          for (const key in fileldsValue) {
            if (['createdDate', 'paymentDate', 'signatureDate', 'deadline'].includes(key) && fileldsValue[key]) {
              fileldsValue[key] = [moment(new Date(fileldsValue[key][0])), moment(new Date(fileldsValue[key][1]))];
            }
            if (key.includes('agent') && fileldsValue[key]) {
              agentsId.push(fileldsValue[key]);
            }
            if (key.includes('client') && fileldsValue[key]) {
              consumersId.push(fileldsValue[key]);
            }
            if (key === 'project' && fileldsValue[key]) {
              onChangeProject(fileldsValue[key]);
            }
          }

          setSearchAgents(Array.from(new Set(agentsId)));
          setSearchConsumers(Array.from(new Set(consumersId)));
          setIsActiveFilters(true);
          setIsSetFilters(true);
          form.setFieldsValue(fileldsValue);
          onSubmit(fileldsValue);
        }
      }, 1000),
    );
  }, [fileManagementFilter]);

  useEffect(() => {
    if (statusCode || propertyId || projectId || name || deadline) {
      if (projectId) {
        onChangeProject(+projectId)?.then((data) => {
          const body = {
            project: Number(projectId) || undefined,
            properties: propertyId ? [+propertyId] : undefined,
            search: name,
            status: statusCode,
            deadline: deadline,
          };

          if (propertyId) {
            const getChildrenIdsById = (data: PropertyModel[], id: number) => {
              let childrenIds: number[] = [];

              const getChildrenIds = (item: PropertyModel) => {
                if (item?.childrens?.length > 0) {
                  item.childrens.forEach((child) => getChildrenIds(child as any));
                } else {
                  childrenIds.push(item.id);
                }
              };

              const searchParentById = (item: PropertyModel) => {
                if (item.id === id) {
                  if (item?.childrens?.length > 0) {
                    item.childrens.forEach((child) => getChildrenIds(child as any));
                  } else {
                    childrenIds = [+propertyId];
                  }
                }
                if (item?.childrens?.length > 0) {
                  item.childrens.forEach((item) => searchParentById(item as any));
                }
              };

              data.forEach((item) => searchParentById(item));
              return childrenIds;
            };

            const childrenIds = getChildrenIdsById(data, +propertyId);
            if (childrenIds.length > 0) {
              if (childrenIds.length > 0) {
                body.properties = childrenIds;
              }
            }
          }

          form.setFieldsValue(body);
          onSubmit(body);
        });
        return;
      }

      const body = {
        project: Number(projectId) || undefined,
        properties: propertyId ? [+propertyId] : undefined,
        search: name,
        status: statusCode,
        deadline: deadline,
      };
      form.setFieldsValue(body);
      onSubmit(body);
    }
  }, [statusCode, propertyId, projectId, name, deadline]);

  const resetFilters = (isNeedSubmit: boolean = false) => {
    history.push(pathname.split('/').pop() as string);
    filtersDispatch({ type: CLEAR_FILEMANAGMENT_FILTERS });
    setIsActiveFilters(false);
    form.resetFields();
    if (isNeedSubmit) {
      onSubmit({});
    }
  };

  const onChangeProject = (projectId: number) => {
    form.setFieldsValue({ properties: undefined });
    if (projectId) {
      const projectKey = (user && user.projects.find((project) => project.id === projectId)?.key) || '';
      return propertyService
        .getLightTreeByProject({
          id: projectId,
          projectKey,
        })
        .then((data: PropertyModel[]) => {
          setProperties(data);
          return data;
        });
    } else {
      setProperties([]);
    }
  };

  const onValuesChange = (changedValues: any, values: any) => {
    if (!!changedValues.project) {
      onChangeProject(+changedValues.project);
    }
  };

  const checkProperties = (obj: { [name: string]: any }) => {
    if (isEmptyObject(obj)) {
      return false;
    }
    for (let key in obj) {
      if (obj[key]) {
        return true;
      }
    }
    return false;
  };

  const onSubmit = (values: any) => {
    setIsActiveFilters(values.isCheck ? false : checkProperties(values));
    setIsChecked && setIsChecked(values.isCheck);
    setIsCheckedExpire(false);
    setIsSetFilters(true);
    filtersDispatch({
      type: SET_FILEMANAGMENT_FILTERS,
      filters: values,
    });
    if (!checkProperties(values)) {
      filtersDispatch({
        type: CLEAR_FILEMANAGMENT_FILTERS,
      });
    }

    const body = generateFiltersData(values, pageTypeConfig.isDocuments);

    if (values.isCheck) {
      delete body.isCheck;
      body['current_step/consumerId'] = {
        type: FilterTypes.SELECT,
        value: user.id,
      };
      body['status_v2/code'] = {
        type: FilterTypes.NOT,
        value: 'rejected',
      };
    }

    onTableChange(null, body);
  };

  if (useLessThen801()) {
    return (
      <FileMangmentFilter
        {...props}
        form={form}
        onSubmit={onSubmit}
        onValuesChange={onValuesChange}
        properties={properties}
        resetFilters={resetFilters}
        openFilterBtnRef={openFilterBtnRef}
        brokerProjects={brokerProjects}
        searchAgents={searchAgents}
        searchConsumers={searchConsumers}
        steps={steps}
        statuses={statuses}
      />
    );
  }

  return (
    <Popover
      placement="bottomLeft"
      content={() => (
        <FileMangmentFilter
          {...props}
          form={form}
          onSubmit={onSubmit}
          onValuesChange={onValuesChange}
          properties={properties}
          resetFilters={resetFilters}
          openFilterBtnRef={openFilterBtnRef}
          brokerProjects={brokerProjects}
          searchAgents={searchAgents}
          searchConsumers={searchConsumers}
          steps={steps}
          statuses={statuses}
        />
      )}
      trigger="click">
      <Button
        ref={openFilterBtnRef}
        className={`common-gray-border-btn common-secondary-btn`}
        style={{ height: '42px' }}>
        <img src={isActiveFilters ? filterIcon : filterGreyIcon} style={{ marginRight: '0.5rem' }} />
        {t('common.filter.open')}
        {isActiveFilters && (
          <img
            src={closeIcon}
            style={{ marginLeft: '0.5rem' }}
            onClick={(e) => {
              e.stopPropagation();
              resetFilters(true);
            }}
          />
        )}
      </Button>
    </Popover>
  );
};

export default FileManagmentFilterPopup;
