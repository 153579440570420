export const convertToPrice = (x?: number) => {
  const coins = convertToCurrency(x);
  if (typeof coins === 'number') {
    return coins.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, '’');
  }
  return;
};

export const convertToCoins = (number: any) => {
  if (number === undefined || number === null || number === '') {
    return number;
  }

  const n = typeof number === 'string' ? parseFloat(number) : number;
  const splitNumber = n.toString().split('.');
  if (splitNumber.length === 1) {
    splitNumber.push('00');
  }
  if (splitNumber.length === 2) {
    if (splitNumber[1].length < 2) {
      splitNumber[1] += '0';
    }
    if (splitNumber[1].length > 2) {
      splitNumber[1] = splitNumber[1].slice(0, 2);
    }
  }
  return Number(splitNumber.join(''));
};

export const convertToCurrency = (number: any) => {
  if (number === undefined || number === null || number === '') {
    return number;
  }

  const n = typeof number === 'string' ? parseFloat(number) : number;

  return n / 100;
};

export const convertToPriceWithSpace = (number: any) => {
  if (number === undefined || number === null || number === '') {
    return number;
  }
  if (Number.isInteger(+number)) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
  let parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  if (parts[1].length > 2) {
    parts[1] = parts[1].substr(0, 2);
  }
  return parts.join(',');
};
export const reverseConvertToPriceWithSpace = (number: any, currency: string = 'CHF') => {
  if (number === undefined || number === null || number === '') {
    return number;
  }
  const regex = new RegExp(currency, 'g');
  return +number!.replace(/[,]/g, '.').replace(regex, '').replaceAll(' ', '');
};

export const convertToPercentage = (number: number) => {
  return 1 + number / 100;
};
