import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Button,
  Row,
  Col,
  Card,
  Upload,
  Select,
  TreeSelect,
  Spin,
  notification,
  Input,
  InputNumber,
  Divider,
} from 'antd';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import ProductService from '../../services/ProductService';
import { PlusOutlined } from '@ant-design/icons';
import DynamicFields from '../common/DynamicFields';
import ArticlesService from '../../services/ArticlesService';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import ArticleModifyForm from './components/ArticleModifyForm';
import { productParamCodes } from '../../constants/productParamCodes';
import { articleCategoryTypes } from '../../constants/articleCategoryTypes';
import { productFileTypes } from '../../constants/productFileTypes';
import ArticleNameLayout from '../../components/common/ArticleNameLayout';
import { ProductsContext } from '../../contexts/ProductsContextProvider';
import css from '../../assets/styles/product.module.css';
import { useLessThen801 } from '../../helpers/mediaDetect';

const service: ProductService = new ProductService();
const articleService: ArticlesService = new ArticlesService();

const ProductCreatePage: React.FC = (props: any) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { products } = useContext(ProductsContext);
  const [form] = Form.useForm();
  const [productParams, setProductParams] = useState<any[]>([]);
  const [fields, setFields] = useState<any>([]);
  const [images, setImages] = useState([]);
  const [textureFile, setTextureFile] = useState<any>([]);
  const [glbFile, setGlbFile] = useState<any>([]);

  const [articleCategories, setArticleCategories] = useState([]);
  const [articles, setArticles] = useState([]);
  const [initialArticleFormValue, setInitialArticleFormValue] = useState<any>();

  const [loading, setLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [breadcrumbNameMap, setBreadcrumbNameMap] = useState<{ [name: string]: string }>({});
  const [loadingArticles, setLoadingArticles] = useState<boolean>(false);
  const [isShowArticles, setIsShowArticles] = useState<boolean>(false);
  const [isShowProduct, setIsShowProduct] = useState<boolean>(false);
  const [isCreateArticle, setIsCreateArticle] = useState<boolean>(false);
  const [isShowAddSelect, setIsShowAddSelect] = useState(false);
  const [isShowTexture, setIsShowTexture] = useState<boolean>(false);
  const [isShowGblFile, setIsShowGblFile] = useState<boolean>(false);
  const [isUploadTexture, setIsUploadTexture] = useState<boolean>(false);
  const [isUploadGlbFile, setIsUploadGlbFile] = useState<boolean>(false);

  const initialMediaComponents = (type: any) => {
    if (type) {
      switch (type.name) {
        case articleCategoryTypes.finishes: {
          setIsShowTexture(true);
          break;
        }
        case articleCategoryTypes.equipment:
        case articleCategoryTypes.interior: {
          setIsShowGblFile(true);
        }
      }
    }
  };

  const getProductParams = () => {
    return service.getProductParams().then((res: any) => {
      setProductParams(res);
    });
  };

  const getArticleCategories = () => {
    return articleService.getSimpleArticleCategories().then((res: any) => {
      if (res && res.length > 0) {
        setArticleCategories(
          res.map((el: any) => ({
            ...el,
            parentId: el.parent ? el.parent.id : null,
          })),
        );
      }
    });
  };

  const getArticles = (filter: any = null) => {
    setLoadingArticles(true);
    return articleService
      .getArticles(filter)
      .then((res: any) => {
        setArticles(res);
      })
      .finally(() => {
        setLoadingArticles(false);
      });
  };

  const getAsyncData = async () => {
    const actions = [getProductParams(), getArticleCategories()];
    Promise.all(actions).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    getAsyncData();
  }, []);

  useEffect(() => {
    const { duplicate = undefined } = products;
    if (duplicate) {
      const { article = undefined, ...rest } = duplicate;
      if (article) {
        const filter = {
          and: [
            {
              'category/id': {
                eq: article.category.id,
              },
            },
          ],
        };
        getArticles(filter);
        setIsShowArticles(true);
        setIsShowProduct(true);
        setFields(rest.fields.map((item: any) => ({ ...item.field, value: item.value })));
        const fields = rest.fields.reduce(
          (obj: any, item: any) => ({
            ...obj,
            [item.field.code]: { value: item.value },
          }),
          {},
        );
        form.setFieldsValue({
          price: rest.price,
          article: {
            id: article.id,
            category: { id: article.category.id },
          },
          fields,
        });
        initialMediaComponents(article.category?.type);
      }
    }
  }, [products.duplicate]);

  useEffect(() => {
    setBreadcrumbNameMap(
      history.location.pathname
        .replace('/products', '')
        .replace(/\/create/, `/${t('common.title.create')}`)
        .split('/')
        .slice(1)
        .reduce(
          (res: any, curr: any, index: number) => ({
            ...res,
            [[Object.keys(res)[index], curr].join('/')]: curr.split(':').shift(),
          }),
          { '/products': t('product.bread.root') },
        ),
    );
  }, [history.location.pathname]);

  const onSubmit = async () => {
    const values = await form.validateFields();
    const hasProperty = Object.prototype.hasOwnProperty;
    if (!hasProperty.call(values, 'errorFields')) {
      setIsSaving(true);
      const modelActions = (id: number) => {
        const promises: Promise<any>[] = [];
        if (images && images.length > 0) {
          promises.push(
            service.uploadImages({
              id,
              type: productFileTypes.picture,
              files: images,
            }),
          );
        }
        if (isUploadTexture && textureFile) {
          promises.push(
            service.uploadImages({
              id,
              type: productFileTypes.texture,
              files: textureFile,
            }),
          );
        }

        if (isUploadGlbFile && glbFile) {
          promises.push(
            service.uploadImages({
              id,
              type: productFileTypes.glb,
              files: glbFile,
            }),
          );
        }

        return Promise.all(promises);
      };
      const { images: bodyImages, ...data } = values;
      const body = {
        ...data,
        fields: Object.entries(values?.fields || []).map(([code, element]: [any, any]) => ({
          field: { code },
          value: element.value || null,
        })),
      };
      service.createProduct(body).then((res: any) => {
        modelActions(res.id)
          .then(() => {
            notification.success({
              message: t('product.create.success.message'),
            });
            form.setFieldsValue({
              code: null,
              price: null,
              fields: [],
            });
          })
          .catch((e) => {
            console.error(e);
            notification.error({
              message: `Error while product create`,
            });
          })
          .finally(() => setIsSaving(false));
      });
    }
  };

  const onSubmitArticle = (values: any) => {
    const { category, id } = values;
    const filter = {
      and: [{ 'category/id': { eq: category.id } }],
    };
    setLoadingArticles(true);
    getArticles(filter).then(() => {
      form.setFieldsValue({
        article: {
          category,
          id,
        },
      });
      setIsShowProduct(true);
      setIsCreateArticle(false);
      setLoadingArticles(false);
    });
  };

  const onCancel = () => {
    history.push(`/products`);
  };

  const handleShowSelect = (isShow?: boolean) => {
    setIsShowAddSelect(isShow === undefined ? !isShowAddSelect : isShow);
  };

  const onShowCreateArticle = () => {
    const { article } = form.getFieldsValue();
    const category = article?.category;
    if (category) {
      setInitialArticleFormValue({
        article: { category },
      });
    }
    setIsCreateArticle(true);
  };

  const onSelectArticleType = (value: any) => {
    const filter = {
      and: [
        {
          'category/id': {
            eq: value,
          },
        },
      ],
    };
    form.setFieldsValue({ article: { id: null } });
    getArticles(filter);
    setIsShowArticles(true);
    const category: any = articleCategories.find((item: any) => item.id.toString() === value.toString());
    if (category) {
      initialMediaComponents(category?.type);
    }
  };

  const setTextureSizeFields = (fileName: string) => {
    const sizeStr: any = fileName.match(/\([0-9]*(x|X)[0-9]*\)/g);
    if (sizeStr) {
      const sizeArr = sizeStr[0]
        .replace(/[\(\)]/g, '')
        .toLowerCase()
        .split('x');
      const width = sizeArr[0];
      const height = sizeArr[1];
      const widthObj = fields?.find((item: any) => item.code === productParamCodes.texture_size_w);
      const heightObj = fields?.find((item: any) => item.code === productParamCodes.texture_size_h);

      if (!widthObj) {
        const textureSizeWidth = productParams.find((param: any) => param.code === productParamCodes.texture_size_w);
        if (textureSizeWidth) {
          fields.push({ ...textureSizeWidth, value: width });
        }
      }

      if (!heightObj) {
        const textureSizeHeight = productParams.find((param: any) => param.code === productParamCodes.texture_size_h);
        if (textureSizeHeight) {
          fields.push({ ...textureSizeHeight, value: height });
        }
      }

      form.setFieldsValue({
        fields: {
          [productParamCodes.texture_size_w]: { value: width },
          [productParamCodes.texture_size_h]: { value: height },
        },
      });

      setFields([...fields]);
    }
  };

  const onClearArticleType = () => {
    form.setFieldsValue({ article: { id: null } });
    setIsShowArticles(false);
    setIsShowProduct(false);
  };

  const onChangeTexture = (info: UploadChangeParam<UploadFile<any>>) => {
    const { file, fileList }: any = info;
    setTextureFile(file?.status !== 'removed' ? fileList : null);
    setIsUploadTexture(file?.status !== 'removed');
    if (file?.status !== 'removed') {
      setTextureSizeFields(file.name);
    }
  };

  const onChangeGlbFile = (info: UploadChangeParam<UploadFile<any>>) => {
    const { file, fileList }: any = info;
    setGlbFile(file?.status !== 'removed' ? fileList : null);
    setIsUploadGlbFile(file?.status !== 'removed');
  };

  const isInitialValueRequired = () => {
    const { article = undefined } = form.getFieldsValue();
    if (article && article.category) {
      const { id: categoryId } = article.category;
      const category: any = articleCategories.find((item: any) => item.id === categoryId);
      return category?.type?.name !== articleCategoryTypes.interior;
    }
    return true;
  };

  if (useLessThen801()) {
    return (
      <Spin spinning={isSaving}>
        <HeaderTable title={t('product.create.title.create')} breadcrumb={breadcrumbNameMap}>
          <Form.Item>
            <div style={{ display: 'flex', marginTop: '1rem' }}>
              <Button
                onClick={onCancel}
                className={`common-blue-border-btn common-secondary-btn`}
                style={{ height: '42px', marginRight: '0.5rem', width: '100%' }}>
                {t('common.btn.cancel')}
              </Button>
              <Button
                onClick={onSubmit}
                disabled={isCreateArticle}
                style={{ height: '42px', marginLeft: '0.5rem', width: '100%' }}
                className={`common-blue-btn common-primary-btn`}>
                {t('common.btn.save')}
              </Button>
            </div>
          </Form.Item>
        </HeaderTable>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            {!isCreateArticle ? (
              <Form autoComplete="off" form={form} layout="vertical" name="modify_product">
                <Card title={t('product.create.card.article.info')} loading={loading}>
                  <Row gutter={[24, 4]}>
                    <Col span={24}>
                      <Form.Item
                        label={t('product.create.card.article.category')}
                        name={['article', 'category', 'id']}
                        rules={[{ required: true, message: t('product.create.card.article.category.validation') }]}>
                        <TreeSelect
                          allowClear
                          treeData={articleCategories.map((c: any) => ({
                            id: c.id,
                            value: c.id,
                            pId: c.parentId,
                            title: c.name,
                            label: c.name,
                            key: c.id,
                          }))}
                          className="common-select common-animation-primary"
                          suffixIcon={
                            <div
                              className={`arrow-drop-img ${css['arrow-drop']}`}
                              style={{ width: '8px', height: '4px' }}></div>
                          }
                          treeDataSimpleMode
                          onSelect={onSelectArticleType}
                          onClear={onClearArticleType}
                        />
                      </Form.Item>
                    </Col>
                    {isShowArticles && (
                      <Col span={24}>
                        <Form.Item
                          label={t('product.create.card.article.item')}
                          name={['article', 'id']}
                          rules={[{ required: true, message: t('product.create.card.article.item.validation') }]}>
                          <Select
                            allowClear
                            showSearch
                            className="common-select common-animation-primary"
                            suffixIcon={
                              <div
                                className={`arrow-drop-img ${css['arrow-drop']}`}
                                style={{ width: '8px', height: '4px' }}></div>
                            }
                            loading={loadingArticles}
                            onSelect={() => setIsShowProduct(true)}
                            onClear={() => setIsShowProduct(false)}
                            filterOption={(input: string, { children: { props } }: any) => {
                              return props?.article?.name?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }}
                            dropdownRender={(menu: any) => (
                              <>
                                {menu}
                                <Divider style={{ margin: '4px 0' }} />
                                <div style={{ width: '100%', textAlign: 'center' }}>
                                  <Button onClick={onShowCreateArticle} style={{ width: '98%' }}>
                                    {t('product.create.card.article.create.btn')}
                                  </Button>
                                </div>
                              </>
                            )}>
                            {articles.map((item: any) => (
                              <Select.Option key={item.id} value={item.id}>
                                <ArticleNameLayout article={item} />
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                </Card>
                {isShowProduct && (
                  <Row gutter={[24, 4]} style={{ paddingTop: '24px' }}>
                    <Col span={24}>
                      <Card title={t('product.create.card.basic.info')} loading={loading}>
                        <Row gutter={[24, 4]}>
                          <Col span={24}>
                            <Form.Item
                              label={t('product.create.card.code')}
                              name="code"
                              rules={[
                                {
                                  required: isInitialValueRequired(),
                                  message: t('product.create.card.code.validation'),
                                },
                              ]}>
                              <Input className="common-animation-primary" />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item label={t('product.create.card.price')} name="price">
                              <InputNumber className="common-animation-primary" style={{ width: '100%' }} />
                            </Form.Item>
                          </Col>
                          <DynamicFields
                            name="fields"
                            isSelect={isShowAddSelect}
                            handleSelect={handleShowSelect}
                            fields={productParams}
                            values={fields}
                            onChangeValues={(params: any) => setFields(params)}
                          />
                        </Row>
                      </Card>
                    </Col>
                    <Col span={24}>
                      <Card title={t('product.create.card.media')} loading={loading}>
                        <Row gutter={[24, 4]}>
                          <Col span={24}>
                            <Form.Item label={t('product.create.images')} name="images" valuePropName="filelist">
                              <div className="dropbox">
                                <Upload.Dragger
                                  listType="picture"
                                  accept=".png, .jpg, .jpeg"
                                  beforeUpload={() => false}
                                  onChange={(e: any) => {
                                    setImages(e.fileList);
                                  }}
                                  multiple>
                                  <p className="ant-upload-drag-icon" style={{ marginTop: 0, marginBottom: 0 }}>
                                    <PlusOutlined />
                                  </p>
                                </Upload.Dragger>
                              </div>
                            </Form.Item>
                          </Col>
                          {isShowTexture && (
                            <Col span={24}>
                              <Form.Item label={t('product.create.texture')} name="texture" valuePropName="filelist">
                                <Upload
                                  listType="picture"
                                  accept=".png, .jpg, .jpeg"
                                  beforeUpload={() => false}
                                  onChange={onChangeTexture}
                                  defaultFileList={textureFile}
                                  customRequest={() => {}}
                                  className="variant-floor-plan">
                                  {!isUploadTexture && (
                                    <Button>
                                      <PlusOutlined /> {t('common.btn.upload')}
                                    </Button>
                                  )}
                                </Upload>
                              </Form.Item>
                            </Col>
                          )}
                          {isShowGblFile && (
                            <Col span={24}>
                              <Form.Item label={t('product.create.glb.file')} name="glbFile" valuePropName="filelist">
                                <Upload
                                  listType="picture"
                                  accept=".glb, .gltf"
                                  beforeUpload={() => false}
                                  onChange={onChangeGlbFile}
                                  defaultFileList={glbFile}
                                  customRequest={() => {}}
                                  className="variant-floor-plan">
                                  {!isUploadGlbFile && (
                                    <Button>
                                      <PlusOutlined /> {t('common.btn.upload')}
                                    </Button>
                                  )}
                                </Upload>
                              </Form.Item>
                            </Col>
                          )}
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                )}
              </Form>
            ) : (
              <ArticleModifyForm
                loading={loading}
                articleCategories={articleCategories}
                initialValue={initialArticleFormValue}
                onSetIsLoading={setLoading}
                onSetIsSaving={setIsSaving}
                onCancel={() => setIsCreateArticle(false)}
                callBack={onSubmitArticle}
              />
            )}
          </Col>
        </Row>
      </Spin>
    );
  }

  return (
    <Spin spinning={isSaving}>
      <HeaderTable title={t('product.create.title.create')} breadcrumb={breadcrumbNameMap}>
        <Form.Item>
          <Button
            onClick={onCancel}
            className={`common-blue-border-btn common-secondary-btn`}
            style={{ height: '42px', marginRight: '1rem' }}>
            {t('common.btn.cancel')}
          </Button>
          <Button
            onClick={onSubmit}
            disabled={isCreateArticle}
            style={{ height: '42px' }}
            className={`common-blue-btn common-primary-btn`}>
            {t('common.btn.save')}
          </Button>
        </Form.Item>
      </HeaderTable>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          {!isCreateArticle ? (
            <Form autoComplete="off" form={form} layout="vertical" name="modify_product">
              <Card title={t('product.create.card.article.info')} loading={loading}>
                <Row gutter={[24, 4]}>
                  <Col span={12}>
                    <Form.Item
                      label={t('product.create.card.article.category')}
                      name={['article', 'category', 'id']}
                      rules={[{ required: true, message: t('product.create.card.article.category.validation') }]}>
                      <TreeSelect
                        allowClear
                        treeData={articleCategories.map((c: any) => ({
                          id: c.id,
                          value: c.id,
                          pId: c.parentId,
                          title: c.name,
                          label: c.name,
                          key: c.id,
                        }))}
                        className="common-select common-animation-primary"
                        suffixIcon={
                          <div
                            className={`arrow-drop-img ${css['arrow-drop']}`}
                            style={{ width: '8px', height: '4px' }}></div>
                        }
                        treeDataSimpleMode
                        onSelect={onSelectArticleType}
                        onClear={onClearArticleType}
                      />
                    </Form.Item>
                  </Col>
                  {isShowArticles && (
                    <Col span={12}>
                      <Form.Item
                        label={t('product.create.card.article.item')}
                        name={['article', 'id']}
                        rules={[{ required: true, message: t('product.create.card.article.item.validation') }]}>
                        <Select
                          allowClear
                          showSearch
                          className="common-select common-animation-primary"
                          suffixIcon={
                            <div
                              className={`arrow-drop-img ${css['arrow-drop']}`}
                              style={{ width: '8px', height: '4px' }}></div>
                          }
                          loading={loadingArticles}
                          onSelect={() => setIsShowProduct(true)}
                          onClear={() => setIsShowProduct(false)}
                          filterOption={(input: string, { children: { props } }: any) => {
                            return props?.article?.name?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                          }}
                          dropdownRender={(menu: any) => (
                            <>
                              {menu}
                              <Divider style={{ margin: '4px 0' }} />
                              <div style={{ width: '100%', textAlign: 'center' }}>
                                <Button onClick={onShowCreateArticle} style={{ width: '98%' }}>
                                  {t('product.create.card.article.create.btn')}
                                </Button>
                              </div>
                            </>
                          )}>
                          {articles.map((item: any) => (
                            <Select.Option key={item.id} value={item.id}>
                              <ArticleNameLayout article={item} />
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Card>
              {isShowProduct && (
                <Row gutter={[24, 4]} style={{ paddingTop: '24px' }}>
                  <Col span={12}>
                    <Card title={t('product.create.card.basic.info')} loading={loading}>
                      <Row gutter={[24, 4]}>
                        <Col span={12}>
                          <Form.Item
                            label={t('product.create.card.code')}
                            name="code"
                            rules={[
                              { required: isInitialValueRequired(), message: t('product.create.card.code.validation') },
                            ]}>
                            <Input className="common-animation-primary" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item label={t('product.create.card.price')} name="price">
                            <InputNumber className="common-animation-primary" style={{ width: '100%' }} />
                          </Form.Item>
                        </Col>
                        <DynamicFields
                          name="fields"
                          isSelect={isShowAddSelect}
                          handleSelect={handleShowSelect}
                          fields={productParams}
                          values={fields}
                          onChangeValues={(params: any) => setFields(params)}
                        />
                      </Row>
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card title={t('product.create.card.media')} loading={loading}>
                      <Row gutter={[24, 4]}>
                        <Col span={24}>
                          <Form.Item label={t('product.create.images')} name="images" valuePropName="filelist">
                            <div className="dropbox">
                              <Upload.Dragger
                                listType="picture"
                                accept=".png, .jpg, .jpeg"
                                beforeUpload={() => false}
                                onChange={(e: any) => {
                                  setImages(e.fileList);
                                }}
                                multiple>
                                <p className="ant-upload-drag-icon" style={{ marginTop: 0, marginBottom: 0 }}>
                                  <PlusOutlined />
                                </p>
                              </Upload.Dragger>
                            </div>
                          </Form.Item>
                        </Col>
                        {isShowTexture && (
                          <Col span={24}>
                            <Form.Item label={t('product.create.texture')} name="texture" valuePropName="filelist">
                              <Upload
                                listType="picture"
                                accept=".png, .jpg, .jpeg"
                                beforeUpload={() => false}
                                onChange={onChangeTexture}
                                defaultFileList={textureFile}
                                customRequest={() => {}}
                                className="variant-floor-plan">
                                {!isUploadTexture && (
                                  <Button>
                                    <PlusOutlined /> {t('common.btn.upload')}
                                  </Button>
                                )}
                              </Upload>
                            </Form.Item>
                          </Col>
                        )}
                        {isShowGblFile && (
                          <Col span={24}>
                            <Form.Item label={t('product.create.glb.file')} name="glbFile" valuePropName="filelist">
                              <Upload
                                listType="picture"
                                accept=".glb, .gltf"
                                beforeUpload={() => false}
                                onChange={onChangeGlbFile}
                                defaultFileList={glbFile}
                                customRequest={() => {}}
                                className="variant-floor-plan">
                                {!isUploadGlbFile && (
                                  <Button>
                                    <PlusOutlined /> {t('common.btn.upload')}
                                  </Button>
                                )}
                              </Upload>
                            </Form.Item>
                          </Col>
                        )}
                      </Row>
                    </Card>
                  </Col>
                </Row>
              )}
            </Form>
          ) : (
            <ArticleModifyForm
              loading={loading}
              articleCategories={articleCategories}
              initialValue={initialArticleFormValue}
              onSetIsLoading={setLoading}
              onSetIsSaving={setIsSaving}
              onCancel={() => setIsCreateArticle(false)}
              callBack={onSubmitArticle}
            />
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default ProductCreatePage;
