import React, { useContext, useState } from 'react';
import { Dropdown, Menu, Empty } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { AppContext } from '../../../contexts/AppContextProvider';
import { SET_CURRENT_PROJECT, SET_CURRENT_PROPERTY } from '../../../constants/actionTypes/appConstants';
import LogoComponent from '../LogoComponent';
import { IAppContext } from '../../../typings/IApp';

interface ProjectsModel {
  isCollapsed: boolean;
}

const menu = (projects: any[] = [], currentProject: number | undefined, onSelect: any) => {
  if (projects.length === 0) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
  const defaultSelected = currentProject ? [currentProject.toString()] : [];

  return (
    <Menu onClick={onSelect} selectedKeys={defaultSelected}>
      {projects
        .sort((a: any, b: any) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
        .map((item: any) => (
          <Menu.Item key={item.id} className="project-menu-item-conteiner">
            <LogoComponent id={item.id} name={item.title} image={item.logo} />
            <span className="item-name">{item.title}</span>
          </Menu.Item>
        ))}
    </Menu>
  );
};

const Projects: React.FC<ProjectsModel> = ({ isCollapsed }: ProjectsModel) => {
  const { app, appDispatch } = useContext<IAppContext>(AppContext);
  const { user, currentProject } = app || {};

  const [isVisible, setIsVisible] = useState(false);

  const handleVisible = (visible: boolean) => {
    setIsVisible(visible);
  };

  const onSelect = ({ key }: any) => {
    if (+key !== currentProject.id) {
      const item = user?.projects.find((project: any) => project.id === +key);
      appDispatch({ type: SET_CURRENT_PROJECT, currentProject: item });
      appDispatch({
        type: SET_CURRENT_PROPERTY,
        currentProperty: user.properties.find((property: any) => property.project.id === item?.id),
      });
      setIsVisible(false);
    }
  };

  return (
    <>
      {user?.projects && user?.projects.length > 0 && (
        <Dropdown
          overlay={menu(user?.projects || [], currentProject?.id, onSelect)}
          trigger={['click']}
          onVisibleChange={handleVisible}>
          <div className={`projects-conteiner${isCollapsed ? '-collapsed' : ''}`}>
            <LogoComponent
              id={currentProject?.id}
              name={currentProject?.title}
              image={currentProject?.logo}
              size="large"
            />
            {!isCollapsed && (
              <>
                <span className="name-text">{currentProject?.title}</span>
                {isVisible ? <UpOutlined className="navigation-btn" /> : <DownOutlined className="navigation-btn" />}
              </>
            )}
          </div>
        </Dropdown>
      )}
    </>
  );
};

export default Projects;
