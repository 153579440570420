import { Card, Empty } from 'antd';
import React, { useEffect, useState } from 'react';
import ProjectCardItem from './ProjectCardItem';
import { IQuoteStatuses } from '../../../../../models/DashboardModel';
import { StatusesDocumentsModel } from '../../../../../models/DocumentsModel';

interface IProps {
  itemStatuses: IQuoteStatuses[];
  statuses: StatusesDocumentsModel;
  isAvailabilityProperties: boolean;
}

const ProjectCard: React.FC<IProps> = ({ itemStatuses, statuses, isAvailabilityProperties }) => {
  const [statusesData, setStatusesData] = useState<{ label: string; code: string; count: number }[]>([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const newStatusesData = statuses
      .map((status) => {
        const currentItem = itemStatuses.find((item) => item.status_v2_code === status.code);
        setCount((state) => (state += currentItem ? +currentItem.count : 0));
        return {
          label: status.description,
          code: status.code,
          count: +(currentItem?.count || 0),
        };
      })
      .sort((a, b) => {
        if (a.count === 0 && b.count !== 0) {
          return 1;
        }
        if (a.count !== 0 && b.count === 0) {
          return -1;
        }
        return a.label.localeCompare(b.label);
      });
    setStatusesData(newStatusesData);

    return () => {
      setCount(0);
      setStatusesData([]);
    };
  }, [itemStatuses, statuses]);

  return (
    <Card
      style={{
        border: '1px solid #C4C6CD',
        borderRadius: 8,
        padding: '2px 6px',
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: isAvailabilityProperties ? 'column' : 'row',
          gap: 10,
          overflowX: 'auto',
        }}>
        {statusesData.length > 0 ? (
          statusesData.map((item, i) => <ProjectCardItem key={i} item={item} count={count} />)
        ) : (
          <Empty />
        )}
      </div>
    </Card>
  );
};

export default ProjectCard;
