import { Button, Modal, notification, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../../assets/styles/faq.module.css';
import { DocumentsModel } from '../../../../models/DocumentsModel';
import DocumentsService from '../../../../services/DocumentsService';

interface Props {
  id: number | null;
  documentNumber: string | null;
  setClose: () => void;
  isOpen: boolean;
}

const service = new DocumentsService();

const ArchiveConfirmForm = (props: Props) => {
  const { id, documentNumber, setClose, isOpen } = props;
  const { t } = useTranslation();
  const [fetchingData, setFetchingData] = useState(false);
  const [document, setDocument] = useState<DocumentsModel | null>(null);

  useEffect(() => {
    if (id) {
      service
        .getDocumentsInfoById(id)
        .then((value) => setDocument(value))
        .catch((e) => {
          notification.error({
            message: e.message,
          });
          setClose();
        });
    }
  }, []);

  const updateDocument = () => {
    if (document) {
      setFetchingData(true);
      const body = {
        id: document.id,
        isArchive: true,
      };
      service
        .updateDocument(body)
        .then(() =>
          notification.success({
            message: t('documents.modal.archived.success.message'),
          }),
        )
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        )
        .finally(() => {
          setClose();
          setFetchingData(false);
        });
    }
  };

  return (
    <Spin spinning={fetchingData}>
      <Modal
        className="common-modal"
        centered
        visible={isOpen}
        style={{ width: '10rem' }}
        footer={
          <div className="common-modal-footer">
            <Button
              className={`common-gray-border-btn common-secondary-btn`}
              onClick={() => setClose()}
              disabled={fetchingData}>
              {t('budget.modal.cancel.btn')}
            </Button>
            <Button
              className={`common-green-btn common-modal-btn`}
              onClick={() => updateDocument()}
              disabled={fetchingData}>
              {t('budget.modal.arhive.btn')}
            </Button>
          </div>
        }
        closable={false}>
        <span className={css['delete-question-title']}>
          {t('documents.modal.confirm.archived.message')}
          {`${documentNumber}?`}
        </span>
      </Modal>
    </Spin>
  );
};

export default ArchiveConfirmForm;
