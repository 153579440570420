import { Button, Tooltip } from 'antd';
import React from 'react';
import css from '../../../assets/styles/reports.module.css';
import { useTranslation } from 'react-i18next';
import { ReloadOutlined } from '@ant-design/icons';

interface ReportDataControlModel {
  onRefresh: () => void;
  onCreate: () => void;
}

const ReportDataControl: React.FC<ReportDataControlModel> = (propsControl: ReportDataControlModel) => {
  const { onRefresh, onCreate } = propsControl;
  const { t } = useTranslation();

  return (
    <div className={css['reports-control-container']}>
      <div style={{ display: 'flex' }}>
        <Tooltip title={t('common.btn.refresh')}>
          <Button
            className={`common-blue-border-btn common-secondary-btn`}
            onClick={onRefresh}
            style={{ height: '42px', marginRight: '1rem' }}>
            <ReloadOutlined />
          </Button>
        </Tooltip>
        <Button className={`common-blue-btn common-primary-btn`} onClick={onCreate} style={{ height: '42px' }}>
          <span>{t('budget.control.create.btn')}</span>
        </Button>
      </div>
    </div>
  );
};

export default ReportDataControl;
