import React, { useState } from 'react';
import AIContainer from './AIContainer';
import { useHistory } from 'react-router';
import GenerativeAIModal from './GenerativeAIModal';
import { getOnboardingFaq } from '../../../../helpers/onboarding';

const AICreatingPage = () => {
  const history = useHistory();
  const [isOpenModal, setIsOpenModal] = useState(getOnboardingFaq());

  const handleClose = () => {
    history.push('/manage_faq');
  };

  return (
    <>
      {isOpenModal ? (
        <GenerativeAIModal isOpen={true} handleOk={() => setIsOpenModal(false)} handleCancel={handleClose} />
      ) : (
        <AIContainer isOpen={true} onClose={handleClose} />
      )}
    </>
  );
};

export default AICreatingPage;
