import { Button, Modal, Row, Upload, Form, Input, Col, Select, notification, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import css from '../../../assets/styles/documents.module.css';
import { useTranslation } from 'react-i18next';
import {
  IContractModel,
  NewDocumentsModel,
  StatusDocumentsModel,
  StatusesDocumentsModel,
  UpdatedDocumentsModel,
} from '../../../models/DocumentsModel';
import config from '../../../config';
import { AppContext } from '../../../contexts/AppContextProvider';
import icAttention from '../../../assets/images/ic-attention.svg';
import { convertToCoins, convertToCurrency } from '../../../helpers/convertToPrice';
import ContractsService from '../../../services/ContractsService';
import CustomSignFlow from '../../../services/CustomSignFlow';
import { generateFilterfromObj } from '../../../helpers/generateFIlterfromObj';
import { FilterTypes } from '../../../constants/filterTypes';
import { IFlow, IFlowStep, ISignatoryFlow } from '../../../models/CustomSignFlowModel';
import { useLessThen801, useLessThen768 } from '../../../helpers/mediaDetect';
import { isEmptyObject } from '../../../helpers/isEmptyObject';
import { setTextColorByStatus } from '../../../helpers/setTextColorByStatus';
import { onChangeFeesVat, onChangeSumFeesWithVat, onChangeSum } from '../../../helpers/onChangeSumAndVat';
import { IAppContext } from '../../../typings/IApp';
import { IUserEasy } from '../../../models/UserModel';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import useCloseTabWarning from '../../../hooks/useCloseTabWarning';

const initialVat = 7.7;
interface DocumentsModalProps {
  isOpenModal: boolean;
  currentId: number | null;
  projectId: number;
  getDocumentsInfo: () => void;
  propertyId: number;
  setClose: () => void;
  signatories: IUserEasy[];
  setIsOpenUploadLoader: (b: boolean) => void;
  setIsUploadingFile: (isLoading: boolean) => void;
}

const service = new ContractsService();
const serviceFlow = new CustomSignFlow();

const ContractForm = (props: DocumentsModalProps) => {
  const {
    isOpenModal,
    currentId,
    getDocumentsInfo,
    propertyId,
    setClose,
    signatories,
    setIsUploadingFile,
    setIsOpenUploadLoader,
    projectId,
  } = props;
  const { t } = useTranslation();

  const SIZE_COLUMN = useLessThen801() ? 24 : 12;
  const HALF_COLUMN_SIZE = useLessThen768() ? 24 : 12;

  const {
    app: { user, isConsumer },
  } = useContext<IAppContext>(AppContext);

  const [statuses, setStatuses] = useState<StatusesDocumentsModel>([]);
  const [documentsInfo, setDocumentsInfo] = useState<IContractModel | null>(null);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileAttachmentList, setFileAttachmentList] = useState<UploadFile[]>([]);
  const [fetchingData, setFetchingData] = useState(false);
  const [isHaveFile, setIsHaveFile] = useState(true);
  const [isHaveSignature, setIsHaveSignature] = useState(false);
  const [signatoriesFull, setSignatoriesFull] = useState<any[]>([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isDisabledStatus, setIsDisabledStatus] = useState(true);
  const [isRejectCancel, setIsRejectCancel] = useState(false);
  const [flows, setFlows] = useState<IFlow[]>([]);
  const [flowSteps, setFlowSteps] = useState<IFlowStep[]>([]);

  const { routerPrompt: Prompt, setIsDirty } = useCloseTabWarning(t('common.modal.onbeforeunload.message'));
  useEffect(() => {
    setIsDirty(fetchingData);
  }, [fetchingData]);

  useEffect(() => {
    setFetchingData(true);
    serviceFlow
      .getStatuses({
        filters: generateFilterfromObj({
          'doc_types_statuses/typeCode': {
            type: FilterTypes.SELECT,
            value: 'documents',
          },
        }),
      })
      .then((statusesInfo: StatusesDocumentsModel) => {
        setStatuses(statusesInfo);
        if (currentId) {
          service
            .getContractInfoById(currentId)
            .then((value: IContractModel) => {
              serviceFlow
                .getFlows(
                  {
                    filters: generateFilterfromObj({
                      'projects/id': {
                        type: FilterTypes.SELECT,
                        value: +projectId,
                      },
                      'document_types/code': {
                        type: FilterTypes.SELECT,
                        value: 'contracts',
                      },
                    }),
                  },
                  value.flow ? value.flow.id : undefined,
                )
                .then((flows: IFlow | IFlow[]) => {
                  const isArrayFlows = Array.isArray(flows);
                  setFlows((isArrayFlows ? flows : [flows]) as IFlow[]);
                  const status = statusesInfo.filter(
                    (status: StatusDocumentsModel) => status.code === value.statusV2.code,
                  )[0].code;
                  let fieldsValues: { [name: string]: any } = {
                    title: value.title,
                    status,
                    description: value.description,
                    sumWithFees: value.sumWithFees ? convertToCurrency(value.sumWithFees) : undefined,
                    sumWithVat: value.sumWithVat ? convertToCurrency(value.sumWithVat) : undefined,
                    hasSignature: value.hasSignature,
                    signInfo: value.signInfo || value.comment,
                    comment: value.comment,
                    flow: value.flow ? value.flow.id : '',
                    responsible_agent: value.createdUser.id,
                  };
                  (
                    (isArrayFlows ? (flows as IFlow[]).find((item) => item.id === value.flow?.id) : flows) as IFlow
                  )?.flowsSteps
                    .map((item) => ({
                      stepCode: item.stepCode,
                      signatoriesName: item.step.signatoriesName,
                    }))
                    .forEach((item) => {
                      fieldsValues[item.stepCode] = value.signatories
                        .filter((sign) => sign.stepCode === item.stepCode)
                        .map((item) => item.consumerId);
                    });
                  form.setFieldsValue(fieldsValues);
                  setSignatoriesFull(value.signatories);
                  setIsHaveSignature(value.hasSignature);
                  if (value.flow && value.flow.id) {
                    setFlowSteps(
                      (
                        (isArrayFlows ? (flows as IFlow[]).find((item) => item.id === value.flow?.id) : flows) as IFlow
                      )?.flowsSteps.map((item) => ({
                        signatoriesName: item.step.signatoriesName,
                        code: item.step.code,
                      })) || [],
                    );
                  }
                  if (value.file) {
                    setFileList([
                      {
                        name: value.file,
                        uid: value.file,
                        url: `${config.storeUrl}/${value.file}`,
                      },
                    ]);
                  }
                  setIsDisabled(true);
                  setIsDisabledStatus(
                    value.flow === null
                      ? ['rejected', 'done', 'canceled', 'signed'].includes(value.statusV2.code)
                      : true,
                  );
                  setIsRejectCancel(['rejected', 'done', 'canceled', 'signed'].includes(value.statusV2.code));
                  setDocumentsInfo(value);
                });
            })
            .catch((e) => console.log(e));
        } else {
          serviceFlow
            .getFlows({
              filters: generateFilterfromObj({
                'projects/id': {
                  type: FilterTypes.SELECT,
                  value: +projectId,
                },
                'document_types/code': {
                  type: FilterTypes.SELECT,
                  value: 'contracts',
                },
              }),
            })
            .then((flows: IFlow[]) => {
              setFlows(flows);
              const defaultFlowId = flows.find((item) => item.isDefault)?.id;
              form.setFieldsValue({ flow: defaultFlowId || '' });
              setIsHaveSignature(true);
              setFlowSteps(
                flows
                  .find((item) => item.id === defaultFlowId)
                  ?.flowsSteps.map((item) => ({ signatoriesName: item.step.signatoriesName, code: item.step.code })) ||
                  [],
              );
            });
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setFetchingData(false));
  }, []);

  const handleFile = (info: UploadChangeParam<UploadFile>) => {
    setFileList(info.fileList);
    setIsHaveFile(true);
  };

  const handleFileAttachment = (info: UploadChangeParam<UploadFile>) => {
    setFileAttachmentList(info.fileList);
  };

  const successUpdate = () => {
    setClose();
    notification.success({
      message: t('contract.modal.updated.success.message'),
    });
    getDocumentsInfo();
  };

  const onSubmit = (values: any) => {
    if (fileList.length < 1) {
      setIsHaveFile(false);
      return;
    }
    setFetchingData(true);
    const filesData = new FormData();
    for (const file of fileList) {
      if (file.originFileObj) {
        filesData.append('file', file.originFileObj);
      }
    }
    if (!documentsInfo) {
      for (const file of fileAttachmentList) {
        if (file.originFileObj) {
          filesData.append('attachments', file.originFileObj);
        }
      }
    }

    let body: NewDocumentsModel = {
      title: values.title,
      description: values.description,
      statusV2: {
        code: values.status.toString() ?? documentsInfo?.statusV2.code.toString(),
      },
      property: {
        id: documentsInfo?.property.id ? documentsInfo?.property.id : propertyId,
      },
      sumWithFees: convertToCoins(values.sumWithFees),
      sumWithVat: convertToCoins(values.sumWithVat),
      hasSignature: isHaveSignature,
      signInfo: values.signInfo,
    };

    const signatoriesFlow: ISignatoryFlow[] = [];
    const currentFlow = flows
      ?.find((item) => values.flow && item.id === +values.flow)
      ?.flowsSteps?.sort((a, b) => a.ord - b.ord);

    currentFlow &&
      currentFlow.forEach((item) => {
        values[item.step.code].forEach((id: number) => {
          signatoriesFlow.push({ consumerId: id, stepCode: item.stepCode });
        });
      });

    if (!documentsInfo && signatoriesFlow.length > 0) {
      body['signatories'] = signatoriesFlow;
    }

    if (!documentsInfo) {
      body['flow'] = values.flow ? { id: values.flow } : null;
    }

    if (documentsInfo) {
      (body as UpdatedDocumentsModel)['id'] = documentsInfo.id;
      body['comment'] = values.comment;

      service
        .updateContract(body as UpdatedDocumentsModel)
        .then(() => {
          if (filesData.getAll('file').length > 0) {
            setIsUploadingFile(true);
            setIsOpenUploadLoader(true);
            setClose();
            service
              .uploadContractFiles(documentsInfo.id, filesData)
              .then(() => {
                successUpdate();
              })
              .catch((e) => {
                notification.error({
                  message: e.message || t('common.error.internalServerError'),
                });
              })
              .finally(() => {
                setIsUploadingFile(false);
                setClose();
              });
          } else {
            successUpdate();
          }
        })
        .catch((e) => {
          setClose();
          notification.error({
            message: e.message || t('common.error.internalServerError'),
          });
        })
        .finally(() => {
          setClose();
          setFetchingData(false);
        });
    } else {
      service
        .createContract(body)
        .then((value) => {
          if (filesData.getAll('file').length > 0 && value.id) {
            setIsUploadingFile(true);
            setIsOpenUploadLoader(true);
            setClose();
            service
              .uploadContractFiles(value.id, filesData)
              .then(() => {
                setClose();
                notification.success({
                  message: t('contract.modal.created.success.message'),
                });
              })
              .catch((e) => {
                notification.error({
                  message: e.message || t('common.error.internalServerError'),
                });
              })
              .finally(() => setIsUploadingFile(false));
          }
        })
        .catch((e) => {
          setClose();
          notification.error({
            message: e.message || t('common.error.internalServerError'),
          });
        })
        .finally(() => {
          setFetchingData(false);
        });
    }
  };

  const onStatusSelect = (value: string) => {
    const isCancel = ['rejected', 'canceled'].includes(value);
    setIsRejectCancel(isCancel);
    if (isCancel) {
      form.setFields([{ name: 'comment', value: null }]);
    }
  };

  const onSelectFlow = (value: number | '') => {
    form.setFieldsValue({ status: 'new' });
    setIsHaveSignature(value !== '');
    setFlowSteps(
      flows
        .find((item) => item.id === value)
        ?.flowsSteps.map((item) => ({ signatoriesName: item.step.signatoriesName, code: item.step.code })) || [],
    );
  };

  return (
    <Spin spinning={fetchingData}>
      <Modal
        className="upload-modal"
        centered
        visible={isOpenModal}
        footer={
          <div className="common-modal-footer">
            <Button
              className={`common-gray-border-btn common-secondary-btn`}
              disabled={fetchingData}
              onClick={() => setClose()}>
              {t('documents.modal.cancel.btn')}
            </Button>
            <Button
              className={`common-green-btn common-modal-btn`}
              disabled={fetchingData}
              onClick={() => form.submit()}>
              {t('documents.modal.save.btn')}
            </Button>
          </div>
        }
        closable={false}>
        <Form autoComplete="off" onFinish={onSubmit} form={form} className={css['add-documents-modal']}>
          <Form.Item
            className={css['form-item-documents']}
            colon={false}
            label={t('documents.form.file')}
            labelCol={{ span: 24 }}
            initialValue={fileList}
            name="file">
            <div style={{ position: 'relative' }}>
              <Upload
                style={{ width: '100%' }}
                className="consumers-import-upload-item"
                maxCount={1}
                accept=".pdf"
                beforeUpload={() => false}
                onChange={handleFile}
                fileList={fileList}
                disabled={isDisabled}>
                {fileList.length < 1 && (
                  <div
                    className={css['upload-item-documents']}
                    style={{
                      borderColor: documentsInfo && !documentsInfo.file && fileList.length < 1 ? '#F63A3A' : '#778dac',
                    }}>
                    <div
                      className={css['upload-img']}
                      style={{ marginRight: '0.5rem', width: '18px', height: '14px' }}></div>
                    <span style={{ color: '#076ee5' }}>{t('documents.modal.upload.btn.title')}</span>
                  </div>
                )}
              </Upload>
              {documentsInfo && !documentsInfo.file && fileList.length < 1 ? (
                <img
                  src={icAttention}
                  alt=""
                  style={{
                    position: 'absolute',
                    right: '-15px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                />
              ) : null}
            </div>
            {((documentsInfo && !documentsInfo.file) || !documentsInfo) && (
              <>
                <div style={{ width: '100%', borderBottom: '1px solid #dbdde4', margin: '8px 0' }}></div>
                <Upload
                  className="consumers-import-upload-item upload-list documents-attachments"
                  style={{ width: '100%' }}
                  beforeUpload={() => false}
                  onChange={handleFileAttachment}
                  onRemove={() => true}
                  multiple={true}
                  fileList={fileAttachmentList}
                  accept=".pdf"
                  disabled={isDisabled}>
                  <div className={css['upload-item-documents']}>
                    <span style={{ color: '#076ee5' }}>{t('documents.modal.upload.attachment.btn.title')}</span>
                  </div>
                </Upload>
              </>
            )}
            {!isHaveFile && <span style={{ color: '#ff4d4f' }}>{t('common.validation.required')}</span>}
          </Form.Item>
          <Form.Item style={{ padding: '1.5rem' }}>
            <Row gutter={[24, 4]}>
              <Col span={12}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: t('common.validation.required'),
                    },
                  ]}
                  name="title"
                  colon={false}
                  label={<span style={{ color: '#778dac' }}>{t('documents.form.name')}</span>}
                  className={css['form-item']}
                  labelAlign="left">
                  <Input className="common-animation-primary" disabled={isDisabled} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="status"
                  colon={false}
                  rules={[
                    {
                      required: true,
                      message: t('common.validation.required'),
                    },
                  ]}
                  label={
                    <span style={{ color: '#778dac' }} className="documents-form-label">
                      {t('documents.form.status')}
                    </span>
                  }
                  initialValue={'new'}
                  className={css['form-item']}
                  labelAlign="left">
                  <Select
                    className="common-select common-animation-primary"
                    suffixIcon={
                      <div
                        className={`arrow-drop-img ${css['arrow-drop']}`}
                        style={{ width: '8px', height: '4px' }}></div>
                    }
                    style={{ marginTop: '0' }}
                    onSelect={onStatusSelect}
                    disabled={!currentId || isDisabledStatus}>
                    {statuses.length > 0 &&
                      statuses
                        .filter((status: StatusDocumentsModel) =>
                          documentsInfo && documentsInfo.flow === null
                            ? status.docTypesStatuses.find((item) => item.typeCode === 'documents')?.isManual
                            : true,
                        )
                        .map((item: StatusDocumentsModel) => (
                          <Select.Option key={item.code} value={item.code}>
                            <span style={{ color: `${setTextColorByStatus(item.code)}` }}>{item.description}</span>
                          </Select.Option>
                        ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 4]}>
              <Col span={HALF_COLUMN_SIZE}>
                <Form.Item
                  name="sumWithFees"
                  colon={false}
                  labelAlign="left"
                  rules={[{ required: true, message: t('common.validation.required') }]}
                  label={<span style={{ color: '#778dac' }}>{t('budget.form.sum')}</span>}
                  className={css['form-item']}>
                  <Input
                    className="common-animation-primary"
                    disabled={isDisabled}
                    type="number"
                    onChange={(e) => onChangeSum(e, form)}
                  />
                </Form.Item>
              </Col>
              <Col span={HALF_COLUMN_SIZE}>
                <Form.Item
                  name="sumWithVat"
                  colon={false}
                  rules={[{ required: true, message: t('common.validation.required') }]}
                  label={<span style={{ color: '#778dac' }}>{t('budget.form.sum.with.vat')}</span>}
                  className={css['form-item']}
                  labelAlign="left">
                  <Input
                    className="common-animation-primary"
                    disabled={true}
                    type="number"
                    onChange={(e) => onChangeSumFeesWithVat(e, form)}
                  />
                </Form.Item>
              </Col>
              <Col span={HALF_COLUMN_SIZE}>
                <Form.Item
                  name="vat"
                  colon={false}
                  rules={[{ required: true, message: t('common.validation.required') }]}
                  label={<span style={{ color: '#778dac' }}>{t('budget.form.vat')}</span>}
                  className={css['form-item']}
                  labelAlign="left"
                  initialValue={initialVat}>
                  <Input
                    className="common-animation-primary"
                    disabled={isDisabled}
                    type="number"
                    onChange={(e) => onChangeFeesVat(e, form)}
                  />
                </Form.Item>
              </Col>
              <Col span={HALF_COLUMN_SIZE} />
            </Row>
            {!isConsumer && isRejectCancel && (
              <Form.Item
                name="comment"
                colon={false}
                label={<span style={{ color: '#778dac' }}>{t('budget.form.cancel.comment')}</span>}
                className={css['form-item']}
                labelAlign="left">
                <Input className="common-animation-primary" disabled={isRejectCancel} />
              </Form.Item>
            )}
            <Form.Item
              name="description"
              colon={false}
              label={<span style={{ color: '#778dac' }}>{t('documents.form.description')}</span>}
              className={css['form-item']}
              labelAlign="left">
              <Input className="common-animation-primary" disabled={isRejectCancel} />
            </Form.Item>
            <Form.Item
              name="signInfo"
              colon={false}
              label={<span style={{ color: '#778dac' }}>{t('budget.form.sign.comments')}</span>}
              className={css['form-item']}
              labelAlign="left">
              <Input.TextArea
                className="common-animation-primary"
                autoSize={{ maxRows: 3, minRows: 3 }}
                disabled={isRejectCancel}
              />
            </Form.Item>
            <Form.Item
              name="flow"
              colon={false}
              label={<span style={{ color: '#778dac' }}>{t('contract.flow.title')}</span>}
              className={css['form-item']}
              initialValue={''}
              labelAlign="left">
              <Select
                className="common-select common-animation-primary"
                suffixIcon={
                  <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
                }
                style={{ marginTop: '0' }}
                onSelect={onSelectFlow}
                disabled={isDisabled}>
                <Select.Option value={''}>{t('contract.flow.noFlow')}</Select.Option>
                {flows.length > 0 &&
                  flows.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            {isHaveSignature && flowSteps.length > 0 ? (
              <Row gutter={[24, 4]}>
                {flowSteps.map((item) => (
                  <Col span={SIZE_COLUMN} key={item.code}>
                    <Form.Item
                      name={item.code}
                      colon={false}
                      label={<span style={{ color: '#778dac' }}>{item.signatoriesName} *</span>}
                      rules={[{ required: !documentsInfo, message: t('common.validation.required') }]}
                      className={css['form-item']}
                      labelAlign="left">
                      <Select className="common-select common-animation-primary" mode="multiple" disabled={isDisabled}>
                        {item.code.split('_').includes('agent') ? (
                          <>
                            {documentsInfo && documentsInfo.createdUser && !isEmptyObject(documentsInfo.createdUser) && (
                              <Select.Option value={documentsInfo.createdUser.id} className="documents-control-option">
                                <span
                                  style={{
                                    fontSize: '14px',
                                    fontWeight: 600,
                                  }}>{`${documentsInfo.createdUser.firstName} ${documentsInfo.createdUser.lastName}`}</span>
                              </Select.Option>
                            )}
                            <Select.Option
                              key={user.id}
                              value={user.id}
                              disabled={
                                signatoriesFull.findIndex((sign) => sign.consumerId === user.id && sign.signed) >= 0
                              }>
                              {`${user.firstName} ${user.lastName}`}
                            </Select.Option>
                          </>
                        ) : (
                          signatories.length > 0 &&
                          signatories.map((signatory) => (
                            <Select.Option
                              key={signatory.id}
                              value={signatory.id}
                              disabled={
                                signatoriesFull.findIndex((sign) => sign.consumerId === signatory.id && sign.signed) >=
                                0
                              }>
                              {`${signatory.firstName} ${signatory.lastName}`}
                            </Select.Option>
                          ))
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                ))}
                <Col span={SIZE_COLUMN}>
                  <Form.Item
                    name="responsible_agent"
                    colon={false}
                    label={<span style={{ color: '#778dac' }}>{t('fileManagement.control.all.responsible')}</span>}
                    className={css['form-item']}
                    initialValue={user.id}
                    labelAlign="left">
                    <Select className="common-select common-animation-primary" disabled={true}>
                      {documentsInfo && documentsInfo.createdUser && !isEmptyObject(documentsInfo.createdUser) && (
                        <Select.Option value={documentsInfo.createdUser.id} className="documents-control-option">
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 600,
                            }}>{`${documentsInfo.createdUser.firstName} ${documentsInfo.createdUser.lastName}`}</span>
                        </Select.Option>
                      )}
                      <Select.Option value={user.id} className="documents-control-option">
                        <span
                          style={{
                            fontSize: '14px',
                            fontWeight: 600,
                          }}>{`${user.firstName} ${user.lastName}`}</span>
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <Row gutter={[24, 4]}>
                <Col span={SIZE_COLUMN}>
                  <Form.Item
                    name="responsible_agent"
                    colon={false}
                    label={<span style={{ color: '#778dac' }}>{t('fileManagement.control.all.responsible')}</span>}
                    className={css['form-item']}
                    initialValue={user.id}
                    labelAlign="left">
                    <Select className="common-select common-animation-primary" disabled={true}>
                      {documentsInfo && documentsInfo.createdUser && !isEmptyObject(documentsInfo.createdUser) && (
                        <Select.Option value={documentsInfo.createdUser.id} className="documents-control-option">
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 600,
                            }}>{`${documentsInfo.createdUser.firstName} ${documentsInfo.createdUser.lastName}`}</span>
                        </Select.Option>
                      )}
                      <Select.Option value={user.id} className="documents-control-option">
                        <span
                          style={{
                            fontSize: '14px',
                            fontWeight: 600,
                          }}>{`${user.firstName} ${user.lastName}`}</span>
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Form.Item>
        </Form>
      </Modal>
      {Prompt}
    </Spin>
  );
};

export default ContractForm;
