import { Badge, Button, Calendar, DatePicker, Row, Upload } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import css from '../../../../assets/styles/dashboard.module.css';
import { AppContext } from '../../../../contexts/AppContextProvider';
import CalendarService from '../../../../services/CalendarServices';
import config from '../../../../config';
import { useLessThen801 } from '../../../../helpers/mediaDetect';
import { formatDate } from '../../../../helpers/dateTools';
import calendarIcon from '../../../../assets/images/ic-calendar-date-picker.svg';
import UpdatesMobileComponent from '../../../Calendar/ConsumerCalendar/components/UpdatesMobileComponent';
import { authDownloadFile } from '../../../../helpers/authFileTools';
import { CalendarTypes } from '../../../../models/CalendarModel';
import parser from 'html-react-parser';
import { IAppContext } from '../../../../typings/IApp';

const service = new CalendarService();
interface IConsumerDashboardNotes {
  isEmptyGallery: boolean;
}

const ConsumerDashboardNotes = ({ isEmptyGallery }: IConsumerDashboardNotes) => {
  const {
    app: { currentProperty },
  } = useContext<IAppContext>(AppContext);
  const [currDate, setCurrDate] = useState(moment());
  const [updates, setUpdates] = useState([]);
  const [begin, setBegin] = useState(moment().startOf('month').format('YYYY-MM-DD hh:mm'));
  const [end, setEnd] = useState(moment().endOf('month').format('YYYY-MM-DD hh:mm'));
  const [isOpenPicker, setIsOpenPicker] = useState(false);
  const [datePickerValue, setDatePickerValue] = useState<moment.Moment | null>(null);
  const [currUpdates, setCurrUpdates] = useState([]);
  const [isChangedDate, setIsChangedDate] = useState(false);
  const {
    app: { isConsumer },
  } = useContext<IAppContext>(AppContext);

  useEffect(() => {
    getCalendarUpdates(currentProperty.id);
  }, [currentProperty.id, currDate]);

  const getCalendarUpdates = (id: number | null) => {
    service
      .getCalendarUpdates({
        filter: {
          and: [
            {
              'properties/id': {
                eq: id,
              },
            },
            {
              date: {
                ge: begin,
              },
            },
            {
              date: {
                lt: moment(end).set({ hour: 0, minute: 0 }).add(1, 'day').format('YYYY-MM-DD HH:mm'),
              },
            },
          ],
        },
      })
      .then((value) => {
        setUpdates(value);
        setCurrUpdates(value);
      })
      .catch((e) => console.log(e));
  };

  const setMonth = (step: string) => {
    const currentMonth = currDate.clone();
    if (step === 'prev') {
      currentMonth.subtract(1, 'months');
      setBegin(currentMonth.format('YYYY-MM-01'));
      setEnd(currentMonth.format('YYYY-MM-') + currentMonth.daysInMonth());
    } else {
      currentMonth.add(1, 'months');
      setBegin(currentMonth.format('YYYY-MM-01'));
      setEnd(currentMonth.format('YYYY-MM-') + currentMonth.daysInMonth());
    }
    setCurrDate(currentMonth);
  };

  const dateCellRender = (value: moment.Moment) => {
    const listData = updates.filter(
      (update: any) => moment(update.date).format('YYYY-MM-DD') === value.utc().format('YYYY-MM-DD'),
    );

    return (
      <ul className={css['events']}>
        {listData.slice(0, 3).map((list: any, index: number) => (
          <li className={css['dot']} key={index}></li>
        ))}
      </ul>
    );
  };

  const setDay = (date: moment.Moment) => {
    setCurrDate(date);
  };

  const dateRender = (current: moment.Moment) => {
    const listData = updates.filter(
      (update: any) => moment(update.date).format('YYYY-MM-DD') === current.utc().format('YYYY-MM-DD'),
    );

    return (
      <div
        className="ant-picker-cell-inner"
        onClick={() => {
          setIsChangedDate(true);
          setCurrUpdates(listData);
          setDatePickerValue(current);
          setIsOpenPicker(false);
        }}>
        {listData.length > 0 ? (
          <Badge count={listData.length} size="small" style={{ top: '-4px' }} color="blue">
            <span style={{ fontSize: '18px', marginBottom: '2px' }}>{current.date()}</span>
          </Badge>
        ) : (
          <span style={{ fontSize: '18px', marginBottom: '2px' }}>{current.date()}</span>
        )}
      </div>
    );
  };

  const setMobileCalendarDate = (value: moment.Moment) => {
    setDatePickerValue(null);
    setIsChangedDate(false);
    setCurrDate(value);
    setBegin(value.format('YYYY-MM-01'));
    setEnd(value.format('YYYY-MM-') + value.daysInMonth());
  };

  const resetMobileCalendarDate = () => {
    setDatePickerValue(null);
    getCalendarUpdates(currentProperty.id);
    setIsChangedDate(false);
  };

  if (useLessThen801()) {
    return (
      <div className={css['calendar-mobile-container']}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: '1rem' }}>
          <span style={{ color: '#778dac', fontSize: '16px', marginRight: '1rem' }}>{`${formatDate(
            currDate,
            'MMMM YYYY',
            true,
          )}`}</span>
          <DatePicker
            allowClear={false}
            showToday={false}
            onBlur={() => setIsOpenPicker(false)}
            onClick={() => setIsOpenPicker(true)}
            open={isOpenPicker}
            value={datePickerValue}
            inputReadOnly
            format="DD.MM.YYYY"
            dateRender={dateRender}
            suffixIcon={<img src={calendarIcon} alt="" />}
            style={{ width: '50%' }}
            onPanelChange={(value) => {
              setMobileCalendarDate(moment(value));
            }}
          />
          {isChangedDate && (
            <span onClick={() => resetMobileCalendarDate()} style={{ marginLeft: '0.5rem' }}>
              x
            </span>
          )}
        </div>
        {isConsumer && updates.length > 0 && <UpdatesMobileComponent updates={currUpdates} />}
      </div>
    );
  }

  return (
    <div className={css[isEmptyGallery ? 'consumer-notes-container' : 'consumer-notes']}>
      <div className={css['notes-calendar-container']}>
        <Calendar
          value={currDate}
          dateCellRender={dateCellRender}
          onSelect={(date) => setDay(date)}
          headerRender={() => (
            <Row className={css['calendar-header-consumer']}>
              <div className={css['header-date']}>
                <span className={css['current-date-calendar']}>{`${formatDate(currDate, 'MMMM YYYY', true)}`}</span>
                <span className={css['arrows-container']}>
                  <Button className={css['arrow-container']} onClick={() => setMonth('prev')}>
                    <div className={`${css['arrow']} ${css['arrow-left']}`}></div>
                  </Button>
                  <Button className={css['arrow-container']} onClick={() => setMonth('next')}>
                    <div className={`${css['arrow']} ${css['arrow-right']}`}></div>
                  </Button>
                </span>
              </div>
            </Row>
          )}
        />
      </div>
      <div className={css['notes-updates-container']}>
        {updates
          .sort((a: any, b: any) => b.id - a.id)
          .filter((update: any) => moment(update.date).format('YYYY-MM-DD') === currDate.format('YYYY-MM-DD'))
          .map((update: any) => (
            <div key={update.id}>
              <div className={css['date-news-desc']}>
                <li className={css['update-date']}>{formatDate(currDate, 'DD MMMM', true)}</li>
                <br />
                <span className="parser-container">{parser(update.description || '')}</span>
                <Upload
                  listType={
                    update.type.code === CalendarTypes.document ||
                    update.files.map((file: any) => file.file.slice(-3)).includes('pdf')
                      ? 'text'
                      : 'picture-card'
                  }
                  openFileDialogOnClick={false}
                  className={
                    update.type.code === CalendarTypes.document ||
                    update.files.map((file: any) => file.file.slice(-3)).includes('pdf')
                      ? 'upload-updates'
                      : 'upload-updates-view'
                  }
                  defaultFileList={
                    update.files.map((file: any) => ({
                      name: file.fileName || '',
                      uid: file.id,
                      url: `${config.storeUrl}/${file.file}`,
                      file: file.file,
                    })) as any
                  }
                  directory
                  showUploadList={{ showDownloadIcon: true, showRemoveIcon: false }}
                  beforeUpload={() => false}
                  onPreview={(file: any) => {
                    authDownloadFile(file.file, file.name);
                  }}
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ConsumerDashboardNotes;
