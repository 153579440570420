import moment from 'moment';
import React from 'react';
import { convertToPrice } from '../../../../helpers/convertToPrice';
import { setTextColorByStatus } from '../../../../helpers/setTextColorByStatus';
import { IContractModel } from '../../../../models/DocumentsModel';
import { IStatusModel } from '../../../../models/StatusModel';
import { TFunction } from 'react-i18next';
import { IQuoteModel } from '../../../../models/QuotesModel';
import { IAmendmentModel } from '../../../../models/AmendmentModel';

interface ColumnsProps {
  t: TFunction<'translation'>;
}

export const getQuotesColumns = ({ t }: ColumnsProps) => [
  {
    title: () => <span className="budget-table-tr">№</span>,
    key: 'number',
    width: '15%',
    render: (cell: IQuoteModel) => <span>{cell.number}</span>,
  },
  {
    title: () => <span className="budget-table-tr">{t('documents.table.column.description')}</span>,
    key: 'description',
    width: '16%',
    render: (cell: IQuoteModel) => <span style={{ wordWrap: 'break-word' }}>{cell.description}</span>,
  },
  {
    title: () => <span className="budget-table-tr">{t('amendment.title')}</span>,
    key: 'amendment',
    width: '10%',
    render: (cell: IQuoteModel) => <span style={{ wordWrap: 'break-word' }}>{cell?.amendment?.number || '-'}</span>,
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.sum')}</span>,
    key: 'sum',
    width: '7%',
    render: (cell: IQuoteModel) =>
      `${cell.currency?.description || t('budget.currency')} ${
        cell.sumWithFees !== null
          ? convertToPrice(+cell.sumWithFees)
          : cell.sum !== null
          ? convertToPrice(+cell.sum)
          : '-'
      }`,
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.sum.vat')}</span>,
    key: 'sumWithVat',
    width: '13%',
    render: (cell: IQuoteModel) =>
      `${cell.currency?.description || t('budget.currency')} ${
        cell.sumWithVat !== null ? convertToPrice(+cell.sumWithVat) : '-'
      }`,
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.created.date')}</span>,
    key: 'createdDate',
    width: '100px',
    render: (cell: IQuoteModel) => `${moment(cell.createdDate).format('DD.MM.YYYY')}`,
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.form.deadline')}</span>,
    key: 'deadline',
    width: '80px',
    render: (cell: IQuoteModel) => (!!cell.deadline ? moment(cell.deadline).format('DD.MM.YYYY') : ''),
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.form.date.of.signature')}</span>,
    key: 'lastSignDateTime',
    width: '80px',
    render: (cell: IQuoteModel) =>
      cell.statusV2?.code === 'signed' && !!cell.lastSignDateTime
        ? moment(cell.lastSignDateTime).format('DD.MM.YYYY HH:mm')
        : '',
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.status')}</span>,
    key: 'status',
    width: '120px',
    render: (cell: IQuoteModel) => (
      <span style={{ color: setTextColorByStatus(cell.statusV2.code) }}>{cell.statusV2.description}</span>
    ),
  },
];

const colors: { [code: string]: string } = {
  new: '#1374e6',
  approved: '#19ac87',
  signed: '#00d29f',
};

const setImgByStatus = (status: IStatusModel) => {
  const { code, description } = status;
  return <span style={{ color: colors[code] || '#eb7d5a', fontSize: '14px' }}>{description?.toLowerCase()}</span>;
};

export const getDocumentsColumns = ({ t }: ColumnsProps) => [
  {
    title: () => <span className="documents-table-tr">{t('documents.table.column.name')}</span>,
    key: 'name',
    width: '17%',
    render: (cell: IContractModel) => <span>{cell.title}</span>,
  },
  {
    title: () => <span className="documents-table-tr">{t('documents.table.column.description')}</span>,
    key: 'description',
    width: '23%',
    render: (cell: IContractModel) => <span style={{ wordWrap: 'break-word' }}>{cell.description}</span>,
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.sum')}</span>,
    key: 'sumWithFees',
    width: '13%',
    render: (cell: IContractModel) =>
      `${cell.currency?.description || t('budget.currency')} ${
        cell.sumWithFees !== null ? convertToPrice(cell.sumWithFees) : '-'
      }`,
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.sum.vat')}</span>,
    key: 'sumWithVat',
    width: '13%',
    render: (cell: IContractModel) =>
      `${cell.currency?.description || t('budget.currency')} ${
        cell.sumWithVat !== null ? convertToPrice(cell.sumWithVat) : '-'
      }`,
  },
  {
    title: () => <span className="documents-table-tr">{t('documents.table.date')}</span>,
    key: 'createdDate',
    sorter: true,
    width: '100px',
    render: (cell: IContractModel) => `${moment(cell.createdDate).format('DD.MM.YYYY')}`,
  },
  {
    title: () => <span className="budget-table-tr">{t('documents.table.date.of.signature')}</span>,
    key: 'lastSignDateTime',
    sorter: true,
    width: '120px',
    render: (cell: IContractModel) =>
      cell.statusV2?.code === 'signed' && !!cell.lastSignDateTime
        ? moment(cell.lastSignDateTime).format('DD.MM.YYYY HH:mm')
        : '',
  },
  {
    title: () => <span className="documents-table-tr">{t('documents.table.status')}</span>,
    key: 'statusV2',
    width: '14%',
    render: (cell: IContractModel) => setImgByStatus(cell.statusV2),
  },
];

export const getAmedmentsColumns = ({ t }: ColumnsProps) => [
  {
    title: () => <span className="budget-table-tr">{t('documents.table.column.number')}</span>,
    key: 'number',
    width: '10%',
    render: (cell: IAmendmentModel) => <span>{cell.number}</span>,
  },
  {
    title: () => <span className="budget-table-tr">{t('documents.table.column.name')}</span>,
    key: 'name',
    width: '10%',
    render: (cell: IAmendmentModel) => <span>{cell.name}</span>,
  },
  {
    title: () => <span className="budget-table-tr">{t('documents.table.column.description')}</span>,
    key: 'description',
    width: '18%',
    render: (cell: IAmendmentModel) => <span style={{ wordWrap: 'break-word' }}>{cell.description}</span>,
  },
  {
    title: () => <span className="budget-table-tr">{t('documents.table.column.description')}</span>,
    key: 'description',
    width: '16%',
    render: (cell: IAmendmentModel) => <span style={{ wordWrap: 'break-word' }}>{cell.description}</span>,
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.sum.vat')}</span>,
    key: 'sumWithVat',
    sorter: true,
    width: '13%',
    render: (cell: IAmendmentModel) =>
      `${cell.currency?.description || t('budget.currency')} ${
        cell.deltaWithVat !== null ? convertToPrice(cell.deltaWithVat) : '-'
      }`,
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.created.date')}</span>,
    key: 'createdDate',
    sorter: true,
    width: '100px',
    render: (cell: IAmendmentModel) => `${moment(cell.createdDate).format('DD.MM.YYYY')}`,
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.form.deadline')}</span>,
    key: 'deadline',
    sorter: true,
    width: '80px',
    render: (cell: IAmendmentModel) => (!!cell.deadline ? moment(cell.deadline).format('DD.MM.YYYY') : ''),
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.status')}</span>,
    key: 'status',
    width: '120px',
    render: (cell: IAmendmentModel) => (
      <span style={{ color: setTextColorByStatus(cell.statusV2?.code) }}>{cell.statusV2.description}</span>
    ),
  },
];
