import HttpService from './common/httpService';
import config from '../config';
import buildQuery from 'odata-query';
import { IUserServiceOptions, IUserTableServiceOptions } from '../typings/IServiceOptions';
import { IUserBlock, UserCreateModel, UserUpdateModel } from '../models/UserModel';

class UsersService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getUsers(option: any) {
    const { top, count, skip, filters, orderBy } = option;

    let filter = [];

    let defaultFilter: any = {};

    if (filters) {
      if (Object.keys(defaultFilter).length) {
        filter = [defaultFilter, ...filters.filter];
      } else {
        filter = [...filters.filter];
      }
      filter.sort((a: any, b: any) =>
        Object.keys(Object.values(a)[0] as any)[0] == 'contains'
          ? -1
          : Object.keys(Object.values(b)[0] as any)[0] == 'contains'
          ? 1
          : 0,
      );
    } else {
      filter = defaultFilter;
    }

    const query = buildQuery({ filter, top, count, skip, orderBy });
    return this.httpService.get(`${config.baseRequest}users${query}`);
  }

  public getUsersOnly({
    projectId,
    top,
    count,
    skip,
    filters,
    userName,
    consumers,
    createdUser,
    supplier,
    agents,
    projects,
    role,
    properties,
    orderBy = ['id desc'],
    isNeedAddOrderBy = false,
    isNeedParams = true,
  }: IUserServiceOptions) {
    let filter: any = [];

    let defaultFilter: any = {
      'roles/name': {
        ne: 'consumer',
      },
    };
    if (isNeedParams) {
      if (consumers) {
        defaultFilter['roles/name'] = {
          eq: 'consumer',
        };
      }

      if (role) {
        defaultFilter['roles/name'] = {
          eq: role,
        };
      }

      if (agents) {
        defaultFilter = {
          and: [
            {
              'roles/name': {
                ne: 'supplier',
              },
            },
            {
              'roles/name': {
                ne: 'consumer',
              },
            },
          ],
        };
      }

      if (supplier) {
        defaultFilter['roles/name'] = {
          eq: 'supplier',
        };
      }

      if (createdUser) {
        defaultFilter['createdUser'] = {
          eq: createdUser,
        };
      }
      if (properties && properties.length > 0) {
        defaultFilter['or'] = properties.map((p: any) => {
          return {
            'properties/id': {
              eq: p,
            },
          };
        });
      }

      if (!!userName) {
        defaultFilter['or'] = [
          {
            firstName: {
              contains: userName,
            },
          },
          {
            lastName: {
              contains: userName,
            },
          },
          {
            login: {
              contains: userName,
            },
          },
        ];
      }

      if (!!projectId) {
        defaultFilter['projects/id'] = {
          eq: projectId,
        };
      }

      if (!!projects) {
        defaultFilter['or'] = projects.map((p: any) => {
          return {
            'projects/id': {
              eq: p,
            },
          };
        });
      }
    }

    if (filters) {
      filter = [defaultFilter, ...filters.filter];
      filter.sort((a: any, b: any) => {
        const check = (item: any) => {
          if (
            Object.values(item).length > 0 &&
            (Object.values(item)[0] as any).length > 0 &&
            Object.values(Object.values(item)[0] as any).length > 0 &&
            Object.values((Object.values(item)[0] as any)[0] as any).length > 0
          ) {
            return Object.keys(Object.values((Object.values(item)[0] as any)[0] as any)[0] as any)[0] == 'contains';
          }
          return false;
        };

        return Object.keys(Object.values(a)[0] as any)[0] == 'contains' || check(a)
          ? -1
          : Object.keys(Object.values(b)[0] as any)[0] == 'contains'
          ? 1
          : 0;
      });
    } else {
      filter = defaultFilter;
    }

    if (isNeedAddOrderBy) {
      orderBy.unshift('usersProjects/deletedDate desc');
    }

    let buildQueryParams: any = {
      filter,
      orderBy,
    };

    if (top) {
      buildQueryParams.top = top;
    }
    if (count) {
      buildQueryParams.count = count;
    }
    if (skip) {
      buildQueryParams.skip = skip;
    }

    const query = buildQuery(buildQueryParams);
    return this.httpService.get(`${config.baseRequest}users${query}`);
  }

  public getConsumersByProject(projectId: number) {
    const filter = {
      and: [
        {
          'projects/id': {
            eq: projectId,
          },
        },
        {
          'roles/name': {
            eq: 'consumer',
          },
        },
      ],
    };

    const query = buildQuery({ filter });
    return this.httpService.get(`${config.baseRequest}users${query}`);
  }

  public getConsumersByProperty(propertyId: number) {
    const filter = {
      and: [
        {
          'properties/id': {
            eq: propertyId,
          },
        },
        {
          'roles/name': {
            eq: 'consumer',
          },
        },
      ],
    };

    const query = buildQuery({ filter });
    return this.httpService.get(`${config.baseRequest}users${query}&$expand=paymentInfo`);
  }
  public getBrokersByProperty(propertyId: number) {
    const filter = {
      and: [
        {
          'properties/id': {
            eq: propertyId,
          },
        },
        {
          'roles/name': {
            eq: 'broker',
          },
        },
      ],
    };

    const query = buildQuery({ filter });
    return this.httpService.get(`${config.baseRequest}users${query}`);
  }

  public getUserById(id: number) {
    return this.httpService.get(`${config.baseRequest}users/${id}`);
  }

  public getUserByEmail(email: string) {
    const filter = {
      and: [
        {
          email: {
            eq: email,
          },
        },
      ],
    };
    const query = buildQuery({ top: 1, filter });
    return this.httpService.get(`${config.baseRequest}users/rolesParams/IS_BROKER${query}`);
  }

  public createUser(body: UserCreateModel) {
    return this.httpService.post(`${config.baseRequest}users`, body);
  }

  public updateUser(id: number, body: UserUpdateModel) {
    return this.httpService.put(`${config.baseRequest}users/${id}`, body);
  }

  public uploadImage(userId: number, file: any) {
    const formData = new FormData();
    formData.append('file', file.originFileObj);
    return this.httpService.postFile(`${config.baseRequest}users/image/${userId}`, formData);
  }

  public deleteImage(userId: number) {
    return this.httpService.delete(`${config.baseRequest}users/image/${userId}`);
  }

  public unblock(userId: number) {
    return this.httpService.put(`${config.baseRequest}users/activate/${userId}`, {});
  }

  public switchUserBlock(body: IUserBlock) {
    return this.httpService.put(`${config.baseRequest}users/company/delete`, body);
  }

  public getEasyUsers(params: {
    projectId?: string[] | number[];
    propertyId?: number[];
    userId?: number[];
    top?: number;
    count?: boolean;
    skip?: number;
    fullName?: string;
    roles?: string[];
  }) {
    const { projectId = [], propertyId = [], userId = [], top, count, skip, fullName, roles = [] } = params;
    let query: any[] = [];
    if (projectId.length > 0) {
      projectId.forEach((id) => query.push(`projectId[]=${id}`));
    } else if (propertyId.length > 0) {
      propertyId.forEach((id) => query.push(`propertyId[]=${id}`));
    }
    if (userId.length > 0) {
      userId.forEach((id) => query.push(`userId[]=${id}`));
    }
    if (top) {
      query.push(`$top=${top}`);
    }
    if (skip) {
      query.push(`$skip=${skip}`);
    }
    if (count) {
      query.push(`$count=${count}`);
    }
    if (fullName) {
      query.push(`fullName=${fullName}`);
    }
    if (roles.length > 0) {
      roles.forEach((role) => query.push(`roles[]=${role}`));
    }
    return this.httpService.get(`${config.baseRequest}users/easy?${query.join('&')}`);
  }

  public changeLanguage(lang: string) {
    return this.httpService.put(`${config.baseRequest}users/language/${lang}`, {});
  }

  public getUsersTable({
    projectId,
    top,
    count,
    skip,
    filters,
    userName,
    projects,
    orderBy = ['id desc'],
    isNeedAddOrderBy = false,
    isNeedParams = true,
  }: IUserTableServiceOptions) {
    let filter: any = [];

    let defaultFilter: any = {
      'roles/name': {
        ne: 'consumer',
      },
    };

    if (isNeedParams) {
      if (!!userName) {
        defaultFilter['or'] = [
          {
            firstName: {
              contains: userName,
            },
          },
          {
            lastName: {
              contains: userName,
            },
          },
          {
            login: {
              contains: userName,
            },
          },
        ];
      }

      if (!!projectId) {
        defaultFilter['projects/id'] = {
          eq: projectId,
        };
      }

      if (!!projects) {
        defaultFilter['or'] = projects.map((p: any) => {
          return {
            'projects/id': {
              eq: p,
            },
          };
        });
      }
    }

    if (filters) {
      filter = [defaultFilter, ...filters.filter];
      filter.sort((a: any, b: any) => {
        const check = (item: any) => {
          if (
            Object.values(item).length > 0 &&
            (Object.values(item)[0] as any).length > 0 &&
            Object.values(Object.values(item)[0] as any).length > 0 &&
            Object.values((Object.values(item)[0] as any)[0] as any).length > 0
          ) {
            return Object.keys(Object.values((Object.values(item)[0] as any)[0] as any)[0] as any)[0] == 'contains';
          }
          return false;
        };

        return Object.keys(Object.values(a)[0] as any)[0] == 'contains' || check(a)
          ? -1
          : Object.keys(Object.values(b)[0] as any)[0] == 'contains'
          ? 1
          : 0;
      });
    } else {
      filter = defaultFilter;
    }

    if (isNeedAddOrderBy) {
      orderBy.unshift('usersProjects/deletedDate desc');
    }

    let buildQueryParams: any = {
      filter,
      orderBy,
    };

    if (top) {
      buildQueryParams.top = top;
    }
    if (count) {
      buildQueryParams.count = count;
    }
    if (skip) {
      buildQueryParams.skip = skip;
    }

    const query = buildQuery(buildQueryParams);
    return this.httpService.get(`${config.baseRequest}users/table${query}`);
  }
}

export default UsersService;
