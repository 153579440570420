import HttpService from './common/httpService';
import config from '../config';
import buildQuery from 'odata-query';
import { NewDocumentsModel, UpdatedDocumentsModel } from '../models/DocumentsModel';

class ContractsService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getContracts(propertyId: number | null, option: any) {
    const { top, count, skip, filters, orderBy } = option;
    let filter = [];

    let defaultFilter: any = {
      'property/id': {
        eq: propertyId,
      },
    };

    if (!propertyId) {
      defaultFilter = {};
    }

    if (filters) {
      if (Object.keys(defaultFilter).length) {
        filter = [defaultFilter, ...filters.filter];
      } else {
        filter = [...filters.filter];
      }
      filter.sort((a: any, b: any) =>
        Object.keys(Object.values(a)[0] as any)[0] == 'contains'
          ? -1
          : Object.keys(Object.values(b)[0] as any)[0] == 'contains'
          ? 1
          : 0,
      );
    } else {
      filter = defaultFilter;
    }
    const query = buildQuery({ filter, top, count, skip, orderBy });
    return this.httpService.get(`${config.baseRequest}contracts${query}`);
  }

  public getContractsNoArchive(propertyId: number | null, option: any) {
    const { top, count, skip, filters, orderBy } = option;
    let filter = [];

    let defaultFilter: any = {
      'property/id': {
        eq: propertyId,
      },
      isArchive: {
        eq: false,
      },
    };

    if (!propertyId) {
      defaultFilter = {
        isArchive: {
          eq: false,
        },
      };
    }

    if (filters) {
      filters.filter.sort((a: any, b: any) => {
        const check = (item: any) => {
          if (
            Object.values(item).length > 0 &&
            (Object.values(item)[0] as any).length > 0 &&
            Object.values(Object.values(item)[0] as any).length > 0 &&
            Object.values((Object.values(item)[0] as any)[0] as any).length > 0
          ) {
            return Object.keys(Object.values((Object.values(item)[0] as any)[0] as any)[0] as any)[0] == 'contains';
          }
          return false;
        };

        return Object.keys(Object.values(a)[0] as any)[0] == 'contains' || check(a)
          ? -1
          : Object.keys(Object.values(b)[0] as any)[0] == 'contains'
          ? 1
          : 0;
      });
      filter = [...filters.filter, defaultFilter];
      filter.sort((a: any, b: any) => {
        const check = (item: any) => {
          if (
            Object.values(item).length > 0 &&
            (Object.values(item)[0] as any).length > 0 &&
            Object.values(Object.values(item)[0] as any).length > 0 &&
            Object.values((Object.values(item)[0] as any)[0] as any).length > 0
          ) {
            return Object.keys(Object.values((Object.values(item)[0] as any)[0] as any)[0] as any)[0] == 'contains';
          }
          return false;
        };

        return Object.keys(Object.values(a)[0] as any)[0] == 'contains' || check(a)
          ? -1
          : Object.keys(Object.values(b)[0] as any)[0] == 'contains'
          ? 1
          : 0;
      });
    } else {
      filter = defaultFilter;
    }
    const query = buildQuery({ filter, top, count, skip, orderBy });
    return this.httpService.get(`${config.baseRequest}contracts${query}`);
  }

  public getContractInfoById(id: number) {
    return this.httpService.get(`${config.baseRequest}contracts/${id}`);
  }

  public createContract(body: NewDocumentsModel) {
    return this.httpService.post(`${config.baseRequest}contracts`, body);
  }

  public updateContract(body: UpdatedDocumentsModel) {
    return this.httpService.put(`${config.baseRequest}contracts`, body);
  }

  public rejectContract(id: number | null, comment: string, envelopeId?: string) {
    const body = id ? { id, comment } : { envelopeId, comment };
    return this.httpService.patch(`${config.baseRequest}contracts/step/reject`, body);
  }

  public uploadContractFiles(updateId: number, file: any) {
    return this.httpService.postFile(`${config.baseRequest}contracts/${updateId}/file`, file);
  }

  //for diagram. Not used.
  public getContractsStatistic(propertyId: number) {
    return this.httpService.get(`${config.baseRequest}contracts/statistic_by_property/${propertyId}`);
  }

  public deleteContract(id: number) {
    return this.httpService.delete(`${config.baseRequest}contracts/${id}`);
  }

  public getSigningUrl(id: number, returnUrl: string, consumerId: number) {
    return this.httpService.post(`${config.baseRequest}contracts/step/redirect`, { id, consumerId, returnUrl });
  }

  public confirmStep(envelopeId?: string, id?: number) {
    return this.httpService.patch(`${config.baseRequest}contracts/step/done`, envelopeId ? { envelopeId } : { id });
  }

  public getContractsHistory(id: number) {
    return this.httpService.get(`${config.baseRequest}contracts/${id}/history`);
  }
}

export default ContractsService;
