import React, { useState } from 'react';
import css from '../../../../../assets/styles/statistics.module.css';
import { IQuoteStatus } from '../../../../../models/DashboardModel';
import { DashboardStatusColors } from '../../../../../constants/DashboardStatusColors';
import { Tooltip } from 'antd';
import { useLessThen801 } from '../../../../../helpers/mediaDetect';
import DiagramTable from '../Diagrams/DiagramTable';
import { useHistory } from 'react-router';

interface IProps {
  status: IQuoteStatus;
  projectId: number;
  propertyId: number;
  isQuote: boolean;
}

const BuildingStatusItem: React.FC<IProps> = ({ status, projectId, propertyId, isQuote }) => {
  const history = useHistory();
  const [isShow, setIsShow] = useState(false);

  const color = DashboardStatusColors.find((item) => item.code === status.code)?.color;

  const handleClick = () => {
    history.push(
      `/file_management/${isQuote ? 'quotes' : 'amendments'}?statusCode=${
        status.code
      }&propertyId=${propertyId}&projectId=${projectId}`,
    );
  };

  return (
    <Tooltip
      color={'#fff'}
      placement={'right'}
      overlayStyle={{
        marginLeft: '1rem',
        maxWidth: '600px',
        width: '100%',
        maxHeight: '80vh',
        overflowY: 'auto',
        display: useLessThen801() ? 'none' : 'block',
      }}
      onVisibleChange={() => setIsShow(!isShow)}
      overlayInnerStyle={{ width: '100%', padding: '1rem', border: '1px solid #c4c6cd' }}
      title={() => (
        <DiagramTable
          quotesId={(isQuote ? status!.quotes : status!.amendments) || []}
          projectId={projectId}
          currency={status.currencies[0] || undefined}
          isQuote={isQuote}
          isShow={isShow}
        />
      )}>
      <div className={css['status-container']} onClick={handleClick}>
        <div className={css['status-color-container']} style={{ backgroundColor: color }} />
        <div className={css['status']}>{`${status.description} -`}</div>
        <div style={{ fontWeight: 800, marginLeft: 3 }}>{status.count}</div>
      </div>
    </Tooltip>
  );
};

export default BuildingStatusItem;
