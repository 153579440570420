import React, { useContext } from 'react';
import { Typography, Space } from 'antd';
import * as pjson from '../../../../package.json';
import { AppContext } from '../../../contexts/AppContextProvider';
import { IAppContext } from '../../../typings/IApp';

const { Text } = Typography;

const Versions = () => {
  const {
    app: { user },
  } = useContext<IAppContext>(AppContext);

  return (
    <Space direction="vertical" className="version-conteiner">
      <Text type="secondary" code>
        v.{pjson.version}, API v.{user?.apiVersion}
      </Text>
    </Space>
  );
};

export default Versions;
