import { Button, Carousel, Row } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import css from '../../../../assets/styles/dashboard.module.css';
import config from '../../../../config';
import { AppContext } from '../../../../contexts/AppContextProvider';
import CalendarService from '../../../../services/CalendarServices';
import { CarouselRef } from 'antd/lib/carousel';
import { IAppContext } from '../../../../typings/IApp';

const service = new CalendarService();

interface IConsumerCarousel {
  setIsEmptyGallery: (b: boolean) => void;
}

interface IPostImage {
  id: number;
  fileName: string;
  file: string;
  size: number;
}

const ConsumerCarousel = ({ setIsEmptyGallery }: IConsumerCarousel) => {
  const history = useHistory();
  const { t } = useTranslation();
  const {
    app: { currentProperty },
  } = useContext<IAppContext>(AppContext);
  const [carouselItems, setCarouselItems] = useState<IPostImage[]>([]);
  const carouselRef = useRef<CarouselRef>(null);
  const option = {
    limit: 5,
    propertyId: currentProperty.id,
  };

  const getCarousel = () => {
    service.getLastImageCalendarUpdate(option.propertyId, option.limit).then((res) => setCarouselItems(res));
  };

  useEffect(() => {
    getCarousel();
  }, [currentProperty]);

  useEffect(() => {
    if (carouselItems.length === 0) {
      return setIsEmptyGallery(true);
    }
    setIsEmptyGallery(false);
  }, [carouselItems]);

  return (
    <>
      {carouselItems.length > 0 && (
        <div className={css['consumer-carousel-container']}>
          <Row className={css['consumer-header-carousel']}>
            <div className={css['header-date']}>
              <span className={css['consumer-carousel-title']}>{t('consumers.galery.title')}</span>
              <div className={css['consumer-corousel-btn-container']}>
                <Button
                  className={`${css['consumer-carousel-btn']} common-secondary-btn`}
                  onClick={() => history.push('/gallery')}>
                  {t('consumers.galery.btn')}
                </Button>
                <span className={css['arrows-container-carousel']}>
                  <Button className={css['arrow-container-carousel']} onClick={() => carouselRef.current?.prev()}>
                    <div className={`${css['arrow']} ${css['arrow-left']}`}></div>
                  </Button>
                  <Button className={css['arrow-container-carousel']} onClick={() => carouselRef.current?.next()}>
                    <div className={`${css['arrow']} ${css['arrow-right']}`}></div>
                  </Button>
                </span>
              </div>
            </div>
          </Row>
          <Carousel ref={carouselRef} className={'consumer-dashboard-slider'}>
            {carouselItems.map((el) => (
              <div key={el.id} className={css['consumer-carousel-item']}>
                <img src={`${config.storeUrl}/${el?.file}`} className={css['consumer-carousel-img']} />
              </div>
            ))}
          </Carousel>
        </div>
      )}
    </>
  );
};

export default ConsumerCarousel;
