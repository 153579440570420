import React from 'react';
import useGetPageTypeConfig from './useGetPageTypeConfig';
import QuotesService from '../../../services/QuoteService';
import DocumentsService from '../../../services/DocumentsService';
import BudgetService from '../../../services/BudgetService';
import AmendmentService from '../../../services/AmendmentService';
import CustomSignFlow from '../../../services/CustomSignFlow';
import { generateFilterfromObj } from '../../../helpers/generateFIlterfromObj';
import { FilterTypes } from '../../../constants/filterTypes';

const quoteService = new QuotesService();
const documentService = new DocumentsService();
const invoiceService = new BudgetService();
const amendmentService = new AmendmentService();
const serviceFlow = new CustomSignFlow();

const useRequests = () => {
  const pageTypeConfig = useGetPageTypeConfig();

  const setRequestByType = (params: any) => {
    if (pageTypeConfig.isDocuments) {
      return documentService.getDocumentsNoArchive(null, params);
    }
    if (pageTypeConfig.isInvoices) {
      return invoiceService.getBudgetNoArchived(null, params);
    }
    if (pageTypeConfig.isAmendments) {
      return amendmentService.getAmendments(null, params);
    }
    return quoteService.getQuotesNoArchived(null, params);
  };

  const setByIdRequestByPageType = (id: number) => {
    if (pageTypeConfig.isDocuments) {
      return documentService.getDocumentsInfoById(id);
    }
    if (pageTypeConfig.isInvoices) {
      return invoiceService.getBudgetInfoById(id);
    }
    if (pageTypeConfig.isAmendments) {
      return amendmentService.getAmendmentById(id);
    }
    return quoteService.getQuoteInfoById(id);
  };

  const setStatusByPageType = () => {
    return serviceFlow.getStatuses({
      filters: generateFilterfromObj({
        'doc_types_statuses/typeCode': {
          type: FilterTypes.SELECT,
          value: pageTypeConfig.isDocuments
            ? 'documents'
            : pageTypeConfig.isQuotes
            ? 'quotes'
            : pageTypeConfig.isAmendments
            ? 'amendments'
            : pageTypeConfig.isInvoices
            ? 'invoices'
            : null,
        },
      }),
    });
  };

  const setCreateRequestByPageType = (body: any) => {
    if (pageTypeConfig.isDocuments) {
      return documentService.createDocument(body);
    }
    if (pageTypeConfig.isInvoices) {
      return invoiceService.createInvoice(body);
    }
    if (pageTypeConfig.isAmendments) {
      return amendmentService.createAmendment(body);
    }
    return quoteService.createQuote(body);
  };

  const setUpdateRequestByPageType = (body: any) => {
    if (pageTypeConfig.isDocuments) {
      return documentService.updateDocument(body);
    }
    if (pageTypeConfig.isInvoices) {
      return invoiceService.updateInvoice(body);
    }
    if (pageTypeConfig.isAmendments) {
      return amendmentService.updateAmendment(body);
    }
    return quoteService.updateQuote(body);
  };

  const setFileRequestByPageType = (id: number, filesData: any) => {
    if (pageTypeConfig.isDocuments) {
      return documentService.uploadDocumentFiles(id, filesData);
    }
    if (pageTypeConfig.isInvoices) {
      return invoiceService.uploadInvoiceFiles(id, filesData);
    }
    if (pageTypeConfig.isAmendments) {
      return amendmentService.uploadAmendmentFiles(id, filesData);
    }
    return quoteService.uploadQuoteFiles(id, filesData);
  };

  const setRejectByPageType = (id: number | null, comment: string, envelopeId?: string) => {
    if (pageTypeConfig.isDocuments) {
      return documentService.rejectDocument(id, comment, envelopeId);
    }
    if (pageTypeConfig.isAmendments) {
      return amendmentService.rejectAmendment(id, comment, envelopeId);
    }
    if (pageTypeConfig.isInvoices) {
      return invoiceService.rejectInvoice(id, comment, envelopeId);
    }
    return quoteService.rejectQuote(id, comment, envelopeId);
  };

  const setDeleteRequestByPageType = (id: number) => {
    if (pageTypeConfig.isDocuments) {
      return documentService.deleteDocuments(id);
    }
    if (pageTypeConfig.isInvoices) {
      return invoiceService.deleteInvoice(id);
    }
    if (pageTypeConfig.isAmendments) {
      return amendmentService.deleteAmedment(id);
    }
    return quoteService.deleteQuote(id);
  };

  const setApproveRequestByPageType = (id: number) => {
    if (pageTypeConfig.isDocuments) {
      return documentService.confirmStep(undefined, id);
    }
    if (pageTypeConfig.isAmendments) {
      return amendmentService.confirmStep(undefined, id);
    }
    if (pageTypeConfig.isInvoices) {
      return invoiceService.confirmStep(undefined, id);
    }
    return quoteService.confirmStep(undefined, id);
  };

  const setConfirmSignRequestByPageType = (id: string) => {
    if (pageTypeConfig.isDocuments) {
      return documentService.confirmStep(id);
    }
    if (pageTypeConfig.isAmendments) {
      return amendmentService.confirmStep(id);
    }
    if (pageTypeConfig.isInvoices) {
      return invoiceService.confirmStep(id);
    }
    return quoteService.confirmStep(id);
  };

  const setHistoryRequestByPageType = (id: number) => {
    if (pageTypeConfig.isDocuments) {
      return documentService.getDocumentHistory(id);
    }
    if (pageTypeConfig.isInvoices) {
      return invoiceService.getInvoiceHistory(id);
    }
    if (pageTypeConfig.isAmendments) {
      return amendmentService.getAmendmentHistory(id);
    }
    return quoteService.getQuoteHistory(id);
  };

  const setSignRequestByPageType = (currentId: number, location: string, userId: number) => {
    if (pageTypeConfig.isDocuments) {
      return documentService.getSigningUrl(currentId, location, userId);
    }
    if (pageTypeConfig.isAmendments) {
      return amendmentService.getSigningUrl(currentId, location, userId);
    }
    if (pageTypeConfig.isInvoices) {
      return invoiceService.getSigningUrl(currentId, location, userId);
    }
    return quoteService.getSigningUrl(currentId, location, userId);
  };

    const setArchivesRequestByPageType = (params: { [name: string]: any }) => {
      if (pageTypeConfig.isAmendments) {
        return amendmentService.getArchiveAmendments(null, params);
      }
      if (pageTypeConfig.isQuotes) {
        return quoteService.getQuotes(params);
      }
      if (pageTypeConfig.isDocuments) {
        return documentService.getDocuments(null, params);
      }
      if (pageTypeConfig.isInvoices) {
        return invoiceService.getBudget(null, params);
      }
      return quoteService.getQuotes(params);
    };

  return {
    setRequestByType,
    setByIdRequestByPageType,
    setStatusByPageType,
    setCreateRequestByPageType,
    setUpdateRequestByPageType,
    setFileRequestByPageType,
    setRejectByPageType,
    setDeleteRequestByPageType,
    setApproveRequestByPageType,
    setConfirmSignRequestByPageType,
    setHistoryRequestByPageType,
    setSignRequestByPageType,
    setArchivesRequestByPageType,
  };
};

export default useRequests;
