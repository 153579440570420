import { Button, notification, Select, TablePaginationConfig } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { TopicModel, TopicsModel } from '../../../../models/FAQModel';
import css from '../../../../assets/styles/faq.module.css';
import cssAI from '../../../../assets/styles/faqAI.module.css';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../utils/i18n';
import { NavLink } from 'react-router-dom';
import { FilterTypes } from '../../../../constants/filterTypes';
import { SelectOutlined } from '@ant-design/icons';
import FAQService from '../../../../services/FAQService';
import { FiltersContext } from '../../../../contexts/FiltersContextProvider';
import { SET_FAQ_FILTERS } from '../../../../constants/actionTypes/filtersConstants';
import { useLessThen801 } from '../../../../helpers/mediaDetect';
import { FilterValue } from 'antd/lib/table/interface';
import aiIcon from '../../../../assets/images/ai/aiIcon.png';

interface ControlProps {
  topics: TopicsModel;
  changeSelectMode: () => void;
  onTableChange: (pagination: TablePaginationConfig | null, filters: Record<string, FilterValue | null | any>) => void;
  isSelectMode: boolean;
  selectedItems: Array<number> | [];
  getQuestions: () => void;
  setIsSelectMode: (b: boolean) => void;
}

const service = new FAQService();

const { Option } = Select;

const ManageFAQControl = (props: ControlProps) => {
  const { topics, changeSelectMode, onTableChange, isSelectMode, selectedItems, getQuestions, setIsSelectMode } = props;
  const { t } = useTranslation();
  const {
    filters: { faqFilter },
    filtersDispatch,
  } = useContext(FiltersContext);
  const [filters, setFilters] = useState(faqFilter);

  useEffect(() => {
    filtersDispatch({
      type: SET_FAQ_FILTERS,
      filters,
    });
  }, [filters]);

  const onChangeTopic = (value: string) => {
    setFilters({ ...filters, topic: value });
    const body = {
      topic: {
        type: FilterTypes.SELECT,
        value: value,
      },
    };
    onTableChange(null, body);
  };

  const deleteItems = () => {
    if (selectedItems.length === 0) {
      notification.error({
        message: t('manage.faq.delete.no.items'),
      });
      return;
    } else {
      service
        .deleteFAQMulti(selectedItems)
        .then(() =>
          notification.success({
            message: t('manage.faq.delete.success.questions'),
          }),
        )
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        )
        .finally(() => {
          getQuestions();
          setIsSelectMode(false);
        });
    }
  };

  return (
    <div className={`${css['control-container']} consumer-control-wrapper`}>
      <div style={useLessThen801() ? { display: 'flex', width: '100%' } : {}}>
        <Button onClick={() => changeSelectMode()} className={css['selected-mode-btn']}>
          <SelectOutlined />
          <span style={{ fontWeight: 600, padding: '5px 0' }}>{t('common.goods.placeholder')}</span>
        </Button>
        <Select
          defaultValue={faqFilter.topic}
          className="consumers-control-select common-animation-primary"
          style={
            useLessThen801() ? { padding: '5px 0', height: '42px', marginTop: 0 } : { padding: '5px 0', height: '42px' }
          }
          onChange={onChangeTopic}
          placeholder={t('budget.control.filter.status.placeholder')}
          dropdownClassName="consumers-control-select-dropdown"
          suffixIcon={
            <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
          }>
          <Option value="" className="budget-control-option">
            <span style={{ fontSize: '14px', fontWeight: 600 }}>{t('manage.faq.control.select.placeholder')}</span>
          </Option>
          {topics.length > 0 &&
            topics.map((topic: TopicModel) => (
              <Option value={topic.id} key={topic.id} className="budget-control-option">
                <span style={{ fontSize: '14px' }}>{i18n.language === 'fr-FR' ? topic.topicFr : topic.topicEn}</span>
              </Option>
            ))}
        </Select>
      </div>
      <div className={css['control-btns-container']}>
        {!useLessThen801() && (
          <>
            <NavLink
              to={'/manage_faq/ai_creating'}
              target="_blank"
              className="common-animation-primary"
              style={{ marginRight: '0.5rem', borderRadius: '4px' }}>
              <Button type="primary" className={cssAI.aiButton}>
                <img src={aiIcon} style={{ height: 39 }} />
                {t('faq.ai.btn.title')}
              </Button>
            </NavLink>

            <NavLink
              to={'/manage_faq/topics'}
              className="common-animation-primary"
              style={{ marginRight: '0.5rem', borderRadius: '4px' }}>
              <Button className={`common-blue-border-btn common-secondary-btn`} style={{ height: '42px' }}>
                {t('manage.control.topics.control.btn')}
              </Button>
            </NavLink>
          </>
        )}
        {isSelectMode && (
          <Button
            onClick={() => deleteItems()}
            className={`${css['manageFaq-control-delete-btn']} common-blue-border-btn common-secondary-btn`}
            style={{ marginRight: '0.5rem', height: '42px' }}>
            {t('common.title.delete')}
          </Button>
        )}
        {!useLessThen801() && (
          <NavLink
            to={'/manage_faq/modify'}
            className="common-animation-primary"
            style={{ marginRight: '0.5rem', borderRadius: '4px' }}>
            <Button className={`common-blue-btn common-primary-btn`} style={{ height: '42px' }}>
              {t('common.btn.new')}
            </Button>
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default ManageFAQControl;
