import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileAddOutlined, ReloadOutlined, DeleteOutlined } from '@ant-design/icons';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import { Row, Col, Card, Tree, Space, Typography, Badge, Button, Popconfirm, notification, Select } from 'antd';
import TypologiesService from '../../services/TypologiesService';
import { AppContext } from '../../contexts/AppContextProvider';
import { TypologiesContext } from '../../contexts/TypologiesContextProvider';
import {
  TYPOLOGY_REFRESH_ITEMS,
  TYPOLOGY_SET_CURRENT_NODE,
  TYPOLOGY_SET_IS_SELECTED_NODE,
  TYPOLOGY_SET_ITEMS,
  TYPOLOGY_SET_PARENT,
  TYPOLOGY_SET_SELECTED_NODES,
} from '../../constants/actionTypes/typologiesConstants';
import { nodeKeys } from './enums/nodeKeys';
import TypologiesModify from './components/TypologiesModify';
import LevelsModify from './components/LevelsModify';
import VariantsModify from './components/VariantsModify';
import SpacesModify from './components/SpacesModify';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import SpaceObjectsModify from './components/SpaceObjectsModify';
import LevelsService from '../../services/LevelsService';
import VariantsService from '../../services/VariantsService';
import SpacesService from '../../services/SpacesService';
import { HeaderContext } from '../../contexts/HeaderContextProvider';
import { CHANGE_CURRENT_PAGE } from '../../constants/actionTypes/headerConstants';
import { useLessThen801 } from '../../helpers/mediaDetect';
import { ProjectModel } from '../../models/ProjectModel';
import css from '../../assets/styles/properties.module.css';
import { SET_CURRENT_PROJECT, SET_CURRENT_PROPERTY } from '../../constants/actionTypes/appConstants';
import { IAppContext } from '../../typings/IApp';
import i18n from '../../utils/i18n';

const typologiesService: TypologiesService = new TypologiesService();
const levelsService: LevelsService = new LevelsService();
const variantsService: VariantsService = new VariantsService();
const spacesService: SpacesService = new SpacesService();

const TypologiesPage: React.FC = (props: any) => {
  const {
    app: { currentProject, user },
    appDispatch,
  } = useContext<IAppContext>(AppContext);
  const {
    nodes: { items, selectedNodes, ...nodes },
    nodesDispatch,
  } = useContext(TypologiesContext);
  const [projectId, setProjectId] = useState(currentProject ? currentProject.id : undefined);

  const { headerDispatch } = useContext(HeaderContext);
  useEffect(() => {
    headerDispatch({
      type: CHANGE_CURRENT_PAGE,
      currentPage: t('header.title.Typologies'),
      path: 'typologies',
    });
  }, [i18n.language]);

  const { t } = useTranslation();
  const { height: windowHeight } = useWindowDimensions();

  const setItems = (items: any) => {
    nodesDispatch({
      type: TYPOLOGY_SET_ITEMS,
      items,
    });
  };

  const setIsRefresh = (isRefresh: boolean) => {
    nodesDispatch({
      type: TYPOLOGY_REFRESH_ITEMS,
      isRefresh,
    });
  };

  const mapChildNodes = (node: any, propName: string, index: number = 1) => {
    const keys = Object.keys(nodeKeys);
    node.key = `${keys[index - 1]}-${node.id}`;
    if (keys[index - 1] !== nodeKeys.spaceObject) {
      node.children = node[propName]
        ?.sort((a: any, b: any) => a?.name.toLowerCase().localeCompare(b?.name.toLowerCase()))
        .map((item: any) => mapChildNodes(item, `${keys[index + 1]}s`, index + 1));
    }
    return node;
  };

  const getTypologies = () => {
    typologiesService.getTypologies(currentProject?.key).then((res: any) => {
      const data = res
        .sort((a: any, b: any) => a?.title.toLowerCase().localeCompare(b?.title.toLowerCase()))
        .map((typology: any) => {
          return mapChildNodes(typology, 'levels');
        });
      setItems(data);
    });
  };

  useEffect(() => {
    return () => {
      setItems([]);
    };
  }, []);

  useEffect(() => {
    if (nodes.isRefresh) {
      getTypologies();
      setIsRefresh(false);
    }
  }, [nodes.isRefresh]);

  const setIsSelectedNode = (value: boolean) => {
    nodesDispatch({
      type: TYPOLOGY_SET_IS_SELECTED_NODE,
      isSelectedNode: value,
    });
  };

  const setSelectedNodes = (selectedNodes: any[]) => {
    nodesDispatch({
      type: TYPOLOGY_SET_SELECTED_NODES,
      selectedNodes,
    });
  };

  const setCurrentNode = (node: any, key: string | null) => {
    nodesDispatch({
      type: TYPOLOGY_SET_CURRENT_NODE,
      currentNode: node,
      currentNodeKey: key,
    });
  };

  const setParent = (parent: any) => {
    nodesDispatch({
      type: TYPOLOGY_SET_PARENT,
      parent,
    });
  };

  useEffect(() => {
    if (currentProject) {
      getTypologies();
      setIsSelectedNode(false);
      setCurrentNode(null, null);
    }
  }, [currentProject]);

  const parseTreeTitle = (node: any) => {
    const key = node.key.split('-')[0];
    switch (key) {
      case nodeKeys.typology:
        return (
          <Space>
            {node.title || node.name}
            <Typography.Title level={5} disabled style={{ fontSize: '12px' }}>
              (&darr; {node.levels.length} levels)
            </Typography.Title>
          </Space>
        );
      case nodeKeys.level:
        return (
          <Space>
            {node.isDefault ? (
              <Typography.Text strong>{node.title || node.name}</Typography.Text>
            ) : (
              <Typography.Text>{node.title || node.name}</Typography.Text>
            )}
            <Typography.Title level={5} disabled style={{ fontSize: '12px' }}>
              (&darr; {node.variants.length} variants)
            </Typography.Title>
          </Space>
        );
      case nodeKeys.variant:
        return (
          <Space>
            <Badge
              color={node.isActive ? 'green' : 'red'}
              title="Active"
              text={
                <>
                  {node.isDefault ? (
                    <Typography.Text strong>{node.title || node.name}</Typography.Text>
                  ) : (
                    <Typography.Text>{node.title || node.name}</Typography.Text>
                  )}
                </>
              }
            />
            <Typography.Title level={5} disabled style={{ fontSize: '12px' }}>
              (&darr; {node.spaces.length} spaces)
            </Typography.Title>
          </Space>
        );
      case nodeKeys.space:
        return (
          <Space>
            {node.title || node.name}
            <Typography.Title level={5} disabled style={{ fontSize: '12px' }}>
              (&darr; {node.spaceObjects.length} objects)
            </Typography.Title>
          </Space>
        );
      case nodeKeys.spaceObject:
        return (
          <Space>
            {node.isDefault ? (
              <Typography.Text strong>{node.title || node.name}</Typography.Text>
            ) : (
              <Typography.Text>{node.title || node.name}</Typography.Text>
            )}
          </Space>
        );
    }
  };

  const parseModifyPage = (node: any) => {
    switch (nodes.currentNodeKey) {
      case nodeKeys.typology:
        return <TypologiesModify />;
      case nodeKeys.level:
        return <LevelsModify />;
      case nodeKeys.variant:
        return <VariantsModify />;
      case nodeKeys.space:
        return <SpacesModify />;
      case nodeKeys.spaceObject:
        return <SpaceObjectsModify />;
      default:
        return <>{node.title}</>;
    }
  };

  const onSelectNode = (selectedKeys: any, { selected, node }: any) => {
    setIsSelectedNode(false);
    setTimeout(() => {
      setSelectedNodes(selectedKeys);
      if (selected) {
        const [selectedKey] = selectedKeys;
        const key = selectedKey.split('-')[0];
        setCurrentNode(node, key);
      } else {
        setCurrentNode(null, null);
      }
      setIsSelectedNode(selected);
    }, 0);
  };

  const handleCreate = () => {
    let key = nodeKeys.typology;
    switch (nodes.currentNodeKey) {
      case nodeKeys.typology: {
        key = nodeKeys.level;
        break;
      }
      case nodeKeys.level: {
        key = nodeKeys.variant;
        break;
      }
      case nodeKeys.variant: {
        key = nodeKeys.space;
        break;
      }
      case nodeKeys.space: {
        key = nodeKeys.spaceObject;
        break;
      }
    }
    setParent(nodes.currentNode);
    setIsSelectedNode(true);
    setCurrentNode(null, key);
  };

  const handleDelete = async () => {
    const { currentNodeKey, currentNode } = nodes;
    let promiseService;
    switch (currentNodeKey) {
      case nodeKeys.typology: {
        if (currentNode.levels && currentNode.levels.length > 0) {
          notification.error({
            message: t('typology.not.delete.message'),
          });
          return;
        }
        promiseService = typologiesService.deleteTypology(currentNode.id, currentProject?.key);
        break;
      }
      case nodeKeys.level: {
        if (currentNode.variants && currentNode.variants.length > 0) {
          notification.error({
            message: t('level.not.delete.message'),
          });
          return;
        }
        promiseService = levelsService.deleteLevel(currentNode.id);
        break;
      }
      case nodeKeys.variant: {
        if (currentNode.spaces && currentNode.spaces.length > 0) {
          notification.error({
            message: t('variant.not.delete.message'),
          });
          return;
        }
        promiseService = variantsService.deleteVariant(currentNode.id);
        break;
      }
      case nodeKeys.space: {
        if (currentNode.spaceObjects && currentNode.spaceObjects.length > 0) {
          notification.error({
            message: t('space.not.delete.message'),
          });
          return;
        }
        promiseService = spacesService.deleteSpace(currentNode.id);
        break;
      }
      case nodeKeys.spaceObject: {
        promiseService = spacesService.deleteSpaceObject(currentNode.id);
        break;
      }
    }
    Promise.all([promiseService]).then(() => {
      getTypologies();
      setParent(null);
      setIsSelectedNode(false);
      setCurrentNode(null, null);
    });
  };

  const onSelect = (id: any) => {
    setProjectId(id);
    if (+id !== currentProject.id) {
      const item = user?.projects.find((project: any) => project.id === +id);
      appDispatch({ type: SET_CURRENT_PROJECT, currentProject: item });
      appDispatch({
        type: SET_CURRENT_PROPERTY,
        currentProperty: user.properties.find((property: any) => property.project.id === item?.id),
      });
    }
  };

  if (useLessThen801()) {
    return (
      <>
        <HeaderTable title={t('typology.title')}></HeaderTable>
        <Row gutter={[24, 4]} style={{ marginBottom: '1rem' }}>
          <Col span={24}>
            <Select
              style={{ padding: '5px 0', marginRight: '1rem' }}
              className="consumers-control-select common-animation-primary"
              value={projectId}
              onChange={onSelect}
              dropdownClassName="consumers-control-select-dropdown"
              placeholder={t('properties.placeholder.change.project')}
              suffixIcon={
                <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
              }>
              {user &&
                user.projects?.length > 0 &&
                user.projects
                  .filter((project) => user.projects.find((item) => item.id === project.id))
                  .map((project) => (
                    <Select.Option value={project.id} key={project.id} className="budget-control-option">
                      <span style={{ fontSize: '14px' }}>{project.title}</span>
                    </Select.Option>
                  ))}
            </Select>
          </Col>
        </Row>
        <Row gutter={[24, 4]}>
          <Col span={24}>
            <Card
              title={
                <div className="modify-title-header">
                  <Typography.Text>{t('common.title.tree')}</Typography.Text>
                  <div className="modify-action-btn">
                    <Space size="small">
                      <Popconfirm
                        placement="topLeft"
                        title={t('common.delete.confirm.message')}
                        onConfirm={handleDelete}
                        okText={t('common.confirm.btn.yes')}
                        cancelText={t('common.confirm.btn.cancel')}>
                        <Button size="small" disabled={!nodes?.currentNode}>
                          <DeleteOutlined />
                        </Button>
                      </Popconfirm>
                      <Button size="small" onClick={handleCreate}>
                        <FileAddOutlined />
                      </Button>
                      <Button size="small" onClick={getTypologies}>
                        <ReloadOutlined />
                      </Button>
                    </Space>
                  </div>
                </div>
              }>
              <Tree
                showLine
                treeData={items}
                titleRender={parseTreeTitle}
                onSelect={onSelectNode}
                virtual={true}
                selectedKeys={selectedNodes}
              />
            </Card>
          </Col>
          {nodes.isSelectedNode && <Col span={24}>{parseModifyPage(nodes?.currentNode)}</Col>}
        </Row>
      </>
    );
  }

  return (
    <>
      <HeaderTable title={t('typology.title')}></HeaderTable>
      <Row gutter={[24, 4]} style={{ marginBottom: '1rem' }}>
        <Col span={24}>
          <Select
            style={{ padding: '5px 0', marginRight: '1rem' }}
            className="consumers-control-select common-animation-primary"
            value={projectId}
            onChange={onSelect}
            dropdownClassName="consumers-control-select-dropdown"
            placeholder={t('properties.placeholder.change.project')}
            suffixIcon={
              <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
            }>
            {user &&
              user.projects?.length > 0 &&
              user.projects
                .filter((project) => user.projects.find((item) => item.id === project.id))
                .map((project) => (
                  <Select.Option value={project.id} key={project.id} className="budget-control-option">
                    <span style={{ fontSize: '14px' }}>{project.title}</span>
                  </Select.Option>
                ))}
          </Select>
        </Col>
      </Row>
      <Row gutter={[24, 4]}>
        <Col span={8}>
          <Card
            title={
              <div className="modify-title-header">
                <Typography.Text>{t('common.title.tree')}</Typography.Text>
                <div className="modify-action-btn">
                  <Space size="small">
                    <Popconfirm
                      placement="topLeft"
                      title={t('common.delete.confirm.message')}
                      onConfirm={handleDelete}
                      okText={t('common.confirm.btn.yes')}
                      cancelText={t('common.confirm.btn.cancel')}>
                      <Button size="small" disabled={!nodes?.currentNode}>
                        <DeleteOutlined />
                      </Button>
                    </Popconfirm>
                    <Button size="small" onClick={handleCreate}>
                      <FileAddOutlined />
                    </Button>
                    <Button size="small" onClick={getTypologies}>
                      <ReloadOutlined />
                    </Button>
                  </Space>
                </div>
              </div>
            }>
            <Tree
              showLine
              treeData={items}
              titleRender={parseTreeTitle}
              onSelect={onSelectNode}
              virtual={true}
              style={{ height: windowHeight - 407 }}
              height={windowHeight - 407}
              selectedKeys={selectedNodes}
            />
          </Card>
        </Col>
        {nodes.isSelectedNode && <Col span={16}>{parseModifyPage(nodes?.currentNode)}</Col>}
      </Row>
    </>
  );
};

export default TypologiesPage;
