import React, { createContext, useReducer, useEffect } from 'react';
import { initialState, reducer } from '../reducers/appReducer';

export const AppContext = createContext({} as any);

const AppContextProvider: React.FC = (props: any) => {
  const [app, appDispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    localStorage.setItem('currentProject', JSON.stringify(app.currentProject));
  }, [app.currentProject]);

  useEffect(() => {
    localStorage.setItem('currentProperty', JSON.stringify(app.currentProperty));
  }, [app.currentProperty]);

  return <AppContext.Provider value={{ app, appDispatch }}>{props.children}</AppContext.Provider>;
};

export default AppContextProvider;
