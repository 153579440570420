import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { Form, Button, Row, Col, Card, Input, Upload, Spin, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import TypologiesService from '../../services/TypologiesService';
import Levels from './components/Levels';
import { Link } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContextProvider';
import { PlusOutlined } from '@ant-design/icons';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { fileURLToAntdModel } from '../../helpers/imageToAntdModel';
import { IAppContext } from '../../typings/IApp';

const service: TypologiesService = new TypologiesService();

const TypologiesModifyPage: React.FC<any> = (props: any) => {
  const {
    app: { currentProject },
  } = useContext<IAppContext>(AppContext);
  const { t } = useTranslation();
  const { id } = useParams<any>();
  const history = useHistory();
  const isCreate = !id;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [typology, setTypology] = useState<any>(undefined);
  const [breadcrumbNameMap, setBreadcrumbNameMap] = useState<{ [name: string]: string }>({});
  const [object3DModel, setObject3DModel] = useState<any>();
  const [default3DModel, setDefault3DModel] = useState<any>([]);
  const [isUpload3DModel, setIsUpload3DModel] = useState<boolean>(false);
  const [isDelete3DModel, setIsDelete3DModel] = useState<boolean>(false);
  const [initialValue, setInitialValue] = useState<any>({});
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const getTypology = () => {
    return service.getTypologyById(id, currentProject?.key).then((res: any) => {
      setTypology(res);
      const data = {
        title: res.title,
        model: null,
      };
      form.setFieldsValue(data);
      setInitialValue(data);
    });
  };

  const getAsyncData = async () => {
    const actions = [];
    if (!isCreate) {
      actions.unshift(getTypology());
    }
    Promise.all(actions).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    getAsyncData();
  }, [id]);

  useEffect(() => {
    setBreadcrumbNameMap(
      history.location.pathname
        .replace('/typologies', '')
        .replace(/\/modify\/\d+/, '/Modifications')
        .replace(/\/modify/, `/${t('common.title.create')}`)
        .split('/')
        .slice(1)
        .reduce(
          (res: any, curr: any, index: number) => ({
            ...res,
            [[Object.keys(res)[index], curr].join('/')]: curr.split(':').shift(),
          }),
          { '/typologies': t('typology.bread.root') },
        ),
    );
  }, [history.location.pathname]);

  useEffect(() => {
    // form.resetFields();
    if (typology && typology.serverFileName) {
      const file = fileURLToAntdModel({
        name: typology.serverFileName,
        id: Date.now(),
      });
      setDefault3DModel([file]);
      setIsUpload3DModel(true);
    }
  }, [typology]);

  const onSubmit = (values: any) => {
    setIsSaving(true);
    const modelActions = (id: number) => {
      const promises: Promise<any>[] = [];
      if (object3DModel) {
        promises.push(service.upload3DModel(id, object3DModel));
      }
      if (isDelete3DModel && !object3DModel) {
        promises.push(service.delete3DModel(id));
      }
      return Promise.all(promises);
    };

    if (isCreate) {
      const { model, ...body } = values;
      service.createTypology(body, currentProject?.key).then((res: any) => {
        modelActions(res.id)
          .then(() => {
            history.push(`${history.location.pathname}/${res.id}`);
            notification.success({
              message: t('typology.create.notification.create'),
            });
          })
          .catch((e) => {
            console.error(e);
            notification.error({
              message: `Error while typology create`,
            });
          })
          .finally(() => setIsSaving(false));
      });
    } else {
      const { model, ...body } = values;
      service
        .updateTypology(
          {
            ...body,
            id: +id,
          },
          currentProject?.key,
        )
        .then(() => {
          modelActions(+id)
            .then(() => {
              getTypology();

              notification.success({
                message: t('typology.create.notification.update'),
              });
            })
            .catch((e) => {
              console.error(e);
              notification.error({
                message: `Error while typology update`,
              });
            })
            .finally(() => setIsSaving(false));
        });
    }
  };

  const onCancel = () => {
    history.push('/typologies');
  };

  const onChange3DModel = (info: UploadChangeParam<UploadFile<any>>) => {
    const { file }: any = info;
    setObject3DModel(file?.status !== 'removed' ? file : null);
    setIsUpload3DModel(file?.status !== 'removed');
    if (file?.status === 'removed') {
      setIsDelete3DModel(true);
    }
  };

  return (
    <Spin spinning={isSaving}>
      <Form
        form={form}
        layout="vertical"
        name="modify_typology"
        onFinish={onSubmit}
        // initialValues={initialValue ? { ...initialValue } : {}}
      >
        <HeaderTable
          title={!isCreate ? `${t('typology.create.title.update')} #${id}` : t('typology.create.title.create')}
          breadcrumb={breadcrumbNameMap}>
          <Form.Item>
            <Button htmlType="button" onClick={onCancel} className="header-btn">
              {t('common.btn.cancel')}
            </Button>
            <Button type="primary" htmlType="submit" className="header-btn">
              {t('common.btn.save')}
            </Button>
          </Form.Item>
        </HeaderTable>
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Card title={t('typology.create.card.typology.label')} loading={loading}>
              <Row gutter={[24, 4]}>
                <Col span={24}>
                  <Form.Item
                    label={t('typology.create.card.typology.title')}
                    name="title"
                    rules={[{ required: true, message: t('common.validation.required') }]}>
                    <Input className="common-animation-primary" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label={t('typology.create.object3D')} name="model">
                    <div className="dropbox">
                      <Upload
                        listType="picture"
                        accept=".glb, .gltf"
                        beforeUpload={() => false}
                        onChange={onChange3DModel}
                        defaultFileList={default3DModel}
                        // onRemove={onRemove3DModel}
                        className="variant-floor-plan"
                        customRequest={() => {}}>
                        {!isUpload3DModel && (
                          <Button className="btn-upload">{`${(<PlusOutlined />)} ${t('common.btn.upload')}`}</Button>
                        )}
                      </Upload>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
          {!isCreate && (
            <Col span={24}>
              <Card
                loading={loading}
                title={t('typology.create.card.levels.title')}
                extra={
                  <Link to={`/typologies/${id}/levels/modify`}>
                    <Button type="primary" style={{ width: 100 }}>
                      {t('common.btn.new')}
                    </Button>
                  </Link>
                }>
                <Levels items={typology?.levels || []} typologyId={id} />
              </Card>
            </Col>
          )}
        </Row>
      </Form>
    </Spin>
  );
};

export default TypologiesModifyPage;
