export interface TaskModel {
  id: number;
  description: string;
  deadline: string;
  assignee: {
    id: number;
    login: string;
    firstName: string;
    lastName: string;
    lockedDate: string;
    image: null | string;
    roles: {
      name: string;
      description: string;
      createdDate: string;
    }[];
  };
  status: {
    code: string;
    description: string;
    createdDate: string;
    updatedDate: string;
  };
  createdDate: string;
  createdUser: {
    id: number;
    login: string;
    firstName: string;
    lastName: string;
    lockedDate: string;
  };
}

export interface NewTaskModel {
  description: string;
  deadline: string;
  assignee: {
    id: number;
  };
}

export interface UpdatedTaskModel {
  id: number;
  description?: string;
  deadline?: string;
  assignee?: {
    id: number;
  };
  status: {
    code: string;
  };
}

export type TasksModel = Array<TaskModel>;

export const StatusTask = {
  done: 'done',
  todo: 'to_do',
};
