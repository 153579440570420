import { Menu, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../../assets/styles/faq.module.css';
import { FAQType, QuestionsModel, TopicsModel } from '../../../../models/FAQModel';
import FAQService from '../../../../services/FAQService';
import { useLocation, useHistory } from 'react-router';
import { getValueInHash } from '../../../../helpers/getValueInHash';
import i18n from '../../../../utils/i18n';

const service = new FAQService();

interface ITopicHIW {
  setSelectTopicId: (n: number | null) => void;
  setResultHIW: (V: null | QuestionsModel) => void;
  selectTopicId: number | null;
  resultHIW: QuestionsModel | null;
  isEmptySearch: boolean;
}

const TopicHIW = (props: ITopicHIW) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { hash, pathname } = useLocation();
  const { setSelectTopicId, setResultHIW, resultHIW, selectTopicId, isEmptySearch } = props;
  const [fetchingData, setFetchingData] = useState(false);
  const [topics, setTopics] = useState<null | TopicsModel>(null);

  const getTopic = () => {
    setFetchingData(true);
    service
      .getTopics({
        filter: {
          'type/code': {
            eq: FAQType.HIW,
          },
        },
      })
      .then((value: TopicsModel) => {
        setTopics(value);
      })
      .finally(() => setFetchingData(false));
  };

  useEffect(() => {
    getTopic();
  }, []);

  useEffect(() => {
    if (isEmptySearch && topics) {
      setResultHIW(null);
      setSelectTopicId(topics.length > 0 ? topics[0].id : null);
    }
  }, [isEmptySearch, topics]);

  const onSelect = (key: string) => {
    setResultHIW(null);
    setSelectTopicId(+key);
    if (hash) {
      history.push(pathname);
    }
  };

  useEffect(() => {
    if (hash && topics) {
      const variables = hash.replace('#', '').split('&');
      const topicId = getValueInHash(variables[0]);
      if (topicId) {
        setSelectTopicId(topics.find((item) => item.id === +topicId)?.id || selectTopicId);
      }
    }
  }, [hash, topics]);

  return (
    <div className={`faq-spin ${css['faq-topic-container']}`}>
      <Spin spinning={fetchingData}>
        {topics && (
          <Menu
            className="faq-topic-list"
            onSelect={({ key }) => onSelect(key)}
            selectedKeys={resultHIW ? [] : selectTopicId ? [selectTopicId.toString()] : []}
            defaultSelectedKeys={topics?.length > 0 ? [topics[0].id.toString()] : []}>
            {topics.map((el) => (
              <Menu.Item key={el.id}>{i18n.language === 'en-US' ? el.topicEn : el.topicFr}</Menu.Item>
            ))}
          </Menu>
        )}
      </Spin>
      <div className={css['faq-topic-support']}>
        {t('faq.topic.support')}{' '}
        <a className={css['faq-support-email']} href="mailto:support@cmp.com">
          {t('faq.support.email')}
        </a>
      </div>
    </div>
  );
};

export default TopicHIW;
