exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".leads_leads-add-new__3lXjf,\n.leads_leads-add-new__3lXjf:hover,\n.leads_leads-add-new__3lXjf:active,\n.leads_leads-add-new__3lXjf:focus {\n  border-radius: 4px;\n  box-shadow: 0 8px 16px 0 rgba(0, 18, 45, 0.12);\n  background-color: #076ee5;\n  color: #fff;\n  height: 42px;\n  font-weight: 600;\n  width: 100px;\n}\n.leads_leads-dropdown-btn__10j8-,\n.leads_leads-dropdown-btn__10j8-:hover,\n.leads_leads-dropdown-btn__10j8-:active,\n.leads_leads-dropdown-btn__10j8-:focus {\n  background-color: #eef0f4;\n  border-radius: 50%;\n  height: auto;\n  padding: 0 4px 1px;\n  width: auto;\n}\n.leads_update-menu-item__21iQi {\n  display: flex;\n  align-items: center;\n  /* TODO: temporary removed. probably that can broke the mobile view */\n  /* background-color: #eef0f4;\n  padding-left: 0.5rem;\n  border-radius: 6px; */\n}\n.leads_update-menu-item__21iQi:hover {\n  background-color: #eef0f4;\n}\n.leads_leads-control-container__1Bx3a {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 1rem;\n}\n.leads_leads-filter-control__3Lhlw {\n  display: flex;\n  align-items: flex-start;\n  height: 100%;\n}\n.leads_leads-filter-title__1RSDH {\n  margin-right: 1rem;\n  color: #00122d;\n  font-size: 14px;\n  font-weight: 600;\n}\n.leads_leads-filters__3gk9Y {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n}\n.leads_arrow-drop__3d3-7 {\n  transform: rotate(180deg);\n}\n.leads_leads-table-container__3tPN8 {\n  border-radius: 6px;\n  border: solid 1px #c4c6ce;\n  padding: 1rem;\n  background-color: #f5f7fa;\n}\n", ""]);

// Exports
exports.locals = {
	"leads-add-new": "leads_leads-add-new__3lXjf",
	"leads-dropdown-btn": "leads_leads-dropdown-btn__10j8-",
	"update-menu-item": "leads_update-menu-item__21iQi",
	"leads-control-container": "leads_leads-control-container__1Bx3a",
	"leads-filter-control": "leads_leads-filter-control__3Lhlw",
	"leads-filter-title": "leads_leads-filter-title__1RSDH",
	"leads-filters": "leads_leads-filters__3gk9Y",
	"arrow-drop": "leads_arrow-drop__3d3-7",
	"leads-table-container": "leads_leads-table-container__3tPN8"
};