import { Button, Modal, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IConfirm {
  action: () => void;
  text: string;
  type: string;
  fetchingData: boolean;
  isOpen: boolean;
  setClose: () => void;
}

const CommonConfirmForm = (props: IConfirm) => {
  const { action, text, type, fetchingData, isOpen, setClose } = props;
  const { t } = useTranslation();

  const setConfirmButton = (type: string) => {
    switch (type) {
      case 'delete': {
        return t('budget.modal.delete.btn');
      }
      case 'archive': {
        return t('budget.modal.arhive.btn');
      }
      default: {
        return t('common.modal.confirm').toUpperCase();
      }
    }
  };

  return (
    <Spin spinning={fetchingData}>
      <Modal
        className="common-modal"
        centered
        style={{ width: '10rem' }}
        visible={isOpen}
        footer={
          <div className="common-modal-footer">
            <Button className={`common-gray-border-btn common-secondary-btn`} onClick={() => setClose()}>
              {t('budget.modal.cancel.btn')}
            </Button>
            <Button className={`common-green-btn common-modal-btn`} onClick={() => action()}>
              {setConfirmButton(type)}
            </Button>
          </div>
        }
        closable={false}>
        <span className="common-confirm-text">{text}</span>
      </Modal>
    </Spin>
  );
};

export default CommonConfirmForm;
