import { useState } from 'react';
import { CommonDocumentsType } from '../../../models/CommonDocumnetsModel';

interface IProps {
  getItems?: (id?: number) => void;
  items?: CommonDocumentsType[];
}
const useModal = ({ getItems, items = [] }: IProps) => {
  const [id, setId] = useState<null | number>(null);
  const [item, setItem] = useState<CommonDocumentsType | string | null>(null);
  const [signInfo, setSignInfo] = useState<string>();
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = ({
    itemId,
    itemTitle,
    currentItem,
    signInfoItem,
  }: {
    itemId?: number;
    itemTitle?: string;
    currentItem?: CommonDocumentsType;
    signInfoItem?: string;
  }) => {
    if (itemId) {
      setId(itemId);
    }
    if (items.length > 0) {
      const currentItem = items.find((item) => item.id === itemId);
      setItem(currentItem || null);
    }
    if (currentItem || itemTitle) {
      setItem(currentItem || itemTitle || null);
    }
    if (signInfo) {
      setSignInfo(signInfoItem);
    }
    setIsOpen(true);
  };

  const handleClose = (isNeedUpdate: boolean = true) => {
    setId(null);
    setIsOpen(false);
    setItem(null);
    setSignInfo(undefined);
    if (getItems && isNeedUpdate) {
      getItems();
    }
  };

  return { id, item, signInfo, isOpen, handleOpen, handleClose };
};

export default useModal;
