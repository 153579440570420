import React from 'react';
import { Typography, Row, Col } from 'antd';
import ProductNameLayout from '../../../components/common/ProductNameLayout';
import ArticleNameLayout from '../../../components/common/ArticleNameLayout';
import { TFunction } from 'react-i18next';

interface NestedRequestsColumnsModel {
  t: TFunction<'translation'>;
}

export const getColumns = ({ t }: NestedRequestsColumnsModel) => [
  {
    title: t('requests.nested.table.columns.product'),
    key: 'name',
    render: (cell: any) => (
      <Row align="top">
        <Col span={12}>
          <ProductNameLayout product={cell.option?.product} />
        </Col>
        <Col span={12}>
          <Typography.Text type="secondary">
            <ArticleNameLayout article={cell.option?.product?.article} />
          </Typography.Text>
        </Col>
      </Row>
    ),
    width: 250,
  },
  {
    title: t('requests.nested.table.columns.spaceObject'),
    dataIndex: ['option', 'spaceObject', 'title'],
    key: 'space-object-title',
    width: 200,
  },
  {
    title: t('requests.nested.table.columns.space'),
    dataIndex: ['option', 'spaceObject', 'space', 'title'],
    key: 'space-title',
    width: 200,
  },
  {
    title: t('requests.nested.table.columns.variant'),
    dataIndex: ['option', 'spaceObject', 'space', 'variant', 'title'],
    key: 'variant-title',
    width: 200,
  },
  {
    title: t('requests.nested.table.columns.level'),
    dataIndex: ['option', 'spaceObject', 'space', 'variant', 'level', 'title'],
    key: 'level-title',
    width: 200,
  },
];
