import { Button, Form } from 'antd';
import Search from 'antd/lib/input/Search';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../../assets/styles/faq.module.css';
import { FilterTypes } from '../../../../constants/filterTypes';
import { generateFilterfromObj } from '../../../../helpers/generateFIlterfromObj';
import FAQService from '../../../../services/FAQService';
import searchImg from '../../../../assets/images/ic-search.svg';
import i18n from '../../../../utils/i18n';
import { QuestionsModel } from '../../../../models/FAQModel';

const service = new FAQService();

interface ISearchFAQ {
  setResultFAQ: (V: null | QuestionsModel) => void;
  setSearchingWord: (s: string) => void;
}

const SearchFAQ = (props: ISearchFAQ) => {
  const { setResultFAQ, setSearchingWord } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState('');
  const [fetchingData, setFetchingData] = useState(false);
  const [currentTimeout, setCurrentTimeout] = useState<NodeJS.Timeout | null>(null);

  const option = {
    count: true,
    top: 10,
    filter: {
      'type/code': {
        eq: null,
      },
    },
    [i18n.language === 'en-US' ? 'isPublishedEn' : 'isPublishedFr']: { eq: true },
  };

  useEffect(() => {
    onSubmit();
  }, [searchText]);

  const onSubmit = () => {
    if (searchText) {
      setSearchingWord(searchText);
      onSearch(searchText);
    }
    setSearchingWord(searchText);
  };

  const onSearch = (value: string) => {
    const body = {
      searchByQuestion: {
        type: FilterTypes.MULTISEARCH,
        value: [{ question: value }, { questionEn: value }, { questionFr: value }],
      },
      'type/code': {
        type: FilterTypes.SELECT,
        value: null,
      },
    };

    let params: { [name: string]: any } = {
      ...option,
    };

    params = {
      ...params,
      filter: generateFilterfromObj(body).filter,
    };

    clearTimeout(Number(currentTimeout));
    setCurrentTimeout(setTimeout(() => getFAQ(params), 1000));
  };

  const getFAQ = (params: { [name: string]: any }) => {
    setFetchingData(true);
    service
      .getFAQNoDefaultFilters(params)
      .then((res) => {
        const { items }: { items: QuestionsModel } = res;
        setResultFAQ(items);
      })
      .finally(() => setFetchingData(false));
  };

  return (
    <div className={css['faq-search-container']}>
      <Form
        autoComplete="off"
        className={`${css['faq-search-form']} common-animation-primary`}
        onFinish={onSubmit}
        form={form}>
        <Search
          className={css['faq-search-input']}
          placeholder={t('faq.search.placeholder')}
          onChange={(e) => setSearchText(e.target.value)}
          allowClear
        />
        <Button
          className={css['faq-search-button']}
          htmlType="submit"
          icon={<img src={searchImg} />}
          disabled={fetchingData}
        />
      </Form>
    </div>
  );
};

export default SearchFAQ;
