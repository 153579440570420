import { Select, Spin, Collapse } from 'antd';
import { Option } from 'antd/lib/mentions';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../../assets/styles/faq.module.css';
import { FAQType, QuestionsModel, TopicsModel } from '../../../../models/FAQModel';
import FAQService from '../../../../services/FAQService';
import arrowDrop from '../../../../assets/images/ic-arrow-drop.svg';
import parser from 'html-react-parser';
import { useLocation, useHistory } from 'react-router';
import { getValueInHash } from '../../../../helpers/getValueInHash';
import i18n from '../../../../utils/i18n';

const { Panel } = Collapse;
const service = new FAQService();

interface IMobileHIW {
  setSelectTopicId: (n: number | null) => void;
  setResultHIW: (V: null | QuestionsModel) => void;
  selectTopicId: number | null;
  resultHIW: QuestionsModel | null;
  isEmptySearch: boolean;
  searchingWord: string;
}

const MobileHIW = (props: IMobileHIW) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { hash, pathname } = useLocation();
  const { setSelectTopicId, setResultHIW, resultHIW, selectTopicId, isEmptySearch, searchingWord } = props;
  const [fetchingData, setFetchingData] = useState(false);
  const [topics, setTopics] = useState<null | TopicsModel>(null);
  const [questions, setQuestions] = useState<null | QuestionsModel>(null);
  const [activeKey, setActiveKey] = useState<string | string[] | undefined>(undefined);

  const option = {
    count: true,
    top: 10,
    filter: {
      topic: {
        eq: selectTopicId,
      },
      'type/code': {
        eq: FAQType.HIW,
      },
      [i18n.language === 'en-US' ? 'isPublishedEn' : 'isPublishedFr']: { eq: true },
    },
  };

  const getTopic = () => {
    setFetchingData(true);
    service
      .getTopics({
        filter: {
          'type/code': {
            eq: FAQType.HIW,
          },
        },
      })
      .then((value: TopicsModel) => {
        setTopics(value);
      })
      .finally(() => setFetchingData(false));
  };

  useEffect(() => {
    getTopic();
  }, []);

  useEffect(() => {
    if (isEmptySearch && topics) {
      setResultHIW(null);
      setSelectTopicId(topics.length > 0 ? topics[0].id : null);
    }
  }, [isEmptySearch, topics]);

  useEffect(() => {
    if (hash) {
      const variables = hash.replace('#', '').split('&');
      const topicId = getValueInHash(variables[0]);
      const questionId = getValueInHash(variables[1]);
      if (topics && topicId) {
        setSelectTopicId(topics.find((item) => item.id === +topicId)?.id || selectTopicId);
      }
      if (questions && questionId) {
        setActiveKey(questions.find((item) => item.id === +questionId)?.id.toString() || undefined);
      }
    }
  }, [hash, topics, questions]);

  const onSelect = (value: string) => {
    setResultHIW(null);
    setSelectTopicId(+value);
    if (hash) {
      history.push(pathname);
    }
  };

  const getHIW = () => {
    setFetchingData(true);
    service
      .getFAQNoDefaultFilters(option)
      .then((res) => {
        const { items }: { items: QuestionsModel } = res;
        setQuestions(items);
      })
      .finally(() => setFetchingData(false));
  };

  useEffect(() => {
    if (selectTopicId) {
      getHIW();
    }
  }, [selectTopicId, i18n.language]);

  const getResultHeader = (question: string, searchingWord: string) => {
    const regexp = new RegExp(searchingWord, 'ig');
    const matchValue = question.match(regexp);

    if (matchValue) {
      return question.split(regexp).map((str, index, array) => {
        if (index < array.length - 1) {
          const coincidence = matchValue.shift();
          return (
            <>
              {str}
              <span className={css['faq-result-word']}>{coincidence}</span>
            </>
          );
        }
        return str;
      });
    }
    return question;
  };

  return (
    <>
      <Spin spinning={fetchingData}>
        <div className={`faq-spin ${css['faq-topic-mobile-container']}`}>
          {topics && (
            <Select
              value={selectTopicId?.toString() ?? undefined}
              className="faq-topic-select"
              dropdownClassName="budget-control-select-dropdown"
              onChange={onSelect}
              suffixIcon={<img src={arrowDrop} alt="" className={css['arrow-drop']} />}>
              {topics.map((el) => (
                <Option value={el.id.toString()} key={el.id.toString()} className="budget-control-option">
                  <span style={{ fontSize: '14px', fontWeight: 600 }}>
                    {i18n.language === 'en-US' ? el.topicEn : el.topicFr}
                  </span>
                </Option>
              ))}
            </Select>
          )}
        </div>
        <div className={css['faq-list-info-mobile-container']}>
          {resultHIW ? (
            <h3 className={css['faq-title']}>{t('faq.seacrh.title')}</h3>
          ) : (
            questions && questions.length > 0 && <h3 className={css['faq-title']}>{questions[0].topic.topic}</h3>
          )}
          <Collapse
            accordion
            activeKey={activeKey}
            onChange={(key) => {
              setActiveKey(key);
              if (hash) {
                history.push(pathname);
              }
            }}
            className={`faq-list-info faq-list-info-mobile ${
              resultHIW && resultHIW.length === 0 && css['faq-no-match']
            }`}
            expandIcon={(panelProps) =>
              panelProps.isActive ? (
                <div className={css['faq-item-btn']}>
                  <img src={require('./../../../../assets/images/ic-minus.svg')} />
                </div>
              ) : (
                <div className={css['faq-item-btn']}>
                  <img src={require('./../../../../assets/images/ic-plus.svg')} />
                </div>
              )
            }
            expandIconPosition={'right'}>
            {resultHIW ? (
              resultHIW.length > 0 ? (
                resultHIW.map((el) => (
                  <Panel header={<span>{getResultHeader(el.question, searchingWord)}</span>} key={el.id}>
                    <span className="parser-container">{parser(el.answer)}</span>
                  </Panel>
                ))
              ) : (
                <p>
                  {t('faq.no.search.match')}
                  <a className={css['faq-support-email']} href="mailto:support@cmp.com">
                    {t('faq.support.email')}
                  </a>
                </p>
              )
            ) : (
              questions &&
              questions.length > 0 &&
              questions.map((el) => (
                <Panel header={el.question} key={el.id}>
                  <span className="parser-container">{parser(el.answer)}</span>
                </Panel>
              ))
            )}
          </Collapse>
        </div>
      </Spin>
      <div className={css['faq-topic-support']}>
        {t('faq.topic.support')}
        <a className={css['faq-support-email']} href="mailto:support@cmp.com">
          {t('faq.support.email')}
        </a>
      </div>
    </>
  );
};

export default MobileHIW;
