import { Button, notification, Spin } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../assets/styles/budget.module.css';
import MeetingsService from '../../../services/MeetingsService';

interface DeleteProps {
  isOpen: boolean;
  currentId: number;
  setClose: () => void;
}

const service = new MeetingsService();

const DeleteMeetingForm = (props: DeleteProps) => {
  const { currentId, isOpen, setClose } = props;
  const [fetchingData, setFetchingData] = useState(false);
  const { t } = useTranslation();

  const deleteMeeting = () => {
    setFetchingData(true);
    service
      .deleteMeeting(currentId)
      .then(() =>
        notification.success({
          message: t('meetings.success.delete'),
        }),
      )
      .catch((e) => {
        notification.error({
          message: e.message,
        });
      })
      .finally(() => {
        setClose();
        setFetchingData(false);
      });
  };

  return (
    <Spin spinning={fetchingData}>
      <Modal
        className="common-modal"
        centered
        style={{ width: '10rem' }}
        visible={isOpen}
        footer={
          <div className="common-modal-footer">
            <Button className={`common-gray-border-btn common-secondary-btn`} onClick={() => setClose()}>
              {t('budget.modal.cancel.btn')}
            </Button>
            <Button className={`common-green-btn common-modal-btn`} onClick={() => deleteMeeting()}>
              {t('budget.modal.delete.btn')}
            </Button>
          </div>
        }
        closable={false}>
        <span className={css['delete-invoice-title']}>{t('meeting.delete.confirm')}</span>
      </Modal>
    </Spin>
  );
};

export default DeleteMeetingForm;
