import React, { useContext, useEffect } from 'react';
import { CHANGE_CURRENT_PAGE } from '../../constants/actionTypes/headerConstants';
import { AppContext } from '../../contexts/AppContextProvider';
import { HeaderContext } from '../../contexts/HeaderContextProvider';
import { IAppContext } from '../../typings/IApp';
import NoPropertyPage from '../common/NoPropertyPage';
import ConsumerCalendarPage from './ConsumerCalendar/ConsumerCalendarPage';
import { useTranslation } from 'react-i18next';
import i18n from '../../utils/i18n';

const CalendarPage = () => {
  const { t } = useTranslation();
  const { headerDispatch } = useContext(HeaderContext);
  const {
    app: { currentProperty },
  } = useContext<IAppContext>(AppContext);

  useEffect(() => {
    headerDispatch({
      type: CHANGE_CURRENT_PAGE,
      currentPage: t('calendar.title'),
      path: 'calendar',
    });
  }, [i18n.language]);

  return <>{!currentProperty ? <NoPropertyPage /> : <ConsumerCalendarPage propertyId={currentProperty.id} />}</>;
};

export default CalendarPage;
