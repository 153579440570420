export const getUriSearchValue = (searchStr: string, key: string) => {
  const regex = new RegExp(`.*?[&\\?]`);
  const vars = searchStr.replace(regex, '').split('&');
  for (let i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === key) {
      return decodeURIComponent(pair[1]);
    }
  }
  return;
};
