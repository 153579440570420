import React from 'react';
import moment from 'moment';
import { convertToPrice } from '../../../helpers/convertToPrice';
import { BudgetModel } from '../../../models/BudgetModel';
import archivedIcon from '../../../assets/images/ic-archive.svg';
import downloadIcon from '../../../assets/images/ic-download-dark.svg';
import { authDownloadFile } from '../../../helpers/authFileTools';
import { TFunction } from 'react-i18next';
import { DocumentsModel } from '../../../models/DocumentsModel';
import { IQuoteModel } from '../../../models/QuotesModel';
import { IAmendmentModel } from '../../../models/AmendmentModel';

interface ColumnsProps {
  t: TFunction<'translation'>;
  unArchive: (id: number) => void;
}

export const getBudgetColumns = ({ t, unArchive }: ColumnsProps) => [
  {
    title: () => <span className="budget-table-tr">№</span>,
    key: 'number',
    width: '100px',
    render: (cell: BudgetModel) => <span>{cell.number}</span>,
  },
  {
    title: () => <span className="budget-table-tr">{t('documents.table.column.description')}</span>,
    key: 'description',
    width: '150px',
    render: (cell: BudgetModel) => <span style={{ wordWrap: 'break-word' }}>{cell.description}</span>,
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.sum')}</span>,
    key: 'sumWithVat',
    width: '100px',
    render: (cell: BudgetModel) =>
      `${cell.currency?.description || t('budget.currency')} ${convertToPrice(cell.sumWithVat)}`,
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.date')}</span>,
    key: 'paymentDate',
    width: '100px',
    render: (cell: BudgetModel) => `${moment(cell.paymentDate).format('DD.MM.YYYY')}`,
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.status')}</span>,
    key: 'status',
    width: '150px',
    render: () => <span>{t('common.archived.status')}</span>,
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.action')}</span>,
    key: 'action',
    fixed: 'right' as any,
    width: '230px',
    render: (cell: BudgetModel) => (
      <div style={{ display: 'flex', gap: '1rem' }}>
        <div onClick={() => authDownloadFile(cell.file)} className="download-budget-item common-unarchive-btn">
          <img src={downloadIcon} alt="" style={{ height: '15px', width: '15px', marginRight: '0.5rem' }} />
          <span style={{ color: '#00122d', fontWeight: 600 }}>{t('budget.table.download')}</span>
        </div>
        <div onClick={() => unArchive(cell.id)} className="download-budget-item common-unarchive-btn">
          <img src={archivedIcon} alt="" style={{ height: '15px', width: '15px', marginRight: '0.5rem' }} />
          <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.title.unarchive')}</span>
        </div>
      </div>
    ),
  },
];

export const getDocumentsColumns = ({ t, unArchive }: ColumnsProps) => [
  {
    title: () => <span className="budget-table-tr">{t('documents.table.column.name')}</span>,
    key: 'name',
    width: '100px',
    render: (cell: DocumentsModel) => <span>{cell.title}</span>,
  },
  {
    title: () => <span className="budget-table-tr">{t('documents.table.column.description')}</span>,
    key: 'description',
    width: '150px',
    render: (cell: DocumentsModel) => <span style={{ wordWrap: 'break-word' }}>{cell.description}</span>,
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.status')}</span>,
    key: 'status',
    width: '150px',
    render: () => <span>{t('common.archived.status')}</span>,
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.action')}</span>,
    key: 'action',
    fixed: 'right' as any,
    width: '230px',
    render: (cell: DocumentsModel) => (
      <div style={{ display: 'flex', gap: '1rem' }}>
        <div onClick={() => authDownloadFile(cell.file)} className="download-budget-item common-unarchive-btn">
          <img src={downloadIcon} alt="" style={{ height: '15px', width: '15px', marginRight: '0.5rem' }} />
          <span style={{ color: '#00122d', fontWeight: 600 }}>{t('budget.table.download')}</span>
        </div>
        <div onClick={() => unArchive(cell.id)} className="download-budget-item common-unarchive-btn">
          <img src={archivedIcon} alt="" style={{ height: '15px', width: '15px', marginRight: '0.5rem' }} />
          <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.title.unarchive')}</span>
        </div>
      </div>
    ),
  },
];

export const getQuotesColumns = ({ t, unArchive }: ColumnsProps) => [
  {
    title: () => <span className="budget-table-tr">№</span>,
    key: 'number',
    width: '100px',
    render: (cell: IQuoteModel) => <span>{cell.number}</span>,
  },
  {
    title: () => <span className="budget-table-tr">{t('documents.table.column.description')}</span>,
    key: 'description',
    width: '150px',
    render: (cell: IQuoteModel) => <span style={{ wordWrap: 'break-word' }}>{cell.description}</span>,
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.sum.total')}</span>,
    key: 'sumWithVat',
    width: '150px',
    render: (cell: IQuoteModel) =>
      `${cell.currency?.description || t('budget.currency')} ${convertToPrice(+cell.sumWithVat)}`,
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.status')}</span>,
    key: 'status',
    width: '150px',
    render: () => <span>{t('common.archived.status')}</span>,
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.action')}</span>,
    key: 'action',
    fixed: 'right' as any,
    width: '230px',
    render: (cell: IQuoteModel) => (
      <div style={{ display: 'flex', gap: '1rem' }}>
        <div onClick={() => authDownloadFile(cell.file)} className="download-budget-item common-unarchive-btn">
          <img src={downloadIcon} alt="" style={{ height: '15px', width: '15px', marginRight: '0.5rem' }} />
          <span style={{ color: '#00122d', fontWeight: 600 }}>{t('budget.table.download')}</span>
        </div>
        <div onClick={() => unArchive(cell.id)} className="download-budget-item common-unarchive-btn">
          <img src={archivedIcon} alt="" style={{ height: '15px', width: '15px', marginRight: '0.5rem' }} />
          <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.title.unarchive')}</span>
        </div>
      </div>
    ),
  },
];

export const getAmmendmentsColumns = ({ t, unArchive }: ColumnsProps) => [
  {
    title: () => <span className="documents-table-tr">№</span>,
    key: 'name',
    fixed: 'left' as any,
    width: '100px',
    render: (cell: IAmendmentModel) => <span>{cell.number}</span>,
  },
  {
    title: () => <span className="documents-table-tr">{t('documents.table.column.description')}</span>,
    key: 'description',
    width: '150px',
    render: (cell: IAmendmentModel) => <span style={{ wordWrap: 'break-word' }}>{cell.description}</span>,
  },
  {
    title: () => <span className="documents-table-tr">{t('documents.table.status')}</span>,
    key: 'status',
    width: '150px',
    render: () => <span>{t('common.archived.status')}</span>,
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.form.date.of.signature')}</span>,
    key: 'sign_date',
    width: '120px',
    render: (cell: IAmendmentModel) =>
      !!cell.lastSignDateTime ? moment(cell.lastSignDateTime).format('DD.MM.YYYY HH:mm') : '',
  },
  {
    title: () => <span className="documents-table-tr">{t('project.details.table.project')}</span>,
    key: 'project',
    width: '150px',
    render: (cell: IAmendmentModel) => <span>{cell.property.project.title}</span>,
  },
  {
    title: () => <span className="documents-table-tr">{t('requests.view.card.info.property')}</span>,
    key: 'property',
    width: '150px',
    render: (cell: IAmendmentModel) => <span>{cell.property.title}</span>,
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.action')}</span>,
    key: 'action',
    fixed: 'right' as any,
    width: '230px',
    render: (cell: IAmendmentModel) => (
      <div style={{ display: 'flex', gap: '1rem' }}>
        <div onClick={() => authDownloadFile(cell.file)} className="download-budget-item common-unarchive-btn">
          <img src={downloadIcon} alt="" style={{ height: '15px', width: '15px', marginRight: '0.5rem' }} />
          <span style={{ color: '#00122d', fontWeight: 600 }}>{t('budget.table.download')}</span>
        </div>
        <div onClick={() => unArchive(cell.id)} className="download-budget-item common-unarchive-btn">
          <img src={archivedIcon} alt="" style={{ height: '15px', width: '15px', marginRight: '0.5rem' }} />
          <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.title.unarchive')}</span>
        </div>
      </div>
    ),
  },
];
