import HttpService from './common/httpService';
import config from '../config';
import { getApiKeyOptions } from '../helpers/getApiKeyOptions';

class LevelsService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getLevels(projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.get(`${config.baseRequest}levels`, options);
  }

  public getLevelById(id: number, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.get(`${config.baseRequest}levels/${id}`, options);
  }

  public createLevel(body: any, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.post(`${config.baseRequest}levels`, body, options);
  }

  public updateLevel(body: any, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.put(`${config.baseRequest}levels`, body, options);
  }

  public deleteLevel(id: number, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.delete(`${config.baseRequest}levels/${id}`, null, options);
  }
}

export default LevelsService;
