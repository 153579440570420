import { CreateCompanyModel, UpdatedCompayModel } from './../models/CompaniesModel';
import HttpService from './common/httpService';
import config from '../config';
import buildQuery from 'odata-query';

class CompaniesService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getCompanies(option: any) {
    const query = buildQuery(option);
    return this.httpService.get(`${config.baseRequest}companies${query}`);
  }

  public createCompany(body: CreateCompanyModel) {
    return this.httpService.post(`${config.baseRequest}companies`, body);
  }

  public updateCompany(body: UpdatedCompayModel) {
    return this.httpService.put(`${config.baseRequest}companies`, body);
  }

  public getCompanyById(id: number) {
    return this.httpService.get(`${config.baseRequest}companies/${id}`);
  }

  public deleteCompany(id: number) {
    return this.httpService.delete(`${config.baseRequest}companies/${id}`, null);
  }

  public uploadLogo(companyId: number, file: any) {
    const formData = new FormData();
    formData.append('file', file.originFileObj);
    return this.httpService.postFile(`${config.baseRequest}companies/${companyId}/logo`, formData);
  }

  public deleteLogo(companyId: number) {
    return this.httpService.delete(`${config.baseRequest}companies/${companyId}/logo`);
  }
}

export default CompaniesService;
