import React from 'react';
import css from '../../../assets/styles/calendar.module.css';
import CalendarComponent from '../components/CalendarComponent';
import ProgressConsumerComponent from './components/ProgressConsumerComponent';
interface ConsumerCalendarProps {
  propertyId: number;
}
const ConsumerCalendarPage = (props: ConsumerCalendarProps) => {
  const { propertyId } = props;

  return (
    <div className={css['container-consumer']}>
      <ProgressConsumerComponent />
      <CalendarComponent propertyId={propertyId} />
    </div>
  );
};

export default ConsumerCalendarPage;
