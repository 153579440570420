import React from 'react';
import { useTranslation } from 'react-i18next';
import noPropertyIcon from '../../assets/images/ic-consumer-without-property.svg';
import css from '../../assets/styles/consumers.module.css';

const NoPropertyPage = () => {
  const { t } = useTranslation();

  return (
    <div className={css['no-property-page-container']}>
      <img src={noPropertyIcon} alt="" />
      <span style={{ color: '#bcbebf', marginTop: '1rem' }}>{t('no.property.title')}</span>
    </div>
  );
};

export default NoPropertyPage;
