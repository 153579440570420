import { DatePicker, Input, TablePaginationConfig } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../assets/styles/consumers.module.css';
import { FilterTypes } from '../../../constants/filterTypes';
import searchIcon from '../../../assets/images/ic-search.svg';
import { useLessThen801 } from '../../../helpers/mediaDetect';
import i18n from '../../../utils/i18n';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { INotificationHistory } from '../../../models/NotificationModel';
import { RangePickerProps } from 'antd/lib/date-picker';

const { Search } = Input;
const { RangePicker } = DatePicker;

interface PropsControlModel {
  onTableChange: (
    pagination?: TablePaginationConfig,
    filters?: Record<string, FilterValue | null | any>,
    sorter?: SorterResult<INotificationHistory> | SorterResult<INotificationHistory>[],
  ) => void;
}

const NotificationHistoryControl = (propsControl: PropsControlModel) => {
  const { onTableChange } = propsControl;
  const { t } = useTranslation();
  const [dataRange, setDataRange] = useState<RangePickerProps['value']>(null);
  const [searchEventMessage, setSearchEventMessage] = useState('');
  const [searchEventCreator, setSearchEventCreator] = useState('');
  const [searchLinkName, setSearchLinkName] = useState('');
  const [currentTimeout, setCurrentTimeout] = useState<NodeJS.Timeout | null>(null);

  const onChangeData = (value: RangePickerProps['value']) => {
    setDataRange(value);
    const body = {
      createdDate: {
        type: FilterTypes.DATE_RANGE,
        value: value,
      },
    };
    onTableChange(undefined, body);
  };

  const onSearchEventMessage = (value: string) => {
    setSearchEventMessage(value);
    const body =
      i18n.language === 'fr-FR'
        ? {
            messageFr: {
              type: FilterTypes.SEARCH,
              value: value,
            },
          }
        : {
            messageEn: {
              type: FilterTypes.SEARCH,
              value: value,
            },
          };

    clearTimeout(Number(currentTimeout));
    setCurrentTimeout(setTimeout(() => onTableChange(undefined, body), 1000));
  };

  const onSearchEventCreator = (value: string) => {
    setSearchEventCreator(value);
    const searchValues = value.length > 0 ? value.split(' ') : [];
    const body = {
      searchByName: {
        type: FilterTypes.MULTISEARCH,
        value:
          searchValues.length > 1
            ? [{ 'createdUser/firstName': searchValues[0] || '' }, { 'createdUser/lastName': searchValues[1] || '' }]
            : [{ 'createdUser/firstName': value }, { 'createdUser/lastName': value }],
      },
    };
    clearTimeout(Number(currentTimeout));
    setCurrentTimeout(setTimeout(() => onTableChange(undefined, body), 1000));
  };

  const onSearchLinkName = (value: string) => {
    setSearchLinkName(value);
    const body =
      i18n.language === 'fr-FR'
        ? {
            linkNameFr: {
              type: FilterTypes.SEARCH,
              value: value,
            },
          }
        : {
            linkNameEn: {
              type: FilterTypes.SEARCH,
              value: value,
            },
          };

    clearTimeout(Number(currentTimeout));
    setCurrentTimeout(setTimeout(() => onTableChange(undefined, body), 1000));
  };

  return (
    <div
      className={`${css['consumers-control-filters-container']} consumer-control-wrapper`}
      style={{ marginBottom: '1rem', marginTop: 0 }}>
      <Search
        allowClear
        className="consumers-control-search notification-control-common common-animation-primary"
        value={searchEventMessage}
        style={useLessThen801() ? { padding: '5px 0', marginTop: 0 } : { width: '230px', padding: '5px 0' }}
        placeholder={t('notifications.control.search.message.placeholder')}
        suffix={<img src={searchIcon} alt="" />}
        onChange={(e) => onSearchEventMessage(e.target.value)}
      />
      <Search
        allowClear
        className="consumers-control-search notification-control-common common-animation-primary"
        value={searchLinkName}
        style={useLessThen801() ? { padding: '5px 0', marginTop: 0 } : { width: '230px', padding: '5px 0' }}
        placeholder={t('notifications.control.search.linkName')}
        suffix={<img src={searchIcon} alt="" />}
        onChange={(e) => onSearchLinkName(e.target.value)}
      />
      <Search
        allowClear
        value={searchEventCreator}
        className="consumers-control-search notification-control-common common-animation-primary"
        style={useLessThen801() ? { padding: '5px 0', marginTop: 0 } : { width: '230px', padding: '5px 0' }}
        placeholder={t('notifications.control.search.creator.placeholder')}
        suffix={<img src={searchIcon} alt="" />}
        onChange={(e) => onSearchEventCreator(e.target.value)}
      />
      <RangePicker
        style={{ paddingTop: '5px', paddingBottom: '5px' }}
        suffixIcon={<div className={'calendar-img'} style={{ width: '18px', height: '18px' }}></div>}
        className="documents-control-range-picker notification-control-common common-animation-primary"
        value={dataRange}
        inputReadOnly
        onChange={onChangeData}
      />
    </div>
  );
};

export default NotificationHistoryControl;
