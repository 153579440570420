import React, { RefObject, useContext, useEffect, useRef, useState } from 'react';
import { Button, Card, notification, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import FlowItem from './components/customFlow/FlowItem';
import CustomSignFlow from '../../services/CustomSignFlow';
import { ICreateFlow, IDocumentsType, IFlow, IStepType } from '../../models/CustomSignFlowModel';
import { generateFilterfromObj } from '../../helpers/generateFIlterfromObj';
import { FilterTypes } from '../../constants/filterTypes';
import css from '../../assets/styles/project.module.css';
import icAttantion from '../../assets/images/ic-attention-orange.svg';
import ModalCopyToProject from './components/customFlow/ModalCopyToProject';
import { useLessThen801 } from '../../helpers/mediaDetect';
import BoardTourComponent from '../common/BoardTourComponent';
import CustomOnBoard from '../common/CustomOnBoard';
import { getOnboarding, OnboardingTypes, saveOnboarding } from '../../helpers/onboarding';
import { AppContext } from '../../contexts/AppContextProvider';
import { IAppContext } from '../../typings/IApp';

const serviceFlow = new CustomSignFlow();

interface IProjectCustomFlowPage {
  id: number;
}

const ProjectCustomFlowPage = ({ id }: IProjectCustomFlowPage) => {
  const { t } = useTranslation();
  const {
    app: { user },
  } = useContext<IAppContext>(AppContext);
  const btnRef: RefObject<HTMLElement> = useRef(null);
  const [fetchingData, setFetchingData] = useState(false);
  const [flowCount, setFlowCount] = useState(0);

  const [documentsType, setDocumentsType] = useState<IDocumentsType[]>([]);
  const [steps, setSteps] = useState<IStepType[]>([]);
  const [flows, setFlows] = useState<IFlow[]>([]);

  //board
  const [stepsWizard, _] = useState([
    {
      target: '.custom-flow-first-step',
      content: (
        <BoardTourComponent
          title={t('board.custom.flow.firts.title')}
          description={t('board.custom.flow.firts.text')}
        />
      ),
      disableBeacon: true,
      placement: 'top-end',
    },
    {
      target: '.custom-flow-second-step',
      content: <BoardTourComponent title={''} description={t('board.custom.flow.second.text')} />,
      disableBeacon: true,
      placement: 'top-end',
    },
    {
      target: '.custom-flow-third-step',
      content: <BoardTourComponent title={''} description={t('board.custom.flow.third.text')} />,
      disableBeacon: true,
      placement: 'top-end',
    },
    {
      target: '.custom-flow-forth-step',
      content: <BoardTourComponent title={''} description={t('board.custom.flow.forth.text')} />,
      disableBeacon: true,
      placement: 'top-end',
    },
    {
      target: '.custom-flow-fifth-step',
      content: <BoardTourComponent title={''} description={t('board.custom.flow.fifth.text')} />,
      disableBeacon: true,
      placement: 'top-end',
    },
    {
      target: '.custom-flow-sixth-step',
      content: <BoardTourComponent title={''} description={t('board.custom.flow.sixth.text')} />,
      disableBeacon: true,
      placement: 'top-end',
    },
    {
      target: '.custom-flow-seventh-step',
      content: <BoardTourComponent title={''} description={t('board.custom.flow.seventh.text')} />,
      disableBeacon: true,
      placement: 'top-end',
    },
  ]);
  const [run, setRun] = useState(false);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setFetchingData(true);
    const actions = [getFlows(), getSteps(), getDocumnetsTypes()];
    Promise.all(actions)
      .catch((e) => console.log(e))
      .finally(() => {
        setFetchingData(false);
      });
  }, []);

  const getSteps = () => {
    serviceFlow.getFlowSteps().then((res: IStepType[]) => setSteps(res));
  };

  const getDocumnetsTypes = () => {
    serviceFlow.getDocumentsTypes().then((res: IDocumentsType[]) => setDocumentsType(res));
  };

  const runWizzard = () => {
    btnRef?.current?.scrollIntoView();
    setTimeout(() => setRun(true), 500);
  };

  const getFlows = () => {
    const option = {
      count: true,
      filters: generateFilterfromObj({
        'projects/id': {
          type: FilterTypes.SELECT,
          value: +id,
        },
      }),
    };
    serviceFlow.getFlows(option).then((res) => {
      const { items, count } = res;
      setFlows(items);
      setFlowCount(count);
      if (getOnboarding(OnboardingTypes.PROJECT_FLOW)) {
        setFlowCount(count + 1);
        runWizzard();
      }
    });
  };

  const deleteFlow = (id?: number) => {
    if (id) {
      return serviceFlow
        .deleteFlow(id)
        .then(() => getFlows())
        .catch((e) => console.log(e))
        .finally(() => {
          setFetchingData(false);
        });
    }
    setFlowCount(flowCount - 1);
  };

  const onSubmit = (values: any) => {
    const body: ICreateFlow = {
      name: values.name,
      documentTypes: values.documents.map((code: string) => ({ code })),
      projects: [{ id }],
      flowsSteps: values.steps.map((stepCode: string, index: number) => ({
        stepCode,
        ord: index,
      })),
    };

    setFetchingData(true);
    if (values.id) {
      serviceFlow
        .updateFlow(values.id, body)
        .then(() => getFlows())
        .catch((e) => {
          notification.error({
            message: e.message,
          });
        })
        .finally(() => {
          setFetchingData(false);
        });
    } else {
      serviceFlow
        .createFlow(body)
        .then(() => getFlows())
        .catch((e) => {
          notification.error({
            message: e,
          });
        })
        .finally(() => {
          setFetchingData(false);
        });
    }
  };

  return (
    <>
      <CustomOnBoard
        steps={stepsWizard}
        run={run}
        onFinish={() => {
          setFlowCount(flowCount - 1);
          saveOnboarding(OnboardingTypes.PROJECT_FLOW, user.boardCheckpoint);
        }}
      />
      <Card
        style={{
          padding: '0.5rem',
          backgroundColor: '#f5f7fa',
          border: 'solid 1px #c4c6ce',
          borderRadius: '6px',
          height: '100%',
        }}>
        <Spin spinning={fetchingData} className="project-cfc-spin">
          <p className={css['project-customFlow-p']}>{t('projects.flows.title')}</p>
          <div className={css['project-customFlow-notification']}>
            <img src={icAttantion} />
            <div className={css['project-customFlow-notification-text']}>{t('projects.flows.notification')}</div>
          </div>
          <div>
            {new Array(flowCount).fill(undefined).map((_, index) => (
              <FlowItem
                key={index}
                isLast={flowCount === index + 1}
                steps={steps}
                documentsType={documentsType}
                onSubmit={onSubmit}
                flow={flows[index]}
                deleteFlow={deleteFlow}
              />
            ))}
            <Button
              ref={btnRef}
              className="common-primary-btn common-blue-btn custom-flow-first-step"
              style={useLessThen801() ? { height: 40, width: '100%' } : { height: 40 }}
              onClick={() => setFlowCount(flowCount + 1)}>
              {t('projects.flows.btn.add')}
            </Button>
            <Button
              className="common-blue-border-btn common-secondary-btn custom-flow-seventh-step"
              style={
                useLessThen801()
                  ? { height: 40, width: '100%', marginTop: '0.5rem' }
                  : { height: 40, marginLeft: '0.5rem' }
              }
              onClick={() => setIsVisible(true)}>
              {t('projects.flows.btn.copy')}
            </Button>
          </div>
          {isVisible && (
            <ModalCopyToProject
              isVisible={isVisible}
              setClose={() => setIsVisible(false)}
              projectId={id}
              flows={flows.filter((item) => !item.isDefault)}
            />
          )}
        </Spin>
      </Card>
    </>
  );
};

export default ProjectCustomFlowPage;
