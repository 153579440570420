import HttpService from './common/httpService';
import config from '../config';
import buildQuery from 'odata-query';
import { IProjectCreate, IProjectUpdate } from '../models/ProjectModel';

class ProjectsService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getProjects(option?: any) {
    const query = buildQuery(option);
    return this.httpService.get(`${config.baseRequest}projects${query}`);
  }

  public getProjectById(id: number) {
    return this.httpService.get(`${config.baseRequest}projects/${id}`);
  }

  public getProjectSources() {
    return this.httpService.get(`${config.baseRequest}projects/sources`);
  }

  public createProject(body: IProjectCreate) {
    return this.httpService.post(`${config.baseRequest}projects`, body);
  }

  public updateProject(body: IProjectUpdate) {
    return this.httpService.put(`${config.baseRequest}projects`, body);
  }

  public deleteProject(id: number) {
    return this.httpService.delete(`${config.baseRequest}projects/${id}`, null);
  }

  public uploadLogo(projectId: number, file: any) {
    const formData = new FormData();
    formData.append('file', file.originFileObj);
    return this.httpService.postFile(`${config.baseRequest}projects/logo/${projectId}`, formData);
  }

  public deleteLogo(projectId: number) {
    return this.httpService.delete(`${config.baseRequest}projects/logo/${projectId}`);
  }

  public uploadPaymentLogo(projectId: number, file: any) {
    const formData = new FormData();
    formData.append('file', file.originFileObj);
    return this.httpService.postFile(`${config.baseRequest}projects/paymentLogo/${projectId}`, formData);
  }

  public deletePaymentLogo(projectId: number) {
    return this.httpService.delete(`${config.baseRequest}projects/paymentLogo/${projectId}`);
  }

  public uploadFile(projectId: number, file: any) {
    const formData = new FormData();
    formData.append('file', file.originFileObj);
    return this.httpService.postFile(`${config.baseRequest}projects/file/${projectId}`, formData);
  }

  public deleteFile(projectId: number) {
    return this.httpService.delete(`${config.baseRequest}projects/file/${projectId}`);
  }
}

export default ProjectsService;
