import React from 'react';
import { Button } from 'antd';
import { convertToLocalDateTime } from '../../../helpers/converToLocalDate';
import { NavLink } from 'react-router-dom';
import LogoComponent from '../../../components/common/LogoComponent';
import css from '../../../assets/styles/project.module.css';
import editIcon from '../../../assets/images/ic-edit-dark.svg';
import { ColumnsType } from 'antd/lib/table';
import { IProjectEasy } from '../../../models/ProjectModel';
import { TFunction } from 'react-i18next';

interface UserColumnsModel {
  t: TFunction<'translation'>;
}

export const getColumns = ({ t }: UserColumnsModel): ColumnsType<IProjectEasy> => [
  {
    title: () => (
      <span className="budget-table-tr" style={{ marginLeft: '8px' }}>
        #
      </span>
    ),
    key: 'logo',
    width: 50,
    render: (cell: IProjectEasy) => <LogoComponent id={cell.id} name={cell.title} image={cell.logo || ''} />,
  },
  {
    title: () => <span className="budget-table-tr">{t('projects.table.columns.name')}</span>,
    dataIndex: 'name',
    key: 'name',
    sorter: true,
  },
  {
    title: () => <span className="budget-table-tr">{t('projects.table.columns.title')} </span>,
    dataIndex: 'title',
    key: 'title',
    sorter: true,
  },
  {
    title: () => <span className="budget-table-tr">{t('projects.table.columns.createdDate')}</span>,
    key: 'createdDate',
    sorter: true,
    render: (cell: IProjectEasy) => (cell.createdDate ? convertToLocalDateTime(cell.createdDate) : ''),
  },
  {
    title: () => <span className="budget-table-tr">{t('projects.table.columns.updatedDate')}</span>,
    key: 'updatedDate',
    sorter: true,
    render: (cell: IProjectEasy) => (cell.updatedDate ? convertToLocalDateTime(cell.updatedDate) : ''),
  },
  {
    title: () => <span className="budget-table-tr">{t('users.table.columns.action')}</span>,
    key: 'action',
    fixed: 'right' as any,
    width: '100px',
    render: (cell: IProjectEasy) => {
      return (
        <div style={{ display: 'flex', backgroundColor: 'inherit', gap: '1rem' }}>
          <NavLink
            to={`/projects/modify/${cell.id}/general`}
            style={{ borderRadius: 4 }}
            className="common-animation-secondary">
            <Button className={css['edit-project-btn']}>
              <img src={editIcon} alt="" style={{ width: '12px', height: '12px', marginRight: '0.5rem' }} />
              {t('common.btn.modify.lower')}
            </Button>
          </NavLink>
        </div>
      );
    },
  },
];
