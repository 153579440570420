import { Button, Row, Upload, Form, Input, Col, Select, DatePicker, notification, Spin, Drawer } from 'antd';
import React, { useEffect, useState } from 'react';
import css from '../../../assets/styles/budget.module.css';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import config from '../../../config';
import { convertToCoins, convertToCurrency } from '../../../helpers/convertToPrice';
import TextArea from 'antd/lib/input/TextArea';
import { setTextColorByStatus } from '../../../helpers/setTextColorByStatus';
import UsersService from '../../../services/UsersService';
import { ConsumerModel } from '../../../models/ConsumerModel';
import FinalAccountService from '../../../services/FinalAccountService';
import { useLessThen801, useLessThen768 } from '../../../helpers/mediaDetect';
import { useParams } from 'react-router';
import CustomSignFlow from '../../../services/CustomSignFlow';
import { IFlow, IFlowStep, ISignatoryFlow } from '../../../models/CustomSignFlowModel';
import { generateFilterfromObj } from '../../../helpers/generateFIlterfromObj';
import { FilterTypes } from '../../../constants/filterTypes';
import { StatusesDocumentsModel } from '../../../models/DocumentsModel';
import { onChangeSum, onChangeSumWithVat, onChangeVat } from '../../../helpers/onChangeSumAndVat';
import { IFinalAccountModel, ICreateFinalAccount, IUpdateFinalAccount } from '../../../models/FinalAccountModel';
import { IResponceData } from '../../../typings/IServiceOptions';
import { IUserEasy } from '../../../models/UserModel';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import useCloseTabWarning from '../../../hooks/useCloseTabWarning';

interface FinalAccountModalProps {
  isOpenModal: boolean;
  currentId: number | null;
  getFinalAccounts: () => void;
  propertyId: number;
  setClose: () => void;
  setIsOpenUploadLoader: (b: boolean) => void;
  setIsUploadingFile: (isLoading: boolean) => void;
}

const service = new FinalAccountService();
const userService = new UsersService();
const serviceFlow = new CustomSignFlow();

const initialVat = 7.7;

const FinalAccountForm = (props: FinalAccountModalProps) => {
  const { isOpenModal, currentId, getFinalAccounts, propertyId, setClose, setIsUploadingFile, setIsOpenUploadLoader } =
    props;
  const params: { projectId: string } = useParams();
  const { projectId } = params;
  const { t } = useTranslation();
  const [finalAccount, setFinalAccount] = useState<IFinalAccountModel | null>(null);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileAttachmentList, setFileAttachmentList] = useState<UploadFile[]>([]);
  const [fetchingData, setFetchingData] = useState(false);
  const [isHaveFile, setIsHaveFile] = useState(true);
  const [statuses, setStatuses] = useState<StatusesDocumentsModel>([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isDisabledStatus, setIsDisabledStatus] = useState(true);
  const [isRejectCancel, setIsRejectCancel] = useState(false);
  const [flows, setFlows] = useState<IFlow[]>([]);
  const [flowSteps, setFlowSteps] = useState<IFlowStep[]>([]);

  const SIZE_COLUMN = useLessThen801() ? 24 : 12;
  const HALF_COLUMN_SIZE = useLessThen768() ? 24 : 12;
  const QUARTER_COLUMN_SIZE = useLessThen768() ? 24 : 6;

  const [currentSelectedUsers, setCurrentSelectedUsers] = useState<IUserEasy[]>([]);
  const [searchValue, setSearchValue] = useState<null | string>(null);
  const [currentTimeout, setCurrentTimeout] = useState<NodeJS.Timeout | null>(null);
  const [agents, setAgents] = useState<IUserEasy[]>([]);
  const [consumers, setConsumers] = useState<ConsumerModel[]>([]);
  const [isLastAgentsReq, setIsLastAgentsReq] = useState(false);
  const [fetchingUserData, setFetchingUserData] = useState(false);
  const [paginationAgents, setPaginationAgents] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    skip: 0,
  });

  const { routerPrompt: Prompt, setIsDirty } = useCloseTabWarning(t('common.modal.onbeforeunload.message'));
  useEffect(() => {
    setIsDirty(fetchingData);
  }, [fetchingData]);

  useEffect(() => {
    setFetchingData(true);
    serviceFlow
      .getStatuses({
        filters: generateFilterfromObj({
          'doc_types_statuses/typeCode': {
            type: FilterTypes.SELECT,
            value: 'finalAccount',
          },
        }),
      })
      .then((statusesInfo: StatusesDocumentsModel) => {
        setStatuses(statusesInfo);
        getConsumers();
        if (currentId) {
          service
            .getFinalAccountById(currentId)
            .then((value: IFinalAccountModel) => {
              serviceFlow
                .getFlows(
                  {
                    filters: generateFilterfromObj({
                      'projects/id': {
                        type: FilterTypes.SELECT,
                        value: +projectId,
                      },
                      'document_types/code': {
                        type: FilterTypes.SELECT,
                        value: 'finalAccount',
                      },
                    }),
                  },
                  value.flow ? value.flow.id : undefined,
                )
                .then((flows: IFlow | IFlow[]) => {
                  const isArrayFlows = Array.isArray(flows);
                  setFlows((isArrayFlows ? flows : [flows]) as IFlow[]);
                  getAgents(
                    value?.signatories
                      ?.filter((item) => item.stepCode.split('_').includes('agent'))
                      .map((item) => item.consumerId),
                  );
                  let fieldsValues: { [name: string]: any } = {
                    name: value.name,
                    number: value.number,
                    status: value.statusV2.code,
                    sum: convertToCurrency(value.sumWithFees),
                    sumWithVat: convertToCurrency(value.sumWithVat),
                    description: value.description,
                    deadline: !!value.deadline ? moment(value.deadline) : null,
                    createdDate: !!value.createdDate ? moment(value.createdDate) : null,
                    comment: value.comment,
                    flow: value.flow ? value.flow.id : '',
                  };
                  (
                    (isArrayFlows ? (flows as IFlow[]).find((item) => item.id === value.flow?.id) : flows) as IFlow
                  )?.flowsSteps
                    .map((item) => ({
                      stepCode: item.stepCode,
                      signatoriesName: item.step.signatoriesName,
                    }))
                    .forEach((item) => {
                      fieldsValues[item.stepCode] = value.signatories
                        .filter((sign) => sign.stepCode === item.stepCode)
                        .map((item) => item.consumerId);
                    });
                  form.setFieldsValue(fieldsValues);
                  if (value.flow && value.flow.id) {
                    setFlowSteps(
                      (
                        (isArrayFlows ? (flows as IFlow[]).find((item) => item.id === value.flow?.id) : flows) as IFlow
                      )?.flowsSteps.map((item) => ({
                        signatoriesName: item.step.signatoriesName,
                        code: item.step.code,
                      })) || [],
                    );
                  }
                  if (value.file) {
                    setFileList([
                      {
                        name: `${value.file.slice(0, 40)}...`,
                        uid: value.file,
                        url: `${config.storeUrl}/${value.file}`,
                      },
                    ]);
                  }
                  setIsDisabled(true);
                  setIsDisabledStatus(
                    value.flow === null
                      ? ['rejected', 'done', 'canceled', 'signed'].includes(value.statusV2.code)
                      : true,
                  );
                  setIsRejectCancel(['rejected', 'done', 'canceled', 'signed'].includes(value.statusV2.code));
                  setFinalAccount(value);
                });
            })
            .catch((e) => console.log(e));
        } else {
          serviceFlow
            .getFlows({
              filters: generateFilterfromObj({
                'projects/id': {
                  type: FilterTypes.SELECT,
                  value: +projectId,
                },
                'document_types/code': {
                  type: FilterTypes.SELECT,
                  value: 'finalAccount',
                },
              }),
            })
            .then((flows: IFlow[]) => {
              setFlows(flows);
              const defaultFlowId = flows.find((item) => item.isDefault)?.id;
              form.setFieldsValue({ flow: defaultFlowId || '' });
              setFlowSteps(
                flows
                  .find((item) => item.id === defaultFlowId)
                  ?.flowsSteps.map((item) => ({ signatoriesName: item.step.signatoriesName, code: item.step.code })) ||
                  [],
              );
              getAgents();
            });
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setFetchingData(false));
  }, []);

  useEffect(() => {
    if (searchValue === '') {
      return getAgents([], currentSelectedUsers);
    }
    if (searchValue) {
      getAgents();
    }
  }, [searchValue]);

  const handleFile = (info: UploadChangeParam<UploadFile>) => {
    setFileList(info.fileList);
    setIsHaveFile(true);
  };

  const handleFileAttachment = (info: UploadChangeParam<UploadFile>) => {
    setFileAttachmentList(info.fileList);
  };

  const successUpdate = () => {
    setClose();
    notification.success({
      message: t('finalAccount.confirm.update'),
    });
    getFinalAccounts();
  };

  const getAgents = (agentsId?: number[], agentsRes: IUserEasy[] = []) => {
    if (agentsId && agentsId.length > 0) {
      userService
        .getEasyUsers({ userId: agentsId })
        .then((res: IUserEasy[]) => {
          setCurrentSelectedUsers(res);
          getAgents([], res);
        })
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        );
      return;
    }
    const optionUser = {
      count: true,
      top: paginationAgents.pageSize,
      current: paginationAgents.current,
      skip: paginationAgents.pageSize * (paginationAgents.current - 1) || 0,
      projectId: [projectId],
      fullName: searchValue || '',
      roles: ['agent', 'admin', 'super_agent'],
    };

    setFetchingUserData(true);
    userService
      .getEasyUsers(optionUser)
      .then((res: IResponceData<IUserEasy>) => {
        const { count, items } = res;
        const { current, top } = optionUser;
        setPaginationAgents({ ...paginationAgents, current: current + 1, total: count, pageSize: top });
        let agentsId: number[] = [];
        if (agents.length > 0) {
          agentsId = agents.map((agent) => agent.id);
        } else if (agentsRes.length > 0) {
          agentsId = agentsRes.map((agent) => agent.id);
        }
        setAgents(
          [...agents, ...agentsRes, ...items.filter((item) => !agentsId.includes(item.id))].sort((a, b) =>
            a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()),
          ),
        );
        setIsLastAgentsReq(current * top >= count);
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingUserData(false));
  };

  const getConsumers = () => {
    userService
      .getConsumersByProperty(propertyId)
      .then((value: ConsumerModel[]) => setConsumers(value))
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      );
  };

  const onSubmit = (values: any) => {
    if (fileList.length < 1) {
      setIsHaveFile(false);
      return;
    }

    setFetchingData(true);
    const filesData = new FormData();
    if (finalAccount) {
      for (const file of fileList) {
        if (file.originFileObj) {
          filesData.append(`main_${finalAccount.id}`, file.originFileObj);
        }
      }
    }
    if (!finalAccount) {
      for (const file of fileAttachmentList) {
        if (file.originFileObj) {
          filesData.append('attachments', file.originFileObj);
        }
      }
    }

    let body: ICreateFinalAccount = {
      name: values.name,
      number: values.number,
      statusV2: {
        code: values.status,
      },
      sumWithFees: convertToCoins(values.sum),
      sumWithVat: convertToCoins(values.sumWithVat),
      property: {
        id: propertyId,
      },
      objectText: t('finalAccount.template.object'),
      description: values.description || null,
      comment: values.comment || null,
      deadline: moment(values.deadline).format('YYYY-MM-DD'),
    };

    const signatiriesFlow: ISignatoryFlow[] = [];
    const currentFlow = flows
      ?.find((item) => values.flow && item.id === +values.flow)
      ?.flowsSteps?.sort((a, b) => a.ord - b.ord);

    currentFlow &&
      currentFlow.forEach((item) => {
        values[item.step.code].forEach((id: number) => {
          signatiriesFlow.push({ consumerId: id, stepCode: item.stepCode });
        });
      });

    if (!finalAccount && signatiriesFlow.length > 0) {
      body['signatories'] = signatiriesFlow;
    }

    if (!finalAccount) {
      body['flow'] = values.flow ? { id: values.flow } : null;
    }

    if (finalAccount) {
      (body as IUpdateFinalAccount)['id'] = finalAccount.id;
      service
        .updateFinalAccount(body as IUpdateFinalAccount)
        .then(() => {
          if (filesData.getAll(`main_${finalAccount.id}`).length > 0) {
            setIsUploadingFile(true);
            setClose();
            setIsOpenUploadLoader(true);
            service
              .uploadFinalAccountFiles(finalAccount.id, filesData)
              .then(() => {
                successUpdate();
              })
              .catch((e) => {
                notification.error({
                  message: e.message || t('common.error.internalServerError'),
                });
              })
              .finally(() => {
                setClose();
                setIsUploadingFile(false);
              });
          } else {
            successUpdate();
          }
        })
        .catch((e) => {
          setClose();
          notification.error({
            message: e.message || t('common.error.internalServerError'),
          });
        })
        .finally(() => {
          setClose();
          setFetchingData(false);
        });
    } else {
      service
        .createFinalAccount(body)
        .then((value) => {
          for (const file of fileList) {
            if (file.originFileObj) {
              filesData.append(`main_${value.id}`, file.originFileObj);
            }
          }
          if (filesData.getAll(`main_${value.id}`).length > 0 && value.id) {
            setIsUploadingFile(true);
            setIsOpenUploadLoader(true);
            service
              .uploadFinalAccountFiles(value.id, filesData)
              .then(() => {
                notification.success({
                  message: t('finalAccount.confirm.created'),
                });
              })
              .catch((e) => {
                notification.error({
                  message: e.message || t('common.error.internalServerError'),
                });
              })
              .finally(() => {
                setClose();
                setIsUploadingFile(false);
              });
          }
        })
        .catch((e) => {
          setClose();
          notification.error({
            message: e.message || t('common.error.internalServerError'),
          });
        })
        .finally(() => {
          setClose();
          setFetchingData(false);
        });
    }
  };

  const onSelectFlow = (value: number | '') => {
    form.setFieldsValue({ status: 'new' });
    setFlowSteps(
      flows
        .find((item) => item.id === value)
        ?.flowsSteps.map((item) => ({ signatoriesName: item.step.signatoriesName, code: item.step.code })) || [],
    );
  };

  return (
    <Drawer className="upload-form-drawer" visible={isOpenModal} placement="top" closable={false}>
      <Spin spinning={fetchingData}>
        <Form autoComplete="off" onFinish={onSubmit} form={form}>
          <Form.Item>
            <div className={css['btns-container']}>
              <Button
                className={'common-gray-border-btn common-secondary-btn'}
                onClick={() => setClose()}
                style={{ height: '42px', marginRight: '1rem' }}>
                {t('common.return.btn')}
              </Button>
              <Button
                className={'common-blue-btn common-primary-btn'}
                htmlType="submit"
                style={{ height: '42px' }}
                disabled={fetchingData}>
                {currentId ? t('product.create.title.update') : t('common.btn.upload')}
              </Button>
            </div>
          </Form.Item>
          <div
            className="common-flex-between-style"
            style={useLessThen801() ? { height: '90%', flexDirection: 'column' } : { height: '90%' }}>
            <div className={css['file-container']}>
              <Form.Item
                className={css['form-item-budget']}
                colon={false}
                label={t('quotes.modal.upload.file')}
                labelCol={{ span: 24 }}
                initialValue={fileList}
                name="file">
                <>
                  <Upload
                    className="consumers-import-upload-item file-management-upload"
                    style={{ width: '100%' }}
                    maxCount={1}
                    beforeUpload={() => false}
                    onChange={handleFile}
                    accept=".pdf"
                    fileList={fileList}
                    disabled={isDisabled}>
                    {fileList.length < 1 && (
                      <div className={css['upload-item-budget']}>
                        <div
                          className={css['upload-img']}
                          style={{ marginRight: '0.5rem', width: '18px', height: '14px' }}></div>
                        <span style={{ color: '#076ee5' }}>{t('budget.modal.upload.btn.title')}</span>
                      </div>
                    )}
                  </Upload>
                  {!finalAccount && (
                    <>
                      <div style={{ width: '100%', borderBottom: '1px solid #dbdde4', margin: '8px 0' }}></div>
                      <Upload
                        className="consumers-import-upload-item upload-list documents-attachments"
                        style={{ width: '100%' }}
                        beforeUpload={() => false}
                        onChange={handleFileAttachment}
                        onRemove={() => true}
                        multiple={true}
                        fileList={fileAttachmentList}
                        accept=".pdf"
                        disabled={isDisabled}>
                        <div className={css['upload-item-budget']}>
                          <span style={{ color: '#076ee5' }}>{t('documents.modal.upload.attachment.btn.title')}</span>
                        </div>
                      </Upload>
                    </>
                  )}
                  {!isHaveFile && <span style={{ color: '#ff4d4f' }}>{t('common.validation.required')}</span>}
                </>
              </Form.Item>
            </div>
            <div className={css['form-info-container']}>
              <Form.Item>
                <Row gutter={[24, 4]}>
                  <Col span={SIZE_COLUMN}>
                    <Form.Item
                      rules={[{ required: true, message: t('common.validation.required') }]}
                      name="name"
                      colon={false}
                      label={<span style={{ color: '#778dac' }}>{t('consumers.create.card.user.firstName')}</span>}
                      className={css['form-item']}
                      labelAlign="left">
                      <Input className="common-animation-primary" disabled={isDisabled} />
                    </Form.Item>
                  </Col>
                  <Col span={SIZE_COLUMN}>
                    <Form.Item
                      rules={[{ required: true, message: t('common.validation.required') }]}
                      name="number"
                      colon={false}
                      label={<span style={{ color: '#778dac' }}>{t('budget.form.number')}</span>}
                      className={css['form-item']}
                      labelAlign="left">
                      <Input className="common-animation-primary" disabled={isDisabled} />
                    </Form.Item>
                  </Col>
                  <Col span={SIZE_COLUMN}>
                    <Form.Item
                      name="status"
                      colon={false}
                      rules={[
                        {
                          required: true,
                          message: t('common.validation.required'),
                        },
                      ]}
                      label={
                        <span style={{ color: '#778dac' }} className="documents-form-label">
                          {t('documents.form.status')}
                        </span>
                      }
                      initialValue="new"
                      className={css['form-item']}
                      labelAlign="left">
                      <Select
                        className="common-select common-animation-primary"
                        suffixIcon={
                          <div
                            className={`arrow-drop-img ${css['arrow-drop']}`}
                            style={{ width: '8px', height: '4px' }}></div>
                        }
                        disabled={!currentId || isDisabledStatus}>
                        {statuses.length > 0 &&
                          statuses
                            .filter((status) =>
                              finalAccount && finalAccount.flow === null
                                ? status.docTypesStatuses.find((item) => item.typeCode === 'finalAccount')?.isManual
                                : true,
                            )
                            .map((item) => (
                              <Select.Option key={item.code} value={item.code}>
                                <span style={{ color: `${setTextColorByStatus(item.code)}` }}>{item.description}</span>
                              </Select.Option>
                            ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={SIZE_COLUMN}></Col>
                  <Col span={QUARTER_COLUMN_SIZE}>
                    <Form.Item
                      name="sum"
                      colon={false}
                      labelAlign="left"
                      rules={[{ required: true, message: t('common.validation.required') }]}
                      label={<span style={{ color: '#778dac' }}>{t('budget.form.sum')}</span>}
                      className={css['form-item']}>
                      <Input
                        className="common-animation-primary"
                        disabled={isDisabled}
                        type="number"
                        onChange={(e) => onChangeSum(e, form)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={QUARTER_COLUMN_SIZE}>
                    <Form.Item
                      name="sumWithVat"
                      colon={false}
                      rules={[{ required: true, message: t('common.validation.required') }]}
                      label={<span style={{ color: '#778dac' }}>{t('budget.form.sum.with.vat')}</span>}
                      className={css['form-item']}
                      labelAlign="left">
                      <Input
                        className="common-animation-primary"
                        disabled={true}
                        type="number"
                        onChange={(e) => onChangeSumWithVat(e, form)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={HALF_COLUMN_SIZE}>
                    <Form.Item
                      name="vat"
                      colon={false}
                      rules={[{ required: true, message: t('common.validation.required') }]}
                      label={<span style={{ color: '#778dac' }}>{t('budget.form.vat')}</span>}
                      className={css['form-item']}
                      labelAlign="left"
                      initialValue={initialVat}>
                      <Input
                        className="common-animation-primary"
                        disabled={isDisabled}
                        type="number"
                        onChange={(e) => onChangeVat(e, form)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={SIZE_COLUMN}>
                    <Form.Item
                      name="description"
                      colon={false}
                      label={<span style={{ color: '#778dac' }}>{t('budget.form.description')}</span>}
                      className={css['form-item']}
                      labelAlign="left">
                      <TextArea
                        bordered={false}
                        className="consumer-form-input common-animation-primary"
                        autoSize={{ minRows: 2, maxRows: 10 }}
                        disabled={isRejectCancel}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={SIZE_COLUMN}>
                    <Form.Item
                      name="comment"
                      colon={false}
                      label={<span style={{ color: '#778dac' }}>{t('fileManagement.upload.comment')}</span>}
                      className={css['form-item']}
                      labelAlign="left">
                      <TextArea
                        bordered={false}
                        className="consumer-form-input common-animation-primary"
                        autoSize={{ minRows: 2, maxRows: 10 }}
                        disabled={isRejectCancel}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={SIZE_COLUMN}>
                    <Form.Item
                      name="deadline"
                      colon={false}
                      rules={[{ required: true, message: t('common.validation.required') }]}
                      label={<span style={{ color: '#778dac' }}>{t('budget.form.deadline')}</span>}
                      className={css['form-item']}
                      initialValue={moment().add(10, 'days')}
                      labelAlign="left">
                      <DatePicker
                        disabled={isRejectCancel}
                        style={{ width: '100%' }}
                        suffixIcon={<div className={'calendar-img'} style={{ width: '12px', height: '12px' }}></div>}
                        className="common-date-picker common-animation-primary"
                        format="DD.MM.YYYY"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={SIZE_COLUMN}>
                    <Form.Item
                      name="createdDate"
                      colon={false}
                      initialValue={moment()}
                      label={<span style={{ color: '#778dac' }}>{t('fileManagement.upload.date')}</span>}
                      className={css['form-item']}
                      labelAlign="left">
                      <DatePicker
                        disabled
                        style={{ width: '100%' }}
                        suffixIcon={<div className={'calendar-img'} style={{ width: '12px', height: '12px' }}></div>}
                        className="common-date-picker common-animation-primary"
                        format="DD.MM.YYYY"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[24, 4]}>
                  <Col span={24}>
                    <Form.Item
                      name="flow"
                      colon={false}
                      label={<span style={{ color: '#778dac' }}>{t('contract.flow.title')}</span>}
                      className={css['form-item']}
                      initialValue={''}
                      labelAlign="left">
                      <Select
                        className="common-select common-animation-primary"
                        suffixIcon={
                          <div
                            className={`arrow-drop-img ${css['arrow-drop']}`}
                            style={{ width: '8px', height: '4px' }}></div>
                        }
                        style={{ marginTop: '0' }}
                        onSelect={onSelectFlow}
                        disabled={isDisabled}>
                        <Select.Option value={''}>{t('contract.flow.noFlow')}</Select.Option>
                        {flows.length > 0 &&
                          flows.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  {flowSteps.length > 0 &&
                    flowSteps.map((item) => (
                      <Col span={SIZE_COLUMN} key={item.code}>
                        <Form.Item
                          name={item.code}
                          colon={false}
                          label={<span style={{ color: '#778dac' }}>{item.signatoriesName} *</span>}
                          rules={[{ required: !finalAccount, message: t('common.validation.required') }]}
                          className={css['form-item']}
                          labelAlign="left">
                          <Select
                            className="common-select common-animation-primary"
                            mode="multiple"
                            disabled={isDisabled}
                            onDropdownVisibleChange={(value) => {
                              if (item.code.split('_').includes('agent') && !value && searchValue) {
                                setAgents([]);
                                setPaginationAgents({ ...paginationAgents, current: 1, skip: 0 });
                                setSearchValue('');
                              }
                            }}
                            onSearch={(value) => {
                              if (item.code.split('_').includes('agent')) {
                                setAgents([]);
                                setPaginationAgents({ ...paginationAgents, current: 1, skip: 0 });
                                clearTimeout(Number(currentTimeout));
                                setCurrentTimeout(setTimeout(() => setSearchValue(value), 1000));
                              }
                            }}
                            filterOption={(value, option) =>
                              item.code.split('_').includes('agent')
                                ? true
                                : option?.children?.toLowerCase().indexOf(value.toLowerCase()) >= 0
                            }
                            dropdownClassName={`documents-control-select-dropdown ${
                              fetchingUserData ? 'consumers-control-select-dropdown--progress' : ''
                            }`}
                            onPopupScroll={(e: any) => {
                              e.persist();
                              let target = e.target;
                              if (
                                item.code.split('_').includes('agent') &&
                                !fetchingUserData &&
                                !isLastAgentsReq &&
                                target.scrollTop + target.offsetHeight === target.scrollHeight
                              ) {
                                getAgents();
                              }
                            }}
                            suffixIcon={
                              <div
                                className={`arrow-drop-img ${css['arrow-drop']}`}
                                style={{ width: '8px', height: '4px' }}></div>
                            }>
                            {item.code.split('_').includes('agent')
                              ? agents.length > 0 &&
                                agents.map((signatory) => (
                                  <Select.Option key={signatory.id} value={signatory.id}>
                                    {`${signatory.firstName} ${signatory.lastName}`}
                                  </Select.Option>
                                ))
                              : consumers.length > 0 &&
                                consumers
                                  .sort((a, b) => a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()))
                                  .map((signatory) => (
                                    <Select.Option key={signatory.id} value={signatory.id}>
                                      {`${signatory.firstName} ${signatory.lastName}`}
                                    </Select.Option>
                                  ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    ))}
                </Row>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Spin>
      {Prompt}
    </Drawer>
  );
};

export default FinalAccountForm;
