import { notification, Spin, Table, TableProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../../assets/styles/faq.module.css';
import { generateSorter } from '../../../../helpers/generateSorter';
import { useLessThen801 } from '../../../../helpers/mediaDetect';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { FAQType, TopicModel, TopicsModel } from '../../../../models/FAQModel';
import FAQService from '../../../../services/FAQService';
import i18n from '../../../../utils/i18n';
import TableMobile from '../../../common/TableMobile';
import DeleteTopicForm from './TopicsComponents/DeleteTopicForm';
import TopicForm from './TopicsComponents/TopicForm';
import { getColumns } from './TopicsComponents/TopicsColumn';
import TopicsControl from './TopicsComponents/TopicsControl';

const service = new FAQService();

const TopicsPage = () => {
  const [fetchingData, setFetchingData] = useState(false);
  const { height: windowHeight } = useWindowDimensions();
  const [topics, setTopics] = useState<TopicsModel | []>([]);
  const { t } = useTranslation();
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    skip: 0,
    showSizeChanger: true,
  });

  const option = {
    count: true,
    top: pagination.pageSize,
    current: pagination.current,
    filter: {
      'type/code': {
        eq: FAQType.HIW,
      },
    },
    orderBy: generateSorter(['id desc']).orderBy,
  };

  useEffect(() => {
    getTopics(option);
  }, []);

  const getTopics = (option: { [name: string]: any }) => {
    setFetchingData(true);
    service
      .getTopics(option)
      .then((res) => {
        const { count, items } = res;
        const { current, top } = option;
        setPagination({ ...pagination, current, total: count, pageSize: top });
        setTopics(items);
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingData(false));
  };

  const setOpenUpdate = (id?: number) => {
    setIsOpenUpdate(true);
    if (id) {
      setCurrentId(id);
    }
  };

  const setCloseUpdate = () => {
    setIsOpenUpdate(false);
    setCurrentId(null);
    getTopics(option);
  };

  const setOpenDelete = (id?: number) => {
    setIsOpenDelete(true);
    if (id) {
      setCurrentId(id);
    }
  };

  const setCloseDelete = () => {
    setIsOpenDelete(false);
    setCurrentId(null);
    getTopics(option);
  };

  const onTableChange: TableProps<TopicModel>['onChange'] = (pagination) => {
    let params: { [name: string]: any } = {
      ...option,
    };

    if (pagination && pagination.pageSize && pagination.current) {
      params = {
        ...params,
        top: pagination.pageSize,
        skip: pagination.pageSize * (pagination.current - 1),
        current: pagination.current,
      };
    } else {
      params = {
        ...params,
        skip: 0,
        current: 1,
      };
    }

    getTopics(params);
  };

  const loadMore = (option: { [name: string]: any }) => {
    setFetchingData(true);
    option.skip = option.skip ? option.skip + 10 : 10;
    service
      .getTopics(option)
      .then((res) => {
        const { current, top } = option;
        setPagination({ ...pagination, current, total: res.count, pageSize: top });
        setTopics([...topics, ...res.items]);
      })
      .catch((e) => console.log(e))
      .finally(() => setFetchingData(false));
  };

  const data =
    topics.length > 0 &&
    (topics.map((item) => [
      { title: '№', description: item.id, type: 'string' },
      {
        title: t('manage.faq.columns.topic'),
        description: i18n.language === 'fr-FR' ? item.topicFr : item.topicEn,
        type: 'string',
      },
      {
        title: t('budget.table.action'),
        type: 'button',
        openByFunction: true,
        btnsInfo: [
          { function: () => setOpenUpdate(item.id), functionBtnTitle: t('budget.table.edit') },
          { function: () => setOpenDelete(item.id), functionBtnTitle: t('common.title.delete') },
        ],
      },
    ]) as any);

  if (useLessThen801()) {
    return (
      <>
        <div className="common-mobile-title-container">
          <span style={{ fontSize: '20px' }}>{t('hiw.manage.title')}</span>
        </div>
        <TopicsControl setOpenUpdate={setOpenUpdate} />
        <TableMobile
          data={data}
          isLoading={fetchingData}
          loadMore={loadMore}
          option={option}
          itemsLength={topics.length}
          totalItems={pagination.total}
        />
        {isOpenDelete && <DeleteTopicForm setClose={setCloseDelete} isOpen={isOpenDelete} id={currentId} />}
        {isOpenUpdate && <TopicForm setClose={setCloseUpdate} isOpen={isOpenUpdate} id={currentId} />}
      </>
    );
  }

  return (
    <div className={css['container']}>
      <Spin spinning={fetchingData}>
        <TopicsControl setOpenUpdate={setOpenUpdate} />
        <div className={css['faq-table-container']}>
          <Table
            className="consumers-info-table"
            rowClassName="common-table-row--pointer"
            rowKey="id"
            size="middle"
            scroll={{ y: windowHeight - 280 }}
            onChange={onTableChange}
            showHeader={true}
            pagination={pagination}
            columns={getColumns({ t, setOpenDelete, setOpenUpdate })}
            dataSource={topics}
          />
        </div>
        {isOpenDelete && <DeleteTopicForm setClose={setCloseDelete} isOpen={isOpenDelete} id={currentId} />}
        {isOpenUpdate && <TopicForm setClose={setCloseUpdate} isOpen={isOpenUpdate} id={currentId} />}
      </Spin>
    </div>
  );
};

export default TopicsPage;
