import { Button, Checkbox, DatePicker, Form, Input, notification, Select } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../assets/styles/tasks.module.css';
import TasksService from '../../../services/TasksService';
import calendarIcon from '../../../assets/images/ic-calendar.svg';
import arrowIcon from '../../../assets/images/ic-arrow-fill.svg';
import { StatusTask, TaskModel } from '../../../models/TasksModel';
import { checkStatus } from './TaskItem';
import { AppContext } from '../../../contexts/AppContextProvider';
import { IUserEasy } from '../../../models/UserModel';
import { isEmptyObject } from '../../../helpers/isEmptyObject';
import UsersService from '../../../services/UsersService';
import { IResponceData } from '../../../typings/IServiceOptions';
import { IAppContext } from '../../../typings/IApp';

const service = new TasksService();
const userService = new UsersService();
const { Option } = Select;

interface ITaskForm {
  getTasks: () => void;
  showBottom: boolean;
  userId: number | undefined;
  taskInfo?: TaskModel;
  onFinish: () => void;
  openBottom?: () => void;
}

const TaskForm = ({ getTasks, showBottom, userId, taskInfo, onFinish, openBottom }: ITaskForm) => {
  const {
    app: { user },
  } = useContext<IAppContext>(AppContext);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [dataPick, setDataPick] = useState<moment.Moment | null>(null);
  const [isCheck, setIsCheck] = useState<boolean>(taskInfo ? checkStatus(taskInfo.status.code) : false);
  const [searchValue, setSearchValue] = useState('');
  const [currentTimeout, setCurrentTimeout] = useState<NodeJS.Timeout | null>(null);
  const [users, setUsers] = useState<IUserEasy[]>([]);

  const [isLastUserReq, setIsLastUserReq] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    skip: 0,
  });

  const getUsers = () => {
    const optionUser = {
      count: true,
      top: pagination.pageSize,
      current: pagination.current,
      skip: pagination.pageSize * (pagination.current - 1) || 0,
      fullName: searchValue || '',
      projectId: (user && user.projects.map((item) => item.id)) || [],
      roles: ['agent', 'admin', 'super_agent', 'supplier', 'consumer'],
    };

    setFetchingData(true);
    userService
      .getEasyUsers(optionUser)
      .then((res: IResponceData<IUserEasy>) => {
        const { count, items } = res;
        const { current, top } = optionUser;
        setPagination({ ...pagination, current: current + 1, total: count, pageSize: top });
        let usersId: number[] = [];
        if (users.length > 0) {
          usersId = users.map((u) => u.id);
        }
        const ids = Array.from(
          new Set([...users, ...items.filter((item) => !usersId.includes(item.id))].map((user) => user.id)),
        );
        const uniqUsers = ids.map((id) =>
          [...users, ...items.filter((item) => !usersId.includes(item.id))].find((customer) => customer.id === id),
        );
        setUsers(searchValue ? items : (uniqUsers as IUserEasy[]));
        setIsLastUserReq(current * top >= count);
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingData(false));
  };

  useEffect(() => {
    getUsers();
  }, [searchValue]);

  const onSubmit = (data: any) => {
    if (!showBottom) {
      return;
    }
    setFetchingData(true);
    form.resetFields();
    if (!taskInfo) {
      const body = {
        description: data.description,
        deadline: moment(dataPick).format('YYYY-MM-DD'),
        assignee: {
          id: data.assigneeId,
        },
      };
      service
        .createTasks(body)
        .then(() => {
          notification.success({ message: t('tasks.message.create') });
          getTasks();
        })
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        )
        .finally(() => {
          setFetchingData(false);
          onFinish();
        });
    } else {
      const body = {
        id: taskInfo.id,
        description: data.description || taskInfo.description,
        deadline: moment(dataPick || taskInfo.deadline).format('YYYY-MM-DD'),
        assignee: {
          id: data.assigneeId || taskInfo.assignee.id,
        },
        status: {
          code: isCheck ? StatusTask.done : StatusTask.todo,
        },
      };
      service
        .updateTask(body)
        .then(() => {
          notification.success({ message: t('tasks.message.update') });

          getTasks();
        })
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        )
        .finally(() => {
          setFetchingData(false);
          onFinish();
        });
    }
  };

  const getLabel = (roles: string[]) => {
    if (roles.length === 1 && roles.includes('super_agent')) {
      return <span style={{ color: '#0065de' }}>{t('quotes.modal.form.supper.agents')}</span>;
    }
    if ((!roles.includes('consumer') && !roles.includes('supplier')) || roles.includes('agent')) {
      return <span style={{ color: '#0065de' }}>{t('quotes.modal.form.agents')}</span>;
    }
    if (roles.includes('consumer')) {
      return <span style={{ color: '#e08b32' }}>{t('quotes.modal.form.acquirers')}</span>;
    }
    if (roles.includes('supplier')) {
      return <span style={{ color: '#b37feb' }}>{t('tasks.control.suppliers')}</span>;
    }
    return;
  };

  return (
    <Form
      autoComplete="off"
      onFinish={onSubmit}
      form={form}
      className={`${css['task-container']} ${css['task-container-new']}`}>
      <Checkbox className="tasks-checkbox" onChange={(e) => setIsCheck(e.target.checked)} checked={isCheck} />
      <div style={{ width: '100%' }}>
        <Form.Item
          name="description"
          className="error-container"
          style={{ margin: '0' }}
          initialValue={taskInfo && taskInfo.description}
          rules={[{ required: true, message: t('common.validation.required') }]}>
          <Input
            className={`${css['tasks-field-input']} common-animation-primary`}
            bordered={false}
            onChange={() => openBottom && openBottom()}
          />
        </Form.Item>
        {showBottom && (
          <div className={css['task-container-bottom']}>
            <div className={css['task-container-bottom-item']}>
              <img src={arrowIcon} alt="" className={css['task-list-item-icon']} width={13} height={7} />
              <span style={{ whiteSpace: 'nowrap', fontSize: '14px', color: '#778DAC' }}>{`${t(
                'tasks.assign.to',
              )}:`}</span>
              <Form.Item
                name="assigneeId"
                initialValue={userId}
                style={{ margin: '0', width: '100%' }}
                rules={[{ required: true, message: t('common.validation.required') }]}>
                <Select
                  showSearch
                  style={{ paddingTop: '5px', paddingBottom: '5px' }}
                  className={`documents-control-select task-bottom-select common-animation-primary`}
                  dropdownClassName={`documents-control-select-dropdown ${
                    fetchingData ? 'consumers-control-select-dropdown--progress' : ''
                  }`}
                  suffixIcon={
                    <div
                      className={`arrow-drop-img ${css['arrow-drop']}`}
                      style={{ width: '8px', height: '4px' }}></div>
                  }
                  filterOption={() => true}
                  onSelect={() => setSearchValue('')}
                  onSearch={(value) => {
                    if (value === '') {
                      setUsers([]);
                    }
                    setPagination({ ...pagination, current: 1, skip: 0 });
                    clearTimeout(Number(currentTimeout));
                    setCurrentTimeout(setTimeout(() => setSearchValue(value), 1000));
                  }}
                  onPopupScroll={(e: any) => {
                    e.persist();
                    let target = e.target;
                    if (
                      !fetchingData &&
                      !isLastUserReq &&
                      target.scrollTop + target.offsetHeight === target.scrollHeight
                    ) {
                      getUsers();
                    }
                  }}>
                  {searchValue === '' && (
                    <Option value={user.id} key={user.id} className="documents-control-option">
                      <span
                        style={{
                          fontSize: '14px',
                          fontWeight: 600,
                        }}>
                        {`${user.firstName} ${user.lastName}`} ({getLabel(user.roles)})
                      </span>
                    </Option>
                  )}
                  {taskInfo && !isEmptyObject(taskInfo.assignee) && (
                    <Option
                      value={taskInfo.assignee.id}
                      key={taskInfo.assignee.id}
                      className="documents-control-option">
                      <span
                        style={{
                          fontSize: '14px',
                          fontWeight: 600,
                        }}>
                        {`${taskInfo.assignee.firstName} ${taskInfo.assignee.lastName}`} (
                        {getLabel(taskInfo.assignee.roles.map((role) => role.name))})
                      </span>
                    </Option>
                  )}
                  {users
                    .filter((u) => u.id !== user.id)
                    .filter((user) =>
                      taskInfo && !isEmptyObject(taskInfo.assignee) ? user.id !== taskInfo.assignee.id : true,
                    )
                    .map((user) => (
                      <Option value={user.id} key={user.id} className="documents-control-option">
                        <span
                          style={{
                            fontSize: '14px',
                            fontWeight: 600,
                          }}>
                          {`${user.firstName} ${user.lastName}`} ({getLabel(user.roles.map((role) => role.name))})
                        </span>
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
            <div className={css['task-container-bottom-item']}>
              <img src={calendarIcon} alt="" className={css['task-list-item-icon']} />
              <span style={{ fontSize: '14px', color: '#778DAC', marginRight: '5px' }}>{t('tasks.deadline')}</span>
              <Form.Item
                name="deadline"
                className="error-container task-range-picker-container"
                style={{ margin: '0', width: '100%', height: '100%', borderRadius: 6 }}
                initialValue={taskInfo && moment(taskInfo?.deadline)}
                rules={[{ required: true, message: t('common.validation.required') }]}>
                <DatePicker
                  suffixIcon={<div className={'calendar-img'} style={{ width: '18px', height: '18px' }}></div>}
                  style={{ paddingTop: '5px', paddingBottom: '5px', height: '100%' }}
                  className={`documents-control-range-picker task-range-picker task-bottom-select common-animation-primary`}
                  placeholder={t('tasks.datepicker.placeholder')}
                  inputReadOnly
                  onChange={setDataPick}
                />
              </Form.Item>
            </div>
            <Button className={`common-blue-btn common-primary-btn`} htmlType="submit" style={{ height: '42px' }}>
              <span>{taskInfo ? t('common.btn.save') : t('tasks.create.btn')}</span>
            </Button>
          </div>
        )}
      </div>
    </Form>
  );
};

export default TaskForm;
