import React from 'react';

interface Props {
  title: string;
  description: string;
}

const BoardTourComponent = (props: Props) => {
  const { title, description } = props;
  return (
    <div className="custom-board-step-container">
      <span style={{ fontWeight: 600 }}>{title}</span>
      <br />
      <span style={{ fontSize: '14px', fontWeight: 400 }}>{description}</span>
    </div>
  );
};

export default BoardTourComponent;
