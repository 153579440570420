import { CHANGE_CURRENT_PAGE } from '../constants/actionTypes/headerConstants';

export const initialState = {
  currentPage: 'Dashboard',
  path: 'dashboard',
};

export function reducer(state: any, action: any) {
  switch (action.type) {
    case CHANGE_CURRENT_PAGE:
      return { currentPage: action.currentPage, path: action.path };
    default:
      return initialState;
  }
}
