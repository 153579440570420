import React, { useContext, useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router';
import { CHANGE_CURRENT_PAGE } from '../../constants/actionTypes/headerConstants';
import { HeaderContext } from '../../contexts/HeaderContextProvider';
import BrokerCalendar from '../Calendar/BrokerCalendar/BrokerCalendar';
import UsersService from '../../services/UsersService';
import { useTranslation } from 'react-i18next';
import BrokerBudget from '../Budget/BrokerBudget';
import BrokerDetailsPage from '../DetailsProject/BrokerDetailsPage';
import BrokerGallery from '../Gallery/BrokerGallery';
import BrokerDocuments from '../Documents/BrokerDocuments';
import InvoiceUploadForm from '../Budget/components/UploadInvoice/InvoiceUploadForm';
import BrokerQuotes from '../Quotes/QuotesBrokerPage';
import QuoteUploadForm from '../Quotes/components/UploadQuote/QuoteUploadForm';
import { NavLink } from 'react-router-dom';
import Tasks from '../Tasks/Tasks';
import MeetingsTabPage from '../Meetings/components/MeetingsTabPage';
import { AppContext } from '../../contexts/AppContextProvider';
import { useLessThen801 } from '../../helpers/mediaDetect';
import MeetingsTabMobilePage from '../Meetings/components/MeetingsTabMobilePage';
import ModalUploadFileSuccess from '../../components/common/Modals/ModalUploadFileSuccess';
import AmendmentPage from '../Amendment/AmendmentPage';
import CreateAmendment from '../Amendment/components/create/CreateAmendment';
import ListPageServices from '../../services/PropertiesService';
import { IAppContext } from '../../typings/IApp';
import FinalAccountPage from '../FinalAccount/FinalAccountPage';
import CreateFinalAccount from '../FinalAccount/components/create/CreateFinalAccount';

const service = new UsersService();
const serviceProperties: ListPageServices = new ListPageServices();

const PropertyManagePage = () => {
  const params = useParams();
  const { t } = useTranslation();
  const { headerDispatch } = useContext(HeaderContext);
  const {
    app,
    app: { user, isSupplier },
  } = useContext<IAppContext>(AppContext);
  const { state }: any = useLocation();
  const { propertyId, projectId, tabName }: any = params;
  const [consumers, setConsumers] = useState<any[]>([]);
  const [brokerInfo, setBrokerInfo] = useState<any>();
  const [isOpenUpload, setIsOpenUpload] = useState(false);
  const [isOpenUploadMessageModal, setIsOpenUploadMessageModal] = useState(false);
  const [isCreatedInvoice, setIsCreatedInvoice] = useState(false);
  const [isOpenStatusForm, setIsOpenStatusForm] = useState(false);
  const [currentInvoice, setCurrentInvoice] = useState<number | null>(null);
  const [isOpenUploadQuote, setIsOpenUploadQuote] = useState(false);
  const [isCreatedQuote, setIsCreatedQuote] = useState(false);
  const [isOpenStatusQuoteForm, setIsOpenStatusQuoteForm] = useState(false);
  const [currentQuote, setCurrentQuote] = useState<number | null>(null);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [signatories, setSignatories] = useState<any>([]);
  const [propertyTitle, setPropertyTitle] = useState<string>('');
  const [currency, setCurrency] = useState<string>(t('budget.currency'));
  //amendments
  const [currentAmendment, setCurrentAmendment] = useState<number | null>(null);
  const [isOpenUploadAmendment, setIsOpenUploadAmendment] = useState(false);
  const [isCreatedAmendment, setIsCreatedAmendment] = useState(false);
  const [isOpenStatusAmendmentForm, setIsOpenStatusAmendmentForm] = useState(false);
  //final account
  const [currentFinalAccount, setCurrentFinalAccount] = useState<number | null>(null);
  const [isOpenUploadFinalAccount, setIsOpenUploadFinalAccount] = useState(false);
  const [isCreatedFinalAccount, setIsCreatedFinalAccount] = useState(false);
  const [isOpenStatusFinalAccountForm, setIsOpenStatusFinalAccountForm] = useState(false);

  const tabs = [
    {
      id: 1,
      name: t('consumers.manage.tabs.calendar'),
      path: 'calendar',
      page: <BrokerCalendar propertyId={propertyId} />,
    },
    {
      id: 9,
      path: 'amendment',
      name: t('amendment.contaracts'),
      page: (
        <AmendmentPage
          consumers={consumers}
          propertyId={propertyId}
          projectId={projectId}
          signatories={signatories}
          setIsOpenUploadAmendment={setIsOpenUploadAmendment}
          isCreatedAmendment={isCreatedAmendment}
          isOpenStatusAmendmentForm={isOpenStatusAmendmentForm}
          setIsOpenStatusAmendmentForm={setIsOpenStatusAmendmentForm}
          setIsCreatedAmendment={setIsCreatedAmendment}
          currentAmendment={currentAmendment}
          setCurrentAmendment={setCurrentAmendment}
        />
      ),
    },
    {
      id: 10,
      path: 'final_account',
      name: t('finalAccount.title'),
      page: (
        <FinalAccountPage
          propertyId={propertyId}
          setIsOpenUploadFinalAccount={setIsOpenUploadFinalAccount}
          isCreatedFinalAccount={isCreatedFinalAccount}
          isOpenStatusFinalAccountForm={isOpenStatusFinalAccountForm}
          setIsOpenStatusFinalAccountForm={setIsOpenStatusFinalAccountForm}
          setIsCreatedFinalAccount={setIsCreatedFinalAccount}
          currentFinalAccount={currentFinalAccount}
          setCurrentFinalAccount={setCurrentFinalAccount}
        />
      ),
    },
    {
      id: 2,
      name: t('consumers.manage.tabs.quotes'),
      path: 'quotes',
      page: (
        <BrokerQuotes
          propertyId={propertyId}
          setIsOpenUploadQuote={setIsOpenUploadQuote}
          isCreatedQuote={isCreatedQuote}
          isOpenStatusQuoteForm={isOpenStatusQuoteForm}
          setIsOpenStatusQuoteForm={setIsOpenStatusQuoteForm}
          setIsCreatedQuote={setIsCreatedQuote}
          currentQuote={currentQuote}
          setCurrentQuote={setCurrentQuote}
        />
      ),
    },
    {
      id: 3,
      name: t('consumers.manage.tabs.budget'),
      path: 'invoices',
      page: (
        <BrokerBudget
          propertyId={propertyId}
          projectId={projectId}
          setIsOpenUpload={setIsOpenUpload}
          isCreatedInvoice={isCreatedInvoice}
          isOpenStatusForm={isOpenStatusForm}
          setIsOpenStatusForm={setIsOpenStatusForm}
          setIsCreatedInvoice={setIsCreatedInvoice}
          currentInvoice={currentInvoice}
          setCurrentInvoice={setCurrentInvoice}
          setIsNeedUpdate={setIsNeedUpdate}
          isNeedUpdate={isNeedUpdate}
          currency={currency}
        />
      ),
    },
    {
      id: 4,
      name: t('consumers.manage.tabs.documents'),
      path: 'documents',
      page: <BrokerDocuments propertyId={propertyId} projectId={projectId} signatories={signatories} />,
    },
    {
      id: 5,
      name: t('consumers.manage.tabs.tasks'),
      path: 'tasks',
      page: <Tasks isTab={true} />,
    },
    {
      id: 8,
      path: 'meetings',
      name: t('header.title.Meetings'),
      page: useLessThen801() ? <MeetingsTabMobilePage /> : <MeetingsTabPage />,
    },
    {
      id: 6,
      name: t('consumers.manage.tabs.gallery'),
      path: 'gallery',
      page: <BrokerGallery propertyId={propertyId} projectId={projectId} />,
    },
    {
      id: 7,
      path: 'details_project',
      name: t('consumers.manage.tabs.details.project'),
      page: <BrokerDetailsPage consumers={consumers} brokerInfo={brokerInfo} />,
    },
  ];

  const tabsSupplier = [
    {
      id: 1,
      path: 'details_project',
      name: t('consumers.manage.tabs.details.project'),
      page: <BrokerDetailsPage consumers={consumers} brokerInfo={brokerInfo} />,
    },
    {
      id: 2,
      name: t('consumers.manage.tabs.quotes'),
      path: 'quotes',
      page: (
        <BrokerQuotes
          propertyId={propertyId}
          supplierId={app.user.id}
          setIsOpenUploadQuote={setIsOpenUploadQuote}
          isCreatedQuote={isCreatedQuote}
          isOpenStatusQuoteForm={isOpenStatusQuoteForm}
          setIsOpenStatusQuoteForm={setIsOpenStatusQuoteForm}
          setIsCreatedQuote={setIsCreatedQuote}
          currentQuote={currentQuote}
          setCurrentQuote={setCurrentQuote}
        />
      ),
    },
  ];

  useEffect(() => {
    if (projectId && +projectId) {
      setCurrency(
        user?.projects?.find((project: any) => project.id === +projectId)?.defaultCurrency?.description ||
          t('budget.currency'),
      );
    }
  }, [projectId]);

  useEffect(() => {
    if (propertyId) {
      getSignatories();
    }
  }, [propertyId]);

  useEffect(() => {
    if (propertyId && state?.projectKey) {
      getProperty(state.projectKey);
    }
  }, [propertyId, state?.projectKey]);

  useEffect(() => {
    headerDispatch({
      type: CHANGE_CURRENT_PAGE,
      currentPage: `${t('consumers.table.columns.property')} ${propertyTitle}`,
      path: 'view',
    });
  }, [propertyTitle]);

  const getProperty = (projectKey: string) => {
    serviceProperties.getPropertyById({ id: propertyId, projectKey }).then((res: any) => {
      setPropertyTitle(res.title);
      setConsumers(res.consumers);
      setBrokerInfo(res.agent);
    });
  };

  const getSignatories = () => {
    if (isSupplier) {
      service.getBrokersByProperty(propertyId).then((res: any) => {
        setSignatories(res);
      });
    } else {
      service.getConsumersByProperty(propertyId).then((res: any) => {
        setSignatories(res);
      });
    }
  };

  return (
    <div className="consumer-tab-container">
      {isOpenUpload && (
        <InvoiceUploadForm
          isOpenUpload={isOpenUpload}
          setIsOpenUpload={setIsOpenUpload}
          projectId={projectId}
          propertyId={propertyId}
          setIsCreatedInvoice={setIsCreatedInvoice}
          setIsOpenStatusForm={setIsOpenStatusForm}
          setCurrentInvoice={setCurrentInvoice}
          setIsNeedUpdate={setIsNeedUpdate}
          setIsOpenUploadMessageModal={setIsOpenUploadMessageModal}
        />
      )}
      {isOpenUploadQuote && (
        <QuoteUploadForm
          isOpenUpload={isOpenUploadQuote}
          setIsOpenUpload={setIsOpenUploadQuote}
          projectId={projectId}
          propertyId={propertyId}
          setIsCreatedQuote={setIsCreatedQuote}
          setIsOpenStatusQuoteForm={setIsOpenStatusQuoteForm}
          setCurrentQuote={setCurrentQuote}
          setIsOpenUploadMessageModal={setIsOpenUploadMessageModal}
        />
      )}
      {isOpenUploadAmendment && (
        <CreateAmendment
          isOpenUpload={isOpenUploadAmendment}
          setIsOpenUpload={setIsOpenUploadAmendment}
          projectId={projectId}
          propertyId={propertyId}
          setIsCreatedAmendment={setIsCreatedAmendment}
          setIsOpenStatusAmendmentForm={setIsOpenStatusAmendmentForm}
          setCurrentAmendment={setCurrentAmendment}
          setIsOpenUploadMessageModal={setIsOpenUploadMessageModal}
        />
      )}
      {isOpenUploadFinalAccount && (
        <CreateFinalAccount
          isOpenUpload={isOpenUploadFinalAccount}
          setIsOpenUpload={setIsOpenUploadFinalAccount}
          projectId={projectId}
          propertyId={propertyId}
          setIsCreatedFinalAccount={setIsCreatedFinalAccount}
          setIsOpenStatusFinalAccountForm={setIsOpenStatusFinalAccountForm}
          setCurrentFinalAccount={setCurrentFinalAccount}
          setIsOpenUploadMessageModal={setIsOpenUploadMessageModal}
        />
      )}
      {isOpenUploadMessageModal && (
        <ModalUploadFileSuccess isOpen={isOpenUploadMessageModal} onOk={() => setIsOpenUploadMessageModal(false)} />
      )}
      {isSupplier ? (
        <>
          <div className="tab-container">
            {tabsSupplier.map((tab: any) => (
              <NavLink
                exact
                to={{
                  pathname: tab.path,
                  state,
                }}
                isActive={(match, location) => location.pathname.split('/').pop() === tab.path}
                className="consumer-tab common-animation-primary"
                activeClassName="consumer-tab-active"
                key={tab.id}>
                {tab.name}
              </NavLink>
            ))}
          </div>
          {tabsSupplier.find((tab: any) => tab.path === tabName)?.page}
        </>
      ) : (
        <>
          <div className="tab-container">
            {tabs.map((tab: any) => (
              <NavLink
                exact
                to={{
                  pathname: tab.path,
                  state,
                }}
                isActive={(match, location) => location.pathname.split('/').pop() === tab.path}
                className="consumer-tab common-animation-primary"
                activeClassName="consumer-tab-active"
                key={tab.id}>
                {tab.name}
              </NavLink>
            ))}
          </div>
          {tabs.find((tab: any) => tab.path === tabName)?.page}
        </>
      )}
    </div>
  );
};

export default PropertyManagePage;
