import React, { useState, useEffect, useContext } from 'react';
import { Button, Empty, Spin, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { getColumns } from './components/TicketsColumns';
import { AppContext } from '../../contexts/AppContextProvider';
import { HeaderContext } from '../../contexts/HeaderContextProvider';
import { CHANGE_CURRENT_PAGE } from '../../constants/actionTypes/headerConstants';
import TicketsControl from './components/TicketsControl';
import css from '../../assets/styles/tickets.module.css';
import cssTickets from '../../assets/styles/tickets.module.css';
import { generateFilterfromObj } from '../../helpers/generateFIlterfromObj';
import TicketsService from '../../services/TicketsService';
import { generateSorter } from '../../helpers/generateSorter';
import { useHistory } from 'react-router';
import { FiltersContext } from '../../contexts/FiltersContextProvider';
import { FilterTypes } from '../../constants/filterTypes';
import { useLessThen801 } from '../../helpers/mediaDetect';
import TableMobile from '../common/TableMobile';
import filterIcon from '../../assets/images/ic-filter.svg';
import filterActiveIcon from '../../assets/images/ic-filter-active.svg';
import { Link } from 'react-router-dom';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { IAppContext } from '../../typings/IApp';

const ticketService: TicketsService = new TicketsService();

const TicketsPage: React.FC = (props: any) => {
  const { t } = useTranslation();
  const { height: windowHeight } = useWindowDimensions();
  const history = useHistory();
  const {
    app: { user, isConsumer, currentProperty },
  } = useContext<IAppContext>(AppContext);
  const {
    filters: { ticketsFilter },
  } = useContext(FiltersContext);
  const isBroker = !!user?.rolesParams?.IS_BROKER;

  const userId = user?.id;
  const [items, setItems] = useState<any>([]);
  const [currentFilters, setCurrentFilters] = useState<any>({});
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    total: 0,
  });
  const [types, setTypes] = useState<any>([]);
  const [fetchingData, setFetchingData] = useState(false);
  const { headerDispatch } = useContext(HeaderContext);

  const option = {
    orderBy: generateSorter(['id desc']).orderBy,
    typeFilter: generateFilterfromObj(
      ticketsFilter.type === 'incoming'
        ? {
            'recipient/id': {
              type: FilterTypes.SELECT,
              value: user.id,
            },
          }
        : ticketsFilter.type === 'outgoing'
        ? {
            'created_user/id': {
              type: FilterTypes.SELECT,
              value: user.id,
            },
          }
        : undefined,
    ).filter,
    statusFilter: generateFilterfromObj({
      'status/code': {
        type: FilterTypes.SELECT,
        value: ticketsFilter.status ? ticketsFilter.status : undefined,
      },
    }).filter,
    projectFilter: generateFilterfromObj(
      user?.projects?.length === 1
        ? {
            'project/id': {
              type: FilterTypes.SELECT,
              value: +user.projects[0].id,
            },
          }
        : undefined,
    ).filter,
    priorityFilter: generateFilterfromObj({
      'priority/code': {
        type: FilterTypes.SELECT,
        value: ticketsFilter.priority ? ticketsFilter.priority : undefined,
      },
    }).filter,
    dateRangeFilter: generateFilterfromObj({
      dueDate: {
        type: FilterTypes.DATE_RANGE,
        value: ticketsFilter.dateRange ? ticketsFilter.dateRange : undefined,
      },
    }).filter,
  };

  useEffect(() => {
    headerDispatch({
      type: CHANGE_CURRENT_PAGE,
      currentPage: t('header.title.Tickets'),
      path: 'tickets',
    });
    getTypes();
    getTickets(option);
  }, [currentProperty]);

  const getTypes = () => {
    ticketService.getTypes().then((res: any) => {
      setTypes(res);
    });
  };

  const getTickets = (option: any) => {
    if (isConsumer) {
      option.propertyFilter = generateFilterfromObj({
        'property/id': {
          type: FilterTypes.SELECT,
          value: +currentProperty.id,
        },
      }).filter;
    }
    setFetchingData(true);
    ticketService
      .getTicketsByUserId(userId, option)
      .then((res: any) => {
        const { count, items } = res;
        const { current, top } = option;
        setPagination({ ...pagination, current, total: count, pageSize: top });
        setItems(res);
      })
      .catch((e: any) => console.log(e))
      .finally(() => setFetchingData(false));
  };

  const isEmptyFilter = (obj: any) => {
    return Object.keys(obj).length === 0;
  };

  const onTableChange = (pagination?: any, filters?: any, sorter?: any) => {
    let params: any = {
      ...option,
    };

    if (filters && !isEmptyFilter(filters)) {
      setCurrentFilters((state: any) => {
        const [filter] = Object.keys(filters);
        const newState = { ...state, [filter]: filters[filter] };

        Object.keys(newState).forEach((filter) => {
          params = {
            ...params,
            [filter]: newState[filter] ? generateFilterfromObj(newState[filter]).filter : undefined,
          };
        });
        return newState;
      });
    } else {
      Object.keys(currentFilters).forEach((filter) => {
        params = {
          ...params,
          [filter]: currentFilters[filter] ? generateFilterfromObj(currentFilters[filter]).filter : undefined,
        };
      });
    }

    getTickets(params);
  };

  const loadMore = (option: any) => {
    setFetchingData(true);
    option.skip = option.skip ? option.skip + 10 : 10;
    ticketService
      .getTicketsByUserId(userId, option)
      .then((res: any) => {
        const { current, top } = option;
        setPagination({ ...pagination, current, total: res.count, pageSize: top });
        setItems([...items, ...res.items]);
      })
      .catch((e: any) => console.log(e))
      .finally(() => setFetchingData(false));
  };

  const data =
    items.length > 0 &&
    (items.map((item: any) => [
      { title: t('tickets.table.columns.id'), description: item.id, type: 'string' },
      { title: t('tickets.table.columns.type'), description: item, type: 'type' },
      { title: t('tickets.table.columns.subject'), description: item.subject, type: 'string' },
      {
        title: t('tickets.table.columns.acquirer'),
        description:
          isBroker && item.createdUser.id === userId
            ? `${item.recipient.firstName} ${item.recipient.lastName}`
            : `${item.createdUser.firstName} ${item.createdUser.lastName}`,
        type: 'string',
      },
      { title: t('tickets.table.columns.project'), description: item.project.title, type: 'string' },
      { title: t('tickets.table.columns.priority'), description: item.priority, type: 'status' },
      { title: t('tickets.table.columns.status'), description: item.status, type: 'status' },
      {
        title: t('tickets.table.columns.duedate'),
        description: item.dueDate,
        text: {
          title: t('tickets.table.columns.duedate.popover.attention.content'),
          description: t('tickets.table.columns.duedate.popover.attention.title'),
        },
        type: 'date',
      },
      { title: t('tickets.table.columns.answer'), description: item, type: 'answer' },
      {
        title: t('budget.table.action'),
        type: 'button',
        openAction: true,
        description: 'tickets',
        id: item.id,
      },
    ]) as any);

  const changeOpenFilter = () => {
    setIsOpenFilter(!isOpenFilter);
  };

  if (useLessThen801()) {
    return (
      <>
        <div className="common-mobile-title-container">
          <span style={{ fontSize: '20px' }}>{t('header.title.Tickets')}</span>
          <div className="common-modbile-filter-container" onClick={changeOpenFilter}>
            <img src={isOpenFilter ? filterActiveIcon : filterIcon} width={16} />
            <span className="filter-title" style={{ marginRight: '1rem' }}>
              {isOpenFilter ? t('common.filter.close') : t('common.filter.open')}
            </span>
            <div className={css['tickets-control-btns-container-mobile']}>
              <Link to={`${props.history.location.pathname}/new`}>
                <Button className={css['tickets-add-new']}>{t('common.btn.new')}</Button>
              </Link>
            </div>
          </div>
        </div>
        {isOpenFilter && (
          <TicketsControl
            props={props}
            onTableChange={onTableChange}
            getTickets={getTickets}
            option={option}
            types={types}
          />
        )}
        <TableMobile
          data={data}
          isLoading={fetchingData}
          loadMore={loadMore}
          option={option}
          itemsLength={items.length}
          totalItems={pagination.total}
          types={types}
          userId={userId}
        />
      </>
    );
  }

  return (
    <Spin spinning={fetchingData}>
      <TicketsControl
        props={props}
        onTableChange={onTableChange}
        getTickets={getTickets}
        option={option}
        types={types}
      />
      <div className={cssTickets['tickets-table-container']}>
        <Table
          columns={getColumns({
            getTickets,
            option,
            t,
            types,
            userId: user.id,
            isBroker,
          })}
          onChange={onTableChange}
          size="middle"
          dataSource={items}
          showHeader={true}
          locale={{
            emptyText: (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('tickets.table.placeholder.empty')} />
            ),
          }}
          scroll={{ y: windowHeight - 300 }}
          className="consumers-info-table"
          rowClassName="common-table-row--pointer"
          rowKey={(record: any) =>
            `${record.id}+${record.currentProject?.id ?? ''}+${record.currentProperty?.id ?? ''}`
          }
          pagination={pagination}
          onRow={(record) => {
            return {
              onClick: () => {
                history.push(`/tickets/view/${record.id}`);
              },
            };
          }}
        />
      </div>
      <div />
    </Spin>
  );
};

export default TicketsPage;
