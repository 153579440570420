import React from 'react';
import { Button, Dropdown, Menu, Badge } from 'antd';
import { convertToLocalDateTime } from '../../../helpers/converToLocalDate';
import css from '../../../assets/styles/leads.module.css';
import moreIcon from '../../../assets/images/ic-more.svg';
import deleteIcon from '../../../assets/images/ic-delete-dark-gray.svg';
import editIcon from '../../../assets/images/ic-edit-dark.svg';
import { RedoOutlined } from '@ant-design/icons';
import { TFunction } from 'react-i18next';

interface LeadsColumnsModel {
  onDelete?: (id: number) => void;
  resendConfirmation?: (email: string) => void;
  history: any;
  t: TFunction<'translation'>;
}

export const getColumns = ({ onDelete, resendConfirmation = () => {}, history, t }: LeadsColumnsModel) =>
  [
    {
      title: () => <span className="budget-table-tr">{t('consumers.table.columns.email')}</span>,
      dataIndex: 'email',
      key: 'email',
      width: 250,
    },
    {
      title: () => <span className="budget-table-tr">{t('consumers.table.columns.phone')}</span>,
      dataIndex: 'phone',
      key: 'phone',
      width: 150,
    },
    {
      title: () => <span className="budget-table-tr">{t('consumers.table.columns.name')}</span>,
      key: 'name',
      render: (cell: any) => `${cell.name || ''} ${cell.surname || ''}`,
      width: 250,
    },
    {
      title: () => <span className="budget-table-tr">{t('consumers.table.columns.isConfirmed')}</span>,
      dataIndex: 'isConfirmed',
      key: 'isConfirmed',
      render: (cell: any) =>
        cell ? (
          <Badge status="success" text={t('consumers.table.columns.isConfirmed.confirmed')} />
        ) : (
          <Badge status="error" text={t('consumers.table.columns.isConfirmed.unconfirmed')} />
        ),
      width: 150,
    },
    {
      title: () => <span className="budget-table-tr">{t('consumers.table.columns.confirmedDate')}</span>,
      dataIndex: 'confirmedDate',
      key: 'confirmedDate',
      render: (cell: any) => (cell ? convertToLocalDateTime(cell) : ''),
      width: 200,
    },
    {
      title: () => <span className="budget-table-tr">{t('consumers.table.columns.failedLoginAttempts')}</span>,
      dataIndex: 'failedLoginAttempts',
      key: 'failedLoginAttempts',
      width: 150,
    },
    {
      title: () => <span className="budget-table-tr">{t('consumers.table.columns.lockedDate')}</span>,
      dataIndex: 'lockedDate',
      key: 'lockedDate',
      render: (cell: any) => (cell ? convertToLocalDateTime(cell) : ''),
      width: 200,
    },
    {
      title: () => <span className="budget-table-tr">{t('consumers.table.columns.lastLoginDate')}</span>,
      dataIndex: 'lastLoginDate',
      key: 'lastLoginDate',
      render: (cell: any) => (cell ? convertToLocalDateTime(cell) : ''),
      width: 200,
    },
    {
      title: () => <span className="budget-table-tr">{t('consumers.table.columns.action')}</span>,
      key: 'action',
      fixed: 'right',
      render: (cell: any) => {
        const menu = (
          <Menu className="update-menu-container" style={{ width: '130px' }}>
            <Menu.Item
              key="edit"
              className={css['update-menu-item']}
              onClick={() => history.push(`/leads/modify/${cell.id}`)}>
              <Button
                type="link"
                style={{
                  border: 'none',
                  backgroundColor: 'inherit',
                  boxShadow: 'none',
                  padding: 0,
                  marginRight: '0.5rem',
                }}>
                <img src={editIcon} alt="" style={{ height: '15px', width: '15px' }} />
              </Button>
              <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.btn.modify')}</span>
            </Menu.Item>
            {onDelete && (
              <Menu.Item key="block" className={css['update-menu-item']} onClick={() => onDelete(cell.id)}>
                <Button
                  type="link"
                  style={{
                    border: 'none',
                    backgroundColor: 'inherit',
                    boxShadow: 'none',
                    padding: 0,
                    marginRight: '0.5rem',
                  }}>
                  <img src={deleteIcon} alt="" height={15} width={15} />
                </Button>
                <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.title.delete')}</span>
              </Menu.Item>
            )}
            {!cell.isConfirmed && (
              <Menu.Item key="resend" onClick={() => resendConfirmation(cell.email)}>
                <Button
                  type="link"
                  style={{
                    border: 'none',
                    backgroundColor: 'inherit',
                    boxShadow: 'none',
                    padding: 0,
                    marginRight: '0.5rem',
                  }}>
                  <RedoOutlined style={{ color: '#000' }} />
                </Button>
                <span style={{ color: '#00122d', fontWeight: 600 }}>{t('consumers.table.columns.action.resend')}</span>
              </Menu.Item>
            )}
          </Menu>
        );

        return (
          <span>
            <Dropdown overlay={menu} trigger={['click']}>
              <Button type="link" className={css['leads-dropdown-btn']}>
                <img src={moreIcon} alt="" height={15} width={15} />
              </Button>
            </Dropdown>
          </span>
        );
      },
      width: 100,
    },
  ] as any[];
