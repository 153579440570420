import { Button, Modal, notification, Spin } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../assets/styles/faq.module.css';
import ListPageServices from '../../../services/PropertiesService';
import UsersService from '../../../services/UsersService';

interface ConfirmProps {
  type: string;
  isOpen: boolean;
  id: number | null;
  createdUserId?: number | null;
  getUsers: () => void;
  setClose: () => void;
  projectKey?: string | null;
  currentPropertyId?: number | null;
}

const userService = new UsersService();
const listService = new ListPageServices();

const ConfirmChangeModal = (props: ConfirmProps) => {
  const [fetchingData, setFetchingData] = useState(false);
  const { isOpen, type, id, getUsers, createdUserId, setClose, projectKey, currentPropertyId } = props;
  const { t } = useTranslation();

  const updateUser = () => {
    setFetchingData(true);
    if (id) {
      if (type === 'createdUser' && projectKey) {
        const body = {
          agent: { id: createdUserId },
        };
        listService
          .saveProperty({ body, id: currentPropertyId, projectKey: projectKey })
          .then(() => {
            getUsers();
            notification.success({
              message: t('consumers.notification.agent.update'),
            });
          })
          .catch((e) => {
            notification.error({
              message: e,
            });
          })
          .finally(() => {
            setFetchingData(false);
            setClose();
          });
      } else {
        userService
          .updateUser(id, { properties: [], projects: [] })
          .then(() => {
            getUsers();
            notification.success({
              message: t('consumers.notification.property.update'),
            });
          })
          .catch((e) => {
            notification.error({
              message: e,
            });
          })
          .finally(() => {
            setFetchingData(false);
            setClose();
          });
      }
    }
  };

  return (
    <Spin spinning={fetchingData}>
      <Modal
        className="common-modal"
        centered
        visible={isOpen}
        style={{ width: '10rem' }}
        footer={
          <div className="common-modal-footer">
            <Button
              className={`common-gray-border-btn common-secondary-btn`}
              onClick={() => setClose()}
              disabled={fetchingData}>
              {t('budget.modal.cancel.btn')}
            </Button>
            <Button
              className={`common-green-btn common-modal-btn`}
              onClick={() => updateUser()}
              disabled={fetchingData}>
              {t('common.modal.confirm')}
            </Button>
          </div>
        }
        closable={false}>
        <span className={css['delete-question-title']}>
          {type === 'createdUser' ? t('consumers.confirm.update.agent') : t('consumers.confirm.update.property')}
        </span>
      </Modal>
    </Spin>
  );
};

export default ConfirmChangeModal;
