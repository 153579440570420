import React, { useEffect, useState, useContext, useMemo } from 'react';
import { ODataOptionModel } from '../../models/ODataOptionModel';
import ReportsService from '../../services/ReportsService';
import { RouteComponentProps, useParams } from 'react-router';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import { Button, Table, notification } from 'antd';
import { getColumns } from './components/ReportDataColumns';
import ReportDataControl from './components/ReportDataControl';
import { useTranslation } from 'react-i18next';
import { CHANGE_CURRENT_PAGE } from '../../constants/actionTypes/headerConstants';
import { HeaderContext } from '../../contexts/HeaderContextProvider';
import { ArrowLeftOutlined } from '@ant-design/icons';
import DynamicParamsModal from './components/DynamicParamsModal';
import { getErrorNotification } from '../../components/common/Notifications';
import css from '../../assets/styles/reports.module.css';

const service = new ReportsService();

const ReportDataPage: React.FC<RouteComponentProps> = (props) => {
  const { t, i18n } = useTranslation();
  const { name } = useParams<{ name: string }>();
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [report, setReport] = useState();
  const [isVisibleParams, setIsVisibleParams] = useState(false);
  const [breadcrumbNameMap, setBreadcrumbNameMap] = useState({} as any);
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    total: 0,
  });

  const { height: windowHeight } = useWindowDimensions();
  const { headerDispatch } = useContext(HeaderContext);

  const defaultOptions = {
    count: true,
    orderBy: 'startDate desc',
  };

  const reportMemo: any = useMemo(() => report || undefined, [report]);
  const lang = useMemo(() => i18n.language.split('-')[0], [i18n.language]);

  const getReport = () => {
    service.getReport(name).then((res: any) => {
      setReport(res);
    });
  };

  const getReportData = (options?: ODataOptionModel, current?: number) => {
    setLoading(true);
    service
      .getGeneratedReportData(name, options)
      .then((res: any) => {
        const { items, count } = res;
        const { top } = options || {};
        setData(items);
        setPagination({ ...pagination, current, total: count, pageSize: top });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    headerDispatch({
      type: CHANGE_CURRENT_PAGE,
      currentPage: t('header.title.Reports'),
      path: 'reports',
    });
    getReportData(
      {
        ...defaultOptions,
        top: pagination.pageSize,
      },
      pagination.current,
    );
    getReport();
  }, []);

  useEffect(() => {
    setBreadcrumbNameMap({
      '/reports': <ArrowLeftOutlined />,
      [props.history.location.pathname]: reportMemo?.title || '',
    });
  }, [props.history.location.pathname, reportMemo?.title]);

  const onTableChange = (pagination: any, filters?: any, sorter?: any) => {
    let params: any = {
      ...defaultOptions,
    };

    if (pagination) {
      params = {
        ...params,
        top: pagination.pageSize,
        skip: pagination.pageSize * (pagination.current - 1),
      };
    }
    getReportData(params, pagination.current);
  };

  const downloadReportResult = (reportName: string, id: number, fileName: string) => {
    service.downloadReportData(reportName, id).then((res: any) => {
      const a = document.createElement('a');
      const url = window.URL.createObjectURL(new Blob([res]));
      a.href = url;
      a.download = fileName;
      a.click();
    });
  };

  const onRefresh = () => {
    getReportData(
      {
        ...defaultOptions,
        top: pagination.pageSize,
      },
      pagination.current,
    );
  };

  const openInfoNotification = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button type="primary" size="small" onClick={() => notification.close(key)}>
        {t('common.modal.confirm.ok')}
      </Button>
    );
    notification.info({
      message: t('report.confirm.message'),
      description: t('report.confirm.description'),
      btn,
      key,
      duration: 0,
    });
  };

  const onCreate = (values?: any) => {
    const hasOwnProperty = Object.prototype.hasOwnProperty;
    const isHasParams = !hasOwnProperty.call(values, 'target');
    service
      .createRreport(reportMemo.name, isHasParams ? values : null, reportMemo.fileExtention)
      .finally(() => {
        setIsVisibleParams(false);
        onRefresh();
        openInfoNotification();
      })
      .catch((e) => {
        getErrorNotification(e);
      });
  };

  return (
    <>
      <div className={css['reports-container']}>
        <HeaderTable breadcrumb={breadcrumbNameMap}></HeaderTable>
        <ReportDataControl onRefresh={onRefresh} onCreate={() => setIsVisibleParams(true)} />
      </div>
      <div className="table-container">
        <Table
          columns={getColumns({
            t,
            downloadFile: downloadReportResult,
            lang,
          })}
          loading={loading}
          size="small"
          dataSource={data}
          showHeader={true}
          className="consumers-info-table"
          rowClassName="common-table-row--pointer"
          rowKey="id"
          scroll={{ y: windowHeight - 450 }}
          pagination={pagination}
          onChange={onTableChange}
        />
      </div>
      {isVisibleParams && (
        <DynamicParamsModal
          visible={isVisibleParams}
          report={reportMemo}
          onOk={onCreate}
          onCancel={() => setIsVisibleParams(false)}
        />
      )}
    </>
  );
};

export default ReportDataPage;
