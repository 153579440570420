import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../contexts/AppContextProvider';
import { IAppContext } from '../../typings/IApp';
import i18n, { langs } from '../../utils/i18n';
import css from './../../assets/styles/header.module.css';
import LogoComponent from './LogoComponent';
import NavMenu from './Nav/enums/NavMenu';
import NotificationsBell from './NotificationsBell';

const HeaderMobile = () => {
  const { app } = useContext<IAppContext>(AppContext);
  const [isOpenNav, setIsOpenNav] = useState(false);

  const changeLanguage = (key: string) => {
    if (i18n.language !== key) {
      i18n.changeLanguage(key);
    }
  };

  return (
    <Header
      style={{
        background: isOpenNav ? '#fff' : '#eef0f4',
        padding: 0,
        borderRadius: '6px',
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      className="header-sh-box">
      <div className="container-header-mobile" style={{ width: '90%', borderRadius: '6px' }}>
        {app.user && !isOpenNav && (
          <span className={css['header-profile-dropdown']}>
            <LogoComponent
              id={app.user.id}
              name={`${app.user.firstName} ${app.user.lastName}`}
              image={app.user.image || ''}
            />
            <span className={css['header-profile-username']}>
              {app?.user ? [app.user.firstName, app.user.lastName].join(' ') : 'User Name'}
            </span>
          </span>
        )}
        {isOpenNav && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '30%' }}>
            {langs &&
              langs.map((lng) => (
                <span
                  key={lng.key}
                  onClick={() => changeLanguage(lng.key)}
                  className={css[i18n.language === lng.key ? 'lng-active-btn' : 'lng-btn']}>
                  {lng.label}
                </span>
              ))}
          </div>
        )}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {!isOpenNav && (
            <span className={css['header-profile-dropdown']} style={{ marginRight: '1rem' }}>
              <NotificationsBell />
            </span>
          )}
          <Dropdown
            placement="bottomCenter"
            visible={isOpenNav}
            overlay={<NavMenu setIsOpenNav={setIsOpenNav} />}
            trigger={['click']}
            overlayStyle={{ height: 'calc(100vh - 60px)', width: '100%', zIndex: 1000 }}>
            <div className="trigger-mobile" onClick={() => setIsOpenNav(!isOpenNav)}>
              {isOpenNav ? <CloseOutlined /> : <MenuOutlined />}
            </div>
          </Dropdown>
        </div>
      </div>
    </Header>
  );
};

export default HeaderMobile;
