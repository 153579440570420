import { notification } from 'antd';
import { DefaultMessages } from '../../constants/norification';

export const getSuccessNotification = (desc: string = '') => {
  return getNotification('success', undefined, desc);
};

export const getWarningNotification = (desc: string = '') => {
  return getNotification('warning', undefined, desc);
};

export const getErrorNotification = (e: any, message?: string) => {
  let description;

  if (e.status === 401) {
    return;
  }
  if (typeof e === 'string') {
    description = e;
  } else if (typeof e === 'object' && e !== null) {
    description = e.error && e.error.message ? e.error.message : e.message || e.msg;
  }

  return getNotification('error', message, description);
};

export const getNotification = (type: keyof typeof notification, message?: string, description?: any) => {
  let msg = message;
  if (isValidMessageType(type)) {
    msg = msg || DefaultMessages[type];
  }
  if (typeof description === 'object' && description !== null) {
    description = description.message || '';
  }

  notification[type]({
    message: msg || 'System error',
    description,
  } as any);
};

function isValidMessageType(value: string | number | symbol): value is keyof typeof DefaultMessages {
  return value in DefaultMessages;
}
