import React, { useContext, useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router';
import { CHANGE_CURRENT_PAGE } from '../../constants/actionTypes/headerConstants';
import { HeaderContext } from '../../contexts/HeaderContextProvider';
import { useTranslation } from 'react-i18next';
import QuoteUploadForm from '../Quotes/components/UploadQuote/QuoteUploadForm';
import { NavLink } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContextProvider';
import ModalUploadFileSuccess from '../../components/common/Modals/ModalUploadFileSuccess';
import ListPageServices from '../../services/PropertiesService';
import BrokerDetailsPage from '../DetailsProject/BrokerDetailsPage';
import BrokerQuotes from '../Quotes/QuotesBrokerPage';
import { PropertyModel } from '../../models/PropertyModel';
import { IUserEasy } from '../../models/UserModel';
import { IAppContext } from '../../typings/IApp';

const serviceProperties: ListPageServices = new ListPageServices();

const ConsumerManagePage = () => {
  const params = useParams<{ propertyId: string; projectId: string; tabName: string }>();
  const { t } = useTranslation();
  const { headerDispatch } = useContext(HeaderContext);
  const {
    app: { user, isSupplier },
  } = useContext<IAppContext>(AppContext);
  const { state } = useLocation<{ projectKey: string }>();
  const { propertyId, projectId, tabName } = params;
  const [consumers, setConsumers] = useState<IUserEasy[]>([]);
  const [brokerInfo, setBrokerInfo] = useState<IUserEasy>();
  const [isOpenUploadMessageModal, setIsOpenUploadMessageModal] = useState(false);
  const [isOpenUploadQuote, setIsOpenUploadQuote] = useState(false);
  const [isCreatedQuote, setIsCreatedQuote] = useState(false);
  const [isOpenStatusQuoteForm, setIsOpenStatusQuoteForm] = useState(false);
  const [currentQuote, setCurrentQuote] = useState<number | null>(null);
  const [propertyTitle, setPropertyTitle] = useState<string>('');

  const tabsSupplier = [
    {
      id: 1,
      path: 'details_project',
      name: t('consumers.manage.tabs.details.project'),
      page: <BrokerDetailsPage consumers={consumers} brokerInfo={brokerInfo} />,
    },
    {
      id: 2,
      name: t('consumers.manage.tabs.quotes'),
      path: 'quotes',
      page: (
        <BrokerQuotes
          propertyId={+propertyId}
          supplierId={user.id}
          setIsOpenUploadQuote={setIsOpenUploadQuote}
          isCreatedQuote={isCreatedQuote}
          isOpenStatusQuoteForm={isOpenStatusQuoteForm}
          setIsOpenStatusQuoteForm={setIsOpenStatusQuoteForm}
          setIsCreatedQuote={setIsCreatedQuote}
          currentQuote={currentQuote}
          setCurrentQuote={setCurrentQuote}
        />
      ),
    },
  ];

  useEffect(() => {
    if (propertyId && state?.projectKey) {
      getProperty(state.projectKey);
    }
  }, [propertyId, state?.projectKey]);

  useEffect(() => {
    headerDispatch({
      type: CHANGE_CURRENT_PAGE,
      currentPage: `${t('consumers.table.columns.property')} ${propertyTitle}`,
      path: 'view',
    });
  }, [propertyTitle]);

  const getProperty = (projectKey: string) => {
    serviceProperties.getPropertyById({ id: propertyId, projectKey }).then((res: PropertyModel) => {
      setPropertyTitle(res.title);
      setConsumers(res.consumers);
      setBrokerInfo(res.agent);
    });
  };

  return (
    <div className="consumer-tab-container">
      {isOpenUploadQuote && (
        <QuoteUploadForm
          isOpenUpload={isOpenUploadQuote}
          setIsOpenUpload={setIsOpenUploadQuote}
          projectId={projectId}
          propertyId={propertyId}
          setIsCreatedQuote={setIsCreatedQuote}
          setIsOpenStatusQuoteForm={setIsOpenStatusQuoteForm}
          setCurrentQuote={setCurrentQuote}
          setIsOpenUploadMessageModal={setIsOpenUploadMessageModal}
        />
      )}
      {isOpenUploadMessageModal && (
        <ModalUploadFileSuccess isOpen={isOpenUploadMessageModal} onOk={() => setIsOpenUploadMessageModal(false)} />
      )}
      {isSupplier && (
        <>
          <div className="tab-container">
            {tabsSupplier.map((tab) => (
              <NavLink
                exact
                to={{
                  pathname: tab.path,
                  state,
                }}
                isActive={(_, location) => location.pathname.split('/').pop() === tab.path}
                className="consumer-tab common-animation-primary"
                activeClassName="consumer-tab-active"
                key={tab.id}>
                {tab.name}
              </NavLink>
            ))}
          </div>
          {tabsSupplier.find((tab) => tab.path === tabName)?.page}
        </>
      )}
    </div>
  );
};

export default ConsumerManagePage;
