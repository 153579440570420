import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../assets/styles/gallery.module.css';
import { CHANGE_CURRENT_PAGE } from '../../constants/actionTypes/headerConstants';
import { AppContext } from '../../contexts/AppContextProvider';
import { HeaderContext } from '../../contexts/HeaderContextProvider';
import { IAppContext } from '../../typings/IApp';
import NoPropertyPage from '../common/NoPropertyPage';
import GalleryListInfo from './components/GalleryListInfo';
import i18n from '../../utils/i18n';

const ConsumerGallery = () => {
  const { headerDispatch } = useContext(HeaderContext);
  const { t } = useTranslation();
  const {
    app: { currentProperty },
  } = useContext<IAppContext>(AppContext);

  useEffect(() => {
    headerDispatch({
      type: CHANGE_CURRENT_PAGE,
      currentPage: t('consumers.manage.tabs.gallery'),
      path: 'gallery',
    });
  }, [i18n.language]);

  if (!currentProperty) {
    return <NoPropertyPage />;
  }

  return (
    <div className={`gallery-common-container ${css['container-сonsumer']}`}>
      <GalleryListInfo propertyId={currentProperty.id} />
    </div>
  );
};

export default ConsumerGallery;
