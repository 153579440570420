import {
  CLEAR_FILEMANAGMENT_FILTERS,
  GET_FILEMANAGMENT_FILTERS,
  SAVE_FILEMANAGMENT_FILTERS,
  SET_CONSUMERS_FILTERS,
  SET_CONSUMERS_PAGINATION,
  SET_DOCUMENTS_FILTERS,
  SET_FAQ_FILTERS,
  SET_HIW_FILTERS,
  SET_FILEMANAGMENT_FILTERS,
  SET_GALLERY_FILTERS,
  SET_INTERNAL_DOCUMENTS_FILTERS,
  SET_INVOICES_FILTERS,
  SET_LEADS_FILTERS,
  SET_PRODUCTS_FILTERS,
  SET_PROPERTIES_FILTERS,
  SET_QUOTES_FILTERS,
  SET_TASKS_FILTERS,
  SET_TICKETS_FILTERS,
  SET_USERS_FILTERS,
  SET_USER_PAGINATION,
  SET_AMENDMENTS_FILTERS,
  SET_CONTRACTS_FILTERS,
  SET_FINAL_ACCOUNTS_FILTERS,
} from '../constants/actionTypes/filtersConstants';

export const initialState = {
  consumersFilter: {
    searchLoginEmail: '',
    project: '',
    searchProperty: '',
    agent: '',
    isProperty: true,
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
  },
  tasksFilter: {
    assigneeRole: '',
    assignee: undefined,
    status: '',
    dateRange: null,
  },
  faqFilter: {
    topic: '',
  },
  hiwFilter: {
    topic: '',
  },
  ticketsFilter: {
    type: '',
    status: '',
    project: '',
    priority: '',
    dateRange: null,
  },
  internalDocumentsFilter: {
    type: '',
    status: '',
    agent: '',
    consumer: '',
    project: '',
    dateRange: null,
  },
  propertiesFilter: {
    project: null,
    search: '',
  },
  productsFilter: {
    status: '',
  },
  usersFilter: {
    project: '',
    roll: '',
    searchLoginEmail: '',
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
  },
  leadsFilter: {
    status: '',
  },
  quotesFilter: {
    status: '',
  },
  amendmentsFilter: {
    status: '',
  },
  contractsFilter: {
    status: '',
  },
  invoicesFilter: {
    status: '',
    dateRange: null,
  },
  documentsFilter: {
    status: '',
    dateRange: null,
  },
  galleryFilter: {
    dateRange: null,
  },
  fileManagementFilter: {},
  finalAccountsFilter: {
    status: '',
  },
};

export function reducer(state: any, action: any) {
  switch (action.type) {
    case SET_CONSUMERS_FILTERS:
      return { ...state, consumersFilter: action.filters };
    case SET_CONSUMERS_PAGINATION:
      return { ...state, consumersFilter: { ...state.consumersFilter, pagination: action.pagination } };
    case SET_TASKS_FILTERS:
      return { ...state, tasksFilter: action.filters };
    case SET_FAQ_FILTERS:
      return { ...state, faqFilter: action.filters };
    case SET_HIW_FILTERS:
      return { ...state, hiwFilter: action.filters };
    case SET_TICKETS_FILTERS:
      return { ...state, ticketsFilter: action.filters };
    case SET_INTERNAL_DOCUMENTS_FILTERS:
      return { ...state, internalDocumentsFilter: action.filters };
    case SET_PROPERTIES_FILTERS:
      return { ...state, propertiesFilter: action.filters };
    case SET_PRODUCTS_FILTERS:
      return { ...state, productsFilter: action.filters };
    case SET_USERS_FILTERS:
      return { ...state, usersFilter: action.filters };
    case SET_USER_PAGINATION:
      return { ...state, usersFilter: { ...state.usersFilter, pagination: action.pagination } };
    case SET_LEADS_FILTERS:
      return { ...state, leadsFilter: action.filters };
    case SET_AMENDMENTS_FILTERS:
      return { ...state, amendmentsFilter: action.filters };
    case SET_CONTRACTS_FILTERS:
      return { ...state, contractsFilter: action.filters };
    case SET_QUOTES_FILTERS:
      return { ...state, quotesFilter: action.filters };
    case SET_INVOICES_FILTERS:
      return { ...state, invoicesFilter: action.filters };
    case SET_DOCUMENTS_FILTERS:
      return { ...state, documentsFilter: action.filters };
    case SET_GALLERY_FILTERS:
      return { ...state, galleryFilter: action.filters };
    case SET_FILEMANAGMENT_FILTERS:
      return { ...state, fileManagementFilter: action.filters };
    case SET_FINAL_ACCOUNTS_FILTERS:
      return { ...state, finalAccountsFilter: action.filters };
    case GET_FILEMANAGMENT_FILTERS: {
      const filters = localStorage.getItem(SET_FILEMANAGMENT_FILTERS)
        ? JSON.parse(localStorage.getItem(SET_FILEMANAGMENT_FILTERS) as string)
        : null;
      if (filters) {
        return { ...state, fileManagementFilter: filters };
      }
      return { ...state, fileManagementFilter: initialState.fileManagementFilter };
    }
    case CLEAR_FILEMANAGMENT_FILTERS: {
      localStorage.removeItem(SET_FILEMANAGMENT_FILTERS);
      return {
        ...state,
        fileManagementFilter: initialState.fileManagementFilter,
      };
    }
    case SAVE_FILEMANAGMENT_FILTERS: {
      localStorage.setItem(SET_FILEMANAGMENT_FILTERS, JSON.stringify(state.fileManagementFilter));
      return { ...state };
    }
    default:
      return initialState;
  }
}
