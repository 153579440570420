import { Button } from 'antd';
import React from 'react';
import { TFunction } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { IReport } from '../../../models/ReportModel';

export const getColumns = (t: TFunction<'translation'>) => [
  {
    title: <span className="budget-table-tr">{t('report.table.columns.name')}</span>,
    dataIndex: ['name'],
    key: 'name',
    width: '25%',
  },
  {
    title: <span className="budget-table-tr">{t('report.table.columns.title')}</span>,
    dataIndex: ['title'],
    key: 'title',
    width: '25%',
  },
  {
    title: <span className="budget-table-tr">{t('report.table.columns.description')}</span>,
    dataIndex: ['description'],
    key: 'description',
    width: '35%',
  },
  {
    title: <span className="budget-table-tr">{t('report.table.columns.action')}</span>,
    key: 'action',
    width: '15%',
    render: (cell: IReport) => {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <NavLink to={`/reports/${cell.name}`} style={{ borderRadius: 4 }} className="common-anmation-primary">
            <Button
              className="common-secondary-btn"
              style={{ borderColor: '#076ee5', backgroundColor: '#eef0f4', borderRadius: '6px' }}>
              <span style={{ color: '#076ee5' }}>{t('common.btn.open')}</span>
            </Button>
          </NavLink>
          {/* <Button
            style={{
              borderColor: '#076ee5',
              backgroundColor: '#eef0f4',
              borderRadius: '6px',
              marginRight: '1rem',
              height: '100%',
            }}
            onClick={() => getChildren(cell)}>
            <span style={{ color: '#076ee5' }}>{t('consumers.table.columns.manage')}</span>
          </Button>
          <NavLink
            to={`${pathname}/property/${cell.id}/project/${cell.project.id}`}
            className={css['edit-consumer-btn']}>
            <img src={editIcon} alt="" style={{ width: '12px', height: '12px', marginRight: '0.5rem' }} />
            {t('gallery.modal.select.btn').toLowerCase()}
          </NavLink> */}
        </div>
      );
    },
  },
];
