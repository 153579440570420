import { Button, Col, Drawer, Form, Input, notification, Row } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../../assets/styles/budget.module.css';
import ProjectsService from '../../../../services/ProjectsService';
import { PaymentInfo } from '../../../../models/CompaniesModel';
import FinalAccountRight from './components/FinalAccountRight';
import FinalAccountTitle from './components/FinalAccountTitle';
import FinalAccountOptionalText from './components/FinalAccountOptionalText';
import FinalAccountContractAndAmendments from './components/FinalAccountContractAndAmendments';
import FinalAccountPayment from './components/FinalAccountPayment';
import ConfirmUploadForm from './ConfirmUploadForm';
import { ConsumerModel } from '../../../../models/ConsumerModel';
import { useLessThen801 } from '../../../../helpers/mediaDetect';
import CustomSignFlow from '../../../../services/CustomSignFlow';
import { IFlow, IFlowStep, ISignatoryFlow } from '../../../../models/CustomSignFlowModel';
import { generateFilterfromObj } from '../../../../helpers/generateFIlterfromObj';
import { FilterTypes } from '../../../../constants/filterTypes';
import { ICreateFinalAccount } from '../../../../models/FinalAccountModel';
import { UploadFile, UploadProps } from 'antd/lib/upload/interface';
import { IProjectFull, ProjectCompanyModel } from '../../../../models/ProjectModel';

interface FinalAccountUploadProps {
  isOpenUpload: boolean;
  setIsOpenUpload: (b: boolean) => void;
  projectId: string;
  propertyId: string;
  setIsCreatedFinalAccount: (b: boolean) => void;
  setIsOpenStatusFinalAccountForm: (b: boolean) => void;
  setCurrentFinalAccount: (v: number | null) => void;
  setIsOpenUploadMessageModal: (b: boolean) => void;
}

const projectsService = new ProjectsService();
const serviceFlow = new CustomSignFlow();

const CreateFinalAccount = (props: FinalAccountUploadProps) => {
  const {
    isOpenUpload,
    setIsOpenUpload,
    projectId,
    propertyId,
    setIsCreatedFinalAccount,
    setIsOpenStatusFinalAccountForm,
    setCurrentFinalAccount,
    setIsOpenUploadMessageModal,
  } = props;
  const { t } = useTranslation();
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [project, setProject] = useState<IProjectFull | null>(null);
  const [introductionText, setIntroductionText] = useState('');
  const [includingText, setIncludingText] = useState('');
  const [createdBody, setCreatedBody] = useState<ICreateFinalAccount | null>(null);
  const [date, setDate] = useState<moment.Moment | null>(moment());
  const [fileAttachmentList, setFileAttachmentList] = useState<UploadFile[]>([]);
  const [fileAmendmentsList, setFileAmendmentsList] = useState<UploadFile[]>([]);
  const [fileContract, setFileContract] = useState<UploadFile[]>([]);
  const [paymentsProfile, setPaymentsProfile] = useState<PaymentInfo[] | null>(null);
  const [paymentProfile, setPaymentProfile] = useState<PaymentInfo | null>(null);
  const [selectCompany, setSelectCompany] = useState<ProjectCompanyModel>();
  const [consumers, setConsumers] = useState<ConsumerModel[]>([]);
  const [flows, setFlows] = useState<IFlow[]>([]);
  const [flowSteps, setFlowSteps] = useState<IFlowStep[]>([]);
  const [selectConsumers, setSelectConsumers] = useState<ConsumerModel[]>([]);

  const [fields, setFields] = useState([
    { id: 1, isDone: true, title: t('budget.upload.overview.fields.client') },
    { id: 2, isDone: true, title: t('budget.upload.overview.fields.id') },
    { id: 3, isDone: false, title: t('finalAccount.id') },
    { id: 9, isDone: false, title: t('finalAccount.name') },
    { id: 4, isDone: true, title: t('finalAccount.date') },
    { id: 6, isDone: true, title: t('budget.upload.overview.fields.details') },
    { id: 7, isDone: true, title: t('budget.upload.overview.fields.vat') },
    { id: 8, isDone: true, title: t('budget.upload.overview.fields.fees') },
  ]);

  const [form] = Form.useForm();

  useEffect(() => {
    projectsService
      .getProjectById(+projectId)
      .then((value: IProjectFull) => {
        setProject(value);
        if (!value.defaultPaymentInfoId || value.companies.length === 0) {
          notification.error({
            message: `${t('project.not.fill.payment')} ${value.title}`,
          });
          setIsOpenUpload(false);
        }
        if (value.companies.length > 0) {
          setPaymentsProfile(
            value.companies.reduceRight((prev: PaymentInfo[], curr) => {
              prev.push(...curr.paymentInfo);
              return prev;
            }, []),
          );
          setSelectCompany(
            value.companies.find((company) =>
              company.paymentInfo.find((payment) => payment.id === value.defaultPaymentInfoId),
            ),
          );
        }
        form.setFieldsValue({ defaultPaymentInfoId: value.defaultPaymentInfoId });
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      );
  }, []);

  useEffect(() => {
    serviceFlow
      .getFlows({
        filters: generateFilterfromObj({
          'projects/id': {
            type: FilterTypes.SELECT,
            value: +projectId,
          },
          'document_types/code': {
            type: FilterTypes.SELECT,
            value: 'finalAccount',
          },
        }),
      })
      .then((flows: IFlow[]) => {
        setFlows(flows);
        const defaultFlowId = flows.find((item) => item.isDefault)?.id;
        form.setFieldsValue({ flow: defaultFlowId || '' });
        setFlowSteps(
          flows
            .find((item) => item.id === defaultFlowId)
            ?.flowsSteps.map((item) => ({ signatoriesName: item.step.signatoriesName, code: item.step.code })) || [],
        );
      });
  }, []);

  const onSubmit = (value: any) => {
    if (value.amendments.filter((item: any) => item.name === null).length > 0 || value?.contract?.name === null) {
      notification.error({
        message: t('finalAccount.no.items'),
      });
      return;
    }

    let consumerForPayment = selectConsumers[0];

    if (
      consumerForPayment?.paymentInfo &&
      (!consumerForPayment?.paymentInfo?.id ||
        !consumerForPayment?.paymentInfo?.firstName ||
        !consumerForPayment?.paymentInfo?.lastName)
    ) {
      notification.error({
        message: t('common.no.user.paymentProfile'),
      });
      return;
    }

    const body: ICreateFinalAccount = {
      date: moment(date).format('YYYY-MM-DD'),
      deadline: moment(value.deadline).format('YYYY-MM-DD'),
      vat: 7.7,
      fees: 18,
      sumWithVat: (+value.totalFullSum.sumWithVat * 100).toFixed(0),
      sumWithFees: (+value.totalFullSum.sumWithFees * 100).toFixed(0),
      number: value.finAccId,
      objectText: value.objectText,
      currency: {
        code: project?.defaultCurrency?.code || t('budget.currency').toLowerCase(),
      },
      property: {
        id: +propertyId,
      },
      contract: {
        id: value.contract.id || undefined,
        title: value.contract.name || undefined,
        sumWithFees: (+value.contract.sumWithFees * 100).toFixed(0),
        sumWithVat: (+value.contract.sumWithVat * 100).toFixed(0),
        isNeedCreate: fileContract.length > 0,
      },
      companyPaymentInfo: {
        id: paymentProfile?.id,
      },
      name: value.finAccName || '',
      description: value.description,
      statusV2: {
        code: 'new',
      },
      comment: value.signInfo,
      flow: value.flow ? { id: value.flow } : null,
    } as any;

    if (consumerForPayment) {
      body['consumerPaymentInfo'] = {
        id: consumerForPayment?.paymentInfo.id,
      };
    }

    const signatoriesFlow: ISignatoryFlow[] = [];
    const currentFlow = flows
      ?.find((item) => value.flow && item.id === +value.flow)
      ?.flowsSteps?.sort((a, b) => a.ord - b.ord);

    currentFlow &&
      currentFlow.forEach((item) => {
        value[item.step.code].forEach((id: number) => {
          signatoriesFlow.push({ consumerId: id, stepCode: item.stepCode });
        });
      });

    if (signatoriesFlow.length > 0) {
      body['signatories'] = signatoriesFlow;
    }

    if (value.amendments.length > 1 || value.amendments[0].name) {
      body.finalAccountsAmendments = value.amendments.map((item: any) => ({
        amendmentId: item.id || undefined,
        amendment: {
          id: item.id || undefined,
          number: item.fakeName || item.name,
          guid: item.uid || undefined,
          deltaWithFees: (+item.sumWithFees * 100).toFixed(0),
          deltaWithVat: (+item.sumWithVat * 100).toFixed(0),
        },
      }));
    }

    if (introductionText) {
      body.introduction = introductionText;
    }
    if (includingText) {
      body.concluding = includingText;
    }
    setIsOpenConfirm(true);
    setCreatedBody(body);
  };

  const handleFileAttachment: UploadProps['onChange'] = (info) => {
    setFileAttachmentList(info.fileList);
  };

  const onFormChange = (value: any, allValues: any) => {
    const consumerFieldsName = flowSteps.filter((item) => item.code.split('_').includes('client')) ?? [];
    let currentConsumers: number[] = [];
    consumerFieldsName?.forEach((item) => {
      const users = allValues[item.code];
      if (users && users.length > 0) {
        currentConsumers.push(...users);
      }
    });
    const uniqCustomers = currentConsumers.map((id) => consumers.find((customer) => customer.id === id)) ?? [];
    setSelectConsumers(uniqCustomers as ConsumerModel[]);
  };

  const onSelectFlow = (value: number | '') => {
    form.setFieldsValue({ status: 'new' });
    setSelectConsumers([]);
    setFlowSteps(
      flows
        .find((item) => item.id === value)
        ?.flowsSteps.map((item) => ({ signatoriesName: item.step.signatoriesName, code: item.step.code })) || [],
    );
  };

  return (
    <Drawer className="upload-form-drawer" visible={isOpenUpload} placement="top" closable={false}>
      <Form autoComplete="off" onFinish={onSubmit} form={form} onValuesChange={onFormChange}>
        <Form.Item>
          <div className={css['btns-container']}>
            <Button className={`${css['return-btn']} common-secondary-btn`} onClick={() => setIsOpenUpload(false)}>
              {t('common.return.btn')}
            </Button>
            <Button
              className={`${css['upload-btn']} common-primary-btn`}
              style={{ marginLeft: '1rem' }}
              htmlType="submit">
              {t('common.title.create')}
            </Button>
          </div>
        </Form.Item>
        <div className={css['content-container']}>
          <div className={css['upload-info-container']}>
            <div className={css['invoice-info-container']}>
              <FinalAccountTitle fields={fields} setFields={setFields} date={date} setDate={setDate} />
              <FinalAccountOptionalText
                isTopOptionalText={true}
                introductionText={introductionText}
                setIntroductionText={setIntroductionText}
              />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <span className={css['upload-steps-quoteId']}>{t('amendment.objectText')}</span>
                <Form.Item
                  name="objectText"
                  initialValue={t('ammendment.template.object')}
                  style={{ margin: 0, marginLeft: '1rem' }}
                  rules={[{ required: true, message: t('common.validation.required') }]}>
                  <Input
                    style={{ width: useLessThen801() ? '100%' : '400px' }}
                    className="consumer-form-input common-animation-primary"
                    onPressEnter={(e) => e.preventDefault()}
                  />
                </Form.Item>
              </div>
              <div className={css['invoice-info-container']} style={{ marginBottom: '1rem' }}>
                <Row>
                  <Col flex={'auto'}></Col>
                  <Col style={{ display: 'flex', justifyContent: 'center' }} flex={'0 0 180px'}>
                    <span style={{ fontWeight: 700 }}>HT</span>
                  </Col>
                  <Col style={{ display: 'flex', justifyContent: 'center' }} flex={'0 0 180px'}>
                    <span style={{ fontWeight: 700 }}>TTC</span>
                  </Col>
                </Row>
                <FinalAccountContractAndAmendments
                  form={form}
                  propertyId={propertyId}
                  setFileContract={setFileContract}
                  fileAmedmentsList={fileAmendmentsList}
                  setFileAmedmentsList={setFileAmendmentsList}
                  currency={(project && project.defaultCurrency.description) || ''}
                />
              </div>
            </div>
            <FinalAccountOptionalText
              isTopOptionalText={false}
              includingText={includingText}
              setIncludingText={setIncludingText}
            />
            <FinalAccountPayment
              paymentsProfile={paymentsProfile}
              setPaymentProfile={setPaymentProfile}
              selectCompany={selectCompany}
              project={project}
              setSelectCompany={setSelectCompany}
              paymentProfile={paymentProfile}
            />
          </div>
          <FinalAccountRight
            handleFileAttachment={handleFileAttachment}
            fileAttachmentList={fileAttachmentList}
            fields={fields}
            propertyId={propertyId}
            setConsumersList={setConsumers}
            onSelectFlow={onSelectFlow}
            flows={flows}
            flowSteps={flowSteps}
          />
          {isOpenConfirm && createdBody && (
            <ConfirmUploadForm
              isOpenConfirm={isOpenConfirm}
              setIsOpenUpload={setIsOpenUpload}
              setIsOpenConfirm={setIsOpenConfirm}
              createdBody={createdBody}
              fileAttachmentList={fileAttachmentList}
              fileAmendmentsList={fileAmendmentsList}
              fileContract={fileContract}
              setIsCreatedInvoice={setIsCreatedFinalAccount}
              setIsOpenStatusForm={setIsOpenStatusFinalAccountForm}
              setCurrentFinAcc={setCurrentFinalAccount}
              setIsOpenUploadMessageModal={setIsOpenUploadMessageModal}
            />
          )}
        </div>
      </Form>
    </Drawer>
  );
};

export default CreateFinalAccount;
