import HttpService from './common/httpService';
import config from '../config';

class ChangeOrdersService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getChangeOrders(projectId: number) {
    return this.httpService.get(`${config.baseRequest}changeOrders/list?projectId=${projectId}`);
  }
}

export default ChangeOrdersService;
