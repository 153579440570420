export const getSearchParam = (searchUrl: string, query: string) => {
  if (!searchUrl || !query) {
    return '';
  }
  const result = searchUrl
    .split('&')
    ?.find((el) => el.includes(query))
    ?.split('=')[1];
  if (!result) {
    return '';
  }
  return checkEncodeURI(result) ? decodeURIComponent(result) : result;
};

const checkEncodeURI = (str: string) => {
  return /%[0-9a-f]{2}/i.test(str);
};
