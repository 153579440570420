const regExRule = new RegExp('\\$\\{\\S+\\}', 'g');

export const isTemplateValue = (str: string) => {
    return regExRule.test(str);
};

export const getTemplateVariables = (str: string) => {
    return str.match(regExRule);
};

export const parseTemplateVariable = (varStr: string) => {
    const value = varStr.replace(/\$\{|\}/g, '');
    const [obj, key] = value.split('.');
    return { obj, key };
};

export const replaceTemplateValue = (str: string, varStr: string, value: any) => {
    if (!value) return '';
    return str.replace(varStr, value);
};