import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Input, Checkbox, Transfer, Tree, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { validateObjectField } from '../../../helpers/validateObjectField';
import ProductService from '../../../services/ProductService';
import { productNodeKeys } from '../enums/productNodeKeys';
import { findNodeObj, findAllLeafs } from '../../../helpers/proccessTreeTools';
import ProductNameLayout from '../../../components/common/ProductNameLayout';
import ArticleNameLayout from '../../../components/common/ArticleNameLayout';

const productService: ProductService = new ProductService();

interface SpaceOptionsModifyModel {
  option: any;
  options: any[];
}

const isChecked = (selectedKeys: any, eventKey: any) => selectedKeys.indexOf(eventKey) !== -1;

const generateTree = (treeNodes: any = [], checkedKeys: any = []): any =>
  treeNodes.map(({ children, ...props }: any) => ({
    ...props,
    disabled: props.disabled || checkedKeys.includes(props.key),
    children: generateTree(children, checkedKeys),
  }));

const parseTreeTitle = (node: any) => {
  const nodeType = node.key.split('-')[0];
  switch (nodeType) {
    case productNodeKeys.types: {
      return (
        <Space align="start">
          {node?.description}
          <Typography.Title level={5} disabled style={{ fontSize: '10px', paddingTop: '3px' }}>
            (&darr; {node.children.length} sub)
          </Typography.Title>
        </Space>
      );
    }
    case productNodeKeys.categories: {
      return (
        <Space align="start">
          {node?.name}
          <Typography.Title level={5} disabled style={{ fontSize: '10px', paddingTop: '3px' }}>
            (&darr; {node.children.length} sub)
          </Typography.Title>
        </Space>
      );
    }
    case productNodeKeys.articles: {
      return (
        <Space align="start">
          <ArticleNameLayout article={node} />
          <Typography.Title level={5} disabled style={{ fontSize: '10px', paddingTop: '3px' }}>
            (&darr; {node.children.length} sub)
          </Typography.Title>
        </Space>
      );
    }
    case productNodeKeys.products: {
      return <ProductNameLayout product={node} />;
    }
    default: {
      return (
        <Space align="start">
          {node?.name || node?.code || node?.description}
          <Typography.Title level={5} disabled style={{ fontSize: '10px', paddingTop: '3px' }}>
            (&darr; {node.children.length} sub)
          </Typography.Title>
        </Space>
      );
    }
  }
};

const TreeTransfer = ({ dataSource, targetKeys, ...props }: any) => {
  const transferDataSource: any = [];
  function flatten(list = []) {
    list.forEach((item: any) => {
      transferDataSource.push(item);
      flatten(item.children);
    });
  }
  flatten(dataSource);

  return (
    <Transfer
      {...props}
      dataSource={transferDataSource}
      showSelectAll={false}
      targetKeys={targetKeys}
      listStyle={{ height: 400 }}>
      {({ direction, onItemSelect, selectedKeys }) => {
        if (direction === 'left') {
          const checkedKeys = [...selectedKeys, ...targetKeys];
          return (
            <Tree
              checkable
              checkedKeys={checkedKeys}
              height={350}
              treeData={generateTree(dataSource, targetKeys)}
              titleRender={parseTreeTitle}
              onCheck={(_, { node: { key } }) => {
                onItemSelect(key as string, !isChecked(checkedKeys, key));
              }}
              onSelect={(_, { node: { key } }) => {
                onItemSelect(key as string, !isChecked(checkedKeys, key));
              }}
            />
          );
        }
      }}
    </Transfer>
  );
};

const SpaceOptionsModify: React.FC<any> = React.forwardRef(
  ({ option, options = [] }: SpaceOptionsModifyModel, ref: any) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [products, setProducts] = useState<any[]>([]);
    const [targetProducts, setTargetProducts] = useState<string[]>([]);

    const mapChildNodes = (nodes: any, key: string = productNodeKeys.types) => {
      const keys = Object.keys(productNodeKeys);
      nodes.forEach((filteredItem: any) => {
        filteredItem.key = `${key}-${filteredItem.id}`;
        filteredItem.selectable = false;
        if (key !== productNodeKeys.products) {
          const idx = keys.findIndex((item: string) => key === item);
          if (idx >= 0) {
            filteredItem.children = mapChildNodes(filteredItem[keys[idx + 1]], keys[idx + 1]);
          }
        } else {
          filteredItem.isLeaf = true;
        }
      });
      return nodes;
    };

    const getProducts = () => {
      productService
        .getProductsByCategoryTypes({
          filter: {
            and: [
              ...options.map((op: any) => ({
                products: {
                  id: { ne: op.product?.id },
                },
              })),
              { products: { id: { ne: null } } },
            ],
          },
        })
        .then((res: any) => {
          const tree = mapChildNodes(res);
          setProducts(tree);
        });
    };

    const getAsyncData = async () => {
      const actions = [getProducts()];
      Promise.all(actions);
    };

    useEffect(() => {
      getAsyncData();
      if (option) {
        form.setFieldsValue(option);
      }
    }, [option]);

    const handleProductsChange = (keys: string[]) => {
      let leafs: any[] = [];
      keys.forEach((key: string) => {
        const result = findNodeObj(key, products);
        leafs = [...leafs, ...findAllLeafs(result)];
      });
      const target = leafs
        .map((leaf: any) => leaf.key)
        .filter((value: string, idx: number, self: string[]) => self.indexOf(value) === idx);
      setTargetProducts(target);
      form.setFieldsValue({
        products: target.map((key: any) => findNodeObj(key, products)),
      });
    };

    return (
      <Form autoComplete="off" ref={ref} form={form} layout="vertical" name="modify_space_option">
        <Row gutter={[24, 24]}>
          {option ? (
            <Col span={24}>
              <Form.Item label={t('space.object.option.create.product')}>
                <Space align="start">
                  <ProductNameLayout product={option?.product} />
                  <ArticleNameLayout article={option?.product.article} />
                </Space>
              </Form.Item>
            </Col>
          ) : (
            <Col span={24}>
              <Form.Item label={t('space.object.option.create.products')} name="products">
                <TreeTransfer
                  titles={[t('common.label.source'), t('space.object.option.create.products')]}
                  dataSource={products}
                  targetKeys={targetProducts}
                  onChange={handleProductsChange}
                  render={(item: any) => <ProductNameLayout product={item} />}
                />
              </Form.Item>
            </Col>
          )}
          <Col span={12}>
            <Form.Item
              label={t('space.object.option.create.isActive.label')}
              name="isActive"
              valuePropName="checked"
              initialValue={true}>
              <Checkbox>{t('space.object.option.create.isActive.value')}</Checkbox>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('space.object.option.create.isDefault.label')} name="isDefault" valuePropName="checked">
              <Checkbox disabled={targetProducts.length > 1}>
                {t('space.object.option.create.isDefault.value')}
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('space.object.option.create.babylonCode')} name="babylonCode">
              <Input disabled={targetProducts.length > 1} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('space.object.option.create.babylonParams')}
              name="babylonParams"
              rules={[validateObjectField('babylonParams')]}>
              <Input.TextArea disabled={targetProducts.length > 1} rows={4} className="common-animation-primary" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  },
);

export default SpaceOptionsModify;
