import React, { useState, useEffect, useContext } from 'react';
import { Form, Row, Col, Input, Select, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import ArticlesService from '../../../services/ArticlesService';
import { CategoriesContext } from '../../../contexts/CategoriesContextProvider';
import NodesModifyLayout from './NodesModifyLayout';
import css from '../../../assets/styles/budget.module.css';
import {
  CATEGORY_REFRESH_ITEMS,
  CATEGORY_SET_SELECTED_NODES,
} from '../../../constants/actionTypes/categoriesConstants';
import { useLessThen801 } from '../../../helpers/mediaDetect';

const service: ArticlesService = new ArticlesService();

const CategoriesModify: React.FC = () => {
  const {
    nodes: { currentNode, currentNodeKey, parent, types = [] },
    nodesDispatch,
  } = useContext(CategoriesContext);
  const { t } = useTranslation();
  const isCreate = !currentNodeKey && !currentNode;
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const setIsRefresh = (isRefresh: boolean) => {
    nodesDispatch({
      type: CATEGORY_REFRESH_ITEMS,
      isRefresh,
    });
  };

  const setSelectedNodes = (selectedNodes: any[]) => {
    nodesDispatch({
      type: CATEGORY_SET_SELECTED_NODES,
      selectedNodes,
    });
  };

  useEffect(() => {
    if (currentNode) {
      form.setFieldsValue(currentNode);
    } else {
      form.resetFields();
    }
  }, [currentNode]);

  const onSubmit = async () => {
    const values = await form.validateFields();
    const hasProperty = Object.prototype.hasOwnProperty;
    if (!hasProperty.call(values, 'errorFields')) {
      setIsSaving(true);

      if (isCreate) {
        service
          .createCategory({ ...values, parent })
          .then((res) => {
            setSelectedNodes([`${res.id}`]);
            notification.success({
              message: t('category.create.notification.create'),
            });
            setIsRefresh(true);
          })
          .catch((e) => {
            notification.error({
              message: e.message || t('common.error.internalServerError'),
            });
          })
          .finally(() => setIsSaving(false));
      } else {
        service
          .createCategory({ ...values, id: currentNode.id })
          .then(() => {
            notification.success({
              message: t('category.create.notification.create'),
            });
            setIsRefresh(true);
          })
          .catch((e) => {
            notification.error({
              message: e.message || t('common.error.internalServerError'),
            });
          })
          .finally(() => setIsSaving(false));
      }
    }
  };

  if (useLessThen801()) {
    return (
      <NodesModifyLayout submit={onSubmit} isSaving={isSaving}>
        <Form autoComplete="off" form={form} layout="vertical" name="modify_variant">
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Form.Item label={t('category.create.type')} name={['type', 'id']}>
                <Select
                  className="common-select common-animation-primary"
                  suffixIcon={
                    <div
                      className={`arrow-drop-img ${css['arrow-drop']}`}
                      style={{ width: '8px', height: '4px' }}></div>
                  }>
                  {types.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item?.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t('category.create.name')}
                name="name"
                rules={[{ required: true, message: t('category.create.name.validation') }]}>
                <Input className="common-animation-primary" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </NodesModifyLayout>
    );
  }

  return (
    <NodesModifyLayout submit={onSubmit} isSaving={isSaving}>
      <Form autoComplete="off" form={form} layout="vertical" name="modify_variant">
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Form.Item label={t('category.create.type')} name={['type', 'id']}>
              <Select
                className="common-select common-animation-primary"
                suffixIcon={
                  <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
                }>
                {types.map((item: any) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item?.description}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('category.create.name')}
              name="name"
              rules={[{ required: true, message: t('category.create.name.validation') }]}>
              <Input className="common-animation-primary" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </NodesModifyLayout>
  );
};

export default CategoriesModify;
