import { DatePicker, Form, Input } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../../../assets/styles/budget.module.css';
import { useLessThen801 } from '../../../../../helpers/mediaDetect';

interface IAmendmentTitle {
  fields: {
    id: number;
    isDone: boolean;
    title: string;
  }[];
  setFields: (
    v: {
      id: number;
      isDone: boolean;
      title: string;
    }[],
  ) => void;
  qouteDate: moment.Moment | null;
  setQuoteDate: (v: moment.Moment | null) => void;
}

const AmendmentTitle = ({ fields, setFields, qouteDate, setQuoteDate }: IAmendmentTitle) => {
  const { t } = useTranslation();
  const [isOpenQuoteDate, setIsOpenQuoteDate] = useState(false);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span style={{ fontWeight: 600, fontSize: '24px', marginBottom: '1rem' }}>{t('amendment.create.title')}</span>
        <Form.Item name="amedmentDate" initialValue={moment()} className={css['upload-steps-qouteDate']}>
          <>
            {!isOpenQuoteDate && (
              <span onClick={() => setIsOpenQuoteDate(true)} className={css['upload-steps-qouteDate']}>
                <span>{t('amendment.date')}</span>
                {` ${moment(qouteDate).format('DD.MM.YYYY') || moment().format('DD.MM.YYYY')}`}
              </span>
            )}
            {isOpenQuoteDate && (
              <DatePicker
                inputReadOnly
                onBlur={() => setIsOpenQuoteDate(false)}
                format="DD.MM.YYYY"
                onChange={(value) => setQuoteDate(value)}
                getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
                suffixIcon={<div className={'calendar-img'} style={{ width: '12px', height: '12px' }}></div>}
                className={`common-date-picker common-animation-primary ${css['upload-steps-qouteDate']}`}
              />
            )}
          </>
        </Form.Item>
      </div>
      <div style={{ display: 'flex' }}>
        <div
          className={css['upload-steps-quoteId-container']}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '1rem',
            marginRight: '2rem',
          }}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: useLessThen801() ? '1rem' : '0' }}>
            <span className={css['upload-steps-quoteId']}>{t('amendment.id')}</span>
            <Form.Item
              name="amedmentId"
              style={{ margin: 0, marginLeft: '1rem' }}
              rules={[{ required: true, message: t('common.validation.required') }]}>
              <Input
                style={{ width: useLessThen801() ? '100%' : '200px' }}
                className="consumer-form-input common-animation-primary"
                onPressEnter={(e) => e.preventDefault()}
                onChange={(e) => {
                  const newFields = fields.slice().map((item) => {
                    if (item.id === 3) {
                      return {
                        id: item.id,
                        isDone: e.target.value ? true : false,
                        title: item.title,
                      };
                    } else {
                      return item;
                    }
                  });
                  setFields(newFields);
                }}
              />
            </Form.Item>
          </div>
        </div>
        <div
          className={css['upload-steps-quoteId-container']}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '1rem',
          }}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: useLessThen801() ? '1rem' : '0' }}>
            <span className={css['upload-steps-quoteId']}>{t('amendment.name')}</span>
            <Form.Item
              name="amedmentName"
              style={{ margin: 0, marginLeft: '1rem' }}
              rules={[{ required: true, message: t('common.validation.required') }]}>
              <Input
                style={{ width: useLessThen801() ? '100%' : '200px' }}
                className="consumer-form-input common-animation-primary"
                onPressEnter={(e) => e.preventDefault()}
                onChange={(e) => {
                  const newFields = fields.slice().map((item) => {
                    if (item.id === 9) {
                      return {
                        id: item.id,
                        isDone: e.target.value ? true : false,
                        title: item.title,
                      };
                    } else {
                      return item;
                    }
                  });
                  setFields(newFields);
                }}
              />
            </Form.Item>
          </div>
        </div>
      </div>
    </>
  );
};

export default AmendmentTitle;
