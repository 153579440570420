import React from 'react';
import { Button } from 'antd';
import css from '../../../../assets/styles/project.module.css';
import editIcon from '../../../../assets/images/ic-edit-dark.svg';
import { PaymentInfo } from '../../../../models/CompaniesModel';
import { TFunction } from 'react-i18next';

interface CompaniesColumnsModel {
  t: TFunction<'translation'>;
  onEditClick: (p: PaymentInfo) => void;
}

export const getColumns = ({ t, onEditClick }: CompaniesColumnsModel) => [
  {
    title: () => (
      <span className="budget-table-tr" style={{ marginLeft: '8px' }}>
        #
      </span>
    ),
    dataIndex: 'id',
    key: 'id',
    width: 50,
  },
  {
    title: () => <span className="budget-table-tr">{t('companies.table.column.holder')}</span>,
    dataIndex: 'accountHolder',
    key: 'accountHolder',
  },
  {
    title: () => <span className="budget-table-tr">{t('companies.table.column.bank.name')}</span>,
    dataIndex: 'bankName',
    key: 'bankName',
  },
  {
    title: () => <span className="budget-table-tr">{t('users.table.columns.action')}</span>,
    key: 'action',
    fixed: 'right' as any,
    with: '100px',
    render: (cell: PaymentInfo) => {
      return (
        <div style={{ display: 'flex', backgroundColor: 'inherit', gap: '1rem' }}>
          <Button
            className={`${css['edit-project-btn']} common-animation-secondary`}
            onClick={() => {
              onEditClick(cell);
            }}>
            <img src={editIcon} alt="" style={{ width: '12px', height: '12px', marginRight: '0.5rem' }} />
            {t('common.btn.modify.lower')}
          </Button>
        </div>
      );
    },
  },
];
