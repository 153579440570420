import React from 'react';
const icons = {
  Search: require('../../assets/images/ic-search.svg'),
  ArrowDrop: require('../../assets/images/ic-arrow-drop.svg'),
};

export type IIconName = keyof typeof icons;

interface IDesignIconProps
  extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  name: IIconName;
  className?: string;
}

const DesignIcon = ({ name, ...props }: IDesignIconProps) => {
  return <img src={icons[name]} {...props} />;
};

export default DesignIcon;
