import { CloseOutlined } from '@ant-design/icons';
import { Modal, Spin, Steps } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SHORT_DATE_TIME_FORMAT } from '../../constants/formats';
import { isEmptyObject } from '../../helpers/isEmptyObject';
import { IFlowsStep } from '../../models/CustomSignFlowModel';
import CustomSignFlow from '../../services/CustomSignFlow';
import i18n from '../../utils/i18n';

const { Step } = Steps;
const serviceFlow = new CustomSignFlow();

interface IProps {
  isOpen: boolean;
  setClose: () => void;
  fetchingData: boolean;
  setFetchingData?: (b: boolean) => void;
  items: any;
  currentItem?: any;
}

const CommonHistoryModal = (props: IProps) => {
  const { isOpen, fetchingData, items, setClose, currentItem, setFetchingData } = props;
  const { t } = useTranslation();
  const [steps, setSteps] = useState<IFlowsStep[]>([]);
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    if (currentItem && !isEmptyObject(currentItem) && currentItem.flow && currentItem.flow.id) {
      getFlow();
    }
  }, [currentItem]);

  const getFlow = () => {
    setFetchingData && setFetchingData(true);
    serviceFlow
      .getFlows({}, currentItem.flow.id)
      .then((res) => {
        if (res) {
          setSteps(res.flowsSteps);
          setCurrentStep(
            currentItem.step
              ? res.flowsSteps.find((step: IFlowsStep) => step.stepCode === currentItem.step.code)?.ord + 1 ?? 0
              : res.flowsSteps.length + 1,
          );
        }
      })
      .finally(() => setFetchingData && setFetchingData(false));
  };

  const setHistoryText = (item: any) => {
    if (item.isTemplateDate) {
      return i18n.language === 'fr' || i18n.language === 'fr-FR'
        ? changeTextWithDate(item.messageFr, item.date)
        : changeTextWithDate(item.messageEn, item.date);
    }

    return i18n.language === 'fr' || i18n.language === 'fr-FR' ? item.messageFr : item.messageEn;
  };

  const changeTextWithDate = (text: string, date: moment.Moment) => {
    const dateIndex = text.indexOf('*');
    return text.slice(0, dateIndex) + moment(date).format('DD.MM.YYYY HH:mm') + text.slice(dateIndex + 8, text.length);
  };

  return (
    <Spin spinning={fetchingData}>
      <Modal
        className="common-modal"
        centered
        style={{ width: '10rem' }}
        visible={isOpen}
        closable={false}
        footer={null}>
        <div className="common-flex-column-style">
          <div className="common-flex-between-style">
            <span className="common-confirm-text">{t('fileManagement.history.title')}</span>
            <CloseOutlined onClick={() => setClose()} />
          </div>
          <Steps
            className={
              currentItem && currentItem.createdUser
                ? 'fileManagment-histories-steps'
                : 'fileManagment-histories-clasic'
            }
            direction="vertical"
            current={currentStep}
            style={{ marginTop: '1rem' }}>
            {currentItem && currentItem.createdUser && (
              <Step
                title={t('history.created.title')}
                description={`${currentItem.createdUser.firstName} ${currentItem.createdUser.lastName} (${moment(
                  currentItem.createdDate,
                ).format(SHORT_DATE_TIME_FORMAT)})`}
              />
            )}
            {steps.length > 0
              ? steps.map((step, i) => {
                  const signatories: any[] =
                    currentItem.signatories.filter((sign: any) => sign.stepCode === step.stepCode) ?? [];
                  return (
                    <Step
                      key={step.ord}
                      status={
                        currentItem?.statusV2.code === 'rejected'
                          ? i + 1 === steps.length
                            ? 'error'
                            : undefined
                          : undefined
                      }
                      title={step.step.name}
                      description={
                        <div>
                          {signatories.length > 0 &&
                            signatories.map((sign) => (
                              <div key={sign.consumerId}>{`${sign.consumer.firstName} ${sign.consumer.lastName} ${
                                sign.isDone
                                  ? `(${moment(sign.doneDate).format(SHORT_DATE_TIME_FORMAT)})`
                                  : currentItem?.statusV2.code === 'rejected' &&
                                    step.ord === currentStep - 1 &&
                                    items &&
                                    items.length > 0
                                  ? `(${moment(items[items.length - 1].date).format(SHORT_DATE_TIME_FORMAT)})`
                                  : ''
                              }`}</div>
                            ))}
                          {currentItem?.statusV2.code === 'rejected' && i + 1 === steps.length && (
                            <div>
                              <hr />
                              {currentItem.comment}
                            </div>
                          )}
                        </div>
                      }
                    />
                  );
                })
              : items.length > 0 &&
                items.map((item: any) => (
                  <Step
                    key={item.date}
                    title={<span style={{ color: '#889bb6' }}>{moment(item.date).format('DD.MM.YYYY HH:mm')}</span>}
                    description={<span>{setHistoryText(item)}</span>}
                  />
                ))}
          </Steps>
        </div>
      </Modal>
    </Spin>
  );
};

export default CommonHistoryModal;
