import HttpService from './common/httpService';
import config from '../config';
import buildQuery from 'odata-query';
import { NewDocumentsModel, UpdatedDocumentsModel } from '../models/DocumentsModel';

class DocumentsService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getDocuments(propertyId: number | null, option: any) {
    const { top, count, skip, filters } = option;
    let filter = [];

    let defaultFilter: any = {
      'property/id': {
        eq: propertyId,
      },
    };

    if (!propertyId) {
      defaultFilter = {};
    }

    if (filters) {
      if (Object.keys(defaultFilter).length) {
        filter = [defaultFilter, ...filters.filter];
      } else {
        filter = [...filters.filter];
      }
      filter.sort((a: any, b: any) =>
        Object.keys(Object.values(a)[0] as any)[0] == 'contains'
          ? -1
          : Object.keys(Object.values(b)[0] as any)[0] == 'contains'
          ? 1
          : 0,
      );
    } else {
      filter = defaultFilter;
    }
    const query = buildQuery({ filter, top, count, skip });
    return this.httpService.get(`${config.baseRequest}documents${query}`);
  }
  public getDocumentsNoArchive(propertyId: number | null, option: any) {
    const { top, count, skip, filters, orderBy } = option;
    let filter = [];

    let defaultFilter: any = {
      'property/id': {
        eq: propertyId,
      },
      isArchive: {
        eq: false,
      },
      or: [
        {
          'type/code': {
            ne: 'contract',
          },
        },
        {
          'type/code': {
            eq: null,
          },
        },
      ],
    };

    if (!propertyId) {
      defaultFilter = {
        isArchive: {
          eq: false,
        },
        or: [
          {
            'type/code': {
              ne: 'contract',
            },
          },
          {
            'type/code': {
              eq: null,
            },
          },
        ],
      };
    }

    if (filters) {
      filters.filter.sort((a: any, b: any) => {
        const check = (item: any) => {
          if (
            Object.values(item).length > 0 &&
            (Object.values(item)[0] as any).length > 0 &&
            Object.values(Object.values(item)[0] as any).length > 0 &&
            Object.values((Object.values(item)[0] as any)[0] as any).length > 0
          ) {
            return Object.keys(Object.values((Object.values(item)[0] as any)[0] as any)[0] as any)[0] == 'contains';
          }
          return false;
        };

        return Object.keys(Object.values(a)[0] as any)[0] == 'contains' || check(a)
          ? -1
          : Object.keys(Object.values(b)[0] as any)[0] == 'contains'
          ? 1
          : 0;
      });
      filter = [...filters.filter, defaultFilter];
      filter.sort((a: any, b: any) => {
        const check = (item: any) => {
          if (
            Object.values(item).length > 0 &&
            (Object.values(item)[0] as any).length > 0 &&
            Object.values(Object.values(item)[0] as any).length > 0 &&
            Object.values((Object.values(item)[0] as any)[0] as any).length > 0
          ) {
            return Object.keys(Object.values((Object.values(item)[0] as any)[0] as any)[0] as any)[0] == 'contains';
          }
          return false;
        };

        return Object.keys(Object.values(a)[0] as any)[0] == 'contains' || check(a)
          ? -1
          : Object.keys(Object.values(b)[0] as any)[0] == 'contains'
          ? 1
          : 0;
      });
    } else {
      filter = defaultFilter;
    }
    const query = buildQuery({ filter, top, count, skip, orderBy, expand: ['type'] });
    return this.httpService.get(`${config.baseRequest}documents${query}`);
  }

  public getDocumentsInfoById(id: number) {
    return this.httpService.get(`${config.baseRequest}documents/${id}`);
  }

  public createDocument(body: NewDocumentsModel) {
    return this.httpService.post(`${config.baseRequest}documents`, body);
  }

  public updateDocument(body: UpdatedDocumentsModel | { id: number; isArchive: boolean }) {
    return this.httpService.put(`${config.baseRequest}documents`, body);
  }

  public rejectDocument(id: number | null, comment: string, envelopeId?: string) {
    const body = id ? { id, comment } : { envelopeId, comment };
    return this.httpService.patch(`${config.baseRequest}documents/step/reject`, body);
  }

  public uploadDocumentFiles(updateId: number, file: any) {
    return this.httpService.postFile(`${config.baseRequest}documents/${updateId}/file`, file);
  }

  //for diagram. Not used.
  public getDocumentStatistic(propertyId: number) {
    return this.httpService.get(`${config.baseRequest}documents/statistic_by_property/${propertyId}`);
  }

  public deleteDocuments(id: number) {
    return this.httpService.delete(`${config.baseRequest}documents/${id}`);
  }

  public getSigningUrl(id: number, returnUrl: string, consumerId: number) {
    return this.httpService.post(`${config.baseRequest}documents/step/redirect`, { id, consumerId, returnUrl });
  }

  public confirmStep(envelopeId?: string, id?: number) {
    return this.httpService.patch(`${config.baseRequest}documents/step/done`, envelopeId ? { envelopeId } : { id });
  }

  public getDocumentHistory(id: number) {
    return this.httpService.get(`${config.baseRequest}documents/${id}/history`);
  }
}

export default DocumentsService;
