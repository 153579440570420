import { Button, Checkbox, notification, Spin, Table, TablePaginationConfig } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CHANGE_CURRENT_PAGE } from '../../../constants/actionTypes/headerConstants';
import { HeaderContext } from '../../../contexts/HeaderContextProvider';
import { QuestionModel, QuestionsModel, TopicsModel } from '../../../models/FAQModel';
import FAQService from '../../../services/FAQService';
import { getColumns } from './components/ManageFAQColumns';
import css from '../../../assets/styles/faq.module.css';
import cssAI from '../../../assets/styles/faqAI.module.css';
import ManageFAQControl from './components/ManageFAQControl';
import { generateFilterfromObj } from '../../../helpers/generateFIlterfromObj';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { generateSorter } from '../../../helpers/generateSorter';
import DeleteForm from './components/DeleteForm';
import filterIcon from '../../../assets/images/ic-filter.svg';
import filterActiveIcon from '../../../assets/images/ic-filter-active.svg';
import aiIcon from '../../../assets/images/ai/aiIcon.png';
import { FilterTypes } from '../../../constants/filterTypes';
import { FiltersContext } from '../../../contexts/FiltersContextProvider';
import { useLessThen801 } from '../../../helpers/mediaDetect';
import { NavLink } from 'react-router-dom';
import TableMobile from '../../common/TableMobile';
import i18n from '../../../utils/i18n';
import { isEmptyObject } from '../../../helpers/isEmptyObject';
import { FilterValue } from 'antd/lib/table/interface';

const service = new FAQService();

const ManageFAQ = () => {
  const {
    filters: { faqFilter },
  } = useContext(FiltersContext);
  const { headerDispatch } = useContext(HeaderContext);
  const { t } = useTranslation();
  const [questions, setQuestions] = useState<QuestionsModel | []>([]);
  const [fetchingData, setFetchingData] = useState(false);
  const [topics, setTopics] = useState<TopicsModel | []>([]);
  const [isSelectMode, setIsSelectMode] = useState(false);
  const [selectedItems, setSelectedItems] = useState<Array<number> | []>([]);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [currentFilters, setCurrentFilters] = useState({
    topic: {
      type: FilterTypes.SELECT,
      value: faqFilter.topic,
    },
    'type/code': {
      type: FilterTypes.SELECT,
      value: null,
    },
  });
  const { height: windowHeight } = useWindowDimensions();
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    skip: 0,
    showSizeChanger: true,
  });

  const option = {
    count: true,
    top: pagination.pageSize,
    current: pagination.current,
    orderBy: generateSorter(['id desc']).orderBy,
    filters: generateFilterfromObj(currentFilters),
  };

  const getQuestions = (option: { [name: string]: any }) => {
    setFetchingData(true);
    service
      .getFAQ(option)
      .then((res) => {
        const { count, items } = res;
        const { current, top } = option;
        setPagination({ ...pagination, current, total: count, pageSize: top });
        setQuestions(items);
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingData(false));
  };

  const getTopics = () => {
    setFetchingData(true);
    service
      .getTopics({
        filter: {
          'type/code': {
            eq: null,
          },
        },
      })
      .then((value: TopicsModel) => setTopics(value))
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingData(false));
  };

  useEffect(() => {
    headerDispatch({
      type: CHANGE_CURRENT_PAGE,
      currentPage: t('header.title.ManageFAQ'),
      path: 'manage_faq',
    });
  }, [i18n.language]);

  useEffect(() => {
    getQuestions(option);
    getTopics();
  }, []);

  const changeSelectMode = () => {
    setIsSelectMode(!isSelectMode);
    if (!isSelectMode) {
      setSelectedItems([]);
    }
  };

  const setOpenDelete = (id?: number) => {
    setIsOpenDelete(true);
    if (id) {
      setCurrentId(id);
    }
  };

  const setCloseDelete = () => {
    setIsOpenDelete(false);
    setCurrentId(null);
    getQuestions(option);
  };

  const applyNewFilter = (filters: { [name: string]: any }) => {
    setCurrentFilters({ ...currentFilters, ...filters });
    return { ...currentFilters, ...filters };
  };

  const onTableChange = (
    pagination: TablePaginationConfig | null,
    filters: Record<string, FilterValue | null | any>,
  ) => {
    let params: { [name: string]: any } = {
      ...option,
    };

    if (filters && !isEmptyObject(filters)) {
      params = {
        ...params,
        filters: generateFilterfromObj(applyNewFilter(filters)),
      };
    } else {
      params = {
        ...params,
        filters: generateFilterfromObj(currentFilters),
      };
    }
    if (pagination && pagination.pageSize && pagination.current) {
      params = {
        ...params,
        top: pagination.pageSize,
        skip: pagination.pageSize * (pagination.current - 1),
        current: pagination.current,
      };
    } else {
      params = {
        ...params,
        skip: 0,
        current: 1,
      };
    }

    getQuestions(params);
  };
  const setDeletedItems = (id: number) => {
    const isHaveItem = selectedItems.find((item) => item === id);
    let newSelectedItems = selectedItems.slice(0);

    if (isHaveItem) {
      newSelectedItems = newSelectedItems.filter((item) => item !== id);
    } else {
      newSelectedItems.push(id);
    }

    setSelectedItems(newSelectedItems);
  };

  const setAllDeletedItems = (checked: boolean) => {
    let newSelectedItems = selectedItems.slice(0);
    const questionsArray = questions.slice(0).map((question: QuestionModel) => question.id);
    if (checked) {
      newSelectedItems = newSelectedItems.concat(questionsArray);
      const result: any = new Map();
      for (const number of newSelectedItems) {
        result.set(number, number);
      }
      setSelectedItems([...result.values()]);
    } else {
      setSelectedItems(newSelectedItems.filter((item) => !questionsArray.includes(item)));
    }
  };

  const loadMore = (option: { [name: string]: any }) => {
    setFetchingData(true);
    option.skip = option.skip ? option.skip + 10 : 10;
    service
      .getFAQ(option)
      .then((res) => {
        const { current, top } = option;
        setPagination({ ...pagination, current, total: res.count, pageSize: top });
        setQuestions([...questions, ...res.items]);
      })
      .catch((e) => console.log(e))
      .finally(() => setFetchingData(false));
  };

  const changeOpenFilter = () => {
    setIsOpenFilter(!isOpenFilter);
  };

  const data =
    questions.length > 0 &&
    (questions.map((item: QuestionModel) =>
      isSelectMode
        ? [
            {
              title: (
                <Checkbox
                  onChange={() => setDeletedItems(item.id)}
                  checked={Boolean(selectedItems.find((el) => el === item.id))}
                />
              ),
            },
            { title: '№', description: item.id, type: 'string' },
            {
              title: t('manage.faq.columns.question'),
              description: i18n.language === 'fr-FR' ? item.questionFr : item.questionEn,
              type: 'string',
            },
            {
              title: t('manage.faq.columns.topic'),
              description: i18n.language === 'fr-FR' ? item.topic?.topicFr : item.topic?.topicEn,
              type: 'string',
            },
            {
              title: t('manage.faq.columns.statuses'),
              description: (
                <div className={css['statuses-column-published-container']}>
                  <span>
                    EN:
                    <span className={css[item.isPublishedEn ? 'published-status' : 'draft-status']}>{` ${
                      item.isPublishedEn
                        ? t('manage.faq.columns.statuses.published')
                        : t('manage.faq.columns.statuses.draft')
                    }`}</span>
                  </span>
                  <span>
                    FR:
                    <span className={css[item.isPublishedFr ? 'published-status' : 'draft-status']}>{` ${
                      item.isPublishedFr
                        ? t('manage.faq.columns.statuses.published')
                        : t('manage.faq.columns.statuses.draft')
                    }`}</span>
                  </span>
                </div>
              ),
            },
            {
              title: t('budget.table.action'),
              type: 'button',
              openUrl: true,
              btnsInfoUrl: [
                {
                  url: `/manage_faq/modify/${item.id}`,
                  urlBtnTitle: t('budget.table.edit'),
                },
              ],
              openByFunction: true,
              btnsInfo: [{ function: () => setOpenDelete(item.id), functionBtnTitle: t('common.title.delete') }],
            },
          ]
        : [
            { title: '№', description: item.id, type: 'string' },
            {
              title: t('manage.faq.columns.question'),
              description: i18n.language === 'fr-FR' ? item.questionFr : item.questionEn,
              type: 'string',
            },
            {
              title: t('manage.faq.columns.topic'),
              description: i18n.language === 'fr-FR' ? item.topic?.topicFr : item.topic?.topicEn,
              type: 'string',
            },
            {
              title: t('manage.faq.columns.statuses'),
              description: (
                <div className={css['statuses-column-published-container']}>
                  <span>
                    EN:
                    <span className={css[item.isPublishedEn ? 'published-status' : 'draft-status']}>{` ${
                      item.isPublishedEn
                        ? t('manage.faq.columns.statuses.published')
                        : t('manage.faq.columns.statuses.draft')
                    }`}</span>
                  </span>
                  <span>
                    FR:
                    <span className={css[item.isPublishedFr ? 'published-status' : 'draft-status']}>{` ${
                      item.isPublishedFr
                        ? t('manage.faq.columns.statuses.published')
                        : t('manage.faq.columns.statuses.draft')
                    }`}</span>
                  </span>
                </div>
              ),
            },
            {
              title: t('budget.table.action'),
              type: 'button',
              openUrl: true,
              btnsInfoUrl: [
                {
                  url: `/manage_faq/modify/${item.id}`,
                  urlBtnTitle: t('budget.table.edit'),
                },
              ],
              openByFunction: true,
              btnsInfo: [{ function: () => setOpenDelete(item.id), functionBtnTitle: t('common.title.delete') }],
            },
          ],
    ) as any);

  if (useLessThen801()) {
    return (
      <>
        <div className="common-mobile-title-container">
          <span style={{ fontSize: '20px' }}>{t('header.title.ManageFAQ')}</span>
          <div className="common-modbile-filter-container" onClick={changeOpenFilter}>
            <img src={isOpenFilter ? filterActiveIcon : filterIcon} width={16} />
            <span className="filter-title" style={{ marginRight: '1rem' }}>
              {isOpenFilter ? t('common.filter.close') : t('common.filter.open')}
            </span>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            marginBottom: '0.5rem',
            flexWrap: 'wrap',
            gap: '0.4rem',
            flexDirection: 'column',
          }}>
          <NavLink
            to={'/manage_faq/topics'}
            className="common-animation-primary"
            style={{ borderRadius: '4px', width: '100%' }}>
            <Button className={`common-blue-border-btn common-secondary-btn`} style={{ height: '42px', width: '100%' }}>
              {t('manage.control.topics.control.btn')}
            </Button>
          </NavLink>
          <NavLink
            to={'/manage_faq/modify'}
            className="common-animation-primary"
            style={{ borderRadius: '4px', width: '100%' }}>
            <Button className={`common-blue-btn common-primary-btn`} style={{ height: '42px', width: '100%' }}>
              {t('common.btn.new')}
            </Button>
          </NavLink>
          <NavLink
            to={'/manage_faq/ai_creating'}
            target="_blank"
            className="common-animation-primary"
            style={{ borderRadius: '4px', width: '100%' }}>
            <Button
              type="primary"
              className={cssAI.aiButton}
              style={{ borderRadius: '4px', width: '100%', display: 'flex', justifyContent: 'center' }}>
              <img src={aiIcon} style={{ height: 39 }} />
              {t('faq.ai.btn.title')}
            </Button>
          </NavLink>
        </div>
        {isOpenFilter && (
          <ManageFAQControl
            topics={topics}
            getQuestions={() => getQuestions(option)}
            selectedItems={selectedItems}
            isSelectMode={isSelectMode}
            setIsSelectMode={setIsSelectMode}
            onTableChange={onTableChange}
            changeSelectMode={changeSelectMode}
          />
        )}
        <TableMobile
          data={data}
          isLoading={fetchingData}
          loadMore={loadMore}
          option={option}
          itemsLength={questions.length}
          totalItems={pagination.total}
        />
        {isOpenDelete && <DeleteForm setClose={setCloseDelete} isOpen={isOpenDelete} id={currentId} />}
      </>
    );
  }

  return (
    <Spin spinning={fetchingData}>
      <ManageFAQControl
        topics={topics}
        getQuestions={() => getQuestions(option)}
        selectedItems={selectedItems}
        isSelectMode={isSelectMode}
        setIsSelectMode={setIsSelectMode}
        onTableChange={onTableChange}
        changeSelectMode={changeSelectMode}
      />
      <div className={css['faq-table-container']}>
        <Table
          className="consumers-info-table"
          rowClassName="common-table-row--pointer"
          rowKey="id"
          size="middle"
          scroll={{ y: windowHeight - 280, x: 1000 }}
          onChange={onTableChange}
          showHeader={true}
          pagination={pagination}
          columns={getColumns({ t, setOpenDelete, isSelectMode, setDeletedItems, selectedItems, setAllDeletedItems })}
          dataSource={questions}
        />
      </div>
      {isOpenDelete && <DeleteForm setClose={setCloseDelete} isOpen={isOpenDelete} id={currentId} />}
    </Spin>
  );
};

export default ManageFAQ;
