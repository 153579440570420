import HttpService from './common/httpService';
import config from '../config';
import { ConfirmedPasswordModel, RegisterModel } from '../models/RegisterModel';

class RegisterPageServices {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public checkToken(token: string) {
    return this.httpService.post(`${config.baseRequest}auth/confirmToken`, { token });
  }
  public registerUser(user: RegisterModel) {
    return this.httpService.post(`${config.baseRequest}auth/register`, user);
  }

  public confirmNewPass(body: ConfirmedPasswordModel, token: string) {
    return this.httpService.post(`${config.baseRequest}auth/restorePassword/confirm?token=${token}`, body);
  }
}

export default RegisterPageServices;
