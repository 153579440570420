import { Button, Drawer, Form, Input, notification, Select, Spin, Table } from 'antd';
import React, { useEffect, useMemo, useState, useContext } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import config from '../../../../config';
import css from '../../../../assets/styles/budget.module.css';
import BudgetService from '../../../../services/BudgetService';
import { useTranslation } from 'react-i18next';
import ConfirmPaidForm from './ConfirmPaidForm';
import moment from 'moment';
import arrowIcon from '../../../../assets/images/ic-arrow-back.svg';
import { getColumns } from './InvoiceStatusColumns';
import { getStorageToken } from '../../../../helpers/storageTools';
import { AppContext } from '../../../../contexts/AppContextProvider';
import { useLessThen801 } from '../../../../helpers/mediaDetect';
import CustomSignFlow from '../../../../services/CustomSignFlow';
import { IFlow, IFlowStep, ISignatoryFlow } from '../../../../models/CustomSignFlowModel';
import { generateFilterfromObj } from '../../../../helpers/generateFIlterfromObj';
import { FilterTypes } from '../../../../constants/filterTypes';
import { useParams } from 'react-router';
import UsersService from '../../../../services/UsersService';
import { ConsumerModel } from '../../../../models/ConsumerModel';
import { setTextColorByStatus } from '../../../../helpers/setTextColorByStatus';
import { StatusesDocumentsModel } from '../../../../models/DocumentsModel';
import { BudgetModel, UpdatedBudgetModel } from '../../../../models/BudgetModel';
import { IUserEasy } from '../../../../models/UserModel';
import { IResponceData } from '../../../../typings/IServiceOptions';
import { IAppContext } from '../../../../typings/IApp';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface InvoiceProps {
  id: number | null;
  isOpen: boolean;
  setCloseStatus: () => void;
  setCurrentInvoice?: (v: number | null) => void;
  setIsNeedUpdate?: (v: boolean) => void;
  currentInvoice?: number | null;
  propertyId: number;
}
const service = new BudgetService();
const serviceFlow = new CustomSignFlow();
const userService = new UsersService();

const { TextArea } = Input;

const InvoiceStatusForm = (props: InvoiceProps) => {
  const { id, isOpen, setCloseStatus, setCurrentInvoice, currentInvoice, setIsNeedUpdate, propertyId } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const params: { projectId: string } = useParams();
  const { projectId } = params;
  const [invoice, setInvoice] = useState<BudgetModel | null>(null);
  const [statuses, setStatuses] = useState<StatusesDocumentsModel>([]);
  const [fetchingData, setFetchingData] = useState(false);
  const [paidAt, setPaidAt] = useState<string | null>(null);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [isRejectCancel, setIsRejectCancel] = useState(false);
  const [isDisabledStatus, setIsDisabledStatus] = useState(true);
  const [flows, setFlows] = useState<IFlow[]>([]);
  const [flowSteps, setFlowSteps] = useState<IFlowStep[]>([]);
  const [currency, setCurrency] = useState<string>(t('budget.currency'));

  const [consumers, setConsumers] = useState<ConsumerModel[]>([]);
  const [agents, setAgents] = useState<IUserEasy[]>([]);
  const [currentSelectedUsers, setCurrentSelectedUsers] = useState<IUserEasy[]>([]);
  const [searchValue, setSearchValue] = useState<null | string>(null);
  const [currentTimeout, setCurrentTimeout] = useState<NodeJS.Timeout | null>(null);
  const [isLastAgentsReq, setIsLastAgentsReq] = useState(false);
  const [fetchingUserData, setFetchingUserData] = useState(false);
  const [paginationAgents, setPaginationAgents] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    skip: 0,
  });

  const {
    app: { isConsumer },
  } = useContext<IAppContext>(AppContext);

  useEffect(() => {
    setFetchingData(true);
    serviceFlow
      .getStatuses({
        filters: generateFilterfromObj({
          'doc_types_statuses/typeCode': {
            type: FilterTypes.SELECT,
            value: 'invoices',
          },
        }),
      })
      .then((statusesInfo: StatusesDocumentsModel) => {
        setStatuses(statusesInfo);
        getConsumers();
        if (id || currentInvoice) {
          service.getBudgetInfoById((id || currentInvoice) as number).then((value: BudgetModel) => {
            setCurrency(value?.currency?.description || t('budget.currency'));
            serviceFlow
              .getFlows(
                {
                  filters: generateFilterfromObj({
                    'projects/id': {
                      type: FilterTypes.SELECT,
                      value: +projectId,
                    },
                    'document_types/code': {
                      type: FilterTypes.SELECT,
                      value: 'invoices',
                    },
                  }),
                },
                value.flow ? value.flow.id : undefined,
              )
              .then((flows: IFlow | IFlow[]) => {
                const isArrayFlows = Array.isArray(flows);
                setFlows((isArrayFlows ? flows : [flows]) as IFlow[]);
                getAgents(
                  value?.signatories
                    ?.filter((item) => item.stepCode.split('_').includes('agent'))
                    .map((item) => item.consumerId),
                );
                let fieldsValues: { [name: string]: any } = {
                  status: value.statusV2.code,
                  description: value.description,
                  comment: value.comment,
                  isAmendment: false,
                  flow: value.flow ? value.flow.id : '',
                };
                (
                  (isArrayFlows ? (flows as IFlow[]).find((item) => item.id === value.flow?.id) : flows) as IFlow
                )?.flowsSteps
                  .map((item) => ({
                    stepCode: item.stepCode,
                    signatoriesName: item.step.signatoriesName,
                  }))
                  .forEach((item) => {
                    fieldsValues[item.stepCode] = value.signatories
                      .filter((sign) => sign.stepCode === item.stepCode)
                      .map((item) => item.consumerId);
                  });
                form.setFieldsValue(fieldsValues);
                if (value.flow && value.flow.id) {
                  setFlowSteps(
                    (
                      (isArrayFlows ? (flows as IFlow[]).find((item) => item.id === value.flow?.id) : flows) as IFlow
                    )?.flowsSteps.map((item) => ({
                      signatoriesName: item.step.signatoriesName,
                      code: item.step.code,
                    })) || [],
                  );
                }
                setIsDisabledStatus(
                  value.flow === null ? ['rejected', 'done', 'canceled', 'signed'].includes(value.statusV2.code) : true,
                );
                setIsRejectCancel(['rejected', 'done', 'canceled', 'signed'].includes(value.statusV2.code));
                setInvoice(value);
                if (value.paidAt) {
                  setPaidAt(value.paidAt);
                }
              })
              .catch((e) => {
                notification.error({
                  message: e.message,
                });
                setCloseStatus();
              });
          });
        } else {
          serviceFlow
            .getFlows({
              filters: generateFilterfromObj({
                'projects/id': {
                  type: FilterTypes.SELECT,
                  value: +projectId,
                },
                'document_types/code': {
                  type: FilterTypes.SELECT,
                  value: 'invoices',
                },
              }),
            })
            .then((flows: IFlow[]) => {
              setFlows(flows);
              const defaultFlowId = flows.find((item) => item.isDefault)?.id;
              form.setFieldsValue({ flow: defaultFlowId || '' });
              setFlowSteps(
                flows
                  .find((item) => item.id === defaultFlowId)
                  ?.flowsSteps.map((item) => ({ signatoriesName: item.step.signatoriesName, code: item.step.code })) ||
                  [],
              );
              getAgents();
            });
        }
      })
      .catch((e) => {
        notification.error({
          message: e.message,
        });
        setCloseStatus();
      })
      .finally(() => setFetchingData(false));
  }, []);

  useEffect(() => {
    if (searchValue === '') {
      return getAgents([], currentSelectedUsers);
    }
    if (searchValue) {
      getAgents();
    }
  }, [searchValue]);

  const getAgents = (agentsId?: number[], agentsRes: IUserEasy[] = []) => {
    if (agentsId && agentsId.length > 0) {
      userService
        .getEasyUsers({ userId: agentsId })
        .then((res: IUserEasy[]) => {
          setCurrentSelectedUsers(res);
          getAgents([], res);
        })
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        );
      return;
    }
    const optionUser = {
      count: true,
      top: paginationAgents.pageSize,
      current: paginationAgents.current,
      skip: paginationAgents.pageSize * (paginationAgents.current - 1) || 0,
      fullName: searchValue || '',
      projectId: [projectId],
      roles: ['agent', 'admin', 'super_agent'],
    };

    setFetchingUserData(true);
    userService
      .getEasyUsers(optionUser)
      .then((res: IResponceData<IUserEasy>) => {
        const { count, items } = res;
        const { current, top } = optionUser;
        setPaginationAgents({ ...paginationAgents, current: current + 1, total: count, pageSize: top });
        let agentsId: number[] = [];
        if (agents.length > 0) {
          agentsId = agents.map((agent) => agent.id);
        } else if (agentsRes.length > 0) {
          agentsId = agentsRes.map((agent) => agent.id);
        }
        setAgents(
          [...agents, ...agentsRes, ...items.filter((item) => !agentsId.includes(item.id))].sort((a, b) =>
            a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()),
          ),
        );
        setIsLastAgentsReq(current * top >= count);
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingUserData(false));
  };

  const getConsumers = () => {
    userService
      .getConsumersByProperty(propertyId)
      .then((value: ConsumerModel[]) => setConsumers(value))
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      );
  };

  const onSubmit = (value: any) => {
    setFetchingData(true);
    const body: { [name: string]: any } = {
      id: invoice!.id,
      description: value.description,
      statusV2: {
        code: value.status,
      },
      paidAt: paidAt ? moment(paidAt).format('YYYY-MM-DD') : null,
      comment: value.comment,
    };

    const signatiriesFlow: ISignatoryFlow[] = [];
    const currentFlow = flows
      ?.find((item) => value.flow && item.id === +value.flow)
      ?.flowsSteps?.sort((a, b) => a.ord - b.ord);

    currentFlow &&
      currentFlow.forEach((item) => {
        value[item.step.code].forEach((id: number) => {
          signatiriesFlow.push({ consumerId: id, stepCode: item.stepCode });
        });
      });

    if (signatiriesFlow.length > 0) {
      body['signatories'] = signatiriesFlow;
    }

    service
      .updateInvoice(body as UpdatedBudgetModel)
      .then(() => {
        setCloseStatus();
        notification.success({
          message: t('budget.modal.update.success'),
        });
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => {
        setFetchingData(false);
        setCurrentInvoice && setCurrentInvoice(null);
        setIsNeedUpdate && setIsNeedUpdate(true);
      });
  };

  const changeStatus = (value: string) => {
    if (value === 'paid') {
      setIsOpenConfirm(true);
    } else {
      setPaidAt(null);
    }
    const isCancel = ['rejected', 'canceled'].includes(value);
    setIsRejectCancel(isCancel);
    if (isCancel) {
      form.setFields([{ name: 'comment', value: null }]);
    }
  };

  const onDocumentLoad = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const file = useMemo(
    () => ({
      url: `${config.baseRequest}file/binary/${invoice?.file}`,
      httpHeaders: {
        Authorization: 'Bearer ' + getStorageToken(),
      },
    }),
    [invoice],
  );

  const onSelectFlow = (value: number | '') => {
    form.setFieldsValue({ status: 'new' });
    setFlowSteps(
      flows
        .find((item) => item.id === value)
        ?.flowsSteps.map((item) => ({ signatoriesName: item.step.signatoriesName, code: item.step.code })) || [],
    );
  };

  return (
    <Drawer className="upload-form-drawer" visible={isOpen} placement="top" closable={false}>
      {isOpenConfirm && (
        <ConfirmPaidForm isOpen={isOpenConfirm} setIsOpen={setIsOpenConfirm} paidAt={paidAt} setPaidAt={setPaidAt} />
      )}
      <Spin spinning={fetchingData}>
        <Form autoComplete="off" onFinish={onSubmit} form={form} layout="vertical">
          <Form.Item>
            <div className={css['btns-container']}>
              <Button
                className={`${css['return-btn']} common-secondary-btn`}
                onClick={() => setCloseStatus()}
                disabled={fetchingData}>
                {t('common.return.btn')}
              </Button>
              <Button
                className={`${css['upload-btn']} common-primary-btn`}
                htmlType="submit"
                disabled={fetchingData}
                style={{ width: 'auto', marginLeft: '1rem' }}>
                {t('common.btn.save')}
              </Button>
            </div>
          </Form.Item>
          <div
            className={css['content-container']}
            style={{ padding: useLessThen801() ? '0' : '0 3rem', justifyContent: 'space-around' }}>
            {invoice && (
              <div
                className={css['document-control-container']}
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    disabled={pageNumber === 1}
                    onClick={() => setPageNumber(pageNumber - 1)}
                    style={{ border: 'none' }}>
                    <img src={arrowIcon} alt="" style={{ height: '15px', width: '15px' }} />
                  </Button>
                  <span>
                    {pageNumber}/{numPages || '1'}
                  </span>
                  <Button
                    disabled={pageNumber === numPages}
                    onClick={() => setPageNumber(pageNumber + 1)}
                    style={{ border: 'none' }}>
                    <img
                      src={arrowIcon}
                      alt=""
                      style={{ transform: 'rotate(180deg)', height: '15px', width: '15px' }}
                    />
                  </Button>
                </div>
                <Document
                  className="document-pdf"
                  file={file}
                  onLoadSuccess={onDocumentLoad}
                  onLoadError={console.error}>
                  <Page pageNumber={pageNumber} />
                </Document>
              </div>
            )}
            <div
              className={css['upload-steps-item']}
              style={{ backgroundColor: '#fff', padding: '1rem', marginTop: '2rem' }}>
              <Form.Item
                name="status"
                label={<span style={{ color: '#778dac' }}>{t('properties.table.columns.status')}</span>}
                colon={false}>
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
                  onSelect={changeStatus}
                  suffixIcon={
                    <div
                      className={`arrow-drop-img ${css['arrow-drop']}`}
                      style={{ width: '8px', height: '4px' }}></div>
                  }
                  className="common-select common-animation-primary"
                  disabled={!id || isDisabledStatus}>
                  {statuses.length > 0 &&
                    statuses
                      .filter((status) =>
                        invoice && invoice.flow === null
                          ? status.docTypesStatuses.find((item) => item.typeCode === 'invoices')?.isManual
                          : true,
                      )
                      .map((item) => (
                        <Select.Option key={item.code} value={item.code}>
                          <span style={{ color: `${setTextColorByStatus(item.code)}` }}>{item.description}</span>
                        </Select.Option>
                      ))}
                </Select>
              </Form.Item>
              {!isConsumer && (
                <>
                  <span style={{ marginBottom: '0.5rem', color: '#778dac' }}>{t('contract.flow.title')}</span>
                  <Form.Item name="flow" colon={false} className={css['form-item']} initialValue={''} labelAlign="left">
                    <Select
                      className="common-select common-animation-primary"
                      suffixIcon={
                        <div
                          className={`arrow-drop-img ${css['arrow-drop']}`}
                          style={{ width: '8px', height: '4px' }}></div>
                      }
                      style={{ marginTop: '0' }}
                      disabled={true}
                      onSelect={onSelectFlow}>
                      <Select.Option value={''}>{t('contract.flow.noFlow')}</Select.Option>
                      {flows.length > 0 &&
                        flows.map((item) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                  {flowSteps.length > 0 &&
                    flowSteps.map((item) => (
                      <div key={item.code}>
                        <span style={{ marginBottom: '0.5rem', color: '#778dac' }}>{`${item.signatoriesName} *`}</span>
                        <Form.Item
                          name={item.code}
                          colon={false}
                          rules={[{ required: !invoice, message: t('common.validation.required') }]}
                          className={css['form-item']}
                          labelAlign="left">
                          <Select
                            className="common-select common-animation-primary"
                            mode="multiple"
                            disabled={true}
                            onDropdownVisibleChange={(value) => {
                              if (item.code.split('_').includes('agent') && !value && searchValue) {
                                setAgents([]);
                                setPaginationAgents({ ...paginationAgents, current: 1, skip: 0 });
                                setSearchValue('');
                              }
                            }}
                            onSearch={(value) => {
                              if (item.code.split('_').includes('agent')) {
                                setAgents([]);
                                setPaginationAgents({ ...paginationAgents, current: 1, skip: 0 });
                                clearTimeout(Number(currentTimeout));
                                setCurrentTimeout(setTimeout(() => setSearchValue(value), 1000));
                              }
                            }}
                            filterOption={(value, option) =>
                              item.code.split('_').includes('agent')
                                ? true
                                : option?.children?.toLowerCase().indexOf(value.toLowerCase()) >= 0
                            }
                            dropdownClassName={`documents-control-select-dropdown ${
                              fetchingUserData ? 'consumers-control-select-dropdown--progress' : ''
                            }`}
                            onPopupScroll={(e: any) => {
                              e.persist();
                              let target = e.target;
                              if (
                                item.code.split('_').includes('agent') &&
                                !fetchingUserData &&
                                !isLastAgentsReq &&
                                target.scrollTop + target.offsetHeight === target.scrollHeight
                              ) {
                                getAgents();
                              }
                            }}
                            suffixIcon={
                              <div
                                className={`arrow-drop-img ${css['arrow-drop']}`}
                                style={{ width: '8px', height: '4px' }}></div>
                            }>
                            {item.code.split('_').includes('agent')
                              ? agents.length > 0 &&
                                agents.map((signatory) => (
                                  <Select.Option key={signatory.id} value={signatory.id}>
                                    {`${signatory.firstName} ${signatory.lastName}`}
                                  </Select.Option>
                                ))
                              : consumers.length > 0 &&
                                consumers
                                  .sort((a, b) => a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()))
                                  .map((signatory) => (
                                    <Select.Option key={signatory.id} value={signatory.id}>
                                      {`${signatory.firstName} ${signatory.lastName}`}
                                    </Select.Option>
                                  ))}
                          </Select>
                        </Form.Item>
                      </div>
                    ))}
                </>
              )}
              <Form.Item
                name="description"
                colon={false}
                label={<span style={{ color: '#778dac' }}>{t('roles.table.columns.description')}</span>}>
                <TextArea className="consumer-form-input common-animation-primary" disabled={isRejectCancel} />
              </Form.Item>
              <span key="label-description" style={{ color: '#778dac', paddingBottom: '0.5rem' }}>
                {t('budget.form.sign.comments')}
              </span>
              <Form.Item key="comment" name="comment" colon={false}>
                <TextArea
                  className="consumer-form-input common-animation-primary"
                  autoSize={{ minRows: 2, maxRows: 3 }}
                  disabled={isRejectCancel}
                />
              </Form.Item>
              {paidAt && <span>{`Paid at ${moment(paidAt).format('DD.MM.YYYY')}`}</span>}
              <div style={{ marginTop: '1rem', display: 'flex', flexDirection: 'column' }}>
                <span style={{ color: '#778dac' }}>{`${t('budget.upload.overview.fields.invoice')}: ${
                  invoice?.number || ''
                }`}</span>
                <span style={{ color: '#778dac' }}>{`${t('budget.upload.overview.fields.date')}: ${
                  moment(invoice?.invoiceDate).format('DD.MM.YYYY') || ''
                }`}</span>
                <Table
                  style={{ marginTop: '1rem' }}
                  columns={getColumns({ t })}
                  dataSource={invoice?.items}
                  size="small"
                  className="list-info-table"
                  showHeader={true}
                  pagination={false}
                  rowClassName="common-table-row--pointer"
                  rowKey="id"
                />
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1rem' }}>
                  <span style={{ color: '#778dac' }}>{`${t('budget.invoice.form.subtotal.1')}: ${currency || ''} ${
                    invoice?.sum ? (invoice.sum / 100).toFixed(2) : ''
                  }`}</span>
                  <span style={{ color: '#778dac' }}>{`${t('budget.upload.overview.fields.fees')}: ${
                    invoice?.fees || ''
                  } %`}</span>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ color: '#778dac' }}>{t('budget.invoice.form.subtotal.2')}</span>
                    <span style={{ color: '#778dac' }}>{`${currency || ''} ${
                      invoice?.sumWithFees ? (invoice.sumWithFees / 100).toFixed(2) : ''
                    }`}</span>
                  </div>
                  <span style={{ color: '#778dac' }}>{`${t('budget.upload.overview.fields.vat')}: ${
                    invoice?.vat || ''
                  } %`}</span>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ color: '#778dac' }}>{t('budget.invoice.form.total')}</span>
                    <span style={{ color: '#778dac' }}>{`${currency || ''} ${
                      invoice?.sumWithVat ? (invoice.sumWithVat / 100).toFixed(2) : ''
                    }`}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Spin>
    </Drawer>
  );
};
export default InvoiceStatusForm;
