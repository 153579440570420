import React, { useContext, useEffect, useState } from 'react';
import { Spin, List, Card, Pagination, Button, Skeleton, TablePaginationConfig } from 'antd';
import { AppContext } from '../../../contexts/AppContextProvider';
import { generateFilterfromObj } from '../../../helpers/generateFIlterfromObj';
import GalleryControl from './GalleryControl';
import ImageCard from './ImageCard';
import { formatDate } from '../../../helpers/dateTools';
import { FiltersContext } from '../../../contexts/FiltersContextProvider';
import { FilterTypes } from '../../../constants/filterTypes';
import { useLessThen801 } from '../../../helpers/mediaDetect';
import { useTranslation } from 'react-i18next';
import filterIcon from '../../../assets/images/ic-filter.svg';
import filterActiveIcon from '../../../assets/images/ic-filter-active.svg';
import linkIcon from '../../../assets/images/ic-link.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import ModalUploadFileSuccess from '../../../components/common/Modals/ModalUploadFileSuccess';
import CalendarService from '../../../services/CalendarServices';
import { CalendarTypes, IPost } from '../../../models/CalendarModel';
import css from '../../../assets/styles/gallery.module.css';
import PostModalForm from '../../Posts/components/PostModalForm';
import PostConfirmModal from '../../Posts/components/PostConfirmModal';
import { IResponceData } from '../../../typings/IServiceOptions';
import { isEmptyObject } from '../../../helpers/isEmptyObject';
import { FilterValue } from 'antd/lib/table/interface';
import { IAppContext } from '../../../typings/IApp';

const service = new CalendarService();

interface GalleryListInfoProps {
  propertyId: number;
  projectId?: number;
}

const GalleryListInfo = (props: GalleryListInfoProps) => {
  const {
    filters: { galleryFilter },
  } = useContext(FiltersContext);
  const { t } = useTranslation();
  const { propertyId, projectId } = props;
  const [selectPostItem, setSelectPostItem] = useState<null | number>(null);
  const [isOpenModalDeletePost, setIsOpenModalDeletePost] = useState(false);
  const [galleryInfoPosts, setGalleryInfoPosts] = useState<IPost[]>([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenUploadMessageModal, setIsOpenUploadMessageModal] = useState(false);
  const [currentFilters, setCurrentFilters] = useState({
    'type/code': {
      type: FilterTypes.SELECT,
      value: CalendarTypes.image,
    },
    'properties/id': {
      type: FilterTypes.SELECT,
      value: propertyId,
    },
    date: {
      type: FilterTypes.DATE_RANGE,
      value: galleryFilter.dateRange,
    },
  });
  const {
    app: { isConsumer, currentProperty },
  } = useContext<IAppContext>(AppContext);
  const [fetchingData, setFetchingData] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    skip: 0,
  });

  const option = {
    count: true,
    filter: generateFilterfromObj(currentFilters).filter,
    top: pagination.pageSize,
    current: pagination.current,
    skip: 0,
    orderBy: ['date desc'],
  };

  const applyNewFilter = (filters: { [name: string]: any }) => {
    setCurrentFilters({ ...currentFilters, ...filters });
    return { ...currentFilters, ...filters };
  };

  useEffect(() => {
    if (isConsumer) {
      const body = {
        'properties/id': {
          type: FilterTypes.SELECT,
          value: currentProperty.id,
        },
      };
      onChangeControl(undefined, body);
    } else {
      getCalendarGallery(option);
    }
  }, [currentProperty]);

  const getCalendarGallery = async (option: { [name: string]: any }) => {
    setFetchingData(true);
    await service
      .getCalendarUpdates(option)
      .then((res: IResponceData<IPost>) => {
        const { count, items } = res;
        const { current, top } = option;
        setPagination({ ...pagination, current, total: count, pageSize: top });
        setGalleryInfoPosts(items);
      })
      .catch((e) => console.log(e))
      .finally(() => setFetchingData(false));
  };

  const onChangeControl = (pagination?: TablePaginationConfig, filters?: Record<string, FilterValue | null | any>) => {
    let params: { [name: string]: any } = {
      ...option,
    };

    if (filters && !isEmptyObject(filters)) {
      params = {
        ...params,
        filter: generateFilterfromObj(applyNewFilter(filters)).filter,
      };
    } else {
      params = {
        ...params,
        filter: generateFilterfromObj(currentFilters).filter,
      };
    }
    if (pagination && pagination.pageSize && pagination.current) {
      params = {
        ...params,
        top: pagination.pageSize,
        skip: pagination.pageSize * (pagination.current - 1),
        current: pagination.current,
      };
    } else {
      params = {
        ...params,
        skip: 0,
        current: 1,
      };
    }
    getCalendarGallery(params);
  };

  const onChangePagination = (page: number, pageSize: number) => {
    onChangeControl({ current: page, pageSize });
  };

  const changeOpenFilter = () => {
    setIsOpenFilter(!isOpenFilter);
  };

  const loadMore = () => {
    setFetchingData(true);
    option.skip = option.skip ? option.skip + pagination.pageSize : pagination.pageSize;
    service
      .getCalendarUpdates(option)
      .then((res: IResponceData<IPost>) => {
        const { count, items } = res;
        const { current, top } = option;
        setPagination({ ...pagination, current, total: count, pageSize: top });
        setGalleryInfoPosts([...galleryInfoPosts, ...items]);
      })
      .catch((e) => console.log(e))
      .finally(() => setFetchingData(false));
  };

  if (useLessThen801()) {
    return (
      <>
        <div className="common-mobile-title-container">
          <span style={{ fontSize: '20px' }}>{t('consumers.manage.tabs.gallery')}</span>
          <div className="common-modbile-filter-container" onClick={changeOpenFilter}>
            <img src={isOpenFilter ? filterActiveIcon : filterIcon} width={16} />
            <span className="filter-title">{isOpenFilter ? t('common.filter.close') : t('common.filter.open')}</span>
          </div>
        </div>
        <div>
          {!isConsumer && (
            <Button
              className={`common-blue-btn common-primary-btn`}
              onClick={() => setIsOpenModal(true)}
              style={{ width: '100%', marginBottom: '1rem' }}>
              <span>{t('gallery.upload.images')}</span>
            </Button>
          )}
        </div>
        {isOpenFilter && (
          <GalleryControl setIsOpenModal={setIsOpenModal} onChangeControl={onChangeControl} isConsumer={isConsumer} />
        )}
        <InfiniteScroll
          dataLength={galleryInfoPosts.length}
          next={loadMore}
          hasMore={galleryInfoPosts.length < pagination.total}
          loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
          scrollableTarget="scrollableDiv">
          <List
            dataSource={galleryInfoPosts}
            renderItem={(item) => (
              <List.Item key={item.id}>
                <Card
                  title={
                    <div className={css['gallery-card-title']}>
                      <div style={{ display: 'flex' }}>
                        <span style={{ marginRight: '0.5rem' }}>{formatDate(item.date, 'MMMM YYYY', true)}</span>
                        <span style={{ marginRight: '0.5rem' }}>{item.description}</span>
                        <img style={{ marginRight: '1rem' }} src={linkIcon} />
                      </div>
                      {!isConsumer && (
                        <div className={css['gallery-card-btns']}>
                          <Button
                            onClick={() => {
                              setSelectPostItem(item.id);
                              setIsOpenModal(true);
                            }}
                            className={css['gallery-card-edit']}
                            style={{ marginRight: '1rem' }}>
                            <div className={css['edit-gallery-img']} style={{ height: '15px', width: '15px' }} />
                          </Button>
                          <Button
                            onClick={() => {
                              setSelectPostItem(item.id);
                              setIsOpenModalDeletePost(true);
                            }}
                            className={css['gallery-card-delete']}>
                            <span className={css['gallery-card-delete-icon']}></span>
                          </Button>
                        </div>
                      )}
                    </div>
                  }
                  style={{ width: '100%' }}
                  bodyStyle={{
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}>
                  {item.files?.map((el) => (
                    <ImageCard key={el.id} imageInfo={el} />
                  ))}
                </Card>
              </List.Item>
            )}
          />
        </InfiniteScroll>
        {isOpenUploadMessageModal && (
          <ModalUploadFileSuccess isOpen={isOpenUploadMessageModal} onOk={() => setIsOpenUploadMessageModal(false)} />
        )}
        {isOpenModal && (
          <PostModalForm
            setIsOpenUploadMessageModal={setIsOpenUploadMessageModal}
            isOpenModal={isOpenModal}
            defaultType={CalendarTypes.image}
            postId={selectPostItem}
            projectId={projectId}
            propertyId={propertyId}
            getCalendarUpdates={() => getCalendarGallery(option)}
            closeModal={() => {
              setIsOpenModal(false);
              setSelectPostItem(null);
            }}
          />
        )}
        {isOpenModalDeletePost && (
          <PostConfirmModal
            currentId={selectPostItem}
            isOpen={isOpenModalDeletePost}
            setClose={() => {
              setIsOpenModalDeletePost(false);
              getCalendarGallery(option);
            }}
          />
        )}
      </>
    );
  }

  return (
    <div style={{ width: '100%' }}>
      <Spin spinning={fetchingData}>
        <GalleryControl setIsOpenModal={setIsOpenModal} onChangeControl={onChangeControl} isConsumer={isConsumer} />
        <div style={{ height: '90%' }}>
          <List
            style={{ height: '95%', overflowY: 'auto' }}
            dataSource={galleryInfoPosts}
            renderItem={(item) => (
              <List.Item key={item.id}>
                <Card
                  title={
                    <div className={css['gallery-card-title']}>
                      <span style={{ marginRight: '0.5rem' }}>{formatDate(item.date, 'MMMM YYYY', true)}</span>
                      <span style={{ marginRight: '0.5rem' }}>{item.description}</span>
                      <img style={{ marginRight: '1rem' }} src={linkIcon} />
                      {!isConsumer && (
                        <>
                          <Button
                            onClick={() => {
                              setSelectPostItem(item.id);
                              setIsOpenModal(true);
                            }}
                            className={css['gallery-card-edit']}
                            style={{ marginRight: '1rem' }}>
                            <div className={css['edit-gallery-img']} style={{ height: '15px', width: '15px' }} />
                          </Button>
                          <Button
                            onClick={() => {
                              setSelectPostItem(item.id);
                              setIsOpenModalDeletePost(true);
                            }}
                            className={css['gallery-card-delete']}>
                            <span className={css['gallery-card-delete-icon']}></span>
                          </Button>
                        </>
                      )}
                    </div>
                  }
                  style={{ width: '100%' }}
                  bodyStyle={{
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}>
                  {item.files?.map((el) => (
                    <ImageCard key={el.id} imageInfo={el} />
                  ))}
                </Card>
              </List.Item>
            )}
          />
          <div className="gallery-pagination">
            <Pagination
              size="small"
              style={{ float: 'right', marginTop: '0.5rem' }}
              current={pagination.current}
              showSizeChanger
              onChange={onChangePagination}
              pageSize={pagination.pageSize}
              total={pagination.total}
            />
          </div>
        </div>
        {isOpenUploadMessageModal && (
          <ModalUploadFileSuccess isOpen={isOpenUploadMessageModal} onOk={() => setIsOpenUploadMessageModal(false)} />
        )}
        {isOpenModal && (
          <PostModalForm
            setIsOpenUploadMessageModal={setIsOpenUploadMessageModal}
            isOpenModal={isOpenModal}
            defaultType={CalendarTypes.image}
            postId={selectPostItem}
            getCalendarUpdates={() => getCalendarGallery(option)}
            projectId={projectId}
            propertyId={propertyId}
            closeModal={() => {
              setIsOpenModal(false);
              setSelectPostItem(null);
            }}
          />
        )}
        {isOpenModalDeletePost && (
          <PostConfirmModal
            currentId={selectPostItem}
            isOpen={isOpenModalDeletePost}
            setClose={() => {
              setIsOpenModalDeletePost(false);
              getCalendarGallery(option);
            }}
          />
        )}
      </Spin>
    </div>
  );
};

export default GalleryListInfo;
