import { Button, notification, Spin } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../assets/styles/documents.module.css';
import DocumentsService from '../../../services/DocumentsService';

interface DeleteConfirmFormProps {
  isOpen: boolean;
  currentId: number;
  getDocuments: () => void;
  setClose: () => void;
  documentNumber: string | null;
}

const service = new DocumentsService();

const DeleteDocumensForm = (props: DeleteConfirmFormProps) => {
  const { currentId, isOpen, setClose, getDocuments, documentNumber } = props;
  const [fetchingData, setFetchingData] = useState(false);
  const { t } = useTranslation();

  const deleteDocumens = () => {
    setFetchingData(true);
    service
      .deleteDocuments(currentId)
      .then(() => {
        setClose();
        notification.success({
          message: t('documents.modal.delete.success.message'),
        });
        getDocuments();
      })
      .catch((e) =>
        notification.error({
          message: e,
        }),
      )
      .finally(() => setFetchingData(false));
  };

  return (
    <Spin spinning={fetchingData}>
      <Modal
        className="common-modal"
        centered
        style={{ width: '10rem' }}
        visible={isOpen}
        footer={
          <div className="common-modal-footer">
            <Button
              className={`common-gray-border-btn common-secondary-btn`}
              disabled={fetchingData}
              onClick={() => setClose()}>
              {t('documents.modal.cancel.btn')}
            </Button>
            <Button
              className={`common-green-btn common-modal-btn`}
              disabled={fetchingData}
              onClick={() => deleteDocumens()}>
              {t('documents.modal.delete.btn')}
            </Button>
          </div>
        }
        closable={false}>
        <span className={css['delete-documents-title']}>{`${t(
          'documents.modal.confirm.delete.message',
        )} ${documentNumber}?`}</span>
      </Modal>
    </Spin>
  );
};

export default DeleteDocumensForm;
