import { Button, Col, Form, FormInstance, Input, InputNumber, notification, Row, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import css from '../../../../../assets/styles/budget.module.css';
import deleteBtnIcon from '../../../../../assets/images/ic-delete-blue.svg';
import addIcon from '../../../../../assets/images/ic-plus-blue.svg';
import uploadIcon from '../../../../../assets/images/ic-upload-blue.svg';
import linkIcon from '../../../../../assets/images/ic-link-blue.svg';
import deleteIcon from '../../../../../assets/images/ic-btn-close-grey.svg';
import { useTranslation } from 'react-i18next';
import SelectTableModal from '../SelectTableModal';
import { DocumentsModel } from '../../../../../models/DocumentsModel';
import {
  convertToCoins,
  convertToCurrency,
  convertToPriceWithSpace,
  reverseConvertToPriceWithSpace,
} from '../../../../../helpers/convertToPrice';
import AmendmentService from '../../../../../services/AmendmentService';
import { generateFilterfromObj } from '../../../../../helpers/generateFIlterfromObj';
import { FilterTypes } from '../../../../../constants/filterTypes';
import ContractsService from '../../../../../services/ContractsService';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { IAmendmentModel } from '../../../../../models/AmendmentModel';

interface IFinalAccountContractAndAmendments {
  form: FormInstance;
  propertyId: string | null;
  setFileContract: (f: UploadFile[]) => void;
  fileAmedmentsList: UploadFile[];
  setFileAmedmentsList: (f: UploadFile[]) => void;
  currency: string;
}

interface IItem {
  id?: number;
  name: null | string;
  sumWithFees: number;
  sumWithVat: number;
  isReadOnly?: boolean;
  fakeName: null | string;
  uid?: string;
  price?: number;
}

const contractsService = new ContractsService();
const amendmentService = new AmendmentService();

const FinalAccountContractAndAmendments = ({
  form,
  propertyId,
  setFileContract,
  fileAmedmentsList,
  setFileAmedmentsList,
  currency,
}: IFinalAccountContractAndAmendments) => {
  const { t } = useTranslation();
  const vat = 7.7;
  const [isOpenSelectContract, setIsOpenSelectContract] = useState(false);
  const [isOpenSelectAmendments, setIsOpenSelectAmendments] = useState(false);
  const [itemKey, setItemKey] = useState(0);
  const [contractNameFile, setContractNameFile] = useState<string | null>(null);
  const [amendmentsItems, setAmendmentsItems] = useState<IItem[]>([
    { name: null, sumWithFees: 0, sumWithVat: 0, isReadOnly: true, fakeName: null },
  ]);

  const handleFile = (info: UploadChangeParam<UploadFile<any>>, index: number) => {
    const values = { ...form.getFieldsValue() };

    if (index === -1 && values && values.contract) {
      values.contract.name = info.file.name;
      values.contract.uid = info.file.uid;
      form.setFieldsValue(values);
      setContractNameFile(info.file.name);
      setFileContract(info.fileList);
    }

    if (index > -1) {
      if (values && values.amendments.length > 0) {
        values.amendments[index].name = info.file.name || null;
        values.amendments[index].uid = info.file.uid;
        form.setFieldsValue(values);
      }
      amendmentsItems[index].name = info.file.name;
      amendmentsItems[index].uid = info.file.uid;
      amendmentsItems[index].isReadOnly = false;
      setAmendmentsItems(amendmentsItems);
      setFileAmedmentsList([...fileAmedmentsList, ...info.fileList]);
    }
  };

  const deleteFile = () => {
    const values = { ...form.getFieldsValue() };
    if (values && values.contract) {
      values.contract.id = null;
      values.contract.name = null;
      if (values.contract.uid) {
        setFileContract([]);
      }
      values.contract.uid = null;
      values.contract.sumWithFees = 0;
      values.contract.sumWithVat = 0;
      form.setFieldsValue(values);
      setContractNameFile(null);
    }
    calculateSum();
  };

  const onSaveContract = (ids: number[]) => {
    if (ids.length > 0) {
      contractsService
        .getContractInfoById(ids[0])
        .then((res: DocumentsModel) => {
          setContractNameFile(res.title);
          const values = { ...form.getFieldsValue() };
          values.contract.id = res.id;
          values.contract.name = res.title;
          values.contract.sumWithFees = res.sumWithFees !== null ? convertToCurrency(res.sumWithFees).toFixed(2) : 0;
          values.contract.sumWithVat = res.sumWithVat !== null ? convertToCurrency(res.sumWithVat).toFixed(2) : 0;
          form.setFieldsValue(values);
        })
        .catch((e) => console.log(e));
    }
    setIsOpenSelectContract(false);
  };
  const onSaveAmendments = (ids: number[]) => {
    if (ids.length > 0 && propertyId) {
      const params = {
        filters: generateFilterfromObj({
          id: {
            type: FilterTypes.SELECT_MULTIPLE,
            value: ids,
          },
        }),
        orderBy: ['createdDate desc'],
      };
      amendmentService
        .getAmendments(+propertyId, params)
        .then((res) => {
          const newItems = res.map((item: IAmendmentModel, index: number) => {
            return {
              index: itemKey + index + 1,
              id: item.id,
              name: item.number,
              sumWithFees: item.deltaWithFees || 0,
              sumWithVat: item.deltaWithVat || 0,
              isReadOnly: item.deltaWithFees !== null,
            };
          });
          const lastItem = amendmentsItems && amendmentsItems.length > 0 ? amendmentsItems.pop() : null;
          if (lastItem && lastItem.name !== '' && lastItem.price !== 0) {
            if (amendmentsItems.length === 1) {
              setAmendmentsItems([lastItem, ...newItems]);
            } else {
              setAmendmentsItems(newItems);
            }
          } else {
            setAmendmentsItems([...amendmentsItems, ...newItems]);
          }
        })
        .catch((e) => console.log(e));
    }
    setIsOpenSelectAmendments(false);
  };

  const addItem = () => {
    setAmendmentsItems([
      ...amendmentsItems,
      { name: '', sumWithFees: 0, sumWithVat: 0, isReadOnly: true, fakeName: null },
    ]);
    setItemKey(itemKey + 1);
  };

  const deleteItem = (index: number, uid: undefined | string) => {
    if (index === 0 && amendmentsItems.length === 1) {
      setAmendmentsItems([{ name: '', sumWithFees: 0, sumWithVat: 0, fakeName: null }]);
      setFileAmedmentsList([]);
    } else {
      if (uid) {
        setAmendmentsItems(fileAmedmentsList.filter((item) => item.uid !== uid) as IItem[]);
      }
      const newItems = amendmentsItems.slice();
      const items = newItems.filter((_, itemIndex) => itemIndex !== index);
      const fieldValue = form.getFieldsValue();
      const newFieldsItems = fieldValue.amendments.filter((_: IItem, itemIndex: number) => itemIndex !== index);
      form.setFieldsValue({ ...fieldValue, items: newFieldsItems });
      setAmendmentsItems(items);
    }
  };

  const calculateSum = () => {
    const values = { ...form.getFieldsValue() };
    //currentAmendmentSum
    const sumAmendmentsFees = values.amendments.reduceRight(
      (prev: number, curr: IItem) => (prev += +curr.sumWithFees),
      0,
    );
    const sumAmendmentsVat = values.amendments.reduceRight(
      (prev: number, curr: IItem) => (prev += +curr.sumWithVat),
      0,
    );
    //TotalFullContract
    values.totalFullSum.sumWithFees = +sumAmendmentsFees + +values.contract.sumWithFees;
    values.totalFullSum.sumWithVat = +sumAmendmentsVat + +values.contract.sumWithVat;
    form.setFieldsValue(values);
  };

  useEffect(() => {
    const values = { ...form.getFieldsValue() };
    if (values && values.amendments.length > 0) {
      amendmentsItems.forEach((item, index: number) => {
        values.amendments[index].id = item.id;
        values.amendments[index].name = item.name || null;
        values.amendments[index].sumWithFees = item.sumWithFees
          ? convertToCurrency(item.sumWithFees)
          : item.sumWithFees;
        values.amendments[index].sumWithVat = item.sumWithVat ? convertToCurrency(item.sumWithVat) : item.sumWithVat;
        values.amendments[index].fakeName = item.fakeName || null;
      });
      form.setFieldsValue(values);
    }
    calculateSum();
  }, [amendmentsItems]);

  const setContractPriceFees = () => {
    const values = { ...form.getFieldsValue() };
    const sumWithVat = convertToCurrency(
      Math.floor(
        convertToCoins(values.contract.sumWithFees) + (vat / 100) * convertToCoins(values.contract.sumWithFees),
      ),
    );
    values.contract.sumWithVat = sumWithVat;
    form.setFieldsValue(values);
    calculateSum();
  };

  const setAmendmentPriceFees = (index: number) => {
    const values = { ...form.getFieldsValue() };
    const sumWithVat = convertToCurrency(
      Math.floor(
        convertToCoins(values.amendments[index].sumWithFees) +
          (vat / 100) * convertToCoins(values.amendments[index].sumWithFees),
      ),
    );
    amendmentsItems[index].sumWithFees = convertToCoins(values.amendments[index].sumWithFees);
    amendmentsItems[index].sumWithVat = convertToCoins(+sumWithVat);
    setAmendmentsItems(amendmentsItems);
    values.amendments[index].sumWithVat = sumWithVat;
    form.setFieldsValue(values);
    calculateSum();
  };

  const setAmendmentPriceVat = (index: number) => {
    const values = { ...form.getFieldsValue() };
    amendmentsItems[index].sumWithVat = convertToCoins(+values.amendments[index].sumWithVat);
    setAmendmentsItems(amendmentsItems);
    calculateSum();
  };

  return (
    <>
      <div className={css['amendment-item-container-contract']}>
        <Row gutter={24} wrap={false} className={css['amendment-item-container-contract--item']}>
          <Col flex={'0 0 90px'}>{t('amendment.contaract')}:</Col>
          <Col flex={'auto'} style={{ display: 'flex' }}>
            <Form.Item name={['contract', 'id']} style={{ marginBottom: 0, display: 'none' }} initialValue={null}>
              <Input />
            </Form.Item>
            <Form.Item name={['contract', 'name']} style={{ marginBottom: 0, display: 'none' }} initialValue={null}>
              <Input />
            </Form.Item>
            <Form.Item name={['contract', 'uid']} style={{ marginBottom: 0, display: 'none' }} initialValue={null}>
              <Input />
            </Form.Item>
            {contractNameFile ? (
              <div
                className={css['ademdum-item-btn']}
                style={{
                  marginRight: '0.5rem',
                  padding: '0.5rem',
                }}>
                <img src={linkIcon} width={18} height={18} style={{ marginRight: '0.5rem' }} />
                <span>{contractNameFile}</span>
                <img
                  onClick={() => deleteFile()}
                  src={deleteIcon}
                  width={16}
                  height={16}
                  style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                />
              </div>
            ) : (
              <>
                <Button
                  onClick={() => {
                    if (propertyId) {
                      setIsOpenSelectContract(true);
                    } else {
                      notification.warning({
                        message: t('tickets.create.placeholder.property'),
                      });
                    }
                  }}
                  className={css['ademdum-item-btn']}
                  style={{ padding: 0, justifyContent: 'center', marginRight: '0.5rem' }}>
                  <img src={linkIcon} width={18} height={18} />
                </Button>
                <Upload
                  className={'upload-updates-reverse'}
                  beforeUpload={() => false}
                  onChange={(info) => {
                    handleFile(info, -1);
                  }}
                  multiple
                  accept=".pdf"
                  maxCount={1}>
                  <Button className={css['ademdum-item-btn']} style={{ padding: 0, justifyContent: 'center' }}>
                    <img src={uploadIcon} width={18} height={18} />
                  </Button>
                </Upload>
              </>
            )}
          </Col>
          <Col flex={'0 0 180px'}>
            <Form.Item
              name={['contract', 'sumWithFees']}
              style={{ marginBottom: 0 }}
              initialValue={0}
              rules={[{ required: true, message: t('common.validation.required') }]}>
              {currency ? (
                <InputNumber
                  style={{ width: '100%', padding: '4px 0' }}
                  onBlur={setContractPriceFees}
                  formatter={(value) => `${currency} ${convertToPriceWithSpace(value)}`}
                  parser={(value) => reverseConvertToPriceWithSpace(value, currency)}
                  min={0}
                  onPressEnter={(e) => {
                    e.preventDefault();
                    setContractPriceFees();
                  }}
                />
              ) : (
                <InputNumber style={{ width: '100%', padding: '4px 0' }} disabled={true} />
              )}
            </Form.Item>
          </Col>
          <Col flex={'0 0 180px'}>
            <Form.Item
              name={['contract', 'sumWithVat']}
              style={{ marginBottom: 0 }}
              initialValue={0}
              rules={[{ required: true, message: t('common.validation.required') }]}>
              {currency ? (
                <InputNumber
                  onPressEnter={(e) => {
                    e.preventDefault();
                    calculateSum();
                  }}
                  style={{ width: '100%', padding: '4px 0' }}
                  onBlur={calculateSum}
                  formatter={(value) => `${currency} ${convertToPriceWithSpace(value)}`}
                  parser={(value) => reverseConvertToPriceWithSpace(value, currency)}
                  min={0}
                />
              ) : (
                <InputNumber style={{ width: '100%', padding: '4px 0' }} disabled={true} />
              )}
            </Form.Item>
          </Col>
        </Row>
        <div className={css['amendment-item-container-contract--divider']}></div>
        {amendmentsItems.length > 0 &&
          amendmentsItems.map((el, index) => (
            <React.Fragment key={index}>
              <div className={css['amendment-item-container-contract--divider']}></div>
              <Row gutter={24} wrap={false} className={css['amendment-item-container-contract--item']}>
                <Col flex={'0 0 120px'}>{index === 0 ? `${t('finalAccount.amendment')}:` : ''}</Col>
                <Col flex={'auto'} style={{ display: 'flex' }}>
                  {el.name ? (
                    <>
                      <div
                        className={css['ademdum-item-btn']}
                        style={
                          el?.uid
                            ? {
                                padding: '0.5rem',
                                maxWidth: '125px',
                              }
                            : {
                                padding: '0.5rem',
                              }
                        }>
                        <img src={linkIcon} width={18} height={18} style={{ marginRight: '0.5rem' }} />
                        <span>{el.name}</span>
                        <img
                          onClick={() => deleteItem(index, el.uid)}
                          src={deleteIcon}
                          width={16}
                          height={16}
                          style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                        />
                      </div>
                      {el.uid && (
                        <Form.Item
                          name={['amendments', index, 'fakeName']}
                          style={{ marginBottom: 0, marginLeft: '0.5rem' }}
                          rules={[{ required: true, message: t('common.validation.required') }]}>
                          <Input
                            title={t('amendment.prev.placeholder')}
                            style={{ height: '40px', maxWidth: '125px' }}
                            placeholder={t('amendment.prev.placeholder')}
                            onChange={(e) => {
                              amendmentsItems[index].fakeName = e.target.value || null;
                              setAmendmentsItems(amendmentsItems);
                            }}
                          />
                        </Form.Item>
                      )}
                      {amendmentsItems.length === index + 1 && (
                        <Button
                          onClick={() => addItem()}
                          className={css['ademdum-item-btn']}
                          style={{
                            padding: 0,
                            marginLeft: '0.5rem',
                            justifyContent: 'center',
                          }}>
                          <img src={addIcon} width={18} height={18} />
                        </Button>
                      )}
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={() => {
                          if (propertyId) {
                            setIsOpenSelectAmendments(true);
                          } else {
                            notification.warning({
                              message: t('tickets.create.placeholder.property'),
                            });
                          }
                        }}
                        className={css['ademdum-item-btn']}
                        style={{ padding: 0, justifyContent: 'center', marginRight: '0.5rem' }}>
                        <img src={linkIcon} width={18} height={18} />
                      </Button>
                      <Upload
                        className={'upload-updates-reverse'}
                        beforeUpload={() => false}
                        onChange={(info) => {
                          handleFile(info, index);
                        }}
                        multiple
                        accept=".pdf"
                        maxCount={1}>
                        <Button className={css['ademdum-item-btn']} style={{ padding: 0, justifyContent: 'center' }}>
                          <img src={uploadIcon} width={18} height={18} />
                        </Button>
                      </Upload>
                      {index !== 0 && (
                        <Button
                          onClick={() => deleteItem(index, el.uid)}
                          className={css['ademdum-item-btn']}
                          style={{
                            padding: 0,
                            marginLeft: '0.5rem',
                            justifyContent: 'center',
                          }}>
                          <img src={deleteBtnIcon} width={18} height={18} />
                        </Button>
                      )}
                    </>
                  )}
                  <Form.Item
                    name={['amendments', index, 'id']}
                    style={{ marginBottom: 0, display: 'none' }}
                    initialValue={el.id || null}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={['amendments', index, 'name']}
                    style={{ marginBottom: 0, display: 'none' }}
                    initialValue={el.name || null}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={['amendments', index, 'uid']}
                    style={{ marginBottom: 0, display: 'none' }}
                    initialValue={el.uid || null}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex={'0 0 180px'}>
                  <Form.Item
                    name={['amendments', index, 'sumWithFees']}
                    style={{ marginBottom: 0 }}
                    initialValue={el.sumWithFees ? convertToCurrency(Math.floor(el.sumWithFees)) : el.sumWithFees}
                    rules={[{ required: true, message: t('common.validation.required') }]}>
                    {currency ? (
                      <InputNumber
                        onPressEnter={(e) => {
                          e.preventDefault();
                          setAmendmentPriceFees(index);
                        }}
                        readOnly={el.isReadOnly}
                        onBlur={() => setAmendmentPriceFees(index)}
                        style={{ width: '100%', padding: '4px 0', backgroundColor: el.isReadOnly ? '#f5f5f5' : '#fff' }}
                        formatter={(value) => `${currency} ${convertToPriceWithSpace(value)}`}
                        parser={(value) => reverseConvertToPriceWithSpace(value, currency)}
                      />
                    ) : (
                      <InputNumber
                        style={{ width: '100%', padding: '4px 0', backgroundColor: el.isReadOnly ? '#f5f5f5' : '#fff' }}
                        disabled={true}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col flex={'0 0 180px'}>
                  <Form.Item
                    name={['amendments', index, 'sumWithVat']}
                    style={{ marginBottom: 0 }}
                    initialValue={el.sumWithVat ? convertToCurrency(el.sumWithVat).toFixed(2) : el.sumWithVat}
                    rules={[{ required: true, message: t('common.validation.required') }]}>
                    {currency ? (
                      <InputNumber
                        onPressEnter={(e) => {
                          e.preventDefault();
                          setAmendmentPriceVat(index);
                        }}
                        readOnly={el.isReadOnly}
                        onBlur={() => setAmendmentPriceVat(index)}
                        style={{ width: '100%', padding: '4px 0', backgroundColor: el.isReadOnly ? '#f5f5f5' : '#fff' }}
                        formatter={(value) => `${currency} ${convertToPriceWithSpace(value)}`}
                        parser={(value) => reverseConvertToPriceWithSpace(value, currency)}
                      />
                    ) : (
                      <InputNumber
                        style={{ width: '100%', padding: '4px 0', backgroundColor: el.isReadOnly ? '#f5f5f5' : '#fff' }}
                        disabled={true}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </React.Fragment>
          ))}
      </div>
      <Row style={{ alignItems: 'center' }}>
        <Col style={{ display: 'flex', justifyContent: 'flex-end' }} flex={'auto'}>
          <span style={{ fontWeight: 700 }}>{`${t('amendment.total')}:`}</span>
        </Col>
        <Col style={{ display: 'flex', justifyContent: 'center' }} flex={'0 0 180px'}>
          <Form.Item
            name={['totalFullSum', 'sumWithFees']}
            style={{ marginBottom: 0 }}
            initialValue={0}
            rules={[{ required: true, message: t('common.validation.required') }]}>
            {currency && (
              <InputNumber
                className={css['amendment-item-container-contract--total']}
                bordered={false}
                readOnly={true}
                style={{ width: '100%', padding: '4px 0' }}
                formatter={(value) => `${currency} ${convertToPriceWithSpace(value)}`}
                parser={(value) => reverseConvertToPriceWithSpace(value, currency)}
                min={0}
              />
            )}
          </Form.Item>
        </Col>
        <Col style={{ display: 'flex', justifyContent: 'center' }} flex={'0 0 180px'}>
          <Form.Item
            name={['totalFullSum', 'sumWithVat']}
            style={{ marginBottom: 0 }}
            initialValue={0}
            rules={[{ required: true, message: t('common.validation.required') }]}>
            {currency && (
              <InputNumber
                className={css['amendment-item-container-contract--total']}
                bordered={false}
                readOnly={true}
                style={{ width: 'fit-content', padding: '4px 0' }}
                formatter={(value) => `${currency} ${convertToPriceWithSpace(value)}`}
                parser={(value) => reverseConvertToPriceWithSpace(value, currency)}
                min={0}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      {isOpenSelectContract && propertyId && (
        <SelectTableModal
          isOpen={isOpenSelectContract}
          propertyId={+propertyId}
          onSave={onSaveContract}
          onClose={() => setIsOpenSelectContract(false)}
          isContract={true}
        />
      )}
      {isOpenSelectAmendments && propertyId && (
        <SelectTableModal
          isOpen={isOpenSelectAmendments}
          propertyId={+propertyId}
          onSave={onSaveAmendments}
          onClose={() => setIsOpenSelectAmendments(false)}
          isContract={false}
        />
      )}
    </>
  );
};

export default FinalAccountContractAndAmendments;
