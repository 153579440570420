import { PRODUCTS_SET_DUPLICATE } from '../constants/actionTypes/productsConstants';

export interface ProductsReducerModel {
  type?: string;
  duplicate?: any;
}

export const initialState: ProductsReducerModel = {
  duplicate: undefined,
};

export function reducer(state: any, action: ProductsReducerModel) {
  switch (action.type) {
    case PRODUCTS_SET_DUPLICATE: {
      return {
        ...state,
        duplicate: action.duplicate,
      };
    }
    default:
      return initialState;
  }
}
