import config from '../config';
import HttpService from '../services/common/httpService';
import { getStorageToken } from './storageTools';

const httpService = new HttpService();

export const authDownloadFile = (file: string, fileName?: string) => {
  httpService
    .getFile(`${config.baseRequest}file/binary/${encodeURI(file)}`, {
      header: { Authorization: 'Bearer ' + getStorageToken() },
    })
    .then((res) => {
      const a = document.createElement('a');
      const url = window.URL.createObjectURL(res);
      a.href = url;
      a.download = fileName || file;
      a.click();
    });
};

function blobToBase64(blob: Blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export const makeAuthObjectUrl = async (fileName: string, callback: Function) => {
  const blob: any = await httpService.getFile(`${config.baseRequest}file/binary/${fileName}`, {
    header: { Authorization: 'Bearer ' + getStorageToken() },
  });

  var reader = new FileReader();

  reader.onloadend = function () {
    callback(reader.result);
  };
  reader.readAsDataURL(blob);
};
