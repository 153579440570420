import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { Form, Button, Row, Col, Input, InputNumber, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import VariantsService from '../../services/VariantsService';

const service: VariantsService = new VariantsService();

const VariantsModifyPage: React.FC<any> = (props: any) => {
  const { t } = useTranslation();
  const { id, typologyId, levelId } = useParams<any>();
  const history = useHistory();
  const isCreate = !id;
  const [form] = Form.useForm();

  const [breadcrumbNameMap, setBreadcrumbNameMap] = useState<{ [name: string]: string }>({});
  const [variant, setVariant] = useState<any>(undefined);

  const getVariant = () => {
    return service.getVariantById(id).then((res: any) => {
      setVariant(res);
      form.setFieldsValue({
        title: res.title,
        name: res.name,
        description: res.description,
        rooms: res.rooms,
        isActive: res.isActive,
        isDefault: res.isDefault,
        babylonId: res.babylonId,
      });
    });
  };

  const getAsyncData = async () => {
    const actions = [];
    if (!isCreate) {
      actions.unshift(getVariant());
    }
    Promise.all(actions).finally(() => {
      // TODO: set loading stop
      console.log('>>> STOP -> ', new Date());
    });
  };

  useEffect(() => {
    // TODO: set loading start
    console.log('>>> START -> ', new Date());
    getAsyncData();
  }, []);

  const onSubmit = (values: any) => {
    if (isCreate) {
      service
        .createVariant({
          ...values,
          level: { id: levelId },
        })
        .then((res: any) => {
          history.push(`/typologies/${typologyId}/levels/modify/${levelId}`);
        });
    } else {
      service
        .updateVariant({
          ...values,
          id: +id,
        })
        .then(() => {
          history.push(`/typologies/${typologyId}/levels/modify/${levelId}`);
        });
    }
  };

  const onCancel = () => {
    history.push(`/typologies/${typologyId}/levels/modify/${levelId}`);
  };

  return (
    <div>
      <Form autoComplete="off" form={form} layout="vertical" name="modify_typology" onFinish={onSubmit}>
        <HeaderTable
          title={!isCreate ? `${t('variant.create.title.update')} #${id}` : t('variant.create.title.create')}
          breadcrumb={breadcrumbNameMap}>
          <Form.Item>
            <Button htmlType="button" onClick={onCancel} className="header-btn">
              {t('common.btn.cancel')}
            </Button>
            <Button type="primary" htmlType="submit" className="header-btn">
              {t('common.btn.save')}
            </Button>
          </Form.Item>
        </HeaderTable>
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Row gutter={[24, 4]}>
              <Col span={12}>
                <Form.Item
                  label={t('variant.create.title')}
                  name="title"
                  rules={[{ required: true, message: t('variant.create.title.validation') }]}>
                  <Input className="common-animation-primary" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('variant.create.name')}
                  name="name"
                  rules={[{ required: true, message: t('variant.create.name.validation') }]}>
                  <Input className="common-animation-primary" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('variant.create.description')}
                  name="description"
                  rules={[{ required: true, message: t('variant.create.description.validation') }]}>
                  <Input className="common-animation-primary" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t('variant.create.rooms')} name="rooms">
                  <Input className="common-animation-primary" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t('variant.create.allSurface')} name="allSurface">
                  <InputNumber className="common-animation-primary" style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t('variant.create.babylonId')} name="babylonId">
                  <InputNumber className="common-animation-primary" style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t('variant.create.isActive.label')} name="isActive" valuePropName="checked">
                  <Checkbox>{t('variant.create.isActive.value')}</Checkbox>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t('variant.create.isDefault.label')} name="isDefault" valuePropName="checked">
                  <Checkbox>{t('variant.create.isDefault.value')}</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default VariantsModifyPage;
