import React, { useState } from 'react';
import { Button, Select, Col, Form, Checkbox, Input, InputNumber, DatePicker, Row } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { SelectValue } from 'antd/lib/select';
import moment from 'moment';
import { checkDigit } from '../../helpers/checkDigit';
import { IsJsonString } from '../../helpers/isJSONValid';
import { useLessThen801 } from '../../helpers/mediaDetect';

interface DynamicFieldsModel {
  name: any;
  isSelect: boolean;
  handleSelect: (isShow?: any) => void;
  fields: any[];
  values?: any;
  onChangeValues?: (params: any) => void;
}

const DynamicFields: React.FC<DynamicFieldsModel> = ({
  name,
  isSelect,
  handleSelect,
  fields,
  values = [],
  onChangeValues = () => {},
}: DynamicFieldsModel) => {
  const isArray = Array.isArray;
  const { t } = useTranslation();
  const [params, setParams] = useState<any[]>(isArray(values) ? values.sort((a: any, b: any) => a.name - b.name) : []);
  const defaultLocalString = { en: '', fr: '' };

  const getFieldName = (item: any) => `${item.paramsCode || item.param?.code || item.code}`;

  const getLabel = (code: string, label: string, key?: string) => {
    return (
      <>
        {key ? `${label} ${key}` : label}
        <CloseOutlined style={{ cursor: 'pointer' }} onClick={() => deleteParam(code)} />
      </>
    );
  };

  const selectFilterOption = (inputValue: any, option: any) => {
    return option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
  };

  const onChange = (params: any) => {
    setParams(params.sort((a: any, b: any) => a.name - b.name));
  };

  const handleParamSelect = (param: SelectValue) => {
    const item = fields.find((item: any) => item.code === param);
    if (item) {
      onChange([...params, { value: null, type: item.type, name: item.name, code: item.code }]);
      handleSelect(false);
    }
  };

  const deleteParam = (code: string) => {
    const toUpdate = [...params];
    const idx = toUpdate.findIndex((item: any) => item.code === code);
    toUpdate.splice(idx, 1);
    setParams(toUpdate);
    onChangeValues(toUpdate);
  };

  const defaultParams = fields
    .filter((item: any) => item.isRequired)
    .map((item: any) => {
      const field: any = params?.find((param: any) => param.code === item.code) || undefined;
      return { ...item, value: field?.value || null };
    });

  const visibleParams = Object.values(params).filter((item: any) => !item.isRequired);

  const filteredParams = fields
    .filter((item: any) => !item.isRequired)
    .filter((item: any) => {
      const param = params.find((p: any) => p.code === item.code);
      return !param ? true : !param;
    });

  if (useLessThen801()) {
    const getParameterField = (item: any) => {
      const fieldName = [name, getFieldName(item), 'value'];
      const label = item.isRequired ? item.name : getLabel(item.code, item.name);
      switch (item?.type?.code) {
        case 'boolean':
          return (
            <Form.Item
              name={fieldName}
              label={label}
              initialValue={item.value ? item.value === 'true' : true}
              valuePropName="checked"
              wrapperCol={{ span: 24 }}>
              <Checkbox style={{ width: '100%' }} />
            </Form.Item>
          );
        case 'number': {
          return (
            <Form.Item
              name={fieldName}
              label={label}
              initialValue={item.value ? JSON.parse(item.value) : null}
              rules={[{ required: true, message: t('common.validation.required') }]}
              wrapperCol={{ span: 24 }}>
              <InputNumber
                className="common-animation-primary"
                style={{ width: '100%' }}
                onKeyDown={(e) => {
                  if (!checkDigit(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          );
        }
        case 'string':
          return (
            <Form.Item
              name={fieldName}
              label={label}
              initialValue={item.value || ''}
              rules={[{ required: true, message: t('common.validation.required') }]}
              wrapperCol={{ span: 24 }}>
              <Input style={{ width: '100%' }} className="common-animation-primary" />
            </Form.Item>
          );
        case 'date':
          return (
            <Form.Item
              name={fieldName}
              label={label}
              initialValue={moment(item.value) || moment()}
              rules={[{ required: true, message: t('common.validation.required') }]}
              wrapperCol={{ span: 24 }}>
              <DatePicker inputReadOnly style={{ width: '100%' }} showToday className="common-animation-primary" />
            </Form.Item>
          );
        case 'timestamp':
          return (
            <Form.Item
              name={fieldName}
              label={label}
              initialValue={moment(item.value) || moment()}
              rules={[{ required: true, message: t('common.validation.required') }]}
              wrapperCol={{ span: 24 }}>
              <DatePicker
                inputReadOnly
                style={{ width: '100%' }}
                showTime
                showToday
                className="common-animation-primary"
              />
            </Form.Item>
          );
        case 'localeString':
          return Object.entries(
            item.value && IsJsonString(item.value) ? JSON.parse(item.value) : defaultLocalString,
          ).map(([key, value]) => (
            <Col span={24}>
              <Form.Item
                key={key}
                name={[name, getFieldName(item), key]}
                initialValue={value || ''}
                label={getLabel(item.code, item.name, key)}
                rules={[{ required: true, message: t('common.validation.required') }]}
                wrapperCol={{ span: 24 }}>
                <Input style={{ width: '100%' }} className="common-animation-primary" />
              </Form.Item>
            </Col>
          ));
        // TODO: ->
        // case 'dictionary':
        //     if (item.param.dictionaryName === 'printers') {
        //         return (
        //             <DictionarySelect
        //                 dictionaryName={item.param.dictionaryName}
        //                 keyField="id"
        //                 showField={['name', 'sellPoint.name']}
        //                 showFieldSeparator=": "
        //                 item={item}
        //                 multipleMode
        //             />
        //         );
        //     }
        //     return (
        //         <DictionarySelect
        //             dictionaryName={item.param.dictionaryName}
        //             keyField="code"
        //             showField="type"
        //             item={item}
        //         />
        //     );
        default:
          return null;
      }
    };
    return (
      <>
        {defaultParams &&
          defaultParams.length > 0 &&
          defaultParams.map((item: any) => (
            <Col span={24} key={item.code}>
              {getParameterField(item)}
            </Col>
          ))}
        {visibleParams.map((item: any) =>
          item.type.code === 'localeString' ? (
            <Row gutter={[24, 4]} justify={'space-between'} style={{ marginLeft: '0.2rem', width: '100%' }}>
              {getParameterField(item)}
            </Row>
          ) : (
            <Col span={24} key={item.code}>
              {getParameterField(item)}
            </Col>
          ),
        )}
        {isSelect ? (
          <Select
            className="common-select common-animation-primary"
            autoFocus
            defaultOpen
            placeholder={t('properties.create.items.addDomain.placeholder')}
            onChange={handleParamSelect}
            onBlur={() => handleSelect(false)}
            showSearch
            filterOption={selectFilterOption}
            style={{ width: '100%', margin: '0 0.6rem' }}>
            {filteredParams.map((item) => (
              <Select.Option key={item.code} value={item.code}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <Button type="dashed" onClick={handleSelect} className="add-field-btn" icon={<PlusOutlined />}>
            {t('common.btn.add.field')}
          </Button>
        )}
      </>
    );
  }

  const getParameterField = (item: any) => {
    const fieldName = [name, getFieldName(item), 'value'];
    const label = item.isRequired ? item.name : getLabel(item.code, item.name);
    switch (item?.type?.code) {
      case 'boolean':
        return (
          <Form.Item
            name={fieldName}
            label={label}
            initialValue={item.value ? item.value === 'true' : true}
            valuePropName="checked"
            wrapperCol={{ span: 24 }}>
            <Checkbox style={{ width: '100%' }} />
          </Form.Item>
        );
      case 'number': {
        return (
          <Form.Item
            name={fieldName}
            label={label}
            initialValue={item.value ? JSON.parse(item.value) : null}
            rules={[{ required: true, message: t('common.validation.required') }]}
            wrapperCol={{ span: 24 }}>
            <InputNumber
              className="common-animation-primary"
              style={{ width: '100%' }}
              onKeyDown={(e) => {
                if (!checkDigit(e.key)) {
                  e.preventDefault();
                }
              }}
            />
          </Form.Item>
        );
      }
      case 'string':
        return (
          <Form.Item
            name={fieldName}
            label={label}
            initialValue={item.value || ''}
            rules={[{ required: true, message: t('common.validation.required') }]}
            wrapperCol={{ span: 24 }}>
            <Input style={{ width: '100%' }} className="common-animation-primary" />
          </Form.Item>
        );
      case 'date':
        return (
          <Form.Item
            name={fieldName}
            label={label}
            initialValue={moment(item.value) || moment()}
            rules={[{ required: true, message: t('common.validation.required') }]}
            wrapperCol={{ span: 24 }}>
            <DatePicker inputReadOnly style={{ width: '100%' }} showToday className="common-animation-primary" />
          </Form.Item>
        );
      case 'timestamp':
        return (
          <Form.Item
            name={fieldName}
            label={label}
            initialValue={moment(item.value) || moment()}
            rules={[{ required: true, message: t('common.validation.required') }]}
            wrapperCol={{ span: 24 }}>
            <DatePicker
              inputReadOnly
              style={{ width: '100%' }}
              showTime
              showToday
              className="common-animation-primary"
            />
          </Form.Item>
        );
      case 'localeString':
        return Object.entries(item.value && IsJsonString(item.value) ? JSON.parse(item.value) : defaultLocalString).map(
          ([key, value]) => (
            <Col span={12}>
              <Form.Item
                key={key}
                name={[name, getFieldName(item), key]}
                initialValue={value || ''}
                label={getLabel(item.code, item.name, key)}
                rules={[{ required: true, message: t('common.validation.required') }]}
                wrapperCol={{ span: 24 }}>
                <Input style={{ width: '100%' }} className="common-animation-primary" />
              </Form.Item>
            </Col>
          ),
        );
      // TODO: ->
      // case 'dictionary':
      //     if (item.param.dictionaryName === 'printers') {
      //         return (
      //             <DictionarySelect
      //                 dictionaryName={item.param.dictionaryName}
      //                 keyField="id"
      //                 showField={['name', 'sellPoint.name']}
      //                 showFieldSeparator=": "
      //                 item={item}
      //                 multipleMode
      //             />
      //         );
      //     }
      //     return (
      //         <DictionarySelect
      //             dictionaryName={item.param.dictionaryName}
      //             keyField="code"
      //             showField="type"
      //             item={item}
      //         />
      //     );
      default:
        return null;
    }
  };

  return (
    <>
      {defaultParams &&
        defaultParams.length > 0 &&
        defaultParams.map((item: any) => (
          <Col span={12} key={item.code}>
            {getParameterField(item)}
          </Col>
        ))}
      {visibleParams.map((item: any) =>
        item.type.code === 'localeString' ? (
          <Row gutter={[24, 4]} justify={'space-between'} style={{ marginLeft: '0.2rem', width: '100%' }}>
            {getParameterField(item)}
          </Row>
        ) : (
          <Col span={12} key={item.code}>
            {getParameterField(item)}
          </Col>
        ),
      )}
      {isSelect ? (
        <Select
          className="common-select common-animation-primary"
          autoFocus
          defaultOpen
          placeholder={t('properties.create.items.addDomain.placeholder')}
          onChange={handleParamSelect}
          onBlur={() => handleSelect(false)}
          showSearch
          filterOption={selectFilterOption}
          style={{ width: '100%', margin: '0 0.6rem' }}>
          {filteredParams.map((item) => (
            <Select.Option key={item.code} value={item.code}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      ) : (
        <Button type="dashed" onClick={handleSelect} className="add-field-btn" icon={<PlusOutlined />}>
          {t('common.btn.add.field')}
        </Button>
      )}
    </>
  );
};

export default DynamicFields;
