export const validateObjectField =
  (fieldName: string) =>
  ({ getFieldValue }: any) => ({
    validator() {
      const value = getFieldValue(fieldName);
      try {
        if (value) {
          JSON.parse(value);
        }
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
  });
