import React from 'react';
import { Typography } from 'antd';

interface ArticleNameLayoutModel {
  article: any;
  inline?: boolean;
}

const ArticleNameLayout: React.FC<ArticleNameLayoutModel> = ({ article, inline = false }: ArticleNameLayoutModel) => {
  return (
    <>
      <Typography.Text>{article?.name}</Typography.Text>
      {inline ? (
        article?.fields
          ?.sort((a: any, b: any) => a.value - b.value)
          .map((field: any) => (
            <Typography.Text type="secondary" key={field.id}>
              &bull;{field.value}
            </Typography.Text>
          ))
      ) : (
        <p className="article-name">
          {article?.fields
            ?.sort((a: any, b: any) => a.value - b.value)
            .map((field: any) => (
              <Typography.Text type="secondary" key={field.id}>
                &bull;{field.value}
              </Typography.Text>
            ))}
        </p>
      )}
    </>
  );
};

export default ArticleNameLayout;
