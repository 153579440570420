import { SET_PROJECT_ID } from '../constants/actionTypes/propertyConstants';

export const initialState = {
  project: null,
};

export function reducer(state: any, action: any) {
  switch (action.type) {
    case SET_PROJECT_ID:
      return { project: action.project };
    default:
      return initialState;
  }
}
