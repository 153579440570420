import moment from 'moment';
import React from 'react';
import { convertToPrice } from '../../../../helpers/convertToPrice';
import { BudgetModel } from '../../../../models/BudgetModel';
import archivedIcon from '../../../../assets/images/ic-archive.svg';
import downloadIcon from '../../../../assets/images/ic-download-dark.svg';
import { authDownloadFile } from '../../../../helpers/authFileTools';
import { TFunction } from 'react-i18next';

interface ColumnsProps {
  t: TFunction<'translation'>;
  unArchive: (id: number) => void;
  isConsumer: boolean;
}

export const getBudgetColumns = ({ t, unArchive, isConsumer }: ColumnsProps) => [
  {
    title: () => <span className="budget-table-tr">№</span>,
    key: 'number',
    width: '8%',
    render: (cell: BudgetModel) => <span>{cell.number}</span>,
  },
  {
    title: () => <span className="budget-table-tr">{t('documents.table.column.description')}</span>,
    key: 'description',
    width: '24%',
    render: (cell: BudgetModel) => <span style={{ wordWrap: 'break-word' }}>{cell.description}</span>,
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.sum')}</span>,
    key: 'sum',
    width: '8%',
    render: (cell: BudgetModel) => `${cell.currency?.description || t('budget.currency')} ${convertToPrice(cell.sum)}`,
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.paid.at')}</span>,
    key: 'paidAt',
    width: '8%',
    render: (cell: BudgetModel) => (cell.paidAt ? `${moment(cell.paidAt).format('DD.MM.YYYY')}` : '-'),
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.date')}</span>,
    key: 'paymentDate',
    width: '10%',
    render: (cell: BudgetModel) => `${moment(cell.paymentDate).format('DD.MM.YYYY')}`,
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.status')}</span>,
    key: 'status',
    width: '13%',
    render: () => <span>{t('common.archived.status')}</span>,
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.action')}</span>,
    key: 'action',
    width: '29%',
    render: (cell: BudgetModel) => (
      <div style={{ display: 'flex', gap: '1rem' }}>
        <div onClick={() => authDownloadFile(cell.file)} className="download-budget-item common-unarchive-btn">
          <img src={downloadIcon} alt="" style={{ height: '15px', width: '15px', marginRight: '0.5rem' }} />
          <span style={{ color: '#00122d', fontWeight: 600 }}>{t('budget.table.download')}</span>
        </div>
        {!isConsumer && (
          <div onClick={() => unArchive(cell.id)} className="download-budget-item common-unarchive-btn">
            <img src={archivedIcon} alt="" style={{ height: '15px', width: '15px', marginRight: '0.5rem' }} />
            <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.title.unarchive')}</span>
          </div>
        )}
      </div>
    ),
  },
];
