import HttpService from './common/httpService';
import config from '../config';
import buildQuery from 'odata-query';

class InternalDocumentsService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getInternalDocuments(option: any) {
    const { top, count, skip, filters, orderBy } = option;

    let filter: any = [];
    filter = [...filters.filter];
    filter.sort((a: any, b: any) =>
      Object.keys(Object.values(a)[0] as any)[0] == 'contains'
        ? -1
        : Object.keys(Object.values(b)[0] as any)[0] == 'contains'
        ? 1
        : 0,
    );

    const query = buildQuery({ filter, top, count, skip, orderBy });
    return this.httpService.get(`${config.baseRequest}internalSignature${query}`);
  }

  public getInternalDocumentsNoArchive(option: any) {
    const { top, count, skip, filters, orderBy } = option;

    let filter = [];

    const defaultFilter: any = {
      isArchive: {
        eq: false,
      },
      'status/code': {
        ne: 'allocated',
      },
    };

    if (filters) {
      filter = [...filters.filter, defaultFilter];
      filter.sort((a: any, b: any) =>
        Object.keys(Object.values(a)[0] as any)[0] == 'contains'
          ? -1
          : Object.keys(Object.values(b)[0] as any)[0] == 'contains'
          ? 1
          : 0,
      );
    } else {
      filter = defaultFilter;
    }

    const query = buildQuery({ filter, top, count, skip, orderBy });
    return this.httpService.get(`${config.baseRequest}internalSignature${query}`);
  }

  public createInternalDocument(body: any) {
    return this.httpService.post(`${config.baseRequest}internalSignature`, body);
  }

  public deleteInternalDocument = (id: number) => {
    return this.httpService.delete(`${config.baseRequest}internalSignature/${id}`);
  };

  public getInternalDocumentsById(id: number) {
    return this.httpService.get(`${config.baseRequest}internalSignature/${id}`);
  }

  public updateInternalDocument(id: number, body: any) {
    return this.httpService.put(`${config.baseRequest}internalSignature/${id}`, body);
  }

  public uploadInternalDocumentFile(id: number, file: any) {
    return this.httpService.postFile(`${config.baseRequest}internalSignature/${id}/file`, file);
  }

  public getInternalStatuses() {
    return this.httpService.get(`${config.baseRequest}dictionary/internal_signature_statuses`);
  }

  public getSigningUrl(id: number, returnUrl: string, consumerId?: number) {
    return this.httpService.post(`${config.baseRequest}internalSignature/sign`, { id, consumerId, returnUrl });
  }

  public confirmSigning(envelopeId: string) {
    return this.httpService.patch(`${config.baseRequest}internalSignature/sign/confirm`, { envelopeId });
  }

  public cancelSigning(envelopeId: string) {
    return this.httpService.patch(`${config.baseRequest}internalSignature/sign/cancel`, { envelopeId });
  }

  public allocateInternalDocument(id: number, body: any) {
    return this.httpService.post(`${config.baseRequest}internalSignature/${id}/allocate`, body);
  }

  public rejectInternalDocument(id: number, comment: string) {
    const body = { comment };
    return this.httpService.patch(`${config.baseRequest}internalSignature/${id}/reject`, body);
  }

  public changeIsArchive(id: number) {
    return this.httpService.put(`${config.baseRequest}internalSignature/${id}/archive`, {});
  }
}

export default InternalDocumentsService;
