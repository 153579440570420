import React, { useEffect, useState } from 'react';
import {
  AiLoadingActions,
  IAiDedailsBody,
  IAiFaqQuestion,
  IAiTopic,
  IAiTopicWithId,
  ILoadingAction,
  QuestionsCreateModel,
  TopicsModel,
} from '../../../../models/FAQModel';
import { Button, Empty, Form, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import backIcon from '../../../../assets/images/ic-arrow-back2.svg';
import css from '../../../../assets/styles/faqAI.module.css';
import AIItem from './components/AIItem';
import { aiLanguageGenerating } from '../../../../constants/aiLanguageGenerating';
import ConfirmModal from './components/ConfirmModal';
import FAQService from '../../../../services/FAQService';
import AIService from '../../../../services/AIService';
import { initSocket } from '../../../../helpers/initSocket';
import { useLessThen801 } from '../../../../helpers/mediaDetect';
import { PlusOutlined, SaveOutlined } from '@ant-design/icons';

const aiService = new AIService();
const faqService = new FAQService();

interface IFromInstance {
  [key: string]: {
    isSelect: boolean;
    en: {
      question: string;
      answer: string;
    };
    fr: {
      question: string;
      answer: string;
    };
    topicEn?: (number | string)[];
    topicFr?: (number | string)[];
  };
}

interface IProps {
  questions: IAiFaqQuestion[];
  setStep: (n: number) => void;
  languageGenerating: aiLanguageGenerating;
  prompt: string;
  onFinishPublic: () => void;
}

const AIResult = ({ questions, setStep, languageGenerating, prompt, onFinishPublic }: IProps) => {
  const { t } = useTranslation();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isDraftOpen, setIsDraftOpen] = useState(false);
  const [isDraftConfirmOpen, setIsDraftConfirmOpen] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [isSaveDraft, setIsSaveDraft] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isSelectSomeOne, setIsSelectSomeOne] = useState(false);
  const [isTopicsLoading, setIsTopicsLoading] = useState(false);
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [aiTopics, setAiTopics] = useState<IAiTopicWithId[]>([]);
  const [topics, setTopics] = useState<TopicsModel>([]);
  const [items, setItems] = useState(questions);
  const [loadingAction, setLoadingAction] = useState<ILoadingAction>();
  const [form] = Form.useForm();

  useEffect(() => {
    getTopics();
  }, []);

  const generateAiTopics = (question: IAiFaqQuestion) => {
    const { id, ...rest } = question;
    setLoadingAction({
      isloading: true,
      owner: AiLoadingActions.generateTopics,
      index: id,
    });
    aiService
      .generateTopics(rest)
      .then((res) => {
        initSocket({
          eventName: res.eventName,
          isNeedDebounce: true,
          message: t('faq.ai.soket.timeout.message'),
          callback: (res) => {
            const aiTopicEn = aiTopics.map((item) => item.en.topic.toLowerCase());
            const aiTopicFr = aiTopics.map((item) => item.fr.topic.toLowerCase());

            const newBody = res.body
              .filter(
                (item: IAiTopic) =>
                  !aiTopicEn.includes(item.en.topic.toLowerCase()) && !aiTopicFr.includes(item.fr.topic.toLowerCase()),
              )
              .map((item: IAiTopic, index: number) => ({
                id: `ai${aiTopics.length + index}`,
                ...item,
              }));

            setAiTopics((state) => [...state, ...newBody]);
            setLoadingAction({
              isloading: false,
              owner: AiLoadingActions.generateTopics,
              index: id,
            });
          },
          callbackError: () => {
            setLoadingAction({
              isloading: false,
              owner: AiLoadingActions.generateTopics,
              index: id,
            });
          },
        });
      })
      .catch((e) => {
        notification.error({
          message: e.message,
        });
        setLoadingAction({
          isloading: false,
          owner: AiLoadingActions.generateTopics,
          index: id,
        });
      });
  };

  const getTopics = () => {
    setIsTopicsLoading(true);
    faqService
      .getTopics({
        filter: {
          'type/code': {
            eq: null,
          },
        },
      })
      .then((value: TopicsModel) => setTopics(value))
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => {
        setIsTopicsLoading(false);
      });
  };

  const onSubmit = async (values: IFromInstance) => {
    const getTopic = (value: {
      topicEn?: (number | string)[];
      topicFr?: (number | string)[];
    }): QuestionsCreateModel['topic'] => {
      const regex = /^ai\d+/;
      if (languageGenerating !== aiLanguageGenerating.fr_en) {
        const topic = languageGenerating === aiLanguageGenerating.en ? value.topicEn![0] : value.topicFr![0];
        if (Number.isInteger(topic)) {
          return { id: +topic };
        }
        if (regex.test(topic.toString())) {
          const aiTopic = aiTopics.find((item) => item.id === topic.toString());
          return {
            topicEn: aiTopic!.en.topic,
            topicFr: aiTopic!.fr.topic,
          };
        }
        return {
          topicEn: topic.toString(),
          topicFr: topic.toString(),
        };
      }

      if (Number.isInteger(value.topicEn![0])) {
        return { id: +value.topicEn![0] };
      }
      if (regex.test(value.topicEn![0].toString())) {
        const aiTopic = aiTopics.find((item) => item.id === value.topicEn![0].toString());
        return {
          topicEn: aiTopic!.en.topic,
          topicFr: aiTopic!.fr.topic,
        };
      }
      return {
        topicEn: value.topicEn![0].toString(),
        topicFr: value.topicFr![0].toString(),
      };
    };

    setLoadingAction({
      isloading: true,
      owner: isPublished ? AiLoadingActions.publish : AiLoadingActions.draft,
    });

    const body: QuestionsCreateModel[] = [];
    const keys = Object.keys(values);
    for (const key of keys) {
      if (values[key].isSelect) {
        body.push({
          topic: getTopic(values[key]),
          questionEn:
            languageGenerating !== aiLanguageGenerating.fr ? values[key].en.question : values[key].fr.question,
          questionFr:
            languageGenerating !== aiLanguageGenerating.en ? values[key].fr.question : values[key].en.question,
          answerEn: languageGenerating !== aiLanguageGenerating.fr ? values[key].en.answer : values[key].fr.answer,
          answerFr: languageGenerating !== aiLanguageGenerating.en ? values[key].fr.answer : values[key].en.answer,
          isPublishedEn: languageGenerating !== aiLanguageGenerating.fr ? isPublished : false,
          isPublishedFr: languageGenerating !== aiLanguageGenerating.en ? isPublished : false,
        });
      }
      if (isPublished && isSaveDraft && !values[key].isSelect) {
        body.push({
          topic: getTopic(values[key]),
          questionEn:
            languageGenerating !== aiLanguageGenerating.fr ? values[key].en.question : values[key].fr.question,
          questionFr:
            languageGenerating !== aiLanguageGenerating.en ? values[key].fr.question : values[key].en.question,
          answerEn: languageGenerating !== aiLanguageGenerating.fr ? values[key].en.answer : values[key].fr.answer,
          answerFr: languageGenerating !== aiLanguageGenerating.en ? values[key].fr.answer : values[key].en.answer,
          isPublishedEn: false,
          isPublishedFr: false,
        });
      }
    }

    await faqService
      .createFAQMany(body)
      .then(onFinishPublic)
      .catch((e) => {
        notification.error({
          message: e.message,
        });
      })
      .finally(() => {
        setLoadingAction({
          isloading: false,
          owner: isPublished ? AiLoadingActions.publish : AiLoadingActions.draft,
        });
      });
    //todo
    // handleSaveDraft();
  };

  // const handleSaveDraft = async (body: any = {}) => {
  //   setLoadingAction({
  //     isloading: true,
  //     owner: AiLoadingActions.draft,
  //   });
  //   await aiService
  //     .saveDraft(body)
  //     .then(() => {
  //       notification.success({ message: '' });
  //     })
  //     .catch((e) => {
  //       notification.error({
  //         message: e.message,
  //       });
  //     })
  //     .finally(() => {
  //       setLoadingAction({
  //         isloading: false,
  //         owner: AiLoadingActions.draft,
  //       });
  //     });
  // };

  const handleRegenerate = async (item: Omit<IAiFaqQuestion, 'id'>, id: number) => {
    setLoadingAction({
      isloading: true,
      owner: AiLoadingActions.details,
      index: id,
    });

    const { id: itemId, ...currentItem }: IAiFaqQuestion = items.find((el) => el.id === id)!;
    const body: IAiDedailsBody =
      languageGenerating === aiLanguageGenerating.fr_en
        ? {
            text: prompt,
            question: item,
          }
        : {
            text: prompt,
            question: { ...currentItem, ...item },
          };

    await aiService
      .detailsQuestion(body)
      .then((res) => {
        initSocket({
          eventName: res.eventName,
          isNeedDebounce: true,
          message: t('faq.ai.soket.timeout.message'),
          callback: (res) => {
            const formValues = form.getFieldsValue();
            formValues[id] = { ...formValues[id], ...res.body };
            form.setFieldsValue({ ...formValues });
            setItems(
              items.map((el) => {
                if (el.id === id) {
                  return {
                    id,
                    ...res.body,
                  };
                }
                return el;
              }),
            );

            if (JSON.stringify(res.body) === JSON.stringify(body)) {
              notification.error({ message: t('faq.ai.notification.regenerate.exact') });
            }

            setLoadingAction({
              isloading: false,
              owner: AiLoadingActions.details,
              index: id,
            });
          },
          callbackError: () => {
            setLoadingAction({
              isloading: false,
              owner: AiLoadingActions.details,
              index: id,
            });
          },
        });
      })
      .catch((e) => {
        setLoadingAction({
          isloading: false,
          owner: AiLoadingActions.details,
          index: id,
        });
        notification.error({
          message: e.message,
        });
      });
  };

  const toogleSelectAll = (isSelect: boolean) => {
    setIsSelectAll(isSelect);
    const formValues = form.getFieldsValue();
    for (const key in formValues) {
      formValues[key].isSelect = isSelect;
    }
    form.setFieldsValue(formValues);
  };

  const handleDeleteProperty = (id: number) => {
    setItems((state) => state.filter((item) => item.id !== id));
    setCurrentId(null);
    setIsDeleteOpen(false);
  };

  return (
    <>
      <div style={{ paddingBottom: '3rem', height: '100%' }}>
        <Form
          scrollToFirstError={{
            block: 'center',
          }}
          form={form}
          layout="vertical"
          className={css.aiResultForm}
          onFinish={onSubmit}
          onValuesChange={(_, values) => {
            setIsSelectSomeOne(Object.values(values).some((item) => item.isSelect));
            setIsSelectAll(Object.values(values).every((item) => item.isSelect));
          }}
          onFinishFailed={() => {
            setIsDraftOpen(false);
            setIsDraftConfirmOpen(false);
          }}>
          {items.length > 0 ? (
            items.map((item) => (
              <AIItem
                key={item.id}
                form={form}
                item={item}
                handleDeleteProperty={(id) => {
                  setCurrentId(id);
                  setIsDeleteOpen(true);
                }}
                id={item.id}
                onRegenerate={handleRegenerate}
                languageGenerating={languageGenerating}
                handleGenerateTopic={generateAiTopics}
                topics={topics}
                aiTopics={aiTopics}
                loadingAction={loadingAction}
                defaultSelect={isSelectAll || isSaveDraft}
                isTopicsLoading={isTopicsLoading}
              />
            ))
          ) : (
            <Empty className={css.aiEmpty} />
          )}
        </Form>
      </div>
      <div className={css.aiResultFooter}>
        <div className={css.aiBackBtn} onClick={() => setStep(1)}>
          <img src={backIcon} />
          {t('faq.step2.btn.back')}
        </div>
        <div className={css.aiFooterBtnGroup}>
          <Button
            type="text"
            style={{ color: '#356ede' }}
            onClick={() => toogleSelectAll(!isSelectAll)}
            disabled={loadingAction && loadingAction.isloading}>
            {isSelectAll ? t('faq.step2.btn.unselectAll') : t('faq.step2.btn.selectAll')}
          </Button>
          <Button
            className={`common-blue-border-btn common-secondary-btn`}
            onClick={() => {
              if (!(isSelectSomeOne || isSelectAll)) {
                setIsDraftOpen(false);
                setIsDraftConfirmOpen(false);
                return notification.error({ message: t('faq.ai.notification.error') });
              }
              setIsSaveDraft(false);
              setIsPublished(false);
              setTimeout(() => {
                form.validateFields().then(() => {
                  setIsDraftConfirmOpen(true);
                });
              }, 1);
            }}
            loading={loadingAction && loadingAction.owner === AiLoadingActions.draft && loadingAction.isloading}
            disabled={loadingAction && loadingAction.owner !== AiLoadingActions.draft && loadingAction.isloading}>
            {useLessThen801() ? <SaveOutlined /> : t('faq.step2.btn.save')}
          </Button>
          <Button
            className={`common-blue-btn common-primary-btn`}
            onClick={() => {
              if (!(isSelectSomeOne || isSelectAll)) {
                setIsDraftOpen(false);
                setIsDraftConfirmOpen(false);
                return notification.error({ message: t('faq.ai.notification.error') });
              }
              form
                .validateFields()
                .then(() => {
                  setIsSaveDraft(false);
                  setIsPublished(true);
                  if (isSelectAll) {
                    return form.submit();
                  }
                  setIsDraftOpen(true);
                })
                .catch(() => {
                  notification.error({ message: t('faq.ai.public.validation.error.message') });
                });
            }}
            loading={loadingAction && loadingAction.owner === AiLoadingActions.publish && loadingAction.isloading}
            disabled={loadingAction && loadingAction.owner !== AiLoadingActions.publish && loadingAction.isloading}>
            {useLessThen801() ? <PlusOutlined /> : t('faq.step2.btn.publish')}
          </Button>
          {isDeleteOpen && currentId !== null && (
            <ConfirmModal
              handleOk={() => handleDeleteProperty(currentId)}
              handleCancel={() => setIsDeleteOpen(false)}
              handleClose={() => setIsDeleteOpen(false)}
              title={t('faq.confirm.delete.title')}
              description={t('faq.confirm.delete.description')}
              okTitle={t('faq.confirm.delete.btn.ok')}
              cancelTitle={t('faq.confirm.delete.btn.cancel')}
              isLoading={false}
            />
          )}
          {isDraftOpen && (
            <ConfirmModal
              handleOk={() => {
                setIsSaveDraft(true);
                setTimeout(() => form.submit(), 1);
              }}
              handleCancel={() => {
                setIsSaveDraft(false);
                form.submit();
              }}
              handleClose={() => setIsDraftOpen(false)}
              title={t('faq.confirm.draft.title')}
              description={t('faq.confirm.draft.description')}
              okTitle={t('faq.confirm.draft.btn.ok')}
              cancelTitle={t('faq.confirm.draft.btn.cancel')}
              closeTitle={t('common.btn.cancel')}
              isLoading={!!loadingAction?.isloading}
            />
          )}
          {isDraftConfirmOpen && (
            <ConfirmModal
              handleOk={() => {
                form.submit();
              }}
              handleCancel={() => {
                setIsDraftConfirmOpen(false);
              }}
              handleClose={() => setIsDraftConfirmOpen(false)}
              title={t('faq.ai.confirm.draft.save.title')}
              description={t('faq.ai.confirm.draft.save.description')}
              okTitle={t('common.btn.save')}
              cancelTitle={t('common.btn.cancel')}
              isLoading={!!loadingAction?.isloading}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AIResult;
