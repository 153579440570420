import { Button, Col, Form, Modal, notification, Row, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../../../assets/styles/faq.module.css';
import { QuestionsModel, TopicsModel, TopicModel } from '../../../../../models/FAQModel';
import FAQService from '../../../../../services/FAQService';
import i18n from '../../../../../utils/i18n';

interface DeleteFormProps {
  id: number | null;
  setClose: () => void;
  isOpen: boolean;
}

const service = new FAQService();

const { Option } = Select;

const DeleteTopicForm = (props: DeleteFormProps) => {
  const { id, setClose, isOpen } = props;
  const { t } = useTranslation();
  const [fetchingData, setFetchingData] = useState(false);
  const [questions, setQuestions] = useState<QuestionsModel | []>([]);
  const [topics, setTopics] = useState<TopicsModel>([]);
  const [form] = Form.useForm();
  const isFrLanguage = i18n.language === 'fr-FR';

  useEffect(() => {
    setFetchingData(true);
    service
      .getFAQNoDefaultFilters({ filter: { topic: id } })
      .then((value: QuestionsModel) => {
        setQuestions(value);
        form.setFieldsValue({
          defaultFields: value.map((item) => item.id) || [],
        });
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingData(false));
  }, []);

  useEffect(() => {
    setFetchingData(true);
    service
      .getTopics()
      .then((value: TopicsModel) => setTopics(value))
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingData(false));
  }, []);

  const onSubmit = (value: any) => {
    setFetchingData(true);
    const result = [];
    questions.forEach((item, index) => {
      result.push(service.updateFAQ({ ...item, topic: value.defaultFields[index].topic ?? null }));
    });
    result.push(service.deleteTopic(id));

    return Promise.all(result)
      .then(() =>
        notification.success({
          message: t('manage.modal.success.delete.topic'),
        }),
      )
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => {
        setClose();
        setFetchingData(false);
      });
  };

  return (
    <Spin spinning={fetchingData}>
      <Modal
        className="common-modal"
        centered
        visible={isOpen}
        footer={
          <div className="common-modal-footer">
            <Button
              className={`common-gray-border-btn common-secondary-btn`}
              onClick={() => setClose()}
              disabled={fetchingData}>
              {t('budget.modal.cancel.btn')}
            </Button>
            <Button
              className={`common-green-btn common-modal-btn`}
              onClick={() => form.submit()}
              disabled={fetchingData}>
              {t('budget.modal.delete.btn')}
            </Button>
          </div>
        }
        closable={false}>
        <Form
          form={form}
          layout="vertical"
          name="delete_topic"
          onFinish={onSubmit}
          style={{ maxHeight: '90vh', overflowY: 'auto' }}>
          <div>
            <span className={css['delete-question-title']}>
              {t('manage.modal.confirm.delete.topic')}
              {`${id}?`}
            </span>
            {questions.length > 0 && (
              <div style={{ fontWeight: 600, color: '#ff4d4f' }}>{t('manage.faq.delete.confirm.warning')}</div>
            )}
          </div>
          {questions.length > 0 && (
            <div style={{ height: '20rem', overflowX: 'hidden' }}>
              <Form.List name="defaultFields">
                {(fields) => (
                  <>
                    {fields.map((field, index) => (
                      <div key={field.name}>
                        <Row gutter={[24, 4]}>
                          <Col span={20}>
                            <Form.Item
                              label={isFrLanguage ? questions[index].questionFr : questions[index].questionEn}
                              colon={false}
                              labelAlign="left"
                              name={[index, 'topic']}>
                              <Select
                                suffixIcon={
                                  <div
                                    className={`arrow-drop-img ${css['arrow-drop']}`}
                                    style={{ width: '8px', height: '4px' }}></div>
                                }
                                placeholder={t('users.create.card.user.projects.placeholder')}
                                className="consumer-form-input common-animation-primary">
                                {topics.length > 0 &&
                                  topics
                                    .filter((topic: TopicModel) => topic.id !== id)
                                    .map((topic: TopicModel) => (
                                      <Option value={topic.id} key={topic.id}>
                                        {isFrLanguage ? topic.topicFr : topic.topicEn}
                                      </Option>
                                    ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </>
                )}
              </Form.List>
            </div>
          )}
        </Form>
      </Modal>
    </Spin>
  );
};

export default DeleteTopicForm;
