import { Button, notification, Spin } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../assets/styles/budget.module.css';
import FinalAccountService from '../../../services/FinalAccountService';

interface DeleteFinalAccountFormProps {
  isOpen: boolean;
  currentId: number;
  getItems: () => void;
  setClose: () => void;
  itemNumber: string | null;
}

const finalAccountService = new FinalAccountService();

const DeleteFinalAccountForm = (props: DeleteFinalAccountFormProps) => {
  const { currentId, isOpen, setClose, getItems, itemNumber } = props;
  const [fetchingData, setFetchingData] = useState(false);
  const { t } = useTranslation();

  const deleteItem = () => {
    setFetchingData(true);
    finalAccountService
      .deleteFinalAccount(currentId)
      .then(() => {
        setClose();
        notification.success({
          message: t('finalAccount.modal.delete.success.message'),
        });
        getItems();
      })
      .catch((e) =>
        notification.error({
          message: e,
        }),
      )
      .finally(() => setFetchingData(false));
  };

  return (
    <Spin spinning={fetchingData}>
      <Modal
        className="common-modal"
        centered
        style={{ width: '10rem' }}
        visible={isOpen}
        footer={
          <div className="common-modal-footer">
            <Button className={`common-gray-border-btn common-secondary-btn`} onClick={() => setClose()}>
              {t('budget.modal.cancel.btn')}
            </Button>
            <Button className={`common-green-btn common-modal-btn`} onClick={() => deleteItem()}>
              {t('budget.modal.delete.btn')}
            </Button>
          </div>
        }
        closable={false}>
        <span className={css['delete-invoice-title']}>{`${t(
          'finalAccount.modal.confirm.delete.message',
        )} ${itemNumber}?`}</span>
      </Modal>
    </Spin>
  );
};

export default DeleteFinalAccountForm;
