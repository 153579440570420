import { Button, notification, Spin, Table, TablePaginationConfig, TableProps } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import AmendmentControl from './AmendmentControl';
import css from '../../../assets/styles/budget.module.css';
import { getColumns, getDocumentsColumns } from './AmendmentColumns';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import AmendmentService from '../../../services/AmendmentService';
import { AppContext } from '../../../contexts/AppContextProvider';
import PreviewPDF from './PreviewPDF';
import DeleteAmendmentForm from './DeleteAmendmentForm';
import AmendmentStatusForm from './AmendmentStatusForm';
import ContractForm from '../Contracts/ContractForm';
import UploadFileLoader from '../../common/UploadFileLoader';
import moment from 'moment';
import SigningConfirmForm from '../../common/SigningConfirmForm';
import { getStorageEnvelopeId, setStorageEnvelopeId } from '../../../helpers/envelopeStorage';
import { getUriSearchValue } from '../../../helpers/proccessUri';
import { docusignEventStatuses } from '../../../constants/docusignEventStatuses';
import { useLocation, useHistory } from 'react-router';
import AmendmentForm from './AmendmentForm';
import { generateFilterfromObj } from '../../../helpers/generateFIlterfromObj';
import { isEmptyFilter } from '../../../helpers/isEmptyFilter';
import config from '../../../config';
import HttpService from '../../../services/common/httpService';
import { getStorageToken } from '../../../helpers/storageTools';
import { useLessThen801 } from '../../../helpers/mediaDetect';
import filterIcon from '../../../assets/images/ic-filter.svg';
import filterActiveIcon from '../../../assets/images/ic-filter-active.svg';
import TableMobile from '../../common/TableMobile';
import { getSearchParam } from '../../../helpers/customUrlSearchParams';
import ContractsService from '../../../services/ContractsService';
import CommonApproveForm from '../../common/CommonApproveForm';
import CommonHistoryModal from '../../common/CommonHistoryModal';
import { FiltersContext } from '../../../contexts/FiltersContextProvider';
import { FilterTypes } from '../../../constants/filterTypes';
import { SET_AMENDMENTS_FILTERS, SET_CONTRACTS_FILTERS } from '../../../constants/actionTypes/filtersConstants';
import { IAmendmentModel } from '../../../models/AmendmentModel';
import { IHistoryModel } from '../../../models/CommonDocumnetsModel';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { IContractModel } from '../../../models/DocumentsModel';
import { IAppContext } from '../../../typings/IApp';
import { IUserEasy } from '../../../models/UserModel';
import { authDownloadFile } from '../../../helpers/authFileTools';

interface IAmendmentListInfo {
  propertyId: number;
  projectId: number;
  signatories?: IUserEasy[];
  consumers?: IUserEasy[];
  setIsOpenUploadAmendment?: (b: boolean) => void;
  setIsCreatedAmendment?: (b: boolean) => void;
  isOpenStatusAmendmentForm?: boolean;
  isCreatedAmendment?: boolean;
  setIsOpenStatusAmendmentForm?: (b: boolean) => void;
  setCurrentAmendment?: (v: number | null) => void;
  currentAmendment?: number | null;
}

const httpService = new HttpService();
const service = new AmendmentService();
const contractsService = new ContractsService();

const AmendmentListInfo = ({
  propertyId,
  projectId,
  consumers = [],
  signatories = [],
  setIsOpenUploadAmendment,
  setIsCreatedAmendment,
  isOpenStatusAmendmentForm,
  isCreatedAmendment,
  setIsOpenStatusAmendmentForm,
  setCurrentAmendment,
  currentAmendment,
}: IAmendmentListInfo) => {
  const { t } = useTranslation();
  const { height: windowHeight } = useWindowDimensions();
  const {
    filters: { amendmentsFilter, contractsFilter },
    filtersDispatch,
  } = useContext(FiltersContext);
  const {
    app: { isConsumer, isSupplier, user },
  } = useContext<IAppContext>(AppContext);
  const location = useLocation();
  const tabIndex = getSearchParam(location.search, 'tabIndex');
  const storageActiveTab = localStorage.getItem('amendmentActiveTab');
  const [fetchingData, setFetchingData] = useState(false);
  const history = useHistory();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalContract, setIsOpenModalContract] = useState(false);
  const [isOpenUploadLoader, setIsOpenUploadLoader] = useState(false);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [amedments, setAmedments] = useState<IAmendmentModel[] | IContractModel[]>([]);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [activeTab, setActiveTab] = useState(tabIndex ? +tabIndex : storageActiveTab ? +storageActiveTab : 1);
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [currentItemNumber, setCurrentItemNumber] = useState<string | null>(null);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenSigningConfirm, setIsOpenSigningConfirm] = useState(false);
  const [signInfo, setSignInfo] = useState<string | null | undefined>(null);
  const event = getUriSearchValue(location.search, 'event');
  const [isDocusignConfirm, setIsDocusignConfirm] = useState(
    !!event && event === docusignEventStatuses.signingComplete,
  );
  const [isDocusignDecline, setIsDocusignDecline] = useState(!!event && event === docusignEventStatuses.decline);
  const [isOpenApprove, setIsOpenApprove] = useState(false);
  const [currentFilters, setCurrentFilters] = useState({
    'status_v2/code': {
      type: FilterTypes.SELECT,
      value: activeTab === 1 ? amendmentsFilter.status : contractsFilter.status,
    },
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    skip: 0,
  });

  //history
  const [isOpenHistory, setIsOpenHistory] = useState(false);
  const [historyArray, setHistoryArray] = useState<IHistoryModel>([]);
  const [currentItem, setCurrentItem] = useState<IAmendmentModel | IContractModel | string | null>(null);

  const option = {
    count: true,
    top: pagination.pageSize,
    current: pagination.current,
    filters: {
      filter: generateFilterfromObj(currentFilters).filter,
    },
    orderBy: ['createdDate desc'],
  };

  const tabsTable = [
    { id: 1, title: t('amendment.title') },
    { id: 2, title: t('amendment.contaracts') },
  ];

  useEffect(() => {
    if (isCreatedAmendment) {
      if (activeTab === 1) {
        getAmedments(option);
      }
      setIsCreatedAmendment && setIsCreatedAmendment(false);
    }
  }, [isCreatedAmendment]);

  useEffect(() => {
    if (!location.search || isDocusignConfirm) {
      if (activeTab === 1) {
        getLocalFilters();
        getAmedments(option);
      }
      if (activeTab === 2) {
        getLocalFilters();
        getContracts(option);
      }
    }
  }, [propertyId, isDocusignConfirm]);

  const getLocalFilters = () => {
    const localFilters = localStorage.getItem(activeTab === 1 ? 'amendmentsFilter' : 'contractsFilter');
    const filters = localFilters && JSON.parse(localFilters);
    if (filters) {
      option.filters = generateFilterfromObj(
        applyNewFilter({
          'status_v2/code': {
            type: FilterTypes.SELECT,
            value: filters.status,
          },
        }),
      );
      filtersDispatch({
        type: activeTab === 1 ? SET_AMENDMENTS_FILTERS : SET_CONTRACTS_FILTERS,
        filters: {
          status: filters.status,
        },
      });
    }
  };

  const onChangeTab = (value: number) => {
    setAmedments([]);
    setActiveTab(value);
    localStorage.setItem('amendmentActiveTab', value.toString());
    const params = {
      ...option,
      top: pagination.pageSize,
      current: 1,
      skip: 0,
      filters: generateFilterfromObj(
        applyNewFilter({
          'status_v2/code': {
            type: FilterTypes.SELECT,
            value: value === 1 ? amendmentsFilter.status : contractsFilter.status,
          },
        }),
      ),
    };
    if (value === 1) {
      return getAmedments(params);
    }
    if (value === 2) {
      return getContracts(params);
    }
  };

  useEffect(() => {
    const envelopeId = getStorageEnvelopeId();
    if (activeTab === 1) {
      if (isDocusignConfirm) {
        setFetchingData(true);
        if (!envelopeId) {
          setFetchingData(false);
          setIsDocusignConfirm(false);
        } else {
          service
            .confirmStep(envelopeId)
            .catch((e) => console.error(e))
            .finally(() => {
              setIsDocusignConfirm(false);
              setStorageEnvelopeId('');
              history.replace({ search: '' });
            });
        }
      } else if (isDocusignDecline) {
        setFetchingData(true);
        if (!envelopeId) {
          setFetchingData(false);
          setIsDocusignDecline(false);
        } else {
          service
            .rejectAmendment(null, '', envelopeId)
            .catch((e) => console.error(e))
            .finally(() => {
              setIsDocusignDecline(false);
              setStorageEnvelopeId('');
              history.replace({ search: '' });
            });
        }
      }
    }
    if (activeTab === 2) {
      if (isDocusignConfirm) {
        setFetchingData(true);
        if (!envelopeId) {
          setFetchingData(false);
          setIsDocusignConfirm(false);
        } else {
          contractsService
            .confirmStep(envelopeId)
            .catch((e) => console.error(e))
            .finally(() => {
              setIsDocusignConfirm(false);
              setStorageEnvelopeId('');
              setFetchingData(false);
              history.replace({ search: '' });
            });
        }
      } else if (isDocusignDecline) {
        setFetchingData(true);
        if (!envelopeId) {
          setFetchingData(false);
          setIsDocusignDecline(false);
        } else {
          contractsService
            .rejectContract(null, '', envelopeId)
            .catch((e) => console.error(e))
            .finally(() => {
              setIsDocusignDecline(false);
              setStorageEnvelopeId('');
              setFetchingData(false);
              history.replace({ search: '' });
            });
        }
      }
    }
    if (location.search.includes('event')) {
      history.replace({ search: '' });
    }
  }, [activeTab]);

  const checkIsDelete = (items: IAmendmentModel[]) => {
    if (location.search && items.length === 0) {
      notification.warning({
        message: t('common.warning.notification.file.deleted'),
      });
      history.push(location.pathname);
    }
  };

  const getAmedments = (option: { [name: string]: any }) => {
    setFetchingData(true);
    service
      .getAmendments(+propertyId, option)
      .then((res) => {
        const { count, items } = res;
        const { current, top } = option;
        setPagination({ ...pagination, current, total: count, pageSize: top });
        setAmedments(items);
        checkIsDelete(items);
      })
      .catch((e) => console.log(e))
      .finally(() => setFetchingData(false));
  };

  const getContracts = (option: { [name: string]: any }) => {
    setFetchingData(true);
    contractsService
      .getContractsNoArchive(+propertyId, option)
      .then((res) => {
        const { count, items } = res;
        const { current, top } = option;
        setPagination({ ...pagination, current, total: count, pageSize: top });
        setAmedments(items);
        checkIsDelete(items);
      })
      .catch((e) => console.log(e))
      .finally(() => setFetchingData(false));
  };

  const loadMore = (option: { [name: string]: any }) => {
    setFetchingData(true);
    option.skip = option.skip ? option.skip + 10 : 10;
    activeTab === 1
      ? service
          .getAmendments(+propertyId, option)
          .then((res) => {
            const { count, items } = res;
            const { current, top } = option;
            setPagination({ ...pagination, current, total: count, pageSize: top });
            setAmedments([...amedments, ...items]);
          })
          .catch((e) => console.log(e))
          .finally(() => setFetchingData(false))
      : contractsService
          .getContracts(+propertyId, option)
          .then((res) => {
            const { count, items } = res;
            const { current, top } = option;
            setPagination({ ...pagination, current, total: count, pageSize: top });
            setAmedments([...amedments, ...items]);
          })
          .catch((e) => console.log(e))
          .finally(() => setFetchingData(false));
  };

  const applyNewFilter = (filters: { [name: string]: any }) => {
    setCurrentFilters({ ...currentFilters, ...filters });
    return { ...currentFilters, ...filters };
  };

  const onTableChange = (
    pagination?: TablePaginationConfig,
    filters?: Record<string, FilterValue | null | any>,
    sorter?: SorterResult<IAmendmentModel | IContractModel> | SorterResult<IAmendmentModel | IContractModel>[],
  ) => {
    let params: { [name: string]: any } = {
      ...option,
    };

    if (filters && !isEmptyFilter(filters)) {
      params = {
        ...params,
        filters: generateFilterfromObj(applyNewFilter(filters)),
      };
    } else {
      params = {
        ...params,
        filters: generateFilterfromObj(currentFilters),
      };
    }
    if (pagination && pagination.pageSize && pagination.current) {
      params = {
        ...params,
        top: pagination.pageSize,
        skip: pagination.pageSize * (pagination.current - 1),
        current: pagination.current,
      };
    } else {
      params = {
        ...params,
        skip: 0,
        current: 1,
      };
    }
    if (sorter && !Array.isArray(sorter) && sorter.order) {
      const { order, columnKey } = sorter;
      switch (columnKey) {
        case 'createdDate': {
          params = {
            ...params,
            orderBy: `createdDate ${order === 'ascend' ? 'asc' : 'desc'}`,
          };
          break;
        }
        case 'deadline': {
          params = {
            ...params,
            orderBy: `deadline ${order === 'ascend' ? 'asc' : 'desc'}`,
          };
          break;
        }
        case 'sumWithVat': {
          params = {
            ...params,
            orderBy: `deltaWithVat ${order === 'ascend' ? 'asc' : 'desc'}`,
          };
          break;
        }
        case 'date': {
          params = {
            ...params,
            orderBy: `date ${order === 'ascend' ? 'asc' : 'desc'}`,
          };
          break;
        }
        case 'lastSignDateTime': {
          params = {
            ...params,
            orderBy: `lastSignDateTime ${order === 'ascend' ? 'asc' : 'desc'}`,
          };
          break;
        }
        default: {
          return;
        }
      }
    }

    activeTab === 1 ? getAmedments(params) : getContracts(params);
  };

  const openPreview = (id?: number) => {
    setIsOpenPreview(true);
    if (id) {
      setCurrentId(id);
    }
  };

  const closePreview = () => {
    setIsOpenPreview(false);
    setCurrentId(null);
    activeTab === 1 ? getAmedments(option) : getContracts(option);
  };

  const setOpenDelete = (id?: number, itemNumber?: string) => {
    setIsOpenDeleteModal(true);
    if (id) {
      setCurrentId(id);
    }
    if (itemNumber) {
      setCurrentItemNumber(itemNumber);
    }
  };

  const setCloseDelete = () => {
    setIsOpenDeleteModal(false);
    setCurrentId(null);
    setCurrentItemNumber(null);
  };

  const setCloseStatus = () => {
    setIsOpenStatusAmendmentForm && setIsOpenStatusAmendmentForm(false);
    setCurrentId(null);
    getAmedments(option);
  };

  const setOpenStatus = (id?: number) => {
    setIsOpenStatusAmendmentForm && setIsOpenStatusAmendmentForm(true);
    if (id) {
      setCurrentId(id);
    }
  };

  const setClose = () => {
    setIsOpenModalContract(false);
    setIsOpenModal(false);
    setCurrentId(null);
  };

  const closeUploadLoader = () => {
    setIsOpenUploadLoader(false);
    activeTab === 1 ? getAmedments(option) : getContracts(option);
  };

  const openSigningConfirm = (id?: number, signInfo?: string) => {
    setIsOpenSigningConfirm(true);
    if (id) {
      setCurrentId(id);
      setSignInfo(signInfo);
    }
  };

  const closeSigningConfirm = () => {
    setIsOpenSigningConfirm(false);
    setCurrentId(null);
    setSignInfo(null);
    if (activeTab === 1) {
      getAmedments(option);
    }
    if (activeTab === 2) {
      getContracts(option);
    }
  };

  const signItem = () => {
    activeTab === 1
      ? localStorage.setItem('amendmentsFilter', JSON.stringify(amendmentsFilter))
      : localStorage.setItem('contractsFilter', JSON.stringify(contractsFilter));
    if (currentId) {
      setFetchingData(true);
      if (activeTab === 1) {
        service
          .getSigningUrl(currentId, window.location.href, user.id)
          .then((res) => {
            const { alreadySigned = false, declined = false } = res;
            if (!alreadySigned && !declined) {
              setStorageEnvelopeId(res.envelopeId);
              window.location.href = res.redirectUrl;
            } else {
              if (alreadySigned) {
                notification.warning({
                  message: t('amendment.modal.already.signed.message'),
                });
              }
              if (declined) {
                notification.warning({
                  message: t('amendment.modal.already.decline.message'),
                });
              }
              setFetchingData(false);
            }
          })
          .catch((e) => {
            notification.error({
              message: e.message,
            });
            setFetchingData(false);
          })
          .finally(() => {
            setClose();
          });
      }
      if (activeTab === 2) {
        contractsService
          .getSigningUrl(currentId, window.location.href, user.id)
          .then((res) => {
            const { alreadySigned = false, declined = false } = res;
            if (!alreadySigned && !declined) {
              setStorageEnvelopeId(res.envelopeId);
              window.location.href = res.redirectUrl;
            } else {
              if (alreadySigned) {
                notification.warning({
                  message: t('contract.modal.already.signed.message'),
                });
              }
              if (declined) {
                notification.warning({
                  message: t('contract.modal.already.decline.message'),
                });
              }
              setFetchingData(false);
            }
          })
          .catch((e) => {
            notification.error({
              message: e.message,
            });
            setFetchingData(false);
          })
          .finally(() => {
            setClose();
          });
      }
    }
  };

  const rejectItem = (iD: number | null, comment: string, isApprove: boolean = false) => {
    const id = iD || currentId;
    if (id) {
      if (activeTab === 1) {
        service
          .rejectAmendment(id, comment)
          .catch((e) =>
            notification.error({
              message: e.message,
            }),
          )
          .finally(() => {
            isApprove ? closeApprove() : closeSigningConfirm();
          });
      }
      if (activeTab === 2) {
        contractsService
          .rejectContract(id, comment)
          .catch((e) =>
            notification.error({
              message: e.message,
            }),
          )
          .finally(() => {
            isApprove ? closeApprove() : closeSigningConfirm();
          });
      }
    }
  };

  const openApprove = (id: number) => {
    setCurrentId(id);
    setIsOpenApprove(true);
  };

  const closeApprove = () => {
    setCurrentId(null);
    setIsOpenApprove(false);
    if (activeTab === 1) {
      getAmedments(option);
    }
    if (activeTab === 2) {
      getContracts(option);
    }
  };

  const approveItem = () => {
    setFetchingData(true);
    if (currentId) {
      if (activeTab === 1) {
        service
          .confirmStep(undefined, currentId)
          .then(() => {
            notification.success({
              message: t(activeTab === 1 ? 'fileManagement.amendment.approved' : 'fileManagement.contract.approved'),
            });
            closeApprove();
          })
          .catch((e) =>
            notification.error({
              message: e.message,
            }),
          )
          .finally(() => setFetchingData(false));
      } else {
        contractsService
          .confirmStep(undefined, currentId)
          .then(() => {
            notification.success({
              message: t(activeTab === 1 ? 'fileManagement.amendment.approved' : 'fileManagement.contract.approved'),
            });
            closeApprove();
          })
          .catch((e) =>
            notification.error({
              message: e.message,
            }),
          )
          .finally(() => setFetchingData(false));
      }
    }
  };

  const setOpen = (id?: number) => {
    setIsOpenModal(true);
    if (id) {
      setCurrentId(id);
    }
  };

  const setOpenDoc = (id?: number) => {
    setIsOpenModalContract(true);
    if (id) {
      setCurrentId(id);
    }
  };

  const authDownloadFileAmm = (file: string, id: number, fileName?: string) => {
    httpService
      .getFile(`${config.baseRequest}amendments/${id}/fileFull`, {
        header: { Authorization: 'Bearer ' + getStorageToken() },
      })
      .then((res) => {
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(res);
        a.href = url;
        a.download = fileName || file;
        a.click();
      });
  };

  const openHistory = (id: number, title?: string, item?: IAmendmentModel | IContractModel) => {
    if (item) {
      if (item.statusV2.code === 'rejected') {
        setFetchingData(true);
        (activeTab === 1 ? service.getAmendmentHistory(id) : contractsService.getContractsHistory(id))
          .then((value) => {
            setIsOpenHistory(true);
            setCurrentId(id);
            setHistoryArray(value);
            setCurrentItem(item);
          })
          .catch((e) =>
            notification.error({
              message: e.message,
            }),
          )
          .finally(() => setFetchingData(false));
        return;
      } else {
        setIsOpenHistory(true);
        setCurrentItem(item);
        return;
      }
    }
    setFetchingData(true);
    (activeTab === 1 ? service.getAmendmentHistory(id) : contractsService.getContractsHistory(id))
      .then((value) => {
        setIsOpenHistory(true);
        setCurrentId(id);
        setHistoryArray(value);
        setCurrentItem(title || null);
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingData(false));
  };

  const closeHistory = () => {
    setCurrentId(null);
    setIsOpenHistory(false);
    setCurrentItem(null);
  };

  const data =
    amedments.length > 0 && activeTab === 1
      ? ((amedments as IAmendmentModel[]).map((item) => [
          {
            title: t('documents.table.column.number'),
            description: item.number,
            warning: {
              title: (
                <span style={{ color: '#EB3B3B', fontSize: '18px' }}>{t('common.popover.attention.file.title')}</span>
              ),
              content: (
                <div style={{ width: '200px' }}>
                  <p>{t('common.popover.attention.file.content')}</p>
                </div>
              ),
            },
            type: !item.file ? 'warning' : 'string',
          },
          { title: t('documents.table.column.description'), description: item.description, type: 'string' },
          { title: t('budget.table.sum.total'), description: item.deltaWithVat, type: 'price' },
          { title: t('budget.form.deadline'), description: item.deadline, type: 'date' },
          { title: t('budget.table.status'), description: item.statusV2, type: 'status' },
          {
            title: t('budget.table.action'),
            description: item.file,
            type: 'button',
            previewAction: !!item.file && openPreview,
            id: item.id,
            signatories: item.signatories,
            comment: item.comment || item.signInfo,
            status: item.statusV2,
            openByFunction: true,
            btnsInfo: [
              {
                function: () => setOpen(item.id),
                functionBtnTitle: t('budget.table.edit'),
                isNotShow: isConsumer,
              },
              {
                function: () => openApprove(item.id),
                functionBtnTitle: t('common.title.approve.reject'),
                isNotShow: !(
                  item.signatories &&
                  item.signatories.findIndex(
                    (signatory) =>
                      signatory.consumerId === user.id &&
                      (item.step?.code === 'agent_approve_open' ||
                        item.step?.code === 'agent_approve_close' ||
                        item.step?.code === 'client_approve') &&
                      signatory.stepCode === item.step?.code &&
                      !signatory.isDone,
                  ) >= 0 &&
                  !['rejected'].includes(item.statusV2.code)
                ),
              },
              {
                function: () => openSigningConfirm(item.id),
                functionBtnTitle: t('common.title.sign'),
                isNotShow: !(
                  item.signatories &&
                  item.signatories.findIndex(
                    (signatory) =>
                      signatory.consumerId === user.id &&
                      (item.step?.code === 'agent_sign_open' ||
                        item.step?.code === 'agent_sign_close' ||
                        item.step?.code === 'client_sign') &&
                      signatory.stepCode === item.step?.code &&
                      !signatory.isDone,
                  ) >= 0 &&
                  !['rejected'].includes(item.statusV2.code)
                ),
              },
              {
                function: () =>
                  item.flow ? openHistory(item.id, item.number, item) : openHistory(item.id, item.number),
                functionBtnTitle: t('fileManagement.history.title'),
                isNotShow: isConsumer,
              },
              {
                function: () => authDownloadFileAmm(item.file, item.id, item.fileName),
                functionBtnTitle: t('budget.table.download'),
              },
            ],
          },
        ]) as any)
      : ((amedments as IContractModel[]).map((item) => [
          {
            title: t('documents.table.column.name'),
            description: item.title,
            warning: {
              title: (
                <span style={{ color: '#EB3B3B', fontSize: '18px' }}>{t('common.popover.attention.file.title')}</span>
              ),
              content: (
                <div style={{ width: '200px' }}>
                  <p>{t('common.popover.attention.file.content')}</p>
                </div>
              ),
            },
            type: !item.file ? 'warning' : 'string',
          },
          { title: t('documents.table.column.description'), description: item.description, type: 'string' },
          { title: t('budget.table.sum.total'), description: item.sumWithVat, type: 'price' },
          { title: t('documents.table.status'), description: item.statusV2, type: 'status' },
          {
            title: t('budget.table.action'),
            description: item.file,
            type: 'button',
            previewAction: !!item.file && openPreview,
            id: item.id,
            signatories: item.signatories,
            comment: item.comment || item.signInfo,
            status: item.statusV2,
            openByFunction: true,
            btnsInfo: [
              {
                function: () => setOpen(item.id),
                functionBtnTitle: t('budget.table.edit'),
                isNotShow: isConsumer,
              },
              {
                function: () => openApprove(item.id),
                functionBtnTitle: t('common.title.approve.reject'),
                isNotShow: !(
                  item.signatories &&
                  item.signatories.findIndex(
                    (signatory) =>
                      signatory.consumerId === user.id &&
                      (item.step?.code === 'agent_approve_open' ||
                        item.step?.code === 'agent_approve_close' ||
                        item.step?.code === 'client_approve') &&
                      signatory.stepCode === item.step?.code &&
                      !signatory.isDone,
                  ) >= 0 &&
                  !['rejected'].includes(item.statusV2.code)
                ),
              },
              {
                function: () => openSigningConfirm(item.id),
                functionBtnTitle: t('common.title.sign'),
                isNotShow: !(
                  item.signatories &&
                  item.signatories.findIndex(
                    (signatory) =>
                      signatory.consumerId === user.id &&
                      (item.step?.code === 'agent_sign_open' ||
                        item.step?.code === 'agent_sign_close' ||
                        item.step?.code === 'client_sign') &&
                      signatory.stepCode === item.step?.code &&
                      !signatory.isDone,
                  ) >= 0 &&
                  !['rejected'].includes(item.statusV2.code)
                ),
              },
              {
                function: () => (item.flow ? openHistory(item.id, item.title, item) : openHistory(item.id, item.title)),
                functionBtnTitle: t('fileManagement.history.title'),
                isNotShow: isConsumer,
              },
              {
                function: () => authDownloadFile(item.file, item.fileName),
                functionBtnTitle: t('budget.table.download'),
              },
            ],
          },
        ]) as any);

  const changeOpenFilter = () => {
    setIsOpenFilter(!isOpenFilter);
  };

  if (useLessThen801()) {
    return (
      <>
        <div className="common-mobile-title-container">
          <span style={{ fontSize: '20px' }}>{t('amendment.contaracts')}</span>
          <div className="common-modbile-filter-container" onClick={changeOpenFilter}>
            <img src={isOpenFilter ? filterActiveIcon : filterIcon} width={16} />
            <span className="filter-title">{isOpenFilter ? t('common.filter.close') : t('common.filter.open')}</span>
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: !isSupplier ? '0' : isOpenFilter ? '0' : '1rem' }}>
          {!isConsumer && (
            <Button
              className={`common-blue-border-btn common-secondary-btn`}
              onClick={() => (activeTab === 1 ? setIsOpenModal(true) : setIsOpenModalContract(true))}
              style={{ width: '100%' }}>
              <span>{activeTab === 1 ? t('amendment.upload') : t('contract.upload')}</span>
            </Button>
          )}
        </div>
        {isOpenFilter && (
          <AmendmentControl
            setIsOpenModal={setIsOpenModal}
            openCreate={setIsOpenUploadAmendment}
            activeTab={activeTab}
            propertyId={propertyId}
            onTableChange={onTableChange}
            setIsOpenModalContract={setIsOpenModalContract}
          />
        )}
        <div className={css['container-table-quote-control']}>
          {tabsTable.map((el) => (
            <Button
              key={el.id}
              className={`${activeTab === el.id ? css['active'] : ''}`}
              onClick={() => onChangeTab(el.id)}>
              {el.title}
            </Button>
          ))}
        </div>
        <TableMobile
          data={data}
          isLoading={fetchingData}
          loadMore={loadMore}
          option={option}
          itemsLength={amedments.length}
          totalItems={pagination.total}
        />
        {isOpenModal && (
          <AmendmentForm
            setClose={setClose}
            propertyId={propertyId}
            getAmendments={() => getAmedments(option)}
            currentId={currentId}
            isOpenModal={isOpenModal}
            setIsOpenUploadLoader={setIsOpenUploadLoader}
            setIsUploadingFile={setIsUploadingFile}
          />
        )}
        {isOpenStatusAmendmentForm && (
          <AmendmentStatusForm
            isOpen={isOpenStatusAmendmentForm}
            id={currentId}
            propertyId={+propertyId}
            currentId={currentId}
            setCloseStatus={setCloseStatus}
            currentAmm={currentAmendment}
            setCurrentAmm={setCurrentAmendment}
          />
        )}
        {isOpenPreview && (
          <PreviewPDF id={currentId} isOpen={isOpenPreview} setClose={closePreview} isAmendment={activeTab === 1} />
        )}
        {isOpenModalContract && (
          <ContractForm
            setClose={setClose}
            propertyId={+propertyId}
            getDocumentsInfo={() => getContracts(option)}
            currentId={currentId}
            signatories={signatories}
            isOpenModal={isOpenModalContract}
            setIsOpenUploadLoader={setIsOpenUploadLoader}
            setIsUploadingFile={setIsUploadingFile}
            projectId={projectId}
          />
        )}
        {isOpenUploadLoader && (
          <UploadFileLoader isOpen={isOpenUploadLoader} isLoading={isUploadingFile} setClose={closeUploadLoader} />
        )}
        {isOpenSigningConfirm && (
          <SigningConfirmForm
            id={currentId}
            isOpen={isOpenSigningConfirm}
            setClose={closeSigningConfirm}
            sign={signItem}
            reject={rejectItem}
            fetchingData={fetchingData}
            signInfo={signInfo}
          />
        )}
        {isOpenApprove && (
          <CommonApproveForm
            approve={approveItem}
            isOpen={isOpenApprove}
            reject={(id, comment) => rejectItem(id, comment, true)}
            setClose={closeApprove}
            fetchingData={fetchingData}
            type={'contract'}
          />
        )}
        {isOpenHistory && (
          <CommonHistoryModal
            fetchingData={fetchingData}
            setFetchingData={setFetchingData}
            isOpen={isOpenHistory}
            items={historyArray}
            setClose={closeHistory}
            currentItem={currentItem}
          />
        )}
      </>
    );
  }

  return (
    <div className={css['table-container']} style={{ width: '100%', flex: 'none' }}>
      <Spin spinning={fetchingData}>
        <AmendmentControl
          setIsOpenModal={setIsOpenModal}
          openCreate={setIsOpenUploadAmendment}
          activeTab={activeTab}
          propertyId={propertyId}
          onTableChange={onTableChange}
          setIsOpenModalContract={setIsOpenModalContract}
        />
        <div style={{ display: 'flex', gap: '1rem' }}>
          <div className={css['container-table-quote-control']}>
            {tabsTable.map((el) => (
              <Button
                key={el.id}
                className={`${activeTab === el.id ? css['active'] : ''}`}
                onClick={() => onChangeTab(el.id)}>
                {el.title}
              </Button>
            ))}
          </div>
          {activeTab === 1 ? (
            <Table
              columns={getColumns({
                t,
                isConsumer,
                consumerId: user.id,
                openPreview,
                setOpenDelete,
                setOpenStatus,
                openSigningConfirm,
                openApprove,
                authDownloadFile: authDownloadFileAmm,
                setOpen,
                openHistory,
              })}
              size="small"
              className={`list-info-table ${!isSupplier && css['custom-supplier-table']}`}
              rowClassName={(record) =>
                `common-table-row--pointer ${
                  (!record.file ||
                    (moment(record.deadline).isBefore(moment()) &&
                      !['signed', 'rejected', 'done', 'canceled'].includes(record?.statusV2.code))) &&
                  'common-table-row--error'
                }`
              }
              dataSource={amedments as IAmendmentModel[]}
              showHeader={true}
              pagination={pagination}
              scroll={{ y: windowHeight - 330 }}
              onChange={onTableChange as TableProps<IAmendmentModel>['onChange']}
              rowKey="id"
            />
          ) : (
            <Table
              columns={getDocumentsColumns({
                t,
                isConsumer,
                setOpenDoc,
                setOpenDelete,
                openPreview,
                userId: user.id,
                openSigningConfirm,
                openApprove,
                openHistory,
              })}
              size="small"
              className={`list-info-table ${!isSupplier && css['custom-supplier-table']}`}
              dataSource={amedments as IContractModel[]}
              showHeader={true}
              pagination={pagination}
              scroll={{ y: windowHeight - 330 }}
              onChange={onTableChange as TableProps<IContractModel>['onChange']}
              rowKey="id"
            />
          )}
        </div>
        {isOpenModal && (
          <AmendmentForm
            setClose={setClose}
            propertyId={propertyId}
            getAmendments={() => getAmedments(option)}
            currentId={currentId}
            isOpenModal={isOpenModal}
            setIsOpenUploadLoader={setIsOpenUploadLoader}
            setIsUploadingFile={setIsUploadingFile}
          />
        )}
        {isOpenDeleteModal && currentId && (
          <DeleteAmendmentForm
            setClose={setCloseDelete}
            itemNumber={currentItemNumber}
            getItems={() => (activeTab === 1 ? getAmedments(option) : getContracts(option))}
            currentId={currentId}
            isOpen={isOpenDeleteModal}
            isAmendment={activeTab === 1}
          />
        )}
        {isOpenStatusAmendmentForm && (
          <AmendmentStatusForm
            isOpen={isOpenStatusAmendmentForm}
            id={currentId}
            propertyId={+propertyId}
            currentId={currentId}
            setCloseStatus={setCloseStatus}
            currentAmm={currentAmendment}
            setCurrentAmm={setCurrentAmendment}
          />
        )}
        {isOpenPreview && (
          <PreviewPDF id={currentId} isOpen={isOpenPreview} setClose={closePreview} isAmendment={activeTab === 1} />
        )}
        {isOpenModalContract && (
          <ContractForm
            setClose={setClose}
            propertyId={+propertyId}
            getDocumentsInfo={() => getContracts(option)}
            currentId={currentId}
            signatories={signatories}
            isOpenModal={isOpenModalContract}
            setIsOpenUploadLoader={setIsOpenUploadLoader}
            setIsUploadingFile={setIsUploadingFile}
            projectId={projectId}
          />
        )}
        {isOpenUploadLoader && (
          <UploadFileLoader isOpen={isOpenUploadLoader} isLoading={isUploadingFile} setClose={closeUploadLoader} />
        )}
        {isOpenSigningConfirm && (
          <SigningConfirmForm
            id={currentId}
            isOpen={isOpenSigningConfirm}
            setClose={closeSigningConfirm}
            sign={signItem}
            reject={rejectItem}
            fetchingData={fetchingData}
            signInfo={signInfo}
          />
        )}
        {isOpenApprove && (
          <CommonApproveForm
            approve={approveItem}
            isOpen={isOpenApprove}
            reject={(id, comment) => rejectItem(id, comment, true)}
            setClose={closeApprove}
            fetchingData={fetchingData}
            type={activeTab === 1 ? 'amendment' : 'contract'}
          />
        )}
        {isOpenHistory && (
          <CommonHistoryModal
            fetchingData={fetchingData}
            setFetchingData={setFetchingData}
            isOpen={isOpenHistory}
            items={historyArray}
            setClose={closeHistory}
            currentItem={currentItem}
          />
        )}
      </Spin>
    </div>
  );
};

export default AmendmentListInfo;
