import React, { createContext, useReducer } from 'react';
import { initialState, reducer } from '../reducers/productsReducer';

export const ProductsContext = createContext({} as any);

const ProductsContextProvider: React.FC = (props: any) => {
  const [products, productsDispatch] = useReducer(reducer, initialState);

  return <ProductsContext.Provider value={{ products, productsDispatch }}>{props.children}</ProductsContext.Provider>;
};

export default ProductsContextProvider;
