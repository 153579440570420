import React, { useContext, useEffect, useState } from 'react';
import { CHANGE_CURRENT_PAGE } from '../../constants/actionTypes/headerConstants';
import { HeaderContext } from '../../contexts/HeaderContextProvider';
import css from '../../assets/styles/companies.module.css';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Spin, Table, TablePaginationConfig } from 'antd';
import { getColumns } from './components/CompaniesColumns';
import CompaniesService from '../../services/CompaniesService';
import CompaniesDeleteConfirmModal from './components/CompaniesDeleteConfirmModal';
import { useLessThen801 } from '../../helpers/mediaDetect';
import TableMobile from '../common/TableMobile';
import LogoComponent from '../../components/common/LogoComponent';
import { convertToLocalDateTime } from '../../helpers/converToLocalDate';
import { useHistory } from 'react-router';
import CustomOnBoard from '../common/CustomOnBoard';
import BoardTourComponent from '../common/BoardTourComponent';
import { getOnboarding, OnboardingTypes, saveOnboarding } from '../../helpers/onboarding';
import { AppContext } from '../../contexts/AppContextProvider';
import { CompanyModel } from '../../models/CompaniesModel';
import { IAppContext } from '../../typings/IApp';
import i18n from '../../utils/i18n';

const service = new CompaniesService();

const Companies: React.FC<RouteComponentProps> = (props) => {
  const { t } = useTranslation();
  const [items, setItems] = useState<CompanyModel[]>([]);
  const { height: windowHeight } = useWindowDimensions();
  const [fetchingData, setFetchingData] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [idCompany, setIdCompany] = useState<number | null>(null);
  const [nameCompany, setNameCompany] = useState<string | null>(null);
  const {
    app: { user },
  } = useContext<IAppContext>(AppContext);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    skip: 0,
    showSizeChanger: true,
  });
  const option = {
    count: true,
    top: pagination.pageSize,
    current: pagination.current,
    orderBy: ['createdDate desc'],
    skip: 0,
  };
  const { headerDispatch } = useContext(HeaderContext);
  const history = useHistory();
  const currentPath = props.history.location.pathname;

  //board
  const [steps, setSteps] = useState<
    {
      target: string;
      content: JSX.Element;
      disableBeacon: boolean;
      placement?: string;
    }[]
  >([
    {
      target: '.company-first-step',
      content: (
        <BoardTourComponent title={t('board.company.first.title')} description={t('board.company.first.text')} />
      ),
      disableBeacon: true,
      placement: 'left',
    },
  ]);
  const [run, setRun] = useState(false);

  useEffect(() => {
    headerDispatch({
      type: CHANGE_CURRENT_PAGE,
      currentPage: t('companies.title.header'),
      path: 'companies',
    });
    getCompanies(option);
  }, [i18n.language]);

  const getCompanies = (option: { [name: string]: any }) => {
    setFetchingData(true);
    service
      .getCompanies(option)
      .then((res) => {
        const { count, items } = res;
        const { current, top } = option;
        setPagination({ ...pagination, current, total: count, pageSize: top });
        setItems(items);
        if (count > 0) {
          setSteps([
            ...steps,
            {
              target: '.company-second-step',
              disableBeacon: true,
              content: (
                <BoardTourComponent
                  title={t('board.company.second.title')}
                  description={t('board.company.second.text')}
                />
              ),
            },
          ]);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setFetchingData(false);
        setRun(getOnboarding(OnboardingTypes.COMPANIES));
      });
  };

  const onTableChange = (pagination?: TablePaginationConfig) => {
    let params: { [name: string]: any } = {};

    if (pagination && pagination.pageSize && pagination.current) {
      params = {
        ...option,
        top: pagination.pageSize,
        skip: pagination.pageSize * (pagination.current - 1),
        current: pagination.current,
      };
    }

    getCompanies(params);
  };
  const deleteCompany = (id: number, name: string) => {
    setIdCompany(id);
    setNameCompany(name);
    setOpenModal(true);
  };

  const loadMore = (option: { [name: string]: any }) => {
    setFetchingData(true);
    option.skip = option.skip ? option.skip + 10 : 10;
    service
      .getCompanies(option)
      .then((res) => {
        const { count } = res;
        const { current, top } = option;
        setPagination({ ...pagination, current, total: count, pageSize: top });
        setItems([...items, ...res.items]);
      })
      .catch((e) => console.log(e))
      .finally(() => setFetchingData(false));
  };

  if (useLessThen801()) {
    const data =
      items.length > 0 &&
      (items.map((item) => [
        {
          title: '#',
          description: <LogoComponent id={item.id} name={`${item.companyName} `} image={item.logo || ''} />,
          type: 'string',
        },
        { title: t('projects.table.columns.name'), description: item.companyName, type: 'string' },
        {
          title: t('projects.table.columns.createdDate'),
          description: item.createdDate ? convertToLocalDateTime(item.createdDate) : '',
          type: 'string',
        },
        {
          title: t('projects.table.columns.updatedDate'),
          description: item.updatedDate ? convertToLocalDateTime(item.updatedDate) : '',
          type: 'string',
        },
        {
          title: t('budget.table.action'),
          type: 'button',
          openByFunction: true,
          btnsInfo: [
            {
              function: () => history.push(`/companies/modify/${item.id}`),
              functionBtnTitle: t('common.btn.modify.lower'),
            },
            {
              function: () => deleteCompany(item.id, item.companyName),
              functionBtnTitle: t('common.title.delete'),
            },
          ],
        },
      ]) as any);

    return (
      <>
        <div className="common-mobile-title-container">
          <span style={{ fontSize: '20px' }}>{t('companies.title.header')}</span>
          <div className={css['project-control-container']}>
            <Link to={`${currentPath}/modify`} style={{ borderRadius: 4 }} className="common-animation-primary">
              <Button className={`common-blue-btn common-primary-btn`} style={{ height: '42px' }}>
                {t('common.btn.new')}
              </Button>
            </Link>
          </div>
        </div>
        <TableMobile
          data={data}
          isLoading={fetchingData}
          loadMore={loadMore}
          option={option}
          itemsLength={items.length}
          totalItems={pagination.total}
        />
      </>
    );
  }
  return (
    <Spin spinning={fetchingData}>
      <CustomOnBoard
        steps={steps}
        run={run}
        onFinish={() => saveOnboarding(OnboardingTypes.COMPANIES, user.boardCheckpoint)}
      />
      <div className={css['companies-control-container']}>
        <Link
          to={`${currentPath}/modify`}
          style={{ borderRadius: 4 }}
          className="common-animation-primary company-first-step">
          <Button className={`common-blue-btn common-primary-btn`} style={{ height: '42px' }}>
            {t('common.btn.new')}
          </Button>
        </Link>
      </div>
      <div className={css['companies-table-container']}>
        <Table
          columns={getColumns({
            t,
            deleteCompany,
          })}
          dataSource={items}
          showHeader={true}
          scroll={{ y: windowHeight - 280, x: 900 }}
          size="middle"
          pagination={pagination}
          onChange={onTableChange}
          className="consumers-info-table"
          rowClassName="common-table-row--pointer"
          rowKey="id"
        />
      </div>
      {openModal && idCompany && (
        <CompaniesDeleteConfirmModal
          isOpen={openModal}
          currentId={idCompany}
          getCompanies={() => getCompanies(option)}
          companyName={nameCompany}
          setClose={() => {
            setOpenModal(false);
            setIdCompany(null);
            setNameCompany(null);
          }}
        />
      )}
    </Spin>
  );
};

export default Companies;
