import HttpService from './common/httpService';
import config from '../config';
import buildQuery from 'odata-query';
import { ICreateAmend, IUpdateAmend } from '../models/AmendmentModel';

class AmendmentService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getAmendments(propertyId: number | null, option: any) {
    const { top, count, skip, filters, orderBy = null } = option;

    let filter: any = [];

    let defaultFilter: any = {
      'property/id': {
        eq: propertyId,
      },
      isArchive: {
        eq: false,
      },
    };

    if (!propertyId) {
      defaultFilter = {
        isArchive: {
          eq: false,
        },
      };
    }

    if (filters) {
      filters.filter.sort((a: any, b: any) => {
        const check = (item: any) => {
          if (
            Object.values(item).length > 0 &&
            (Object.values(item)[0] as any).length > 0 &&
            Object.values(Object.values(item)[0] as any).length > 0 &&
            Object.values((Object.values(item)[0] as any)[0] as any).length > 0
          ) {
            return Object.keys(Object.values((Object.values(item)[0] as any)[0] as any)[0] as any)[0] == 'contains';
          }
          return false;
        };

        return Object.keys(Object.values(a)[0] as any)[0] == 'contains' || check(a)
          ? -1
          : Object.keys(Object.values(b)[0] as any)[0] == 'contains'
          ? 1
          : 0;
      });
      filter = [...filters.filter, defaultFilter];

      filter.sort((a: any, b: any) => {
        const check = (item: any) => {
          if (
            Object.values(item).length > 0 &&
            (Object.values(item)[0] as any).length > 0 &&
            Object.values(Object.values(item)[0] as any).length > 0 &&
            Object.values((Object.values(item)[0] as any)[0] as any).length > 0
          ) {
            return Object.keys(Object.values((Object.values(item)[0] as any)[0] as any)[0] as any)[0] == 'contains';
          }
          return false;
        };

        return Object.keys(Object.values(a)[0] as any)[0] == 'contains' || check(a)
          ? -1
          : Object.keys(Object.values(b)[0] as any)[0] == 'contains'
          ? 1
          : 0;
      });
    } else {
      filter = defaultFilter;
    }
    const query = buildQuery({ filter, top, count, skip, orderBy });
    return this.httpService.get(`${config.baseRequest}amendments${query}`);
  }

  public getArchiveAmendments(propertyId: number | null, option: any) {
    const { top, count, skip, filters, orderBy = null } = option;

    let filter: any = [];

    let defaultFilter: any = {
      'property/id': {
        eq: propertyId,
      },
      isArchive: {
        eq: true,
      },
    };

    if (!propertyId) {
      defaultFilter = {
        isArchive: {
          eq: true,
        },
      };
    }

    if (filters) {
      filters.filter.sort((a: any, b: any) => {
        const check = (item: any) => {
          if (
            Object.values(item).length > 0 &&
            (Object.values(item)[0] as any).length > 0 &&
            Object.values(Object.values(item)[0] as any).length > 0 &&
            Object.values((Object.values(item)[0] as any)[0] as any).length > 0
          ) {
            return Object.keys(Object.values((Object.values(item)[0] as any)[0] as any)[0] as any)[0] == 'contains';
          }
          return false;
        };

        return Object.keys(Object.values(a)[0] as any)[0] == 'contains' || check(a)
          ? -1
          : Object.keys(Object.values(b)[0] as any)[0] == 'contains'
          ? 1
          : 0;
      });
      filter = [...filters.filter, defaultFilter];

      filter.sort((a: any, b: any) => {
        const check = (item: any) => {
          if (
            Object.values(item).length > 0 &&
            (Object.values(item)[0] as any).length > 0 &&
            Object.values(Object.values(item)[0] as any).length > 0 &&
            Object.values((Object.values(item)[0] as any)[0] as any).length > 0
          ) {
            return Object.keys(Object.values((Object.values(item)[0] as any)[0] as any)[0] as any)[0] == 'contains';
          }
          return false;
        };

        return Object.keys(Object.values(a)[0] as any)[0] == 'contains' || check(a)
          ? -1
          : Object.keys(Object.values(b)[0] as any)[0] == 'contains'
          ? 1
          : 0;
      });
    } else {
      filter = defaultFilter;
    }
    const query = buildQuery({ filter, top, count, skip, orderBy });
    return this.httpService.get(`${config.baseRequest}amendments${query}`);
  }
  public getAmendmentById(id: number) {
    return this.httpService.get(`${config.baseRequest}amendments/${id}`);
  }
  public createAmendment(body: ICreateAmend) {
    return this.httpService.post(`${config.baseRequest}amendments`, body);
  }
  public uploadAmendmentFiles(id: number, body: any) {
    return this.httpService.postFile(`${config.baseRequest}amendments/${id}/file`, body);
  }
  public createAmedmentNewVersion(id: number, body: any) {
    return this.httpService.post(`${config.baseRequest}amendments/${id}/newVersion `, body);
  }
  public deleteAmedment(id: number) {
    return this.httpService.delete(`${config.baseRequest}amendments/${id}`);
  }
  public updateAmendment(body: IUpdateAmend) {
    return this.httpService.put(`${config.baseRequest}amendments`, body);
  }
  public confirmStep(envelopeId?: string, id?: number) {
    return this.httpService.patch(`${config.baseRequest}amendments/step/done`, envelopeId ? { envelopeId } : { id });
  }

  public getSigningUrl(id: number, returnUrl: string, consumerId?: number) {
    return this.httpService.post(`${config.baseRequest}amendments/step/redirect`, { id, consumerId, returnUrl });
  }
  public getAmendmentFile = (fileName: string) => {
    return this.httpService.getFile(`${config.baseRequest}file/binary/${fileName}`).then((res: any) => {
      let file = new File([res], fileName);
      return file;
    });
  };
  public approveAmendment(id: number) {
    return this.httpService.put(`${config.baseRequest}amendments/${id}/approve`, {});
  }
  public rejectAmendment(id: number | null, comment: string, envelopeId?: string) {
    const body = id ? { id, comment } : { envelopeId, comment };
    return this.httpService.patch(`${config.baseRequest}amendments/step/reject`, body);
  }
  public getAmendmentHistory(id: number) {
    return this.httpService.get(`${config.baseRequest}amendments/${id}/history`);
  }
}

export default AmendmentService;
