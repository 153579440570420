import { Button, Form, FormInstance, Input, InputNumber } from 'antd';
import React, { useState } from 'react';
import css from '../../../../assets/styles/budget.module.css';
import {
  convertToCurrency,
  convertToPriceWithSpace,
  reverseConvertToPriceWithSpace,
} from '../../../../helpers/convertToPrice';
import { ICFC, ICFCitem } from '../../../../models/CFCModel';
import addFieldIcon from '../../../../assets/images/ic-addField.svg';
import { TFunction } from 'react-i18next';

interface ColumnsProps {
  t: TFunction<'translation'>;
  form: FormInstance<any>;
  dataSource: { index: number; quantity: number; price: number }[];
  setDataSource: (v: { index: number; quantity: number; price: number }[]) => void;
  CFC: ICFC[];
  getCFCitems: (code: string) => void;
  CFCitems?: ICFCitem[];
  setIsNeedUpdateSum: (b: boolean) => void;
  currency: string;
}

export const getColumns = ({
  t,
  form,
  dataSource,
  setDataSource,
  CFC,
  getCFCitems,
  CFCitems,
  setIsNeedUpdateSum,
  currency,
}: ColumnsProps) => [
  {
    title: t('budget.invoice.column.pos'),
    key: 'cfc',
    width: '100px',
    render: (cell: any, record: any, index: number) => {
      const [value, setValue] = useState('');
      const [showOptions, setShowOptions] = useState(false);

      const handleSelect = (code: string) => {
        const values = { ...form.getFieldsValue() };
        if (values && values.items.length > 0) {
          values.items[index].cfc = code;
          form.setFieldsValue(values);
          setValue(code);
        }
      };

      return (
        <>
          <Form.Item name={['items', index, 'id']} style={{ marginBottom: 0, display: 'none' }}>
            <Input style={{ display: 'none' }} />
          </Form.Item>
          <Form.Item name={['items', index, 'cfc']} style={{ marginBottom: 0 }}>
            <Input
              onPressEnter={(e) => e.preventDefault()}
              value={value}
              className={`common-animation-primary ${css['custom-select-ref']}`}
              style={{ border: 'none', borderBottom: '1px solid black' }}
              onChange={(e) => handleSelect(e.target.value)}
              onFocus={() => setShowOptions(true)}
              onBlur={() => {
                setTimeout(() => setShowOptions(false), 200);
              }}
              min={1}
            />
            {showOptions && CFC && CFC.length > 0 && (
              <div className={css['custom-select']}>
                {CFC.filter((item) => item.code?.toLowerCase().includes(value?.toLocaleLowerCase())).map(
                  (item, index) => (
                    <div key={index} className={css['custom-select-option']} onClick={() => handleSelect(item.code)}>
                      {item.code}
                    </div>
                  ),
                )}
              </div>
            )}
          </Form.Item>
        </>
      );
    },
  },
  {
    title: t('budget.invoice.column.task.name'),
    key: 'name',
    render: (cell: any, record: any, index: number) => {
      const [showComment, setShowComment] = useState(false);
      const [showOptions, setShowOptions] = useState(false);
      const [name, setName] = useState('');

      const handleSelect = (description: string, sum?: number | null, unit?: string, id?: number) => {
        const values = { ...form.getFieldsValue() };
        if (values && values.items.length > 0) {
          values.items[index].name = description;
          if (id) {
            values.items[index].id = id;
          }
          if (sum) {
            values.items[index].price = convertToCurrency(sum);
          }
          if (unit) {
            values.items[index].unit = unit;
          }
          form.setFieldsValue(values);
          setName(description);
          setIsNeedUpdateSum(true);
        }
      };

      const onFocus = () => {
        const values = { ...form.getFieldsValue() };
        if (values.items[index]?.cfc) {
          getCFCitems(values.items[index].cfc);
        }
        setShowOptions(true);
      };

      return (
        <>
          <div style={{ display: 'flex' }}>
            <Form.Item
              name={['items', index, 'name']}
              className={css['custom-select-container']}
              initialValue={
                form.getFieldsValue() && form.getFieldsValue()?.items?.length > 0
                  ? form.getFieldsValue().items[index]?.name || ''
                  : ''
              }
              rules={[{ required: true, message: t('common.validation.required') }]}
              style={{ marginBottom: 0, width: '100%' }}>
              <Input
                onPressEnter={(e) => e.preventDefault()}
                value={name}
                onChange={(e) => handleSelect(e.target.value)}
                onFocus={onFocus}
                allowClear
                onBlur={() => setTimeout(() => setShowOptions(false), 200)}
                className={`common-animation-primary ${css['custom-select-ref']}`}
                style={{ border: 'none', borderBottom: '1px solid black' }}
              />
              {showOptions && CFCitems && CFCitems.length > 0 && (
                <div className={css['custom-select']}>
                  {CFCitems.filter((item) => item.description?.toLowerCase().includes(name?.toLocaleLowerCase())).map(
                    (item, index) => (
                      <div
                        key={index}
                        className={css['custom-select-option']}
                        onClick={() => handleSelect(item.description, item.sum, item.unit, item.id)}>
                        {item.description}
                      </div>
                    ),
                  )}
                </div>
              )}
            </Form.Item>
            <img src={addFieldIcon} style={{ marginLeft: '1rem' }} onClick={() => setShowComment(true)} />
          </div>
          {showComment && (
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
              <Form.Item
                name={['items', index, 'comment']}
                className={css['custom-select-container']}
                style={{ marginBottom: 0, width: '100%' }}>
                <Input
                  onPressEnter={(e) => e.preventDefault()}
                  className={`common-animation-primary`}
                  style={{ border: 'none', borderBottom: '1px solid black' }}
                />
              </Form.Item>
              <div
                className="icIconClose"
                style={{ width: 20, height: 20, marginLeft: '1rem' }}
                onClick={() => {
                  setShowComment(false);
                }}
              />
            </div>
          )}
        </>
      );
    },
  },
  {
    title: t('budget.invoice.column.quantity'),
    key: 'quantity',
    width: '50px',
    render: (cell: any, record: any, index: number) => {
      const checkValue = (value: number | string) => {
        const values = { ...form.getFieldsValue() };
        if (values && values.items.length > 0) {
          values.items[index].quantity = value && +value && +value > 1 ? value : 1;
          form.setFieldsValue(values);
          setIsNeedUpdateSum(true);
        }
      };
      return (
        <Form.Item
          name={['items', index, 'quantity']}
          initialValue={1}
          style={{ marginBottom: 0 }}
          rules={[{ required: true, message: t('common.validation.required') }]}>
          <InputNumber
            onPressEnter={(e) => e.preventDefault()}
            className="common-animation-primary"
            onChange={checkValue}
            style={{ border: 'none', borderBottom: '1px solid black' }}
            parser={(value) => Number(value!.replace(/[,]/g, '.'))}
            min={1}
          />
        </Form.Item>
      );
    },
  },
  {
    title: t('cfc.unit'),
    key: 'unit',
    width: '60px',
    render: (cell: any, record: any, index: number) => (
      <Form.Item name={['items', index, 'unit']} style={{ marginBottom: 0 }}>
        <Input
          className="common-animation-primary"
          style={{ border: 'none', borderBottom: '1px solid black' }}
          onPressEnter={(e) => e.preventDefault()}
        />
      </Form.Item>
    ),
  },
  {
    title: t('cfc.price'),
    key: 'price',
    width: '100px',
    render: (cell: any, record: any, index: number) => {
      return (
        <Form.Item
          name={['items', index, 'price']}
          style={{ marginBottom: 0 }}
          initialValue={0}
          rules={[{ required: true, message: t('common.validation.required') }]}>
          <InputNumber
            onPressEnter={(e) => e.preventDefault()}
            className="common-animation-primary"
            style={{ border: 'none', borderBottom: '1px solid black' }}
            formatter={(value) => convertToPriceWithSpace(value)}
            parser={(value) => reverseConvertToPriceWithSpace(value, currency)}
          />
        </Form.Item>
      );
    },
  },
  {
    title: t('budget.invoice.column.total'),
    key: 'total',
    render: (cell: any, record: any, index: number) => {
      const total =
        form.getFieldValue(['items', index, 'quantity']) && form.getFieldValue(['items', index, 'price'])
          ? form.getFieldValue(['items', index, 'quantity']) * form.getFieldValue(['items', index, 'price'])
          : form.getFieldValue(['items', index, 'price']);
      return (
        <Form.Item name={['items', index, 'total']} style={{ marginBottom: 0 }}>
          {convertToPriceWithSpace(total)}
        </Form.Item>
      );
    },
  },
  {
    title: '',
    key: 'delete',
    render: (cell: any, record: any, index: number) => {
      const deleteItem = () => {
        const newItems = dataSource.slice();
        const items = newItems.filter((_, itemIndex) => itemIndex !== index);
        const fieldValue = form.getFieldsValue();
        const newFieldsItems = fieldValue.items.filter((_: any, itemIndex: number) => itemIndex !== index);
        form.setFieldsValue({ ...fieldValue, items: newFieldsItems });
        setDataSource(items);
        setIsNeedUpdateSum(true);
      };
      return (
        <Button className={css['delete-btn']} style={{ border: 'none' }} onClick={() => deleteItem()}>
          <span className={css['delete-icon']}></span>
        </Button>
      );
    },
  },
];
