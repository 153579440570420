import React, { useState } from 'react';
import { Button, Modal, Spin, Alert, Space, Input, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import css from '../../assets/styles/signConfirm.module.css';
import { useForm } from 'antd/lib/form/Form';

interface Props {
  id?: number | null;
  setClose: () => void;
  isOpen: boolean;
  sign: () => void;
  reject: (id: number | null, comment: string) => void;
  fetchingData?: boolean;
  signInfo?: string | null;
}

const CANCEL_SHOWING_TIME = 15000; // millisec

const SigningConfirmForm = (props: Props) => {
  const { id = null, setClose, isOpen, sign, reject, fetchingData = false, signInfo } = props;
  const { t } = useTranslation();
  const [form] = useForm();

  const [isReject, setIsReject] = useState(false);
  const [isSigning, setIsSigning] = useState(false);
  const [isShowCancel, setIsShowCancel] = useState(false);

  const onSubmit = (value: { comment: string }) => {
    reject(id, value.comment);
  };

  const onClickReject = () => {
    setIsReject(true);
  };

  const onClickSign = () => {
    sign();
    setIsSigning(true);
    setTimeout(() => {
      setIsShowCancel(true);
    }, CANCEL_SHOWING_TIME);
  };

  return (
    <div>
      <Spin spinning={fetchingData}>
        <Modal
          className={css['confirm-sign-modal']}
          centered
          visible={isOpen}
          width={450}
          footer={
            <div className={css['confirm-sign-modal-footer']}>
              {!isReject
                ? [
                    <Button
                      key="sign"
                      className={`${css['confirm-sign-modal-save-btn']} common-modal-btn`}
                      onClick={onClickSign}
                      disabled={fetchingData}>
                      {t('common.title.sign')}
                    </Button>,
                    <Button
                      key="reject"
                      className={css['confirm-sign-modal-reject-btn']}
                      onClick={onClickReject}
                      disabled={fetchingData}>
                      {t('quotes.status.reject.btn')}
                    </Button>,
                    <Button
                      key="camcel"
                      className={`${css['confirm-sign-modal-cancel-btn']} common-secondary-btn`}
                      onClick={setClose}
                      disabled={fetchingData}>
                      {t('common.btn.cancel')}
                    </Button>,
                  ]
                : [
                    <Button
                      key="yes"
                      className={css['confirm-sign-modal-reject-btn']}
                      onClick={() => form.submit()}
                      disabled={fetchingData}>
                      {t('quotes.status.reject.btn')}
                    </Button>,
                    <Button
                      key="no"
                      className={`${css['confirm-sign-modal-cancel-btn']} common-secondary-btn`}
                      onClick={setClose}
                      disabled={fetchingData}>
                      {t('common.btn.cancel')}
                    </Button>,
                  ]}
            </div>
          }
          closable={false}>
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <div>
              <span className={css['confirm-sign-modal-title']}>
                {!isReject ? t('quotes.modal.confirm.signing.message') : t('quotes.modal.confirm.reject.message')}
              </span>
            </div>
            {signInfo && !isReject && <Alert description={signInfo} type="info" showIcon />}
            {isReject && (
              <Form form={form} layout="vertical" onFinish={onSubmit}>
                <Form.Item
                  labelAlign="left"
                  label={
                    <span key="label-comment" style={{ color: '#778dac' }}>
                      {t('budget.form.cancel.comment')}
                    </span>
                  }
                  name="comment"
                  rules={[
                    {
                      required: true,
                      message: t('common.validation.required'),
                    },
                  ]}
                  style={{ marginBottom: 0 }}>
                  <Input.TextArea rows={4} />
                </Form.Item>
              </Form>
            )}
            {isSigning && (
              <Alert
                message={t('quotes.modal.confirm.signing.info')}
                type="info"
                showIcon
                banner
                action={
                  <>
                    {isShowCancel && (
                      <Button type="link" size="small" onClick={setClose}>
                        Cancel
                      </Button>
                    )}
                  </>
                }
              />
            )}
          </Space>
        </Modal>
      </Spin>
    </div>
  );
};

export default SigningConfirmForm;
