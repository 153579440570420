import React from 'react';
import { Button, Dropdown, Menu, Badge, Space, Typography } from 'antd';
import css from '../../../assets/styles/roles.module.css';
import moreIcon from '../../../assets/images/ic-more.svg';
import { convertToLocalDateTime } from '../../../helpers/converToLocalDate';
import { getColumnSearchProps } from '../../../helpers/getColumnSearchProps';
import { TFunction } from 'react-i18next';

export const statusColor: any = {
  new: 'default',
  processing: 'processing',
  done: 'success',
  deleted: 'error',
  draft: 'warning',
};

interface ConsumerRequestsColumnsModel {
  onDelete?: (id: number) => void;
  onSetStatus: ({ item, key, keyPath, domEvent, id }: any) => void;
  history: any;
  t: TFunction<'translation'>;
  statuses?: any[];
}

export const getColumns = ({ onDelete, onSetStatus, history, t, statuses }: ConsumerRequestsColumnsModel) =>
  [
    {
      title: () => <span className="budget-table-tr">{t('requests.table.columns.consumer')}</span>,
      key: 'consumer',
      render: (cell: any) => (
        <Space size="small">
          <Typography.Text>{cell?.consumer?.email}</Typography.Text>
          <Typography.Text type="secondary">({`${cell?.consumer?.name} ${cell?.consumer?.surname}`})</Typography.Text>
        </Space>
      ),
      width: '30%',
      ...getColumnSearchProps(),
    },
    {
      title: () => <span className="budget-table-tr">{t('requests.table.columns.phone')}</span>,
      key: 'consumer-phone',
      sorter: true,
      render: (cell: any) => cell?.consumer?.phone || '',
      width: '18%',
      ...getColumnSearchProps(),
    },
    {
      title: () => <span className="budget-table-tr">{t('requests.table.columns.status')}</span>,
      key: 'status-description',
      sorter: true,
      filters: statuses?.map((status: any) => ({ text: status.description, value: status.name })),
      render: (cell: any) => <Badge status={statusColor[cell?.status?.name]} text={cell?.status?.description} />,
      width: '22%',
    },
    {
      title: () => <span className="budget-table-tr">{t('requests.table.columns.createdDate')}</span>,
      dataIndex: 'createdDate',
      key: 'createdDate',
      sorter: true,
      render: (cell: any) => (cell ? convertToLocalDateTime(cell) : ''),
      width: '18%',
    },
    {
      title: () => <span className="budget-table-tr">{t('requests.table.columns.action')}</span>,
      key: 'action',
      fixed: 'right',
      render: (cell: any) => {
        const menu = (
          <Menu onClick={(props: any) => onSetStatus({ ...props, id: cell.id })}>
            <Menu.Item key="view" onClick={() => history.push(`/requests/view/${cell.id}`)}>
              <Button type="link">{t('common.title.view')}</Button>
            </Menu.Item>
            {statuses && statuses.length > 0 && (
              <Menu.SubMenu
                key="setstatus"
                className="action-sub-menu"
                title={<Button type="link">{t('requests.table.columns.btn.status')}</Button>}>
                {statuses.map((status: any) => (
                  <Menu.Item key={status.name}>
                    <Badge status={statusColor[status?.name]} text={status?.description} />
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            )}
            {onDelete && (
              <Menu.Item key="block" onClick={() => onDelete(cell.id)}>
                <Button type="link">{t('common.title.delete')}</Button>
              </Menu.Item>
            )}
          </Menu>
        );

        return (
          <span>
            <Dropdown overlay={menu} trigger={['click']}>
              <Button className={css['roles-dropdown-btn']}>
                <img src={moreIcon} alt="" height={15} width={15} />
              </Button>
            </Dropdown>
          </span>
        );
      },
      width: '12%',
    },
  ] as any[];
