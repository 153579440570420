import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Button,
  Row,
  Col,
  Tabs,
  Card,
  Input,
  InputNumber,
  Upload,
  Spin,
  Table,
  notification,
  Descriptions,
  TreeSelect,
} from 'antd';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import ProductService from '../../services/ProductService';
import { InboxOutlined, UnorderedListOutlined, PlusOutlined } from '@ant-design/icons';
import DynamicFields from '../common/DynamicFields';
import { imageURLToAntdModel } from '../../helpers/imageToAntdModel';
import ArticlesService from '../../services/ArticlesService';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { getColumns as getArticlesProductColumns } from './components/ArticleProductColumns';
import { productParamCodes } from '../../constants/productParamCodes';
import { articleCategoryTypes } from '../../constants/articleCategoryTypes';
import { productFileTypes } from '../../constants/productFileTypes';
import { ProductsContext } from '../../contexts/ProductsContextProvider';
import { PRODUCTS_SET_DUPLICATE } from '../../constants/actionTypes/productsConstants';
import css from '../../assets/styles/product.module.css';
import { useLessThen801 } from '../../helpers/mediaDetect';

const service: ProductService = new ProductService();
const articleService: ArticlesService = new ArticlesService();

const ProductModifyPage: React.FC = (props: any) => {
  const { t } = useTranslation();
  const { id } = useParams<any>();
  const history = useHistory();
  const { productsDispatch } = useContext(ProductsContext);
  const isCreate = !id;
  const [form] = Form.useForm();
  const [product, setProduct] = useState<any>(undefined);
  const [original, setOriginal] = useState<any>(undefined);
  const [fields, setFields] = useState<any>([]);
  const [productParams, setProductParams] = useState<any[]>([]);
  const [articleParams, setArticleParams] = useState<any[]>([]);
  const [currentArticle, setCurrentArticle] = useState<any>(undefined);
  const [images, setImages] = useState<any>([]);
  const [deletedImages, setDeletedImages] = useState<number[]>([]);
  const [articleImages, setArticleImages] = useState([]);
  const [deletedArticleImages, setDeletedArticleImages] = useState<number[]>([]);
  const [articleCategories, setArticleCategories] = useState([]);
  const [breadcrumbNameMap, setBreadcrumbNameMap] = useState<{ [name: string]: string }>({});
  const [textureFile, setTextureFile] = useState<any>([]);
  const [glbFile, setGlbFile] = useState<any>([]);

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const [isShowAddSelect, setIsShowAddSelect] = useState(false);
  const [isShowArticleAddSelect, setIsShowArticleAddSelect] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isUploadTexture, setIsUploadTexture] = useState<boolean>(false);
  const [isUploadGlbFile, setIsUploadGlbFile] = useState<boolean>(false);
  const [isShowTexture, setIsShowTexture] = useState<boolean>(false);
  const [isShowGblFile, setIsShowGblFile] = useState<boolean>(false);

  const initialMediaComponents = (type: any) => {
    if (type) {
      switch (type.name) {
        case articleCategoryTypes.finishes: {
          setIsShowTexture(true);
          break;
        }
        case articleCategoryTypes.equipment:
        case articleCategoryTypes.interior: {
          setIsShowGblFile(true);
        }
      }
    }
  };

  const getProduct = () => {
    return service.getProductById(id).then((res: any) => {
      setOriginal(res);
      const { article, ...product } = res;
      const { type } = article?.category || {};
      setProduct(product);
      setFields(product.fields.map((field: any) => ({ ...field.field, value: field.value })));
      setCurrentArticle({
        ...article,
        fields: article.fields.map((field: any) => ({ ...field.field, value: field.value })),
        initialFields: article.fields,
      });
      form.setFieldsValue({
        code: product.code,
        price: product.price,
        article: {
          id: article.id,
          name: article.name,
          descriptionEn: article.descriptionEn,
          descriptionFr: article.descriptionFr,
          category: article?.category,
        },
      });
      initialMediaComponents(type);
    });
  };

  const getProductParams = () => {
    return service.getProductParams().then((res: any) => {
      setProductParams(res);
    });
  };

  const getArticleParams = () => {
    return articleService.getArticleFields().then((res: any) => {
      setArticleParams(res);
    });
  };

  const getArticleCategories = () => {
    return articleService.getSimpleArticleCategories().then((res: any) => {
      if (res && res.length > 0) {
        setArticleCategories(
          res.map((el: any) => ({
            ...el,
            parentId: el.parent ? el.parent.id : null,
          })),
        );
      }
    });
  };

  const getAsyncData = async () => {
    const actions = [getProductParams(), getArticleParams(), getArticleCategories()];
    if (!isCreate) {
      actions.unshift(getProduct());
    }
    Promise.all(actions).finally(() => {
      setLoading(false);
    });
  };

  const initialMediaFiles = () => {
    const { images = [] } = product || {};
    const pictures = images
      .filter((image: any) => image.type === productFileTypes.picture)
      .map((image: any) => imageURLToAntdModel({ ...image, name: image.fileName }));
    const textures = images
      .filter((image: any) => image.type === productFileTypes.texture)
      .map((image: any) => imageURLToAntdModel({ ...image, name: image.fileName }));
    const glbs = images
      .filter((image: any) => image.type === productFileTypes.glb)
      .map((image: any) => imageURLToAntdModel({ ...image, name: image.fileName }));
    if (pictures.length > 0) {
      setImages(pictures);
    }
    if (textures.length > 0) {
      setTextureFile(textures);
      setIsUploadTexture(true);
    }
    if (glbs.length > 0) {
      setGlbFile(glbs);
      setIsUploadGlbFile(true);
    }
  };

  useEffect(() => {
    setLoading(true);
    getAsyncData();
  }, [id]);

  useEffect(() => {
    if (isFirstRender) {
      form.resetFields();
      setIsFirstRender(false);
    }
    initialMediaFiles();
  }, [product]);

  useEffect(() => {
    const { images } = currentArticle || {};
    setArticleImages(images ? images.map((image: any) => imageURLToAntdModel({ ...image, name: image.fileName })) : []);
  }, [currentArticle]);

  useEffect(() => {
    setBreadcrumbNameMap(
      history.location.pathname
        .replace('/products', '')
        .replace(/\/modify\/\d+/, '/Modifications')
        .split('/')
        .slice(1)
        .reduce(
          (res: any, curr: any, index: number) => ({
            ...res,
            [[Object.keys(res)[index], curr].join('/')]: curr.split(':').shift(),
          }),
          { '/products': t('product.bread.root') },
        ),
    );
  }, [history.location.pathname]);

  const onSubmit = (values: any) => {
    setIsSaving(true);
    const modelActions = (id: number) => {
      const articleId = currentArticle.id;
      const { article: updateArticle, articleFields } = values;
      const { initialFields = [] } = currentArticle;
      const promises: Promise<any>[] = [];
      const hasOwnProperty = Object.prototype.hasOwnProperty;
      const newImages = images.filter((image: any) => hasOwnProperty.call(image, 'originFileObj'));
      if (newImages.length > 0) {
        promises.push(
          service.uploadImages({
            id,
            type: productFileTypes.picture,
            files: newImages,
          }),
        );
      }

      if (isUploadTexture && textureFile) {
        promises.push(
          service.uploadImages({
            id,
            type: productFileTypes.texture,
            files: textureFile,
          }),
        );
      }

      if (isUploadGlbFile && glbFile) {
        promises.push(
          service.uploadImages({
            id,
            type: productFileTypes.glb,
            files: glbFile,
          }),
        );
      }

      if (deletedImages.length > 0) {
        promises.push(service.deleteImages(deletedImages));
      }

      const newArticleImages = articleImages.filter((image: any) => hasOwnProperty.call(image, 'originFileObj'));
      if (newArticleImages.length > 0) {
        promises.push(
          articleService.uploadImages({
            id: articleId,
            type: 'picture',
            files: newArticleImages,
          }),
        );
      }
      if (deletedArticleImages.length > 0) {
        promises.push(articleService.deleteImages(deletedArticleImages));
      }

      if (updateArticle) {
        promises.push(
          articleService.updateArticle({
            ...updateArticle,
            id: articleId,
            fields: Object.entries(articleFields || []).map(([code, element]: [any, any]) => ({
              id: initialFields.length > 0 ? initialFields.find((item: any) => item.field.code === code)?.id : null,
              field: { code },
              value: element.value || null,
            })),
          }),
        );
      }
      return Promise.all(promises);
    };
    const { images: bodyImages, article: removedArticle, articleFields, ...data } = values;
    const body = {
      id: +id,
      ...data,
      fields: Object.entries(values?.fields || []).map(([code, element]: [any, any]) => ({
        id: product?.fields?.length > 0 ? product?.fields?.find((item: any) => item.field.code === code)?.id : null,
        field: { code },
        value:
          product?.fields?.find((item: any) => item?.field?.code === code)?.field?.type?.code === 'localeString'
            ? JSON.stringify(element)
            : element.value || null,
      })),
    };
    service.updateProduct(body).then(() => {
      modelActions(id)
        .then(() => {
          history.push('/products');
        })
        .catch((e) => {
          console.error(e);
          notification.error({
            message: `Error while product create`,
          });
        })
        .finally(() => setIsSaving(false));
    });
  };

  const onCancel = () => {
    history.push(`/products`);
  };

  const handleShowSelect = (isShow?: boolean) => {
    setIsShowAddSelect(isShow === undefined ? !isShowAddSelect : isShow);
  };

  const handleShowArticleSelect = (isShow?: boolean) => {
    setIsShowArticleAddSelect(isShow === undefined ? !isShowArticleAddSelect : isShow);
  };

  const setTextureSizeFields = (fileName: string) => {
    const sizeStr: any = fileName.match(/\([0-9]*(x|X)[0-9]*\)/g);
    if (sizeStr) {
      const sizeArr = sizeStr[0]
        .replace(/[\(\)]/g, '')
        .toLowerCase()
        .split('x');
      const width = sizeArr[0];
      const height = sizeArr[1];
      const widthObj = fields?.find((item: any) => item.code === productParamCodes.texture_size_w);
      const heightObj = fields?.find((item: any) => item.code === productParamCodes.texture_size_h);

      if (!widthObj) {
        const textureSizeWidth = productParams.find((param: any) => param.code === productParamCodes.texture_size_w);
        if (textureSizeWidth) {
          fields.push({ ...textureSizeWidth, value: width });
        }
      }

      if (!heightObj) {
        const textureSizeHeight = productParams.find((param: any) => param.code === productParamCodes.texture_size_h);
        if (textureSizeHeight) {
          fields.push({ ...textureSizeHeight, value: height });
        }
      }

      form.setFieldsValue({
        fields: {
          [productParamCodes.texture_size_w]: { value: width },
          [productParamCodes.texture_size_h]: { value: height },
        },
      });

      setFields([...fields]);
    }
  };

  const onChangeImages = (info: UploadChangeParam<UploadFile<any>>) => {
    const { file, fileList }: any = info;
    const hasOwnProperty = Object.prototype.hasOwnProperty;
    setImages(fileList);
    if (file?.status === 'removed' && !hasOwnProperty.call(file, 'originFileObj')) {
      setDeletedImages([...deletedImages, +file.uid]);
    }
  };

  const onChangeTexture = (info: UploadChangeParam<UploadFile<any>>) => {
    const { file, fileList }: any = info;
    setTextureFile(file?.status !== 'removed' ? fileList : null);
    setIsUploadTexture(file?.status !== 'removed');
    const hasOwnProperty = Object.prototype.hasOwnProperty;
    if (file?.status === 'removed' && !hasOwnProperty.call(file, 'originFileObj')) {
      setDeletedImages([...deletedImages, +file.uid]);
    }
    if (file?.status !== 'removed') {
      setTextureSizeFields(file.name);
    }
  };

  const onChangeGlbFile = (info: UploadChangeParam<UploadFile<any>>) => {
    const { file, fileList }: any = info;
    setGlbFile(file?.status !== 'removed' ? fileList : null);
    setIsUploadGlbFile(file?.status !== 'removed');
    const hasOwnProperty = Object.prototype.hasOwnProperty;
    if (file?.status === 'removed' && !hasOwnProperty.call(file, 'originFileObj')) {
      setDeletedImages([...deletedImages, +file.uid]);
    }
  };

  const onChangeArticleImages = (info: UploadChangeParam<UploadFile<any>>) => {
    const { file, fileList }: any = info;
    const hasOwnProperty = Object.prototype.hasOwnProperty;
    setArticleImages(fileList);
    if (file?.status === 'removed' && !hasOwnProperty.call(file, 'originFileObj')) {
      setDeletedArticleImages([...deletedArticleImages, +file.uid]);
    }
  };

  const isInitialValueRequired = () => {
    const article = currentArticle;
    if (article && article.category) {
      const { id: categoryId } = article.category;
      const category: any = articleCategories.find((item: any) => item.id === categoryId);
      return category?.type?.name !== articleCategoryTypes.interior;
    }
    return true;
  };

  const onDuplicateProduct = () => {
    productsDispatch({
      type: PRODUCTS_SET_DUPLICATE,
      duplicate: original,
    });
    history.push('/products/create');
  };

  if (useLessThen801()) {
    return (
      <Spin spinning={isSaving}>
        <Form autoComplete="off" form={form} layout="vertical" name="modify_product" onFinish={onSubmit}>
          <HeaderTable
            title={!isCreate ? `${t('product.create.title.update')} #${id}` : t('product.create.title.create')}
            breadcrumb={breadcrumbNameMap}>
            <div className="products-modify-btns-container">
              <Form.Item>
                <Button
                  htmlType="button"
                  onClick={onDuplicateProduct}
                  className={`common-blue-border-btn common-secondary-btn`}
                  style={{ height: '42px', marginRight: '1rem', marginTop: '1rem' }}>
                  {t('common.btn.duplicate')}
                </Button>
                <Button
                  htmlType="button"
                  onClick={onCancel}
                  className={`common-blue-border-btn common-secondary-btn`}
                  style={{ height: '42px', marginRight: '1rem', marginTop: '1rem' }}>
                  {t('common.btn.cancel')}
                </Button>
                <Button
                  htmlType="submit"
                  className={`common-blue-btn common-primary-btn`}
                  style={{ height: '42px', marginRight: '1rem', marginTop: '1rem' }}>
                  {t('common.btn.save')}
                </Button>
              </Form.Item>
            </div>
          </HeaderTable>
          <Tabs>
            <Tabs.TabPane
              key="product"
              tab={
                <span>
                  <InboxOutlined />
                  {t('product.create.tabs.product')}
                </span>
              }
              forceRender={true}>
              <Row gutter={[24, 4]}>
                <Col span={24}>
                  <Card title={t('product.create.card.basic.info')} loading={loading}>
                    <Row gutter={[24, 4]}>
                      <Col span={24} style={{ paddingBottom: '16px' }}>
                        <Descriptions
                          title={t('product.create.article.info.title')}
                          size="small"
                          layout="vertical"
                          bordered>
                          <Descriptions.Item label={t('product.create.article.info.category.type')}>
                            {currentArticle?.category?.type?.description}
                          </Descriptions.Item>
                          <Descriptions.Item label={t('product.create.article.info.category')}>
                            {currentArticle?.category?.name}
                          </Descriptions.Item>
                          <Descriptions.Item label={t('product.create.article.info.description')}>
                            {currentArticle?.name}
                          </Descriptions.Item>
                        </Descriptions>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label={t('product.create.card.code')}
                          name="code"
                          rules={[
                            { required: isInitialValueRequired(), message: t('product.create.card.code.validation') },
                          ]}>
                          <Input className="common-animation-primary" />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label={t('product.create.card.price')} name="price">
                          <InputNumber className="common-animation-primary" style={{ width: '100%' }} />
                        </Form.Item>
                      </Col>
                      <DynamicFields
                        name="fields"
                        isSelect={isShowAddSelect}
                        handleSelect={handleShowSelect}
                        fields={productParams}
                        values={fields}
                        onChangeValues={(params: any) => setFields(params)}
                      />
                    </Row>
                  </Card>
                </Col>
                <Col span={24}>
                  <Card title={t('product.create.card.media')} loading={loading}>
                    <Row gutter={[24, 4]}>
                      <Col span={24}>
                        <Form.Item label={t('product.create.images')} name="images" valuePropName="filelist">
                          <div className="dropbox">
                            <Upload.Dragger
                              listType="picture"
                              accept=".png, .jpg, .jpeg"
                              beforeUpload={() => false}
                              defaultFileList={images}
                              onChange={onChangeImages}
                              multiple>
                              <p className="ant-upload-drag-icon" style={{ marginTop: 0, marginBottom: 0 }}>
                                <PlusOutlined />
                              </p>
                            </Upload.Dragger>
                          </div>
                        </Form.Item>
                      </Col>
                      {isShowTexture && (
                        <Col span={24}>
                          <Form.Item label={t('product.create.texture')} name="texture" valuePropName="filelist">
                            <Upload
                              listType="picture"
                              accept=".png, .jpg, .jpeg"
                              beforeUpload={() => false}
                              onChange={onChangeTexture}
                              defaultFileList={textureFile}
                              customRequest={() => {}}
                              className="variant-floor-plan">
                              {!isUploadTexture && <Button icon={<PlusOutlined />}>{t('common.btn.upload')}</Button>}
                            </Upload>
                          </Form.Item>
                        </Col>
                      )}
                      {isShowGblFile && (
                        <Col span={24}>
                          <Form.Item label={t('product.create.glb.file')} name="glbFile" valuePropName="filelist">
                            <Upload
                              listType="picture"
                              accept=".glb, .gltf"
                              beforeUpload={() => false}
                              onChange={onChangeGlbFile}
                              defaultFileList={glbFile}
                              customRequest={() => {}}
                              className="variant-floor-plan">
                              {!isUploadGlbFile && <Button icon={<PlusOutlined />}>{t('common.btn.upload')}</Button>}
                            </Upload>
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane
              key="article"
              tab={
                <span>
                  <UnorderedListOutlined />
                  {t('product.create.tabs.article')}
                </span>
              }
              forceRender={true}>
              <Row gutter={[24, 4]}>
                <Col span={24}>
                  <Card title={t('product.create.article.card.basic.info')} loading={loading}>
                    <Row gutter={[24, 4]}>
                      <Col span={24}>
                        <Form.Item
                          label={t('product.create.article.name')}
                          name={['article', 'name']}
                          rules={[{ required: true, message: t('product.create.article.name.validation') }]}>
                          <Input className="common-animation-primary" />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label={t('product.create.article.descriptionEn')}
                          name={['article', 'descriptionEn']}
                          rules={[{ required: true, message: t('product.create.article.description.validation') }]}>
                          <Input className="common-animation-primary" />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label={t('product.create.article.descriptionFr')}
                          name={['article', 'descriptionFr']}
                          rules={[{ required: true, message: t('product.create.article.description.validation') }]}>
                          <Input className="common-animation-primary" />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label={t('product.create.card.article.category')}
                          name={['article', 'category', 'id']}
                          rules={[{ required: true, message: t('product.create.card.article.category.validation') }]}>
                          <TreeSelect
                            className="common-select common-animation-primary"
                            suffixIcon={
                              <div
                                className={`arrow-drop-img ${css['arrow-drop']}`}
                                style={{ width: '8px', height: '4px' }}></div>
                            }
                            allowClear
                            treeData={articleCategories.map((c: any) => ({
                              id: c.id,
                              value: c.id,
                              pId: c.parentId,
                              title: c.name,
                              label: c.name,
                              key: c.id,
                            }))}
                            treeDataSimpleMode
                          />
                        </Form.Item>
                      </Col>
                      <DynamicFields
                        name="articleFields"
                        isSelect={isShowArticleAddSelect}
                        handleSelect={handleShowArticleSelect}
                        fields={articleParams}
                        values={currentArticle?.fields || {}}
                      />
                    </Row>
                  </Card>
                </Col>
                <Col span={24}>
                  <Card title={t('product.create.article.card.media')} loading={loading}>
                    <Row gutter={[24, 4]}>
                      <Col span={24}>
                        <Form.Item label={t('product.create.article.images')} name="images" valuePropName="filelist">
                          <div className="dropbox">
                            <Upload.Dragger
                              listType="picture"
                              accept=".png, .jpg, .jpeg"
                              beforeUpload={() => false}
                              defaultFileList={articleImages}
                              onChange={onChangeArticleImages}
                              multiple>
                              <p className="ant-upload-drag-icon" style={{ marginTop: 0, marginBottom: 0 }}>
                                <PlusOutlined />
                              </p>
                            </Upload.Dragger>
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row style={{ paddingTop: '24px' }}>
                <Col span={24}>
                  <Card title={t('product.create.article.products.list')} loading={loading}>
                    <Table
                      columns={getArticlesProductColumns({
                        t,
                      })}
                      size="small"
                      dataSource={currentArticle?.products || []}
                      showHeader={true}
                      rowClassName="common-table-row--pointer"
                      rowKey="id"
                    />
                  </Card>
                </Col>
              </Row>
            </Tabs.TabPane>
          </Tabs>
        </Form>
      </Spin>
    );
  }

  return (
    <Spin spinning={isSaving}>
      <Form autoComplete="off" form={form} layout="vertical" name="modify_product" onFinish={onSubmit}>
        <HeaderTable
          title={!isCreate ? `${t('product.create.title.update')} #${id}` : t('product.create.title.create')}
          breadcrumb={breadcrumbNameMap}>
          <Form.Item>
            <Button
              htmlType="button"
              onClick={onDuplicateProduct}
              className={`common-blue-border-btn common-secondary-btn`}
              style={{ height: '42px', marginRight: '1rem' }}>
              {t('common.btn.duplicate')}
            </Button>
            <Button
              htmlType="button"
              onClick={onCancel}
              className={`common-blue-border-btn common-secondary-btn`}
              style={{ height: '42px', marginRight: '1rem' }}>
              {t('common.btn.cancel')}
            </Button>
            <Button htmlType="submit" className={`common-blue-btn common-primary-btn`} style={{ height: '42px' }}>
              {t('common.btn.save')}
            </Button>
          </Form.Item>
        </HeaderTable>
        <Tabs>
          <Tabs.TabPane
            key="product"
            tab={
              <span>
                <InboxOutlined />
                {t('product.create.tabs.product')}
              </span>
            }
            forceRender={true}>
            <Row gutter={[24, 4]}>
              <Col span={12}>
                <Card title={t('product.create.card.basic.info')} loading={loading}>
                  <Row gutter={[24, 4]}>
                    <Col span={24} style={{ paddingBottom: '16px' }}>
                      <Descriptions
                        title={t('product.create.article.info.title')}
                        size="small"
                        layout="vertical"
                        bordered>
                        <Descriptions.Item label={t('product.create.article.info.category.type')}>
                          {currentArticle?.category?.type?.description}
                        </Descriptions.Item>
                        <Descriptions.Item label={t('product.create.article.info.category')}>
                          {currentArticle?.category?.name}
                        </Descriptions.Item>
                        <Descriptions.Item label={t('product.create.article.info.description')}>
                          {currentArticle?.name}
                        </Descriptions.Item>
                      </Descriptions>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={t('product.create.card.code')}
                        name="code"
                        rules={[
                          { required: isInitialValueRequired(), message: t('product.create.card.code.validation') },
                        ]}>
                        <Input className="common-animation-primary" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label={t('product.create.card.price')} name="price">
                        <InputNumber className="common-animation-primary" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                    <DynamicFields
                      name="fields"
                      isSelect={isShowAddSelect}
                      handleSelect={handleShowSelect}
                      fields={productParams}
                      values={fields}
                      onChangeValues={(params: any) => setFields(params)}
                    />
                  </Row>
                </Card>
              </Col>
              <Col span={12}>
                <Card title={t('product.create.card.media')} loading={loading}>
                  <Row gutter={[24, 4]}>
                    <Col span={24}>
                      <Form.Item label={t('product.create.images')} name="images" valuePropName="filelist">
                        <div className="dropbox">
                          <Upload.Dragger
                            listType="picture"
                            accept=".png, .jpg, .jpeg"
                            beforeUpload={() => false}
                            defaultFileList={images}
                            onChange={onChangeImages}
                            multiple>
                            <p className="ant-upload-drag-icon" style={{ marginTop: 0, marginBottom: 0 }}>
                              <PlusOutlined />
                            </p>
                          </Upload.Dragger>
                        </div>
                      </Form.Item>
                    </Col>
                    {isShowTexture && (
                      <Col span={24}>
                        <Form.Item label={t('product.create.texture')} name="texture" valuePropName="filelist">
                          <Upload
                            listType="picture"
                            accept=".png, .jpg, .jpeg"
                            beforeUpload={() => false}
                            onChange={onChangeTexture}
                            defaultFileList={textureFile}
                            customRequest={() => {}}
                            className="variant-floor-plan">
                            {!isUploadTexture && <Button icon={<PlusOutlined />}>{t('common.btn.upload')}</Button>}
                          </Upload>
                        </Form.Item>
                      </Col>
                    )}
                    {isShowGblFile && (
                      <Col span={24}>
                        <Form.Item label={t('product.create.glb.file')} name="glbFile" valuePropName="filelist">
                          <Upload
                            listType="picture"
                            accept=".glb, .gltf"
                            beforeUpload={() => false}
                            onChange={onChangeGlbFile}
                            defaultFileList={glbFile}
                            customRequest={() => {}}
                            className="variant-floor-plan">
                            {!isUploadGlbFile && <Button icon={<PlusOutlined />}>{t('common.btn.upload')}</Button>}
                          </Upload>
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                </Card>
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane
            key="article"
            tab={
              <span>
                <UnorderedListOutlined />
                {t('product.create.tabs.article')}
              </span>
            }
            forceRender={true}>
            <Row gutter={[24, 4]}>
              <Col span={12}>
                <Card title={t('product.create.article.card.basic.info')} loading={loading}>
                  <Row gutter={[24, 4]}>
                    <Col span={12}>
                      <Form.Item
                        label={t('product.create.article.name')}
                        name={['article', 'name']}
                        rules={[{ required: true, message: t('product.create.article.name.validation') }]}>
                        <Input className="common-animation-primary" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={t('product.create.article.descriptionEn')}
                        name={['article', 'descriptionEn']}
                        rules={[{ required: true, message: t('product.create.article.description.validation') }]}>
                        <Input className="common-animation-primary" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={t('product.create.article.descriptionFr')}
                        name={['article', 'descriptionFr']}
                        rules={[{ required: true, message: t('product.create.article.description.validation') }]}>
                        <Input className="common-animation-primary" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label={t('product.create.card.article.category')}
                        name={['article', 'category', 'id']}
                        rules={[{ required: true, message: t('product.create.card.article.category.validation') }]}>
                        <TreeSelect
                          className="common-select common-animation-primary"
                          suffixIcon={
                            <div
                              className={`arrow-drop-img ${css['arrow-drop']}`}
                              style={{ width: '8px', height: '4px' }}></div>
                          }
                          allowClear
                          treeData={articleCategories.map((c: any) => ({
                            id: c.id,
                            value: c.id,
                            pId: c.parentId,
                            title: c.name,
                            label: c.name,
                            key: c.id,
                          }))}
                          treeDataSimpleMode
                        />
                      </Form.Item>
                    </Col>
                    <DynamicFields
                      name="articleFields"
                      isSelect={isShowArticleAddSelect}
                      handleSelect={handleShowArticleSelect}
                      fields={articleParams}
                      values={currentArticle?.fields || {}}
                    />
                  </Row>
                </Card>
              </Col>
              <Col span={12}>
                <Card title={t('product.create.article.card.media')} loading={loading}>
                  <Row gutter={[24, 4]}>
                    <Col span={24}>
                      <Form.Item label={t('product.create.article.images')} name="images" valuePropName="filelist">
                        <div className="dropbox">
                          <Upload.Dragger
                            listType="picture"
                            accept=".png, .jpg, .jpeg"
                            beforeUpload={() => false}
                            defaultFileList={articleImages}
                            onChange={onChangeArticleImages}
                            multiple>
                            <p className="ant-upload-drag-icon" style={{ marginTop: 0, marginBottom: 0 }}>
                              <PlusOutlined />
                            </p>
                          </Upload.Dragger>
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row style={{ paddingTop: '24px' }}>
              <Col span={24}>
                <Card title={t('product.create.article.products.list')} loading={loading}>
                  <Table
                    columns={getArticlesProductColumns({
                      t,
                    })}
                    size="small"
                    dataSource={currentArticle?.products || []}
                    showHeader={true}
                    rowClassName="common-table-row--pointer"
                    rowKey="id"
                  />
                </Card>
              </Col>
            </Row>
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </Spin>
  );
};

export default ProductModifyPage;
