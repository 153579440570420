import React, { useContext } from 'react';
import { Form, Button, Input, Card, Row, Col } from 'antd';
import logo from '../../assets/images/logo_dark.svg';
import logo_big from '../../assets/images/login_big.png';
import css from './../../assets/styles/changePassword.module.css';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../contexts/AppContextProvider';
import { DELETE_CHANGE_PASSWORD } from '../../constants/actionTypes/appConstants';

import LoginPageServices from '../../services/LoginPageServices';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { IAppContext } from '../../typings/IApp';

const ChangePasswordPage: React.FC = (props: any) => {
  const { t } = useTranslation();
  const { app, appDispatch } = useContext<IAppContext>(AppContext);
  const [form] = Form.useForm();
  const loginPageServices = new LoginPageServices();

  if (!app.isChangePassword) {
    props.history.push('login');
  }

  if (app.isAuthenticated) {
    props.history.push('/');
  }

  const compareToFirstPassword = (rule: any, value: any, callback: any) => {
    if (value && value !== form.getFieldValue('newPassword')) {
      callback(t('change.password.invalid'));
    } else {
      callback();
    }
  };

  const handleSubmit = (values: any) => {
    const username = app.username;
    loginPageServices
      .changePassword({
        username,
        newPassword: values.newPassword,
        confirmNewPassword: values.confirmNewPassword,
      })
      .then((res: any) => {
        appDispatch({ type: DELETE_CHANGE_PASSWORD });
        props.history.push('login');
      });
  };

  return (
    <Row
      justify="center"
      align="middle"
      style={{ height: '100vh', background: '#f0f2f5' }}
      className={css['changePassword-container']}>
      <Col>
        <div style={{ background: '#fff', width: '351px' }}>
          <img src={logo_big} alt="" className={css['changePassword-img']} />
        </div>
      </Col>
      <Col>
        <Card style={{ width: 400, height: 500, border: 'none' }} className={css['changePassword-body']}>
          <img src={logo} alt="Logo" className={css['logo-changePassword']} />
          <p className={css['changePassword-form-title']}>{t('change.password.title')}</p>
          <Form form={form} onFinish={handleSubmit} className={css['changePassword-form']}>
            <p className={css['changePassword-title']}>{t('change.password.newPassword.placeholder')}</p>
            <Form.Item
              name="newPassword"
              rules={[{ required: true, message: t('change.password.newPassword.validation') }]}>
              <Input.Password
                className="common-animation-primary"
                type="password"
                style={{ borderRadius: '6px' }}
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            <p className={css['changePassword-title']}>{t('change.password.confirmNewPassword.placeholder')}</p>
            <Form.Item
              name="confirmNewPassword"
              rules={[
                { required: true, message: t('change.password.confirmNewPassword.validation') },
                { validator: compareToFirstPassword },
              ]}>
              <Input.Password
                className="common-animation-primary"
                type="password"
                style={{ borderRadius: '6px' }}
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            <div style={{ height: '100%' }}>
              <Button
                type="primary"
                htmlType="submit"
                className={`${css['changePassword-form-button']} common-primary-btn`}
                block
                shape="round">
                {t('change.password.btn')}
              </Button>
            </div>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default ChangePasswordPage;
