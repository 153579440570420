const TYPOLOGY_SET_CURRENT_NODE = 'TYPOLOGY_SET_CURRENT_NODE';
const TYPOLOGY_SET_IS_SELECTED_NODE = 'TYPOLOGY_SET_IS_SELECTED_NODE';
const TYPOLOGY_SET_SELECTED_NODES = 'TYPOLOGY_SET_SELECTED_NODES';
const TYPOLOGY_SET_ITEMS = 'TYPOLOGY_SET_ITEMS';
const TYPOLOGY_REFRESH_ITEMS = 'TYPOLOGY_REFRESH_ITEMS';
const TYPOLOGY_SET_PARENT = 'TYPOLOGY_SET_PARENT';

export {
  TYPOLOGY_SET_CURRENT_NODE,
  TYPOLOGY_SET_IS_SELECTED_NODE,
  TYPOLOGY_SET_SELECTED_NODES,
  TYPOLOGY_SET_ITEMS,
  TYPOLOGY_REFRESH_ITEMS,
  TYPOLOGY_SET_PARENT,
};
