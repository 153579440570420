import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import ConsumerRequestsService from '../../services/ConsumerRequestsService';
import { Table, Modal, notification, Row, Col, Select } from 'antd';
import { getColumns } from './components/ConsumerRequestsColumns';
import { AppContext } from '../../contexts/AppContextProvider';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { HeaderContext } from '../../contexts/HeaderContextProvider';
import { CHANGE_CURRENT_PAGE } from '../../constants/actionTypes/headerConstants';
import css from '../../assets/styles/roles.module.css';
import { ProjectModel } from '../../models/ProjectModel';
import { SET_CURRENT_PROJECT, SET_CURRENT_PROPERTY } from '../../constants/actionTypes/appConstants';
import { IAppContext } from '../../typings/IApp';
import i18n from '../../utils/i18n';

const service: ConsumerRequestsService = new ConsumerRequestsService();

const ConsumerRequestsPage: React.FC<any> = (props: any) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    app: { currentProject, user },
    appDispatch,
  } = useContext<IAppContext>(AppContext);
  const { height: windowHeight } = useWindowDimensions();
  const [modal, contextHolder] = Modal.useModal();
  const [items, setItems] = useState<any>([]);
  const [statuses, setStatuses] = useState<any>([]);
  const [breadcrumbNameMap, setBreadcrumbNameMap] = useState<{ [name: string]: string }>({});
  const [projectId, setProjectId] = useState(currentProject ? currentProject.id : undefined);

  const [loading, setLoading] = useState<boolean>(false);
  const { headerDispatch } = useContext(HeaderContext);
  useEffect(() => {
    headerDispatch({
      type: CHANGE_CURRENT_PAGE,
      currentPage: t('header.title.Requests'),
      path: 'requests',
    });
  }, [i18n.language]);
  const defaultParams = {
    count: true,
  };

  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    total: 0,
  });

  const getRequests = (option: any) => {
    setLoading(true);
    service
      .getRequests(option, currentProject?.key)
      .then((res: any) => {
        const { items, count } = res;
        const { current, top } = option;
        setItems(items);
        setPagination({ ...pagination, current, total: count, pageSize: top });
      })
      .finally(() => setLoading(false));
  };

  const getStatuses = () => {
    service.getRequestStatuses().then((res: any) => {
      setStatuses(res);
    });
  };

  const setStatus = ({ key, keyPath, id }: any) => {
    const isSet = keyPath.some((key: string) => key === 'setstatus');
    if (isSet) {
      service.setConsumerRequestStatus({ id, status: { name: key } }, currentProject.key).then(() => {
        getRequests({
          ...defaultParams,
          top: pagination.pageSize,
          skip: pagination.pageSize * ((pagination.current || 1) - 1),
          current: pagination.current,
        });
        notification.success({
          message: t('requests.status.updated'),
        });
      });
    }
  };

  useEffect(() => {
    getRequests({
      ...defaultParams,
      top: pagination.pageSize,
    });
    getStatuses();
  }, [currentProject.key]);

  useEffect(() => {
    setBreadcrumbNameMap(
      props.history.location.pathname
        .replace('/requests', '')
        .split('/')
        .slice(1)
        .reduce(
          (res: any, curr: any, index: number) => ({
            ...res,
            [[Object.keys(res)[index], curr].join('/')]: curr.split(':').shift(),
          }),
          { '/requests': t('requests.bread.root') },
        ),
    );
  }, [props.history.location.pathname]);

  const mapFilterValues = (filters: any) => {
    const keys = Object.keys(filters);
    const result = keys.reduce((prev: any, curr: string) => {
      const values: string[] = filters[curr] || [];
      if (values.length > 0) {
        const [parent, child] = curr.split('-');
        let obj: any = Object.keys(prev).length === 0 ? { and: [] } : prev;
        switch (curr) {
          case 'consumer': {
            const [value] = values;
            obj.and.push({
              or: [
                {
                  [`tolower(${parent}/email)`]: { contains: encodeURIComponent(value?.toLowerCase()) },
                },
                {
                  [`tolower(${parent}/name)`]: { contains: encodeURIComponent(value?.toLowerCase()) },
                },
                {
                  [`tolower(${parent}/surname)`]: { contains: encodeURIComponent(value?.toLowerCase()) },
                },
              ],
            });
            break;
          }
          case 'consumer-phone': {
            const [value] = values;
            obj.and.push({
              [`${parent}/${child}`]: { eq: value },
            });
            break;
          }
          case 'status-description': {
            obj.and.push({
              or: [...values.map((value: any) => ({ [`${parent}/name`]: { eq: value } }))],
            });
            break;
          }
        }
        return obj;
      }
      return prev;
    }, {});
    return result;
  };

  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    let params: any = {
      ...defaultParams,
    };

    if (filters) {
      const data = mapFilterValues(filters);

      params = {
        ...params,
        filter: { ...data },
      };
    }

    if (pagination) {
      params = {
        ...params,
        top: pagination.pageSize,
        skip: pagination.pageSize * (pagination.current - 1),
        current: pagination.current,
      };
    }

    if (sorter && sorter.order) {
      params = {
        ...params,
        orderBy: `${sorter.columnKey.replace('-', '/')} ${sorter.order === 'ascend' ? 'asc' : 'desc'}`,
      };
    }
    getRequests(params);
  };

  const onSetStatus = (props: any) => {
    modal.confirm({
      title: t('common.modal.confirm'),
      content: <div>{t('requests.status.confirm.text')}</div>,
      okText: t('common.modal.confirm.ok'),
      cancelText: t('common.modal.confirm.cancel'),
      onOk: () => setStatus(props),
      centered: true,
    });
  };

  const onDelete = (id: number) => {
    service.deleteRequest(id, currentProject.key).then(() => {
      getRequests({
        ...defaultParams,
        top: pagination.pageSize,
        skip: pagination.pageSize * ((pagination.current || 1) - 1),
        current: pagination.current,
      });
    });
  };

  const onSelect = (id: any) => {
    setProjectId(id);
    if (+id !== currentProject.id) {
      const item = user?.projects.find((project: any) => project.id === +id);
      appDispatch({ type: SET_CURRENT_PROJECT, currentProject: item });
      appDispatch({
        type: SET_CURRENT_PROPERTY,
        currentProperty: user.properties.find((property) => property.project.id === item?.id),
      });
    }
  };

  return (
    <>
      <HeaderTable title={t('requests.title')} breadcrumb={breadcrumbNameMap} />
      {contextHolder}
      <Row gutter={[24, 4]} style={{ marginBottom: '1rem' }}>
        <Col span={24}>
          <Select
            style={{ padding: '5px 0', marginRight: '1rem' }}
            className="consumers-control-select common-animation-primary"
            value={projectId}
            onChange={onSelect}
            dropdownClassName="consumers-control-select-dropdown"
            placeholder={t('properties.placeholder.change.project')}
            suffixIcon={
              <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
            }>
            {user &&
              user.projects?.length > 0 &&
              user.projects
                .filter((project) => user.projects.find((item) => item.id === project.id))
                .map((project) => (
                  <Select.Option value={project.id} key={project.id} className="budget-control-option">
                    <span style={{ fontSize: '14px' }}>{project.title}</span>
                  </Select.Option>
                ))}
          </Select>
        </Col>
      </Row>
      <div className={css['consumerReq-table-container']}>
        <Table
          columns={getColumns({
            onDelete,
            onSetStatus,
            history,
            t,
            statuses,
          })}
          loading={loading}
          size="small"
          dataSource={items}
          showHeader={true}
          rowKey="id"
          scroll={{ x: '100%', y: windowHeight - 450 }}
          pagination={pagination}
          className="consumers-info-table"
          rowClassName="common-table-row--pointer"
          onChange={onTableChange}
        />
      </div>
    </>
  );
};

export default ConsumerRequestsPage;
