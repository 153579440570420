import React, { useState, useEffect, useContext } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Table, Select, Input, Badge, Spin, TablePaginationConfig } from 'antd';
import { useTranslation } from 'react-i18next';
import { getColumns } from './components/UsersColumns';
import UsersService from '../../services/UsersService';
import { AppContext } from '../../contexts/AppContextProvider';
import { HeaderContext } from '../../contexts/HeaderContextProvider';
import { CHANGE_CURRENT_PAGE } from '../../constants/actionTypes/headerConstants';
import DesignIcon from '../../components/common/DesignIcon';
import { IProjectEasy } from '../../models/ProjectModel';
import cssConsumers from '../../assets/styles/consumers.module.css';
import ProjectsService from '../../services/ProjectsService';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { FiltersContext } from '../../contexts/FiltersContextProvider';
import { SET_USERS_FILTERS, SET_USER_PAGINATION } from '../../constants/actionTypes/filtersConstants';
import TableMobile from '../common/TableMobile';
import filterIcon from '../../assets/images/ic-filter.svg';
import filterActiveIcon from '../../assets/images/ic-filter-active.svg';
import { useLessThen801 } from '../../helpers/mediaDetect';
import LogoComponent from '../../components/common/LogoComponent';
import { useHistory } from 'react-router';
import CustomOnBoard from '../common/CustomOnBoard';
import BoardTourComponent from '../common/BoardTourComponent';
// import RolesService from '../../services/RolesService';
import ModalSwitchUserBlock from '../common/ModalSwitchUserBlock';
import { isEmptyFilter } from '../../helpers/isEmptyFilter';
import { generateFilterfromObj } from '../../helpers/generateFIlterfromObj';
import { isEmptyObject } from '../../helpers/isEmptyObject';
import { FilterTypes } from '../../constants/filterTypes';
import { generateSorter } from '../../helpers/generateSorter';
import { getOnboarding, OnboardingTypes, saveOnboarding } from '../../helpers/onboarding';
import { IUserTable } from '../../models/UserModel';
import { FilterValue } from 'antd/lib/table/interface';
import { IAppContext } from '../../typings/IApp';
import i18n from '../../utils/i18n';

const { Search } = Input;
const { Option } = Select;

const service = new UsersService();
const projectsService = new ProjectsService();
// const rolesService = new RolesService();

const UsersPage: React.FC<RouteComponentProps> = (props) => {
  const { t } = useTranslation();
  const {
    app: { user },
  } = useContext<IAppContext>(AppContext);
  const {
    filters: { usersFilter },
    filtersDispatch,
  } = useContext(FiltersContext);
  const { height: windowHeight } = useWindowDimensions();
  const [fetchingData, setFetchingData] = useState(false);
  const [projects, setProjects] = useState<IProjectEasy[]>([]);
  const [items, setItems] = useState<IUserTable[]>([]);
  const [userName, setUserName] = useState<string>(usersFilter.searchLoginEmail);
  const [projectId, setProjectId] = useState<number | undefined>(usersFilter.project);
  // TODO: Review after backend refactoring
  // const [currentRole, setCurrentRole] = useState(usersFilter.roll);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  // TODO: Review after backend refactoring
  // const [roles, setRoles] = useState([]);
  const [currentTimeoutLogin, setCurrentTimeoutLogin] = useState<NodeJS.Timeout | null>(null);
  const [currentFilters, setCurrentFilters] = useState<{ [name: string]: { type: string; value: any } }>({
    ['projects/id']: {
      type: FilterTypes.SELECT,
      value: usersFilter.project ? usersFilter.project : null,
    },
  });
  const [filters, setFilters] = useState(usersFilter);
  const [pagination, setPagination] = useState(
    usersFilter?.pagination || {
      current: 1,
      pageSize: 10,
      total: 0,
      skip: 0,
    },
  );
  const history = useHistory();
  const { headerDispatch } = useContext(HeaderContext);

  const option = {
    filters: generateFilterfromObj(currentFilters) || null,
    count: true,
    top: pagination.pageSize,
    current: pagination.current,
    orderBy: generateSorter(usersFilter.project ? ['usersProjects/deletedDate desc'] : ['id desc']).orderBy,
    skip: pagination.pageSize * (pagination.current - 1) || 0,
  };

  //switch delete
  const [currentUser, setCurrentUser] = useState<IUserTable | null>(null);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  //board
  const [steps, _] = useState([
    {
      target: '.users-first-step',
      content: <BoardTourComponent title={t('users.first.title')} description={t('users.first.text')} />,
      disableBeacon: true,
      placement: 'left',
    },
  ]);
  const [run, setRun] = useState(false);

  useEffect(() => {
    headerDispatch({
      type: CHANGE_CURRENT_PAGE,
      currentPage: t('header.title.Users'),
      path: 'users',
    });
  }, [i18n.language]);

  const getUsers = (option: { [name: string]: any }) => {
    setFetchingData(true);
    service
      .getUsersTable({
        projectId,
        userName,
        isNeedAddOrderBy: !!projectId,
        isNeedParams: false,
        ...option,
      })
      .then((res) => {
        const { count, items } = res;
        const { current, top } = option;
        setPagination({ ...pagination, current, total: count, pageSize: top });
        const data: IUserTable[] = user ? items.filter((item: IUserTable) => item.id !== user.id) : items;
        setItems(data);
      })
      .finally(() => {
        setFetchingData(false);
        setRun(getOnboarding(OnboardingTypes.USER));
      });
  };

  // TODO: Review after backend refactoring
  // const getRoles = () => {
  //   rolesService
  //     .getRoles()
  //     .then((value: any) => setRoles(value))
  //     .catch((e: any) => notification.error({ message: e.message }));
  // };

  useEffect(() => {
    //TODO: Review after backend refactoring
    // getRoles();
    getUsers(option);
  }, []);

  useEffect(() => {
    projectsService.getProjects().then((value: IProjectEasy[]) => setProjects(value));
  }, []);

  const handleSearchNameUser = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setUserName(e.target.value);
    setFilters({ ...filters, searchLoginEmail: e.target.value });
    const body = {
      searchByName: {
        type: FilterTypes.MULTISEARCH,
        value: [{ firstName: e.target.value }, { lastName: e.target.value }, { login: e.target.value }],
      },
    };

    clearTimeout(Number(currentTimeoutLogin));
    setCurrentTimeoutLogin(setTimeout(() => onTableChange(undefined, body), 1000));
  };

  useEffect(() => {
    filtersDispatch({
      type: SET_USERS_FILTERS,
      filters,
    });
  }, [filters]);

  const handleChangeProject = (value: string) => {
    setProjectId(value ? +value : undefined);
    setFilters({ ...filters, project: value });
    const body = {
      ['projects/id']: {
        type: FilterTypes.SELECT,
        value: value ? value : null,
      },
    };

    onTableChange(undefined, body);
  };

  // TODO: Review after backend refactoring
  // const handleChangeRole = (value: any) => {
  //   setCurrentRole(value);
  //   setFilters({ ...filters, roll: value });
  //   const body = {
  //     ['roles/name']: {
  //       type: FilterTypes.SELECT,
  //       value: value ? value : null,
  //     },
  //   };

  //   onTableChange(null, body);
  // };

  useEffect(() => {
    if (user?.projects?.length === 1) {
      filtersDispatch({ type: SET_USERS_FILTERS, filters: { project: user.projects[0].id } });
    }
  }, [user]);

  const applyNewFilter = (filters: { [name: string]: any }) => {
    setCurrentFilters({ ...currentFilters, ...filters });
    return { ...currentFilters, ...filters };
  };

  const onTableChange = (pagination?: TablePaginationConfig, filters?: Record<string, FilterValue | null | any>) => {
    let params: { [name: string]: any } = {
      ...option,
    };
    if (filters && !isEmptyFilter(filters)) {
      params = {
        ...params,
        filters: generateFilterfromObj(applyNewFilter(filters)),
      };
    } else {
      params = {
        ...params,
        filters: generateFilterfromObj(currentFilters),
      };
    }
    if (pagination && pagination.pageSize && pagination.current) {
      filtersDispatch({ type: SET_USER_PAGINATION, pagination });

      params = {
        ...params,
        top: pagination.pageSize,
        skip: pagination.pageSize * (pagination.current - 1),
        current: pagination.current,
      };
    }

    if (filters && !isEmptyObject(filters) && filters['projects/id'] && !isEmptyObject(filters['projects/id'])) {
      params = {
        ...params,
        orderBy: ['usersProjects/deletedDate desc'],
      };
    }

    getUsers(params);
  };

  const changeOpenFilter = () => {
    setIsOpenFilter(!isOpenFilter);
  };

  const isOpenSwitchUserBlock = (user: IUserTable) => {
    setCurrentUser(user);
    setIsOpenDelete(true);
  };

  const loadMore = (option: { [name: string]: any }) => {
    setFetchingData(true);
    option.skip = option.skip ? option.skip + 10 : 10;
    service
      .getUsersTable({
        projectId,
        userName,
        isNeedAddOrderBy: !!projectId,
        isNeedParams: false,
        ...option,
      })
      .then((res) => {
        const { count, items } = res;
        const { current, top } = option;
        setPagination({ ...pagination, current, total: count, pageSize: top });
        const data: IUserTable[] = user ? items.filter((item: IUserTable) => item.id !== user.id) : items;
        setItems(data);
      })
      .finally(() => {
        setFetchingData(false);
        setRun(getOnboarding(OnboardingTypes.USER));
      });
  };

  if (useLessThen801()) {
    const data =
      items.length > 0 &&
      (items.map((item) => [
        {
          title: '#',
          description: (
            <LogoComponent id={item.id} name={`${item.firstName} ${item.lastName}`} image={item.image || ''} />
          ),
          type: 'string',
        },
        { title: t('users.table.columns.login'), description: item.login, type: 'string' },
        { title: t('users.table.columns.name'), description: `${item.firstName} ${item.lastName}`, type: 'string' },
        {
          title: t('consumers.table.columns.project'),
          description: (item.projects ?? []).map((p) => p.title).join(', '),
          type: 'string',
        },
        {
          title: t('users.table.columns.lockedDate'),
          description: item.lockedDate ? <Badge status="error" text="fermé à clé" /> : '',
          type: 'string',
        },
        {
          title: t('users.table.columns.isPasswordExpired'),
          description: item.isPasswordExpired ? <Badge status="error" text="passe expiré" /> : '',
          type: 'string',
        },
        {
          title: t('users.table.columns.isActive'),
          description: item.isActive ? (
            <Badge status="success" text="Online" />
          ) : (
            <Badge status="error" text="Offline" />
          ),
          type: 'string',
        },
        {
          title: t('budget.table.action'),
          type: 'button',
          openByFunction: true,
          btnsInfo: [
            {
              function: () => history.push(`/users/modify/${item.id}`),
              functionBtnTitle: t('common.btn.modify.lower'),
            },
          ],
        },
      ]) as any);

    return (
      <>
        <div className="common-mobile-title-container">
          <span style={{ fontSize: '20px' }}>{t('header.title.Users')}</span>
          <div className="common-modbile-filter-container" onClick={changeOpenFilter}>
            <img src={isOpenFilter ? filterActiveIcon : filterIcon} width={16} />
            <span className="filter-title">{isOpenFilter ? t('common.filter.close') : t('common.filter.open')}</span>
          </div>
          <Link
            to={`${props.history.location.pathname}/modify`}
            style={{ borderRadius: 4 }}
            className="common-animation-primary">
            <Button className={`common-blue-btn common-primary-btn`}>{t('common.btn.new')}</Button>
          </Link>
        </div>
        {isOpenFilter && (
          <div className="users-filter-container">
            <Search
              allowClear
              className="consumers-control-search common-animation-primary"
              style={{ width: '230px', padding: '5px 0', marginRight: '1rem' }}
              placeholder={t('consumers.control.search.placeholder')}
              suffix={<DesignIcon name="Search" />}
              onChange={handleSearchNameUser}
            />
            <Select
              defaultValue={usersFilter.project}
              style={{ padding: '5px 0', height: '42px' }}
              className="consumers-control-select common-animation-primary"
              onChange={handleChangeProject}
              suffixIcon={<DesignIcon name="ArrowDrop" className={cssConsumers['arrow-drop']} />}>
              <Option value="" className="budget-control-option">
                <span style={{ fontSize: '14px', fontWeight: 600 }}>{t('consumers.control.all.projects')}</span>
              </Option>
              {projects
                .filter((project) => user.projects.find((item) => item.id === project.id))
                .sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))
                .map((project) => (
                  <Option value={project.id} key={project.id} className="budget-control-option">
                    <span style={{ fontSize: '14px' }}>{project.title}</span>
                  </Option>
                ))}
            </Select>
            {/* TODO: Review after backend refactoring
            <Select
              defaultValue={usersFilter.roll}
              style={{ padding: '5px 0', height: '42px' }}
              className="consumers-control-select common-animation-primary"
              onChange={handleChangeRole}
              suffixIcon={<DesignIcon name="ArrowDrop" className={cssConsumers['arrow-drop']} />}>
              <Option value="" className="budget-control-option">
                <span style={{ fontSize: '14px', fontWeight: 600 }}>{t('roles.filter.common')}</span>
              </Option>
              {roles.map((roll: any) => (
                <Option value={roll.name} key={roll.name} className="budget-control-option">
                  <span style={{ fontSize: '14px' }}>{roll.name}</span>
                </Option>
              ))}
            </Select> */}
          </div>
        )}
        <TableMobile
          data={data}
          isLoading={fetchingData}
          option={option}
          loadMore={loadMore}
          itemsLength={items.length}
          totalItems={pagination.total}
        />
      </>
    );
  }

  return (
    <Spin spinning={fetchingData}>
      <CustomOnBoard
        steps={steps}
        run={run}
        isShowProgress={false}
        onFinish={() => saveOnboarding(OnboardingTypes.USER, user.boardCheckpoint)}
      />
      <div className={'userContainerInputs consumer-control-wrapper'}>
        <div>
          <Search
            allowClear
            className="consumers-control-search common-animation-primary"
            style={{ width: '230px', padding: '5px 0', marginRight: '1rem' }}
            placeholder={t('consumers.control.search.placeholder')}
            suffix={<DesignIcon name="Search" />}
            onChange={handleSearchNameUser}
          />
          <Select
            value={usersFilter.project}
            style={{ padding: '5px 0' }}
            className="consumers-control-select common-animation-primary"
            onChange={handleChangeProject}
            suffixIcon={<DesignIcon name="ArrowDrop" className={cssConsumers['arrow-drop']} />}>
            <Option value="" className="budget-control-option">
              <span style={{ fontSize: '14px', fontWeight: 600 }}>{t('consumers.control.all.projects')}</span>
            </Option>
            {projects
              .filter((project) => user.projects.find((item) => item.id === project.id))
              .sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))
              .map((project) => (
                <Option value={project.id} key={project.id} className="budget-control-option">
                  <span style={{ fontSize: '14px' }}>{project.title}</span>
                </Option>
              ))}
          </Select>
          {/* TODO: Review after backend refactoring
          <Select
            defaultValue={usersFilter.roll}
            style={{ padding: '5px 0', height: '42px', marginLeft: '1rem' }}
            className="consumers-control-select common-animation-primary"
            onChange={handleChangeRole}
            suffixIcon={<DesignIcon name="ArrowDrop" className={cssConsumers['arrow-drop']} />}>
            <Option value="" className="budget-control-option">
              <span style={{ fontSize: '14px', fontWeight: 600 }}>{t('roles.filter.common')}</span>
            </Option>
            {roles
              .filter((role: any) => role.name !== 'consumer')
              .map((roll: any) => (
                <Option value={roll.name} key={roll.name} className="budget-control-option">
                  <span style={{ fontSize: '14px' }}>{roll.name}</span>
                </Option>
              ))}
          </Select> */}
        </div>
        <Link
          to={`${props.history.location.pathname}/modify`}
          style={{ borderRadius: 4 }}
          className="common-animation-primary users-first-step">
          <Button className={`common-blue-btn common-primary-btn`}>{t('common.btn.new')}</Button>
        </Link>
      </div>
      <div className={cssConsumers['consumers-table-container']}>
        <Table
          columns={getColumns({
            isOpenSwitchUserBlock,
            t,
          })}
          size="middle"
          pagination={pagination}
          dataSource={items}
          showHeader={true}
          onChange={onTableChange}
          scroll={{ y: windowHeight - 310, x: 1300 }}
          className="consumers-info-table"
          rowClassName={'common-table-row--pointer'}
          rowKey="id"
        />
      </div>
      {isOpenDelete && currentUser && (
        <ModalSwitchUserBlock
          isOpen={isOpenDelete}
          setClose={() => {
            setCurrentUser(null);
            setIsOpenDelete(false);
          }}
          getUsers={() => getUsers(option)}
          isConsumer={false}
          user={currentUser}
        />
      )}
    </Spin>
  );
};

export default UsersPage;
