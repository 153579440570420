import React, { useContext, useEffect, useState } from 'react';
import { Form, Button, Input, Card, Row, Col, Radio, notification } from 'antd';
import logo from '../../assets/images/logo_dark.svg';
import logo_big from '../../assets/images/login_big.png';
import { AppContext } from '../../contexts/AppContextProvider';
import css from './../../assets/styles/login.module.css';
import { SET_AUTHENTICATED, SET_CHANGE_PASSWORD } from '../../constants/actionTypes/appConstants';
import { failedTofetch, tokenExpired } from '../../constants/tokenFailConstants';

import LoginPageServices from '../../services/LoginPageServices';

import { useTranslation } from 'react-i18next';
import { langs } from '../../utils/i18n';
import { Link } from 'react-router-dom';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import config from '../../config';
import ModalContactForm from '../../components/common/Modals/ModalContactForm';
import { IAppContext } from '../../typings/IApp';

const enabledProcore = config.enableProcoreAuth;
const procoreAuthUrl = config.procoreAuthUrl;
const procoreResponseType = config.procoreResponseType;
const procoreClientId = config.procoreClientId;
const procoreRedirectUri = config.procoreRedirectUri;

const checkIsFailed = (props: any) => {
  const search = props.location.search;
  const searchStr = search ? search.substr(1, search.length) : undefined;
  if (searchStr) {
    const type = searchStr.split('=')[1];
    switch (type) {
      case failedTofetch: {
        props.history.push('/login');
        notification.warning({
          message: props.t('login.page.notification.warning'),
          description: props.t('login.page.notification.serverError'),
        });
        break;
      }
      case tokenExpired: {
        props.history.push('/login');
        notification.warning({
          message: props.t('login.page.notification.warning'),
          description: props.t('login.page.notification.tokenExpired'),
        });
        break;
      }
    }
  }
};

const LanguageRadio = ({ i18n, changeLanguage }: any) => (
  <Radio.Group
    size="small"
    defaultValue={i18n.language}
    onChange={(e) => changeLanguage(e.target.value)}
    className={css['language-group-btn']}>
    {langs &&
      langs.map((lng: any) => (
        <Radio.Button key={lng.key} value={lng.key} className={css['language-btn']}>
          {lng.label}
        </Radio.Button>
      ))}
  </Radio.Group>
);

const LoginPage: React.FC = (props: any) => {
  const { t, i18n } = useTranslation();
  const { appDispatch } = useContext<IAppContext>(AppContext);
  const loginPageServices = new LoginPageServices();

  useEffect(() => {
    const isClosedHelper = window.localStorage.getItem('isClosedHelper');
    localStorage.clear();
    if (isClosedHelper) {
      window.localStorage.setItem('isClosedHelper', isClosedHelper);
    }
  }, []);

  // check type of token errors and show notification
  checkIsFailed({ ...props, t });

  const setGlobalCredentials = (user: any, data: any) => {
    appDispatch({ type: SET_AUTHENTICATED, user: user });
    localStorage.setItem('user', JSON.stringify(user));
    if (user.projects && user.projects.length > 0) {
      localStorage.setItem('currentProject', JSON.stringify(user.projects[0]));
    }
    if (user.properties && user.properties.length > 0 && user.projects && user.projects.length > 0) {
      localStorage.setItem(
        'currentProperty',
        JSON.stringify(
          user.properties.find((property: any) => property.project.id === user.projects[0].id) || user.properties[0],
        ),
      );
    }
    if (user.rolesParams && user.rolesParams.IS_CONSUMER) {
      localStorage.setItem('IS_CONSUMER', JSON.stringify(user.rolesParams.IS_CONSUMER));
    }
    if (user.rolesParams && user.rolesParams.IS_SUPPLIER) {
      localStorage.setItem('IS_SUPPLIER', JSON.stringify(user.rolesParams.IS_SUPPLIER));
    }
    localStorage.setItem('id_token', JSON.stringify(data));
    props.history.push(user.rolesParams && user.rolesParams.IS_SUPPLIER ? '/consumers' : '/dashboard');
  };

  const handleSubmit = (values: any) => {
    loginPageServices
      .getToken({ username: values.username.trim(), password: values.password })
      .then((res: any) => {
        const { message = null } = res;
        if (message && message === 'CHANGE_PASSWORD') {
          appDispatch({ type: SET_CHANGE_PASSWORD, username: values.username.trim() });
          props.history.push('change-pass');
        } else {
          const user = res.user;
          const data = res;
          setGlobalCredentials(user, data);
        }
      })
      .catch((err: any) => {
        if (err.status === 423) {
          notification.error({
            message: 'Error',
            duration: null,
            description: (
              <span>
                {t('common.notification.block.user')}
                <a href="/restore-pass">{t('common.notification.block.user.link')}</a>
              </span>
            ),
          });
        } else {
          notification.error({
            message: 'Error',
            description: err.message,
          });
        }
      });
  };

  const changeLanguage = (key: string) => {
    if (i18n.language !== key) {
      i18n.changeLanguage(key);
    }
  };

  const handleProcoreAuth = () => {
    const url = `${procoreAuthUrl}?response_type=${procoreResponseType}&client_id=${procoreClientId}&redirect_uri=${procoreRedirectUri}`;
    window.open(url /* , '_self' */);
  };

  return (
    <Row justify="center" align="middle" className="login-container">
      <Col>
        <div style={{ background: '#fff', width: '351px' }}>
          <img src={logo_big} alt="" className={css['login-img']} />
        </div>
      </Col>
      <Col>
        <Card style={{ width: 400, height: 500, border: 'none' }} className={css['login-body']}>
          <img src={logo} alt="Logo" className={css['logo-login']} />
          <p className={css['login-form-title']}>{t('login.page.btn')}</p>
          <Form onFinish={handleSubmit} className="login-form">
            <p className={css['login-title']}>{t('login.page.username.placeholder')}</p>
            <Form.Item name="username" rules={[{ required: true, message: t('login.page.username.validation') }]}>
              <Input className="common-animation-primary" style={{ borderRadius: '6px' }} />
            </Form.Item>
            <p className={css['login-title']} style={{ marginTop: '-1rem' }}>
              {t('login.page.password.placeholder')}
            </p>
            <Form.Item name="password" rules={[{ required: true, message: t('login.page.password.validation') }]}>
              <Input.Password
                className="common-animation-primary"
                type="password"
                style={{ borderRadius: '6px' }}
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            <div style={{ height: '100%' }}>
              <Button
                type="primary"
                htmlType="submit"
                className={`${css['login-form-button']} common-primary-btn`}
                block
                shape="round">
                {t('login.page.btn')}
              </Button>
            </div>
            <div style={{ height: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              <Link to={'/restore-pass'} className={css['login-restorePass-link']}>
                {t('login.page.resrorePass.btn.forgot')}
              </Link>
            </div>
          </Form>
          {enabledProcore && (
            <div style={{ height: '100%' }}>
              <Button
                type="primary"
                className={`${css['login-form-button']} ${css['login-form-button--procore']} common-primary-btn`}
                block
                onClick={handleProcoreAuth}
                shape="round">
                Sign in with Procore
              </Button>
            </div>
          )}
          <ModalContactForm />

          {/* <iframe
            src={`https://login-sandbox.procore.com/oauth/authorize?response_type=code&client_id=e6924eb408c46fe6495b1f9afd5778cea9377bfd66c22d52fb4e6f594c413a81&redirect_uri=https://app.localhost/procore/callback`}
            width={600}
            height={400}
            sandbox={'allow-same-origin allow-modals allow-scripts'}
          /> */}
          <div>
            <LanguageRadio i18n={i18n} changeLanguage={changeLanguage} />
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default LoginPage;
