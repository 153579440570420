import { Button, Col, Table } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../../assets/styles/companies.module.css';
import { useLessThen801 } from '../../../../helpers/mediaDetect';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { PaymentInfo } from '../../../../models/CompaniesModel';
import TableMobile from '../../../common/TableMobile';
import ModalFormPaymentProfile from './ModalFormPaymentProfile';
import { getColumns } from './PaymentProfileColumns';

interface IPaymentProfile {
  paymentsInfo: PaymentInfo[] | undefined;
  setPaymentsInfo: (p: PaymentInfo) => void;
}

const PaymentProfile = ({ paymentsInfo, setPaymentsInfo }: IPaymentProfile) => {
  const { t } = useTranslation();
  const { height: windowHeight } = useWindowDimensions();
  const [openModal, setOpenModal] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfo | undefined>(undefined);

  const handleSave = (data: PaymentInfo) => {
    setOpenModal(false);
    setPaymentsInfo(data);
  };

  const onEditClick = (data: PaymentInfo) => {
    setPaymentInfo(data);
    setOpenModal(true);
  };

  const data =
    paymentsInfo &&
    paymentsInfo.length > 0 &&
    (paymentsInfo.map((item) => [
      {
        title: '#',
        description: item.id,
        type: 'string',
      },
      {
        title: t('companies.table.column.holder'),
        description: item.accountHolder,
        type: 'string',
      },
      {
        title: t('companies.table.column.bank.name'),
        description: item.bankName,
        type: 'string',
      },
      {
        title: t('budget.table.action'),
        type: 'button',
        openByFunction: true,
        btnsInfo: [
          {
            function: () => onEditClick(item),
            functionBtnTitle: t('common.btn.modify.lower'),
          },
        ],
      },
    ]) as any);
  return (
    <>
      <Col span={12} style={{ height: '100%' }} className={css['companies-modify-col']}>
        <div
          className={css['companies-table-container']}
          style={{ padding: '36px', height: '100%', overflowY: 'auto' }}>
          <div className={css['companies-control-container']}>
            <Button
              className={`common-blue-btn common-primary-btn company-modify-second-step`}
              onClick={() => setOpenModal(true)}
              style={useLessThen801() ? { width: '100%' } : {}}>
              {t('common.btn.new')}
            </Button>
          </div>
          {useLessThen801() && paymentsInfo ? (
            <TableMobile
              data={data}
              isLoading={false}
              option={{}}
              itemsLength={paymentsInfo.length}
              totalItems={paymentsInfo.length}
            />
          ) : (
            <div className={css['companies-table-container']}>
              <Table
                columns={getColumns({
                  t,
                  onEditClick,
                })}
                dataSource={paymentsInfo}
                showHeader={true}
                scroll={{ y: windowHeight - 280 }}
                size="small"
                className="consumers-info-table"
                rowClassName="common-table-row--pointer"
                rowKey="id"
              />
            </div>
          )}
        </div>
      </Col>
      <ModalFormPaymentProfile
        paymentInfo={paymentInfo}
        isOpenModal={openModal}
        setClose={() => {
          setPaymentInfo(undefined);
          setOpenModal(false);
        }}
        onSave={handleSave}
      />
    </>
  );
};

export default PaymentProfile;
