import HttpService from './common/httpService';
import config from '../config';
import { getApiKeyOptions } from '../helpers/getApiKeyOptions';

class TypologiesService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getTypologies(projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.get(`${config.baseRequest}typologies`, options);
  }

  public getTypologyById(id: number, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.get(`${config.baseRequest}typologies/${id}`, options);
  }

  public createTypology(body: any, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.post(`${config.baseRequest}typologies`, body, options);
  }

  public updateTypology(body: any, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.put(`${config.baseRequest}typologies`, body, options);
  }

  public deleteTypology(id: number, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.delete(`${config.baseRequest}typologies/${id}`, null, options);
  }

  public upload3DModel(typologyId: number, file: any) {
    const formData = new FormData();
    formData.append('file', file);
    return this.httpService.postFile(`${config.baseRequest}typologies/model/${typologyId}`, formData);
  }

  public delete3DModel(typologyId: number) {
    return this.httpService.delete(`${config.baseRequest}typologies/model/${typologyId}`);
  }
}

export default TypologiesService;
