import React, { useContext, useEffect, useState } from 'react';
import css from '../../../assets/styles/statistics.module.css';
import { IQuotesInfo } from '../../../models/DashboardModel';
import DashboardService from '../../../services/DashboardService';
import { notification } from 'antd';
import StatisticsContainer from './StatisticsContainer';
import { IAppContext } from '../../../typings/IApp';
import { AppContext } from '../../../contexts/AppContextProvider';
import i18n from '../../../utils/i18n';
import { StatusesDocumentsModel } from '../../../models/DocumentsModel';
import CustomSignFlow from '../../../services/CustomSignFlow';
import { generateFilterfromObj } from '../../../helpers/generateFIlterfromObj';
import { FilterTypes } from '../../../constants/filterTypes';

const service = new DashboardService();
const customFlowService = new CustomSignFlow();

export enum DocumentTypes {
  quote = 'quote',
  amendments = 'amendments',
}

const StatisticsPage = () => {
  const {
    app: {
      user: { projects },
    },
  } = useContext<IAppContext>(AppContext);
  const [items, setItems] = useState<IQuotesInfo[]>([]);
  const [statuses, setStatuses] = useState<StatusesDocumentsModel>([]);
  const [currentItem, setCurrentItems] = useState<IQuotesInfo>();
  const [activeProject, setActiveProject] = useState<number>(projects[0].id);
  const [documentType, setDocumentType] = useState<DocumentTypes>(DocumentTypes.quote);

  useEffect(() => {
    customFlowService
      .getStatuses({
        filters: generateFilterfromObj({
          'doc_types_statuses/typeCode': {
            type: FilterTypes.SELECT,
            value: documentType === DocumentTypes.quote ? 'quotes' : 'amendments',
          },
        }),
      })
      .then((value: StatusesDocumentsModel) => setStatuses(value))
      .catch((e) => console.log(e));
  }, [documentType]);

  const getQuoteInfo = () => {
    service
      .getQuoteInfo()
      .then((value: IQuotesInfo[]) => {
        setItems(value);
      })
      .catch((e) => {
        notification.error({
          message: e.message,
        });
      });
  };

  const getAmentdmentsInfo = () => {
    service
      .getAmendmentsInfo()
      .then((value: IQuotesInfo[]) => {
        setItems(value);
      })
      .catch((e) => {
        notification.error({
          message: e.message,
        });
      });
  };

  useEffect(() => {
    if (documentType === DocumentTypes.quote) {
      getQuoteInfo();
    }
    if (documentType === DocumentTypes.amendments) {
      getAmentdmentsInfo();
    }
    return () => setItems([]);
  }, [documentType, i18n.language]);

  useEffect(() => {
    if (activeProject && items.length > 0) {
      setCurrentItems(items.find((item) => item.id === activeProject));
    }
  }, [activeProject, items]);

  return (
    <div style={{ width: '100%' }}>
      <div className="tab-container" style={{ overflowX: 'auto' }}>
        {projects
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((item) => (
            <a
              type="button"
              className={`consumer-tab common-animation-primary ${
                activeProject === item.id ? 'consumer-tab-active' : ''
              }`}
              style={{ minWidth: 'fit-content' }}
              onClick={() => setActiveProject(item.id)}
              key={item.id}>
              {item.name}
            </a>
          ))}
      </div>
      <div className={css['container']}>
        {currentItem && (
          <StatisticsContainer
            item={currentItem}
            documentType={documentType}
            statuses={statuses}
            setDocumentType={setDocumentType}
          />
        )}
      </div>
    </div>
  );
};

export default StatisticsPage;
