import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import { AppContext } from '../../contexts/AppContextProvider';
import HeaderComponent from '../../components/common/HeaderComponent';
import NavComponent from '../../components/common/Nav/NavComponent';
import NavContextProvider from '../../contexts/NavContextProvider';
import { SET_PROFILE, SET_IS_NON_LOGIN } from '../../constants/actionTypes/appConstants';
import LoginPageServices from '../../services/LoginPageServices';
import Routes from '../../routes/Routes';
import { getRouting } from '../../helpers/getRouting';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ProductsContextProvider from '../../contexts/ProductsContextProvider';
import { defaultFunctions } from '../../constants/DefaultFunctions';
import HeaderContextProvider from '../../contexts/HeaderContextProvider';
import { useHistory } from 'react-router';
import PropertyContextProvider from '../../contexts/PropertyProvider';
import { useTranslation } from 'react-i18next';
import HeaderMobile from '../../components/common/HeaderMobile';
import { useLessThen801 } from '../../helpers/mediaDetect';
import FiltersContextProvider from '../../contexts/FiltersContextProvider';
import { setOnboarding } from '../../helpers/onboarding';
import { IAppContext } from '../../typings/IApp';

const { Content } = Layout;
const loginServices: LoginPageServices = new LoginPageServices();

const DefaultLayout: React.FC = (props: any) => {
  const { app, appDispatch } = useContext<IAppContext>(AppContext);
  const [functions, setFunctions] = useState(defaultFunctions);
  const { height: windowHeight } = useWindowDimensions();
  const history = useHistory();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (app.isAuthenticated && !app.isLogin) {
      loginServices
        .getProfile()
        .then((res) => {
          setOnboarding(res.boardCheckpoint);
          appDispatch({ type: SET_PROFILE, user: res });
          appDispatch({ type: SET_IS_NON_LOGIN });
        })
        .catch(() => history.push('/login'));
    }
  }, [i18n.language]);

  useEffect(() => {
    if (app.user && app.user.functions && app.user.functions.length > 0) {
      setFunctions(app.user.functions.concat(defaultFunctions as any) as any);
    }
  }, [app.user?.functions]);

  return (
    <NavContextProvider>
      <PropertyContextProvider>
        <HeaderContextProvider>
          <FiltersContextProvider>
            <Layout style={{ height: '100vh' }}>
              {!useLessThen801() && <NavComponent {...props} />}
              <Layout>
                {useLessThen801() ? <HeaderMobile /> : <HeaderComponent {...props} />}
                {/* TODO: simple solution for the products context, NEED to be refactored */}
                <ProductsContextProvider>
                  <Content
                    id="scrollableDiv"
                    className="content-style"
                    style={{
                      margin: '0 16px',
                      background: '#fff',
                      height: windowHeight - 200,
                      overflow: 'auto',
                    }}>
                    {app.isAuthenticated ? (
                      <Switch>
                        <Route exact path="/">
                          <Redirect to={app.isSupplier ? '/consumers' : '/dashboard'} />
                        </Route>
                        <Routes options={getRouting(functions)} />

                        {/* TODO: process not found page */}
                        {/* <Route component={NotFoundPage} /> */}
                      </Switch>
                    ) : (
                      <Switch>
                        <Redirect to="/login" />
                      </Switch>
                    )}
                  </Content>
                </ProductsContextProvider>
                {/* <FooterComponent /> */}
              </Layout>
            </Layout>
          </FiltersContextProvider>
        </HeaderContextProvider>
      </PropertyContextProvider>
    </NavContextProvider>
  );
};

export default DefaultLayout;
