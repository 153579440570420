import React, { useState } from 'react';
import { Form, Modal, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import DynamicParams from './DynamicParams';
import ReportDynamicParamsProvider from '../../../contexts/ReportDynamicParamsProvider';

interface DynamicParamsModel {
  visible: boolean;
  report: any;
  onOk: (values?: any) => void;
  onCancel: () => void;
}

const EmptyParamsMessage = ({ t }: any) => (
  <Typography.Text type="secondary">{t('report.modal.empty.message')}</Typography.Text>
);

const DynamicParamsModal: React.FC<DynamicParamsModel> = ({ visible, report, onOk, onCancel }: DynamicParamsModel) => {
  const { t } = useTranslation();
  const { title, params = [] } = report;
  const [changed, setChanged] = useState<any>({});
  const [parentIdCustom, setParentIdCustom] = useState('');

  const [form] = Form.useForm();

  const onSubmit = () => {
    form.submit();
  };

  const onFieldsChange = ([value]: any = []) => {
    if (value.name.includes('project')) {
      form.setFieldsValue({
        parentId: [],
      });
    }

    const [name] = value?.name || [];
    if (value) {
      setChanged({ ...changed, [name]: true });
    }
  };

  const checkValue = (values: any) => {
    if (values.parentId) {
      values.parentId = parentIdCustom;
    } else {
      delete values.parentId;
    }
    onOk(values);
  };

  return (
    <ReportDynamicParamsProvider>
      <Modal
        title={title}
        visible={visible}
        onOk={params.length === 0 ? checkValue : onSubmit}
        onCancel={onCancel}
        maskClosable={false}
        okText={t('common.btn.generate')}
        okButtonProps={{ htmlType: 'submit' }}>
        {params.length === 0 ? (
          <EmptyParamsMessage t={t} />
        ) : (
          <Form
            layout="vertical"
            onFinish={checkValue}
            onFieldsChange={onFieldsChange}
            form={form}
            style={{ paddingRight: '1rem', height: 'calc(100% - 16px)', overflow: 'hidden' }}>
            {params.map((param: any, index: number) => (
              <DynamicParams
                key={index}
                param={param}
                form={form}
                changed={changed}
                setChanged={setChanged}
                setParentIdCustom={setParentIdCustom}
              />
            ))}
          </Form>
        )}
      </Modal>
    </ReportDynamicParamsProvider>
  );
};

export default DynamicParamsModal;
