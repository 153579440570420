import { notification, Spin, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterTypes } from '../../../../../constants/filterTypes';
import { convertToPrice } from '../../../../../helpers/convertToPrice';
import { generateFilterfromObj } from '../../../../../helpers/generateFIlterfromObj';
import QuotesService from '../../../../../services/QuoteService';
import { getDiagramColumns } from './DiagramColumns';
import css from '../../../../../assets/styles/dashboard.module.css';
import { ICurrencyModel, IQuote } from '../../../../../models/DashboardModel';
import { useHistory } from 'react-router';
import useWindowDimensions from '../../../../../hooks/useWindowDimensions';
import { useLessThen801 } from '../../../../../helpers/mediaDetect';
import TableMobile from '../../../../common/TableMobile';
import moment from 'moment';
import AmendmentService from '../../../../../services/AmendmentService';

interface IDiagramTable {
  projectId: number;
  quotesId: number[];
  currency?: ICurrencyModel;
  isQuote: boolean;
  isShow: boolean;
}

const serviceQuote = new QuotesService();
const serviceAmendment = new AmendmentService();

const DiagramTable = ({ quotesId, projectId, currency, isQuote, isShow }: IDiagramTable) => {
  const { t } = useTranslation();
  const { height: windowHeight } = useWindowDimensions();
  const history = useHistory();
  const [quotes, setQuotes] = useState<any[]>([]);
  const [fetchingData, setFetchingData] = useState(false);
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
  });

  const option = {
    count: true,
    filters: {
      filter: generateFilterfromObj({
        id: {
          type: FilterTypes.SELECT_MULTIPLE,
          value: quotesId,
        },
      }).filter,
    },
    top: pagination.pageSize,
    current: pagination.current,
    orderBy: ['createdDate desc'],
  };

  const getQuotes = (option: any) => {
    serviceQuote
      .getQuotes(option)
      .then((res) => {
        const { count, items } = res;
        const { current, top } = option;

        setPagination({ ...pagination, current, total: count, pageSize: top });
        setQuotes(items);
      })
      .catch((e: any) => {
        notification.error({
          message: e.message,
        });
      })
      .finally(() => setFetchingData(false));
  };

  const getAmendments = (option: any) => {
    serviceAmendment
      .getAmendments(null, option)
      .then((res) => {
        const { count, items } = res;
        const { current, top } = option;

        setPagination({ ...pagination, current, total: count, pageSize: top });
        setQuotes(items);
      })
      .catch((e: any) => {
        notification.error({
          message: e.message,
        });
      })
      .finally(() => setFetchingData(false));
  };

  useEffect(() => {
    if (isShow) {
      setFetchingData(true);
      if (isQuote) {
        getQuotes(option);
      } else {
        getAmendments(option);
      }
    }
    return () => setQuotes([]);
  }, [isShow]);

  const redirectToConsumer = (propertyId: number) => {
    history.push(`/properties/${propertyId}/project/${projectId}/manage/${isQuote ? 'quotes' : 'amendment'}`);
  };

  const onTableChange = (pagination?: any) => {
    let params: any = {
      ...option,
    };

    if (pagination) {
      params = {
        ...params,
        top: pagination.pageSize,
        skip: pagination.pageSize * (pagination.current - 1),
        current: pagination.current,
      };
    }

    if (isQuote) {
      getQuotes(params);
    } else {
      getAmendments(params);
    }
  };

  const loadMore = (option: any) => {
    setFetchingData(true);
    option.skip = option.skip ? option.skip + 10 : 10;
    (isQuote ? serviceQuote.getQuotes(option) : serviceAmendment.getAmendments(null, option))
      .then((res: any) => {
        const { current, top } = option;
        setPagination({ ...pagination, current, total: res.count, pageSize: top });
        setQuotes([...quotes, ...res.items] as []);
      })
      .catch((e: any) => console.log(e))
      .finally(() => setFetchingData(false));
  };

  if (useLessThen801()) {
    const data =
      quotes.length > 0 &&
      (quotes.map((item: IQuote) => [
        {
          title: '№',
          description: item.number,
          type: 'string',
        },
        { title: t('documents.table.column.description'), description: item.description, type: 'string' },
        {
          title: t('budget.table.sum'),
          description: `${item?.currency?.description || t('budget.currency')} ${convertToPrice(item.sumWithVat)}`,
          type: 'string',
        },
        {
          title: t('budget.form.date.of.signature'),
          description:
            item.statusV2?.code === 'signed' && !!item.lastSignDateTime
              ? moment(item.lastSignDateTime).format('DD.MM.YYYY HH:mm')
              : '',
        },
        {
          title: t('budget.table.status'),
          description: item.statusV2,
          type: 'status',
        },
        {
          title: t('users.table.columns.action'),
          type: 'button',
          openByFunction: true,
          btnsInfo: [{ function: () => redirectToConsumer(item.property.id) }],
        },
      ]) as any);

    return (
      <Spin spinning={fetchingData}>
        {quotes.length > 0 && (
          <div className={css['diagram-table-footer']}>
            <span style={{ color: '#778DAC', fontSize: '15px' }}>{t('budget.table.sum.total')}</span>
            {currency && (
              <span style={{ fontWeight: 700 }}>{`${currency.description || t('budget.currency')} ${convertToPrice(
                currency.value || undefined,
              )}`}</span>
            )}
          </div>
        )}
        <div style={{ overflowY: 'auto' }}>
          <TableMobile
            data={data}
            loadMore={loadMore}
            isLoading={fetchingData}
            option={option}
            itemsLength={quotes.length}
            totalItems={pagination.total}
            height={'calc(90vh - 155px)'}
          />
        </div>
      </Spin>
    );
  }

  return (
    <Spin spinning={fetchingData}>
      <Table
        columns={getDiagramColumns({ t, redirectToConsumer, isDisabled: fetchingData, isQuote })}
        size="small"
        className="list-info-table"
        dataSource={quotes}
        showHeader={true}
        pagination={pagination}
        onChange={onTableChange}
        scroll={{ y: windowHeight - 360, x: 'max-content' }}
        rowClassName="common-table-row--pointer"
        footer={() =>
          quotes.length > 0 && (
            <div className={css['diagram-table-footer']}>
              <span style={{ color: '#778DAC', fontSize: '15px' }}>{t('budget.table.sum.total')}</span>
              {currency && (
                <span style={{ fontWeight: 700 }}>{`${currency.description || t('budget.currency')} ${
                  Number.isInteger(currency.value) ? convertToPrice(currency.value as number) : '-'
                }`}</span>
              )}
            </div>
          )
        }
        rowKey="id"
      />
    </Spin>
  );
};

export default DiagramTable;
