
import buildQuery from 'odata-query';
import HttpService from './common/httpService';
import config from '../config';
import { ODataOptionModel } from '../models/ODataOptionModel';

class ReportsService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getReports(options?: ODataOptionModel) {
    const query = buildQuery(options);
    return this.httpService.get(`${config.baseRequest}reports${query}`);
  }

  public getReport(name: string) {
    return this.httpService.get(`${config.baseRequest}reports/${name}`);
  }

  public getGeneratedReportData(name: string, options?: ODataOptionModel) {
    const query = buildQuery(options);
    return this.httpService.get(`${config.baseRequest}reports/${name}/generated${query}`);
  }

  public getReportData(id: number) {
    return this.httpService.get(`${config.baseRequest}reports/generated/${id}/result-data`);
  }

  public downloadReportData(reportName: string, id: number) {
    return this.httpService.getFile(`${config.baseRequest}reports/${reportName}/${id}`);
  }

  public createRreport(name: string, params: any = null, type: string = 'csv', isAwait: boolean = false) {
    return this.httpService.post(`${config.baseRequest}reports/${name}?type=${type}&await=${isAwait}`, params);
  }
}

export default ReportsService;
