import React, { useContext, useState } from 'react';
import { Dropdown, Menu, Empty } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { AppContext } from '../../../contexts/AppContextProvider';
import { SET_CURRENT_PROPERTY } from '../../../constants/actionTypes/appConstants';
import { IAppContext } from '../../../typings/IApp';

interface ProjectsModel {
  isCollapsed: boolean;
}

const menu = (properties: any[] = [], currentProperty: number | undefined, onSelect: any, currentProject: any) => {
  if (properties.length === 0) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
  const defaultSelected = currentProperty ? [currentProperty.toString()] : [];

  return (
    <Menu onClick={onSelect} selectedKeys={defaultSelected}>
      {properties
        .filter((property: any) => property.project.id === currentProject.id)
        .sort((a: any, b: any) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
        .map((item: any) => (
          <Menu.Item key={item.id} className="project-menu-item-conteiner">
            <span>{item.title}</span>
          </Menu.Item>
        ))}
    </Menu>
  );
};

const Properties = ({ isCollapsed }: ProjectsModel) => {
  const { app, appDispatch } = useContext<IAppContext>(AppContext);
  const { user, currentProperty, currentProject } = app || {};

  const [isVisible, setIsVisible] = useState(false);

  const handleVisible = (visible: boolean) => {
    setIsVisible(visible);
  };

  const onSelect = ({ key }: any) => {
    if (+key !== currentProperty.id) {
      const item = user?.properties.find((property: any) => property.id === +key);
      appDispatch({ type: SET_CURRENT_PROPERTY, currentProperty: item });
      setIsVisible(false);
    }
  };

  return (
    <>
      {user?.properties && user?.properties.length > 0 && (
        <Dropdown
          overlay={menu(user?.properties || [], currentProperty?.id, onSelect, currentProject)}
          trigger={['click']}
          onVisibleChange={handleVisible}>
          <div className={`projects-conteiner${isCollapsed ? '-collapsed' : ''}`}>
            <span className="name-text">{currentProperty?.title}</span>
            {!isCollapsed &&
              (isVisible ? <UpOutlined className="navigation-btn" /> : <DownOutlined className="navigation-btn" />)}
          </div>
        </Dropdown>
      )}
    </>
  );
};

export default Properties;
