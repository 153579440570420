import { Button, DatePicker, Modal } from 'antd';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../../assets/styles/budget.module.css';

interface Props {
  isOpen: boolean;
  setIsOpen: (b: boolean) => void;
  paidAt: string | null;
  setPaidAt: (d: string | null) => void;
}

const ConfirmPaidForm = (props: Props) => {
  const { isOpen, setIsOpen, paidAt, setPaidAt } = props;
  const { t } = useTranslation();

  return (
    <Modal
      visible={isOpen}
      centered
      title={<span style={{ fontWeight: 600, fontSize: '18px' }}>{t('budget.form.paid.at.title')}</span>}
      footer={
        <div className="common-modal-footer">
          <Button className={`common-gray-border-btn common-secondary-btn`} onClick={() => setIsOpen(false)}>
            {t('budget.modal.cancel.btn')}
          </Button>
          <Button className={`${css['invoice-modal-save-btn']} common-modal-btn`} onClick={() => setIsOpen(false)}>
            {t('budget.modal.save.btn')}
          </Button>
        </div>
      }
      closable={false}
      className="common-modal">
      <div className={css['confirm-msg-container']}>
        <span style={{ color: '#778dac', marginBottom: '0.5rem' }}>{t('budget.form.paid.at.desc')}</span>
        <DatePicker
          inputReadOnly
          className="common-date-picker"
          defaultValue={paidAt ? moment(paidAt) : undefined}
          format="DD.MM.YYYY"
          suffixIcon={<div className={'calendar-img'} style={{ width: '18px', height: '18px' }}></div>}
          onChange={(value) => setPaidAt(value?.toString() || null)}
        />
      </div>
    </Modal>
  );
};

export default ConfirmPaidForm;
