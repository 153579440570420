import React, { useState } from 'react';
import { Button, notification, Select } from 'antd';
import { NavLink } from 'react-router-dom';
import LogoComponent from '../../../components/common/LogoComponent';
import ListPageServices from '../../../services/PropertiesService';
import UsersService from '../../../services/UsersService';
import css from '../../../assets/styles/consumers.module.css';
import editIcon from '../../../assets/images/ic-edit-dark.svg';
import ConfirmChangeModal from './ConfirmChangeModal';
import { ConsumerV2Model } from '../../../models/ConsumerModel';
import ConsumersService from '../../../services/ConsumersService';
import { TFunction } from 'react-i18next';
import { PropertyModel } from '../../../models/PropertyModel';
import { IUserEasy } from '../../../models/UserModel';
import { IResponceData } from '../../../typings/IServiceOptions';

interface ConsumerColumnsModel {
  t: TFunction<'translation'>;
  getUsers: () => void;
  isSupplier: boolean;
  isOpenSwitchUserBlock: (id: number, projectId: number, isDelete: boolean) => void;
}
const listService = new ListPageServices();
const userService = new UsersService();
const consumerService = new ConsumersService();

const { Option } = Select;

export const getColumns = ({ t, getUsers, isSupplier, isOpenSwitchUserBlock }: ConsumerColumnsModel) => [
  {
    title: () => (
      <span className="budget-table-tr" style={{ marginLeft: '8px' }}>
        #
      </span>
    ),
    key: 'logo',
    width: 50,
    render: (cell: ConsumerV2Model) => (
      <LogoComponent id={cell.id} name={`${cell.firstName} ${cell.lastName}`} image={cell.image || ''} />
    ),
  },
  {
    title: () => <span className="budget-table-tr">{t('users.table.columns.email')}</span>,
    dataIndex: 'email',
    width: '20%',
    key: 'email',
  },
  {
    title: () => <span className="budget-table-tr">{t('users.table.columns.name')}</span>,
    key: 'name',
    width: '23%',
    sorter: true,
    render: (cell: ConsumerV2Model) => `${cell.firstName} ${cell.lastName}`,
  },
  {
    title: () => <span className="budget-table-tr">{t('consumers.table.columns.project')}</span>,
    width: '12%',
    key: 'project',
    render: (cell: ConsumerV2Model) => {
      return cell.projectId && cell.projectTitle;
    },
  },
  {
    title: () => <span className="budget-table-tr">{t('consumers.table.columns.property')}</span>,
    width: '16%',
    key: 'property',
    sorter: true,
    render: (cell: ConsumerV2Model) => {
      const [properties, setProperties] = useState<PropertyModel[] | null>(null);
      const [isOpen, setIsOpen] = useState(false);
      const [currentId, setCurrentId] = useState<number | null>(null);

      const setClose = () => {
        setIsOpen(false);
        setCurrentId(null);
      };

      const getProperties = (projectKey: string) => {
        listService
          .getPropertyWithFilter({
            projectKey,
            sortObject: { title: 'asc' },
          })
          .then((value: PropertyModel[]) => {
            setProperties(value);
          })
          .catch((e) => console.log(e));
      };

      const changeProperty = (value: string) => {
        const body: { [name: string]: number } = {
          newProperty: +value,
        };
        if (cell.propertyId) {
          body.currentProperty = cell.propertyId;
        }
        consumerService
          .updateConsumerPropertyV2(cell.id, body)
          .then(() => {
            notification.success({
              message: t('consumers.notification.property.update'),
            });
            getUsers();
          })
          .catch((e) =>
            notification.error({
              message: e.message,
            }),
          );
      };

      if (!cell.projectId) {
        return null;
      }

      return (
        <>
          <ConfirmChangeModal
            getUsers={getUsers}
            id={currentId}
            isOpen={isOpen}
            setClose={setClose}
            type="newProperty"
          />
          <Select
            className="consumers-column-select"
            suffixIcon={
              <div
                className={`arrow-drop-img ${css['arrow-drop-table']}`}
                style={{ width: '8px', height: '4px' }}></div>
            }
            onSelect={changeProperty}
            disabled={isSupplier}
            // BUG: probably has the version collision. on this line, in the production instance, the app has an error.
            // NOTE: the error stack: Property 'onClick' does not exist on type 'IntrinsicAttributes & SelectProps<any,
            //       DefaultOptionType> & { children?: ReactNode; } & { ref?: ((instance: BaseSelectRef | null) => void) | RefObject<...> | null | undefined; }'.
            onClick={() => getProperties(cell.projectKey)}
            defaultValue={cell.propertyId ? cell.propertyTitle : '__________'}>
            {properties &&
              properties
                .filter((property) => property.childrens.length === 0)
                .map((property) => (
                  <Option key={property.id} value={property.id}>
                    {property.title}
                  </Option>
                ))}
          </Select>
        </>
      );
    },
  },
  {
    title: () => <span className="budget-table-tr">{t('consumers.table.columns.agent')}</span>,
    width: '20%',
    key: 'agent',
    render: (cell: ConsumerV2Model) => {
      const [users, setUsers] = useState<IUserEasy[]>([]);
      const [isOpen, setIsOpen] = useState(false);
      const [currentId, setCurrentId] = useState<number | null>(null);
      const [newCreatedUser, setNewCreatedUser] = useState<number | null>(null);
      const [projectKey, setProjectKey] = useState<string | null>(null);
      const [currentPropertyId, setCurrentPropertyId] = useState<number | null>(null);

      const [isLastUserReq, setIsLastUserReq] = useState(false);
      const [fetchingData, setFetchingData] = useState(false);
      const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
        skip: 0,
      });

      const setOpen = (id?: number) => {
        setIsOpen(true);
        if (id) {
          setCurrentId(cell.id);
        }
      };
      const setClose = () => {
        setIsOpen(false);
        setCurrentId(null);
      };

      const getEasyUsers = () => {
        const optionUser = {
          count: true,
          top: pagination.pageSize,
          current: pagination.current,
          skip: pagination.pageSize * (pagination.current - 1) || 0,
          projectId: [cell.projectId],
          roles: ['agent', 'admin', 'super_agent'],
        };

        setFetchingData(true);
        userService
          .getEasyUsers(optionUser)
          .then((res: IResponceData<IUserEasy>) => {
            const { count, items } = res;
            const { current, top } = optionUser;
            setPagination({ ...pagination, current: current + 1, total: count, pageSize: top });
            setUsers([...users, ...items]);
            setIsLastUserReq(current * top >= count);
          })
          .catch((e) => console.log(e))
          .finally(() => setFetchingData(false));
      };

      const changeUser = (value: number) => {
        setNewCreatedUser(+value);
        setProjectKey(cell.projectKey);
        setCurrentPropertyId(cell.propertyId);
        if (cell.agentId) {
          setOpen(cell.id);
        } else {
          const body = {
            agent: { id: value },
          };
          listService
            .saveProperty({ body, id: cell.propertyId, projectKey: cell.projectKey })
            .then(() => {
              getUsers();
              notification.success({
                message: t('consumers.notification.agent.update'),
              });
            })
            .catch((e) => {
              notification.error({
                message: e,
              });
            });
        }
      };

      if (!cell.propertyId) {
        return null;
      }

      return (
        <>
          <ConfirmChangeModal
            getUsers={getUsers}
            id={currentId}
            isOpen={isOpen}
            setClose={setClose}
            type="createdUser"
            createdUserId={newCreatedUser}
            projectKey={projectKey}
            currentPropertyId={currentPropertyId}
          />
          <Select
            className="consumers-column-select consumer-column-agent"
            suffixIcon={
              <div
                className={`arrow-drop-img ${css['arrow-drop-table']}`}
                style={{ width: '8px', height: '4px' }}></div>
            }
            onSelect={(value) => changeUser(+value)}
            disabled={isSupplier}
            dropdownClassName={`consumer-column-dropdown ${
              fetchingData ? 'consumers-control-select-dropdown--progress' : ''
            }`}
            onPopupScroll={(e: any) => {
              e.persist();
              let target = e.target;
              if (!fetchingData && !isLastUserReq && target.scrollTop + target.offsetHeight === target.scrollHeight) {
                getEasyUsers();
              }
            }}
            // BUG: probably has the version collision. on this line, in the production instance, the app has an error.
            // NOTE: the error stack: Property 'onClick' does not exist on type 'IntrinsicAttributes & SelectProps<any,
            //       DefaultOptionType> & { children?: ReactNode; } & { ref?: ((instance: BaseSelectRef | null) => void) | RefObject<...> | null | undefined; }'.
            onClick={() => getEasyUsers()}
            defaultValue={
              cell.propertyId && cell.agentId ? `${cell.agentFirstName} ${cell.agentLastName}` : '__________'
            }>
            {users &&
              users.map((user) => (
                <Option key={user.id} value={user.id}>
                  {user.firstName} {user.lastName}
                </Option>
              ))}
          </Select>
        </>
      );
    },
  },
  {
    title: () => <span className="budget-table-tr">{t('users.table.columns.action')}</span>,
    width: isSupplier ? 100 : 225,
    key: 'views',
    fixed: 'right' as any,
    render: (cell: ConsumerV2Model) => {
      return isSupplier ? (
        <NavLink
          to={{
            pathname: `/consumers/project/${cell?.projectId}/property/${cell?.propertyId}/manage/${
              isSupplier ? 'details_project' : 'calendar'
            }`,
            state: {
              projectKey: cell.projectKey,
            },
          }}
          style={{ borderRadius: 4 }}
          className="common-animation-primary">
          <Button
            className="common-secondary-btn"
            style={{ borderColor: '#076ee5', backgroundColor: '#eef0f4', borderRadius: '6px' }}>
            <span style={{ color: '#076ee5' }}>{t('consumers.table.columns.manage')}</span>
          </Button>
        </NavLink>
      ) : (
        <div style={{ display: 'flex', backgroundColor: 'inherit', gap: '1rem' }}>
          <NavLink
            to={`/consumers/modify/${cell.id}`}
            style={{ borderRadius: 4 }}
            className="common-animation-secondary">
            <Button
              className="common-secondary-btn common-color-blue"
              style={{
                borderColor: '#076ee5',
                backgroundColor: '#eef0f4',
                borderRadius: '6px',
              }}>
              <img src={editIcon} alt="" style={{ width: '12px', height: '12px', marginRight: '0.5rem' }} />
              {t('common.btn.modify.lower')}
            </Button>
          </NavLink>
          <Button
            className={`${css['edit-consumer-btn']} common-color-dark`}
            onClick={() => isOpenSwitchUserBlock(cell.id, cell.projectId, cell.usersProjects_deleted_date === null)}>
            <span style={{ color: '#076ee5' }}>
              {cell.usersProjects_deleted_date !== null ? t('user.btn.activate') : t('user.btn.deactivate')}
            </span>
          </Button>
        </div>
      );
    },
  },
];
