import { NewTaskModel, UpdatedTaskModel } from './../models/TasksModel';
import HttpService from './common/httpService';
import config from '../config';
import buildQuery from 'odata-query';

class TasksService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getTasks(option: any) {
    let { top, count, skip, filters, orderBy } = option;
    let filter = [];
    let defaultFilter: any = {};

    if (filters) {
      if (Object.keys(defaultFilter).length) {
        filter = [defaultFilter, ...filters.filter];
      } else {
        filter = [...filters.filter];
      }
      filter.sort((a: any, b: any) =>
        Object.keys(Object.values(a)[0] as any)[0] == 'contains'
          ? -1
          : Object.keys(Object.values(b)[0] as any)[0] == 'contains'
          ? 1
          : 0,
      );
    } else {
      filter = defaultFilter;
    }

    const query = buildQuery({ filter, top, count, skip, orderBy });
    return this.httpService.get(`${config.baseRequest}tasks${query}`);
  }

  public createTasks(body: NewTaskModel) {
    return this.httpService.post(`${config.baseRequest}tasks`, body);
  }

  public updateTask(body: UpdatedTaskModel) {
    return this.httpService.put(`${config.baseRequest}tasks`, body);
  }

  public deleteTask(id: number) {
    return this.httpService.delete(`${config.baseRequest}tasks/${id}`);
  }
}

export default TasksService;
