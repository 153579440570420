import { Button, Card, Popover, Skeleton, Tag, Upload } from 'antd';
import moment, { MomentInput } from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { noPDFFormat } from '../../helpers/noPDFFormat';
import config from '../../config';
import InfiniteScroll from 'react-infinite-scroll-component';
import { convertToPrice } from '../../helpers/convertToPrice';
import { IStatusModel } from '../../models/StatusModel';
import downloadIcon from '../../assets/images/ic-download-dark.svg';
import icIncoming from '../../assets/images/ic-mail-incoming.svg';
import icOutgoing from '../../assets/images/ic-mail-outgoing.svg';
import icAttention from '../../assets/images/ic-attention.svg';
import { useHistory } from 'react-router';
import { setTextColorByStatus } from '../../helpers/setTextColorByStatus';
import getProcoreStatusTag from '../../helpers/getProcoreStatusTag';

export interface IData {
  title: string;
  description: string | boolean | number | MomentInput | null;
  type: string;
  previewAction?: () => void;
  id?: number;
  downloadAction?: boolean;
  status: {
    code: string;
    description: string;
  };
  signatories?: any;
  signAction?: (id?: number, signInfo?: string) => void;
  signInfo?: any;
}
interface TableProps {
  data: Array<Array<IData>>;
  isLoading?: boolean;
  loadMore?: (option: any) => void;
  option: any;
  itemsLength: number;
  totalItems: number;
  userId?: number | number[];
  types?: any[];
  height?: string;
}

const TableMobile = (props: TableProps) => {
  const { data, isLoading, loadMore, option, itemsLength, totalItems, userId, types, height } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const loadMoreData = () => {
    loadMore && loadMore(option);
  };

  const setStatus = (status: IStatusModel) => {
    return (
      <span style={{ color: setTextColorByStatus(status?.code) || '#d9b21e', fontSize: '14px' }}>
        {status?.description.toLowerCase()}
      </span>
    );
  };

  const convertByType = (value: any) => {
    switch (value.type) {
      case 'warning': {
        return (
          <Popover content={value.warning.content} title={value.warning.title} placement="bottom" trigger="hover">
            <span>{value.description}</span>
            <img src={icAttention} alt="" style={{ marginLeft: 5, verticalAlign: 'initial' }} />
          </Popover>
        );
      }
      case 'date': {
        if (value.text) {
          const content = (
            <div style={{ width: '200px' }}>
              <p>{value.text?.title}</p>
            </div>
          );

          const title = <span style={{ color: '#EB3B3B', fontSize: '18px' }}>{value.text?.description}</span>;

          const duedate = moment(value.description);
          const today = moment();
          if (duedate.isBefore(today)) {
            return (
              <div style={{ minWidth: 100 }}>
                <Popover content={content} title={title} placement="bottom" trigger="hover">
                  <span style={{ color: '#EB3B3B' }}>{duedate.format(value?.format || 'DD.MM.YYYY')}</span>
                  <img src={icAttention} alt="" style={{ marginLeft: 5 }} />
                </Popover>
              </div>
            );
          }
        }
        return <span>{value.description ? moment(value.description).format(value?.format || 'DD.MM.YYYY') : '-'}</span>;
      }
      case 'price': {
        return <span>{`CHF ${value.description !== null ? convertToPrice(value.description) : '-'}`}</span>;
      }
      case 'status': {
        return <span>{setStatus(value.description)}</span>;
      }
      case 'statusTag': {
        return getProcoreStatusTag(value.description, t);
      }
      case 'button': {
        const isCanSign =
          value.signatories &&
          value.signatories.findIndex(
            (signatory: any) =>
              (Array.isArray(userId) ? userId?.includes(signatory.consumerId) : signatory.consumerId === userId) &&
              !signatory.signed,
          ) >= 0;
        return (
          <div className="common-flex-column-style">
            {value.openAction && (
              <Button
                className="common-blue-border-btn"
                style={{ marginBottom: '1rem', padding: '0 2rem' }}
                onClick={() => {
                  history.push(`/${value.description}/view/${value.id}`);
                }}>
                <span style={{ color: '#076ee5' }}>{t('common.btn.open')}</span>
              </Button>
            )}
            {value.previewAction && (
              <Button
                className="common-blue-border-btn"
                style={{ marginBottom: '1rem' }}
                onClick={() => {
                  value.description.slice(-3) === 'pdf' ? value.previewAction(value.id) : noPDFFormat(t);
                }}>
                <span style={{ color: '#076ee5' }}>{t('common.preview.btn')}</span>
              </Button>
            )}
            {value.downloadAction && (
              <div
                className="common-flex-style"
                style={{
                  backgroundColor: '#eef0f4',
                  borderRadius: '6px',
                  marginBottom: '1rem',
                }}>
                <Upload
                  className="download-budget-item"
                  iconRender={() => <img style={{ marginLeft: '6px' }} src={downloadIcon} />}
                  showUploadList={{ showRemoveIcon: false }}
                  style={{ height: '100%', backgroundColor: 'inherit' }}
                  maxCount={1}
                  beforeUpload={() => false}
                  fileList={[
                    {
                      name: t('budget.table.download'),
                      uid: value.description,
                      url: `${config.storeUrl}/${value.description}`,
                    } as any,
                  ]}
                />
              </div>
            )}
            {value.openUrl &&
              value.btnsInfoUrl.map((btn: any, index: number) => (
                <Button
                  key={index}
                  onClick={() => history.push(btn.url, btn.state && btn.state)}
                  className="common-blue-border-btn common-secondary-btn"
                  style={{
                    marginBottom: '1rem',
                  }}>
                  <span style={{ color: '#076ee5' }}>{btn.urlBtnTitle || t('consumers.table.columns.manage')}</span>
                </Button>
              ))}
            {value.openByFunction &&
              value.btnsInfo.map((btn: any, index: number) =>
                !btn.isNotShow ? (
                  <Button
                    key={index}
                    className="common-blue-border-btn common-secondary-btn"
                    style={{
                      marginBottom: '1rem',
                    }}
                    onClick={btn.function && btn.function}>
                    <span style={{ color: '#076ee5' }}>
                      {btn.functionBtnTitle || t('consumers.table.columns.manage')}
                    </span>
                  </Button>
                ) : (
                  ''
                ),
              )}
            {isCanSign &&
              ['new', 'pending', 'partially_sign', 'overdue', 'acquirer_signature', 'agent_signature'].includes(
                value.status.code,
              ) && (
                <Button className="common-blue-btn" onClick={() => value.signAction(value.id, value.comment)}>
                  <span style={{ color: '#fff' }}>{t('common.title.sign')}</span>
                </Button>
              )}
          </div>
        );
      }
      case 'type': {
        const isOutgoing = value.description.createdUser.id === userId;
        const codeType = isOutgoing ? 'outgoing' : 'incoming';
        const description = types?.find((type) => type.code === codeType)?.description;
        return (
          <div style={{ minWidth: 100 }}>
            <img src={isOutgoing ? icOutgoing : icIncoming} alt={description} style={{ marginRight: 10 }} />
            {description}
          </div>
        );
      }
      case 'answer': {
        const answerMsgs = value.description.messages.filter(
          (msg: any) => !msg.isSystem && msg.createdUser.id !== userId,
        );
        const notReadMsgs = answerMsgs.filter((msg: any) => !msg.isRead);
        if (notReadMsgs.length > 0) {
          return <div>+{notReadMsgs.length}</div>;
        }
        return <>{`${answerMsgs.length || '-'}`}</>;
      }
      default:
        return value.description;
    }
  };

  return (
    <div>
      <InfiniteScroll
        dataLength={itemsLength}
        next={loadMoreData}
        hasMore={itemsLength < totalItems}
        height={height}
        loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
        scrollableTarget="scrollableDiv">
        {data &&
          data.map((invoice, index) => (
            <Card className="table-card-container" key={index} loading={isLoading}>
              {invoice.map((item, index) => (
                <Card
                  loading={isLoading}
                  key={index}
                  className="table-card"
                  title={item.title}
                  bordered={false}
                  bodyStyle={{
                    border: 'none',
                    width: '50%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    height: 'auto',
                  }}>
                  {convertByType(item)}
                </Card>
              ))}
            </Card>
          ))}
      </InfiniteScroll>
    </div>
  );
};

export default TableMobile;
