import { Modal, notification, Table, TableProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterTypes } from '../../../../constants/filterTypes';
import { generateFilterfromObj } from '../../../../helpers/generateFIlterfromObj';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import BudgetService from '../../../../services/BudgetService';
import { getBudgetColumns } from './ArchivedColumns';
import css from '../../../../assets/styles/budget.module.css';
import { useLessThen801 } from '../../../../helpers/mediaDetect';
import TableMobile from '../../../common/TableMobile';
import { BudgetInfoModel, BudgetModel } from '../../../../models/BudgetModel';
import { authDownloadFile } from '../../../../helpers/authFileTools';

interface ArchiveProps {
  isOpen: boolean;
  setIsOpen: (b: boolean) => void;
  propertyId: number;
  getInvoice: () => void;
  isConsumer: boolean;
}
const service = new BudgetService();

const ArchivesInvoices = (props: ArchiveProps) => {
  const { isOpen, setIsOpen, propertyId, getInvoice, isConsumer } = props;
  const [invoices, setInvoices] = useState<BudgetInfoModel>([]);
  const { t } = useTranslation();
  const { height: windowHeight } = useWindowDimensions();
  const [fetchingData, setFetchingData] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    skip: 0,
    simple: true,
  });

  const option = {
    count: true,
    top: pagination.pageSize,
    current: pagination.current,
    filters: generateFilterfromObj({
      isArchive: {
        type: FilterTypes.SELECT,
        value: true,
      },
    }),
  };

  useEffect(() => {
    getArchives(option);
  }, []);

  const getArchives = (option: { [name: string]: any }) => {
    setFetchingData(true);
    service
      .getBudget(propertyId, option)
      .then((value) => {
        const { count, items } = value;
        const { current, top } = option;
        if (items.length === 0) {
          notification.info({
            message: t('common.no.archive.items'),
          });
          setIsOpen(false);
        }
        setPagination({ ...pagination, current, total: count, pageSize: top, simple: true });
        setInvoices(items);
      })
      .catch((e) => {
        notification.error({
          message: e.message,
        });
        setIsOpen(false);
      })
      .finally(() => setFetchingData(false));
  };

  const loadMore = (option: { [name: string]: any }) => {
    setFetchingData(true);
    option.skip = option.skip ? option.skip + 10 : 10;
    service
      .getBudget(propertyId, option)
      .then((value) => {
        const { count, items } = value;
        const { current, top } = option;
        setPagination({ ...pagination, current, total: count, pageSize: top, simple: true });
        setInvoices([...invoices, ...items]);
      })
      .catch((e) => {
        notification.error({
          message: e.message,
        });
        setIsOpen(false);
      })
      .finally(() => setFetchingData(false));
  };

  const onTableChange: TableProps<BudgetModel>['onChange'] = (pagination) => {
    let params: { [name: string]: any } = {
      ...option,
    };
    if (pagination && pagination.pageSize && pagination.current) {
      params = {
        ...params,
        top: pagination.pageSize,
        skip: pagination.pageSize * (pagination.current - 1),
        current: pagination.current,
      };
    }

    getArchives(params);
  };

  const unArchive = (id: number) => {
    updateInvoice(invoices.find((el) => el.id === id) as BudgetModel);
  };

  const updateInvoice = (invoice: BudgetModel) => {
    const body = {
      id: invoice.id,
      isArchive: false,
    };
    service
      .updateInvoice(body)
      .then(() =>
        notification.success({
          message: t('budget.modal.unarchived.success.message'),
        }),
      )
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => {
        getArchives(option);
        getInvoice();
      });
  };

  const data =
    invoices.length > 0 &&
    (invoices.map((item) => [
      { title: '№', description: item.number, type: 'string' },
      { title: t('documents.table.column.description'), description: item.description, type: 'string' },
      { title: t('budget.table.sum.total'), description: item.sumWithVat, type: 'price' },
      { title: t('budget.table.paid.at'), description: item.paidAt, type: 'date' },
      { title: t('budget.table.date'), description: item.paymentDate, type: 'date' },
      {
        title: t('budget.table.status'),
        description: { code: 'archived', description: t('common.archived.status') },
        type: 'status',
      },
      {
        title: t('budget.table.action'),
        description: item.file,
        type: 'button',
        openByFunction: true,
        btnsInfo: [
          {
            function: () => unArchive(item.id),
            functionBtnTitle: t('common.title.unarchive'),
          },
          {
            function: () => authDownloadFile(item.file, item.fileName),
            functionBtnTitle: t('budget.table.download'),
          },
        ],
      },
    ]) as any);

  if (useLessThen801()) {
    return (
      <Modal
        visible={isOpen}
        className="archive-modal"
        centered
        footer={null}
        closable={false}
        style={{ paddingBottom: 0 }}>
        <div onClick={() => setIsOpen(false)} className={css['close-archive-btn']}>
          <span className={css['close-archive-text']}>X</span>
        </div>
        <TableMobile
          data={data}
          isLoading={fetchingData}
          loadMore={loadMore}
          option={option}
          itemsLength={invoices.length}
          totalItems={pagination.total}
        />
      </Modal>
    );
  }

  return (
    <Modal
      visible={isOpen}
      className="archive-modal"
      centered
      footer={null}
      closable={false}
      style={{ paddingBottom: 0 }}>
      <div onClick={() => setIsOpen(false)} className={css['close-archive-btn']}>
        <span className={css['close-archive-text']}>X</span>
      </div>
      <Table
        columns={getBudgetColumns({ t, unArchive, isConsumer })}
        size="small"
        className="list-info-table"
        dataSource={invoices}
        showHeader={true}
        pagination={pagination}
        scroll={{ y: windowHeight - 280 }}
        onChange={onTableChange}
        rowClassName="common-table-row--pointer"
        rowKey="id"
      />
    </Modal>
  );
};

export default ArchivesInvoices;
