import { Modal, notification, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterTypes } from '../../../../constants/filterTypes';
import { generateFilterfromObj } from '../../../../helpers/generateFIlterfromObj';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { getColumns } from './ArchivedColumns';
import css from '../../../../assets/styles/budget.module.css';
import InternalDocumentsService from '../../../../services/InternalDocumentsService';
import { InternalDocumentModel } from '../../../../models/InternalDocumentsModel';

interface ArchiveProps {
  isOpen: boolean;
  setIsOpen: any;
  getInternalDocuments: (o: any) => void;
  internalOption: any;
}
const service = new InternalDocumentsService();

const ArchivesInternalDocuments = (props: ArchiveProps) => {
  const { isOpen, setIsOpen, getInternalDocuments, internalOption } = props;
  const [internalDocuments, setInternalDocuments] = useState<any>([]);
  const { t } = useTranslation();
  const { height: windowHeight } = useWindowDimensions();
  const [fetchingData, setFetchingData] = useState(false);

  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
  });

  const option = {
    count: true,
    top: pagination.pageSize,
    current: pagination.current,
    filters: generateFilterfromObj({
      isArchive: {
        type: FilterTypes.SELECT,
        value: true,
      },
    }),
  };

  useEffect(() => {
    getDocuments(option);
  }, []);

  const getDocuments = (option: any) => {
    setFetchingData(true);
    service
      .getInternalDocuments(option)
      .then((value: any) => {
        const { count, items } = value;
        const { current, top } = option;
        if (items.length === 0) {
          notification.info({
            message: t('common.no.archive.items'),
          });
          setIsOpen(false);
        }
        setPagination({ ...pagination, current, total: count, pageSize: top, simple: true });
        setInternalDocuments(items);
      })
      .catch((e: any) => {
        notification.error({
          message: e.message,
        });
        setIsOpen(false);
      })
      .finally(() => setFetchingData(false));
  };

  const onTableChange = (pagination?: any, filters?: any, sorter?: any) => {
    let params: any = {
      ...option,
    };
    if (pagination) {
      params = {
        ...params,
        top: pagination.pageSize,
        skip: pagination.pageSize * (pagination.current - 1),
        current: pagination.current,
      };
    }

    getDocuments(params);
  };

  const unArchive = (id: number) => {
    updateInternalDocument(internalDocuments.find((el: any) => el.id === id));
  };

  const updateInternalDocument = (internalDocument: InternalDocumentModel) => {
    service
      .changeIsArchive(internalDocument.id)
      .then(() =>
        notification.success({
          message: `${internalDocument.name} ${t('internal.documents.unarchive.success')}`,
        }),
      )
      .catch((e: any) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => {
        getDocuments(option);
        getInternalDocuments(internalOption);
      });
  };

  return (
    <Modal
      visible={isOpen}
      className="archive-modal"
      centered
      footer={null}
      closable={false}
      style={{ paddingBottom: 0 }}>
      <div onClick={() => setIsOpen(false)} className={css['close-archive-btn']}>
        <span className={css['close-archive-text']}>X</span>
      </div>
      <Table
        columns={getColumns({ t, unArchive })}
        size="small"
        className="list-info-table"
        rowClassName="common-table-row--pointer"
        dataSource={internalDocuments}
        showHeader={true}
        pagination={pagination}
        scroll={{ y: windowHeight - 280 }}
        onChange={onTableChange}
        rowKey="id"
      />
    </Modal>
  );
};

export default ArchivesInternalDocuments;
