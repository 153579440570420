import { Button, Checkbox, Dropdown, List, Menu, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import deleteIcon from '../../../assets/images/ic-delete-dark-gray.svg';
import editIcon from '../../../assets/images/ic-edit-dark.svg';
import moreIcon from '../../../assets/images/ic-more.svg';
import calendarIcon from '../../../assets/images/ic-calendar.svg';
import arrowIcon from '../../../assets/images/ic-arrow-fill.svg';
import css from '../../../assets/styles/tasks.module.css';
import { useTranslation } from 'react-i18next';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import TaskConfirmModal from './TaskConfirmModal';
import { StatusTask, TaskModel, UpdatedTaskModel } from '../../../models/TasksModel';
import moment from 'moment';
import TasksService from '../../../services/TasksService';
import LogoComponent from '../../../components/common/LogoComponent';
import TaskForm from './TaskForm';
import TaskDeleteConfirmModal from './TaskDeleteConfirmModal';

const service = new TasksService();

interface ITask {
  isConsumer: boolean;
  isSupplier: boolean;
  task: TaskModel;
  getTasks: () => void;
  setFetchingData: (b: boolean) => void;
}

export const checkStatus = (status: string) => {
  switch (status) {
    case StatusTask.done:
      return true;
    default:
      return false;
  }
};

const TaskItem = ({ isConsumer, isSupplier, task, getTasks, setFetchingData }: ITask) => {
  const { t } = useTranslation();
  const [isCheck, setIsCheck] = useState(checkStatus(task.status.code));
  const [isEdit, setIsEdit] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  useEffect(() => {
    setIsCheck(checkStatus(task.status.code));
  }, [task]);

  const checkIsConsumer = (e: CheckboxChangeEvent) => {
    if (isConsumer && e.target.checked) {
      return setOpenModal(true);
    }
    updateTaskStatus(e.target.checked);
    changeCheck(e.target.checked);
  };

  const changeCheck = (isCheck: boolean) => setIsCheck(isCheck);

  const onOk = () => {
    updateTaskStatus(true);
    changeCheck(true);
    setOpenModal(false);
  };

  const updateTaskStatus = (isCheck: boolean) => {
    const body: UpdatedTaskModel =
      isConsumer || isSupplier
        ? {
            id: task.id,
            status: {
              code: isCheck ? StatusTask.done : StatusTask.todo,
            },
          }
        : {
            id: task.id,
            description: task.description,
            deadline: task.deadline,
            assignee: {
              id: task.assignee.id,
            },
            status: {
              code: isCheck ? StatusTask.done : StatusTask.todo,
            },
          };
    setFetchingData(true);
    service
      .updateTask(body)
      .then(() => {
        notification.success({ message: t('tasks.message.update') });
        getTasks();
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingData(false));
  };

  const MenuComponent = () => (
    <Menu className="update-menu-container" style={{ width: '130px' }}>
      <Menu.Item key="edit">
        <div onClick={() => setIsEdit(true)} className={css['update-menu-item']}>
          <Button
            style={{
              border: 'none',
              backgroundColor: 'inherit',
              boxShadow: 'none',
              padding: 0,
              marginRight: '0.5rem',
            }}>
            <img src={editIcon} alt="" style={{ height: '15px', width: '15px' }} />
          </Button>
          <span style={{ color: '#00122d', fontWeight: 600 }}>{t('budget.table.edit')}</span>
        </div>
      </Menu.Item>
      <Menu.Item key="delete">
        <div onClick={() => setOpenModalDelete(true)} className={css['update-menu-item']}>
          <Button
            style={{
              border: 'none',
              backgroundColor: 'inherit',
              boxShadow: 'none',
              padding: 0,
              marginRight: '0.5rem',
            }}>
            <img src={deleteIcon} alt="" style={{ height: '15px', width: '15px' }} />
          </Button>
          <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.title.delete')}</span>
        </div>
      </Menu.Item>
    </Menu>
  );

  if (isEdit) {
    return (
      <TaskForm
        getTasks={getTasks}
        userId={task.assignee.id}
        taskInfo={task}
        showBottom={true}
        onFinish={() => setIsEdit(false)}
      />
    );
  }

  return (
    <List.Item className={css[isCheck ? 'task-container-checked' : 'task-container']}>
      <Checkbox className="tasks-checkbox" onChange={checkIsConsumer} checked={isCheck} />
      <List.Item.Meta
        title={task.description}
        description={
          <div className={css['task-list-item-container']}>
            <div className={css['task-list-item-user']}>
              <img src={arrowIcon} alt="" className={css['task-list-item-icon']} width={13} height={7} />
              <LogoComponent
                id={task.assignee.id}
                name={`${task.assignee.firstName} ${task.assignee.lastName}`}
                image={task.assignee.image || undefined}
              />
              <span style={{ fontSize: '12px' }}>{`${task.assignee.firstName} ${task.assignee.lastName}`}</span>
            </div>
            <div>
              <img src={calendarIcon} alt="" className={css['task-list-item-icon']} />
              <span style={{ fontSize: '12px', marginLeft: '0.5rem' }}>
                {moment(task.deadline).format('DD.MM.YYYY')}
              </span>
            </div>
          </div>
        }
      />
      {!isConsumer && !isSupplier && (
        <Dropdown overlay={MenuComponent} trigger={['click']}>
          <Button className={css['update-dropdown-btn']} style={{ alignSelf: 'start' }}>
            <img src={moreIcon} alt="" height={15} width={15} />
          </Button>
        </Dropdown>
      )}
      <TaskConfirmModal isVisible={openModal} onOk={onOk} onCancel={() => setOpenModal(false)} />
      <TaskDeleteConfirmModal
        isVisible={openModalDelete}
        onCancel={() => setOpenModalDelete(false)}
        taskTitle={task.description}
        getTasks={getTasks}
        taskId={task.id}
      />
    </List.Item>
  );
};

export default TaskItem;
