import { Button, Col, List, notification, Row, Skeleton, Spin, TablePaginationConfig } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../assets/styles/post.module.css';
import { CHANGE_CURRENT_PAGE } from '../../constants/actionTypes/headerConstants';
import { AppContext } from '../../contexts/AppContextProvider';
import { HeaderContext } from '../../contexts/HeaderContextProvider';
import { generateFilterfromObj } from '../../helpers/generateFIlterfromObj';
import { isEmptyFilter } from '../../helpers/isEmptyFilter';
import { useLessThen801 } from '../../helpers/mediaDetect';
import { CalendarTypes, IPost } from '../../models/CalendarModel';
import CalendarService from '../../services/CalendarServices';
import PostCard from './components/PostCard';
import PostCreateFilters from './components/PostCreateFilters';
import PostConfirmModal from './components/PostConfirmModal';
import PostFilters from './components/PostFilters';
import PostModalForm from './components/PostModalForm';
import filterIcon from '../../assets/images/ic-filter.svg';
import filterActiveIcon from '../../assets/images/ic-filter-active.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import ModalUploadFileSuccess from '../../components/common/Modals/ModalUploadFileSuccess';
import { FilterValue } from 'antd/lib/table/interface';
import { IAppContext } from '../../typings/IApp';
import i18n from '../../utils/i18n';

const service = new CalendarService();

const PostsPage = () => {
  const { t } = useTranslation();
  const [fetchingData, setFetchingData] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [posts, setPosts] = useState<IPost[]>([]);
  const [defaultType, setDefaultType] = useState<CalendarTypes>();
  const [postId, setPostId] = useState<number | null>(null);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isOpenUploadMessageModal, setIsOpenUploadMessageModal] = useState(false);
  const { headerDispatch } = useContext(HeaderContext);
  const {
    app: { isConsumer },
  } = useContext<IAppContext>(AppContext);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    skip: 0,
    showSizeChanger: true,
  });
  const [currentFilters, setCurrentFilters] = useState({});
  const option = {
    count: true,
    filter: generateFilterfromObj(currentFilters).filter,
    top: pagination.pageSize,
    current: pagination.current,
    orderBy: ['createdDate desc'],
    skip: 0,
  };

  const openModal = (type: CalendarTypes) => {
    setDefaultType(type);
    setIsOpenModal(true);
  };

  useEffect(() => {
    headerDispatch({
      type: CHANGE_CURRENT_PAGE,
      currentPage: t('post.header.title'),
      path: 'post',
    });
  }, [i18n.language]);

  useEffect(() => {
    getCalendarUpdates(option);
  }, []);

  const getCalendarUpdates = (option: { [name: string]: any }) => {
    setFetchingData(true);
    service
      .getCalendarUpdates(option)
      .then((res) => {
        const { count, items } = res;
        const { current, top } = option;
        setPagination({ ...pagination, current, total: count, pageSize: top });
        setPosts(items);
      })
      .catch((e) =>
        notification.error({
          message: e.message || e,
        }),
      )
      .finally(() => setFetchingData(false));
  };

  const applyNewFilter = (filters: { [name: string]: any }) => {
    setCurrentFilters({ ...currentFilters, ...filters });
    return { ...currentFilters, ...filters };
  };

  const onFilterChange = (pagination?: TablePaginationConfig, filters?: Record<string, FilterValue | null | any>) => {
    let params: { [name: string]: any } = {
      ...option,
    };

    if (filters && !isEmptyFilter(filters)) {
      params = {
        ...params,
        filter: generateFilterfromObj(applyNewFilter(filters)).filter,
      };
    } else {
      params = {
        ...params,
        filter: generateFilterfromObj(currentFilters).filter,
      };
    }
    if (pagination && pagination.pageSize && pagination.current) {
      params = {
        ...params,
        top: pagination.pageSize,
        skip: pagination.pageSize * (pagination.current - 1),
        current: pagination.current,
      };
    } else {
      params = {
        ...params,
        skip: 0,
        current: 1,
      };
    }

    getCalendarUpdates(params);
  };

  const changePagination = (page: number, pageSize: number) => {
    onFilterChange({ current: page, pageSize });
  };

  const onEdit = (postId: number) => {
    setPostId(postId);
    setIsOpenModal(true);
  };

  const onDelete = (postId: number) => {
    setPostId(postId);
    setIsOpenModalDelete(true);
  };

  const changeOpenFilter = () => {
    setIsOpenFilter(!isOpenFilter);
  };

  const loadMore = (option: { [name: string]: any }) => {
    setFetchingData(true);
    option.skip = option.skip ? option.skip + 10 : 10;
    service
      .getCalendarUpdates(option)
      .then((res) => {
        const { count, items } = res;
        const { current, top } = option;
        setPagination({ ...pagination, current, total: count, pageSize: top });
        setPosts([...posts, ...items]);
      })
      .catch((e) =>
        notification.error({
          message: e.message || e,
        }),
      )
      .finally(() => setFetchingData(false));
  };

  if (useLessThen801()) {
    return (
      <>
        <div className="common-mobile-title-container">
          <span style={{ fontSize: '20px' }}>{t('post.header.title')}</span>
          <div className="common-modbile-filter-container" onClick={changeOpenFilter}>
            <img src={isOpenFilter ? filterActiveIcon : filterIcon} width={16} />
            <span className="filter-title">{isOpenFilter ? t('common.filter.close') : t('common.filter.open')}</span>
          </div>
          {!isConsumer && (
            <Button
              className={`common-blue-btn common-primary-btn`}
              onClick={() => setIsOpenModal(true)}
              style={{ marginLeft: '0.5rem' }}>
              <span>{t('budget.control.create.btn')}</span>
            </Button>
          )}
        </div>
        {isOpenFilter && <PostFilters onFilterChange={onFilterChange} pageSize={pagination.pageSize} />}
        <List>
          <InfiniteScroll
            dataLength={posts.length}
            next={() => loadMore(option)}
            hasMore={posts.length < pagination.total}
            loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
            scrollableTarget="scrollableDiv">
            {posts.length > 0 &&
              posts.map((post) => (
                <PostCard isConsumer={isConsumer} key={post.id} item={post} onEdit={onEdit} onDelete={onDelete} />
              ))}
          </InfiniteScroll>
        </List>
        {isOpenModal && (
          <PostModalForm
            setIsOpenUploadMessageModal={setIsOpenUploadMessageModal}
            isOpenModal={isOpenModal}
            defaultType={CalendarTypes.update}
            postId={postId}
            getCalendarUpdates={() => getCalendarUpdates(option)}
            closeModal={() => {
              setIsOpenModal(false);
              setPostId(null);
            }}
          />
        )}
        {isOpenModalDelete && (
          <PostConfirmModal
            currentId={postId}
            isOpen={isOpenModalDelete}
            setClose={() => {
              setIsOpenModalDelete(false);
              getCalendarUpdates(option);
            }}
          />
        )}
        {isOpenUploadMessageModal && (
          <ModalUploadFileSuccess isOpen={isOpenUploadMessageModal} onOk={() => setIsOpenUploadMessageModal(false)} />
        )}
      </>
    );
  }

  return (
    <Spin spinning={fetchingData}>
      <div className={css['post-container']}>
        <Row gutter={38}>
          <Col md={8} lg={7} xl={6}>
            <PostFilters onFilterChange={onFilterChange} pageSize={pagination.pageSize} />
          </Col>
          <Col md={16} lg={17} xl={18}>
            {!isConsumer && <PostCreateFilters openModal={openModal} />}
            <List
              className={isConsumer ? 'posts-list-consumer' : 'posts-list'}
              pagination={{
                size: 'small',
                className: 'tasks-pagination',
                ...pagination,
                onChange: changePagination,
              }}
              dataSource={posts}
              renderItem={(item, index) => (
                <PostCard isConsumer={isConsumer} key={index} item={item} onEdit={onEdit} onDelete={onDelete} />
              )}
            />
          </Col>
        </Row>
        {isOpenModal && (
          <PostModalForm
            setIsOpenUploadMessageModal={setIsOpenUploadMessageModal}
            isOpenModal={isOpenModal}
            defaultType={defaultType}
            postId={postId}
            getCalendarUpdates={() => getCalendarUpdates(option)}
            closeModal={() => {
              setIsOpenModal(false);
              setPostId(null);
            }}
          />
        )}
        {isOpenModalDelete && (
          <PostConfirmModal
            currentId={postId}
            isOpen={isOpenModalDelete}
            setClose={() => {
              setIsOpenModalDelete(false);
              getCalendarUpdates(option);
            }}
          />
        )}
        {isOpenUploadMessageModal && (
          <ModalUploadFileSuccess isOpen={isOpenUploadMessageModal} onOk={() => setIsOpenUploadMessageModal(false)} />
        )}
      </div>
    </Spin>
  );
};

export default PostsPage;
