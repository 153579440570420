import { DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmptyObject } from '../../../../helpers/isEmptyObject';
import { IDocumentsType, IFlow, IStepType } from '../../../../models/CustomSignFlowModel';
const { Option } = Select;

interface IFlowItem {
  steps: IStepType[];
  documentsType: IDocumentsType[];
  flow?: IFlow;
  onSubmit: (values: any) => void;
  deleteFlow: (id?: number) => void;
  isLast: boolean;
}

const FlowItem = ({ steps, documentsType, onSubmit, flow, deleteFlow, isLast }: IFlowItem) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isEdit, setIsEdit] = useState(!flow);
  const [isDisbled, setIsDisbled] = useState(false);
  const [stepsCount, setStepsCount] = useState(1);

  useEffect(() => {
    if (flow && !isEmptyObject(flow)) {
      setStepsCount(flow.flowsSteps.length);
      form.setFieldsValue({
        id: flow.id,
        name: flow.name,
        documents: flow.documentTypes.map((type) => type.code),
        steps: flow.flowsSteps.map((step) => step.stepCode),
      });
    }
  }, [flow]);

  useEffect(() => {
    setIsDisbled(flow?.isDefault ? true : !isEdit);
  }, [isEdit, flow]);

  return (
    <Form
      form={form}
      onFinish={(value) => {
        onSubmit(value);
        setIsEdit(false);
      }}>
      {flow?.isDefault ? (
        <div
          style={{
            fontWeight: 500,
            fontSize: 16,
            color: '#16122D',
            marginBottom: '1rem',
            textTransform: 'capitalize',
          }}>
          {flow.name}*
        </div>
      ) : (
        <div className="project-customFlow-steps-container">
          <Form.Item name={'id'} style={{ marginBottom: 0, display: 'none' }}>
            <Input />
          </Form.Item>
          <span style={{ fontWeight: 500, fontSize: 16, color: '#16122D', whiteSpace: 'nowrap' }}>
            {t('projects.flow.new.title')}:
          </span>
          <Form.Item
            name={'name'}
            style={{ marginBottom: 0, marginRight: '0.5rem' }}
            rules={[{ required: true, message: t('common.validation.required') }]}>
            <Input
              disabled={isDisbled}
              className={`project-customFlow-steps-name ${isLast ? 'custom-flow-second-step' : ''}`}
            />
          </Form.Item>
          <Form.Item
            name={'documents'}
            style={{ marginBottom: 0, display: flow?.isDefault ? 'none' : 'block' }}
            rules={[{ required: true, message: t('common.validation.required') }]}>
            <Select
              mode="multiple"
              className={`common-select common-animation-primary common-select--disabled ${
                isLast ? 'custom-flow-third-step' : ''
              }`}
              dropdownClassName="budget-control-select-dropdown"
              style={{ minWidth: 200 }}
              placeholder={t('projects.flow.documents.placeholder')}
              showArrow={!isDisbled}
              disabled={isDisbled}>
              {documentsType.length > 0 &&
                documentsType.map((type) => (
                  <Option key={type.code} value={type.code}>
                    {type.description}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </div>
      )}
      <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '0.5rem', flexWrap: 'wrap' }}>
        {new Array(stepsCount).fill(undefined).map((_, index) => (
          <Form.Item
            key={index}
            label={
              <div
                style={{
                  background: '#EEF0F4',
                  border: '1px solid #C4C6CE',
                  borderRadius: 4,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  height: 42,
                  width: 42,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                {index + 1}
              </div>
            }
            name={['steps', index]}
            colon={false}
            className="project-customFlow-step"
            rules={[{ required: true, message: t('common.validation.required') }]}>
            <Select
              className={`common-select common-animation-primary common-select--disabled ${
                isLast ? 'custom-flow-forth-step' : ''
              }`}
              dropdownClassName="budget-control-select-dropdown common-dropdown-fitContent"
              placeholder={t('projects.flow.step.placeholder')}
              style={{ height: 42 }}
              showArrow={!isDisbled}
              disabled={isDisbled}>
              {steps.length > 0 &&
                steps.map((step) => (
                  <Option key={step.code} value={step.code}>
                    {step.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        ))}
        {!flow?.isDefault &&
          (isEdit ? (
            <>
              <Button
                style={{
                  background: '#EEF0F4',
                  border: '1px solid #C4C6CE',
                  borderRadius: 4,
                  marginRight: '0.5rem',
                  marginBottom: '0.5rem',
                  height: 42,
                  width: 42,
                }}
                className={isLast ? 'custom-flow-fifth-step' : ''}
                icon={<PlusOutlined style={{ fontSize: 18 }} />}
                onClick={() => setStepsCount(stepsCount + 1)}
              />
              <Button
                style={{
                  background: '#EEF0F4',
                  border: '1px solid #C4C6CE',
                  borderRadius: 4,
                  marginRight: '0.5rem',
                  marginBottom: '0.5rem',
                  height: 42,
                  width: 42,
                }}
                icon={<DeleteOutlined />}
                onClick={() => setStepsCount(stepsCount === 1 ? 1 : stepsCount - 1)}
              />
              <Button
                style={{
                  background: '#356EDE',
                  border: '1px solid #C4C6CE',
                  marginBottom: '0.5rem',
                  borderRadius: 4,
                  height: 42,
                  width: 42,
                }}
                className={isLast ? 'custom-flow-sixth-step' : ''}
                icon={<SaveOutlined style={{ color: '#FFF', fontSize: 18 }} />}
                onClick={() => (flow?.isDefault ? setIsEdit(false) : form.submit())}
              />
            </>
          ) : (
            <>
              <Button
                style={{
                  background: '#EEF0F4',
                  border: '1px solid #C4C6CE',
                  borderRadius: 4,
                  marginRight: '0.5rem',
                  marginBottom: '0.5rem',
                  height: 42,
                  width: 42,
                }}
                icon={<EditOutlined style={{ fontSize: 18 }} />}
                onClick={() => setIsEdit(true)}
              />
              {!flow?.isDefault && (
                <Button
                  style={{
                    background: '#EEF0F4',
                    border: '1px solid #C4C6CE',
                    borderRadius: 4,
                    marginBottom: '0.5rem',
                    height: 42,
                    width: 42,
                  }}
                  icon={<DeleteOutlined style={{ fontSize: 18 }} />}
                  onClick={() => deleteFlow(flow?.id)}
                />
              )}
            </>
          ))}
      </div>
      {flow?.isDefault && (
        <div
          style={{
            fontWeight: 400,
            fontSize: 12,
            color: '#8B8B8B',
            marginBottom: '1rem',
          }}>
          {t('projects.flow.default.description')}
        </div>
      )}
      <Divider style={{ margin: 0, marginBottom: '1rem' }} />
    </Form>
  );
};

export default FlowItem;
