import { Button, notification, Spin, Table, TablePaginationConfig, TableProps } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import FinalAccountControl from './FinalAccountControl';
import css from '../../../assets/styles/budget.module.css';
import { getColumns } from './FinalAccountColumns';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import FinalAccountService from '../../../services/FinalAccountService';
import { AppContext } from '../../../contexts/AppContextProvider';
import PreviewPDF from './PreviewPDF';
import DeleteFinalAccountForm from './DeleteFinalAccountForm';
import FinalAccountStatusForm from './FinalAccountStatusForm';
import UploadFileLoader from '../../common/UploadFileLoader';
import moment from 'moment';
import SigningConfirmForm from '../../common/SigningConfirmForm';
import { getStorageEnvelopeId, setStorageEnvelopeId } from '../../../helpers/envelopeStorage';
import { getUriSearchValue } from '../../../helpers/proccessUri';
import { docusignEventStatuses } from '../../../constants/docusignEventStatuses';
import { useLocation, useHistory } from 'react-router';
import FinalAccountForm from './FinalAccountForm';
import { generateFilterfromObj } from '../../../helpers/generateFIlterfromObj';
import { isEmptyFilter } from '../../../helpers/isEmptyFilter';
import { useLessThen801 } from '../../../helpers/mediaDetect';
import filterIcon from '../../../assets/images/ic-filter.svg';
import filterActiveIcon from '../../../assets/images/ic-filter-active.svg';
import TableMobile from '../../common/TableMobile';
import CommonApproveForm from '../../common/CommonApproveForm';
import CommonHistoryModal from '../../common/CommonHistoryModal';
import { FiltersContext } from '../../../contexts/FiltersContextProvider';
import { FilterTypes } from '../../../constants/filterTypes';
import { SET_FINAL_ACCOUNTS_FILTERS } from '../../../constants/actionTypes/filtersConstants';
import { IFinalAccountModel } from '../../../models/FinalAccountModel';
import { IHistoryModel } from '../../../models/CommonDocumnetsModel';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { IAppContext } from '../../../typings/IApp';
import { authDownloadFile } from '../../../helpers/authFileTools';

interface IFinalAccountListInfo {
  propertyId: number;
  setIsOpenUploadFinalAccount?: (b: boolean) => void;
  setIsCreatedFinalAccount?: (b: boolean) => void;
  isOpenStatusFinalAccountForm?: boolean;
  isCreatedFinalAccount?: boolean;
  setIsOpenStatusFinalAccountForm?: (b: boolean) => void;
  setCurrentFinalAccount?: (v: number | null) => void;
  currentFinalAccount?: number | null;
}

const service = new FinalAccountService();

const FinalAccountListInfo = ({
  propertyId,
  setIsOpenUploadFinalAccount,
  setIsCreatedFinalAccount,
  isOpenStatusFinalAccountForm,
  isCreatedFinalAccount,
  setIsOpenStatusFinalAccountForm,
  setCurrentFinalAccount,
  currentFinalAccount,
}: IFinalAccountListInfo) => {
  const { t } = useTranslation();
  const { height: windowHeight } = useWindowDimensions();
  const {
    filters: { finalAccountsFilter },
    filtersDispatch,
  } = useContext(FiltersContext);
  const {
    app: { isConsumer, isSupplier, user },
  } = useContext<IAppContext>(AppContext);
  const location = useLocation();
  const [fetchingData, setFetchingData] = useState(false);
  const history = useHistory();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenUploadLoader, setIsOpenUploadLoader] = useState(false);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [finalAcounts, setFinalAccount] = useState<IFinalAccountModel[]>([]);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [currentItemNumber, setCurrentItemNumber] = useState<string | null>(null);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenSigningConfirm, setIsOpenSigningConfirm] = useState(false);
  const [signInfo, setSignInfo] = useState<string | null | undefined>(null);
  const event = getUriSearchValue(location.search, 'event');
  const [isDocusignConfirm, setIsDocusignConfirm] = useState(
    !!event && event === docusignEventStatuses.signingComplete,
  );
  const [isDocusignDecline, setIsDocusignDecline] = useState(!!event && event === docusignEventStatuses.decline);
  const [isOpenApprove, setIsOpenApprove] = useState(false);
  const [currentFilters, setCurrentFilters] = useState({
    'status_v2/code': {
      type: FilterTypes.SELECT,
      value: finalAccountsFilter.status,
    },
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    skip: 0,
  });

  //history
  const [isOpenHistory, setIsOpenHistory] = useState(false);
  const [historyArray, setHistoryArray] = useState<IHistoryModel>([]);
  const [currentItem, setCurrentItem] = useState<IFinalAccountModel | string | null>(null);

  const option = {
    count: true,
    top: pagination.pageSize,
    current: pagination.current,
    filters: {
      filter: generateFilterfromObj(currentFilters).filter,
    },
    orderBy: ['createdDate desc'],
  };

  useEffect(() => {
    if (isCreatedFinalAccount) {
      getFinalAccount(option);
      setIsCreatedFinalAccount && setIsCreatedFinalAccount(false);
    }
  }, [isCreatedFinalAccount]);

  useEffect(() => {
    if (!location.search || isDocusignConfirm) {
      getLocalFilters();
      getFinalAccount(option);
    }
  }, [propertyId, isDocusignConfirm]);

  const getLocalFilters = () => {
    const localFilters = localStorage.getItem('finalAccountsFilter');
    const filters = localFilters && JSON.parse(localFilters);
    if (filters) {
      option.filters = generateFilterfromObj(
        applyNewFilter({
          'status_v2/code': {
            type: FilterTypes.SELECT,
            value: filters.status,
          },
        }),
      );
      filtersDispatch({
        type: SET_FINAL_ACCOUNTS_FILTERS,
        filters: {
          status: filters.status,
        },
      });
    }
  };

  useEffect(() => {
    const envelopeId = getStorageEnvelopeId();
    if (isDocusignConfirm) {
      setFetchingData(true);
      if (!envelopeId) {
        setFetchingData(false);
        setIsDocusignConfirm(false);
      } else {
        service
          .confirmStep(envelopeId)
          .catch((e) => console.error(e))
          .finally(() => {
            setIsDocusignConfirm(false);
            setStorageEnvelopeId('');
            history.replace({ search: '' });
          });
      }
    } else if (isDocusignDecline) {
      setFetchingData(true);
      if (!envelopeId) {
        setFetchingData(false);
        setIsDocusignDecline(false);
      } else {
        service
          .rejectFinalAccount(null, '', envelopeId)
          .catch((e) => console.error(e))
          .finally(() => {
            setIsDocusignDecline(false);
            setStorageEnvelopeId('');
            history.replace({ search: '' });
          });
      }
    }
    if (location.search.includes('event')) {
      history.replace({ search: '' });
    }
  }, []);

  const checkIsDelete = (items: IFinalAccountModel[]) => {
    if (location.search && items.length === 0) {
      notification.warning({
        message: t('common.warning.notification.file.deleted'),
      });
      history.push(location.pathname);
    }
  };

  const getFinalAccount = (option: { [name: string]: any }) => {
    setFetchingData(true);
    service
      .getFinalAccounts(+propertyId, option)
      .then((res) => {
        const { count, items } = res;
        const { current, top } = option;
        setPagination({ ...pagination, current, total: count, pageSize: top });
        setFinalAccount(items);
        checkIsDelete(items);
      })
      .catch((e) => console.log(e))
      .finally(() => setFetchingData(false));
  };

  const loadMore = (option: { [name: string]: any }) => {
    setFetchingData(true);
    option.skip = option.skip ? option.skip + 10 : 10;
    service
      .getFinalAccounts(+propertyId, option)
      .then((res) => {
        const { count, items } = res;
        const { current, top } = option;
        setPagination({ ...pagination, current, total: count, pageSize: top });
        setFinalAccount([...finalAcounts, ...items]);
      })
      .catch((e) => console.log(e))
      .finally(() => setFetchingData(false));
  };

  const applyNewFilter = (filters: { [name: string]: any }) => {
    setCurrentFilters({ ...currentFilters, ...filters });
    return { ...currentFilters, ...filters };
  };

  const onTableChange = (
    pagination?: TablePaginationConfig,
    filters?: Record<string, FilterValue | null | any>,
    sorter?: SorterResult<IFinalAccountModel> | SorterResult<IFinalAccountModel>[],
  ) => {
    let params: { [name: string]: any } = {
      ...option,
    };

    if (filters && !isEmptyFilter(filters)) {
      params = {
        ...params,
        filters: generateFilterfromObj(applyNewFilter(filters)),
      };
    } else {
      params = {
        ...params,
        filters: generateFilterfromObj(currentFilters),
      };
    }
    if (pagination && pagination.pageSize && pagination.current) {
      params = {
        ...params,
        top: pagination.pageSize,
        skip: pagination.pageSize * (pagination.current - 1),
        current: pagination.current,
      };
    } else {
      params = {
        ...params,
        skip: 0,
        current: 1,
      };
    }
    if (sorter && !Array.isArray(sorter) && sorter.order) {
      const { order, columnKey } = sorter;
      switch (columnKey) {
        case 'createdDate': {
          params = {
            ...params,
            orderBy: `createdDate ${order === 'ascend' ? 'asc' : 'desc'}`,
          };
          break;
        }
        case 'deadline': {
          params = {
            ...params,
            orderBy: `deadline ${order === 'ascend' ? 'asc' : 'desc'}`,
          };
          break;
        }
        case 'sumWithVat': {
          params = {
            ...params,
            orderBy: `sumWithVat ${order === 'ascend' ? 'asc' : 'desc'}`,
          };
          break;
        }
        case 'date': {
          params = {
            ...params,
            orderBy: `date ${order === 'ascend' ? 'asc' : 'desc'}`,
          };
          break;
        }
        case 'lastSignDateTime': {
          params = {
            ...params,
            orderBy: `lastSignDateTime ${order === 'ascend' ? 'asc' : 'desc'}`,
          };
          break;
        }
        default: {
          return;
        }
      }
    }

    getFinalAccount(params);
  };

  const openPreview = (id?: number) => {
    setIsOpenPreview(true);
    if (id) {
      setCurrentId(id);
    }
  };

  const closePreview = () => {
    setIsOpenPreview(false);
    setCurrentId(null);
    getFinalAccount(option);
  };

  const setOpenDelete = (id?: number, itemNumber?: string) => {
    setIsOpenDeleteModal(true);
    if (id) {
      setCurrentId(id);
    }
    if (itemNumber) {
      setCurrentItemNumber(itemNumber);
    }
  };

  const setCloseDelete = () => {
    setIsOpenDeleteModal(false);
    setCurrentId(null);
    setCurrentItemNumber(null);
  };

  const setCloseStatus = () => {
    setIsOpenStatusFinalAccountForm && setIsOpenStatusFinalAccountForm(false);
    setCurrentId(null);
    getFinalAccount(option);
  };

  const setOpenStatus = (id?: number) => {
    setIsOpenStatusFinalAccountForm && setIsOpenStatusFinalAccountForm(true);
    if (id) {
      setCurrentId(id);
    }
  };

  const setClose = () => {
    setIsOpenModal(false);
    setCurrentId(null);
  };

  const closeUploadLoader = () => {
    setIsOpenUploadLoader(false);
    getFinalAccount(option);
  };

  const openSigningConfirm = (id?: number, signInfo?: string) => {
    setIsOpenSigningConfirm(true);
    if (id) {
      setCurrentId(id);
      setSignInfo(signInfo);
    }
  };

  const closeSigningConfirm = () => {
    setIsOpenSigningConfirm(false);
    setCurrentId(null);
    setSignInfo(null);
    getFinalAccount(option);
  };

  const signItem = () => {
    localStorage.setItem('finalAccountsFilter', JSON.stringify(finalAccountsFilter));
    if (currentId) {
      setFetchingData(true);
      service
        .getSigningUrl(currentId, window.location.href, user.id)
        .then((res) => {
          const { alreadySigned = false, declined = false } = res;
          if (!alreadySigned && !declined) {
            setStorageEnvelopeId(res.envelopeId);
            window.location.href = res.redirectUrl;
          } else {
            if (alreadySigned) {
              notification.warning({
                message: t('finalAccount.modal.already.signed.message'),
              });
            }
            if (declined) {
              notification.warning({
                message: t('finalAccount.modal.already.decline.message'),
              });
            }
            setFetchingData(false);
          }
        })
        .catch((e) => {
          notification.error({
            message: e.message,
          });
          setFetchingData(false);
        })
        .finally(() => {
          setClose();
        });
    }
  };

  const rejectItem = (iD: number | null, comment: string, isApprove: boolean = false) => {
    const id = iD || currentId;
    if (id) {
      service
        .rejectFinalAccount(id, comment)
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        )
        .finally(() => {
          isApprove ? closeApprove() : closeSigningConfirm();
        });
    }
  };

  const openApprove = (id: number) => {
    setCurrentId(id);
    setIsOpenApprove(true);
  };

  const closeApprove = () => {
    setCurrentId(null);
    setIsOpenApprove(false);
    getFinalAccount(option);
  };

  const approveItem = () => {
    setFetchingData(true);
    if (currentId) {
      service
        .confirmStep(undefined, currentId)
        .then(() => {
          notification.success({
            message: t('fileManagement.finalAccount.approved'),
          });
          closeApprove();
        })
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        )
        .finally(() => setFetchingData(false));
    }
  };

  const setOpen = (id?: number) => {
    setIsOpenModal(true);
    if (id) {
      setCurrentId(id);
    }
  };

  const openHistory = (id: number, title?: string, item?: IFinalAccountModel) => {
    if (item) {
      if (item.statusV2.code === 'rejected') {
        setFetchingData(true);
        service
          .getFinalAccountHistory(id)
          .then((value) => {
            setIsOpenHistory(true);
            setCurrentId(id);
            setHistoryArray(value);
            setCurrentItem(item);
          })
          .catch((e) =>
            notification.error({
              message: e.message,
            }),
          )
          .finally(() => setFetchingData(false));
        return;
      } else {
        setIsOpenHistory(true);
        setCurrentItem(item);
        return;
      }
    }
    setFetchingData(true);
    service
      .getFinalAccountHistory(id)
      .then((value) => {
        setIsOpenHistory(true);
        setCurrentId(id);
        setHistoryArray(value);
        setCurrentItem(title || null);
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingData(false));
  };

  const closeHistory = () => {
    setCurrentId(null);
    setIsOpenHistory(false);
    setCurrentItem(null);
  };

  const data =
    finalAcounts.length > 0 &&
    ((finalAcounts as IFinalAccountModel[]).map((item) => [
      {
        title: t('documents.table.column.name'),
        description: item.number,
        warning: {
          title: <span style={{ color: '#EB3B3B', fontSize: '18px' }}>{t('common.popover.attention.file.title')}</span>,
          content: (
            <div style={{ width: '200px' }}>
              <p>{t('common.popover.attention.file.content')}</p>
            </div>
          ),
        },
        type: !item.file ? 'warning' : 'string',
      },
      { title: t('documents.table.column.description'), description: item.description, type: 'string' },
      { title: t('budget.table.sum.total'), description: item.sumWithVat, type: 'price' },
      { title: t('budget.form.deadline'), description: item.deadline, type: 'date' },
      { title: t('budget.table.status'), description: item.statusV2, type: 'status' },
      {
        title: t('budget.table.action'),
        description: item.file,
        type: 'button',
        previewAction: !!item.file && openPreview,
        id: item.id,
        signatories: item.signatories,
        comment: item.comment || item.signInfo,
        status: item.statusV2,
        openByFunction: true,
        btnsInfo: [
          {
            function: () => setOpen(item.id),
            functionBtnTitle: t('budget.table.edit'),
            isNotShow: isConsumer,
          },
          {
            function: () => openApprove(item.id),
            functionBtnTitle: t('common.title.approve.reject'),
            isNotShow: !(
              item.signatories &&
              item.signatories.findIndex(
                (signatory) =>
                  signatory.consumerId === user.id &&
                  (item.step?.code === 'agent_approve_open' ||
                    item.step?.code === 'agent_approve_close' ||
                    item.step?.code === 'client_approve') &&
                  signatory.stepCode === item.step?.code &&
                  !signatory.isDone,
              ) >= 0 &&
              !['rejected'].includes(item.statusV2.code)
            ),
          },
          {
            function: () => openSigningConfirm(item.id),
            functionBtnTitle: t('common.title.sign'),
            isNotShow: !(
              item.signatories &&
              item.signatories.findIndex(
                (signatory) =>
                  signatory.consumerId === user.id &&
                  (item.step?.code === 'agent_sign_open' ||
                    item.step?.code === 'agent_sign_close' ||
                    item.step?.code === 'client_sign') &&
                  signatory.stepCode === item.step?.code &&
                  !signatory.isDone,
              ) >= 0 &&
              !['rejected'].includes(item.statusV2.code)
            ),
          },
          {
            function: () => (item.flow ? openHistory(item.id, item.number, item) : openHistory(item.id, item.number)),
            functionBtnTitle: t('fileManagement.history.title'),
            isNotShow: isConsumer,
          },
          {
            function: () => authDownloadFile(item.file, item.fileName),
            functionBtnTitle: t('budget.table.download'),
          },
        ],
      },
    ]) as any);

  const changeOpenFilter = () => {
    setIsOpenFilter(!isOpenFilter);
  };

  if (useLessThen801()) {
    return (
      <>
        <div className="common-mobile-title-container">
          <span style={{ fontSize: '20px' }}>{t('finalAccount.title')}</span>
          <div className="common-modbile-filter-container" onClick={changeOpenFilter}>
            <img src={isOpenFilter ? filterActiveIcon : filterIcon} width={16} />
            <span className="filter-title">{isOpenFilter ? t('common.filter.close') : t('common.filter.open')}</span>
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: !isSupplier ? '0' : isOpenFilter ? '0' : '1rem' }}>
          {!isConsumer && (
            <Button
              className={`common-blue-border-btn common-secondary-btn`}
              onClick={() => setIsOpenModal(true)}
              style={{ width: '100%' }}>
              <span>{t('finalAccount.upload')}</span>
            </Button>
          )}
        </div>
        {isOpenFilter && (
          <FinalAccountControl
            setIsOpenModal={setIsOpenModal}
            openCreate={setIsOpenUploadFinalAccount}
            propertyId={propertyId}
            onTableChange={onTableChange}
          />
        )}
        <TableMobile
          data={data}
          isLoading={fetchingData}
          loadMore={loadMore}
          option={option}
          itemsLength={finalAcounts.length}
          totalItems={pagination.total}
        />
        {isOpenModal && (
          <FinalAccountForm
            setClose={setClose}
            propertyId={propertyId}
            getFinalAccounts={() => getFinalAccount(option)}
            currentId={currentId}
            isOpenModal={isOpenModal}
            setIsOpenUploadLoader={setIsOpenUploadLoader}
            setIsUploadingFile={setIsUploadingFile}
          />
        )}
        {isOpenStatusFinalAccountForm && (
          <FinalAccountStatusForm
            isOpen={isOpenStatusFinalAccountForm}
            id={currentId}
            propertyId={+propertyId}
            currentId={currentId}
            setCloseStatus={setCloseStatus}
            currentFinalAccount={currentFinalAccount}
            setCurrentFinalAccount={setCurrentFinalAccount}
          />
        )}
        {isOpenPreview && <PreviewPDF id={currentId} isOpen={isOpenPreview} setClose={closePreview} />}
        {isOpenUploadLoader && (
          <UploadFileLoader isOpen={isOpenUploadLoader} isLoading={isUploadingFile} setClose={closeUploadLoader} />
        )}
        {isOpenSigningConfirm && (
          <SigningConfirmForm
            id={currentId}
            isOpen={isOpenSigningConfirm}
            setClose={closeSigningConfirm}
            sign={signItem}
            reject={rejectItem}
            fetchingData={fetchingData}
            signInfo={signInfo}
          />
        )}
        {isOpenApprove && (
          <CommonApproveForm
            approve={approveItem}
            isOpen={isOpenApprove}
            reject={(id, comment) => rejectItem(id, comment, true)}
            setClose={closeApprove}
            fetchingData={fetchingData}
            type={'final account'}
          />
        )}
        {isOpenHistory && (
          <CommonHistoryModal
            fetchingData={fetchingData}
            setFetchingData={setFetchingData}
            isOpen={isOpenHistory}
            items={historyArray}
            setClose={closeHistory}
            currentItem={currentItem}
          />
        )}
      </>
    );
  }

  return (
    <div className={css['table-container']} style={{ width: '100%', flex: 'none' }}>
      <Spin spinning={fetchingData}>
        <FinalAccountControl
          setIsOpenModal={setIsOpenModal}
          openCreate={setIsOpenUploadFinalAccount}
          propertyId={propertyId}
          onTableChange={onTableChange}
        />
        <Table
          columns={getColumns({
            t,
            isConsumer,
            consumerId: user.id,
            openPreview,
            setOpenDelete,
            setOpenStatus,
            openSigningConfirm,
            openApprove,
            setOpen,
            openHistory,
          })}
          size="small"
          className={`list-info-table`}
          rowClassName={(record) =>
            `common-table-row--pointer ${
              (!record.file ||
                (moment(record.deadline).isBefore(moment()) &&
                  !['signed', 'rejected', 'done', 'canceled'].includes(record?.statusV2.code))) &&
              'common-table-row--error'
            }`
          }
          dataSource={finalAcounts as IFinalAccountModel[]}
          showHeader={true}
          pagination={pagination}
          scroll={{ y: windowHeight - 330 }}
          onChange={onTableChange as TableProps<IFinalAccountModel>['onChange']}
          rowKey="id"
        />
        {isOpenModal && (
          <FinalAccountForm
            setClose={setClose}
            propertyId={propertyId}
            getFinalAccounts={() => getFinalAccount(option)}
            currentId={currentId}
            isOpenModal={isOpenModal}
            setIsOpenUploadLoader={setIsOpenUploadLoader}
            setIsUploadingFile={setIsUploadingFile}
          />
        )}
        {isOpenDeleteModal && currentId && (
          <DeleteFinalAccountForm
            setClose={setCloseDelete}
            itemNumber={currentItemNumber}
            getItems={() => getFinalAccount(option)}
            currentId={currentId}
            isOpen={isOpenDeleteModal}
          />
        )}
        {isOpenStatusFinalAccountForm && (
          <FinalAccountStatusForm
            isOpen={isOpenStatusFinalAccountForm}
            id={currentId}
            propertyId={+propertyId}
            currentId={currentId}
            setCloseStatus={setCloseStatus}
            currentFinalAccount={currentFinalAccount}
            setCurrentFinalAccount={setCurrentFinalAccount}
          />
        )}
        {isOpenPreview && <PreviewPDF id={currentId} isOpen={isOpenPreview} setClose={closePreview} />}
        {isOpenUploadLoader && (
          <UploadFileLoader isOpen={isOpenUploadLoader} isLoading={isUploadingFile} setClose={closeUploadLoader} />
        )}
        {isOpenSigningConfirm && (
          <SigningConfirmForm
            id={currentId}
            isOpen={isOpenSigningConfirm}
            setClose={closeSigningConfirm}
            sign={signItem}
            reject={rejectItem}
            fetchingData={fetchingData}
            signInfo={signInfo}
          />
        )}
        {isOpenApprove && (
          <CommonApproveForm
            approve={approveItem}
            isOpen={isOpenApprove}
            reject={(id, comment) => rejectItem(id, comment, true)}
            setClose={closeApprove}
            fetchingData={fetchingData}
            type="finalAccount"
          />
        )}
        {isOpenHistory && (
          <CommonHistoryModal
            fetchingData={fetchingData}
            setFetchingData={setFetchingData}
            isOpen={isOpenHistory}
            items={historyArray}
            setClose={closeHistory}
            currentItem={currentItem}
          />
        )}
      </Spin>
    </div>
  );
};

export default FinalAccountListInfo;
