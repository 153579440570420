import { Upload } from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import css from '../../../../assets/styles/calendar.module.css';
import config from '../../../../config';
import { authDownloadFile } from '../../../../helpers/authFileTools';
import { CalendarTypes } from '../../../../models/CalendarModel';
import i18n from '../../../../utils/i18n';
import parser from 'html-react-parser';

interface UpdatesProps {
  updates: any;
  currDate: moment.Moment;
}
const UpdatesComponent = (props: UpdatesProps) => {
  const { updates, currDate } = props;

  useEffect(() => {
    if (updates) {
      const currUpdate = updates.filter(
        (update: any) => moment(update.date).format('YYYY-MM-DD') === currDate.utc().format('YYYY-MM-DD'),
      )[0];
      if (currUpdate) {
        document.getElementById(`id-${currUpdate?.id}`)?.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [currDate, updates]);

  return (
    <div className={css['date-news-view']}>
      {updates
        .sort((a: any, b: any) => b.id - a.id)
        .map((update: any) => (
          <div id={`id-${update.id}`} key={update.id}>
            <div className={css['date-news-desc']}>
              <li className={css['update-date']} style={{ marginBottom: '0.5rem' }}>
                {moment(update.date || update.datetime)
                  .locale(i18n.language)
                  .format('DD MMMM')}
              </li>
              <span className="parser-container">{parser(update.description || update.name || '')}</span>
              {update.files && (
                <Upload
                  listType={
                    update.type.code === CalendarTypes.document ||
                    update.files.map((file: any) => file.file.slice(-3)).includes('pdf')
                      ? 'text'
                      : 'picture-card'
                  }
                  openFileDialogOnClick={false}
                  className={
                    update.type.code === CalendarTypes.document ||
                    update.files.map((file: any) => file.file.slice(-3)).includes('pdf')
                      ? 'upload-updates'
                      : 'upload-updates-view'
                  }
                  defaultFileList={
                    update.files.map((file: any) => ({
                      name: file.fileName || '',
                      uid: file.id,
                      url: `${config.storeUrl}/${file.file}`,
                      file: file.file,
                    })) as any
                  }
                  directory
                  showUploadList={{ showDownloadIcon: true, showRemoveIcon: false }}
                  beforeUpload={() => false}
                  onPreview={(file: any) => {
                    authDownloadFile(file.file, file.name);
                  }}
                />
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

export default UpdatesComponent;
