import { Button, Modal, notification, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProjectsService from '../../services/ProjectsService';
import UsersService from '../../services/UsersService';
import css from '../../assets/styles/consumers.module.css';

interface IModalSwitchUserBlock {
  isOpen: boolean;
  setClose: any;
  getUsers: any;
  isConsumer: boolean;
  user?: any;
  userId?: number;
  projectId?: number;
  isDelete?: boolean;
}

const projectService = new ProjectsService();
const userService = new UsersService();

const ModalSwitchUserBlock = ({
  isOpen,
  setClose,
  userId,
  projectId,
  getUsers,
  isDelete,
  isConsumer,
  user,
}: IModalSwitchUserBlock) => {
  const { t } = useTranslation();
  const [fetchingData, setFetchingData] = useState(false);
  const [companies, setCompanies] = useState<any[]>([]);
  const [companyId, setCompanyId] = useState<any>(null);
  const [stage, setStage] = useState(1);
  const [IsDelete, setIsDelete] = useState(isDelete || false);

  useEffect(() => {
    if (user?.projects.length > 0) {
      const userCompanies: any[] = [];
      user.projects.forEach((p: any) => {
        p.companies.forEach((c: any) => {
          userCompanies.push(c);
        });
      });
      const ids = Array.from(new Set(userCompanies.map((c: any) => c.id)));
      setCompanies(ids.map((id) => userCompanies.find((c: any) => c.id === id)));
    }
  }, [user]);

  useEffect(() => {
    if (projectId) {
      projectService
        .getProjectById(projectId)
        .then((project) => {
          setCompanies(project.companies || []);
          if (project.companies.length === 1) {
            setCompanyId(project.companies[0].id);
            setStage(2);
          }
        })
        .catch((e: any) =>
          notification.error({
            message: e.message,
          }),
        )
        .finally(() => setFetchingData(false));
    }
  }, [projectId]);

  useEffect(() => {
    if (companies.length === 1) {
      setCompanyId(companies[0].id);
      setStage(2);
    }
  }, [companies]);

  useEffect(() => {
    if (!isConsumer && companyId) {
      const project = user.projects.find((p: any) => p.companies.find((c: any) => c.id === companyId));
      setIsDelete(!user.usersProjects.find((p: any) => p.projectId === project.id)?.deletedDate);
    }
  }, [companyId]);

  const isOpenSwitchUserBlock = () => {
    setFetchingData(true);
    userService
      .switchUserBlock({ userId: isConsumer ? userId : user.id, companyId, isDelete: IsDelete })
      .then(() => {
        getUsers();
        notification.success({
          message: isConsumer
            ? IsDelete
              ? t('consumers.block.success.notify')
              : t('consumers.unblock.success.notify')
            : IsDelete
            ? t('users.block.success.notify')
            : t('users.unblock.success.notify'),
        });
        setClose();
      })
      .catch((e: any) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingData(false));
  };

  return (
    <Spin spinning={fetchingData}>
      <Modal
        className="common-modal"
        centered
        style={{ width: '10rem' }}
        visible={isOpen}
        footer={
          <div className="common-modal-footer">
            <Button
              className={`common-gray-border-btn common-secondary-btn`}
              onClick={() => (stage === 1 || companies.length === 1 ? setClose() : setStage(1))}
              disabled={fetchingData}>
              {t('budget.modal.cancel.btn')}
            </Button>
            <Button
              style={{ textTransform: 'uppercase' }}
              className={`common-green-btn common-modal-btn`}
              onClick={() => (stage === 1 ? setStage(2) : isOpenSwitchUserBlock())}
              disabled={stage === 1 ? !companyId : false || fetchingData}>
              {stage === 1 ? t('common.modal.confirm') : IsDelete ? t('user.btn.deactivate') : t('user.btn.activate')}
            </Button>
          </div>
        }
        closable={false}>
        {stage === 1 ? (
          <div>
            <div>{t('company.select.placeholder')}</div>
            <Select
              className="common-select common-animation-primary"
              suffixIcon={
                <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
              }
              style={{ marginTop: '1rem' }}
              placeholder={t('company.select.placeholder')}
              onChange={setCompanyId}>
              {companies.length > 0 &&
                companies.map((item: any) => (
                  <Select.Option key={item.id} value={item.id}>
                    <span>{item.companyName}</span>
                  </Select.Option>
                ))}
            </Select>
          </div>
        ) : (
          <span>{`${IsDelete ? t('modal.delete.user.delete.confirm') : t('modal.delete.user.restore.confirm')} ${
            companies.find((el) => el.id === companyId)?.companyName || ''
          }?`}</span>
        )}
      </Modal>
    </Spin>
  );
};

export default ModalSwitchUserBlock;
