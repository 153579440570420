import Search from 'antd/lib/input/Search';
import React, { useState } from 'react';
import css from '../../../assets/styles/reports.module.css';
import searchIcon from '../../../assets/images/ic-search.svg';
import { useTranslation } from 'react-i18next';
import { FilterTypes } from '../../../constants/filterTypes';

interface ReportControlModel {
  onTableChange: (pagination: any, filters?: any, sorter?: any) => void;
}

const ReportControl: React.FC<ReportControlModel> = (propsControl: ReportControlModel) => {
  const { onTableChange } = propsControl;
  const { t } = useTranslation();
  const [currentTimeout, setCurrentTimeout] = useState<any>(null);

  const onSearch = (value: any) => {
    const searchFilter = {
      searchByName: {
        type: FilterTypes.MULTISEARCH,
        value: [{ name: value.target.value }, { title: value.target.value }],
      },
    };

    clearTimeout(currentTimeout);
    setCurrentTimeout(setTimeout(() => onTableChange(null, searchFilter), 1000));
  };

  return (
    <div className={css['reports-control-container']}>
      <div className={`${css['reports-control-filters-container']} consumer-control-wrapper`}>
        <div className={css['reports-filter-row']}>
          <Search
            onChange={onSearch}
            placeholder={t('internal.search.name.placeholder')}
            allowClear
            className="budget-control-search common-animation-primary"
            suffix={<img src={searchIcon} alt="" />}
            style={{ width: '295px', padding: '1px 0' }}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportControl;
