import React, { useState } from 'react';
import { Button, Form, Modal, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import css from '../../assets/styles/signConfirm.module.css';
import TextArea from 'antd/lib/input/TextArea';
import { useForm } from 'antd/lib/form/Form';

interface Props {
  setClose: () => void;
  isOpen: boolean;
  approve: () => void;
  reject: (n: number | null, s: string) => void;
  fetchingData?: boolean;
  type: string;
}

const CommonApproveForm = (props: Props) => {
  const { setClose, isOpen, reject, fetchingData = false, approve, type } = props;
  const { t } = useTranslation();
  const [form] = useForm();
  const [isReject, setisReject] = useState(false);

  const onSubmit = (value: { comment: string }) => {
    reject(null, value.comment);
  };

  return (
    <div>
      <Spin spinning={fetchingData}>
        <Modal
          className={css['confirm-sign-modal']}
          centered
          style={{ height: '5rem' }}
          visible={isOpen}
          width={450}
          closable={false}
          footer={
            <div className={css['confirm-sign-modal-footer']}>
              {!isReject && (
                <Button
                  key="approve"
                  className={`${css['confirm-sign-modal-save-btn']} common-modal-btn`}
                  onClick={approve}
                  disabled={fetchingData}>
                  {t('quotes.status.approve.btn')}
                </Button>
              )}
              <Button
                key="reject"
                className={css['confirm-sign-modal-reject-btn']}
                onClick={() => (isReject ? form.submit() : setisReject(true))}
                disabled={fetchingData}>
                {t('quotes.status.reject.btn')}
              </Button>
              <Button
                key="camcel"
                className={`${css['confirm-sign-modal-cancel-btn']} common-secondary-btn`}
                onClick={setClose}
                disabled={fetchingData}>
                {t('common.btn.cancel')}
              </Button>
            </div>
          }>
          {isReject ? (
            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', gap: '1rem' }}>
              <div className={css['confirm-sign-modal-title']}>{t('quotes.modal.confirm.reject.message')}</div>
              <Form form={form} layout="vertical" onFinish={onSubmit}>
                <Form.Item
                  labelAlign="left"
                  label={
                    <span key="label-comment" style={{ color: '#778dac' }}>
                      {t('budget.form.cancel.comment')}
                    </span>
                  }
                  name="comment"
                  rules={[
                    {
                      required: true,
                      message: t('common.validation.required'),
                    },
                  ]}
                  style={{ marginBottom: 0 }}>
                  <TextArea
                    className="common-animation-primary common-textarea"
                    autoSize={{ minRows: 4 }}
                    placeholder={'Agent reasons'}
                  />
                </Form.Item>
              </Form>
            </div>
          ) : (
            <span className={css['confirm-sign-modal-title']}>{`${t('fileManagement.approve.text')} ${type}`}</span>
          )}
        </Modal>
      </Spin>
    </div>
  );
};

export default CommonApproveForm;
