exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".signConfirm_confirm-sign-modal__1m6a8 {\n  width: 5rem;\n  background-color: rgba(0, 18, 45, 0.8);\n}\n.signConfirm_confirm-sign-modal-footer__3Likk {\n  bottom: 0;\n  width: 100%;\n  display: flex;\n  justify-content: flex-end;\n  margin-bottom: 1rem;\n}\n.signConfirm_confirm-sign-modal-save-btn__2mnYg,\n.signConfirm_confirm-sign-modal-save-btn__2mnYg:hover,\n.signConfirm_confirm-sign-modal-save-btn__2mnYg:focus,\n.signConfirm_confirm-sign-modal-save-btn__2mnYg:active {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border: solid 1px #00d29f;\n  border-radius: 4px;\n  background-color: #00d29f;\n  padding: 1rem;\n  color: #fff;\n  padding: 1rem;\n}\n.signConfirm_confirm-sign-modal-reject-btn__1QE07,\n.signConfirm_confirm-sign-modal-reject-btn__1QE07:hover,\n.signConfirm_confirm-sign-modal-reject-btn__1QE07:focus,\n.signConfirm_confirm-sign-modal-reject-btn__1QE07:active {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 4px;\n  padding: 1rem;\n  color: #fff;\n  padding: 1rem;\n  border: solid 1px #ff4d4f;\n  background-color: #ff4d4f;\n}\n.signConfirm_confirm-sign-modal-reject-btn__1QE07:hover {\n  border-color: rgb(255, 77, 79, 0.8);\n  background: rgb(255, 77, 79, 0.8);\n}\n.signConfirm_confirm-sign-modal-cancel-btn__2irjS,\n.signConfirm_confirm-sign-modal-cancel-btn__2irjS:hover,\n.signConfirm_confirm-sign-modal-cancel-btn__2irjS:focus,\n.signConfirm_confirm-sign-modal-cancel-btn__2irjS:active {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 4px;\n  border: solid 1px #c4c6ce;\n  padding: 1rem;\n  color: #778dac;\n  padding: 1rem;\n}\n.signConfirm_confirm-sign-modal-title__3mHEL {\n  color: #00122d;\n  font-size: 16px;\n  font-weight: 600;\n}\n", ""]);

// Exports
exports.locals = {
	"confirm-sign-modal": "signConfirm_confirm-sign-modal__1m6a8",
	"confirm-sign-modal-footer": "signConfirm_confirm-sign-modal-footer__3Likk",
	"confirm-sign-modal-save-btn": "signConfirm_confirm-sign-modal-save-btn__2mnYg",
	"confirm-sign-modal-reject-btn": "signConfirm_confirm-sign-modal-reject-btn__1QE07",
	"confirm-sign-modal-cancel-btn": "signConfirm_confirm-sign-modal-cancel-btn__2irjS",
	"confirm-sign-modal-title": "signConfirm_confirm-sign-modal-title__3mHEL"
};