import { Button, Checkbox, Form, FormInstance, Input, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../../../assets/styles/faqAI.module.css';
import {
  AiLoadingActions,
  IAiFaqQuestion,
  IAiTopicWithId,
  ILoadingAction,
  TopicsModel,
} from '../../../../../models/FAQModel';
import { DeleteOutlined } from '@ant-design/icons';
import aiRegenerateIcon from '../../../../../assets/images/aiRegenerate.svg';
import { aiLanguageGenerating } from '../../../../../constants/aiLanguageGenerating';
import AITopicFields from './AITopicFields';
import { useLessThen801 } from '../../../../../helpers/mediaDetect';

interface IProps {
  form: FormInstance;
  id: number;
  item: IAiFaqQuestion;
  topics: TopicsModel;
  aiTopics: IAiTopicWithId[];
  languageGenerating: aiLanguageGenerating;
  loadingAction?: ILoadingAction;
  isTopicsLoading: boolean;
  defaultSelect: boolean;
  handleDeleteProperty: (n: number) => void;
  onRegenerate: (item: Omit<IAiFaqQuestion, 'id'>, id: number) => void;
  handleGenerateTopic: (item: IAiFaqQuestion) => void;
}

const AIItem = ({
  id,
  form,
  item,
  topics,
  aiTopics,
  loadingAction,
  handleDeleteProperty,
  onRegenerate,
  languageGenerating,
  handleGenerateTopic,
  defaultSelect,
  isTopicsLoading,
}: IProps) => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(defaultSelect);

  useEffect(() => {
    setIsChecked(defaultSelect);
  }, [defaultSelect]);

  const handleRegenerate = () => {
    const { isSelect, topicEn, topicFr, ...item } = form.getFieldValue(id);
    if (Object.values(item as Omit<IAiFaqQuestion, 'id'>).some((el) => !el.question || !el.answer)) {
      return notification.error({ message: t('faq.ai.notification.regenerate.required') });
    }
    onRegenerate(item, id);
  };

  if (useLessThen801()) {
    return (
      <Form.Item name={id}>
        <AITopicFields
          id={id}
          isChecked={isChecked}
          form={form}
          aiTopics={aiTopics}
          languageGenerating={languageGenerating}
          topics={topics}
          loadingAction={loadingAction}
          handleGenerateTopic={() => handleGenerateTopic(item)}
          isTopicsLoading={isTopicsLoading}
        />
        <div className={css.aiResultItem}>
          <div style={{ display: 'flex', gap: 8, justifyContent: 'space-between' }}>
            <Form.Item name={[id, 'isSelect']} valuePropName="checked" initialValue={false} style={{ marginBottom: 0 }}>
              <Checkbox onChange={(e) => setIsChecked(e.target.checked)} />
            </Form.Item>
            <div style={{ display: 'flex', gap: 8 }}>
              <Button
                className={css.aiRegenerateBtn}
                onClick={handleRegenerate}
                loading={
                  loadingAction &&
                  loadingAction.owner === AiLoadingActions.details &&
                  loadingAction.index === id &&
                  loadingAction.isloading
                }
                disabled={
                  loadingAction &&
                  (loadingAction.owner === AiLoadingActions.details
                    ? loadingAction.index !== id && loadingAction.isloading
                    : loadingAction.isloading)
                }>
                <img src={aiRegenerateIcon} />
              </Button>
              <Button
                icon={<DeleteOutlined />}
                className={css.aiDeleteBtn}
                onClick={() => handleDeleteProperty(id)}
                disabled={loadingAction && loadingAction.isloading}></Button>
            </div>
          </div>
          <Form.Item
            label={languageGenerating !== aiLanguageGenerating.fr ? 'EN' : 'FR'}
            name={[id, languageGenerating === aiLanguageGenerating.fr_en ? 'en' : languageGenerating]}
            style={{ marginBottom: 0 }}>
            <Form.Item
              name={[id, languageGenerating === aiLanguageGenerating.fr_en ? 'en' : languageGenerating, 'question']}
              style={{ marginBottom: 8 }}
              rules={[{ required: isChecked, message: t('common.validation.required') }]}
              initialValue={languageGenerating !== aiLanguageGenerating.fr ? item.en.question : item.fr.question}>
              <Input />
            </Form.Item>
            <Form.Item
              name={[id, languageGenerating == aiLanguageGenerating.fr_en ? 'en' : languageGenerating, 'answer']}
              style={{ marginBottom: 8 }}
              rules={[{ required: isChecked, message: t('common.validation.required') }]}
              initialValue={languageGenerating !== aiLanguageGenerating.fr ? item.en.answer : item.fr.answer}>
              <Input.TextArea />
            </Form.Item>
          </Form.Item>
          {languageGenerating === aiLanguageGenerating.fr_en && (
            <div style={{ width: '100%' }}>
              <Form.Item name={[id, 'fr']} label={'FR'} style={{ marginBottom: 0 }}>
                <Form.Item
                  name={[id, 'fr', 'question']}
                  style={{ marginBottom: 8 }}
                  rules={[{ required: isChecked, message: t('common.validation.required') }]}
                  initialValue={item.fr.question}>
                  <Input />
                </Form.Item>
                <Form.Item
                  name={[id, 'fr', 'answer']}
                  style={{ marginBottom: 8 }}
                  rules={[{ required: isChecked, message: t('common.validation.required') }]}
                  initialValue={item.fr.answer}>
                  <Input.TextArea />
                </Form.Item>
              </Form.Item>
            </div>
          )}
        </div>
      </Form.Item>
    );
  }

  return (
    <Form.Item name={id}>
      <AITopicFields
        id={id}
        isChecked={isChecked}
        form={form}
        aiTopics={aiTopics}
        languageGenerating={languageGenerating}
        topics={topics}
        loadingAction={loadingAction}
        handleGenerateTopic={() => handleGenerateTopic(item)}
        isTopicsLoading={isTopicsLoading}
      />
      <div className={css.aiResultItem}>
        <Form.Item name={[id, 'isSelect']} valuePropName="checked" initialValue={false}>
          <Checkbox onChange={(e) => setIsChecked(e.target.checked)} />
        </Form.Item>
        <div style={{ width: '100%' }}>
          <Form.Item name={[id, languageGenerating === aiLanguageGenerating.fr_en ? 'en' : languageGenerating]}>
            <Form.Item
              name={[id, languageGenerating === aiLanguageGenerating.fr_en ? 'en' : languageGenerating, 'question']}
              style={{ marginBottom: 8 }}
              rules={[{ required: isChecked, message: t('common.validation.required') }]}
              initialValue={languageGenerating !== aiLanguageGenerating.fr ? item.en.question : item.fr.question}>
              <Input />
            </Form.Item>
            <Form.Item
              name={[id, languageGenerating == aiLanguageGenerating.fr_en ? 'en' : languageGenerating, 'answer']}
              style={{ marginBottom: 8 }}
              rules={[{ required: isChecked, message: t('common.validation.required') }]}
              initialValue={languageGenerating !== aiLanguageGenerating.fr ? item.en.answer : item.fr.answer}>
              <Input.TextArea />
            </Form.Item>
          </Form.Item>
          <div style={{ display: 'flex', gap: 8 }}>
            <Button
              className={css.aiRegenerateBtn}
              onClick={handleRegenerate}
              loading={
                loadingAction &&
                loadingAction.owner === AiLoadingActions.details &&
                loadingAction.index === id &&
                loadingAction.isloading
              }
              disabled={
                loadingAction &&
                (loadingAction.owner === AiLoadingActions.details
                  ? loadingAction.index !== id && loadingAction.isloading
                  : loadingAction.isloading)
              }>
              <img src={aiRegenerateIcon} style={{ marginRight: '1rem' }} />
              {t('faq.step2.btn.regenerate')}
            </Button>
            <Button
              icon={<DeleteOutlined />}
              className={css.aiDeleteBtn}
              onClick={() => handleDeleteProperty(id)}
              disabled={loadingAction && loadingAction.isloading}>
              {t('faq.step2.btn.delete')}
            </Button>
          </div>
        </div>
        {languageGenerating === aiLanguageGenerating.fr_en && (
          <div style={{ width: '100%' }}>
            <Form.Item name={[id, 'fr']}>
              <Form.Item
                name={[id, 'fr', 'question']}
                style={{ marginBottom: 8 }}
                rules={[{ required: isChecked, message: t('common.validation.required') }]}
                initialValue={item.fr.question}>
                <Input />
              </Form.Item>
              <Form.Item
                name={[id, 'fr', 'answer']}
                style={{ marginBottom: 8 }}
                rules={[{ required: isChecked, message: t('common.validation.required') }]}
                initialValue={item.fr.answer}>
                <Input.TextArea />
              </Form.Item>
            </Form.Item>
          </div>
        )}
      </div>
    </Form.Item>
  );
};

export default AIItem;
