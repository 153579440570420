import { useState } from 'react';
import useGetPageTypeConfig from './useGetPageTypeConfig';
import { TablePaginationConfig, notification } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { isEmptyFilter } from '../../../helpers/isEmptyFilter';
import { generateFilterfromObj } from '../../../helpers/generateFIlterfromObj';
import i18n from '../../../utils/i18n';
import { fileManagementTypes } from '../../../constants/fileManagementTypes';
import useRequests from './useRequest';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FilterTypes } from '../../../constants/filterTypes';
import { CommonDocumentsType } from '../../../models/CommonDocumnetsModel';

interface IProps {
  resetFiltersRef: any;
  initPagination?: {
    current: number;
    pageSize: number;
    total: number;
    skip: number;
    showSizeChanger: boolean;
  };
  initFilters?: {
    [key: string]: {
      type: FilterTypes;
      value: any;
    };
  };
}

const useTable = ({ resetFiltersRef, initPagination, initFilters }: IProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const pageType = location.pathname.slice(17, location.pathname.length);
  const { setRequestByType } = useRequests();
  const pageTypeConfig = useGetPageTypeConfig();
  const [fetchingData, setFetchingData] = useState(false);
  const [pagination, setPagination] = useState(
    initPagination || {
      current: 1,
      pageSize: 10,
      total: 0,
      skip: 0,
      showSizeChanger: true,
    },
  );
  const [filters, setFilters] = useState(initFilters || {});
  const [items, setItems] = useState<CommonDocumentsType[]>([]);

  const option = {
    count: true,
    top: pagination.pageSize,
    current: pagination.current,
    filters: {
      filter: generateFilterfromObj(filters).filter,
    },
    orderBy: ['createdDate desc'],
    skip: pagination.pageSize * (pagination.current - 1) || 0,
  };

  const checkIsDelete = (items: CommonDocumentsType[]) => {
    if (location.search && items.length === 0) {
      notification.warning({
        message: t('common.warning.notification.file.deleted'),
      });
      history.push(location.pathname);
      resetFiltersRef.current();
    }
  };

  const getItems = (params: any) => {
    setFetchingData(true);
    if (fileManagementTypes.includes(pageType)) {
      setRequestByType(params)
        .then((res) => {
          const { count, items } = res;
          const { current, top } = params;
          setPagination({ ...pagination, current, total: count, pageSize: top });
          setItems(items);
          checkIsDelete(items);
        })
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        )
        .finally(() => setFetchingData(false));
    }
  };

  const loadMore = (isDocusignConfirm: boolean) => {
    if (!isDocusignConfirm) {
      setFetchingData(true);
      option.skip = option.skip ? option.skip + 10 : 10;
      setRequestByType(option)
        .then((res) => {
          const { count } = res;
          const { current, top } = option;
          setPagination({ ...pagination, current, total: count, pageSize: top });
          setItems([...items, ...res.items]);
        })
        .catch((e) => console.log(e))
        .finally(() => setFetchingData(false));
    }
  };

  const getItemsReq = () => getItems(option);

  const applyNewFilter = (newfilters: any) => {
    setFilters({ ...initFilters, ...newfilters });
    return { ...initFilters, ...newfilters };
  };

  const onTableChange = (
    newPagination?: TablePaginationConfig,
    newFilters?: Record<string, FilterValue | null | any>,
    newSorter?: SorterResult<CommonDocumentsType> | SorterResult<CommonDocumentsType>[],
  ) => {
    let params: { [name: string]: any } = {
      ...option,
    };

    if (newFilters && !isEmptyFilter(newFilters)) {
      params = {
        ...params,
        filters: generateFilterfromObj(applyNewFilter(newFilters)),
      };
    } else if (!newPagination && !newSorter) {
      params = {
        ...params,
        filters: generateFilterfromObj(applyNewFilter(initFilters)),
      };
    }
    if (newPagination && newPagination.pageSize && newPagination.current) {
      params = {
        ...params,
        top: newPagination.pageSize,
        skip: newPagination.pageSize * (newPagination.current - 1),
        current: newPagination.current,
      };
    } else {
      params = {
        ...params,
        skip: 0,
        current: 1,
      };
    }

    if (newSorter && !Array.isArray(newSorter) && newSorter.order) {
      const { order, columnKey } = newSorter;
      switch (columnKey) {
        case 'property': {
          params = {
            ...params,
            orderBy: `property/title ${order === 'ascend' ? 'asc' : 'desc'}`,
          };
          break;
        }
        case 'project': {
          params = {
            ...params,
            orderBy: `${
              pageTypeConfig.isDocuments || pageTypeConfig.isInvoices ? 'property_project/title' : 'project/title'
            } ${order === 'ascend' ? 'asc' : 'desc'}`,
          };
          break;
        }
        case 'name': {
          params = {
            ...params,
            orderBy: `${pageTypeConfig.isDocuments ? 'title' : 'number'} ${order === 'ascend' ? 'asc' : 'desc'}`,
          };
          break;
        }
        case 'description': {
          params = {
            ...params,
            orderBy: `description ${order === 'ascend' ? 'asc' : 'desc'}`,
          };
          break;
        }
        case 'sumWithVat': {
          params = {
            ...params,
            orderBy: `${pageTypeConfig.isAmendments ? 'deltaWithVat' : 'sumWithVat'} ${
              order === 'ascend' ? 'asc' : 'desc'
            }`,
          };
          break;
        }
        case 'deadline': {
          params = {
            ...params,
            orderBy: `deadline ${order === 'ascend' ? 'asc' : 'desc'}`,
          };
          break;
        }
        case 'status': {
          params = {
            ...params,
            orderBy: `status_v2/description${i18n.language
              .split('-')[0]
              .split('')
              .map((char, index) => (index === 0 ? char.toUpperCase() : char))
              .join('')} ${order === 'ascend' ? 'asc' : 'desc'}`,
          };
          break;
        }
        default: {
          return;
        }
      }
    }
    getItems(params);
  };

  return { onTableChange, fetchingData, items, getItemsReq, option, pagination, loadMore };
};

export default useTable;
