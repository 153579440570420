import React from 'react';
import css from '../../../../../assets/styles/statistics.module.css';

interface IProps {
  items: {
    id: number;
    color: string;
    percent: number;
  }[];
}
const CustomProgress: React.FC<IProps> = ({ items }) => {
  const isFullProgress = items.reduceRight((prev, curr) => (prev += curr.percent), 0) === 100;
  const getStyle = (
    item: {
      id: number;
      color: string;
      percent: number;
    },
    index: number,
  ) => {
    let style: any = {
      backgroundColor: item.color,
      width: `${item.percent}%`,
    };

    if (index === 0) {
      style.borderTopLeftRadius = 'inherit';
      style.borderBottomLeftRadius = 'inherit';
    }

    if (isFullProgress && (index === items.length - 1 || items.length === 1)) {
      style.borderTopRightRadius = 'inherit';
      style.borderBottomRightRadius = 'inherit';
    }

    return style;
  };
  return (
    <div className={css['progress-container']}>
      {items.map((item, index) => (
        <div key={item.id} style={getStyle(item, index)} />
      ))}
    </div>
  );
};

export default CustomProgress;
