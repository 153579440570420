import React from 'react';
import { Typography } from 'antd';
import { IsJsonString } from '../../helpers/isJSONValid';

interface ProductNameLayoutModel {
  product: any;
}

const ProductNameLayout: React.FC<ProductNameLayoutModel> = ({ product }: ProductNameLayoutModel) => {
  return (
    <>
      <Typography.Text>{product?.code}</Typography.Text>
      <p className="article-name">
        {product?.fields
          ?.sort((a: any, b: any) => a.value - b.value)
          .map((field: any) => (
            <Typography.Text type="secondary" key={field.id}>
              {field.field && field.field.type && field.field.type.code === 'localeString' ? (
                Object.entries(field.value && IsJsonString(field.value) && JSON.parse(field.value)).map(
                  ([key, value]: any) => <span key={key}>&bull;{value}</span>,
                )
              ) : (
                <span>&bull;{field.value}</span>
              )}
            </Typography.Text>
          ))}
      </p>
    </>
  );
};

export default ProductNameLayout;
