import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Badge, Button, Table } from 'antd';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import { getColumns } from './components/ProductColumns';
import ProductService from '../../services/ProductService';
import { ProductsContext } from '../../contexts/ProductsContextProvider';
import { PRODUCTS_SET_DUPLICATE } from '../../constants/actionTypes/productsConstants';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { HeaderContext } from '../../contexts/HeaderContextProvider';
import { CHANGE_CURRENT_PAGE } from '../../constants/actionTypes/headerConstants';
import ProductControl from './components/ProductControl';
import { generateFilterfromObj } from '../../helpers/generateFIlterfromObj';
import { FiltersContext } from '../../contexts/FiltersContextProvider';
import { FilterTypes } from '../../constants/filterTypes';
import filterIcon from '../../assets/images/ic-filter.svg';
import filterActiveIcon from '../../assets/images/ic-filter-active.svg';
import { useLessThen801 } from '../../helpers/mediaDetect';
import { Link } from 'react-router-dom';
import TableMobile from '../common/TableMobile';
import ArticleNameLayout from '../../components/common/ArticleNameLayout';
import ProductNameLayout from '../../components/common/ProductNameLayout';
import i18n from '../../utils/i18n';

const service: ProductService = new ProductService();

const ProductPage: React.FC = (props: any) => {
  const { t } = useTranslation();
  const {
    filters: { productsFilter },
  } = useContext(FiltersContext);
  const history = useHistory();
  const { productsDispatch } = useContext(ProductsContext);
  const { height: windowHeight } = useWindowDimensions();
  const [items, setItems] = useState<any[]>([]);
  const [breadcrumbNameMap, setBreadcrumbNameMap] = useState<{ [name: string]: string }>({});
  const [loading, setLoading] = useState<boolean>(false);
  const { headerDispatch } = useContext(HeaderContext);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [currentFilters, setCurrentFilters] = useState<any>({
    isActive: {
      type: FilterTypes.SELECT,
      value: productsFilter.status ? productsFilter.status : undefined,
    },
  });

  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    total: 0,
  });

  const defaultParams = {
    count: true,
    filters: generateFilterfromObj(currentFilters),
    top: pagination.pageSize,
    current: pagination.current,
    skip: 0,
  };
  useEffect(() => {
    headerDispatch({
      type: CHANGE_CURRENT_PAGE,
      currentPage: t('header.title.Products'),
      path: 'products',
    });
  }, [i18n.language]);

  const getProducts = (option: any) => {
    setLoading(true);
    return service
      .getProducts(option)
      .then((res: any) => {
        const { items, count } = res;
        const { current, top } = option;
        setItems(items);
        setPagination({ ...pagination, current, total: count, pageSize: top });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getProducts({
      ...defaultParams,
      top: pagination.pageSize,
    });
  }, []);

  useEffect(() => {
    setBreadcrumbNameMap(
      props.history.location.pathname
        .replace('/products', '')
        .split('/')
        .slice(1)
        .reduce(
          (res: any, curr: any, index: number) => ({
            ...res,
            [[Object.keys(res)[index], curr].join('/')]: curr.split(':').shift(),
          }),
          { '/products': t('product.bread.root') },
        ),
    );
  }, [props.history.location.pathname]);

  const isEmptyFilter = (obj: any) => {
    return Object.keys(obj).length === 0;
  };

  const applyNewFilter = (filters: any) => {
    setCurrentFilters({ ...currentFilters, ...filters });
    return { ...currentFilters, ...filters };
  };

  const onTableChange = (pagination: any, filters: any, sorter?: any) => {
    let params: any = {
      ...defaultParams,
    };

    if (filters && !isEmptyFilter(filters)) {
      params = {
        ...params,
        filters: generateFilterfromObj(applyNewFilter(filters)),
      };
    } else {
      params = {
        ...params,
        filters: generateFilterfromObj(currentFilters),
      };
    }

    if (pagination) {
      params = {
        ...params,
        top: pagination.pageSize,
        skip: pagination.pageSize * (pagination.current - 1),
        current: pagination.current,
      };
    }
    getProducts(params);
  };

  const onDeleteProduct = (id: number) => {
    service.deleteProduct(id).then(() => {
      getProducts({
        ...defaultParams,
        top: pagination.pageSize,
        skip: pagination.pageSize * ((pagination.current || 1) - 1),
        current: pagination.current,
      });
    });
  };

  const onDuplicateProduct = (product: any) => {
    productsDispatch({
      type: PRODUCTS_SET_DUPLICATE,
      duplicate: product,
    });
    history.push('/products/create');
  };

  const changeOpenFilter = () => {
    setIsOpenFilter(!isOpenFilter);
  };

  const loadMore = (option: any) => {
    setLoading(true);
    option.skip = option.skip ? option.skip + 10 : 10;
    service
      .getProducts(option)
      .then((res: any) => {
        const { count } = res;
        const { current, top } = option;
        setPagination({ ...pagination, current, total: count, pageSize: top });
        setItems([...items, ...res.items]);
      })
      .catch((e: any) => console.log(e))
      .finally(() => setLoading(false));
  };

  if (useLessThen801()) {
    const data =
      items.length > 0 &&
      (items.map((item: any) => [
        { title: '#', description: item.id, type: 'string' },
        {
          title: t('product.table.columns.article'),
          description: <ArticleNameLayout article={item?.article} />,
          type: 'string',
        },
        {
          title: t('product.table.columns.barcode'),
          description: <ProductNameLayout product={item} />,
          type: 'string',
        },
        {
          title: t('product.table.columns.price'),
          description: isNaN(item.price) ? item.price : Number(item.price).toFixed(2),
          type: 'string',
        },
        {
          title: t('product.table.columns.isActive'),
          description: item.isActive ? (
            <Badge status="success" text={t('product.table.columns.isActive.active')} />
          ) : (
            <Badge status="error" text={t('product.table.columns.isActive.inactive')} />
          ),
          type: 'string',
        },
        {
          title: t('budget.table.action'),
          type: 'button',
          openByFunction: true,
          btnsInfo: [
            {
              function: () => history.push(`/products/modify/${item.id}`),
              functionBtnTitle: t('common.btn.modify'),
            },
            {
              function: () => onDuplicateProduct(item),
              functionBtnTitle: t('common.btn.duplicate'),
            },
            {
              function: () => onDeleteProduct && onDeleteProduct(item.id),
              functionBtnTitle: t('common.title.delete'),
            },
          ],
        },
      ]) as any);
    return (
      <>
        <HeaderTable breadcrumb={breadcrumbNameMap}></HeaderTable>
        <div
          className="common-modbile-filter-container"
          style={{ justifyContent: 'space-between' }}
          onClick={changeOpenFilter}>
          <div>
            <img src={isOpenFilter ? filterActiveIcon : filterIcon} width={16} />
            <span className="filter-title" style={{ marginRight: '1rem' }}>
              {isOpenFilter ? t('common.filter.close') : t('common.filter.open')}
            </span>
          </div>
          <div>
            <Link to={`/products/create`} style={{ borderRadius: 4 }} className="common-animation-primary">
              <Button className={`common-blue-btn common-primary-btn`} style={{ height: '42px' }}>
                {t('common.btn.new')}
              </Button>
            </Link>
          </div>
        </div>
        {isOpenFilter && <ProductControl props={props} onTableChange={onTableChange} />}
        <div style={{ marginTop: '1rem' }}>
          <TableMobile
            data={data}
            isLoading={loading}
            loadMore={loadMore}
            option={defaultParams}
            itemsLength={items.length}
            totalItems={pagination.total}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <HeaderTable breadcrumb={breadcrumbNameMap}></HeaderTable>
      <ProductControl props={props} onTableChange={onTableChange} />
      <Table
        columns={getColumns({
          history,
          onDelete: onDeleteProduct,
          onDuplicate: onDuplicateProduct,
          t,
        })}
        loading={loading}
        size="small"
        dataSource={items}
        showHeader={true}
        className="consumers-info-table"
        rowClassName="common-table-row--pointer"
        rowKey="id"
        scroll={{ x: 800, y: windowHeight - 450 }}
        pagination={pagination}
        onChange={onTableChange}
      />
    </>
  );
};

export default ProductPage;
