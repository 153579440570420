import { Button, DatePicker, Select, Input } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import css from '../../../assets/styles/internal.module.css';
import { useTranslation } from 'react-i18next';
import { FilterTypes } from '../../../constants/filterTypes';
import searchIcon from '../../../assets/images/ic-search.svg';
import UsersService from '../../../services/UsersService';
import { UserModel, UsersModel } from '../../../models/UserModel';
import { AppContext } from '../../../contexts/AppContextProvider';
import { ConsumerModel } from '../../../models/ConsumerModel';
import { IProjectEasy, ProjectModel, ProjectsModel } from '../../../models/ProjectModel';
import ProjectsService from '../../../services/ProjectsService';
import InternalDocumentsService from '../../../services/InternalDocumentsService';
import { FiltersContext } from '../../../contexts/FiltersContextProvider';
import { SET_INTERNAL_DOCUMENTS_FILTERS } from '../../../constants/actionTypes/filtersConstants';
import { StatusesDocumentsModel } from '../../../models/DocumentsModel';
import { IAppContext } from '../../../typings/IApp';

interface QuotesControlProps {
  setIsOpenSelect: any;
  onTableChange: any;
}

const { Option, OptGroup } = Select;
const { Search } = Input;
const { RangePicker } = DatePicker;

const userService = new UsersService();
const projectService = new ProjectsService();
const internalService = new InternalDocumentsService();

const InternalDocumentsControl = (props: QuotesControlProps) => {
  const { setIsOpenSelect, onTableChange } = props;
  const { t } = useTranslation();
  const {
    filters: { internalDocumentsFilter },
    filtersDispatch,
  } = useContext(FiltersContext);
  const [filters, setFilters] = useState(internalDocumentsFilter);
  const [isShowExtFilter, setIsShowExtFilter] = useState<boolean>(false);
  const [statuses, setStatuses] = useState<StatusesDocumentsModel>([]);
  const [dataRange, setDataRange] = useState<any>(internalDocumentsFilter.dateRange);
  const [brokers, setBrokers] = useState<UsersModel>([]);
  const [consumers, setConsumers] = useState<ConsumerModel[]>([]);
  const [projects, setProjects] = useState<IProjectEasy[]>([]);

  const [isLastAgentsReq, setIsLastAgentsReq] = useState(false);
  const [isLastConsumersReq, setIsLastConsumersReq] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [paginationAgents, setPaginationAgents] = useState<any>({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [paginationConsumers, setPaginationConsumers] = useState<any>({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const {
    app: { user },
  } = useContext<IAppContext>(AppContext);
  const types = ['Invoice', 'Document', 'Quote', 'Amendment'];

  useEffect(() => {
    getAgents();
    getConsumers();
    projectService.getProjects().then((value: IProjectEasy[]) => setProjects(value));
    internalService.getInternalStatuses().then((value: any) => setStatuses(value));
  }, []);

  useEffect(() => {
    filtersDispatch({
      type: SET_INTERNAL_DOCUMENTS_FILTERS,
      filters,
    });
  }, [filters]);

  useEffect(() => {
    if (user?.projects?.length === 1) {
      setFilters({ ...filters, project: user.projects[0].id });
    }
  }, [user]);

  useEffect(() => {
    setDataRange(internalDocumentsFilter.dateRange);
  }, [internalDocumentsFilter.dateRange]);

  const getAgents = () => {
    const optionUser: any = {
      count: true,
      top: paginationAgents.pageSize,
      current: paginationAgents.current,
      skip: paginationAgents.pageSize * (paginationAgents.current - 1) || 0,
      roles: ['agent', 'admin', 'super_agent'],
    };

    setFetchingData(true);
    userService
      .getEasyUsers(optionUser)
      .then((res: any) => {
        const { count, items } = res;
        const { current, top } = optionUser;
        setPaginationAgents({ ...paginationAgents, current: current + 1, total: count, pageSize: top });
        setBrokers([...brokers, ...items]);
        setIsLastAgentsReq(current * top >= count);
      })
      .finally(() => setFetchingData(false));
  };

  const getConsumers = () => {
    const optionUser: any = {
      count: true,
      top: paginationAgents.pageSize,
      current: paginationAgents.current,
      skip: paginationAgents.pageSize * (paginationAgents.current - 1) || 0,
      roles: ['consumer'],
    };

    setFetchingData(true);
    userService
      .getEasyUsers(optionUser)
      .then((res: any) => {
        const { count, items } = res;
        const { current, top } = optionUser;
        setPaginationConsumers({ ...paginationConsumers, current: current + 1, total: count, pageSize: top });
        setConsumers([...consumers, ...items]);
        setIsLastConsumersReq(current * top >= count);
      })
      .finally(() => setFetchingData(false));
  };

  const onSearchProperty = (value: any) => {
    const body = {
      searchByProperty: {
        type: FilterTypes.MULTISEARCH,
        value: [{ 'property/title': value.target.value }],
      },
    };

    onTableChange(null, body);
  };

  const onSelectType = (value: any) => {
    setFilters({ ...filters, type: value });
    const body = {
      'type/code': {
        type: FilterTypes.SELECT,
        value: value,
      },
    };
    onTableChange(null, body);
  };

  const onSelectStatus = (value: any) => {
    setFilters({ ...filters, status: value });
    const body = {
      'status/code': {
        type: FilterTypes.SELECT,
        value: value,
      },
    };
    onTableChange(null, body);
  };

  const onSelectSignatorAgent = (value: any) => {
    setFilters({ ...filters, agent: value });
    const body = {
      'signatories_filter/user/id': {
        type: FilterTypes.SELECT,
        value: value,
      },
    };

    onTableChange(null, body);
  };

  const onSelectConsumer = (value: any) => {
    setFilters({ ...filters, consumer: value });
    const body = {
      'consumer/id': {
        type: FilterTypes.SELECT,
        value: value,
      },
    };

    onTableChange(null, body);
  };

  const onSearch = (value: any) => {
    const body = {
      name: {
        type: FilterTypes.SEARCH,
        value: value.target.value,
      },
    };
    onTableChange(null, body);
  };

  const onSelectProject = (value: any) => {
    setFilters({ ...filters, project: value });
    const body = {
      'project/id': {
        type: FilterTypes.SELECT,
        value: value,
      },
    };

    onTableChange(null, body);
  };

  const onChangeCalendar = (value: any) => {
    setFilters({ ...filters, dateRange: value });
    const body = {
      createdDate: {
        type: FilterTypes.DATE_RANGE,
        value: value,
      },
    };
    onTableChange(null, body);
  };

  return (
    <div className={css['internal-control-container']}>
      <div
        className={`${css['internal-control-filters-container']} consumer-control-wrapper`}
        style={{
          alignItems: 'start',
          marginBottom: 0,
          height: '100%',
          marginTop: 0,
          flexDirection: isShowExtFilter ? 'column' : 'row',
        }}>
        <div className={css['internal-filter-row']}>
          <Search
            onChange={onSearch}
            placeholder={t('internal.search.name.placeholder')}
            allowClear
            className="budget-control-search common-animation-primary"
            suffix={<img src={searchIcon} alt="" />}
            style={{ width: '195px', marginLeft: '5px', padding: '1px 0' }}
          />
          <Select
            style={{ width: '150px', marginLeft: '0.5rem' }}
            defaultValue={internalDocumentsFilter.type}
            className="budget-control-select common-animation-primary"
            dropdownClassName="budget-control-select-dropdown"
            onChange={onSelectType}
            placeholder={t('budget.control.filter.status.placeholder')}
            suffixIcon={
              <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
            }>
            <Option value="" className="budget-control-option">
              <span style={{ fontSize: '14px', fontWeight: 600 }}>{t('tickets.control.filter.defvalue.type')}</span>
            </Option>
            {types.map((status: string) => (
              <Option value={status.toLowerCase()} key={status} className="budget-control-option">
                <span style={{ fontSize: '14px', fontWeight: 600 }}>{status}</span>
              </Option>
            ))}
          </Select>
          <Select
            style={{ width: '150px', marginLeft: '0.5rem' }}
            defaultValue={internalDocumentsFilter.status}
            className="budget-control-select common-animation-primary"
            dropdownClassName="budget-control-select-dropdown"
            onChange={onSelectStatus}
            suffixIcon={
              <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
            }>
            <Option value="" className="budget-control-option">
              <span style={{ fontSize: '14px', fontWeight: 600 }}>{t('budget.control.select.all')}</span>
            </Option>
            {statuses.length > 0 &&
              statuses.map((status) => (
                <Option value={status.code} key={status.code} className="budget-control-option">
                  <span style={{ fontSize: '14px', fontWeight: 600 }}>{status.description}</span>
                </Option>
              ))}
          </Select>
          <div className={css['select-container']}>
            <Select
              defaultValue={internalDocumentsFilter.agent}
              style={{ marginLeft: '0.5rem' }}
              className="consumers-control-select common-animation-primary"
              onChange={onSelectSignatorAgent}
              dropdownClassName={`consumers-control-select-dropdown ${
                fetchingData ? 'consumers-control-select-dropdown--progress' : ''
              }`}
              onPopupScroll={(e: any) => {
                e.persist();
                let target = e.target;
                if (
                  !fetchingData &&
                  !isLastAgentsReq &&
                  target.scrollTop + target.offsetHeight === target.scrollHeight
                ) {
                  getAgents();
                }
              }}
              placeholder={t('internal.documents.all.signatories')}
              suffixIcon={
                <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
              }>
              <Option value="" className="budget-control-option">
                <span style={{ fontSize: '14px', fontWeight: 600 }}>{t('internal.documents.all.signatories')}</span>
              </Option>
              {user && (
                <Option value={user.id} key={user.id} className="budget-control-option">
                  <span style={{ fontSize: '14px' }}>{`${user.firstName} ${user.lastName}`}</span>
                </Option>
              )}
              <OptGroup label={t('quotes.modal.form.agents')}>
                {brokers &&
                  brokers
                    .filter((u: UserModel) => u.id !== user.id)
                    .map((u: UserModel) => (
                      <Option value={u.id} key={u.id} className="budget-control-option">
                        <span style={{ fontSize: '14px' }}>{`${u.firstName} ${u.lastName}`}</span>
                      </Option>
                    ))}
              </OptGroup>
            </Select>
          </div>
          <Button
            type="link"
            className={css['btn-filter-extend']}
            onClick={() => setIsShowExtFilter((state) => !state)}>
            {t('tickets.control.filter.extend')}
            <div
              className={`${css['arrow-drop-img']} ${isShowExtFilter ? '' : css['arrow-drop']}`}
              style={{ marginLeft: 5, width: '8px', height: '4px' }}></div>
          </Button>
        </div>
        {isShowExtFilter && (
          <div className={css['internal-filter-row']} style={{ marginTop: 10 }}>
            <div className={css['select-container']}>
              <Select
                defaultValue={internalDocumentsFilter.consumer}
                style={{ width: '150px', marginLeft: '0.5rem' }}
                className="consumers-control-select common-animation-primary"
                onChange={onSelectConsumer}
                dropdownClassName={`consumers-control-select-dropdown ${
                  fetchingData ? 'consumers-control-select-dropdown--progress' : ''
                }`}
                onPopupScroll={(e: any) => {
                  e.persist();
                  let target = e.target;
                  if (
                    !fetchingData &&
                    !isLastConsumersReq &&
                    target.scrollTop + target.offsetHeight === target.scrollHeight
                  ) {
                    getConsumers();
                  }
                }}
                placeholder={t('internal.documents.all.consumers')}
                suffixIcon={
                  <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
                }>
                <Option value="" className="budget-control-option">
                  <span style={{ fontSize: '14px', fontWeight: 600 }}>{t('internal.documents.all.consumers')}</span>
                </Option>
                {consumers.length > 0 &&
                  consumers
                    .sort((a: any, b: any) => a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()))
                    .map((u: ConsumerModel) => (
                      <Option value={u.id} key={u.id} className="budget-control-option">
                        <span style={{ fontSize: '14px' }}>{`${u.firstName} ${u.lastName}`}</span>
                      </Option>
                    ))}
              </Select>
            </div>
            <div className={css['select-container']}>
              <Select
                defaultValue={internalDocumentsFilter.project}
                style={{ width: '150px', marginLeft: '0.5rem' }}
                className="consumers-control-select common-animation-primary"
                onChange={onSelectProject}
                dropdownClassName="consumers-control-select-dropdown"
                placeholder={t('tickets.control.filter.defvalue.project')}
                suffixIcon={
                  <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
                }>
                <Option value="" className="budget-control-option">
                  <span style={{ fontSize: '14px', fontWeight: 600 }}>
                    {t('tickets.control.filter.defvalue.project')}
                  </span>
                </Option>
                {projects.length > 0 &&
                  projects
                    .sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))
                    .map((u) => (
                      <Option value={u.id} key={u.id} className="budget-control-option">
                        <span style={{ fontSize: '14px' }}>{u.title}</span>
                      </Option>
                    ))}
              </Select>
              <Search
                onChange={onSearchProperty}
                placeholder={t('consumers.control.search.placeholder.property')}
                allowClear
                className="budget-control-search common-animation-primary"
                suffix={<img src={searchIcon} alt="" />}
                style={{ width: '195px', marginLeft: '5px', padding: '1px 0' }}
              />
              <RangePicker
                suffixIcon={<div className={'calendar-img'} style={{ width: '18px', height: '18px' }}></div>}
                className="budget-control-range-picker common-animation-primary"
                style={{ height: '34px' }}
                defaultValue={dataRange}
                inputReadOnly
                onChange={onChangeCalendar}
                format="DD.MM.YYYY"
              />
            </div>
          </div>
        )}
      </div>
      <div style={{ display: 'flex' }}>
        <Button className={`common-blue-btn common-secondary-btn`} onClick={() => setIsOpenSelect(true)}>
          <span>{t('quotes.table.upload.invoce')}</span>
        </Button>
        {/* <Button className={css['add-invoce-btn']} onClick={() => setIsOpenUpload(true)}>
            <span>{t('budget.control.create.btn')}</span>
          </Button> */}
      </div>
    </div>
  );
};

export default InternalDocumentsControl;
