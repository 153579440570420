import React from 'react';
import css from '../../assets/styles/documents.module.css';
import { IUserEasy } from '../../models/UserModel';
import DocumentsListInfo from './components/DocumentsListInfo';

interface BrokerDocumentsProps {
  propertyId: number;
  projectId: number;
  signatories: IUserEasy[];
}

const BrokerDocuments = (props: BrokerDocumentsProps) => {
  const { propertyId, signatories, projectId } = props;

  return (
    <div className={css['container']}>
      <DocumentsListInfo propertyId={propertyId} projectId={projectId} signatories={signatories} />
    </div>
  );
};

export default BrokerDocuments;
