import config from '../config';

export function imageURLToAntdModel(image: any) {
  return {
    thumbUrl: `${config.storeUrl}/${image.name}`,
    name: image?.name,
    uid: String(image?.id),
    type: `image/${image.name.substr(image.name.indexOf('.') + 1)}`,
    size: image?.size || 0,
  };
}

export function fileURLToAntdModel(file: any) {
  return {
    thumbUrl: `${config.storeUrl}/${file.name}`,
    name: file?.name,
    uid: String(file?.id),
    size: file?.size || 0,
  };
}
