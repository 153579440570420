import { Button, Modal } from 'antd';
import React from 'react';
import css from '../../../assets/styles/tasks.module.css';
import { useTranslation } from 'react-i18next';

interface ITaskConfirmModal {
  isVisible: boolean;
  onOk: () => void;
  onCancel: () => void;
}

const TaskConfirmModal = ({ isVisible, onCancel, onOk }: ITaskConfirmModal) => {
  const { t } = useTranslation();
  return (
    <Modal
      className="common-modal"
      visible={isVisible}
      closable={false}
      centered={true}
      maskClosable={false}
      footer={
        <div className="common-modal-footer" style={{ justifyContent: 'center' }}>
          <Button className={`common-gray-border-btn common-secondary-btn`} onClick={onCancel}>
            {t('common.confirm.btn.cancel')}
          </Button>
          <Button className={`common-green-btn common-modal-btn`} onClick={onOk}>
            {t('common.confirm.btn.yes')}
          </Button>
        </div>
      }>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <span className={css['task-modal-text']}>{t('tasks.modal.title')}</span>
      </div>
    </Modal>
  );
};

export default TaskConfirmModal;
