import HttpService from './common/httpService';
import config from '../config';
import { getApiKeyOptions } from '../helpers/getApiKeyOptions';
import buildQuery from 'odata-query';
import generateODataQuery, { IODataModel } from '../helpers/generateODataQuery';

interface ProductUploadImagesModel {
  id: number;
  type: string;
  files: any;
  projectKey?: string | undefined;
}

class ProductService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getProducts(params: IODataModel = {}, projectKey?: string) {
    const { top, count, skip, filters } = params;

    let filter: any = [];

    if (filters) {
      filter = [...filters.filter];
      filter.sort((a: any, b: any) =>
        Object.keys(Object.values(a)[0] as any)[0] == 'contains'
          ? -1
          : Object.keys(Object.values(b)[0] as any)[0] == 'contains'
          ? 1
          : 0,
      );
    }

    const options = getApiKeyOptions(projectKey);
    const query = buildQuery({ filter, top, count, skip });
    return this.httpService.get(`${config.baseRequest}products${query}`, options);
  }

  public getProductsByCategoryTypes(params: IODataModel = {}, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    const query = generateODataQuery(params);
    return this.httpService.get(`${config.baseRequest}products/by-type${query}`, options);
  }

  public getProductById(id: number, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.get(`${config.baseRequest}products/${id}`, options);
  }

  public getProductParams(projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.get(`${config.baseRequest}products/fields`, options);
  }

  public createProduct(body: any, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.post(`${config.baseRequest}products`, body, options);
  }

  public updateProduct(body: any, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.put(`${config.baseRequest}products`, body, options);
  }

  public deleteProduct(id: number, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.delete(`${config.baseRequest}products/${id}`, null, options);
  }

  public uploadImages({ id, type, projectKey, files }: ProductUploadImagesModel) {
    const formData = new FormData();

    files
      .filter((data: any) => !!data.originFileObj)
      .forEach((data: any) => {
        formData.append('file', data.originFileObj);
      });

    const options = getApiKeyOptions(projectKey);
    return this.httpService.postFile(`${config.baseRequest}products/images/${id}/${type}`, formData, options);
  }

  public deleteImages(ids: number[]) {
    return this.httpService.delete(`${config.baseRequest}products/images`, ids);
  }
}

export default ProductService;
