import { Button, notification, Spin, Table } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { fileManagementTypes } from '../../constants/fileManagementTypes';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import QuotesService from '../../services/QuoteService';
import NotFoundPage from '../common/NotFoundPage';
import { getColumns } from './components/FileManagementColumns';
import css from '../../assets/styles/file-management.module.css';
import { HeaderContext } from '../../contexts/HeaderContextProvider';
import { CHANGE_CURRENT_PAGE } from '../../constants/actionTypes/headerConstants';
import FileManagementControl from './components/FileManagementControl';
import { AppContext } from '../../contexts/AppContextProvider';
import { FilterTypes } from '../../constants/filterTypes';
import UploadFileManagement from './components/UploadFileManagement';
import CommonConfirmForm from '../common/CommonConfirmForm';
import ArchivesFileManagement from './components/ArchivesFileManagement';
import CommonPreviewPDF from '../common/CommonPreviewPDF';
import CommonApproveForm from '../common/CommonApproveForm';
import SigningConfirmForm from '../common/SigningConfirmForm';
import { getUriSearchValue } from '../../helpers/proccessUri';
import { docusignEventStatuses } from '../../constants/docusignEventStatuses';
import { getStorageEnvelopeId, setStorageEnvelopeId } from '../../helpers/envelopeStorage';
import CreateFileManagement from './components/CreateFileManagement';
import { useLessThen801 } from '../../helpers/mediaDetect';
import moment from 'moment';
import FileManagementStatusForm from './components/FileManagementStatusForm';
import CommonHistoryModal from '../common/CommonHistoryModal';
import UploadFileLoader from '../common/UploadFileLoader';
import config from '../../config';
import { getStorageToken } from '../../helpers/storageTools';
import HttpService from '../../services/common/httpService';
import { CLEAR_FILEMANAGMENT_FILTERS, SAVE_FILEMANAGMENT_FILTERS } from '../../constants/actionTypes/filtersConstants';
import { FiltersContext } from '../../contexts/FiltersContextProvider';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { IQuoteModel } from '../../models/QuotesModel';
import { BudgetModel } from '../../models/BudgetModel';
import { IAmendmentModel } from '../../models/AmendmentModel';
import { CommonDocumentsType, IHistoryModel } from '../../models/CommonDocumnetsModel';
import { IAppContext } from '../../typings/IApp';
import useGetPageTypeConfig from './hooks/useGetPageTypeConfig';
import {
  getMobileTitle,
  getPageTypeTitle,
  getUploadTitleByType,
  setApproveNotificationMessage,
  setArchiveNotificationMessage,
  setDeclineNotificationMessage,
  setDeleteNotificationMessage,
  setRejectNotificationMessage,
  setSignedNotificationMessage,
} from './utils/getMessages';
import useRequests from './hooks/useRequest';
import useModal from './hooks/useModal';
import TableMobile from '../common/TableMobile';
import filterIcon from '../../assets/images/ic-filter.svg';
import filterActiveIcon from '../../assets/images/ic-filter-active.svg';
import { authDownloadFile } from '../../helpers/authFileTools';
import useTable from './hooks/useTable';
import useMobileData from './hooks/useMobileData';

//services
const quoteService = new QuotesService();
const httpService = new HttpService();

const FileManagementPage = () => {
  //page type
  const history = useHistory();
  const location = useLocation();
  const pageType = location.pathname.slice(17, location.pathname.length);
  //common
  const { headerDispatch } = useContext(HeaderContext);
  const { t } = useTranslation();
  const { height: windowHeight } = useWindowDimensions();
  const {
    app: { user },
  } = useContext<IAppContext>(AppContext);
  const {
    filters: { fileManagementFilter },
    filtersDispatch,
  } = useContext(FiltersContext);
  const resetFiltersRef = useRef(() => '');
  const [fetchingData, setFetchingData] = useState(false);
  const [fetchingDataSign, setFetchingDataSign] = useState(false);
  const [isAfterGetFilters, setIsAfterGetFilters] = useState(false);
  const [isDocusignRedirect, setIsDocusignRedirect] = useState(false);
  const [isChecked, setIsChecked] = useState(fileManagementFilter.isCheck);
  const [isCheckedExpire, setIsCheckedExpire] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [isUpload, setIsUpload] = useState(true);
  //sign
  const event = getUriSearchValue(location.search, 'event');
  const [isDocusignConfirm, setIsDocusignConfirm] = useState(
    !!event && event === docusignEventStatuses.signingComplete,
  );
  const [isDocusignDecline, setIsDocusignDecline] = useState(!!event && event === docusignEventStatuses.decline);
  // hooks
  const pageTypeConfig = useGetPageTypeConfig();
  const {
    setByIdRequestByPageType,
    setUpdateRequestByPageType,
    setRejectByPageType,
    setDeleteRequestByPageType,
    setApproveRequestByPageType,
    setConfirmSignRequestByPageType,
    setHistoryRequestByPageType,
    setSignRequestByPageType,
  } = useRequests();

  const initFilters = pageTypeConfig.isQuotes
    ? {
        isSuppliers: {
          type: FilterTypes.SELECT,
          value: 'false',
        },
      }
    : undefined;

  const {
    onTableChange,
    fetchingData: isLoading,
    items,
    getItemsReq,
    option,
    pagination,
    loadMore,
  } = useTable({ resetFiltersRef, initFilters });

  useEffect(() => {
    setFetchingData(isLoading);
  }, [isLoading]);

  //items
  const [historyArray, setHistoryArray] = useState<IHistoryModel>([]);

  const getPathByType = () => {
    if (pageTypeConfig.isDocuments) {
      return 'management_documents';
    }
    if (pageTypeConfig.isInvoices) {
      return 'management_invoices';
    }
    if (pageTypeConfig.isQuotes) {
      return 'management_quotes';
    }
    if (pageTypeConfig.isAmendments) {
      return 'management_amendments';
    }
    return 'file_management';
  };

  useEffect(() => {
    headerDispatch({
      type: CHANGE_CURRENT_PAGE,
      currentPage: `${t('header.title.file.management')}: ${getPageTypeTitle(pageTypeConfig)}`,
      path: getPathByType(),
    });

    const envelopeId = getStorageEnvelopeId();
    if (isDocusignConfirm) {
      setFetchingDataSign(true);
      if (!envelopeId) {
        setFetchingDataSign(false);
        setIsDocusignConfirm(false);
      } else {
        setConfirmSignRequestByPageType(envelopeId)
          .catch((e) => console.error(e))
          .finally(() => {
            setIsDocusignConfirm(false);
            setStorageEnvelopeId('');
            setFetchingDataSign(false);
            history.replace({ search: '' });
          });
      }
    } else if (isDocusignDecline) {
      setFetchingDataSign(true);
      if (!envelopeId) {
        setFetchingDataSign(false);
        setIsDocusignDecline(false);
      } else {
        setRejectByPageType(null, '', envelopeId)
          .catch((e) => console.error(e))
          .finally(() => {
            setIsDocusignDecline(false);
            setStorageEnvelopeId('');
            setFetchingDataSign(false);
            history.replace({ search: '' });
          });
      }
    }
    if (location.search.includes('event')) {
      history.replace({ search: '' });
    }

    return () => {
      if (!isDocusignRedirect) {
        filtersDispatch({
          type: CLEAR_FILEMANAGMENT_FILTERS,
        });
      }
    };
  }, []);

  useEffect(() => {
    if (isAfterGetFilters && !isDocusignConfirm && !fetchingDataSign) {
      getItemsReq();
    }
  }, [isDocusignConfirm, fetchingDataSign]);

  const closeUploadLoader = (isNeedUpdate: boolean = true) => {
    handleCloseUploadLoader(isNeedUpdate);
    setIsUpload(true);
  };

  //modals
  const {
    id: idForUpload,
    isOpen: isOpenUpload,
    handleOpen: handleOpenUpload,
    handleClose: handleCloseUpload,
  } = useModal({ getItems: getItemsReq });
  const {
    id: idForStatus,
    isOpen: isOpenStatus,
    handleOpen: handleOpenStatus,
    handleClose: handleCloseStatus,
  } = useModal({ getItems: getItemsReq });
  const {
    id: idForArchive,
    isOpen: isOpenArchive,
    handleOpen: handleOpenArchive,
    handleClose: handleCloseArchive,
  } = useModal({ getItems: getItemsReq });
  const {
    isOpen: isOpenArchives,
    handleOpen: handleOpenArchives,
    handleClose: handleCloseArchives,
  } = useModal({ getItems: getItemsReq });
  const {
    id: idForDelete,
    isOpen: isOpenDelete,
    handleOpen: handleOpenDelete,
    handleClose: handleCloseDelete,
  } = useModal({ getItems: getItemsReq });
  const {
    item: itemForPreview,
    isOpen: isOpenPreview,
    handleOpen: handleOpenPreview,
    handleClose: handleClosePreview,
  } = useModal({ getItems: getItemsReq, items });
  const {
    id: idForApprove,
    isOpen: isOpenApprove,
    handleOpen: handleOpenApprove,
    handleClose: handleCloseApprove,
  } = useModal({ getItems: getItemsReq });
  const {
    isOpen: isOpenCreate,
    handleOpen: handleOpenCreate,
    handleClose: handleCloseCreate,
  } = useModal({ getItems: getItemsReq });
  const {
    isOpen: isOpenHistory,
    item: itemForHistory,
    handleOpen: handleOpenHistory,
    handleClose: handleCloseHistory,
  } = useModal({ getItems: (id) => id && getHistory(id) });
  const {
    id: idForSign,
    isOpen: isOpenSign,
    signInfo,
    handleOpen: handleOpenSign,
    handleClose: handleCloseSign,
  } = useModal({ getItems: getItemsReq });
  const {
    isOpen: isOpenUploadLoader,
    handleOpen: handleOpenUploadLoader,
    handleClose: handleCloseUploadLoader,
  } = useModal({ getItems: getItemsReq });

  //helpers
  const setArchiveQuestion = () => {
    return `${t('internal.documents.confirm.archive')} ${pageType.slice(0, pageType.length - 1)}?`;
  };
  const setDeleteQuestion = () => {
    return `${t('internal.documents.confirm.delete')} ${pageType.slice(0, pageType.length - 1)}?`;
  };

  //actions
  const archiveItem = (id: number) => {
    setFetchingData(true);
    setByIdRequestByPageType(id)
      .then((value) => {
        const body = {
          id: value.id,
          isArchive: true,
        };
        setUpdateRequestByPageType(body)
          .then(() =>
            notification.success({
              message: setArchiveNotificationMessage(pageTypeConfig),
            }),
          )
          .catch((e) =>
            notification.error({
              message: e.message,
            }),
          )
          .finally(() => {
            handleCloseArchive();
            setFetchingData(false);
          });
      })
      .catch((e) => {
        notification.error({
          message: e.message,
        });
        setFetchingData(false);
        handleCloseArchive();
      });
  };

  const deleteItem = (id: number) => {
    setFetchingData(true);
    setDeleteRequestByPageType(id)
      .then(() => {
        handleCloseDelete();
        notification.success({
          message: setDeleteNotificationMessage(pageTypeConfig),
        });
        getItemsReq();
      })
      .catch((e) =>
        notification.error({
          message: e,
        }),
      )
      .finally(() => setFetchingData(false));
  };

  const approveItem = (id: number) => {
    setFetchingData(true);
    setApproveRequestByPageType(id)
      .then(() => {
        notification.success({
          message: setApproveNotificationMessage(pageTypeConfig),
        });
        handleCloseApprove();
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingData(false));
  };

  const signItem = (id: number) => {
    //TODO: make multiitems
    setIsDocusignRedirect(true);
    filtersDispatch({
      type: SAVE_FILEMANAGMENT_FILTERS,
    });
    setFetchingData(true);
    setSignRequestByPageType(id, window.location.href, user.id)
      .then((res) => {
        const { alreadySigned = false, declined = false } = res;
        if (!alreadySigned && !declined) {
          setStorageEnvelopeId(res.envelopeId);
          window.location.href = res.redirectUrl;
        } else {
          if (alreadySigned) {
            notification.warning({
              message: setSignedNotificationMessage(pageTypeConfig),
            });
          }
          if (declined) {
            notification.warning({
              message: setDeclineNotificationMessage(pageTypeConfig),
            });
          }
          setFetchingData(false);
        }
      })
      .catch((e) => {
        notification.error({
          message: e.message,
        }),
          setFetchingData(false);
      })
      .finally(() => {
        setIsDocusignRedirect(false);
        handleCloseSign();
      });
  };

  const rejectItem = (id: number, comment: string) => {
    setFetchingData(true);
    setRejectByPageType(id, comment)
      .then(() =>
        notification.success({
          message: setRejectNotificationMessage(pageTypeConfig),
        }),
      )
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => {
        setFetchingData(false);
        getItemsReq();
        handleCloseApprove();
        handleCloseSign();
      });
  };

  const downloadQuotes = async () => {
    if (pagination.total! > 50) {
      return notification.warning({
        message: t('fileManagement.apply.filter'),
      });
    }
    setIsUpload(false);
    handleOpenUploadLoader({});
    await quoteService.downloadQuotes(option).then((res) => {
      const a = document.createElement('a');
      const url = window.URL.createObjectURL(new Blob([res]));
      a.href = url;
      a.download = 'Quotes.zip';
      a.click();
    });
  };

  const getHistory = (itemId: number, itemTitle?: string, currentItem?: CommonDocumentsType) => {
    setFetchingData(true);
    setHistoryRequestByPageType(itemId)
      .then((value) => {
        setHistoryArray(value);
        handleOpenHistory({ itemId, itemTitle, currentItem });
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingData(false));
  };

  const changeOpenFilter = () => {
    setIsOpenFilter(!isOpenFilter);
  };

  const amendmentDownloadFile = (file: string, id: number, fileName?: string) => {
    httpService
      .getFile(`${config.baseRequest}amendments/${id}/fileFull`, {
        header: { Authorization: 'Bearer ' + getStorageToken() },
      })
      .then((res) => {
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(res);
        a.href = url;
        a.download = fileName || file;
        a.click();
      });
  };

  const onChangeCheckbox = (e: CheckboxChangeEvent) => {
    setIsChecked(e.target.checked);
    const body = {
      'current_step/consumerId': {
        type: FilterTypes.SELECT,
        value: e.target.checked ? user.id : null,
      },
      'status_v2/code': {
        type: FilterTypes.NOT,
        value: e.target.checked ? 'rejected' : 'suppliers_quote',
      },
    };
    onTableChange(undefined, body);
  };

  if (!fileManagementTypes.includes(pageType)) {
    return <NotFoundPage />;
  }

  const { getDataForType } = useMobileData({
    items,
    handleOpenPreview,
    handleOpenApprove,
    handleOpenSign,
    handleOpenDelete,
    handleOpenArchive,
    handleOpenUpload,
    authDownloadFile,
    amendmentDownloadFile,
  });

  if (useLessThen801()) {
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
          <div className="common-mobile-title-container">
            <span style={{ fontSize: '20px' }}>{getMobileTitle(pageTypeConfig)}</span>
            <div className="common-modbile-filter-container" onClick={changeOpenFilter}>
              <img src={isOpenFilter ? filterActiveIcon : filterIcon} width={16} />
              <span className="filter-title">{isOpenFilter ? t('common.filter.close') : t('common.filter.open')}</span>
            </div>
          </div>
          <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
            <Button
              className={`common-blue-border-btn common-secondary-btn`}
              onClick={() => handleOpenUpload({})}
              style={{ height: '42px', width: '100%' }}>
              <span>{getUploadTitleByType(pageTypeConfig)}</span>
            </Button>
            {pageTypeConfig.isQuotes && (
              <Button
                className={`common-blue-border-btn common-secondary-btn`}
                onClick={downloadQuotes}
                style={{ height: '42px', marginTop: '0.5rem', width: '100%' }}>
                <span>{t('budget.table.download')}</span>
              </Button>
            )}
          </div>
        </div>
        {isOpenFilter && (
          <FileManagementControl
            closeModal={() => setIsOpenFilter(false)}
            downloadQuotes={downloadQuotes}
            onTableChange={onTableChange}
            openUpload={() => handleOpenUpload({})}
            openCreate={() => handleOpenCreate({})}
            isOpenModal={isOpenFilter}
            resetFiltersRef={resetFiltersRef}
            setIsAfterGetFilters={setIsAfterGetFilters}
            setIsChecked={setIsChecked}
            isChecked={isChecked}
            isCheckedExpire={isCheckedExpire}
            setIsCheckedExpire={setIsCheckedExpire}
          />
        )}
        <div className={'common-flex-between-style'} style={{ marginBottom: '1rem' }}>
          <Checkbox checked={isChecked} onChange={onChangeCheckbox}>
            {t('fileManagement.control.checkbox')}
          </Checkbox>
          <span onClick={() => handleOpenArchives({})} className={`common-archive-icon-item common-animation-primary`}>
            <div className={css['archive-img']} style={{ marginRight: '0.5rem', width: '18px', height: '14px' }}></div>
            <span style={{ color: '#076ee5', marginRight: '0.5rem', textDecoration: 'underline' }}>
              {t('common.archive.title')}
            </span>
          </span>
        </div>
        <TableMobile
          data={getDataForType()}
          isLoading={fetchingData || fetchingDataSign}
          loadMore={() => loadMore(isDocusignConfirm)}
          option={option}
          itemsLength={items.length}
          totalItems={pagination.total!}
        />
        {isOpenUpload && (
          <UploadFileManagement
            currentId={idForUpload}
            isOpenUpload={isOpenUpload}
            pageType={pageType}
            closeUpload={handleCloseUpload}
            user={user}
            setIsOpenUploadLoader={() => handleOpenUploadLoader({})}
            setIsUploadingFile={setIsUploadingFile}
          />
        )}
        {isOpenArchive && idForArchive && (
          <CommonConfirmForm
            action={() => archiveItem(idForArchive)}
            fetchingData={fetchingData}
            isOpen={isOpenArchive}
            setClose={handleCloseArchive}
            text={setArchiveQuestion()}
            type="archive"
          />
        )}
        {isOpenDelete && idForDelete && (
          <CommonConfirmForm
            action={() => deleteItem(idForDelete)}
            fetchingData={fetchingData}
            isOpen={isOpenDelete}
            setClose={handleCloseDelete}
            text={setDeleteQuestion()}
            type="delete"
          />
        )}
        {isOpenPreview && (
          <CommonPreviewPDF currentInfo={itemForPreview} isOpen={isOpenPreview} setClose={handleClosePreview} />
        )}
        {
          // TODO: make multiitems
          isOpenArchives && (
            <ArchivesFileManagement getItems={getItemsReq} isOpen={isOpenArchives} setIsOpen={handleCloseArchives} />
          )
        }
        {isOpenApprove && idForApprove && (
          <CommonApproveForm
            approve={() => approveItem(idForApprove)}
            isOpen={isOpenApprove}
            reject={(_, comment) => rejectItem(idForApprove, comment)}
            setClose={handleCloseApprove}
            fetchingData={fetchingData}
            type={pageType.slice(0, pageType.length - 1)}
          />
        )}
        {isOpenSign && idForSign && (
          <SigningConfirmForm
            isOpen={isOpenSign}
            fetchingData={fetchingData}
            reject={(_, comment) => rejectItem(idForSign, comment)}
            setClose={handleCloseSign}
            signInfo={signInfo}
            sign={() => signItem(idForSign)}
          />
        )}
        {isOpenCreate && (
          <CreateFileManagement
            isOpenCreate={isOpenCreate}
            closeCreate={handleCloseCreate}
            user={user}
            handleOpenStatus={handleOpenStatus}
          />
        )}
        {isOpenUploadLoader && (
          <UploadFileLoader
            isOpen={isOpenUploadLoader}
            isLoading={isUploadingFile}
            setClose={closeUploadLoader}
            isUpload={isUpload}
          />
        )}
      </>
    );
  }

  return (
    <div className={css['table-container']}>
      <Spin spinning={fetchingData || fetchingDataSign}>
        <FileManagementControl
          isOpenModal={isOpenFilter}
          closeModal={() => setIsOpenFilter(false)}
          downloadQuotes={downloadQuotes}
          onTableChange={onTableChange}
          openUpload={() => handleOpenUpload({})}
          openCreate={() => handleOpenCreate({})}
          resetFiltersRef={resetFiltersRef}
          setIsAfterGetFilters={setIsAfterGetFilters}
          setIsChecked={setIsChecked}
          isChecked={isChecked}
          isCheckedExpire={isCheckedExpire}
          setIsCheckedExpire={setIsCheckedExpire}
        />
        <Table
          columns={getColumns({
            t,
            user,
            openArchive: (itemId) => handleOpenArchive({ itemId }),
            openDelete: (itemId) => handleOpenDelete({ itemId }),
            openPreview: (itemId) => handleOpenPreview({ itemId }),
            openUpload: (itemId) => handleOpenUpload({ itemId }),
            openApprove: (itemId) => handleOpenApprove({ itemId }),
            openSign: (itemId, signInfoItem) => handleOpenSign({ itemId, signInfoItem }),
            openStatus: (itemId) => handleOpenStatus({ itemId }),
            openHistory: (itemId, itemTitle, currentItem) => getHistory(itemId, itemTitle, currentItem),
            amendmentDownloadFile,
            ...pageTypeConfig,
          })}
          size="middle"
          className="consumers-info-table"
          rowClassName={(record) => {
            if (pageTypeConfig.isDocuments) {
              return `common-table-row--pointer`;
            }
            return `common-table-row--pointer ${
              (!record.file ||
                (moment(
                  pageTypeConfig.isInvoices
                    ? (record as BudgetModel).paymentDate
                    : (record as IQuoteModel | IAmendmentModel).deadline,
                ).isBefore(moment()) &&
                  !['signed', 'rejected', 'done', 'canceled'].includes(record.statusV2.code))) &&
              'common-table-row--error-management'
            }`;
          }}
          dataSource={items}
          showHeader={true}
          pagination={pagination}
          scroll={{ y: windowHeight - 300, x: 1000 }}
          onChange={onTableChange}
          rowKey="id"
        />
        <div className="common-flex-end-style">
          <span onClick={() => handleOpenArchives({})} className={`common-archive-icon-item common-animation-primary`}>
            <div className={css['archive-img']} style={{ marginRight: '0.5rem', width: '18px', height: '14px' }}></div>
            <span style={{ color: '#076ee5', marginRight: '0.5rem', textDecoration: 'underline' }}>
              {t('common.archive.title')}
            </span>
          </span>
        </div>
        {isOpenUpload && (
          <UploadFileManagement
            currentId={idForUpload}
            isOpenUpload={isOpenUpload}
            pageType={pageType}
            closeUpload={handleCloseUpload}
            user={user}
            setIsOpenUploadLoader={() => handleOpenUploadLoader({})}
            setIsUploadingFile={setIsUploadingFile}
          />
        )}
        {isOpenArchive && idForArchive && (
          <CommonConfirmForm
            action={() => archiveItem(idForArchive)}
            fetchingData={fetchingData}
            isOpen={isOpenArchive}
            setClose={handleCloseArchive}
            text={setArchiveQuestion()}
            type="archive"
          />
        )}
        {isOpenDelete && idForDelete && (
          <CommonConfirmForm
            action={() => deleteItem(idForDelete)}
            fetchingData={fetchingData}
            isOpen={isOpenDelete}
            setClose={handleCloseDelete}
            text={setDeleteQuestion()}
            type="delete"
          />
        )}
        {isOpenPreview && (
          <CommonPreviewPDF
            currentInfo={itemForPreview}
            isOpen={isOpenPreview}
            setClose={handleClosePreview}
            isAmendment={pageTypeConfig.isAmendments}
          />
        )}
        {isOpenUploadLoader && (
          <UploadFileLoader
            isOpen={isOpenUploadLoader}
            isLoading={isUploadingFile}
            setClose={closeUploadLoader}
            isUpload={isUpload}
          />
        )}
        {
          // TODO: make multiitems
          isOpenArchives && (
            <ArchivesFileManagement getItems={getItemsReq} isOpen={isOpenArchives} setIsOpen={handleCloseArchives} />
          )
        }
        {isOpenApprove && idForApprove && (
          <CommonApproveForm
            approve={() => approveItem(idForApprove)}
            isOpen={isOpenApprove}
            reject={(_, comment) => rejectItem(idForApprove, comment)}
            setClose={handleCloseApprove}
            fetchingData={fetchingData}
            type={pageType.slice(0, pageType.length - 1)}
          />
        )}
        {isOpenSign && idForSign && (
          <SigningConfirmForm
            isOpen={isOpenSign}
            fetchingData={fetchingData}
            reject={(_, comment) => rejectItem(idForSign, comment)}
            setClose={handleCloseSign}
            signInfo={signInfo}
            sign={() => signItem(idForSign)}
          />
        )}
        {isOpenCreate && (
          <CreateFileManagement
            isOpenCreate={isOpenCreate}
            closeCreate={handleCloseCreate}
            user={user}
            handleOpenStatus={handleOpenStatus}
          />
        )}
        {isOpenStatus && (
          <FileManagementStatusForm
            isOpen={isOpenStatus}
            id={idForStatus}
            setCloseStatus={handleCloseStatus}
            pageType={pageType}
          />
        )}
        {isOpenHistory && (
          <CommonHistoryModal
            fetchingData={fetchingData}
            setFetchingData={setFetchingData}
            isOpen={isOpenHistory}
            items={historyArray}
            setClose={handleCloseHistory}
            currentItem={itemForHistory}
          />
        )}
      </Spin>
    </div>
  );
};

export default FileManagementPage;
