import React, { useContext, useMemo, useState } from 'react';
import { Button, Layout } from 'antd';
import { NavContext } from '../../../contexts/NavContextProvider';
import { AppContext } from '../../../contexts/AppContextProvider';
import Logo from './enums/Logo';
import Projects from './Projects';
import NavMenu from './enums/NavMenu';
import Properties from './Properties';
import Versions from './Versions';
import { TOGGLE_MENU } from '../../../constants/actionTypes/navConstants';
import PostModalForm from '../../../views/Posts/components/PostModalForm';
import { CalendarTypes } from '../../../models/CalendarModel';
import { useTranslation } from 'react-i18next';
import ModalUploadFileSuccess from '../Modals/ModalUploadFileSuccess';
import { IAppContext } from '../../../typings/IApp';
import config from '../../../config';
import ArkaService from '../../../services/ArkaService';
import { getErrorNotification } from '../Notifications';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

const { Sider } = Layout;
const arkaService: ArkaService = new ArkaService();

const NavComponent: React.FC = (props: any) => {
  const { t } = useTranslation();
  const [isArkaLoader, setIsArkaLoader] = useState<boolean>(false);
  const [isOpenModalPost, setIsOpenModaPost] = useState(false);
  const [isOpenUploadMessageModal, setIsOpenUploadMessageModal] = useState(false);

  const {
    app: { isConsumer, isSupplier, user },
  } = useContext<IAppContext>(AppContext);

  const isShowArkaBtn = useMemo(() => {
    const userProjectsIds = user?.projects.map((el) => el.id) || [];
    return config.arkaProjectsId?.split(',').some((projectId) => userProjectsIds.includes(+projectId));
  }, [config.arkaProjectsId, user?.projects]);

  const {
    nav: { isMenuCollapsed },
    navDispatch,
  } = useContext(NavContext);

  const changeCollapse = () => {
    navDispatch({
      type: TOGGLE_MENU,
      isMenuCollapsed: !isMenuCollapsed,
    });
  };

  const redirectToArka = async () => {
    setIsArkaLoader(true);
    await arkaService
      .getToken()
      .then((res) => {
        const a = document.createElement('a');
        a.href = `${config.arkaUrl}?token=${res.token}`;
        a.target = '_blank';
        a.click();
      })
      .catch((e) => {
        getErrorNotification(e);
      })
      .finally(() => setIsArkaLoader(false));
  };

  return (
    <>
      <Sider
        theme="light"
        trigger={null}
        collapsible
        collapsed={isMenuCollapsed}
        collapsedWidth="60"
        width={250}
        className="slider">
        <Logo isCollapsed={isMenuCollapsed} />
        {isConsumer && <Projects isCollapsed={isMenuCollapsed} />}
        {isConsumer && !isMenuCollapsed && <Properties isCollapsed={isMenuCollapsed} />}
        {!isConsumer && !isSupplier && (
          <Button
            className={`common-blue-btn common-primary-btn`}
            onClick={() => setIsOpenModaPost(true)}
            style={
              isMenuCollapsed
                ? { width: 40, height: 40, margin: '0.5rem', padding: 0 }
                : { margin: '0 1rem 1rem', width: 215, height: 42 }
            }>
            {isMenuCollapsed ? '+' : `+ ${t('post.create.nav')}`}
          </Button>
        )}
        {isShowArkaBtn && (
          <Button
            htmlType="button"
            loading={isArkaLoader}
            onClick={redirectToArka}
            className={`common-blue-border-btn common-secondary-btn`}
            style={
              isMenuCollapsed
                ? { width: 40, height: 40, margin: '0.5rem', padding: 0 }
                : { margin: '0 1rem 1rem', width: 215, height: 42 }
            }>
            {isMenuCollapsed ? 'Arka' : t('arka.btn')}
          </Button>
        )}
        <NavMenu isMenuCollapsed={isMenuCollapsed} />
        {!isMenuCollapsed && <Versions />}
      </Sider>
      <Button className="collapse-btn" onClick={() => changeCollapse()}>
        {isMenuCollapsed ? <RightOutlined style={{ color: '#fff' }} /> : <LeftOutlined style={{ color: '#fff' }} />}
      </Button>
      {isOpenModalPost && (
        <PostModalForm
          setIsOpenUploadMessageModal={setIsOpenUploadMessageModal}
          isOpenModal={isOpenModalPost}
          defaultType={CalendarTypes.update}
          closeModal={() => {
            setIsOpenModaPost(false);
          }}
        />
      )}
      {isOpenUploadMessageModal && (
        <ModalUploadFileSuccess isOpen={isOpenUploadMessageModal} onOk={() => setIsOpenUploadMessageModal(false)} />
      )}
    </>
  );
};

export default NavComponent;
