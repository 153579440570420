import { TFunction } from 'react-i18next';
import { getColumnSearchProps } from '../../../helpers/getColumnSearchProps';

interface AssignOptionColumnsModel {
  t: TFunction<'translation'>;
}

export const getColumns = ({ t }: AssignOptionColumnsModel) => [
  {
    title: t('space.object.option.modal.table.columns.spaceObject'),
    dataIndex: 'title',
    key: 'title',
    sorter: true,
    width: 200,
    ...getColumnSearchProps(),
  },
  {
    title: t('space.object.option.modal.table.columns.space'),
    dataIndex: ['space', 'title'],
    key: 'space-title',
    sorter: true,
    width: 200,
    ...getColumnSearchProps(),
  },
  {
    title: t('space.object.option.modal.table.columns.variant'),
    dataIndex: ['space', 'variant', 'title'],
    key: 'variant-title',
    sorter: true,
    width: 200,
    ...getColumnSearchProps(),
  },
  {
    title: t('space.object.option.modal.table.columns.level'),
    dataIndex: ['space', 'variant', 'level', 'title'],
    key: 'level-title',
    sorter: true,
    width: 200,
    ...getColumnSearchProps(),
  },
  {
    title: t('space.object.option.modal.table.columns.typology'),
    dataIndex: ['space', 'variant', 'level', 'typology', 'title'],
    key: 'typology-title',
    sorter: true,
    width: 200,
    ...getColumnSearchProps(),
  },
];
