import i18n from '../../../utils/i18n';

interface IConfig {
  isDocuments: boolean;
  isInvoices: boolean;
  isQuotes: boolean;
  isAmendments: boolean;
}

export const getPageTypeTitle = ({ isDocuments, isInvoices, isQuotes, isAmendments }: IConfig) => {
  if (isDocuments) {
    return i18n.t('post.documents').toUpperCase();
  }
  if (isInvoices) {
    return i18n.t('consumers.manage.tabs.budget').toUpperCase();
  }
  if (isQuotes) {
    return i18n.t('consumers.manage.tabs.quotes').toUpperCase();
  }
  if (isAmendments) {
    return i18n.t('amendment.title').toUpperCase();
  }
  return '';
};

export const setCreateNotificationMessage = ({ isDocuments, isInvoices, isAmendments }: IConfig) => {
  if (isDocuments) {
    return i18n.t('documents.modal.created.success.message');
  }
  if (isInvoices) {
    return i18n.t('budget.invoice.created');
  }
  if (isAmendments) {
    return i18n.t('amendment.confirm.created');
  }
  return i18n.t('quotes.confirm.created');
};

export const setUpdateNotificationMessage = ({ isDocuments, isInvoices, isAmendments }: IConfig) => {
  if (isDocuments) {
    return i18n.t('documents.modal.updated.success.message');
  }
  if (isInvoices) {
    return i18n.t('budget.modal.update.success');
  }
  if (isAmendments) {
    return i18n.t('amendment.confirm.update');
  }
  return i18n.t('quotes.confirm.update');
};

export const setArchiveNotificationMessage = ({ isDocuments, isInvoices, isAmendments }: IConfig) => {
  if (isDocuments) {
    return i18n.t('documents.modal.archived.success.message');
  }
  if (isInvoices) {
    return i18n.t('budget.modal.archived.success.message');
  }
  if (isAmendments) {
    return i18n.t('ammendment.modal.archived.success.message');
  }
  return i18n.t('quotes.modal.archived.success.message');
};

export const setUnarchiveNotificationMessage = ({ isDocuments, isInvoices, isAmendments }: IConfig) => {
  if (isDocuments) {
    return i18n.t('documents.modal.unarchived.success.message');
  }
  if (isInvoices) {
    return i18n.t('budget.modal.unarchived.success.message');
  }
  if (isAmendments) {
    return i18n.t('ammendment.modal.unarchived.success.message');
  }
  return i18n.t('quotes.modal.unarchived.success.message');
};

export const setDeleteNotificationMessage = ({ isDocuments, isInvoices, isAmendments }: IConfig) => {
  if (isDocuments) {
    return i18n.t('documents.modal.delete.success.message');
  }
  if (isInvoices) {
    return i18n.t('budget.modal.delete.success.message');
  }
  if (isAmendments) {
    return i18n.t('amendment.modal.delete.success.message');
  }
  return i18n.t('quotes.modal.delete.success.message');
};

export const setApproveNotificationMessage = ({ isDocuments, isInvoices, isAmendments }: IConfig) => {
  if (isDocuments) {
    return i18n.t('fileManagement.document.approved');
  }
  if (isInvoices) {
    return i18n.t('fileManagement.invoice.approved');
  }
  if (isAmendments) {
    return i18n.t('fileManagement.amendment.approved');
  }
  return i18n.t('fileManagement.quote.approved');
};

export const setRejectNotificationMessage = ({ isDocuments, isInvoices, isAmendments }: IConfig) => {
  if (isDocuments) {
    return i18n.t('fileManagement.document.rejected');
  }
  if (isInvoices) {
    return i18n.t('fileManagement.invoice.rejected');
  }
  if (isAmendments) {
    return i18n.t('fileManagement.amendment.rejected');
  }
  return i18n.t('fileManagement.quote.rejected');
};

export const setSignedNotificationMessage = ({ isDocuments, isInvoices, isAmendments }: IConfig) => {
  if (isDocuments) {
    return i18n.t('documents.modal.already.signed.message');
  }
  if (isInvoices) {
    return i18n.t('invoice.modal.already.signed.message');
  }
  if (isAmendments) {
    return i18n.t('amendment.modal.already.signed.message');
  }
  return i18n.t('quotes.modal.already.signed.message');
};

export const setDeclineNotificationMessage = ({ isDocuments, isInvoices, isAmendments }: IConfig) => {
  if (isDocuments) {
    return i18n.t('documents.modal.already.decline.message');
  }
  if (isInvoices) {
    return i18n.t('invoice.modal.already.decline.message');
  }
  if (isAmendments) {
    return i18n.t('amendment.modal.already.decline.message');
  }
  return i18n.t('quotes.modal.already.decline.message');
};

export const getMobileTitle = ({ isDocuments, isInvoices, isAmendments }: IConfig) => {
  if (isDocuments) {
    return i18n.t('consumers.manage.tabs.documents');
  }
  if (isInvoices) {
    return i18n.t('consumers.manage.tabs.budget');
  }
  if (isAmendments) {
    return i18n.t('common.isAmendment.column');
  }
  return i18n.t('consumers.manage.tabs.quotes');
};

export const getUploadTitleByType = ({ isDocuments, isInvoices, isAmendments }: IConfig) => {
  if (isAmendments) {
    return i18n.t('amendment.upload');
  }
  if (isDocuments) {
    return i18n.t('document.upload');
  }
  if (isInvoices) {
    return i18n.t('invoice.upload');
  }
  return i18n.t('quotes.table.upload.invoce');
};
