import React, { useContext, useEffect, useState } from 'react';
import { Form, Button, Row, Col, Input, Upload, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import TypologiesService from '../../../services/TypologiesService';
import { AppContext } from '../../../contexts/AppContextProvider';
import { PlusOutlined } from '@ant-design/icons';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { fileURLToAntdModel } from '../../../helpers/imageToAntdModel';
import { TypologiesContext } from '../../../contexts/TypologiesContextProvider';
import { nodeKeys } from '../enums/nodeKeys';
import NodesModifyLayout from './NodesModifyLayout';
import { TYPOLOGY_REFRESH_ITEMS } from '../../../constants/actionTypes/typologiesConstants';
import { validateObjectField } from '../../../helpers/validateObjectField';
import { useLessThen801 } from '../../../helpers/mediaDetect';
import { IAppContext } from '../../../typings/IApp';

const service: TypologiesService = new TypologiesService();

const TypologiesModify: React.FC<any> = (props: any) => {
  const {
    app: { currentProject },
  } = useContext<IAppContext>(AppContext);
  const {
    nodes: { currentNode, currentNodeKey },
    nodesDispatch,
  } = useContext(TypologiesContext);
  const { t } = useTranslation();
  const isCreate = currentNodeKey === nodeKeys.typology && !currentNode;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [typology, setTypology] = useState<any>(undefined);
  const [object3DModel, setObject3DModel] = useState<any>();
  const [default3DModel, setDefault3DModel] = useState<any>([]);
  const [isUpload3DModel, setIsUpload3DModel] = useState<boolean>(false);
  const [isDelete3DModel, setIsDelete3DModel] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const setIsRefresh = (isRefresh: boolean) => {
    nodesDispatch({
      type: TYPOLOGY_REFRESH_ITEMS,
      isRefresh,
    });
  };

  const getTypology = (id: number) => {
    return service
      .getTypologyById(id, currentProject?.key)
      .then((res: any) => {
        setTypology(res);
        const data = {
          title: res.title,
          model: null,
        };
        form.setFieldsValue(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (currentNodeKey === nodeKeys.typology && currentNode && typology?.id !== currentNode?.id) {
      setLoading(true);
      getTypology(currentNode.id);
    }
  }, [currentNodeKey, currentNode]);

  useEffect(() => {
    return () => {
      setDefault3DModel([]);
    };
  }, []);

  useEffect(() => {
    if (typology && typology.serverFileName) {
      const file = fileURLToAntdModel({
        name: typology.serverFileName,
        id: Date.now(),
      });
      setDefault3DModel([file]);
      setIsUpload3DModel(true);
    }
  }, [typology]);

  const onSubmit = async () => {
    const values = await form.validateFields();
    const hasProperty = Object.prototype.hasOwnProperty;
    if (!hasProperty.call(values, 'errorFields')) {
      setIsSaving(true);
      const modelActions = (id: number) => {
        const promises: Promise<any>[] = [];
        if (object3DModel) {
          promises.push(service.upload3DModel(id, object3DModel));
        }
        if (isDelete3DModel && !object3DModel) {
          promises.push(service.delete3DModel(id));
        }
        return Promise.all(promises);
      };

      if (isCreate) {
        const { model, ...body } = values;
        service.createTypology(body, currentProject?.key).then((res: any) => {
          modelActions(res.id)
            .then(() => {
              getTypology(res.id);
              notification.success({
                message: t('typology.create.notification.create'),
              });
              setIsRefresh(true);
            })
            .catch((e) => {
              console.error(e);
              notification.error({
                message: `Error while typology create`,
              });
            })
            .finally(() => setIsSaving(false));
        });
      } else {
        const { model, ...body } = values;
        service
          .updateTypology(
            {
              ...body,
              id: currentNode.id,
            },
            currentProject?.key,
          )
          .then(() => {
            modelActions(currentNode.id)
              .then(() => {
                getTypology(currentNode.id);
                notification.success({
                  message: t('typology.create.notification.update'),
                });
                setIsRefresh(true);
              })
              .catch((e) => {
                console.error(e);
                notification.error({
                  message: `Error while typology update`,
                });
              })
              .finally(() => setIsSaving(false));
          });
      }
    }
  };

  const onChange3DModel = (info: UploadChangeParam<UploadFile<any>>) => {
    const { file }: any = info;
    setObject3DModel(file?.status !== 'removed' ? file : null);
    setIsUpload3DModel(file?.status !== 'removed');
    if (file?.status === 'removed') {
      setIsDelete3DModel(true);
    }
  };

  if (useLessThen801()) {
    return (
      <NodesModifyLayout submit={onSubmit} loading={loading} isSaving={isSaving}>
        <Form autoComplete="off" form={form} layout="vertical" name="modify_typology" style={{ padding: '1rem' }}>
          <Row gutter={[24, 4]}>
            <Col span={24}>
              <Form.Item
                label={t('typology.create.card.typology.title')}
                name="title"
                rules={[{ required: true, message: t('common.validation.required') }]}>
                <Input className="common-animation-primary" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={t('typology.create.babylonCode')} name="babylonCode">
                <Input className="common-animation-primary" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t('typology.create.babylonParams')}
                name="babylonParams"
                rules={[validateObjectField('babylonParams')]}>
                <Input.TextArea rows={4} className="common-animation-primary" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={t('typology.create.object3D')} name="model">
                <div className="dropbox">
                  <Upload
                    listType="picture"
                    accept=".glb, .gltf"
                    beforeUpload={() => false}
                    onChange={onChange3DModel}
                    defaultFileList={default3DModel}
                    className="variant-floor-plan"
                    customRequest={() => {}}>
                    {!isUpload3DModel && (
                      <Button className="btn-upload" icon={<PlusOutlined />}>
                        {t('common.btn.upload')}
                      </Button>
                    )}
                  </Upload>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </NodesModifyLayout>
    );
  }

  return (
    <NodesModifyLayout submit={onSubmit} loading={loading} isSaving={isSaving}>
      <Form autoComplete="off" form={form} layout="vertical" name="modify_typology" style={{ padding: '1rem' }}>
        <Row gutter={[24, 4]}>
          <Col span={12}>
            <Form.Item
              label={t('typology.create.card.typology.title')}
              name="title"
              rules={[{ required: true, message: t('common.validation.required') }]}>
              <Input className="common-animation-primary" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('typology.create.babylonCode')} name="babylonCode">
              <Input className="common-animation-primary" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('typology.create.babylonParams')}
              name="babylonParams"
              rules={[validateObjectField('babylonParams')]}>
              <Input.TextArea rows={4} className="common-animation-primary" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={t('typology.create.object3D')} name="model">
              <div className="dropbox">
                <Upload
                  listType="picture"
                  accept=".glb, .gltf"
                  beforeUpload={() => false}
                  onChange={onChange3DModel}
                  defaultFileList={default3DModel}
                  className="variant-floor-plan"
                  customRequest={() => {}}>
                  {!isUpload3DModel && (
                    <Button className="btn-upload" icon={<PlusOutlined />}>
                      {t('common.btn.upload')}
                    </Button>
                  )}
                </Upload>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </NodesModifyLayout>
  );
};

export default TypologiesModify;
