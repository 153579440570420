interface DynamicParamsActionModel {
  keyName: string;
  data: any[],
}

export const initialState: any = {};

export function reducer(state: any, action: DynamicParamsActionModel) {
  const { keyName, data } = action;
  return {
    ...state,
    [keyName]: data,
  };
}
