import { Button, Dropdown, Menu } from 'antd';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../assets/styles/meetings.module.css';
import moreIcon from '../../../assets/images/ic-more.svg';
import deleteIcon from '../../../assets/images/ic-delete-dark-gray.svg';
import editIcon from '../../../assets/images/ic-edit-dark.svg';
import { useLessThen801 } from '../../../helpers/mediaDetect';
import { MeetingModel, MeetingsModel } from '../../../models/MeetingsModel';
import { AppContext } from '../../../contexts/AppContextProvider';
import moment from 'moment';
import { formatDate } from '../../../helpers/dateTools';
import LogoComponent from '../../../components/common/LogoComponent';
import commentIcon from '../../../assets/images/comment.svg';
import ModalExportCalendar from './ModalExportCalendar';
import { IAppContext } from '../../../typings/IApp';

interface IList {
  openPreview: (id: number | undefined) => void;
  openEdit: (id: number | undefined) => void;
  openDelete: (id: number | undefined) => void;
  isTab?: boolean;
  meetings: MeetingsModel;
}

const MeetingsList = (props: IList) => {
  const { t } = useTranslation();
  const { openPreview, openEdit, openDelete, isTab, meetings } = props;
  const {
    app: { user },
  } = useContext<IAppContext>(AppContext);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectMeeting, setSelectMeeting] = useState<MeetingModel | null>(null);

  if (useLessThen801()) {
    return (
      <div className={isTab ? css['meetings-list-container-tab'] : css['meetings-list-container']}>
        {meetings.map((meeting: MeetingModel) => {
          const menu = (
            <Menu className="update-menu-container" style={{ width: '130px' }}>
              {moment(meeting.datetime).isAfter(moment()) && (
                <Menu.Item key="edit">
                  <div onClick={() => openEdit(meeting.id)} className={css['update-menu-item']}>
                    <Button
                      style={{
                        border: 'none',
                        backgroundColor: 'inherit',
                        boxShadow: 'none',
                        padding: 0,
                        marginRight: '0.5rem',
                      }}>
                      <img src={editIcon} alt="" style={{ height: '15px', width: '15px' }} />
                    </Button>
                    <span style={{ color: '#00122d', fontWeight: 600 }}>{t('budget.table.edit')}</span>
                  </div>
                </Menu.Item>
              )}
              <Menu.Item key="delete">
                <div onClick={() => openDelete(meeting.id)} className={css['update-menu-item']}>
                  <Button
                    style={{
                      border: 'none',
                      backgroundColor: 'inherit',
                      boxShadow: 'none',
                      padding: 0,
                      marginRight: '0.5rem',
                    }}>
                    <img src={deleteIcon} alt="" style={{ height: '15px', width: '15px' }} />
                  </Button>
                  <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.title.delete')}</span>
                </div>
              </Menu.Item>
            </Menu>
          );
          return (
            <div
              className={
                moment(meeting.datetime).isAfter(moment()) ? css['meeting-info'] : css['meeting-info-non-active']
              }
              key={meeting.id}>
              <div className={css['meeting-date']}>
                <div onClick={() => openPreview(meeting.id)}>
                  <span style={{ fontSize: '20px' }}>{formatDate(meeting.datetime, 'DD MMM', true)}</span>
                  <span style={{ marginLeft: '1rem' }}>{moment(meeting.datetime).format('HH:mm')}</span>
                </div>
                <div>
                  <Button
                    className={css['exportCalendarIcon']}
                    onClick={() => {
                      setSelectMeeting(meeting);
                      setIsOpenModal(true);
                    }}></Button>
                  {meeting.createdUser.id === user.id && (
                    <Dropdown overlay={menu} trigger={['click']}>
                      <Button className={css['update-dropdown-btn']} style={{ marginLeft: '1rem' }}>
                        <img src={moreIcon} alt="" height={15} width={15} />
                      </Button>
                    </Dropdown>
                  )}
                </div>
              </div>
              <div className={css['meeting-full-info']}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <span onClick={() => openPreview(meeting.id)}>{meeting.name || ''}</span>
                </div>
                <div onClick={() => openPreview(meeting.id)} style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className={css['details-text']}>{t('meeting.details.title')}:</span>
                  <span>
                    {`${meeting.details.length < 21 ? meeting.details : meeting.details.slice(0, 20) + '...'}` || ''}
                  </span>
                </div>
                <div className={css['footer-container']} onClick={() => openPreview(meeting.id)}>
                  <span>
                    <LogoComponent
                      id={meeting.createdUser.id}
                      name={`${meeting.createdUser.firstName} ${meeting.createdUser.lastName}`}
                      image={meeting.createdUser.image}
                    />
                    {meeting.assignees.map((a) => (
                      <LogoComponent id={a.id} name={`${a.firstName} ${a.lastName}`} image={a.image} key={a.id} />
                    ))}
                  </span>
                  <span style={{ color: '#889bb7', marginTop: '1rem' }}>
                    <img src={commentIcon} alt="" width={15} height={15} style={{ marginRight: '0.5rem' }} />
                    {`${meeting.comments.length} comments`}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className={isTab ? css['meetings-list-container-tab'] : css['meetings-list-container']}>
      {meetings.map((meeting: MeetingModel) => {
        const menu = (
          <Menu className="update-menu-container" style={{ width: '130px' }}>
            {moment(meeting.datetime).isAfter(moment()) && (
              <Menu.Item key="edit">
                <div onClick={() => openEdit(meeting.id)} className={css['update-menu-item']}>
                  <Button
                    style={{
                      border: 'none',
                      backgroundColor: 'inherit',
                      boxShadow: 'none',
                      padding: 0,
                      marginRight: '0.5rem',
                    }}>
                    <img src={editIcon} alt="" style={{ height: '15px', width: '15px' }} />
                  </Button>
                  <span style={{ color: '#00122d', fontWeight: 600 }}>{t('budget.table.edit')}</span>
                </div>
              </Menu.Item>
            )}
            <Menu.Item key="delete">
              <div onClick={() => openDelete(meeting.id)} className={css['update-menu-item']}>
                <Button
                  style={{
                    border: 'none',
                    backgroundColor: 'inherit',
                    boxShadow: 'none',
                    padding: 0,
                    marginRight: '0.5rem',
                  }}>
                  <img src={deleteIcon} alt="" style={{ height: '15px', width: '15px' }} />
                </Button>
                <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.title.delete')}</span>
              </div>
            </Menu.Item>
          </Menu>
        );
        return (
          <div
            className={
              moment(meeting.datetime).isAfter(moment()) ? css['meeting-info'] : css['meeting-info-non-active']
            }
            key={meeting.id}>
            <div className={css['meeting-date']} onClick={() => openPreview(meeting.id)}>
              <span style={{ fontSize: '20px' }}>{formatDate(meeting.datetime, 'DD MMM', true)}</span>
              <br />
              <span>{moment(meeting.datetime).format('HH:mm')}</span>
            </div>
            <div className={css['meeting-full-info']}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <span onClick={() => openPreview(meeting.id)}>{meeting.name || ''}</span>
                <div>
                  <Button
                    className={css['exportCalendarIcon']}
                    onClick={() => {
                      setSelectMeeting(meeting);
                      setIsOpenModal(true);
                    }}></Button>
                  {meeting.createdUser.id === user.id && (
                    <Dropdown overlay={menu} trigger={['click']}>
                      <Button className={css['update-dropdown-btn']} style={{ marginLeft: '1rem' }}>
                        <img src={moreIcon} alt="" height={15} width={15} />
                      </Button>
                    </Dropdown>
                  )}
                </div>
              </div>
              <div onClick={() => openPreview(meeting.id)} style={{ display: 'flex', flexDirection: 'column' }}>
                <span className={css['details-text']}> {`${t('meetings.details.title')}:`}</span>
                <span>
                  {`${meeting.details.length < 21 ? meeting.details : meeting.details.slice(0, 20) + '...'}` || ''}
                </span>
              </div>
              <div className={css['footer-container']} onClick={() => openPreview(meeting.id)}>
                <span>
                  <LogoComponent
                    id={meeting.createdUser.id}
                    name={`${meeting.createdUser.firstName} ${meeting.createdUser.lastName}`}
                    image={meeting.createdUser.image}
                  />
                  {meeting.assignees.map((a) => (
                    <LogoComponent id={a.id} name={`${a.firstName} ${a.lastName}`} image={a.image} key={a.id} />
                  ))}
                </span>
                <span style={{ color: '#889bb7' }}>
                  <img src={commentIcon} alt="" width={15} height={15} style={{ marginRight: '0.5rem' }} />
                  {`${meeting.comments.length} ${t('budget.form.sign.comments').toLowerCase()}`}
                </span>
              </div>
            </div>
          </div>
        );
      })}
      {isOpenModal && (
        <ModalExportCalendar isOpen={isOpenModal} meeting={selectMeeting} cancel={() => setIsOpenModal(false)} />
      )}
    </div>
  );
};

export default MeetingsList;
