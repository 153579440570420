import React, { createContext, useReducer } from 'react';
import { initialState, reducer } from '../reducers/categoriesReducer';

export const CategoriesContext = createContext({} as any);

const CategoriesContextProvider: React.FC = (props: any) => {
  const [nodes, nodesDispatch] = useReducer(reducer, initialState);

  return <CategoriesContext.Provider value={{ nodes, nodesDispatch }}>{props.children}</CategoriesContext.Provider>;
};

export default CategoriesContextProvider;
