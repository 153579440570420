import React, { useContext, useEffect, useState } from 'react';
import { CHANGE_CURRENT_PAGE } from '../../../constants/actionTypes/headerConstants';
import { HeaderContext } from '../../../contexts/HeaderContextProvider';
import css from '../../../assets/styles/faq.module.css';
import SearchHIW from './components/SearchHIW';
import TopicHIW from './components/TopicHIW';
import ResultHIW from './components/ResultHIW';
import { QuestionsModel } from '../../../models/FAQModel';
import { useLessThen640 } from '../../../helpers/mediaDetect';
import MobileHIW from './components/MobileHIW';
import { useTranslation } from 'react-i18next';
import i18n from '../../../utils/i18n';

const HowItWorks = () => {
  const { t } = useTranslation();
  const { headerDispatch } = useContext(HeaderContext);
  const [selectTopicId, setSelectTopicId] = useState<null | number>(null);
  const [resultHIW, setResultHIW] = useState<null | QuestionsModel>(null);
  const [searchingWord, setSearchingWord] = useState<string>('');

  useEffect(() => {
    headerDispatch({
      type: CHANGE_CURRENT_PAGE,
      currentPage: t('hiw.title'),
      path: 'hiw',
    });
  }, [i18n.language]);

  return (
    <div className={css['container-consumer']}>
      <SearchHIW setResultHIW={setResultHIW} setSearchingWord={setSearchingWord} />
      {useLessThen640() ? (
        <div className={css['wrapperMobile']}>
          <MobileHIW
            setSelectTopicId={setSelectTopicId}
            setResultHIW={setResultHIW}
            isEmptySearch={!searchingWord}
            selectTopicId={selectTopicId}
            resultHIW={resultHIW}
            searchingWord={searchingWord}
          />
        </div>
      ) : (
        <div className={css['wrapper']}>
          <TopicHIW
            setSelectTopicId={setSelectTopicId}
            setResultHIW={setResultHIW}
            resultHIW={resultHIW}
            selectTopicId={selectTopicId}
            isEmptySearch={!searchingWord}
          />
          <ResultHIW selectTopicId={selectTopicId} resultHIW={resultHIW} searchingWord={searchingWord} />
        </div>
      )}
    </div>
  );
};

export default HowItWorks;
