import HttpService from './common/httpService';
import config from '../config';
import buildQuery from 'odata-query';
import { NewTicketModel, UpdateTicketModel } from '../models/TicketModel';
import { NewTicketMessageModel } from '../models/TicketModel';

class TicketsService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getTicketsByUserId(userId: any, option: any) {
    const { top, count, skip, orderBy } = option;
    const { dateRangeFilter, searchFilter, projectFilter, typeFilter, statusFilter, priorityFilter, propertyFilter } =
      option;

    let filter: any[] = [];

    const defaultFilter: any = [
      {
        or: [{ createdUser: { eq: userId } }, { recipient: { eq: userId } }],
      },
    ];

    if (typeFilter) {
      filter = typeFilter;
    }
    if (searchFilter) {
      const [created, recipient] = searchFilter;
      const common = [
        {
          or: [
            {
              and: [recipient, { 'created_user/id': { eq: userId } }],
            },
            {
              and: [created, { 'recipient/id': { eq: userId } }],
            },
          ],
        },
      ];
      filter = filter.length > 0 ? [{ and: [filter, common] }] : common;
    }
    if (projectFilter) {
      filter = [{ and: [filter.length ? filter : defaultFilter, projectFilter] }];
    }
    if (statusFilter) {
      filter = [{ and: [filter.length ? filter : defaultFilter, statusFilter] }];
    }
    if (priorityFilter) {
      filter = [{ and: [filter.length ? filter : defaultFilter, priorityFilter] }];
    }
    if (dateRangeFilter) {
      filter = [{ and: [filter.length ? filter : defaultFilter, dateRangeFilter] }];
    }
    if (propertyFilter) {
      filter = [{ and: [filter.length ? filter : defaultFilter, propertyFilter] }];
    }

    if (!filter.length) {
      filter = defaultFilter;
    }
    const query = buildQuery({ filter, top, count, skip, orderBy });

    return this.httpService.get(`${config.baseRequest}ticket${query}`);
  }

  public getStatuses() {
    return this.httpService.get(`${config.baseRequest}dictionary/tickets_statuses`);
  }

  public getPriorities() {
    return this.httpService.get(`${config.baseRequest}dictionary/tickets_priority`);
  }

  public getTypes() {
    return this.httpService.get(`${config.baseRequest}dictionary/tickets_types`);
  }

  //dictinary statuses
  public getSystemMessages() {
    return this.httpService.get(`${config.baseRequest}dictionary/tickets_system_messages`);
  }

  public getTicketById(id: number) {
    return this.httpService.get(`${config.baseRequest}ticket/${id}`);
  }

  public createTicket(body: NewTicketModel) {
    return this.httpService.post(`${config.baseRequest}ticket`, body);
  }

  public updateTicketById(ticketId: number, body: UpdateTicketModel) {
    return this.httpService.put(`${config.baseRequest}ticket/${ticketId}`, body);
  }
  public setReadTicketById(ticketId: number) {
    return this.httpService.put(`${config.baseRequest}ticket/${ticketId}/read`, {});
  }

  public deleteTicketById(id: number) {
    return this.httpService.delete(`${config.baseRequest}ticket/${id}`);
  }

  public addMessageByTicketId(id: number, body: NewTicketMessageModel) {
    return this.httpService.post(`${config.baseRequest}ticket/${id}/message`, body);
  }

  public addFileByMessageId(id: number, file: any) {
    return this.httpService.postFile(`${config.baseRequest}ticket/message/${id}/files`, file);
  }
}

export default TicketsService;
