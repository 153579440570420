import { Modal, notification } from 'antd';
import React, { useState } from 'react';
import AIForm from './AIForm';
import AILoader from './AILoader';
import AIService from '../../../../services/AIService';
import { IAiFaqQuestion } from '../../../../models/FAQModel';
import AIResult from './AIResult';
import { aiLanguageGenerating } from '../../../../constants/aiLanguageGenerating';
import { initSocket } from '../../../../helpers/initSocket';
import ConfirmModal from './components/ConfirmModal';
import { useTranslation } from 'react-i18next';

export interface IFormValues {
  text: string;
}

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const aiService = new AIService();

const AIContainer = ({ isOpen, onClose }: IProps) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [languageGenerating, setLanguageGenerating] = useState<aiLanguageGenerating>(aiLanguageGenerating.fr_en);
  const [isLoading, setIsLoading] = useState(false);
  const [isCloseConfirm, setIsCloseConfirm] = useState(false);
  //todo true or delete
  // const [isLoadingDraft, setIsLoadingDraft] = useState(false);
  const [prompt, setPrompt] = useState('');
  const [questions, setQuestions] = useState<IAiFaqQuestion[]>([]);
  // todo delete?
  // useEffect(() => {
  //   getDraft();
  // }, []);

  const generateFAQ = async (values: IFormValues) => {
    setIsLoading(true);
    setPrompt(values.text);
    await aiService
      .generateFAQ({ prompt: values.text })
      .then((res) => {
        initSocket({
          eventName: res.eventName,
          callback: (res) => {
            const questionsWithId: IAiFaqQuestion[] = res.body.map(
              (item: Omit<IAiFaqQuestion, 'id'>, index: number) => {
                return {
                  id: index,
                  ...item,
                };
              },
            );
            setQuestions(questionsWithId);
            setStep(2);
            setIsLoading(false);
          },
          callbackError: () => {
            setIsLoading(false);
          },
        });
      })
      .catch((e) => {
        setIsLoading(false);
        notification.error({
          message: e.message,
        });
      });
  };

  // todo delete?
  // const getDraft = async () => {
  //   setIsLoadingDraft(true);
  //   await aiService
  //     .getDraft()
  //     .then((res) => {
  //       console.log(res);
  //     })
  //     .catch((e) => {
  //       notification.error({
  //         message: e.message,
  //       });
  //     })
  //     .finally(() => setIsLoadingDraft(false));
  // };

  return (
    <Modal
      visible={isOpen}
      footer={false}
      className={'aiModalContainer'}
      onCancel={() => {
        step === 1 ? onClose() : setIsCloseConfirm(true);
      }}
      closable
      maskClosable={false}>
      {step === 1 && (
        <AIForm
          promptDefault={prompt}
          onClose={onClose}
          onSubmit={generateFAQ}
          languageGenerating={languageGenerating}
          setLanguageGenerating={setLanguageGenerating}
          isLoading={isLoading}
        />
      )}
      {step === 2 && (
        <AIResult
          questions={questions}
          setStep={setStep}
          languageGenerating={languageGenerating}
          prompt={prompt}
          onFinishPublic={onClose}
        />
      )}
      {isCloseConfirm && (
        <ConfirmModal
          handleOk={onClose}
          handleCancel={() => setIsCloseConfirm(false)}
          title={t('faq.ai.confirm.close.title')}
          description={t('faq.ai.confirm.close.description')}
          okTitle={t('common.confirm.btn.yes')}
          cancelTitle={t('common.confirm.btn.cancel')}
          isLoading={false}
        />
      )}
      {isLoading && <AILoader />}
      {/* {isLoadingDraft && <AILoader isDraft />} */}
    </Modal>
  );
};

export default AIContainer;
