import HttpService from './common/httpService';
import config from '../config';

class DictionariesServices {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getDictionary(name: string) {
    return this.httpService.get(`${config.baseRequest}dictionary/${name}`);
  }
}

export default DictionariesServices;
