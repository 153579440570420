import HttpService from './common/httpService';
import config from '../config';
import generateODataQuery, { IODataModel } from '../helpers/generateODataQuery';
import { getApiKeyOptions } from '../helpers/getApiKeyOptions';

class ConsumerRequestsService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  //request dor website

  public getRequests(params: IODataModel = {}, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    const query = generateODataQuery(params);
    return this.httpService.get(`${config.baseRequest}consumers/requests${query}`, options);
  }

  public getRequestById(id: number, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.get(`${config.baseRequest}consumers/requests/${id}`, options);
  }

  public getRequestStatuses() {
    return this.httpService.get(`${config.baseRequest}consumers/requests/statuses`);
  }

  public setConsumerRequestStatus(body: any, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.put(`${config.baseRequest}consumers/requests/status`, body, options);
  }

  public deleteRequest(id: number, projectKey?: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.delete(`${config.baseRequest}consumers/requests/${id}`, null, options);
  }
}

export default ConsumerRequestsService;
