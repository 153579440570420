import { Button, DatePicker, TablePaginationConfig } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import css from '../../../assets/styles/gallery.module.css';
import { useTranslation } from 'react-i18next';
import { FilterTypes } from '../../../constants/filterTypes';
import { FiltersContext } from '../../../contexts/FiltersContextProvider';
import { SET_GALLERY_FILTERS } from '../../../constants/actionTypes/filtersConstants';
import { useLessThen801 } from '../../../helpers/mediaDetect';
import { FilterValue } from 'antd/lib/table/interface';
import { RangePickerProps } from 'antd/lib/date-picker';

interface GalleryControlProps {
  setIsOpenModal: (b: boolean) => void;
  isConsumer: boolean;
  onChangeControl: (pagination?: TablePaginationConfig, filters?: Record<string, FilterValue | null | any>) => void;
}

const { RangePicker } = DatePicker;

const GalleryControl = (props: GalleryControlProps) => {
  const { setIsOpenModal, isConsumer, onChangeControl } = props;
  const {
    filters: { galleryFilter },
    filtersDispatch,
  } = useContext(FiltersContext);
  const { t } = useTranslation();
  const [dataRange, setDataRange] = useState<RangePickerProps['value']>(galleryFilter.dateRange);

  useEffect(() => {
    setDataRange(galleryFilter.dateRange);
  }, [galleryFilter.dateRange]);

  const onChangeCalendar = (value: RangePickerProps['value']) => {
    filtersDispatch({ type: SET_GALLERY_FILTERS, filters: { dateRange: value } });
    const body = {
      date: {
        type: FilterTypes.DATE_RANGE,
        value: value,
      },
    };
    onChangeControl(undefined, body);
  };

  return (
    <div className={css['gallery-control-container']}>
      <div className={css['gallery-control-wrapper']}>
        <RangePicker
          inputReadOnly
          suffixIcon={<div className={'calendar-img'} style={{ width: '18px', height: '18px' }}></div>}
          className="gallery-control-range-picker common-animation-primary"
          defaultValue={dataRange}
          onChange={onChangeCalendar}
        />
      </div>
      {!isConsumer && !useLessThen801() && (
        <Button className={`common-blue-btn common-primary-btn`} onClick={() => setIsOpenModal(true)}>
          <span>{t('gallery.upload.images')}</span>
        </Button>
      )}
    </div>
  );
};

export default GalleryControl;
