import HttpService from './common/httpService';
import config from '../config';
import generateODataQuery, { IODataModel } from '../helpers/generateODataQuery';
import buildQuery from 'odata-query';
import {
  CreateTopicModel,
  FAQType,
  QuestionCreateModel,
  QuestionUpdatedModel,
  QuestionsCreateModel,
  UpdateTopicModel,
} from '../models/FAQModel';

class FAQService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getFAQ(params: any = {}) {
    const { top, count, skip, filters, orderBy } = params;

    let filter = [];

    const defaultFilter: any = {
      topic: {
        ne: null,
      },
      'type/code': {
        eq: null,
      },
    };

    if (filters) {
      filter = [defaultFilter, ...filters.filter];
    } else {
      filter = defaultFilter;
    }

    const query = buildQuery({ filter, top, count, skip, orderBy });
    return this.httpService.get(`${config.baseRequest}faq${query}`);
  }

  public getHIW(params: any = {}) {
    const { top, count, skip, filters, orderBy } = params;

    let filter = [];

    const defaultFilter: any = {
      topic: {
        ne: null,
      },
      'type/code': {
        eq: FAQType.HIW,
      },
    };

    if (filters) {
      filter = [defaultFilter, ...filters.filter];
    } else {
      filter = defaultFilter;
    }

    const query = buildQuery({ filter, top, count, skip, orderBy });
    return this.httpService.get(`${config.baseRequest}faq${query}`);
  }

  public getFAQNoDefaultFilters(params: IODataModel = {}) {
    const query = generateODataQuery(params);
    return this.httpService.get(`${config.baseRequest}faq/${query}`);
  }

  public getFAQById(id: number | null) {
    return this.httpService.get(`${config.baseRequest}faq/${id}`);
  }

  public deleteFAQ(id: number | null) {
    return this.httpService.delete(`${config.baseRequest}faq/${id}`);
  }

  public deleteFAQMulti(numbers: Array<number>) {
    return this.httpService.delete(`${config.baseRequest}faq`, numbers);
  }

  public updateFAQ(body: QuestionUpdatedModel) {
    return this.httpService.put(`${config.baseRequest}faq`, body);
  }

  public createFAQ(body: QuestionCreateModel) {
    return this.httpService.post(`${config.baseRequest}faq`, body);
  }

  public createFAQMany(body: QuestionsCreateModel[]) {
    return this.httpService.post(`${config.baseRequest}faq/many`, body);
  }

  public updateTopic(body: UpdateTopicModel) {
    return this.httpService.put(`${config.baseRequest}faq/topics`, body);
  }

  public createTopic(body: CreateTopicModel) {
    return this.httpService.post(`${config.baseRequest}faq/topics`, body);
  }

  public getTopics(params: IODataModel = {}) {
    const query = buildQuery(params);
    return this.httpService.get(`${config.baseRequest}faq/topics${query}`);
  }

  public getTopicById(id: number | null) {
    return this.httpService.get(`${config.baseRequest}faq/topics/${id}`);
  }

  public deleteTopic(id: number | null) {
    return this.httpService.delete(`${config.baseRequest}faq/topics/${id}`);
  }
}

export default FAQService;
