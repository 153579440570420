import { Button, Col, Form, FormInstance, Input, InputNumber, notification, Row, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../../../assets/styles/budget.module.css';
import {
  convertToCoins,
  convertToCurrency,
  convertToPriceWithSpace,
  reverseConvertToPriceWithSpace,
} from '../../../../../helpers/convertToPrice';
import deleteBtnIcon from '../../../../../assets/images/ic-delete-blue.svg';
import addIcon from '../../../../../assets/images/ic-plus-blue.svg';
import uploadIcon from '../../../../../assets/images/ic-upload-blue.svg';
import linkIcon from '../../../../../assets/images/ic-link-blue.svg';
import deleteIcon from '../../../../../assets/images/ic-btn-close-grey.svg';
import SelectTableModal from '../SelectTableModal';
import { generateFilterfromObj } from '../../../../../helpers/generateFIlterfromObj';
import { FilterTypes } from '../../../../../constants/filterTypes';
import QuotesService from '../../../../../services/QuoteService';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { IQuoteModel } from '../../../../../models/QuotesModel';

const quotesService = new QuotesService();

interface IAmendmentQuotes {
  form: FormInstance;
  propertyId: string | null;
  fields?: {
    id: number;
    isDone: boolean;
    title: string;
  }[];
  setFields?: (
    v: {
      id: number;
      isDone: boolean;
      title: string;
    }[],
  ) => void;
  fileQuotesList: UploadFile[];
  setFileQuotesList: (f: UploadFile[]) => void;
  currency: string;
}

interface IItem {
  id?: number;
  name: null | string;
  sumWithFees: number | string;
  sumWithVat: number | string;
  price?: number;
  uid?: string;
}

const AmendmentQuotes = ({
  form,
  propertyId,
  fields,
  setFields,
  fileQuotesList,
  setFileQuotesList,
  currency,
}: IAmendmentQuotes) => {
  const { t } = useTranslation();
  const vat = 7.7;
  const [isOpenSelectQuotes, setIsOpenSelectQuotes] = useState(false);
  const [itemKey, setItemKey] = useState(0);
  const [quotesItems, setQuotesItems] = useState<IItem[]>([{ name: null, sumWithFees: 0, sumWithVat: 0 }]);
  const [totalWithFees, setTotalWithFees] = useState(0);
  const [totalWithVat, setTotalWithVat] = useState(0);

  const addItem = () => {
    setQuotesItems([...quotesItems, { name: '', sumWithFees: 0, sumWithVat: 0 }]);
    setItemKey(itemKey + 1);
  };

  const deleteItem = (index: number, uid: undefined | string) => {
    if (index === 0 && quotesItems.length === 1) {
      setQuotesItems([{ name: '', sumWithFees: 0, sumWithVat: 0 }]);
      setFileQuotesList([]);
    } else {
      if (uid) {
        setFileQuotesList(fileQuotesList.filter((item) => item.uid !== uid));
      }
      const newItems = quotesItems.slice();
      const items = newItems.filter((_, itemIndex) => itemIndex !== index);
      const fieldValue = form.getFieldsValue();
      const newFieldsItems = (fieldValue.quotes as IItem[]).filter((_, itemIndex) => itemIndex !== index);
      form.setFieldsValue({ ...fieldValue, items: newFieldsItems });
      setQuotesItems(items);
    }
  };

  const onSave = (ids: number[]) => {
    if (ids.length > 0 && propertyId) {
      const params = {
        filters: generateFilterfromObj({
          id: {
            type: FilterTypes.SELECT_MULTIPLE,
            value: ids,
          },
        }),
        orderBy: ['createdDate desc'],
      };
      quotesService
        .getQuotesNoArchived(+propertyId, params)
        .then((res: IQuoteModel[]) => {
          const newItems = res.map((item, index) => {
            const sumWithFees = item.sumWithFees !== null ? item.sumWithFees : item.sum || 0;
            return {
              index: itemKey + index + 1,
              id: item.id,
              name: item.number,
              sumWithFees,
              sumWithVat: item.sumWithVat || 0,
            };
          });
          const lastItem = quotesItems && quotesItems.length > 0 ? quotesItems.pop() : null;
          if (lastItem && lastItem.name !== '' && lastItem.price !== 0) {
            if (quotesItems.length === 1) {
              setQuotesItems([lastItem, ...newItems]);
            } else {
              setQuotesItems(newItems);
            }
          } else {
            setQuotesItems([...quotesItems, ...newItems]);
          }
        })
        .catch((e) => console.log(e));
    }
    setIsOpenSelectQuotes(false);
  };

  const handleFile = (info: UploadChangeParam<UploadFile<any>>, index: number) => {
    const values = { ...form.getFieldsValue() };

    if (values && values.quotes.length > 0) {
      values.quotes[index].name = info.file.name || null;
      values.quotes[index].uid = info.file.uid;
      form.setFieldsValue(values);
    }
    quotesItems[index].name = info.file.name;
    quotesItems[index].uid = info.file.uid;
    setQuotesItems(quotesItems);
    setFileQuotesList([...fileQuotesList, ...info.fileList]);
  };

  const calculateSum = () => {
    const values = { ...form.getFieldsValue() };
    setTotalWithFees(values.quotes.reduceRight((prev: number, curr: IItem) => (prev += +curr.sumWithFees), 0));
    setTotalWithVat(values.quotes.reduceRight((prev: number, curr: IItem) => (prev += +curr.sumWithVat), 0));
    //currentAmendmentSum
    const sumQuotesFees = values.quotes.reduceRight((prev: number, curr: IItem) => (prev += +curr.sumWithFees), 0);
    const sumAmendmentsFees = values.amendments.reduceRight(
      (prev: number, curr: IItem) => (prev += +curr.sumWithFees),
      0,
    );
    const sumQuotesVat = values.quotes.reduceRight((prev: number, curr: IItem) => (prev += +curr.sumWithVat), 0);
    const sumAmendmentsVat = values.amendments.reduceRight(
      (prev: number, curr: IItem) => (prev += +curr.sumWithVat),
      0,
    );
    values.currentSum.sumWithFees = +sumQuotesFees;
    values.currentSum.sumWithVat = +sumQuotesVat;
    //TotalFullContract
    values.totalFullSum.sumWithFees = +sumQuotesFees + +sumAmendmentsFees + +values.contract.sumWithFees;
    values.totalFullSum.sumWithVat = +sumQuotesVat + +sumAmendmentsVat + +values.contract.sumWithVat;
    form.setFieldsValue(values);
  };

  useEffect(() => {
    if (fields && setFields) {
      const newFields = fields.slice().map((item) => {
        if (item.id === 5) {
          return {
            id: item.id,
            isDone: quotesItems.length > 0 ? true : false,
            title: item.title,
          };
        } else {
          return item;
        }
      });
      setFields(newFields);
    }
    const values = { ...form.getFieldsValue() };
    if (values && values.quotes.length > 0) {
      quotesItems.forEach((item, index) => {
        values.quotes[index].id = item.id;
        values.quotes[index].name = item.name || null;
        values.quotes[index].sumWithFees = item.sumWithFees
          ? convertToCurrency(item.sumWithFees).toFixed(2)
          : item.sumWithFees;
        values.quotes[index].sumWithVat = item.sumWithVat
          ? convertToCurrency(item.sumWithVat).toFixed(2)
          : item.sumWithVat;
      });
      form.setFieldsValue(values);
    }
    calculateSum();
  }, [quotesItems]);

  const setPriceFees = (el: IItem, index: number) => {
    if (el.uid) {
      const values = { ...form.getFieldsValue() };
      const sumWithVat = convertToCurrency(
        Math.floor(
          +(
            convertToCoins(values.quotes[index].sumWithFees) +
            (vat / 100) * convertToCoins(values.quotes[index].sumWithFees)
          ),
        ),
      );
      quotesItems[index].sumWithFees = convertToCoins(values.quotes[index].sumWithFees);
      quotesItems[index].sumWithVat = convertToCoins(+sumWithVat);
      setQuotesItems(quotesItems);
      values.quotes[index].sumWithVat = +sumWithVat;
      form.setFieldsValue(values);
      calculateSum();
    }
  };

  const setPriceVat = (el: IItem, index: number) => {
    if (el.uid) {
      const values = { ...form.getFieldsValue() };
      quotesItems[index].sumWithVat = convertToCoins(+values.quotes[index].sumWithVat);
      setQuotesItems(quotesItems);
      calculateSum();
    }
  };

  return (
    <>
      <div className={css['amendment-item-container']}>
        {quotesItems.length > 0 &&
          quotesItems.map((el, index) => (
            <Row
              key={index}
              wrap={false}
              gutter={24}
              style={{ alignItems: 'center', marginTop: index === 0 ? '0' : '1rem' }}>
              <Col flex={'0 0 86px'}>{index === 0 ? `${t('consumers.manage.tabs.quotes')}:` : ''}</Col>
              <Col flex={'auto'} style={{ display: 'flex' }}>
                {el.name ? (
                  <>
                    <div
                      className={css['ademdum-item-btn']}
                      style={{
                        padding: '0.5rem',
                      }}>
                      <img src={linkIcon} width={18} height={18} style={{ marginRight: '0.5rem' }} />
                      <span>{el.name}</span>
                      <img
                        onClick={() => deleteItem(index, el.uid)}
                        src={deleteIcon}
                        width={16}
                        height={16}
                        style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                      />
                    </div>
                    {quotesItems.length === index + 1 && (
                      <Button
                        onClick={() => addItem()}
                        className={css['ademdum-item-btn']}
                        style={{
                          padding: 0,
                          marginLeft: '0.5rem',
                          justifyContent: 'center',
                        }}>
                        <img src={addIcon} width={18} height={18} />
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    <Button
                      onClick={() => {
                        if (propertyId) {
                          setIsOpenSelectQuotes(true);
                        } else {
                          notification.warning({
                            message: t('tickets.create.placeholder.property'),
                          });
                        }
                      }}
                      className={css['ademdum-item-btn']}
                      style={{ padding: 0, justifyContent: 'center', marginRight: '0.5rem' }}>
                      <img src={linkIcon} width={18} height={18} />
                    </Button>
                    <Upload
                      className={'upload-updates-reverse'}
                      beforeUpload={() => false}
                      onChange={(info) => {
                        handleFile(info, index);
                      }}
                      multiple
                      accept=".pdf"
                      maxCount={1}>
                      <Button className={css['ademdum-item-btn']} style={{ padding: 0, justifyContent: 'center' }}>
                        <img src={uploadIcon} width={18} height={18} />
                      </Button>
                    </Upload>
                    {index !== 0 && (
                      <Button
                        onClick={() => deleteItem(index, el.uid)}
                        className={css['ademdum-item-btn']}
                        style={{
                          padding: 0,
                          marginLeft: '0.5rem',
                          justifyContent: 'center',
                        }}>
                        <img src={deleteBtnIcon} width={18} height={18} />
                      </Button>
                    )}
                  </>
                )}
                <Form.Item
                  name={['quotes', index, 'id']}
                  style={{ marginBottom: 0, display: 'none' }}
                  initialValue={el.id || null}>
                  <Input />
                </Form.Item>
                <Form.Item
                  name={['quotes', index, 'name']}
                  style={{ marginBottom: 0, display: 'none' }}
                  initialValue={el.name || null}>
                  <Input />
                </Form.Item>
                <Form.Item
                  name={['quotes', index, 'uid']}
                  style={{ marginBottom: 0, display: 'none' }}
                  initialValue={el.uid || null}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex={'0 0 180px'}>
                <Form.Item
                  name={['quotes', index, 'sumWithFees']}
                  style={{ marginBottom: 0 }}
                  initialValue={el.sumWithFees ? convertToCurrency(el.sumWithFees).toFixed(2) : el.sumWithFees}
                  rules={[{ required: true, message: t('common.validation.required') }]}>
                  {currency ? (
                    <InputNumber
                      readOnly={!el.uid}
                      onPressEnter={(e) => {
                        e.preventDefault();
                        setPriceFees(el, index);
                      }}
                      onBlur={() => {
                        setPriceFees(el, index);
                      }}
                      style={{ width: '100%', padding: '4px 0', backgroundColor: el.uid ? '#fff' : '#f5f5f5' }}
                      formatter={(value) => `${currency} ${convertToPriceWithSpace(value)}`}
                      parser={(value) => reverseConvertToPriceWithSpace(value, currency)}
                    />
                  ) : (
                    <InputNumber
                      style={{ width: '100%', padding: '4px 0', backgroundColor: el.uid ? '#fff' : '#f5f5f5' }}
                      disabled={true}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col flex={'0 0 180px'}>
                <Form.Item
                  name={['quotes', index, 'sumWithVat']}
                  style={{ marginBottom: 0 }}
                  initialValue={el.sumWithVat ? convertToCurrency(el.sumWithVat).toFixed(2) : el.sumWithVat}
                  rules={[{ required: true, message: t('common.validation.required') }]}>
                  {currency ? (
                    <InputNumber
                      onPressEnter={(e) => {
                        e.preventDefault();
                        setPriceVat(el, index);
                      }}
                      readOnly={!el.uid}
                      onBlur={() => {
                        setPriceVat(el, index);
                      }}
                      style={{ width: '100%', padding: '4px 0', backgroundColor: el.uid ? '#fff' : '#f5f5f5' }}
                      formatter={(value) => `${currency} ${convertToPriceWithSpace(value)}`}
                      parser={(value) => reverseConvertToPriceWithSpace(value, currency)}
                    />
                  ) : (
                    <InputNumber
                      style={{ width: '100%', padding: '4px 0', backgroundColor: el.uid ? '#fff' : '#f5f5f5' }}
                      disabled={true}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          ))}
        <Row style={{ marginTop: '1rem' }}>
          <Col style={{ display: 'flex', justifyContent: 'flex-end' }} flex={'auto'}>
            <span style={{ fontWeight: 700 }}>{`${t('budget.invoice.column.total')}:`}</span>
          </Col>
          <Col style={{ display: 'flex', justifyContent: 'center' }} flex={'0 0 180px'}>
            <span style={{ fontWeight: 700 }}>{`${currency || ''} ${convertToPriceWithSpace(totalWithFees)}`}</span>
          </Col>
          <Col style={{ display: 'flex', justifyContent: 'center' }} flex={'0 0 180px'}>
            <span style={{ fontWeight: 700 }}>{`${currency || ''} ${convertToPriceWithSpace(totalWithVat)}`}</span>
          </Col>
        </Row>
      </div>
      {isOpenSelectQuotes && propertyId && (
        <SelectTableModal
          isOpen={isOpenSelectQuotes}
          propertyId={+propertyId}
          onSave={onSave}
          onClose={() => setIsOpenSelectQuotes(false)}
          isQuote={true}
          isContract={false}
        />
      )}
    </>
  );
};

export default AmendmentQuotes;
