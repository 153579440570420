import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';

const useCloseTabWarning = (message: string) => {
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    window.onbeforeunload = (e) => {
      if (isDirty) {
        e.returnValue = message;
        return message;
      }
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, [isDirty]);

  const routerPrompt = <Prompt when={isDirty} message={message} />;

  return { routerPrompt, setIsDirty };
};

export default useCloseTabWarning;
