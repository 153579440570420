import { Avatar } from 'antd';
import { AvatarSize } from 'antd/lib/avatar/SizeContext';
import React from 'react';
import config from '../../config';
import { getName, hashCode } from '../../helpers/avatarTools';

interface LogoComponentModel {
  id: number;
  name: string;
  image?: string;
  size?: AvatarSize;
}

const LogoComponent: React.FC<LogoComponentModel> = ({ id, name, image, size = 'small' }) => {
  return (
    <Avatar
      size={size}
      style={{
        backgroundColor: `#${hashCode(`${name}${id}`)}`,
        ...(size === 'small' ? { fontSize: '10px' } : {}),
      }}
      {...(image ? { src: `${config.storeUrl}/${image}` } : {})}>
      {getName(name)}
    </Avatar>
  );
};

export default LogoComponent;
