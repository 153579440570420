import { useTranslation } from 'react-i18next';
import useGetPageTypeConfig from './useGetPageTypeConfig';
import { CommonDocumentsType } from '../../../models/CommonDocumnetsModel';
import { IData } from '../../common/TableMobile';
import { IQuoteModel } from '../../../models/QuotesModel';
import { DocumentsModel } from '../../../models/DocumentsModel';
import { BudgetModel } from '../../../models/BudgetModel';
import { IAmendmentModel } from '../../../models/AmendmentModel';
import { useContext } from 'react';
import { IAppContext } from '../../../typings/IApp';
import { AppContext } from '../../../contexts/AppContextProvider';

interface IProps {
  items: CommonDocumentsType[];
  handleOpenPreview: ({ itemId }: { itemId: number }) => void;
  handleOpenApprove: ({ itemId }: { itemId: number }) => void;
  handleOpenSign: ({ itemId }: { itemId: number }) => void;
  handleOpenDelete: ({ itemId }: { itemId: number }) => void;
  handleOpenArchive: ({ itemId }: { itemId: number }) => void;
  handleOpenUpload: ({ itemId }: { itemId: number }) => void;
  authDownloadFile: (file: any, fileName?: string) => void;
  amendmentDownloadFile: (file: any, id: number, fileName: string) => void;
}
const useMobileData = ({
  items,
  handleOpenPreview,
  handleOpenApprove,
  handleOpenSign,
  handleOpenDelete,
  handleOpenArchive,
  handleOpenUpload,
  authDownloadFile,
  amendmentDownloadFile,
}: IProps) => {
  const { t } = useTranslation();
  const {
    app: { user },
  } = useContext<IAppContext>(AppContext);
  const pageTypeConfig = useGetPageTypeConfig();

  const getQuoteConfig = (item: IQuoteModel): any => {
    return [
      { title: t('requests.view.card.info.property'), description: item.property.title, type: 'string' },
      { title: t('project.details.table.project'), description: item.property.project.title, type: 'string' },
      {
        title: '№',
        description: item.number,
        warning: {
          title: <span style={{ color: '#EB3B3B', fontSize: '18px' }}>{t('common.popover.attention.file.title')}</span>,
          content: (
            <div style={{ width: '200px' }}>
              <p>{t('common.popover.attention.file.content')}</p>
            </div>
          ),
        },
        type: !item.file ? 'warning' : 'string',
      },
      { title: t('documents.table.column.description'), description: item.description, type: 'string' },
      { title: t('budget.table.sum.total'), description: item.sumWithVat, type: 'price' },
      {
        title: t('fileManagement.table.deadline'),
        description: !!item.deadline ? item.deadline : '',
        type: 'date',
      },
      { title: t('documents.table.status'), description: item.statusV2, type: 'status' },
      {
        title: t('budget.table.action'),
        description: item.file,
        type: 'button',
        previewAction: item.file ? () => handleOpenPreview({ itemId: item.id }) : false,
        id: item.id,
        status: item.statusV2,
        openByFunction: true,
        btnsInfo: [
          {
            function: () => handleOpenApprove({ itemId: item.id }),
            functionBtnTitle: t('common.title.approve.reject'),
            isNotShow: !(
              item.signatories &&
              item.signatories.findIndex(
                (signatory) =>
                  signatory.consumerId === user.id &&
                  (item.step?.code === 'agent_approve_open' ||
                    item.step?.code === 'agent_approve_close' ||
                    item.step?.code === 'client_approve') &&
                  signatory.stepCode === item.step?.code &&
                  !signatory.isDone,
              ) >= 0 &&
              !['rejected'].includes(item.statusV2.code)
            ),
          },
          {
            function: () => handleOpenSign({ itemId: item.id }),
            functionBtnTitle: t('common.title.sign'),
            isNotShow: !(
              item.signatories &&
              item.signatories.findIndex(
                (signatory) =>
                  signatory.consumerId === user.id &&
                  (item.step?.code === 'agent_sign_open' ||
                    item.step?.code === 'agent_sign_close' ||
                    item.step?.code === 'client_sign') &&
                  signatory.stepCode === item.step?.code &&
                  !signatory.isDone,
              ) >= 0 &&
              !['rejected'].includes(item.statusV2.code)
            ),
          },
          {
            function: () => handleOpenPreview({ itemId: item.id }),
            functionBtnTitle: t('budget.table.edit'),
            isNotShow: item.isSuppliers,
          },
          {
            function: () => handleOpenDelete({ itemId: item.id }),
            functionBtnTitle: t('common.title.delete'),
          },
          {
            function: () => handleOpenArchive({ itemId: item.id }),
            functionBtnTitle: t('common.title.archive'),
          },
          {
            function: () => authDownloadFile(item.file, item.fileName),
            functionBtnTitle: t('budget.table.download'),
          },
        ],
      },
    ];
  };
  const getDocumentConfig = (item: DocumentsModel): any => {
    return [
      { title: t('requests.view.card.info.property'), description: item.property.title, type: 'string' },
      { title: t('project.details.table.project'), description: item.property.project.title, type: 'string' },
      {
        title: t('documents.table.column.name'),
        description: item.title,
        warning: {
          title: <span style={{ color: '#EB3B3B', fontSize: '18px' }}>{t('common.popover.attention.file.title')}</span>,
          content: (
            <div style={{ width: '200px' }}>
              <p>{t('common.popover.attention.file.content')}</p>
            </div>
          ),
        },
        type: !item.file ? 'warning' : 'string',
      },
      { title: t('documents.table.column.description'), description: item.description, type: 'string' },
      { title: t('documents.table.status'), description: item.statusV2, type: 'status' },
      {
        title: t('budget.table.action'),
        description: item.file,
        type: 'button',
        previewAction: item.file ? () => handleOpenPreview({ itemId: item.id }) : false,
        id: item.id,
        status: item.statusV2,
        openByFunction: true,
        btnsInfo: [
          {
            function: () => handleOpenApprove({ itemId: item.id }),
            functionBtnTitle: t('common.title.approve.reject'),
            isNotShow: !(
              item.signatories &&
              item.signatories.findIndex(
                (signatory) =>
                  signatory.consumerId === user.id &&
                  (item.step?.code === 'agent_approve_open' ||
                    item.step?.code === 'agent_approve_close' ||
                    item.step?.code === 'client_approve') &&
                  signatory.stepCode === item.step?.code &&
                  !signatory.isDone,
              ) >= 0 &&
              !['rejected'].includes(item.statusV2.code)
            ),
          },
          {
            function: () => handleOpenSign({ itemId: item.id }),
            functionBtnTitle: t('common.title.sign'),
            isNotShow: !(
              item.signatories &&
              item.signatories.findIndex(
                (signatory) =>
                  signatory.consumerId === user.id &&
                  (item.step?.code === 'agent_sign_open' ||
                    item.step?.code === 'agent_sign_close' ||
                    item.step?.code === 'client_sign') &&
                  signatory.stepCode === item.step?.code &&
                  !signatory.isDone,
              ) >= 0 &&
              !['rejected'].includes(item.statusV2.code)
            ),
          },
          {
            function: () => handleOpenUpload({ itemId: item.id }),
            functionBtnTitle: t('budget.table.edit'),
          },
          {
            function: () => handleOpenDelete({ itemId: item.id }),
            functionBtnTitle: t('common.title.delete'),
          },
          {
            function: () => handleOpenArchive({ itemId: item.id }),
            functionBtnTitle: t('common.title.archive'),
          },
          {
            function: () => authDownloadFile(item.file, item.fileName),
            functionBtnTitle: t('budget.table.download'),
          },
        ],
      },
    ];
  };
  const getInvoiceConfig = (item: BudgetModel): any => {
    return [
      { title: t('requests.view.card.info.property'), description: item.property.title, type: 'string' },
      { title: t('project.details.table.project'), description: item.property.project.title, type: 'string' },
      {
        title: '№',
        description: item.number,
        warning: {
          title: <span style={{ color: '#EB3B3B', fontSize: '18px' }}>{t('common.popover.attention.file.title')}</span>,
          content: (
            <div style={{ width: '200px' }}>
              <p>{t('common.popover.attention.file.content')}</p>
            </div>
          ),
        },
        type: !item.file ? 'warning' : 'string',
      },
      { title: t('documents.table.column.description'), description: item.description, type: 'string' },
      { title: t('budget.table.sum.total'), description: item.sumWithVat, type: 'price' },
      { title: t('documents.table.status'), description: item.statusV2, type: 'status' },
      {
        title: t('budget.table.action'),
        description: item.file,
        type: 'button',
        previewAction: item.file ? () => handleOpenPreview({ itemId: item.id }) : false,
        id: item.id,
        status: item.statusV2,
        openByFunction: true,
        btnsInfo: [
          {
            function: () => handleOpenApprove({ itemId: item.id }),
            functionBtnTitle: t('common.title.approve.reject'),
            isNotShow: !(
              item.signatories &&
              item.signatories.findIndex(
                (signatory) =>
                  signatory.consumerId === user.id &&
                  (item.step?.code === 'agent_approve_open' ||
                    item.step?.code === 'agent_approve_close' ||
                    item.step?.code === 'client_approve') &&
                  signatory.stepCode === item.step?.code &&
                  !signatory.isDone,
              ) >= 0 &&
              !['rejected'].includes(item.statusV2.code)
            ),
          },
          {
            function: () => handleOpenSign({ itemId: item.id }),
            functionBtnTitle: t('common.title.sign'),
            isNotShow: !(
              item.signatories &&
              item.signatories.findIndex(
                (signatory) =>
                  signatory.consumerId === user.id &&
                  (item.step?.code === 'agent_sign_open' ||
                    item.step?.code === 'agent_sign_close' ||
                    item.step?.code === 'client_sign') &&
                  signatory.stepCode === item.step?.code &&
                  !signatory.isDone,
              ) >= 0 &&
              !['rejected'].includes(item.statusV2.code)
            ),
          },
          {
            function: () => handleOpenPreview({ itemId: item.id }),
            functionBtnTitle: t('budget.table.edit'),
          },
          {
            function: () => handleOpenDelete({ itemId: item.id }),
            functionBtnTitle: t('common.title.delete'),
          },
          {
            function: () => handleOpenArchive({ itemId: item.id }),
            functionBtnTitle: t('common.title.archive'),
          },
          {
            function: () => authDownloadFile(item.file, item.fileName),
            functionBtnTitle: t('budget.table.download'),
          },
        ],
      },
    ];
  };
  const getAmendmentConfig = (item: IAmendmentModel): any => {
    return [
      { title: t('requests.view.card.info.property'), description: item.property.title, type: 'string' },
      { title: t('project.details.table.project'), description: item.property.project.title, type: 'string' },
      {
        title: '№',
        description: item.number,
        warning: {
          title: <span style={{ color: '#EB3B3B', fontSize: '18px' }}>{t('common.popover.attention.file.title')}</span>,
          content: (
            <div style={{ width: '200px' }}>
              <p>{t('common.popover.attention.file.content')}</p>
            </div>
          ),
        },
        type: !item.file ? 'warning' : 'string',
      },
      { title: t('documents.table.column.description'), description: item.description, type: 'string' },
      {
        title: t('budget.table.sum.total'),
        description: item.deltaWithVat ? item.deltaWithVat : 0,
        type: 'price',
      },
      {
        title: t('fileManagement.table.deadline'),
        description: !!item.deadline ? item.deadline : '',
        type: 'date',
      },
      { title: t('documents.table.status'), description: item.statusV2, type: 'status' },
      {
        title: t('budget.table.action'),
        description: item.file,
        type: 'button',
        previewAction: item.file ? () => handleOpenPreview({ itemId: item.id }) : false,
        id: item.id,
        status: item.statusV2,
        openByFunction: true,
        btnsInfo: [
          {
            function: () => handleOpenApprove({ itemId: item.id }),
            functionBtnTitle: t('common.title.approve.reject'),
            isNotShow: !(
              item.signatories &&
              item.signatories.findIndex(
                (signatory) =>
                  signatory.consumerId === user.id &&
                  (item.step?.code === 'agent_approve_open' ||
                    item.step?.code === 'agent_approve_close' ||
                    item.step?.code === 'client_approve') &&
                  signatory.stepCode === item.step?.code &&
                  !signatory.isDone,
              ) >= 0 &&
              !['rejected'].includes(item.statusV2.code)
            ),
          },
          {
            function: () => handleOpenSign({ itemId: item.id }),
            functionBtnTitle: t('common.title.sign'),
            isNotShow: !(
              item.signatories &&
              item.signatories.findIndex(
                (signatory) =>
                  signatory.consumerId === user.id &&
                  (item.step?.code === 'agent_sign_open' ||
                    item.step?.code === 'agent_sign_close' ||
                    item.step?.code === 'client_sign') &&
                  signatory.stepCode === item.step?.code &&
                  !signatory.isDone,
              ) >= 0 &&
              !['rejected'].includes(item.statusV2.code)
            ),
          },
          {
            function: () => handleOpenPreview({ itemId: item.id }),
            functionBtnTitle: t('budget.table.edit'),
          },
          {
            function: () => handleOpenDelete({ itemId: item.id }),
            functionBtnTitle: t('common.title.delete'),
          },
          {
            function: () => handleOpenArchive({ itemId: item.id }),
            functionBtnTitle: t('common.title.archive'),
          },
          {
            function: () => amendmentDownloadFile(item.file, item.id, item.fileName),
            functionBtnTitle: t('budget.table.download'),
          },
        ],
      },
    ];
  };

  const getDataForType = (): IData[][] => {
    if (items.length > 0) {
      return items.map((item) => {
        if (pageTypeConfig.isAmendments) {
          return getAmendmentConfig(item as IAmendmentModel);
        }
        if (pageTypeConfig.isDocuments) {
          return getDocumentConfig(item as DocumentsModel);
        }
        if (pageTypeConfig.isInvoices) {
          return getInvoiceConfig(item as BudgetModel);
        }
        return getQuoteConfig(item as IQuoteModel);
      });
    }
    return [];
  };

  return { getDataForType };
};

export default useMobileData;
