import { FileOutlined, FileSearchOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../assets/styles/details.module.css';
import config from '../../../config';
import { PropertyModel, PlanModel } from '../../../models/PropertyModel';
import downloadIcon from '../../../assets/images/ic-download-dark.svg';
import PreviewPDF from './PreviewPDF';
import ConfirmDeletePlan from './ConfirmDeletePlan';
import CreatePlan from './CreatePlan';
import { AppContext } from '../../../contexts/AppContextProvider';
import ProjectDetailsTable from './ProjectDetailsTable';
import { IAppContext } from '../../../typings/IApp';

interface ProjectDetailsProps {
  propertyInfo: PropertyModel | null;
  setIsNeedUpdate?: (b: boolean) => void;
}

const ProjectDetailsMobile = (props: ProjectDetailsProps) => {
  const { propertyInfo, setIsNeedUpdate } = props;
  const { t } = useTranslation();
  const [currentPlan, setCurrentPlan] = useState<PlanModel | null>(null);
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const {
    app: { isConsumer },
  } = useContext<IAppContext>(AppContext);
  if (!propertyInfo) {
    return null;
  }

  const openPreview = (plan: PlanModel) => {
    if (plan.name.slice(-3) === 'pdf') {
      setCurrentPlan(plan);
      setIsOpenPreview(true);
    } else {
      let src = `${config.storeUrl}/${plan.name}`;
      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);
      imgWindow?.document.write(image.outerHTML);
    }
  };
  const closePreview = () => {
    setCurrentPlan(null);
    setIsOpenPreview(false);
  };

  const closeDelete = () => {
    setCurrentPlan(null);
    setIsOpenDelete(false);
    setIsNeedUpdate && setIsNeedUpdate(true);
  };

  return (
    <div className={css['project-details-container']}>
      {isOpenPreview && <PreviewPDF isOpen={isOpenPreview} plan={currentPlan} setClose={closePreview} />}
      {isOpenDelete && (
        <ConfirmDeletePlan
          isOpen={isOpenDelete}
          plan={currentPlan}
          setClose={closeDelete}
          setIsNeedUpdate={setIsNeedUpdate}
        />
      )}
      {isOpenCreate && (
        <CreatePlan isOpen={isOpenCreate} setIsNeedUpdate={setIsNeedUpdate} setClose={setIsOpenCreate} />
      )}
      <span className={css['project-details-title']}>{t('project.details.project.title')}</span>
      <ProjectDetailsTable t={t} propertyInfo={propertyInfo} />
      <span>{t('project.details.floor.plan')}</span>
      {!isConsumer && (
        <Button onClick={() => setIsOpenCreate(true)} className="common-blue-btn" style={{ margin: '1rem 0' }}>
          {t('budget.upload.attachment.btn.add')}
        </Button>
      )}
      <div style={{ flex: 2, overflowY: 'auto' }}>
        <div className={css['project-details-floor-plan']}>
          {propertyInfo.plans.map((plan: PlanModel) => (
            <div
              key={plan.id}
              style={{ display: 'flex', alignItems: 'center', marginTop: '1rem', justifyContent: 'space-between' }}>
              <FileOutlined style={{ marginRight: '1rem' }} />
              {`${plan.name.slice(0, 20)}...`}
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '3rem' }}>
                <FileSearchOutlined
                  onClick={() => {
                    openPreview(plan);
                  }}
                  style={{ marginRight: '1rem' }}
                />
                <Upload
                  className="download-budget-item"
                  iconRender={() => <></>}
                  showUploadList={{ showRemoveIcon: false }}
                  style={{ height: '100%', backgroundColor: 'inherit' }}
                  maxCount={1}
                  itemRender={() => (
                    <a href={`${config.storeUrl}/${plan.name}`} target={'_blank'} download>
                      <img src={downloadIcon} alt="" style={{ height: '15px', width: '15px' }} />
                    </a>
                  )}
                  beforeUpload={() => false}
                  fileList={[
                    {
                      name: t('budget.table.download'),
                      uid: plan.name,
                      url: `${config.storeUrl}/${plan.name}`,
                    },
                  ]}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectDetailsMobile;
