import { Button, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IModalSaveForUnmount {
  isVisible: boolean;
  handleClose: () => void;
  handleSave: () => void;
  handleContinue: () => void;
}

const ModalSaveForUnmount = ({ isVisible, handleClose, handleSave, handleContinue }: IModalSaveForUnmount) => {
  const { t } = useTranslation();
  return (
    <Modal
      visible={isVisible}
      closable={false}
      centered
      footer={
        <div className="common-modal-footer">
          <Button className="modalSaveForUnmount-btn-save common-modal-btn" onClick={handleSave}>
            {t('modalSaveForUnmount.btn.save')}
          </Button>
          <Button className="modalSaveForUnmount-btn-cancle" onClick={handleClose}>
            {t('modalSaveForUnmount.btn.cancle')}
          </Button>
          <Button className="modalSaveForUnmount-btn-continue common-primary-btn" onClick={handleContinue}>
            {t('modalSaveForUnmount.btn.continue')}
          </Button>
        </div>
      }
      maskClosable={false}>
      <p>{t('modalSaveForUnmount.message')}</p>
    </Modal>
  );
};

export default ModalSaveForUnmount;
