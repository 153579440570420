import i18n from '../utils/i18n';

export const navGroups = [
  {
    code: 'managing_projects',
    description: i18n.t('nav.group.managingProjects').toUpperCase(),
  },
  {
    code: 'customizing_projects',
    description: i18n.t('nav.group.customizingProjects').toUpperCase(),
  },
  {
    code: 'settings',
    description: i18n.t('nav.group.settings').toUpperCase(),
  },
];

i18n.on('languageChanged', () => {
  navGroups[0].description = i18n.t('nav.group.managingProjects').toUpperCase();
  navGroups[1].description = i18n.t('nav.group.customizingProjects').toUpperCase();
  navGroups[2].description = i18n.t('nav.group.settings').toUpperCase();
});
