import React, { useContext, useEffect, useState } from 'react';
import css from '../../../assets/styles/budget.module.css';
import { Table, Spin, Button, notification, TablePaginationConfig } from 'antd';
import BudgetService from '../../../services/BudgetService';
import { getBudgetColumns } from './BudgetColumns';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../contexts/AppContextProvider';
import InvoiceForm from './InvoiceForm';
import { BudgetInfoModel, BudgetModel } from '../../../models/BudgetModel';
import BudgetControl from './BudgetControl';
import { generateFilterfromObj } from '../../../helpers/generateFIlterfromObj';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import DeleteInvoiceForm from './DeleteInvoiceForm';
import InvoiceStatusForm from './UploadInvoice/InvoiceStatusForm';
import ArchivesInvoices from './ArchivesInvoices/ArchivesInvoices';
import ArchiveConfirmForm from './ArchivesInvoices/ArchiveConfirmForm';
import PreviewPDF from './PreviewPDF';
import { useLessThen801 } from '../../../helpers/mediaDetect';
import TableMobile from '../../common/TableMobile';
import { FiltersContext } from '../../../contexts/FiltersContextProvider';
import { FilterTypes } from '../../../constants/filterTypes';
import filterIcon from '../../../assets/images/ic-filter.svg';
import filterActiveIcon from '../../../assets/images/ic-filter-active.svg';
import UploadFileLoader from '../../common/UploadFileLoader';
import { useHistory } from 'react-router';
import CommonApproveForm from '../../common/CommonApproveForm';
import { getStorageEnvelopeId, setStorageEnvelopeId } from '../../../helpers/envelopeStorage';
import SigningConfirmForm from '../../common/SigningConfirmForm';
import { docusignEventStatuses } from '../../../constants/docusignEventStatuses';
import { getUriSearchValue } from '../../../helpers/proccessUri';
import moment from 'moment';
import CommonHistoryModal from '../../common/CommonHistoryModal';
import { SET_INVOICES_FILTERS } from '../../../constants/actionTypes/filtersConstants';
import { isEmptyObject } from '../../../helpers/isEmptyObject';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { IHistoryModel } from '../../../models/CommonDocumnetsModel';
import { IAppContext } from '../../../typings/IApp';
import { authDownloadFile } from '../../../helpers/authFileTools';

const service = new BudgetService();

interface BudgetListInfoProps {
  propertyId: number;
  setIsNeedUpdate?: (b: boolean) => void;
  setIsOpenUpload?: (b: boolean) => void;
  isCreatedInvoice?: boolean;
  setIsCreatedInvoice?: (b: boolean) => void;
  isOpenStatusForm?: boolean;
  setIsOpenStatusForm?: (b: boolean) => void;
  setCurrentInvoice?: (v: number | null) => void;
  currentInvoice?: number | null;
}

const BudgetListInfo = (props: BudgetListInfoProps) => {
  const {
    propertyId,
    setIsNeedUpdate,
    setIsOpenUpload,
    isCreatedInvoice,
    setIsCreatedInvoice,
    setIsOpenStatusForm,
    isOpenStatusForm,
    setCurrentInvoice,
    currentInvoice,
  } = props;
  const {
    filters: { invoicesFilter },
    filtersDispatch,
  } = useContext(FiltersContext);
  const [budgetInfo, setBudgetInfo] = useState<BudgetInfoModel>([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenArchives, setIsOpenArchives] = useState(false);
  const [isOpenUploadLoader, setIsOpenUploadLoader] = useState(false);
  const [isOpenApprove, setIsOpenApprove] = useState(false);
  const [isOpenSigningConfirm, setIsOpenSigningConfirm] = useState(false);
  const [signInfo, setSignInfo] = useState<string | null | undefined>(null);
  const [currentFilters, setCurrentFilters] = useState({
    'status_v2/code': {
      type: FilterTypes.SELECT,
      value: invoicesFilter.status,
    },
    paymentDate: {
      type: FilterTypes.DATE_RANGE,
      value: invoicesFilter.dateRange,
    },
  });
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [isOpenArchiveConfirm, setIsOpenArchiveConfirm] = useState(false);
  const [currentInvoiceNumber, setCurrentInvoiceNumber] = useState<string | null>(null);
  const { height: windowHeight } = useWindowDimensions();
  const {
    app: { isConsumer, user },
  } = useContext<IAppContext>(AppContext);
  const history = useHistory();
  const { t } = useTranslation();
  const [fetchingData, setFetchingData] = useState(false);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const event = getUriSearchValue(location.search, 'event');
  const [isDocusignConfirm, setIsDocusignConfirm] = useState(
    !!event && event === docusignEventStatuses.signingComplete,
  );
  const [isDocusignDecline, setIsDocusignDecline] = useState(!!event && event === docusignEventStatuses.decline);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    skip: 0,
  });

  const option = {
    count: true,
    filters: {
      filter: generateFilterfromObj(currentFilters).filter,
    },
    top: pagination.pageSize,
    current: pagination.current,
    orderBy: ['createdDate desc'],
  };

  //history
  const [isOpenHistory, setIsOpenHistory] = useState(false);
  const [historyArray, setHistoryArray] = useState<IHistoryModel>([]);
  const [currentItem, setCurrentItem] = useState<BudgetModel | string | null>(null);

  useEffect(() => {
    if (isCreatedInvoice) {
      getBudgetInfo(option);
      setIsCreatedInvoice && setIsCreatedInvoice(false);
    }
  }, [isCreatedInvoice]);

  useEffect(() => {
    if (!location.search || isDocusignConfirm) {
      getLocalFilters();
      getBudgetInfo(option);
    }
  }, [propertyId, isDocusignConfirm]);

  const getLocalFilters = () => {
    const localFilters = localStorage.getItem('invoicesFilter');
    const filters = localFilters && JSON.parse(localFilters);
    if (filters) {
      option.filters = generateFilterfromObj(
        applyNewFilter({
          'status_v2/code': {
            type: FilterTypes.SELECT,
            value: filters.status,
          },
        }),
      );
      filtersDispatch({
        type: SET_INVOICES_FILTERS,
        filters: {
          status: filters.status,
        },
      });
    }
  };

  useEffect(() => {
    const envelopeId = getStorageEnvelopeId();
    if (isDocusignConfirm) {
      setFetchingData(true);
      if (!envelopeId) {
        setFetchingData(false);
        setIsDocusignConfirm(false);
      } else {
        service
          .confirmStep(envelopeId)
          .catch((e) => console.error(e))
          .finally(() => {
            setIsDocusignConfirm(false);
            setStorageEnvelopeId('');
            history.replace({ search: '' });
          });
      }
    } else if (isDocusignDecline) {
      setFetchingData(true);
      if (!envelopeId) {
        setFetchingData(false);
        setIsDocusignDecline(false);
      } else {
        service
          .rejectInvoice(null, '', envelopeId)
          .catch((e) => console.error(e))
          .finally(() => {
            setIsDocusignDecline(false);
            setStorageEnvelopeId('');
            history.replace({ search: '' });
          });
      }
    }

    if (location.search.includes('event')) {
      history.replace({ search: '' });
    }
  }, []);

  const checkIsDelete = (items: BudgetInfoModel) => {
    if (location.search && items.length === 0) {
      notification.warning({
        message: t('common.warning.notification.file.deleted'),
      });
      history.push(location.pathname);
    }
  };

  const getBudgetInfo = (option: { [name: string]: any }) => {
    setFetchingData(true);
    service
      .getBudgetNoArchived(propertyId, option)
      .then((res) => {
        const { count, items } = res;
        const { current, top } = option;
        setPagination({ ...pagination, current, total: count, pageSize: top });
        setBudgetInfo(items);
        checkIsDelete(items);
      })
      .catch((e) => console.log(e))
      .finally(() => setFetchingData(false));
  };

  const loadMore = (option: { [name: string]: any }) => {
    setFetchingData(true);
    option.skip = option.skip ? option.skip + 10 : 10;
    service
      .getBudgetNoArchived(propertyId, option)
      .then((res) => {
        const { count, items } = res;
        const { current, top } = option;
        setPagination({ ...pagination, current, total: count, pageSize: top });
        setBudgetInfo([...budgetInfo, ...items]);
      })
      .catch((e) => console.log(e))
      .finally(() => setFetchingData(false));
  };

  const setOpen = (id?: number) => {
    setIsOpenModal(true);
    if (id) {
      setCurrentId(id);
    }
  };

  const setOpenStatus = (id?: number) => {
    setIsOpenStatusForm && setIsOpenStatusForm(true);
    if (id) {
      setCurrentId(id);
    }
  };

  const setCloseStatus = () => {
    setIsOpenStatusForm && setIsOpenStatusForm(false);
    setCurrentId(null);
    getBudgetInfo(option);
  };

  const setClose = () => {
    setIsOpenModal(false);
    setCurrentId(null);
  };

  const setOpenDelete = (id?: number, invoiceNumber?: string) => {
    setIsOpenDeleteModal(true);
    if (id) {
      setCurrentId(id);
    }
    if (invoiceNumber) {
      setCurrentInvoiceNumber(invoiceNumber);
    }
  };

  const setCloseDelete = () => {
    setIsOpenDeleteModal(false);
    setCurrentId(null);
    setCurrentInvoiceNumber(null);
  };

  const openArchiveConfirm = (id?: number, invoiceNumber?: string) => {
    setIsOpenArchiveConfirm(true);
    if (id) {
      setCurrentId(id);
    }
    if (invoiceNumber) {
      setCurrentInvoiceNumber(invoiceNumber);
    }
  };

  const closeArchiveConfirm = () => {
    setIsOpenArchiveConfirm(false);
    setCurrentId(null);
    getBudgetInfo(option);
  };

  const openPreview = (id?: number) => {
    setIsOpenPreview(true);
    if (id) {
      setCurrentId(id);
    }
  };

  const closePreview = () => {
    setIsOpenPreview(false);
    setCurrentId(null);
    getBudgetInfo(option);
  };

  const closeUploadLoader = () => {
    setIsOpenUploadLoader(false);
    getBudgetInfo(option);
  };

  const openSigningConfirm = (id?: number, signInfo?: string) => {
    setIsOpenSigningConfirm(true);
    if (id) {
      setCurrentId(id);
      setSignInfo(signInfo);
    }
  };

  const closeSigningConfirm = () => {
    setIsOpenSigningConfirm(false);
    setCurrentId(null);
    setSignInfo(null);
    getBudgetInfo(option);
  };

  const signItem = () => {
    localStorage.setItem('invoicesFilter', JSON.stringify(invoicesFilter));
    if (currentId) {
      setFetchingData(true);
      service
        .getSigningUrl(currentId, window.location.href, user.id)
        .then((res) => {
          const { alreadySigned = false, declined = false } = res;
          if (!alreadySigned && !declined) {
            setStorageEnvelopeId(res.envelopeId);
            window.location.href = res.redirectUrl;
          } else {
            if (alreadySigned) {
              notification.warning({
                message: t('invoice.modal.already.signed.message'),
              });
            }
            if (declined) {
              notification.warning({
                message: t('invoice.modal.already.decline.message'),
              });
            }
            setFetchingData(false);
          }
        })
        .catch((e) => {
          notification.error({
            message: e.message,
          });
          setFetchingData(false);
        })
        .finally(() => {
          setClose();
        });
    }
  };

  const rejectItem = (iD: number | null, comment: string, isApprove: boolean = false) => {
    const id = iD || currentId;
    if (id) {
      service
        .rejectInvoice(id, comment)
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        )
        .finally(() => {
          isApprove ? closeApprove() : closeSigningConfirm();
        });
    }
  };

  const openApprove = (id: number) => {
    setCurrentId(id);
    setIsOpenApprove(true);
  };

  const closeApprove = () => {
    setCurrentId(null);
    setIsOpenApprove(false);
    getBudgetInfo(option);
  };

  const approveItem = () => {
    setFetchingData(true);
    if (currentId) {
      service
        .confirmStep(undefined, currentId)
        .then(() => {
          notification.success({
            message: t('fileManagement.invoice.approved'),
          });
          closeApprove();
        })
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        )
        .finally(() => setFetchingData(false));
    }
  };

  const data =
    budgetInfo.length > 0 &&
    (budgetInfo.map((item) => [
      {
        title: '№',
        description: item.number,
        warning: {
          title: <span style={{ color: '#EB3B3B', fontSize: '18px' }}>{t('common.popover.attention.file.title')}</span>,
          content: (
            <div style={{ width: '200px' }}>
              <p>{t('common.popover.attention.file.content')}</p>
            </div>
          ),
        },
        type: !item.file ? 'warning' : 'string',
      },
      { title: t('documents.table.column.description'), description: item.description, type: 'string' },
      { title: t('budget.table.sum.total'), description: item.sumWithVat, type: 'price' },
      { title: t('budget.table.status'), description: item.statusV2, type: 'status' },
      {
        title: t('budget.table.action'),
        description: item.file,
        type: 'button',
        previewAction: !!item.file && openPreview,
        id: item.id,
        signatories: item.signatories,
        comment: item.comment || item.signInfo,
        status: item.statusV2,
        openByFunction: true,
        btnsInfo: [
          {
            function: () => (item.items.length > 0 ? setOpenStatus(item.id) : setOpen(item.id)),
            functionBtnTitle: t('budget.table.edit'),
            isNotShow: isConsumer,
          },
          {
            function: () => openApprove(item.id),
            functionBtnTitle: t('common.title.approve.reject'),
            isNotShow: !(
              item.signatories &&
              item.signatories.findIndex(
                (signatory) =>
                  signatory.consumerId === user.id &&
                  (item.step?.code === 'agent_approve_open' ||
                    item.step?.code === 'agent_approve_close' ||
                    item.step?.code === 'client_approve') &&
                  signatory.stepCode === item.step?.code &&
                  !signatory.isDone,
              ) >= 0 &&
              !['rejected'].includes(item.statusV2.code)
            ),
          },
          {
            function: () => openSigningConfirm(item.id),
            functionBtnTitle: t('common.title.sign'),
            isNotShow: !(
              item.signatories &&
              item.signatories.findIndex(
                (signatory) =>
                  signatory.consumerId === user.id &&
                  (item.step?.code === 'agent_sign_open' ||
                    item.step?.code === 'agent_sign_close' ||
                    item.step?.code === 'client_sign') &&
                  signatory.stepCode === item.step?.code &&
                  !signatory.isDone,
              ) >= 0 &&
              !['rejected'].includes(item.statusV2.code)
            ),
          },
          {
            function: () => (item.flow ? openHistory(item.id, item.number, item) : openHistory(item.id, item.number)),
            functionBtnTitle: t('fileManagement.history.title'),
            isNotShow: isConsumer,
          },
          {
            function: () => authDownloadFile(item.file, item.fileName),
            functionBtnTitle: t('budget.table.download'),
          },
        ],
      },
    ]) as any);

  const applyNewFilter = (filters: { [name: string]: any }) => {
    setCurrentFilters({ ...currentFilters, ...filters });
    return { ...currentFilters, ...filters };
  };

  const onTableChange = (
    pagination?: TablePaginationConfig,
    filters?: Record<string, FilterValue | null | any>,
    sorter?: SorterResult<BudgetModel> | SorterResult<BudgetModel>[],
  ) => {
    let params: { [name: string]: any } = {
      ...option,
    };

    if (filters && !isEmptyObject(filters)) {
      params = {
        ...params,
        filters: generateFilterfromObj(applyNewFilter(filters)),
      };
    } else {
      params = {
        ...params,
        filters: generateFilterfromObj(currentFilters),
      };
    }
    if (pagination && pagination.pageSize && pagination.current) {
      params = {
        ...params,
        top: pagination.pageSize,
        skip: pagination.pageSize * (pagination.current - 1),
        current: pagination.current,
      };
    } else {
      params = {
        ...params,
        skip: 0,
        current: 1,
      };
    }
    if (sorter && !Array.isArray(sorter) && sorter.order) {
      const { order, columnKey } = sorter;
      switch (columnKey) {
        case 'paymentDate': {
          params = {
            ...params,
            orderBy: `paymentDate ${order === 'ascend' ? 'asc' : 'desc'}`,
          };
          break;
        }
        case 'sumWithVat': {
          params = {
            ...params,
            orderBy: `sumWithVat ${order === 'ascend' ? 'asc' : 'desc'}`,
          };
          break;
        }
        default: {
          return;
        }
      }
    }
    getBudgetInfo(params);
  };

  const changeOpenFilter = () => {
    setIsOpenFilter(!isOpenFilter);
  };

  const openHistory = (id: number, title?: string, item?: BudgetModel) => {
    if (item) {
      if (item.statusV2.code === 'rejected') {
        setFetchingData(true);
        service
          .getInvoiceHistory(id)
          .then((value) => {
            setIsOpenHistory(true);
            setCurrentId(id);
            setHistoryArray(value);
            setCurrentItem(item);
          })
          .catch((e) =>
            notification.error({
              message: e.message,
            }),
          )
          .finally(() => setFetchingData(false));
        return;
      } else {
        setIsOpenHistory(true);
        setCurrentItem(item);
        return;
      }
    }
    setFetchingData(true);
    service
      .getInvoiceHistory(id)
      .then((value) => {
        setIsOpenHistory(true);
        setCurrentId(id);
        setHistoryArray(value);
        setCurrentItem(title || null);
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingData(false));
  };

  const closeHistory = () => {
    setCurrentId(null);
    setIsOpenHistory(false);
    setCurrentItem(null);
  };

  if (useLessThen801()) {
    return (
      <>
        <div className="common-mobile-title-container">
          <span style={{ fontSize: '20px' }}>{t('consumers.manage.tabs.budget')}</span>
          <div className="common-modbile-filter-container" onClick={changeOpenFilter}>
            <img src={isOpenFilter ? filterActiveIcon : filterIcon} width={16} />
            <span className="filter-title">{isOpenFilter ? t('common.filter.close') : t('common.filter.open')}</span>
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: isOpenFilter ? '0' : '1rem' }}>
          {!isConsumer && (
            <Button
              className={`common-blue-border-btn common-secondary-btn`}
              onClick={() => setIsOpenModal(true)}
              style={{ width: '100%' }}>
              <span>{t('budget.table.upload.invoce')}</span>
            </Button>
          )}
        </div>
        {isOpenFilter && (
          <BudgetControl
            pagination={pagination}
            onTableChange={onTableChange}
            setIsOpenModal={setIsOpenModal}
            propertyId={propertyId}
            isConsumer={isConsumer}
            setIsOpenUpload={setIsOpenUpload}
          />
        )}
        <div className="common-flex-end-style" style={{ marginBottom: '1rem' }}>
          <span onClick={() => setIsOpenArchives(true)} className={`common-archive-icon-item common-animation-primary`}>
            <div className={css['archive-img']} style={{ marginRight: '0.5rem', width: '18px', height: '14px' }}></div>
            <span style={{ color: '#076ee5', marginRight: '0.5rem', textDecoration: 'underline' }}>
              {t('common.archive.title')}
            </span>
          </span>
        </div>
        <TableMobile
          data={data}
          isLoading={fetchingData}
          loadMore={loadMore}
          option={option}
          itemsLength={budgetInfo.length}
          totalItems={pagination.total}
        />
        {isOpenModal && (
          <InvoiceForm
            setIsNeedUpdate={setIsNeedUpdate}
            setClose={setClose}
            propertyId={propertyId}
            getBudget={() => getBudgetInfo(option)}
            currentId={currentId}
            isOpenModal={isOpenModal}
            setIsOpenUploadLoader={setIsOpenUploadLoader}
            setIsUploadingFile={setIsUploadingFile}
          />
        )}
        {isOpenArchives && (
          <ArchivesInvoices
            isOpen={isOpenArchives}
            setIsOpen={setIsOpenArchives}
            propertyId={propertyId}
            getInvoice={() => getBudgetInfo(option)}
            isConsumer={isConsumer}
          />
        )}
        {isOpenStatusForm && (
          <InvoiceStatusForm
            isOpen={isOpenStatusForm}
            id={currentId}
            setCloseStatus={setCloseStatus}
            currentInvoice={currentInvoice}
            setCurrentInvoice={setCurrentInvoice}
            setIsNeedUpdate={setIsNeedUpdate}
            propertyId={propertyId}
          />
        )}
        {isOpenPreview && <PreviewPDF id={currentId} isOpen={isOpenPreview} setClose={closePreview} />}
        {isOpenUploadLoader && (
          <UploadFileLoader isOpen={isOpenUploadLoader} isLoading={isUploadingFile} setClose={closeUploadLoader} />
        )}
        {isOpenSigningConfirm && (
          <SigningConfirmForm
            id={currentId}
            isOpen={isOpenSigningConfirm}
            setClose={closeSigningConfirm}
            sign={signItem}
            reject={rejectItem}
            fetchingData={fetchingData}
            signInfo={signInfo}
          />
        )}
        {isOpenApprove && (
          <CommonApproveForm
            approve={approveItem}
            isOpen={isOpenApprove}
            reject={(id, comment) => rejectItem(id, comment, true)}
            setClose={closeApprove}
            fetchingData={fetchingData}
            type={'invoice'}
          />
        )}
        {isOpenHistory && (
          <CommonHistoryModal
            fetchingData={fetchingData}
            setFetchingData={setFetchingData}
            isOpen={isOpenHistory}
            items={historyArray}
            setClose={closeHistory}
            currentItem={currentItem}
          />
        )}
      </>
    );
  }

  return (
    <div className={css['table-container']}>
      <Spin spinning={fetchingData}>
        {isOpenStatusForm && (
          <InvoiceStatusForm
            isOpen={isOpenStatusForm}
            id={currentId}
            setCloseStatus={setCloseStatus}
            currentInvoice={currentInvoice}
            setCurrentInvoice={setCurrentInvoice}
            setIsNeedUpdate={setIsNeedUpdate}
            propertyId={propertyId}
          />
        )}
        <BudgetControl
          pagination={pagination}
          onTableChange={onTableChange}
          setIsOpenModal={setIsOpenModal}
          propertyId={propertyId}
          isConsumer={isConsumer}
          setIsOpenUpload={setIsOpenUpload}
        />
        <Table
          columns={getBudgetColumns({
            t,
            setOpen,
            isConsumer,
            setOpenDelete,
            setOpenStatus,
            openArchiveConfirm,
            openPreview,
            openSigningConfirm,
            openApprove,
            consumerId: user.id,
            openHistory,
          })}
          size="small"
          className="list-info-table"
          dataSource={budgetInfo}
          showHeader={true}
          pagination={pagination}
          scroll={{ y: isConsumer ? windowHeight - 330 : windowHeight - 360 }}
          onChange={onTableChange}
          rowClassName={(record) =>
            `common-table-row--pointer ${
              (!record.file ||
                (moment(record.paymentDate).isBefore(moment()) &&
                  !['signed', 'rejected', 'done', 'canceled'].includes(record.statusV2.code))) &&
              'common-table-row--error-management'
            }`
          }
          rowKey="id"
        />
        <div className="common-flex-end-style">
          <span onClick={() => setIsOpenArchives(true)} className={`common-archive-icon-item common-animation-primary`}>
            <div className={css['archive-img']} style={{ marginRight: '0.5rem', width: '18px', height: '14px' }}></div>
            <span style={{ color: '#076ee5', marginRight: '0.5rem', textDecoration: 'underline' }}>
              {t('common.archive.title')}
            </span>
          </span>
        </div>
        {isOpenModal && (
          <InvoiceForm
            setIsNeedUpdate={setIsNeedUpdate}
            setClose={setClose}
            propertyId={propertyId}
            getBudget={() => getBudgetInfo(option)}
            currentId={currentId}
            isOpenModal={isOpenModal}
            setIsOpenUploadLoader={setIsOpenUploadLoader}
            setIsUploadingFile={setIsUploadingFile}
          />
        )}
        {isOpenDeleteModal && (
          <DeleteInvoiceForm
            setIsNeedUpdate={setIsNeedUpdate}
            setClose={setCloseDelete}
            invoiceNumber={currentInvoiceNumber}
            getBudget={() => getBudgetInfo(option)}
            currentId={currentId}
            isOpen={isOpenDeleteModal}
          />
        )}
        {isOpenArchives && (
          <ArchivesInvoices
            isOpen={isOpenArchives}
            setIsOpen={setIsOpenArchives}
            propertyId={propertyId}
            getInvoice={() => getBudgetInfo(option)}
            isConsumer={isConsumer}
          />
        )}
        {isOpenArchiveConfirm && (
          <ArchiveConfirmForm
            id={currentId}
            invoiceNumber={currentInvoiceNumber}
            isOpen={isOpenArchiveConfirm}
            setClose={closeArchiveConfirm}
            setIsNeedUpdate={setIsNeedUpdate}
          />
        )}
        {isOpenPreview && <PreviewPDF id={currentId} isOpen={isOpenPreview} setClose={closePreview} />}
        {isOpenUploadLoader && (
          <UploadFileLoader isOpen={isOpenUploadLoader} isLoading={isUploadingFile} setClose={closeUploadLoader} />
        )}
        {isOpenSigningConfirm && (
          <SigningConfirmForm
            id={currentId}
            isOpen={isOpenSigningConfirm}
            setClose={closeSigningConfirm}
            sign={signItem}
            reject={rejectItem}
            fetchingData={fetchingData}
            signInfo={signInfo}
          />
        )}
        {isOpenApprove && (
          <CommonApproveForm
            approve={approveItem}
            isOpen={isOpenApprove}
            reject={(id, comment) => rejectItem(id, comment, true)}
            setClose={closeApprove}
            fetchingData={fetchingData}
            type={'invoice'}
          />
        )}
        {isOpenHistory && (
          <CommonHistoryModal
            fetchingData={fetchingData}
            setFetchingData={setFetchingData}
            isOpen={isOpenHistory}
            items={historyArray}
            setClose={closeHistory}
            currentItem={currentItem}
          />
        )}
      </Spin>
    </div>
  );
};

export default BudgetListInfo;
