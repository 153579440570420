import React from 'react';
import css from '../../assets/styles/budget.module.css';
import { IUserEasy } from '../../models/UserModel';
import AmendmentListInfo from './components/AmendmentListInfo';

interface IAmendmentPage {
  propertyId: number;
  projectId: number;
  signatories: IUserEasy[];
  consumers: IUserEasy[];
  setIsOpenUploadAmendment: (b: boolean) => void;
  setIsCreatedAmendment: (b: boolean) => void;
  isOpenStatusAmendmentForm: boolean;
  isCreatedAmendment: boolean;
  setIsOpenStatusAmendmentForm: (b: boolean) => void;
  setCurrentAmendment: (v: number | null) => void;
  currentAmendment: number | null;
}

const AmendmentPage = ({
  propertyId,
  signatories,
  consumers,
  setIsOpenUploadAmendment,
  setIsCreatedAmendment,
  isOpenStatusAmendmentForm,
  isCreatedAmendment,
  setIsOpenStatusAmendmentForm,
  setCurrentAmendment,
  currentAmendment,
  projectId,
}: IAmendmentPage) => {
  return (
    <div className={css['container']}>
      <AmendmentListInfo
        propertyId={propertyId}
        projectId={projectId}
        consumers={consumers}
        signatories={signatories}
        setIsOpenUploadAmendment={setIsOpenUploadAmendment}
        setIsCreatedAmendment={setIsCreatedAmendment}
        isOpenStatusAmendmentForm={isOpenStatusAmendmentForm}
        isCreatedAmendment={isCreatedAmendment}
        setIsOpenStatusAmendmentForm={setIsOpenStatusAmendmentForm}
        setCurrentAmendment={setCurrentAmendment}
        currentAmendment={currentAmendment}
      />
    </div>
  );
};

export default AmendmentPage;
