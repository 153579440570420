import { Button, Form, Input, Modal, notification } from 'antd';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from './../../../assets/styles/login.module.css';
import { getErrorNotification } from '../Notifications';
import LoginPageServices from '../../../services/LoginPageServices';
import i18n from '../../../utils/i18n';
import ReCAPTCHA from 'react-google-recaptcha';
import config from '../../../config';

const service = new LoginPageServices();

const ModalContactForm = () => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const reRef = useRef<ReCAPTCHA | any>();
  const enableRecaptcha = config.enableRecaptcha;

  const handleClose = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleOk = () => {
    form.submit();
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleFinish = async (values: any) => {
    try {
      setIsLoading(true);
      //captcha
      let token;
      if (enableRecaptcha) {
        token = await reRef?.current?.getValue();
        reRef?.current?.reset();
      }

      values.language = i18n.language.split('-')[0];
      await service.sendContactForm(values, token).then(() => {
        notification.success({ message: t('login.contact.us.success') });
      });
      handleClose();
    } catch (e) {
      getErrorNotification(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ height: '100%', display: 'flex', justifyContent: 'flex-end' }}>
      <a type="button" className={css['login-restorePass-link']} onClick={handleOpenModal}>
        {t('login.contact.us')}
      </a>
      <Modal
        className="common-modal"
        footer={
          <div className="common-modal-footer">
            <Button loading={isLoading} onClick={handleClose} className={`common-gray-border-btn common-secondary-btn`}>
              {t('consumers.modal.cancel.btn')}
            </Button>
            <Button loading={isLoading} onClick={handleOk} className={`common-green-btn common-modal-btn`}>
              {t('calendar.add.update.btn')}
            </Button>
          </div>
        }
        closeIcon={() => null}
        centered
        visible={isModalVisible}>
        <Form autoComplete="off" form={form} layout={'vertical'} onFinish={handleFinish}>
          <Form.Item
            name={'email'}
            label={`${t('consumers.create.email')}*`}
            rules={[{ required: true, message: t('login.page.resrorePass.email.validation') }, { type: 'email' }]}>
            <Input className="common-animation-primary" style={{ borderRadius: '6px' }} autoComplete="off" />
          </Form.Item>
          <Form.Item
            name={'phone'}
            label={`${t('register.phone')}*`}
            rules={[{ required: true, message: t('login.page.contact.phone.validation') }]}>
            <Input className="consumer-form-input" />
          </Form.Item>
          {enableRecaptcha && <ReCAPTCHA sitekey={config.recaptchaPublicSiteKey} ref={reRef} />}
        </Form>
      </Modal>
    </div>
  );
};

export default ModalContactForm;
