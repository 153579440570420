import { Col, Modal, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import css from '../../../assets/styles/properties.module.css';
import propertiesGroup from '../../../assets/images/properties-group.svg';
import propertiesBuilding from '../../../assets/images/properties-building.svg';
import propertiesFlour from '../../../assets/images/properties-flour.svg';
import propertiesUnit from '../../../assets/images/properties-unit.svg';
import arrowIcon from '../../../assets/images/properties-arrow.svg';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BoardTourComponent from '../../common/BoardTourComponent';
import CustomOnBoard from '../../common/CustomOnBoard';
import { getOnboarding, OnboardingTypes, saveOnboarding } from '../../../helpers/onboarding';
import { AppContext } from '../../../contexts/AppContextProvider';
import { IAppContext } from '../../../typings/IApp';

interface IModal {
  isOpen: boolean;
  item: any;
  onClose: () => void;
  generateUrl: (item: any, isEdit: boolean, type: string) => any;
}

const ModalCreateProperty = ({ isOpen, onClose, generateUrl, item }: IModal) => {
  const { t } = useTranslation();
  const {
    app: { user },
  } = useContext<IAppContext>(AppContext);
  const [isShow, setIsShow] = useState(false);

  //board
  const [steps, _] = useState([
    {
      target: '.property-select-first-step',
      content: (
        <BoardTourComponent title={t('property.select.first.title')} description={t('property.select.first.text')} />
      ),
      disableBeacon: true,
      placement: 'left',
      offset: 20,
    },
  ]);
  const [run, setRun] = useState(false);

  useEffect(() => {
    setTimeout(() => setRun(getOnboarding(OnboardingTypes.PROPERTY_CREATE)), 200);
  }, []);

  const getTitle = (type: string) => {
    switch (type) {
      case 'group':
        return `${t('properties.group.title')} “${item.title}”?`;
      case 'building':
        return `${t('properties.building.title')} “${item.title}”?`;
      case 'floor':
        return `${t('properties.floor.title')} “${item.title}”?`;
      default:
        return t('properties.create.title');
    }
  };

  return (
    <Modal
      className="modal-property property-select-first-step"
      visible={isOpen}
      centered
      onCancel={onClose}
      width={500}
      maskClosable={false}
      title={<span style={{ fontWeight: 600, fontSize: '18px' }}>{getTitle(item ? item.type.code : null)}</span>}
      footer={
        <div className={`${css['modal-create-btns-container']}`}>
          <CustomOnBoard
            steps={steps}
            run={run}
            isShowProgress={false}
            zIndex={9999999999}
            onFinish={() => saveOnboarding(OnboardingTypes.PROPERTY_CREATE, user.boardCheckpoint)}
          />
          {isShow && !item && (
            <div className={css['modal-create-card-container']}>
              <Row gutter={[24, 4]}>
                <Col span={8} className={css['modal-create-icon']}>
                  <img src={propertiesGroup} />
                </Col>
                <Col span={16} className={css['modal-create-card-right']}>
                  <NavLink to={generateUrl(item, false, 'group')} className={css['modal-create-btn']}>
                    {t('properties.group')} {<img className={css['modal-create-img']} src={arrowIcon} />}
                  </NavLink>
                  <span className={css['modal-create-text']}>{t('properties.group.description')}</span>
                </Col>
              </Row>
            </div>
          )}
          {(!item || item.type.code === 'group') && (
            <div className={css['modal-create-card-container']}>
              <Row gutter={[24, 4]}>
                <Col span={8} className={css['modal-create-icon']}>
                  <img src={propertiesBuilding} />
                </Col>
                <Col span={16} className={css['modal-create-card-right']}>
                  <NavLink to={generateUrl(item, false, 'building')} className={css['modal-create-btn']}>
                    {t('properties.building')} {<img className={css['modal-create-img']} src={arrowIcon} />}
                  </NavLink>
                  <span className={css['modal-create-text']}>{t('properties.building.description')}</span>
                </Col>
              </Row>
            </div>
          )}
          {isShow && (!item || item.type.code === 'building' || item.type.code === 'group') && (
            <div className={css['modal-create-card-container']}>
              <Row gutter={[24, 4]}>
                <Col span={8} className={css['modal-create-icon']}>
                  <img src={propertiesFlour} />
                </Col>
                <Col span={16} className={css['modal-create-card-right']}>
                  <NavLink to={generateUrl(item, false, 'floor')} className={css['modal-create-btn']}>
                    {t('properties.floor')} {<img className={css['modal-create-img']} src={arrowIcon} />}
                  </NavLink>
                  <span className={css['modal-create-text']}>{t('properties.floor.description')}</span>
                </Col>
              </Row>
            </div>
          )}
          {(!item || item.type.code !== 'unit') && (
            <div className={css['modal-create-card-container']}>
              <Row gutter={[24, 4]}>
                <Col span={8} className={css['modal-create-icon']}>
                  <img src={propertiesUnit} />
                </Col>
                <Col span={16} className={css['modal-create-card-right']}>
                  <NavLink to={generateUrl(item, false, 'unit')} className={css['modal-create-btn']}>
                    {t('properties.unit')} {<img className={css['modal-create-img']} src={arrowIcon} />}
                  </NavLink>
                  <span className={css['modal-create-text']}>{t('properties.unit.description')}</span>
                </Col>
              </Row>
            </div>
          )}
          {!isShow && (!item || item.type.code === 'building' || item.type.code === 'group') && (
            <a type="button" onClick={() => setIsShow(true)} style={{ textDecoration: 'underline' }}>
              {t('common.more')}
            </a>
          )}
        </div>
      }></Modal>
  );
};

export default ModalCreateProperty;
