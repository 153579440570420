import React from 'react';
import { Badge } from 'antd';
import ProductNameLayout from '../../../components/common/ProductNameLayout';
import { TFunction } from 'react-i18next';

interface ArticleProductColumnsModel {
  t: TFunction<'translation'>;
}

export const getColumns = ({ t }: ArticleProductColumnsModel) => [
  {
    title: '#',
    dataIndex: 'id',
    key: 'id',
    width: 100,
  },
  {
    title: t('product.table.columns.barcode'),
    key: 'code',
    render: (cell: any) => <ProductNameLayout product={cell} />,
    width: 200,
  },
  {
    title: t('product.table.columns.price'),
    dataIndex: 'price',
    key: 'price',
    align: 'right',
    render: (cell: any) => (isNaN(cell) ? cell : Number(cell).toFixed(2)),
    width: 200,
  } as any,
  {
    title: t('product.table.columns.isActive'),
    dataIndex: 'isActive',
    key: 'isActive',
    render: (cell: any) => {
      return cell ? (
        <Badge status="success" text={t('product.table.columns.isActive.active')} />
      ) : (
        <Badge status="error" text={t('product.table.columns.isActive.inactive')} />
      );
    },
    width: 200,
  },
];
