import { Collapse, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../../assets/styles/faq.module.css';
import { QuestionsModel } from '../../../../models/FAQModel';
import FAQService from '../../../../services/FAQService';
import parser from 'html-react-parser';
import { useLocation, useHistory } from 'react-router';
import { getValueInHash } from '../../../../helpers/getValueInHash';
import i18n from '../../../../utils/i18n';

const { Panel } = Collapse;
const service = new FAQService();

interface IResultFAQ {
  selectTopicId: number | null;
  searchingWord: string;
  resultFAQ: QuestionsModel | null;
}

const ResultFAQ = (props: IResultFAQ) => {
  const { t } = useTranslation();
  const { hash, pathname } = useLocation();
  const history = useHistory();
  const { selectTopicId, resultFAQ, searchingWord } = props;
  const [fetchingData, setFetchingData] = useState(false);
  const [questions, setQuestions] = useState<null | QuestionsModel>(null);
  const [activeKey, setActiveKey] = useState<string | string[] | undefined>(undefined);

  const option = {
    count: true,
    top: 10,
    filter: {
      topic: {
        eq: selectTopicId,
      },
      'type/code': {
        eq: null,
      },
      [i18n.language === 'en-US' ? 'isPublishedEn' : 'isPublishedFr']: { eq: true },
    },
  };

  const getFAQ = () => {
    setFetchingData(true);
    service
      .getFAQNoDefaultFilters(option)
      .then((res) => {
        const { items }: { items: QuestionsModel } = res;
        setQuestions(items);
      })
      .finally(() => setFetchingData(false));
  };

  useEffect(() => {
    if (selectTopicId) {
      getFAQ();
    }
  }, [selectTopicId, i18n.language]);

  useEffect(() => {
    if (hash && questions) {
      const variables = hash.replace('#', '').split('&');
      const questionId = getValueInHash(variables[1]);
      if (questionId) {
        setActiveKey(questions.find((item) => item.id === +questionId)?.id.toString() || undefined);
      }
    }
  }, [hash, questions]);

  const getResultHeader = (question: string, searchingWord: string) => {
    const regexp = new RegExp(searchingWord, 'ig');
    const matchValue = question.match(regexp);

    if (matchValue) {
      return question.split(regexp).map((str, index, array) => {
        if (index < array.length - 1) {
          const coincidence = matchValue.shift();
          return (
            <>
              {str}
              <span className={css['faq-result-word']}>{coincidence}</span>
            </>
          );
        }
        return str;
      });
    }
    return question;
  };

  return (
    <div className={`faq-spin ${css['faq-list-info-container']}`}>
      <Spin spinning={fetchingData}>
        {resultFAQ ? (
          <h3 className={css['faq-title']}>{t('faq.seacrh.title')}</h3>
        ) : (
          questions && questions.length > 0 && <h3 className={css['faq-title']}>{questions[0].topic.topic}</h3>
        )}
        <Collapse
          accordion
          activeKey={activeKey}
          onChange={(key) => {
            setActiveKey(key);
            if (hash) {
              history.push(pathname);
            }
          }}
          className={`faq-list-info ${resultFAQ && resultFAQ.length === 0 && css['faq-no-match']}`}
          expandIcon={(panelProps) =>
            panelProps.isActive ? (
              <div className={css['faq-item-btn']}>
                <img src={require('./../../../../assets/images/ic-minus.svg')} />
              </div>
            ) : (
              <div className={css['faq-item-btn']}>
                <img src={require('./../../../../assets/images/ic-plus.svg')} />
              </div>
            )
          }
          expandIconPosition={'right'}>
          {resultFAQ ? (
            resultFAQ.length > 0 ? (
              resultFAQ.map((el) => (
                <Panel header={<span>{getResultHeader(el.question, searchingWord)}</span>} key={el.id}>
                  <span className="parser-container">{parser(el.answer)}</span>
                </Panel>
              ))
            ) : (
              <p>
                {t('faq.no.search.match')}{' '}
                <a className={css['faq-support-email']} href="mailto:support@cmp.com">
                  {t('faq.support.email')}
                </a>
              </p>
            )
          ) : (
            questions &&
            questions.length > 0 &&
            questions.map((el) => (
              <Panel header={el.question} key={el.id}>
                <span className="parser-container">{parser(el.answer)}</span>
              </Panel>
            ))
          )}
        </Collapse>
      </Spin>
    </div>
  );
};

export default ResultFAQ;
