import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import { Badge, Button, Input, notification, Select, Table } from 'antd';
import ListPageServices from '../../services/PropertiesService';
import { getBadgeStatus, getColumns } from './components/PropertiesColumns';
import { AppContext } from '../../contexts/AppContextProvider';
import { HeaderContext } from '../../contexts/HeaderContextProvider';
import { CHANGE_CURRENT_PAGE } from '../../constants/actionTypes/headerConstants';
import css from '../../assets/styles/properties.module.css';
import SelectProjectForm from './components/SelectProjectForm';
import { PropertyContext } from '../../contexts/PropertyProvider';
import { SET_PROJECT_ID } from '../../constants/actionTypes/propertyConstants';
import { useHistory } from 'react-router';
import { FiltersContext } from '../../contexts/FiltersContextProvider';
import { SET_PROPERTIES_FILTERS } from '../../constants/actionTypes/filtersConstants';
import { useLessThen801 } from '../../helpers/mediaDetect';
import TableMobile from '../common/TableMobile';
import ModalCreateProperty from './components/ModalCreateProperty';
import BoardTourComponent from '../common/BoardTourComponent';
import CustomOnBoard from '../common/CustomOnBoard';
import searchIcon from '../../assets/images/ic-search.svg';
import { FilterTypes } from '../../constants/filterTypes';
import { generateFilterfromObj } from '../../helpers/generateFIlterfromObj';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { getOnboarding, OnboardingTypes, saveOnboarding } from '../../helpers/onboarding';
import PropertyDeleteConfirmModal from './components/PropertyDeleteConfirmModal';
import { IAppContext } from '../../typings/IApp';
import ModalImportProperties from './components/ModalImportProperties';
import i18n from '../../utils/i18n';

const { Option } = Select;
const { Search } = Input;

const PropertiesPage = (props: any) => {
  const { t } = useTranslation();
  const {
    app: { user, isSupplier },
  } = useContext<IAppContext>(AppContext);
  const {
    property: { project },
    propertyDispatch,
  } = useContext(PropertyContext);
  const {
    filters: { propertiesFilter },
    filtersDispatch,
  } = useContext(FiltersContext);
  const { height: windowHeight } = useWindowDimensions();
  const service = new ListPageServices();
  const [items, setItems] = useState([]);
  const [breadcrumbNameMap, setBreadcrumbNameMap] = useState<{ [name: string]: string }>({});
  const [projectId, setProjectId] = useState(propertiesFilter.project);
  const [isOpenSelect, setIsOpenSelect] = useState(false);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [currentItem, setCurrentItem] = useState<any>(null);
  const [currentTimeout, setCurrentTimeout] = useState<any>(null);
  const [searchValue, setSearchValue] = useState(propertiesFilter.search);
  const [searchConsumerValue, setSearchConsumerValue] = useState('');
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
  const [currentProperty, setCurrentProperty] = useState({} as any);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenImportModal, setIsOpenImportModal] = useState(false);
  const history = useHistory();

  //board
  const [steps, setSteps] = useState<any>([
    {
      target: '.property-first-step',
      content: (
        <BoardTourComponent title={t('property.project.first.title')} description={t('property.project.first.text')} />
      ),
      disableBeacon: true,
      placement: 'left',
    },
  ]);

  const [childrenSteps, setChildrenSteps] = useState<any>([
    {
      target: '.property-children-first-step',
      content: (
        <BoardTourComponent title={t('property.project.first.title')} description={t('property.project.first.text')} />
      ),
      disableBeacon: true,
      placement: 'left',
    },
  ]);
  const [run, setRun] = useState(false);
  const [runChildren, setRunChildren] = useState(false);

  const { headerDispatch } = useContext(HeaderContext);
  useEffect(() => {
    headerDispatch({
      type: CHANGE_CURRENT_PAGE,
      currentPage: t('header.title.Properties'),
      path: 'properties',
    });
  }, [i18n.language]);

  useEffect(() => {
    filtersDispatch({
      type: SET_PROPERTIES_FILTERS,
      filters: { project: projectId, search: searchValue },
    });
  }, [projectId, searchValue]);

  useEffect(() => {
    if (project) {
      return setProjectId(project);
    }
    if (user.projects.length > 0) {
      setProjectId(user.projects[0].id);
    }
  }, [user, project]);

  useEffect(() => {
    if (projectId) {
      setFetchingData(true);
      if (searchValue) {
        getSearchProperties(
          generateFilterfromObj({
            title: {
              type: FilterTypes.SEARCH,
              value: searchValue,
            },
          }),
        );
      } else {
        getProperties();
      }
      setBreadcrumbNameMap(
        props.history.location.pathname
          .replace('/properties', '')
          .split('/')
          .slice(1)
          .reduce(
            (res: any, curr: any, index: number) => ({
              ...res,
              [[Object.keys(res)[index], curr].join('/')]: curr.split(':').shift(),
            }),
            { '/properties': t('properties.bread.root') },
          ),
      );
    }
    setRun(getOnboarding(OnboardingTypes.PROPERTY));
  }, [props.history.location.pathname, projectId]);

  const getProperties = () => {
    const id = parseInt(props.history.location.pathname.split(':').pop());
    service[id ? 'getPropertyChildren' : 'getPropertyWithFilter']({
      ...(id ? { id } : { searchObjects: { 'parent/id': null } }),
      projectKey: user.projects.find((project) => project.id === projectId)?.key || '',
    })
      .then((value) =>
        setItems(
          value
            .sort((a: any, b: any) => a.title?.toLowerCase().localeCompare(b.title?.toLowerCase()))
            .map((el: any) => {
              if (el.childrens.length > 0) {
                el.children = el.childrens;
              }
              return el;
            }),
        ),
      )
      .catch((e: any) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => {
        setFetchingData(false);
        setRunChildren(false);
      });
  };

  const getChildren = (parent: any, isMobile = false) => {
    setFetchingData(true);
    service
      .getPropertyChildren({
        id: parent?.id,
        projectKey: user.projects.find((project) => project.id === projectId)?.key || '',
      })
      .then((data) => {
        if (isMobile) {
          data.length
            ? props.history.push(`${props?.history?.location?.pathname}/${parent?.title}:${parent?.id}`)
            : notification.warning({ message: t('properties.notification.warning.nested') });
        } else {
          if (data.length > 0) {
            const newData = data.map((el: any) => {
              if (el.childrens.length > 0) {
                el.children = el.childrens;
              }
              return el;
            });

            const searchChildrenIdInParent = (obj: any, searchId: number) => {
              if (obj.id === searchId) {
                obj.children = newData;
                obj.childrens = newData;
                return obj;
              }
              if (obj?.children && obj?.children?.length > 0) {
                obj.children.map((el: any) => searchChildrenIdInParent(el, searchId));
              }
              return obj;
            };

            setItems((state: any) => state.map((el: any) => searchChildrenIdInParent(el, parent.id)));
          }
        }
      })
      .catch((e: any) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => {
        setFetchingData(false);
      });
  };

  const deleteProperty = (item: any) => {
    setCurrentProperty(item);
    setIsOpenDeleteModal(true);
  };

  const openSelect = () => {
    if (user.projects.length === 1) {
      history.push(`/properties/property/project/${user.projects[0].id}`);
    } else if (projectId) {
      history.push(`/properties/property/project/${projectId}`);
    } else {
      setIsOpenSelect(true);
    }
  };

  const chackProject = () => {
    if (projectId) {
      setIsOpenCreate(true);
    } else {
      notification.warning({ message: t('properties.notification.select.project') });
    }
  };

  const createProperty = (item: any) => {
    setCurrentItem(item);
    setIsOpenCreate(true);
  };

  const generateUrl = (item: any, isEdit = false, type = '') => {
    if (isEdit) {
      return item?.parent?.id
        ? `/properties/${item.parent.id}/property/${item.id}/project/${item?.project?.id || projectId}`
        : `/properties/property/${item.id}/project/${item?.project?.id || projectId}`;
    }
    return item
      ? `/properties/${item.id}/property/project/${item.project.id || projectId}/${type}`
      : `/properties/property/project/${projectId}/${type}`;
  };

  const handleEdit = (item: any) => {
    history.push(generateUrl(item, true));
  };

  const onSearch = (value: any) => {
    setSearchValue(value.target.value);
    let body: { filter: any[]; fullName?: string | null } = generateFilterfromObj({
      title: {
        type: FilterTypes.SEARCH,
        value: value.target.value,
      },
    });
    body.fullName = searchConsumerValue || null;

    clearTimeout(currentTimeout);
    if (value.target.value === '' && searchConsumerValue === '') {
      return getProperties();
    }
    setCurrentTimeout(setTimeout(() => getSearchProperties(body), 1000));
  };

  const onSearchConsumer = (value: any) => {
    setSearchConsumerValue(value.target.value);
    let body: { filter: any[]; fullName?: string | null } = generateFilterfromObj({
      title: {
        type: FilterTypes.SEARCH,
        value: searchValue,
      },
    });
    body.fullName = value.target.value || null;

    clearTimeout(currentTimeout);
    if (value.target.value === '' && searchValue === '') {
      return getProperties();
    }
    setCurrentTimeout(setTimeout(() => getSearchProperties(body), 1000));
  };

  const getSearchProperties = (params: any) => {
    setFetchingData(true);
    service
      .getProperties(user.projects.find((project) => project.id === projectId)?.key || '', params)
      .then((value) =>
        setItems(
          value
            .sort((a: any, b: any) => a.title?.toLowerCase().localeCompare(b.title?.toLowerCase()))
            .map((el: any) => {
              if (el.childrens.length > 0) {
                el.children = el.childrens;
              }
              return el;
            }),
        ),
      )
      .catch((e: any) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => {
        setFetchingData(false);
      });
  };

  const exportProperties = async () => {
    const project = user.projects.find((project) => project.id === projectId);
    await service.exportProperties(project!.key).then((res) => {
      const a = document.createElement('a');
      const url = window.URL.createObjectURL(new Blob([res]));
      a.href = url;
      a.download = `${project!.title}.csv`;
      a.click();
    });
  };

  if (useLessThen801()) {
    const data =
      items.length > 0 &&
      (items.map((item: any) => [
        { title: t('properties.table.columns.title'), description: item.title, type: 'string' },
        {
          title: t('properties.table.columns.type'),
          description: item.type ? item.type.description : 'N/A',
          type: 'string',
        },
        {
          title: t('properties.table.columns.status'),
          description: item.status ? (
            <Badge status={getBadgeStatus(item.status.code)} text={item.status.description} />
          ) : (
            'N/A'
          ),
          type: 'string',
        },
        {
          title: t('properties.table.columns.typology'),
          description: 'N/A',
          openByFunction: item?.typology?.title,
          btnsInfo: [
            {
              function: () => history.push(`/typologies/modify/${item?.typology?.id}`),
              functionBtnTitle: item?.typology?.title,
            },
          ],
          type: item?.typology?.title ? 'button' : 'string',
        },
        {
          title: t('properties.table.columns.action'),
          type: 'button',
          openByFunction: true,
          btnsInfo: [
            {
              function: () => getChildren(item, true),
              functionBtnTitle: t('consumers.table.columns.manage'),
              isNotShow: item?.childrens?.length === 0,
            },
            {
              function: () =>
                history.push(`${props.history.location.pathname}/property/${item.id}/project/${item.project.id}`),
              functionBtnTitle: t('gallery.modal.select.btn').toLowerCase(),
              isNotShow: isSupplier,
            },
            {
              function: () =>
                history.push(
                  `/properties/${item?.id}/project/${item?.project?.id}/manage/${
                    isSupplier ? 'details_project' : 'calendar'
                  }`,
                  {
                    projectKey: item?.project?.key,
                  },
                ),
              functionBtnTitle: t('consumers.table.columns.manage').toLowerCase(),
              isNotShow: item?.childrens?.length > 0,
            },
            {
              function: () => deleteProperty(item),
              functionBtnTitle: t('common.title.delete').toLowerCase(),
              isNotShow: isSupplier || item?.childrens?.length > 0,
            },
          ],
        },
      ]) as any);

    return (
      <>
        {isOpenSelect && (
          <SelectProjectForm isOpenSelect={isOpenSelect} setIsOpenSelect={setIsOpenSelect} data={props} />
        )}
        <HeaderTable breadcrumb={breadcrumbNameMap}></HeaderTable>
        <div
          className={`${css['controls-container']} consumer-control-wrapper`}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '1rem',
          }}>
          <Select
            style={{ padding: '5px 0', marginBottom: '1rem', marginTop: 0 }}
            className="consumers-control-select common-animation-primary"
            value={projectId}
            onChange={(value: any) => {
              setSearchValue('');
              setProjectId(value);
              propertyDispatch({
                type: SET_PROJECT_ID,
                project: value,
              });
            }}
            dropdownClassName="consumers-control-select-dropdown"
            placeholder={t('properties.placeholder.change.project')}
            suffixIcon={
              <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
            }>
            {user &&
              user.projects
                .sort((a: any, b: any) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))
                .filter((project) => user.projects.find((item) => item.id === project.id))
                .map((project) => (
                  <Option value={project.id} key={project.id} className="budget-control-option">
                    <span style={{ fontSize: '14px' }}>{project.title}</span>
                  </Option>
                ))}
          </Select>
          <Search
            allowClear
            value={searchValue}
            className="consumers-control-search common-animation-primary"
            style={{ padding: '5px 0', marginTop: 0, marginBottom: '1rem' }}
            placeholder={t('properties.search.placeholder')}
            suffix={<img src={searchIcon} alt="" />}
            onChange={onSearch}
            disabled={user?.projects?.length === 0}
          />
          <Search
            allowClear
            value={searchConsumerValue}
            className="consumers-control-search common-animation-primary"
            style={{ padding: '5px 0', marginTop: 0, marginBottom: '1rem' }}
            placeholder={t('properties.search.acquirers.placeholder')}
            suffix={<img src={searchIcon} alt="" />}
            onChange={onSearchConsumer}
            disabled={user?.projects?.length === 0}
          />
          <Button
            className={`common-blue-border-btn common-secondary-btn`}
            onClick={() => setIsOpenImportModal(true)}
            style={{ width: '100%', marginBottom: '1rem' }}>
            <span>{t('consumers.modal.import.btn')}</span>
          </Button>
          <Button
            className={`common-blue-border-btn common-secondary-btn`}
            onClick={exportProperties}
            style={{ width: '100%', marginBottom: '1rem' }}>
            <span>{t('properties.export.btn')}</span>
          </Button>
          <Button
            className={`${css['property-add-new']} common-primary-btn`}
            style={{ width: '100%' }}
            onClick={() => openSelect()}>
            {t('common.btn.new')}
          </Button>
        </div>
        <TableMobile
          data={data}
          isLoading={fetchingData}
          option={{}}
          itemsLength={items.length}
          totalItems={items.length}
        />
        {items.length === 0 && (
          <div style={{ color: '#ff4d4f', width: '100%', textAlign: 'center' }}>
            {t('property.empty.table.placeholder')}
          </div>
        )}
        {isOpenDeleteModal && (
          <PropertyDeleteConfirmModal
            title={currentProperty.title}
            id={currentProperty.id}
            isVisible={isOpenDeleteModal}
            projectKey={user.projects.find((project) => project.id === projectId)?.key || ''}
            onCancel={() => {
              setIsOpenDeleteModal(false);
            }}
            onOk={() => {
              setExpandedRowKeys([]);
              getProperties();
              setIsOpenDeleteModal(false);
            }}
          />
        )}
        {isOpenImportModal && (
          <ModalImportProperties
            isVisible={isOpenImportModal}
            handleClose={() => {
              getProperties();
              setIsOpenImportModal(false);
            }}
            projectKey={user.projects.find((project) => project.id === projectId)?.key || ''}
          />
        )}
      </>
    );
  }

  return (
    <>
      <CustomOnBoard
        steps={steps}
        run={run}
        isShowProgress={false}
        onFinish={() => saveOnboarding(OnboardingTypes.PROPERTY, user.boardCheckpoint)}
      />
      <CustomOnBoard
        steps={childrenSteps}
        run={runChildren}
        isShowProgress={false}
        onFinish={() => saveOnboarding(OnboardingTypes.PROPERTY_CHILDREN, user.boardCheckpoint)}
      />
      {isOpenCreate && (
        <ModalCreateProperty
          isOpen={isOpenCreate}
          item={currentItem}
          generateUrl={generateUrl}
          onClose={() => {
            setIsOpenCreate(false);
            setCurrentItem(null);
          }}
        />
      )}
      <div
        className={`${css['controls-container']} consumer-control-wrapper`}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div>
          <Select
            style={{ padding: '5px 0', marginRight: '1rem' }}
            className="consumers-control-select common-animation-primary"
            value={projectId}
            onChange={(value: any) => {
              setExpandedRowKeys([]);
              setSearchValue('');
              setSearchConsumerValue('');
              setProjectId(value);
              propertyDispatch({
                type: SET_PROJECT_ID,
                project: value,
              });
            }}
            dropdownClassName="consumers-control-select-dropdown"
            placeholder={t('properties.placeholder.change.project')}
            suffixIcon={
              <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
            }>
            {user &&
              user.projects
                .sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))
                .filter((project) => user.projects.find((item) => item.id === project.id))
                .map((project) => (
                  <Option value={project.id} key={project.id} className="budget-control-option">
                    <span style={{ fontSize: '14px' }}>{project.title}</span>
                  </Option>
                ))}
          </Select>
          <Search
            allowClear
            value={searchValue}
            className="consumers-control-search common-animation-primary"
            style={{ width: '230px', padding: '5px 0', marginRight: '1rem' }}
            placeholder={t('properties.search.placeholder')}
            suffix={<img src={searchIcon} alt="" />}
            onChange={onSearch}
            disabled={user?.projects?.length === 0}
          />
          <Search
            allowClear
            value={searchConsumerValue}
            className="consumers-control-search common-animation-primary"
            style={{ width: '230px', padding: '5px 0', marginRight: '1rem' }}
            placeholder={t('properties.search.acquirers.placeholder')}
            suffix={<img src={searchIcon} alt="" />}
            onChange={onSearchConsumer}
            disabled={user?.projects?.length === 0}
          />
        </div>
        {!isSupplier && (
          <div style={{ display: 'flex' }}>
            <Button
              className={`common-blue-border-btn common-secondary-btn`}
              onClick={() => setIsOpenImportModal(true)}
              style={{ marginRight: '1rem', height: '42px' }}>
              <span>{t('consumers.modal.import.btn')}</span>
            </Button>
            <Button
              className={`common-blue-border-btn common-secondary-btn`}
              onClick={exportProperties}
              style={{ marginRight: '1rem', height: '42px' }}>
              <span>{t('properties.export.btn')}</span>
            </Button>
            <Button
              className={`${css['property-add-new']} common-primary-btn property-first-step`}
              onClick={chackProject}>
              {t('common.btn.new')}
            </Button>
          </div>
        )}
      </div>
      <div className={css['property-table-container']}>
        <Table
          columns={getColumns(t, createProperty, handleEdit, isSupplier, deleteProperty)}
          dataSource={items}
          showHeader={true}
          expandedRowKeys={expandedRowKeys}
          onExpandedRowsChange={(expandedRow) => setExpandedRowKeys(expandedRow as number[])}
          onExpand={(expanded, record) => expanded && getChildren(record)}
          size="middle"
          locale={{
            emptyText: <span style={{ color: '#ff4d4f' }}>{t('property.empty.table.placeholder')}</span>,
          }}
          loading={fetchingData}
          className="consumers-info-table common-table-expand-scroll"
          rowClassName="common-table-row--pointer"
          rowKey={(record) => record.id}
          scroll={{ y: windowHeight - 300 }}
        />
      </div>
      {isOpenDeleteModal && (
        <PropertyDeleteConfirmModal
          title={currentProperty.title}
          id={currentProperty.id}
          isVisible={isOpenDeleteModal}
          projectKey={currentProperty.project.key}
          onCancel={() => {
            setIsOpenDeleteModal(false);
          }}
          onOk={() => {
            setExpandedRowKeys([]);
            getProperties();
            setIsOpenDeleteModal(false);
          }}
        />
      )}
      {isOpenImportModal && (
        <ModalImportProperties
          isVisible={isOpenImportModal}
          handleClose={() => {
            getProperties();
            setIsOpenImportModal(false);
          }}
          projectKey={user.projects.find((project) => project.id === projectId)?.key || ''}
        />
      )}
    </>
  );
};

export default PropertiesPage;
