import React, { useState } from 'react';
import { Button, Checkbox, Modal } from 'antd';
import aiPic from '../../../../assets/images/ai/aiPic.png';
import css from '../../../../assets/styles/faqAI.module.css';
import { useTranslation } from 'react-i18next';
import { saveAiFaq } from '../../../../helpers/onboarding';
import { useLessThen801 } from '../../../../helpers/mediaDetect';

interface IProps {
  isOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}

const GenerativeAIModal = ({ isOpen, handleOk, handleCancel }: IProps) => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);

  const handleClick = () => {
    if (isChecked) {
      saveAiFaq();
    }
    handleOk();
  };

  return (
    <Modal
      centered
      closable
      className="generativeAIModal"
      bodyStyle={
        useLessThen801()
          ? { borderRadius: 8, padding: '1rem', overflowY: 'auto', height: '100%', maxHeight: '96vh' }
          : { borderRadius: 8, padding: 44 }
      }
      visible={isOpen}
      onCancel={handleCancel}
      footer={false}
      maskClosable={false}>
      <div className={css.generativeAIModalContainer}>
        {!useLessThen801() && <img src={aiPic} className={css.generativeAIModalRowLeft} />}
        <div className={css.generativeAIModalRowRight}>
          <h2>{t('faq.ai.popup.title')}</h2>
          <p>{t('faq.ai.popup.text1')}</p>
          <p>{t('faq.ai.popup.text2')}</p>
          <p>{t('faq.ai.popup.text3')}</p>
          <p>{t('faq.ai.popup.text6')}</p>
          <p className={css.weight}>{t('faq.ai.popup.text4')}</p>
          <Checkbox style={{ marginBottom: 22 }} value={isChecked} onChange={(e) => setIsChecked(e.target.checked)}>
            <span className={css.checkboxTitle}>{t('faq.ai.popup.text5')}</span>
          </Checkbox>
          <Button type="primary" className={css.button} onClick={handleClick}>
            {t('faq.ai.popup.btn')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default GenerativeAIModal;
