import React, { useState, useEffect, useContext } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Table, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import { getColumns } from './components/RolesColumns';
import RolesService from '../../services/RolesService';
import { HeaderContext } from '../../contexts/HeaderContextProvider';
import { CHANGE_CURRENT_PAGE } from '../../constants/actionTypes/headerConstants';
import { useHistory } from 'react-router';
import css from '../../assets/styles/roles.module.css';
import { useLessThen801 } from '../../helpers/mediaDetect';
import TableMobile from '../common/TableMobile';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { RoleModel } from '../../models/RoleModel';
import i18n from '../../utils/i18n';

const service = new RolesService();

const RolesPage: React.FC<RouteComponentProps> = (props) => {
  const { t } = useTranslation();
  const { height: windowHeight } = useWindowDimensions();
  const [items, setItems] = useState<RoleModel[]>([]);
  const [breadcrumbNameMap, setBreadcrumbNameMap] = useState<{ [name: string]: string }>({});

  const { headerDispatch } = useContext(HeaderContext);
  const history = useHistory();
  useEffect(() => {
    headerDispatch({
      type: CHANGE_CURRENT_PAGE,
      currentPage: t('header.title.Roles'),
      path: 'roles',
    });
  }, [i18n.language]);

  const getRoles = () => {
    service.getRoles().then((res: RoleModel[]) => {
      setItems(res);
    });
  };

  useEffect(() => {
    getRoles();
  }, [setItems]);

  useEffect(() => {
    getRoles();
    setBreadcrumbNameMap(
      props.history.location.pathname
        .replace('/roles', '')
        .split('/')
        .slice(1)
        .reduce(
          (res, curr, index) => ({
            ...res,
            [[Object.keys(res)[index], curr].join('/')]: curr.split(':').shift(),
          }),
          { '/roles': t('roles.bread.root') },
        ),
    );
  }, [props.history.location.pathname]);

  if (useLessThen801()) {
    const data =
      items.length > 0 &&
      (items.map((item) => [
        {
          title: t('roles.table.columns.name'),
          description: item.name,
          type: 'string',
        },
        { title: t('roles.table.columns.description'), description: item.description, type: 'string' },
        {
          title: t('roles.table.columns.menuFunctions'),
          description: (
            <div>
              {item.menuFunctions.map((item) => (
                <Tag
                  key={item.id}
                  style={{
                    backgroundColor: 'rgba(56, 83, 92, 0.8)',
                    color: 'rgb(255, 255, 255)',
                    borderColor: 'rgba(56, 83, 92, 0.85)',
                    margin: '2px 8px 2px 0',
                  }}>
                  {item.name}
                </Tag>
              ))}
            </div>
          ),
          type: 'string',
        },
        {
          title: t('budget.table.action'),
          type: 'button',
          openByFunction: true,
          btnsInfo: [
            {
              function: () => history.push(`/roles/modify/${item.name}`),
              functionBtnTitle: t('common.btn.modify.lower'),
            },
          ],
        },
      ]) as any);

    return (
      <>
        <HeaderTable title={t('roles.title')} breadcrumb={breadcrumbNameMap}>
          <Link
            to={`${props.history.location.pathname}/modify`}
            style={{ borderRadius: 4 }}
            className="common-animation-primary">
            <Button className={`common-blue-btn common-primary-btn`} style={{ height: '42px', width: '100%' }}>
              {t('common.btn.new')}
            </Button>
          </Link>
        </HeaderTable>
        <TableMobile data={data} isLoading={false} option={{}} itemsLength={items.length} totalItems={items.length} />
      </>
    );
  }
  return (
    <>
      <HeaderTable title={t('roles.title')} breadcrumb={breadcrumbNameMap}>
        <Link
          to={`${props.history.location.pathname}/modify`}
          style={{ borderRadius: 4 }}
          className="common-animation-primary">
          <Button className={`common-blue-btn common-primary-btn`} style={{ height: '42px' }}>
            {t('common.btn.new')}
          </Button>
        </Link>
      </HeaderTable>
      <div className={css['roles-table-container']}>
        <Table
          columns={getColumns({ t, history })}
          dataSource={items}
          showHeader={true}
          scroll={{ y: windowHeight - 380, x: 1000 }}
          size="middle"
          rowKey="name"
          className="consumers-info-table"
          rowClassName="common-table-row--pointer"
        />
      </div>
    </>
  );
};

export default RolesPage;
