import { Button, notification, Spin } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../assets/styles/budget.module.css';
import BudgetService from '../../../services/BudgetService';

interface DeleteConfirmFormProps {
  isOpen: boolean;
  currentId: number | null;
  getBudget: () => void;
  setIsNeedUpdate?: (b: boolean) => void;
  setClose: () => void;
  invoiceNumber: string | null;
}

const service = new BudgetService();

const DeleteInvoiceForm = (props: DeleteConfirmFormProps) => {
  const { currentId, isOpen, setClose, getBudget, setIsNeedUpdate, invoiceNumber } = props;
  const [fetchingData, setFetchingData] = useState(false);
  const { t } = useTranslation();

  const deleteInvoice = () => {
    if (currentId) {
      setFetchingData(true);
      service
        .deleteInvoice(currentId)
        .then(() => {
          setClose();
          notification.success({
            message: t('budget.modal.delete.success.message'),
          });
          getBudget();
          setIsNeedUpdate && setIsNeedUpdate(true);
        })
        .catch((e) =>
          notification.error({
            message: e,
          }),
        )
        .finally(() => setFetchingData(false));
    }
  };

  return (
    <Spin spinning={fetchingData}>
      <Modal
        className="common-modal"
        centered
        style={{ width: '10rem' }}
        visible={isOpen}
        footer={
          <div className="common-modal-footer">
            <Button className={`common-gray-border-btn common-secondary-btn`} onClick={() => setClose()}>
              {t('budget.modal.cancel.btn')}
            </Button>
            <Button className={`common-green-btn common-modal-btn`} onClick={() => deleteInvoice()}>
              {t('budget.modal.delete.btn')}
            </Button>
          </div>
        }
        closable={false}>
        <span className={css['delete-invoice-title']}>{`${t(
          'budget.modal.confirm.delete.message',
        )} ${invoiceNumber}?`}</span>
      </Modal>
    </Spin>
  );
};

export default DeleteInvoiceForm;
