import React, { useEffect, useMemo, useState } from 'react';
import css from '../../../assets/styles/details.module.css';
import { FieldModel, PropertyModel } from '../../../models/PropertyModel';
import { NavLink } from 'react-router-dom';
import { TFunction } from 'react-i18next';
import { IUserEasy } from '../../../models/UserModel';
import DictionariesServices from '../../../services/DictionariesServices';

interface IProps {
  t: TFunction<'translation'>;
  propertyInfo: PropertyModel;
  consumers?: IUserEasy[];
  isSupplier?: boolean;
  isConsumer?: boolean;
}

const service = new DictionariesServices();

const useDictionaryOptionTitle = (field?: FieldModel) => {
  const dictionaryName = field?.field.dictionaryName;
  const optionId = field?.value;
  const [options, setOptions] = useState([] as any[]);

  useEffect(() => {
    if (dictionaryName) {
      service.getDictionary(dictionaryName).then((options) => {
        setOptions(options);
      });
    }
  }, [dictionaryName]);

  const title = useMemo(
    () => (optionId && options.find((el) => el.id === +optionId)?.description) || '',
    [optionId, options],
  );

  return title;
};

const getTitleByCode = (code: string, t: TFunction<'translation'>) => {
  switch (code) {
    case 'type':
      return t('project.details.table.type');
    case 'noOfRooms':
      return t('project.details.table.noOfRooms');
    case 'order':
      return t('project.details.table.order');
    case 'floor':
      return t('project.details.table.floor');
    case 'maxSurface':
      return t('project.details.table.maxSurface');
    case 'minSurface':
      return t('project.details.table.minSurface');
    case 'price':
      return t('project.details.table.price');
    case 'rooms':
      return t('project.details.table.rooms');
    case 'orientation':
      return t('project.details.table.orientation');
    case 'floorHeight':
      return t('project.details.table.floor.height');
    case 'totalBuildingHeight':
      return t('project.details.table.total.height');
    case 'surface':
      return t('project.details.table.surface');
  }
};

const ProjectDetailsTable = ({ t, propertyInfo, consumers, isSupplier, isConsumer }: IProps) => {
  const orientationValue = useDictionaryOptionTitle(
    propertyInfo.fields.find((field: FieldModel) => field.field.code === 'orientation'),
  );

  return (
    <div className={css['project-details-table']}>
      <div className={css['project-details-item']}>
        <span className={css['project-details-item-title']}>{t('project.details.table.project')}</span>
        <span className={css['project-details-item-desc']}>{propertyInfo.project.title}</span>
      </div>
      <div className={css['project-details-item']}>
        <span className={css['project-details-item-title']}>{t('project.details.table.property')}</span>
        <span className={css['project-details-item-desc']}>{propertyInfo.title}</span>
      </div>
      {isConsumer ? (
        <div className={css['project-details-item']}>
          <span className={css['project-details-item-title']}>{t('project.details.table.type')}</span>
          <span className={css['project-details-item-desc']}>{propertyInfo.type?.description}</span>
        </div>
      ) : (
        <div className={css['project-details-item']}>
          <span className={css['project-details-item-title']}>{t('dashboard.acquirers.title')}</span>
          <span className={css['project-details-item-desc']}>
            {consumers &&
              consumers.length > 0 &&
              consumers.map((user, index) =>
                isSupplier ? (
                  <>{`${user.firstName} ${user.lastName}${index !== consumers.length - 1 ? ', ' : ''}`}</>
                ) : (
                  <React.Fragment key={user.id}>
                    <NavLink to={`/consumers/modify/${user.id}`}>{`${user.firstName} ${user.lastName}`}</NavLink>
                    {`${index !== consumers.length - 1 ? ', ' : ''}`}
                  </React.Fragment>
                ),
              )}
          </span>
        </div>
      )}
      {propertyInfo.fields &&
        propertyInfo.fields.map((el) => (
          <div key={el.id} className={css['project-details-item']}>
            <span className={css['project-details-item-title']}>{getTitleByCode(el.field.code, t)}</span>
            <span className={css['project-details-item-desc']}>
              {el.field.code === 'price'
                ? `CHF ${el?.value}`
                : el.field.code === 'orientation'
                ? orientationValue
                : el?.value}
            </span>
          </div>
        ))}
    </div>
  );
};

export default ProjectDetailsTable;
