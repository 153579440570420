import React, { useEffect, useState } from 'react';
import css from '../../../../assets/styles/faqAI.module.css';
import aiPic from '../../../../assets/images/ai/aiPic.png';
import { Button, Form, Input, Radio, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { IFormValues } from './AIContainer';
import { aiLanguageGenerating } from '../../../../constants/aiLanguageGenerating';
import AIService from '../../../../services/AIService';
import { LoadingOutlined } from '@ant-design/icons';
import config from '../../../../config';
import { useLessThen801 } from '../../../../helpers/mediaDetect';

interface IProps {
  promptDefault: string;
  onClose: () => void;
  onSubmit: (values: IFormValues) => void;
  languageGenerating: aiLanguageGenerating;
  setLanguageGenerating: (langs: aiLanguageGenerating) => void;
  isLoading: boolean;
}

const aiService = new AIService();

const AIForm = ({ onClose, onSubmit, languageGenerating, setLanguageGenerating, promptDefault, isLoading }: IProps) => {
  const MAX_TOKENS = +config.gptTokensLimit;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [tokens, setTokens] = useState(0);
  const [prompt, setPrompt] = useState(promptDefault);
  const [isFieldTextValidated, setIsFieldTextValidated] = useState(true);
  const [isFieldTextTyping, setIsFieldTextTyping] = useState(false);
  const [currentTimeout, setCurrentTimeout] = useState<NodeJS.Timeout>();

  const getTokens = async (text: string) => {
    await aiService
      .getTokens({ text })
      .then((res) => {
        setTokens(res.count);
        form.validateFields();
      })
      .finally(() => {
        setIsFieldTextTyping(false);
      });
  };

  useEffect(() => {
    if (prompt.length > 0) {
      clearTimeout(Number(currentTimeout));
      setCurrentTimeout(setTimeout(() => getTokens(prompt), 500));
    }
  }, [prompt]);

  if (useLessThen801()) {
    return (
      <div className={css.aiContainerForm}>
        <div className={css.aiStep1ColRight}>
          <h2>{t('faq.step1.title')}</h2>
          <Form
            layout="vertical"
            form={form}
            onFinish={onSubmit}
            onFieldsChange={(field) => {
              const { name, errors } = field[0];
              if (Array.isArray(name) && name.includes('text')) {
                if (errors && errors?.length > 0) {
                  setIsFieldTextValidated(false);
                } else {
                  setIsFieldTextValidated(true);
                }
              }
            }}>
            <div style={{ position: 'relative' }} className="adaptiveTextAreaContainer">
              <Form.Item
                name="text"
                label={
                  <span style={{ color: '#778dac' }}>{`${t('faq.step1.text.1')} ${MAX_TOKENS} ${t(
                    'faq.step1.text.2',
                  )}`}</span>
                }
                hidden={isLoading}
                rules={[
                  { required: true, message: t('common.validation.required') },
                  () => ({
                    validator() {
                      if (tokens <= MAX_TOKENS) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t('faq.ai.token.notification')));
                    },
                  }),
                ]}
                initialValue={promptDefault}>
                <Input.TextArea
                  allowClear
                  autoSize={{ minRows: 10, maxRows: 10 }}
                  onChange={(e) => {
                    setIsFieldTextTyping(!!e.target.value);
                    setPrompt(e.target.value);
                  }}
                />
              </Form.Item>
              <div className={isFieldTextValidated ? css.aiTokenSizer : css.aiTokenSizerWithError}>
                <span style={{ color: '#778dac' }}>
                  {isFieldTextTyping ? (
                    <LoadingOutlined />
                  ) : prompt.length > 0 ? (
                    <>
                      <span className={css.aiTokens}>{tokens}</span> / {MAX_TOKENS}
                    </>
                  ) : (
                    `0 / ${MAX_TOKENS}`
                  )}
                </span>
              </div>
            </div>
            <div className={css.aiLanguageGeneratingField}>
              <span style={{ color: '#778dac' }}>{t('faq.step1.language')}:</span>
              <Radio.Group value={languageGenerating} onChange={(e) => setLanguageGenerating(e.target.value)}>
                <Space direction="vertical">
                  <Radio value={aiLanguageGenerating.fr_en} style={{ marginRight: 20 }}>
                    French & English
                  </Radio>
                  <Radio value={aiLanguageGenerating.fr} style={{ marginRight: 20 }}>
                    French
                  </Radio>
                  <Radio value={aiLanguageGenerating.en} style={{ marginRight: 20 }}>
                    English
                  </Radio>
                </Space>
              </Radio.Group>
            </div>
            <div style={{ display: 'flex' }}>
              <Button
                htmlType="submit"
                loading={isFieldTextTyping}
                className={`common-blue-btn common-primary-btn`}
                style={{ height: '42px', width: '100%', marginRight: '1rem' }}>
                {t('faq.step1.btn.generate')}
              </Button>
              <Button
                className={`common-blue-border-btn common-secondary-btn`}
                style={{ height: '42px', width: '100%' }}
                onClick={onClose}>
                {t('faq.step1.btn.close')}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }

  return (
    <div className={css.aiContainerForm}>
      <div className={css.aiStep1ColLeft}>
        <img className={css.aiImg} src={aiPic} />
      </div>
      <div className={css.aiStep1ColRight}>
        <h2>{t('faq.step1.title')}</h2>
        <Form
          layout="vertical"
          form={form}
          className={css.aiPromptForm}
          onFinish={onSubmit}
          onFieldsChange={(field) => {
            const { name, errors } = field[0];
            if (Array.isArray(name) && name.includes('text')) {
              if (errors && errors?.length > 0) {
                setIsFieldTextValidated(false);
              } else {
                setIsFieldTextValidated(true);
              }
            }
          }}>
          <div style={{ height: '100%' }}>
            <div style={{ position: 'relative' }} className="adaptiveTextAreaContainer">
              <Form.Item
                name="text"
                label={
                  <span style={{ color: '#778dac' }}>{`${t('faq.step1.text.1')} ${MAX_TOKENS} ${t(
                    'faq.step1.text.2',
                  )}`}</span>
                }
                className="adaptiveTextAreaFormItem"
                hidden={isLoading}
                rules={[
                  { required: true, message: t('common.validation.required') },
                  () => ({
                    validator() {
                      if (tokens <= MAX_TOKENS) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t('faq.ai.token.notification')));
                    },
                  }),
                ]}
                initialValue={promptDefault}>
                <Input.TextArea
                  allowClear
                  className="adaptiveTextAreaContainer"
                  onChange={(e) => {
                    setIsFieldTextTyping(!!e.target.value);
                    setPrompt(e.target.value);
                  }}
                />
              </Form.Item>
              <div className={isFieldTextValidated ? css.aiTokenSizer : css.aiTokenSizerWithError}>
                <span style={{ color: '#778dac' }}>
                  {isFieldTextTyping ? (
                    <LoadingOutlined />
                  ) : prompt.length > 0 ? (
                    <>
                      <span className={css.aiTokens}>{tokens}</span> / {MAX_TOKENS}
                    </>
                  ) : (
                    `0 / ${MAX_TOKENS}`
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className={css.aiLanguageGeneratingField}>
            <span style={{ color: '#778dac' }}>{t('faq.step1.language')}:</span>
            <Radio.Group value={languageGenerating} onChange={(e) => setLanguageGenerating(e.target.value)}>
              <Radio value={aiLanguageGenerating.fr_en} style={{ marginRight: 20 }}>
                French & English
              </Radio>
              <Radio value={aiLanguageGenerating.fr} style={{ marginRight: 20 }}>
                French
              </Radio>
              <Radio value={aiLanguageGenerating.en} style={{ marginRight: 20 }}>
                English
              </Radio>
            </Radio.Group>
          </div>
          <Button
            htmlType="submit"
            loading={isFieldTextTyping}
            className={`common-blue-btn common-primary-btn`}
            style={{ height: '42px', width: 150, marginRight: '1rem' }}>
            {t('faq.step1.btn.generate')}
          </Button>
          <Button
            className={`common-blue-border-btn common-secondary-btn`}
            style={{ height: '42px', width: 150 }}
            onClick={onClose}>
            {t('faq.step1.btn.close')}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default AIForm;
