import { Button, Select } from 'antd';
import Search from 'antd/lib/input/Search';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import css from '../../../assets/styles/leads.module.css';
import searchIcon from '../../../assets/images/ic-search.svg';
import { useTranslation } from 'react-i18next';
import { Option } from 'antd/lib/mentions';
import { FilterTypes } from '../../../constants/filterTypes';
import { FiltersContext } from '../../../contexts/FiltersContextProvider';
import { SET_LEADS_FILTERS } from '../../../constants/actionTypes/filtersConstants';
import { AppContext } from '../../../contexts/AppContextProvider';
import { ProjectModel } from '../../../models/ProjectModel';
import { SET_CURRENT_PROJECT, SET_CURRENT_PROPERTY } from '../../../constants/actionTypes/appConstants';
import { IAppContext } from '../../../typings/IApp';

interface PropsControlModel {
  props: any;
  onTableChange: any;
}

const LeadsControl = (propsControl: PropsControlModel) => {
  const { onTableChange, props } = propsControl;
  const { t } = useTranslation();
  const {
    app: { currentProject, user },
    appDispatch,
  } = useContext<IAppContext>(AppContext);
  const {
    filters: { leadsFilter },
    filtersDispatch,
  } = useContext(FiltersContext);
  const [projectId, setProjectId] = useState(currentProject ? currentProject.id : undefined);

  const onSearch = (value: any) => {
    const searchFilter = {
      searchByName: {
        type: FilterTypes.MULTISEARCH,
        value: [
          { email: value.target.value },
          { phone: value.target.value },
          { name: value.target.value },
          { surname: value.target.value },
        ],
      },
    };

    onTableChange(null, searchFilter);
  };

  const onChangeStatus = (value: any) => {
    filtersDispatch({
      type: SET_LEADS_FILTERS,
      filters: {
        status: value,
      },
    });
    const statusFilter = {
      isConfirmed: {
        type: FilterTypes.SELECT,
        value: value ? value : undefined,
      },
    };

    onTableChange(null, statusFilter);
  };

  const onSelect = (id: any) => {
    setProjectId(id);
    if (+id !== currentProject.id) {
      const item = user?.projects.find((project: any) => project.id === +id);
      appDispatch({ type: SET_CURRENT_PROJECT, currentProject: item });
      appDispatch({
        type: SET_CURRENT_PROPERTY,
        currentProperty: user.properties.find((property: any) => property.project.id === item?.id),
      });
    }
  };

  return (
    <div className={css['leads-control-container']}>
      <div className={css['leads-filter-control']}>
        <div className={`${css['leads-filters']} budget-control-wrapper`}>
          <Select
            style={{ padding: '5px 0', marginRight: '1rem' }}
            className="consumers-control-select common-animation-primary"
            value={projectId}
            onChange={onSelect}
            dropdownClassName="consumers-control-select-dropdown"
            placeholder={t('properties.placeholder.change.project')}
            suffixIcon={
              <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
            }>
            {user &&
              user.projects?.length > 0 &&
              user.projects
                .filter((project) => user.projects.find((item) => item.id === project.id))
                .map((project) => (
                  <Select.Option value={project.id} key={project.id} className="budget-control-option">
                    <span style={{ fontSize: '14px' }}>{project.title}</span>
                  </Select.Option>
                ))}
          </Select>
          <Search
            allowClear
            className="tickets-control-search common-animation-primary"
            style={{ width: '280px', padding: '4px 0', marginRight: 20 }}
            placeholder={t('leads.control.search.placeholder')}
            suffix={<img src={searchIcon} alt="" />}
            onChange={onSearch}
          />
          <Select
            defaultValue={leadsFilter.status}
            style={{ padding: '5px 0', marginRight: 20 }}
            className="consumers-control-select common-animation-primary"
            onChange={onChangeStatus}
            dropdownClassName="consumers-control-select-dropdown"
            placeholder={t('leads.control.filter.placeholder.status')}
            suffixIcon={
              <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
            }>
            <Option value="" className="budget-control-option">
              <span style={{ fontSize: '14px', fontWeight: 600 }}>{t('leads.control.filter.defvalue.status')}</span>
            </Option>
            <Option value="true" className="budget-control-option">
              <span style={{ fontSize: '14px', fontWeight: 600 }}>
                {t('consumers.table.columns.isConfirmed.confirmed')}
              </span>
            </Option>
            <Option value="false" className="budget-control-option">
              <span style={{ fontSize: '14px', fontWeight: 600 }}>
                {t('consumers.table.columns.isConfirmed.unconfirmed')}
              </span>
            </Option>
          </Select>
        </div>
      </div>
      <Link
        to={`${props.history.location.pathname}/modify`}
        style={{ borderRadius: 4 }}
        className="common-animation-primary">
        <Button className={`common-blue-btn common-primary-btn`} style={{ height: '42px' }}>
          {t('common.btn.new')}
        </Button>
      </Link>
    </div>
  );
};

export default LeadsControl;
