export enum FilterTypes {
  SELECT = 'SELECT',
  SELECT_MULTIPLE = 'SELECT_MULTIPLE',
  // INPUT to search for equality, SEARCH to search for a substring
  SEARCH = 'SEARCH',
  INPUT = 'INPUT',
  SWITCH = 'SWITCH',
  DATE = 'DATE',
  DATE_RANGE = 'DATE_RANGE',
  RADIO = 'RADIO',
  CHECKBOX = 'CHECKBOX',
  MULTISEARCH = 'MULTISEARCH',
  NOT = 'NOT',
  AND = 'AND',
  OR = 'OR',
  DATE_LESS_THAN = 'DATE_LESS_THAN'
}
