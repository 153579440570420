import { Button, Form, notification, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../assets/styles/meetings.module.css';
import MeetingFormComponent from './MeetingFormComponent';
import MeetingsList from './MeetingsList';
import MeetingsService from '../../../services/MeetingsService';
import { MeetingsModel } from '../../../models/MeetingsModel';
import moment from 'moment';
import PreviewMeetingForm from './PreviewMeetingForm';
import MeetingForm from './MeetingForm';
import DeleteMeetingForm from './DeleteMeetingForm';
import UsersService from '../../../services/UsersService';
import { formatDate } from '../../../helpers/dateTools';
import ModalUploadFileSuccess from '../../../components/common/Modals/ModalUploadFileSuccess';
import { useParams } from 'react-router';
import { IResponceData } from '../../../typings/IServiceOptions';
import { IUserEasy } from '../../../models/UserModel';

const meetingService = new MeetingsService();
const userService = new UsersService();

interface ITabPage {
  consumerId?: number;
}

const MeetingsTabPage = (props: ITabPage) => {
  const { consumerId } = props;
  const params = useParams<{ propertyId: string }>();
  const { propertyId } = params;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [fetchingData, setFetchingData] = useState(false);
  const [meetings, setMeetings] = useState<MeetingsModel>([]);
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenUploadMessageModal, setIsOpenUploadMessageModal] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [consumers, setConsumers] = useState<IUserEasy[]>([]);
  const [begin, setBegin] = useState(moment().startOf('month').format('YYYY-MM-DD hh:mm'));
  const [end, setEnd] = useState(moment().endOf('month').format('YYYY-MM-DD hh:mm'));
  const [currDate, setCurrDate] = useState(moment());
  const [selectConsumerId, setSelectConsumerId] = useState<null | number>(null);
  const [isDisabled, setIsDisabled] = useState<boolean>(consumerId ? false : !selectConsumerId);

  const [isLastUserReq, setIsLastUserReq] = useState(false);
  const [fetchingUserData, setFetchingUserData] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    skip: 0,
  });

  useEffect(() => {
    setIsDisabled(consumerId ? false : !selectConsumerId);
  }, [selectConsumerId]);

  useEffect(() => {
    if (consumerId || selectConsumerId) {
      getMeetings();
      // form.setFieldsValue({
      //   assignees: [consumerId ? +consumerId : +(selectConsumerId as number)],
      // });
    }
  }, [currDate, selectConsumerId, consumerId]);

  useEffect(() => {
    getConsumers();
  }, []);

  const getConsumers = () => {
    const optionUser: { [name: string]: any } = {
      count: true,
      top: pagination.pageSize,
      current: pagination.current,
      skip: pagination.pageSize * (pagination.current - 1) || 0,
      propertyId: [propertyId],
      roles: ['consumer'],
    };

    setFetchingUserData(true);
    userService
      .getEasyUsers(optionUser)
      .then((res: IResponceData<IUserEasy>) => {
        const { count, items } = res;
        const { current, top } = optionUser;
        setPagination({ ...pagination, current: current + 1, total: count, pageSize: top });
        setConsumers([...consumers, ...items]);
        setIsLastUserReq(current * top >= count);
      })
      .finally(() => setFetchingUserData(false));
  };

  const getMeetings = () => {
    setFetchingData(true);
    meetingService
      .getMeetings({
        filter: {
          and: [
            {
              datetime: {
                ge: moment(begin).set({ hour: 0, minute: 0 }).format('YYYY-MM-DD HH:mm'),
              },
            },
            {
              datetime: {
                lt: moment(end).set({ hour: 0, minute: 0 }).add(1, 'day').format('YYYY-MM-DD HH:mm'),
              },
            },
          ],
          or: [
            {
              'assignees/id': {
                eq: consumerId ? +consumerId : +(selectConsumerId as number),
              },
            },
            {
              'created_user/id': {
                eq: consumerId ? +consumerId : +(selectConsumerId as number),
              },
            },
          ],
        },
      })
      .then((value: MeetingsModel) => setMeetings(value))
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingData(false));
  };
  const onSubmit = (values: any) => {
    const date = new Date(
      moment(values.date).format('YYYY-MM-DD') + ' ' + moment(values.time).format('HH:mm'),
    ).toUTCString();

    const body = {
      name: values.name,
      details: values.details,
      assignees:
        values.assignees.map((assignee: any) => {
          return { id: assignee };
        }) || [],
      datetime: date,
    };

    meetingService
      .createMeeting(body)
      .then(() => {
        notification.success({
          message: t('meetings.success.create'),
        });
        form.setFieldsValue({
          name: null,
          details: null,
          date: null,
          time: null,
          assignees: [consumerId ? +consumerId : +(selectConsumerId as number)],
        });
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => {
        getMeetings();
        setFetchingData(false);
      });
  };

  const openEdit = (id?: number) => {
    if (id) {
      setCurrentId(id);
    }
    setIsOpenEdit(true);
  };

  const closeEdit = () => {
    setCurrentId(null);
    setIsOpenEdit(false);
    getMeetings();
  };

  const openDelete = (id?: number) => {
    if (id) {
      setCurrentId(id);
    }
    setIsOpenDelete(true);
  };

  const closeDelete = () => {
    setCurrentId(null);
    setIsOpenDelete(false);
    getMeetings();
  };

  const openPreview = (id?: number) => {
    if (id) {
      setCurrentId(id);
    }
    setIsOpenPreview(true);
  };

  const closePreview = () => {
    setCurrentId(null);
    setIsOpenPreview(false);
    getMeetings();
  };

  const setMonth = (step: string) => {
    const currentMonth = currDate.clone();
    if (step === 'prev') {
      currentMonth.subtract(1, 'months');
      setBegin(currentMonth.format('YYYY-MM-01'));
      setEnd(currentMonth.format('YYYY-MM-') + currentMonth.daysInMonth());
    } else {
      currentMonth.add(1, 'months');
      setBegin(currentMonth.format('YYYY-MM-01'));
      setEnd(currentMonth.format('YYYY-MM-') + currentMonth.daysInMonth());
    }
    setCurrDate(currentMonth);
    getMeetings();
  };

  return (
    <div className={css['container-tab']}>
      {!consumerId && (
        <Select
          value={selectConsumerId || undefined}
          style={{ height: '36px', maxWidth: '300px' }}
          className="common-select common-animation-primary"
          dropdownClassName={`budget-control-select-dropdown ${
            fetchingUserData ? 'consumers-control-select-dropdown--progress' : ''
          }`}
          onPopupScroll={(e: any) => {
            e.persist();
            let target = e.target;
            if (!fetchingUserData && !isLastUserReq && target.scrollTop + target.offsetHeight === target.scrollHeight) {
              getConsumers();
            }
          }}
          onChange={(id: number) => setSelectConsumerId(id)}
          placeholder={t('tickets.create.placeholder.acquirer')}
          suffixIcon={
            <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
          }>
          {consumers &&
            consumers.length > 0 &&
            consumers.map((u) => (
              <Select.Option value={u.id} key={u.id} className="budget-control-option">
                <span style={{ fontSize: '14px' }}>{`${u.firstName} ${u.lastName}`}</span>
              </Select.Option>
            ))}
        </Select>
      )}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className={css['meeting-tab-form']}>
          <div className={css['tab-title']} style={{ marginTop: '0.8rem' }}>
            <span style={{ color: '#8fa2c4' }}>{t('meetings.create.meeting.new')}</span>
          </div>
          <MeetingFormComponent onSubmit={onSubmit} isTab={true} form={form} />
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              className={`common-blue-btn common-primary-btn`}
              disabled={fetchingData}
              onClick={() => form.submit()}
              style={{ right: 0 }}>
              {t('meetings.create.meeting')}
            </Button>
          </div>
        </div>
        <div className={css['meeting-tab-list']}>
          <div
            className={css['tab-title']}
            style={{ marginBottom: '2rem', display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
            <span style={{ color: '#8fa2c4' }}>{t('meetings.existings')}</span>
            <Row className={css['calendar-header-tab']}>
              <div className={css['header-date']} style={{ display: 'flex', alignItems: 'center' }}>
                <span className={css['arrows-container']}>
                  <Button className={css['arrow-container']} onClick={() => setMonth('prev')} disabled={isDisabled}>
                    <div className={`${css['arrow']} ${css['arrow-left']}`}></div>
                  </Button>
                  <span
                    className={css['current-date']}
                    style={{ display: 'flex', alignItems: 'center', marginRight: '0.5rem' }}>{`${formatDate(
                    currDate,
                    'MMMM YYYY',
                    true,
                  )}`}</span>
                  <Button className={css['arrow-container']} onClick={() => setMonth('next')} disabled={isDisabled}>
                    <div className={`${css['arrow']} ${css['arrow-right']}`}></div>
                  </Button>
                </span>
              </div>
            </Row>
          </div>
          <div className={css['meetings-tab-list-container']}>
            {!selectConsumerId && (
              <div style={{ textAlign: 'center', color: 'rgb(143, 162, 196)' }}>
                {t('meetings.notification.select.project')}
              </div>
            )}
            <MeetingsList
              openDelete={openDelete}
              openEdit={openEdit}
              openPreview={openPreview}
              isTab={true}
              meetings={meetings}
            />
          </div>
        </div>
        {isOpenPreview && (
          <PreviewMeetingForm
            isOpen={isOpenPreview}
            id={currentId}
            setClose={closePreview}
            openDelete={openDelete}
            openEdit={openEdit}
            setIsOpenUploadMessageModal={setIsOpenUploadMessageModal}
          />
        )}
        {isOpenUploadMessageModal && (
          <ModalUploadFileSuccess isOpen={isOpenUploadMessageModal} onOk={() => setIsOpenUploadMessageModal(false)} />
        )}
        {isOpenEdit && <MeetingForm isOpen={isOpenEdit} id={currentId} setClose={closeEdit} />}
        {isOpenDelete && currentId && (
          <DeleteMeetingForm currentId={currentId} isOpen={isOpenDelete} setClose={closeDelete} />
        )}
      </div>
    </div>
  );
};

export default MeetingsTabPage;
