import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Spin,
  TreeSelect,
  Upload,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../assets/styles/post.module.css';
import calendarIcon from '../../../assets/images/ic-calendar.svg';
import arrowIcon from '../../../assets/images/ic-arrow-fill.svg';
import icAttach from '../../../assets/images/ic-attach.svg';
import { CalendarTypes, EditUpdate, NewUpdate } from '../../../models/CalendarModel';
import CalendarService from '../../../services/CalendarServices';
import { AppContext } from '../../../contexts/AppContextProvider';
import ListPageServices from '../../../services/PropertiesService';
import moment from 'moment';
import config from '../../../config';
import { authDownloadFile } from '../../../helpers/authFileTools';
import SunEditor from 'suneditor-react';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { SelectValue } from 'antd/lib/select';
import { IAppContext } from '../../../typings/IApp';

const { Option } = Select;

const service = new CalendarService();
const propertyService = new ListPageServices();

interface IPostModalForm {
  isOpenModal: boolean;
  defaultType: CalendarTypes | undefined;
  closeModal: () => void;
  getCalendarUpdates?: () => void;
  postId?: number | null | undefined;
  setIsOpenUploadMessageModal?: (b: boolean) => void;
  projectId?: number;
  propertyId?: number;
}

const PostModalForm = ({
  isOpenModal,
  defaultType,
  closeModal,
  getCalendarUpdates,
  postId,
  setIsOpenUploadMessageModal,
  projectId,
  propertyId,
}: IPostModalForm) => {
  const { t } = useTranslation();
  const {
    app: { user },
  } = useContext<IAppContext>(AppContext);
  const [form] = Form.useForm();
  const [fetchingData, setFetchingData] = useState(false);
  const [isLoading, setIsLoading] = useState(!!postId);
  const [selectType, setSelectType] = useState(defaultType);
  const [description, setDescription] = useState('');
  const [properties, setProperties] = useState<any[]>([]);
  const [selectedProject, setSelectedProject] = useState<SelectValue>(
    user.projects.length === 1 ? user.projects[0].id : projectId || undefined,
  );
  const [filesToDelete, setFilesToDelete] = useState<string[]>([]);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isHaveFile, setIsHaveFile] = useState(true);
  const [isDisabled, setIsDisabled] = useState(!!propertyId || !!projectId);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [updatePostData, setUpdatePostData] = useState<EditUpdate | null>(null);

  useEffect(() => {
    if (postId) {
      getCalendarUpdate(postId);
    }
  }, [postId]);

  useEffect(() => {
    form.setFieldsValue({ properties: undefined });
    if (selectedProject) {
      const projectKey = (user && user.projects.find((project) => project.id === selectedProject)?.key) || '';
      propertyService
        .getLightTreeByProject({
          id: selectedProject,
          projectKey,
        })
        .then((data) => {
          setProperties(data);
        });
    } else {
      setProperties([]);
    }
  }, [selectedProject]);

  useEffect(() => {
    if (!postId) {
      setFileList([]);
      setDescription('');
      form.resetFields();
      setIsHaveFile(true);
    }
  }, [selectType]);

  const getCalendarUpdate = async (id: number) => {
    setFetchingData(true);
    setIsLoading(true);
    await service
      .getCalendarUpdateById(id)
      .then((value) => {
        const projectKey =
          (user && user.projects.find((project) => project.id === value.properties[0].project.id)?.key) || '';
        propertyService
          .getLightTreeByProject({
            id: value.properties[0].project.id,
            projectKey,
          })
          .then((data) => {
            setProperties(data);
          });
        setSelectType(value.type.code);
        setDescription(value.description);
        setIsDisabled(true);
        form.setFieldsValue({
          date: moment(value.date),
          properties: value.properties.map((property: any) => property.id),
          name: value.name,
          project: value.properties[0].project.id,
          description: value.description,
        });
        if (value.files) {
          setFileList(
            value.files.map((file: any) => ({
              name: file.fileName,
              uid: file.id,
              url: `${config.storeUrl}/${file.file}`,
              file: file.file,
            })),
          ) as any;
        }
      })
      .catch((e) =>
        notification.error({
          message: e.message || e,
        }),
      )
      .finally(() => {
        setFetchingData(false);
        setIsLoading(false);
      });
  };

  const handleFile = (info: UploadChangeParam<UploadFile<any>>) => {
    const newFilesToDelete = filesToDelete.slice(0);

    if (info.file.status && info.file.status === 'removed') {
      newFilesToDelete.push(info.file.uid);
    }

    setFilesToDelete(newFilesToDelete);
    setFileList(info.fileList);
    setIsHaveFile(true);
  };

  const successUpdate = () => {
    getCalendarUpdates && getCalendarUpdates();
    closeModal();
    notification.success({
      message: postId ? t('post.notification.updated') : t('post.notification.created'),
    });
  };

  const updatePost = async () => {
    setFetchingData(true);
    const filesData = new FormData();
    for (const file of fileList) {
      if (file.originFileObj) {
        filesData.append('file', file.originFileObj);
      }
    }

    if (updatePostData) {
      const requests = [service.editUpdate(updatePostData)];

      if (filesData.getAll('file').length > 0) {
        requests.push(service.uploadUpdatesFiles(postId as number, filesData));
      }

      if (filesToDelete.length > 0) {
        requests.push(service.deleteCalendarFiles(postId as number, filesToDelete));
      }

      setIsOpenUploadMessageModal && setIsOpenUploadMessageModal(true);
      return Promise.all(requests)
        .then(() => {
          successUpdate();
        })
        .catch((e) => {
          notification.error({
            message: e,
          });
        })
        .finally(() => {
          setFetchingData(false);
        });
    }
  };

  const onSubmit = async (values: any) => {
    if ((selectType === CalendarTypes.document || selectType === CalendarTypes.image) && fileList.length < 1) {
      setIsHaveFile(false);
      return;
    }
    let newUpdate: NewUpdate = {
      description: description || null,
      isPublished: true,
      date: values.date.format('YYYY-MM-DD hh:mm'),
      properties: values.properties.map((id: number) => ({ id })),
      type: {
        code: selectType as CalendarTypes,
      },
    };
    if (selectType === CalendarTypes.document) {
      newUpdate['name'] = values.name;
    }
    if (postId) {
      (newUpdate as EditUpdate)['id'] = postId;
    }

    const filesData = new FormData();
    for (const file of fileList) {
      if (file.originFileObj) {
        filesData.append('file', file.originFileObj);
      }
    }

    if (postId) {
      setUpdatePostData(newUpdate as EditUpdate);
      setIsOpenConfirm(true);
    } else {
      setFetchingData(true);
      await service
        .addCalendarUpdate(newUpdate)
        .then((value) => {
          if (filesData.getAll('file').length > 0 && value.id) {
            setIsOpenUploadMessageModal && setIsOpenUploadMessageModal(true);
            service
              .uploadUpdatesFiles(value.id, filesData)
              .then(() => {
                successUpdate();
              })
              .catch((e) =>
                notification.error({
                  message: e.message || e,
                }),
              );
          } else {
            successUpdate();
          }
        })
        .catch((e) =>
          notification.error({
            message: e.message || e,
          }),
        )
        .finally(() => setFetchingData(false));
    }
  };

  return (
    <Spin spinning={fetchingData}>
      <Modal
        className="common-modal common-modal-post"
        centered
        style={{ width: '20rem' }}
        visible={isOpenModal}
        title={
          isOpenConfirm ? (
            t('post.warning.update.title')
          ) : (
            <div className={css['post-form-title']}>
              <span
                style={{
                  fontWeight: 500,
                  fontSize: '14px',
                  color: '#00122D',
                  marginRight: '1rem',
                  whiteSpace: 'nowrap',
                }}>
                {`${t('post.update.title')} :`}
              </span>
              <Select
                style={{ padding: '5px 0', height: '32px' }}
                className="consumers-control-select post-modal-title-select common-animation-primary"
                dropdownClassName="consumers-control-select-dropdown"
                value={selectType}
                onChange={setSelectType}
                disabled={isDisabled}
                suffixIcon={
                  <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
                }>
                <Option value={CalendarTypes.update}>{t('post.update')}</Option>
                <Option value={CalendarTypes.document}>{t('post.documents')}</Option>
                <Option value={CalendarTypes.image}>{t('post.image')}</Option>
              </Select>
            </div>
          )
        }
        footer={
          <div className="common-modal-footer">
            <Button
              className={`common-gray-border-btn common-secondary-btn`}
              onClick={() => (isOpenConfirm ? setIsOpenConfirm(false) : closeModal())}
              disabled={fetchingData}>
              {t('budget.modal.cancel.btn')}
            </Button>
            <Button
              className={`common-green-btn common-modal-btn`}
              onClick={isOpenConfirm ? updatePost : form.submit}
              disabled={fetchingData}>
              {postId ? t('budget.modal.save.btn') : t('post.create.btn')}
            </Button>
          </div>
        }
        closable={false}>
        {isOpenConfirm ? (
          <div style={{ display: 'flex', justifyContent: 'center', color: '#00122D', fontWeight: 400, margin: '1rem' }}>
            {t('post.warning.update.description')}
          </div>
        ) : (
          <Form autoComplete="off" onFinish={onSubmit} form={form} style={{ maxHeight: '85vh', overflowY: 'auto' }}>
            <div style={{ margin: '1rem' }}>
              {(selectType === CalendarTypes.update || selectType === CalendarTypes.post) && (
                <Form.Item colon={false} style={{ margin: 0 }} name="description">
                  <div className={`${css['edit-field']} common-animation-primary`}>
                    {!isLoading && (
                      <SunEditor
                        defaultValue={description || ''}
                        onChange={(value) => setDescription(value)}
                        setDefaultStyle="font-family: Arial"
                        setOptions={{
                          buttonList: [
                            ['undo', 'redo'],
                            ['fontSize', 'formatBlock'],
                            ['outdent', 'indent'],
                            ['bold', 'underline', 'italic', 'strike', 'list', 'link'],
                            ['fontColor', 'hiliteColor'],
                            ['removeFormat'],
                            ['fullScreen'],
                          ],
                        }}
                      />
                    )}
                    <Upload
                      className={'upload-updates post-upload'}
                      listType="text"
                      beforeUpload={() => false}
                      fileList={fileList}
                      onPreview={(file: any) => {
                        authDownloadFile(file.file, file.name);
                      }}
                      multiple
                      onChange={handleFile}
                      iconRender={() => <img src={icAttach} style={{ height: '15px', width: '15px' }} />}>
                      <Button className="common-upload-file-btn">
                        <img src={icAttach} alt="" className={css['ic-attach']} />
                        <span className={css['attach-file-title']}>{t('calendar.arrach.file')}</span>
                      </Button>
                    </Upload>
                  </div>
                </Form.Item>
              )}
              {selectType !== CalendarTypes.update && selectType !== CalendarTypes.post && (
                <Form.Item className={css['form-item-post']} colon={false} initialValue={fileList} name="file">
                  <Upload
                    maxCount={selectType === CalendarTypes.document ? 1 : undefined}
                    accept={selectType === CalendarTypes.image ? '.png, .jpg, .jpeg' : '.pdf'}
                    className={`consumers-import-upload-item ${
                      selectType === CalendarTypes.image ? 'upload-post-item upload-list-inline' : ''
                    }
                    ${
                      selectType === CalendarTypes.document &&
                      (fileList.length < 1 ? 'upload-post-item' : 'upload-post-document')
                    }`}
                    style={{ width: '100%' }}
                    beforeUpload={() => false}
                    onChange={handleFile}
                    onRemove={() => true}
                    onPreview={(file: any) => {
                      authDownloadFile(file.file, file.name);
                    }}
                    multiple={true}
                    listType={selectType === CalendarTypes.image ? 'picture-card' : 'text'}
                    fileList={fileList}>
                    {selectType === CalendarTypes.document && fileList.length < 1 && (
                      <div className={css['upload-item-post']}>
                        <div
                          className={css['upload-img']}
                          style={{ marginRight: '0.5rem', width: '18px', height: '14px' }}></div>
                        <span style={{ color: '#076ee5' }}>{`${t('gallery.modal.upload.btn.title')} *`}</span>
                      </div>
                    )}
                    {selectType === CalendarTypes.image && (
                      <div className={css['upload-item-post']}>
                        <div
                          className={css['upload-img']}
                          style={{ marginRight: '0.5rem', width: '18px', height: '14px' }}></div>
                        <span style={{ color: '#076ee5' }}>{`${t('gallery.modal.upload.btn.title')} *`}</span>
                      </div>
                    )}
                  </Upload>
                  {!isHaveFile && <span style={{ color: '#ff4d4f' }}>{t('common.validation.required')}</span>}
                </Form.Item>
              )}
              {selectType === CalendarTypes.document && (
                <Form.Item
                  className={css['form-item']}
                  style={{ margin: '0' }}
                  colon={false}
                  label={<span style={{ color: '#778dac' }}>{t('post.name.title')} *</span>}
                  name="name"
                  rules={[{ required: true, message: t('common.validation.required') }]}
                  labelAlign="left">
                  <Input className="common-animation-primary" />
                </Form.Item>
              )}
              {selectType === CalendarTypes.image && (
                <Form.Item
                  className={css['form-item']}
                  style={{ margin: '0' }}
                  label={<span style={{ color: '#778dac' }}>{t('budget.form.description')}</span>}
                  colon={false}
                  name="description"
                  labelAlign="left">
                  <TextArea
                    value={description}
                    autoSize={{ minRows: 1, maxRows: 1 }}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Form.Item>
              )}
              {selectType === CalendarTypes.document && (
                <Form.Item
                  className={css['form-item']}
                  style={{ margin: '0' }}
                  label={<span style={{ color: '#778dac' }}>{t('budget.form.description')}</span>}
                  colon={false}
                  name="description"
                  labelAlign="left">
                  <TextArea
                    value={description}
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Form.Item>
              )}
            </div>
            <Divider style={{ margin: 0 }} />
            <div style={{ margin: '1rem' }}>
              <Row gutter={12} style={{ marginBottom: '1rem' }}>
                <Col xs={6} sm={6} md={5} style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={arrowIcon} alt="" width={13} height={12} style={{ marginLeft: '0.5rem' }} />
                  <span
                    style={{
                      marginLeft: '0.5rem',
                      fontSize: '12px',
                      color: '#778DAC',
                      fontWeight: 400,
                      whiteSpace: 'nowrap',
                    }}>
                    {t('tasks.assign.to')} *:
                  </span>
                </Col>
                <Col span={8}>
                  <Form.Item
                    colon={false}
                    style={{ width: '100%', margin: 0 }}
                    initialValue={selectedProject}
                    rules={[{ required: true, message: t('common.validation.required') }]}
                    name="project">
                    <Select
                      disabled={!!projectId}
                      style={{ padding: '5px 0' }}
                      className="consumers-control-select post-modal-field common-animation-primary"
                      dropdownClassName="consumers-control-select-dropdown"
                      placeholder={t('tickets.table.columns.project')}
                      onChange={setSelectedProject}
                      suffixIcon={
                        <div
                          className={`arrow-drop-img ${css['arrow-drop']}`}
                          style={{ width: '8px', height: '4px' }}></div>
                      }>
                      {user &&
                        user.projects &&
                        user.projects
                          .sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))
                          .map((project) => (
                            <Select.Option value={project.id} key={project.id} className="documents-control-option">
                              <span style={{ fontSize: '14px', fontWeight: 600 }}>{project.title}</span>
                            </Select.Option>
                          ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={10} sm={10} md={11}>
                  <Form.Item
                    colon={false}
                    style={{ width: '100%', margin: 0 }}
                    initialValue={propertyId ? [propertyId] : []}
                    rules={[{ required: true, message: t('common.validation.required') }]}
                    name="properties">
                    <TreeSelect
                      disabled={!!propertyId}
                      treeData={properties || undefined}
                      multiple
                      showCheckedStrategy="SHOW_CHILD"
                      treeCheckable
                      maxTagCount={1}
                      style={{ padding: '5px 0' }}
                      placeholder={t('consumers.control.search.placeholder.property')}
                      className="faq-modify-select post-modal-field common-animation-primary"
                      fieldNames={{ label: 'title', value: 'id', children: 'childrens' }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col xs={6} sm={6} md={5} style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={calendarIcon} alt="" width={12} height={12} style={{ marginLeft: '0.5rem' }} />
                  <span
                    style={{
                      marginLeft: '0.5rem',
                      fontSize: '12px',
                      color: '#778DAC',
                      fontWeight: 400,
                      whiteSpace: 'nowrap',
                    }}>
                    {t('requests.table.columns.createdDate')} *:
                  </span>
                </Col>
                <Col xs={18} sm={18} md={19}>
                  <Form.Item
                    colon={false}
                    style={{ width: '100%', margin: 0 }}
                    initialValue={moment()}
                    rules={[{ required: true, message: t('common.validation.required') }]}
                    name="date">
                    <DatePicker
                      disabled={!!propertyId || !!projectId}
                      inputReadOnly
                      style={{ width: '100%', marginRight: 10, height: 42 }}
                      suffixIcon={<div className={'calendar-img'} style={{ width: '12px', height: '12px' }}></div>}
                      className={`common-date-picker common-animation-primary ${css['post-form-datePicker']}`}
                      format="DD.MM.YYYY"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Modal>
    </Spin>
  );
};

export default PostModalForm;
