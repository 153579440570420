import {
  Badge,
  Button,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Modal,
  notification,
  Row,
  Select,
  TimePicker,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../assets/styles/meetings.module.css';
import MeetingsList from './MeetingsList';
import MeetingsService from '../../../services/MeetingsService';
import { MeetingModel, MeetingsModel } from '../../../models/MeetingsModel';
import moment from 'moment';
import PreviewMeetingForm from './PreviewMeetingForm';
import MeetingForm from './MeetingForm';
import DeleteMeetingForm from './DeleteMeetingForm';
import { AppContext } from '../../../contexts/AppContextProvider';
import UsersService from '../../../services/UsersService';
import { formatDate } from '../../../helpers/dateTools';
import TextArea from 'antd/lib/input/TextArea';
import calendarIcon from '../../../assets/images/ic-calendar-date-picker.svg';
import ModalUploadFileSuccess from '../../../components/common/Modals/ModalUploadFileSuccess';
const { Option } = Select;
import { useParams } from 'react-router';
import { IUserEasy } from '../../../models/UserModel';
import { IResponceData } from '../../../typings/IServiceOptions';
import { IAppContext } from '../../../typings/IApp';

const meetingService = new MeetingsService();
const userService = new UsersService();

interface ITabPage {
  consumerId?: number;
}

interface IModal {
  isOpen: boolean;
  onSubmit: (value: any) => void;
  setClose: () => void;
  form: FormInstance;
}

const CreateMeetingModal = ({ onSubmit, isOpen, setClose, form }: IModal) => {
  const { t } = useTranslation();
  const {
    app: { isConsumer, isSupplier, user },
  } = useContext<IAppContext>(AppContext);
  const [currentSelectedUsers, setCurrentSelectedUsers] = useState<IUserEasy[]>([]);
  const [users, setUsers] = useState<IUserEasy[]>([]);
  const [isLastUserReq, setIsLastUserReq] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [searchValue, setSearchValue] = useState<null | string>(null);
  const [currentTimeout, setCurrentTimeout] = useState<NodeJS.Timeout | null>(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    skip: 0,
  });

  useEffect(() => {
    if (isConsumer) {
      const onlyMyAgents = user.properties.map((property) => property.agent);
      //remove dublicates
      const agentsWithoutDublicates = onlyMyAgents
        .filter((agent, index, prev) => prev.findIndex((prev2) => prev2.id === agent.id) === index)
        .map((agent) => ({ ...agent, roles: [{ name: 'agent', description: 'Agent' }] }));
      setUsers(agentsWithoutDublicates);
      getAllUsers([], agentsWithoutDublicates);
    } else {
      getAllUsers();
    }
  }, []);

  useEffect(() => {
    if (searchValue === '') {
      return getAllUsers([], currentSelectedUsers);
    }
    if (searchValue) {
      getAllUsers();
    }
  }, [searchValue]);

  const getAllUsers = (usersId?: number[], usersRes: IUserEasy[] = []) => {
    if (usersId && usersId.length > 0) {
      userService
        .getEasyUsers({ userId: usersId })
        .then((res: IUserEasy[]) => {
          setCurrentSelectedUsers(res);
          getAllUsers([], res);
        })
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        );
      return;
    }
    const optionUser = {
      count: true,
      top: pagination.pageSize,
      current: pagination.current,
      skip: pagination.pageSize * (pagination.current - 1) || 0,
      fullName: searchValue || '',
      projectId: isSupplier
        ? user.suppliersProperties.map((sup) => sup.id)
        : user.properties.map((property) => property.id),
      roles: isConsumer ? ['consumer', 'supplier'] : ['consumer', 'supplier', 'agent', 'admin', 'super_agent'],
    };

    setFetchingData(true);
    userService
      .getEasyUsers(optionUser)
      .then((res: IResponceData<IUserEasy>) => {
        const { count, items } = res;
        const { current, top } = optionUser;
        setPagination({ ...pagination, current: current + 1, total: count, pageSize: top });
        let usersIds: number[] = [];
        if (users.length > 0) {
          usersIds = users.map((user) => user.id);
        } else if (usersRes.length > 0) {
          usersIds = usersRes.map((user) => user.id);
        }
        setUsers([...usersRes, ...users, ...items.filter((item) => !usersIds.includes(item.id))]);
        setIsLastUserReq(current * top >= count);
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingData(false));
  };

  const getLabel = (roles: string[]) => {
    if (roles.length === 1 && roles.includes('super_agent')) {
      return <span style={{ color: '#0065de' }}>{t('quotes.modal.form.supper.agents')}</span>;
    }
    if ((!roles.includes('consumer') && !roles.includes('supplier')) || roles.includes('agent')) {
      return <span style={{ color: '#0065de' }}>{t('quotes.modal.form.agents')}</span>;
    }
    if (roles.includes('consumer')) {
      return <span style={{ color: '#e08b32' }}>{t('quotes.modal.form.acquirers')}</span>;
    }
    if (roles.includes('supplier')) {
      return <span style={{ color: '#b37feb' }}>{t('tasks.control.suppliers')}</span>;
    }
    return;
  };

  return (
    <Modal
      className="common-modal"
      centered
      style={{ width: '10rem' }}
      visible={isOpen}
      title={<span style={{ fontSize: '18px', fontWeight: 600 }}>{t('meetings.create.meeting.new')}</span>}
      footer={
        <div className="common-modal-footer">
          <Button className={`common-gray-border-btn common-secondary-btn`} onClick={() => setClose()}>
            {t('budget.modal.cancel.btn')}
          </Button>
          <Button className={`common-green-btn common-modal-btn`} onClick={() => form.submit()}>
            {t('meetings.create.meeting')}
          </Button>
        </div>
      }
      closable={false}>
      <Form
        onFinish={onSubmit}
        form={form}
        style={{ maxHeight: '90vh', overflowY: 'auto', padding: 0, overflowX: 'hidden' }}>
        <Row gutter={[24, 4]}>
          <Col span={12}>
            <Form.Item
              name="date"
              colon={false}
              rules={[{ required: true, message: t('common.validation.required') }]}
              label={<span style={{ color: '#778dac' }}>{t('budget.form.currentDate')}</span>}
              className={css['form-item']}
              labelAlign="left">
              <DatePicker
                inputReadOnly
                format="DD.MM.YYYY"
                suffixIcon={<div className={'calendar-img'} style={{ width: '12px', height: '12px' }}></div>}
                className="common-date-picker common-animation-primary"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="time"
              colon={false}
              rules={[{ required: true, message: t('common.validation.required') }]}
              label={<span style={{ color: '#778dac' }}>{t('meeting.form.time')}</span>}
              className={css['form-item']}
              labelAlign="left">
              <TimePicker
                inputReadOnly
                format="HH:mm"
                className="common-date-picker common-animation-primary"
                style={{ width: '100%' }}
                minuteStep={5}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 4]}>
          <Col span={24}>
            <Form.Item
              name="name"
              colon={false}
              rules={[{ required: true, message: t('common.validation.required') }]}
              label={<span style={{ color: '#778dac' }}>{t('meeting.form.name')}</span>}
              className={css['form-item']}
              labelAlign="left">
              <Input className="common-animation-primary" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 4]}>
          <Col span={24}>
            <Form.Item
              name="details"
              colon={false}
              rules={[{ required: true, message: t('common.validation.required') }]}
              label={<span style={{ color: '#778dac' }}>{t('meeting.form.details')}</span>}
              className={css['form-item']}
              labelAlign="left">
              <TextArea autoSize={{ minRows: 3, maxRows: 10 }} className="common-animation-primary" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 4]}>
          <Col span={24}>
            <Form.Item
              name="assignees"
              colon={false}
              rules={[{ required: true, message: t('common.validation.required') }]}
              label={<span style={{ color: '#778dac' }}>{t('meeting.assigned.to')}</span>}
              className={css['form-item']}
              labelAlign="left">
              <Select
                className="common-select common-animation-primary"
                mode="multiple"
                onDropdownVisibleChange={(value) => {
                  if (!value && searchValue) {
                    setUsers([]);
                    setPagination({ ...pagination, current: 1, skip: 0 });
                    setSearchValue('');
                  }
                }}
                onSearch={(value) => {
                  setUsers([]);
                  setPagination({ ...pagination, current: 1, skip: 0 });
                  clearTimeout(Number(currentTimeout));
                  setCurrentTimeout(setTimeout(() => setSearchValue(value), 1000));
                }}
                filterOption={() => true}
                dropdownClassName={`${fetchingData ? 'consumers-control-select-dropdown--progress' : ''}`}
                onPopupScroll={(e: any) => {
                  e.persist();
                  let target = e.target;
                  if (
                    !fetchingData &&
                    !isLastUserReq &&
                    target.scrollTop + target.offsetHeight === target.scrollHeight
                  ) {
                    getAllUsers();
                  }
                }}>
                {users.map((user) => (
                  <Option value={user.id} key={user.id}>
                    {`${user.firstName} ${user.lastName}`} ({getLabel(user.roles.map((role) => role.name))})
                    {`${user.firstName} ${user.lastName}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const MeetingsTabMobilePage = (props: ITabPage) => {
  const { consumerId } = props;
  const params = useParams<{ propertyId: string }>();
  const { propertyId } = params;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [fetchingData, setFetchingData] = useState(false);
  const [meetings, setMeetings] = useState<MeetingsModel>([]);
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenUploadMessageModal, setIsOpenUploadMessageModal] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [consumers, setConsumers] = useState<IUserEasy[]>([]);
  const [begin, setBegin] = useState(moment().startOf('month').format('YYYY-MM-DD hh:mm'));
  const [end, setEnd] = useState(moment().endOf('month').format('YYYY-MM-DD hh:mm'));
  const [currDate, setCurrDate] = useState(moment());
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenPicker, setIsOpenPicker] = useState(false);
  const [isChangedDate, setIsChangedDate] = useState(false);
  const [datePickerValue, setDatePickerValue] = useState<moment.Moment | null>(null);
  const [currMeetings, setCurrMeetings] = useState<MeetingsModel>([]);
  const [selectConsumerId, setSelectConsumerId] = useState<null | number>(null);
  const [isDisabled, setIsDisabled] = useState<boolean>(consumerId ? false : !selectConsumerId);

  const [isLastUserReq, setIsLastUserReq] = useState(false);
  const [fetchingUserData, setFetchingUserData] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    skip: 0,
  });

  useEffect(() => {
    setIsDisabled(consumerId ? false : !selectConsumerId);
  }, [selectConsumerId]);

  useEffect(() => {
    if (consumerId || selectConsumerId) {
      getMeetings();
      // form.setFieldsValue({
      //   assignees: [consumerId ? +consumerId : +(selectConsumerId as number)],
      // });
    }
  }, [currDate, selectConsumerId, consumerId]);

  useEffect(() => {
    getConsumers();
  }, []);

  const getConsumers = () => {
    const optionUser: { [name: string]: any } = {
      count: true,
      top: pagination.pageSize,
      current: pagination.current,
      skip: pagination.pageSize * (pagination.current - 1) || 0,
      propertyId: [propertyId],
      roles: ['consumer'],
    };

    setFetchingUserData(true);
    userService
      .getEasyUsers(optionUser)
      .then((res: IResponceData<IUserEasy>) => {
        const { count, items } = res;
        const { current, top } = optionUser;
        setPagination({ ...pagination, current: current + 1, total: count, pageSize: top });
        setConsumers([...consumers, ...items]);
        setIsLastUserReq(current * top >= count);
      })
      .finally(() => setFetchingData(false));
  };

  const getMeetings = () => {
    setFetchingData(true);
    meetingService
      .getMeetings({
        filter: {
          and: [
            {
              datetime: {
                ge: moment(begin).set({ hour: 0, minute: 0 }).format('YYYY-MM-DD HH:mm'),
              },
            },
            {
              datetime: {
                lt: moment(end).set({ hour: 0, minute: 0 }).add(1, 'day').format('YYYY-MM-DD HH:mm'),
              },
            },
          ],
          or: [
            {
              'assignees/id': {
                eq: consumerId ? +consumerId : +(selectConsumerId as number),
              },
            },
            {
              'created_user/id': {
                eq: consumerId ? +consumerId : +(selectConsumerId as number),
              },
            },
          ],
        },
      })
      .then((value: MeetingsModel) => {
        setMeetings(value);
        setCurrMeetings(value);
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingData(false));
  };
  const onSubmit = (values: any) => {
    const date = new Date(
      moment(values.date).format('YYYY-MM-DD') + ' ' + moment(values.time).format('HH:mm'),
    ).toUTCString();

    const body = {
      name: values.name,
      details: values.details,
      assignees:
        values.assignees.map((assignee: any) => {
          return { id: assignee };
        }) || [],
      datetime: date,
    };

    meetingService
      .createMeeting(body)
      .then(() => {
        notification.success({
          message: t('meetings.success.create'),
        });
        form.setFieldsValue({
          name: null,
          details: null,
          date: null,
          time: null,
          eq: consumerId ? +consumerId : +(selectConsumerId as number),
        });
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => {
        getMeetings();
        setFetchingData(false);
        setIsOpenModal(false);
      });
  };

  const openEdit = (id?: number) => {
    if (id) {
      setCurrentId(id);
    }
    setIsOpenEdit(true);
  };

  const closeEdit = () => {
    setDatePickerValue(null);
    setIsChangedDate(false);
    setCurrentId(null);
    setIsOpenEdit(false);
    getMeetings();
  };

  const openDelete = (id?: number) => {
    if (id) {
      setCurrentId(id);
    }
    setIsOpenDelete(true);
  };

  const closeDelete = () => {
    setDatePickerValue(null);
    setIsChangedDate(false);
    setCurrentId(null);
    setIsOpenDelete(false);
    getMeetings();
  };

  const openPreview = (id?: number) => {
    if (id) {
      setCurrentId(id);
    }
    setIsOpenPreview(true);
  };

  const closePreview = () => {
    setDatePickerValue(null);
    setIsChangedDate(false);
    setCurrentId(null);
    setIsOpenPreview(false);
    getMeetings();
  };

  const dateRender = (current: moment.Moment) => {
    const listData = meetings.filter(
      (meeting: MeetingModel) => moment(meeting.datetime).format('YYYY-MM-DD') === current.utc().format('YYYY-MM-DD'),
    );

    return (
      <div
        className="ant-picker-cell-inner"
        onClick={() => {
          setCurrMeetings(listData);
          setDatePickerValue(current);
          setIsOpenPicker(false);
          setIsChangedDate(true);
        }}>
        {listData.length > 0 ? (
          <Badge count={listData.length} size="small" style={{ top: '-4px' }} color="blue">
            <span style={{ fontSize: '18px', marginBottom: '2px' }}>{current.date()}</span>
          </Badge>
        ) : (
          <span style={{ fontSize: '18px', marginBottom: '2px' }}>{current.date()}</span>
        )}
      </div>
    );
  };

  const setMobileCalendarDate = (value: moment.Moment) => {
    setDatePickerValue(null);
    setIsChangedDate(false);
    setCurrDate(value);
    setBegin(value.format('YYYY-MM-01'));
    setEnd(value.format('YYYY-MM-') + value.daysInMonth());
  };

  const resetMobileCalendarDate = () => {
    setDatePickerValue(null);
    getMeetings();
    setIsChangedDate(false);
  };

  return (
    <div className={css['container-tab']}>
      <div className="common-mobile-title-container">
        <span style={{ fontSize: '20px' }}>{t('header.title.Meetings')}</span>
        <Button
          className={`common-blue-btn common-primary-btn`}
          disabled={fetchingData}
          onClick={() => setIsOpenModal(true)}>
          {t('meetings.create.meeting')}
        </Button>
      </div>
      <div className={css['calendar-mobile-container']}>
        {!consumerId && (
          <Select
            value={selectConsumerId || undefined}
            style={{ height: '32px', maxWidth: '300px' }}
            className="common-select common-animation-primary"
            dropdownClassName={`budget-control-select-dropdown ${
              fetchingUserData ? 'consumers-control-select-dropdown--progress' : ''
            }`}
            onChange={(id: number) => setSelectConsumerId(id)}
            onPopupScroll={(e: any) => {
              e.persist();
              let target = e.target;
              if (
                !fetchingUserData &&
                !isLastUserReq &&
                target.scrollTop + target.offsetHeight === target.scrollHeight
              ) {
                getConsumers();
              }
            }}
            placeholder={t('tickets.create.placeholder.acquirer')}
            suffixIcon={
              <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
            }>
            {consumers &&
              consumers.length > 0 &&
              consumers
                .sort((a, b) => a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()))
                .map((u) => (
                  <Select.Option value={u.id} key={u.id} className="budget-control-option">
                    <span style={{ fontSize: '14px' }}>{`${u.firstName} ${u.lastName}`}</span>
                  </Select.Option>
                ))}
          </Select>
        )}
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: '1rem' }}>
          <span style={{ color: '#778dac', fontSize: '16px', marginRight: '1rem' }}>{`${formatDate(
            currDate,
            'MMMM YYYY',
            true,
          )}`}</span>
          <DatePicker
            allowClear={false}
            showToday={false}
            onBlur={() => setIsOpenPicker(false)}
            onClick={() => setIsOpenPicker(true)}
            open={isOpenPicker}
            value={datePickerValue}
            disabled={isDisabled}
            inputReadOnly
            format="DD.MM.YYYY"
            dateRender={dateRender}
            suffixIcon={<img src={calendarIcon} alt="" />}
            style={{ width: '50%' }}
            onPanelChange={(value) => {
              setMobileCalendarDate(moment(value));
            }}
          />
          {isChangedDate && (
            <span onClick={() => resetMobileCalendarDate()} style={{ marginLeft: '0.5rem' }}>
              x
            </span>
          )}
        </div>
      </div>
      <div className={css['meetings-tab-list-container']}>
        {!consumerId && !selectConsumerId && (
          <div
            style={{
              textAlign: 'center',
              color: 'rgb(143, 162, 196)',
              marginTop: '1rem',
            }}>
            {t('meetings.notification.select.project')}
          </div>
        )}
        <MeetingsList
          openDelete={openDelete}
          openEdit={openEdit}
          openPreview={openPreview}
          isTab={true}
          meetings={currMeetings}
        />
      </div>
      {isOpenPreview && (
        <PreviewMeetingForm
          isOpen={isOpenPreview}
          id={currentId}
          setClose={closePreview}
          openDelete={openDelete}
          openEdit={openEdit}
          setIsOpenUploadMessageModal={setIsOpenUploadMessageModal}
        />
      )}
      {isOpenUploadMessageModal && (
        <ModalUploadFileSuccess isOpen={isOpenUploadMessageModal} onOk={() => setIsOpenUploadMessageModal(false)} />
      )}
      {isOpenEdit && <MeetingForm isOpen={isOpenEdit} id={currentId} setClose={closeEdit} />}
      {isOpenDelete && currentId && (
        <DeleteMeetingForm currentId={currentId} isOpen={isOpenDelete} setClose={closeDelete} />
      )}
      {isOpenModal && (
        <CreateMeetingModal
          form={form}
          isOpen={isOpenModal}
          onSubmit={onSubmit}
          setClose={() => {
            setDatePickerValue(null);
            setIsChangedDate(false);
            setIsOpenModal(false);
          }}
        />
      )}
    </div>
  );
};

export default MeetingsTabMobilePage;
