import HttpService from './common/httpService';
import config from '../config';
import generateODataQuery, { IODataModel } from '../helpers/generateODataQuery';
import { NewUpdate, EditUpdate } from '../models/CalendarModel';

class CalendarService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getCalendarUpdates(params: IODataModel = {}) {
    const expand = ['createdUser'];
    const query = generateODataQuery({ ...params, expand });
    return this.httpService.get(`${config.baseRequest}calendarUpdates${query}`);
  }

  public getCalendarUpdateById(id: number | null) {
    return this.httpService.get(`${config.baseRequest}calendarUpdates/${id}`);
  }

  public getLastImageCalendarUpdate(propertyId: number, limit: number) {
    return this.httpService.get(
      `${config.baseRequest}calendarUpdates/images/dashboard?propertyId=${propertyId}&limit=${limit}`,
    );
  }

  public addCalendarUpdate(body: NewUpdate) {
    return this.httpService.post(`${config.baseRequest}calendarUpdates`, body);
  }

  public editUpdate(body: EditUpdate) {
    return this.httpService.put(`${config.baseRequest}calendarUpdates`, body);
  }

  public uploadUpdatesFiles(updateId: number, files: any) {
    return this.httpService.postFile(`${config.baseRequest}calendarUpdates/${updateId}/files`, files);
  }

  public deleteCalendarUpdate(id: number | null) {
    return this.httpService.delete(`${config.baseRequest}calendarUpdates/${id}`);
  }

  public deleteCalendarFiles(id: number | null, filesId: string[]) {
    return this.httpService.delete(`${config.baseRequest}calendarUpdates/${id}/files`, filesId);
  }
}

export default CalendarService;
