import React from 'react';
import { Button } from 'antd';
import i18n from '../../../../../utils/i18n';
import css from '../../../../../assets/styles/faq.module.css';
import { TopicModel } from '../../../../../models/FAQModel';
import { TFunction } from 'react-i18next';

interface TopicsModelProps {
  t: TFunction<'translation'>;
  setOpenDelete: (n: number) => void;
  setOpenUpdate: (n: number) => void;
}

export const getColumns = ({ t, setOpenDelete, setOpenUpdate }: TopicsModelProps) => [
  {
    title: () => <span className="budget-table-tr">№</span>,
    dataIndex: 'id',
    width: 65,
    key: 'id',
  },
  {
    title: () => <span className="budget-table-tr">{t('manage.faq.columns.topic')}</span>,
    key: 'topic',
    render: (cell: TopicModel) => (i18n.language === 'fr-FR' ? cell.topicFr : cell.topicEn),
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.action')}</span>,
    key: 'action',
    width: 150,
    render: (cell: TopicModel) => (
      <div style={{ display: 'flex', backgroundColor: 'inherit' }}>
        <Button
          className={css['edit-faq-btn']}
          style={{ border: 'none', backgroundColor: 'inherit', boxShadow: 'none' }}
          onClick={() => setOpenUpdate(cell.id)}>
          <div className={css['edit-consumer-img']} style={{ height: '15px', width: '15px' }} />
        </Button>
        <Button
          className={css['delete-faq-btn']}
          style={{ border: 'none', backgroundColor: 'inherit', boxShadow: 'none' }}
          onClick={() => setOpenDelete(cell.id)}>
          <div className={css['faq-delete-img']} style={{ height: '15px', width: '15px' }} />
        </Button>
      </div>
    ),
  },
];
