// TODO: unit test
export const validateDateLessThanToday =
  (fieldName: string, compareFieldName: any, message: string) =>
  ({ getFieldValue }: any) => ({
    validator() {
      const value = getFieldValue(fieldName);
      const compareValue = typeof compareFieldName === 'string' ? getFieldValue(compareFieldName) : compareFieldName;

      if (!!value && value.valueOf() < compareValue.valueOf()) {
        return Promise.reject();
      }
      return Promise.resolve();
    },
    message,
  });
