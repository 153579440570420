import React, { useMemo } from 'react';
import Control from './Components/Control';
import ProjectCard from './Components/AllProject/ProjectCard';
import BuildingCard from './Components/Building/BuildingCard';
import { IQuotesInfo } from '../../../models/DashboardModel';
import { useLessThen801 } from '../../../helpers/mediaDetect';
import css from '../../../assets/styles/statistics.module.css';
import { DocumentTypes } from './StatisticsPage';
import { StatusesDocumentsModel } from '../../../models/DocumentsModel';

interface IProps {
  item: IQuotesInfo;
  documentType: DocumentTypes;
  statuses: StatusesDocumentsModel;
  setDocumentType: (v: DocumentTypes) => void;
}

const StatisticsContainer: React.FC<IProps> = ({ item, documentType, statuses, setDocumentType }) => {
  const isAvailabilityProperties = item.properties.length > 0;

  const sortedItems = useMemo(
    () => item.properties.sort((a, b) => a.title?.toLowerCase().localeCompare(b.title?.toLowerCase())),
    [item],
  );

  if (useLessThen801()) {
    return (
      <>
        <Control documentType={documentType} setDocumentType={setDocumentType} />
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <div style={{ flex: '0 0 100%' }}>
            <ProjectCard itemStatuses={item.statuses} statuses={statuses} isAvailabilityProperties={false} />
          </div>
          {isAvailabilityProperties && (
            <div className={css['column100']}>
              {sortedItems.map((property) => (
                <BuildingCard
                  key={property.id}
                  property={property}
                  projectId={item.id}
                  isQuote={documentType === DocumentTypes.quote}
                />
              ))}
            </div>
          )}
        </div>
      </>
    );
  }
  return (
    <>
      <Control documentType={documentType} setDocumentType={setDocumentType} />
      <div style={{ display: 'flex', gap: 10 }}>
        <div style={isAvailabilityProperties ? { flex: '0 0 33%' } : { flex: '0 0 100%', width: '100%' }}>
          <ProjectCard
            itemStatuses={item.statuses}
            statuses={statuses}
            isAvailabilityProperties={isAvailabilityProperties}
          />
        </div>
        {isAvailabilityProperties && (
          <div className={css['column66']}>
            <div className={css['column33']}>
              {sortedItems.map((property, index) => {
                if (index % 2 === 0) {
                  return (
                    <BuildingCard
                      key={property.id}
                      property={property}
                      projectId={item.id}
                      isQuote={documentType === DocumentTypes.quote}
                    />
                  );
                }
                return null;
              })}
            </div>
            <div className={css['column33']}>
              {sortedItems.map((property, index) => {
                if (index % 2 !== 0) {
                  return (
                    <BuildingCard
                      key={property.id}
                      property={property}
                      projectId={item.id}
                      isQuote={documentType === DocumentTypes.quote}
                    />
                  );
                }
                return null;
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default StatisticsContainer;
