import React from 'react';
import { Typography, Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import css from './../../assets/styles/header.module.css';

const { Title } = Typography;

interface IHeaderPage {
  title?: string;
  breadcrumb?: any;
  children?: any;
}

const BreadcrumpComponent = ({ breadcrumb }: any) => {
  return (
    <div className="demo">
      <Breadcrumb className="breadcrump-container">
        {Object.entries(breadcrumb).map(([key, value], index, arr) => (
          <Breadcrumb.Item key={key}>
            {arr.length !== ++index ? (
              <Link to={key} className="breadcrump-link">
                {value as string}
              </Link>
            ) : (
              (value as string)
            )}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  );
};

const HeaderTable = ({ title, breadcrumb, children: Component }: IHeaderPage) => {
  return (
    <div className={css.header}>
      {!!title && <Title level={4}>{title}</Title>}
      <div className={css.con}>
        <BreadcrumpComponent breadcrumb={breadcrumb || {}} />
        {Component}
      </div>
    </div>
  );
};

export default HeaderTable;
