const localStorageEnvelopeIdKey = 'ENVELOPE_ID';

export const setStorageEnvelopeId = (value: string) => {
  if (!value) {
    localStorage.removeItem(localStorageEnvelopeIdKey);
    return;
  }
  localStorage.setItem(localStorageEnvelopeIdKey, value);
};

export const getStorageEnvelopeId = () => {
  return localStorage.getItem(localStorageEnvelopeIdKey);
};
