import React, { useState, useEffect, useContext } from 'react';
import { Button, notification, Spin, Table, TablePaginationConfig } from 'antd';
import { useTranslation } from 'react-i18next';
import { getColumns } from './components/ConsumersColumns';
import { AppContext } from '../../contexts/AppContextProvider';
import { HeaderContext } from '../../contexts/HeaderContextProvider';
import { CHANGE_CURRENT_PAGE } from '../../constants/actionTypes/headerConstants';
import ConsumersControl from './components/ConsumersControl';
import css from '../../assets/styles/consumers.module.css';
import { generateFilterfromObj } from '../../helpers/generateFIlterfromObj';
import ConsumersService from '../../services/ConsumersService';
import { FilterTypes } from '../../constants/filterTypes';
import { generateSorter } from '../../helpers/generateSorter';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { FiltersContext } from '../../contexts/FiltersContextProvider';
import filterIcon from '../../assets/images/ic-filter.svg';
import filterActiveIcon from '../../assets/images/ic-filter-active.svg';
import { useLessThen801 } from '../../helpers/mediaDetect';
import TableMobile from '../common/TableMobile';
import { Link, RouteComponentProps } from 'react-router-dom';
import LogoComponent from '../../components/common/LogoComponent';
import { ConsumersV2Model, ConsumerV2Model } from '../../models/ConsumerModel';
import { SET_CONSUMERS_PAGINATION } from '../../constants/actionTypes/filtersConstants';
import ModalSwitchUserBlock from '../common/ModalSwitchUserBlock';
import { isEmptyObject } from '../../helpers/isEmptyObject';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { IAppContext } from '../../typings/IApp';
import i18n from '../../utils/i18n';

const service = new ConsumersService();

const ConsumersPage: React.FC<RouteComponentProps> = (props) => {
  const { t } = useTranslation();
  const {
    app: { user, isSupplier },
  } = useContext<IAppContext>(AppContext);
  const {
    filters: { consumersFilter },
    filtersDispatch,
  } = useContext(FiltersContext);
  const brokerProjects = user && user.projects.map((item) => item.id);
  const [items, setItems] = useState<ConsumersV2Model>([]);
  const { height: windowHeight } = useWindowDimensions();
  const [fetchingData, setFetchingData] = useState(false);
  const { headerDispatch } = useContext(HeaderContext);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [currentFilters, setCurrentFilters] = useState(
    consumersFilter?.isProperty
      ? {
          'properties/project/id': {
            type: FilterTypes.SELECT,
            value: consumersFilter.project,
          },
          'properties/agent/id': {
            type: FilterTypes.SELECT,
            value: consumersFilter.agent,
          },
          searchByName: {
            type: FilterTypes.MULTISEARCH,
            value: [
              { firstName: consumersFilter.searchLoginEmail },
              { lastName: consumersFilter.searchLoginEmail },
              { email: consumersFilter.searchLoginEmail },
            ],
          },
          'properties/title': {
            type: FilterTypes.SEARCH,
            value: consumersFilter.searchProperty,
          },
        }
      : {
          'projects/id': {
            type: FilterTypes.SELECT,
            value: consumersFilter.project,
          },
          'properties/agent/id': {
            type: FilterTypes.SELECT,
            value: consumersFilter.agent || null,
          },
          searchByName: {
            type: FilterTypes.MULTISEARCH,
            value: [
              { firstName: consumersFilter.searchLoginEmail },
              { lastName: consumersFilter.searchLoginEmail },
              { email: consumersFilter.searchLoginEmail },
            ],
          },
          'properties/title': {
            type: FilterTypes.SEARCH,
            value: consumersFilter.searchProperty,
          },
        },
  );

  const [pagination, setPagination] = useState(
    consumersFilter?.pagination || {
      current: 1,
      pageSize: 10,
      total: 0,
      skip: 0,
    },
  );

  //switch delete
  const [userId, setUserId] = useState<null | number>(null);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isDelete, setIsDelete] = useState(true);
  const [projectId, setProjectId] = useState<number | null>(null);

  const option = {
    filters: generateFilterfromObj(currentFilters).filter,
    count: true,
    top: pagination.pageSize,
    current: pagination.current,
    orderBy: generateSorter(['usersProjects/deletedDate desc', 'id desc']).orderBy,
    byProperties: consumersFilter.isProperty,
    skip: pagination.pageSize * (pagination.current - 1) || 0,
  };

  useEffect(() => {
    headerDispatch({
      type: CHANGE_CURRENT_PAGE,
      currentPage: t('header.title.Consumers'),
      path: 'consumers',
    });
  }, [i18n.language]);

  const getUsers = (option: { [name: string]: any }) => {
    setFetchingData(true);
    service
      .getConsumersV2(option, brokerProjects)
      .then((res) => {
        const { count, items } = res;
        const { current, top } = option;
        setPagination({ ...pagination, current, total: count, pageSize: top });
        setItems(items);
      })
      .catch(() =>
        notification.error({
          message: t('common.error.internalServerError'),
        }),
      )
      .finally(() => setFetchingData(false));
  };

  useEffect(() => {
    getUsers(option);
  }, [props.history.location.pathname]);

  const applyNewFilter = (filters: { [name: string]: any }) => {
    setCurrentFilters({ ...currentFilters, ...filters });
    return { ...currentFilters, ...filters };
  };

  const onTableChange = (
    pagination?: TablePaginationConfig,
    filters?: Record<string, FilterValue | null | any>,
    sorter?: SorterResult<ConsumerV2Model> | SorterResult<ConsumerV2Model>[],
  ) => {
    let params: { [name: string]: any } = {
      ...option,
    };
    if (filters && !isEmptyObject(filters)) {
      params = {
        ...params,
        filters: generateFilterfromObj(applyNewFilter(filters)).filter,
      };
    } else {
      params = {
        ...params,
        filters: generateFilterfromObj(currentFilters).filter,
      };
    }
    if (pagination && pagination.pageSize && pagination.current) {
      filtersDispatch({ type: SET_CONSUMERS_PAGINATION, pagination });

      params = {
        ...params,
        top: pagination.pageSize,
        skip: pagination.pageSize * (pagination.current - 1),
        current: pagination.current,
      };
    }

    if (sorter && !Array.isArray(sorter) && sorter.order) {
      const { order, columnKey } = sorter;
      switch (columnKey) {
        case 'property': {
          params = {
            ...params,
            orderBy: option.orderBy.includes('usersProjects/deletedDate desc')
              ? ['usersProjects/deletedDate desc', `properties/title ${order === 'ascend' ? 'asc' : 'desc'}`]
              : `properties/title ${order === 'ascend' ? 'asc' : 'desc'}`,
          };
          break;
        }
        case 'name': {
          params = {
            ...params,
            orderBy: option.orderBy.includes('usersProjects/deletedDate desc')
              ? [
                  'usersProjects/deletedDate desc',
                  `firstName ${order === 'ascend' ? 'asc' : 'desc'}`,
                  `lastName ${order === 'ascend' ? 'asc' : 'desc'}`,
                ]
              : [`firstName ${order === 'ascend' ? 'asc' : 'desc'}`, `lastName ${order === 'ascend' ? 'asc' : 'desc'}`],
          };
          break;
        }
      }
    } else if (
      filters &&
      !isEmptyObject(filters) &&
      filters['properties/project/id'] &&
      !isEmptyObject(filters['properties/project/id'])
    ) {
      params = {
        ...params,
        orderBy: ['usersProjects/deletedDate desc'],
      };
    }

    getUsers(params);
  };

  const changeOpenFilter = () => {
    setIsOpenFilter(!isOpenFilter);
  };

  const isOpenSwitchUserBlock = (userId: number, projectId: number, isDelete: boolean) => {
    setUserId(userId);
    setProjectId(projectId);
    setIsOpenDelete(true);
    setIsDelete(isDelete);
  };

  const loadMore = (option: { [name: string]: any }) => {
    setFetchingData(true);
    option.skip = option.skip ? option.skip + 10 : 10;
    service
      .getConsumersV2(option, brokerProjects)
      .then((res) => {
        const { count, items } = res;
        const { current, top } = option;
        setPagination({ ...pagination, current, total: count, pageSize: top });
        setItems(items);
      })
      .catch(() =>
        notification.error({
          message: t('common.error.internalServerError'),
        }),
      )
      .finally(() => setFetchingData(false));
  };

  if (useLessThen801()) {
    const data =
      items.length > 0 &&
      (items.map((item) => [
        {
          title: '#',
          description: (
            <LogoComponent id={item.id} name={`${item.firstName} ${item.lastName}`} image={item.image || ''} />
          ),
          type: 'string',
        },
        { title: t('users.table.columns.email'), description: item.email, type: 'string' },
        { title: t('users.table.columns.name'), description: `${item.firstName} ${item.lastName}`, type: 'string' },
        {
          title: t('consumers.table.columns.project'),
          description: item.projectTitle,
          type: 'string',
        },
        {
          title: t('consumers.table.columns.property'),
          description: item.propertyTitle || '__________',
          type: 'string',
        },
        {
          title: t('consumers.table.columns.agent'),
          description: item.agentId ? `${item.agentFirstName} ${item.agentLastName}` : '__________',
          type: 'string',
        },
        {
          title: t('users.table.columns.action'),
          type: 'button',
          openUrl: true,
          btnsInfoUrl: isSupplier
            ? []
            : [
                {
                  url: `/consumers/modify/${item.id}`,
                  urlBtnTitle: t('common.btn.modify.lower'),
                },
              ],
        },
      ]) as any);

    return (
      <>
        <div className="common-mobile-title-container">
          <span style={{ fontSize: '20px' }}>{t('header.title.Consumers')}</span>
          <>
            <div className="common-modbile-filter-container" onClick={changeOpenFilter}>
              <img src={isOpenFilter ? filterActiveIcon : filterIcon} width={16} />
              <span className="filter-title" style={{ marginRight: '1rem' }}>
                {isOpenFilter ? t('common.filter.close') : t('common.filter.open')}
              </span>
            </div>
            {!isSupplier && (
              <Link
                to={`${props.history.location.pathname}/modify`}
                style={{ borderRadius: 4 }}
                className="common-animation-primary">
                <Button className={`common-blue-btn common-primary-btn`} style={{ height: '42px' }}>
                  {t('common.btn.new')}
                </Button>
              </Link>
            )}
          </>
        </div>
        {isOpenFilter && (
          <ConsumersControl
            props={props}
            onTableChange={onTableChange}
            getUsers={getUsers}
            option={option}
            setCurrentFilters={setCurrentFilters}
          />
        )}
        <TableMobile
          data={data}
          isLoading={fetchingData}
          option={option}
          loadMore={loadMore}
          itemsLength={items.length}
          totalItems={pagination.total}
        />
      </>
    );
  }

  return (
    <>
      <Spin spinning={fetchingData}>
        <ConsumersControl
          props={props}
          onTableChange={onTableChange}
          getUsers={getUsers}
          option={option}
          setCurrentFilters={setCurrentFilters}
        />
        <div className={css['consumers-table-container']}>
          <Table
            columns={getColumns({
              getUsers: () => getUsers(option),
              t,
              isSupplier,
              isOpenSwitchUserBlock,
            })}
            onChange={onTableChange}
            size="middle"
            dataSource={items}
            showHeader={true}
            style={{ height: '100%' }}
            scroll={{ y: windowHeight - 280, x: 1300 }}
            className="consumers-info-table"
            rowClassName={(record: ConsumerV2Model) => {
              if (record.usersProjects_deleted_date) {
                return 'common-table-row--delete-user';
              }
              return 'common-table-row--pointer';
            }}
            rowKey={(record: ConsumerV2Model) => `${record.id}+${record.projectId ?? ''}+${record.propertyId ?? ''}`}
            pagination={pagination}
          />
        </div>
        {isOpenDelete && userId && projectId && (
          <ModalSwitchUserBlock
            isOpen={isOpenDelete}
            setClose={() => {
              setProjectId(null);
              setIsOpenDelete(false);
            }}
            userId={userId}
            projectId={+projectId}
            getUsers={() => getUsers(option)}
            isDelete={isDelete}
            isConsumer={true}
          />
        )}
      </Spin>
    </>
  );
};

export default ConsumersPage;
