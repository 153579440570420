import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import ProjectModifyPage from './ProjectModifyPage';
import { Card } from 'antd';
import ProjectCFCPage from './ProjectCFCPage';
import ProjectCustomFlowPage from './ProjectCustomFlowPage';

const ProjectManagePage = () => {
  const { t } = useTranslation();
  const params: { tabName: string; id: string } = useParams();
  const { tabName, id } = params;

  const tabs = id
    ? [
        {
          id: 1,
          name: t('projects.tab.general'),
          path: 'general',
          page: <ProjectModifyPage />,
        },
        {
          id: 2,
          name: t('projects.tab.cfc'),
          path: 'cfc_codes',
          page: <ProjectCFCPage id={id} />,
        },
        {
          id: 3,
          name: t('projects.tab.files.validation'),
          path: 'files_validation',
          page: <ProjectCustomFlowPage id={+id} />,
        },
      ]
    : [
        {
          id: 1,
          name: t('projects.tab.general'),
          path: 'general',
          page: <ProjectModifyPage />,
        },
      ];

  return (
    <div className="consumer-tab-container">
      <div className="tab-container">
        {tabs.map((tab) => (
          <NavLink
            to={tab.path}
            className={`consumer-tab common-animation-primary ${
              tab.path === 'files_validation' ? 'project-modify-forth-step' : ''
            }`}
            activeClassName="consumer-tab-active"
            key={tab.id}>
            {tab.name}
          </NavLink>
        ))}
      </div>
      <Card className="project-manage-container">{tabs.find((tab) => tab.path === tabName)?.page}</Card>
    </div>
  );
};

export default ProjectManagePage;
