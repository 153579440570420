import React, { useContext, useEffect, useMemo, useState } from 'react';
import css from '../../../assets/styles/budget.module.css';
import { Select, Spin, Table, TableProps, notification } from 'antd';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { useTranslation } from 'react-i18next';
import { getChangeOrdersColumns } from './ChangeOrdersColumns';
import ChangeOrdersService from '../../../services/ChangeOrdersService';
import { IChangeOrder } from '../../../models/ChangeOrdersModel';
import PreviewPDF from './PreviewPDF';
import config from '../../../config';
import { getStorageToken } from '../../../helpers/storageTools';
import HttpService from '../../../services/common/httpService';
import { IAppContext } from '../../../typings/IApp';
import { AppContext } from '../../../contexts/AppContextProvider';
import { useLessThen801 } from '../../../helpers/mediaDetect';
import TableMobile from '../../common/TableMobile';

const httpService = new HttpService();
const service = new ChangeOrdersService();

const ChangeOrdersListInfo = () => {
  const { t } = useTranslation();
  const {
    app: {
      user: { projects },
      currentProject,
      isConsumer,
    },
  } = useContext<IAppContext>(AppContext);
  const { height: windowHeight } = useWindowDimensions();
  const [fetchingData, setFetchingData] = useState(false);
  const [items, setItems] = useState<IChangeOrder[]>([]);
  const [currentItem, setCurrentItem] = useState<IChangeOrder | null>(null);
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [projectId, setProjectId] = useState<number>();
  const projectOptions = isConsumer
    ? undefined
    : projects
        .filter((p) => p.externalId)
        .map((p) => ({ label: p.title, value: p.id }))
        .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));

  useEffect(() => {
    if (projectId) {
      getItems(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    if (isConsumer && currentProject?.id) {
      setProjectId(currentProject.id);
    }
    if (!isConsumer && projectOptions && !projectId) {
      setProjectId(projectOptions[0].value);
    }
  }, [currentProject, isConsumer, projectOptions]);

  const getItems = (projectId: number) => {
    setFetchingData(true);
    service
      .getChangeOrders(projectId)
      .then((res: IChangeOrder[]) => {
        setItems(res.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()));
      })
      .catch((e) => {
        notification.error({ message: e.message || t('changeOrders.error.massage') });
      })
      .finally(() => setFetchingData(false));
  };

  const onTableChange: TableProps<IChangeOrder>['onChange'] = (pagination, filters, sorter) => {
    if (sorter && !Array.isArray(sorter) && sorter.order) {
      return setItems(
        items.sort((a, b) =>
          sorter.order === 'ascend'
            ? new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
            : new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
        ),
      );
    }
    setItems(items.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()));
  };

  const openPreview = (item: IChangeOrder) => {
    setIsOpenPreview(true);
    setCurrentItem(item);
  };
  const downloadFile = (item: IChangeOrder) => {
    setFetchingData(true);
    httpService
      .getFile(
        `${config.baseRequest}changeOrders/${item.externalId}/pdf?projectId=${projectId}&externalContractId=${item.externalContractId}`,
        {
          header: { Authorization: 'Bearer ' + getStorageToken() },
        },
      )
      .then((res: any) => {
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(res);
        a.href = url;
        a.download = `${item.title}.pdf`;
        a.click();
      })
      .catch((e) => notification.error({ message: e.message }))
      .finally(() => {
        setFetchingData(false);
      });
  };

  const data = useMemo(
    () =>
      items.length > 0 &&
      (items.map((item) =>
        isConsumer
          ? [
              {
                title: t('projects.create.title'),
                description: item.title,
                type: 'string',
              },
              { title: t('budget.table.status'), description: item.status, type: 'statusTag' },
              { title: t('projects.table.columns.createdDate'), description: item.created_at, type: 'date' },
              { title: t('projects.table.columns.updatedDate'), description: item.updated_at, type: 'date' },
              {
                title: t('budget.table.action'),
                type: 'button',
                openByFunction: true,
                btnsInfo: [
                  {
                    function: () => downloadFile(item),
                    functionBtnTitle: t('budget.table.download'),
                  },
                  {
                    function: () => openPreview(item),
                    functionBtnTitle: t('common.preview.btn'),
                  },
                ],
              },
            ]
          : [
              {
                title: t('projects.create.title'),
                description: item.title,
                type: 'string',
              },
              {
                title: t('properties.table.columns.consumers'),
                description: item.recipients?.map((user) => `${user.firstName} ${user.lastName}`).join(', '),
                type: 'string',
              },
              { title: t('budget.table.status'), description: item.status, type: 'statusTag' },
              { title: t('projects.table.columns.createdDate'), description: item.created_at, type: 'date' },
              { title: t('projects.table.columns.updatedDate'), description: item.updated_at, type: 'date' },
              {
                title: t('budget.table.action'),
                type: 'button',
                openByFunction: true,
                btnsInfo: [
                  {
                    function: () => downloadFile(item),
                    functionBtnTitle: t('budget.table.download'),
                  },
                  {
                    function: () => openPreview(item),
                    functionBtnTitle: t('common.preview.btn'),
                  },
                ],
              },
            ],
      ) as any),
    [items],
  );

  if (useLessThen801()) {
    return (
      <div>
        <Spin spinning={fetchingData}>
          {!isConsumer && (
            <div style={{ marginBottom: '1rem' }}>
              <Select
                defaultValue={projectOptions ? projectOptions[0].value : undefined}
                className={'budget-control-select common-animation-primary'}
                options={projectOptions}
                onChange={(value: number | undefined) => setProjectId(value)}
              />
            </div>
          )}
          <TableMobile
            data={data}
            option={{}}
            isLoading={fetchingData}
            itemsLength={items.length}
            totalItems={items.length}
          />
          {isOpenPreview && currentItem && projectId && (
            <PreviewPDF
              isOpen={isOpenPreview}
              exterlanId={currentItem.externalId}
              externalContractId={currentItem.externalContractId}
              projectId={projectId}
              onClose={() => {
                setCurrentItem(null);
                setIsOpenPreview(false);
              }}
            />
          )}
        </Spin>
      </div>
    );
  }

  return (
    <div className={isConsumer ? css['table-container'] : ''} style={{ width: '100%', flex: 'none' }}>
      <Spin spinning={fetchingData}>
        {!isConsumer && (
          <div>
            <Select
              defaultValue={projectOptions ? projectOptions[0].value : undefined}
              className={'budget-control-select common-animation-primary'}
              options={projectOptions}
              onChange={(value: number | undefined) => setProjectId(value)}
            />
          </div>
        )}
        <Table
          columns={getChangeOrdersColumns({
            t,
            isConsumer,
            openPreview,
            downloadFile,
          })}
          size="small"
          className={`list-info-table`}
          rowClassName={() => `common-table-row--pointer`}
          dataSource={items}
          onChange={onTableChange}
          pagination={{ showSizeChanger: true }}
          showHeader={true}
          scroll={isConsumer ? { y: windowHeight - 330 } : { y: windowHeight - 290 }}
          rowKey="externalId"
        />
        {isOpenPreview && currentItem && projectId && (
          <PreviewPDF
            isOpen={isOpenPreview}
            exterlanId={currentItem.externalId}
            externalContractId={currentItem.externalContractId}
            projectId={projectId}
            onClose={() => {
              setCurrentItem(null);
              setIsOpenPreview(false);
            }}
          />
        )}
      </Spin>
    </div>
  );
};

export default ChangeOrdersListInfo;
