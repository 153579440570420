import { Button, DatePicker, Drawer, Form, Input, notification, Select, Spin, Table } from 'antd';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import config from '../../../../config';
import css from '../../../../assets/styles/budget.module.css';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { getColumns } from './QuoteStatusColumns';
import arrowIcon from '../../../../assets/images/ic-arrow-back.svg';
import QuotesService from '../../../../services/QuoteService';
import { AppContext } from '../../../../contexts/AppContextProvider';
import { validateDateLessThanToday } from '../../../../helpers/validateDateField';
import { getStorageToken } from '../../../../helpers/storageTools';
import { useLessThen801 } from '../../../../helpers/mediaDetect';
import { ConsumerModel } from '../../../../models/ConsumerModel';
import UsersService from '../../../../services/UsersService';
import { useParams } from 'react-router';
import CustomSignFlow from '../../../../services/CustomSignFlow';
import { IFlow, IFlowStep } from '../../../../models/CustomSignFlowModel';
import { generateFilterfromObj } from '../../../../helpers/generateFIlterfromObj';
import { FilterTypes } from '../../../../constants/filterTypes';
import { setTextColorByStatus } from '../../../../helpers/setTextColorByStatus';
import { StatusesDocumentsModel } from '../../../../models/DocumentsModel';
import { IQuoteModel } from '../../../../models/QuotesModel';
import { IUserEasy } from '../../../../models/UserModel';
import { IResponceData } from '../../../../typings/IServiceOptions';
import { IAppContext } from '../../../../typings/IApp';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface QuotesProps {
  id: number | null;
  isOpen: boolean;
  setCloseStatus: () => void;
  setCurrentQuote?: (v: number | null) => void;
  currentQuote?: number | null;
  propertyId: number;
}
const service = new QuotesService();
const userService = new UsersService();
const serviceFlow = new CustomSignFlow();
const { TextArea } = Input;

const QuoteStatusForm = (props: QuotesProps) => {
  const { id, isOpen, setCloseStatus, setCurrentQuote, currentQuote, propertyId } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const params: { projectId: string } = useParams();
  const { projectId } = params;
  const [statuses, setStatuses] = useState<StatusesDocumentsModel>([]);
  const [fetchingData, setFetchingData] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [quotesInfo, setQuotesInfo] = useState<IQuoteModel | null>(null);
  const [isDisabledStatus, setIsDisabledStatus] = useState(true);
  const [isRejectCancel, setIsRejectCancel] = useState(false);
  const [consumers, setConsumers] = useState<ConsumerModel[]>([]);
  const [agents, setAgents] = useState<IUserEasy[]>([]);
  const [currency, setCurrency] = useState<string>(t('budget.currency'));
  const {
    app: { isConsumer },
  } = useContext<IAppContext>(AppContext);

  const [flows, setFlows] = useState<IFlow[]>([]);
  const [flowSteps, setFlowSteps] = useState<IFlowStep[]>([]);
  const [currentSelectedUsers, setCurrentSelectedUsers] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<null | string>(null);
  const [currentTimeout, setCurrentTimeout] = useState<NodeJS.Timeout | null>(null);
  const [isLastAgentsReq, setIsLastAgentsReq] = useState(false);
  const [fetchingUserData, setFetchingUserData] = useState(false);
  const [paginationAgents, setPaginationAgents] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    skip: 0,
  });

  useEffect(() => {
    setFetchingData(true);
    serviceFlow
      .getStatuses({
        filters: generateFilterfromObj({
          'doc_types_statuses/typeCode': {
            type: FilterTypes.SELECT,
            value: 'quotes',
          },
        }),
      })
      .then((statusesInfo: StatusesDocumentsModel) => {
        setStatuses(statusesInfo);
        getConsumers();
        if (id || currentQuote) {
          service
            .getQuoteInfoById((id || currentQuote) as number)
            .then((value: IQuoteModel) => {
              setCurrency(value?.currency?.description || t('budget.currency'));
              serviceFlow
                .getFlows(
                  {
                    filters: generateFilterfromObj({
                      'projects/id': {
                        type: FilterTypes.SELECT,
                        value: +projectId,
                      },
                      'document_types/code': {
                        type: FilterTypes.SELECT,
                        value: 'quotes',
                      },
                    }),
                  },
                  value.flow ? value.flow.id : undefined,
                )
                .then((flows: IFlow | IFlow[]) => {
                  const isArrayFlows = Array.isArray(flows);
                  setFlows((isArrayFlows ? flows : [flows]) as IFlow[]);
                  getAgents(
                    value?.signatories
                      ?.filter((item) => item.stepCode.split('_').includes('agent'))
                      .map((item) => item.consumerId),
                  );
                  let fieldsValues: { [name: string]: any } = {
                    status: value.statusV2.code,
                    description: value.description,
                    signInfo: value.comment || value.signInfo,
                    deadline: !!value.deadline ? moment(value.deadline) : null,
                    date: !!value.createdDate ? moment(value.createdDate) : null,
                    comment: value.comment || value.signInfo,
                    flow: value.flow ? value.flow.id : '',
                  };
                  (
                    (isArrayFlows ? (flows as IFlow[]).find((item) => item.id === value.flow?.id) : flows) as IFlow
                  )?.flowsSteps
                    .map((item) => ({
                      stepCode: item.stepCode,
                      signatoriesName: item.step.signatoriesName,
                    }))
                    .forEach((item) => {
                      fieldsValues[item.stepCode] = value.signatories
                        .filter((sign) => sign.stepCode === item.stepCode)
                        .map((item) => item.consumerId);
                    });
                  form.setFieldsValue(fieldsValues);
                  if (value.flow && value.flow.id) {
                    setFlowSteps(
                      (
                        (isArrayFlows ? (flows as IFlow[]).find((item) => item.id === value.flow?.id) : flows) as IFlow
                      )?.flowsSteps.map((item) => ({
                        signatoriesName: item.step.signatoriesName,
                        code: item.step.code,
                      })) || [],
                    );
                  }
                  setIsDisabledStatus(
                    value.flow === null
                      ? ['rejected', 'done', 'canceled', 'signed'].includes(value.statusV2.code)
                      : true,
                  );
                  setIsRejectCancel(['rejected', 'done', 'canceled', 'signed'].includes(value.statusV2.code));
                  setQuotesInfo(value);
                });
            })
            .catch((e) => {
              notification.error({
                message: e.message,
              });
              setCloseStatus();
            });
        } else {
          serviceFlow
            .getFlows({
              filters: generateFilterfromObj({
                'projects/id': {
                  type: FilterTypes.SELECT,
                  value: +projectId,
                },
                'document_types/code': {
                  type: FilterTypes.SELECT,
                  value: 'quotes',
                },
              }),
            })
            .then((flows: IFlow[]) => {
              setFlows(flows);
              const defaultFlowId = flows.find((item) => item.isDefault)?.id;
              form.setFieldsValue({ flow: defaultFlowId || '' });
              setFlowSteps(
                flows
                  .find((item) => item.id === defaultFlowId)
                  ?.flowsSteps.map((item) => ({ signatoriesName: item.step.signatoriesName, code: item.step.code })) ||
                  [],
              );
              getAgents();
            });
        }
      })
      .catch((e) => {
        notification.error({
          message: e.message,
        });
        setCloseStatus();
      })
      .finally(() => setFetchingData(false));
  }, []);

  useEffect(() => {
    if (searchValue === '') {
      return getAgents([], currentSelectedUsers);
    }
    if (searchValue) {
      getAgents();
    }
  }, [searchValue]);

  const getAgents = (agentsId?: number[], agentsRes: IUserEasy[] = []) => {
    if (agentsId && agentsId.length > 0) {
      userService
        .getEasyUsers({ userId: agentsId })
        .then((res: IUserEasy[]) => {
          setCurrentSelectedUsers(res);
          getAgents([], res);
        })
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        );
      return;
    }
    const optionUser = {
      count: true,
      top: paginationAgents.pageSize,
      current: paginationAgents.current,
      skip: paginationAgents.pageSize * (paginationAgents.current - 1) || 0,
      fullName: searchValue || '',
      projectId: [projectId],
      roles: ['agent', 'admin', 'super_agent'],
    };

    setFetchingUserData(true);
    userService
      .getEasyUsers(optionUser)
      .then((res: IResponceData<IUserEasy>) => {
        const { count, items } = res;
        const { current, top } = optionUser;
        setPaginationAgents({ ...paginationAgents, current: current + 1, total: count, pageSize: top });
        let agentsId: number[] = [];
        if (agents.length > 0) {
          agentsId = agents.map((agent) => agent.id);
        } else if (agentsRes.length > 0) {
          agentsId = agentsRes.map((agent) => agent.id);
        }
        setAgents(
          [...agents, ...agentsRes, ...items.filter((item) => !agentsId.includes(item.id))].sort((a, b) =>
            a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()),
          ),
        );
        setIsLastAgentsReq(current * top >= count);
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingUserData(false));
  };

  const getConsumers = () => {
    userService
      .getConsumersByProperty(propertyId)
      .then((value: ConsumerModel[]) => setConsumers(value))
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      );
  };

  const onSubmit = (value: any) => {
    setFetchingData(true);
    const body = {
      id: quotesInfo!.id,
      description: value.description,
      statusV2: {
        code: value.status,
      },
      deadline: moment(value.deadline).format('YYYY-MM-DD'),
      comment: value.comment || value.signInfo,
    };

    service
      .updateQuote(body)
      .then(() => {
        setCloseStatus();
        notification.success({
          message: t('quotes.confirm.update'),
        });
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => {
        setFetchingData(false);
        setCurrentQuote && setCurrentQuote(null);
      });
  };

  const onDocumentLoad = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const onStatusSelect = (value: string) => {
    const isCancel = ['rejected', 'canceled'].includes(value);
    setIsRejectCancel(isCancel);
    if (isCancel) {
      form.setFields([{ name: 'comment', value: null }]);
    }
  };

  const file = useMemo(
    () => ({
      url: `${config.baseRequest}file/binary/${quotesInfo?.file}`,
      httpHeaders: {
        Authorization: 'Bearer ' + getStorageToken(),
      },
    }),
    [quotesInfo],
  );

  const onSelectFlow = (value: number | '') => {
    form.setFieldsValue({ status: 'new' });
    setFlowSteps(
      flows
        .find((item) => item.id === value)
        ?.flowsSteps.map((item) => ({ signatoriesName: item.step.signatoriesName, code: item.step.code })) || [],
    );
  };

  return (
    <Drawer className="upload-form-drawer" visible={isOpen} placement="top" closable={false}>
      <Spin spinning={fetchingData}>
        <Form autoComplete="off" onFinish={onSubmit} form={form}>
          <Form.Item>
            <div className={css['btns-container']}>
              <Button
                className={`${css['return-btn']} common-secondary-btn`}
                onClick={() => setCloseStatus()}
                disabled={fetchingData}>
                {t('common.return.btn')}
              </Button>
              <Button
                className={`${css['upload-btn']} common-primary-btn`}
                htmlType="submit"
                disabled={fetchingData}
                style={{ width: 'auto', marginLeft: '1rem' }}>
                {t('common.btn.save')}
              </Button>
            </div>
          </Form.Item>
          <div
            className={css['content-container']}
            style={{ padding: useLessThen801() ? '0' : '0 3rem', justifyContent: 'space-around' }}>
            {quotesInfo && (
              <div
                className={css['document-control-container']}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    disabled={pageNumber === 1}
                    onClick={() => setPageNumber(pageNumber - 1)}
                    style={{ border: 'none' }}>
                    <img src={arrowIcon} alt="" style={{ height: '15px', width: '15px' }} />
                  </Button>
                  <span>
                    {pageNumber}/{numPages || '1'}
                  </span>
                  <Button
                    disabled={pageNumber === numPages}
                    onClick={() => setPageNumber(pageNumber + 1)}
                    style={{ border: 'none' }}>
                    <img
                      src={arrowIcon}
                      alt=""
                      style={{ transform: 'rotate(180deg)', height: '15px', width: '15px' }}
                    />
                  </Button>
                </div>
                <Document
                  className="document-pdf"
                  file={file}
                  onLoadError={console.error}
                  onLoadSuccess={onDocumentLoad}>
                  <Page pageNumber={pageNumber} />
                </Document>
              </div>
            )}
            <div
              className={css['upload-steps-item']}
              style={{ backgroundColor: '#fff', padding: '1rem', marginTop: '2rem' }}>
              <Form.Item
                name="status"
                colon={false}
                rules={[
                  {
                    required: true,
                    message: t('common.validation.required'),
                  },
                ]}
                label={
                  <span style={{ color: '#778dac' }} className="documents-form-label">
                    {t('documents.form.status')}
                  </span>
                }
                initialValue={'new'}
                className={css['form-item']}
                labelAlign="left">
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
                  className="common-select common-animation-primary"
                  suffixIcon={
                    <div
                      className={`arrow-drop-img ${css['arrow-drop']}`}
                      style={{ width: '8px', height: '4px' }}></div>
                  }
                  onSelect={onStatusSelect}
                  disabled={isDisabledStatus}>
                  {statuses.length > 0 &&
                    statuses
                      .filter((status) =>
                        quotesInfo && quotesInfo.flow === null
                          ? status.docTypesStatuses.find((item) => item.typeCode === 'quotes')?.isManual
                          : true,
                      )
                      .map((item) => (
                        <Select.Option key={item.code} value={item.code}>
                          <span style={{ color: `${setTextColorByStatus(item.code)}` }}>{item.description}</span>
                        </Select.Option>
                      ))}
                </Select>
              </Form.Item>
              {/* {!isConsumer &&
                isRejectCancel && [
                  <span key="label-description" style={{ color: '#778dac', paddingBottom: '0.5rem' }}>
                    {t('budget.form.cancel.comment')}
                  </span>,
                  <Form.Item
                    key="comment"
                    name="comment"
                    colon={false}
                    rules={[{ required: true, message: t('common.validation.required') }]}>
                    <TextArea
                      className="consumer-form-input common-animation-primary"
                      autoSize={{ minRows: 2, maxRows: 3 }}
                      disabled={isRejectCancel}
                    />
                  </Form.Item>,
                ]} */}
              {!isConsumer && (
                <>
                  <span style={{ marginBottom: '0.5rem', color: '#778dac' }}>{t('contract.flow.title')}</span>
                  <Form.Item name="flow" colon={false} className={css['form-item']} initialValue={''} labelAlign="left">
                    <Select
                      className="common-select common-animation-primary"
                      suffixIcon={
                        <div
                          className={`arrow-drop-img ${css['arrow-drop']}`}
                          style={{ width: '8px', height: '4px' }}></div>
                      }
                      style={{ marginTop: '0' }}
                      disabled={true}
                      onSelect={onSelectFlow}>
                      <Select.Option value={''}>{t('contract.flow.noFlow')}</Select.Option>
                      {flows.length > 0 &&
                        flows.map((item) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                  {flowSteps.length > 0 &&
                    flowSteps.map((item) => (
                      <div key={item.code}>
                        <span style={{ marginBottom: '0.5rem', color: '#778dac' }}>{`${item.signatoriesName} *`}</span>
                        <Form.Item
                          name={item.code}
                          colon={false}
                          rules={[{ required: !quotesInfo, message: t('common.validation.required') }]}
                          className={css['form-item']}
                          labelAlign="left">
                          <Select
                            className="common-select common-animation-primary"
                            mode="multiple"
                            disabled={true}
                            onDropdownVisibleChange={(value) => {
                              if (item.code.split('_').includes('agent') && !value && searchValue) {
                                setAgents([]);
                                setPaginationAgents({ ...paginationAgents, current: 1, skip: 0 });
                                setSearchValue('');
                              }
                            }}
                            onSearch={(value) => {
                              if (item.code.split('_').includes('agent')) {
                                setAgents([]);
                                setPaginationAgents({ ...paginationAgents, current: 1, skip: 0 });
                                clearTimeout(Number(currentTimeout));
                                setCurrentTimeout(setTimeout(() => setSearchValue(value), 1000));
                              }
                            }}
                            filterOption={(value, option) =>
                              item.code.split('_').includes('agent')
                                ? true
                                : option?.children?.toLowerCase().indexOf(value.toLowerCase()) >= 0
                            }
                            dropdownClassName={`documents-control-select-dropdown ${
                              fetchingUserData ? 'consumers-control-select-dropdown--progress' : ''
                            }`}
                            onPopupScroll={(e: any) => {
                              e.persist();
                              let target = e.target;
                              if (
                                item.code.split('_').includes('agent') &&
                                !fetchingUserData &&
                                !isLastAgentsReq &&
                                target.scrollTop + target.offsetHeight === target.scrollHeight
                              ) {
                                getAgents();
                              }
                            }}
                            suffixIcon={
                              <div
                                className={`arrow-drop-img ${css['arrow-drop']}`}
                                style={{ width: '8px', height: '4px' }}></div>
                            }>
                            {item.code.split('_').includes('agent')
                              ? agents.length > 0 &&
                                agents.map((signatory) => (
                                  <Select.Option key={signatory.id} value={signatory.id}>
                                    {`${signatory.firstName} ${signatory.lastName}`}
                                  </Select.Option>
                                ))
                              : consumers.length > 0 &&
                                consumers
                                  .sort((a, b) => a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()))
                                  .map((signatory) => (
                                    <Select.Option key={signatory.id} value={signatory.id}>
                                      {`${signatory.firstName} ${signatory.lastName}`}
                                    </Select.Option>
                                  ))}
                          </Select>
                        </Form.Item>
                      </div>
                    ))}
                </>
              )}
              {!isConsumer && [
                <span key="label-deadline" style={{ color: '#778dac', paddingBottom: '0.5rem' }}>
                  {t('budget.form.deadline')}
                </span>,
                <Form.Item
                  key="deadline"
                  name="deadline"
                  colon={false}
                  initialValue={moment().add(10, 'days')}
                  rules={[
                    { required: true, message: t('common.validation.required') },
                    validateDateLessThanToday('deadline', 'date', t('budget.form.deadline.validate')),
                  ]}>
                  <DatePicker
                    inputReadOnly
                    getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
                    className="common-animation-primary"
                    format="DD.MM.YYYY"
                    disabled={isRejectCancel}
                  />
                </Form.Item>,
              ]}
              {isConsumer ? (
                <span>{quotesInfo?.description || ''}</span>
              ) : (
                [
                  <span key="label-description" style={{ color: '#778dac', paddingBottom: '0.5rem' }}>
                    {t('roles.table.columns.description')}
                  </span>,
                  <Form.Item key="description" name="description" colon={false}>
                    <TextArea
                      className="consumer-form-input common-animation-primary"
                      autoSize={{ minRows: 2, maxRows: 3 }}
                      disabled={isRejectCancel}
                    />
                  </Form.Item>,
                ]
              )}
              {!isConsumer && [
                <span key="label-description" style={{ color: '#778dac', paddingBottom: '0.5rem' }}>
                  {t('budget.form.sign.comments')}
                </span>,
                <Form.Item key="signInfo" name="signInfo" colon={false}>
                  <TextArea
                    className="consumer-form-input common-animation-primary"
                    autoSize={{ minRows: 2, maxRows: 3 }}
                    disabled={isRejectCancel}
                  />
                </Form.Item>,
              ]}
              <div style={{ marginTop: '1rem', display: 'flex', flexDirection: 'column' }}>
                <span style={{ color: '#778dac' }}>{`${t('quotes.upload.overview.fields.quote')}: ${
                  quotesInfo?.number || ''
                }`}</span>
                <span style={{ color: '#778dac' }}>{`${t('filemanagment.creator.title')}: ${
                  quotesInfo?.createdUser?.firstName || ''
                } ${quotesInfo?.createdUser?.lastName || ''}`}</span>
                <span style={{ color: '#778dac' }}>{`${t('quote.upload.overview.fields.date')}: ${
                  moment(quotesInfo?.quoteDate).format('DD.MM.YYYY') || ''
                }`}</span>
                <Table
                  style={{ marginTop: '1rem' }}
                  columns={getColumns({ t })}
                  dataSource={quotesInfo?.items}
                  size="small"
                  className="list-info-table"
                  rowClassName="common-table-row--pointer"
                  showHeader={true}
                  pagination={false}
                  rowKey="id"
                />
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1rem' }}>
                  <span style={{ color: '#778dac' }}>{`${t('budget.invoice.form.subtotal.1')}: ${currency || ''} ${
                    quotesInfo?.sum ? (+quotesInfo.sum / 100).toFixed(2) : ''
                  }`}</span>
                  <span style={{ color: '#778dac' }}>{`${t('budget.upload.overview.fields.fees')}: ${
                    quotesInfo?.fees || ''
                  } %`}</span>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ color: '#778dac' }}>{t('budget.invoice.form.subtotal.2')}</span>
                    <span style={{ color: '#778dac' }}>{`${currency || ''} ${
                      quotesInfo?.sumWithFees ? (+quotesInfo.sumWithFees / 100).toFixed(2) : ''
                    }`}</span>
                  </div>
                  <span style={{ color: '#778dac' }}>{`${t('budget.upload.overview.fields.vat')}: ${
                    quotesInfo?.vat || ''
                  } %`}</span>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ color: '#778dac' }}>{t('budget.invoice.form.total')}</span>
                    <span style={{ color: '#778dac' }}>{`${currency || ''} ${
                      quotesInfo?.sumWithVat ? (+quotesInfo.sumWithVat / 100).toFixed(2) : ''
                    }`}</span>
                  </div>
                </div>
                {isConsumer && (
                  <Button className={css['upload-btn']} style={{ width: '100%', marginTop: '1rem' }}>
                    {t('quotes.status.approve.btn')}
                  </Button>
                )}
                {isConsumer && (
                  <Button className={css['return-btn']} style={{ width: '100%', marginTop: '1rem' }}>
                    {t('quotes.status.reject.btn')}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Form>
      </Spin>
    </Drawer>
  );
};
export default QuoteStatusForm;
