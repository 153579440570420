import { Button, Modal, notification, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../../assets/styles/faq.module.css';
import { InternalDocumentModel } from '../../../../models/InternalDocumentsModel';
import InternalDocumentsService from '../../../../services/InternalDocumentsService';

interface Props {
  id: number | null;
  setClose: () => void;
  isOpen: boolean;
}

const service = new InternalDocumentsService();

const ArchiveConfirmForm = (props: Props) => {
  const { id, setClose, isOpen } = props;
  const { t } = useTranslation();
  const [fetchingData, setFetchingData] = useState(false);
  const [internalDocument, setInternalDocument] = useState<InternalDocumentModel | null>(null);

  useEffect(() => {
    if (id) {
      service
        .getInternalDocumentsById(id)
        .then((value: any) => setInternalDocument(value))
        .catch((e: any) => {
          notification.error({
            message: e.message,
          });
          setClose();
        });
    }
  }, []);

  const updateDocument = () => {
    if (internalDocument) {
      setFetchingData(true);
      service
        .changeIsArchive(internalDocument.id)
        .then(() =>
          notification.success({
            message: t('documents.modal.archived.success.message'),
          }),
        )
        .catch((e: any) =>
          notification.error({
            message: e.message,
          }),
        )
        .finally(() => {
          setClose();
          setFetchingData(false);
        });
    }
  };

  if (!internalDocument) {
    return null;
  }

  return (
    <Spin spinning={fetchingData}>
      <Modal
        className="common-modal"
        centered
        visible={isOpen}
        style={{ width: '10rem' }}
        footer={
          <div className="common-modal-footer">
            <Button
              className={`common-gray-border-btn common-secondary-btn`}
              onClick={() => setClose()}
              disabled={fetchingData}>
              {t('budget.modal.cancel.btn')}
            </Button>
            <Button
              className={`common-green-btn common-modal-btn`}
              onClick={() => updateDocument()}
              disabled={fetchingData}>
              {t('budget.modal.arhive.btn')}
            </Button>
          </div>
        }
        closable={false}>
        <span className={css['delete-question-title']}>
          {`${t('internal.documents.confirm.archive')}
              ${internalDocument.type.code} ${internalDocument.name}?`}
        </span>
      </Modal>
    </Spin>
  );
};

export default ArchiveConfirmForm;
