import React from 'react';
import { Button } from 'antd';
import { convertToLocalDateTime } from '../../../helpers/converToLocalDate';
import { NavLink } from 'react-router-dom';
import LogoComponent from '../../../components/common/LogoComponent';
import css from '../../../assets/styles/companies.module.css';
import editIcon from '../../../assets/images/ic-edit-dark.svg';
import { CompanyModel } from '../../../models/CompaniesModel';
import { TFunction } from 'react-i18next';

interface CompaniesColumnsModel {
  t: TFunction<'translation'>;
  deleteCompany: (id: number, name: string) => void;
}

export const getColumns = ({ t, deleteCompany }: CompaniesColumnsModel) => [
  {
    title: () => (
      <span className="budget-table-tr" style={{ marginLeft: '8px' }}>
        #
      </span>
    ),
    key: 'logo',
    width: 50,
    render: (cell: CompanyModel) => (
      <LogoComponent id={cell.id} name={`${cell.companyName} `} image={cell.logo || ''} />
    ),
  },
  {
    title: () => <span className="budget-table-tr">{t('projects.table.columns.name')}</span>,
    dataIndex: 'companyName',
    key: 'companyName',
  },
  {
    title: () => <span className="budget-table-tr">{t('projects.table.columns.createdDate')}</span>,
    key: 'createdDate',
    render: (cell: CompanyModel) => (cell.createdDate ? convertToLocalDateTime(cell.createdDate) : ''),
  },
  {
    title: () => <span className="budget-table-tr">{t('projects.table.columns.updatedDate')}</span>,
    key: 'updatedDate',
    render: (cell: CompanyModel) => (cell.updatedDate ? convertToLocalDateTime(cell.updatedDate) : ''),
  },
  {
    title: () => <span className="budget-table-tr">{t('users.table.columns.action')}</span>,
    key: 'action',
    fixed: 'right' as any,
    with: '100px',
    render: (cell: CompanyModel) => {
      return (
        <div style={{ display: 'flex', backgroundColor: 'inherit', gap: '1rem' }}>
          <NavLink
            to={`/companies/modify/${cell.id}`}
            style={{ borderRadius: 4 }}
            className="common-animation-secondary company-second-step">
            <Button className={css['edit-company-btn']}>
              <img src={editIcon} alt="" style={{ width: '12px', height: '12px', marginRight: '0.5rem' }} />
              {t('common.btn.modify.lower')}
            </Button>
          </NavLink>
          <Button
            onClick={() => deleteCompany(cell.id, cell.companyName)}
            className={css['company-table-btn-delete']}></Button>
        </div>
      );
    },
  },
];
