import { Button, Input, Select, Switch, TablePaginationConfig } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, RouteComponentProps } from 'react-router-dom';
import css from '../../../assets/styles/consumers.module.css';
import ProjectsService from '../../../services/ProjectsService';
import { IProjectEasy } from '../../../models/ProjectModel';
import { FilterTypes } from '../../../constants/filterTypes';
import { AppContext } from '../../../contexts/AppContextProvider';
import searchIcon from '../../../assets/images/ic-search.svg';
import UsersService from '../../../services/UsersService';
import { IUserEasy } from '../../../models/UserModel';
import { FiltersContext } from '../../../contexts/FiltersContextProvider';
import { SET_CONSUMERS_FILTERS } from '../../../constants/actionTypes/filtersConstants';
import { useLessThen801 } from '../../../helpers/mediaDetect';
import ModalUploadFileSuccess from '../../../components/common/Modals/ModalUploadFileSuccess';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { ConsumerV2Model } from '../../../models/ConsumerModel';
import { IResponceData } from '../../../typings/IServiceOptions';
import { IAppContext } from '../../../typings/IApp';
import ImportConsumersModal from './ImportConsumers/ImportConsumersModal';

const { Search } = Input;
const { Option, OptGroup } = Select;

interface PropsControlModel {
  props: RouteComponentProps;
  onTableChange: (
    pagination?: TablePaginationConfig,
    filters?: Record<string, FilterValue | null | any>,
    sorter?: SorterResult<ConsumerV2Model> | SorterResult<ConsumerV2Model>[],
  ) => void;
  getUsers: (option: { [name: string]: any }) => void;
  option: { [name: string]: any };
  setCurrentFilters: (v: any) => void;
}

const projectsService = new ProjectsService();
const userService = new UsersService();

const ConsumersControl = (propsControl: PropsControlModel) => {
  const { onTableChange, props, getUsers, option, setCurrentFilters } = propsControl;
  const { t } = useTranslation();
  const [isOpenImportForm, setIsOpenImportForm] = useState(false);
  const [isOpenUploadMessageModal, setIsOpenUploadMessageModal] = useState(false);
  const [projects, setProjects] = useState<IProjectEasy[] | null>(null);
  const [users, setUsers] = useState<IUserEasy[]>([]);
  const {
    app: { user, isSupplier },
  } = useContext<IAppContext>(AppContext);
  const {
    filters: { consumersFilter },
    filtersDispatch,
  } = useContext(FiltersContext);
  const [filters, setFilters] = useState(consumersFilter);
  const [currentTimeoutLogin, setCurrentTimeoutLogin] = useState<NodeJS.Timeout | null>(null);
  const [currentTimeoutProperty, setCurrentTimeoutProperty] = useState<NodeJS.Timeout | null>(null);

  const [isLastUserReq, setIsLastUserReq] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    skip: 0,
  });

  useEffect(() => {
    projectsService.getProjects().then((value: IProjectEasy[]) => setProjects(value));
    getAgents();
  }, []);

  const getAgents = () => {
    const optionUser = {
      count: true,
      top: pagination.pageSize,
      current: pagination.current,
      skip: pagination.pageSize * (pagination.current - 1) || 0,
      roles: ['agent', 'admin', 'super_agent'],
    };

    setFetchingData(true);
    userService
      .getEasyUsers(optionUser)
      .then((res: IResponceData<IUserEasy>) => {
        const { count, items } = res;
        const { current, top } = optionUser;
        setPagination({ ...pagination, current: current + 1, total: count, pageSize: top });
        let usersId: number[] = [];
        if (users.length > 0) {
          usersId = users.map((user) => user.id);
        }
        setUsers(
          [...users, ...items.filter((item: IUserEasy) => !usersId.includes(item.id))].sort((a, b) =>
            a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()),
          ),
        );
        setIsLastUserReq(current * top >= count);
      })
      .finally(() => setFetchingData(false));
  };

  useEffect(() => {
    filtersDispatch({
      type: SET_CONSUMERS_FILTERS,
      filters,
    });
  }, [filters]);

  useEffect(() => {
    if (user?.projects?.length === 1) {
      setFilters({ ...filters, project: user.projects[0].id });
    }
  }, [user]);

  const onChangeProject = (value: string) => {
    setFilters({ ...filters, project: value });
    let body;
    if (value) {
      body = {
        [consumersFilter.isProperty ? 'properties/project/id' : 'projects/id']: {
          type: FilterTypes.SELECT,
          value: value,
        },
      };
    } else {
      body = {
        [consumersFilter.isProperty ? 'properties/project/id' : 'projects/id']: {
          type: FilterTypes.SELECT_MULTIPLE,
          value: null,
        },
      };
    }

    onTableChange(undefined, body);
  };

  const onChangeBroker = (value: string) => {
    setFilters({ ...filters, agent: value });
    let body;
    if (value) {
      body = {
        'properties/agent/id': {
          type: FilterTypes.SELECT,
          value: value,
        },
      };
    } else {
      body = {
        'properties/agent/id': {
          type: FilterTypes.SELECT,
          value: null,
        },
      };
    }

    onTableChange(undefined, body);
  };

  const onSearch = (value: string) => {
    setFilters({ ...filters, searchLoginEmail: value });
    const body = {
      searchByName: {
        type: FilterTypes.MULTISEARCH,
        value: [{ firstName: value }, { lastName: value }, { email: value }],
      },
    };

    clearTimeout(Number(currentTimeoutLogin));
    setCurrentTimeoutLogin(setTimeout(() => onTableChange(undefined, body), 1000));
  };

  const onSearchProperty = (value: string) => {
    setFilters({ ...filters, searchProperty: value });
    const body = {
      'properties/title': {
        type: FilterTypes.SEARCH,
        value: value,
      },
    };

    clearTimeout(Number(currentTimeoutProperty));
    setCurrentTimeoutProperty(setTimeout(() => onTableChange(undefined, body), 1000));
  };

  const switchByProperty = (value: boolean) => {
    setCurrentFilters({});
    setFilters({ searchLoginEmail: '', searchProperty: '', agent: '', project: '', isProperty: value });
    option.byProperties = value;
    option.current = 1;
    option.filters = {};
    getUsers(option);
  };

  return (
    <div className={css['consumers-control-container']}>
      <div
        className={`${css['consumers-control-filters-container']} consumer-control-wrapper`}
        style={{ marginBottom: 0, height: '100%', marginTop: 0 }}>
        {isOpenImportForm && (
          <ImportConsumersModal
            isOpenImportForm={isOpenImportForm}
            setIsOpenImportForm={setIsOpenImportForm}
            getUsers={() => getUsers(option)}
            setIsOpenUploadMessageModal={setIsOpenUploadMessageModal}
          />
        )}
        {isOpenUploadMessageModal && (
          <ModalUploadFileSuccess isOpen={isOpenUploadMessageModal} onOk={() => setIsOpenUploadMessageModal(false)} />
        )}
        <Search
          allowClear
          className="consumers-control-search common-animation-primary"
          value={filters.searchLoginEmail}
          style={useLessThen801() ? { padding: '5px 0', marginTop: 0 } : { width: '230px', padding: '5px 0' }}
          placeholder={t('consumers.control.search.placeholder')}
          suffix={<img src={searchIcon} alt="" />}
          onChange={(e) => onSearch(e.target.value)}
        />
        <div className={css['select-container']}>
          <Select
            value={filters.project}
            style={useLessThen801() ? { padding: '5px 0', marginTop: 0 } : { padding: '5px 0' }}
            className="consumers-control-select common-animation-primary"
            onChange={onChangeProject}
            dropdownClassName="consumers-control-select-dropdown"
            placeholder={t('budget.control.filter.status.placeholder')}
            suffixIcon={
              <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
            }>
            <Option value="" className="budget-control-option">
              <span style={{ fontSize: '14px', fontWeight: 600 }}>{t('consumers.control.all.projects')}</span>
            </Option>
            {projects &&
              user &&
              projects
                .filter((project) => user.projects.find((item) => item.id === project.id))
                .sort((a, b) => a?.title.toLowerCase().localeCompare(b?.title.toLowerCase()))
                .map((project) => (
                  <Option value={project.id} key={project.id} className="budget-control-option">
                    <span style={{ fontSize: '14px' }}>{project.title}</span>
                  </Option>
                ))}
          </Select>
        </div>
        <Search
          allowClear
          value={filters.searchProperty}
          className="consumers-control-search common-animation-primary"
          style={useLessThen801() ? { padding: '5px 0', marginTop: 0 } : { width: '230px', padding: '5px 0' }}
          placeholder={t('consumers.control.search.placeholder.property')}
          suffix={<img src={searchIcon} alt="" />}
          onChange={(e) => onSearchProperty(e.target.value)}
          disabled={!consumersFilter.isProperty}
        />
        <div className={css['select-container']}>
          <Select
            value={filters.agent}
            disabled={!consumersFilter.isProperty}
            style={useLessThen801() ? { padding: '5px 0', marginTop: 0 } : { padding: '5px 0' }}
            className="consumers-control-select common-animation-primary"
            onChange={onChangeBroker}
            dropdownClassName={`consumers-control-select-dropdown ${
              fetchingData ? 'consumers-control-select-dropdown--progress' : ''
            }`}
            placeholder={t('budget.control.filter.status.placeholder')}
            onPopupScroll={(e: any) => {
              e.persist();
              let target = e.target;
              if (!fetchingData && !isLastUserReq && target.scrollTop + target.offsetHeight === target.scrollHeight) {
                getAgents();
              }
            }}
            suffixIcon={
              <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
            }>
            <Option value="" className="budget-control-option">
              <span style={{ fontSize: '14px', fontWeight: 600 }}>{t('consumers.control.all.agents')}</span>
            </Option>
            <Option value={user.id} key={user.id} className="budget-control-option">
              <span style={{ fontSize: '14px' }}>{`${user.firstName} ${user.lastName}`}</span>
            </Option>
            <OptGroup label={t('quotes.modal.form.agents')}>
              {users &&
                users.length > 0 &&
                users
                  .filter((u) => u.id !== user.id)
                  .sort((a, b) => a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()))
                  .map((u) => (
                    <Option value={u.id} key={u.id} className="budget-control-option">
                      <span style={{ fontSize: '14px' }}>{`${u.firstName} ${u.lastName}`}</span>
                    </Option>
                  ))}
            </OptGroup>
          </Select>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginLeft: '1rem' }}>{t('consumers.table.with.property')}</span>
          <Switch
            size="small"
            style={{ marginLeft: '1rem' }}
            defaultChecked={consumersFilter.isProperty}
            onChange={(value) => {
              switchByProperty(value);
            }}
          />
        </div>
      </div>
      {!isSupplier && !useLessThen801() && (
        <div className={css['consumers-control-btns-container']}>
          {/* <Link
          to="/import_consumers_example.csv"
          target="_blank"
          download
          className={css['consumers-control-download-link']}>
          <img src={downloadIcon} alt="" style={{ height: '15px', width: '15px' }} />
          <span style={{ marginLeft: '10px', fontWeight: 600 }}>{t('consumers.control.download.template')}</span>
        </Link>*/}
          <Button
            className={`common-blue-border-btn common-secondary-btn`}
            onClick={() => setIsOpenImportForm(true)}
            style={{ marginRight: '1rem', height: '42px' }}>
            {t('consumers.modal.import.btn')}
          </Button>
          <Link
            to={`${props.history.location.pathname}/modify`}
            style={{ borderRadius: 4 }}
            className="common-animation-primary">
            <Button className={`common-blue-btn common-primary-btn`} style={{ height: '42px' }}>
              {t('common.btn.new')}
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default ConsumersControl;
