import { Col, DatePicker, Form, FormInstance, Input, notification, Row, Select, TimePicker } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import css from '../../../assets/styles/meetings.module.css';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../contexts/AppContextProvider';
import UsersService from '../../../services/UsersService';
import { IUserEasy } from '../../../models/UserModel';
import { MeetingModel } from '../../../models/MeetingsModel';
import { IResponceData } from '../../../typings/IServiceOptions';
import { IAppContext } from '../../../typings/IApp';

const { TextArea } = Input;
const { Option } = Select;
const userService = new UsersService();

interface IForm {
  onSubmit: (data: any) => void;
  isTab?: boolean;
  form: FormInstance;
  id?: number | null;
  meetingInfo?: MeetingModel | null;
}

const MeetingFormComponent = (props: IForm) => {
  const { onSubmit, isTab, form, meetingInfo, id } = props;
  const { t } = useTranslation();
  const {
    app: { isConsumer, isSupplier, user },
  } = useContext<IAppContext>(AppContext);
  const [users, setUsers] = useState<IUserEasy[]>([]);
  const [currentSelectedUsers, setCurrentSelectedUsers] = useState<IUserEasy[]>([]);
  const [isLastUserReq, setIsLastUserReq] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [searchValue, setSearchValue] = useState<null | string>(null);
  const [currentTimeout, setCurrentTimeout] = useState<NodeJS.Timeout | null>(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    skip: 0,
  });

  useEffect(() => {
    if (isConsumer) {
      const onlyMyAgents = user.properties.map((property) => property.agent);
      //remove dublicates
      const agentsWithoutDublicates = onlyMyAgents
        .filter((agent, index: number, prev) => prev.findIndex((prev2) => prev2.id === agent.id) === index)
        .map((agent) => ({ ...agent, roles: [{ name: 'agent', description: 'Agent' }] }));
      setUsers(agentsWithoutDublicates);
      getAllUsers([], agentsWithoutDublicates);
    } else {
      if (id) {
        meetingInfo && getAllUsers(meetingInfo?.assignees?.map((a) => a.id) || []);
      } else {
        getAllUsers();
      }
    }
  }, [id, meetingInfo]);

  useEffect(() => {
    if (searchValue === '') {
      return getAllUsers([], currentSelectedUsers);
    }
    if (searchValue) {
      getAllUsers();
    }
  }, [searchValue]);

  const getAllUsers = (usersId?: number[], usersRes: IUserEasy[] = []) => {
    if (usersId && usersId.length > 0) {
      userService
        .getEasyUsers({ userId: usersId })
        .then((res: IUserEasy[]) => {
          setCurrentSelectedUsers(res);
          getAllUsers([], res);
        })
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        );
      return;
    }
    const optionUser = {
      count: true,
      top: pagination.pageSize,
      current: pagination.current,
      skip: pagination.pageSize * (pagination.current - 1) || 0,
      fullName: searchValue || '',
      projectId: isSupplier
        ? user.suppliersProperties.map((sup) => sup.id)
        : user.properties.map((property) => property.id),
      roles: isConsumer ? ['consumer', 'supplier'] : ['consumer', 'supplier', 'agent', 'admin', 'super_agent'],
    };

    setFetchingData(true);
    userService
      .getEasyUsers(optionUser)
      .then((res: IResponceData<IUserEasy>) => {
        const { count, items } = res;
        const { current, top } = optionUser;
        setPagination({ ...pagination, current: current + 1, total: count, pageSize: top });
        let usersIds: number[] = [];
        if (users.length > 0) {
          usersIds = users.map((user) => user.id);
        } else if (usersRes.length > 0) {
          usersIds = usersRes.map((user) => user.id);
        }
        setUsers([...usersRes, ...users, ...items.filter((item) => !usersIds.includes(item.id))]);
        setIsLastUserReq(current * top >= count);
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingData(false));
  };

  const getLabel = (roles: string[]) => {
    if (roles.length === 1 && roles.includes('super_agent')) {
      return <span style={{ color: '#0065de' }}>{t('quotes.modal.form.supper.agents')}</span>;
    }
    if ((!roles.includes('consumer') && !roles.includes('supplier')) || roles.includes('agent')) {
      return <span style={{ color: '#0065de' }}>{t('quotes.modal.form.agents')}</span>;
    }
    if (roles.includes('consumer')) {
      return <span style={{ color: '#e08b32' }}>{t('quotes.modal.form.acquirers')}</span>;
    }
    if (roles.includes('supplier')) {
      return <span style={{ color: '#b37feb' }}>{t('tasks.control.suppliers')}</span>;
    }
    return;
  };

  return (
    <Form
      onFinish={onSubmit}
      form={form}
      style={{ maxHeight: '90vh', overflowY: 'auto', padding: isTab ? 0 : '2rem', overflowX: 'hidden' }}>
      <Row gutter={[24, 4]}>
        <Col span={12}>
          <Form.Item
            name="date"
            colon={false}
            rules={[{ required: true, message: t('common.validation.required') }]}
            label={<span style={{ color: '#778dac' }}>{t('budget.form.currentDate')}</span>}
            className={css['form-item']}
            labelAlign="left">
            <DatePicker
              inputReadOnly
              format="DD.MM.YYYY"
              suffixIcon={<div className={'calendar-img'} style={{ width: '12px', height: '12px' }}></div>}
              className="common-date-picker common-animation-primary"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="time"
            colon={false}
            rules={[{ required: true, message: t('common.validation.required') }]}
            label={<span style={{ color: '#778dac' }}>{t('meeting.form.time')}</span>}
            className={css['form-item']}
            labelAlign="left">
            <TimePicker
              inputReadOnly
              format="HH:mm"
              className="common-date-picker common-animation-primary"
              style={{ width: '100%' }}
              minuteStep={5}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 4]}>
        <Col span={24}>
          <Form.Item
            name="name"
            colon={false}
            rules={[{ required: true, message: t('common.validation.required') }]}
            label={<span style={{ color: '#778dac' }}>{t('meeting.form.name')}</span>}
            className={css['form-item']}
            labelAlign="left">
            <Input className="common-animation-primary" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 4]}>
        <Col span={24}>
          <Form.Item
            name="details"
            colon={false}
            rules={[{ required: true, message: t('common.validation.required') }]}
            label={<span style={{ color: '#778dac' }}>{t('meeting.form.details')}</span>}
            className={css['form-item']}
            labelAlign="left">
            <TextArea autoSize={{ minRows: 3, maxRows: 10 }} className="common-animation-primary" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 4]}>
        <Col span={24}>
          <Form.Item
            name="assignees"
            colon={false}
            rules={[{ required: true, message: t('common.validation.required') }]}
            label={<span style={{ color: '#778dac' }}>{t('meeting.assigned.to')}</span>}
            className={css['form-item']}
            labelAlign="left">
            <Select
              className="common-select common-animation-primary"
              mode="multiple"
              onDropdownVisibleChange={(value) => {
                if (!value && searchValue) {
                  setUsers([]);
                  setPagination({ ...pagination, current: 1, skip: 0 });
                  setSearchValue('');
                }
              }}
              onSearch={(value) => {
                setUsers([]);
                setPagination({ ...pagination, current: 1, skip: 0 });
                clearTimeout(Number(currentTimeout));
                setCurrentTimeout(setTimeout(() => setSearchValue(value), 1000));
              }}
              filterOption={() => true}
              dropdownClassName={`${fetchingData ? 'consumers-control-select-dropdown--progress' : ''}`}
              onPopupScroll={(e: any) => {
                e.persist();
                let target = e.target;
                if (!fetchingData && !isLastUserReq && target.scrollTop + target.offsetHeight === target.scrollHeight) {
                  getAllUsers();
                }
              }}>
              {users.map((user) => (
                <Option value={user.id} key={user.id}>
                  {`${user.firstName} ${user.lastName}`} ({getLabel(user.roles.map((role) => role.name))})
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default MeetingFormComponent;
