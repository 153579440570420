import React from 'react';
import { INotificationHistory } from '../../../models/NotificationModel';
import moment from 'moment';
import { SHORT_DATE_TIME_FORMAT } from '../../../constants/formats';
import { TFunction } from 'react-i18next';

interface NotificationColumnsModel {
  t: TFunction<'translation'>;
}

export const getColumns = ({ t }: NotificationColumnsModel) => [
  {
    title: () => (
      <span className="budget-table-tr" style={{ marginLeft: '8px' }}>
        #
      </span>
    ),
    dataIndex: 'id',
    key: 'id',
    width: '5%',
  },
  {
    title: () => <span className="budget-table-tr">{t('notifications.column.message')}</span>,
    dataIndex: 'message',
    key: 'message',
    width: '16%',
  },
  {
    title: () => <span className="budget-table-tr">{t('notifications.column.linkName')}</span>,
    dataIndex: 'linkName',
    key: 'linkName',
    width: '16%',
  },
  {
    title: () => <span className="budget-table-tr">{t('notifications.column.creator')}</span>,
    width: '14%',
    key: 'createdUser',
    render: (cell: INotificationHistory) => (
      <span>{`${cell.createdUser?.firstName || ''} ${cell.createdUser?.lastName || ''}`}</span>
    ),
  },
  {
    title: () => <span className="budget-table-tr">{t('notifications.column.date')}</span>,
    width: '12%',
    key: 'createdDate',
    sorter: true,
    render: (cell: INotificationHistory) => (
      <span>{cell.createdDate && moment(cell.createdDate).format(SHORT_DATE_TIME_FORMAT)}</span>
    ),
  },
  {
    title: () => <span className="budget-table-tr">{t('notifications.column.delivered')}</span>,
    width: '18%',
    key: 'recipients',
    render: (cell: INotificationHistory) => <span>{cell?.recipients.map((el) => el.recipient?.login).join(' ')}</span>,
  },
];
