import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../assets/styles/details.module.css';
import LogoComponent from '../../../components/common/LogoComponent';
import { AppContext } from '../../../contexts/AppContextProvider';
import { PropertyModel } from '../../../models/PropertyModel';
import { IUserEasy } from '../../../models/UserModel';
import { IAppContext } from '../../../typings/IApp';

interface ClientInfoProps {
  propertyInfo: PropertyModel | null;
  userId?: number;
  brokerInfo?: IUserEasy;
}

const ClientInfo = (props: ClientInfoProps) => {
  const { propertyInfo, userId, brokerInfo } = props;
  const { t } = useTranslation();
  const {
    app: { user, isConsumer },
  } = useContext<IAppContext>(AppContext);

  const consumerId = isConsumer ? user.id : +(userId as number);
  const consumerInfo = propertyInfo && propertyInfo.consumers.find((consumer) => consumer.id === consumerId);

  return (
    <div className={css['info-container']}>
      <div className={css['client-title-container']}>
        <span className={css['client-title']}>
          {brokerInfo ? t('project.details.broker.info.title') : t('project.details.client.info.title')}
        </span>
      </div>
      <div className="client-main-info">
        <LogoComponent
          id={brokerInfo ? brokerInfo.id : consumerInfo!.id}
          size={'large'}
          name={`${brokerInfo ? brokerInfo.firstName : consumerInfo?.firstName || ''} ${
            brokerInfo ? brokerInfo.lastName : consumerInfo?.lastName || ''
          }`}
          image={
            brokerInfo
              ? brokerInfo.image || ''
              : propertyInfo?.consumers?.find((user) => user.id === user.id)!.image || ''
          }
        />
        <span className={css['client-name']}>{`${brokerInfo ? brokerInfo.firstName : consumerInfo?.firstName || ''} ${
          brokerInfo ? brokerInfo.lastName : consumerInfo?.lastName || ''
        }`}</span>
      </div>
      <div className={css['client-contact-info']}>
        <div className={css['client-contact-info-item']}>
          <span className={css['client-contact-label']}>{t('project.details.client.info.phone')}</span>
          <span>{brokerInfo ? brokerInfo.phone : consumerInfo?.phone || ''}</span>
        </div>
        <div className={css['client-contact-info-item']}>
          <span className={css['client-contact-label']}>{t('project.details.client.info.email')}</span>
          <span>{brokerInfo ? brokerInfo.email : consumerInfo?.email || ''}</span>
        </div>
      </div>
    </div>
  );
};

export default ClientInfo;
