import { Button, Checkbox, Form, Modal } from 'antd';
import React, { useContext, useEffect } from 'react';
import css from '../../../assets/styles/file-management.module.css';
import { FilterTypes } from '../../../constants/filterTypes';
import { useLessThen801 } from '../../../helpers/mediaDetect';
import moment from 'moment';
import { FiltersContext } from '../../../contexts/FiltersContextProvider';
import { GET_FILEMANAGMENT_FILTERS, SET_FILEMANAGMENT_FILTERS } from '../../../constants/actionTypes/filtersConstants';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useTranslation } from 'react-i18next';
import { IAppContext } from '../../../typings/IApp';
import { AppContext } from '../../../contexts/AppContextProvider';
import FileMangmentFilterPopup from './filter/FileManagmentFilterPopup';
import useGetPageTypeConfig from '../hooks/useGetPageTypeConfig';
import { getUploadTitleByType } from '../utils/getMessages';

interface IControl {
  onTableChange: any;
  openUpload: () => void;
  openCreate: () => void;
  downloadQuotes: () => void;
  closeModal: () => void;
  isOpenModal: boolean;
  resetFiltersRef: any;
  setIsChecked?: (b: boolean) => void;
  isChecked?: boolean;
  setIsAfterGetFilters: (b: boolean) => void;
  isCheckedExpire: boolean;
  setIsCheckedExpire: React.Dispatch<React.SetStateAction<boolean>>;
}

const FileManagementControl = (props: IControl) => {
  const {
    onTableChange,
    openUpload,
    openCreate,
    downloadQuotes,
    isOpenModal,
    closeModal,
    resetFiltersRef,
    setIsAfterGetFilters,
    setIsChecked,
    isChecked,
    isCheckedExpire,
    setIsCheckedExpire,
  } = props;
  const { t } = useTranslation();
  const {
    app: { user },
  } = useContext<IAppContext>(AppContext);
  const pageTypeConfig = useGetPageTypeConfig();
  const { filtersDispatch } = useContext(FiltersContext);
  const [form] = Form.useForm();

  useEffect(() => {
    filtersDispatch({
      type: GET_FILEMANAGMENT_FILTERS,
    });
    setIsAfterGetFilters(true);
  }, []);

  const onChangeCheckbox = (isCheck: boolean) => {
    setIsCheckedExpire(false);
    setIsChecked && setIsChecked(isCheck);
    resetFiltersRef.current();

    filtersDispatch({
      type: SET_FILEMANAGMENT_FILTERS,
      filters: {
        isCheck,
      },
    });

    let body: { [name: string]: { type: FilterTypes; value: any } } = {};
    if (isCheck) {
      body['current_step/consumerId'] = {
        type: FilterTypes.SELECT,
        value: user.id,
      };
      body['status_v2/code'] = {
        type: FilterTypes.NOT,
        value: 'rejected',
      };
    }
    onTableChange(null, body);
  };

  const onChangeCheckboxExpired = (e: CheckboxChangeEvent) => {
    const isCheck = e.target.checked;

    setIsCheckedExpire(isCheck);
    setIsChecked && setIsChecked(false);
    resetFiltersRef.current();

    let body: { [name: string]: { type: FilterTypes; value: any } } = {};
    if (isCheck) {
      body['status_v2/code'] = {
        type: FilterTypes.SELECT_MULTIPLE,
        value: [
          'client_approve',
          'client_sign',
          'agent_approve',
          'agent_sign',
          'client_approve_partially',
          'client_sign_partially',
          'agent_approve_partially',
          'agent_sign_partially',
          'new',
          ...(pageTypeConfig.isInvoices ? ['in_process', 'on_pause'] : []),
        ],
      };
      body[`${pageTypeConfig.isInvoices ? 'paymentDate' : 'deadline'}`] = {
        type: FilterTypes.DATE_LESS_THAN,
        value: moment(new Date()),
      };
    }
    onTableChange(null, body);
  };

  if (useLessThen801()) {
    return (
      <>
        {isOpenModal && (
          <Modal
            className="upload-modal"
            centered
            width={'auto'}
            visible={isOpenModal}
            footer={
              <div className="common-modal-footer">
                <Button
                  className={`common-gray-border-btn common-secondary-btn`}
                  onClick={() => {
                    resetFiltersRef.current();
                    closeModal();
                  }}>
                  {t('fileManagement.reset.all')}
                </Button>
                <Button
                  className={`common-green-btn common-modal-btn`}
                  onClick={() => {
                    form.submit();
                    closeModal();
                  }}>
                  {t('fileManagement.apply')}
                </Button>
              </div>
            }
            closable={false}>
            <div style={{ padding: '1rem', maxHeight: '84vh', overflowY: 'auto' }}>
              <FileMangmentFilterPopup
                onTableChange={onTableChange}
                setIsChecked={setIsChecked}
                setIsCheckedExpire={setIsCheckedExpire}
                resetFiltersRef={resetFiltersRef}
              />
            </div>
          </Modal>
        )}
      </>
    );
  }

  return (
    <div className={css['management-control-container']} style={{ marginBottom: '1rem' }}>
      <div
        className={`common-flex-start-style consumer-control-wrapper`}
        style={{
          marginBottom: 0,
          height: '100%',
          marginTop: 0,
        }}>
        <FileMangmentFilterPopup
          onTableChange={onTableChange}
          setIsChecked={setIsChecked}
          setIsCheckedExpire={setIsCheckedExpire}
          resetFiltersRef={resetFiltersRef}
        />
        <Checkbox
          checked={isChecked}
          style={{ marginLeft: '1rem' }}
          onChange={(e) => onChangeCheckbox(e.target.checked)}>
          {t('fileManagement.control.checkbox')}
        </Checkbox>
        {!pageTypeConfig.isDocuments && (
          <Checkbox checked={isCheckedExpire} style={{ marginLeft: '1rem' }} onChange={onChangeCheckboxExpired}>
            {t('fileManagement.control.checkboxExpired')}
          </Checkbox>
        )}
      </div>
      <div style={{ display: 'flex' }}>
        {pageTypeConfig.isQuotes && (
          <Button
            className={`common-blue-border-btn common-secondary-btn`}
            onClick={downloadQuotes}
            style={{ marginRight: '1rem', height: '42px' }}>
            <span>{t('budget.table.download')}</span>
          </Button>
        )}
        <Button
          className={`common-blue-border-btn common-secondary-btn`}
          onClick={() => openUpload()}
          style={{ marginRight: '1rem', height: '42px' }}>
          <span>{getUploadTitleByType(pageTypeConfig)}</span>
        </Button>
        {!pageTypeConfig.isDocuments && (
          <Button
            className={`common-blue-btn common-primary-btn`}
            onClick={() => openCreate()}
            style={{ height: '42px' }}>
            <span>{t('budget.control.create.btn')}</span>
          </Button>
        )}
      </div>
    </div>
  );
};

export default FileManagementControl;
