import React, { useState } from 'react';
import { Button, Select, Col, Form, Checkbox, Input, InputNumber, DatePicker } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { SelectValue } from 'antd/lib/select';
import { checkDigit } from '../../../helpers/checkDigit';
import DictionaryFieldSelect from '../../../components/common/DictionaryFieldSelect';
import css from '../../../assets/styles/properties.module.css';
import { useLessThen801 } from '../../../helpers/mediaDetect';

interface DynamicFieldsModel {
  name: string;
  isSelect: boolean;
  handleSelect: (isShow?: any) => void;
  fields: any[];
  values: any;
}

const DynamicFields: React.FC<DynamicFieldsModel> = ({
  name,
  isSelect,
  handleSelect,
  fields,
  values,
}: DynamicFieldsModel) => {
  const { t } = useTranslation();
  const [params, setParams] = useState(values);

  const getFieldName = (item: any) => `${item.paramsCode || item.param?.code || item.code}`;

  const getLabel = (code: string, label: string) => {
    return (
      <>
        <span style={{ color: '#778dac' }}>{label}</span>
        <CloseOutlined
          className="property-dynamic-delete-btn"
          style={{ cursor: 'pointer', paddingLeft: '1rem', color: 'rgb(7, 110, 229)', display: 'none' }}
          onClick={() => deleteParam(code)}
        />
      </>
    );
  };

  const selectFilterOption = (inputValue: any, option: any) => {
    return option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
  };

  const onChange = (params: any) => {
    setParams(params);
  };

  const handleParamSelect = (param: SelectValue) => {
    const item = fields.find((item: any) => item.code === param);
    if (item) {
      onChange({
        ...params,
        [item.code]: {
          value: null,
          type: item.type,
          title: item.title,
          description: item.description,
          code: item.code,
          dictionaryName: item.dictionary_name || item.dictionaryName,
        },
      });
      handleSelect(false);
    }
  };

  const deleteParam = (code: string) => {
    const toUpdate = { ...params };
    delete toUpdate[code];
    setParams(toUpdate);
  };

  const filteredParams = fields.filter((item: any) => {
    return !params ? true : !params[item.code];
  });

  const [formatStep, setFormatStep] = useState('1');
  const [isStep, setIsStep] = useState(false);

  const generageFormatStep = (value: string) => {
    if (!isStep) {
      setIsStep(true);
      let format = '1';
      value
        .split('')
        .reverse()
        .forEach((el: string, index: number) => {
          if (index === 0) {
            return;
          }
          if (el === '.') {
            return (format += el);
          }
          format += '0';
        });

      return setFormatStep(format.split('').reverse().join(''));
    }
  };

  const getParameterField = (item: any) => {
    const fieldName = [name, getFieldName(item), 'value'];
    switch (item.type) {
      case 'boolean':
        return (
          <Form.Item
            className="property-dynamic-fields-wrapper"
            name={fieldName}
            label={getLabel(item.code, item.description)}
            valuePropName="checked"
            wrapperCol={{ span: 24 }}>
            <Checkbox style={{ width: '100%' }} />
          </Form.Item>
        );
      case 'number': {
        return (
          <Form.Item
            className="property-dynamic-fields-wrapper"
            name={fieldName}
            label={getLabel(item.code, item.description)}
            rules={[{ required: true, message: t('common.validation.required') }]}
            wrapperCol={{ span: 24 }}>
            <InputNumber
              step={formatStep}
              style={{ width: '100%' }}
              className="faq-modify-select common-animation-primary"
              onChange={generageFormatStep}
              onKeyDown={(e) => {
                if (!checkDigit(e.key)) {
                  e.preventDefault();
                }
                setIsStep(false);
              }}
              stringMode
            />
          </Form.Item>
        );
      }
      case 'string':
        return (
          <Form.Item
            className="property-dynamic-fields-wrapper"
            name={fieldName}
            label={getLabel(item.code, item.description)}
            rules={[{ required: true, message: t('common.validation.required') }]}
            wrapperCol={{ span: 24 }}>
            <Input style={{ width: '100%' }} className="faq-modify-select common-animation-primary" />
          </Form.Item>
        );
      case 'date':
        return (
          <Form.Item
            className="property-dynamic-fields-wrapper"
            name={fieldName}
            label={getLabel(item.code, item.description)}
            rules={[{ required: true, message: t('common.validation.required') }]}
            wrapperCol={{ span: 24 }}>
            <DatePicker inputReadOnly style={{ width: '100%' }} showToday className="common-animation-primary" />
          </Form.Item>
        );
      case 'timestamp':
        return (
          <Form.Item
            className="property-dynamic-fields-wrapper"
            name={fieldName}
            label={getLabel(item.code, item.description)}
            rules={[{ required: true, message: t('common.validation.required') }]}
            wrapperCol={{ span: 24 }}>
            <DatePicker
              inputReadOnly
              style={{ width: '100%' }}
              showTime
              showToday
              className="common-animation-primary"
            />
          </Form.Item>
        );
      case 'dictionary':
        return (
          <span className="property-dynamic-fields-wrapper">
            <DictionaryFieldSelect
              name={fieldName}
              label={getLabel(item.code, item.description)}
              dictionaryName={item.dictionaryName}
              keyField="id"
              showField="description"
            />
          </span>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {useLessThen801()
        ? !!Object.keys(params).length &&
          Object.values(params).map((item: any) => (
            <Col span={24} key={item.code}>
              {getParameterField(item)}
            </Col>
          ))
        : !!Object.keys(params).length &&
          Object.values(params).map((item: any) => (
            <Col span={12} key={item.code}>
              {getParameterField(item)}
            </Col>
          ))}
      {isSelect ? (
        <Select
          autoFocus
          defaultOpen
          className="common-select common-animation-primary"
          suffixIcon={
            <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
          }
          placeholder={<span style={{ color: '#778dac' }}>{t('properties.create.items.addDomain.placeholder')}</span>}
          onChange={handleParamSelect}
          onBlur={handleSelect}
          showSearch
          filterOption={selectFilterOption}
          style={{ width: '100%' }}>
          {filteredParams.map((item) => (
            <Select.Option key={item.code} value={item.code}>
              {item.description}
            </Select.Option>
          ))}
        </Select>
      ) : (
        <Button
          type="dashed"
          onClick={handleSelect}
          className="add-field-btn"
          icon={<PlusOutlined style={{ color: '#778dac' }} />}>
          <span style={{ color: '#778dac', fontWeight: 600 }}>{t('properties.create.items.addField')}</span>
        </Button>
      )}
    </>
  );
};

export default DynamicFields;
