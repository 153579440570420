import { Button, Cascader, Col, Form, Input, Modal, notification, Row, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StatusesModel, StepsModel } from '../../../../models/TimelineModel';
import TimelineService from '../../../../services/TimelineService';
import css from '../../../../assets/styles/properties.module.css';
import ListPageServices from '../../../../services/PropertiesService';
import { AppContext } from '../../../../contexts/AppContextProvider';
import { useParams } from 'react-router';
import { IAppContext } from '../../../../typings/IApp';

interface Props {
  isOpen: boolean;
  id: number;
  setIsOpen: (b: boolean) => void;
  getSteps: (id: number) => void;
}
const { Option } = Select;

const service = new TimelineService();
const propertyService = new ListPageServices();

const StepsSettings = (props: Props) => {
  const { isOpen, id, setIsOpen, getSteps } = props;
  const params = useParams<{ projectId: string; id: string }>();
  const {
    app: { user },
  } = useContext<IAppContext>(AppContext);
  const [form] = Form.useForm();
  const [statuses, setStatuses] = useState<StatusesModel>([]);
  const [steps, setSteps] = useState<StepsModel>([]);
  const { t } = useTranslation();
  const [properties, setProperties] = useState<any[]>([]);
  const projectKey = (user && user.projects.find((project) => project.id === +params.projectId)?.key) || '';

  useEffect(() => {
    service
      .getTimeline(id)
      .then((value: StepsModel) => setSteps(value))
      .catch((e) => {
        notification.error({
          message: e.message,
        });
        setIsOpen(false);
      });
    service
      .getStatuses()
      .then((value: StatusesModel) => setStatuses(value))
      .catch((e) => {
        notification.error({
          message: e.message,
        });
        setIsOpen(false);
      });
    propertyService
      .getPropertyWithFilter({
        searchObjects: { type_code: 'building' },
        projectKey,
      })
      .then((data) => {
        propertiesTree(data);
      });
  }, []);

  const onSubmit = (values: any) => {
    const newSteps = values.steps?.map((step: any, index: number) => {
      const newStep: { [name: string]: any } = {
        ord: index + 1,
        description: step.description,
        dateDescription: step.dateDescription || null,
      };
      if (step.id) {
        newStep.id = step.id;
      }
      if (step.status?.code) {
        newStep.status = {
          code: step.status.code,
        };
      } else {
        newStep.status = { code: 'to_do' };
      }
      return newStep;
    });
    const stepsDTO = {
      steps: newSteps,
    } as any;

    if (values.copyTo?.length > 0) {
      const newCopyTo: any = [];
      values.copyTo.map((item: any) => newCopyTo.push(item[item.length - 1]));
      stepsDTO.copyToProperties = newCopyTo;
    }
    service
      .createTimeline(stepsDTO, id)
      .then(() => {
        notification.success({
          message: t('calendar.timeline.update.notification'),
        });
      })
      .catch((e) => {
        notification.error({
          message: e.message,
        });
      })
      .finally(() => {
        setIsOpen(false);
        getSteps(id);
      });
  };

  const loadData = async (selectedOptions: { [name: string]: any }) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;

    const propertyChildren = await propertyService
      .getPropertyChildren({
        id: targetOption.id,
        projectKey,
      })
      .finally(() => {
        targetOption.loading = false;
      });
    propertiesTree(propertyChildren, targetOption);
  };

  const propertiesTree = (data: any[], targetOption?: any) => {
    let newData: any[] = [];
    if (targetOption) {
      targetOption.childrens = data.map((item: any) => {
        const { childrens, ...rest } = item;
        return {
          ...rest,
          disabled: item.id === +params.id,
          isLeaf: childrens.length === 0,
        };
      });
    } else {
      newData = data.map((item: any) => {
        const { childrens, ...rest } = item;
        return {
          ...rest,
          disabled: item.id === +params.id,
          isLeaf: childrens.length === 0,
        };
      });
    }
    setProperties(targetOption ? [...properties] : newData);
  };

  return (
    <Modal
      centered
      width={'34rem'}
      visible={isOpen}
      title={<span style={{ fontSize: '18px' }}>{t('properties.steps.title')}</span>}
      footer={
        <div className="common-modal-footer">
          <Button
            className={`${css['settings-modal-cancel-btn']} common-secondary-btn`}
            onClick={() => setIsOpen(false)}>
            {t('budget.modal.cancel.btn')}
          </Button>
          <Button className={`${css['settings-modal-save-btn']} common-modal-btn`} onClick={() => form.submit()}>
            {t('budget.modal.save.btn')}
          </Button>
        </div>
      }
      closable={false}
      className="common-modal">
      <Form autoComplete="off" form={form} onFinish={onSubmit}>
        {steps && steps.length > 0 && (
          <Form.List
            name="steps"
            initialValue={
              steps &&
              steps.map((step) => {
                return {
                  ...step,
                };
              })
            }>
            {(fields, { add, remove }) => {
              return (
                <div style={{ marginTop: '1rem' }}>
                  {fields.map((field, index) => {
                    return (
                      <div key={field.key}>
                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                          <span className={css['step-index']}>{index + 1}</span>
                          <div
                            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}
                            className={css['steps-settings-bottom-item']}>
                            <div style={{ display: 'flex' }}>
                              <Form.Item
                                className={css['steps-settings-input']}
                                name={[index, 'description']}
                                style={{ margin: 0 }}
                                rules={[
                                  { required: true, message: t('common.validation.required') },
                                  { max: 40, message: t('properties.length.rule') },
                                ]}>
                                <Input
                                  className={`${css['steps-settings-input']} consumer-form-input common-animation-primary`}
                                  style={{ width: '400px' }}
                                />
                              </Form.Item>
                              <Button
                                className={css['btn-delete']}
                                onClick={() => remove(field.name)}
                                style={{ marginLeft: '1rem', border: 'none' }}></Button>
                            </div>
                            <Row
                              gutter={[24, 4]}
                              style={{ marginTop: '1rem' }}
                              className={css['steps-settings-bottom-container']}>
                              <Col span={16} className={css['steps-settings-bottom-item']}>
                                <span style={{ color: '#778dac' }}>{t('calendar.status.title').toLowerCase()}</span>
                                <Form.Item
                                  name={[index, 'dateDescription']}
                                  className={css['steps-settings-bottom-item-form']}>
                                  <Input
                                    className={`${css['property-date-input']} common-animation-primary`}
                                    placeholder={t('calendar.data.placeholder')}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8} className={css['steps-settings-bottom-item']}>
                                <span style={{ color: '#778dac' }}>
                                  {t('requests.view.card.info.status').toLowerCase()}
                                </span>
                                <Form.Item
                                  name={[index, 'status', 'code']}
                                  initialValue="to_do"
                                  rules={[{ required: true, message: t('common.validation.required') }]}>
                                  <Select
                                    className="faq-modify-select common-animation-primary"
                                    suffixIcon={
                                      <div
                                        className={`arrow-drop-img ${css['arrow-drop']}`}
                                        style={{ width: '8px', height: '4px' }}></div>
                                    }>
                                    {statuses.map((status) => (
                                      <Option value={status.code} key={status.code}>
                                        {status.description}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <Button
                    className={`${css['property-add-new']} common-primary-btn`}
                    style={{ height: '30px', width: '70px' }}
                    onClick={() => add()}>
                    {t('budget.upload.attachment.btn.add')}
                  </Button>
                </div>
              );
            }}
          </Form.List>
        )}
        {((steps && steps.length === 0) || !steps) && (
          <>
            <br />
            <Button
              className={`${css['property-add-new']} common-primary-btn`}
              style={{ height: '30px', width: '70px', marginTop: '1rem' }}
              onClick={() => setSteps([{ description: null } as any])}>
              {t('budget.upload.attachment.btn.add')}
            </Button>
          </>
        )}
        <Form.Item
          label={<span style={{ color: '#778dac' }}>{t('properties.create.copy.to')}</span>}
          name="copyTo"
          style={{ marginTop: '1rem' }}>
          <Cascader
            //NOTE: need research why is note supported in 4.17.0 v of antd
            // suffixIcon={
            //   <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
            // }
            className="faq-modify-select common-animation-primary"
            placeholder={t('properties.create.items.goods.placeholder')}
            fieldNames={{ label: 'title', value: 'id', children: 'childrens' }}
            options={properties}
            loadData={loadData}
            multiple
            changeOnSelect
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default StepsSettings;
