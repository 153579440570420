import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import {
  Form,
  Input,
  Button,
  Card,
  Row,
  Col,
  Select,
  Avatar,
  Image,
  Space,
  Upload,
  notification,
  Spin,
  Radio,
  RadioChangeEvent,
} from 'antd';
import { useTranslation } from 'react-i18next';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import UsersService from '../../services/UsersService';
import RolesService from '../../services/RolesService';
import ProjectsService from '../../services/ProjectsService';
import config from '../../config';
import { DeleteOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { UploadChangeParam } from 'antd/lib/upload';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { getBase64Url } from '../../helpers/avatarTools';
import consumerCss from '../../assets/styles/consumers.module.css';
import { useLessThen801 } from '../../helpers/mediaDetect';
import { CHANGE_CURRENT_PAGE } from '../../constants/actionTypes/headerConstants';
import { HeaderContext } from '../../contexts/HeaderContextProvider';
import { AppContext } from '../../contexts/AppContextProvider';
import BoardTourComponent from '../common/BoardTourComponent';
import CustomOnBoard from '../common/CustomOnBoard';
import ProjectFields from './components/ProjectsFields';
import DefaultProjectFields from './components/DefaultProjectFields';
import moment from 'moment';
import ConsumersService from '../../services/ConsumersService';
import editIcon from '../../assets/images/ic-edit-blue.svg';
import checkIcon from '../../assets/images/ic-check-mark.svg';
import { getOnboarding, OnboardingTypes, saveOnboarding } from '../../helpers/onboarding';
import { IProjectCompany, IProjectEasy } from '../../models/ProjectModel';
import { IUserFull, UserCreateModel } from '../../models/UserModel';
import { RoleModel } from '../../models/RoleModel';
import { IAppContext } from '../../typings/IApp';

const userService: UsersService = new UsersService();
const consumerService: ConsumersService = new ConsumersService();
const rolesService: RolesService = new RolesService();
const projectsService: ProjectsService = new ProjectsService();

const UserModifyPage: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { headerDispatch } = useContext(HeaderContext);
  const isCreate = !id;
  const [form] = Form.useForm();
  const [user, setUser] = useState<IUserFull | undefined>(undefined);
  const [roles, setRoles] = useState<RoleModel[]>([]);
  const [projects, setProjects] = useState<(IProjectEasy | IProjectCompany)[]>([]);
  const [breadcrumbNameMap, setBreadcrumbNameMap] = useState<{ [name: string]: string }>({});
  const [image, setImage] = useState<UploadFile | null>(null);
  const [imageUrl, setImageUrl] = useState<string | ArrayBuffer | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDeleteImage, setIsDeleteImage] = useState<boolean>(false);
  const [isLoadingImage, setIsLoadingImage] = useState<boolean>(false);
  const [isSupplierRole, setIsSupplierRole] = useState(false);
  const [userProjects, setUserProjects] = useState<IProjectCompany[]>([]);
  const [selectedProjects, setSelectedProjects] = useState<Array<string>>([]);
  const [isEmailInvite, setIsEmailInvite] = useState<boolean>(false);
  const [isEmailEdit, setIsEmailEdit] = useState(false);
  const { app } = useContext<IAppContext>(AppContext);

  //board
  const [steps, _] = useState([
    {
      target: '.users-modify-first-step',
      content: <BoardTourComponent title={t('users.modify.first.title')} description={t('users.modify.first.text')} />,
      disableBeacon: true,
      placement: 'right',
    },
    {
      target: '.users-modify-second-step',
      content: (
        <BoardTourComponent title={t('users.modify.second.title')} description={t('users.modify.second.text')} />
      ),
      disableBeacon: true,
      placement: 'left',
    },
    {
      target: '.users-modify-third-step',
      content: <BoardTourComponent title={t('users.modify.third.title')} description={t('users.modify.third.text')} />,
      disableBeacon: true,
      placement: 'left',
    },
  ]);
  const [run, setRun] = useState(false);

  const getUser = () => {
    userService
      .getUserById(+id)
      .then((user: IUserFull) => {
        setUser(user);
        if (user.roles.includes('supplier')) {
          setIsSupplierRole(true);
        }
        setUserProjects(user.projects);
        form.setFieldsValue({
          login: user.login,
          firstName: user.firstName,
          lastName: user.lastName,
          projects: user.projects?.map((project) => project.id) || [],
          phone: user.phone,
          email: user.email,
          defaultFields: user.projects?.map((property) => property.id) || [],
          roles: user.roles ?? [],
        });
        setSelectedProjects(user.projects?.map((property) => property.key));
        if (user.image) {
          setImageUrl(`${config.storeUrl}/${user.image}`);
        }
        getProjects(user.projects);
      })
      .finally(() =>
        setRun(getOnboarding(isCreate ? OnboardingTypes.USER_MODIFY_NEW : OnboardingTypes.USER_MODIFY_EDIT)),
      );
  };

  const getRoles = () => {
    rolesService.getRoles().then((res: RoleModel[]) => {
      setRoles(res);
    });
  };

  const getProjects = (userProjects: IProjectCompany[] = []) => {
    projectsService.getProjects().then((res: IProjectEasy[]) => {
      if (userProjects.length > 0) {
        const projectsId = res.map((p) => p.id);
        return setProjects([...res, ...userProjects.filter((p) => !projectsId.includes(p.id))]);
      }
      setProjects(res);
    });
  };

  useEffect(() => {
    if (!isCreate) {
      getUser();
    } else {
      setRun(getOnboarding(isCreate ? OnboardingTypes.USER_MODIFY_NEW : OnboardingTypes.USER_MODIFY_EDIT));
      getProjects();
    }
    getRoles();
  }, []);

  useEffect(() => {
    if (isCreate) {
      headerDispatch({
        type: CHANGE_CURRENT_PAGE,
        currentPage: t('header.title.Consumers.create'),
        path: 'users/modify',
      });
    }
  }, []);

  useEffect(() => {
    setBreadcrumbNameMap(
      history.location.pathname
        .replace('/users', '')
        .replace(/\/modify\/\d+/, '/Modifications')
        .replace(/\/modify/, `/${t('common.title.create')}`)
        .split('/')
        .slice(1)
        .reduce(
          (res, curr, index) => ({
            ...res,
            [[Object.keys(res)[index], curr].join('/')]: curr.split(':').shift(),
          }),
          { '/users': t('users.bread.root') },
        ),
    );
  }, [history.location.pathname]);

  const beforeUploadImage = (file: RcFile) => {
    setIsLoadingImage(true);
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      notification.error({ message: t('projects.create.logo.mimetype.error') });
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      notification.error({ message: t('projects.create.logo.fileSize.error') });
    }
    setIsLoadingImage(false);
    return isJpgOrPng && isLt2M;
  };

  const handleChangeImage = (info: UploadChangeParam<UploadFile<any>>) => {
    const { file } = info;
    getBase64Url(file.originFileObj, (imageUrl: string | ArrayBuffer | null) => {
      setImage(file);
      setImageUrl(imageUrl);
      setIsLoadingImage(false);
    });
  };

  const deleteImage = () => {
    if (imageUrl) {
      setImage(null);
      setImageUrl(null);
      setIsDeleteImage(true);
    }
  };

  const onSubmit = (values: any) => {
    if (
      values.projects ? !values.projects?.length : isCreate ? !values.fields?.length : !values.defaultFields?.length
    ) {
      notification.error({
        message: t('properties.notification.select.project'),
      });
      return;
    }

    setIsSaving(true);

    let resultProjects;
    let resultProperties;

    if (values.roles === 'supplier' || 'supplier'.includes(values.roles)) {
      const defaultProperties =
        values.defaultFields && values.defaultFields.map((field: any) => field.properties).flat(4);
      const defaultPropertiesArray = defaultProperties && [...defaultProperties];

      const newProperties = values.fields && values.fields.map((field: any) => field.properties).flat();

      if (!defaultPropertiesArray) {
        resultProperties = [...newProperties];
      } else if (!newProperties) {
        resultProperties = defaultPropertiesArray;
      } else {
        resultProperties = defaultPropertiesArray.concat(...newProperties);
      }
      const defaultProjects = values.defaultFields && values.defaultFields.map((field: any) => field.project);
      const newProjects = values.fields && values.fields.map((field: any) => field.project);
      const defaultProjectsArray =
        defaultProjects &&
        projects
          .filter((f: any) => defaultProjects.some((item: any) => item === f.title || item === f.key))
          .map((item: any) => item.id);
      const newProjectsArray =
        newProjects &&
        projects.filter((f: any) => newProjects.some((item: any) => item === f.key)).map((item: any) => item.id);

      if (!defaultProjects) {
        resultProjects = [...newProjectsArray];
      } else if (!newProjects) {
        resultProjects = defaultProjectsArray;
      } else {
        resultProjects = defaultProjectsArray.concat(...newProjectsArray);
      }
    }

    const body: { [name: string]: any } = {
      firstName: values.firstName,
      roles: values.roles === 'supplier' || 'supplier'.includes(values.roles) ? ['supplier'] : values.roles,
      lastName: values.lastName,
      phone: values.phone,
      email: values.email,
      login: values.login.trim(),
      technicalPassword: values.technicalPassword || undefined,
      projects: values.roles === 'supplier' || 'supplier'.includes(values.roles) ? resultProjects : values.projects,
    };

    if (isCreate) {
      body.withInvite = isEmailInvite;
    }

    if (values.roles === 'supplier' || 'supplier'.includes(values.roles)) {
      body.suppliersProperties = resultProperties;
    }

    const modelActions = (id: number) => {
      const promises: Promise<any>[] = [];
      if (image) {
        promises.push(userService.uploadImage(id, image));
      }
      if (isDeleteImage && !imageUrl) {
        promises.push(userService.deleteImage(id));
      }
      Promise.all(promises).finally(() => {
        setIsSaving(false);
        if (isCreate) {
          notification.success({
            message: t('users.create.notification.success'),
          });
        }
        history.push('/users');
      });
    };

    if (isCreate) {
      userService
        .createUser(body as UserCreateModel)
        .then((res) => {
          modelActions(res.id);
        })
        .catch((e) => {
          notification.error({
            message: e.message,
          });
          setIsSaving(false);
        });
    } else {
      userService
        .updateUser(+id, body)
        .then(() => {
          modelActions(+id);
        })
        .catch((e) => {
          notification.error({
            message: e.message,
          });
          setIsSaving(false);
        });
    }
  };

  const onCancel = () => {
    history.push('/users');
  };

  const unblockUser = () => {
    setIsSaving(true);
    userService
      .unblock(+id)
      .then(() => {
        getUser();
        notification.success({
          message: t('consumers.unblock.success.notify'),
        });
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setIsSaving(false));
  };

  const selectFilterOption = (inputValue: any, option: any) => {
    return option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
  };

  const selectRole = (value: string | undefined) => {
    if (value === 'supplier') {
      setIsSupplierRole(true);
      const fieldValue = form.getFieldsValue();
      form.setFieldsValue({ ...fieldValue, roles: 'supplier', projects: [] });
    } else {
      setIsSupplierRole(false);
    }
  };

  const resendEmail = () => {
    if (user) {
      setIsSaving(true);
      consumerService
        .resendEmail(user.id)
        .then(() => {
          notification.success({
            message: t('consumers.email.resend.notification'),
          });
          getUser();
        })
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        )
        .finally(() => setIsSaving(false));
    }
  };

  const handleCheckEditEmail = () => {
    if (isEmailEdit) {
      return form.submit();
    }
    setIsEmailEdit(!isEmailEdit);
  };

  const checkSuperBroker = () => {
    if (user && app.user.rolesParams.IS_SUPER_BROKER) {
      return !isEmailEdit;
    }
    return !!user;
  };

  const isDisableRole = (role: string) => {
    if (app.user.roles.includes('admin')) {
      return false;
    }
    if (app.user.roles.includes('super_agent')) {
      return !['supplier', 'agent', 'super_agent'].includes(role);
    }
    if (app.user.roles.includes('agent')) {
      return !['supplier', 'agent'].includes(role);
    }
    return true;
  };

  if (useLessThen801()) {
    return (
      <Spin spinning={isSaving}>
        <Form autoComplete="off" form={form} layout="vertical" name="modify_users" onFinish={onSubmit}>
          <HeaderTable
            title={!isCreate ? `${t('users.create.title.update')} #${id}` : t('users.create.title.create')}
            breadcrumb={breadcrumbNameMap}>
            <Form.Item>
              <div className="common-flex-style" style={{ marginTop: '1rem' }}>
                <Button
                  htmlType="button"
                  onClick={onCancel}
                  className={`common-blue-border-btn common-secondary-btn`}
                  style={{ height: '42px', marginRight: '0.5rem', width: '100%' }}>
                  {t('common.btn.cancel')}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={`common-blue-btn common-primary-btn`}
                  style={{ height: '42px', marginLeft: '0.5rem', width: '100%' }}>
                  {t('common.btn.save')}
                </Button>
              </div>
            </Form.Item>
          </HeaderTable>
          <Row gutter={[24, 4]}>
            <Col span={24}>
              <Card
                title={t('users.create.card.user.title')}
                style={{
                  padding: '2rem',
                  border: 'solid 1px #c4c6ce',
                  backgroundColor: '#f5f7fa',
                  borderRadius: '6px',
                }}>
                <Row gutter={[24, 4]}>
                  <Col span={24}>
                    <Form.Item>
                      {imageUrl ? (
                        <Avatar
                          src={
                            <>
                              <Image src={imageUrl as string} preview={false} />
                              <div className="logo-mask">
                                <Space>
                                  <Upload
                                    accept=".png, .jpg, .jpeg"
                                    showUploadList={false}
                                    beforeUpload={beforeUploadImage}
                                    onChange={handleChangeImage}
                                    customRequest={() => {}}>
                                    <Button type="link" size="large">
                                      <EditOutlined style={{ fontSize: '24px' }} />
                                    </Button>
                                  </Upload>
                                  <Button type="link" size="large" onClick={deleteImage}>
                                    <DeleteOutlined style={{ fontSize: '24px' }} />
                                  </Button>
                                </Space>
                              </div>
                            </>
                          }
                          size={150}
                        />
                      ) : (
                        <Upload.Dragger
                          accept=".png, .jpg, .jpeg"
                          listType="picture-card"
                          showUploadList={false}
                          beforeUpload={beforeUploadImage}
                          onChange={handleChangeImage}
                          customRequest={() => {}}
                          className="consumer-modify-upload">
                          <div className="consumer-modify-upload">
                            {isLoadingImage ? <LoadingOutlined /> : <div className="consumer-modify-upload-icon"></div>}
                            <div style={{ marginTop: 8, marginLeft: 4, marginRight: 4 }}>
                              {t('consumers.btn.upload.profile')}
                            </div>
                          </div>
                        </Upload.Dragger>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={<span style={{ color: '#778dac' }}>{t('users.create.card.user.login')}</span>}
                      name="login"
                      rules={[{ required: true, message: t('common.validation.required') }]}>
                      {/* TODO: Translate */}
                      <Input disabled={!isCreate} className="common-animation-primary" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={<span style={{ color: '#778dac' }}>{t('users.create.card.user.firstName')}</span>}
                      name="firstName"
                      rules={[{ required: true, message: t('common.validation.required') }]}>
                      <Input className="common-animation-primary" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={<span style={{ color: '#778dac' }}>{t('users.create.card.user.lastName')}</span>}
                      name="lastName"
                      rules={[{ required: true, message: t('common.validation.required') }]}>
                      <Input className="common-animation-primary" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={<span style={{ color: '#778dac' }}>{t('users.create.card.user.phone')}</span>}
                      name="phone">
                      <Input className="common-animation-primary" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={<span style={{ color: '#778dac' }}>{t('users.create.card.user.email')}</span>}
                      name="email"
                      rules={[{ required: true, message: t('common.validation.required') }, { type: 'email' }]}>
                      <Input className="common-animation-primary" disabled={checkSuperBroker()} />
                    </Form.Item>
                    {user && app.user.rolesParams.IS_SUPER_BROKER && (
                      <Button
                        className={consumerCss['user-edit-email-btn']}
                        icon={<img src={isEmailEdit ? checkIcon : editIcon} />}
                        onClick={() => handleCheckEditEmail()}></Button>
                    )}
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={<span style={{ color: '#778dac' }}>{t('users.create.card.user.projects')}</span>}
                      name="projects"
                      rules={[{ required: true, message: t('common.validation.required') }]}>
                      <Select
                        className="common-select common-animation-primary"
                        mode="multiple"
                        showSearch
                        filterOption={selectFilterOption}
                        placeholder={t('users.create.card.user.projects.placeholder')}>
                        {projects
                          .sort((a, b) => a?.title.toLowerCase().localeCompare(b?.title.toLowerCase()))
                          .map((project) => {
                            return (
                              <Select.Option value={project.id} key={project.id} title={project.title}>
                                {project.title}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                  {isCreate && (
                    <Col span={24}>
                      <Radio.Group
                        style={{ marginBottom: '1rem' }}
                        onChange={(e: RadioChangeEvent) => setIsEmailInvite(e.target.value)}
                        value={isEmailInvite}>
                        <Radio value={false}>{t('users.create.card.user.technicalPassword')}</Radio>
                        <Radio value={true}>{t('users.create.card.user.emailIntite')}</Radio>
                      </Radio.Group>
                    </Col>
                  )}
                  {(isCreate ? !isEmailInvite : true) && (
                    <Col span={24}>
                      <Form.Item
                        label={
                          <span style={{ color: '#778dac' }}>{t('users.create.card.user.technicalPassword')}</span>
                        }
                        name="technicalPassword">
                        <Input.Password className="common-animation-primary" style={{ borderRadius: '6px' }} />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Card>
            </Col>
            <Col span={24}>
              <Card
                title={t('users.create.card.roles.title')}
                style={{ border: 'solid 1px #c4c6ce', backgroundColor: '#f5f7fa', borderRadius: '6px' }}>
                <Col span={24}>
                  <Form.Item
                    label={<span style={{ color: '#778dac' }}>{t('roles.bread.root')}</span>}
                    name="roles"
                    rules={[{ required: true, message: t('common.validation.required') }]}>
                    <Select
                      className="common-select common-animation-primary"
                      mode={isSupplierRole ? undefined : 'multiple'}
                      showSearch
                      filterOption={selectFilterOption}
                      onSelect={(role) => selectRole(role as string)}
                      placeholder={t('users.create.card.user.projects.placeholder')}>
                      {roles
                        .filter((role) => role.name !== 'consumer')
                        .sort((a, b) => a?.name.toLowerCase().localeCompare(b?.name.toLowerCase()))
                        .map((role) => {
                          return (
                            <Select.Option
                              value={role.name}
                              key={role.name}
                              title={role.name}
                              disabled={isDisableRole(role.name)}>
                              {role.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                {!isSupplierRole && (
                  <Col span={24}>
                    <Form.Item
                      label={<span style={{ color: '#778dac' }}>{t('users.create.card.user.projects')}</span>}
                      name="projects"
                      rules={[{ required: true, message: t('common.validation.required') }]}>
                      <Select
                        className="common-select common-animation-primary"
                        mode="multiple"
                        showSearch
                        filterOption={selectFilterOption}
                        placeholder={t('users.create.card.user.projects.placeholder')}>
                        {projects
                          .sort((a, b) => a?.title.toLowerCase().localeCompare(b?.title.toLowerCase()))
                          .map((project) => {
                            return (
                              <Select.Option value={project.id} key={project.id} title={project.title}>
                                {project.title}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                {isSupplierRole && (
                  <Col span={24} style={{ height: '100%' }} className={consumerCss['consumer-modify-col']}>
                    <div
                      className={consumerCss['consumers-table-container']}
                      style={{ height: '100%', overflowY: 'auto', border: 'none', padding: 0 }}>
                      <Row>
                        {projects.length > 0 && (
                          <Form.List name="defaultFields">
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map((field, index) => (
                                  <Row
                                    key={field.name}
                                    gutter={[0, 4]}
                                    className={consumerCss['consumer-table-group-row__right-col']}>
                                    {userProjects.length > 0 && (
                                      <>
                                        <DefaultProjectFields
                                          key={userProjects[index].id}
                                          projects={projects as IProjectEasy[]}
                                          user={user}
                                          consumerProject={userProjects[index]}
                                          index={index}
                                          selectedProjects={selectedProjects}
                                          setSelectedProjects={setSelectedProjects}
                                          form={form}
                                        />
                                        <Button
                                          onClick={() => {
                                            remove(field.name);
                                            const newUserProjects = userProjects.slice(0);
                                            newUserProjects.splice(index, 1);
                                            setUserProjects(newUserProjects);
                                            const fieldValue = form.getFieldsValue();
                                            const newDefaultFields =
                                              fieldValue.defaultFields &&
                                              fieldValue.defaultFields.map(
                                                (field: { project: string }) => field.project,
                                              );
                                            const newFields =
                                              fieldValue.fields &&
                                              fieldValue.fields.map((field: { project: string }) => field.project);
                                            const newSelectedProjects = newFields
                                              ? newDefaultFields.concat(newFields)
                                              : newDefaultFields;
                                            setSelectedProjects(newSelectedProjects);
                                          }}
                                          className={consumerCss['consumer-table-btn-delete']}></Button>
                                      </>
                                    )}
                                  </Row>
                                ))}
                              </>
                            )}
                          </Form.List>
                        )}
                        <Form.List name="fields">
                          {(fields, { add, remove }) => {
                            return (
                              <>
                                {fields.map((field, index) => (
                                  <Row
                                    key={field.key}
                                    gutter={[0, 4]}
                                    className={consumerCss['consumer-table-group-row__right-col']}>
                                    <ProjectFields
                                      projects={projects as IProjectEasy[]}
                                      user={user}
                                      selectedProjects={selectedProjects}
                                      setSelectedProjects={setSelectedProjects}
                                      index={index}
                                      remove={remove}
                                      form={form}
                                    />
                                    <Button
                                      onClick={() => {
                                        remove(field.name);
                                        const fieldValue = form.getFieldsValue();
                                        const newDefaultFields =
                                          fieldValue.defaultFields &&
                                          fieldValue.defaultFields.map((field: { project: string }) => field.project);
                                        const newFields =
                                          fieldValue.fields &&
                                          fieldValue.fields.map((field: { project: string }) => field.project);
                                        const newSelectedProjects = newDefaultFields
                                          ? newDefaultFields.concat(newFields)
                                          : newFields;
                                        setSelectedProjects(newSelectedProjects);
                                      }}
                                      className={consumerCss['consumer-table-btn-delete']}></Button>
                                  </Row>
                                ))}
                                <Row
                                  className={consumerCss['create-add-project-container']}
                                  style={{ marginBottom: '24px' }}>
                                  <Button
                                    className={`common-gray-border-btn ${consumerCss['common-consumer-modify-btn']}`}
                                    onClick={() => add()}>
                                    {t('consumers.create.add.project')}
                                  </Button>
                                </Row>
                              </>
                            );
                          }}
                        </Form.List>
                      </Row>
                    </div>
                  </Col>
                )}
                {!isCreate && (
                  <Row>
                    <div className={consumerCss['user-table-group-title']}>{t('consumers.table.group.tech.info')}</div>
                    <Row gutter={[0, 4]} className={consumerCss['consumer-table-group-row__right-col']}>
                      <Col span={24}>
                        <Row
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginLeft: '1rem',
                          }}>
                          <span style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ color: '#778dac' }}>{t('consumers.invite.confirmed')}</span>
                            {user && user.isConfirmed ? (
                              <span style={{ color: '#00d2ab', marginLeft: '2rem' }}>
                                {t('common.confirm.btn.yes')}
                              </span>
                            ) : (
                              <span style={{ color: '#eb7d7e', marginLeft: '2rem' }}>
                                {t('common.confirm.btn.cancel')}
                              </span>
                            )}
                          </span>
                          {app.user.rolesParams.hasOwnProperty('IS_SUPER_BROKER') && user && !user.isConfirmed && (
                            <Button
                              className={`common-blue-btn common-primary-btn ${consumerCss['common-consumer-modify-btn']}`}
                              onClick={resendEmail}>
                              {t('consumers.email.resend.btn')}
                            </Button>
                          )}
                        </Row>
                        {user && user.lastLoginDate && (
                          <Row
                            style={{
                              marginTop: '1rem',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                              marginLeft: '1rem',
                            }}>
                            <span style={{ color: '#778dac' }}>{t('consumers.table.columns.lastLoginDate')}</span>
                            <span style={{ marginLeft: '1rem' }}>{`${moment(user.lastLoginDate).format(
                              'YYYY.MM.DD HH:MM',
                            )}`}</span>
                          </Row>
                        )}
                        {(app.user.rolesParams.IS_SUPER_BROKER || app.user.rolesParams.IS_SUPER_USER) &&
                          user &&
                          user.lockedDate && (
                            <div
                              style={{ marginTop: '1rem', float: 'right' }}
                              className={consumerCss['unlock-container']}>
                              <Button
                                className={`common-blue-btn common-primary-btn ${consumerCss['common-consumer-modify-btn']}`}
                                onClick={unblockUser}>
                                {t('consumers.btn.unblock')}
                              </Button>
                            </div>
                          )}
                      </Col>
                    </Row>
                  </Row>
                )}
              </Card>
            </Col>
          </Row>
        </Form>
      </Spin>
    );
  }

  return (
    <Spin spinning={isSaving}>
      <CustomOnBoard
        steps={steps}
        run={run}
        onFinish={() =>
          saveOnboarding(
            isCreate ? OnboardingTypes.USER_MODIFY_NEW : OnboardingTypes.USER_MODIFY_EDIT,
            app.user.boardCheckpoint,
          )
        }
      />
      <Form autoComplete="off" form={form} layout="vertical" name="modify_users" onFinish={onSubmit}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <HeaderTable breadcrumb={breadcrumbNameMap} />
          <Form.Item>
            <div className="common-flex-style users-modify-third-step">
              <Button
                htmlType="button"
                onClick={onCancel}
                className={`common-blue-border-btn common-secondary-btn`}
                style={{ height: '42px', marginRight: '1rem' }}>
                {t('common.btn.cancel')}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className={`common-blue-btn common-primary-btn`}
                style={{ height: '42px' }}>
                {t('common.btn.save')}
              </Button>
            </div>
          </Form.Item>
        </div>
        <Row gutter={[24, 4]}>
          <Col span={12} className="users-modify-first-step">
            <Card
              title={t('users.create.card.user.title')}
              style={{ padding: '2rem', border: 'solid 1px #c4c6ce', backgroundColor: '#f5f7fa', borderRadius: '6px' }}>
              <Row gutter={[24, 4]}>
                <Col span={12}>
                  <Form.Item>
                    {imageUrl ? (
                      <Avatar
                        src={
                          <>
                            <Image src={imageUrl as string} preview={false} />
                            <div className="logo-mask">
                              <Space>
                                <Upload
                                  accept=".png, .jpg, .jpeg"
                                  showUploadList={false}
                                  beforeUpload={beforeUploadImage}
                                  onChange={handleChangeImage}
                                  customRequest={() => {}}>
                                  <Button type="link" size="large">
                                    <EditOutlined style={{ fontSize: '24px' }} />
                                  </Button>
                                </Upload>
                                <Button type="link" size="large" onClick={deleteImage}>
                                  <DeleteOutlined style={{ fontSize: '24px' }} />
                                </Button>
                              </Space>
                            </div>
                          </>
                        }
                        size={150}
                      />
                    ) : (
                      <Upload.Dragger
                        accept=".png, .jpg, .jpeg"
                        listType="picture-card"
                        showUploadList={false}
                        beforeUpload={beforeUploadImage}
                        onChange={handleChangeImage}
                        customRequest={() => {}}
                        className="consumer-modify-upload">
                        <div className="consumer-modify-upload">
                          {isLoadingImage ? <LoadingOutlined /> : <div className="consumer-modify-upload-icon"></div>}
                          <div style={{ marginTop: 8, marginLeft: 4, marginRight: 4 }}>
                            {t('consumers.btn.upload.profile')}
                          </div>
                        </div>
                      </Upload.Dragger>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={<span style={{ color: '#778dac' }}>{t('users.create.card.user.login')}</span>}
                    name="login"
                    rules={[{ required: true, message: t('common.validation.required') }]}>
                    <Input disabled={!isCreate} className="common-animation-primary" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={<span style={{ color: '#778dac' }}>{t('users.create.card.user.firstName')}</span>}
                    name="firstName"
                    rules={[{ required: true, message: t('common.validation.required') }]}>
                    <Input className="common-animation-primary" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={<span style={{ color: '#778dac' }}>{t('users.create.card.user.lastName')}</span>}
                    name="lastName"
                    rules={[{ required: true, message: t('common.validation.required') }]}>
                    <Input className="common-animation-primary" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={<span style={{ color: '#778dac' }}>{t('users.create.card.user.phone')}</span>}
                    name="phone">
                    <Input className="common-animation-primary" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={<span style={{ color: '#778dac' }}>{t('users.create.card.user.email')}</span>}
                    name="email"
                    rules={[{ required: true, message: t('common.validation.required') }, { type: 'email' }]}>
                    <Input className="common-animation-primary" disabled={checkSuperBroker()} />
                  </Form.Item>
                  {user && app.user.rolesParams.IS_SUPER_BROKER && (
                    <Button
                      className={consumerCss['user-edit-email-btn']}
                      icon={<img src={isEmailEdit ? checkIcon : editIcon} />}
                      onClick={() => handleCheckEditEmail()}></Button>
                  )}
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={12} className="users-modify-second-step">
            <Card
              title={t('users.create.card.roles.title')}
              style={{ border: 'solid 1px #c4c6ce', backgroundColor: '#f5f7fa', borderRadius: '6px' }}>
              <Form.Item
                name="roles"
                rules={[
                  {
                    validator: (_, value) => {
                      if (value === undefined || value.length === 0)
                        return Promise.reject(new Error('Please select Role for user')); // TODO: Translate

                      return Promise.resolve();
                    },
                  },
                ]}>
                <Col span={24}>
                  <Form.Item
                    label={<span style={{ color: '#778dac' }}>{t('roles.bread.root')}</span>}
                    name="roles"
                    rules={[{ required: true, message: t('common.validation.required') }]}>
                    <Select
                      className="common-select common-animation-primary"
                      mode={isSupplierRole ? undefined : 'multiple'}
                      showSearch
                      filterOption={selectFilterOption}
                      onSelect={(role) => selectRole(role as string)}
                      placeholder={t('users.create.card.user.roles.placeholder')}>
                      {roles
                        .filter((role) => role.name !== 'consumer')
                        .sort((a, b) => a?.name.toLowerCase().localeCompare(b?.name.toLowerCase()))
                        .map((role) => {
                          return (
                            <Select.Option
                              value={role.name}
                              key={role.name}
                              title={role.name}
                              disabled={isDisableRole(role.name)}>
                              {role.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                {!isSupplierRole && (
                  <Col span={24}>
                    <Form.Item
                      label={<span style={{ color: '#778dac' }}>{t('users.create.card.user.projects')}</span>}
                      name="projects"
                      rules={[{ required: true, message: t('common.validation.required') }]}>
                      <Select
                        className="common-select common-animation-primary"
                        mode="multiple"
                        showSearch
                        filterOption={selectFilterOption}
                        placeholder={t('users.create.card.user.projects.placeholder')}>
                        {projects
                          .sort((a, b) => a?.title.toLowerCase().localeCompare(b?.title.toLowerCase()))
                          .map((project) => {
                            return (
                              <Select.Option value={project.id} key={project.id} title={project.title}>
                                {project.title}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                {isSupplierRole && (
                  <Col span={24} style={{ height: '100%' }} className={consumerCss['consumer-modify-col']}>
                    <div
                      className={consumerCss['consumers-table-container']}
                      style={{ height: '100%', overflowY: 'auto', border: 'none', padding: 0 }}>
                      <Row>
                        {projects.length > 0 && (
                          <Form.List name="defaultFields">
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map((field, index) => (
                                  <Row
                                    key={field.name}
                                    gutter={[0, 4]}
                                    className={consumerCss['consumer-table-group-row__right-col']}>
                                    {userProjects.length > 0 && (
                                      <>
                                        <DefaultProjectFields
                                          key={userProjects[index].id}
                                          projects={projects as IProjectEasy[]}
                                          user={user}
                                          consumerProject={userProjects[index]}
                                          index={index}
                                          selectedProjects={selectedProjects}
                                          setSelectedProjects={setSelectedProjects}
                                          form={form}
                                        />
                                        <Button
                                          onClick={() => {
                                            remove(field.name);
                                            const newUserProjects = userProjects.slice(0);
                                            newUserProjects.splice(index, 1);
                                            setUserProjects(newUserProjects);
                                            const fieldValue = form.getFieldsValue();
                                            const newDefaultFields =
                                              fieldValue.defaultFields &&
                                              fieldValue.defaultFields.map(
                                                (field: { project: string }) => field.project,
                                              );
                                            const newFields =
                                              fieldValue.fields &&
                                              fieldValue.fields.map((field: { project: string }) => field.project);
                                            const newSelectedProjects = newFields
                                              ? newDefaultFields.concat(newFields)
                                              : newDefaultFields;
                                            setSelectedProjects(newSelectedProjects);
                                          }}
                                          className={consumerCss['consumer-table-btn-delete']}></Button>
                                      </>
                                    )}
                                  </Row>
                                ))}
                              </>
                            )}
                          </Form.List>
                        )}
                        <Form.List name="fields">
                          {(fields, { add, remove }) => {
                            return (
                              <>
                                {fields.map((field, index) => (
                                  <Row
                                    key={field.key}
                                    gutter={[0, 4]}
                                    className={consumerCss['consumer-table-group-row__right-col']}>
                                    <ProjectFields
                                      projects={projects as IProjectEasy[]}
                                      user={user}
                                      selectedProjects={selectedProjects}
                                      setSelectedProjects={setSelectedProjects}
                                      index={index}
                                      remove={remove}
                                      form={form}
                                    />
                                    <Button
                                      onClick={() => {
                                        remove(field.name);
                                        const fieldValue = form.getFieldsValue();
                                        const newDefaultFields =
                                          fieldValue.defaultFields &&
                                          fieldValue.defaultFields.map((field: { project: string }) => field.project);
                                        const newFields =
                                          fieldValue.fields &&
                                          fieldValue.fields.map((field: { project: string }) => field.project);
                                        const newSelectedProjects = newDefaultFields
                                          ? newDefaultFields.concat(newFields)
                                          : newFields;
                                        setSelectedProjects(newSelectedProjects);
                                      }}
                                      className={consumerCss['consumer-table-btn-delete']}></Button>
                                  </Row>
                                ))}
                                <Row
                                  className={consumerCss['create-add-project-container']}
                                  style={{ marginBottom: '24px' }}>
                                  <Button
                                    className={`common-gray-border-btn ${consumerCss['common-consumer-modify-btn']}`}
                                    onClick={() => add()}>
                                    {t('consumers.create.add.project')}
                                  </Button>
                                </Row>
                              </>
                            );
                          }}
                        </Form.List>
                      </Row>
                    </div>
                  </Col>
                )}
                {isCreate && (
                  <Col span={24}>
                    <Radio.Group
                      style={{ marginBottom: '1rem' }}
                      onChange={(e: RadioChangeEvent) => setIsEmailInvite(e.target.value)}
                      value={isEmailInvite}>
                      <Radio value={false}>{t('users.create.card.user.technicalPassword')}</Radio>
                      <Radio value={true}>{t('users.create.card.user.emailIntite')}</Radio>
                    </Radio.Group>
                  </Col>
                )}
                {(isCreate ? !isEmailInvite : true) && (
                  <Col span={24}>
                    <Form.Item
                      label={<span style={{ color: '#778dac' }}>{t('users.create.card.user.technicalPassword')}</span>}
                      rules={[{ required: !user, message: t('common.validation.required') }]}
                      name="technicalPassword">
                      <Input.Password
                        autoComplete="new-password"
                        className="common-animation-primary"
                        style={{ borderRadius: '6px' }}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Form.Item>
              {!isCreate && (
                <Row>
                  <div className={consumerCss['user-table-group-title']}>{t('consumers.table.group.tech.info')}</div>
                  <Row gutter={[0, 4]} className={consumerCss['consumer-table-group-row__right-col']}>
                    <Col span={24}>
                      <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          <span style={{ color: '#778dac' }}>{t('consumers.invite.confirmed')}</span>
                          {user && user.isConfirmed ? (
                            <span style={{ color: '#00d2ab', marginLeft: '2rem' }}>{t('common.confirm.btn.yes')}</span>
                          ) : (
                            <span style={{ color: '#eb7d7e', marginLeft: '2rem' }}>
                              {t('common.confirm.btn.cancel')}
                            </span>
                          )}
                        </span>
                        {app.user.rolesParams.hasOwnProperty('IS_SUPER_BROKER') && user && !user.isConfirmed && (
                          <Button
                            className={`common-blue-btn common-primary-btn ${consumerCss['common-consumer-modify-btn']}`}
                            onClick={resendEmail}>
                            {t('consumers.email.resend.btn')}
                          </Button>
                        )}
                      </Row>
                      {user && user.lastLoginDate && (
                        <Row style={{ marginTop: '1rem' }}>
                          <span style={{ color: '#778dac' }}>{t('consumers.table.columns.lastLoginDate')}</span>
                          <span style={{ marginLeft: '1rem' }}>{`${moment(user.lastLoginDate).format(
                            'YYYY.MM.DD HH:MM',
                          )}`}</span>
                        </Row>
                      )}
                      {(app.user.rolesParams.IS_SUPER_BROKER || app.user.rolesParams.IS_SUPER_USER) &&
                        user &&
                        user.lockedDate && (
                          <div
                            style={{ marginTop: '1rem', float: 'right' }}
                            className={consumerCss['unlock-container']}>
                            <Button
                              className={`common-blue-btn common-primary-btn ${consumerCss['common-consumer-modify-btn']}`}
                              onClick={unblockUser}>
                              {t('consumers.btn.unblock')}
                            </Button>
                          </div>
                        )}
                    </Col>
                  </Row>
                </Row>
              )}
            </Card>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default UserModifyPage;
