import { Button, Form, Input } from 'antd';
import React from 'react';
import { convertToCurrency } from '../../../helpers/convertToPrice';
import css from '../../../assets/styles/project.module.css';
import { ICFC, ICFCitem } from '../../../models/CFCModel';
import { TFunction } from 'react-i18next';

interface ProjectCFCColumnsProps {
  t: TFunction<'translation'>;
  openModal: (CFC: ICFC) => void;
}

export const getColumns = ({ t, openModal }: ProjectCFCColumnsProps) => [
  {
    title: () => <span className="documents-table-tr">{t('projects.cfc')}</span>,
    key: 'code',
    width: '13%',
    render: (cell: ICFC) => <span>{cell.code}</span>,
  },
  {
    title: () => <span className="documents-table-tr">{t('budget.form.description')}</span>,
    key: 'description',
    with: '15%',
    render: (cell: ICFC) => <span>{cell.description}</span>,
  },
  {
    title: () => <span className="documents-table-tr">{t('projects.cfc.items')}</span>,
    key: 'items',
    width: '10%',
    render: (cell: ICFC) => <span>{cell.items}</span>,
  },
  {
    title: () => <span className="documents-table-tr">{t('budget.table.status')}</span>,
    key: 'active',
    width: '15%',
    render: (cell: ICFC) => (
      <span>
        {cell.active ? t('common.filters.isActive').toLowerCase() : t('common.filters.nonActive').toLowerCase()}
      </span>
    ),
  },
  {
    title: () => <span className="documents-table-tr">{t('budget.table.action')}</span>,
    key: 'action',
    width: 100,
    render: (cell: ICFC) => (
      <Button
        className={`common-blue-border-btn common-secondary-btn cfc-modify-second-step`}
        onClick={() => {
          openModal(cell);
        }}>
        {t('projects.cfc.setting')}
      </Button>
    ),
  },
];

interface ProjectCFCModalColumnsProps {
  t: TFunction<'translation'>;
  currency: string;
}

export const getModalColumns = ({ t, currency }: ProjectCFCModalColumnsProps) => [
  {
    title: () => <span className="documents-table-tr">№</span>,
    key: 'id',
    width: 50,
    render: (cell: ICFCitem, _: any, index: number) => (
      <Form.Item name={['items', index, 'id']} initialValue={cell.id} style={{ marginBottom: 0 }}>
        <span> {index + 1}</span>,
      </Form.Item>
    ),
  },
  {
    title: () => <span className="documents-table-tr">{t('budget.form.description')}</span>,
    key: 'description',
    width: '60%',
    render: (cell: ICFCitem) => <span>{cell.description}</span>,
  },
  {
    title: () => <span className="documents-table-tr">{t('properties.unit')}</span>,
    key: 'unit',
    width: '15%',
    render: (cell: ICFCitem) => <span>{cell.unit || '-'}</span>,
  },
  {
    title: () => <span className="documents-table-tr">{t('budget.table.sum')}</span>,
    key: 'sum',
    width: '25%',
    render: (cell: ICFCitem, _: any, index: number) => (
      <div style={{ display: 'flex', alignItems: 'center' }} className={css['project-cfc-sum-container']}>
        {currency}
        <Form.Item
          name={['items', index, 'sum']}
          initialValue={cell.sum ? convertToCurrency(cell.sum).toFixed(2) : cell.sum}
          style={{ marginBottom: 0 }}>
          <Input type="number" step={0.01} className={css['project-cfc-input']} />
        </Form.Item>
      </div>
    ),
  },
];
