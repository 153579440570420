import React, { useState, useEffect, useContext } from 'react';
import { Form, Row, Col, Input, InputNumber, Select, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import SpacesService from '../../../services/SpacesService';
import { TypologiesContext } from '../../../contexts/TypologiesContextProvider';
import { nodeKeys } from '../enums/nodeKeys';
import NodesModifyLayout from './NodesModifyLayout';
import {
  TYPOLOGY_REFRESH_ITEMS,
  TYPOLOGY_SET_SELECTED_NODES,
} from '../../../constants/actionTypes/typologiesConstants';
import { validateObjectField } from '../../../helpers/validateObjectField';
import { useLessThen801 } from '../../../helpers/mediaDetect';

const service: SpacesService = new SpacesService();

const SpacesModify: React.FC<any> = (props: any) => {
  const {
    nodes: { currentNode, currentNodeKey, parent },
    nodesDispatch,
  } = useContext(TypologiesContext);
  const { t } = useTranslation();
  const isCreate = currentNodeKey === nodeKeys.space && !currentNode;
  const [form] = Form.useForm();
  const [space, setSpace] = useState<any>(undefined);
  const [types, setTypes] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [variantId, setVariantId] = useState<number | null>(null);

  const setIsRefresh = (isRefresh: boolean) => {
    nodesDispatch({
      type: TYPOLOGY_REFRESH_ITEMS,
      isRefresh,
    });
  };

  const setSelectedNodes = (selectedNodes: any[]) => {
    nodesDispatch({
      type: TYPOLOGY_SET_SELECTED_NODES,
      selectedNodes,
    });
  };

  const getSpace = (id: number) => {
    return service
      .getSpaceById(id)
      .then((res: any) => {
        setSpace(res);
        form.setFieldsValue({
          name: res.name,
          title: res.title,
          surface: res.surface,
          babylonCode: res.babylonCode,
          babylonParams: res.babylonParams && JSON.stringify(res.babylonParams),
          type: res.type,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getSpaceTypes = () => {
    return service.getSpaceTypes().then((res: any) => {
      setTypes(res);
    });
  };

  const getAsyncData = async () => {
    const actions = [getSpaceTypes()];
    if (!isCreate) {
      actions.unshift(getSpace(currentNode.id));
    }
    Promise.all(actions).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (currentNodeKey === nodeKeys.space && (!currentNode || space?.id !== currentNode?.id)) {
      setLoading(true);
      getAsyncData();
    }
    if (currentNodeKey === nodeKeys.space && isCreate) {
      setVariantId(parent.id);
    }
  }, [currentNodeKey, currentNode]);

  const onSubmit = async () => {
    const values = await form.validateFields();
    const hasProperty = Object.prototype.hasOwnProperty;
    if (!hasProperty.call(values, 'errorFields')) {
      setIsSaving(true);
      if (isCreate) {
        service
          .createSpace({
            ...values,
            ...(values.babylonParams ? { babylonParams: JSON.parse(values.babylonParams) } : {}),
            variant: { id: variantId },
          })
          .then((res: any) => {
            setSelectedNodes([`${nodeKeys.space}-${res.id}`]);
            getSpace(res.id);
            notification.success({
              message: t('space.create.notification.create'),
            });
            setIsRefresh(true);
          })
          .catch((e) => {
            notification.error({
              message: e.message || t('common.error.internalServerError'),
            });
          })
          .finally(() => setIsSaving(false));
      } else {
        service
          .updateSpace({
            ...values,
            ...(values.babylonParams ? { babylonParams: JSON.parse(values.babylonParams) } : {}),
            id: currentNode.id,
          })
          .then(() => {
            getSpace(currentNode.id);
            notification.success({
              message: t('space.create.notification.update'),
            });
            setIsRefresh(true);
          })
          .catch((e) => {
            notification.error({
              message: e.message || t('common.error.internalServerError'),
            });
          })
          .finally(() => setIsSaving(false));
      }
    }
  };

  if (useLessThen801()) {
    return (
      <NodesModifyLayout submit={onSubmit} loading={loading} isSaving={isSaving}>
        <Form autoComplete="off" form={form} layout="vertical" name="modify_space">
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Form.Item
                label={t('space.create.name')}
                name="name"
                rules={[{ required: true, message: t('space.create.name.validation') }]}>
                <Input className="common-animation-primary" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t('space.create.title')}
                name="title"
                rules={[{ required: true, message: t('space.create.title.validation') }]}>
                <Input className="common-animation-primary" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={t('space.create.surface')} name="surface">
                <InputNumber className="common-animation-primary" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t('space.create.types')}
                name={['type', 'id']}
                rules={[{ required: true, message: t('space.create.types.validation') }]}>
                <Select
                  className="budget-control-select common-select common-animation-primary"
                  allowClear
                  placeholder={t('common.goods.placeholder')}>
                  {types.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item?.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={t('space.create.babylonCode')} name="babylonCode">
                <Input className="common-animation-primary" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t('space.create.babylonParams')}
                name="babylonParams"
                rules={[validateObjectField('babylonParams')]}>
                <Input.TextArea rows={4} className="common-animation-primary" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </NodesModifyLayout>
    );
  }

  return (
    <NodesModifyLayout submit={onSubmit} loading={loading} isSaving={isSaving}>
      <Form autoComplete="off" form={form} layout="vertical" name="modify_space">
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Form.Item
              label={t('space.create.name')}
              name="name"
              rules={[{ required: true, message: t('space.create.name.validation') }]}>
              <Input className="common-animation-primary" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('space.create.title')}
              name="title"
              rules={[{ required: true, message: t('space.create.title.validation') }]}>
              <Input className="common-animation-primary" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('space.create.surface')} name="surface">
              <InputNumber className="common-animation-primary" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('space.create.types')}
              name={['type', 'id']}
              rules={[{ required: true, message: t('space.create.types.validation') }]}>
              <Select
                className="budget-control-select common-select common-animation-primary"
                allowClear
                placeholder={t('common.goods.placeholder')}>
                {types.map((item: any) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item?.description}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('space.create.babylonCode')} name="babylonCode">
              <Input className="common-animation-primary" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('space.create.babylonParams')}
              name="babylonParams"
              rules={[validateObjectField('babylonParams')]}>
              <Input.TextArea rows={4} className="common-animation-primary" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </NodesModifyLayout>
  );
};

export default SpacesModify;
