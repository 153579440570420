import HttpService from './common/httpService';
import { LoginModel } from '../models/LoginModel';
import { ChangePasswordModel, RestorePasswordModel } from '../models/ChangePasswordModel';
import config from '../config';

class LoginPageServices {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getToken(body: LoginModel) {
    return this.httpService.post(`${config.authRequest}`, body);
  }

  public getProfile() {
    return this.httpService.get(`${config.baseRequest}auth/user/profile`);
  }

  public logOut() {
    return this.httpService.put(`${config.baseRequest}auth/logout`, null);
  }

  public changePassword(body: ChangePasswordModel) {
    return this.httpService.post(`${config.baseRequest}auth/changePassword`, body);
  }

  public restorePassword(body: RestorePasswordModel) {
    return this.httpService.post(`${config.baseRequest}auth/restorePassword`, body);
  }

  public procoreAuth(token: string) {
    return this.httpService.post(`${config.baseRequest}auth/procore/token`, { token });
  }

  public sendContactForm(body: { email: string; phone: string; language: string }, token: string | null | undefined) {
    return this.httpService.post(`${config.baseRequest}emit/contact-us${token ? `?token=${token}` : ''} `, body);
  }
}

export default LoginPageServices;
