import { Col, Form, FormInstance, Select, TreeSelect } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ListPageServices from '../../../services/PropertiesService';
import css from '../../../assets/styles/consumers.module.css';
import { useLessThen640 } from '../../../helpers/mediaDetect';
import { IProjectCompany, IProjectEasy } from '../../../models/ProjectModel';
import { IUserFull } from '../../../models/UserModel';
import { PropertyModel } from '../../../models/PropertyModel';

interface ProjectFieldsProps {
  consumerProject?: IProjectCompany;
  projects: IProjectEasy[];
  user: IUserFull | undefined;
  index?: number;
  remove?: (index: number | number[]) => void;
  form: FormInstance;
  selectedProjects: string[];
  setSelectedProjects: React.Dispatch<React.SetStateAction<string[]>>;
}
const listService = new ListPageServices();

const DefaultProjectFields = (props: ProjectFieldsProps) => {
  const { t } = useTranslation();
  const { consumerProject, projects, user, index, form, selectedProjects, setSelectedProjects } = props;
  const [properties, setProperties] = useState<PropertyModel[]>([]);

  const prop = user?.suppliersProperties
    .filter((item) => item.project.key === consumerProject?.key)
    .map((item) => item.id);

  useEffect(() => {
    if (consumerProject) {
      getProperties(consumerProject.key);
    }
  }, []);

  const getProperties = async (projectKey: string) => {
    await listService
      .getLightTreeByProject({
        id: projects.find((project) => project.key === projectKey)!.id,
        projectKey,
      })
      .then((value: PropertyModel[]) => {
        setProperties(value);
      });
  };

  return (
    <>
      <Col span={useLessThen640() ? 22 : 10}>
        <Form.Item
          label={<span style={{ color: '#778dac' }}>{t('consumers.choose.project')}</span>}
          initialValue={consumerProject?.key}
          rules={[{ required: true, message: t('common.validation.required') }]}
          name={[index as number, 'project']}>
          <Select
            onChange={(value: string) => {
              const fieldValue = form.getFieldsValue();
              const newDefaultFields =
                fieldValue.defaultFields && fieldValue.defaultFields.map((field: { project: string }) => field.project);
              const newFields =
                fieldValue.fields && fieldValue.fields.map((field: { project: string }) => field.project);
              const newSelectedProjects = newFields ? newDefaultFields.concat(newFields) : newDefaultFields;
              setSelectedProjects(newSelectedProjects);
              getProperties(value);
              form.setFieldsValue({
                ...fieldValue,
                defaultFields: fieldValue.defaultFields.map((value: { project: string }, i: number) => {
                  if (i === index) {
                    return { ...value, properties: [] };
                  }
                  return value;
                }),
              });
            }}
            suffixIcon={
              <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
            }
            placeholder={t('users.create.card.user.projects.placeholder')}
            className="consumer-form-input common-animation-primary">
            {projects.map((project) => {
              return (
                <Select.Option
                  value={project.key}
                  key={project.id}
                  title={project.title}
                  disabled={selectedProjects.includes(project.key)}>
                  {project.title}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </Col>
      <Col span={useLessThen640() ? 22 : 11}>
        {properties.length > 0 && (
          <Form.Item
            shouldUpdate
            name={[index as number, 'properties']}
            initialValue={prop}
            rules={[{ required: true, message: t('common.validation.required') }]}
            label={<span style={{ color: '#778dac' }}>{t('consumers.choose.property')}</span>}>
            <TreeSelect
              treeData={properties}
              multiple
              showCheckedStrategy="SHOW_CHILD"
              treeCheckable
              fieldNames={{ label: 'title', value: 'id', children: 'childrens' }}
            />
          </Form.Item>
        )}
      </Col>
    </>
  );
};

export default DefaultProjectFields;
