import HttpService from './common/httpService';
import buildQuery from 'odata-query';
import config from '../config';
import { getApiKeyOptions } from '../helpers/getApiKeyOptions';

const toDataURL = (url: any) =>
  fetch(url, {
    headers: {},
  }).then((response) => response.blob());

class ListPageServices {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getProperties(projectKey?: string, filter?: { [name: string]: any }) {
    let query = '';
    const options = getApiKeyOptions(projectKey);
    if (filter) {
      const { fullName, ...rest } = filter;
      query = buildQuery({ filter: rest });
      if (fullName) {
        if (query) {
          query += `&fullName=${fullName}`;
        } else {
          query = `?fullName=${fullName}`;
        }
      }
    }
    return this.httpService.get(`${config.baseRequest}properties${query}`, options);
  }

  public getPropertyChildren({ id, projectKey }: any) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.get(`${config.baseRequest}properties/${id}/children`, options);
  }

  public getPropertyById({ id, projectKey }: any) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.get(`${config.baseRequest}properties/${id}`, options);
  }

  public saveProperty({ body, id, projectKey }: any) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService[id ? 'put' : 'post'](`${config.baseRequest}properties${id ? `/${id}` : ``}`, body, options);
  }

  public async saveImages(propertyId: number, fileList: any[], type: string) {
    const formData = new FormData();

    fileList
      .filter((data: any) => !!data.originFileObj)
      .forEach((data: any) => {
        formData.append('file', data.originFileObj);
      });

    return this.httpService.postFile(`${config.baseRequest}properties/images/${type}/${propertyId}`, formData);
  }

  public async deleteImages(ids: number[]) {
    return this.httpService.delete(`${config.baseRequest}properties/images/plans`, ids);
  }

  public getPropertyWithFilter({ searchObjects, sortObject, pagingObject, expandParam, projectKey }: any) {
    let orderBy: any = null;
    let filter: any = { or: [], and: [] };
    let top: any = null;
    let skip: any = null;
    let expand: any = expandParam;

    //Search part
    if (searchObjects) {
      Object.entries(searchObjects).map(([key, value]: [any, any]) => {
        filter.and.push({
          [key]: {
            eq:
              value === null
                ? null
                : encodeURI(
                    value
                      .replace(/[/\\!@#$%^&*)(+=.,_-]/g, '')
                      .replace(/`/g, `'`)
                      .toLowerCase(),
                  ),
          },
        });
      });
    }

    //Sort part
    if (sortObject) {
      const keys = Object.keys(sortObject);
      orderBy = [];
      keys.map((key) => {
        const sortOrder = sortObject[key];
        orderBy.push(`${key} ${sortOrder}`);
      });
    }

    // Paging part
    if (pagingObject) {
      top = pagingObject.sizePerPage;
      skip = (pagingObject.page - 1) * pagingObject.sizePerPage;
    }

    const query = buildQuery({ filter, orderBy, top, skip, expand });

    const options = getApiKeyOptions(projectKey);
    return this.httpService.get(`${config.baseRequest}properties${query}`, options);
  }

  public async getLightTreeByProperty({ id, projectKey }: any) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.get(`${config.baseRequest}properties/${id}/childrens/depth`, options);
  }

  public async getLightTreeByProject({ id, projectKey }: any) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.get(`${config.baseRequest}projects/${id}/properties`, options);
  }

  public async deleteProperty(id: number, projectKey: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.delete(`${config.baseRequest}properties/${id}`, {}, options);
  }

  public async importProperties(projectKey: string, formData: any) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.postFile(`${config.baseRequest}properties/import`, formData, options);
  }

  public async exportProperties(projectKey: string) {
    const options = getApiKeyOptions(projectKey);
    return this.httpService.get(`${config.baseRequest}properties/import/example?isReal=true`, options);
  }
}

export default ListPageServices;
