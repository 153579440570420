import { Modal, notification, Table, TablePaginationConfig } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterTypes } from '../../../constants/filterTypes';
import { generateFilterfromObj } from '../../../helpers/generateFIlterfromObj';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import css from '../../../assets/styles/budget.module.css';
import { useLessThen801 } from '../../../helpers/mediaDetect';
import TableMobile, { IData } from '../../common/TableMobile';
import { CheckOutlined } from '@ant-design/icons';
import { IQuoteModel } from '../../../models/QuotesModel';
import { BudgetModel } from '../../../models/BudgetModel';
import { DocumentsModel } from '../../../models/DocumentsModel';
import { IAmendmentModel } from '../../../models/AmendmentModel';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { getAmmendmentsColumns, getBudgetColumns, getDocumentsColumns, getQuotesColumns } from './ArchiveColumns';
import { authDownloadFile } from '../../../helpers/authFileTools';
import config from '../../../config';
import { getStorageToken } from '../../../helpers/storageTools';
import HttpService from '../../../services/common/httpService';
import { setUnarchiveNotificationMessage } from '../utils/getMessages';
import useGetPageTypeConfig from '../hooks/useGetPageTypeConfig';
import useRequests from '../hooks/useRequest';

interface ArchiveProps {
  isOpen: boolean;
  setIsOpen: (b: boolean) => void;
  getItems: () => void;
}
const httpService = new HttpService();

const ArchivesFileManagement = (props: ArchiveProps) => {
  const { isOpen, setIsOpen, getItems } = props;
  const [items, setItems] = useState<(IQuoteModel | BudgetModel | DocumentsModel | IAmendmentModel)[]>([]);
  const { t } = useTranslation();
  const { height: windowHeight } = useWindowDimensions();
  const [fetchingData, setFetchingData] = useState(false);
  const pageTypeConfig = useGetPageTypeConfig();
  const { setUpdateRequestByPageType, setArchivesRequestByPageType } = useRequests();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    skip: 0,
    showSizeChanger: true,
    simple: true,
  });

  const option = {
    count: true,
    top: pagination.pageSize,
    current: pagination.current,
    filters: generateFilterfromObj(
      pageTypeConfig.isQuotes
        ? {
            isArchive: {
              type: FilterTypes.SELECT,
              value: true,
            },
            isSuppliers: {
              type: FilterTypes.SELECT,
              value: 'false',
            },
          }
        : {
            isArchive: {
              type: FilterTypes.SELECT,
              value: true,
            },
          },
    ),
  };

  useEffect(() => {
    getArchives(option);
  }, []);

  const getArchives = (option: { [name: string]: any }) => {
    setFetchingData(true);
    setArchivesRequestByPageType(option)
      .then((res) => {
        const { count, items } = res;
        const { current, top } = option;
        if (items.length === 0) {
          notification.info({
            message: t('common.no.archive.items'),
          });
          setIsOpen(false);
        }
        setPagination({ ...pagination, current, total: count, pageSize: top, simple: true });
        setItems(items);
      })
      .catch((e) => {
        notification.error({
          message: e.message,
        });
        setIsOpen(false);
      })
      .finally(() => setFetchingData(false));
  };

  const loadMore = (option: { [name: string]: any }) => {
    setFetchingData(true);
    option.skip = option.skip ? option.skip + 10 : 10;
    setArchivesRequestByPageType(option)
      .then((res) => {
        const { count } = res;
        const { current, top } = option;
        setPagination({ ...pagination, current, total: count, pageSize: top });
        setItems([...items, ...res.items]);
      })
      .catch((e) => console.log(e))
      .finally(() => setFetchingData(false));
  };

  const onTableChange: (
    pagination?: TablePaginationConfig,
    filters?: Record<string, FilterValue | null | any>,
    sorter?:
      | SorterResult<IQuoteModel | BudgetModel | DocumentsModel | IAmendmentModel>
      | SorterResult<IQuoteModel | BudgetModel | DocumentsModel | IAmendmentModel>[],
  ) => void = (pagination) => {
    let params: { [name: string]: any } = {
      ...option,
    };
    if (pagination && pagination.pageSize && pagination.current) {
      params = {
        ...params,
        top: pagination.pageSize,
        skip: pagination.pageSize * (pagination.current - 1),
        current: pagination.current,
      };
    }

    getArchives(params);
  };

  const unArchive = (id: number) => {
    updateReqByType(items.find((el) => el.id === id));
  };

  const updateReqByType = (item: any) => {
    const body = {
      id: item.id,
      isArchive: false,
    };
    setUpdateRequestByPageType(body)
      .then(() =>
        notification.success({
          message: setUnarchiveNotificationMessage(pageTypeConfig),
        }),
      )
      .catch((e: any) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => {
        getArchives(option);
        getItems();
      });
  };

  //TODO: check new signatories
  const getSignatories = (item: any, type: string) =>
    item.signatories
      .filter((sign: any) => {
        if (type === 'responsibleAgent') {
          return sign.isCreator;
        }
        if (type === 'controlAgents') {
          return sign.isAgent && !sign.isCreator;
        }
        return !sign.isAgent;
      })
      .map((el: any) => {
        return (
          <div
            key={
              type === 'responsibleAgent'
                ? el.id
                : type === 'controlAgents'
                ? `${el.consumerId} + ${item.id}`
                : el.consumerId
            }
            style={{
              display: 'flex',
              marginLeft:
                type === 'controlAgents' || type === 'responsibleAgent'
                  ? el.signed || el.isApproved || item.statusV2.code === 'rejected'
                    ? '0'
                    : '40px'
                  : el.signed || item.statusV2.code === 'rejected'
                  ? '0'
                  : '40px',
            }}>
            {(type === 'controlAgents' || type === 'responsibleAgent') &&
              el.isApproved &&
              !el.signed &&
              item.statusV2.code !== 'rejected' && (
                <CheckOutlined style={{ color: '#0067ff', marginRight: '10px', width: '30px' }} />
              )}
            {(type === 'controlAgents' || type === 'responsibleAgent'
              ? el.signed && el.isApproved && item.statusV2.code !== 'rejected'
              : el.signed) && (
              <div style={{ display: 'flex', width: '30px', marginRight: '10px' }}>
                <CheckOutlined style={{ color: '#39bb50', width: '15px', height: '15px' }} />
                <CheckOutlined
                  style={{
                    color: '#39bb50',
                    width: '15px',
                    height: '15px',
                    position: 'relative',
                    left: '-10px',
                  }}
                />
              </div>
            )}
            <span style={{ marginLeft: item.statusV2.code === 'rejected' ? '40px' : '0' }}>{`${
              type === 'responsibleAgent' ? item.createdUser.firstName : el?.consumer?.firstName || ''
            } ${type === 'responsibleAgent' ? item.createdUser.lastName : el?.consumer?.lastName || ''}`}</span>
          </div>
        );
      });

  const amendmentDownloadFile = (file: string, id: number, fileName?: string) => {
    httpService
      .getFile(`${config.baseRequest}amendments/${id}/fileFull`, {
        header: { Authorization: 'Bearer ' + getStorageToken() },
      })
      .then((res) => {
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(res);
        a.href = url;
        a.download = fileName || file;
        a.click();
      });
  };

  const getDataForType = (): IData[][] => {
    if (pageTypeConfig.isDocuments) {
      return (
        items.length > 0 &&
        ((items as DocumentsModel[]).map((item) => [
          {
            title: t('documents.table.column.name'),
            description: item.title,
            warning: {
              title: (
                <span style={{ color: '#EB3B3B', fontSize: '18px' }}>{t('common.popover.attention.file.title')}</span>
              ),
              content: (
                <div style={{ width: '200px' }}>
                  <p>{t('common.popover.attention.file.content')}</p>
                </div>
              ),
            },
            type: !item.file ? 'warning' : 'string',
          },
          { title: t('documents.table.column.description'), description: item.description, type: 'string' },
          {
            title: t('dashboard.acquirers.title'),
            description: <div className="common-flex-column-style">{getSignatories(item, 'acquirers')}</div>,
            type: '',
          },
          {
            title: t('consumers.table.group.responsible.agent'),
            description: <div>{getSignatories(item, 'responsibleAgent')}</div>,
            type: '',
          },
          {
            title: t('fileManagement.table.control.agent'),
            description: <div className="common-flex-column-style">{getSignatories(item, 'controlAgents')}</div>,
            type: '',
          },
          { title: t('documents.table.status'), description: item.statusV2, type: 'status' },
          { title: t('budget.table.sum.vat'), description: item.sumWithVat, type: 'price' },
          { title: t('budget.table.created.date'), description: item.createdDate, type: 'date' },
          { title: t('project.details.table.project'), description: item.property.project.title, type: 'string' },
          { title: t('requests.view.card.info.property'), description: item.property.title, type: 'string' },
          {
            title: t('budget.table.action'),
            description: item.file,
            type: 'button',
            openByFunction: true,
            btnsInfo: [
              {
                function: () => unArchive(item.id),
                functionBtnTitle: t('common.title.unarchive'),
              },
              {
                function: () => authDownloadFile(item.file, item.fileName),
                functionBtnTitle: t('budget.table.download'),
              },
            ],
          },
        ]) as any)
      );
    }
    if (pageTypeConfig.isInvoices) {
      return (
        items.length > 0 &&
        ((items as BudgetModel[]).map((item) => [
          {
            title: '№',
            description: item.number,
            warning: {
              title: (
                <span style={{ color: '#EB3B3B', fontSize: '18px' }}>{t('common.popover.attention.file.title')}</span>
              ),
              content: (
                <div style={{ width: '200px' }}>
                  <p>{t('common.popover.attention.file.content')}</p>
                </div>
              ),
            },
            type: !item.file ? 'warning' : 'string',
          },
          { title: t('documents.table.column.description'), description: item.description, type: 'string' },
          {
            title: t('dashboard.acquirers.title'),
            description: <div className="common-flex-column-style">{getSignatories(item, 'acquirers')}</div>,
            type: '',
          },
          {
            title: t('consumers.table.group.responsible.agent'),
            description: <div>{getSignatories(item, 'responsibleAgent')}</div>,
            type: '',
          },
          {
            title: t('fileManagement.table.control.agent'),
            description: <div className="common-flex-column-style">{getSignatories(item, 'controlAgents')}</div>,
            type: '',
          },
          { title: t('budget.table.status'), description: item.statusV2, type: 'status' },
          { title: t('budget.table.sum.vat'), description: item.sumWithVat, type: 'price' },
          { title: t('budget.table.created.date'), description: item.createdDate, type: 'date' },
          { title: t('budget.table.date'), description: item.paymentDate, type: 'date' },
          { title: t('project.details.table.project'), description: item.property.project.title, type: 'string' },
          { title: t('requests.view.card.info.property'), description: item.property.title, type: 'string' },
          {
            title: t('budget.table.action'),
            description: item.file,
            type: 'button',
            openByFunction: true,
            btnsInfo: [
              {
                function: () => unArchive(item.id),
                functionBtnTitle: t('common.title.unarchive'),
              },
              {
                function: () => authDownloadFile(item.file, item.fileName),
                functionBtnTitle: t('budget.table.download'),
              },
            ],
          },
        ]) as any)
      );
    }
    return (
      items.length > 0 &&
      ((items as (IQuoteModel | IAmendmentModel)[]).map((item) => [
        {
          title: '№',
          description: item.number,
          warning: {
            title: (
              <span style={{ color: '#EB3B3B', fontSize: '18px' }}>{t('common.popover.attention.file.title')}</span>
            ),
            content: (
              <div style={{ width: '200px' }}>
                <p>{t('common.popover.attention.file.content')}</p>
              </div>
            ),
          },
          type: !item.file ? 'warning' : 'string',
        },
        { title: t('documents.table.column.description'), description: item.description, type: 'string' },
        {
          title: t('fileManagement.table.deadline'),
          description: !!item.deadline ? item.deadline : '',
          type: 'date',
        },
        {
          title: t('dashboard.acquirers.title'),
          description: <div className="common-flex-column-style">{getSignatories(item, 'acquirers')}</div>,
          type: '',
        },
        {
          title: t('consumers.table.group.responsible.agent'),
          description: <div>{getSignatories(item, 'responsibleAgent')}</div>,
          type: '',
        },
        {
          title: t('fileManagement.table.control.agent'),
          description: <div className="common-flex-column-style">{getSignatories(item, 'controlAgents')}</div>,
          type: '',
        },
        { title: t('documents.table.status'), description: item.statusV2, type: 'status' },
        { title: t('budget.table.sum.vat'), description: item.sumWithVat, type: 'price' },
        { title: t('budget.table.created.date'), description: item.createdDate, type: 'date' },
        {
          title: t('budget.form.date.of.signature'),
          description: item.lastSignDateTime ? item.lastSignDateTime : '',
          type: 'date',
        },
        { title: t('project.details.table.project'), description: item.property.project.title, type: 'string' },
        { title: t('requests.view.card.info.property'), description: item.property.title, type: 'string' },
        {
          title: t('budget.table.action'),
          description: item.file,
          type: 'button',
          openByFunction: true,
          btnsInfo: [
            {
              function: () => unArchive(item.id),
              functionBtnTitle: t('common.title.unarchive'),
            },
            {
              function: () =>
                pageTypeConfig.isQuotes
                  ? authDownloadFile(item.file, item.fileName)
                  : amendmentDownloadFile(item.file, item.id, item.fileName),
              functionBtnTitle: t('budget.table.download'),
            },
          ],
        },
      ]) as any)
    );
  };

  if (useLessThen801()) {
    return (
      <Modal
        visible={isOpen}
        className="archive-modal"
        centered
        footer={null}
        closable={false}
        style={{ paddingBottom: 0 }}>
        <div onClick={() => setIsOpen(false)} className={css['close-archive-btn']}>
          <span className={css['close-archive-text']}>X</span>
        </div>
        <TableMobile
          data={getDataForType()}
          isLoading={fetchingData}
          loadMore={loadMore}
          option={option}
          itemsLength={items.length}
          totalItems={pagination.total}
        />
      </Modal>
    );
  }

  return (
    <Modal
      visible={isOpen}
      className="archive-modal"
      centered
      footer={null}
      closable={false}
      style={{ paddingBottom: 0 }}>
      <div onClick={() => setIsOpen(false)} className={css['close-archive-btn']}>
        <span className={css['close-archive-text']}>X</span>
      </div>
      <Table
        columns={
          pageTypeConfig.isQuotes
            ? getQuotesColumns({ t, unArchive })
            : pageTypeConfig.isInvoices
            ? getBudgetColumns({ t, unArchive })
            : pageTypeConfig.isDocuments
            ? getDocumentsColumns({ t, unArchive })
            : getAmmendmentsColumns({ t, unArchive })
        }
        size="small"
        className="list-info-table"
        rowClassName="common-table-row--pointer"
        dataSource={items}
        showHeader={true}
        pagination={pagination}
        scroll={{ y: windowHeight - 280 }}
        onChange={onTableChange}
        rowKey="id"
      />
    </Modal>
  );
};

export default ArchivesFileManagement;
