import { Menu, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../../assets/styles/faq.module.css';
import { QuestionsModel, TopicsModel } from '../../../../models/FAQModel';
import FAQService from '../../../../services/FAQService';
import { useLocation, useHistory } from 'react-router';
import { getValueInHash } from '../../../../helpers/getValueInHash';
import i18n from '../../../../utils/i18n';

const service = new FAQService();

interface ITopicFAQ {
  setSelectTopicId: (n: number | null) => void;
  setResultFAQ: (V: null | QuestionsModel) => void;
  selectTopicId: number | null;
  resultFAQ: QuestionsModel | null;
  isEmptySearch: boolean;
}

const TopicFAQ = (props: ITopicFAQ) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { hash, pathname } = useLocation();
  const { setSelectTopicId, setResultFAQ, resultFAQ, selectTopicId, isEmptySearch } = props;
  const [fetchingData, setFetchingData] = useState(false);
  const [topics, setTopics] = useState<null | TopicsModel>(null);

  const getTopic = () => {
    setFetchingData(true);
    service
      .getTopics({
        filter: {
          'type/code': {
            eq: null,
          },
        },
      })
      .then((value: TopicsModel) => {
        setTopics(value);
      })
      .finally(() => setFetchingData(false));
  };

  useEffect(() => {
    getTopic();
  }, []);

  useEffect(() => {
    if (isEmptySearch && topics) {
      setResultFAQ(null);
      setSelectTopicId(topics.length > 0 ? topics[0].id : null);
    }
  }, [isEmptySearch, topics]);

  const onSelect = (key: string) => {
    setResultFAQ(null);
    setSelectTopicId(+key);
    if (hash) {
      history.push(pathname);
    }
  };

  useEffect(() => {
    if (hash && topics) {
      const variables = hash.replace('#', '').split('&');
      const topicId = getValueInHash(variables[0]);
      if (topicId) {
        setSelectTopicId(topics.find((item) => item.id === +topicId)?.id || selectTopicId);
      }
    }
  }, [hash, topics]);

  return (
    <div className={`faq-spin ${css['faq-topic-container']}`}>
      <Spin spinning={fetchingData}>
        {topics && (
          <Menu
            className="faq-topic-list"
            onSelect={({ key }) => onSelect(key)}
            selectedKeys={resultFAQ ? [] : selectTopicId ? [selectTopicId.toString()] : []}
            defaultSelectedKeys={topics?.length > 0 ? [topics[0].id.toString()] : []}>
            {topics.map((el) => (
              <Menu.Item key={el.id}>{i18n.language === 'en-US' ? el.topicEn : el.topicFr}</Menu.Item>
            ))}
          </Menu>
        )}
        <div className={css['faq-topic-support']}>
          {t('faq.topic.support')}{' '}
          <a className={css['faq-support-email']} href="mailto:support@cmp.com">
            {t('faq.support.email')}
          </a>
        </div>
      </Spin>
    </div>
  );
};

export default TopicFAQ;
