import HttpService from './common/httpService';
import config from '../config';
import { IAiDedailsBody, IAiFaqQuestion } from '../models/FAQModel';

class AIService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public generateFAQ(body: { prompt: string }) {
    return this.httpService.post(`${config.baseRequest}faq/ai/basic`, body);
  }

  public generateTopics(body: Omit<IAiFaqQuestion, 'id'>) {
    return this.httpService.post(`${config.baseRequest}faq/ai/topics`, body);
  }

  public getTokens(body: { text: string }) {
    return this.httpService.post(`${config.baseRequest}gpt/tokens?returnTokens=false`, body);
  }

  public getDraft() {
    return this.httpService.get(`${config.baseRequest}faq/ai/draft`);
  }

  public saveDraft(body: any) {
    return this.httpService.post(`${config.baseRequest}faq/ai/draft`, body);
  }

  public detailsQuestion(body: IAiDedailsBody) {
    return this.httpService.post(`${config.baseRequest}faq/ai/details`, body);
  }
}

export default AIService;
