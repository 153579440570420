import { Button, Dropdown, Menu, Upload } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import css from '../../../../assets/styles/calendar.module.css';
import config from '../../../../config';
import editIcon from '../../../../assets/images/ic-edit-blue.svg';
import deleteIcon from '../../../../assets/images/ic-delete-blue.svg';
import moreIcon from '../../../../assets/images/ic-more.svg';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../utils/i18n';
import ModalUploadFileSuccess from '../../../../components/common/Modals/ModalUploadFileSuccess';
import PostModalForm from '../../../Posts/components/PostModalForm';
import { CalendarTypes } from '../../../../models/CalendarModel';
import PostConfirmModal from '../../../Posts/components/PostConfirmModal';
import { authDownloadFile } from '../../../../helpers/authFileTools';
import parser from 'html-react-parser';

interface UpdatesProps {
  updates: any[];
  resetMobileCalendarDate: () => void;
}
const UpdatesBrokerMobileComponent = (props: UpdatesProps) => {
  const { updates, resetMobileCalendarDate } = props;
  const { t } = useTranslation();
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenUploadMessageModal, setIsOpenUploadMessageModal] = useState(false);

  const setOpenEdit = (id: number | null) => {
    setIsOpenEdit(true);
    if (id) {
      setCurrentId(id);
    }
  };

  const setOpenDelete = (id: number | null) => {
    setIsOpenDelete(true);
    if (id) {
      setCurrentId(id);
    }
  };

  const menu = (
    <Menu className="update-menu-container">
      <Menu.Item key="edit">
        <div onClick={() => setOpenEdit(currentId)} className={css['update-menu-item']}>
          <img src={editIcon} alt="" style={{ marginRight: '10px', height: '15px', width: '15px' }} />
          <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.btn.modify')}</span>
        </div>
      </Menu.Item>
      <Menu.Item key="delete">
        <div onClick={() => setOpenDelete(currentId)} className={css['update-menu-item']}>
          <img src={deleteIcon} alt="" style={{ marginRight: '10px', height: '15px', width: '15px' }} />
          <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.title.delete')}</span>
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={css['date-news-view']}>
      {updates
        .sort((a, b) => b.id - a.id)
        .map((update) => (
          <div id={`id-${update.id}`} key={update.id}>
            <div className={css['date-news-desc']}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: '0.5rem',
                }}>
                <li className={css['update-date']} style={{ marginBottom: '0.5rem' }}>
                  {moment(update.date || update.datetime)
                    .locale(i18n.language)
                    .format('DD MMMM')}
                </li>
                {!update.isSystem && (
                  <Dropdown overlay={menu} trigger={['click']}>
                    <Button className={css['update-dropdown-btn']} onClick={() => setCurrentId(update.id)}>
                      <img src={moreIcon} alt="" height={15} width={15} />
                    </Button>
                  </Dropdown>
                )}
              </div>
              <span className="parser-container">{parser(update.description || update.name || '')}</span>
              {update.files && (
                <Upload
                  listType={
                    update.type.code === CalendarTypes.document ||
                    update.files.map((file: any) => file.file.slice(-3)).includes('pdf')
                      ? 'text'
                      : 'picture-card'
                  }
                  openFileDialogOnClick={false}
                  className={
                    update.type.code === CalendarTypes.document ||
                    update.files.map((file: any) => file.file.slice(-3)).includes('pdf')
                      ? 'upload-updates'
                      : 'upload-updates-view'
                  }
                  defaultFileList={
                    update.files.map((file: any) => ({
                      name: file.fileName || '',
                      uid: file.id,
                      url: `${config.storeUrl}/${file.file}`,
                      file: file.file,
                    })) as any
                  }
                  directory
                  showUploadList={{ showDownloadIcon: true, showRemoveIcon: false }}
                  beforeUpload={() => false}
                  onPreview={(file: any) => {
                    authDownloadFile(file.file, file.name);
                  }}
                />
              )}
            </div>
          </div>
        ))}
      {isOpenDelete && (
        <PostConfirmModal
          currentId={currentId}
          isOpen={isOpenDelete}
          setClose={() => {
            setIsOpenDelete(false);
            resetMobileCalendarDate;
          }}
        />
      )}
      {isOpenEdit && (
        <PostModalForm
          setIsOpenUploadMessageModal={setIsOpenUploadMessageModal}
          isOpenModal={isOpenEdit}
          defaultType={CalendarTypes.update}
          postId={currentId}
          getCalendarUpdates={resetMobileCalendarDate}
          closeModal={() => {
            setIsOpenEdit(false);
            setCurrentId(null);
          }}
        />
      )}
      {isOpenUploadMessageModal && (
        <ModalUploadFileSuccess isOpen={isOpenUploadMessageModal} onOk={() => setIsOpenUploadMessageModal(false)} />
      )}
    </div>
  );
};

export default UpdatesBrokerMobileComponent;
