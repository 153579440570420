exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".roles_roles-add-new__31BVm,\n.roles_roles-add-new__31BVm:hover,\n.roles_roles-add-new__31BVm:active,\n.roles_roles-add-new__31BVm:focus {\n  border-radius: 4px;\n  box-shadow: 0 8px 16px 0 rgba(0, 18, 45, 0.12);\n  background-color: #076ee5;\n  color: #fff;\n  height: 42px;\n  font-weight: 600;\n  width: 100px;\n}\n.roles_roles-dropdown-btn__1JCy2,\n.roles_roles-dropdown-btn__1JCy2:active,\n.roles_roles-dropdown-btn__1JCy2:focus {\n  background-color: #eef0f4;\n  border-radius: 50%;\n  height: auto;\n  padding: 0 4px 1px;\n  width: auto;\n  border: none;\n}\n.roles_roles-dropdown-btn__1JCy2:hover {\n  background-color: #e4e6eb;\n}\n.roles_update-menu-item__2mw-x {\n  display: flex;\n  align-items: center;\n  /* TODO: temporary removed. probably that can broke the mobile view */\n  /* background-color: #eef0f4;\n  padding-left: 0.5rem;\n  border-radius: 6px; */\n}\n.roles_update-menu-item__2mw-x:hover {\n  background-color: #eef0f4;\n}\n.roles_roles-table-container__1-MpZ {\n  border-radius: 6px;\n  border: solid 1px #c4c6ce;\n  padding: 1rem;\n  background-color: #f5f7fa;\n}\n.roles_arrow-drop__cRzZ8 {\n  transform: rotate(180deg);\n}\n", ""]);

// Exports
exports.locals = {
	"roles-add-new": "roles_roles-add-new__31BVm",
	"roles-dropdown-btn": "roles_roles-dropdown-btn__1JCy2",
	"update-menu-item": "roles_update-menu-item__2mw-x",
	"roles-table-container": "roles_roles-table-container__1-MpZ",
	"arrow-drop": "roles_arrow-drop__cRzZ8"
};