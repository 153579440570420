import { Button, Modal, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCloseTabWarning from '../../hooks/useCloseTabWarning';

interface Props {
  isOpen: boolean;
  isUpload?: boolean;
  setClose: (b?: boolean) => void;
  isLoading: boolean;
}

const UploadFileLoader = (props: Props) => {
  const { isOpen, setClose, isLoading, isUpload = true } = props;
  const { t } = useTranslation();
  const { routerPrompt: Prompt, setIsDirty } = useCloseTabWarning(t('common.modal.onbeforeunload.message'));
  useEffect(() => {
    setIsDirty(isLoading);
  }, [isLoading]);

  const [counter, setCounter] = useState(20);

  useEffect(() => {
    counter > 0 ? setTimeout(() => setCounter(counter - 1), 1000) : setClose(isUpload);
  }, [counter]);

  return (
    <Modal
      visible={isOpen}
      closable={false}
      footer={
        isLoading ? null : (
          <div className="common-modal-footer">
            <Button className={`common-green-btn common-modal-btn`} onClick={() => setClose(isUpload)}>
              {t('common.modal.confirm.ok')}
            </Button>
          </div>
        )
      }
      className="common-modal"
      centered
      style={{ width: '10rem' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <span style={{ textAlign: 'center' }}>
          {isUpload ? t('common.upload.file.loader') : t('common.download.file.loader')}
        </span>
        <br />
        <span>{isUpload ? t('common.upload.file.message') : t('common.download.file.message')}</span>
        <Spin spinning={isLoading} size="large" style={{ marginTop: '1rem' }} />
      </div>
      {Prompt}
    </Modal>
  );
};

export default UploadFileLoader;
