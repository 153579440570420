import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Spin, Row, Col, Card, Descriptions, Typography, Select, Badge, Tabs, Button, Modal, notification } from 'antd';
import ConsumerRequestsService from '../../services/ConsumerRequestsService';
import { AppContext } from '../../contexts/AppContextProvider';
import { formatDate } from '../../helpers/dateTools';
import { statusColor } from './components/ConsumerRequestsColumns';
import NestedRequestTable from './components/NestedRequestTable';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import { IAppContext } from '../../typings/IApp';

const service: ConsumerRequestsService = new ConsumerRequestsService();

const ConsumerRequestsView: React.FC<any> = (props: any) => {
  const { t } = useTranslation();
  const { height: windowHeight } = useWindowDimensions();
  const { id } = useParams<any>();
  const history = useHistory();
  const {
    app: { currentProject },
  } = useContext<IAppContext>(AppContext);
  const [modal, contextHolder] = Modal.useModal();

  const [item, setItem] = useState<any>();
  const [statuses, setStatuses] = useState<any>([]);
  const [breadcrumbNameMap, setBreadcrumbNameMap] = useState<{ [name: string]: string }>({});

  const [loading, setLoading] = useState<boolean>(false);

  const getRequest = () => {
    return service.getRequestById(id, currentProject?.key).then((res: any) => {
      setItem(res);
    });
  };

  const getStatuses = () => {
    service.getRequestStatuses().then((res: any) => {
      setStatuses(res);
    });
  };

  const getAsyncData = async () => {
    setLoading(true);
    const actions = [getRequest(), getStatuses()];
    Promise.all(actions).finally(() => {
      setLoading(false);
    });
  };

  const setStatus = (key: string) => {
    service.setConsumerRequestStatus({ id, status: { name: key } }, currentProject.key).then(() => {
      getRequest();
      notification.success({
        message: t('requests.status.updated'),
      });
    });
  };

  useEffect(() => {
    getAsyncData();
  }, []);

  useEffect(() => {
    setBreadcrumbNameMap(
      history.location.pathname
        .replace('/requests', '')
        .replace(/\/view\/\d+/, `/${t('common.title.view')}`)
        .split('/')
        .slice(1)
        .reduce(
          (res: any, curr: any, index: number) => ({
            ...res,
            [[Object.keys(res)[index], curr].join('/')]: curr.split(':').shift(),
          }),
          { '/requests': t('requests.bread.root') },
        ),
    );
  }, [history.location.pathname]);

  const onSelectStatus = (key: string) => {
    modal.confirm({
      title: t('common.modal.confirm'),
      content: <div>{t('requests.status.confirm.text')}</div>,
      okText: t('common.modal.confirm.ok'),
      cancelText: t('common.modal.confirm.cancel'),
      onOk: () => setStatus(key),
      onCancel: () => getAsyncData(),
      centered: true,
    });
  };

  return (
    <Spin spinning={loading}>
      {contextHolder}
      <HeaderTable title={t('common.title.view')} breadcrumb={breadcrumbNameMap}>
        <Button type="primary" style={{ width: 100 }} className="common-primary-btn">
          {t('requests.btn.pdf')}
        </Button>
      </HeaderTable>
      <Row gutter={[24, 4]}>
        <Col span={8}>
          <Card
            title={`# ${item?.id || ''}`}
            extra={<div>{formatDate(item?.createdDate)}</div>}
            loading={loading}
            bodyStyle={{ height: windowHeight - 390 }}>
            <Descriptions layout="vertical" column={2}>
              <Descriptions.Item contentStyle={{ display: 'block' }} span={2}>
                <Typography.Title level={4}>{`${item?.consumer?.name} ${item?.consumer?.surname}`}</Typography.Title>
                <Typography.Link href={`mailto:${item?.consumer?.email}`} target="_blank" style={{ paddingTop: '2px' }}>
                  {item?.consumer?.email}
                </Typography.Link>
                <p>
                  <Typography.Text type="secondary">{item?.consumer?.phone}</Typography.Text>
                </p>
              </Descriptions.Item>
              <Descriptions.Item label={t('requests.view.card.info.status')} span={2}>
                <Select
                  className="common-select common-animation-primary"
                  style={{ width: '100%' }}
                  defaultValue={item?.status?.name}
                  options={statuses.map((item: any) => ({
                    label: <Badge status={statusColor[item?.name]} text={item?.description} />,
                    value: item.name,
                  }))}
                  onChange={onSelectStatus}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t('requests.view.card.info.property')}>
                <Typography.Title level={5}>{item?.property?.title}</Typography.Title>
              </Descriptions.Item>
              <Descriptions.Item label={t('requests.view.card.info.property.type')}>
                <Typography.Text type="secondary">{item?.property?.type?.description}</Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item label={t('requests.view.card.info.typology')} span={2}>
                <Typography.Title level={5}>{item?.property?.typology?.title}</Typography.Title>
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col span={16}>
          <Tabs defaultActiveKey="options" type="card" size="middle">
            <Tabs.TabPane tab={t('requests.view.card.options.title')} key="options">
              <NestedRequestTable record={item} />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    </Spin>
  );
};

export default ConsumerRequestsView;
