import { Button, Form, Modal, Spin } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImportConsumersFirstStage from './ImportConsumersFirstStage';
import ImportConsumersSecondStage from './ImportConsumersSecondStage';
import { IUserExport } from '../../../../models/ConsumerModel';

interface IProps {
  isOpenImportForm: boolean;
  setIsOpenImportForm: (b: boolean) => void;
  getUsers: () => void;
  setIsOpenUploadMessageModal: (b: boolean) => void;
}

const ImportConsumersModal = ({
  isOpenImportForm,
  setIsOpenImportForm,
  getUsers,
  setIsOpenUploadMessageModal,
}: IProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [stage, setStage] = useState(1);
  const [projectId, setProjectId] = useState<number>(14);
  const [fetchingData, setFetchingData] = useState(false);
  const [users, setUsers] = useState<IUserExport[]>([]);

  const handleClose = () => {
    setIsOpenUploadMessageModal(true);
    setIsOpenImportForm(false);
    getUsers();
  };

  return (
    <Spin spinning={fetchingData}>
      <Modal
        className="add-import-consumers-modal"
        centered
        footer={
          stage === 1 ? (
            <div className="common-modal-footer">
              <Button
                className={`common-gray-border-btn common-secondary-btn`}
                onClick={() => setIsOpenImportForm(false)}>
                {t('consumers.modal.cancel.btn')}
              </Button>
              <Button className={`common-green-btn common-modal-btn`} onClick={() => form.submit()}>
                {t('consumers.modal.import.btn')}
              </Button>
            </div>
          ) : null
        }
        closable={false}
        visible={isOpenImportForm}>
        {stage === 1 && (
          <ImportConsumersFirstStage
            form={form}
            setStage={setStage}
            setUsers={setUsers}
            setProjectId={setProjectId}
            setFetchingData={setFetchingData}
          />
        )}
        {stage === 2 && projectId && (
          <ImportConsumersSecondStage
            projectId={projectId}
            users={users}
            setStage={setStage}
            handleClose={handleClose}
            setFetchingData={setFetchingData}
          />
        )}
      </Modal>
    </Spin>
  );
};

export default ImportConsumersModal;
