import React from 'react';
import css from '../../assets/styles/gallery.module.css';
import GalleryListInfo from './components/GalleryListInfo';

interface BrokerGalleryProps {
  propertyId: number;
  projectId: number;
}

const BrokerGallery = (props: BrokerGalleryProps) => {
  const { propertyId, projectId } = props;

  return (
    <div className={`gallery-common-container ${css['container']}`}>
      <GalleryListInfo propertyId={+propertyId} projectId={+projectId} />
    </div>
  );
};

export default BrokerGallery;
