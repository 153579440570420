import React, { createContext, useReducer } from 'react';
import { initialState, reducer } from '../reducers/headerReducer';

export const HeaderContext = createContext({} as any);

const HeaderContextProvider: React.FC = (props: any) => {
  const [header, headerDispatch] = useReducer(reducer, initialState);

  return <HeaderContext.Provider value={{ header, headerDispatch }}>{props.children}</HeaderContext.Provider>;
};

export default HeaderContextProvider;
