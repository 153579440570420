exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".reports_reports-control-container__2xucp {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 1rem;\n}\n.reports_reports-control-filters-container__1GcQp {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n}\n.reports_reports-filter-row__2kZ-_ {\n  display: flex;\n}\n.reports_reports-container__38ySQ {\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: wrap;\n}\n\n@media screen and (max-width: 400px) {\n  .reports_reports-container__38ySQ .reports_reports-control-container__2xucp {\n    width: 100%;\n    justify-content: flex-end;\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"reports-control-container": "reports_reports-control-container__2xucp",
	"reports-control-filters-container": "reports_reports-control-filters-container__1GcQp",
	"reports-filter-row": "reports_reports-filter-row__2kZ-_",
	"reports-container": "reports_reports-container__38ySQ"
};