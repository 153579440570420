export const DashboardStatusColors = [
  {
    code: 'client_approve',
    color: '#C38768',
  },
  {
    code: 'client_sign',
    color: '#FFBC75',
  },
  {
    code: 'agent_approve',
    color: '#6796F5',
  },
  {
    code: 'agent_sign',
    color: '#58b7b7',
  },
  {
    code: 'client_approve_partially',
    color: '#B9C951',
  },
  {
    code: 'client_sign_partially',
    color: '#51C9C4',
  },
  {
    code: 'agent_approve_partially',
    color: '#B583C2',
  },
  {
    code: 'agent_sign_partially',
    color: '#C9A151',
  },
  {
    code: 'signed',
    color: '#A3F4A1',
  },
  {
    code: 'rejected',
    color: '#F85E51',
  },
  {
    code: 'new',
    color: '#DC77C0',
  },
];
