import { Button, Modal, notification, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../../assets/styles/faq.module.css';
import { IQuoteModel, IUpdateQuoteModel } from '../../../../models/QuotesModel';
import QuotesService from '../../../../services/QuoteService';

interface Props {
  id: number | null;
  quoteNumber: string | null;
  setClose: () => void;
  isOpen: boolean;
}

const service = new QuotesService();

const ArchiveConfirmForm = (props: Props) => {
  const { id, quoteNumber, setClose, isOpen } = props;
  const { t } = useTranslation();
  const [fetchingData, setFetchingData] = useState(false);
  const [quote, setQuote] = useState<IQuoteModel | null>(null);

  useEffect(() => {
    if (id) {
      service
        .getQuoteInfoById(id)
        .then((value) => setQuote(value))
        .catch((e) => {
          notification.error({
            message: e.message,
          });
          setClose();
        });
    }
  }, []);

  const updateInvoice = () => {
    if (quote) {
      setFetchingData(true);
      const body: IUpdateQuoteModel = {
        id: quote.id,
        isArchive: true,
      };
      service
        .updateQuote(body)
        .then(() =>
          notification.success({
            message: t('quotes.modal.archived.success.message'),
          }),
        )
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        )
        .finally(() => {
          setClose();
          setFetchingData(false);
        });
    }
  };

  return (
    <Spin spinning={fetchingData}>
      <Modal
        className="common-modal"
        centered
        visible={isOpen}
        style={{ width: '10rem' }}
        footer={
          <div className="common-modal-footer">
            <Button
              className={`common-gray-border-btn common-secondary-btn`}
              onClick={() => setClose()}
              disabled={fetchingData}>
              {t('budget.modal.cancel.btn')}
            </Button>
            <Button
              className={`common-green-btn common-modal-btn`}
              onClick={() => updateInvoice()}
              disabled={fetchingData}>
              {t('budget.modal.arhive.btn')}
            </Button>
          </div>
        }
        closable={false}>
        <span className={css['delete-question-title']}>
          {t('quotes.modal.confirm.archived.message')}
          {`${quoteNumber}?`}
        </span>
      </Modal>
    </Spin>
  );
};

export default ArchiveConfirmForm;
