import RouteList from '../routes/enum/RouterList';
import { RouterListModel } from '../models/RouterListModel';

const checkRoutelist = (item: any, routeList: any[] = []) => {
  for (let i = 0; i < routeList.length; i++) {
    if (routeList[i].code === item.path) return routeList[i];

    if (routeList[i].children && routeList[i].children.length) {
      const child: any = checkRoutelist(item, routeList[i].children);
      if (child) {
        if (item.ord) {
          child.ord = item.ord;
        }
        return child;
      }
    }
  }
};

export const getRouting = (functions: RouterListModel[] = []) => {
  const result: any[] = [];

  functions.forEach((item: RouterListModel) => {
    const route = checkRoutelist(item, RouteList);
    if (route) {
      const pushItem = { ...route, name: item.name, children: getRouting(item.children), group: item.group };
      result.push(pushItem);
    }
  });
  return result;
};
