import config from '../config';
import { notification } from 'antd';
import DashboardService from '../services/DashboardService';
const service = new DashboardService();

const localStorageKey = 'onboarding';
const enabledOnBoarding = config.enableOnboarding;

export enum OnboardingTypes {
  COMPANIES = 'COMPANIES',
  COMPANIES_MODIFY_NEW = 'COMPANIES_MODIFY_NEW',
  COMPANIES_MODIFY_EDIT = 'COMPANIES_MODIFY_EDIT',
  PROJECT = 'PROJECT',
  PROJECT_MODIFY_NEW = 'PROJECT_MODIFY_NEW',
  PROJECT_MODIFY_EDIT = 'PROJECT_MODIFY_EDIT',
  PROJECT_FLOW = 'PROJECT_FLOW',
  PROJECT_CFC = 'PROJECT_CFC',
  PROPERTY = 'PROPERTY',
  PROPERTY_CHILDREN = 'PROPERTY_CHILDREN',
  PROPERTY_MODIFY_NEW = 'PROPERTY_MODIFY_NEW',
  PROPERTY_MODIFY_EDIT = 'PROPERTY_MODIFY_EDIT',
  PROPERTY_CREATE = 'PROPERTY_CREATE',
  USER = 'USER',
  USER_MODIFY_NEW = 'USER_MODIFY_NEW',
  USER_MODIFY_EDIT = 'USER_MODIFY_EDIT',
  AI_FAQ_MODAL = 'AI_FAQ_MODAL',
}

export const saveUserOnboarding = async (body: any) => {
  await service.saveOnboard(body).catch((e: any) =>
    notification.error({
      message: e.message,
    }),
  );
};

export const saveOnboarding = (key: OnboardingTypes, boardCheckpoint: string | null) => {
  const value = localStorage.getItem(localStorageKey);
  if (value) {
    const obj = JSON.parse(value);
    obj[key] = false;
    localStorage.setItem(localStorageKey, JSON.stringify(obj));
    if (!boardCheckpoint || (boardCheckpoint && JSON.parse(boardCheckpoint)[key] === undefined)) {
      const body = { ...obj };
      const userDashboardHelper = boardCheckpoint ? JSON.parse(boardCheckpoint).dashboardHelper : undefined;
      if (userDashboardHelper !== undefined) {
        body.dashboardHelper = userDashboardHelper;
      }
      saveUserOnboarding(body);
    }
  } else {
    const obj: any = {};
    obj[key] = false;
    localStorage.setItem(localStorageKey, JSON.stringify(obj));
    if (!boardCheckpoint || (boardCheckpoint && JSON.parse(boardCheckpoint)[key] === undefined)) {
      const body = { ...obj };
      const userDashboardHelper = boardCheckpoint ? JSON.parse(boardCheckpoint).dashboardHelper : undefined;
      if (userDashboardHelper !== undefined) {
        body.dashboardHelper = userDashboardHelper;
      }
      saveUserOnboarding(body);
    }
  }
};

export const setOnboarding = (onboarding: string | null) => {
  if (onboarding && onboarding.length > 0) {
    localStorage.removeItem(localStorageKey);
    localStorage.setItem(localStorageKey, onboarding);
    const dashboardHelper = JSON.parse(onboarding).dashboardHelper;
    if (dashboardHelper !== undefined) {
      localStorage.setItem('dashboardHelper', JSON.stringify(dashboardHelper));
    }
  }
};

export const getOnboardingFaq = () => {
  const value = localStorage.getItem(localStorageKey);
  if (value) {
    const obj = JSON.parse(value);
    if (obj[OnboardingTypes.AI_FAQ_MODAL] === undefined) {
      return true;
    }
    return !obj[OnboardingTypes.AI_FAQ_MODAL];
  }
  return true;
};

export const getOnboarding = (key: OnboardingTypes) => {
  if (enabledOnBoarding === 'false') {
    return false;
  }
  const value = localStorage.getItem(localStorageKey);
  if (value) {
    const obj = JSON.parse(value);
    if (obj[key] === undefined) {
      return true;
    }
    return obj[key];
  }
  return true;
};

export const deleteOnBoarding = (keys: OnboardingTypes[] | undefined) => {
  const value = localStorage.getItem(localStorageKey);
  if (value && keys) {
    const obj = JSON.parse(value);
    keys.forEach((key) => {
      delete obj[key];
    });
    localStorage.setItem(localStorageKey, JSON.stringify(obj));
  }
};

export const saveAiFaq = () => {
  const value = localStorage.getItem(localStorageKey);
  if (value) {
    const obj = JSON.parse(value);
    localStorage.setItem(localStorageKey, JSON.stringify(obj));
    const body = { ...obj, [OnboardingTypes.AI_FAQ_MODAL]: true };
    saveUserOnboarding(body);
  } else {
    const obj: any = {
      [OnboardingTypes.AI_FAQ_MODAL]: true,
    };
    localStorage.setItem(localStorageKey, JSON.stringify(obj));
    const body = { ...obj };
    saveUserOnboarding(body);
  }
};
