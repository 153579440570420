import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router';
import { Form, Input, Button, Card, Row, Col, Spin, Select, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import ConsumersService from '../../services/ConsumersService';
import DictionariesServices from '../../services/DictionariesServices';
import { AppContext } from '../../contexts/AppContextProvider';
import { IAppContext } from '../../typings/IApp';

const service: ConsumersService = new ConsumersService();
const dictionariesServices: DictionariesServices = new DictionariesServices();

const LeadsModifyPage: React.FC<any> = (props: any) => {
  const { t } = useTranslation();
  const { id } = useParams<any>();
  const {
    app: { currentProject },
  } = useContext<IAppContext>(AppContext);
  const history = useHistory();
  const isCreate = !id;
  const [form] = Form.useForm();
  const [consumer, setConsumer] = useState<any>(undefined);
  const [countriesCodes, setCountriesCodes] = useState<any>([]);
  const [countryCode, setCountryCode] = useState<string>('');
  const [breadcrumbNameMap, setBreadcrumbNameMap] = useState<{ [name: string]: string }>({});

  const [loading, setLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const getConsumer = () => {
    return service.getConsumerById(id, currentProject?.key).then((res: any) => {
      setConsumer(res);
      const code = res.phone?.substr(0, 2) || null;
      const phone = res.phone?.substr(2, res.phone.length) || null;
      setCountryCode(code);
      form.setFieldsValue({
        email: res.email,
        phone: phone,
        name: res.name,
        surname: res.surname,
        isConfirmed: res.isConfirmed,
        isLocked: !!res.lockedDate,
      });
    });
  };

  const getCountrieCodes = () => {
    return dictionariesServices.getDictionary('countries_codes').then((res: any) => {
      setCountriesCodes(res);
    });
  };

  const getAsyncData = async () => {
    const actions = [getCountrieCodes()];
    if (!isCreate) {
      actions.unshift(getConsumer());
    }
    Promise.all(actions).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    getAsyncData();
  }, []);

  useEffect(() => {
    if (countriesCodes.length > 0 && !countryCode) {
      setCountryCode(countriesCodes[0].code);
    }
  }, [countriesCodes, countryCode]);

  useEffect(() => {
    setBreadcrumbNameMap(
      history.location.pathname
        .replace('/leads', '')
        .replace(/\/modify\/\d+/, '/Modifications')
        .replace(/\/modify/, `/${t('common.title.create')}`)
        .split('/')
        .slice(1)
        .reduce(
          (res: any, curr: any, index: number) => ({
            ...res,
            [[Object.keys(res)[index], curr].join('/')]: curr.split(':').shift(),
          }),
          { '/leads': t('header.title.Leads') },
        ),
    );
  }, [history.location.pathname]);

  const onSelectCountryCode = (value: any) => {
    setCountryCode(value);
  };

  const onSubmit = (values: any) => {
    const body = {
      ...values,
      ...(values.phone ? { phone: `${countryCode}${values.phone}` } : {}),
    };
    if (isCreate) {
      service.createConsumer(body, currentProject.key).then((res: any) => {
        history.push('/leads');
      });
    } else {
      service
        .updateConsumer(
          {
            id: +id,
            ...body,
          },
          currentProject.key,
        )
        .then(() => {
          history.push('/leads');
        });
    }
  };

  const onCancel = () => {
    history.push('/leads');
  };

  return (
    <Spin spinning={isSaving}>
      <Form autoComplete="off" form={form} layout="vertical" name="modify_projects" onFinish={onSubmit}>
        <HeaderTable
          title={!isCreate ? `${t('header.title.Leads')} #${id}` : t('leads.create.title.create')}
          breadcrumb={breadcrumbNameMap}>
          <Form.Item>
            <Button
              htmlType="button"
              onClick={onCancel}
              className={`common-blue-border-btn common-secondary-btn`}
              style={{ height: '42px', marginRight: '1rem' }}>
              {t('common.btn.cancel')}
            </Button>
            <Button htmlType="submit" className={`common-blue-btn common-primary-btn`} style={{ height: '42px' }}>
              {t('common.btn.save')}
            </Button>
          </Form.Item>
        </HeaderTable>
        <Card loading={loading}>
          <Row gutter={[24, 4]}>
            <Col span={12}>
              <Form.Item
                label={t('consumers.create.email')}
                name="email"
                rules={[
                  {
                    type: 'email',
                    required: true,
                    message: t('consumers.create.email.validation'),
                  },
                ]}>
                <Input className="common-animation-primary" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t('consumers.create.phone')} name="phone">
                <Input
                  className="common-animation-primary"
                  style={{ borderRadius: '6px' }}
                  maxLength={10}
                  addonBefore={
                    <Select
                      value={countryCode}
                      style={{ minWidth: '70px', borderRadius: '6px' }}
                      onSelect={onSelectCountryCode}>
                      {countriesCodes.map((item: any) => (
                        <Select.Option key={item.id} value={item.code}>
                          {item.code}
                        </Select.Option>
                      ))}
                    </Select>
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('consumers.create.name')}
                name="name"
                rules={[{ required: true, message: t('consumers.create.name.validation') }]}>
                <Input className="common-animation-primary" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('consumers.create.surname')}
                name="surname"
                rules={[{ required: true, message: t('consumers.create.surname.validation') }]}>
                <Input className="common-animation-primary" />
              </Form.Item>
            </Col>
            {!isCreate && (
              <Col span={12}>
                <Form.Item label={t('consumers.create.isConfirmed.label')} name="isConfirmed" valuePropName="checked">
                  <Checkbox>{t('consumers.create.isConfirmed.value')}</Checkbox>
                </Form.Item>
              </Col>
            )}
            {!!consumer?.lockedDate && (
              <Col span={12}>
                <Form.Item label={t('consumers.create.isLocked.label')} name="isLocked" valuePropName="checked">
                  <Checkbox>{t('consumers.create.isLocked.value')}</Checkbox>
                </Form.Item>
              </Col>
            )}
          </Row>
        </Card>
      </Form>
    </Spin>
  );
};

export default LeadsModifyPage;
