import i18n from '../utils/i18n';

export const defaultFunctions = [
  {
    children: [],
    icon: null,
    id: 20,
    isActive: true,
    name: i18n.t('header.title.Dashboard'),
    ord: 6,
    path: 'dashboard',
    code: 'dashboard',
  },
];

i18n.on('languageChanged', () => {
  defaultFunctions[0].name = i18n.t('header.title.Dashboard');
});
