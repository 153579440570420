import React from 'react';
import css from '../../assets/styles/budget.module.css';
import QuotesListInfo from './components/QuotesListInfo';

interface BrokerQuotesProps {
  propertyId: number;
  supplierId?: number;
  setIsOpenUploadQuote: (b: boolean) => void;
  setIsCreatedQuote: (b: boolean) => void;
  isOpenStatusQuoteForm: boolean;
  isCreatedQuote: boolean;
  setIsOpenStatusQuoteForm: (b: boolean) => void;
  setCurrentQuote: (v: number | null) => void;
  currentQuote: number | null;
}

const BrokerQuotes = (props: BrokerQuotesProps) => {
  const {
    propertyId,
    setIsOpenUploadQuote,
    setIsCreatedQuote,
    isOpenStatusQuoteForm,
    isCreatedQuote,
    setIsOpenStatusQuoteForm,
    setCurrentQuote,
    currentQuote,
    supplierId,
  } = props;

  return (
    <div className={css['container']}>
      <QuotesListInfo
        propertyId={propertyId}
        supplierId={supplierId}
        setIsOpenUploadQuote={setIsOpenUploadQuote}
        setIsCreatedQuote={setIsCreatedQuote}
        isCreatedQuote={isCreatedQuote}
        isOpenStatusQuoteForm={isOpenStatusQuoteForm}
        setIsOpenStatusQuoteForm={setIsOpenStatusQuoteForm}
        currentQuote={currentQuote}
        setCurrentQuote={setCurrentQuote}
      />
    </div>
  );
};

export default BrokerQuotes;
