import { DatePicker, Input, Select, TablePaginationConfig, TreeSelect } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../assets/styles/post.module.css';
import searchIcon from '../../../assets/images/ic-search.svg';
import { CalendarTypes } from '../../../models/CalendarModel';
import { AppContext } from '../../../contexts/AppContextProvider';
import { FilterTypes } from '../../../constants/filterTypes';
import ListPageServices from '../../../services/PropertiesService';
import { FilterValue } from 'antd/lib/table/interface';
import { IAppContext } from '../../../typings/IApp';
import { RangePickerProps } from 'antd/lib/date-picker';
import { useEasyUsers } from '../../../hooks/useEasyUsers';

const { RangePicker } = DatePicker;
const { Search } = Input;

const propertyService = new ListPageServices();

interface IPostFilters {
  pageSize: number;
  onFilterChange: (pagination?: TablePaginationConfig, filters?: Record<string, FilterValue | null | any>) => void;
}

const PostFilters = ({ onFilterChange, pageSize }: IPostFilters) => {
  const { t } = useTranslation();
  const {
    app: { user },
  } = useContext<IAppContext>(AppContext);
  const [projectId, setProjectId] = useState<number | undefined>();
  const [type, setType] = useState<null | string>(null);
  const [properties, setProperties] = useState<any[]>([]);
  const [selectProperties, setSelectProperties] = useState<any[]>([]);
  const {
    users: agents,
    isLastUsersReq: isLastUserReq,
    fetchingUserData: fetchingData,
    getUsers: getAgents,
  } = useEasyUsers(user.projects.map((project) => project.id));

  useEffect(() => {
    setSelectProperties([]);
    if (projectId) {
      const projectKey = (user && user.projects.find((project) => project.id === +projectId)?.key) || '';
      propertyService
        .getLightTreeByProject({
          id: projectId,
          projectKey,
        })
        .then((data) => {
          setProperties(data);
        });
      getAgents([+projectId]);
    } else {
      getAgents();
      setProperties([]);
    }
  }, [projectId]);

  useEffect(() => {
    if (user?.projects.length > 0) {
      setProjectId(user.projects.length === 1 ? user.projects[0].id : undefined);
    }
  }, [user.projects.length]);

  const onChangeType = (value: string) => {
    setType(value || null);
    const body = {
      'type/code': {
        type: FilterTypes.SELECT,
        value,
      },
    };
    onFilterChange({ current: 1, pageSize }, body);
  };

  const onChangeAgent = (value: string) => {
    const body = {
      'createdUser/id': {
        type: FilterTypes.SELECT,
        value,
      },
    };
    onFilterChange({ current: 1, pageSize }, body);
  };

  const onChangeProject = (value: string) => {
    setProjectId(Number(value) || undefined);
    let body = {};
    if (value) {
      body = {
        'properties/project/id': {
          type: FilterTypes.SELECT,
          value,
        },
      };
    } else {
      body = {
        'properties/project/id': {
          type: FilterTypes.SELECT_MULTIPLE,
          value: user.projects.map((project) => project.id),
        },
      };
    }
    onFilterChange({ current: 1, pageSize }, body);
  };

  const onChangeDate = (value: RangePickerProps['value']) => {
    const body = {
      date: {
        type: FilterTypes.DATE_RANGE,
        value,
      },
    };
    onFilterChange({ current: 1, pageSize }, body);
  };

  const onSearchName = (value: string) => {
    const body = {
      name: {
        type: FilterTypes.SEARCH,
        value,
      },
    };
    onFilterChange({ current: 1, pageSize }, body);
  };

  const onSearchDescription = (value: string) => {
    const body = {
      description: {
        type: FilterTypes.SEARCH,
        value,
      },
    };
    onFilterChange({ current: 1, pageSize }, body);
  };

  const onSearchProperty = (value: string[]) => {
    setSelectProperties(value);
    const body = {
      'properties/id': {
        type: FilterTypes.SELECT_MULTIPLE,
        value: value.length > 0 ? value : null,
      },
    };
    onFilterChange({ current: 1, pageSize }, body);
  };

  return (
    <div className={css['post-filters-container']}>
      <Select
        style={{ padding: '5px 0' }}
        className="consumers-control-select post-filter-field common-animation-primary"
        onChange={onChangeType}
        defaultValue={''}
        dropdownClassName="consumers-control-select-dropdown"
        suffixIcon={
          <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
        }>
        <Select.Option value="" className="budget-control-option">
          <span className={css['select-option']}>{t('post.filter.select.all.types')}</span>
        </Select.Option>
        <Select.Option value={CalendarTypes.update} className="budget-control-option">
          <span className={css['select-option']}>{t('post.update')}</span>
        </Select.Option>
        <Select.Option value={CalendarTypes.document} className="budget-control-option">
          <span className={css['select-option']}>{t('post.documents')}</span>
        </Select.Option>
        <Select.Option value={CalendarTypes.image} className="budget-control-option">
          <span className={css['select-option']}>{t('post.image')}</span>
        </Select.Option>
      </Select>
      <Select
        style={{ padding: '5px 0' }}
        className="consumers-control-select post-filter-field common-animation-primary"
        onChange={onChangeAgent}
        defaultValue={''}
        showSearch
        dropdownClassName={`consumers-control-select-dropdown ${
          fetchingData ? 'consumers-control-select-dropdown--progress' : ''
        }`}
        placeholder={t('budget.control.filter.status.placeholder')}
        onPopupScroll={(e: any) => {
          e.persist();
          let target = e.target;
          if (!fetchingData && !isLastUserReq && target.scrollTop + target.offsetHeight === target.scrollHeight) {
            getAgents();
          }
        }}
        filterOption={(input: string, { children }: any) => {
          return children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
        suffixIcon={
          <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
        }>
        <Select.Option
          value=""
          className="budget-control-option"
          style={{ fontSize: '14px', fontWeight: 600, color: '#000000' }}>
          {t('internal.documents.all.signatories')}
        </Select.Option>
        {agents.length > 0 &&
          agents.map((agent) => (
            <Select.Option key={agent.id} value={agent.id}>
              {`${agent.firstName} ${agent.lastName}`}
            </Select.Option>
          ))}
      </Select>
      <Select
        style={{ padding: '5px 0' }}
        className="consumers-control-select post-filter-field common-animation-primary"
        onChange={onChangeProject}
        value={(projectId as string | undefined) || ''}
        dropdownClassName="consumers-control-select-dropdown"
        suffixIcon={
          <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
        }>
        <Select.Option value="" className="documents-control-option">
          <span style={{ fontSize: '14px', fontWeight: 600 }}>{t('tickets.control.filter.defvalue.project')}</span>
        </Select.Option>
        {user &&
          user.projects &&
          user.projects
            .sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))
            .map((project) => (
              <Select.Option value={project.id} key={project.id} className="documents-control-option">
                <span style={{ fontSize: '14px', fontWeight: 600 }}>{project.title}</span>
              </Select.Option>
            ))}
      </Select>
      <TreeSelect
        treeData={properties}
        multiple
        showCheckedStrategy="SHOW_CHILD"
        treeCheckable
        maxTagCount={5}
        value={selectProperties}
        style={{ padding: '5px 0' }}
        placeholder={t('consumers.control.search.placeholder.property')}
        className="faq-modify-select post-modal-field common-animation-primary post-filter-field"
        fieldNames={{ label: 'title', value: 'id', children: 'childrens' }}
        onChange={onSearchProperty}
      />
      <RangePicker
        suffixIcon={<div className={'calendar-img'} style={{ width: '12px', height: '12px' }}></div>}
        className="budget-control-range-picker common-animation-primary post-filter-field"
        style={{ height: '42px', padding: '10px 5px' }}
        format="DD.MM.YYYY"
        inputReadOnly
        onChange={onChangeDate}
      />
      {type === CalendarTypes.document && (
        <Search
          onChange={(e) => onSearchName(e.target.value)}
          placeholder={t('budget.search.number.placeholder')}
          allowClear
          className="budget-control-search common-animation-primary post-filter-field"
          suffix={<img src={searchIcon} alt="" />}
          style={{ padding: '5px 0' }}
        />
      )}
      <Search
        onChange={(e) => onSearchDescription(e.target.value)}
        placeholder={t('post.fileter.text.placeholder')}
        allowClear
        className="budget-control-search common-animation-primary post-filter-field"
        suffix={<img src={searchIcon} alt="" />}
        style={{ padding: '5px 0' }}
      />
    </div>
  );
};

export default PostFilters;
