import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import moment from 'moment';
import { Form, Button, DatePicker, Row, Col, Select, Upload, notification, Spin } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../contexts/AppContextProvider';
import LogoComponent from '../../components/common/LogoComponent';
import TicketsService from '../../services/TicketsService';
import { SystemMessagesTicketModel, UpdateTicketModel } from '../../models/TicketModel';
import { disabledDate } from '../../helpers/dateTools';
import css from '../../assets/styles/tickets.module.css';
import icAttach from '../../assets/images/ic-attach.svg';
import icIncoming from '../../assets/images/ic-mail-incoming.svg';
import icOutgoing from '../../assets/images/ic-mail-outgoing.svg';
import icPin from '../../assets/images/ic-pin.svg';
import icDate from '../../assets/images/ic-date.svg';
import config from '../../config';
import { IAppContext } from '../../typings/IApp';

const ticketsService: TicketsService = new TicketsService();

interface SystemMessageProps {
  createdDate: string;
  status: string;
  systemMessages: SystemMessagesTicketModel;
}

const SystemMessage = ({ createdDate, status, systemMessages }: SystemMessageProps) => {
  const backgroundColor: any = {
    opened: { color: '#FAFAFA', text: 'Opened ticket' },
    closed: { color: '#EAF7EC', text: 'Closed ticket' },
    canceled: { color: '#F7EFEA', text: 'Opened ticket' },
  };

  const message = systemMessages.find((item) => item.code === status)?.description;

  return (
    <div className={css['ticket-system-message']} style={{ backgroundColor: backgroundColor[status]?.color }}>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <div>{message}</div>
      </div>
      <div style={{ paddingLeft: '13px', paddingRight: '16px', display: 'flex' }}>
        <img src={icDate} alt="" />
        <span style={{ paddingLeft: '13px' }}>{moment(createdDate).format('DD/MM/YYYY')}</span>
      </div>
    </div>
  );
};

interface UserMessageProps {
  createdDate: string;
  files: Array<any>;
  fullName: string;
  message: string;
  userId: number;
}

const UserMessage = ({ createdDate, files, fullName, message, userId }: UserMessageProps) => {
  return (
    <div className={css['ticket-user-message-container']}>
      <div style={{ display: 'flex' }}>
        <div>
          <LogoComponent id={userId} size={54} name={fullName} image={''} />
        </div>
        <div className={css['ticket-user-message-content']}>
          <div style={{ marginBottom: '14px' }}>{fullName}</div>
          <div style={{ fontWeight: 300, fontSize: '14px', marginBottom: '10px' }}>{message}</div>
          <div className={css['ticket-user-files-container']}>
            {files.map((file) => (
              <a
                key={file.id}
                className={css['ticket-user-file-container']}
                href={`${config.storeUrl}/${file.fileName}`}
                target="_blank">
                <div className="">
                  <img src={icAttach} style={{ height: '15px', width: '15px' }} />
                </div>
                <span className={css['ticket-user-file-name']}>{file.originalName}</span>
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className={css['ticket-user-message-date']}>
        <img src={icDate} alt="" />
        <span>{moment(createdDate).format('DD/MM/YYYY')}</span>
      </div>
    </div>
  );
};

const TicketViewPage: React.FC = (props: any) => {
  const { t } = useTranslation();
  const { id: paramTicketId } = useParams<any>();
  const {
    app: { user, currentProperty },
  } = useContext<IAppContext>(AppContext);
  const history = useHistory();
  const isConsumer = !!user?.rolesParams?.IS_CONSUMER;
  const [form] = Form.useForm();

  const [ticket, setTicket] = useState(null as any);
  const [messages, setMessages] = useState<any[]>([]);
  const [priorities, setPriorities] = useState<any[]>([]);
  const [statuses, setStatuses] = useState<any[]>([]);
  const [systemMessages, setSystemMessages] = useState<any[]>([]);
  const [fileList, setFileList] = useState([] as any[]);
  const [broker, setBroker] = useState({ id: 0, fullName: '' } as any);

  const [fetchingData, setFetchingData] = useState(true);
  const [isLoadedData, setIstLoadedData] = useState({
    ticket: false,
    priority: false,
    status: false,
    systemMessages: false,
  });

  const getPriorities = () => {
    ticketsService.getPriorities().then((res: any) => {
      setPriorities(res);
      setIstLoadedData((state) => ({ ...state, priority: true }));
    });
  };

  const getStatuses = () => {
    ticketsService.getStatuses().then((res: any) => {
      setStatuses(res);
      setIstLoadedData((state) => ({ ...state, status: true }));
    });
  };

  const getSystemMessages = () => {
    ticketsService.getSystemMessages().then((res: any) => {
      setSystemMessages(res);
      setIstLoadedData((state) => ({ ...state, systemMessages: true }));
    });
  };

  const getTicket = (id: number) => {
    ticketsService.getTicketById(id).then((res: any) => {
      setTicket(res);
      setMessages(res.messages.sort((a: any, b: any) => b.id - a.id));
      ticketsService.setReadTicketById(id);
      form.setFieldsValue({
        status: res?.status?.code,
        priority: res?.priority?.code,
        date: res?.dueDate ? moment(res.dueDate) : undefined,
      });
      setIstLoadedData((state) => ({ ...state, ticket: true }));
    });
  };

  const getBroker = () => {
    setBroker({
      id: currentProperty.agent.id,
      fullName: `${currentProperty.agent.firstName} ${currentProperty.agent.lastName}`,
    });
  };

  // ? []
  useEffect(() => {
    if (isConsumer) {
      getBroker();
    }
    getPriorities();
    getStatuses();
    getSystemMessages();
    getTicket(paramTicketId);
  }, []);

  // ? [isLoadedData]
  useEffect(() => {
    const { ticket, priority, status, systemMessages } = isLoadedData;
    if (ticket && priority && status && systemMessages) setFetchingData(false);
  }, [isLoadedData]);

  const onCancelOrSuccess = (isShowMessage: boolean = false) => {
    if (isShowMessage) {
      notification.success({
        message: t('tickets.create.message.success.notify'),
      });
    }
    history.push('/tickets');
  };

  const onSubmit = (values: any) => {
    const { message } = values;
    if (!message) return;

    const filesData = new FormData();
    for (const file of fileList) {
      if (file.originFileObj) {
        filesData.append('file', file.originFileObj);
      }
    }
    setFetchingData(true);

    ticketsService
      .addMessageByTicketId(paramTicketId, { message: values.message || 'Message...' })
      .then((resMessage: any) => {
        if (filesData.getAll('file').length > 0) {
          ticketsService
            .addFileByMessageId(resMessage.id, filesData)
            .then(() => {
              onCancelOrSuccess(true);
            })
            .catch((e: any) => {
              console.log(e);
              notification.error({
                message: e.message,
              });
              setFetchingData(false);
            });
        } else {
          onCancelOrSuccess(true);
        }
      })
      .catch((e: any) => {
        console.log(e);
        // TODO! Show message something went wrong
        notification.error({
          message: e.message,
        });
        setFetchingData(false);
      });

    return;
  };

  const onValuesChange = (changedValues: any, allValues: any) => {
    const { message } = changedValues;
    if (message) return;

    const { status, priority, date: dueDate } = allValues;
    const { id } = ticket;
    const body: UpdateTicketModel = { status, dueDate: dueDate.format('YYYY-MM-DD'), priority };

    ticketsService
      .updateTicketById(id, body)
      .then(() => {
        notification.success({
          message: t('tickets.update.success.notify'),
        });
        if (ticket.status !== status) getTicket(id);
      })
      .catch((err) => {
        notification.success({
          message: t('tickets.update.error.common'),
        });
      });
  };

  const handleFile = (info: any) => {
    setFileList(info.fileList);
  };

  const {
    id: ticketId = '',
    subject = '',
    createdDate,
    recipient = {},
    project: { title: titleProject = '' },
    property: { title: titleProperty = '' },
  } = ticket || { project: {}, property: {} };

  const { firstName = '', lastName = '', id: recipientId } = recipient;

  const fullNameInfo = isConsumer ? `${broker?.fullName}` : `${firstName} ${lastName}`;

  return (
    <Form
      className={css['tickets-view-form']}
      layout="vertical"
      onFinish={onSubmit}
      onValuesChange={onValuesChange}
      form={form}
      style={{ height: '100%', overflowX: 'hidden' }}>
      <Spin spinning={fetchingData} wrapperClassName="property-spin">
        <div className={css['view-header-container']}>
          <Button
            htmlType="button"
            onClick={() => history.push('/tickets')}
            className={`common-blue-border-btn common-secondary-btn`}
            style={{ marginBottom: '1rem', height: '42px' }}>
            {t('common.return.btn')}
          </Button>
        </div>
        <Row gutter={[16, 0]} style={{ height: '100%' }}>
          <Col xs={24} lg={10} xl={6}>
            <div style={{ backgroundColor: 'white', padding: '1rem', height: '100%' }}>
              {!!ticketId && (
                <div style={{ marginBottom: 20 }}>
                  <img src={user.id === recipientId ? icIncoming : icOutgoing} alt="" style={{ marginRight: 10 }} />{' '}
                  {`#${ticketId}`}
                </div>
              )}
              <Form.Item
                shouldUpdate
                name="status"
                label={<span style={{ color: '#778dac' }}>{t('tickets.create.status')}</span>}
                rules={[{ required: true, message: t('common.validation.required') }]}>
                <Select
                  placeholder={t('tickets.create.placeholder.status')}
                  className="consumer-form-input common-animation-primary"
                  suffixIcon={
                    <div
                      className={`arrow-drop-img ${css['arrow-drop']}`}
                      style={{ width: '8px', height: '4px' }}></div>
                  }>
                  {statuses.map((status: any) => {
                    return (
                      <Select.Option
                        value={status.code}
                        key={status.code}
                        title={status.description}
                        disabled={form && form.getFieldValue('status') === status.code}>
                        {status.description}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                shouldUpdate
                name="priority"
                label={<span style={{ color: '#778dac' }}>{t('tickets.create.priority')}</span>}
                rules={[{ required: true, message: t('common.validation.required') }]}>
                <Select
                  placeholder={t('tickets.create.placeholder.priority')}
                  className="consumer-form-input common-animation-primary"
                  suffixIcon={
                    <div
                      className={`arrow-drop-img ${css['arrow-drop']}`}
                      style={{ width: '8px', height: '4px' }}></div>
                  }>
                  {priorities.map((priority: any) => {
                    return (
                      <Select.Option
                        value={priority.code}
                        key={priority.code}
                        title={priority.description}
                        disabled={form && form.getFieldValue('priority') === priority.code}>
                        {priority.description}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label={<span style={{ color: '#778dac' }}>{t('tickets.create.date')}</span>}
                rules={[{ required: true, message: t('common.validation.required') }]}
                name="date">
                <DatePicker
                  inputReadOnly
                  className="consumer-form-input common-animation-primary"
                  suffixIcon={<div className={'calendar-img'} style={{ width: '12px', height: '12px' }}></div>}
                  allowClear={false}
                  format={'DD.MM.YYYY'}
                  style={{ width: '100%' }}
                  disabledDate={disabledDate}
                />
              </Form.Item>
              <Form.Item label={<span style={{ color: '#778dac' }}>{t('tickets.create.createddate')}</span>}>
                <span style={{ paddingLeft: 11 }}>{createdDate && moment(createdDate).format('DD.MM.YYYY')}</span>
              </Form.Item>
              {!fetchingData && (
                <div className={css['ticket-user-info']}>
                  <div className={css['ticket-user']}>
                    <LogoComponent
                      id={isConsumer ? broker?.id : recipientId}
                      size={64}
                      name={fullNameInfo}
                      image={''}
                    />
                    <div className={css['ticket-user-detail']}>
                      <span>{fullNameInfo}</span>
                      {isConsumer && (
                        <span className={css['ticket-user-broker']}>{t('tickets.view.broker.title')}</span>
                      )}
                    </div>
                  </div>
                  {!isConsumer && (
                    <>
                      <div className={css['ticket-user-detail-property']}>
                        <span>{t('tickets.view.project')}</span>
                        <span>{titleProject}</span>
                      </div>
                      <div className={css['ticket-user-detail-property']}>
                        <span>{t('tickets.view.property')}</span>
                        <span>{titleProperty}</span>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </Col>
          <Col xs={24} lg={14} xl={18}>
            <div style={{ backgroundColor: 'white', height: '100%' }}>
              <div style={{ padding: '1rem' }}>
                <div className={css['ticket-messages']}>
                  <div>
                    <img src={icPin} alt="" />
                    <span className={css['ticket-messages-title']}>{t('tickets.table.columns.subject')}</span>
                  </div>
                  <span className={css['ticket-messages-subject']}>{subject}</span>
                </div>
                {ticket?.status?.code === 'opened' && (
                  <div className={css['ticket-messages-answer']}>
                    <LogoComponent id={user.id} size={54} name={`${user.firstName} ${user.lastName}`} image={''} />
                    <Form.Item
                      // rules={[{ required: true, message: t('common.validation.required') }]}
                      colon={false}
                      // name="common"
                      style={{ width: 'calc(100% - 99px)', marginLeft: 28 }}>
                      <div
                        className="ticket-new-textarea common-animation-primary"
                        style={{
                          borderRadius: '6px',
                          padding: '1rem',
                          backgroundColor: 'white',
                        }}>
                        <Form.Item name="message">
                          <TextArea
                            bordered={false}
                            className={css['updates-input']}
                            autoSize={{ minRows: 2, maxRows: 10 }}
                            placeholder={t('tickets.create.placeholder.message')}
                            style={{ backgroundColor: 'white' }}
                          />
                        </Form.Item>
                        <Upload
                          name="upload"
                          className={'upload-updates'}
                          fileList={fileList}
                          beforeUpload={() => false}
                          onChange={handleFile}
                          multiple
                          listType="text"
                          iconRender={() => <img src={icAttach} style={{ height: '15px', width: '15px' }} />}>
                          <Button className="common-upload-file-btn">
                            <img src={icAttach} alt="" className={css['ic-attach']} />
                            <span className={css['attach-file-title']}>{t('tickets.attach.file')}</span>
                          </Button>
                        </Upload>
                      </div>
                      <div className={css['tickets-view-btns-container']}>
                        <Button htmlType="submit" className={`${css['tickets-add-new']} common-primary-btn`}>
                          {t('tickets.btn.reply')}
                        </Button>
                      </div>
                    </Form.Item>
                  </div>
                )}
              </div>
              {messages.map((msg) => (
                <div key={msg.id} className={css['ticket-message-row']}>
                  {msg.isSystem ? (
                    <SystemMessage createdDate={msg.createdDate} status={msg.message} systemMessages={systemMessages} />
                  ) : (
                    <UserMessage
                      createdDate={msg.createdDate}
                      userId={msg.createdUser.id}
                      fullName={`${msg.createdUser.firstName} ${msg.createdUser.lastName}`}
                      files={msg.files}
                      message={msg.message}
                    />
                  )}
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Spin>
    </Form>
  );
};

export default TicketViewPage;
