import React, { FC, useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router';
import { notification, Spin } from 'antd';
import { AppContext } from '../../contexts/AppContextProvider';
import { getSearchParam } from '../../helpers/customUrlSearchParams';
import LoginPageServices from '../../services/LoginPageServices';
import { SET_AUTHENTICATED } from '../../constants/actionTypes/appConstants';
import { IAppContext } from '../../typings/IApp';

const ProcoreCallBackPage: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const { appDispatch } = useContext<IAppContext>(AppContext);
  const loginPageServices = new LoginPageServices();

  useEffect(() => {
    procoreAuth();
  }, []);

  const setGlobalCredentials = (user: any, data: any) => {
    appDispatch({ type: SET_AUTHENTICATED, user: user });
    localStorage.setItem('user', JSON.stringify(user));
    if (user.projects && user.projects.length > 0) {
      localStorage.setItem('currentProject', JSON.stringify(user.projects[0]));
    }
    if (user.properties && user.properties.length > 0 && user.projects && user.projects.length > 0) {
      localStorage.setItem(
        'currentProperty',
        JSON.stringify(
          user.properties.find((property: any) => property.project.id === user.projects[0].id) || user.properties[0],
        ),
      );
    }
    if (user.rolesParams && user.rolesParams.IS_CONSUMER) {
      localStorage.setItem('IS_CONSUMER', JSON.stringify(user.rolesParams.IS_CONSUMER));
    }
    if (user.rolesParams && user.rolesParams.IS_SUPPLIER) {
      localStorage.setItem('IS_SUPPLIER', JSON.stringify(user.rolesParams.IS_SUPPLIER));
    }
    localStorage.setItem('id_token', JSON.stringify(data));
    history.push(user.rolesParams && user.rolesParams.IS_SUPPLIER ? '/consumers' : '/dashboard');
  };

  const procoreAuth = () => {
    const access_token = getSearchParam(location.hash, 'access_token');
    const token_type = getSearchParam(location.hash, 'token_type');
    const expires_in = getSearchParam(location.hash, 'expires_in');
    loginPageServices
      .procoreAuth(access_token)
      .then((res: any) => {
        const user = res.user;
        const data = res;
        setGlobalCredentials(user, data);
      })
      .catch((err: any) => {
        history.push('/login');
        notification.error({
          message: 'Error',
          description: err.message,
        });
      });
  };

  return <Spin size="large" className="procore-loading" />;
};

export default ProcoreCallBackPage;
