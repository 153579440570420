import React, { useContext, useEffect, useState } from 'react';
import { CHANGE_CURRENT_PAGE } from '../../../constants/actionTypes/headerConstants';
import { AppContext } from '../../../contexts/AppContextProvider';
import { HeaderContext } from '../../../contexts/HeaderContextProvider';
import css from '../../../assets/styles/faq.module.css';
import NoPropertyPage from '../../common/NoPropertyPage';
import SearchFAQ from './components/SearchFAQ';
import TopicFAQ from './components/TopicFAQ';
import ResultFAQ from './components/ResultFAQ';
import { QuestionsModel } from '../../../models/FAQModel';
import { useLessThen640 } from '../../../helpers/mediaDetect';
import MobileFAQ from './components/MobileFAQ';
import { IAppContext } from '../../../typings/IApp';

const ConsumerFAQ = () => {
  const { headerDispatch } = useContext(HeaderContext);
  const {
    app: { currentProperty },
  } = useContext<IAppContext>(AppContext);
  const [selectTopicId, setSelectTopicId] = useState<null | number>(null);
  const [resultFAQ, setResultFAQ] = useState<null | QuestionsModel>(null);
  const [searchingWord, setSearchingWord] = useState<string>('');

  useEffect(() => {
    headerDispatch({
      type: CHANGE_CURRENT_PAGE,
      currentPage: 'FAQ',
      path: 'faq',
    });
  }, []);

  if (!currentProperty) {
    return <NoPropertyPage />;
  }

  return (
    <div className={css['container-consumer']}>
      <SearchFAQ setResultFAQ={setResultFAQ} setSearchingWord={setSearchingWord} />
      {useLessThen640() ? (
        <div className={css['wrapperMobile']}>
          <MobileFAQ
            setSelectTopicId={setSelectTopicId}
            setResultFAQ={setResultFAQ}
            isEmptySearch={!searchingWord}
            selectTopicId={selectTopicId}
            resultFAQ={resultFAQ}
            searchingWord={searchingWord}
          />
        </div>
      ) : (
        <div className={css['wrapper']}>
          <TopicFAQ
            setSelectTopicId={setSelectTopicId}
            setResultFAQ={setResultFAQ}
            resultFAQ={resultFAQ}
            selectTopicId={selectTopicId}
            isEmptySearch={!searchingWord}
          />
          <ResultFAQ selectTopicId={selectTopicId} resultFAQ={resultFAQ} searchingWord={searchingWord} />
        </div>
      )}
    </div>
  );
};

export default ConsumerFAQ;
