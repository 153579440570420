import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { Form, Button, Row, notification, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import css from '../../assets/styles/companies.module.css';
import CompaniesService from '../../services/CompaniesService';
import { CompanyModel, CreateCompanyModel, PaymentInfo, UpdatedCompayModel } from '../../models/CompaniesModel';
import CompanyInfo from './components/CompanyInfo';
import PaymentProfile from './components/PaymentProfile/PaymentProfile';
import ProjectsService from '../../services/ProjectsService';
import config from '../../config';
import { UploadChangeParam } from 'antd/lib/upload';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { getBase64Url } from '../../helpers/avatarTools';
import { AppContext } from '../../contexts/AppContextProvider';
import ConfirmChangeEmailModal from './components/ConfirmChangeEmailModal';
import { HeaderContext } from '../../contexts/HeaderContextProvider';
import { CHANGE_CURRENT_PAGE } from '../../constants/actionTypes/headerConstants';
import { useLessThen801 } from '../../helpers/mediaDetect';
import CustomOnBoard from '../common/CustomOnBoard';
import BoardTourComponent from '../common/BoardTourComponent';
import { getOnboarding, OnboardingTypes, saveOnboarding } from '../../helpers/onboarding';
import { IProjectEasy } from '../../models/ProjectModel';
import { IAppContext } from '../../typings/IApp';
import i18n from '../../utils/i18n';

const projectsService = new ProjectsService();
const service = new CompaniesService();

const CompaniesModifyPage: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const {
    app: { user },
  } = useContext<IAppContext>(AppContext);
  const history = useHistory();
  const isCreate = !id;
  const [form] = Form.useForm();
  const [breadcrumbNameMap, setBreadcrumbNameMap] = useState<{ [name: string]: string }>({});
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [company, setCompany] = useState<CompanyModel>();
  const [paymentsProfile, setPaymentsProfile] = useState<PaymentInfo[] | []>(company?.paymentInfo || []);
  const [projects, setProjects] = useState<IProjectEasy[]>([]);
  const [logoUrl, setLogoUrl] = useState<string | ArrayBuffer | null>(null);
  const [logo, setLogo] = useState<any>();
  const [isDeleteLogo, setIsDeleteLogo] = useState<boolean>(false);
  const [isLoadingLogo, setIsLoadingLogo] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const [isEmailEdit, setIsEmailEdit] = useState(false);
  const { headerDispatch } = useContext(HeaderContext);

  //board
  const [steps, _] = useState([
    {
      target: '.company-modify-first-step',
      content: (
        <BoardTourComponent
          title={t('board.company.modify.first.title')}
          description={t('board.company.modify.first.text')}
        />
      ),
      disableBeacon: true,
      placement: 'right-start',
    },
    {
      target: '.company-modify-second-step',
      disableBeacon: true,
      content: (
        <BoardTourComponent
          title={t('board.company.modify.second.title')}
          description={t('board.company.modify.second.text')}
        />
      ),
      placement: 'left',
    },
    {
      target: '.company-modify-third-step',
      disableBeacon: true,
      content: (
        <BoardTourComponent
          title={t('board.company.modify.third.title')}
          description={t('board.company.modify.third.text')}
        />
      ),
      placement: 'left',
    },
  ]);
  const [run, setRun] = useState(false);

  useEffect(() => {
    headerDispatch({
      type: CHANGE_CURRENT_PAGE,
      currentPage: t('companies.title.header'),
      path: 'companies',
    });
  }, [i18n.language]);

  useEffect(() => {
    setRun(getOnboarding(isCreate ? OnboardingTypes.COMPANIES_MODIFY_NEW : OnboardingTypes.COMPANIES_MODIFY_EDIT));
  }, [isCreate]);

  useEffect(() => {
    setBreadcrumbNameMap(
      history.location.pathname
        .replace('/companies', '')
        .replace(/\/modify\/\d+/, '/Modifications')
        .replace(/\/modify/, `/${t('common.title.create')}`)
        .split('/')
        .slice(1)
        .reduce(
          (res, curr, index) => ({
            ...res,
            [[Object.keys(res)[index], curr].join('/')]: curr.split(':').shift(),
          }),
          { '/companies': t('companies.title.header') },
        ),
    );
  }, [history.location.pathname]);

  const getCompany = () => {
    service.getCompanyById(+id).then((company: CompanyModel) => {
      form.resetFields();
      setCompany(company);
      setPaymentsProfile(company.paymentInfo);
      form.setFieldsValue({
        companyName: company.companyName,
        street: company.street,
        zip: company.zip,
        city: company.city,
        state: company.state,
        country: company.country,
        phone: company.phone,
        email: company.email,
        projects: company.projects.map((el) => el.id) || [],
      });
      if (company.logo) {
        setLogoUrl(`${config.storeUrl}/${company.logo}`);
      }
    });
  };
  const getProjects = () => {
    projectsService.getProjects().then((res: IProjectEasy[]) => {
      setProjects(res);
    });
  };
  const getAsyncData = async () => {
    setIsSaving(true);
    const actions = [getProjects()];
    if (!isCreate) {
      actions.unshift(getCompany());
    }
    Promise.all(actions).finally(() => {
      setIsSaving(false);
    });
  };
  useEffect(() => {
    getAsyncData();
  }, []);

  const beforeUploadLogo = (file: RcFile) => {
    setIsLoadingLogo(true);
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      notification.error({ message: t('projects.create.logo.mimetype.error') });
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      notification.error({ message: t('projects.create.logo.fileSize.error') });
    }
    return isJpgOrPng && isLt2M;
  };
  const deleteLogo = () => {
    if (logoUrl) {
      setLogo(null);
      setLogoUrl(null);
      setIsDeleteLogo(true);
    }
  };
  const handleChangeLogo = (info: UploadChangeParam<UploadFile<any>>) => {
    const { file } = info;
    getBase64Url(file.originFileObj, (imageUrl: string | ArrayBuffer | null) => {
      setLogo(file);
      setLogoUrl(imageUrl);
      setIsLoadingLogo(false);
    });
  };

  const onSubmit = (values: any) => {
    setIsSaving(true);
    const modelActions = (id: number) => {
      const promises: Promise<any>[] = [];
      if (logo) {
        promises.push(
          new Promise((resolve, reject) =>
            service
              .uploadLogo(id, logo)
              .then(() => resolve(null))
              .catch(() => {
                reject(t('common.large.file.size'));
              }),
          ),
        );
      }
      if (isDeleteLogo && !logoUrl) {
        promises.push(service.deleteLogo(id));
      }

      Promise.all(promises)
        .then(
          () => {
            notification.success({
              message: t(isCreate ? 'companies.create.success.notify' : 'companies.update.success.notify'),
            });
            history.push('/companies');
          },
          (reason) => {
            notification.error({
              message: reason,
            });
          },
        )
        .finally(() => {
          setIsSaving(false);
        });
    };

    const body: CreateCompanyModel = {
      companyName: values.companyName,
      email: values.email,
      street: values.street,
      city: values.city,
      zip: values.zip,
      state: values.state || null,
      country: values.country,
      phone: values.phone,
      projects: values.projects ? values.projects.map((id: number) => ({ id })) : [],
      paymentInfo: paymentsProfile,
    };

    if (isCreate) {
      service
        .createCompany(body)
        .then((res) => {
          modelActions(res.id);
        })
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        )
        .finally(() => setIsSaving(false));
    } else {
      (body as UpdatedCompayModel)['id'] = +id;
      service
        .updateCompany(body as UpdatedCompayModel)
        .then(() => {
          modelActions(+id);
        })
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        )
        .finally(() => setIsSaving(false));
    }
  };

  const onCancel = () => {
    history.push('/companies');
  };

  const setPaymentsInfo = (data: PaymentInfo) => {
    if (data.id) {
      return setPaymentsProfile(paymentsProfile.map((item) => (item.id === data.id ? data : item)));
    }
    setPaymentsProfile([...paymentsProfile, data]);
  };

  const handleSave = () => {
    if (company && user.rolesParams.IS_SUPER_BROKER && isEmailEdit) {
      return setOpenModal(true);
    }
    form.submit();
  };

  const onOk = () => {
    setIsEmailEdit(false);
    form.submit();
    setOpenModal(false);
  };

  return (
    <Spin spinning={isSaving}>
      <CustomOnBoard
        steps={steps}
        run={run}
        onFinish={() =>
          saveOnboarding(
            isCreate ? OnboardingTypes.COMPANIES_MODIFY_NEW : OnboardingTypes.COMPANIES_MODIFY_EDIT,
            user.boardCheckpoint,
          )
        }
      />
      <Form
        form={form}
        layout={useLessThen801() ? 'horizontal' : 'vertical'}
        name="modify_companies"
        onFinish={onSubmit}
        style={{ overflowX: 'hidden' }}>
        <div style={useLessThen801() ? {} : { display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <HeaderTable breadcrumb={breadcrumbNameMap} />
          <Form.Item style={{ display: 'flex', alignItems: 'center', marginBottom: 0 }}>
            {useLessThen801() ? (
              <div style={{ display: 'flex' }}>
                <Button
                  htmlType="button"
                  onClick={onCancel}
                  className={`common-blue-border-btn common-secondary-btn`}
                  style={{ height: '42px', marginRight: '0.5rem', width: '100%' }}>
                  {t('common.btn.cancel')}
                </Button>
                <Button
                  className={`common-blue-btn common-primary-btn`}
                  onClick={handleSave}
                  style={{ height: '42px', marginLeft: '0.5rem', width: '100%' }}>
                  {t('common.btn.save')}
                </Button>
              </div>
            ) : (
              <div className="company-modify-third-step">
                <Button
                  htmlType="button"
                  onClick={onCancel}
                  className={`common-blue-border-btn common-secondary-btn`}
                  style={{ height: '42px', marginRight: '1rem' }}>
                  {t('common.btn.cancel')}
                </Button>
                <Button
                  className={`common-blue-btn common-primary-btn`}
                  onClick={handleSave}
                  style={{ height: '42px' }}>
                  {t('common.btn.save')}
                </Button>
              </div>
            )}
          </Form.Item>
        </div>
        <Row
          gutter={[12, 4]}
          style={{ paddingTop: '24px', height: useLessThen801() ? '100% ' : '82vh' }}
          className={css['companies-modify-container']}>
          <CompanyInfo
            form={form}
            company={company}
            projects={projects}
            deleteLogo={deleteLogo}
            handleChangeLogo={handleChangeLogo}
            logoUrl={logoUrl}
            isLoadingLogo={isLoadingLogo}
            beforeUploadLogo={beforeUploadLogo}
            isEmailEdit={isEmailEdit}
            setIsEmailEdit={setIsEmailEdit}
            isCreate={isCreate}
          />
          <PaymentProfile paymentsInfo={paymentsProfile} setPaymentsInfo={setPaymentsInfo} />
        </Row>
      </Form>
      <ConfirmChangeEmailModal isOpen={openModal} onOk={onOk} onCancel={() => setOpenModal(false)} />
    </Spin>
  );
};

export default CompaniesModifyPage;
