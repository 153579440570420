import HttpService from './common/httpService';
import config from '../config';
import buildQuery from 'odata-query';
import { UpdatedEmailSetting, UpdatedNotificationSetting } from '../models/NotificationModel';

class NotificationsService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getNotifications(params: any) {
    const { top, skip, count = true, orderBy = 'createdDate desc', filter = {} } = params;
    const query = buildQuery({ filter, top, count, skip, orderBy });
    return this.httpService.get(`${config.baseRequest}notification${query}`);
  }

  public setNotificationsAsRead(ids: number[]) {
    return this.httpService.patch(`${config.baseRequest}notification`, ids);
  }

  public getNotificationsSettings() {
    return this.httpService.get(`${config.baseRequest}notification/settings`);
  }

  public getEmailSettings() {
    return this.httpService.get(`${config.baseRequest}emails/settings`);
  }

  public updateNotificationSettings(body: UpdatedNotificationSetting) {
    return this.httpService.patch(`${config.baseRequest}notification/settings`, body);
  }

  public updateEmailSettings(body: UpdatedEmailSetting) {
    return this.httpService.patch(`${config.baseRequest}emails/settings`, body);
  }

  public getNotificationsHistory(params: any) {
    const { top, skip, count = true, orderBy, filters = {} } = params;
    const query = buildQuery({ filter: filters, top, count, skip, orderBy });
    return this.httpService.get(`${config.baseRequest}notification/view/history${query}`);
  }

  public getNotificationTypes() {
    return this.httpService.get(`${config.baseRequest}notification/dictionary/types`);
  }
}

export default NotificationsService;
