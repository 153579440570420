import React, { createContext, useReducer } from 'react';
import { initialState, reducer } from '../reducers/propertyReducer';

export const PropertyContext = createContext({} as any);

const PropertyContextProvider: React.FC = (props: any) => {
  const [property, propertyDispatch] = useReducer(reducer, initialState);

  return <PropertyContext.Provider value={{ property, propertyDispatch }}>{props.children}</PropertyContext.Provider>;
};

export default PropertyContextProvider;
