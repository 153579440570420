import HttpService from './common/httpService';
import config from '../config';

class ArkaService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getToken() {
    return this.httpService.post(`${config.baseRequest}auth/arka`);
  }
}

export default ArkaService;
