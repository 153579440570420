import { Button, DatePicker, Dropdown, Input, Menu, notification, Upload } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../../assets/styles/calendar.module.css';
import CalendarService from '../../../../services/CalendarServices';
import icAttach from '../../../../assets/images/ic-attach.svg';
import config from '../../../../config';
import moreIcon from '../../../../assets/images/ic-more.svg';
import { UpdateModel } from '../../../../models/CalendarModel';
import editIcon from '../../../../assets/images/ic-edit-blue.svg';
import deleteIcon from '../../../../assets/images/ic-delete-blue.svg';
import linkIcon from '../../../../assets/images/ic-link.svg';
import i18n from '../../../../utils/i18n';
import { MeetingModel } from '../../../../models/MeetingsModel';
import ModalUploadFileSuccess from '../../../../components/common/Modals/ModalUploadFileSuccess';
import { NewUpdate, CalendarTypes } from '../../../../models/CalendarModel';
import PostModalForm from '../../../Posts/components/PostModalForm';
import PostConfirmModal from '../../../Posts/components/PostConfirmModal';
import { authDownloadFile } from '../../../../helpers/authFileTools';
import parser from 'html-react-parser';

const { TextArea } = Input;

interface UpdatesEditProps {
  updates: any;
  propertyId: number;
  getCalendarUpdates: any;
  currDate: moment.Moment;
  setCurrDate: any;
}

const service = new CalendarService();

const UpdatesEditComponent = (props: UpdatesEditProps) => {
  const { updates, propertyId, getCalendarUpdates, currDate, setCurrDate } = props;
  const [fileList, setFileList] = useState([] as any[]);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenUploadMessageModal, setIsOpenUploadMessageModal] = useState(false);
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [date, setDate] = useState(currDate);
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const [fetchingData, setFetchingData] = useState(false);

  useEffect(() => {
    setDate(currDate);
    if (updates) {
      const currUpdate = updates.filter(
        (update: any) =>
          moment(update.date || update.datetime).format('YYYY-MM-DD') === currDate.utc().format('YYYY-MM-DD'),
      )[0];
      if (currUpdate) {
        document.getElementById(`id-${currUpdate?.id}`)?.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [currDate]);

  const successUpdate = () => {
    getCalendarUpdates(propertyId);
    setValue('');
    setFileList([]);
    notification.success({
      message: t('calendar.update.create.notification'),
    });
  };

  const onSubmit = () => {
    if (!value && fileList.length === 0) {
      notification.error({
        message: t('calendar.update.no.items.notification'),
      });
      return;
    }
    setFetchingData(true);
    const newUpdate: NewUpdate = {
      description: value,
      isPublished: true,
      date: date.format('YYYY-MM-DD hh:mm'),
      properties: [
        {
          id: propertyId,
        },
      ],
      type: {
        code: CalendarTypes.update,
      },
    };

    const filesData = new FormData();
    for (const file of fileList) {
      if (file.originFileObj) {
        filesData.append('file', file.originFileObj);
      }
    }

    service
      .addCalendarUpdate(newUpdate)
      .then((value: any) => {
        if (filesData.getAll('file').length > 0 && value.id) {
          setIsOpenUploadMessageModal(true);
          service
            .uploadUpdatesFiles(value.id, filesData)
            .then(() => {
              successUpdate();
            })
            .catch((e: any) => console.log(e));
        } else {
          successUpdate();
        }
      })
      .catch((e: any) => console.log(e))
      .finally(() => setFetchingData(false));
  };

  const setOpenEdit = (id: any) => {
    setIsOpenEdit(true);
    if (id) {
      setCurrentId(id);
    }
  };

  const setOpenDelete = (id: any) => {
    setIsOpenDelete(true);
    if (id) {
      setCurrentId(id);
    }
  };

  const handleFile = (info: any) => {
    setFileList(info.fileList);
  };

  const menu = (
    <Menu className="update-menu-container">
      <Menu.Item key="edit">
        <div onClick={() => setOpenEdit(currentId)} className={css['update-menu-item']}>
          <img src={editIcon} alt="" style={{ marginRight: '10px', height: '15px', width: '15px' }} />
          <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.btn.modify')}</span>
        </div>
      </Menu.Item>
      <Menu.Item key="delete">
        <div onClick={() => setOpenDelete(currentId)} className={css['update-menu-item']}>
          <img src={deleteIcon} alt="" style={{ marginRight: '10px', height: '15px', width: '15px' }} />
          <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.title.delete')}</span>
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={css['date-news']}>
      <div className={css['date-news-desc-edit']}>
        <span style={{ color: '#778dac', fontSize: '11px' }}>{t('calendar.update.title')}</span>
        <br />
        <DatePicker
          inputReadOnly
          suffixIcon={<div className={'calendar-img'} style={{ width: '12px', height: '12px' }}></div>}
          value={date}
          allowClear={false}
          className={`${css['datepicker-updates']} common-date-picker common-animation-primary`}
          onChange={(date: any) => {
            setDate(date);
            setCurrDate(date);
          }}
          format={'DD MMMM'}
          defaultValue={currDate}
        />
        <div className={`${css['edit-field']} common-animation-primary`}>
          <TextArea
            bordered={false}
            className={css['updates-input']}
            autoSize={{ minRows: 2, maxRows: 2 }}
            onChange={(value: any) => setValue(value.target.value)}
            placeholder={t('calendar.update.placeholder')}
            value={value}
          />
          <Upload
            className={'upload-updates'}
            fileList={fileList}
            beforeUpload={() => false}
            onChange={handleFile}
            onPreview={(file: any) => {
              authDownloadFile(file.file, file.name);
            }}
            multiple
            listType="text"
            iconRender={() => <img src={icAttach} style={{ height: '15px', width: '15px' }} />}>
            <Button className="common-upload-file-btn">
              <img src={icAttach} alt="" className={css['ic-attach']} />
              <span className={css['attach-file-title']}>{t('calendar.arrach.file')}</span>
            </Button>
          </Upload>
        </div>
        <Button
          className={`${css['update-send-btn']} common-primary-btn`}
          style={{
            textTransform: 'uppercase',
          }}
          onClick={onSubmit}
          disabled={fetchingData}>
          {t('calendar.add.update.btn')}
        </Button>
      </div>
      {updates.length > 0 && (
        <div className={css['date-new-desc-container']}>
          {updates
            .sort((a: any, b: any) => new Date(b.date).getDate() - new Date(a.date).getDate())
            .map((update: UpdateModel | MeetingModel | any) => (
              <div key={update.id} id={`id-${update.id}`}>
                <div className={css['date-news-desc']}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginBottom: '0.5rem',
                    }}>
                    <li className={css['update-date']}>
                      {moment(update.date || update.datetime)
                        .locale(i18n.language)
                        .format('DD MMMM')}
                    </li>
                    <div style={{ display: 'flex' }}>
                      {update.properties?.length > 1 && <img src={linkIcon} style={{ marginRight: '0.5rem' }} />}
                      {!update.isSystem && !update.datetime && (
                        <Dropdown overlay={menu} trigger={['click']}>
                          <Button className={css['update-dropdown-btn']} onClick={() => setCurrentId(update.id)}>
                            <img src={moreIcon} alt="" height={15} width={15} />
                          </Button>
                        </Dropdown>
                      )}
                    </div>
                  </div>
                  <span className="parser-container">{parser(update.description || update.name || '')}</span>
                  {update.files && (
                    <Upload
                      listType={
                        update.type.code === CalendarTypes.document ||
                        update.files.map((file: any) => file.file.slice(-3)).includes('pdf')
                          ? 'text'
                          : 'picture-card'
                      }
                      className={
                        update.type.code === CalendarTypes.document ||
                        update.files.map((file: any) => file.file.slice(-3)).includes('pdf')
                          ? 'upload-updates'
                          : 'upload-updates-view'
                      }
                      fileList={
                        update.files.map((file: any) => ({
                          name: file.fileName || '',
                          uid: file.id,
                          url: `${config.storeUrl}/${file.file}`,
                          file: file.file,
                        })) as any
                      }
                      showUploadList={{ showRemoveIcon: false }}
                      beforeUpload={() => false}
                      onPreview={(file: any) => {
                        authDownloadFile(file.file, file.name);
                      }}
                    />
                  )}
                </div>
              </div>
            ))}
        </div>
      )}
      {isOpenDelete && (
        <PostConfirmModal
          currentId={currentId}
          isOpen={isOpenDelete}
          setClose={() => {
            setIsOpenDelete(false);
            setCurrentId(null);
            getCalendarUpdates(propertyId);
          }}
        />
      )}
      {isOpenEdit && (
        <PostModalForm
          setIsOpenUploadMessageModal={setIsOpenUploadMessageModal}
          isOpenModal={isOpenEdit}
          defaultType={CalendarTypes.update}
          postId={currentId}
          getCalendarUpdates={() => getCalendarUpdates(propertyId)}
          closeModal={() => {
            setIsOpenEdit(false);
            setCurrentId(null);
          }}
        />
      )}
      {isOpenUploadMessageModal && (
        <ModalUploadFileSuccess isOpen={isOpenUploadMessageModal} onOk={() => setIsOpenUploadMessageModal(false)} />
      )}
    </div>
  );
};

export default UpdatesEditComponent;
