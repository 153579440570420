export const getName = (name: string) => {
  return name
    .split(' ')
    .map((i: string) => i.substring(0, 1).toUpperCase())
    .join('')
    .substring(0, 3);
};

export function hashCode(str: string) {
  let hash: number = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return intToRGB(hash);
}

export function intToRGB(i: number) {
  let c = (i & 0x00ffffff).toString(16).toUpperCase();

  return '00000'.substring(0, 6 - c.length) + c;
}

export function getBase64Url(img: any, callback: (imageUrl: string | ArrayBuffer | null) => void) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}
