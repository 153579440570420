import React, { createContext, useReducer } from 'react';
import { initialState, reducer } from '../reducers/typologiesReducer';

export const TypologiesContext = createContext({} as any);

const TypologiesContextProvider: React.FC = (props: any) => {
  const [nodes, nodesDispatch] = useReducer(reducer, initialState);

  return <TypologiesContext.Provider value={{ nodes, nodesDispatch }}>{props.children}</TypologiesContext.Provider>;
};

export default TypologiesContextProvider;
