import { Col, Form, FormInstance, Select, TreeSelect } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ListPageServices from '../../../services/PropertiesService';
import css from '../../../assets/styles/consumers.module.css';
import { IProjectEasy } from '../../../models/ProjectModel';
import { IUserFull } from '../../../models/UserModel';
import { PropertyModel } from '../../../models/PropertyModel';

interface ProjectFieldsProps {
  projects: IProjectEasy[];
  user: IUserFull | undefined;
  index?: number;
  remove?: (index: number | number[]) => void;
  form: FormInstance;
  selectedProjects: string[];
  setSelectedProjects: React.Dispatch<React.SetStateAction<string[]>>;
}
const listService = new ListPageServices();

const ProjectFields = (props: ProjectFieldsProps) => {
  const { t } = useTranslation();
  const { projects, index, form, selectedProjects, setSelectedProjects } = props;
  const [properties, setProperties] = useState<PropertyModel[]>([]);

  const getProperties = (projectKey: string) => {
    listService
      .getLightTreeByProject({
        id: projects.find((project) => project.key === projectKey)!.id,
        projectKey,
      })
      .then((value: PropertyModel[]) => {
        setProperties(value);
      });
  };

  return (
    <>
      <Col span={10}>
        <Form.Item
          label={<span style={{ color: '#778dac' }}>{t('consumers.choose.project')}</span>}
          name={[index as number, 'project']}
          rules={[{ required: true, message: t('common.validation.required') }]}>
          <Select
            suffixIcon={
              <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
            }
            onChange={(value) => {
              const fieldValue = form.getFieldsValue();
              const newDefaultFields =
                fieldValue.defaultFields && fieldValue.defaultFields.map((field: { project: string }) => field.project);
              const newFields =
                fieldValue.fields && fieldValue.fields.map((field: { project: string }) => field.project);
              const newSelectedProjects = newDefaultFields ? newDefaultFields.concat(newFields) : newFields;
              setSelectedProjects(newSelectedProjects);
              getProperties(value as string);
              form.setFieldsValue({
                ...fieldValue,
                fields: fieldValue.fields.map((value: { project: string }, i: number) => {
                  if (i === index) {
                    return { ...value, properties: [] };
                  }
                  return value;
                }),
              });
            }}
            className="consumer-form-input"
            placeholder={t('users.create.card.user.projects.placeholder')}>
            {projects.map((project) => (
              <Select.Option
                value={project.key}
                key={project.id}
                title={project.title}
                disabled={selectedProjects && selectedProjects.includes(project.key)}>
                {project.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={11}>
        {properties.length > 0 && (
          <Form.Item
            shouldUpdate
            name={[index as number, 'properties']}
            label={<span style={{ color: '#778dac' }}>{t('consumers.choose.property')}</span>}
            rules={[{ required: true, message: t('common.validation.required') }]}>
            <TreeSelect
              treeData={properties}
              multiple
              showCheckedStrategy="SHOW_CHILD"
              treeCheckable
              fieldNames={{ label: 'title', value: 'id', children: 'childrens' }}
            />
          </Form.Item>
        )}
      </Col>
    </>
  );
};

export default ProjectFields;
