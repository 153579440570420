import { Col, Form, FormInstance, Select } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ListPageServices from '../../../services/PropertiesService';
import css from '../../../assets/styles/consumers.module.css';
import { IUserFull } from '../../../models/UserModel';
import { PropertyModel } from '../../../models/PropertyModel';
import { IAppUserProject } from '../../../typings/IApp';

interface ProjectFieldsProps {
  projects: IAppUserProject[];
  user: IUserFull | undefined;
  index?: number;
  remove?: (index: number | number[]) => void;
  form: FormInstance;
  selectedProjects: string[];
  setSelectedProjects: React.Dispatch<React.SetStateAction<string[]>>;
}
const listService = new ListPageServices();

const ProjectFields = (props: ProjectFieldsProps) => {
  const { t } = useTranslation();
  const { projects, index, form, selectedProjects, setSelectedProjects } = props;
  const [properties, setProperties] = useState<PropertyModel[]>([]);
  const [currentProject, setCurrentProject] = useState<string | null>(null);

  const getProperties = (projectKey: string) => {
    listService
      .getPropertyWithFilter({
        projectKey,
        sortObject: { title: 'asc' },
      })
      .then((value: PropertyModel[]) => {
        setProperties(value);
      });
  };

  return (
    <>
      <Col span={10}>
        <Form.Item
          label={<span style={{ color: '#778dac' }}>{t('consumers.choose.project')}</span>}
          name={[index as number, 'project']}
          rules={[{ required: true, message: t('common.validation.required') }]}>
          <Select
            suffixIcon={
              <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
            }
            onChange={(value) => {
              const fieldValue = form.getFieldsValue();
              const newDefaultFields =
                fieldValue.defaultFields && fieldValue.defaultFields.map((field: { project: string }) => field.project);
              const newFields =
                fieldValue.fields && fieldValue.fields.map((field: { project: string }) => field.project);
              const newSelectedProjects = newDefaultFields ? newDefaultFields.concat(newFields) : newFields;
              setSelectedProjects(newSelectedProjects);
              getProperties(value as string);
              setCurrentProject(value as string);
              form.setFieldsValue({
                ...fieldValue,
                fields: fieldValue.fields.map((value: { project: string }, i: number) => {
                  if (i === index) {
                    return { ...value, property: [] };
                  }
                  return value;
                }),
              });
            }}
            className="consumer-form-input"
            placeholder={t('users.create.card.user.projects.placeholder')}>
            {projects.map((project) => (
              <Select.Option
                value={project.key}
                key={project.id}
                title={project.title}
                disabled={selectedProjects && selectedProjects.includes(project.key)}>
                {project.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={11}>
        {properties.length > 0 && (
          <Form.Item
            shouldUpdate
            name={[index as number, 'property']}
            label={<span style={{ color: '#778dac' }}>{t('consumers.choose.property')}</span>}
            rules={[{ required: true, message: t('common.validation.required') }]}>
            <Select
              placeholder="Please select property"
              mode="multiple"
              className="consumer-form-input"
              filterOption={(input, option) => {
                return option?.title.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              allowClear={true}>
              {currentProject &&
                properties
                  .filter((property) => property.childrens && property.childrens.length === 0)
                  .map((property) => {
                    return (
                      <Select.Option value={property.id} key={property.id} title={property.title}>
                        {property.title}
                      </Select.Option>
                    );
                  })}
            </Select>
          </Form.Item>
        )}
      </Col>
    </>
  );
};

export default ProjectFields;
