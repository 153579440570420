import { FRONT_DATE_TIME_FORMAT, FRONT_DATE_FORMAT } from './../constants/formats';
import moment from 'moment';

export const convertToLocalDate = (date: string | Date | number, format?: string): string | null => {
  if (!date) {
    return null;
  }

  return moment
    .utc(new Date(date))
    .local()
    .format(format || FRONT_DATE_FORMAT);
};

export const convertToLocalDateTime = (date: string | Date | number, format?: string): string | null => {
  if (!date) {
    return null;
  }

  return moment
    .utc(new Date(date))
    .local()
    .format(format || FRONT_DATE_TIME_FORMAT);
};
