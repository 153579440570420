import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import css from '../../../../../assets/styles/faq.module.css';
import { useLessThen801 } from '../../../../../helpers/mediaDetect';

interface TopicsControlProps {
  setOpenUpdate: () => void;
}

const TopicsControl = (props: TopicsControlProps) => {
  const { setOpenUpdate } = props;
  const { t } = useTranslation();

  return (
    <div className={css['topics-control-container']}>
      <NavLink
        to={'/manage_faq'}
        className="common-animation-primary"
        style={{ marginRight: '0.5rem', borderRadius: '4px', width: useLessThen801() ? '100%' : 'auto' }}>
        <Button
          className={`common-blue-border-btn common-secondary-btn`}
          style={useLessThen801() ? { width: '100%', height: '42px' } : { height: '42px' }}>
          {t('common.return.btn')}
        </Button>
      </NavLink>
      <Button
        onClick={() => setOpenUpdate()}
        className={`common-blue-btn common-primary-btn`}
        style={useLessThen801() ? { width: '100%', height: '42px' } : { height: '42px' }}>
        {t('common.btn.new')}
      </Button>
    </div>
  );
};

export default TopicsControl;
