import React from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { getColumns } from './LevelsColumns';

interface LevelsModel {
  items: any[];
  typologyId: number;
}

const Levels: React.FC<LevelsModel> = ({ items, typologyId }: LevelsModel) => {
  const { t } = useTranslation();
  const history = useHistory();

  const onDoubleClick = (id: number) => {
    history.push(`/typologies/${typologyId}/levels/modify/${id}`);
  };

  return (
    <Table
      columns={getColumns({ t, typologyId })}
      dataSource={items}
      showHeader={true}
      size="small"
      rowKey="id"
      rowClassName="common-table-row--pointer"
      onRow={(record: any) => ({
        onDoubleClick: () => onDoubleClick(record.id),
      })}
    />
  );
};

export default Levels;
