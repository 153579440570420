import HttpService from './common/httpService';
import config from '../config';

class DashboardService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  //statistics for project (property units)
  public getProjectsInfo() {
    return this.httpService.get(`${config.baseRequest}users/dashboard`);
  }

  public getGallery(limit: number, propertyId: number) {
    return this.httpService.get(`${config.baseRequest}galery/dashboard?limit=${limit}&propertyId=${propertyId}`);
  }

  public getQuoteInfo() {
    return this.httpService.get(`${config.baseRequest}quotes/info/dashboard`);
  }

  public getAmendmentsInfo() {
    return this.httpService.get(`${config.baseRequest}amendments/info/dashboard`);
  }

  public trainingTutorial() {
    return this.httpService.post(`${config.baseRequest}users/emit/training-tutorial`);
  }

  public saveOnboard(body: any) {
    return this.httpService.put(`${config.baseRequest}users/board/checkpoint`, body);
  }
}

export default DashboardService;
