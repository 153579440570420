import { convertToPercentage, convertToCoins, convertToCurrency } from "./convertToPrice";

export const onChangeSum = (event: React.ChangeEvent<HTMLInputElement>, form: any,) => {
  if (event.target.value === '') {
    return form.setFieldsValue({ sumWithVat: '' });
  }

  const vat = form.getFieldValue('vat');
  const targetValue = convertToCoins(parseFloat(event.target.value));

  const sumWithVat = convertToCurrency(Math.floor(targetValue * convertToPercentage(vat)));
  return form.setFieldsValue({ sumWithVat });
};

export const onChangeSumWithVat = (event: React.ChangeEvent<HTMLInputElement>, form: any) => {
  if (event.target.value === '') {
    return form.setFieldsValue({ sum: '' });
  }

  const vat = form.getFieldValue('vat');
  const targetValue = convertToCoins(parseFloat(event.target.value));

  const sum = convertToCurrency(Math.floor(targetValue / convertToPercentage(vat)));
  return form.setFieldsValue({ sum });
};

export const onChangeSumFeesWithVat = (event: React.ChangeEvent<HTMLInputElement>, form: any) => {
  if (event.target.value === '') {
    return form.setFieldsValue({ sumWithFees: '' });
  }

  const vat = form.getFieldValue('vat');
  const targetValue = convertToCoins(parseFloat(event.target.value));

  const sumWithFees = convertToCurrency(Math.floor(targetValue / convertToPercentage(vat)));
  return form.setFieldsValue({ sumWithFees });
};

export const onChangeVat = (event: React.ChangeEvent<HTMLInputElement>, form: any) => {
  if (event.target.value === '') {
    return form.setFieldsValue({ sumWithVat: '' });
  }

  const sum = convertToCoins(form.getFieldValue('sum'));
  const targetValue = parseFloat(event.target.value);

  const sumWithVat = convertToCurrency(Math.floor(sum * convertToPercentage(targetValue)));
  return form.setFieldsValue({ sumWithVat });
};

export const onChangeFeesVat = (event: React.ChangeEvent<HTMLInputElement>, form: any) => {
  if (event.target.value === '') {
    return form.setFieldsValue({ sumWithVat: '' });
  }

  const sumWithFees = convertToCoins(form.getFieldValue('sumWithFees'));
  const targetValue = parseFloat(event.target.value);

  const sumWithVat = convertToCurrency(Math.floor(sumWithFees * convertToPercentage(targetValue)));
  return form.setFieldsValue({ sumWithVat });
};
