export enum FAQType {
  HIW = 'howItWorks',
}

interface IType {
  code: FAQType;
  description: string;
}

export interface TopicModel {
  createdDate: string | null;
  deletedDate: string | null;
  id: number;
  topic: string;
  topicEn: string;
  topicFr: string;
  type: null | IType;
  updatedDate: string;
}

export interface QuestionModel {
  answer: string;
  answerEn: string;
  answerFr: string;
  createdDate: string | null;
  deletedDate: string | null;
  id: number;
  isPublished: boolean;
  isPublishedEn: boolean;
  isPublishedFr: boolean;
  ord: number | null;
  question: string;
  questionEn: string;
  questionFr: string;
  topic: TopicModel;
  type: null | IType;
  updatedDate: string | null;
}

export interface CreateTopicModel {
  topicEn: string;
  topicFr: string;
}

export interface UpdateTopicModel extends CreateTopicModel {
  id: number | null;
}

export interface QuestionCreateModel {
  questionEn: string;
  questionFr: string;
  answerEn: string;
  answerFr: string;
  isPublishedEn: boolean;
  isPublishedFr: boolean;
  topic: number;
  type?: { code: FAQType.HIW } | null;
}

export interface QuestionUpdatedModel extends QuestionCreateModel {
  id: number;
}

export type QuestionsModel = Array<QuestionModel>;
export type TopicsModel = Array<TopicModel>;

export interface IAiFaqQuestion {
  id: number;
  en: {
    question: string;
    answer: string;
  };
  fr: {
    question: string;
    answer: string;
  };
}

export interface IAiTopic {
  en: {
    topic: string;
  };
  fr: {
    topic: string;
  };
}
export interface IAiTopicWithId extends IAiTopic {
  id: string;
}

export enum AiLoadingActions {
  getTopics,
  generateTopics,
  details,
  publish,
  draft,
}

export interface ILoadingAction {
  index?: number;
  isloading: boolean;
  owner: AiLoadingActions;
}

export interface IAiDedailsBody {
  text: string;
  question: Omit<IAiFaqQuestion, 'id'>;
}

export interface QuestionsCreateModel {
  ord?: number;
  topic: { id: number } | { topicEn: string; topicFr: string };
  questionEn: string;
  questionFr: string;
  answerEn: string;
  answerFr: string;
  isPublishedEn: boolean;
  isPublishedFr: boolean;
}
