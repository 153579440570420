import { Button, Modal, notification, Spin } from 'antd';
import React, { useState } from 'react';
import css from '../../../assets/styles/properties.module.css';
import { useTranslation } from 'react-i18next';
import ListPageServices from '../../../services/PropertiesService';

interface IModalProps {
  isVisible: boolean;
  onCancel: () => void;
  onOk: () => void;
  title: string;
  projectKey: string;
  id: number;
}

const service = new ListPageServices();

const PropertyDeleteConfirmModal = ({ isVisible, onCancel, title, id, onOk, projectKey }: IModalProps) => {
  const { t } = useTranslation();
  const [fetchingData, setFetchingData] = useState(false);

  const deleteTask = () => {
    setFetchingData(true);
    service
      .deleteProperty(id, projectKey)
      .then(() => {
        notification.success({
          message: t('properties.delete.success.message'),
        });
        onOk();
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingData(false));
  };

  return (
    <Spin spinning={fetchingData}>
      <Modal
        className="common-modal"
        centered
        visible={isVisible}
        style={{ width: '10rem' }}
        footer={
          <div className="common-modal-footer">
            <Button className={`common-gray-border-btn common-secondary-btn`} onClick={onCancel}>
              {t('common.confirm.btn.cancel')}
            </Button>
            <Button className={`common-green-btn common-modal-btn`} onClick={deleteTask}>
              {t('common.confirm.btn.yes')}
            </Button>
          </div>
        }
        closable={false}>
        <span className={css['delete-property-title']}>
          {t('property.modal.confirm.delete.message')}
          {` "${title}"?`}
        </span>
      </Modal>
    </Spin>
  );
};

export default PropertyDeleteConfirmModal;
